<div *ngIf="errorMessage" class="alert-danger">
  <mat-error class="mat-small">
    {{ errorMessage }}
  </mat-error>
</div>

<form *ngIf="!errorMessage" [formGroup]="userSettingsForm" fxLayout="column" fxLayoutGap="15px">

  <!-- Accounting System ID (optional field) -->
  <div fxLayout="row wrap" fxLayoutAlign="start start">
    <ati-labeled-data *ngIf="!canEditSettings" label="Accounting System ID" [data]="userSettingsForm.get('accountingSystemId').value" fxFlex.xs="100" fxFlex.gt-xs="45"></ati-labeled-data>
    <mat-form-field *ngIf="canEditSettings" fxFlex.xs="100" fxFlex.gt-xs="45">
      <input matInput placeholder="Accounting System ID" formControlName="accountingSystemId" trim="blur" mask="A*">
    </mat-form-field>
  </div>

  <div>
    <div class="mat-hint mat-small" fxLayoutAlign="start end">Authorized Locations</div>
    <!-- Add new location -->
    <div *ngIf="(activeLocations$ | async) && canEditSettings && (locations.length > 0)" fxLayout="row wrap" fxLayoutGap="5px">
      <mat-form-field>
        <mat-label>Location</mat-label>
        <mat-select [(value)]="locationSelected">
          <mat-option *ngFor="let location of locations" [value]="location">{{ location.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="canEditSettings" fxLayoutGap="5px">
        <button type="button" id="add-user-settings-location" aria-label="Add Location" mat-stroked-button color="accent" (click)="addNewLocation()">Add</button>
        <button type="button" id="add-user-settings-all-locations" aria-label="Add All Locations"mat-stroked-button color="accent" (click)="addAllLocations()">Add All</button>
      </div>
    </div>

    <div *ngIf="canEditSettings" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="5" class="authorized-location-list">
      <mat-checkbox fxFlex.xs="100" fxFlex.gt-xs="25" *ngFor="let authorizedLocation of authorizedLocations" (change)="checkedLocations.toggle(authorizedLocation)" [checked]="checkedLocations.isSelected(authorizedLocation)">
        {{ authorizedLocation.name }}
      </mat-checkbox>
    </div>
    <div *ngIf="!canEditSettings" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="5" class="authorized-location-list">
      <span *ngFor="let authorizedLocation of authorizedLocations" fxFlex.xs="100" fxFlex.gt-xs="25" class="mat-body">{{authorizedLocation.name}}</span>
    </div>

    <div *ngIf="canEditSettings && !checkedLocations.isEmpty()" fxLayoutGap="5px">
      <button type="button" id="remove-user-settings-selected-locations" aria-label="Remove Selected Locations" mat-stroked-button color="accent" (click)="removeSelectedLocations()">Remove</button>
      <button type="button" id="set-user-settings-base-location" aria-label="Set Base Location" [disabled]="(checkedLocations.selected.length !== 1) || !(checkedLocations.selected[0].isClientLocation)" mat-stroked-button color="accent" (click)="setAsBase()">Set as Base Location</button>
    </div>

  </div>

  <ati-labeled-data label="Base Location" [data]="baseLocation ? baseLocation.name : 'None'"></ati-labeled-data>

  <!-- Contract Limits (optional) -->
  <div fxLayout="column" fxLayoutGap="10px">
    <div *ngIf="canEditSettings" class="mat-hint mat-small">Contract Limits</div>
    <div *ngFor="let profile of commodityProfiles$ | async">
      <ati-labeled-data *ngIf="!canEditSettings" label="Contract Limit: {{ profile.name }}" [data]="getContractLimit(profile.docId)"></ati-labeled-data>

      <div *ngIf="canEditSettings" fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="start center">
        <mat-label fxFlex.gt-xs="25" fxFlex.xs="100">{{ profile.name }}</mat-label>
        <mat-form-field>
          <input matInput placeholder="Limit" formControlName="{{ profile.docId }}" value="{{ getContractLimit(profile.docId) }}" trim="blur" (change)="updateContractLimit(profile.docId)">
          <mat-error *ngIf="!userSettingsForm.get(profile.docId).valid">{{ getErrorMessage(userSettingsForm.get(profile.docId)) }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

</form>
