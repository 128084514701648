<div class="container" fxLayout="column" fxLayoutGap="15px">
  <h4>Manage Delivery Period</h4>
  <!-- Add new delivery period -->
  <div fxLayout="row" fxLayoutGap="5px">
    <mat-form-field>
      <mat-label>Period</mat-label>
      <mat-select [(value)]="periodSelected">
        <mat-option *ngFor="let period of periods" [value]="period">{{ period | codeToMonth:'long' }}</mat-option>
      </mat-select>
    </mat-form-field>
    <button type="button" id="add-delivery-period" aria-label="Add Delivery Period" mat-stroked-button color="accent" (click)="addNewPeriod()">Add</button>
  </div>
  <!-- Current delivery period display -->
  <div>
    <mat-chip-list #chipList aria-label="period management">
      <mat-chip *ngFor="let period of chipListPeriods" [selectable]="selectable"
                [removable]="removable" (removed)="remove(period)" [color]="oldPeriodDict[period] ? 'primary' : 'accent'" selected>
        {{ period | codeToMonth }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <ng-container *ngIf="!chipListPeriods || chipListPeriods.length === 0">
      <div class="mat-small">No periods available</div>
    </ng-container>
  </div>
  <div>
    <button type="button" id="update-delivery-period" aria-label="Update Delivery Period" mat-stroked-button color="accent" (click)="onApplyChanges()" [disabled]="!chipListChanged">Apply Changes</button>
  </div>
</div>
