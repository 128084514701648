<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

      <hms-error-message [title]="'Error Performing Hedges Search'" [message]="errorMessage"></hms-error-message>

      <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
        <h2 data-cy="page-title-hedges" class="card-title">Hedges</h2>
        <div fxLayout="row" fxLayoutAlign="end center">
          <mat-spinner *ngIf="isLoading" diameter="40" color="accent"></mat-spinner>

          <button type="button" *ngIf="!isLoading && isHedgeCreator" id="add-new-hedge" class="action-button" aria-label="Add New Hedge" (click)="addHedge()" mat-mini-fab color="accent" matTooltip="Add New Hedge">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>

      <form *ngIf="!errorMessage" [formGroup]="hedgeSearchForm" (ngSubmit)="searchHedges(true)" fxLayout="column">
        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="10px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
          <mat-form-field fxFlex.gt-xs="45">
            <mat-label>Order ID</mat-label>
            <input matInput formControlName="orderDocId" mask="A*">
          </mat-form-field>
          <mat-form-field fxFlex.gt-xs="45">
            <input matInput placeholder="Commodity Profile" formControlName="commodityProfile" [matAutocomplete]="autoCommodityProfile">
            <mat-error *ngIf="hedgeSearchForm.get('commodityProfile').invalid">You must select a valid commodity profile</mat-error>
            <button type="button" id="clear-hedge-search-commodity-profile" mat-button *ngIf="hedgeSearchForm.get('commodityProfile').value" matSuffix mat-icon-button aria-label="Clear Commodity Profile" matTooltip="Clear Commodity Profile" (click)="hedgeSearchForm.get('commodityProfile').setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoCommodityProfile="matAutocomplete" [displayWith]="displayCommodityProfile">
            <mat-option *ngFor="let profile of filteredCommodityProfiles$ | async" [value]="profile">{{ displayCommodityProfile(profile) }}</mat-option>
          </mat-autocomplete>
        </div>

        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="10px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
          <mat-form-field hmsMonthPicker>
            <input matInput [matDatepicker]="futuresYearMonthPicker" placeholder="Futures Month" formControlName="futuresYearMonth" (keydown)="$event.preventDefault()">
            <mat-datepicker-toggle matSuffix [for]="futuresYearMonthPicker"></mat-datepicker-toggle>
            <mat-datepicker #futuresYearMonthPicker startView="year" (monthSelected)="selectFuturesYearMonth($event)" panelClass="futures-date-panel month-picker"></mat-datepicker>
            <mat-error *ngIf="hedgeSearchForm.get('futuresYearMonth').invalid">{{ getErrorMessage(hedgeSearchForm.get('futuresYearMonth')) }}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
          <mat-form-field>
            <input matInput [matDatepicker]="startDatePicker" placeholder="Created Date (Start)" formControlName="startDate" [max]="hedgeSearchForm.get('endDate').value">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="hedgeSearchForm.get('startDate').invalid">{{ getErrorMessage(hedgeSearchForm.get('startDate')) }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput [matDatepicker]="endDatePicker" placeholder="Created Date (End)" formControlName="endDate" [min]="hedgeSearchForm.get('startDate').value">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
            <mat-error *ngIf="hedgeSearchForm.get('endDate').invalid">{{ getErrorMessage(hedgeSearchForm.get('endDate')) }}</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="!isSearching" fxLayout="row" fxLayoutGap="10px">
          <button id="submit-hedge-search" [disabled]="hedgeSearchForm.invalid" aria-label="Search" type="submit" mat-raised-button color="accent">Search</button>
          <button type="button" id="clear-hedge-search" *ngIf="!hedgeSearchForm.pristine" aria-label="Reset" (click)="reset()" mat-stroked-button color="accent">Reset</button>
        </div>
        <div *ngIf="isSearching" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-spinner diameter="40" color="accent"></mat-spinner>
        </div>
      </form>

      <div *ngIf="showHedges && !errorMessage" class="hedge-list">
        <hms-hedges (hedgeListChange)="handleHedgeListChange($event)" (hedgeSearchError)="handleHedgeListError($event)" (isSearching)="handleIsSearching($event)" [selectedHedges$]="selectedHedges$" [initialTableState]="tableState"></hms-hedges>
      </div>

    </mat-card>
  </div>

</div>
