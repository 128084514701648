<div fxLayout="row" fxLayoutAlign="space-around start">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <div *ngIf="errorMessage">
      <hms-error-message [title]="'Error Retrieving New Contract Form'" [message]="errorMessage"></hms-error-message>
    </div>

    <form *ngIf="!errorMessage && commodityMap$ | async" [formGroup]="contractForm">

      <!-- Title and Buttons -->
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 data-cy="page-title-new-contract" class="card-title">New Contract</h2>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
          <button type="button" id="create-contract-add-another" data-cy="submitAddAnother" class="action-button" aria-label="Create Contract, Add Another" *ngIf="updateComplete && !isLoading" [disabled]="!canSubmitContract" (click)="submit(true)" mat-mini-fab color="accent" matTooltip="Create Contract, Add Another" tabindex="60">
            <mat-icon svgIcon="create_another"></mat-icon>
          </button>
          <button type="submit" id="create-contract" data-cy="submit" class="action-button" aria-label="Create Contract" *ngIf="updateComplete && !isLoading" [disabled]="!canSubmitContract" (click)="submit()" mat-mini-fab color="accent" matTooltip="Create Contract" tabindex="61">
            <mat-icon>done</mat-icon>
          </button>
          <button type="button" id="clear-contract" class="action-button" aria-label="Discard Changes" data-cy="reset" *ngIf="!contractForm.pristine && updateComplete" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes" tabindex="62">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-spinner *ngIf="!updateComplete || isLoading" diameter="40" color="accent"></mat-spinner>
        </div>
      </div>

      <!-- Contract Inputs -->
      <div fxLayout="column" fxLayoutGap="15px" *ngIf="commodityProfiles$ | async as commodityProfiles">

        <!-- Contract Type, Pricing Type, Side, Delivery Type -->
        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">

          <div>
            <mat-button-toggle-group formControlName="contractType" aria-label="Contract Type" matTooltip="Contract Type" #ContractTypeToggle>
              <mat-button-toggle value="SPOT" data-cy="contractType-SPOT" tabindex="1">Spot</mat-button-toggle>
              <mat-button-toggle *ngFor="let type of contractTypes" attr.data-cy="contractType-{{type}}" [value]="type" tabindex="1">{{ type | contractType }}</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div *ngIf="contractForm.get('contractType').value !== 'DP' && contractForm.get('contractType').value !== 'SPOT'">
            <mat-button-toggle-group formControlName="pricingType" aria-label="Pricing Type" matTooltip="Pricing Type">
              <mat-button-toggle *ngFor="let type of pricingTypes" attr.data-cy="pricingType-{{type}}" [value]="type" tabindex="2">{{ type | pricingType | titlecase }}</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div *ngIf="contractForm.get('contractType').value !== 'DP'">
            <mat-button-toggle-group formControlName="side" aria-label="Buy or Sell">
              <mat-button-toggle *ngFor="let side of sides" attr.data-cy="side-{{side}}" [value]="side" tabindex="3">{{ side | titlecase }}</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div *ngIf="contractForm.get('contractType').value !== 'DP'">
            <mat-button-toggle-group formControlName="deliveryType" aria-label="Delivered or FOB">
              <mat-button-toggle *ngFor="let type of deliveryTypes" attr.data-cy="deliveryType-{{type}}" [value]="type" tabindex="4">{{ type === 'FOB' ? type : type | titlecase }}</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

        </div>

        <!-- Client Location, Delivery Location Patron -->
        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">

          <div fxFlex.gt-xs="30">
            <mat-form-field fxFlex="100" data-cy="clientLocation">
              <input required matInput placeholder="Client Location" formControlName="clientLocation" [matAutocomplete]="autoClientLocation" tabindex="5">
              <mat-error *ngIf="contractForm.get('clientLocation').invalid">You must select a valid client location</mat-error>
              <button type="button" id="clear-new-contract-client-location" *ngIf="contractForm.get('clientLocation').value" matSuffix mat-icon-button aria-label="Clear Client Location" matTooltip="Clear Client Location" (click)="clearField('clientLocation')" tabindex="6">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-autocomplete #autoClientLocation="matAutocomplete" [displayWith]="displayLocation">
              <mat-option *ngFor="let location of filteredClientLocations$ | async" [value]="location">{{ displayLocation(location) }}</mat-option>
            </mat-autocomplete>
          </div>

          <div fxFlex.gt-xs="30">
            <mat-form-field fxFlex="100" data-cy="deliveryLocation">
              <input required matInput placeholder="Delivery Location" formControlName="deliveryLocation" [matAutocomplete]="autoDeliveryLocation" tabindex="7">
              <mat-error *ngIf="contractForm.get('deliveryLocation').invalid">You must select a valid delivery location</mat-error>
              <button type="button" id="clear-new-contract-delivery-location" *ngIf="contractForm.get('deliveryLocation').value" matSuffix mat-icon-button aria-label="Clear Delivery Location" matTooltip="Clear Delivery Location" (click)="clearField('deliveryLocation')" tabindex="8">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-autocomplete #autoDeliveryLocation="matAutocomplete" [displayWith]="displayLocation">
              <mat-option *ngFor="let location of filteredDeliveryLocations$ | async" [value]="location">{{ displayLocation(location) }}</mat-option>
            </mat-autocomplete>
          </div>

          <div fxFlex.gt-xs="30">
            <mat-form-field fxFlex="100" data-cy="patron">
              <input required matInput placeholder="Patron" formControlName="patron" [matAutocomplete]="autoPatron" tabindex="9">
              <mat-error *ngIf="contractForm.get('patron').invalid">You must select a valid patron</mat-error>
              <div matSuffix fxLayout="row" fxLayoutAlign="space-between center">
                <mat-spinner diameter="16" *ngIf="isLoadingPatron"></mat-spinner>
                <button type="button" id="clear-new-contract-patron" *ngIf="contractForm.get('patron').value" matSuffix mat-icon-button aria-label="Clear Patron" matTooltip="Clear Patron" (click)="clearField('patron')" tabindex="10">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </mat-form-field>
            <ng-container *ngIf="activePatrons$ | async"></ng-container>
            <ng-container *ngIf="patronControlChanges$ | async"></ng-container>
            <mat-autocomplete #autoPatron="matAutocomplete" [displayWith]="displayPatron">
              <mat-option *ngFor="let patron of filteredPatrons$ | async" [value]="patron">{{ displayPatron(patron) }}</mat-option>
            </mat-autocomplete>
          </div>

        </div>

        <!-- Commodity Profile, Production Year, Quantity -->
        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">

          <mat-form-field fxFlex.gt-xs="30" data-cy="commodityProfile">
            <mat-select required placeholder="Commodity Profile" formControlName="commodityProfile" [compareWith]="compareCommodityProfile" tabindex="11">
              <mat-option *ngFor="let profile of commodityProfiles" [value]="profile">{{ profile.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="contractForm.get('commodityProfile').invalid">{{ getErrorMessage(contractForm.get('commodityProfile')) }}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.gt-xs="30" *ngIf="contractForm.get('commodityProfile').value" data-cy="productionYear">
            <mat-select required placeholder="Production Year" formControlName="productionYear" [compareWith]="compareProductionYear" tabindex="12">
              <mat-option *ngFor="let prodYear of prodYears" [value]="prodYear">{{ prodYear.label | productionYear }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex.gt-xs="30" *ngIf="contractForm.get('commodityProfile').value" data-cy="quantity">
            <input required matInput [placeholder]="getContractUnit() | contractUnit" formControlName="quantity" (blur)="formatQuantity('quantity')" tabindex="13">
            <mat-error *ngIf="contractForm.get('quantity').invalid">{{ getErrorMessage(contractForm.get('quantity')) }}</mat-error>
            <button type="button" id="clear-new-contract-quantity" *ngIf="contractForm.get('quantity').value" matSuffix mat-icon-button aria-label="Clear Quantity" matTooltip="Clear Quantity" (click)="clearField('quantity')" tabindex="14">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

        </div>

        <!-- Display after Commodity Profile and Delivery Location are selected -->
        <div *ngIf="contractForm.get('commodityProfile').value && contractForm.get('deliveryLocation').value" fxLayout="column" fxLayoutGap="15px">

          <!-- Delivery Period, Futures Month, Expiration Date -->
          <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">

            <mat-form-field fxFlex.gt-xs="30" hmsMonthPicker>
              <input required matInput [matDatepicker]="deliveryPeriodPicker" placeholder="Delivery Period" formControlName="deliveryPeriod" [min]="minDate" (keydown)="allowTabOnly($event)" tabindex="15">
              <mat-datepicker-toggle matSuffix [for]="deliveryPeriodPicker" data-cy="deliveryPeriodPicker" #deliveryPeriodPickerToggle tabindex="16"></mat-datepicker-toggle>
              <mat-datepicker #deliveryPeriodPicker startView="year" (monthSelected)="selectDeliveryPeriod($event)" panelClass="month-picker" (closed)="onCloseDatePicker('deliveryPeriodPickerToggle')"></mat-datepicker>
              <mat-error *ngIf="contractForm.get('deliveryPeriod').invalid">{{ getErrorMessage(contractForm.get('deliveryPeriod')) }}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="contractForm.get('contractType').value !== 'DP'" fxFlex.gt-xs="30" hmsMonthPicker data-cy="futuresYearMonth">
              <input required matInput [matDatepicker]="futuresMonthPicker" [matDatepickerFilter]="validFuturesMonthFilter" placeholder="Futures Month" formControlName="futuresYearMonth" [min]="minDate" (keydown)="allowTabOnly($event)" tabindex="17">
              <mat-datepicker-toggle matSuffix [for]="futuresMonthPicker" data-cy="futuresMonthPicker" #futuresMonthPickerToggle tabindex="18"></mat-datepicker-toggle>
              <mat-datepicker #futuresMonthPicker startView="year" (monthSelected)="selectFuturesMonth($event)" panelClass="futures-date-panel month-picker" (closed)="onCloseDatePicker('futuresMonthPickerToggle')"></mat-datepicker>
              <mat-error *ngIf="contractForm.get('futuresYearMonth').invalid">{{ getErrorMessage(contractForm.get('futuresYearMonth')) }}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="contractForm.get('contractType').value !== 'DP' && contractForm.get('pricingType').value === 'LIMIT'" fxFlex.gt-xs="30" data-cy="expirationDate">
              <input matInput [matDatepicker]="expirationDatePicker" placeholder="Expiration Date" formControlName="expirationDate" [matDatepickerFilter]="expDateFilter" [min]="expiryMinDate" [max]="expiryMaxDate" (keydown)="allowTabOnly($event)" tabindex="19">
              <mat-datepicker-toggle matSuffix [for]="expirationDatePicker" data-cy="expirationDatePicker" #expirationDatePickerToggle tabindex="20"></mat-datepicker-toggle>
              <mat-datepicker #expirationDatePicker (closed)="onCloseDatePicker('expirationDatePickerToggle')"></mat-datepicker>
              <mat-error *ngIf="contractForm.get('expirationDate').invalid">{{ getErrorMessage(contractForm.get('expirationDate')) }}</mat-error>
            </mat-form-field>

          </div>

          <!-- Special Handling, Special Handling Type, Exchange Type -->
          <div *ngIf="canCreateExchange || canCreateHedgedContract" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">

            <div fxFlex.gt-xs="20" fxLayout="row wrap" fxLayoutAlign.gt-xs="start center" fxLayoutAlign.xs="start start">
              <div class="checkbox-group">
                <mat-checkbox formControlName="specialHandling" data-cy="specialHandling" tabindex="21">Special Handling</mat-checkbox>
              </div>

              <!-- Placeholder for vertical spacing -->
              <div style="visibility: hidden">
                <mat-button-toggle-group>
                  <mat-button-toggle value="x">X</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>

            <div fxFlex.gt-xs="40" *ngIf="contractForm.get('specialHandling').value">
              <mat-radio-group formControlName="specialHandlingType" required>
                <mat-radio-button *ngIf="canCreateExchange" value="EXCHANGE" data-cy="specialHandlingType-EXCHANGE" tabindex="22">Price with Exchange</mat-radio-button>
                <mat-radio-button *ngIf="canCreateHedgedContract" value="OTC" data-cy="specialHandlingType-OTC" tabindex="23">OTC Trade</mat-radio-button>
                <mat-radio-button *ngIf="canCreateHedgedContract" value="OPTION" data-cy="specialHandlingType-OPTION" tabindex="24">Exercised Option</mat-radio-button>
              </mat-radio-group>
            </div>

            <div fxFlex.gt-xs="30" *ngIf="contractForm.get('specialHandling').value && contractForm.get('specialHandlingType').value === 'EXCHANGE'">
              <mat-button-toggle-group formControlName="exchangeType" aria-label="Exchange Type" data-cy="exchangeType">
                <mat-button-toggle *ngFor="let exchangeType of exchangeTypes" [value]="exchangeType" attr.data-cy="exchangeType-{{exchangeType}}" tabindex="25">{{ exchangeType }}</mat-button-toggle>
              </mat-button-toggle-group>
            </div>

          </div>

          <!-- Exchange Contracts, Contra Firm, Contra Account -->
          <div *ngIf="contractForm.get('specialHandling').value && contractForm.get('specialHandlingType').value === 'EXCHANGE'" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">

            <mat-form-field fxFlex.gt-xs="30" data-cy="exchangeContracts">
              <input required matInput placeholder="Exchange Contracts" formControlName="exchangeContracts" trim="blur" tabindex="26">
              <mat-error *ngIf="contractForm.get('exchangeContracts').invalid">{{ getErrorMessage(contractForm.get('exchangeContracts')) }}</mat-error>
              <button type="button" id="clear-new-contract-exchange-contracts" *ngIf="contractForm.get('exchangeContracts').value" matSuffix mat-icon-button aria-label="Clear Contracts" matTooltip="Clear Contracts" (click)="clearField('exchangeContracts')" tabindex="27">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field fxFlex.gt-xs="30" data-cy="exchangeContraFirm">
              <input required matInput placeholder="Contra Firm" formControlName="exchangeContraFirm" trim="blur" tabindex="28">
              <mat-error *ngIf="contractForm.get('exchangeContraFirm').invalid">{{ getErrorMessage(contractForm.get('exchangeContraFirm')) }}</mat-error>
              <button type="button" id="clear-new-contract-exchange-contra-firm" *ngIf="contractForm.get('exchangeContraFirm').value" matSuffix mat-icon-button aria-label="Clear Contra Firm" matTooltip="Clear Contra Firm" (click)="clearField('exchangeContraFirm')" tabindex="29">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field fxFlex.gt-xs="30" data-cy="exchangeContraAccount">
              <input required matInput placeholder="Contra Account" formControlName="exchangeContraAccount" trim="blur" tabindex="30">
              <mat-error *ngIf="contractForm.get('exchangeContraAccount').invalid">{{ getErrorMessage(contractForm.get('exchangeContraAccount')) }}</mat-error>
              <button type="button" id="clear-new-contract-exchange-contra-account" *ngIf="contractForm.get('exchangeContraAccount').value" matSuffix mat-icon-button aria-label="Clear Contra Account" matTooltip="Clear Contra Account" (click)="clearField('exchangeContraAccount')" tabindex="31">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <!-- Option Contracts -->
          <div *ngIf="contractForm.get('specialHandling').value && contractForm.get('specialHandlingType').value === 'OPTION'" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
            <mat-form-field fxFlex.gt-xs="30" data-cy="optionContracts">
              <input required matInput placeholder="Option Contracts" formControlName="optionContracts" trim="blur" [errorStateMatcher]="optionQuantityErrorMatcher" tabindex="32">
              <mat-error *ngIf="contractForm.get('optionContracts').invalid || contractForm.hasError('optionQuantity')">{{ getErrorMessage(contractForm.get('optionContracts')) }}</mat-error>
              <button type="button" id="clear-new-contract-option-contracts" *ngIf="contractForm.get('optionContracts').value" matSuffix mat-icon-button aria-label="Clear Contracts" matTooltip="Clear Contracts" (click)="clearField('optionContracts')" tabindex="33">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <!-- Basis, Futures, Cash -->
          <div *ngIf="contractForm.get('contractType').value !== 'DP'" fxLayout="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutGap.xs="0px" fxLayoutAlign="start center">

            <div fxFlex.gt-xs="30" fxFlex.xs="100" *ngIf="contractForm.get('contractType').value !== 'HTA' && contractForm.get('basisPrice').disabled" fxLayout="row">
              <ati-labeled-data label="Basis" [data]="getDisplayPrice(marketBasisPrice, 'basisPrice')"></ati-labeled-data>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div *ngIf="isLoadingBasis" class="disabled-loading-spinner">
                  <mat-spinner diameter="16"></mat-spinner>
                </div>
              </div>
            </div>
            <mat-form-field fxFlex.gt-xs="30" fxFlex.xs="100" *ngIf="contractForm.get('contractType').value !== 'HTA' && contractForm.get('basisPrice').enabled">
              <input required matInput placeholder="Basis" formControlName="basisPrice" trim="blur" (blur)="processUpdatedPrice('basisPrice')" (input)="calculateCash()" data-cy="basisPrice" tabindex="34">
              <mat-error *ngIf="contractForm.get('basisPrice').invalid">{{ getErrorMessage(contractForm.get('basisPrice')) }}</mat-error>
              <mat-spinner matSuffix *ngIf="isLoadingBasis" diameter="16"></mat-spinner>
              <button type="button" id="clear-new-contract-basis" *ngIf="contractForm.get('basisPrice').value" matSuffix mat-icon-button aria-label="Clear Basis" matTooltip="Clear Basis" (click)="clearField('basisPrice')" tabindex="35">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <div fxFlex.gt-xs="30" fxFlex.xs="100" *ngIf="contractForm.get('contractType').value !== 'BASIS' && contractForm.get('futuresPrice').disabled" fxLayout="row">
              <ati-labeled-data label="Futures" [data]="getDisplayPrice(contractForm.get('futuresPrice').value, 'futuresPrice')" data-cy="futuresPrice"></ati-labeled-data>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div *ngIf="isLoadingFutures" class="disabled-loading-spinner">
                  <mat-spinner diameter="16"></mat-spinner>
                </div>
                <button *ngIf="contractForm.get('pricingType').value === 'MARKET' && !isLoadingFutures" id="new-contract-refresh-futures-price" type="button" mat-icon-button (click)="refreshFutures()" aria-label="Refresh Futures" matTooltip="Refresh Futures">
                  <mat-icon class="disabled-refresh-icon">refresh</mat-icon>
                </button>
              </div>
            </div>
            <mat-form-field fxFlex.gt-xs="30" fxFlex.xs="100" *ngIf="contractForm.get('contractType').value !== 'BASIS' && contractForm.get('futuresPrice').enabled">
              <input required matInput placeholder="Futures" formControlName="futuresPrice" trim="blur" (blur)="processUpdatedPrice('futuresPrice')" (input)="calculateCash()" data-cy="futuresPrice" tabindex="36">
              <mat-error *ngIf="contractForm.get('futuresPrice').invalid">{{ getErrorMessage(contractForm.get('futuresPrice')) }}</mat-error>
              <div matSuffix fxLayout="row" fxLayoutAlign="space-between center">
                <mat-spinner [ngClass]="isLoadingFutures ? '' : 'hidden-spinner'" diameter="16"></mat-spinner>
                <button *ngIf="!isLoadingFutures" id="new-contract-refresh-futures-price" type="button" mat-icon-button (click)="refreshFutures()" aria-label="Refresh Futures" matTooltip="Refresh Futures" data-cy=refreshFutures tabindex="37">
                  <mat-icon>refresh</mat-icon>
                </button>
                <button type="button" id="clear-new-contract-futures" *ngIf="contractForm.get('futuresPrice').value" mat-icon-button aria-label="Clear Futures" matTooltip="Clear Futures" (click)="clearField('futuresPrice')" tabindex="38">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </mat-form-field>

            <div fxFlex.gt-xs="30" fxFlex.xs="100" *ngIf="contractType === 'CASH' && contractForm.get('cashPrice').disabled" fxLayout="row">
              <ati-labeled-data label="Cash" [data]="getDisplayPrice(contractForm.get('cashPrice').value, 'cashPrice')" data-cy="cashPrice"></ati-labeled-data>
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div *ngIf="isLoadingFutures || isLoadingBasis" class="disabled-loading-spinner">
                  <mat-spinner diameter="16"></mat-spinner>
                </div>
              </div>
            </div>
            <mat-form-field fxFlex.gt-xs="30" fxFlex.xs="100" *ngIf="contractForm.get('contractType').value === 'CASH' && contractForm.get('cashPrice').enabled">
              <input required matInput placeholder="Cash" formControlName="cashPrice" trim="blur" (blur)="processUpdatedPrice('cashPrice')" (input)="calculateCash(true)" data-cy="cashPrice" tabindex="39">
              <mat-error *ngIf="contractForm.get('cashPrice').invalid">{{ getErrorMessage(contractForm.get('cashPrice')) }}</mat-error>
              <button type="button" id="clear-new-contract-cash" *ngIf="contractForm.get('cashPrice').value" matSuffix mat-icon-button aria-label="Clear Cash" matTooltip="Clear Cash" (click)="clearField('cashPrice')" tabindex="40">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

          </div>

          <!-- Freight, Basis Adjustment, Exchange Rate, Custom Contract ID -->
          <div fxLayout="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutGap.xs="0px" fxLayoutAlign="start start">

            <mat-form-field fxFlex.gt-xs="30" fxFlex.xs="100" *ngIf="contractForm.get('contractType').value !== 'SPOT' && canAdjustBasis">
              <input required matInput placeholder="Freight" formControlName="freightPrice" trim="blur" (blur)="processUpdatedPrice('freightPrice')" (input)="calculateCash()" data-cy="freightPrice" tabindex="41">
              <mat-error *ngIf="contractForm.get('freightPrice').invalid">{{ getErrorMessage(contractForm.get('freightPrice')) }}</mat-error>
              <button type="button" id="clear-new-contract-freight" *ngIf="contractForm.get('freightPrice').value" matSuffix mat-icon-button aria-label="Clear Freight" matTooltip="Clear Freight" (click)="clearField('freightPrice', '0.0000')" tabindex="42">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field fxFlex.gt-xs="30" fxFlex.xs="100" *ngIf="contractForm.get('contractType').value !== 'SPOT' && canAdjustBasis">
              <input required matInput placeholder="Basis Adjustment" formControlName="basisAdjustment" trim="blur" (blur)="processUpdatedPrice('basisAdjustment')" (input)="calculateCash()" data-cy="basisAdjustment" tabindex="43">
              <mat-error *ngIf="contractForm.get('basisAdjustment').invalid">{{ getErrorMessage(contractForm.get('basisAdjustment')) }}</mat-error>
              <button type="button" id="clear-new-contract-basis-adjustment" *ngIf="contractForm.get('basisAdjustment').value" matSuffix mat-icon-button aria-label="Clear Basis Adjustment" matTooltip="Clear Basis Adjustment" (click)="clearField('basisAdjustment', '0.0000')" tabindex="44">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field fxFlex.gt-xs="30" fxFlex.xs="100" *ngIf="isInternational">
              <input required matInput placeholder="Exchange Rate" formControlName="exchangeRate" trim="blur" (blur)="processUpdatedPrice('exchangeRate')" (input)="calculateCash()" tabindex="45">
              <mat-error *ngIf="contractForm.get('exchangeRate').invalid">{{ getErrorMessage(contractForm.get('exchangeRate')) }}</mat-error>
              <button type="button" id="clear-new-contract-exchange-rate" *ngIf="contractForm.get('exchangeRate').value" matSuffix mat-icon-button aria-label="Clear Exchange Rate" matTooltip="Clear Exchange Rate" (click)="clearField('exchangeRate', '1.0000')" tabindex="46">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-form-field fxFlex.gt-xs="30" fxFlex.xs="100" *ngIf="contractForm.get('side').value === 'SELL' && contractForm.get('contractType').value !== 'DP'">
              <input matInput placeholder="Custom Contract ID" formControlName="customContractId" trim="blur" data-cy="customContractId" tabindex="47">
              <mat-error *ngIf="contractForm.get('customContractId').invalid">{{ getErrorMessage(contractForm.get('customContractId')) }}</mat-error>
              <button type="button" id="clear-new-contract-custom-contract-id" *ngIf="contractForm.get('customContractId').value && contractForm.get('customContractId').enabled" matSuffix mat-icon-button aria-label="Clear Custom Contract ID" matTooltip="Clear Custom Contract ID" (click)="clearField('customContractId')" tabindex="48">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>

          <!-- Price Adjustments Total, Select Price Adjustment, Price Adjustments -->
          <div *ngIf="contractForm.get('side').value === 'BUY' && canAdjustPrices && usePriceAdjustments" fxLayout="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutGap.xs="0px" fxLayoutAlign="start start">
            <form [formGroup]="contractForm.get('priceAdjustments')" fxFlex.gt-xs="30" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="space-between bottom">

              <ati-labeled-data label="Price Adjustments Total" data="{{ priceAdjustmentsTotal | number:'1.0-5' }}"></ati-labeled-data>

              <mat-form-field  *ngIf="unusedPriceAdjustments.length">
                <mat-label>Price Adjustments</mat-label>
                <mat-select #newPriceAdjustment data-cy="priceAdjustments" tabindex="49">
                  <mat-option *ngFor="let priceAdjustment of unusedPriceAdjustments" [value]="priceAdjustment" attr.data-cy="priceAdjustments-{{priceAdjustment.name}}">{{ getHMSPriceAdjustmentDisplay(priceAdjustment) }}</mat-option>
                </mat-select>
                <button data-cy="addAdjustment" type="button" id="add-new-contract-price-adjustment" [disabled]="!newPriceAdjustment.value" mat-button matSuffix mat-icon-button aria-label="Add Price Adjustment" matTooltip="Add Price Adjustment" (click)="addPriceAdjustment($event)" tabindex="50" >
                  <mat-icon>add_circle</mat-icon>
                </button>
              </mat-form-field>

              <mat-form-field *ngFor="let priceAdjustmentId of usedPriceAdjustments">
                <input required matInput attr.data-cy="addedAdjustments-{{priceAdjustmentId}}" [placeholder]="getContractPriceAdjustmentLabel(priceAdjustmentId)" [formControlName]="priceAdjustmentId" trim="blur" (blur)="processUpdatedPriceAdjustment(priceAdjustmentId)" (input)="updatePriceAdjustmentsTotal()" tabindex="51">
                <mat-error *ngIf="contractForm.get('priceAdjustments').get(priceAdjustmentId).invalid">{{ getErrorMessage(contractForm.get('priceAdjustments').get(priceAdjustmentId)) }}</mat-error>
                <div matSuffix fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
                  <button type="button" id="clear-new-contract-price-adjustment" *ngIf="contractForm.get('priceAdjustments').get(priceAdjustmentId).value" mat-icon-button aria-label="Clear Value" matTooltip="Clear Value" (click)="contractForm.get('priceAdjustments').get(priceAdjustmentId).setValue('')" tabindex="52">
                    <mat-icon>close</mat-icon>
                  </button>
                  <button type="button" id="remove-new-contract-price-adjustment" mat-icon-button aria-label="Remove Price Adjustment" matTooltip="Remove Price Adjustment" (click)="removePriceAdjustment(priceAdjustmentId)" tabindex="53">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </mat-form-field>

            </form>
          </div>

          <!-- Comments -->
          <mat-form-field >
            <textarea data-cy="comments" matInput placeholder="Comments" formControlName="comments" tabindex="54"></textarea>
            <mat-hint>{{ contractForm.get('comments').value ? contractForm.get('comments').value.length : 0 }}/800</mat-hint>
            <mat-error *ngIf="contractForm.get('comments').invalid">{{ getErrorMessage(contractForm.get('comments')) }}</mat-error>
            <button type="button" id="clear-new-contract-comments" *ngIf="contractForm.get('comments').value" matSuffix mat-icon-button aria-label="Clear Comments" matTooltip="Clear Comments" (click)="clearField('comments')" tabindex="55">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <!-- Instructions -->
          <mat-form-field >
            <textarea data-cy="instructions" matInput placeholder="Instructions" formControlName="instructions" trim="blur" tabindex="56"></textarea>
            <mat-hint>{{ contractForm.get('instructions').value ? contractForm.get('instructions').value.length : 0 }}/500</mat-hint>
            <mat-error *ngIf="contractForm.get('instructions').invalid">{{ getErrorMessage(contractForm.get('instructions')) }}</mat-error>
            <button type="button" id="clear-new-contract-instructions" *ngIf="contractForm.get('instructions').value" matSuffix mat-icon-button aria-label="Clear Instructions" matTooltip="Clear Instructions" (click)="clearField('instructions')" tabindex="57">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <!-- Originator -->
          <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">

            <div fxFlex.gt-xs="30">
              <mat-form-field fxFlex="100" data-cy="originator">
                <input required matInput placeholder="Originator" formControlName="originator" [matAutocomplete]="autoOriginator" tabindex="58">
                <mat-error *ngIf="contractForm.get('originator').invalid">You must select a valid originator</mat-error>
                <button type="button" id="clear-new-contract-originator" *ngIf="contractForm.get('originator').value" matSuffix mat-icon-button aria-label="Clear Originator" matTooltip="Clear Originator" (click)="clearField('originator')" tabindex="59">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <mat-autocomplete #autoOriginator="matAutocomplete" [displayWith]="displayOriginator">
                <mat-option *ngFor="let originator of filteredOriginators$ | async" [value]="originator">{{ displayOriginator(originator) }}</mat-option>
              </mat-autocomplete>
            </div>

          </div>

        </div>

      </div>
    </form>

  </mat-card>
</div>
