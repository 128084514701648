<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
      <hms-error-message [title]="'Error Retrieving On-Demand Quotes Report'" [message]="errorMessage"></hms-error-message>

      <div *ngIf="!errorMessage" fxLayout="column" fxLayoutAlign="space-between start">
        <h2 data-cy="page-title-on-demand-quotes-report" class="card-title">On-Demand Quotes Report</h2>
        <form [formGroup]="onDemandQuotesSearchForm" (ngSubmit)="loadOnDemandQuotesReportData()" fxLayout="column" class="full-width">
          <div fxLayoutGap.gt-xs="25px" fxLayout.xs="column">
            <mat-form-field fxFlex.gt-xs="250px" hmsMonthPicker>
              <input matInput [matDatepicker]="periodMonthPicker" placeholder="Month" formControlName="periodMonth" (keydown)="$event.preventDefault()">
              <mat-datepicker-toggle matSuffix [for]="periodMonthPicker"></mat-datepicker-toggle>
              <mat-datepicker #periodMonthPicker startView="year" (monthSelected)="selectPeriod($event)" panelClass="period-date-panel month-picker"></mat-datepicker>
            </mat-form-field>
          </div>
          <div *ngIf="!isLoading" fxLayout="row" fxLayoutGap="10px">
            <button id="generate-on-demand-quotes-report" [disabled]="onDemandQuotesSearchForm.invalid" aria-label="Generate Report" type="submit" mat-raised-button color="accent">Generate Report</button>
            <button type="button" id="clear-on-demand-quotes-report" *ngIf="!onDemandQuotesSearchForm.pristine" aria-label="Discard Report Options" (click)="reset()" mat-stroked-button color="accent">Reset</button>
          </div>
          <div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-spinner diameter="40" color="accent"></mat-spinner>
          </div>
        </form>
      </div>

      <ng-container *ngIf="onDemandQuotes$ | async">
        <div *ngIf="!errorMessage && displayReports" class="quote-list" fxLayout="column" fxLayoutGap="10px">
          <h4>{{ periodMonthHeader | date:'MMMM yyyy' }} Report
            <button *ngIf="dataSource.data.length" matTooltip="Export" aria-label="Export" id="export-on-demand-quotes-report" mat-icon-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button"><mat-icon>save_alt</mat-icon></button>
          </h4>
          <mat-menu #exportMenu="matMenu">
            <button aria-label="Export Excel" type="button" id="export-on-demand-quotes-report-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'On-Demand Quotes Report'))">Excel</button>
            <button aria-label="Export CSV" type="button" id="export-on-demand-quotes-report-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'On-Demand Quotes Report'))">CSV</button>
            <button aria-label="Export JSON" type="button" id="export-on-demand-quotes-report-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'On-Demand Quotes Report'))">JSON</button>
          </mat-menu>
          <ati-labeled-data *ngIf="!isSearching" label="Total Quotes" data="{{ clientOnDemandUsage | number }}" class="total-quotes"></ati-labeled-data>
          <div>
            <div fxLayout="column" fxFlex.xs="100" fxFlex.gt-xs="50" fxFlex.gt-md="40">
              <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" class="mat-elevation-z0">
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef fxFlex="70">User</mat-header-cell>
                  <mat-cell *matCellDef="let quotes" fxFlex="70">{{ quotes.name }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="userOnDemandUsage">
                  <mat-header-cell *matHeaderCellDef fxFlex="30">Quotes</mat-header-cell>
                  <mat-cell *matCellDef="let quotes" fxFlex="30">{{ quotes.userOnDemandUsage | number }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="footer">
                  <mat-footer-cell *matFooterCellDef [attr.colspan]="2">No Quotes</mat-footer-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                <mat-row *matRowDef="let quotes; columns: columnsToDisplay"></mat-row>
                <ng-container *ngIf="!dataSource.data.length">
                  <mat-footer-row *matFooterRowDef="footerColumnsToDisplay"></mat-footer-row>
                </ng-container>
              </mat-table>
            </div>
          </div>
        </div>
      </ng-container>
    </mat-card>
  </div>
</div>
