import { FormControl } from '@angular/forms';

// Note: default price regex
export const PRICE_REGEX = /^-?(\d+|\d*\.\d{1,2}|\d*\.\d{2}(0|(00)|(25)|(50)|(5)|(75))|\d+\.\d{0,2}|\d+\.\d{0,2}(0|(00)|(25)|(50)|(5)|(75)))$/;
export const FUTURES_REGEX = /^(\d+|\d*\.\d{1,2}|\d*\.\d{2}(0|(00)|(25)|(50)|(5)|(75))|\d+\.\d{0,2}|\d+\.\d{0,2}(0|(00)|(25)|(50)|(5)|(75)))$/;

// Note: used for OTC and Exercised Options and price adjustments
export const FIVE_DIGIT_REGEX = /^-?(\d+|\d*\.\d{1,5}|\d+\.\d{0,5})$/;

// Note: used when client settings dictate rounding prices to whole cents
export const WHOLE_CENT_REGEX = /^-?(\d+|\d*\.\d{1,2}0*|\d*\.\d{2}0*)$/;

// Note: used for price adjustments
export const NON_ZERO_REGEX = /^(?!-?(0*)?\.?(0*)?$)/;

// Note: for exchange rate. Likely inaccurate as it allows for negatives; not currently used by any client
export const EXCHANGE_RATE_REGEX = /^-?(\d+|\d*\.\d{1,4}|\d+\.\d{0,4})$/;

export class PriceValidators {

  static basicPriceValidator(control: FormControl): {[key: string]: boolean} | null {
    const price = control.value ? control.value : '';
    const invalidBasicPrice = price ? !PRICE_REGEX.test(price) : false;
    return invalidBasicPrice ? {invalidBasicPrice: true} : null;
  }

  static fiveDigitValidator(control: FormControl): { [key: string]: boolean } | null {
    const price = control.value ? control.value : '';
    const invalidFiveDigitPrice = price ? !FIVE_DIGIT_REGEX.test(price) : false;
    return invalidFiveDigitPrice ? {invalidFiveDigitPrice: true} : null;
  }

  // used for cash as well as they both require a positive price
  static futuresPriceValidator(control: FormControl): {[key: string]: boolean} | null {
    const futuresPrice = control.value ? control.value : '';
    const invalidFuturesPrice = futuresPrice ? !FUTURES_REGEX.test(futuresPrice) : false;
    return invalidFuturesPrice ? {invalidFuturesPrice: true} : null;
  }

  static wholeCentValidator(control: FormControl): { [key: string]: boolean } | null {
    const price = control.value ? control.value : '';
    const invalidWholeCentPrice = price ? !WHOLE_CENT_REGEX.test(price) : false;
    return invalidWholeCentPrice ? { invalidWholeCentPrice: true } : null;
  }

  static nonZeroValidator(control: FormControl): { [key: string]: boolean } | null {
    const price = control.value ? control.value : '';
    const invalidNonZeroPrice = price ? !NON_ZERO_REGEX.test(price) : false;
    return invalidNonZeroPrice ? { invalidNonZeroPrice: true } : null;
  }

  static exchangeValidator(control: FormControl): { [key: string]: boolean } | null {
    const price = control.value ? control.value : '';
    const invalidExchange = price ? !EXCHANGE_RATE_REGEX.test(price) : false;
    return invalidExchange ? { invalidExchange: true } : null;
  }

}
