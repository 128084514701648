<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <hms-error-message [title]="'Error Retrieving Commodity Profiles List'" [message]="errorMessage"></hms-error-message>

    <div *ngIf="!errorMessage" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 data-cy="page-title-commodity-profiles" class="card-title">Commodity Profiles</h2>
        <button type="button" id="add-new-commodity-profile" class="action-button" *ngIf="isCommodityProfileAdmin" aria-label="Add New Commodity Profile" (click)="addCommodityProfile()" mat-mini-fab color="accent" matTooltip="Add New Commodity Profile">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="locations-filter-field" #filter>
        <button type="button" id="clear-commodity-profiles-filter" mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <div *ngIf="isLoading" fxFlex="100" fxLayoutAlign="space-around center">
        <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
      </div>

      <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="75" fxFlex.gt-xs="36">Name</mat-header-cell>
          <mat-cell *matCellDef="let commodityProfile" fxFlex.xs="75" fxFlex.gt-xs="36">{{ commodityProfile.name }}</mat-cell>
        </ng-container>
       <ng-container matColumnDef="accountNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="15">Account</mat-header-cell>
          <mat-cell *matCellDef="let commodityProfile" fxFlex.gt-xs="15">{{ commodityProfile.officeCode + commodityProfile.accountNumber }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="commodityName">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="25">Commodity</mat-header-cell>
          <mat-cell *matCellDef="let commodityProfile" fxFlex.gt-xs="25">{{ commodityProfile.commodityName }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="isActive">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.gt-xs="12">Active</mat-header-cell>
          <mat-cell *matCellDef="let commodityProfile" fxFlex.xs="25" fxFlex.gt-xs="12">{{ commodityProfile.isActive | yesNo }}</mat-cell>
        </ng-container>
         <ng-container matColumnDef="isSpreadProfile">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="12">Spreads</mat-header-cell>
          <mat-cell *matCellDef="let commodityProfile" fxFlex.gt-xs="12">{{ commodityProfile.isSpreadProfile | yesNo }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row (click)="selectCommodityProfile(commodityProfile)" *matRowDef="let commodityProfile; columns: columnsToDisplay"></mat-row>
      </mat-table>
      <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50]"></mat-paginator>
    </div>
  </mat-card>
</div>
