import { AfterViewInit, ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommonValidators } from '@advance-trading/angular-common-services';
import { CommodityProfileService } from '@advance-trading/angular-ops-data';
import { Client, CommodityProfile, CommodityProfileProductionYear, HMSClientSettings, LedgerAdjustment, LedgerAdjustmentType } from '@advance-trading/ops-data-lib';

import moment from 'moment-timezone';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { LedgerAdjustmentService } from '../../service/ledger-adjustment.service';
import { LedgerHelperService } from '../../service/ledger-helper.service';

import { LedgerAdjustmentDisplay } from '../ledger-adjustment-display';
import { UserRoles } from '../../utilities/user-roles';
const DEFAULT_START_DATE = new Date('1/1/2000').toISOString();
const DEFAULT_END_DATE = new Date('12/31/2099').toISOString();
const FULL_DATE_FORMAT = 'YYYY-MM-DD';

@Component({
  selector: 'hms-ledger-adjustment-search',
  templateUrl: './ledger-adjustment-search.component.html',
  styleUrls: ['./ledger-adjustment-search.component.scss']
})
export class LedgerAdjustmentSearchComponent implements AfterViewInit, OnInit {

  selectedAdjustments$: Observable<LedgerAdjustmentDisplay[]>;

  adjustmentSearchForm: FormGroup = this.formBuilder.group({
    commodityProfile: [ '', [ CommonValidators.objectValidator ] ],
    prodYear: this.formBuilder.array([]),
    startDate: [ '', { updateOn: 'blur' } ],
    endDate: [ '', { updateOn: 'blur' } ],
    types: [ [] ]
  });

  filteredCommodityProfiles$: Observable<CommodityProfile[]>;
  errorMessage: string;
  prodYears: CommodityProfileProductionYear[] = [];
  ledgerAdjustmentTypes = Object.values(LedgerAdjustmentType);

  showAdjustments = false;
  isLoading = true;
  isSearching = false;
  clientSettings$: Observable<HMSClientSettings>;

  private commodityProfiles: CommodityProfile[];
  private selectedClientDocId: string;
  private queryParams: Params;
  private queryParamProdYears: string[];
  private previousCommodityDocId: string;
  private currentBusinessDay: moment.Moment;
  private ledgerEndOfDay: string;
  private timezone: string;
  private selectedTypes: LedgerAdjustmentType[] = [];

  @ViewChildren('typeBoxes') typeBoxes: QueryList<MatCheckbox>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private changeDetector: ChangeDetectorRef,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private formBuilder: FormBuilder,
    private ledgerAdjustmentService: LedgerAdjustmentService,
    private ledgerHelperService: LedgerHelperService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    if (!this.isLedgerAdjustmentAdmin && !this.authzService.currentUserHasRole(UserRoles.LEDGER_ADJUSTMENT_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to search ledger adjustments.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.clientSettings$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((client: Client) => {
        return this.clientSettingsService.getHmsSettingsByClientDocId(client.docId);
      }),
      tap((clientSettings: HMSClientSettings) => {
        this.ledgerEndOfDay = clientSettings.ledgerEndOfDay;
        this.timezone = clientSettings.timezone;
        this.currentBusinessDay = moment(this.ledgerHelperService.getCurrentBusinessDay(this.ledgerEndOfDay, this.timezone));
      })
    );
    this.prepForCommodityProfileSelection();
    this.setupCommodityProfileListener();
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
      this.queryParams = Object.assign({}, params);
      this.adjustmentSearchForm.get('startDate').setValue(this.queryParams.startDate);
      this.adjustmentSearchForm.get('endDate').setValue(this.queryParams.endDate);
      const commodityProfileValue = this.queryParams.commodityProfile ?
        { docId: this.queryParams.commodityProfile, name: '' } as CommodityProfile : undefined;
      this.adjustmentSearchForm.get('commodityProfile').setValue(commodityProfileValue);
      this.queryParamProdYears = this.queryParams.productionYears ? this.queryParams.productionYears.split(',') : [];
      if (this.queryParams.type) {
        this.selectedTypes = this.queryParams.type.split(',');
        this.adjustmentSearchForm.get('types').setValue(this.selectedTypes);
      }
      if (Object.keys(params).length) {
        // Mart form as dirty so reset button appears
        this.adjustmentSearchForm.markAsDirty();
        this.searchAdjustments();
      }
    }));

    this.isLoading = false;
  }

  ngAfterViewInit() {
    // setting here as [checked] property on template was not setting inner input type="checkbox" to checked
    this.typeBoxes.forEach(typeBox => typeBox.checked = this.selectedTypes.includes(typeBox.value as LedgerAdjustmentType));
    this.changeDetector.detectChanges();
  }

  get isLedgerAdjustmentAdmin() {
    return this.authzService.currentUserHasRole(UserRoles.LEDGER_ADJUSTMENT_ADMIN_ROLE);
  }

  addAdjustment() {
    this.router.navigate(['./new'], {relativeTo: this.activatedRoute});
  }

  handleAdjustmentListError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
  }


  onTypeChange(event) {
    if (event.checked) {
      this.selectedTypes.push(event.source.value);
    } else {
      this.selectedTypes = this.selectedTypes.filter(status => status !== event.source.value);
    }
    this.adjustmentSearchForm.get('types').setValue(this.selectedTypes);
    if (this.selectedTypes.length) {
      this.adjustmentSearchForm.get('types').markAsDirty();
    } else {
      this.adjustmentSearchForm.get('types').markAsPristine();
    }
  }

  reset() {
    this.adjustmentSearchForm.reset();
    this.clearQueryParams();
    this.queryParamProdYears = [];
    this.selectedTypes = [];
    this.typeBoxes.forEach(typeBox => typeBox.checked = false);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });
    this.adjustmentSearchForm.markAsPristine();
  }

  searchAdjustments() {
    this.showAdjustments = false;
    this.changeDetector.detectChanges();

    this.selectedAdjustments$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((selectedClient: Client) => {
        this.selectedClientDocId = selectedClient.docId;
        // load commodity profiles as there is a race condition on page reload with search parameters
        return this.commodityProfileService.getAllCommodityProfilesByClientDocId(selectedClient.docId);
      }),
      switchMap((profiles: CommodityProfile[]) => {
        this.commodityProfiles = profiles;
        return this.getAdjustments(this.selectedClientDocId);
      }),
      map((adjustments: LedgerAdjustment[]) => {
        // Handle edge case when there is no adjustment found
        if (adjustments.length === 0) {
          return [];
        }

        // get commodity profile name and production year label
        return adjustments.map(adjustment => {
          const commodityProfile = this.commodityProfiles.find(profile => profile.docId === adjustment.commodityProfileDocId);
          const label = this.ledgerHelperService.getProdYearLabelForLedgerAdjustment(
            adjustment, commodityProfile, this.ledgerEndOfDay, this.timezone);
          return { ...adjustment, commodityProfileName: commodityProfile.name, productionYearLabel: label } as LedgerAdjustmentDisplay;
        });
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.showAdjustments = true;
    this.changeDetector.detectChanges();
  }

  /**
   * Displays name for commodity profile autocomplete input field
   */
  displayCommodityProfile(profile?: CommodityProfile) {
    return profile ? profile.name : '';
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMax')) {
      return 'Value Invalid';
    }
    return 'Unknown Error';
  }

  clearField(fieldName: string) {
    this.adjustmentSearchForm.get(fieldName).setValue('');
  }

  yearSuffix(prodYear: CommodityProfileProductionYear) {
    const expired = this.ledgerHelperService.endOfBusinessDay(
      moment(prodYear.endDate, FULL_DATE_FORMAT), this.ledgerEndOfDay, this.timezone)
      .isBefore(this.currentBusinessDay);
    return expired ? ` (${prodYear.year})` : '';
  }

  private prepForCommodityProfileSelection() {
    let setInitialValue = false;
    this.filteredCommodityProfiles$ = this.adjustmentSearchForm.controls.commodityProfile.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | CommodityProfile>(''),
      switchMap(searchTerm => {
        return this.clientSelectorService.getSelectedClient().pipe(
          switchMap((selectedClient: Client) => {
            this.selectedClientDocId = selectedClient.docId;
            return this.commodityProfileService.getAllCommodityProfilesByClientDocId(selectedClient.docId).pipe(
              map((profiles: CommodityProfile[]) => {
                this.commodityProfiles = profiles;
                // populate searchTerm on initial load in case there is a mock object present
                if (!searchTerm) {
                  searchTerm = this.adjustmentSearchForm.get('commodityProfile').value;
                }
                // Return all commodity profiles when a profile has already been selected to avoid the user needing to clear the field
                if (typeof searchTerm !== 'string') {
                  // Replace mock commodityProfile from queryParam if necessary
                  if (searchTerm && !(searchTerm as CommodityProfile).name && !setInitialValue) {
                    this.adjustmentSearchForm.get('commodityProfile').setValue(
                      profiles.find(profile => profile.docId === (searchTerm as CommodityProfile).docId));
                    setInitialValue = true;
                  }
                  return profiles;
                }
                return profiles.filter(profile => profile.name.toLowerCase().includes((searchTerm as string).toLowerCase()));
              }),
              shareReplay({ bufferSize: 1, refCount: true }),
              catchError(err => {
                this.isLoading = false;
                this.errorMessage = 'Error retrieving client commodity profiles; please try again later';
                console.error(`Error retrieving client commodity profiles: ${err}`);
                return of([]);
              })
            );
          }),
        );
      }),
    );
  }

  private getAdjustments(clientDocId: string): Observable<LedgerAdjustment[]> {
    const commodityProfile: CommodityProfile = this.adjustmentSearchForm.get('commodityProfile').value;
    const productionYears: string[] = [];
    if (commodityProfile) {
      if (commodityProfile.productionYears) {
        (this.adjustmentSearchForm.get('prodYear').value as boolean[]).forEach((checked, i) => {
          if (checked) {
            productionYears.push(this.prodYears[i].year);
          }
        });
        this.queryParamProdYears = productionYears;
      } else {
        productionYears.push(...this.queryParamProdYears);
      }
    }
    let startDate = this.adjustmentSearchForm.get('startDate').value;
    let endDate = this.adjustmentSearchForm.get('endDate').value;
    const hasDate = startDate || endDate;
    const hasProdYear = productionYears.length > 0;

    this.clearQueryParams();

    // Set default date or the date entered by the user
    if (startDate) {
      startDate = new Date(startDate).toISOString();
      this.queryParams.startDate = startDate;
    } else {
      startDate = DEFAULT_START_DATE;
    }

    if (endDate) {
      endDate = new Date(endDate);
      // Allow contract made on this day before midnight
      endDate.setHours(23, 59, 59, 999);
      endDate = endDate.toISOString();
      this.queryParams.endDate = endDate;
    } else {
      endDate = DEFAULT_END_DATE;
    }

    if (commodityProfile) {
      this.queryParams.commodityProfile = commodityProfile.docId;
    }

    if (hasProdYear) {
      this.queryParams.productionYears = productionYears.join(',');
    }

    if (this.selectedTypes.length) {
      this.queryParams.type = this.selectedTypes.join(',');
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
    const commodityProfileDocIdParam = commodityProfile ? commodityProfile.docId : undefined;
    const startDateParam = hasDate ? startDate : undefined;
    const endDateParam = hasDate ? endDate : undefined;
    return this.ledgerAdjustmentService.getLedgerAdjustmentsBySearchParams(clientDocId, startDateParam, endDateParam, this.selectedTypes, commodityProfileDocIdParam, productionYears);
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }

  private setupCommodityProfileListener() {
    this.adjustmentSearchForm.get('commodityProfile').valueChanges.subscribe((profile: CommodityProfile) => {
      const prodYearArray = this.adjustmentSearchForm.get('prodYear') as FormArray;
      prodYearArray.clear();
      if (profile && profile.productionYears) {
        if (this.previousCommodityDocId && profile.docId !== this.previousCommodityDocId) {
          this.queryParamProdYears = [];
        }
        this.prodYears = Object.values(profile.productionYears);
        this.prodYears.forEach(prodYear => prodYearArray.push(new FormControl(this.queryParamProdYears.includes(prodYear.year))));
        this.previousCommodityDocId = profile.docId;
      } else {
        this.prodYears = [];
        this.queryParamProdYears = [];
      }
    });
  }
}
