import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// TODO Remove when available in library - temporarily in HMS
@Injectable({
  providedIn: 'root'
})
export class HistoryRecordService {
  constructor(
    @Inject('environment') private environment,
    private http: HttpClient
  ) {}

  /**
   * Return contract and pricing segment history documents for the specified Client and Contract
   * @param clientDocId The docId of the Client containing the Contract
   * @param contractDocId The docId of Contract's history to be returned
   * @param accessToken The auth0 accessToken
   */
  getContractHistoryByDocId(clientDocId: string, contractDocId: string, accessToken: string): Observable<any> {
    const endpoint = `${this.environment.services.httpHistoryRecords}/history/clients/${clientDocId}/contracts/${contractDocId}`;
    return this.http.get(endpoint, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${accessToken}`),
    });
  }
}
