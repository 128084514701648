  <h1 mat-dialog-title>Hedgeable Quantities</h1>
  <div mat-dialog-content>
  <p>The following production years still have hedgeable quantities remaining that will be lost when you deactivate this commodity profile.</p>
  <p>Would you like to create ledger adjustments to zero them out, ignore the remaining quantities, or cancel the update?</p>
  <mat-table [dataSource]="ledgersWithHedgeableQuantities" class="mat-elevation-z0">
    <ng-container matColumnDef="productionYear">
      <mat-header-cell *matHeaderCellDef fxFlex="50">Year</mat-header-cell>
      <mat-cell *matCellDef="let ledger" fxFlex="50">{{ ledger.productionYear }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="hedgeableQuantity">
      <mat-header-cell *matHeaderCellDef fxFlex="50">Quantity</mat-header-cell>
      <mat-cell *matCellDef="let ledger" fxFlex="50">{{ ledger.hedgeableQuantity }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row *matRowDef="let ledger; columns: columnsToDisplay"></mat-row>
  </mat-table>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center" fxLayoutGap="25px">
    <button mat-raised-button id="hedgeable-quantity-zero-out" aria-label="Zero Out" class="primary" [mat-dialog-close]="create" cdkFocusInitial>Zero Out</button>
    <button mat-stroked-button id="hedgeable-quantity-ignore" aria-label="Ignore" class="secondary" [mat-dialog-close]="ignore">Ignore</button>
    <button mat-stroked-button id="hedgeable-quantity-cancel" aria-label="Cancel" class="secondary" [mat-dialog-close]="cancel">Cancel</button>
  </div>
