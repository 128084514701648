export interface HistoryEvent {
  eventType: EventType;
  details: string;
  lastUpdatedBy: string;
  timestamp: number;
  identifier?: string;
  index: number;
}

export enum EventType {
  // Contract Event Types
  CONTRACT_IMPORTED = 'Contract Imported',
  CONTRACT_CREATED = 'Contract Created',
  CONTRACT_PENDING_APPROVAL = 'Contract Pending Approval',
  CONTRACT_APPROVED = 'Contract Approved',
  CONTRACT_DENIED = 'Contract Denied',
  CONTRACT_CANCELLED = 'Contract Cancelled',
  CONTRACT_COMPLETE = 'Contract Complete',
  CONTRACT_TARGET_PRICE_LOCKED = 'Contract Target Price Locked',
  CONTRACT_ID_ASSIGNED = 'Contract ID Assigned',
  CONTRACT_ORDER_CREATED = 'Contract Order Created',
  CONTRACT_ORDER_CANCELLED = 'Contract Order Cancelled',
  CONTRACT_ORDER_UPDATED = 'Contract Order Updated',
  CONTRACT_ORDER_REPLACED = 'Contract Order Replaced',
  CONTRACT_EXPIRED = 'Contract Expired',
  CONTRACT_MANUALLY_PRICE_LOCKED = 'Contract Manually Price Locked',
  CONTRACT_DELETED = 'Contract Deleted',
  CONTRACT_PRICED_AT_THE_MARKET = 'Contract Priced at the Market',
  BATCH_DELIVERY_PERIOD_ROLL = 'Batch Delivery Period Roll',
  BATCH_FUTURES_MONTH_ROLL = 'Batch Futures Month Roll',
  BASIS_UPDATED_BY_SYSTEM = 'Basis Updated By System',
  PRICE_ADJUSTMENT_ADDED = 'Price Adjustment Added',
  PRICE_ADJUSTMENT_UPDATED = 'Price Adjustment Updated',
  PRICE_ADJUSTMENT_REMOVED = 'Price Adjustment Removed',
  CONTRACT_FIELD_UPDATED = 'Contract Field Updated',
  // Pricing Segment Event Types
  PRICING_IMPORTED = 'Pricing Imported',
  PRICING_CREATED = 'Pricing Created',
  PRICING_ORDER_CREATED = 'Pricing Order Created',
  PRICING_ORDER_UPDATED = 'Pricing Order Updated',
  PRICING_ORDER_REPLACED = 'Pricing Order Replaced',
  PRICING_ORDER_CANCELLED = 'Pricing Order Cancelled',
  PRICING_TARGET_PRICE_LOCKED = 'Pricing Target Price Locked',
  PRICING_CANCELLED = 'Pricing Cancelled',
  PRICING_DELETED = 'Pricing Deleted',
  PRICING_PRICED = 'Pricing Priced',
  PRICING_MANUALLY_PRICE_LOCKED = 'Pricing Manually Price Locked',
  PRICING_PRICED_AT_THE_MARKET = 'Pricing Priced at the Market',
  PRICING_ID_ASSIGNED = 'Pricing ID Assigned',
  PRICING_FIELD_UPDATED = 'Pricing Field Updated',
  // Special Handling Event Types (Contract and Pricing Segment)
  EXCHANGE_CREATED = 'Exchange Created',
  EXCHANGE_CONTRACT_CREATED = 'Exchange Contract Created',
  EXCHANGE_CONTRACT_CANCELLED = 'Exchange Contract Cancelled',
  EXCHANGE_COMPLETE = 'Exchange Complete',
  EXCHANGE_CONTRACT_COMPLETE = 'Exchange Contract Complete',
  EXCHANGE_PRICING_CREATED = 'Exchange Pricing Created',
  EXCHANGE_PRICING_CANCELLED = 'Exchange Pricing Cancelled',
  EXCHANGE_PRICING_COMPLETE = 'Exchange Pricing Complete',
  OTC_CONTRACT_CREATED = 'OTC Contract Created',
  OTC_PRICING_CREATED = 'OTC Pricing Created',
  EXERCISED_OPTION_CONTRACT_CREATED = 'Exercised Option Contract Created',
  EXERCISED_OPTION_PRICING_CREATED = 'Exercised Option Pricing Created',
  // Second Part Pricing Created (DP - Target Pricing)
  SECOND_PART_PRICING_CREATED = 'Second Part Pricing Created'
}
