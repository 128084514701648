import { FormGroup, ValidatorFn } from '@angular/forms';

import * as moment from 'moment';

export class CommodityProfileValidators {

  static productionYearsValidator(futuresMonths: {[key: string]: number[]}): ValidatorFn {
    return (formGroup: FormGroup): {[key: string]: boolean} | null => {
      const prodYears = formGroup.get('prodYears').value;
      const commodity = formGroup.get('commodity').value;
      const errors = {};

      if (commodity) {
        const commodityId = commodity.id;
        Object.keys(prodYears).forEach(year => {
          // Validate against gap between production years
          const prevYear = (parseInt(year, 10) - 1).toString();
          if (prodYears[prevYear] && moment(prodYears[prevYear]['endDate']).diff(moment(prodYears[year]['startDate']), 'days') !== -1) {
            errors['startDateGap'] = true;
            return errors;
          }

          // Validate against each production year's futures month
          const targetFuturesYearMonth = moment(prodYears[year]['targetFuturesYearMonth']).month();
          const hedgeBuyFuturesYearMonth = moment(prodYears[year]['hedgeBuyFuturesYearMonth']).month();
          const hedgeSellFuturesYearMonth = moment(prodYears[year]['hedgeSellFuturesYearMonth']).month();

          if (!futuresMonths[commodityId].includes(targetFuturesYearMonth)
              || !futuresMonths[commodityId].includes(hedgeBuyFuturesYearMonth)
              || !futuresMonths[commodityId].includes(hedgeSellFuturesYearMonth)) {
              errors['invalidFuturesMonth'] = true;
              return errors;
          }
        });
      }
      return Object.keys(errors).length === 0 ? null : errors;
    };
  }
}
