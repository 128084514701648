<div *ngIf="!errorMessage" fxLayout="column">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="adjustments-filter-field" #filter>
    <button type="button" id="clear-ledger-adjustments-filter" mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <button *ngIf="selectedAdjustments$ | async" aria-label="Export" id="export-ledger-adjustments" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
  <mat-menu #exportMenu="matMenu">
    <button type="button" aria-label="Export Excel" id="export-ledger-adjustments-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'LedgerAdjustments'))">Excel</button>
    <button type="button" aria-label="Export CSV" id="export-ledger-adjustments-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'LedgerAdjustments'))">CSV</button>
    <button type="button" aria-label="Export JSON" id="export-ledger-adjustments-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'LedgerAdjustments'))">JSON</button>
  </mat-menu>

  <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort class="mat-elevation-z0">

    <ng-container matColumnDef="creationTimestamp">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.gt-xs="25">Created</mat-header-cell>
      <mat-cell *matCellDef="let adjustment"  fxFlex.xs="25" fxFlex.gt-xs="25">{{ adjustment.creationTimestamp | date: 'M/d/yyyy h:mm:ss a' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="20" fxFlex.gt-xs="20">Type</mat-header-cell>
      <mat-cell *matCellDef="let adjustment" fxFlex.xs="20" fxFlex.gt-xs="20">{{ adjustment.type | replace | titlecase }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="commodityProfileName">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.gt-xs="20">Profile</mat-header-cell>
      <mat-cell *matCellDef="let adjustment"  fxFlex.xs="25" fxFlex.gt-xs="20">{{ adjustment.commodityProfileName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="productionYearLabel">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="20">Production Year</mat-header-cell>
      <mat-cell *matCellDef="let adjustment" fxFlex.gt-xs="20">{{ adjustment.productionYearLabel | productionYear }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30"  fxFlex.gt-xs="15">Quantity</mat-header-cell>
      <mat-cell *matCellDef="let adjustment" fxFlex.xs="30" fxFlex.gt-xs="15">{{ adjustment.quantity | number }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row (click)="selectAdjustment(adjustment)" *matRowDef="let adjustment; columns: columnsToDisplay"></mat-row>
  </mat-table>
  <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50]"></mat-paginator>
</div>
