<div fxLayout="row" fxLayoutAlign="space-around start">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <hms-error-message [title]="'Error Retrieving Expiring Contracts Report'" [message]="errorMessage"></hms-error-message>

    <div *ngIf="!errorMessage" fxLayout="column" fxLayoutAlign="space-between start">
      <h2 data-cy="page-title-expiring-contracts-report" class="card-title">Expiring Contracts Report</h2>
      <form [formGroup]="expiringContractsSearchForm" (ngSubmit)="loadExpiringContractsReportData()" fxLayout="column" class="full-width">
        <div fxLayoutGap.gt-xs="25px" fxLayout.xs="column">
          <mat-form-field fxFlex.gt-xs="250px">
            <input matInput [matDatepicker]="endDatePicker" placeholder="End Date" formControlName="endDate" [min]="minEndDate">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
            <mat-error *ngIf="expiringContractsSearchForm.get('endDate').invalid">{{ getErrorMessage(expiringContractsSearchForm.get('endDate')) }}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="!isLoading" fxLayout="row" fxLayoutGap="10px">
          <button id="generate-expiring-contracts-report" [disabled]="expiringContractsSearchForm.invalid" aria-label="Generate Report" type="submit" mat-raised-button color="accent">Generate Report</button>
          <button type="button" id="clear-expiring-contracts-report" *ngIf="!expiringContractsSearchForm.pristine" aria-label="Discard Report Options" (click)="reset()" mat-stroked-button color="accent">Reset</button>
        </div>
        <div *ngIf="isLoading" fxLayout="row" fxLayoutGap="10px">
          <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
        </div>
      </form>
    </div>

    <ng-container *ngIf="expiringContracts$ | async">
      <div *ngIf="!errorMessage && displayReports" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10" class="full-width">
        <ng-container *ngFor="let commodity of commodityProfileMap | keyvalue: accountNumberAndProfileAscOrder">
          <h4>
            {{ commodity.value.name }}
            <button *ngIf="dataSourceMap[commodity.key].data.length" matTooltip="Export" aria-label="Export" id="export-expiring-contracts-report" mat-icon-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button"><mat-icon>save_alt</mat-icon></button>
          </h4>
          <mat-menu #exportMenu="matMenu">
            <button type="button" aria-label="Export Excel" id="export-expiring-contracts-report-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', getExportName(commodity.key)))">Excel</button>
            <button type="button" aria-label="Export CSV" id="export-expiring-contracts-report-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', getExportName(commodity.key)))">CSV</button>
            <button type="button" aria-label="Export JSON" id="export-expiring-contracts-report-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', getExportName(commodity.key)))">JSON</button>
          </mat-menu>

          <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSourceMap[commodity.key]" class="mat-elevation-z0 full-width">

            <ng-container matColumnDef="accountingSystemId">
              <mat-header-cell *matHeaderCellDef fxFlex.gt-md="10">ID</mat-header-cell>
              <mat-cell *matCellDef="let contract" fxFlex.gt-md="10">{{ contract.accountingSystemId }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="expirationDate">
              <mat-header-cell *matHeaderCellDef fxFlex.gt-md="12" fxFlex.gt-xs="13" fxFlex.xs="30">Expiration</mat-header-cell>
              <mat-cell *matCellDef="let contract" fxFlex.gt-md="12" fxFlex.gt-xs="13" fxFlex.xs="30">{{ contract.expirationDate | date: 'M/d/yyyy' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="deliveryPeriod">
              <mat-header-cell *matHeaderCellDef fxFlex.gt-md="6" fxFlex.gt-xs="9">Delivery</mat-header-cell>
              <mat-cell *matCellDef="let contract" fxFlex.gt-md="6" fxFlex.gt-xs="9">{{ contract.deliveryPeriod }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="futuresYearMonth">
              <mat-header-cell *matHeaderCellDef fxFlex.gt-md="6" fxFlex.gt-xs="10">Contract</mat-header-cell>
              <mat-cell *matCellDef="let contract" fxFlex.gt-md="6" fxFlex.gt-xs="10">{{ contract.futuresYearMonth }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="originatorName">
              <mat-header-cell *matHeaderCellDef fxFlex.gt-md="12">Originator</mat-header-cell>
              <mat-cell *matCellDef="let contract" fxFlex.gt-md="12">{{ contract.originatorName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef fxFlex.gt-md="12" fxFlex.gt-xs="10">Status</mat-header-cell>
              <mat-cell *matCellDef="let contract" fxFlex.gt-md="12" fxFlex.gt-xs="10">{{ contract.status | replace | titlecase }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="patronName">
              <mat-header-cell *matHeaderCellDef fxFlex.gt-md="10" fxFlex.gt-xs="10" fxFlex.xs="40">Patron</mat-header-cell>
              <mat-cell *matCellDef="let contract" fxFlex.gt-md="10" fxFlex.gt-xs="10" fxFlex.xs="40">{{ contract.patronName }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef fxFlex.gt-md="4" fxFlex.gt-xs="8">Type</mat-header-cell>
              <mat-cell *matCellDef="let contract" fxFlex.gt-md="4" fxFlex.gt-xs="8">{{ contract.type === 'HTA' || contract.type === 'DP' ? contract.type : contract.type | titlecase }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="quantity">
              <mat-header-cell *matHeaderCellDef fxFlex.gt-md="10" fxFlex.gt-xs="13" fxFlex.xs="30">Qty</mat-header-cell>
              <mat-cell *matCellDef="let contract" fxFlex.gt-md="10" fxFlex.gt-xs="13" fxFlex.xs="30">{{ contract.quantity | number:'1.0-2' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="basisPrice">
              <mat-header-cell *matHeaderCellDef fxFlex.gt-md="6" fxFlex.gt-xs="9">Basis</mat-header-cell>
              <mat-cell *matCellDef="let contract" fxFlex.gt-md="6" fxFlex.gt-xs="9">{{ contract.basisPrice | number:'1.4-4' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="futuresPrice">
              <mat-header-cell *matHeaderCellDef fxFlex.gt-md="6" fxFlex.gt-xs="9">Futures</mat-header-cell>
              <mat-cell *matCellDef="let contract" fxFlex.gt-md="6" fxFlex.gt-xs="9">{{ contract.futuresPrice | number:'1.4-4' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="cashPrice">
              <mat-header-cell *matHeaderCellDef fxFlex.gt-md="6" fxFlex.gt-xs="9">Cash</mat-header-cell>
              <mat-cell *matCellDef="let contract" fxFlex.gt-md="6" fxFlex.gt-xs="9">{{ contract.cashPrice | number:'1.4-4' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="footer">
              <mat-footer-cell *matFooterCellDef fxFlex="100">No Contracts Expiring</mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row (click)="selectContract(contract)" *matRowDef="let contract; columns: columnsToDisplay"></mat-row>
            <ng-container *ngIf="!dataSourceMap[commodity.key].data.length">
              <mat-footer-row *matFooterRowDef="footerColumnsToDisplay"></mat-footer-row>
            </ng-container>
          </mat-table>
        </ng-container>
      </div>
    </ng-container>
  </mat-card>
</div>
