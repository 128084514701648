import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor() {}

  getExportOptions(type: string, reportName: string, sheetName?: string) {
    const currentDateTime = moment().format('YYYY-MM-DD hh:mm:ss A');

    switch (type) {
      case 'xlsx':
        return {
          fileName: `HMS-${reportName}-${currentDateTime}`,
          sheet: sheetName || reportName,
          Props: {
            Author: 'Advance Trading, Inc.'
          }
        };
      default:
        return {
          fileName: `HMS-${reportName}-${currentDateTime}`
        };
    }
  }

  /**
   * Export xlsx from a given data, report name, and optional skip header flag.
   * This is useful when we want to export a table that is not well parsed by mat-table-exporter.
   *
   * @param exportableItems The well formatted data that is ready to be exported. This has to be a map
   * that has sheet name as key and an array of the same Object as the value. The exported's column result will
   * be ordered similarly to the order the Object's properties is specified.
   * @param reportName The report name used for the exported file name.
   * @param skipHeader The flag to specify if we want to use header from the object properties or not.
   */
  exportXlsx(exportableItems: {[sheetName: string]: any[]}, reportName: string, skipHeader = false) {
    // TODO: use dynamic import to lazy load the XLSX module after typescript is updated to version >= 3.8
    // to improve initial application load time
    const currentDateTime = moment().format('YYYY-MM-DD hh:mm:ss A');
    const sheetNames = Object.keys(exportableItems);
    const sheets: {[sheet: string]: XLSX.WorkSheet} = {};

    sheetNames.forEach((sheetName: string) => {
      sheets[sheetName] = XLSX.utils.json_to_sheet(exportableItems[sheetName], { skipHeader });
    });

    const workbook: XLSX.WorkBook = {
      Sheets: sheets,
      SheetNames: sheetNames,
      Props: {
        Author: 'Advance Trading, Inc.'
      }
    };

    XLSX.writeFile(workbook, `HMS-${reportName}-${currentDateTime}.xlsx`);
  }
}
