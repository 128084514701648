<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <hms-error-message [title]="'Error Retrieving Batch Roll'" [message]="errorMessage"></hms-error-message>

    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 data-cy="page-title-batch-roll" class="card-title">Batch Roll</h2>
    </div>

    <form #form="ngForm" *ngIf="!errorMessage" [formGroup]="batchRollForm" (ngSubmit)="batchUpdate()" fxLayout="column" fxLayoutGap="15px">
      <!-- Commodity Profile -->
      <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
        <mat-form-field fxFlex.gt-xs="30">
          <mat-select required placeholder="Commodity Profile" formControlName="commodityProfile" [compareWith]="compareCommodityProfile">
            <mat-option *ngFor="let profile of commodityProfiles$ | async" [value]="profile">{{ profile.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="batchRollForm.get('commodityProfile').invalid">{{ getErrorMessage(batchRollForm.get('commodityProfile')) }}</mat-error>
        </mat-form-field>
      </div>

      <!-- Roll Type -->
      <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
        <div>
          <mat-button-toggle-group formControlName="rollType" aria-label="Roll Type" matTooltip="Roll Type">
            <mat-button-toggle *ngFor="let type of rollTypes" [value]="type">{{ type | titlecase }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <!-- From & To (Delivery Month / Futures Month) Period -->
      <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
        <mat-form-field fxFlex.gt-xs="30" hmsMonthPicker>
          <input required matInput [matDatepicker]="fromMonthPicker" [matDatepickerFilter]="validMonthFilter" [max]="maxFromMonth" placeholder="From" formControlName="fromMonth" (keydown)="allowTabOnly($event)">
          <mat-datepicker-toggle matSuffix [for]="fromMonthPicker"></mat-datepicker-toggle>
          <mat-datepicker #fromMonthPicker startView="year" (monthSelected)="selectFromMonth($event)" panelClass="month-picker"></mat-datepicker>
          <mat-error *ngIf="batchRollForm.get('fromMonth').invalid">{{ getErrorMessage(batchRollForm.get('fromMonth')) }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex.gt-xs="30" hmsMonthPicker>
          <input required matInput [matDatepicker]="toMonthPicker" [matDatepickerFilter]="validMonthFilter" [min]="minToMonth" placeholder="To" formControlName="toMonth" (keydown)="allowTabOnly($event)">
          <mat-datepicker-toggle matSuffix [for]="toMonthPicker"></mat-datepicker-toggle>
          <mat-datepicker #toMonthPicker startView="year" (monthSelected)="selectToMonth($event)" panelClass="month-picker"></mat-datepicker>
          <mat-error *ngIf="batchRollForm.get('toMonth').invalid">{{ getErrorMessage(batchRollForm.get('toMonth')) }}</mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="updateComplete && !isLoading" fxLayout="row" fxLayoutGap="10px">
        <button id="submit-batch-roll" [disabled]="batchRollForm.invalid" aria-label="Roll" type="submit" mat-raised-button color="accent">Roll</button>
        <button type="button" id="clear-batch-roll" *ngIf="!batchRollForm.pristine" aria-label="Reset" (click)="reset()" mat-stroked-button color="accent">Reset</button>
      </div>
      <div *ngIf="!updateComplete || isLoading" fxLayout="row" fxLayoutGap="10px">
        <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
      </div>
    </form>
  </mat-card>
</div>
