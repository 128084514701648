import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';

import firebase from 'firebase/app';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { AuthService } from '@advance-trading/angular-ati-security';
import { Client, LedgerAdjustment } from '@advance-trading/ops-data-lib';

@Injectable({
  providedIn: 'root'
})
export class LedgerAdjustmentService {

  constructor(
    private db: AngularFirestore,
    private authService: AuthService
  ) { }

  /**
   * Return ledger adjustment for the specified Client by docId
   *
   * @param clientDocId The docId of the Client containing the LedgerAdjustment
   * @param adjustmentDocId The docId of LedgerAdjustment to be returned
   */
  getLedgerAdjustmentByDocId(clientDocId: string, adjustmentDocId: string): Observable<LedgerAdjustment> {
    return this.db.doc<LedgerAdjustment>(`${Client.getDataPath(clientDocId)}/${LedgerAdjustment.getDataPath(adjustmentDocId)}`)
      .valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Return ledger adjustments for the specified Client, date range, type, commodity profile, and production years
   * @param clientDocId The docId of the Client containing the LedgerAdjustments
   * @param startDate The date before or when the adjustments were made. Pass `undefined` if not being used
   * @param endDate The date after or when the adjustments were made. Pass `undefined if not being used
   * @param types a string array of LedgerAdjustmentTypes of the LedgerAdjustments to be returned. Pass empty array if not being used
   * @param commodityProfileDocId The commodity profile doc id in LedgerAdjustments being returned. Pass `undefined` if not being used
   * @param productionYears A string array of the production year(s) formatted YYYY in LedgerAdjustments being returned. Pass empty array if not being used
   */
  getLedgerAdjustmentsBySearchParams(clientDocId: string, startDate: string, endDate: string, types: string[], commodityProfileDocId: string, productionYears: string[]): Observable<LedgerAdjustment[]> {
    if (types.length) {
      return combineLatest(types.map(type => this.getLedgerAdjustmentsBySearchParamsForSingleType(clientDocId, startDate, endDate, type, commodityProfileDocId, productionYears))).pipe(
        // force potential array of contract arrays into single array then ensure sorting is applied across entire set
        map(arrayOfLedgerAdjustmentArrays => (arrayOfLedgerAdjustmentArrays as LedgerAdjustment[][]).flat()
          .sort((a, b) => a.creationTimestamp < b.creationTimestamp ? 1 : -1)),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    } else {
      return this.getLedgerAdjustmentsBySearchParamsForSingleType(clientDocId, startDate, endDate, undefined, commodityProfileDocId, productionYears).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }
  }

  /**
   * Return ledger adjustments for the specified Client, date range, type, commodity profile, and production years
   * @param clientDocId The docId of the Client containing the LedgerAdjustments
   * @param startDate The date before or when the adjustments were made. Pass `undefined` if not being used
   * @param endDate The date after or when the adjustments were made. Pass `undefined if not being used
   * @param type LedgerAdjustmentType of the LedgerAdjustments to be returned. Pass `undefined` if not being used
   * @param commodityProfileDocId The commodity profile doc id in LedgerAdjustments being returned. Pass `undefined` if not being used
   * @param productionYears A string array of the production year(s) formatted YYYY in LedgerAdjustments being returned. Pass empty array if not being used
   */
  private getLedgerAdjustmentsBySearchParamsForSingleType(clientDocId: string, startDate: string, endDate: string, type: string, commodityProfileDocId: string, productionYears: string[]): Observable<LedgerAdjustment[]> {
    return this.db.collection<LedgerAdjustment>(`${Client.getDataPath(clientDocId)}/${LedgerAdjustment.getDataPath()}`,
      ref => {
        let finalRef: Query<firebase.firestore.DocumentData>;
        if (startDate) {
          finalRef = (finalRef || ref).where('creationTimestamp', '>=', startDate);
        }
        if (endDate) {
          finalRef = (finalRef || ref).where('creationTimestamp', '<=', endDate);
        }
        if (type) {
          finalRef = (finalRef || ref).where('type', '==', type);
        }
        if (commodityProfileDocId) {
          finalRef = (finalRef || ref).where('commodityProfileDocId', '==', commodityProfileDocId);
        }
        if (productionYears.length) {
          finalRef = (finalRef || ref).where('productionYear', 'in', productionYears);
        }
        return finalRef||ref;
      }
    ).valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Return ledger adjustments for the specified Client and date range
   * @param clientDocId The docId of the Client containing the LedgerAdjustments
   * @param startDate The date before or when the adjustments were made
   * @param endDate The date after or when the adjustments were made
   */
  getLedgerAdjustmentsByDate(clientDocId: string, startDate: string, endDate: string): Observable<LedgerAdjustment[]> {
    return this.getLedgerAdjustmentsBySearchParams(clientDocId, startDate, endDate, [], undefined, []);
  }

  /**
   * Return ledger adjustments for the specified Client, types, and date range
   * @param clientDocId The docId of the Client containing the LedgerAdjustments
   * @param types An array of ledgerAdjustment types to be returned
   * @param startDate The date before or when the adjustments were made
   * @param endDate The date after or when the adjustments were made
   */
  getLedgerAdjustmentsByTypeAndDate(clientDocId: string, types: string[], startDate: string, endDate: string):
    Observable<LedgerAdjustment[]> {
    return this.getLedgerAdjustmentsBySearchParams(clientDocId, startDate, endDate, types, undefined, []);
  }

  /**
   * Return ledger adjustments for the specified Client and date range and commodity profile
   * @param clientDocId The docId of the Client containing the LedgerAdjustments
   * @param startDate The date before or when the adjustments were made
   * @param endDate The date after or when the adjustments were made
   * @param commodityProfileDocId The commodity profile doc id in LedgerAdjustments being returned
   */
  getLedgerAdjustmentsByDateAndCommodityProfileDocId(
    clientDocId: string, startDate: string, endDate: string, commodityProfileDocId: string): Observable<LedgerAdjustment[]> {

    return this.getLedgerAdjustmentsBySearchParams(clientDocId, startDate, endDate, [], commodityProfileDocId, []);
  }

  /**
   * Create a LedgerAdjustment document for the specified Client
   *
   * @param clientDocId clientDocId The docId of the Client
   * @param adjustment The LedgerAdjustment object to add
   */
  createLedgerAdjustment(clientDocId: string, adjustment: LedgerAdjustment): Promise<void> {
    adjustment.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.doc<LedgerAdjustment>
      (`${Client.getDataPath(clientDocId)}/${LedgerAdjustment.getDataPath(adjustment.docId)}`)
      .set(adjustment.getPlainObject());
  }

  /**
   * Update a LedgerAdjustment document for the specified Client
   *
   * @param clientDocId The docId of the Client the adjustment to update belongs to
   * @param adjustment The LedgerAdjustment object to update
   */

  updateLedgerAdjustment(clientDocId: string, adjustment: LedgerAdjustment): Promise<void> {
    adjustment.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    if (!adjustment.comments) {
      // @ts-ignore
      adjustment.comments = firebase.firestore.FieldValue.delete();
    }
    return this.db.doc(`${Client.getDataPath(clientDocId)}/${LedgerAdjustment.getDataPath(adjustment.docId)}`).update(adjustment);
  }

}
