import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'productionYear'})
export class ProductionYearPipe implements PipeTransform {
  /**
   * This converts the production year enum value into a more familiar and readable value
   * @param value the ProductionYear to be transformed
   */
  transform(value: string): string {
    return value ? `${value.charAt(0)}${value.substr(1).toLowerCase().replace('_plus_', '+')}` : '';
  }
}
