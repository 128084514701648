<div fxLayout="column">
  <h4>Positions Log
    <button matTooltip="Export" aria-label="Export" id="export-positions-log" mat-icon-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button"><mat-icon>save_alt</mat-icon></button>
  </h4>
  <mat-menu #exportMenu="matMenu">
    <button type="button" aria-label="Export Excel" id="export-positions-log-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Positions Log'))">Excel</button>
    <button type="button" aria-label="Export CSV" id="export-positions-log-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Positions Log'))">CSV</button>
    <button type="button" aria-label="Export JSON" id="export-positions-log-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Positions Log'))">JSON</button>
  </mat-menu>

  <mat-table matTableExporter #exporter="matTableExporter" [hiddenColumns]="[0, 5]" [dataSource]="dataSource" class="mat-elevation-z0">
    <ng-container matColumnDef="position">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('position')">#</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog"  [style.flex]="getFlexLayout('position')">{{ positionsLog.position }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="timestamp">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('timestamp')">Time</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog" [style.flex]="getFlexLayout('timestamp')">{{ positionsLog.timestamp | date: 'shortTime' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="positionsType">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('positionsType')">Type</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog" [style.flex]="getFlexLayout('positionsType')">
        <mat-icon [ngClass]="getTypeNgClass(positionsLog)" [svgIcon]="getIconName(positionsLog.positionsType)" *ngIf="positionsLog.positionsType !== ROLLOVER_TOTAL"></mat-icon>
        <span *ngIf="positionsLog.positionsType !== ROLLOVER_TOTAL" class="cdk-visually-hidden">{{ positionsLog.positionsType === 'HTA' || positionsLog.positionsType === 'DP' ? positionsLog.positionsType : positionsLog.positionsType | titlecase }}</span>
        <div *ngIf="positionsLog.positionsType === ROLLOVER_TOTAL">{{ positionsLog.positionsType }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="futuresYearMonth">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('futuresYearMonth')">Contract</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog" [style.flex]="getFlexLayout('futuresYearMonth')">{{ positionsLog.futuresYearMonth | codeToMonth }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="side">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('side')">Side</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog" [style.flex]="getFlexLayout('side')">{{ positionsLog.side | titlecase }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="displayQuantity">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('displayQuantity')">Qty</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog" [style.flex]="getFlexLayout('displayQuantity')">
        <div  fxLayout="column" fxFlex="100">
          <div fxFlex="100">{{ positionsLog.quantity | number:'1.0-0' }}</div>
          <div fxFlex="100" *ngIf="positionsLog.originalQuantity" class="deleted">{{ positionsLog.originalQuantity | number:'1.0-0' }}</div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Quantity</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog" class="cdk-visually-hidden">{{ positionsLog.quantity | number:'1.0-0' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="originalQuantity">
      <mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Original Quantity</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog" class="cdk-visually-hidden">{{ positionsLog.originalQuantity | number:'1.0-0' }}</mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="label" *ngFor="let label of profileProdYearLabels">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout(label)">{{ label | productionYear }}</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog" [style.flex]="getFlexLayout(label)">{{ positionsLog.prodYears[label] | number:'1.0-0' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="basisPrice">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('basisPrice')">Basis</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog" [style.flex]="getFlexLayout('basisPrice')">{{ positionsLog.basisPrice | number:'1.4-4' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="futuresPrice">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('futuresPrice')">Futures</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog" [style.flex]="getFlexLayout('futuresPrice')">
        <div *ngIf="!positionsLog.isSplitFilled">{{ positionsLog.futuresPrice | number:'1.4-5' }}</div>
        <div *ngIf="positionsLog.isSplitFilled">{{ positionsLog.futuresPrice | number:'1.4-5' }}*</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cashPrice">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('cashPrice')">Cash</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog" [style.flex]="getFlexLayout('cashPrice')">{{ positionsLog.cashPrice | number:'1.4-5' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="originatorName">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('originatorName')">Originator</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog" [style.flex]="getFlexLayout('originatorName')">{{ positionsLog.originatorName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="locationName">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('locationName')">Client<br />Location</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog" [style.flex]="getFlexLayout('locationName')">{{ positionsLog.locationName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="patronDisplayName">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('patronDisplayName')">Patron</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog" [style.flex]="getFlexLayout('patronDisplayName')" >
        <div *ngIf="positionsLog.patronAccountingSystemId; else noPatronAccountingSystemId">
          <div class="truncate-overflow-2">
            {{ positionsLog.patronName }}
          </div>
          <div>
            ({{ positionsLog.patronAccountingSystemId }})
          </div>
        </div>
        <ng-template #noPatronAccountingSystemId>
          <div class="truncate-overflow-3">
            {{ positionsLog.patronName }}
          </div>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="comments">
      <ng-container *ngIf="displayNotesText; then commentsText; else commentsIcon"></ng-container>
      <!-- comment icon template -->
      <ng-template #commentsIcon>
        <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('comments')">Comments</mat-header-cell>
        <mat-cell *matCellDef="let positionsLog; let i = index" [style.flex]="getFlexLayout('comments')">
          <button *ngIf="positionsLog.comments" id="open-activity-log-comment" mat-button matSuffix mat-icon-button
            aria-label="Comments" (mouseenter)="commentTooltip.show()" (mouseleave)="commentTooltip.hide()"
            (click)="commentTooltip.show(); $event.stopPropagation();" #commentTooltip="matTooltip"
            [matTooltip]="positionsLog.comments" matTooltipClass="comment-tooltip">
            <mat-icon svgIcon="comment"></mat-icon>
            <span class="cdk-visually-hidden">{{ positionsLog.comments }}</span>
          </button>
        </mat-cell>
      </ng-template>
      <!-- comment text template -->
      <ng-template #commentsText>
        <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('comments')">Comments</mat-header-cell>
        <mat-cell *matCellDef="let positionsLog; let i = index" [style.flex]="getFlexLayout('comments')">
          <p class="comments-text-inline">{{ positionsLog.comments }}</p>
        </mat-cell>
      </ng-template>
    </ng-container>

    <ng-container matColumnDef="deleted">
      <mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Deleted</mat-header-cell>
      <mat-cell *matCellDef="let positionsLog" class="cdk-visually-hidden">{{ positionsLog.deletionTimestamp ? 'Y' : '' }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row (click)="selectPositionsLog(positionsLog)" *matRowDef="let positionsLog; columns: columnsToDisplay" [ngClass]="{ 'deleted': positionsLog.deletionTimestamp || positionsLog.cancellationTimestamp }"></mat-row>
  </mat-table>
  <mat-paginator color="primary" [pageSizeOptions]="[50, 100, 500]"></mat-paginator>
</div>
