import { Injectable } from '@angular/core';

import { AuthService } from '@advance-trading/angular-ati-security';
import { StorageService } from '@advance-trading/angular-common-services';
import { ClientService } from '@advance-trading/angular-ops-data';
import { Client, HMSUserSettings } from '@advance-trading/ops-data-lib';

import { combineLatest, from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, shareReplay, switchMap, take } from 'rxjs/operators';

import { UserSettingsService } from './user-settings.service';

const SELECTED_CLIENT_KEY = 'hms.selectedClientDocId';

@Injectable({
  providedIn: 'root'
})
export class ClientSelectorService {

  private clientSubject$ = new ReplaySubject<Client>(1);
  private client$ = this.clientSubject$.asObservable();

  constructor(
    private authService: AuthService,
    private clientService: ClientService,
    private storageService: StorageService,
    private userSettingsService: UserSettingsService
  ) {
    // Restore selected from sessionStorage
    this.updateSelectedClient(this.storageService.sessionStorage.get(SELECTED_CLIENT_KEY).value() || '');
  }

  /**
   * Get an Observable<Client> to receive updates on the currently selected Client
   */
  getSelectedClient(): Observable<Client> {
    return this.client$;
  }

  /**
   * Update the currently selected Client; typically handled by the HmsComponent selector
   * @param clientDocId The docId for the Client being selected
   */
  updateSelectedClient(clientDocId: string) {
    // I believe this is memory leak safe because the Observable is eligible for garbage collection once function completes
    this.getAuthorizedClients().pipe(
      take(1),
      switchMap(authorizedClients => {
        const matchedClients = authorizedClients.filter(client => client.docId === clientDocId);
        let newSelectedClient;
        if (matchedClients.length > 0) {
          newSelectedClient = matchedClients[0];
        } else if (authorizedClients.length > 0) {
          newSelectedClient = authorizedClients[0];
        } else {
          const err = new Error(`No authorized Clients for this User`);
          this.clientSubject$.error(err);
          throw err;
        }
        this.clientSubject$.next(newSelectedClient);
        // This selection is in the user's authorized list so store it
        return from(this.storageService.sessionStorage.set(SELECTED_CLIENT_KEY, newSelectedClient.docId));
      }),
      catchError(err => {
        console.error(`Couldn't update the selected Client: ${err}`);
        return of(err);
      })
    ).subscribe();
  }

  /**
   * Get a list of all authorized Clients for this User; typically only used by the HmsComponent selector
   */
  getAuthorizedClients() {
    return this.authService.userProfile$
      .pipe(
        switchMap(userProfile => {
          const userDocId = userProfile.app_metadata.firestoreDocId;
          if (!userDocId) {
            return of([]);
          }
          return this.userSettingsService.getHmsSettingsByUserDocId(userDocId);
        }),
        switchMap((hmsUserSettings: HMSUserSettings) => {
          if (hmsUserSettings?.authorizedClientDocIds?.length === 0) {
            return of([]);
          }
          return combineLatest(hmsUserSettings.authorizedClientDocIds.map(clientDocId => this.clientService.getClient(clientDocId)));
        }),
        shareReplay({bufferSize: 1, refCount: true})
      );
  }
}
