import { Pipe, PipeTransform } from '@angular/core';
import { ContractUnit } from '@advance-trading/ops-data-lib';

/**
 * Returns the display name for a given ContractUnit
 */
@Pipe({name: 'contractUnit'})
export class ContractUnitPipe implements PipeTransform {

  private ContractUnits = [
    { id: ContractUnit.BUSHELS, displayName: 'Bushels' },
    { id: ContractUnit.POUNDS, displayName: 'Pounds' },
    { id: ContractUnit.METRIC_TONS, displayName: 'Metric Tons' },
    { id: ContractUnit.SHORT_TONS, displayName: 'Short Tons' },
    { id: ContractUnit.HUNDREDWEIGHTS, displayName: 'Hundredweights' },
    { id: ContractUnit.GALLONS, displayName: 'Gallons' },
    { id: ContractUnit.TROY_OUNCES, displayName: 'Troy Ounces' },
    { id: ContractUnit.CUBIC_METERS, displayName: 'Cubic Meters' },
    { id: ContractUnit.MMBTU, displayName: 'MMBtu' },
    { id: ContractUnit.BARRELS, displayName: 'Barrels' },
    { id: ContractUnit.NOMINAL_BOARD_FEET, displayName: 'Nominal Board Feet' }
  ];

  transform(value: string): string {
    const matchingEntry = this.ContractUnits.find(contractUnit => contractUnit.id === value);
    return matchingEntry ? matchingEntry.displayName : value;
  }
}
