import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import * as moment from 'moment';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommodityProfileService } from '@advance-trading/angular-ops-data';
import { Client, CommodityProfile } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { LedgerHelperService } from '../../service/ledger-helper.service';
import { UserRoles } from '../../utilities/user-roles';
import { LedgerDay } from '../../utilities/ledger-day';

@Component({
  selector: 'hms-positions-ledger',
  templateUrl: './positions-ledger.component.html',
  styleUrls: ['./positions-ledger.component.scss']
})
export class PositionsLedgerComponent implements OnInit {
  errorMessage: string;
  isLoading = false;

  positionsLedgerForm: FormGroup = this.formBuilder.group({
    commodityProfile: ['', [Validators.required]],
    ledgerDate: ['', [Validators.required]],
  });

  commodityProfiles: CommodityProfile[];
  ledgerEndOfDay: string;
  ledgerEndOfDay$: Observable<moment.Moment>;
  selectedDate: string;
  selectedProfile: CommodityProfile;
  timezone: string;

  private positionsLogLoaded = false;
  private positionsSummaryLoaded = false;
  private queryParams: Params;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private formBuilder: FormBuilder,
    private ledgerHelperService: LedgerHelperService,
    private router: Router
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.FULL_LEDGER_VIEWER_ROLE) &&
      !this.authzService.currentUserHasRole(UserRoles.LOCATION_LEDGER_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to view the positions ledger.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }
    let selectedClientDocId: string;
    this.ledgerEndOfDay$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((selectedClient: Client) => {
        selectedClientDocId = selectedClient.docId;
        return this.commodityProfileService.getActiveCommodityProfilesByTypeAndClientDocId(selectedClientDocId);
      }),
      switchMap((commodityProfiles: CommodityProfile[]) => {
        this.commodityProfiles = commodityProfiles;
        return this.clientSettingsService.getHmsLedgerEndOfDaySettingsByClientDocId(selectedClientDocId);
      }),
      tap((ledgerDay: LedgerDay) => {
        this.ledgerEndOfDay = this.ledgerHelperService.getLedgerEndOfDayFromMoment(ledgerDay.ledgerEndOfDay);
        this.timezone = ledgerDay.timezone;
        this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
          this.queryParams = Object.assign({}, params);
          // must set this.selectedDate before handling commodityProfile param or the setting of this.selectedProfile will trigger a
          // queryParam update that will strip off ledgerDate as it has not been set yet.
          if (this.queryParams.ledgerDate) {
            this.selectedDate = this.ledgerHelperService.forceLedgerEndOfDayFromFullDateFormat(
              this.queryParams.ledgerDate, this.ledgerEndOfDay, this.timezone);
          } else {
            this.selectedDate = this.currentBusinessDay;
          }
          this.positionsLedgerForm.get('ledgerDate').setValue(this.selectedDate);
          if (this.queryParams.commodityProfile) {
            const selectedProfile = this.commodityProfiles.find(profile => profile.docId === this.queryParams.commodityProfile);
            if (selectedProfile) {
              this.positionsLedgerForm.get('commodityProfile').setValue(selectedProfile);
            }
          }
        });
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving client settings; please try again later';
        console.error(`Error retrieving client settings: ${err}`);
        return of(undefined);
      })
    );

    this.positionsLedgerForm.get('commodityProfile').valueChanges.subscribe((profile: CommodityProfile) => {
      this.isLoading = true;
      this.selectedProfile = profile;
      this.setQueryParams();
    });
  }

  onPositionsLogError(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  onPositionsSummaryError(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  onPositionsLogLoaded() {
    this.positionsLogLoaded = true;

    if (this.positionsSummaryLoaded || !this.isLedgerLive) {
      this.isLoading = false;
    }
  }

  onPositionsSummaryLoaded() {
    this.positionsSummaryLoaded = true;

    if (this.positionsLogLoaded) {
      this.isLoading = false;
    }
  }

  compareCommodityProfile(profile1: CommodityProfile, profile2: CommodityProfile) {
    return profile1 && profile2 && profile1.docId === profile2.docId;
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    }
    if (control.hasError('matDatepickerParse')) {
      return 'Value required';
    }
    if (control.hasError('matDatepickerMax')) {
      return 'Value invalid';
    }
    return 'Unknown error';
  }

  listenForEnterOrTab(event: KeyboardEvent) {
    if (['Enter', 'Tab'].includes(event.key)) {
      this.updateSelectedDate();
    }
  }

  goLive() {
    this.positionsLedgerForm.get('ledgerDate').setValue(this.currentBusinessDay);
    this.updateSelectedDate();
  }


  onDatePickerClosed() {
    this.updateSelectedDate();
  }


  get currentBusinessDay() {
    return this.ledgerHelperService.getCurrentBusinessDay(this.ledgerEndOfDay, this.timezone);
  }
  get isLedgerLive() {
    return this.selectedDate === this.currentBusinessDay;
  }

  private updateSelectedDate() {
    const ledgerDate = this.positionsLedgerForm.get('ledgerDate').value as Date;
    let newSelectedDate = this.ledgerHelperService.forceLedgerEndOfDay(
      this.ledgerHelperService.convertDateToMoment(ledgerDate), this.ledgerEndOfDay, this.timezone);
    if (!newSelectedDate) {
      newSelectedDate = this.currentBusinessDay;
      this.positionsLedgerForm.get('ledgerDate').setValue(newSelectedDate);
    }
    if (newSelectedDate !== this.selectedDate) {
      if (this.selectedProfile) {
        this.isLoading = true;
      }
      this.selectedDate = newSelectedDate;
      this.setQueryParams();
    }
  }

  private setQueryParams() {
    if (this.selectedProfile) {
      this.queryParams.commodityProfile = this.selectedProfile.docId;
    }
    // preserve selected ledger date in query param if not current
    if (this.selectedDate !== this.currentBusinessDay) {
      this.queryParams.ledgerDate = this.ledgerHelperService.convertISOStringToFullDate(this.selectedDate);
    } else {
      delete this.queryParams.ledgerDate;
    }
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }
}
