import { AuthService } from '@advance-trading/angular-ati-security';
import { HMSUserPreferences, User } from '@advance-trading/ops-data-lib';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {

  constructor(
    private db: AngularFirestore,
    private authService: AuthService
  ) {}

  /**
   * Return the HMSUserPreferences document for the specified User
   *
   * @param userDocId The docId of the User for which to retrieve HMS settings
   */
  getHmsPreferencesByUserDocId(userDocId: string): Observable<HMSUserPreferences> {
    return this.db.doc<HMSUserPreferences>(`${User.getDataPath(userDocId)}/${HMSUserPreferences.getDataPath()}`)
      .valueChanges();
  }

  /**
   * Set HMSUserPreferences document for the specified User
   *
   * @param userDocId The docId of the User for which to update HMS settings
   * @param hmsUserPreferences The HMSUserPreferences object
   */
  setHmsPreferencesByUserDocId(userDocId: string, hmsUserPreferences: HMSUserPreferences): Promise<void> {
    hmsUserPreferences.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.doc<HMSUserPreferences>(`${User.getDataPath(userDocId)}/${HMSUserPreferences.getDataPath()}`)
      .set(hmsUserPreferences.getPlainObject());
  }
}
