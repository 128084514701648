<div class="checkbox-group search-component">
  <div class="header status-header">
    <span class="check-radio-label">Status</span>
    <div class="button-group-wrapper">
      <mat-button-toggle-group [disabled]="disabled" #group="matButtonToggleGroup" [value]="displayAdvancedListValue"
        (change)="updateLevelSelection(group)" name="statusSelection" aria-label="Status Selection">
        <mat-button-toggle class="" [matTooltipShowDelay]="tooltips.delay" [matTooltip]="tooltips.buttons.basic" [value]="false">
          Basic
        </mat-button-toggle>
        <mat-button-toggle class="" [matTooltipShowDelay]="tooltips.delay" [matTooltip]="tooltips.buttons.advanced" [value]="true">
          Advanced
        </mat-button-toggle>
      </mat-button-toggle-group>
      <button type="button" class="clear-button" [disabled]="disabled" (click)="clearStatusSelection($event)" [matTooltipShowDelay]="tooltips.delay"
        [matTooltip]="tooltips.buttons.clear" mat-stroked-button>Clear</button>
    </div>
  </div>

  <div class="checkbox-items" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="row wrap"
    fxLayoutGap.xs="15px">

    <ng-container *ngIf="this.displayAdvancedListValue; then advancedTemplate; else basicTemplate"></ng-container>
    <ng-template #advancedTemplate>
      <mat-checkbox [disabled]="disabled" fxFlex.gt-xs="25" fxFlex.xs="100" *ngFor="let checkbox of this.advancedCheckboxes"
        (change)="onAdvancedCheckboxChange($event, checkbox)" [checked]="checkbox.checked">
        {{ checkbox.value | replace | titlecase }}
      </mat-checkbox>
    </ng-template>
    <ng-template #basicTemplate>
      <mat-checkbox [disabled]="disabled" fxFlex.gt-xs="25" [matTooltipShowDelay]="tooltips.delay"
        [matTooltip]="tooltips.checkboxes[checkbox.value.toLowerCase()] || ''" fxFlex.xs="100"
        *ngFor="let checkbox of this.basicCheckBoxes" (change)="onBasicCheckboxChange($event, checkbox)"
        [checked]="checkbox.checked">
        {{ checkbox.value | replace | titlecase }}
      </mat-checkbox>
    </ng-template>
  </div>
</div>
