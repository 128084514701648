import { Component, forwardRef, Input, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

import { Moment } from 'moment';

@Component({
  selector: 'hms-month-picker',
  templateUrl: './month-picker.component.html',
  styleUrls: ['./month-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MonthPickerComponent),
    multi: true,
  }]
})
export class MonthPickerComponent implements ControlValueAccessor {
  @ViewChild(MatDatepicker, {static: false}) datePickerRef: MatDatepicker<Moment>;
  @Input() placeholder: string;
  @Input() panelClass: string;
  @Input() formControlName: string;
  formControl: AbstractControl;

  private inputField = new FormControl('');

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(obj: any): void {
    // this.ngControl.valueAccessor.writeValue(obj);
    // this.inputField.setValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.inputField.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void { }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.inputField.disable() : this.inputField.enable();
  }

  selectMonth(e) {
    this.datePickerRef.close();
  }

}
