import { Component, Input } from '@angular/core';

import { AdHocOrderLeg, CommodityMap } from '@advance-trading/ops-data-lib';

@Component({
  selector: 'hms-ad-hoc-order-leg',
  templateUrl: './ad-hoc-order-leg.component.html',
  styleUrls: ['./ad-hoc-order-leg.component.scss']
})
export class AdHocOrderLegComponent {

  @Input() leg: AdHocOrderLeg;
  @Input() commodityMap: CommodityMap;

  constructor() { }

  getCommodityName(commodityId: string) {
    return this.commodityMap.commodities[commodityId].name;
  }
}
