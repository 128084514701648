import { HMSDailyLedger } from '@advance-trading/ops-data-lib';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'hms-hedgeable-quantity-dialog',
  templateUrl: './hedgeable-quantity-dialog.component.html',
  styleUrls: [ './hedgeable-quantity-dialog.component.scss']
})
export class HedgeableQuantityDialogComponent {
  ledgersWithHedgeableQuantities: HMSDailyLedger[];
  columnsToDisplay = [ 'productionYear', 'hedgeableQuantity' ];

  cancel = 'cancel';
  ignore = 'ignore';
  create = 'create';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data = {}) {
    this.ledgersWithHedgeableQuantities = data[ 'ledgersWithHedgeableQuantities' ];
  }

}
