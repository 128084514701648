<div data-cy="pricing-segment" class="pricing-segment" fxFlex="100" fxLayout="column" fxLayoutGap="15px">

  <form [formGroup]="pricingSegmentForm" fxFlex="100" fxLayout="column" fxLayoutGap="15px">
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100">
      <div>
        <h5 *ngIf="createMode">New Pricing ({{ availableQuantity | number: '1.0-0' }} Unpriced {{ contractUnit | contractUnit }})</h5>
        <h5 *ngIf="!createMode">{{ pricingSegment.status | replace | titlecase }} ({{ pricingSegment.quantity | number: '1.0-2' }} {{ contractUnit | contractUnit }}) </h5>
        <div class="pricing-identifier" *ngIf="!createMode">{{ pricingSegment.creationTimestamp  | date:'#yyMMdd.HHmmss' }}</div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <button data-cy="editSegment" id="edit-segment" class="action-button" type="button" aria-label="Edit Pricing" matTooltip="Edit Pricing" *ngIf="canEditPricing" (click)="setEditMode(true)" mat-mini-fab color="accent">
          <mat-icon>edit</mat-icon>
        </button>
        <button id="cancel-pricing-segment" class="action-button" type="button" aria-label="Cancel Pricing" *ngIf="canCancelPricingSegment && !hasPartialFill" (click)="cancelPricingSegment()" mat-mini-fab color="" matTooltip="Cancel Pricing">
          <mat-icon color="primary">do_not_disturb</mat-icon>
        </button>
        <button id="halt-pricing-segment" class="action-button" type="button" aria-label="Cancel Partial Pricing" *ngIf="canCancelPricingSegment && hasPartialFill" (click)="haltPricingSegment()" mat-mini-fab color="" matTooltip="Cancel Partial Pricing">
          <mat-icon color="primary">work_off</mat-icon>
        </button>
        <button id="delete-pricing-segment" class="action-button" type="button" aria-label="Delete Pricing" *ngIf="canDeletePricingSegment" (click)="deletePricingSegment()" mat-mini-fab color="" matTooltip="Delete Pricing">
          <mat-icon color="warn">delete</mat-icon>
        </button>
        <mat-spinner *ngIf="!updateComplete || isFormLoading" diameter="40" color="accent"></mat-spinner>
        <button id="update-pricing-segment-order" class="action-button" type="button" aria-label="Update Pricing" *ngIf="(creatingOrder || recreatingOrder) && (!qstService.enabled || qstCreateError)" [disabled]="orderForm.pristine || !orderForm.valid || qstWorking" (click)="setSegmentOrder()" mat-mini-fab color="accent" matTooltip="Update Pricing">
          <mat-icon>done</mat-icon>
        </button>
        <button id="create-exchange-id" class="action-button" type="submit" aria-label="Update Pricing" *ngIf="editMode && pricingSegment.status === 'PENDING_EXCHANGE'" [disabled]="pricingSegmentForm.pristine || !pricingSegmentForm.valid" (click)="setExchangeId()" mat-mini-fab color="accent" matTooltip="Set Exchange Id">
          <mat-icon>done</mat-icon>
        </button>
        <button data-cy="updatePricing" type="submit" id="update-pricing" class="action-button" aria-label="Update Pricing" matTooltip="Update Pricing" *ngIf="editingPricing" [disabled]="!pricingSegmentForm.valid || pricingSegmentForm.pristine" (click)="submitPricingUpdate()" mat-mini-fab color="accent">
          <mat-icon>done</mat-icon>
        </button>
        <button data-cy="cancelEditPricing" id="cancel-edit-pricing" class="action-button" type="button" aria-label="Discard Changes" matTooltip="Discard Changes" *ngIf="editMode && updateComplete" (click)="reset()" mat-mini-fab color="">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="!errorMessage && segmentDetails$ | async" fxFlex="100" fxLayout="column">

      <div *ngIf="!createMode" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.gt-xs="15px" fxLayoutGap.xs="5px">

        <div *ngIf="pricingSegment && pricingSegment.status === 'WORKING_EXCHANGE' && canCompleteOrderAction" fxLayout="row" fxLayoutGap="10px" class="pricing-segment">
          <button data-cy="completePricingExchange" id="complete-pricing-exchange" type="button"  aria-label="Complete Exchange" (click)="completePricingExchange()" mat-raised-button color="accent">Complete Exchange</button>
          <button data-cy="cancelPricingExchange" id="cancel-pricing-exchange" type="button" aria-label="Cancel Exchange" (click)="cancelPricingExchange()" mat-stroked-button color="accent">Cancel Exchange</button>
        </div>

        <div fxLayout="row" *ngIf="pricingSegment.orderDocId" fxFlex.gt-xs="20" fxFlex.xs="50">
          <ati-labeled-data label="Order ID" [data]="orderDocIdDisplay" [link]="getOrderLink()"></ati-labeled-data>
          <button *ngIf="cancellingOrder && !qstWorking" id="cancel-segment-order" class="action-button" type="button" aria-label="Cancel Order" (click)="cancelOrder()" mat-icon-button color="warn" matTooltip="Cancel Order">
            <mat-icon>do_not_disturb</mat-icon>
          </button>
          <button *ngIf="updatingOrder && !qstWorking" id="update-segment-order" class="action-button" type="button" aria-label="Update Order" (click)="updateOrder()" mat-icon-button color="primary" matTooltip="Update Order">
            <mat-icon>flaky</mat-icon>
          </button>
          <button *ngIf="recreatingOrder && !qstWorking" id="recreate-segment-order" class="action-button" type="button" aria-label="Recreate Order" (click)="recreateOrder()" mat-icon-button color="primary" matTooltip="Recreate Order">
            <mat-icon>flaky</mat-icon>
          </button>
          <mat-spinner *ngIf="qstWorking" diameter="16"></mat-spinner>
        </div>
        <form *ngIf="creatingOrder || (recreatingOrder && !pricingSegment.orderDocId)" class="creating-order" fxFlex.gt-xs="20" fxFlex.xs="100" [formGroup]="orderForm" (ngSubmit)="setSegmentOrder()">
          <mat-form-field>
            <input required matInput placeholder="Order ID" formControlName="orderDocId" trim="blur" (blur)="isOrderFormValid" (keyup)="isOrderFormValid">
            <button class="qst-btn" type="button" id="create-segment-order" *ngIf="!orderForm.get('orderDocId').value && qstService.enabled && !qstWorking" matTooltip="Create Order" matSuffix mat-icon-button aria-label="Create Order" (click)="createOrder()">
              <mat-icon>receipt</mat-icon>
            </button>
            <mat-spinner matSuffix *ngIf="qstWorking" diameter="16"></mat-spinner>
            <mat-error *ngIf="orderForm.get('orderDocId').invalid">{{ getErrorMessage(orderForm.get('orderDocId')) }}</mat-error>
            <div class="margin-bottom-thin mat-form-field-subscript-wrapper" [ngClass]="orderFormInvalid ? 'order-hint-position-with-error' : 'order-hint-position'" >
              <span id="order-hint" class="mat-hint">{{ contract.side }} {{ getOrderQuantity() }} {{ contract.futuresYearMonth | codeToMonth }} {{ contract.commodityId }} @ {{ pricingSegment.futuresPrice }} <span *ngIf="contract.expirationDate">GTD {{ contract.expirationDate | date:'M/d/yyyy' }} </span></span>
            </div>
          </mat-form-field>
        </form>


      </div>

      <div *ngIf="!createMode" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.gt-xs="15px" fxLayoutGap.xs="5px">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" >
          <ati-labeled-data label="Pricing ID" [data]="pricingSegment.accountingSystemId"></ati-labeled-data>
        </div>
      </div>

      <!-- Delivery Location, Delivery Period, Contract, Exchange ID, Related Hedge -->
      <div *ngIf="!createMode" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.gt-xs="15px" fxLayoutGap.xs="5px">
        <ati-labeled-data *ngIf="pricingSegment.contractType === 'HTA'" fxFlex.gt-xs="20" fxFlex.xs="100" label="Delivery Location" [data]="deliveryLocationName"></ati-labeled-data>
        <ati-labeled-data *ngIf="pricingSegment.contractType === 'HTA' || pricingSegment.contractType === 'DP'" fxFlex.gt-xs="20" fxFlex.xs="48" label="Delivery Period" [data]="pricingSegment.deliveryPeriod | codeToMonth"></ati-labeled-data>
        <ati-labeled-data *ngIf="pricingSegment.futuresYearMonth" fxFlex.gt-xs="20" fxFlex.xs="48" label="Contract" [data]="pricingSegment.futuresYearMonth | codeToMonth"></ati-labeled-data>
        <ati-labeled-data *ngIf="pricingSegment.hasRelatedHedge" fxFlex.gt-xs="20" fxFlex.xs="48" label="Related Hedge" [data]="displayRelatedHedge"></ati-labeled-data>
      </div>

      <!-- Pricing Segment Level - Exchange ID, Exchange Type, Exchange Side, Exchange Contracts -->
      <div *ngIf="pricingSegment?.isExchange" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.gt-xs="15px" fxLayoutGap.xs="5px">
        <div *ngIf="!createMode && pricingSegment?.isExchange" fxFlex.gt-xs="20" fxFlex.xs="30">
          <ati-labeled-data *ngIf="!creatingPricingExchange && (!editMode || !pricingSegmentForm.get('exchangeId'))"  label="Exchange ID" [data]="pricingSegment.exchangeId"></ati-labeled-data>

          <div *ngIf="(creatingPricingExchange && !pricingSegment.exchangeId) || (editMode && pricingSegmentForm.get('exchangeId'))">
            <mat-form-field fxFlex="90">
              <input required matInput placeholder="Exchange ID" formControlName="exchangeId" trim="blur">
              <mat-error *ngIf="pricingSegmentForm.get('exchangeId').invalid">{{ getErrorMessage(pricingSegmentForm.get('exchangeId')) }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxFlex.gt-xs="20" fxFlex.xs="30">
          <ati-labeled-data *ngIf="!editMode || ! pricingSegmentForm.get('exchangeType')" label="Exchange Type" [data]="pricingSegment.exchangeType"></ati-labeled-data>
          <div *ngIf="editMode && pricingSegmentForm.get('exchangeType')">
            <mat-button-toggle-group formControlName="exchangeType" aria-label="Exchange Type">
              <mat-button-toggle *ngFor="let exchangeType of exchangeTypes" [value]="exchangeType">{{ exchangeType }}</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="30" label="Exchange Side" [data]="getExchangeSide() | titlecase"></ati-labeled-data>
        <div fxFlex.gt-xs="20" fxFlex.xs="30">
          <ati-labeled-data *ngIf="!editMode || ! pricingSegmentForm.get('exchangeContracts')" label="Exchange Contracts" [data]="pricingSegment.exchangeContracts"></ati-labeled-data>
          <div *ngIf="editMode && pricingSegmentForm.get('exchangeContracts')">
            <mat-form-field fxFlex="90">
              <input required matInput placeholder="Exchange Contracts" formControlName="exchangeContracts" trim="blur">
              <mat-error *ngIf="pricingSegmentForm.get('exchangeContracts').invalid">{{ getErrorMessage(pricingSegmentForm.get('exchangeContracts')) }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Pricing Segment Level - Exchange Price, Contra Firm, Contra Account, Exchange Timestamp -->
      <div *ngIf="pricingSegment?.isExchange" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.gt-xs="15px" fxLayoutGap.xs="5px">
        <div fxFlex.gt-xs="20" fxFlex.xs="30">
          <ati-labeled-data *ngIf="!editMode || !pricingSegmentForm.get('futuresPrice')" label="Exchange Price" [data]="pricingSegment.futuresPrice | number: '1.2-4' "></ati-labeled-data>
          <div *ngIf="editMode && pricingSegmentForm.get('futuresPrice')">
            <mat-form-field fxFlex="90">
              <input required matInput placeholder="Exchange Price" formControlName="futuresPrice" trim="blur">
              <mat-error *ngIf="pricingSegmentForm.get('futuresPrice').invalid">{{ getErrorMessage(pricingSegmentForm.get('futuresPrice')) }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxFlex.gt-xs="20" fxFlex.xs="30">
          <ati-labeled-data *ngIf="!editMode || !pricingSegmentForm.get('exchangeContraFirm')" label="Contra Firm" [data]="pricingSegment.exchangeContraFirm"></ati-labeled-data>
          <div *ngIf="editMode && pricingSegmentForm.get('exchangeContraFirm')">
            <mat-form-field fxFlex="90">
              <input required matInput placeholder="Contra Firm" formControlName="exchangeContraFirm" trim="blur">
              <mat-error *ngIf="pricingSegmentForm.get('exchangeContraFirm').invalid">{{ getErrorMessage(pricingSegmentForm.get('exchangeContraFirm')) }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxFlex.gt-xs="20" fxFlex.xs="30">
          <ati-labeled-data *ngIf="!editMode || !pricingSegmentForm.get('exchangeContraFirm')" label="Contra Account" [data]="pricingSegment.exchangeContraAccount"></ati-labeled-data>
          <div *ngIf="editMode && pricingSegmentForm.get('exchangeContraFirm')">
            <mat-form-field fxFlex="90">
              <input required matInput placeholder="Contra Account" formControlName="exchangeContraAccount" trim="blur">
              <mat-error *ngIf="pricingSegmentForm.get('exchangeContraAccount').invalid">{{ getErrorMessage(pricingSegmentForm.get('exchangeContraAccount')) }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="30" label="Exchange Date" [data]="pricingSegment.exchangeTimestamp | date: 'M/d/yyyy, h:mm:ss a' "></ati-labeled-data>
      </div>

      <!-- Special Handling, Special Handling Type -->
      <div *ngIf="canCreateExchangePricing || canCreateHedgedPricing" class="special-handling" fxFlex="100" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">

        <div fxFlex.gt-xs="20" fxLayout="row wrap" fxLayoutAlign.gt-xs="start center" fxLayoutAlign.xs="start start">
          <div class="checkbox-group">
            <mat-checkbox formControlName="specialHandling">Special Handling</mat-checkbox>
          </div>
        </div>

        <div *ngIf="pricingSegmentForm.get('specialHandling').value">
          <mat-radio-group formControlName="specialHandlingType" required>
            <mat-radio-button *ngIf="canCreateExchangePricing" value="EXCHANGE">Price with Exchange</mat-radio-button>
            <mat-radio-button *ngIf="canCreateHedgedPricing" value="OTC">OTC Trade</mat-radio-button>
            <mat-radio-button *ngIf="canCreateOptionPricing" value="OPTION">Exercised Option</mat-radio-button>
          </mat-radio-group>
        </div>

      </div>

      <!-- Quantity, Futures Month-->
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="15px">
        <ng-container *ngIf="pricingCash && basis$ | async"></ng-container>
        <div fxFlex.gt-xs="20" fxFlex.xs="40" *ngIf="createMode">
          <mat-form-field fxFlex="100">
            <input required matInput placeholder="Quantity" formControlName="quantity" data-cy="quantity">
            <mat-error *ngIf="pricingSegmentForm.get('quantity').invalid">{{ getErrorMessage(pricingSegmentForm.get('quantity')) }}</mat-error>
            <button type="button" id="clear-segment-quantity" mat-button *ngIf="pricingSegmentForm.get('quantity').value" matSuffix mat-icon-button aria-label="Clear Quantity" matTooltip="Clear Quantity" (click)="clearField('quantity')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <mat-form-field *ngIf="pricingFuturesForDPFirstPart || pricingCash || pricingFuturesForDPSecondPart" fxFlex.gt-xs="20" fxFlex.xs="40" hmsMonthPicker data-cy="futuresYearMonth">
          <input required matInput [matDatepicker]="futuresMonthPicker" [matDatepickerFilter]="futuresMonthFilter" placeholder="Futures Month" formControlName="futuresMonth" [min]="minDate" (keydown)="allowTabOnly($event)">
          <mat-datepicker-toggle matSuffix [for]="futuresMonthPicker" data-cy="futuresMonthPicker"></mat-datepicker-toggle>
          <mat-datepicker #futuresMonthPicker startView="year" (monthSelected)="selectFuturesMonth($event)"></mat-datepicker>
          <mat-error *ngIf="pricingSegmentForm.get('futuresMonth').invalid">{{ getErrorMessage(pricingSegmentForm.get('futuresMonth')) }}</mat-error>
        </mat-form-field>

        <!-- Option Contracts -->
        <mat-form-field *ngIf="pricingFuturesWithExercisedOption" fxFlex.gt-xs="20" fxFlex.xs="40">
          <input required matInput placeholder="Option Contracts" formControlName="optionContracts" trim="blur" [errorStateMatcher]="optionQuantityErrorMatcher">
          <mat-error *ngIf="pricingSegmentForm.get('optionContracts').invalid || pricingSegmentForm.hasError('optionQuantity')">{{ getErrorMessage(pricingSegmentForm.get('optionContracts')) }}</mat-error>
          <button type="button" id="clear-segment-option-contracts" *ngIf="pricingSegmentForm.get('optionContracts').value" matSuffix mat-icon-button aria-label="Clear Contracts" matTooltip="Clear Contracts" (click)="clearField('optionContracts')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <!-- Futures, Freight, Basis Adjustment, Cash -->
      <div *ngIf="displayCashForPricingBasis" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.gt-xs="15px" fxLayoutGap.xs="5px">
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="30" label="Futures" [data]="futuresPrice | number: '1.2-5' "></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="30" label="Freight" [data]="contract.freightPrice | number: '1.2-4' "></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="30" label="Basis Adjustment" [data]="contract.basisAdjustment | number: '1.2-4' "></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="30" label="Cash" [data]="cashPriceForPricingBasis | number: '1.2-5' "></ati-labeled-data>
      </div>

      <!-- Basis, Freight, Basis Adjustment, Cash -->
      <div *ngIf="displayCashForPricingFutures" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.gt-xs="15px" fxLayoutGap.xs="5px">
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="30" label="Basis" [data]="basisPrice | number: '1.2-4' "></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="30" label="Freight" [data]="contract.freightPrice | number: '1.2-4' "></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="30" label="Basis Adjustment" [data]="contract.basisAdjustment | number: '1.2-4' "></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="30" label="Cash" [data]="cashPriceForPricingFutures | number: '1.2-5' "></ati-labeled-data>
      </div>

      <!-- Pricing Basis -->
      <div *ngIf="pricingBasisForHTA || pricingBasisForDPFirstPart || pricingBasisForDPSecondPart" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="15px">
        <div fxFlex.gt-xs="20" fxFlex.xs="40">
          <ng-container *ngIf="basis$ | async"></ng-container>
          <mat-form-field fxFlex="100">
            <input data-cy="basisPrice" required matInput placeholder="Basis" formControlName="basisPrice" trim="blur" (blur)="formatPrice('basisPrice')">
            <mat-error *ngIf="pricingSegmentForm.get('basisPrice').invalid">{{ getErrorMessage(pricingSegmentForm.get('basisPrice')) }}</mat-error>
            <mat-spinner matSuffix *ngIf="isLoadingBasis" diameter="16"></mat-spinner>
            <button type="button" id="clear-segment-basis" mat-button *ngIf="pricingSegmentForm.get('basisPrice').value && pricingSegmentForm.get('basisPrice').enabled" matSuffix mat-icon-button aria-label="Clear Basis" matTooltip="Clear Basis" (click)="clearField('basisPrice')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div fxFlex.gt-xs="20" fxFlex.xs="40">
          <mat-button-toggle-group formControlName="basisPricingType" aria-label="Basis Pricing" matTooltip="Basis Pricing">
            <mat-button-toggle *ngFor="let type of pricingTypes" attr.data-cy="pricingType-{{type}}" [value]="type">{{ type | pricingType | titlecase }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <!-- Exchange Type, Exchange Contracts, Contra Firm, Contra Account -->
      <div *ngIf="pricingFuturesWithExchange" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.gt-xs="15px">

        <div fxFlex.gt-xs="20" fxFlex.xs="40">
          <mat-button-toggle-group formControlName="exchangeType" aria-label="Exchange Type">
            <mat-button-toggle *ngFor="let exchangeType of exchangeTypes" [value]="exchangeType">{{ exchangeType }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <mat-form-field fxFlex.gt-xs="20" fxFlex.xs="100">
          <input required matInput placeholder="Contracts" formControlName="exchangeContracts" trim="blur">
          <mat-error *ngIf="pricingSegmentForm.get('exchangeContracts').invalid">{{ getErrorMessage(pricingSegmentForm.get('exchangeContracts')) }}</mat-error>
          <button type="button" id="clear-pricing-segment-exchange-contracts" *ngIf="pricingSegmentForm.get('exchangeContracts').value" matSuffix mat-icon-button aria-label="Clear Contracts" matTooltip="Clear Contracts" (click)="clearField('exchangeContracts')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field fxFlex.gt-xs="20" fxFlex.xs="100">
          <input required matInput placeholder="Contra Firm" formControlName="exchangeContraFirm" trim="blur">
          <mat-error *ngIf="pricingSegmentForm.get('exchangeContraFirm').invalid">{{ getErrorMessage(pricingSegmentForm.get('exchangeContraFirm')) }}</mat-error>
          <button type="button" id="clear-pricing-segment-exchange-contra-firm" *ngIf="pricingSegmentForm.get('exchangeContraFirm').value" matSuffix mat-icon-button aria-label="Clear Contra Firm" matTooltip="Clear Contra Firm" (click)="clearField('exchangeContraFirm')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field fxFlex.gt-xs="20" fxFlex.xs="100">
          <input required matInput placeholder="Contra Account" formControlName="exchangeContraAccount" trim="blur">
          <mat-error *ngIf="pricingSegmentForm.get('exchangeContraAccount').invalid">{{ getErrorMessage(pricingSegmentForm.get('exchangeContraAccount')) }}</mat-error>
          <button type="button" id="clear-pricing-segment-exchange-contra-account" *ngIf="pricingSegmentForm.get('exchangeContraAccount').value" matSuffix mat-icon-button aria-label="Clear Contra Account" matTooltip="Clear Contra Account" (click)="clearField('exchangeContraAccount')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <!-- Pricing Futures -->
      <div *ngIf="pricingFuturesForBasis || ((pricingFuturesForDPFirstPart || pricingFuturesForDPSecondPart) && pricingSegmentForm.get('futuresMonth').value)" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="15px">
        <div fxFlex.gt-xs="20" fxFlex.xs="40">
          <ng-container *ngIf="futures$ | async"></ng-container>
          <div *ngIf="pricingSegmentForm.get('futuresPrice').disabled" fxFlex.gt-xs="20" fxFlex.xs="30" fxLayout="row">
            <ati-labeled-data label="Futures" [data]="pricingSegmentForm.get('futuresPrice').value | number: '1.2-5'"></ati-labeled-data>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div *ngIf="isLoadingMarketData" class="disabled-loading-spinner">
                <mat-spinner diameter="16"></mat-spinner>
              </div>
              <button *ngIf="pricingSegmentForm.get('futuresPricingType').value === 'MARKET' && !isLoadingMarketData" id="refresh-segment-futures" type="button" mat-icon-button (click)="refreshFutures()" aria-label="Refresh Futures" matTooltip="Refresh Futures">
                <mat-icon class="disabled-refresh-icon">refresh</mat-icon>
              </button>
            </div>
          </div>
          <mat-form-field fxFlex="100" *ngIf="pricingSegmentForm.get('futuresPrice').enabled">
            <input required matInput placeholder="Futures" formControlName="futuresPrice" trim="blur" (blur)="formatPrice('futuresPrice')">
            <mat-error *ngIf="pricingSegmentForm.get('futuresPrice').invalid">{{ getErrorMessage(pricingSegmentForm.get('futuresPrice')) }}</mat-error>
            <div matSuffix fxLayout="row" fxLayoutAlign="space-between center">
              <mat-spinner [ngClass]="isLoadingMarketData ? '' : 'hidden-spinner'" diameter="16"></mat-spinner>
              <button *ngIf="!isLoadingMarketData" type="button" id="refresh-segment-futures" mat-button mat-icon-button aria-label="Refresh Futures" matTooltip="Refresh Futures" (click)="refreshFutures()">
                <mat-icon>refresh</mat-icon>
              </button>
              <button type="button" id="clear-segment-futures" mat-button *ngIf="pricingSegmentForm.get('futuresPrice').value && pricingSegmentForm.get('futuresPrice').enabled" mat-icon-button aria-label="Clear Futures" matTooltip="Clear Futures" (click)="clearField('futuresPrice')">
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </mat-form-field>
        </div>
        <div *ngIf="!pricingSegmentForm.get('specialHandling')?.value" fxFlex.gt-xs="20" fxFlex.xs="40">
          <mat-button-toggle-group formControlName="futuresPricingType" aria-label="Futures Pricing" matTooltip="Futures Pricing">
            <mat-button-toggle *ngFor="let type of pricingTypes" attr.data-cy="pricingType-{{type}}" [value]="type">{{ type | pricingType | titlecase }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <!-- Basis, Futures, Freight, Basis Adjustment -->
      <div *ngIf="pricingCash && pricingSegmentForm.get('futuresMonth').value" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.gt-xs="15px" fxLayoutGap.xs="5px">
        <ati-labeled-data *ngIf="!pricingSegmentForm.get('basisPrice')" fxFlex.gt-xs="20" fxFlex.xs="30" label="Basis" [data]="marketBasisPrice | number: '1.2-4' "></ati-labeled-data>
        <mat-form-field *ngIf="pricingSegmentForm.get('basisPrice')" fxFlex.gt-xs="20" fxFlex.xs="30">
          <input required matInput placeholder="Basis" formControlName="basisPrice" trim="blur" (blur)="formatPrice('basisPrice')" (input)="calculateCashPrice()">
          <mat-error *ngIf="pricingSegmentForm.get('basisPrice').invalid">{{ getErrorMessage(pricingSegmentForm.get('basisPrice')) }}</mat-error>
          <mat-spinner matSuffix *ngIf="isLoadingBasis" diameter="16"></mat-spinner>
          <button type="button" id="clear-segment-basis" mat-button *ngIf="pricingSegmentForm.get('basisPrice').value && pricingSegmentForm.get('basisPrice').enabled" matSuffix mat-icon-button aria-label="Clear Basis" matTooltip="Clear Basis" (click)="clearField('basisPrice')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div *ngIf="pricingSegmentForm.get('futuresPrice').disabled" fxFlex.gt-xs="20" fxFlex.xs="30" fxLayout="row">
          <ati-labeled-data label="Futures" [data]="futuresPriceForPricingCash | number: '1.2-5'"></ati-labeled-data>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div *ngIf="isLoadingMarketData" class="disabled-loading-spinner">
              <mat-spinner diameter="16"></mat-spinner>
            </div>
            <button *ngIf="pricingSegmentForm.get('cashPricingType').value === 'MARKET' && !isLoadingMarketData" id="refresh-segment-futures" type="button" mat-icon-button (click)="refreshFutures()" aria-label="Refresh Futures" matTooltip="Refresh Futures">
              <mat-icon class="disabled-refresh-icon">refresh</mat-icon>
            </button>
          </div>
        </div>
        <mat-form-field *ngIf="pricingSegmentForm.get('futuresPrice').enabled" fxFlex.gt-xs="20" fxFlex.xs="30">
          <input required matInput placeholder="Futures" formControlName="futuresPrice" trim="blur" (blur)="formatPrice('futuresPrice')" (input)="calculateCashPrice()">
          <mat-error *ngIf="pricingSegmentForm.get('futuresPrice').invalid">{{ getErrorMessage(pricingSegmentForm.get('futuresPrice')) }}</mat-error>
          <div matSuffix fxLayout="row" fxLayoutAlign="space-between center">
            <mat-spinner [ngClass]="isLoadingMarketData ? '' : 'hidden-spinner'" diameter="16"></mat-spinner>
            <button *ngIf="!isLoadingMarketData" type="button" id="refresh-segment-futures" mat-button mat-icon-button aria-label="Refresh Futures" matTooltip="Refresh Futures" (click)="refreshFutures()">
              <mat-icon>refresh</mat-icon>
            </button>
            <button type="button" id="clear-segment-futures" mat-button *ngIf="pricingSegmentForm.get('futuresPrice').value && pricingSegmentForm.get('futuresPrice').enabled" mat-icon-button aria-label="Clear Futures" matTooltip="Clear Futures" (click)="clearField('futuresPrice')">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </mat-form-field>
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="48" label="Freight" [data]="contract.freightPrice | number: '1.2-4' "></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="48" label="Basis Adjustment" [data]="contract.basisAdjustment | number: '1.2-4' "></ati-labeled-data>
      </div>

      <!-- Pricing Cash -->
      <div *ngIf="pricingCash && !isLoadingBasis && pricingSegmentForm.get('futuresMonth').value" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="15px">
        <div fxFlex.gt-xs="20" fxFlex.xs="40">
          <ng-container *ngIf="futures$ | async"></ng-container>
          <ati-labeled-data *ngIf="pricingSegmentForm.get('cashPrice').disabled" fxFlex.gt-xs="20" fxFlex.xs="30" label="Cash" [data]="pricingSegmentForm.get('cashPrice').value | number: '1.2-5' "></ati-labeled-data>
          <mat-form-field *ngIf="pricingSegmentForm.get('cashPrice').enabled" fxFlex="100">
            <input required matInput placeholder="Cash" formControlName="cashPrice" trim="blur" (blur)="formatPrice('cashPrice')">
            <mat-error *ngIf="pricingSegmentForm.get('cashPrice').invalid">{{ getErrorMessage(pricingSegmentForm.get('cashPrice')) }}</mat-error>
            <mat-spinner matSuffix *ngIf="isLoadingMarketData" diameter="16"></mat-spinner>
            <button type="button" id="clear-segment-cash" mat-button *ngIf="pricingSegmentForm.get('cashPrice').value && pricingSegmentForm.get('cashPrice').enabled" matSuffix mat-icon-button aria-label="Clear Cash" matTooltip="Clear Cash" (click)="clearField('cashPrice')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div fxFlex.gt-xs="20" fxFlex.xs="40">
          <mat-button-toggle-group formControlName="cashPricingType" aria-label="Cash Pricing" matTooltip="Cash Pricing">
            <mat-button-toggle *ngFor="let type of pricingTypes" attr.data-cy="pricingType-{{type}}" [value]="type">{{ type | pricingType | titlecase }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <!-- Basis, Basis Pricing Type, Basis Adjustment -->
      <div *ngIf="!createMode && !pricingFuturesForDPSecondPart && !pricingBasisForDPSecondPart" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.gt-xs="15px" fxLayoutGap.xs="5px">
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="48" label="Basis" [data]="pricingSegment.basisPrice | number: '1.2-5' "></ati-labeled-data>
        <ati-labeled-data *ngIf="pricingSegment.basisPricingType" fxFlex.gt-xs="20" fxFlex.xs="48" label="Basis Pricing Type" [data]="pricingSegment.basisPricingType | pricingType | titlecase"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="48" label="Basis Adjustment" [data]="pricingSegment.basisAdjustment | number: '1.2-4' "></ati-labeled-data>
      </div>

      <!-- Futures, Futures Pricing Type, Freight -->
      <div *ngIf="!createMode && !pricingFuturesForDPSecondPart && !pricingBasisForDPSecondPart" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.gt-xs="15px" fxLayoutGap.xs="5px">
        <div fxFlex.gt-xs="20" fxFlex.xs="48" fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <ati-labeled-data label="Futures" [data]="(pricingSegmentForm.get('futuresPrice') ? pricingSegmentForm.get('futuresPrice').value : pricingSegment.futuresPrice) | number: '1.2-5' "></ati-labeled-data>
            <button *ngIf="canManuallyLockPrice" id="manually-lock-segment" type="button" aria-label="Lock Price" (click)="lockPrice()" mat-icon-button color="accent" matTooltip="Lock Price">
              <mat-icon>lock_outline</mat-icon>
            </button>
          </div>
          <mat-spinner class="futures-spinner" *ngIf="isLoadingMarketData" diameter="16"></mat-spinner>
        </div>
        <ati-labeled-data *ngIf="pricingSegment.futuresPricingType" fxFlex.gt-xs="20" fxFlex.xs="48" label="Futures Pricing Type" [data]="pricingSegment.futuresPricingType | pricingType | titlecase"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="48" label="Freight" [data]="pricingSegment.freightPrice | number: '1.2-4' "></ati-labeled-data>
      </div>

      <!-- Cash, Cash Pricing Type -->
      <div *ngIf="!createMode && !pricingFuturesForDPSecondPart && !pricingBasisForDPSecondPart" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.gt-xs="15px" fxLayoutGap.xs="5px">
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="48" label="Cash" [data]="pricingSegment.cashPrice | number: '1.2-5' "></ati-labeled-data>
        <ati-labeled-data *ngIf="pricingSegment.cashPricingType" fxFlex.gt-xs="20" fxFlex.xs="48" label="Cash Pricing Type" [data]="pricingSegment.cashPricingType | pricingType | titlecase"></ati-labeled-data>
      </div>

      <!-- Creator, Creation Time, Last Updated By, Completion Timestamp -->
      <div *ngIf="!createMode" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.gt-xs="15px" fxLayoutGap.xs="5px">
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="100" label="Created By" [data]="pricingSegment.creatorName"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="100" label="Creation Timestamp" [data]="pricingSegment.creationTimestamp | date: 'M/d/yyyy, h:mm:ss a' "></ati-labeled-data>
        <ati-labeled-data *ngIf="pricingSegment.status === 'PRICED'" fxFlex.gt-xs="20" fxFlex.xs="100" label="Last Updated By" [data]="pricingSegment.lastUpdatedByName"></ati-labeled-data>
        <ati-labeled-data *ngIf="pricingSegment.status === 'PRICED'" fxFlex.gt-xs="20" fxFlex.xs="100" label="Completion Timestamp" [data]="pricingSegment.lastUpdatedTimestamp | date: 'M/d/yyyy, h:mm:ss a' "></ati-labeled-data>
      </div>

      <div *ngIf="(canPriceBasisForDPSecondPart || canPriceFuturesForDPSecondPart) && !editMode" fxLayout="row" fxLayoutGap="10px">
        <button *ngIf="canPriceBasisForDPSecondPart" data-cy="price-dp-second-part-basis" id="price-dp-second-part-basis" type="submit" aria-label="Price Basis" (click)="setEditModeAndBasisObservable()" mat-raised-button color="accent">Price Basis</button>
        <button *ngIf="canPriceFuturesForDPSecondPart" data-cy="price-dp-second-part-futures" id="price-dp-second-part-futures" type="button" aria-label="Price Futures" (click)="setEditMode(true)" mat-raised-button color="accent">Price Futures</button>
      </div>
      <div *ngIf="pricingBasisForHTA" fxLayout="row" fxLayoutGap="10px">
        <button data-cy="price-segment-basis-for-hta" id="price-segment-basis-for-hta" type="submit" aria-label="Price Basis" [disabled]="!pricingSegmentForm.valid || isLoading" (click)="priceBasisForHTA()" mat-raised-button color="accent">Price Basis</button>
        <button data-cy="cancel-price-segment-basis-for-hta" id="cancel-price-segment-basis-for-hta" type="button" aria-label="Cancel" (click)="cancelPricingAction()" mat-stroked-button color="accent">Cancel</button>
      </div>

      <div *ngIf="pricingBasisForDPFirstPart" fxLayout="row" fxLayoutGap="10px">
        <button data-cy="price-segment-basis-for-dp" id="price-segment-basis-for-dp" data-cy="price-segment-basis-for-dp" type="submit" aria-label="Price Basis" [disabled]="!pricingSegmentForm.valid || isLoading" (click)="priceBasisForDPFirstPart()" mat-raised-button color="accent">Price Basis</button>
        <button data-cy="cancel-price-segment-basis-for-dp" id="cancel-price-segment-basis-for-dp" type="button" aria-label="Cancel" (click)="cancelPricingAction()" mat-stroked-button color="accent">Cancel</button>
      </div>

      <div *ngIf="pricingFuturesForBasis" fxLayout="row" fxLayoutGap="10px">
        <button data-cy="price-segment-futures-for-basis" id="price-segment-futures-for-basis" type="submit" aria-label="Price Futures" [disabled]="!pricingSegmentForm.valid || isLoading" (click)="priceFuturesForBasis()" mat-raised-button color="accent">Price Futures</button>
        <button data-cy="cancel-price-segment-futures-for-basis" id="cancel-price-segment-futures-for-basis" type="button" aria-label="Cancel" (click)="cancelPricingAction()" mat-stroked-button color="accent">Cancel</button>
      </div>

      <div *ngIf="pricingFuturesForDPFirstPart" fxLayout="row" fxLayoutGap="10px">
        <button data-cy="price-segment-futures-for-dp" id="price-segment-futures-for-dp" type="submit" aria-label="Price Futures" [disabled]="!pricingSegmentForm.valid || isLoading" (click)="priceFuturesForDPFirstPart()" mat-raised-button color="accent">Price Futures</button>
        <button data-cy="cancel-price-segment-futures-for-dp" id="cancel-price-segment-futures-for-dp" type="button" aria-label="Cancel" (click)="cancelPricingAction()" mat-stroked-button color="accent">Cancel</button>
      </div>

      <div *ngIf="pricingCash" fxLayout="row" fxLayoutGap="10px">
        <button data-cy="price-segment-cash" id="price-segment-cash" type="submit" aria-label="Price Cash" [disabled]="!pricingSegmentForm.valid || isLoading" (click)="priceCash()" mat-raised-button color="accent">Price Cash</button>
        <button data-cy="cancel-price-segment-cash" id="cancel-price-segment-cash" type="button" aria-label="Cancel" (click)="cancelPricingAction()" mat-stroked-button color="accent">Cancel</button>
      </div>

      <div *ngIf="pricingFuturesForDPSecondPart" fxLayout="row" fxLayoutGap="10px">
        <button data-cy="price-segment-futures-for-dp" id="price-segment-futures-for-dp" type="submit" aria-label="Price Futures" [disabled]="!pricingSegmentForm.valid || isLoading" (click)="priceFuturesForDPSecondPart()" mat-raised-button color="accent">Price Futures</button>
        <button data-cy="cancel-price-segment-futures-for-dp" id="cancel-price-segment-futures-for-dp" type="button" aria-label="Cancel" (click)="cancelPricingFuturesForDPSecondPart()" mat-stroked-button color="accent">Cancel</button>
      </div>

      <div *ngIf="pricingBasisForDPSecondPart" fxLayout="row" fxLayoutGap="10px">
        <button data-cy="price-segment-basis-for-dp" id="price-segment-basis-for-dp" type="submit" aria-label="Price Basis" [disabled]="!pricingSegmentForm.valid || isLoading" (click)="priceBasisForDPSecondPart()" mat-raised-button color="accent">Price Basis</button>
        <button data-cy="cancel-price-segment-basis-for-dp" id="cancel-price-segment-basis-for-dp" type="button" aria-label="Cancel" (click)="cancelPricingBasisForDPSecondPart()" mat-stroked-button color="accent">Cancel</button>
      </div>
    </div>
  </form>

</div>
