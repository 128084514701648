import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';

import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommonValidators } from '@advance-trading/angular-common-services';
import {
  CommodityProfileService,
  ExecutionReportService,
  OperationsDataService,
  OrderFillSummary
} from '@advance-trading/angular-ops-data';
import { Client, CommodityMap, CommodityProfile, ContractMonth, Hedge, HedgeType, HMSClientSettings } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { HedgeService } from '../../service/hedge.service';
import { LedgerHelperService } from '../../service/ledger-helper.service';
import { UserRoles } from '../../utilities/user-roles';

import { HedgeDisplay } from '../hedge-display';

const DEFAULT_START_DATE = new Date('1/1/2000').toISOString();
const DEFAULT_END_DATE = new Date('12/31/2099').toISOString();

const YEAR_FORMAT = 'YY';

@Component({
  selector: 'hms-hedge-search',
  templateUrl: './hedge-search.component.html',
  styleUrls: ['./hedge-search.component.scss']
})
export class HedgeSearchComponent implements OnInit {
  hedgeSearchForm: FormGroup = this.formBuilder.group({
    orderDocId: [''],
    commodityProfile: ['', CommonValidators.objectValidator],
    futuresYearMonth: [''],
    startDate: ['', { updateOn: 'blur' }],
    endDate: ['', { updateOn: 'blur' }]
  });

  showHedges = false;
  selectedHedges$: Observable<HedgeDisplay[]>;
  tableState: { [key: string]: string | number } = {};

  filteredCommodityProfiles$: Observable<CommodityProfile[]>;

  errorMessage: string;
  isLoading = true;
  isSearching = false;

  private commodityMap: CommodityMap;
  private queryParams: Params;
  // enum allowed values
  private contractMonths = Object.keys(ContractMonth);

  @ViewChild('futuresYearMonthPicker', { static: false }) futuresYearMonthRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private changeDetector: ChangeDetectorRef,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private executionReportService: ExecutionReportService,
    private formBuilder: FormBuilder,
    private hedgeService: HedgeService,
    private ledgerHelperService: LedgerHelperService,
    private operationsDataService: OperationsDataService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    if (!this.isHedgeCreator && !this.authzService.currentUserHasRole(UserRoles.HEDGE_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to search hedges.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.prepForCommodityProfileSelection();
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
      this.queryParams = Object.assign({}, params);
      this.hedgeSearchForm.get('startDate').setValue(this.queryParams.startDate);
      this.hedgeSearchForm.get('endDate').setValue(this.queryParams.endDate);
      // form expects futuresYearMonth to be moment.Moment
      if (this.queryParams.futuresYearMonth) {
        this.hedgeSearchForm.get('futuresYearMonth').setValue(moment(this.queryParams.futuresYearMonth));
      }
      const commodityProfileValue = this.queryParams.commodityProfile ?
        { docId: this.queryParams.commodityProfile, name: '' } as CommodityProfile : undefined;
      this.hedgeSearchForm.get('commodityProfile').setValue(commodityProfileValue);

      if (Object.keys(params).length) {
        // Mart form as dirty so reset button appears
        this.hedgeSearchForm.markAsDirty();
        this.searchHedges();
      }
    }));

    // Ensure order ID is not combined with other search criteria
    this.hedgeSearchForm.valueChanges.subscribe(() => {
      const orderDocId = this.hedgeSearchForm.get('orderDocId').value;
      const commodityProfile: CommodityProfile = this.hedgeSearchForm.get('commodityProfile').value;
      const futuresYearMonth = this.hedgeSearchForm.get('futuresYearMonth').value;
      const start = this.hedgeSearchForm.get('startDate').value;
      const end = this.hedgeSearchForm.get('endDate').value;

      const hasNoValues = !orderDocId && !start && !end && !futuresYearMonth && !commodityProfile;
      const hasValueOtherThanId = commodityProfile || futuresYearMonth || start || end;

      // Disable all other fields when order ID is entered
      if (orderDocId && this.hedgeSearchForm.get('commodityProfile').enabled) {
        this.hedgeSearchForm.get('commodityProfile').disable();
        this.hedgeSearchForm.get('futuresYearMonth').disable();
        this.hedgeSearchForm.get('startDate').disable();
        this.hedgeSearchForm.get('endDate').disable();

        // Disable order ID field if a value is entered in any other field
      } else if (this.hedgeSearchForm.get('orderDocId').enabled && hasValueOtherThanId) {
        this.hedgeSearchForm.get('orderDocId').disable();
        // Re-enable all fields if a previously entered value has disabled a field(s) but is then cleared
      } else if (hasNoValues && (this.hedgeSearchForm.get('orderDocId').disabled
        || this.hedgeSearchForm.get('commodityProfile').disabled)) {
        this.hedgeSearchForm.enable();
      }
    });

    this.isLoading = false;
  }

  get isHedgeCreator() {
    return this.authzService.currentUserHasRole(UserRoles.HEDGE_CREATOR_ROLE) ||
      this.authzService.currentUserHasRole(UserRoles.HEDGED_CONTRACT_CREATOR_ROLE);
  }

  reset() {
    this.hedgeSearchForm.reset();
    // Clear out queryParams so values aren't forced back in
    this.clearQueryParams();
    this.tableState = {};
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });
    this.hedgeSearchForm.markAsPristine();
  }

  addHedge() {
    this.router.navigate(['/hedges/new']);
  }

  selectFuturesYearMonth(e: moment.Moment) {
    this.hedgeSearchForm.get('futuresYearMonth').setValue(e);
    this.hedgeSearchForm.markAsDirty();
    this.futuresYearMonthRef.close();
  }

  searchHedges(searchButtonClicked: boolean = false) {
    if (searchButtonClicked) {
      // clear initial table state if the user perform a new search
      this.clearQueryParams();
      this.tableState = {};
    } else {
      // set initial table state from query param if the user is back navigating from another page
      const sortDir = this.queryParams.sortDir;
      const sortColName = this.queryParams.sortColName;
      const pageSize = this.queryParams.pageSize;
      const pageIndex = this.queryParams.pageIndex;
      const filter = this.queryParams.filter;
      this.tableState = {
        sortDir,
        sortColName,
        pageSize,
        pageIndex,
        filter
      };
    }

    this.showHedges = false;
    this.changeDetector.detectChanges();
    let selectedClientDocId;
    let ledgerEndOfDay;
    let timezone;

    this.selectedHedges$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((doc: CommodityMap) => {
        this.commodityMap = doc;
        return this.clientSelectorService.getSelectedClient();
      }),
      switchMap((selectedClient: Client) => {
        selectedClientDocId = selectedClient.docId;
        return this.clientSettingsService.getHmsSettingsByClientDocId(selectedClientDocId);
      }),
      switchMap((clientSettings: HMSClientSettings) => {
        ledgerEndOfDay = clientSettings.ledgerEndOfDay;
        timezone = clientSettings.timezone;
        return this.getHedges(selectedClientDocId);
      }),
      switchMap((hedges: Hedge[]) => {
        // Handle edge case when there is no hedge found
        if (hedges.length === 0) {
          return of([]);
        }

        // get commodity profile name
        return combineLatest(
          hedges.map(hedge => {
            return this.commodityProfileService.getCommodityProfileByDocId(selectedClientDocId, hedge.commodityProfileDocId).pipe(
              map(profile => {
                const contractSize = this.commodityMap.commodities[profile.commodityId].contractSize;
                // Show how many contract made for display
                const quantityInContracts = hedge.quantity / contractSize;
                // Add field that contains the displayed period for filtering purpose
                const contractDisplayName = this.getPeriodFromMonthCode(hedge.futuresYearMonth);
                // Get hedge production year label from the commodity profile
                const productionYearLabel = this.ledgerHelperService.getProdYearLabelForHedge(hedge, profile, ledgerEndOfDay, timezone);
                // TODO: remove after old hedges are converted
                const type = hedge.type || HedgeType.STANDARD;
                const futuresPrice = hedge.type !== HedgeType.STANDARD ? hedge.price : undefined;
                return {
                  ...hedge, commodityProfileName: profile.name, commodityProfile: profile,
                  quantityInContracts, contractDisplayName, productionYearLabel, futuresPrice, type
                } as HedgeDisplay;
              })
            );
          })
        );
      }),
      switchMap((hedges: HedgeDisplay[]) => {
        if (hedges.length === 0) {
          return of([]);
        }
        // fetching futures price from ExecutionReports
        return combineLatest(
          hedges.map((hedge: HedgeDisplay) => {
            if (hedge.type !== HedgeType.STANDARD) {
              return of(hedge);
            }
            return this.executionReportService.getOrderFillSummaryByOrderDocId(
              hedge.commodityProfile.accountDocId, hedge.orderDocId).pipe(
                map((orderFillSummary: OrderFillSummary) => {
                  if (orderFillSummary) {
                    return {
                      ...hedge,
                      isSplitFilled: orderFillSummary.isSplitFilled,
                      futuresPrice: this.getDisplayPrice(orderFillSummary.fillPrice, hedge.commodityProfile.commodityId)
                    } as HedgeDisplay;
                  }
                  // this could happen until the order fill data is received from the CME
                  console.error(`Order fill data was not able to be retrieved for Hedge ${hedge.docId}`);
                  return hedge;
                }),
                catchError(err => {
                  console.error(`Failed to retrieve order fill data from execution reports: ${err}`);
                  return of(hedge);
                })
              );
          })
        );
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.showHedges = true;
    this.changeDetector.detectChanges();
  }

  /**
   * Displays name for commodity profile autocomplete input field
   */
  displayCommodityProfile(profile?: CommodityProfile) {
    return profile ? profile.name : '';
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatePickerMax')) {
      return 'Value Invalid';
    }
    return 'Unknown Error';
  }

  handleHedgeListChange(tableState: { [key: string]: string | number }) {
    if (tableState.sortDir && tableState.sortColName) {
      this.queryParams.sortDir = tableState.sortDir;
      this.queryParams.sortColName = tableState.sortColName;
    } else if (this.queryParams.sortDir && this.queryParams.sortColName) {
      // remove sorted direction and column in query param if there's no sort applied
      delete this.queryParams.sortDir;
      delete this.queryParams.sortColName;
    }
    if (tableState.pageSize) {
      this.queryParams.pageSize = tableState.pageSize;
    }
    if (tableState.pageIndex !== undefined) {
      this.queryParams.pageIndex = tableState.pageIndex;
    }

    if (tableState.filter) {
      this.queryParams.filter = tableState.filter;
    } else if (this.queryParams.filter) {
      // remove filter query param if there's no filter applied
      delete this.queryParams.filter;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleHedgeListError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.changeDetector.detectChanges();
  }

  private prepForCommodityProfileSelection() {
    let setInitialValue = false;
    this.filteredCommodityProfiles$ = this.hedgeSearchForm.controls.commodityProfile.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | CommodityProfile>(''),
      switchMap(searchTerm => {
        return this.clientSelectorService.getSelectedClient().pipe(
          switchMap((selectedClient: Client) => {
            return this.commodityProfileService.getAllCommodityProfilesByClientDocId(selectedClient.docId).pipe(
              map((profiles: CommodityProfile[]) => {
                // populate searchTerm on initial load in case there is a mock object present
                if (!searchTerm) {
                  searchTerm = this.hedgeSearchForm.get('commodityProfile').value;
                }
                // Return all commodity profiles when a profile has already been selected to avoid the user needing to clear the field
                if (typeof searchTerm !== 'string') {
                  // Replace mock commodityProfile from queryParam if necessary
                  if (searchTerm && !(searchTerm as CommodityProfile).name && !setInitialValue) {
                    this.hedgeSearchForm.get('commodityProfile').setValue(
                      profiles.find(profile => profile.docId === (searchTerm as CommodityProfile).docId));
                    setInitialValue = true;
                  }
                  return profiles;
                }
                return profiles.filter(profile => profile.name.toLowerCase().includes((searchTerm as string).toLowerCase()));
              }),
              shareReplay({ bufferSize: 1, refCount: true }),
              catchError(err => {
                this.isLoading = false;
                this.errorMessage = 'Error retrieving client commodity profiles; please try again later';
                console.error(`Error retrieving client commodity profiles: ${err}`);
                return of([]);
              })
            );
          }),
        );
      }),
    );
  }

  private getHedges(clientDocId: string): Observable<Hedge[]> {
    const orderDocId = this.hedgeSearchForm.get('orderDocId').value;
    const commodityProfile: CommodityProfile = this.hedgeSearchForm.get('commodityProfile').value;
    const futuresYearMonth = this.hedgeSearchForm.get('futuresYearMonth').value ?
      this.translateToContractMonth(this.hedgeSearchForm.get('futuresYearMonth').value) : undefined;
    let startDate = this.hedgeSearchForm.get('startDate').value;
    let endDate = this.hedgeSearchForm.get('endDate').value;
    const hasDate = startDate || endDate;

    // Set default date or the date entered by the user
    if (startDate) {
      startDate = new Date(startDate).toISOString();
      this.queryParams.startDate = startDate;
    } else {
      startDate = DEFAULT_START_DATE;
    }

    if (endDate) {
      endDate = new Date(endDate);
      // Allow contract made on this day before midnight
      endDate.setHours(23, 59, 59, 999);
      endDate = endDate.toISOString();
      this.queryParams.endDate = endDate;
    } else {
      endDate = DEFAULT_END_DATE;
    }

    if (commodityProfile) {
      this.queryParams.commodityProfile = commodityProfile.docId;
    }

    if (futuresYearMonth) {
      // set to actual value to allow for
      this.queryParams.futuresYearMonth = this.hedgeSearchForm.get('futuresYearMonth').value.toISOString();
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });

    if (orderDocId) {
      return this.hedgeService.getHedgesByOrderDocId(clientDocId, orderDocId);
      // 3 search options
    } else if (commodityProfile && futuresYearMonth && hasDate) {
      return this.hedgeService.getHedgesByDateRangeAndFuturesMonthAndCommodityProfileDocId(clientDocId, startDate, endDate,
        futuresYearMonth, commodityProfile.docId);
      // 2 search options
    } else if (futuresYearMonth && hasDate) {
      return this.hedgeService.getHedgesByDateRangeAndFuturesMonth(clientDocId, startDate, endDate, futuresYearMonth);
    } else if (futuresYearMonth && commodityProfile) {
      return this.hedgeService.getHedgesByFuturesMonthAndCommodityProfileDocId(clientDocId, futuresYearMonth, commodityProfile.docId);
    } else if (hasDate && commodityProfile) {
      return this.hedgeService.getHedgesByDateRangeAndCommodityProfileDocId(clientDocId, startDate, endDate, commodityProfile.docId);
      // 1 search options
    } else if (hasDate) {
      return this.hedgeService.getHedgesByDateRange(clientDocId, startDate, endDate);
    } else if (futuresYearMonth) {
      return this.hedgeService.getHedgesByFuturesMonth(clientDocId, futuresYearMonth);
    } else if (commodityProfile) {
      return this.hedgeService.getHedgesByCommodityProfileDocId(clientDocId, commodityProfile.docId);
    } else {
      return this.hedgeService.getAllHedgesByClientDocId(clientDocId);
    }

  }

  // This returns the month year formatted period from a code
  private getPeriodFromMonthCode(value: string): string {
    const year = value.substring(0, 2);
    const monthCode = value.substring(2);
    const idxMonth = this.contractMonths.indexOf(monthCode);

    return moment.monthsShort()[idxMonth] + ' ' + year;
  }

  private translateToContractMonth(selectedMonth: moment.Moment): string {
    return selectedMonth.format(YEAR_FORMAT) + ContractMonth[this.contractMonths[selectedMonth.month()]];
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private getDisplayPrice(price: number, symbol: string) {
    const priceDivisor = this.commodityMap.commodities[symbol] ? this.commodityMap.commodities[symbol].marketDataDivisor : 1;
    return price / priceDivisor;
  }

}
