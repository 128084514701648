import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { UniqueKeyValidator } from '../../utilities/validators/unique-key-validator';

@Component({
  selector: 'hms-accounting-setting',
  templateUrl: './accounting-setting.component.html',
  styleUrls: ['./accounting-setting.component.scss'],
  providers: []
})
export class AccountingSettingComponent implements OnChanges {

  @Input() accountingSetting: FormGroup;
  @Input() editMode: boolean;
  @Input() existingAccountingSettingKeys: string[];
  @Output() accountingSettingUpdated = new EventEmitter<FormGroup>();
  @Output() accountingSettingRemoved = new EventEmitter<FormGroup>();
  @Input() activeTabName: string;
  @Output() accountingError = new EventEmitter<boolean>();

  accountingSettingForm: FormGroup = this.formBuilder.group({
    settingKey: ['', [Validators.required]],
    settingValue: ['', [Validators.required]],
  });

  constructor(
    private formBuilder: FormBuilder
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['editMode']) {
      if (this.editMode) {
        this.accountingSettingForm.enable();
        this.setupAccountingSettingForm();
      } else {
        this.accountingSettingForm.disable();
        this.setupAccountingSettingForm();
      }
    } else if (changes['accountingSetting'] && !this.editMode) {
      this.setupAccountingSettingForm();
    }
  }

  onAccountingSettingUpdated() {
    this.accountingSettingForm.markAsPristine();
    const settingValueField = this.accountingSettingForm.get('settingValue');
    // ensure appropriate type of newly added boolean setting
    if (typeof(settingValueField.value) === 'string' && ['true', 'false'].includes(settingValueField.value.toLowerCase())) {
      settingValueField.setValue(settingValueField.value.toLowerCase() === 'true');
    }
    this.accountingSettingUpdated.emit(this.accountingSettingForm);
  }

  onAccountingSettingRemoved() {
    this.accountingSettingRemoved.emit(this.accountingSettingForm);
  }

  getErrorMessage(control: FormControl) {
    this.activeTabName === 'Accounting' ? this.accountingError.emit(false) : this.accountingError.emit(true);
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('uniqueKey')) {
      return 'Setting already exists';
    }
    return 'Unknown error';
  }

  get isBoolean() {
    return this.accountingSetting && [true, false].includes(this.accountingSetting.get('settingValue').value);
  }

  setupAccountingSettingForm() {
    if (this.accountingSetting) {
      this.accountingSettingForm = this.accountingSetting;
      const settingValueField = this.accountingSettingForm.get('settingValue');
      if (this.isBoolean) {
        settingValueField.clearValidators();
        // force newly created false boolean to be unchecked
        if (settingValueField.value === 'false') {
          settingValueField.setValue(false);
        }
      }
    } else {
      const settingKeyField = this.accountingSettingForm.get('settingKey');
      settingKeyField.setValue('');
      settingKeyField.setValidators([Validators.required, UniqueKeyValidator.uniqueKey(this.existingAccountingSettingKeys)]);
      this.accountingSettingForm.get('settingValue').setValue('');
      this.accountingSettingForm.markAsUntouched();
      this.accountingSettingForm.markAsPristine();
    }
  }
}
