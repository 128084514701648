import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { LocationService } from '@advance-trading/angular-ops-data';
import { NgxMatIntlTelInputComponent } from '@advance-trading/ngx-mat-intl-tel-input';
import { Location, PhoneNumber } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { UserRoles } from '../../utilities/user-roles';
import { UniqueKeyValidator } from '../../utilities/validators/unique-key-validator';

@Component({
  selector: 'hms-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.scss']
})
export class LocationDetailComponent implements OnInit {
  errorMessage: string;
  editMode = false;
  updateComplete = true;
  enableAddress = false;
  isLoading = false;

  location$: Observable<Location>;
  location: Location;

  @ViewChild('phoneNumber', { static: false }) phoneNumber: NgxMatIntlTelInputComponent;

  locationForm: FormGroup = this.formBuilder.group({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(100)]),
    isActive: new FormControl(),
    address: new FormGroup({
      street1: new FormControl('', [
        Validators.required,
        Validators.maxLength(200)
      ]),
      street2: new FormControl('', [
        Validators.maxLength(200)
      ]),
      city: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      region: new FormControl('', [
        Validators.required
      ]),
      postalCode: new FormControl('', [
        Validators.required
      ]),
      country: new FormControl('', [
        Validators.required
      ])
    }),
    accountingSettings: new FormArray([]),
    newSettingKey: new FormControl('', [Validators.maxLength(100)]),
    newSettingValue: new FormControl('', [Validators.maxLength(500)]),
  });

  phoneNumbers: PhoneNumber[] = [];

  private selectedClientDocId = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.locationForm.disable();
    this.locationForm.get('address').disable();

    this.location$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap(selectedClient => {
        this.selectedClientDocId = selectedClient.docId;
        return this.activatedRoute.paramMap;
      }),
      switchMap((paramMap: ParamMap) => {
        return this.locationService.getLocationByDocId(this.selectedClientDocId, paramMap.get('docId'));
      }),
      tap((loc: Location) => {
        this.location = loc;
        this.setEditMode(false);
        this.isLoading = false;
        return loc;
      })
    );
  }

  get isLocationAdmin() {
    return this.authzService.currentUserHasRole(UserRoles.LOCATION_ADMIN_ROLE);
  }

  setEditMode(mode) {
    this.editMode = mode;
    if (this.editMode) {
      this.locationForm.enable();
      if (!this.enableAddress) {
        this.locationForm.get('address').disable();
      }
    } else { // Abandon an update
      this.locationForm.disable();
      this.setupLocationForm();
    }
  }

  async saveForm() {
    this.updateComplete = false;

    const formValues = this.locationForm.getRawValue();

    this.location.name = formValues.name;
    this.location.isActive = formValues.isActive;
    if (this.enableAddress) {
      this.location.address = formValues.address;
    }
    this.location.phoneNumbers = this.phoneNumbers;

    if (!this.enableAddress) {
      delete this.location.address;
    }
    this.location.accountingSystemSettings = {};
    if (this.locationForm.get('newSettingKey').value) {
      this.addAccountingSettingForm();
    }
    const settingsFormArray = this.locationForm.get('accountingSettings') as FormArray;
    settingsFormArray.controls.forEach((accountingSettingForm: FormGroup) => {
      let settingValue = accountingSettingForm.get('settingValue').value;
      if (this.appearsBoolean(settingValue)) {
        settingValue = this.getValueAsBoolean(settingValue);
      }
      this.location.accountingSystemSettings[accountingSettingForm.get('settingKey').value] = settingValue;
    });

    this.locationService.updateLocation(this.selectedClientDocId, this.location)
      .then(() => {
        this.updateComplete = true;
        console.log('Location successfully updated');
        this.openSnackBar('Location successfully updated', 'DISMISS', true);
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`Location update failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Location update failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  private setupLocationForm() {
    // Check necessary to prevent any race condition in our test suite
    if (this.phoneNumber) {
      this.phoneNumber.phoneNumber = '';
    }

    if (this.location.address) {
      this.enableAddress = true;
    }

    this.locationForm.patchValue(this.location);

    // Set the street2 to empty string if it is null or undefined
    if (!this.locationForm.get('address').get('street2').value) {
      this.locationForm.get('address').get('street2').setValue('');
    }

    this.phoneNumbers = Array.from(this.location.phoneNumbers);

    const settingsFormArray = this.locationForm.get('accountingSettings') as FormArray;
    settingsFormArray.clear();
    Object.keys(this.location.accountingSystemSettings).forEach(accountingSystemSettingKey => {
      settingsFormArray.push(
        this.getAccountingSettingForm(accountingSystemSettingKey, this.location.accountingSystemSettings[accountingSystemSettingKey]));
    });

    this.locationForm.get('newSettingValue').setValue('');
    const keyField = this.locationForm.get('newSettingKey');
    keyField.setValue('');
    keyField.setValidators([Validators.maxLength(100), UniqueKeyValidator.uniqueKey(this.existingAccountingSettingsKeys)]);
    keyField.updateValueAndValidity();

    this.locationForm.markAsPristine();
  }

  showAddress() {
    this.enableAddress = true;
    this.locationForm.get('address').enable();
  }

  hideAddress() {
    this.enableAddress = false;
    this.locationForm.get('address').disable();
  }

  clearAddress() {
    this.hideAddress();
    this.locationForm.markAsDirty();
  }

  onPhoneNumberCreated(phoneNumber: PhoneNumber) {
    this.phoneNumbers.push(phoneNumber);
    this.locationForm.markAsDirty();
  }

  onPhoneNumberRemoved(index?: number) {
    this.phoneNumbers.splice(index, 1);
    this.locationForm.markAsDirty();
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('maxlength')) {
      return 'Value cannot exceed ' + control.errors['maxlength'].requiredLength + ' characters';
    } else if (control.hasError('uniqueKey')) {
      return 'Setting already exists';
    }
    return 'Unknown error';
  }

  appearsBoolean(value: any) {
    return (typeof (value) === 'string' && ['true', 'false'].includes(value.toLowerCase())) || typeof (value) === 'boolean';
  }

  addAccountingSettingForm() {
    const settingsFormArray = this.locationForm.get('accountingSettings') as FormArray;
    const keyField = this.locationForm.get('newSettingKey');
    const valueField = this.locationForm.get('newSettingValue');
    settingsFormArray.push(this.getAccountingSettingForm(keyField.value, valueField.value));
    this.locationForm.get('accountingSettings').markAsDirty();
    keyField.setValue('');
    keyField.setValidators([Validators.maxLength(100), UniqueKeyValidator.uniqueKey(this.existingAccountingSettingsKeys)]);
    valueField.setValue('');
  }

  checkForBoolean(settingKey, settingValue) {
    if (this.appearsBoolean(settingValue)) {
      const updatedForm = this.existingAccountingSettings.find(
        (accountingSetting: FormGroup) => accountingSetting.get('settingKey').value === settingKey);
      updatedForm.get('settingValue').setValue(this.getValueAsBoolean(settingValue));
    }
  }

  listenForEnter(event: KeyboardEvent, currentContents: string, index: number) {
    if (event.key === 'Enter') {
      const settingsFormArray = this.locationForm.get('accountingSettings') as FormArray;
      const settingValueField = settingsFormArray.controls[index].get('settingValue');
      if (settingValueField.value !== currentContents) {
        settingValueField.setValue(currentContents);
        settingValueField.markAsDirty();
        settingValueField.updateValueAndValidity();
        if (this.locationForm.valid) {
          this.saveForm();
        }
      }
    }
  }

  removeAccountingSettingForm(index: number) {
    const settingsFormArray = this.locationForm.get('accountingSettings') as FormArray;
    settingsFormArray.removeAt(index);
    this.locationForm.get('accountingSettings').markAsDirty();
    this.locationForm.get('newSettingKey').setValidators([
      Validators.maxLength(100), UniqueKeyValidator.uniqueKey(this.existingAccountingSettingsKeys)]);
    this.locationForm.get('newSettingKey').updateValueAndValidity();
  }

  get addAccountingFormDisabled() {
    const keyField = this.locationForm.get('newSettingKey');
    const valueField = this.locationForm.get('newSettingValue');
    return !keyField.value || !keyField.valid || !valueField.value || !valueField.valid;
  }

  get existingAccountingSettings() {
    const settingsFormArray = this.locationForm.get('accountingSettings') as FormArray;
    return settingsFormArray.controls as FormGroup[];
  }

  get existingAccountingSettingsKeys() {
    return this.existingAccountingSettings.map(accountingSettingsForm => accountingSettingsForm.get('settingKey').value);
  }

  // return XOR comparison on values in new accounting setting fields
  get partialNewSettingEntered() {
    const settingKey = this.locationForm.get('newSettingKey').value;
    const settingValue = this.locationForm.get('newSettingValue').value;
    return (settingKey === '' && settingValue !== '') || (settingValue === '' && settingKey !== '');
  }

  private getAccountingSettingForm(key: string, value: any) {
    const accountingSettingForm = this.formBuilder.group({ settingKey: [key, [Validators.required, Validators.maxLength(100)]] });
    if (this.appearsBoolean(value)) {
      accountingSettingForm.addControl('settingValue', new FormControl(this.getValueAsBoolean(value)));
    } else {
      accountingSettingForm.addControl('settingValue',
        new FormControl(value, { updateOn: 'blur', validators: [Validators.maxLength(500)] }));
    }
    return accountingSettingForm;
  }

  // Force case-insensitive conversion of 'true' and 'false' to corresponding boolean values or simply return boolean
  private getValueAsBoolean(value: string | boolean): boolean {
    if (typeof (value) === 'boolean') {
      return value;
    }
    return typeof (value) === 'string' && value.toLowerCase() === 'true';
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

}
