import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, tap, switchMap } from 'rxjs/operators';
import * as moment from 'moment';

import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ConfirmationDialogService, ObservableDataSource } from '@advance-trading/angular-common-services';
import { CommodityProfileService, ExecutionReportService, OperationsDataService, OrderFill, OrderService, UserService } from '@advance-trading/angular-ops-data';
import {
  AdHocOrder,
  AdHocOrderStatus,
  AlertStatus,
  Client,
  CommodityMap,
  CommodityProfile,
  ContractMonth,
  HMSClientSettings,
  Order,
  OrderType,
  SecurityType,
  Side,
  TimeInForce,
  User
} from '@advance-trading/ops-data-lib';

import { AdHocOrderService } from '../../service/ad-hoc-order.service';
import { AppAlertService } from '../../service/app-alert.service';
import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { QSTService } from '../../service/qst.service';
import { OrderFillDisplay } from '../../utilities/order-fill-display';
import { UserRoles } from '../../utilities/user-roles';

import { AdHocOrderDisplay } from '../ad-hoc-order-display';
import { FirstFuturesMonthErrorMatcher, FuturesMonthErrorMatcher, FuturesValidators, SecondFuturesMonthErrorMatcher } from '../../utilities/validators/futures.validator';

const DATE_FORMAT = 'YYYY-MM-DD';
const YEAR_FORMAT = 'YY';
const PRICE_REGEX = /^-?(\d+|\d*\.\d{1,4}|\d+\.\d{0,4})$/;
const CONTRACTS_REGEX = /^(?=.*[1-9])\d*$/;

@Component({
  selector: 'hms-ad-hoc-order-detail',
  templateUrl: './ad-hoc-order-detail.component.html',
  styleUrls: ['./ad-hoc-order-detail.component.scss']
})
export class AdHocOrderDetailComponent implements OnDestroy, OnInit {

  @ViewChild('contractYearMonthPicker', { static: false }) contractYearMonthRef;
  @ViewChild('firstFuturesYearMonthPicker', { static: false }) firstFuturesYearMonthPickerRef;
  @ViewChild('secondFuturesYearMonthPicker', { static: false }) secondFuturesYearMonthPickerRef;
  @ViewChild('contractYearMonthPickerInput', { static: false }) contractYearMonthPickerInput: ElementRef;
  @ViewChild('contractYearMonthPickerToggle', { static: false, read: ElementRef }) contractYearMonthPickerToggle: ElementRef;
  @ViewChild('expirationDatePickerToggle', { static: false, read: ElementRef }) expirationDatePickerToggle: ElementRef;
  @ViewChild('firstFuturesYearMonthPickerToggle', { static: false, read: ElementRef }) firstFuturesYearMonthPickerToggle: ElementRef;
  @ViewChild('secondFuturesYearMonthPickerToggle', { static: false, read: ElementRef }) secondFuturesYearMonthPickerToggle: ElementRef;

  adHocOrderForm: FormGroup = this.formBuilder.group({
    comments: ['', Validators.maxLength(400)],
    orderDocId: [ '', [ Validators.required ] ],
    type: [ '', Validators.required ],
    quantity: [ '', [ Validators.required, Validators.maxLength(3), Validators.pattern(CONTRACTS_REGEX) ] ],
    expirationDate: [ '' ],
    price: [ '', [ Validators.required, Validators.pattern(PRICE_REGEX) ] ],
    futuresYearMonth: [ '', [ Validators.required ] ],
    firstFuturesYearMonth: [ '', [ Validators.required ] ],
    firstCommodity: [ '', [ Validators.required ] ],
    secondFuturesYearMonth: [ '', [ Validators.required ] ],
    secondCommodity: [ '', [ Validators.required ] ]
  });

  orderForm: FormGroup = this.formBuilder.group({
    orderDocId: ['', [Validators.required]]
  });

  commodityMap: CommodityMap;

  adHocOrder: AdHocOrder;
  adHocOrder$: Observable<AdHocOrderDisplay>;

  fillDataSource = new ObservableDataSource<OrderFillDisplay>();
  fillColumnsToDisplay = [];
  hasLeg = false;

  errorMessage: string;
  isLoading = true;
  editMode = false;
  updateComplete = true;
  orderActionComplete = true;
  qstWorking = false;
  creatingOrder = false;
  cancellingOrder = false;
  recreatingOrder = false;
  updatingOrder = false;
  orderFormInvalid = false;

  orderTypes = [ OrderType.MARKET, OrderType.LIMIT ];

  commodities: string[] = [];

  // error state matcher to check futuresYearMonth valid state
  futuresMonthErrorMatcher = new FuturesMonthErrorMatcher();
  firstFuturesMonthErrorMatcher = new FirstFuturesMonthErrorMatcher();
  secondFuturesMonthErrorMatcher = new SecondFuturesMonthErrorMatcher();

  // don't allow futures month in the past. Futures datepickers default to first of month.
  minDate = moment().startOf('month');
  currentDate = moment().format();

  private selectedClientDocId: string;
  private loggedInUser: User;
  private commodityProfile: CommodityProfile;
  private existingOrder: Order;

  // Store the clientOrderId returned from QST for use in matching with qstService.orderEntry$
  private newOrderClientId = '';
  private exchangeOrderIdSub: Subscription;

  private alertDocId: string;

  private contractMonths = Object.keys(ContractMonth);

  private futuresMonths: { [ key: string ]: number[] } = {};

  // valid contract months for mat-datepicker filter
  private validContractMonths: number[];

  constructor(
    private activatedRoute: ActivatedRoute,
    private adHocOrderService: AdHocOrderService,
    private appAlertService: AppAlertService,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private dialogService: ConfirmationDialogService,
    private executionReportService: ExecutionReportService,
    private formBuilder: FormBuilder,
    private operationsDataService: OperationsDataService,
    private orderService: OrderService,
    public qstService: QSTService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (!this.isAdHocOrderAdmin && !this.authzService.currentUserHasRole(UserRoles.AD_HOC_ORDER_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to view ad hoc orders.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.adHocOrder$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((doc: CommodityMap) => {
        this.commodityMap = doc;
        Object.keys(doc.commodities).forEach((commodityId: string) => {
          this.futuresMonths[ commodityId ] = doc.commodities[ commodityId ].contractMonths;
        });

        return this.loadUserAndClientSettings();
      }),
      switchMap((clientSettings: HMSClientSettings) => {
        this.commodities = clientSettings.commodities;
        return this.activatedRoute.queryParamMap;
      }),
      switchMap((queryParamMap: ParamMap) => {
        this.alertDocId = queryParamMap.get('alertId');
        return this.activatedRoute.paramMap;
      }),
      switchMap((paramMap: ParamMap) => {
        return this.adHocOrderService.getAdHocOrderByDocId(this.selectedClientDocId, paramMap.get('docId'));
      }),
      switchMap((adHocOrder: AdHocOrder) => {
        this.adHocOrder = adHocOrder;
        return this.commodityProfileService.getCommodityProfileByDocId(this.selectedClientDocId, adHocOrder.commodityProfileDocId).pipe(
          map((profile: CommodityProfile) => {
            this.commodityProfile = profile;
            // set ad hoc order form group validator
            this.adHocOrderForm.setValidators([
              FuturesValidators.futurePeriodValidator(this.futuresMonths, this.commodityProfile),
              FuturesValidators.firstFuturePeriodValidator(this.futuresMonths),
              FuturesValidators.secondFuturePeriodValidator(this.futuresMonths)
            ]);
            return { ...adHocOrder, commodityProfileName: profile.name } as AdHocOrderDisplay;
          })
        );
      }),
      switchMap((adHocOrderDisplay: AdHocOrderDisplay) => {
        if (this.adHocOrder.orderDocId) {
          return this.orderService.getOrderByDocId(this.commodityProfile.accountDocId, this.adHocOrder.orderDocId).pipe(
            map((order: Order) => {
              this.existingOrder = order;
              return adHocOrderDisplay;
            }),
            tap(() => {
              this.getOrderFills();
            }),
            catchError(err => {
              console.error(`Error retrieving Order associated with AdHocOrder: ${err}`);
              return of(adHocOrderDisplay);
            })
          );
        } else {
          return of(adHocOrderDisplay);
        }
      }),
      tap(() => {
        this.isLoading = false;
        this.handlingPendingExchangeOrderAction();
      }),
      catchError(err => {
        this.isLoading = false;
        this.errorMessage = 'Error retrieving ad hoc order details; please try again later';
        console.error(`Error retrieving ad hoc order details: ${err}`);
        return of(undefined);
      })
    );
  }

  ngOnDestroy() {
    if (this.exchangeOrderIdSub) {
      this.exchangeOrderIdSub.unsubscribe();
    }
  }

  setEditMode(editable: boolean) {
    this.editMode = editable;
    if (editable) {
      this.prepAdHocOrderForm();
    }
  }

  reset() {
    this.adHocOrderForm.markAsPristine();
    this.setEditMode(false);
  }

  /**
   * Used for initial adding order ID to adHocOrder - only looks at orderFrom.orderDocId
   */
  setAdHocOrderExchangeOrder() {
    if (this.orderForm.invalid) {
      return false;
    }
    this.handlePostExchangeStatus(this.orderForm.get('orderDocId').value);
  }

  get isOrderFormValid() {
    this.orderFormInvalid = this.orderForm.invalid;
    return this.orderFormInvalid;
  }

  createExchangeOrder() {
    if (this.qstService.enabled) {
      const accountNumber = `${this.commodityProfile.officeCode}${this.commodityProfile.accountNumber}`;

      let expirationDate;

      if (this.adHocOrder.timeInForce === TimeInForce.GTD) {
        expirationDate = moment(this.adHocOrder.expirationDate).format('MM/DD/YYYY');
      }

      let contract = '';
      let commodityId = this.commodityProfile.commodityId;
      if (this.adHocOrder.securityType === SecurityType.FUTURE) {
        const contractMonth = this.adHocOrder.contractYearMonth.substring(2);
        const contractYear = this.adHocOrder.contractYearMonth.substring(0, 2);
        contract = `${commodityId}${contractMonth}${contractYear}`;
      } else if (this.adHocOrder.securityType === SecurityType.FUTURE_SPREAD) {
        contract = this.adHocOrder.legs.map(leg => {
          commodityId = leg.commodityId; // I think we will be ok with taking this from the last leg (for now)
          const contractMonth = leg.contractYearMonth.substring(2);
          const contractYear = leg.contractYearMonth.substring(0, 2);
          return `${leg.commodityId}${contractMonth}${contractYear}`;
        }).join(':');
      } else {
        this.openSnackBar('Unsupported Security Type on Ad Hoc Order', 'DISMISS', false);
        return;
      }

      this.qstWorking = true;
      const commodity = this.commodityMap.commodities[commodityId];
      this.qstService.createOrder(commodity, contract, this.adHocOrder.quantity, accountNumber, this.adHocOrder.side,
        this.adHocOrder.type, this.adHocOrder.price, undefined, this.adHocOrder.timeInForce, expirationDate)
        .then(clientOrderId => {
          this.newOrderClientId = clientOrderId;
        })
        .catch(err => {
          console.log(`QST createOrder error response in ad hoc order detail: ${JSON.stringify(err)}`);
          this.qstWorking = false;
          this.openSnackBar('Error from QST: ' + err, 'DISMISS', false);
        });
    }
  }

  cancelExchangeOrder() {
    if (this.qstService.enabled) {
      this.qstService.cancelOrder(this.existingOrder.accountNumber, this.existingOrder.clientOrderId)
        .then(response => {
          // Cancel needs to explicitly handle setting qstWorking to false because the order entry callback doesn't include xid
          this.qstWorking = false;
          this.setAdHocOrderCancelled();
        })
        .catch(err => {
          console.log(`QST cancelOrder error response in ad hoc order detail: ${JSON.stringify(err)}`);
          this.qstWorking = false;
          this.openSnackBar('Error from QST: ' + err, 'DISMISS', false);
        });
    } else {
      // confirm the user has completed the exchange order cancellation
      this.dialogService.open(
        {
          title: 'AST Not Enabled - Cancel Order',
          message: `Proceed to trading platform to cancel order. Is order ${this.adHocOrder.orderDocId} cancelled?`,
          btnColor: 'accent'
        },
        'auto',
        '400px'
      );

      this.dialogService.afterClosed().subscribe(confirm => {
        if (!confirm) {
          return;
        }
        this.setAdHocOrderCancelled();
      });
    }
  }
  /**
   * Submit only the adHocOrderForm - adHocOrderForm.orderId field and/or comments field if applicable
   */
  submitUpdate() {
    if (this.orderIdEditable) {
      this.adHocOrder.orderDocId = this.adHocOrderForm.get('orderDocId').value;
    }
    if (this.commentsEditable || this.limitAdHocOrderEditable) {
      const comments = this.adHocOrderForm.get('comments').value;
      if (comments) {
        this.adHocOrder.comments = comments;
      } else if (this.adHocOrder.comments) {
        delete this.adHocOrder.comments;
      }
    }
    if (this.limitAdHocOrderEditable) {
      this.adHocOrder.status = this.getUpdatedAdHocOrderStatus(this.adHocOrder, this.adHocOrderForm);
      this.adHocOrder.quantity = parseInt(this.adHocOrderForm.get('quantity').value, 10);
      this.adHocOrder.type = this.adHocOrderForm.get('type').value;
      if (this.adHocOrder.type === OrderType.MARKET) {
        // strip expiration date and price, reset timeInForce if order changed to market
        if (this.adHocOrder.expirationDate) {
          delete this.adHocOrder.expirationDate;
        }
        if (this.adHocOrder.price) {
          delete this.adHocOrder.price;
        }
        this.adHocOrder.timeInForce = TimeInForce.DAY;
      } else {
        if (this.adHocOrderForm.get('expirationDate').value) {
          this.adHocOrder.expirationDate = moment(this.adHocOrderForm.get('expirationDate').value).format(DATE_FORMAT);
          this.adHocOrder.timeInForce = TimeInForce.GTD
        } else if (this.adHocOrder.expirationDate) {
          delete this.adHocOrder.expirationDate;
          this.adHocOrder.timeInForce = TimeInForce.GTC;
        }
        if (Number.isFinite(parseFloat(this.adHocOrderForm.get('price').value))) {
          this.adHocOrder.price = parseFloat(this.adHocOrderForm.get('price').value);
        } else if (Number.isFinite(this.adHocOrder.price)) {
          delete this.adHocOrder.price;
        }
      }
      if (this.adHocOrder.securityType === SecurityType.FUTURE_SPREAD) {
        // currently only supporting two-legged spreads since those are the only ones that can be in WORKING status
        this.adHocOrder.legs[ 0 ].commodityId = this.adHocOrderForm.get('firstCommodity').value;
        this.adHocOrder.legs[ 0 ].contractYearMonth = this.translateMomentToContractMonth(moment(this.adHocOrderForm.get('firstFuturesYearMonth').value));
        this.adHocOrder.legs[ 1 ].commodityId = this.adHocOrderForm.get('secondCommodity').value;
        this.adHocOrder.legs[ 1 ].contractYearMonth = this.translateMomentToContractMonth(moment(this.adHocOrderForm.get('secondFuturesYearMonth').value));
      } else {
        this.adHocOrder.contractYearMonth = this.translateMomentToContractMonth(moment(this.adHocOrderForm.get('futuresYearMonth').value));
      }
    }
    this.updateAdHocOrder();
  }

  cancelLimitAdHocOrder() {
    // confirm if the user wants to cancel ad hoc order
    this.dialogService.open({
      title: 'Cancel Ad Hoc Order',
      message: 'This action cannot be undone. Do you want to cancel this ad hoc order?',
      btnColor: 'accent'
    },
      'auto',
      '400px'
    );

    this.dialogService.afterClosed().subscribe(confirm => {
      if (!confirm) {
        return;
      }
      this.updateComplete = false;
      const newStatus = this.adHocOrder.orderDocId? AdHocOrderStatus.PENDING_ORDER_CANCEL : AdHocOrderStatus.CANCELLED
      this.adHocOrderService.updateAdHocOrderStatus(this.selectedClientDocId, this.adHocOrder.docId, newStatus)
        .then(() => {
          this.updateComplete = true;
          console.log('Ad hoc order successfully cancelled');
          this.openSnackBar('Ad hoc order successfully cancelled', 'DISMISS', true);
          this.router.navigate(['../'], { relativeTo: this.activatedRoute });
        })
        .then(() => {
          if (this.alertDocId) {
            return this.appAlertService.updateAppAlertStatus(this.alertDocId, AlertStatus.COMPLETE)
              .catch(err => {
                console.error(`App alert update failed: ${err}`);
                return Promise.resolve();
              });
          }
          return Promise.resolve();
        })
        .catch(err => {
          this.updateComplete = true;
          console.error(`Ad hoc order cancellation failed: ${err}`);
          const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
          this.openSnackBar(`Ad hoc order cancellation failed: ${errorMsg}`, 'DISMISS', false);
        });
    });
  }

  getDisplayPrice(price: number, symbol: string) {
    const priceDivisor = this.commodityMap.commodities[symbol] ? this.commodityMap.commodities[symbol].marketDataDivisor : 1;
    return price / priceDivisor;
  }

  getContractLabel(quantity: number) {
    return quantity === 1 ? 'contract' : 'contracts';
  }

  getCommodity() {
    const name = this.commodityMap.commodities[this.commodityProfile.commodityId].name;
    return `${name} (${this.commodityProfile.commodityId})`;
  }

  getOrderLink() {
    return this.router.createUrlTree(['/accounts', this.commodityProfile.accountDocId, 'orders', this.adHocOrder.orderDocId]).toString();
  }

  clearField(fieldName: string) {
    const field = this.adHocOrderForm.get(fieldName);
    field.setValue('');
    field.markAsDirty();
  }

  validContractMonthFilter = (currentMoment: moment.Moment | null): boolean => {
    if (currentMoment) {
      const month = currentMoment.month();
      if (this.commodityProfile) {
        // get valid contract month for the current commodity profile
        this.validContractMonths = this.futuresMonths[ this.commodityProfile.commodityId ];
        return this.validContractMonths.includes(month);
      }

      // return true for any month above or the same as current month
      return true;
    } else {
      return true;
    }
  }

  validFirstContractMonthFilter = (currentMoment: moment.Moment | null): boolean => {
    if (currentMoment) {
      const month = currentMoment.month();
      const commodity = this.adHocOrderForm.get('firstCommodity').value;

      if (commodity) {
        // get valid contract month for the current commodity
        this.validContractMonths = this.futuresMonths[ commodity ];
        return this.validContractMonths.includes(month);
      }

      // return true for any month above or the same as current month
      return true;
    } else {
      return true;
    }

  }

  validSecondContractMonthFilter = (currentMoment: moment.Moment | null): boolean => {
    if (currentMoment) {
      const month = currentMoment.month();
      const commodity = this.adHocOrderForm.get('secondCommodity').value;

      if (commodity) {
        // get valid contract month for the current commodity
        this.validContractMonths = this.futuresMonths[ commodity ];
        return this.validContractMonths.includes(month);
      }

      // return true for any month above or the same as current month
      return true;
    } else {
      return true;
    }
  }

  // don't allow weekend days to be selected for expiration date
  expDateFilter = (currentDate: Date): boolean => {
    const dayOfTheWeek = moment(currentDate).weekday();
    return dayOfTheWeek !== 0 && dayOfTheWeek !== 6;
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value Required';
    } else if (control.hasError('maxlength')) {
      return 'Value cannot exceed ' + control.errors[ 'maxlength' ].requiredLength + ' characters';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    } else if (control.hasError('invalidFuturesMonth')) {
      return 'Value invalid for profile';
    } else if (control.hasError('invalidFirstFuturesMonth')) {
      return 'Value must precede second contract month';
    } else if (control.hasError('invalidSecondFuturesMonth')) {
      return 'Value must exceed first contract month';
    } else if (control.hasError('invalidCommodityFirstFuturesMonth')
      || control.hasError('invalidCommoditySecondFuturesMonth')) {
      return 'Value invalid for commodity';
    } else if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('pattern')) {
      return 'Value Invalid';
    } else if (control.hasError('invalidNonZeroPrice')) {
      return 'Value Cannot Be Zero';
    } else if (control.hasError('max')) {
      return 'Value cannot exceed ' + control.errors[ 'max' ].max.toLocaleString();
    }
    return 'Unknown Error';
  }

  selectContractYearMonth(e: moment.Moment, formControlName: string) {

    this.adHocOrderForm.markAsDirty();

    if (formControlName === 'futuresYearMonth') {
      this.adHocOrderForm.get('futuresYearMonth').setValue(e);
      this.contractYearMonthRef.close();
    } else if (formControlName === 'firstFuturesYearMonth') {
      this.adHocOrderForm.get('firstFuturesYearMonth').setValue(e);
      this.firstFuturesYearMonthPickerRef.close();
    } else if (formControlName === 'secondFuturesYearMonth') {
      this.adHocOrderForm.get('secondFuturesYearMonth').setValue(e);
      this.secondFuturesYearMonthPickerRef.close();
    }

  }

  onCloseDatePicker(datePickerToggleId) {
    let toggleButton = null;
    // mat-date-picker has no native focusable element,
    // get the first available focusable element which is the toggle button
    if (datePickerToggleId === 'contractYearMonthPickerToggle') {
      toggleButton = this.contractYearMonthPickerToggle.nativeElement?.firstChild;
    } else if (datePickerToggleId === 'expirationDatePickerToggle') {
      toggleButton = this.expirationDatePickerToggle.nativeElement?.firstChild;
    } else if (datePickerToggleId === 'firstFuturesYearMonthPickerToggle') {
      toggleButton = this.firstFuturesYearMonthPickerToggle.nativeElement?.firstChild;
    } else if (datePickerToggleId === 'secondFuturesYearMonthPickerToggle') {
      toggleButton = this.secondFuturesYearMonthPickerToggle.nativeElement?.firstChild;
    }
    toggleButton?.focus?.();
  }

  recreateExchangeOrder() {
    if (this.qstService.enabled && this.existingOrder) {
      // First cancel the existing order
      this.qstService.cancelOrder(this.existingOrder.accountNumber, this.existingOrder.clientOrderId)
        .then(response => {
          delete this.adHocOrder.orderDocId;
          const accountNumber = `${this.commodityProfile.officeCode}${this.commodityProfile.accountNumber}`;

          let expirationDate;

          if (this.adHocOrder.timeInForce === TimeInForce.GTD) {
            expirationDate = moment(this.adHocOrder.expirationDate).format('MM/DD/YYYY');
          }

          let contract = '';
          let commodityId = this.commodityProfile.commodityId;
          if (this.adHocOrder.securityType === SecurityType.FUTURE) {
            const contractMonth = this.adHocOrder.contractYearMonth.substring(2);
            const contractYear = this.adHocOrder.contractYearMonth.substring(0, 2);
            contract = `${commodityId}${contractMonth}${contractYear}`;
          } else if (this.adHocOrder.securityType === SecurityType.FUTURE_SPREAD) {
            contract = this.adHocOrder.legs.map(leg => {
              commodityId = leg.commodityId; // I think we will be ok with taking this from the last leg (for now)
              const contractMonth = leg.contractYearMonth.substring(2);
              const contractYear = leg.contractYearMonth.substring(0, 2);
              return `${leg.commodityId}${contractMonth}${contractYear}`;
            }).join(':');
          } else {
            this.openSnackBar('Unsupported Security Type on Ad Hoc Order', 'DISMISS', false);
            return;
          }

          this.qstWorking = true;
          const commodity = this.commodityMap.commodities[ commodityId ];
          const quantity = this.adHocOrder.quantity;
          return this.qstService.createOrder(commodity, contract, quantity, accountNumber,
            this.adHocOrder.side, this.adHocOrder.type, this.adHocOrder.price,
            undefined, this.adHocOrder.timeInForce, expirationDate);
        })
        .then(clientOrderId => {
          this.newOrderClientId = clientOrderId;
        })
        .catch(err => {
          console.log(`QST cancel and recreate error response in pricing segment: ${JSON.stringify(err)}`);
          this.qstWorking = false;
          this.openSnackBar('Error from QST: ' + err, 'DISMISS', false);
        });

    } else {
      // confirm the user has completed the exchange order cancel and recreate
      this.dialogService.open(
        {
          title: 'AST Not Enabled - Cancel and Recreate Order',
          message: `Proceed to trading platform to cancel and recreate order. Be sure to enter and save the new order ID on the pricing. Is order ${this.adHocOrder.orderDocId} cancelled and recreated?`,
          btnColor: 'accent'
        },
        'auto',
        '400px'
      );

      this.dialogService.afterClosed().subscribe(confirm => {
        if (!confirm) {
          return;
        }
        delete this.adHocOrder.orderDocId;
      });
    }
  }


  updateExchangeOrder() {
    if (this.qstService.enabled && this.existingOrder) {

      let expirationDate;

      if (this.adHocOrder.timeInForce === TimeInForce.GTD) {
        expirationDate = moment(this.adHocOrder.expirationDate).format('MM/DD/YYYY');
      }

      let contract = '';
      let commodityId = this.commodityProfile.commodityId;
      if (this.adHocOrder.securityType === SecurityType.FUTURE) {
        const contractMonth = this.adHocOrder.contractYearMonth.substring(2);
        const contractYear = this.adHocOrder.contractYearMonth.substring(0, 2);
        contract = `${commodityId}${contractMonth}${contractYear}`;
      } else if (this.adHocOrder.securityType === SecurityType.FUTURE_SPREAD) {
        contract = this.adHocOrder.legs.map(leg => {
          commodityId = leg.commodityId; // I think we will be ok with taking this from the last leg (for now)
          const contractMonth = leg.contractYearMonth.substring(2);
          const contractYear = leg.contractYearMonth.substring(0, 2);
          return `${leg.commodityId}${contractMonth}${contractYear}`;
        }).join(':');
      } else {
        this.openSnackBar('Unsupported Security Type on Ad Hoc Order', 'DISMISS', false);
        return;
      }

      this.qstWorking = true;
      const commodity = this.commodityMap.commodities[ commodityId ];
      const quantity = this.adHocOrder.quantity;
      this.qstService.cancelReplaceOrder(commodity, contract, quantity, this.existingOrder.accountNumber,
        this.existingOrder.clientOrderId, this.adHocOrder.side, this.adHocOrder.type,
        this.adHocOrder.price, undefined, this.adHocOrder.timeInForce, expirationDate)
        .then(clientOrderId => {
          this.qstWorking = false;
          this.newOrderClientId = clientOrderId;
          if (!clientOrderId) {
            const cancelledError = new Error(`QST action cancelled`);
            cancelledError.name = 'QSTActionCancelled';
            throw cancelledError;
          }
          return this.orderService.updateClientOrderId(
            this.existingOrder.accountDocId,
            this.existingOrder.docId,
            clientOrderId,
            this.authService.accessToken);
        })
        .then(orderUpdateResponse => {
          this.updatingOrder = false;
          this.handlePostExchangeStatus(this.adHocOrder.orderDocId);
          this.updateAdHocOrder();
        })
        .catch(err => {
          console.log(`QST cancelReplaceOrder error response in pricing segment detail: ${JSON.stringify(err)}`);
          this.qstWorking = false;
          let displayedError = '';
          if (err.name === 'QSTActionCancelled') {
            this.openSnackBar(err.message, 'DISMISS', true);
            return;
          } else if (err.name === 'ClientOrderIdUpdateError') {
            displayedError = `Error updating Order ID ${this.newOrderClientId}`;
          } else {
            displayedError = `Error from QST: ${err}`;
          }
          this.openSnackBar(displayedError, 'DISMISS', false);
        });
    } else {
      // confirm the user has completed the exchange order cancellation
      this.dialogService.open(
        {
          title: 'AST Not Enabled - Update Order',
          message: `Proceed to trading platform to update order. Is order ${this.adHocOrder.orderDocId} updated?`,
          btnColor: 'accent'
        },
        'auto',
        '400px'
      );

      this.dialogService.afterClosed().subscribe(confirm => {
        if (!confirm) {
          return;
        }
        this.updatingOrder = false;
        this.handlePostExchangeStatus(this.adHocOrder.orderDocId);
        this.updateAdHocOrder();
      });
    }
  }

  get exchangeOrderId() {
    return this.adHocOrder.orderDocId;
  }

  get canEdit() {
    return !this.isInTransitionalState && this.adHocOrder && (this.orderIdEditable || this.commentsEditable || this.limitAdHocOrderEditable);
  }

  get canCancelAdHocOrder() {
    return this.isAdHocOrderAdmin && this.adHocOrder && this.adHocOrder.type === OrderType.LIMIT && (
      (!this.isInTransitionalState && this.adHocOrder.status === AdHocOrderStatus.WORKING) || this.adHocOrder.status === AdHocOrderStatus.PENDING_ORDER
    );
  }

  get canCompleteExchangeOrderAction() {
    return this.authzService.currentUserHasRole(UserRoles.ORDER_ADMIN_ROLE) && !this.isInTransitionalState;
  }

  get shouldDisplayOrderIdField() {
    return this.orderIdEditable && this.editMode;
  }

  get shouldDisplayCommentField() {
    return (this.commentsEditable || this.limitAdHocOrderEditable) && this.editMode;
  }

  private get isAdHocOrderAdmin() {
    return this.authzService.currentUserHasRole(UserRoles.AD_HOC_ORDER_ADMIN_ROLE);
  }

  private get isOrderAdmin() {
    return this.authzService.currentUserHasRole(UserRoles.ORDER_ADMIN_ROLE);
  }

  private get isInTransitionalState() {
    return this.isLoading || this.editMode || !this.updateComplete || !this.orderActionComplete;
  }

  private get commentsEditable() {
    return this.isAdHocOrderAdmin && ((this.adHocOrder.type === OrderType.LIMIT && this.adHocOrder.status === 'WORKING') || this.adHocOrder.status === 'COMPLETE');
  }

  private get limitAdHocOrderEditable() {
    return this.adHocOrder.type === OrderType.LIMIT && this.adHocOrder.status === AdHocOrderStatus.WORKING;
  }

  private get orderIdEditable() {
    return this.isOrderAdmin && this.adHocOrder.orderDocId &&
      (this.adHocOrder.status === AdHocOrderStatus.WORKING || this.adHocOrder.status === AdHocOrderStatus.COMPLETE);
  }

  private getUpdatedAdHocOrderStatus(adHocOrder: AdHocOrder, adHocOrderForm: FormGroup): AdHocOrderStatus {
    const quantityUpdated = adHocOrder.quantity !== parseInt(adHocOrderForm.get('quantity').value, 10);
    // since this method is only called on limit orders, they have to start with a price but if they're changed to market the price will be removed
    const priceUpdated = !adHocOrderForm.get('price').value || parseFloat(adHocOrderForm.get('price').value) !== adHocOrder.price;
    const expirationValue = adHocOrderForm.get('expirationDate').value ? moment(adHocOrderForm.get('expirationDate').value).format(DATE_FORMAT) : undefined;
    const expirationDateUpdated = adHocOrder.expirationDate != expirationValue;
    const typeUpdated = adHocOrder.type !== adHocOrderForm.get('type').value;
    const contractYearMonthUpdated = adHocOrder.contractYearMonth !== this.translateMomentToContractMonth(moment(adHocOrderForm.get('futuresYearMonth').value));
    const legsUpdated = adHocOrder.securityType === SecurityType.FUTURE_SPREAD && (
      adHocOrder.legs[ 0 ].commodityId !== adHocOrderForm.get('firstCommodity').value ||
      adHocOrder.legs[ 0 ].contractYearMonth !== this.translateMomentToContractMonth(moment(adHocOrderForm.get('firstFuturesYearMonth').value)) ||
      adHocOrder.legs[ 1 ].commodityId !== adHocOrderForm.get('secondCommodity').value ||
      adHocOrder.legs[ 1 ].contractYearMonth !== this.translateMomentToContractMonth(moment(this.adHocOrderForm.get('secondFuturesYearMonth').value))
    );

    if (typeUpdated || contractYearMonthUpdated || legsUpdated) {
      return AdHocOrderStatus.PENDING_ORDER_RECREATE;
    } else if (quantityUpdated || priceUpdated || expirationDateUpdated) {
      return AdHocOrderStatus.PENDING_ORDER_UPDATE;
    } else {
      // only comments updated
      return AdHocOrderStatus.WORKING;
    }
  }

  private loadUserAndClientSettings(): Observable<HMSClientSettings> {
    return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
      switchMap((user: User) => {
        this.loggedInUser = user;
        return this.clientSelectorService.getSelectedClient();
      }),
      switchMap((client: Client) => {
        this.selectedClientDocId = client.docId;
        return this.clientSettingsService.getHmsSettingsByClientDocId(this.selectedClientDocId);
      })
    )
  }

  private handlingPendingExchangeOrderAction() {
    // ensure these properties are reset on new AdHocOrder doc emission for changed data while viewing page
    this.orderActionComplete = true;
    this.creatingOrder = false;
    this.cancellingOrder = false;
    this.recreatingOrder = false;
    this.updatingOrder = false;
    if (this.adHocOrder.status === AdHocOrderStatus.PENDING_ORDER && this.canCompleteExchangeOrderAction) {
      this.orderActionComplete = false;
      this.creatingOrder = true;
      this.setupQST();
    } else if (this.adHocOrder.status === AdHocOrderStatus.PENDING_ORDER_CANCEL && this.canCompleteExchangeOrderAction) {
      this.orderActionComplete = false;
      this.cancellingOrder = true;
      this.setupQST();
    } else if (this.adHocOrder.status === AdHocOrderStatus.PENDING_ORDER_RECREATE && this.canCompleteExchangeOrderAction) {
      this.orderActionComplete = false;
      this.recreatingOrder = true;
      this.setupQST();
    } else if (this.adHocOrder.status === AdHocOrderStatus.PENDING_ORDER_UPDATE && this.canCompleteExchangeOrderAction) {
      this.orderActionComplete = false;
      this.updatingOrder = true;
      this.setupQST();
    }
  }

  private setupQST() {
    this.exchangeOrderIdSub = this.qstService.orderEntry$.pipe(
      filter(orderEntry => orderEntry.OD === this.newOrderClientId),
      map(orderEntry => orderEntry.XID)
    ).subscribe(xid => {
      if (xid) {
        this.qstWorking = false;
        this.orderForm.get('orderDocId').setValue(xid);
        this.orderForm.get('orderDocId').markAsDirty();
      }
    });
  }

  private prepAdHocOrderForm() {
    this.adHocOrderForm.get('comments').setValue(this.adHocOrder.comments);
    this.adHocOrderForm.get('orderDocId').setValue(this.adHocOrder.orderDocId);
    this.adHocOrderForm.get('type').disable();;
    this.adHocOrderForm.get('quantity').disable();;
    this.adHocOrderForm.get('expirationDate').disable();
    this.adHocOrderForm.get('price').disable();
    this.adHocOrderForm.get('futuresYearMonth').disable();
    this.adHocOrderForm.get('firstFuturesYearMonth').disable();
    this.adHocOrderForm.get('firstCommodity').disable();
    this.adHocOrderForm.get('secondFuturesYearMonth').disable();
    this.adHocOrderForm.get('secondCommodity').disable();
    if (this.adHocOrder.type === OrderType.LIMIT && this.adHocOrder.status === AdHocOrderStatus.WORKING) {
      this.setFieldEnabledAndSetValue(this.adHocOrderForm.get('type'), this.adHocOrder.type);
      this.setFieldEnabledAndSetValue(this.adHocOrderForm.get('quantity'), this.adHocOrder.quantity);
      this.setFieldEnabledAndSetValue(this.adHocOrderForm.get('expirationDate'), this.adHocOrder.expirationDate);
      this.setFieldEnabledAndSetValue(this.adHocOrderForm.get('price'), this.adHocOrder.price);
      if (this.adHocOrder.securityType === SecurityType.FUTURE_SPREAD) {
        this.setFieldEnabledAndSetValue(this.adHocOrderForm.get('firstFuturesYearMonth'), this.translateContractMonthToMoment(this.adHocOrder.legs[ 0 ].contractYearMonth));
        this.setFieldEnabledAndSetValue(this.adHocOrderForm.get('firstCommodity'), this.adHocOrder.legs[ 0 ].commodityId);
        this.setFieldEnabledAndSetValue(this.adHocOrderForm.get('secondFuturesYearMonth'), this.translateContractMonthToMoment(this.adHocOrder.legs[ 1 ].contractYearMonth));
        this.setFieldEnabledAndSetValue(this.adHocOrderForm.get('secondCommodity'), this.adHocOrder.legs[ 1 ].commodityId);
      } else {
        this.setFieldEnabledAndSetValue(this.adHocOrderForm.get('futuresYearMonth'), this.translateContractMonthToMoment(this.adHocOrder.contractYearMonth));
      }
    }
  }

  private setFieldEnabledAndSetValue(control: AbstractControl, value: any) {
    control.enable();
    control.setValue(value);
  }

  private setAdHocOrderCancelled() {
    this.adHocOrder.status = AdHocOrderStatus.CANCELLED;
    this.adHocOrder.cancellationTimestamp = new Date().toISOString();
    this.cancellingOrder = false;
    this.updateAdHocOrder();
  }

  private translateContractMonthToMoment(value: string): moment.Moment {
    const year = moment.parseTwoDigitYear(value.substring(0, 2));
    const monthCode = value.substring(2);
    const idxMonth = this.contractMonths.indexOf(monthCode);
    return moment().year(year).month(idxMonth).startOf('month');
  }

  private translateMomentToContractMonth(selectedMonth: moment.Moment): string {
    return `${selectedMonth.format(YEAR_FORMAT)}${ContractMonth[ this.contractMonths[ selectedMonth.month() ] ]}`;
  }

  private updateAdHocOrder() {
    const userName = `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`;
    this.adHocOrder.lastUpdatedByName = userName;
    this.adHocOrder.lastUpdatedTimestamp = new Date().toISOString();

    this.updateComplete = false;
    this.adHocOrderService.updateAdHocOrder(this.selectedClientDocId, this.adHocOrder)
      .then(() => {
        this.updateComplete = true;
        this.orderActionComplete = true;
        console.log('Ad hoc order successfully updated');
        this.openSnackBar('Ad hoc order successfully updated', 'DISMISS', true);
        return this.router.navigate(['../'], { relativeTo: this.activatedRoute });
      })
      .then(() => {
        if (this.alertDocId) {
          return this.appAlertService.updateAppAlertStatus(this.alertDocId, AlertStatus.COMPLETE)
            .catch(err => {
              console.error(`App alert update failed: ${err}`);
              return Promise.resolve();
            });
        }
        return Promise.resolve();
      })
      .catch(err => {
        this.updateComplete = true;
        this.orderActionComplete = true;
        console.error(`Ad hoc order update failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Ad hoc order update failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private handlePostExchangeStatus(exchangeOrderId: string) {
    if (this.adHocOrder.type === OrderType.MARKET) {
      this.adHocOrder.completionTimestamp = new Date().toISOString();
      this.adHocOrder.status = AdHocOrderStatus.COMPLETE;
    } else {
      this.adHocOrder.status = AdHocOrderStatus.WORKING;
    }
    this.adHocOrder.orderDocId = exchangeOrderId;
    this.creatingOrder = false;
    this.recreatingOrder = false;
    this.updatingOrder = false;
    this.updateAdHocOrder();
  }

  private getOrderFills() {
    this.fillDataSource.data$ = this.executionReportService
      .getOrderFillsByOrderDocId(this.existingOrder.accountDocId, this.existingOrder.docId).pipe(
        map((fills: OrderFill[]) => {
          return fills.map(fill => {

            if (fill.legFills) {
              const orderLegFillKeys = Object.keys(fill.legFills);
              const buyKey = orderLegFillKeys.find(key => key.includes(Side.BUY));
              const sellKey = orderLegFillKeys.find(key => key.includes(Side.SELL));

              return {
                quantity: fill.fillQuantity,
                orderSymbol: this.existingOrder.symbol,
                orderFillPrice: fill.fillPrice,
                orderBuySymbol: fill.legFills[buyKey].security.substring(0, 2),
                orderBuyPrice: fill.legFills[buyKey].fillPrice,
                orderSellSymbol: fill.legFills[sellKey].security.substring(0, 2),
                orderSellPrice: fill.legFills[sellKey].fillPrice
              } as OrderFillDisplay;
            } else {
              return {
                quantity: fill.fillQuantity,
                orderSymbol: this.existingOrder.symbol,
                orderFillPrice: fill.fillPrice
              } as OrderFillDisplay;
            }
          });
        }),
        tap((fills: OrderFillDisplay[]) => {
          if (fills.find(fill => fill.orderBuyPrice === undefined && fill.orderSellPrice === undefined)) {
            // Future order displayed column
            this.fillColumnsToDisplay = ['quantity', 'orderFillPrice'];
            this.hasLeg = false;
          } else {
            // Spread order displayed column
            this.fillColumnsToDisplay = ['quantity', 'orderFillPrice', 'orderBuyPrice', 'orderSellPrice'];
            this.hasLeg = true;
          }
        })
      );
  }

}
