import { HedgeType } from '@advance-trading/ops-data-lib';
import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'hedgeType'})
export class HedgeTypePipe implements PipeTransform {
  constructor(private titleCasePipe: TitleCasePipe) {}

  /**
   * This converts the hedge type to be more readable
   * @param value the HedgeType to be transformed
   */
  transform(value: string): string {
    return value === HedgeType.OTC ? value : this.titleCasePipe.transform(value);
  }
}
