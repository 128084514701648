import { Directive } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

const YEAR_PICKER_DATE_FORMAT = {
  parse: {
    dateInput: 'YYYY'
  },
  display: {
    dateInput: 'YYYY'
  }
};

@Directive({
  selector: '[hmsYearPicker]',
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: YEAR_PICKER_DATE_FORMAT},
  ],
})
export class YearPickerDirective {
}
