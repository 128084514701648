export const ICON_MAP = {
  SPOT: 'spot',
  BASIS: 'basis',
  HTA: 'hta',
  CASH: 'cash',
  DP: 'dp',
  ADJUSTMENT: 'adjustment',
  HEDGE: 'hedge',
  ORDER: 'order',
  AD_HOC_ORDER: 'ad_hoc_order',
  EXCHANGE: 'exchange'
};
