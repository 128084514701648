import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { OrderType, Side, TimeInForce, Commodity } from '@advance-trading/ops-data-lib';

@Injectable({
  providedIn: 'root'
})
export class QSTService {

  enabled = false;

  private orderEntrySubject$ = new ReplaySubject<any>(1);
  /**
   * Subscribe to this to receive order update events
   */
  orderEntry$ = this.orderEntrySubject$.asObservable();

  constructor() {
    try {
      if (init) {
        // The callback seems to be used for calls other than the order entry register
        init((response) => {
          if (response.RES === 'OK') {
            this.enabled = true;
            console.log(`QST initialization successful: ${JSON.stringify(response)}`);
            registerOrderEntry((oeRegisterResponse) => {
              console.log(`QST Order Entry registration response: ${JSON.stringify(oeRegisterResponse)}`);
            }, this.handleOrderEntry.bind(this));
          } else {
            this.enabled = false;
            console.log(`QST initialization failed: ${JSON.stringify(response)}`);
          }
        });
      }
    } catch (err) {
      console.log(`QST initialization error: ${err} ${JSON.stringify(err)}`);
    }
  }

  /**
   * This function will popup the QST screen associated with creating a new Order; it will resolve with the created Order
   * clientOrderId
   *
   * @param commodity The Commodity object associated with the contract
   * @param contract The full contract string (e.g. ZCZ20)
   * @param quantity The number of contracts as a string
   * @param accountNumber The full account number including office code (e.g. 27712345)
   * @param side The Side (e.g. Side.SELL)
   * @param orderType The OrderType (e.g. OrderType.MARKET)
   * @param price Contract price as a number. STOP and STOP_LIMIT will use as the stop price. undefined for MARKET
   * @param limit Contract limit price for a STOP_LIMIT order; leave as undefined for other OrderTypes
   * @param timeInForce TimeInForce (e.g. TimeInForce.GTC); note does not support FAK
   * @param gtd Optional string containing a date for GTD order (e.g. 01/25/2020)
   *
   * @returns a Promise that will return the resulting response
   */
  createOrder(commodity: Commodity, contract: string, quantity: number, accountNumber: string, side: Side, orderType: OrderType,
              price: number, limit: number, timeInForce: TimeInForce, gtd?: string): Promise<string> {
    const qstSide = side === Side.SELL ? 'S' : 'B';
    const qstType = this.getQSTOrderType(orderType);
    const qstTimeInForce = gtd ? `GTD(${gtd})` : timeInForce;
    const qstPrice = this.getFractionalPriceFromDecimal(price, commodity);
    const qstLimit = this.getFractionalPriceFromDecimal(limit, commodity);
    return new Promise((resolve, reject) => {
      // Check if enabled first
      if (!this.enabled) {
        reject(new Error(`QST not running on this system.`));
      } else {
        placeOrder(contract, 'CQG', accountNumber, qstSide, `${quantity}`, qstType, qstPrice, qstLimit, (response) => {
          // {"RQT":"place_order","RQI":"1","RES":"OK","OD":"123456789"} is response if successful order creation
          // {"RQT":"place_order","RQI":"1","RES":"ERR","MSG":"Order Placement Canceled"} is error if user cancels dialog
          console.log(`QST placeOrder response: ${JSON.stringify(response)}`);
          if (response.RES === 'OK') {
            // Only return the clientOrderId for the new Order
            resolve(response.OD);
          } else {
            // Return just the error message
            reject(response.MSG);
          }
        }, qstTimeInForce);
      }
    });
  }

  /**
   * This function will popup the QST screen associated with cancelling a working Order; it will resolve with 'OK'
   *
   * @param accountNumber The full account number including office code (e.g. 27712345)
   * @param clientOrderId The clientOrderId stored on the existing Order document
   */
  cancelOrder(accountNumber: string, clientOrderId: string): Promise<string> {
    // This is needed to strip off prefix from clientOrderId that comes from ExecutionReports
    const cleanOrderId = clientOrderId.replace(/orderid/ig, '');

    return new Promise((resolve, reject) => {
      // Check if enabled first
      if (!this.enabled) {
        reject(new Error(`QST not running on this system.`));
      } else {
        cancelOrder('CQG', accountNumber, cleanOrderId, (response) => {
          // {"RQT":"cancel_order","RQI":"1","RES":"OK"} is response if successful order cancel
          // {"RQT":"cancel_order","RQI":"1","RES":"ERR","MSG":"Order Placement Canceled"} is error if user cancels dialog
          console.log(`QST cancelOrder response: ${JSON.stringify(response)}`);
          if (response.RES === 'OK') {
            // Only return the clientOrderId for the new Order
            resolve(response.RES);
          } else {
            // Return just the error message
            reject(response.MSG);
          }
        });
      }
    });
  }

  /**
   * This function will popup the QST screen associated with cancel and replacing (modifying) an existing Order;
   * it will resolve with the created Order clientOrderId
   *
   * @param commodity The Commodity object associated with the contract
   * @param contract The full contract string (e.g. ZCZ20)
   * @param quantity The number of contracts as a string
   * @param accountNumber The full account number including office code (e.g. 27712345)
   * @param clientOrderId The clientOrderId from the existing Order object
   * @param side The Side (e.g. Side.SELL)
   * @param orderType The OrderType (e.g. OrderType.MARKET)
   * @param price Contract price as a number. STOP and STOP_LIMIT will use as the stop price. undefined for MARKET
   * @param limit Contract limit price for a STOP_LIMIT order; leave as undefined for other OrderTypes
   * @param timeInForce TimeInForce (e.g. TimeInForce.GTC); note does not support FAK
   * @param gtd Optional string containing a date for GTD order (e.g. 01/25/2020)
   *
   * @returns a Promise that will return the resulting response
   */
  cancelReplaceOrder(commodity: Commodity, contract: string, quantity: number, accountNumber: string, clientOrderId: string, side: Side,
                     orderType: OrderType, price: number, limit: number, timeInForce: TimeInForce, gtd?: string): Promise<string> {
    const qstSide = side === Side.SELL ? 'S' : 'B';
    const qstType = this.getQSTOrderType(orderType);
    const qstTimeInForce = gtd ? `GTD(${gtd})` : timeInForce;
    const qstPrice = this.getFractionalPriceFromDecimal(price, commodity);
    const qstLimit = this.getFractionalPriceFromDecimal(limit, commodity);
    // This is needed to strip off prefix from clientOrderId that comes from ExecutionReports
    const cleanOrderId = clientOrderId.replace(/orderid/ig, '');

    return new Promise((resolve, reject) => {
      // Check if enabled first
      if (!this.enabled) {
        reject(new Error(`QST not running on this system.`));
      } else {
        cancelReplaceOrder(contract, 'CQG', accountNumber, cleanOrderId, qstSide, `${quantity}`, qstType, qstPrice, qstLimit,
          (response) => {
            // {"RQT":"replace_order","RQI":"1","RES":"OK","OD":"123456789"} is response if successful order cancel/replace
            // {"RQT":"replace_order","RQI":"1","RES":"ERR","MSG":"Order Placement Canceled"} is error if user cancels dialog
            console.log(`QST cancelReplaceOrder response: ${JSON.stringify(response)}`);
            if (response.RES === 'OK') {
              // Only return the clientOrderId for the new Order
              resolve(response.OD);
            } else {
              // Return just the error message
              reject(response.MSG);
            }
          }, qstTimeInForce);
      }
    });
  }

  private handleOrderEntry(orderResponse) {
    this.orderEntrySubject$.next(orderResponse);
  }

  // Returns a string containing the order type QST expects
  private getQSTOrderType(orderType: OrderType) {
    switch (orderType) {
      case OrderType.MARKET:
        return 'MKT';
      case OrderType.MARKET_LIMIT:
        return 'MKT';
      case OrderType.STOP:
        return 'STOP';
      case OrderType.STOP_LIMIT:
        return 'STL';
      default:
        return 'LMT';
    }
  }

  /**
   * Converts a regular JavaScript number to a string formatted to use as a price or limit in QST (e.g. 330^2);
   * the number after the ^ is the number of eighths to represent the fractional part of the price.
   *
   * It will return just the string representation of the number if the Commodity does not use fractional pricing.
   *
   * @param price A number representing the 4-digit decimal contract price in dollars (e.g. 3.3025 for ZC)
   * @param commodity The Commodity being purchased/sold (expects an outright leg for spreads)
   * @returns A string representing the whole and fractional parts of the price (e.g. 330^2)
   */
  private getFractionalPriceFromDecimal(price: number, commodity: Commodity): string {
    if (!Number.isFinite(price)) {
      return '';
    }

    const exchangePrice = price * commodity.marketDataDivisor;
    if (!commodity.fractionalPrice) {
      return `${exchangePrice}`;
    }

    // Switch to a 3 position fixed since we need eighths of a cent
    const exchangePriceString = `${exchangePrice.toFixed(3)}`;

    // Handle the scenario where string concatenation causes outputPriceInt of -0 to become 0
    const negativeZeroIndicator = price < 0.00 && price > -0.01 ? '-' : '';

    const outputPriceInt = parseInt(exchangePriceString, 10);
    let fractionalCentString = '^0';
    switch (exchangePriceString.substring(exchangePriceString.length - 3, exchangePriceString.length)) {
      case '125':
        fractionalCentString = '^1';
        break;
      case '250':
        fractionalCentString = '^2';
        break;
      case '375':
        fractionalCentString = '^3';
        break;
      case '500':
        fractionalCentString = '^4';
        break;
      case '625':
        fractionalCentString = '^5';
        break;
      case '750':
        fractionalCentString = '^6';
        break;
      case '875':
        fractionalCentString = '^7';
        break;
      default: // 000
        fractionalCentString = '^0';
    }

    // Add in the negative sign if needed for the -0 scenario
    return `${negativeZeroIndicator}${outputPriceInt}${fractionalCentString}`;
  }
}
