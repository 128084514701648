<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h2 data-cy="page-title-reports" class="card-title">Reports</h2>
   </div>

    <mat-nav-list>
      <a routerLink="/reports/purchasesandsales" matLine>
        <mat-list-item routerLinkActive="active" class="nav-menu-item">
          <mat-icon fontSet="material-icons-outlined" mat-list-icon>attach_money</mat-icon>
          Purchases and Sales Report
          <mat-divider></mat-divider>
        </mat-list-item>
      </a>
      <a routerLink="/reports/ondemandquotes" matLine>
        <mat-list-item routerLinkActive="active" class="nav-menu-item">
          On-Demand Quotes Report
          <mat-divider></mat-divider>
        </mat-list-item>
      </a>
    </mat-nav-list>

  </mat-card>
</div>
