<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <hms-error-message [title]="'Error Retrieving User Details'" [message]="errorMessage"></hms-error-message>
    <ng-container *ngIf="user$ | async as user"></ng-container>
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">{{ user?.firstName }} {{ user?.lastName }}</h2>
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <button type="button" id="edit-user" class="action-button" aria-label="Edit User" matTooltip="Edit User" *ngIf="!editMode && (userCanAdminister() || isSelf()) && updateComplete" (click)="setEditMode(true)" mat-mini-fab color="accent">
          <mat-icon>edit</mat-icon>
        </button>
        <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
        <button type="submit" id="update-user" class="action-button" aria-label="Update User" matTooltip="Update User" *ngIf="editMode && (userCanAdminister() || isSelf()) && updateComplete" [disabled]="!profileForm.valid || profileForm.pristine || !settingsValid" (click)="updateUser()" mat-mini-fab color="accent">
          <mat-icon>done</mat-icon>
        </button>
        <button type="button" id="cancel-edit-user" class="action-button" aria-label="Discard Changes" matTooltip="Discard Changes" *ngIf="editMode && (userCanAdminister() || isSelf()) && updateComplete" (click)="setEditMode(false)" mat-mini-fab color="">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>
    <ng-container *ngIf="selectedClient$ | async"></ng-container>
    <form *ngIf="!errorMessage" [formGroup]="profileForm" (ngSubmit)="updateUser()" fxLayout="column">
      <mat-tab-group>
        <mat-tab label="Profile" fxFlex="100">
          <div class="tab-body" fxLayout="row wrap" fxLayoutAlign="space-between space-between" fxFlex="100" fxLayoutGap="5">
            <ati-labeled-data *ngIf="!editMode" label="First Name" [data]="profileForm.get('firstName').value" fxFlex.xs="100" fxFlex.gt-xs="45"></ati-labeled-data>
            <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
              <input required matInput placeholder="First Name" formControlName="firstName" trim="blur">
              <mat-error *ngIf="profileForm.get('firstName').invalid">{{getErrorMessage(profileForm.get('firstName'))}}</mat-error>
            </mat-form-field>
            <ati-labeled-data *ngIf="!editMode" label="Last Name" [data]="profileForm.get('lastName').value" fxFlex.xs="100" fxFlex.gt-xs="45"></ati-labeled-data>
            <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
              <input required matInput placeholder="Last Name" formControlName="lastName" trim="blur">
              <mat-error *ngIf="profileForm.get('lastName').invalid">{{getErrorMessage(profileForm.get('lastName'))}}</mat-error>
            </mat-form-field>
            <ati-labeled-data *ngIf="!editMode" label="Email Address" [data]="profileForm.get('email').value" fxFlex.xs="100" fxFlex.gt-xs="45"></ati-labeled-data>
            <mat-form-field *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45">
              <input required matInput placeholder="Email Address" formControlName="email" trim="blur">
              <mat-error *ngIf="profileForm.get('email').invalid">{{getErrorMessage(profileForm.get('email'))}}</mat-error>
            </mat-form-field>
            <div fxFlex.xs="10" fxFlex.gt-xs="30">
              <!-- Placeholder for spacing -->
            </div>
            <mat-form-field class="phone-field" fxFlex.xs="100" fxFlex.gt-xs="45">
              <ngx-mat-intl-tel-input required [preferredCountries]="['us']" [enablePlaceholder]="true" [enableSearch]="false" name="mobileNumber" formControlName="mobileNumber" #mobileNumber></ngx-mat-intl-tel-input>
              <button type="button" id="clear-user-detail-mobile" mat-button *ngIf="profileForm.get('mobileNumber').value && editMode && userCanAdminister()" matSuffix mat-icon-button aria-label="Clear Mobile" matTooltip="Clear Mobile" (click)="clearPhoneNumber(profileForm.get('mobileNumber'), mobileNumber)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-label>Mobile Phone</mat-label>
              <mat-error *ngIf="profileForm.get('mobileNumber').invalid">{{getErrorMessage(profileForm.get('mobileNumber'))}}</mat-error>
            </mat-form-field>
            <mat-form-field class="phone-field" fxFlex.xs="100" fxFlex.gt-xs="45">
              <ngx-mat-intl-tel-input [preferredCountries]="['us']" [enablePlaceholder]="true" [enableSearch]="false" name="officeNumber" formControlName="officeNumber" #officeNumber></ngx-mat-intl-tel-input>
              <button type="button" id="clear-user-detail-office" mat-button *ngIf="profileForm.get('officeNumber').value && editMode && userCanAdminister()" matSuffix mat-icon-button aria-label="Clear Office" matTooltip="Clear Office" (click)="clearPhoneNumber(profileForm.get('officeNumber'), officeNumber)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-label>Office Phone</mat-label>
              <mat-error *ngIf="profileForm.get('officeNumber').invalid">{{getErrorMessage(profileForm.get('officeNumber'))}}</mat-error>
            </mat-form-field>
            <mat-form-field class="phone-field" fxFlex.xs="100" fxFlex.gt-xs="45">
              <ngx-mat-intl-tel-input [preferredCountries]="['us']" [enablePlaceholder]="true" [enableSearch]="false" name="tollFreeNumber" formControlName="tollFreeNumber" #tollFreeNumber></ngx-mat-intl-tel-input>
              <button type="button" id="clear-user-detail-toll-free"mat-button *ngIf="profileForm.get('tollFreeNumber').value && editMode && userCanAdminister()" matSuffix mat-icon-button aria-label="Clear Toll-Free" matTooltip="Clear Toll-Free" (click)="clearPhoneNumber(profileForm.get('tollFreeNumber'), tollFreeNumber)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-label>Toll-Free Phone</mat-label>
              <mat-error *ngIf="profileForm.get('tollFreeNumber').invalid">{{getErrorMessage(profileForm.get('tollFreeNumber'))}}</mat-error>
            </mat-form-field>
            <mat-form-field class="phone-field" fxFlex.xs="100" fxFlex.gt-xs="45">
              <ngx-mat-intl-tel-input [preferredCountries]="['us']" [enablePlaceholder]="true" [enableSearch]="false" name="faxNumber" formControlName="faxNumber" #faxNumber></ngx-mat-intl-tel-input>
              <button type="button" id="clear-user-detail-fax" mat-button *ngIf="profileForm.get('faxNumber').value && editMode && userCanAdminister()" matSuffix mat-icon-button aria-label="Clear Fax" matTooltip="Clear Fax" (click)="clearPhoneNumber(profileForm.get('faxNumber'), faxNumber)">
                <mat-icon>close</mat-icon>
              </button>
              <mat-label>Fax Number</mat-label>
              <mat-error *ngIf="profileForm.get('faxNumber').invalid">{{getErrorMessage(profileForm.get('faxNumber'))}}</mat-error>
            </mat-form-field>
          </div>
        </mat-tab>
        <mat-tab label="Security" fxFlex="100" *ngIf="userCanAdminister() && (user$ | async)?.authId">
          <div class="tab-body" fxLayout="row wrap" fxLayoutAlign="space-between space-between" fxFlex="100" fxLayoutGap="5">
            <ati-labeled-data *ngIf="!editMode && userCanAdminister()" label="User Status" data="{{ profileForm.get('status').value | titlecase }}" fxFlex="100"></ati-labeled-data>
            <mat-radio-group required *ngIf="editMode && userCanAdminister()" formControlName="status" fxFlex="100">
              <span class="check-radio-label">User Status</span>
              <mat-radio-button value="NEW">New</mat-radio-button>
              <mat-radio-button value="PENDING">Pending</mat-radio-button>
              <mat-radio-button value="ACTIVE">Active</mat-radio-button>
              <mat-radio-button value="INACTIVE">Inactive</mat-radio-button>
              <mat-radio-button value="DENIED">Denied</mat-radio-button>
            </mat-radio-group>
            <ati-labeled-data *ngIf="!editMode" label="Market Data Frequency" [data]="getMarketDataFrequencyDisplayText(profileForm.get('marketDataFrequency').value)" fxFlex="100"></ati-labeled-data>
            <mat-radio-group *ngIf="editMode" required formControlName="marketDataFrequency" fxFlex="100">
              <span class="check-radio-label">Market Data Frequency</span>
              <mat-radio-button *ngFor="let frequency of marketDataFrequencies" [value]="frequency">{{getMarketDataFrequencyDisplayText(frequency)}}</mat-radio-button>
            </mat-radio-group>
            <ati-labeled-data *ngIf="!editMode" label="HMS Access" [data]="profileForm.get('hmsEnabled').value ? 'Enabled' : 'Disabled'" fxFlex="100"></ati-labeled-data>
            <div *ngIf="editMode" class="checkbox-group" fxFlex="100">
              <span class="check-radio-label">HMS Access</span>
              <mat-checkbox formControlName="hmsEnabled">Enabled</mat-checkbox>
            </div>
            <mat-form-field *ngIf="userGroups; else groupsLoading" fxFlex="100">
              <mat-chip-list #groupChips class="group-list">
                <mat-chip *ngFor="let uGroup of userGroups; index as index;"
                  [color]="(index + 1) % 2 === 0 ? 'accent' : 'primary'"
                  selected
                  [removable]="groupRemovable(uGroup.name)"
                  (removed)="removeGroup(uGroup)">
                  {{uGroup.name}}
                  <mat-icon matChipRemove *ngIf="editMode && groupRemovable(uGroup.name)">cancel</mat-icon>
                </mat-chip>
                <input
                  #groupInput
                  matInput
                  placeholder="Security Groups"
                  formControlName="group"
                  [matAutocomplete]="groupauto"
                  [matChipInputFor]="groupChips"
                  [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                  [matChipInputAddOnBlur]="addOnBlur"
                  (matChipInputTokenEnd)="addGroup($event)">
              </mat-chip-list>
              <mat-autocomplete autoActiveFirstOption #groupauto="matAutocomplete" [displayWith]="displayGroup" (optionSelected)="addGroup()">
                <mat-option *ngFor="let group of filteredGroups$ | async" [value]="group">{{group.name}}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <ng-template #groupsLoading>
              <mat-spinner diameter="40" color="primary"></mat-spinner>
            </ng-template>
          </div>
        </mat-tab>
        <mat-tab label="HMS" fxFlex="100" *ngIf="appSettingsVisible()">
          <div class="tab-body" fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
            <mat-expansion-panel *ngIf="profileForm.get('hmsEnabled').value" [expanded]="true" class="mat-elevation-z0" fxFlex="100">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  HMS Settings
                </mat-panel-title>
              </mat-expansion-panel-header>
              <hms-user-settings (settingsUpdated)="handleSettingsUpdated($event)" (settingsValid)="handleSettingsValid($event)" [hmsUserSettings]="hmsUserSettings" [editMode]="editMode" [updateComplete]="updateComplete" [userCanAdminister]="userCanAdminister()"></hms-user-settings>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="canViewLiveLedgers()" class="mat-elevation-z0" fxFlex="100">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  User Preferences
                </mat-panel-title>
              </mat-expansion-panel-header>
              <hms-user-preferences (userPreferencesUpdated)="handleUserPreferencesUpdated($event)" [hmsUserPreferences]="hmsUserPreferences" [editMode]="editMode"></hms-user-preferences>
            </mat-expansion-panel>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </mat-card>
</div>
