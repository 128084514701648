import { AfterViewInit, ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { OrderService } from '@advance-trading/angular-ops-data';
import { Order, OrderStatus, OrderType } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { UserRoles } from '../../utilities/user-roles';

const DEFAULT_START_DATE = new Date('1/1/2000').toISOString();
const DEFAULT_END_DATE = new Date('12/31/2099').toISOString();

@Component({
  selector: 'hms-order-search',
  templateUrl: './order-search.component.html',
  styleUrls: ['./order-search.component.scss']
})
export class OrderSearchComponent implements OnInit, AfterViewInit {
  @ViewChildren('statusBoxes') statusBoxes: QueryList<MatCheckbox>;

  orderSearchForm: FormGroup = this.formBuilder.group({
    orderId: [''],
    symbol: [''],
    statusBoxes: [],
    type: [],
    startDate: ['', { updateOn: 'blur' }],
    endDate: ['', { updateOn: 'blur' }]
  });

  private statusArray: string[] = [];

  orderStatuses = Object.keys(OrderStatus);
  orderTypes = Object.keys(OrderType);

  errorMessage: string;
  isSearching = false;
  showOrders = false;
  selectedOrders$: Observable<Order[]>;
  tableState: { [key: string]: string | number } = {};

  private queryParams: Params;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private changeDetector: ChangeDetectorRef,
    private clientSelectorService: ClientSelectorService,
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.ORDER_ADMIN_ROLE) &&
      !this.authzService.currentUserHasRole(UserRoles.ORDER_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to search orders.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    // Trade_Corrected and Trade_Cancelled are not used on this search form
    this.orderStatuses = this.orderStatuses.filter(
      status => ![OrderStatus.TRADE_CANCELLED, OrderStatus.TRADE_CORRECTED].includes(OrderStatus[status]));

    this.orderSearchForm.valueChanges.subscribe(() => {
      const id = this.orderSearchForm.get('orderId').value;
      const symbol = this.orderSearchForm.get('symbol').value ? this.orderSearchForm.get('symbol').value.toUpperCase() : '';
      const status = this.statusArray.length;
      const type = this.orderSearchForm.get('type').value;
      const startDate = this.orderSearchForm.get('startDate').value;
      const endDate = this.orderSearchForm.get('endDate').value;

      const hasNoValues = !id && !symbol && !status && !type && !startDate && !endDate;
      const hasValueOtherThanId = symbol || status || type || startDate || endDate;

      // Disable all other fields when order ID is entered
      if (id && this.orderSearchForm.get('symbol').enabled) {
        this.orderSearchForm.get('symbol').disable();
        this.orderSearchForm.get('type').disable();
        this.orderSearchForm.get('startDate').disable();
        this.orderSearchForm.get('endDate').disable();
        this.statusBoxes.forEach((statusbox: MatCheckbox) => statusbox.disabled = true);
        // Disable order ID field if a value is entered in any other field
      } else if (this.orderSearchForm.get('orderId').enabled && hasValueOtherThanId) {
        this.orderSearchForm.get('orderId').disable();
        // Re-enable all fields if a previously entered value has disabled a field(s) but is then cleared
      } else if (hasNoValues && (this.orderSearchForm.get('orderId').disabled || this.orderSearchForm.get('symbol').disabled)) {
        this.orderSearchForm.enable();
        this.statusBoxes.forEach((statusbox: MatCheckbox) => statusbox.disabled = false);
      }
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
      this.queryParams = Object.assign({}, params);
      this.orderSearchForm.get('type').setValue(this.queryParams.type);
      this.orderSearchForm.get('startDate').setValue(this.queryParams.startDate);
      this.orderSearchForm.get('endDate').setValue(this.queryParams.endDate);
      this.orderSearchForm.get('symbol').setValue(this.queryParams.symbol ? this.queryParams.symbol.toUpperCase() : '');
      if (this.queryParams.status) {
        this.statusArray = this.queryParams.status.split(',');
        this.orderSearchForm.get('statusBoxes').setValue(this.statusArray);
      }
      if (Object.keys(params).length) {
        // Mark form as dirty so reset button appears
        this.orderSearchForm.markAsDirty();
        this.searchOrders();
      }
    }));

  }

  ngAfterViewInit() {
    // setting here as [checked] property on template was not setting inner input type="checkbox" to checked
    this.statusBoxes.forEach(statusBox => statusBox.checked = this.statusArray.includes(statusBox.value));
    this.changeDetector.detectChanges();
  }

  reset() {
    this.orderSearchForm.reset();
    this.clearQueryParams();
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });
    this.statusArray = [];
    this.statusBoxes.forEach(statusBox => statusBox.checked = false);
    this.orderSearchForm.enable();
    this.orderSearchForm.markAsPristine();
  }

  searchOrders(searchButtonClicked: boolean = false) {
    if (searchButtonClicked) {
      // clear initial table state if the user perform a new search
      this.clearQueryParams();
      this.tableState = {};
    } else {
      // set initial table state from query param if the user is back navigating from another page
      const sortDir = this.queryParams.sortDir;
      const sortColName = this.queryParams.sortColName;
      const pageSize = this.queryParams.pageSize;
      const pageIndex = this.queryParams.pageIndex;
      const filter = this.queryParams.filter;
      this.tableState = {
        sortDir,
        sortColName,
        pageSize,
        pageIndex,
        filter
      };
    }

    this.showOrders = false;
    this.changeDetector.detectChanges();

    this.selectedOrders$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap(selectedClient => {
        return this.chooseQuery(selectedClient.docId);
      })
    );

    this.showOrders = true;
    this.changeDetector.detectChanges();

  }

  onStatusChange(event) {
    if (event.checked) {
      this.statusArray.push(event.source.value);
    } else {
      this.statusArray = this.statusArray.filter(status => status !== event.source.value);
    }
    this.orderSearchForm.get('statusBoxes').setValue(this.statusArray);
    if (this.statusArray.length) {
      this.orderSearchForm.get('statusBoxes').markAsDirty();
    } else {
      this.orderSearchForm.get('statusBoxes').markAsPristine();
    }
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMax')) {
      return 'Value Invalid';
    }
    return 'Unknown Error';
  }

  handleOrderListChange(tableState: { [key: string]: string | number }) {
    if (tableState.sortDir && tableState.sortColName) {
      this.queryParams.sortDir = tableState.sortDir;
      this.queryParams.sortColName = tableState.sortColName;
    } else if (this.queryParams.sortDir && this.queryParams.sortColName) {
      // remove sorted direction and column in query param if there's no sort applied
      delete this.queryParams.sortDir;
      delete this.queryParams.sortColName;
    }
    if (tableState.pageSize) {
      this.queryParams.pageSize = tableState.pageSize;
    }
    if (tableState.pageIndex !== undefined) {
      this.queryParams.pageIndex = tableState.pageIndex;
    }

    if (tableState.filter) {
      this.queryParams.filter = tableState.filter;
    } else if (this.queryParams.filter) {
      // remove filter query param if there's no filter applied
      delete this.queryParams.filter;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleOrderListError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.changeDetector.detectChanges();
  }

  private chooseQuery(clientDocId: string): Observable<Order[]> {
    const orderIdValue = this.orderSearchForm.get('orderId').value;
    // orderId search selected
    if (orderIdValue) {
      return this.orderService.findClientOrdersByOrderDocId(clientDocId, orderIdValue);
    }

    const symbolValue = this.orderSearchForm.get('symbol').value ? this.orderSearchForm.get('symbol').value.toUpperCase() : '';
    const typeValue = this.orderSearchForm.get('type').value;
    const hasStatus = this.statusArray.length > 0;
    let startDate = this.orderSearchForm.get('startDate').value;
    let endDate = this.orderSearchForm.get('endDate').value;
    const hasDate = startDate || endDate;

    // Maintain table state if any but clear all other search parameters
    this.queryParams = this.tableState as Params;

    // Set default date or the date entered by the user
    if (startDate) {
      startDate = new Date(startDate).toISOString();
      this.queryParams.startDate = startDate;
    } else {
      startDate = DEFAULT_START_DATE;
    }

    if (endDate) {
      endDate = new Date(endDate);
      // Allow order dated on this day before midnight
      endDate.setHours(23, 59, 59, 999);
      endDate = endDate.toISOString();
      this.queryParams.endDate = endDate;
    } else {
      endDate = DEFAULT_END_DATE;
    }

    if (typeValue) {
      this.queryParams.type = typeValue;
    }

    if (symbolValue) {
      this.queryParams.symbol = symbolValue;
    }

    if (hasStatus) {
      this.queryParams.status = this.statusArray.join(',');
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });

    // four search options selected
    if (symbolValue && hasStatus && typeValue && hasDate) {
      return this.orderService.findClientOrdersBySymbolAndStatusAndTypeAndDate(
        clientDocId, symbolValue, this.statusArray, typeValue, startDate, endDate);
      // three search options selected
    } else if (symbolValue && hasStatus && typeValue) {
      return this.orderService.findClientOrdersBySymbolAndStatusAndType(
        clientDocId, symbolValue, this.statusArray, typeValue);
    } else if (symbolValue && hasStatus && hasDate) {
      return this.orderService.findClientOrdersBySymbolAndStatusAndDate(
        clientDocId, symbolValue, this.statusArray, startDate, endDate);
    } else if (symbolValue && typeValue && hasDate) {
      return this.orderService.findClientOrdersBySymbolAndTypeAndDate(
        clientDocId, symbolValue, typeValue, startDate, endDate);
    } else if (hasStatus && typeValue && hasDate) {
      return this.orderService.findClientOrdersByStatusAndTypeAndDate(
        clientDocId, this.statusArray, typeValue, startDate, endDate);
      // two search options selected
    } else if (symbolValue && hasStatus) {
      return this.orderService.findClientOrdersBySymbolAndStatus(clientDocId, symbolValue, this.statusArray);
    } else if (symbolValue && typeValue) {
      return this.orderService.findClientOrdersBySymbolAndType(clientDocId, symbolValue, typeValue);
    } else if (symbolValue && hasDate) {
      return this.orderService.findClientOrdersBySymbolAndDate(clientDocId, symbolValue, startDate, endDate);
    } else if (hasStatus && typeValue) {
      return this.orderService.findClientOrdersByStatusAndType(clientDocId, this.statusArray, typeValue);
    } else if (hasStatus && hasDate) {
      return this.orderService.findClientOrdersByStatusAndDate(clientDocId, this.statusArray, startDate, endDate);
    } else if (typeValue && hasDate) {
      return this.orderService.findClientOrdersByTypeAndDate(clientDocId, typeValue, startDate, endDate);
      // one search option selected
    } else if (symbolValue) {
      return this.orderService.findClientOrdersBySymbol(clientDocId, symbolValue);
    } else if (hasStatus) {
      return this.orderService.findClientOrdersByStatus(clientDocId, this.statusArray);
    } else if (typeValue) {
      return this.orderService.findClientOrdersByType(clientDocId, typeValue);
    } else if (hasDate) {
      return this.orderService.findClientOrdersByDate(clientDocId, startDate, endDate);
      // no seach options selected
    } else {
      return this.orderService.findAllClientOrders(clientDocId);
    }
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}
