import { Contract, PricingSegment } from '@advance-trading/ops-data-lib';

export class MarketDataHelper {

  /**
   * Determine docId of MarketData document for a Contract or PricingSegment
   *
   * @param contractOrPricingSegment the contract or pricing segment object
   */
  static getMarketDataDocId(contractOrPricingSegment: Contract | PricingSegment): string {
    const commodityId = contractOrPricingSegment.commodityId;
    const month = contractOrPricingSegment.futuresYearMonth.substr(2);
    const year = contractOrPricingSegment.futuresYearMonth.substr(0, 2);
    return `${commodityId}${month}${year}`;
  }

}
