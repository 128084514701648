import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import * as moment from 'moment';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommonValidators, ObservableDataSource } from '@advance-trading/angular-common-services';
import { CommodityProfileService, OperationsDataService } from '@advance-trading/angular-ops-data';
import { Client, Commodity, CommodityMap, CommodityProfile, Contract, ContractMonth, ContractType, PricingSegment } from '@advance-trading/ops-data-lib';

import { RollType } from '../../batch-roll/roll-type';
import { ClientSelectorService } from '../../service/client-selector.service';
import { UserRoles } from '../../utilities/user-roles';
import { MonthlyRollReportDisplay } from './monthly-roll-report-display';
import { ContractService } from '../../service/contract.service';
import { PricingSegmentService } from '../../service/pricing-segment.service';
import { ExportService } from '../../service/export.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

const YEAR_FORMAT = 'YY';
const MONTH_YEAR_FORMAT = 'MMM yyyy';

@Component({
  selector: 'hms-monthly-roll-report',
  templateUrl: './monthly-roll-report.component.html',
  styleUrls: ['./monthly-roll-report.component.scss']
})
export class MonthlyRollReportComponent implements OnInit {
  errorMessage = '';
  isLoading = false;
  displayResults = false;
  columnsToDisplay: string[] = [];
  contractsDataSource = new ObservableDataSource<MonthlyRollReportDisplay>();
  pricingsDataSource = new ObservableDataSource<MonthlyRollReportDisplay>();
  monthlyRolls$: Observable<MonthlyRollReportDisplay[]>;
  contractsExportable = false;
  contractReportTitle = '';
  contractSheetName = '';
  pricingsExportable = false;
  pricingReportTitle = '';
  pricingSheetName = '';
  selectedClientDocId: string;
  hideDeliveryPeriod = false;
  hideFuturesYearMonth = false;

  @ViewChild('effectiveMonthPicker', { static: false }) effectiveMonthRef: MatDatepicker<moment.Moment>;
  @ViewChild('form', { static: false }) effectiveRollFormRef: HTMLFormElement;

  monthlyRollForm: FormGroup = this.formBuilder.group({
    commodityProfile: ['', [Validators.required, CommonValidators.objectValidator]],
    rollType: ['', [Validators.required]],
    effectiveMonth: ['', [Validators.required]]
  });

  // form data
  commodityProfiles$: Observable<CommodityProfile[]>;
  commodityProfiles: CommodityProfile[];
  rollTypes = Object.keys(RollType);

  private permissibleMonths: number[] = [];
  private queryParams: Params = {};
  private contractMonths = Object.keys(ContractMonth);
  private contracts: Contract[] = [];
  private commodities: { [key: string]: Commodity };
  private displaySingleMonthColumn = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private contractService: ContractService,
    private commodityProfileService: CommodityProfileService,
    public exportService: ExportService,
    private formBuilder: FormBuilder,
    private operationsDataService: OperationsDataService,
    private pricingSegmentService: PricingSegmentService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.CONTRACT_REPORTS_GENERATOR_ROLE)) {
      this.errorMessage = 'You do not have permission to run the monthly roll report.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .subscribe(state => {
        // display columns for xsmall screens
        this.displaySingleMonthColumn = true;
        if (state.breakpoints[Breakpoints.XSmall]) {
          this.columnsToDisplay = [
            'accountingSystemId',
            'deliveryPeriod',
            'futuresYearMonth',
            'status',
            'contractType',
            'quantity'
          ];
          // display columns for small screens
        } else if (state.breakpoints[Breakpoints.Small]) {
          this.columnsToDisplay = [
            'accountingSystemId',
            'deliveryPeriod',
            'futuresYearMonth',
            'status',
            'patronName',
            'contractType',
            'quantity',
            'basisPrice',
            'futuresPrice',
            'cashPrice'
          ];
          // display columns for medium screens
        } else if (state.breakpoints[Breakpoints.Medium]) {
          this.columnsToDisplay = [
            'accountingSystemId',
            'deliveryPeriod',
            'futuresYearMonth',
            'originatorName',
            'status',
            'patronName',
            'contractType',
            'quantity',
            'basisPrice',
            'futuresPrice',
            'cashPrice'
          ];
          // display columns for larger screens
        } else {
          this.columnsToDisplay = [
            'accountingSystemId',
            'expiration',
            'deliveryPeriod',
            'futuresYearMonth',
            'originatorName',
            'status',
            'patronName',
            'contractType',
            'quantity',
            'basisPrice',
            'futuresPrice',
            'cashPrice'
          ];
          this.displaySingleMonthColumn = false;
        }
        const rollType = this.queryParams.rollType || this.monthlyRollForm.get('rollType').value;
        this.setMonthColumnVisibility(rollType);
      });

    // set listeners before populating values from queryParams so month filter is applied appropriately
    this.onCommodityProfileChanges();
    this.onRollTypeChanges();

    this.commodityProfiles$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((doc: CommodityMap) => {
        this.commodities = doc.commodities;
        return this.clientSelectorService.getSelectedClient();
      }),
      switchMap((client: Client) => {
        this.selectedClientDocId = client.docId;
        return this.activatedRoute.queryParams;
      }),
      switchMap((params) => {
        this.queryParams = Object.assign({}, params);
        this.monthlyRollForm.get('rollType').setValue(this.queryParams.rollType);
        if (this.queryParams.month) {
          this.selectMonth(this.translateContractMonthToMoment(this.queryParams.month));
        }
        return this.commodityProfileService.getActiveCommodityProfilesByTypeAndClientDocId(this.selectedClientDocId);
      }),
      tap((commodityProfiles: CommodityProfile[]) => {
        this.commodityProfiles = commodityProfiles;
        if (this.queryParams.commodity) {
          const commodityProfile = commodityProfiles.find(profile => profile.docId === this.queryParams.commodity);
          this.monthlyRollForm.get('commodityProfile').setValue(commodityProfile);
          this.monthlyRollForm.markAsDirty();
          this.loadMonthlyRollReportData(false);
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving monthly roll form data; please try again later';
        console.error(`Error retrieving monthly roll form data: ${err.message ? err.message : err}`);
        return of([]);
      })
    );

  }

  get monthLabel(): string {
    const rollType = this.monthlyRollForm.get('rollType').value as string;
    if (rollType === 'FUTURES') {
      return 'Futures Month';
    } else if (rollType === 'DELIVERY') {
      return 'Delivery Period';
    } else {
      return 'Month';
    }
  }

  allowTabOnly(event: KeyboardEvent) {
    if (event.key !== 'Tab') {
      event.preventDefault();
    }
  }

  compareCommodityProfile(profile1: CommodityProfile, profile2: CommodityProfile) {
    return profile1 && profile2 && profile1.docId === profile2.docId;
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('matDatepickerFilter')) {
      return 'Value invalid';
    }
    return 'Unknown error';
  }

  loadMonthlyRollReportData(searchButtonClicked: boolean) {

    if (searchButtonClicked) {
      // clear initial table state if the user is performing a new search
      this.clearQueryParams();
    }

    this.isLoading = true;
    this.displayResults = false;
    this.monthlyRolls$ = this.getMonthlyRolls(this.selectedClientDocId);

  }

  reset() {
    // this reset the form behavior to act as if it was rendered in the beginning
    this.effectiveRollFormRef.resetForm();
    this.displayResults = false;
    this.clearQueryParams();
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });
    this.monthlyRollForm.markAsPristine();
  }

  selectItem(rollItem: MonthlyRollReportDisplay) {
    this.router.navigate(['/contracts', rollItem.contractDocId]
      , rollItem.pricingSegmentDocId ?
        { queryParams: { segmentId: rollItem.pricingSegmentDocId }, queryParamsHandling: 'merge' }
        : undefined
    );
  }

  selectMonth(chosenMonth: moment.Moment) {
    this.monthlyRollForm.get('effectiveMonth').setValue(chosenMonth);
    this.monthlyRollForm.get('effectiveMonth').markAsTouched();
    // have to check if viewchild available since this function can be called from ngInit
    if (this.effectiveMonthRef) {
      this.effectiveMonthRef.close();
    }
  }

  validMonthFilter = (currentMoment: moment.Moment | null): boolean => {
    if (currentMoment) {
      return !this.permissibleMonths.length || this.permissibleMonths.includes(currentMoment.month());
    } else {
      return true;
    }
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }

  private getMonthlyRolls(clientDocId: string): Observable<MonthlyRollReportDisplay[]> {
    const commodityProfile = (this.monthlyRollForm.get('commodityProfile').value as CommodityProfile).docId;
    const month = this.translateMomentToContractMonth(this.monthlyRollForm.get('effectiveMonth').value);
    const rollType = this.monthlyRollForm.get('rollType').value;

    this.queryParams.month = month;
    this.queryParams.rollType = rollType;
    this.queryParams.commodity = commodityProfile;

    this.setMonthColumnVisibility(rollType);

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });

    return combineLatest([
      this.loadRollContracts(clientDocId, commodityProfile, rollType, month),
      this.loadRollPricingSegments(clientDocId, commodityProfile, rollType, month)
    ]).pipe(
      map(([contracts, pricings]) => {
        this.contractsExportable = !!contracts.length;
        this.pricingsExportable = !!pricings.length;
        this.isLoading = false;
        this.setReportTitlesAndSheetNames(month, commodityProfile, rollType);
        this.displayResults = true;
        this.contractsDataSource.data = contracts;
        this.pricingsDataSource.data = pricings;
        // combining and returning to the observable that template calls with async pipe
        return contracts.concat(pricings);
      }),
      catchError(err => {
        this.openSnackBar('Error retrieving contracts and pricings; please try again later', 'DISMISS', false);
        this.isLoading = false;
        console.error(`Error retrieving contracts and pricings: ${err}`);
        return of([]);
      })
    );
  }

  private getSingleContract(clientDocId: string, contractDocId: string): Observable<Contract> {
    const queriedResultContract = this.contracts.find(contract => contract.docId === contractDocId);
    return queriedResultContract ?
      of(queriedResultContract) :
      this.contractService.getContractByDocId(clientDocId, contractDocId);
  }

  private loadRollContracts(clientDocId: string, commodityProfile: string, rollType: RollType, month: string)
    : Observable<MonthlyRollReportDisplay[]> {

    let contractServiceCalls: Observable<Contract[]>[];

    if (rollType === RollType.DELIVERY) {
      contractServiceCalls = [
        ContractType.BASIS,
        ContractType.CASH,
        ContractType.DP,
        ContractType.HTA
      ].map((type: ContractType) => this.contractService.getDeliveryRollContracts(clientDocId, type, commodityProfile, month));
    } else {
      contractServiceCalls = [
        ContractType.BASIS,
        ContractType.CASH,
        ContractType.HTA
      ].map((type: ContractType) => this.contractService.getFuturesRollContracts(clientDocId, type, commodityProfile, month));
    }

    return combineLatest(contractServiceCalls).pipe(
      map((contracts: Contract[][]) => {
        this.contracts = contracts.flat();
        const reportableContracts = this.contracts.map((contract: Contract) => {
          return {
            accountingSystemId: contract.accountingSystemId,
            expiration: contract.expirationDate,
            deliveryPeriod: contract.deliveryPeriod,
            futuresYearMonth: contract.futuresYearMonth,
            originatorName: contract.originatorName,
            status: contract.status,
            patronName: contract.patronName,
            contractType: contract.type,
            quantity: contract.quantity,
            basisPrice: contract.basisPrice,
            futuresPrice: contract.futuresPrice,
            cashPrice: contract.cashPrice,
            contractDocId: contract.docId
          } as MonthlyRollReportDisplay;
        });
        return reportableContracts;
      }),
    );
  }

  private loadRollPricingSegments(clientDocId: string, commodityProfile: string, rollType: RollType, month: string)
    : Observable<MonthlyRollReportDisplay[]> {
    let pricingSegmentServiceCall: Observable<PricingSegment[]>;
    if (rollType === RollType.DELIVERY) {
      pricingSegmentServiceCall =
        this.pricingSegmentService.findPricingSegmentsForDeliveryMonthlyRoll(clientDocId, commodityProfile, month);
    } else {
      pricingSegmentServiceCall =
        this.pricingSegmentService.findPricingSegmentsForFuturesMonthlyRoll(clientDocId, commodityProfile, month);
    }
    return pricingSegmentServiceCall.pipe(
      switchMap((reportableSegments: PricingSegment[]) => {
        if (reportableSegments.length === 0) {
          return of([]);
        }
        return combineLatest(
          reportableSegments.map((pricingSegment: PricingSegment) => {
            return this.getSingleContract(clientDocId, pricingSegment.contractDocId).pipe(
              map((singleContract: Contract) => {
                // prevent double-reads for DP contracts
                if (!this.contracts.find(contract => contract.docId === singleContract.docId)) {
                  this.contracts.push(singleContract);
                }
                return {
                  accountingSystemId: singleContract.accountingSystemId,
                  expiration: singleContract.expirationDate,
                  deliveryPeriod: pricingSegment.deliveryPeriod,
                  futuresYearMonth: pricingSegment.futuresYearMonth,
                  originatorName: singleContract.originatorName,
                  status: pricingSegment.status,
                  patronName: singleContract.patronName,
                  contractType: pricingSegment.contractType,
                  quantity: pricingSegment.quantity,
                  basisPrice: pricingSegment.basisPrice,
                  futuresPrice: pricingSegment.futuresPrice,
                  cashPrice: pricingSegment.cashPrice,
                  contractDocId: pricingSegment.contractDocId,
                  pricingSegmentDocId: pricingSegment.docId
                } as MonthlyRollReportDisplay;
              })
            );
          })
        );
      })
    );
  }

  private onCommodityProfileChanges() {
    this.monthlyRollForm.get('commodityProfile').valueChanges.subscribe(() => {
      this.setPermissibleMonths();
    });
  }

  private onRollTypeChanges() {
    this.monthlyRollForm.get('rollType').valueChanges.subscribe(() => {
      this.setPermissibleMonths();
    });
  }

  private setPermissibleMonths() {
    // default to every month permissible
    this.permissibleMonths = [];
    const rollType = this.monthlyRollForm.get('rollType').value;
    // only continue if there's a value and it's FUTURES
    if (rollType === RollType.FUTURES) {
      const commodityProfile = this.monthlyRollForm.get('commodityProfile').value as CommodityProfile;
      // only continue if commodity profile has been selected and it's not the dummy being added from queryParams
      if (commodityProfile && commodityProfile.commodityId) {
        const commodity = this.commodities[commodityProfile.commodityId];
        // only continue if the commodity has been found and it has a defined set of contract months
        if (commodity && commodity.contractMonths.length) {
          this.permissibleMonths = [...commodity.contractMonths];
        }
      }
    }
    // apply filter to any existing value in the month field
    this.monthlyRollForm.get('effectiveMonth').updateValueAndValidity();
  }

  private setMonthColumnVisibility(rollType: RollType) {
    this.hideDeliveryPeriod = this.displaySingleMonthColumn && rollType === RollType.DELIVERY;
    this.hideFuturesYearMonth = this.displaySingleMonthColumn && rollType === RollType.FUTURES;
  }

  private setReportTitlesAndSheetNames(period: string, commodityProfileDocId: string, rollType: string) {
    const periodName = this.translateContractMonthToMoment(period).format(MONTH_YEAR_FORMAT);
    const selectedCommodityProfile = this.commodityProfiles.find(profile => profile.docId === commodityProfileDocId);
    const commodity = this.commodities[selectedCommodityProfile.commodityId];
    const rollTypeTitleCase = `${rollType.substr(0, 1)}${rollType.substr(1).toLowerCase()}`;
    this.contractReportTitle = `${periodName} ${commodity.name} ${rollTypeTitleCase} Contract Roll Report`;
    this.contractSheetName = `${periodName} ${commodity.id} ${rollTypeTitleCase} Contracts`;
    this.pricingReportTitle = `${periodName} ${commodity.name} ${rollTypeTitleCase} Pricing Roll Report`;
    this.pricingSheetName = `${periodName} ${commodity.id} ${rollTypeTitleCase} Pricings`;
  }

  private translateContractMonthToMoment(value: string): moment.Moment {
    const year = moment.parseTwoDigitYear(value.substring(0, 2));
    const monthCode = value.substring(2);
    const idxMonth = this.contractMonths.indexOf(monthCode);
    return moment().year(year).month(idxMonth).startOf('month');
  }

  private translateMomentToContractMonth(selectedMonth: moment.Moment): string {
    return selectedMonth ? selectedMonth.format(YEAR_FORMAT) + ContractMonth[this.contractMonths[selectedMonth.month()]] : undefined;
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 5000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

}
