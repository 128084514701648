import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AuthService } from '@advance-trading/angular-ati-security';
import { AdHocOrder, AdHocOrderStatus, Client, User } from '@advance-trading/ops-data-lib';

@Injectable({
  providedIn: 'root'
})
export class AdHocOrderService {

  constructor(
    private db: AngularFirestore,
    private authService: AuthService
  ) { }

  /**
   * Create a AdHocOrder document for the specified Client
   *
   * @param clientDocId The docId of the Client
   * @param adHocOrder The AdHocOrder object to add
   */
  createAdHocOrder(clientDocId: string, adHocOrder: AdHocOrder): Promise<void> {
    adHocOrder.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.doc<AdHocOrder>(`${Client.getDataPath(clientDocId)}/${AdHocOrder.getDataPath(adHocOrder.docId)}`)
            .set(adHocOrder.getPlainObject());
  }

  /**
   * Update a AdHocOrder document's status for the specified Client
   * @param clientDocId The docId of the Client
   * @param adHocOrderDocId The docId of AdHocOrder object to update
   * @param status The status to be given to the specified AdHocOrder
   */
  updateAdHocOrderStatus(clientDocId: string, adHocOrderDocId: string, status: AdHocOrderStatus): Promise<void> {
    const lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    const adHocOrderRef = this.db.doc<AdHocOrder>(`${Client.getDataPath(clientDocId)}/${AdHocOrder.getDataPath(adHocOrderDocId)}`).ref;
    return this.db.doc<User>(User.getDataPath(lastUpdatedByDocId)).get().toPromise().then(userSnapshot => {
      const user: User = userSnapshot.data() as User;
      return this.db.firestore.runTransaction(tx => {
        return tx.get(adHocOrderRef).then(adHocOrderDoc => {
          const adHocOrderData = adHocOrderDoc.data() as AdHocOrder;
          if (adHocOrderData.status === status) {
            // No update because status is the same
            throw new Error('AdHocOrder status cannot be updated to existing status');
          }
          return tx.update(adHocOrderRef, {
            status,
            lastUpdatedTimestamp: new Date().toISOString(),
            lastUpdatedByName: `${user.firstName} ${user.lastName}`,
            lastUpdatedByDocId
          });
        });
      });
    })
    .then(() => Promise.resolve());
  }

  /**
   * Update a AdHocOrder document for the specified Client
   *
   * @param clientDocId The docId of the Client the ad hoc order to update belongs to
   * @param adHocOrder The AdHocOrder object to update
   */

  updateAdHocOrder(clientDocId: string, adHocOrder: AdHocOrder): Promise<void> {
    adHocOrder.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;

    if (!adHocOrder.comments) {
      // @ts-ignore
      adHocOrder.comments = firebase.firestore.FieldValue.delete();
    }

    if (!adHocOrder.expirationDate) {
      // @ts-ignore
      adHocOrder.expirationDate = firebase.firestore.FieldValue.delete();
    }

    if (!Number.isFinite(adHocOrder.price)) {
      // @ts-ignore
      adHocOrder.price = firebase.firestore.FieldValue.delete();
    }

    return this.db.doc(`${Client.getDataPath(clientDocId)}/${AdHocOrder.getDataPath(adHocOrder.docId)}`).update(adHocOrder);
  }

  /** Return all adhoc orders for the specified Client
   * Return ad hoc order for the specified Client by docId
   *
   * @param clientDocId The docId of the Client containing the AdHocOrders
   * @param adHocOrderDocId The docId of Hedge to be returned
   */
  getAdHocOrderByDocId(clientDocId: string, adHocOrderDocId: string): Observable<AdHocOrder> {
    return this.db.doc<AdHocOrder>(`${Client.getDataPath(clientDocId)}/${AdHocOrder.getDataPath(adHocOrderDocId)}`)
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return all adhoc orders for the specified Client
   *
   * @param clientDocId The docId of the Client containing the AdHocOrders
   */
  getAllAdHocOrdersByClientDocId(clientDocId: string): Observable<AdHocOrder[]> {
    return this.db.collection<AdHocOrder>(`${Client.getDataPath(clientDocId)}/${AdHocOrder.getDataPath()}`)
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return ad hoc orders for the specified Client and Order
   * @param clientDocId The docId of the Client containing the AdHocOrders
   * @param orderDocId The docId of the Order of AdHocOrders being returned
   */
  getAdHocOrdersByOrderDocId(clientDocId: string, orderDocId: string): Observable<AdHocOrder[]> {
    return this.db.collection<AdHocOrder>(`${Client.getDataPath(clientDocId)}/${AdHocOrder.getDataPath()}`,
      ref => ref.where('orderDocId', '==', orderDocId))
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return ad hoc orders for the specified Client, status, and completion date range
   * @param clientDocId The docId of the Client containing the AdHocOrders
   * @param startDate The date before or when the AdHocOrders were completed
   * @param endDate The date after or when the AdHocOrders were completed
   */
     getAdHocOrdersBySearchParameters(
       clientDocId: string, startDate: string, endDate: string,
       contractYearMonth: string, commodityProfileDocId: string,
       lastUpdatedDate: string, lastUpdatedDateEnd: string,
       statuses: AdHocOrderStatus[]): Observable<AdHocOrder[]> {
      return this.db.collection<AdHocOrder>(`${Client.getDataPath(clientDocId)}/${AdHocOrder.getDataPath()}`,
        ref => {
          const inequalityField = lastUpdatedDate ? 'lastUpdatedTimestamp' : 'creationTimestamp';
          let finalRef = ref.orderBy(inequalityField, 'desc');
          if (inequalityField === 'creationTimestamp') {
            finalRef = finalRef.where('creationTimestamp', '>=', startDate).where('creationTimestamp', '<=', endDate);
          } else {
            finalRef = finalRef.where('lastUpdatedTimestamp', '>=', lastUpdatedDate)
                               .where('lastUpdatedTimestamp', '<=', lastUpdatedDateEnd);
          }
          if (commodityProfileDocId) {
            finalRef = finalRef.where('commodityProfileDocId', '==', commodityProfileDocId);
          }
          if (contractYearMonth) {
            finalRef = finalRef.where('contractYearMonth', '==', contractYearMonth);
          }
          if (statuses.length) {
            finalRef = finalRef.where('status', 'in', statuses);
          }
          return finalRef;
        }
      ).valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
    }

  /**
   * Return ad hoc orders for the specified Client, status, and completion date range
   * @param clientDocId The docId of the Client containing the AdHocOrders
   * @param startDate The date before or when the AdHocOrders were completed
   * @param endDate The date after or when the AdHocOrders were completed
   */
  getAdHocOrdersByCompletionDateRange(clientDocId: string, startDate: string, endDate: string): Observable<AdHocOrder[]> {
    return this.db.collection<AdHocOrder>(`${Client.getDataPath(clientDocId)}/${AdHocOrder.getDataPath()}`,
      ref => ref.where('completionTimestamp', '>=', startDate).where('completionTimestamp', '<=', endDate))
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }
}
