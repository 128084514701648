<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <div *ngIf="errorMessage">
      <hms-error-message [title]="'Error Retrieving Commodity Profile'" [message]="errorMessage"></hms-error-message>
    </div>

    <form *ngIf="!errorMessage && commodityMap$ | async" [formGroup]="commodityProfileForm" (ngSubmit)="checkForInactivationOfSpreadProfile()" fxLayout="column" fxLayoutGap="15px">
      <!-- Title and Buttons -->
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 data-cy="page-title-commodity-profile" class="card-title">Commodity Profile: {{ commodityProfile.name }}</h2>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
          <button type="button" id="edit-commodity-profile" class="action-button" aria-label="Edit Commodity Profile" matTooltip="Edit Commodity Profile" *ngIf="!editMode && updateComplete && isCommodityProfileAdmin" (click)="setEditMode(true)" mat-mini-fab color="accent">
            <mat-icon>edit</mat-icon>
          </button>
          <button type="submit" id="update-commodity-profile" class="action-button" aria-label="Update Commodity Profile" *ngIf="editMode && updateComplete" [disabled]="commodityProfileForm.pristine || !commodityProfileForm.valid" (click)="checkForInactivationOfSpreadProfile()" mat-mini-fab color="accent" matTooltip="Update Commodity Profile">
            <mat-icon>done</mat-icon>
          </button>
          <button type="button" id="clear-commodity-profile" class="action-button" aria-label="Discard Changes" *ngIf="editMode  && updateComplete" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-spinner *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
        </div>
      </div>

      <!-- Accounting System ID, Name, Account, isSpreadProfile, isActive -->
      <div fxLayoutGap.gt-xs="5%" fxLayoutGap.xs="0" fxLayout="row wrap" fxLayoutAlign.gt-xs="start start">
        <ati-labeled-data *ngIf="!commodityProfile.isSpreadProfile" fxFlex.gt-xs="20" fxFlex.xs="100" label="ID" [data]="commodityProfile.accountingSystemId"></ati-labeled-data>
        <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="20" fxFlex.xs="100" label="Name" [data]="commodityProfile.name"></ati-labeled-data>
        <mat-form-field fxFlex.gt-xs="20" fxFlex.xs="100" *ngIf="editMode">
          <input required matInput placeholder="Name" trim="blur" formControlName="name" />
          <mat-error *ngIf="commodityProfileForm.get('name').invalid">{{ getErrorMessage(commodityProfileForm.get('name')) }}</mat-error>
        </mat-form-field>
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="100" label="Account" [data]="accountNumber"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="10" fxFlex.xs="100" label="Spread Profile" [data]="commodityProfile.isSpreadProfile | yesNo"></ati-labeled-data>
        <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="10" fxFlex.xs="100" label="Status" [data]="commodityProfile.isActive ? 'Active' : 'Inactive'"></ati-labeled-data>
        <div *ngIf="editMode" class="checkbox-group" fxFlex.gt-xs="10" fxFlex.xs="100">
          <span class="check-radio-label mat-small mat-hint">Status</span>
          <mat-checkbox formControlName="isActive">Active</mat-checkbox>
        </div>
      </div>

      <!-- Commodity, Has Basis, Default Grade, Target Order Threshold -->
      <div *ngIf="!commodityProfile.isSpreadProfile" fxLayoutGap.gt-xs="5%" fxLayoutGap.xs="0" fxLayout="row wrap" fxLayoutAlign.gt-xs="start start">
        <ati-labeled-data fxFlex.gt-xs="20" fxFlex.xs="100" label="Commodity" [data]="commodityName"></ati-labeled-data>
        <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="20" fxFlex.xs="100" label="Basis" [data]="commodityProfile.hasBasis ? 'Enabled' : 'Disabled'"></ati-labeled-data>
        <div *ngIf="editMode" class="checkbox-group" fxFlex.gt-xs="20" fxFlex.xs="100">
          <span class="check-radio-label mat-small mat-hint">Basis</span>
          <mat-checkbox formControlName="hasBasis">Enabled</mat-checkbox>
        </div>
        <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="20" fxFlex.xs="100" label="Default Grade" [data]="commodityProfile.defaultGrade"></ati-labeled-data>
        <mat-form-field fxFlex.gt-xs="20" fxFlex.xs="100" *ngIf="editMode">
          <input required matInput placeholder="Default Grade" trim="blur" mask="A*" formControlName="defaultGrade" />
          <mat-error *ngIf="commodityProfileForm.get('defaultGrade').invalid">{{ getErrorMessage(commodityProfileForm.get('defaultGrade')) }}</mat-error>
        </mat-form-field>
        <ati-labeled-data *ngIf="!editMode || !isHMSAdmin" fxFlex.gt-xs="20" fxFlex.xs="100" label="Target Order Threshold" [data]="commodityProfile.targetOrderThreshold | number: '1.0-0'"></ati-labeled-data>
        <mat-form-field fxFlex.gt-xs="20" fxFlex.xs="100" *ngIf="editMode && isHMSAdmin">
          <input required matInput placeholder="Target Order Threshold" formControlName="targetOrderThreshold" trim="blur">
          <mat-error *ngIf="commodityProfileForm.get('targetOrderThreshold').invalid">{{ getErrorMessage(commodityProfileForm.get('targetOrderThreshold')) }}</mat-error>
        </mat-form-field>
      </div>

      <!-- Min Price, Max Price, Short Threshold, Long Threshold -->
      <div *ngIf="!commodityProfile.isSpreadProfile" fxLayoutGap.gt-xs="5%" fxLayoutGap.xs="0" fxLayout="row wrap" fxLayoutAlign.gt-xs="start start">
        <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="20" fxFlex.xs="100" label="Minimum Price" [data]="commodityProfile.minPrice | number: '1.4-4'"></ati-labeled-data>
        <mat-form-field fxFlex.gt-xs="20" fxFlex.xs="100" *ngIf="editMode">
          <input (blur)="format4('minPrice')" required matInput placeholder="Minimum Price" formControlName="minPrice" trim="blur">
          <mat-error *ngIf="commodityProfileForm.get('minPrice').invalid">{{ getErrorMessage(commodityProfileForm.get('minPrice')) }}</mat-error>
        </mat-form-field>
        <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="20" fxFlex.xs="100" label="Maximum Price" [data]="commodityProfile.maxPrice | number: '1.4-4'"></ati-labeled-data>
        <mat-form-field fxFlex.gt-xs="20" fxFlex.xs="100" *ngIf="editMode">
          <input (blur)="format4('maxPrice')" required matInput placeholder="Maximum Price" formControlName="maxPrice" trim="blur">
          <mat-error *ngIf="commodityProfileForm.get('maxPrice').invalid">{{ getErrorMessage(commodityProfileForm.get('maxPrice')) }}</mat-error>
        </mat-form-field>
        <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="20" fxFlex.xs="100" label="Short Threshold" [data]="commodityProfile.shortThreshold | number: '1.0-0'"></ati-labeled-data>
        <mat-form-field fxFlex.gt-xs="20" fxFlex.xs="100" *ngIf="editMode">
          <input (blur)="ensureNegative('shortThreshold')" required matInput placeholder="Short Threshold" formControlName="shortThreshold" trim="blur">
          <mat-error *ngIf="commodityProfileForm.get('shortThreshold').invalid">{{ getErrorMessage(commodityProfileForm.get('shortThreshold')) }}</mat-error>
        </mat-form-field>
        <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="20" fxFlex.xs="100" label="Long Threshold" [data]="commodityProfile.longThreshold | number: '1.0-0'"></ati-labeled-data>
        <mat-form-field fxFlex.gt-xs="20" fxFlex.xs="100" *ngIf="editMode">
          <input required matInput placeholder="Long Threshold" formControlName="longThreshold" trim="blur">
          <mat-error *ngIf="commodityProfileForm.get('longThreshold').invalid">{{ getErrorMessage(commodityProfileForm.get('longThreshold')) }}</mat-error>
        </mat-form-field>
      </div>

      <!-- Production Years -->
      <div *ngIf="!commodityProfile.isSpreadProfile">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h4>Production Years</h4>
          <button *ngIf="canRoll" id="roll-production-years" class="action-button" type="button" aria-label="Roll Production Years" color="accent" (click)="rollProductionYears()" mat-mini-fab color="accent" matTooltip="Roll Production Years">
            <mat-icon>fast_forward</mat-icon>
          </button>
        </div>
        <hms-production-year *ngFor="let productionYearGroup of prodYearsControls | keyvalue ; trackBy:trackByYear" [commodityId]="commodity.id"
          [futuresMonths]="futuresMonths" [productionYear]="productionYearGroup.value" (productionYearUpdated)="onProductionYearUpdated($event)" (productionYearRemoved)="onProductionYearRemoved($event)"
          [productionYears]="commodityProfileForm.get('prodYears')" [lastAddedProductionYear]="lastAddedProductionYear" [addingProdYear]="addingProdYear" [editMode]="editMode" [createMode]="false" [preservedProductionYears]="preservedProductionYears" [ledgerEndOfDay]="ledgerEndOfDay" [timezone]="timezone"></hms-production-year>
        <div class="production-year-btn-container">
          <button *ngIf="editMode && !addingProdYear && canAdd" id="add-production-year" type="button" aria-label="Add Production Year" color="accent" mat-icon-button (click)="addProductionYear()" matTooltip="Add Production Year">
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
        <hms-production-year *ngIf="addingProdYear" [commodityId]="commodity.id"
          [futuresMonths]="futuresMonths" (productionYearUpdated)="onProductionYearCreated($event)" (productionYearRemoved)="onProductionYearRemoved($event)"
          [productionYears]="commodityProfileForm.get('prodYears')" [lastAddedProductionYear]="lastAddedProductionYear" #newProdYear [editMode]="editMode" [createMode]="false" [ledgerEndOfDay]="ledgerEndOfDay" [timezone]="timezone"></hms-production-year>

      </div>
    </form>
  </mat-card>
</div>
