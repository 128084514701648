import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommodityProfileService, ExecutionReportService, OperationsDataService, OrderFill, OrderService } from '@advance-trading/angular-ops-data';
import { Client, CommodityMap, CommodityProfile, Hedge, HedgeType, HMSClientSettings, Order } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { HedgeService } from '../../service/hedge.service';
import { LedgerHelperService } from '../../service/ledger-helper.service';
import { OrderFillDisplay } from '../../utilities/order-fill-display';
import { UserRoles } from '../../utilities/user-roles';

import { HedgeDisplay } from '../hedge-display';

@Component({
  selector: 'hms-hedge-detail',
  templateUrl: './hedge-detail.component.html',
  styleUrls: ['./hedge-detail.component.scss']
})
export class HedgeDetailComponent implements OnInit {
  hedgeForm: FormGroup = this.formBuilder.group({
    orderDocId: ['', [Validators.required]]
  });

  hedge$: Observable<HedgeDisplay>;

  errorMessage: string;
  editMode = false;
  isLoading = true;
  updateComplete = true;

  quantityUnit: string;

  fillDataSource = new ObservableDataSource<OrderFillDisplay>();
  fillColumnsToDisplay = [];

  private commodityMap: CommodityMap;
  private commodityProfile: CommodityProfile;
  private existingOrder: Order;
  private hedge: Hedge;
  private selectedClientDocId: string;
  private ledgerEndOfDay: string;
  private timezone: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private executionReportService: ExecutionReportService,
    private formBuilder: FormBuilder,
    private hedgeService: HedgeService,
    private ledgerHelperService: LedgerHelperService,
    private operationsDataService: OperationsDataService,
    private orderService: OrderService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.HEDGE_VIEWER_ROLE) &&
      !this.authzService.currentUserHasRole(UserRoles.FULL_LEDGER_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to view hedges.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    const hedgeDocId = this.activatedRoute.snapshot.paramMap.get('docId');

    this.hedge$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((doc: CommodityMap) => {
        this.commodityMap = doc;
        return this.clientSelectorService.getSelectedClient();
      }),
      switchMap((selectedClient: Client) => {
        this.selectedClientDocId = selectedClient.docId;
        return this.clientSettingsService.getHmsSettingsByClientDocId(this.selectedClientDocId);
      }),
      switchMap((clientSettings: HMSClientSettings) => {
        this.ledgerEndOfDay = clientSettings.ledgerEndOfDay;
        this.timezone = clientSettings.timezone;
        return this.hedgeService.getHedgeByDocId(this.selectedClientDocId, hedgeDocId);
      }),
      switchMap((hedge: Hedge) => {
        this.hedge = hedge;
        this.setupHedgeForm();

        return this.commodityProfileService.getCommodityProfileByDocId(this.selectedClientDocId, hedge.commodityProfileDocId).pipe(
          map((profile: CommodityProfile) => {
            this.commodityProfile = profile;
            const currCommodity = this.commodityMap.commodities[profile.commodityId];
            const contractSize = currCommodity.contractSize;
            // Show how many contract made for display
            const quantityInContracts = hedge.quantity / contractSize;
            // Get hedge production year label from the commodity profile
            const productionYearLabel = this.ledgerHelperService.getProdYearLabelForHedge(
              this.hedge, this.commodityProfile, this.ledgerEndOfDay, this.timezone);

            this.quantityUnit = currCommodity.contractUnit;

            return { ...hedge, commodityProfileName: profile.name, quantityInContracts, productionYearLabel } as HedgeDisplay;
          })
        );
      }),
      switchMap((hedge: HedgeDisplay) => {
        if (hedge.orderDocId) {
          return this.orderService.getOrderByDocId(this.commodityProfile.accountDocId, hedge.orderDocId).pipe(
            map((order: Order) => {
              this.existingOrder = order;
              return hedge;
            }),
            tap(() => {
              this.getOrderFill();
            }),
            catchError(err => {
              console.error(`Error retrieving Order associated with Hedge: ${err}`);
              return of(hedge);
            })
          );
        } else {
          return of(hedge);
        }
      }),
      tap((hedge: HedgeDisplay) => {
        this.isLoading = false;
      }),
      catchError(err => {
        this.isLoading = false;
        this.errorMessage = 'Error retrieving hedge details; please try again later';
        console.error(`Error retrieving hedge details: ${err}`);
        return of(undefined);
      })
    );
  }

  getDisplayPrice(price: number, symbol: string) {
    const priceDivisor = this.commodityMap.commodities[symbol] ? this.commodityMap.commodities[symbol].marketDataDivisor : 1;
    return price / priceDivisor;
  }

  getContractLabel(quantity: number) {
    return quantity === 1 ? 'contract' : 'contracts';
  }

  getOrderLink() {
    if (!this.hedge.orderDocId) {
      return '';
    }
    return this.router.createUrlTree(['/accounts', this.commodityProfile.accountDocId, 'orders', this.hedge.orderDocId]).toString();
  }

  setupHedgeForm() {
    this.hedgeForm.get('orderDocId').setValue(this.hedge.orderDocId);
  }

  setEditMode(mode: boolean): void {
    this.editMode = mode;
  }

  reset() {
    this.setEditMode(false);
    this.setupHedgeForm();
    this.hedgeForm.markAsPristine();
  }

  submitHedgeUpdate() {
    const orderDocId = this.hedgeForm.get('orderDocId').value;
    this.hedge.orderDocId = orderDocId;

    this.updateComplete = false;
    this.hedgeService.updateHedge(this.selectedClientDocId, this.hedge)
      .then(() => {
        this.updateComplete = true;
        console.log('Hedge successfully updated');
        this.openSnackBar('Hedge successfully updated', 'DISMISS', true);
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`Hedge update failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Hedge update failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    }
    return 'Unknown error';
  }

  get canEditHedge() {
    return !this.editMode && !this.isLoading && this.updateComplete && this.authzService.currentUserHasRole(UserRoles.HEDGE_CREATOR_ROLE)
      && this.hedge && (!this.hedge.type || this.hedge.type === HedgeType.STANDARD);
  }

  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private getOrderFill() {
    this.fillColumnsToDisplay = ['quantity', 'orderFillPrice'];
    this.fillDataSource.data$ = this.executionReportService
      .getOrderFillsByOrderDocId(this.existingOrder.accountDocId, this.existingOrder.docId).pipe(
        map((fills: OrderFill[]) => {
          return fills.map(fill => {
            return {
              quantity: fill.fillQuantity,
              orderSymbol: this.existingOrder.symbol,
              orderFillPrice: fill.fillPrice,
            } as OrderFillDisplay;
          });
        })
      );
  }

}
