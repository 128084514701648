<div fxLayout="row" fxLayoutAlign="space-around start">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <!-- Error message -->
    <hms-error-message [title]="'Error Retrieving New Ledger Adjustment Form'" [message]="errorMessage"></hms-error-message>

    <form *ngIf="!errorMessage" [formGroup]="adjustmentForm" (ngSubmit)="submit()" fxLayout="column" fxLayoutGap="15px">
      <!-- Title and Buttons -->
      <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
          <h2 class="card-title">New Ledger Adjustment</h2>
          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
            <button type="submit" id="create-adjustment" class="action-button" aria-label="Create Adjustment" *ngIf="updateComplete" [disabled]="!adjustmentForm.valid" mat-mini-fab color="accent" matTooltip="Create Adjustment">
                <mat-icon>done</mat-icon>
            </button>
            <button type="button" id="clear-adjustment" class="action-button" aria-label="Discard Changes" *ngIf="!adjustmentForm.pristine && updateComplete" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes">
                <mat-icon>clear</mat-icon>
            </button>
            <mat-spinner *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
          </div>
      </div>

      <!-- Commodity Profile, Quantity, ProdYear -->
      <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start start" fxLayout.xs="column" fxLayoutGap.xs="15px">
        <mat-form-field  fxFlex.gt-xs="250px">
          <mat-select required placeholder="Commodity Profile" formControlName="commodityProfile">
            <mat-option *ngFor="let profile of commodityProfiles$ | async" [value]="profile">{{ profile.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="adjustmentForm.get('commodityProfile').invalid">{{ getErrorMessage(adjustmentForm.get('commodityProfile')) }}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="adjustmentForm.get('commodityProfile').value" fxFlex.gt-xs="250px">
          <mat-select required placeholder="Production Year" formControlName="prodYear">
            <mat-option *ngFor="let prodYear of prodYears" [value]="prodYear">{{ prodYear.label | productionYear }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="adjustmentForm.get('commodityProfile').value && commodities" fxFlex.gt-xs="250px">
          <input required matInput [placeholder]="getContractUnit(commodities, adjustmentForm.get('commodityProfile').value) | contractUnit" formControlName="quantity">
          <mat-error *ngIf="adjustmentForm.get('quantity').invalid">{{ getErrorMessage(adjustmentForm.get('quantity')) }}</mat-error>
          <button type="button" id="clear-new-ledger-adjustment-quantity" mat-button *ngIf="adjustmentForm.get('quantity').value" matSuffix mat-icon-button aria-label="Clear Quantity" matTooltip="Clear Quantity" (click)="clearField('quantity')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <!-- Comments -->
      <mat-form-field>
        <textarea matInput placeholder="Comments" formControlName="comments" trim="blur"></textarea>
        <mat-error *ngIf="adjustmentForm.get('comments').invalid">{{ getErrorMessage(adjustmentForm.get('comments')) }}</mat-error>
        <button type="button" id="clear-new-ledger-adjustment-comments" mat-button *ngIf="adjustmentForm.get('comments').value" matSuffix mat-icon-button aria-label="Clear Comments" matTooltip="Clear Comments" (click)="clearField('comments')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </form>

  </mat-card>
</div>
