<div fxFlex="100">
  <div *ngIf="errorMessage">
    <hms-error-message [title]="'Error Retrieving Contract History'" [message]="errorMessage"></hms-error-message>
  </div>

  <div *ngIf="!errorMessage" fxLayout="column">
    <mat-table [dataSource]="dataSource" class="mat-elevation-z0 full-width">
      <ng-container matColumnDef="eventType">
        <mat-header-cell *matHeaderCellDef fxFlex.xs="24" fxFlex.gt-xs="25">Event Type</mat-header-cell>
        <mat-cell *matCellDef="let contractHistory" fxFlex.xs="24" fxFlex.gt-xs="25" >
          <div>
            <div>{{ contractHistory.eventType }}</div>
            <div *ngIf="contractHistory.identifier">
              <small>{{ contractHistory.identifier }}</small>
            </div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="details">
        <mat-header-cell *matHeaderCellDef fxFlex.xs="28" fxFlex.gt-xs="30">Details</mat-header-cell>
        <mat-cell *matCellDef="let contractHistory" fxFlex.xs="28" fxFlex.gt-xs="30" fxLayout="row wrap">{{ contractHistory.details }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastUpdatedBy">
        <mat-header-cell *matHeaderCellDef fxFlex.xs="23" fxFlex.gt-xs="20">Updated By</mat-header-cell>
        <mat-cell *matCellDef="let contractHistory" fxFlex.xs="23" fxFlex.gt-xs="20">{{ contractHistory.lastUpdatedBy }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="timestamp">
        <mat-header-cell *matHeaderCellDef fxFlex.xs="25" fxFlex.gt-xs="25">Timestamp</mat-header-cell>
        <mat-cell *matCellDef="let contractHistory" fxFlex.xs="25" fxFlex.gt-xs="25">{{ contractHistory.timestamp | date: 'MM/dd/yyyy hh:mm:ss a' }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let contractHistory; columns: columnsToDisplay; let i = index;" attr.data-cy="history-{{i}}"></mat-row>
    </mat-table>
    <mat-spinner *ngIf="isLoading" class="loading-spinner" diameter="40" color="accent"></mat-spinner>
  </div>
</div>
