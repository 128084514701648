<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <hms-error-message [title]="'Error Retrieving Monthly Roll Report'" [message]="errorMessage"></hms-error-message>

    <div *ngIf="!errorMessage && commodityProfiles$ | async" fxLayout="column" fxLayoutAlign="space-between start">
      <h2 data-cy="page-title-monthly-roll" class="card-title">Monthly Roll</h2>
      <form #form="ngForm" [formGroup]="monthlyRollForm" (ngSubmit)="loadMonthlyRollReportData(true)" fxLayout="column" class="full-width" fxLayoutGap="15px">
        <!-- Commodity Profile -->
        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
          <mat-form-field fxFlex.gt-xs="30">
            <mat-select required placeholder="Commodity Profile" formControlName="commodityProfile" [compareWith]="compareCommodityProfile">
              <mat-option *ngFor="let profile of commodityProfiles" [value]="profile">{{ profile.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="monthlyRollForm.get('commodityProfile').invalid">{{ getErrorMessage(monthlyRollForm.get('commodityProfile')) }}</mat-error>
          </mat-form-field>
        </div>
        <!-- Roll Type -->
        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
          <div>
            <mat-button-toggle-group formControlName="rollType" aria-label="Roll Type" matTooltip="Roll Type">
              <mat-button-toggle *ngFor="let type of rollTypes" [value]="type">{{ type | titlecase }}</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <!-- Effective Month -->
        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
          <mat-form-field fxFlex.gt-xs="30" hmsMonthPicker>
            <input required matInput [matDatepicker]="effectiveMonthPicker" [matDatepickerFilter]="validMonthFilter"  [placeholder]="monthLabel" formControlName="effectiveMonth" (keydown)="allowTabOnly($event)">
            <mat-datepicker-toggle matSuffix [for]="effectiveMonthPicker"></mat-datepicker-toggle>
            <mat-datepicker #effectiveMonthPicker startView="year" (monthSelected)="selectMonth($event)" panelClass="month-picker"></mat-datepicker>
            <mat-error *ngIf="monthlyRollForm.get('effectiveMonth').invalid">{{ getErrorMessage(monthlyRollForm.get('effectiveMonth')) }}</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="!isLoading" fxLayout="row" fxLayoutGap="10px">
          <button type="submit" id="generate-monthly-roll-report" [disabled]="monthlyRollForm.invalid" aria-label="Generate Report" mat-raised-button color="accent" (click)="loadMonthlyRollReportData(true)">Generate Report</button>
          <button type="button" id="clear-monthly-roll-report" *ngIf="!monthlyRollForm.pristine" aria-label="Discard Report Options" (click)="reset()" mat-stroked-button color="accent">Reset</button>
        </div>
        <div *ngIf="isLoading" fxLayout="row" fxLayoutGap="10px">
          <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
        </div>
      </form>

    </div>

    <ng-container  *ngIf="monthlyRolls$ | async">
      <div *ngIf="!errorMessage && displayResults" fxLayout="column">
        <h4>Contracts To Roll
          <button type="button" *ngIf="contractsExportable" matTooltip="Export" aria-label="Export" id="export-monthly-contract-roll-report" mat-icon-button color="primary" class="export-btn" [matMenuTriggerFor]="contractExportMenu" type="button"><mat-icon>save_alt</mat-icon></button>
        </h4>
        <mat-menu #contractExportMenu="matMenu">
          <button type="button" aria-label="Export Excel" id="export-monthly-roll-contract-report-as-xlsx" mat-menu-item (click)="contractExporter.exportTable('xlsx', exportService.getExportOptions('xlsx', contractReportTitle, contractSheetName))">Excel</button>
          <button type="button" aria-label="Export CSV" id="export-monthly-roll-contract-report-as-csv" mat-menu-item (click)="contractExporter.exportTable('csv', exportService.getExportOptions('csv', contractReportTitle, contractSheetName))">CSV</button>
          <button type="button" aria-label="Export JSON" id="export-monthly-roll-contract-report-as-json" mat-menu-item (click)="contractExporter.exportTable('json', exportService.getExportOptions('json', contractReportTitle, contractSheetName))">JSON</button>
        </mat-menu>
        <div fxLayout="column" fxFlex="100">
          <mat-table matTableExporter #contractExporter="matTableExporter" [dataSource]="contractsDataSource" class="mat-elevation-z0 bottom-padded" [ngClass]="{ 'cdk-visually-hidden':!contractsDataSource.data.length }">
            <ng-container matColumnDef="accountingSystemId">
              <mat-header-cell *matHeaderCellDef fxFlex.xs ="18" fxFlex.sm="11" fxFlex.md="8" fxFlex.gt-md="7">ID</mat-header-cell>
              <mat-cell *matCellDef="let rollItem"  fxFlex.xs ="18" fxFlex.sm="11" fxFlex.md="8" fxFlex.gt-md="7">{{ rollItem.accountingSystemId }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="expiration">
              <mat-header-cell *matHeaderCellDef fxFlex.gt-md="8">Expiration</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.gt-md="8">{{ rollItem.expiration | date: 'M/d/yyyy' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="deliveryPeriod">
              <mat-header-cell *matHeaderCellDef fxFlex.xs ="18" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="5" [ngClass]="{'cdk-visually-hidden': hideDeliveryPeriod }">Delivery Period</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.xs ="18" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="5" [ngClass]="{'cdk-visually-hidden': hideDeliveryPeriod }">{{ rollItem.deliveryPeriod | codeToMonth }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="futuresYearMonth">
              <mat-header-cell *matHeaderCellDef fxFlex.xs ="18" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="5" [ngClass]="{'cdk-visually-hidden': hideFuturesYearMonth }">Futures Month</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.xs ="18" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="5" [ngClass]="{'cdk-visually-hidden': hideFuturesYearMonth }">{{ rollItem.futuresYearMonth | codeToMonth }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="originatorName">
              <mat-header-cell *matHeaderCellDef fxFlex.md="15" fxFlex.gt-md="15">Originator</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.md="15" fxFlex.gt-md="15">{{ rollItem.originatorName }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef fxFlex.xs ="32" fxFlex.sm="17" fxFlex.md="14" fxFlex.gt-md="12">Status</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.xs ="32" fxFlex.sm="17" fxFlex.md="14" fxFlex.gt-md="12">{{ rollItem.status | replace | titlecase }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="patronName">
              <mat-header-cell *matHeaderCellDef fxFlex.sm="16" fxFlex.md="14" fxFlex.gt-md="15">Patron</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.sm="16" fxFlex.md="14" fxFlex.gt-md="15">{{ rollItem.patronName }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="contractType">
              <mat-header-cell *matHeaderCellDef fxFlex.xs ="14" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="8">Type</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.xs ="14" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="8">{{ rollItem.contractType | contractType }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="quantity">
              <mat-header-cell *matHeaderCellDef fxFlex.xs ="18" fxFlex.sm="11" fxFlex.md="9" fxFlex.gt-md="7">Qty</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.xs ="18" fxFlex.sm="11" fxFlex.md="9" fxFlex.gt-md="7">{{ rollItem.quantity | number:'1.0-2' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="basisPrice">
              <mat-header-cell *matHeaderCellDef fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="6">Basis</mat-header-cell>
              <mat-cell *matCellDef="let rollItem"  fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="6">{{ rollItem.basisPrice | number:'1.4-4' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="futuresPrice">
              <mat-header-cell *matHeaderCellDef fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="6">Futures</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="6">{{ rollItem.futuresPrice | number:'1.4-4' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="cashPrice">
              <mat-header-cell *matHeaderCellDef fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="6">Cash</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="6">{{ rollItem.cashPrice | number:'1.4-4' }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row (click)="selectItem(rollItem)" *matRowDef="let rollItem; columns: columnsToDisplay"></mat-row>
          </mat-table>
          <div fxFlex="100" *ngIf="!contractsDataSource.data.length" class="bottom-padded"><mat-divider></mat-divider><div class="no-data">No Contracts to be Rolled</div><mat-divider></mat-divider></div>
        </div>
        <h4>Pricings To Roll
          <button type="button" *ngIf="pricingsExportable" matTooltip="Export" aria-label="Export" id="export-monthly-pricing-roll-report" mat-icon-button color="primary" class="export-btn" [matMenuTriggerFor]="pricingExportMenu" type="button"><mat-icon>save_alt</mat-icon></button>
        </h4>
        <mat-menu #pricingExportMenu="matMenu">
          <button type="button" aria-label="Export Excel" id="export-monthly-roll-pricing-report-as-xlsx" mat-menu-item (click)="pricingExporter.exportTable('xlsx', exportService.getExportOptions('xlsx', pricingReportTitle, pricingSheetName))">Excel</button>
          <button type="button" aria-label="Export CSV" id="export-monthly-roll-pricing-report-as-csv" mat-menu-item (click)="pricingExporter.exportTable('csv', exportService.getExportOptions('csv', pricingReportTitle, pricingSheetName))">CSV</button>
          <button type="button" aria-label="Export JSON" id="export-monthly-roll-pricing-report-as-json" mat-menu-item (click)="pricingExporter.exportTable('json', exportService.getExportOptions('json', pricingReportTitle, pricingSheetName))">JSON</button>
        </mat-menu>
        <div fxLayout="column" fxFlex="100">
          <mat-table matTableExporter #pricingExporter="matTableExporter" [dataSource]="pricingsDataSource" class="mat-elevation-z0 bottom-padded" [ngClass]="{ 'cdk-visually-hidden':!pricingsDataSource.data.length }">
            <ng-container matColumnDef="accountingSystemId">
              <mat-header-cell *matHeaderCellDef fxFlex.xs ="18" fxFlex.sm="11" fxFlex.md="8" fxFlex.gt-md="7">Contract ID</mat-header-cell>
              <mat-cell *matCellDef="let rollItem"  fxFlex.xs ="18" fxFlex.sm="11" fxFlex.md="8" fxFlex.gt-md="7">{{ rollItem.accountingSystemId }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="expiration">
              <mat-header-cell *matHeaderCellDef fxFlex.gt-md="8">Expiration</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.gt-md="8">{{ rollItem.expiration | date: 'M/d/yyyy' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="deliveryPeriod">
              <mat-header-cell *matHeaderCellDef fxFlex.xs ="18" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="5" [ngClass]="{'cdk-visually-hidden': hideDeliveryPeriod }">Delivery Period</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.xs ="18" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="5" [ngClass]="{'cdk-visually-hidden': hideDeliveryPeriod }">{{ rollItem.deliveryPeriod | codeToMonth }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="futuresYearMonth">
              <mat-header-cell *matHeaderCellDef fxFlex.xs ="18" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="5" [ngClass]="{'cdk-visually-hidden': hideFuturesYearMonth }">Futures Month</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.xs ="18" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="5" [ngClass]="{'cdk-visually-hidden': hideFuturesYearMonth }">{{ rollItem.futuresYearMonth | codeToMonth }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="originatorName">
              <mat-header-cell *matHeaderCellDef fxFlex.md="15" fxFlex.gt-md="15">Originator</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.md="15" fxFlex.gt-md="15">{{ rollItem.originatorName }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef fxFlex.xs ="32" fxFlex.sm="17" fxFlex.md="14" fxFlex.gt-md="12">Status</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.xs ="32" fxFlex.sm="17" fxFlex.md="14" fxFlex.gt-md="12">{{ rollItem.status | replace | titlecase }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="patronName">
              <mat-header-cell *matHeaderCellDef fxFlex.sm="16" fxFlex.md="14" fxFlex.gt-md="15">Patron</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.sm="16" fxFlex.md="14" fxFlex.gt-md="15">{{ rollItem.patronName }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="contractType">
              <mat-header-cell *matHeaderCellDef fxFlex.xs ="14" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="8">Type</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.xs ="14" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="8">{{ rollItem.contractType | contractType }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="quantity">
              <mat-header-cell *matHeaderCellDef fxFlex.xs ="18" fxFlex.sm="11" fxFlex.md="9" fxFlex.gt-md="7">Qty</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.xs ="18" fxFlex.sm="11" fxFlex.md="9" fxFlex.gt-md="7">{{ rollItem.quantity | number:'1.0-2' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="basisPrice">
              <mat-header-cell *matHeaderCellDef fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="6">Basis</mat-header-cell>
              <mat-cell *matCellDef="let rollItem"  fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="6">{{ rollItem.basisPrice | number:'1.4-4' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="futuresPrice">
              <mat-header-cell *matHeaderCellDef fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="6">Futures</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="6">{{ rollItem.futuresPrice | number:'1.4-4' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="cashPrice">
              <mat-header-cell *matHeaderCellDef fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="6">Cash</mat-header-cell>
              <mat-cell *matCellDef="let rollItem" fxFlex.sm="9" fxFlex.md="8" fxFlex.gt-md="6">{{ rollItem.cashPrice | number:'1.4-4' }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row (click)="selectItem(rollItem)" *matRowDef="let rollItem; columns: columnsToDisplay"></mat-row>

          </mat-table>
          <div fxFlex="100" *ngIf="!pricingsDataSource.data.length" class="bottom-padded"><mat-divider></mat-divider><div class="no-data">No Pricings to be Rolled</div><mat-divider></mat-divider></div>
        </div>
      </div>
    </ng-container>
  </mat-card>
</div>
