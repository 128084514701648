import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommodityProfileService, ExecutionReportService, OperationsDataService, OrderFill, OrderService } from '@advance-trading/angular-ops-data';
import { Client, Commodity, CommodityMap, CommodityProfile, HMSClientSettings, Order, SecurityType, Side } from '@advance-trading/ops-data-lib';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';

import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { OrderFillReportDisplay } from './order-fill-report-display';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { ExportService } from '../../service/export.service';

import { UserRoles } from '../../utilities/user-roles';

const MILITARY_FORMAT = 'HH:mm';

@Component({
  selector: 'hms-order-fill',
  templateUrl: './order-fill.component.html',
  styleUrls: ['./order-fill.component.scss']
})
export class OrderFillComponent implements OnInit {
  orderFillSearchForm: FormGroup = this.formBuilder.group({
    startDate: ['', { updateOn: 'blur' }],
    endDate: ['', { updateOn: 'blur' }]
  });

  clientSettings$: Observable<HMSClientSettings>;

  errorMessage: string;
  isLoading = true;
  displayReports = false;

  accountMap: { [key: string]: string } = {};
  fills$: Observable<OrderFillReportDisplay[]>;
  fillDataSourceMap: { [key: string]: MatTableDataSource<OrderFillReportDisplay> } = {};
  fillColumnsToDisplay;
  footerColumnsToDisplay = ['footer'];

  private commodityMap: CommodityMap;
  private ledgerEndOfDay: string;
  private selectedClientDocId: string;
  private timezone: string;
  private cachedOptionCommodityMap: { [key: string]: Commodity };

  private queryParams: Params;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private breakpointObserver: BreakpointObserver,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private executionReportService: ExecutionReportService,
    public exportService: ExportService,
    private formBuilder: FormBuilder,
    private operationsDataService: OperationsDataService,
    private orderService: OrderService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.OPERATIONS_REPORTS_GENERATOR_ROLE)) {
      this.errorMessage = 'You do not have permission to access the order fill report.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe(state => {
      // display columns for xsmall screen
      if (state.matches) {
        this.fillColumnsToDisplay = [
          'orderDocId', 'security', 'fillQuantity', 'orderFillPrice'
        ];
        // display columns for larger screens
      } else {
        this.fillColumnsToDisplay = [
          'orderDocId', 'fillTimestamp', 'security', 'side', 'fillQuantity', 'orderTargetPrice', 'orderFillPrice', 'orderBuyPrice', 'orderSellPrice'
        ];
      }
    });

    this.clientSettings$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((client: Client) => {
        this.selectedClientDocId = client.docId;
        return this.clientSettingsService.getHmsSettingsByClientDocId(this.selectedClientDocId);
      }),
      tap((clientSettings: HMSClientSettings) => {
        this.ledgerEndOfDay = clientSettings.ledgerEndOfDay;
        this.timezone = clientSettings.timezone;
        this.reset();
        this.isLoading = false;

        this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
          this.queryParams = Object.assign({}, params);
          if (this.queryParams.startDate && this.queryParams.endDate) {
            // cancels out converted startDate for proper datepicker display
            this.orderFillSearchForm.get('startDate').setValue(moment(this.queryParams.startDate).add(1, 'days').toISOString());
            this.orderFillSearchForm.get('endDate').setValue(this.queryParams.endDate);
            this.orderFillSearchForm.markAsDirty();
            this.loadOrderFillReportData();
          } else {
            this.orderFillSearchForm.get('startDate').setValue(this.currentBusinessDay);
            this.orderFillSearchForm.get('endDate').setValue(this.currentBusinessDay);
            this.orderFillSearchForm.markAsDirty();
          }
        });
      })
    );
  }

  loadOrderFillReportData() {
    let startDate = this.getDatepickerValueAsISOString('startDate');
    let endDate = this.getDatepickerValueAsISOString('endDate');

    if (!startDate) {
      // if end date present but no start date, set start date to min (one year prior to end)
      // if no end date exists, start date is today
      startDate = endDate ? this.minStartDate : this.currentBusinessDay;
      this.orderFillSearchForm.get('startDate').setValue(startDate);
      this.orderFillSearchForm.markAsDirty();
    }
    if (!endDate) {
      // force end date to earlier of two values; one year past start date or today
      endDate = this.maxEndDate;
      this.orderFillSearchForm.get('endDate').setValue(endDate);
      this.orderFillSearchForm.markAsDirty();
    }

    // force end date to ledger end of day and start date to one millisecond after previous ledger end of day to address #505
    startDate = this.forceLedgerEndOfDay(moment(startDate), this.ledgerEndOfDay, true);
    endDate = this.forceLedgerEndOfDay(moment(endDate), this.ledgerEndOfDay, false);

    this.queryParams.startDate = startDate;
    this.queryParams.endDate = endDate;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });

    this.isLoading = true;
    // grab order fills report
    let combinedFills: { [key: string]: OrderFill[] };
    this.fills$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((doc: CommodityMap) => {
        this.commodityMap = doc;
        // reset cache if there is an update on commodityMap
        this.cachedOptionCommodityMap = {};
        return this.commodityProfileService.getAllCommodityProfilesByClientDocId(this.selectedClientDocId);
      }),
      switchMap((commodityProfiles: CommodityProfile[]) => {
        if (commodityProfiles.length === 0) {
          return of([]);
        }

        // get unique accounts from commodity profiles
        commodityProfiles.forEach((profile: CommodityProfile) => {
          this.accountMap[`${profile.officeCode}${profile.accountNumber}`] = profile.accountDocId;
        });

        return combineLatest(Object.values(this.accountMap).map((accountDocId: string) => {
          return this.executionReportService.getOrderFillsByAccountDocIdAndDate(this.selectedClientDocId, accountDocId, startDate, endDate);
        }));
      }),
      switchMap((orderFills: { [key: string]: OrderFill[] }[]) => {
        combinedFills = {};
        orderFills.forEach((fills: { [key: string]: OrderFill[] }) => {
          Object.keys(fills).forEach((orderDocId: string) => {
            combinedFills[orderDocId] = fills[orderDocId];
          });
        });

        if (Object.keys(combinedFills).length === 0) {
          return of([]);
        }

        return combineLatest(Object.keys(combinedFills).map((orderDocId: string) => {
          return this.orderService.findClientOrdersByOrderDocId(this.selectedClientDocId, orderDocId);
        }));
      }),
      map((orders: Order[][]) => {
        const orderMap: { [key: string]: Order } = {};
        // store orders in a dictionary for displaying proper price purposes
        orders.flat().forEach((order: Order) => {
          orderMap[order.docId] = order;
        });

        const orderFillReports: OrderFillReportDisplay[][] = [];
        Object.keys(combinedFills).forEach((orderDocId: string) => {
          orderFillReports.push(combinedFills[orderDocId].map((fill: OrderFill) => {
            const order = orderMap[fill.orderDocId];
            if (fill.legFills) {
              const orderLegFillKeys = Object.keys(fill.legFills);
              const buyKey = orderLegFillKeys.find(key => key.includes(Side.BUY));
              const sellKey = orderLegFillKeys.find(key => key.includes(Side.SELL));
              return {
                fillQuantity: fill.fillQuantity,
                orderQuantity: fill.orderQuantity,
                orderTargetPrice: this.getDisplayPrice(fill.targetPrice, order.symbol, order),
                orderFillPrice: this.getDisplayPrice(fill.fillPrice, order.symbol, order),
                orderBuyPrice: this.getDisplayPrice(fill.legFills[buyKey].fillPrice,
                  this.getLegFillSymbol(fill.legFills[buyKey].security, order), order),
                orderSellPrice: this.getDisplayPrice(fill.legFills[sellKey].fillPrice,
                  this.getLegFillSymbol(fill.legFills[sellKey].security, order), order),
                orderType: fill.orderType,
                fillTimestamp: fill.fillTimestamp,
                orderDocId: fill.orderDocId,
                accountDocId: order.accountDocId,
                orderSymbol: order.symbol,
                accountNumber: fill.accountNumber,
                security: fill.security,
                side: order.side
              } as OrderFillReportDisplay;
            } else {
              return {
                fillQuantity: fill.fillQuantity,
                orderQuantity: fill.orderQuantity,
                orderTargetPrice: this.getDisplayPrice(fill.targetPrice, order.symbol, order),
                orderFillPrice: this.getDisplayPrice(fill.fillPrice, order.symbol, order),
                orderType: fill.orderType,
                fillTimestamp: fill.fillTimestamp,
                orderDocId: fill.orderDocId,
                accountDocId: order.accountDocId,
                orderSymbol: order.symbol,
                accountNumber: fill.accountNumber,
                security: fill.security,
                side: order.side
              } as OrderFillReportDisplay;
            }
          }));
        });

        // sort result in descending fillTimestamp, but still maintain grouping of orderDocId
        const sortedOrderFillReports = [];
        orderFillReports.forEach((fills: OrderFillReportDisplay[]) => {
          sortedOrderFillReports.push(fills.sort((a, b) => b.fillTimestamp.localeCompare(a.fillTimestamp)));
        });

        return sortedOrderFillReports.flat();
      }),
      tap((fills: OrderFillReportDisplay[]) => {
        // process fills separated by accountDocId (create a map datasource)
        this.populateDatasource(fills);

        this.displayReports = true;
        this.isLoading = false;
      }),
      catchError(err => {
        this.openSnackBar('Error running report: ' + err.message, 'DISMISS', false);
        console.error(`Error retrieving reportable items: ${err}`);
        this.isLoading = false;
        return of([]);
      })
    );
  }

  reset() {
    this.orderFillSearchForm.get('startDate').setValue('');
    this.orderFillSearchForm.get('endDate').setValue('');
    this.orderFillSearchForm.markAsPristine();
    // Clear out queryParams so values aren't forced back in
    this.queryParams = {} as Params;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });
    this.displayReports = false;
  }

  selectFill(fill: OrderFillReportDisplay) {
    this.router.navigate(['accounts', fill.accountDocId, 'orders', fill.orderDocId]);
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMax')) {
      return 'Value Invalid';
    }
    return 'Unknown Error';
  }

  get ledgerEndOfDayLocal() {
    return moment(this.currentBusinessDay).format('h:mm A');
  }

  get minStartDate() {
    const endDate = this.orderFillSearchForm.get('endDate').value;
    // if end date exists, min start date is one year prior. If none, any start date is valid.
    let minStartDate = endDate ? moment(endDate).subtract(1, 'year').toISOString() : undefined;
    if (minStartDate) {
      const endDateMoment = moment(endDate);
      const startDateMoment = moment(minStartDate);
      // allow for Feb End of Month to Feb End of Month when end is a leap year
      if (endDateMoment.isLeapYear() && endDateMoment.month() === 1 && endDateMoment.date() === 29) {
        startDateMoment.date(28);
      }
      // allow for 2/29 on leap year through March 1st of next year
      if (startDateMoment.isLeapYear() && endDateMoment.month() === 2 && endDateMoment.date() === 1) {
        startDateMoment.month(1).date(29);
      }
      minStartDate = startDateMoment.toISOString();
    }
    return minStartDate;
  }

  get maxStartDate() {
    const endDate = this.orderFillSearchForm.get('endDate').value;
    return endDate ? endDate : this.currentBusinessDay;
  }

  get minEndDate() {
    const startDate = this.orderFillSearchForm.get('startDate').value;
    return startDate ? startDate : undefined;
  }

  get maxEndDate() {
    const startDate = moment(this.orderFillSearchForm.get('startDate').value);
    const endOfDayToday = moment(this.currentBusinessDay);
    // possible max end date is one year after start date if present, if not, it's today
    const endDate = startDate ?
      moment(this.forceLedgerEndOfDay(moment(startDate).add(1, 'year'), this.ledgerEndOfDay, false)) :
      endOfDayToday;
    // allow for Feb End of Month to Feb End of Month when end is a leap year
    if (endDate.isLeapYear() && startDate.month() === 1 && startDate.date() === 28) {
      endDate.date(29);
    }
    // allow for 2/29 on leap year through March 1st of next year
    if (startDate.isLeapYear() && startDate.month() === 1 && startDate.date() === 29) {
      endDate.month(2).date(1);
    }
    // if possible max end date is in future, discard in favor of today.
    return (endDate.isBefore(endOfDayToday) ? endDate : endOfDayToday).toISOString();
  }

  private get currentBusinessDay(): string {
    const now = moment();
    const todaysLedgerEndOfDay = this.endOfBusinessDay(now, this.ledgerEndOfDay);
    return this.forceLedgerEndOfDay(now.isBefore(todaysLedgerEndOfDay) ?
      now :
      now.add(1, 'days'), this.ledgerEndOfDay, false);
  }

  private forceLedgerEndOfDay(day: moment.Moment, ledgerEndOfDay: string, isStartDate: boolean): string {
    const endOfDay = this.endOfBusinessDay(day, ledgerEndOfDay);
    if (isStartDate) {
      endOfDay.subtract(1, 'day').add(1, 'millisecond');
    }
    return endOfDay.toISOString();
  }

  private endOfBusinessDay(day: moment.Moment, ledgerEndOfDay: string): moment.Moment {
    const timeModel = moment.tz(ledgerEndOfDay, MILITARY_FORMAT, this.timezone);
    // set second and millisecond to avoid ExpressionChanged error on this.currentBusinessDay
    return moment.tz(day, this.timezone)
      .set('hour', timeModel.hour()).set('minute', timeModel.minute()).set('second', 0).set('millisecond', 0);
  }

  private getDatepickerValueAsISOString(fieldName: string) {
    const val = this.orderFillSearchForm.get(fieldName).value;
    return val && val.toISOString ? val.toISOString() : val;
  }

  private getLegFillSymbol(security: string, order: Order) {
    if (order.securityType === SecurityType.OPTION) {
      const splitSecurity = security.split(' ');
      return splitSecurity[0].substring(0, splitSecurity.length - 2);
    } else {
      return security.substring(0, security.length - 2);
    }
  }

  private getDisplayPrice(price: number, symbol: string, order: Order) {
    let priceDivisor;
    if (order.securityType === SecurityType.OPTION) {
      // put option commodities in cache to optimize performance when displaying the fill price
      if (!this.cachedOptionCommodityMap[symbol]) {
        const commodity = Object.values(this.commodityMap.commodities).find(cmd => cmd.electronicOptionsSymbol === symbol);
        if (commodity) {
          this.cachedOptionCommodityMap[symbol] = commodity;
        }
      }
      priceDivisor = this.cachedOptionCommodityMap[symbol] ? this.cachedOptionCommodityMap[symbol].marketDataDivisor : 1;
    } else {
      priceDivisor = this.commodityMap.commodities[symbol] ? this.commodityMap.commodities[symbol].marketDataDivisor : 1;
    }

    return price / priceDivisor;
  }

  private populateDatasource(fills: OrderFillReportDisplay[]) {
    this.fillDataSourceMap = {};
    // initialize each table's datasource for each account
    Object.values(this.accountMap).forEach((accountDocId: string) => {
      this.fillDataSourceMap[accountDocId] = new MatTableDataSource<OrderFillReportDisplay>();
      this.fillDataSourceMap[accountDocId].data = [];
    });

    // populate table datasource
    fills.forEach((fill: OrderFillReportDisplay) => {
      this.fillDataSourceMap[fill.accountDocId].data.push(fill);
    });
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}
