import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { UserService } from '@advance-trading/angular-ops-data';
import { Client, User } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';

@Component({
  selector: 'hms-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [BreakpointObserver]
})
export class UsersComponent implements AfterViewInit, OnInit {
  columnsToDisplay = [];
  errorMessage: string;
  dataSource = new ObservableDataSource<User>();
  filterValue = new FormControl();
  selectedClient: Client;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private clientSelectorService: ClientSelectorService,
    private userService: UserService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.XSmall])
      .subscribe(state => {
        if (state.matches) {
          this.columnsToDisplay = [
            'name', 'email'
          ];
        } else {
          this.columnsToDisplay = [
            'name', 'email', 'status'
          ];
        }
      });

    this.dataSource.data$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap(client => {
        this.selectedClient = client;
        return this.userService.getAllUsersByClientId(client.docId);
      }),
      catchError(err => {
        this.errorMessage = 'Error retrieving users; please try again later';
        console.error(`Error retrieving users: ${err}`);
        return of([]);
      })
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    // Special sorting accessor to handle the combined first and last name field
    this.dataSource.sortingDataAccessor = (data, sortHeaderId): string | number => {
      if (sortHeaderId === 'name') {
        return data.firstName + ' ' + data.lastName;
      } else {
        return (data as { [key: string]: any })[sortHeaderId];
      }
    };
    this.dataSource.sort = this.sort;
    this.filter.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectUser(user: User) {
    this.router.navigate(['./', user.docId], { relativeTo: this.route });
  }

}
