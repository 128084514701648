import {
  CommodityMap,
  CommodityProfile,
  Contract,
  ContractMonth,
  ContractPriceAdjustment,
  ContractStatus,
  ContractType,
  HedgeType,
  HistoryRecord,
  HMSPriceAdjustmentMap,
  PricingSegment,
  PricingSegmentPartType,
  PricingSegmentStatus,
  PricingType,
} from '@advance-trading/ops-data-lib';
import { DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { HistoryEvent, EventType } from '../contracts/contract-history/contract-history-display';
import { ContractTypePipe } from '../utilities/pipes/contract-type.pipe';
import { PricingTypePipe } from '../utilities/pipes/pricing-type.pipe';
import { ProductionYearPipe } from '../utilities/pipes/production-year.pipe';
import { TimeInForcePipe } from '../utilities/pipes/time-in-force.pipe';

const BASIS_ADMIN_CHANGE_DOC_ID = 'Function (firestoreOnBasisWrite)';

const EXPIRATION_PIPE_FORMAT = 'M/d/yyyy';
const PRICE_PIPE_FORMAT = '1.4-5';
const SEGMENT_IDENTIFIER_PIPE_FORMAT = '#yyMMdd.HHmmss';
const TIMESTAMP_PIPE_FORMAT = 'MM/dd/yyyy hh:mm:ss a';

const RIGHT_ARROW = '\u2192';

const CONTRACT_FIELD_BLACKLIST = [
  'clientLocationAccountingSystemId',
  'deliveryLocationAccountingSystemId',
  'patronDocId',
  'patronAccountingSystemId',
  'commodityProfileAccountingSystemId',
  'accountDocId',
  'originatorDocId',
  'originatorAccountingSystemId',
  'versionNumber',
  'versionCreationTimestamp',
  'contractOrderDocId',
  'orderDocIds'
];

const SEGMENT_FIELD_BLACKLIST = [
  'contractDocId',
  'contractVersionNumber',
  'isBasisLocked',
  'isFuturesLocked',
  'isCashLocked',
  'orderDocId',
  'creatorDocId',
  'creatorAccountingSystemId'
];

const GENERAL_FIELD_BLACKLIST = [
  'clientDocId',
  'commodityProfileDocId',
  'clientLocationDocId',
  'deliveryLocationDocId',
  'targetOrderThreshold',
  'lastUpdatedByAccountingSystemId',
  'lastUpdatedTimestamp',
  'lastUpdatedByName',
  'lastUpdatedByDocId',
  'priceAdjustments',
  'priceAdjustmentsTotal'
];

// note: ensure displayed terminology consistency
const DISPLAYED_FIELD_NAME_MAP = {
  basisPrice: 'Basis',
  cashPrice: 'Cash',
  clientLocationName: 'Client Location',
  deliveryLocationName: 'Delivery Location',
  freightPrice: 'Freight',
  futuresPrice: 'Futures',
  futuresYearMonth: 'Contract',
  originatorName: 'Originator'
};

// TODO: remove after using CodeToMonthPipe once it is exported from angular-common-services
const MONTH = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

@Injectable({
  providedIn: 'root'
})
export class ContractHistoryHelperService {

  constructor(
    private contractTypePipe: ContractTypePipe,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private pricingTypePipe: PricingTypePipe,
    private productionYearPipe: ProductionYearPipe,
    private timeInForcePipe: TimeInForcePipe,
    private titleCasePipe: TitleCasePipe
  ) { }

  /**
   * Return contract history events for the specified old history record, new history record, commodity profile, and price adjustment map
   * @param oldHistoryRecord the old HistoryRecord document
   * @param newHistoryRecord the new HistoryRecord document
   * @param commodityProfile the commodity profile of the contract that is observed
   * @param priceAdjustmentMap the price adjustment map of the client
   * @param commodityMap the commodity map document
   */
  getContractHistoryEvents(oldHistoryRecord: HistoryRecord, newHistoryRecord: HistoryRecord,
                           commodityProfile: CommodityProfile, priceAdjustmentMap: HMSPriceAdjustmentMap,
                           commodityMap: CommodityMap): HistoryEvent[] {
    const oldContract = oldHistoryRecord ? JSON.parse(oldHistoryRecord.changeDocumentJSON) as Contract : {} as Contract;
    const newContract = JSON.parse(newHistoryRecord.changeDocumentJSON) as Contract;

    // Custom Events
    if (this.isContractImported(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.CONTRACT_IMPORTED, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractCreated(newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.CONTRACT_CREATED, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractPendingApproval(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.CONTRACT_PENDING_APPROVAL, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractApproved(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.CONTRACT_APPROVED, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractDenied(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.CONTRACT_DENIED, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractCancelledWithNoOrderWorking(oldContract, newContract)
      || this.isContractCancelledWithOrderWorking(oldContract, newContract)
      || this.isContractCancelledImmediatelyOrDueToDenied(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.CONTRACT_CANCELLED, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractComplete(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.CONTRACT_COMPLETE, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractTargetPriceLocked(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.CONTRACT_TARGET_PRICE_LOCKED, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractTargetPriceLockedDueToBasisUpdated(newContract)) {
      return this.getCustomHistoryEvent(
        oldContract, newContract, EventType.BASIS_UPDATED_BY_SYSTEM, newHistoryRecord.changeTimestamp)
      .concat(this.getCustomHistoryEvent(
        oldContract, newContract, EventType.CONTRACT_TARGET_PRICE_LOCKED, newHistoryRecord.changeTimestamp, 1));
    }

    if (this.isContractIdAssigned(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.CONTRACT_ID_ASSIGNED, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractOrderCreated(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.CONTRACT_ORDER_CREATED, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractOrderCancelledDueToQtyChangeUnderOrderThreshold(oldContract, newContract)
      || this.isContractOrderCancelledWithOrderWorking(oldContract, newContract)
      || this.isOrderCancelledDueToManualLock(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.CONTRACT_ORDER_CANCELLED, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractOrderUpdated(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.CONTRACT_ORDER_UPDATED, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractOrderReplaced(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.CONTRACT_ORDER_REPLACED, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractExpired(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.CONTRACT_EXPIRED, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractManuallyLocked(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract,
                                        EventType.CONTRACT_MANUALLY_PRICE_LOCKED, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractDeleted(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.CONTRACT_DELETED, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractPricedAtTheMarket(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract,
                                        EventType.CONTRACT_PRICED_AT_THE_MARKET, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractBatchDeliveryPeriodRoll(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.BATCH_DELIVERY_PERIOD_ROLL, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractBatchFuturesMonthRoll(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.BATCH_FUTURES_MONTH_ROLL, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractBasisUpdatedDueToBasisAdmin(oldContract, newContract)
      || this.isContractBasisUpdatedDueToDeliveryPeriodRoll(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract,
        EventType.BASIS_UPDATED_BY_SYSTEM, newHistoryRecord.changeTimestamp);
    }

    if (this.isExchangeContractCreated(newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.EXCHANGE_CONTRACT_CREATED, newHistoryRecord.changeTimestamp);
    }

    if (this.isContractExchangeCreated(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.EXCHANGE_CREATED, newHistoryRecord.changeTimestamp);
    }

    if (this.isExchangeContractCancelled(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.EXCHANGE_CONTRACT_CANCELLED, newHistoryRecord.changeTimestamp);
    }

    if (this.isExchangeComplete(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.EXCHANGE_COMPLETE, newHistoryRecord.changeTimestamp);
    }

    if (this.isExchangeContractComplete(oldContract, newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.EXCHANGE_CONTRACT_COMPLETE, newHistoryRecord.changeTimestamp);
    }

    if (this.isOTCContractCreated(newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract, EventType.OTC_CONTRACT_CREATED, newHistoryRecord.changeTimestamp);
    }

    if (this.isExercisedOptionContractCreated(newContract)) {
      return this.getCustomHistoryEvent(oldContract, newContract,
        EventType.EXERCISED_OPTION_CONTRACT_CREATED, newHistoryRecord.changeTimestamp);
    }

    let returnedHistoryEvents = [];

    // Price Adjustment Add, Update, or Remove Event
    const priceAdjustmentEvents = this.getContractPriceAdjustmentHistoryEvents(oldContract, newContract,
      newHistoryRecord.changeTimestamp, priceAdjustmentMap);
    returnedHistoryEvents = returnedHistoryEvents.concat(priceAdjustmentEvents);

    // Generic Events
    if (!this.isUpdatedBySystem(newContract)) {
      const genericEvents = this.getFieldRemovedContractHistoryEvents(oldContract, newContract,
        newHistoryRecord.changeTimestamp, commodityProfile, commodityMap)
        .concat(this.getFieldSetOrUpdateContractHistoryEvents(oldContract, newContract,
        newHistoryRecord.changeTimestamp, commodityProfile, commodityMap));
      returnedHistoryEvents = returnedHistoryEvents.concat(genericEvents);
    }

    // note: ignore update made by the system that doesn't fall to defined events
    return returnedHistoryEvents;
  }

  /**
   * Return pricing segment history events for the specified old history record, new history record, and
   * commodity map
   * @param oldHistoryRecord the old HistoryRecord document
   * @param newHistoryRecord the new HistoryRecord document
   * @param commodityMap the commodityMap document
   */
  getSegmentHistoryEvents(oldHistoryRecord: HistoryRecord, newHistoryRecord: HistoryRecord,
                          commodityProfile: CommodityProfile, commodityMap: CommodityMap): HistoryEvent[] {
    const oldSegment = oldHistoryRecord ? JSON.parse(oldHistoryRecord.changeDocumentJSON) as PricingSegment : {} as PricingSegment;
    const newSegment = JSON.parse(newHistoryRecord.changeDocumentJSON) as PricingSegment;

    // Custom Events
    if (this.isPricingImported(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.PRICING_IMPORTED, newHistoryRecord.changeTimestamp);
    }

    if (this.isPricingCreated(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.PRICING_CREATED, newHistoryRecord.changeTimestamp);
    }

    if (this.isPricingOrderCreated(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.PRICING_ORDER_CREATED, newHistoryRecord.changeTimestamp);
    }

    if (this.isPricingOrderUpdated(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.PRICING_ORDER_UPDATED, newHistoryRecord.changeTimestamp);
    }

    if (this.isPricingOrderReplaced(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.PRICING_ORDER_REPLACED, newHistoryRecord.changeTimestamp);
    }

    if (this.isPricingOrderCancelled(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.PRICING_ORDER_CANCELLED, newHistoryRecord.changeTimestamp);
    }

    if (this.isPricingTargetPriceLocked(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.PRICING_TARGET_PRICE_LOCKED, newHistoryRecord.changeTimestamp);
    }

    if (this.isPricingTargetPriceLockedDueToBasisUpdated(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.BASIS_UPDATED_BY_SYSTEM, newHistoryRecord.changeTimestamp, 1)
      .concat(this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.PRICING_TARGET_PRICE_LOCKED, newHistoryRecord.changeTimestamp)
      );
    }

    if (this.isPricingCancelled(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.PRICING_CANCELLED, newHistoryRecord.changeTimestamp);
    }

    if (this.isPricingDeleted(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.PRICING_DELETED, newHistoryRecord.changeTimestamp);
    }

    if (this.isPricingPriced(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.PRICING_PRICED, newHistoryRecord.changeTimestamp);
    }

    if (this.isPricingManuallyLocked(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.PRICING_MANUALLY_PRICE_LOCKED, newHistoryRecord.changeTimestamp);
    }

    if (this.isPricingPricedAtTheMarket(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.PRICING_PRICED_AT_THE_MARKET, newHistoryRecord.changeTimestamp);
    }

    if (this.isPricingIdAssigned(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.PRICING_ID_ASSIGNED, newHistoryRecord.changeTimestamp);
    }

    if (this.isExchangePricingCreated(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.EXCHANGE_PRICING_CREATED, newHistoryRecord.changeTimestamp);
    }

    if (this.isSegmentExchangeCreated(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.EXCHANGE_CREATED, newHistoryRecord.changeTimestamp);
    }

    if (this.isExchangePricingCancelled(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.EXCHANGE_PRICING_CANCELLED, newHistoryRecord.changeTimestamp);
    }

    if (this.isExchangePricingComplete(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.EXCHANGE_PRICING_COMPLETE, newHistoryRecord.changeTimestamp);
    }

    if (this.isOTCPricingCreated(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.OTC_PRICING_CREATED, newHistoryRecord.changeTimestamp);
    }

    if (this.isExercisedOptionPricingCreated(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.EXERCISED_OPTION_PRICING_CREATED, newHistoryRecord.changeTimestamp);
    }

    if (this.isSecondPartPricingCreated(oldSegment, newSegment)) {
      return this.getSegmentCustomHistoryEvent(oldSegment, newSegment,
        EventType.SECOND_PART_PRICING_CREATED, newHistoryRecord.changeTimestamp);
    }

    const returnedHistoryEvents = [];

    // Generic Events
    if (!this.isUpdatedBySystem(newSegment)) {
      Object.keys(newSegment).forEach(key => {
        // check if there is a change in the fields we're interested in
        if (this.isSegmentKeyEligibleToTrack(key) && oldSegment[key] !== newSegment[key]) {
          returnedHistoryEvents.push({
            eventType: EventType.PRICING_FIELD_UPDATED,
            details: this.getGenericDetail(oldSegment, newSegment, key, commodityProfile, commodityMap),
            lastUpdatedBy: newSegment.lastUpdatedByName,
            timestamp: newHistoryRecord.changeTimestamp,
            identifier: this.getSegmentIdentifier(newSegment)
          } as HistoryEvent);
        }
      });
    }

    // note: ignore update made by the system that doesn't fall to defined events
    return returnedHistoryEvents;
  }

  /**************************************** FUNCTIONS TO CHECK CONTRACT DEFINED EVENTS ****************************************/

  // Contract Imported
  private isContractImported(oldContract: Contract, newContract: Contract): boolean {
    return newContract.status !== ContractStatus.NEW && Object.keys(oldContract).length === 0;
  }

  // Contract Created
  private isContractCreated(newContract: Contract): boolean {
    return newContract.status === ContractStatus.NEW && !newContract.isExchange && !newContract.hasRelatedHedge;
  }

  // Contract Pending Approval
  private isContractPendingApproval(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.status !== ContractStatus.PENDING_APPROVAL && newContract.status === ContractStatus.PENDING_APPROVAL;
  }

  // Contract Approved
  private isContractApproved(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.status === ContractStatus.PENDING_APPROVAL &&
      (newContract.status === ContractStatus.WORKING_BASIS
      || newContract.status === ContractStatus.WORKING_CASH
      || newContract.status === ContractStatus.WORKING_FUTURES
      || newContract.status === ContractStatus.PENDING_ORDER);
  }

  // Contract Denied
  private isContractDenied(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.status === ContractStatus.PENDING_APPROVAL && newContract.status === ContractStatus.DENIED;
  }

  // Contract Cancelled (no order working)
  private isContractCancelledWithNoOrderWorking(oldContract: Contract, newContract: Contract): boolean {
    return (
      oldContract.status === ContractStatus.WORKING_BASIS
      || oldContract.status === ContractStatus.WORKING_CASH
      || oldContract.status === ContractStatus.WORKING_FUTURES
    ) && newContract.status === ContractStatus.CANCELLED && !newContract.contractOrderDocId;
  }

  // Contract Cancelled (order working)
  private isContractCancelledWithOrderWorking(oldContract: Contract, newContract: Contract): boolean {
    return (oldContract.status === ContractStatus.WORKING_CASH || oldContract.status === ContractStatus.WORKING_FUTURES)
    && newContract.status === ContractStatus.PENDING_ORDER_CANCEL && newContract.contractOrderDocId && !newContract.isPriceManuallyLocked
    && !this.isUpdatedBySystem(newContract);
  }

  // Contract Cancelled (due to limit contract denied or immediately cancelled)
  private isContractCancelledImmediatelyOrDueToDenied(oldContract: Contract, newContract: Contract): boolean {
    return (oldContract.status === ContractStatus.PENDING_APPROVAL || oldContract.status === ContractStatus.DENIED)
    && newContract.status === ContractStatus.CANCELLED;
  }

  // Contract Complete
  private isContractComplete(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.status !== ContractStatus.COMPLETE && oldContract.status !== ContractStatus.WORKING_EXCHANGE
    && newContract.status === ContractStatus.COMPLETE;
  }

  // Contract Target Price Locked
  private isContractTargetPriceLocked(oldContract: Contract, newContract: Contract): boolean {
    return newContract.pricingType === PricingType.LIMIT
    &&
    (
      ((newContract.type === ContractType.CASH || newContract.type === ContractType.HTA)
      && newContract.futuresLockedTimestamp && !oldContract.futuresLockedTimestamp)
      ||
      (newContract.type === ContractType.BASIS && newContract.basisLockedTimestamp && !oldContract.basisLockedTimestamp)
    )
    && !newContract.isPriceManuallyLocked
    && (newContract.lastUpdatedByDocId !== BASIS_ADMIN_CHANGE_DOC_ID || newContract.type !== ContractType.CASH);
  }

  // Contract Target Price Locked Due to Basis Updated (note: only happens for Cash Target - 2 events)
  private isContractTargetPriceLockedDueToBasisUpdated(newContract: Contract): boolean {
    return newContract.pricingType === PricingType.LIMIT
    && newContract.type === ContractType.CASH && newContract.cashLockedTimestamp
    && newContract.lastUpdatedByDocId === BASIS_ADMIN_CHANGE_DOC_ID;
  }

  // Contract ID Assigned
  private isContractIdAssigned(oldContract: Contract, newContract: Contract): boolean {
    return !!(!oldContract.accountingSystemId && newContract.accountingSystemId);
  }

  // Contract Order Created
  private isContractOrderCreated(oldContract: Contract, newContract: Contract): boolean {
    return !!(oldContract.status === ContractStatus.PENDING_ORDER
    && !oldContract.contractOrderDocId && newContract.contractOrderDocId);
  }

  // Contract Order Cancelled (qty change under order threshold)
  private isContractOrderCancelledDueToQtyChangeUnderOrderThreshold(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.status === ContractStatus.PENDING_ORDER_CANCEL
    && newContract.status !== ContractStatus.PENDING_ORDER_CANCEL && !newContract.contractOrderDocId
    && !newContract.isPriceManuallyLocked;
  }

  // Contract Order Cancelled (contract with order working is cancelled)
  private isContractOrderCancelledWithOrderWorking(oldContract: Contract, newContract: Contract): boolean {
    return !!(oldContract.status === ContractStatus.PENDING_ORDER_CANCEL && newContract.status === ContractStatus.CANCELLED
      && newContract.contractOrderDocId);
  }

  // Contract Order Cancelled (due to price manually locked)
  private isOrderCancelledDueToManualLock(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.status === ContractStatus.PENDING_ORDER_CANCEL && newContract.status === ContractStatus.PENDING_ORDER_CANCEL
      && oldContract.contractOrderDocId && !newContract.contractOrderDocId;
  }

  // Contract Order Updated (futures price change, qty change above order threshold, expiration change, time in force change)
  private isContractOrderUpdated(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.status === ContractStatus.PENDING_ORDER_UPDATE && newContract.status !== ContractStatus.PENDING_ORDER_UPDATE;
  }

  // Contract Order Replaced (happens when changing futures months after order opened)
  private isContractOrderReplaced(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.status === ContractStatus.PENDING_ORDER_RECREATE && newContract.status !== ContractStatus.PENDING_ORDER_RECREATE
    && oldContract.contractOrderDocId !== newContract.contractOrderDocId;
  }

  // Contract Expired
  private isContractExpired(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.status !== ContractStatus.EXPIRED && newContract.status === ContractStatus.EXPIRED;
  }

  // Contract Manually Price Locked
  private isContractManuallyLocked(oldContract: Contract, newContract: Contract): boolean {
    return !oldContract.isPriceManuallyLocked && newContract.isPriceManuallyLocked;
  }

  // Contract Deleted
  private isContractDeleted(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.status !== ContractStatus.DELETED && newContract.status === ContractStatus.DELETED;
  }

  // Contract Priced At The Market
  private isContractPricedAtTheMarket(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.pricingType === PricingType.LIMIT && newContract.pricingType === PricingType.MARKET;
  }

  // Batch Delivery Period Roll
  private isContractBatchDeliveryPeriodRoll(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.deliveryPeriod !== newContract.deliveryPeriod && this.isUpdatedBySystem(newContract);
  }

  // Batch Futures Period Roll
  private isContractBatchFuturesMonthRoll(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.futuresYearMonth !== newContract.futuresYearMonth && this.isUpdatedBySystem(newContract);
  }

  // Basis Updated By Basis Admin
  private isContractBasisUpdatedDueToBasisAdmin(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.basisPrice !== newContract.basisPrice
      && newContract.lastUpdatedByDocId === BASIS_ADMIN_CHANGE_DOC_ID && this.isUpdatedBySystem(newContract) ;
  }

  // Basis Updated By Delivery Period Roll
  private isContractBasisUpdatedDueToDeliveryPeriodRoll(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.basisPrice !== newContract.basisPrice
      && newContract.lastUpdatedByDocId !== BASIS_ADMIN_CHANGE_DOC_ID && this.isUpdatedBySystem(newContract) ;
  }

  // Exchange Contract Created
  private isExchangeContractCreated(newContract: Contract): boolean {
    return newContract.isExchange && newContract.status === ContractStatus.NEW;
  }

  // Exchange Created
  private isContractExchangeCreated(oldContract: Contract, newContract: Contract): boolean {
    return !!(!oldContract.exchangeId && newContract.exchangeId);
  }

  // Exchange Contract Cancelled
  private isExchangeContractCancelled(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.status === ContractStatus.WORKING_EXCHANGE && newContract.status === ContractStatus.CANCELLED;
  }

  // Exchange Complete
  private isExchangeComplete(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.status === ContractStatus.WORKING_EXCHANGE && newContract.status === ContractStatus.PENDING_BASIS;
  }

  // Exchange Contract Complete
  private isExchangeContractComplete(oldContract: Contract, newContract: Contract): boolean {
    return oldContract.status === ContractStatus.WORKING_EXCHANGE && newContract.status === ContractStatus.COMPLETE;
  }

  // OTC Contract Created
  private isOTCContractCreated(newContract: Contract): boolean {
    return newContract.relatedHedgeType === HedgeType.OTC && newContract.status === ContractStatus.NEW;
  }

  // Exercised Option Contract Created
  private isExercisedOptionContractCreated(newContract: Contract): boolean {
    return newContract.relatedHedgeType === HedgeType.OPTION && newContract.status === ContractStatus.NEW;
  }

  /**************************************** HELPER FUNCTIONS FOR GETTING CONTRACT EVENTS ****************************************/

  /**
   * Return a custom history event for the specified contract, event type, and timestamp
   * @param oldContract the previous contract document
   * @param newContract the current contract document
   * @param eventType the event type of the event row
   * @param timestamp the timestamp when the contract changed
   * @param index the index determining where the history event will be placed in a specific timestamp
   */
  private getCustomHistoryEvent(oldContract: Contract, newContract: Contract, eventType: EventType,
                                timestamp: number, index = 0): HistoryEvent[] {
    return [{
      eventType,
      details: this.getCustomHistoryEventDetail(oldContract, newContract, eventType),
      lastUpdatedBy: this.getContractLastUpdatedBy(newContract, eventType),
      timestamp,
      index
    }];
  }

  /**
   * Return the custom contract history event detail for the specified contract and event type
   * @param oldContract the previous contract document
   * @param newContract the current contract document
   * @param eventType the event type of the event row
   */
  private getCustomHistoryEventDetail(oldContract: Contract, newContract: Contract, eventType: EventType): string {
    switch (eventType) {
      case EventType.CONTRACT_CREATED:
      case EventType.OTC_CONTRACT_CREATED:
      case EventType.EXERCISED_OPTION_CONTRACT_CREATED:
        return this.getContractCreatedDetail(newContract);
      case EventType.CONTRACT_TARGET_PRICE_LOCKED:
        return this.getContractLockPriceDetail(newContract);
      case EventType.CONTRACT_ID_ASSIGNED:
        return `Contract ID ${newContract.accountingSystemId}`;
      case EventType.CONTRACT_ORDER_CREATED:
        return `Order ID ${newContract.contractOrderDocId}`;
      case EventType.CONTRACT_ORDER_UPDATED:
        return `Order ID ${newContract.contractOrderDocId}`;
      case EventType.CONTRACT_ORDER_REPLACED:
        return `Order ID ${newContract.contractOrderDocId}`;
      case EventType.CONTRACT_MANUALLY_PRICE_LOCKED:
        return `${this.getContractLockPriceDetail(newContract)} \
        (${this.decimalPipe.transform(newContract.manualLockPriceDifference, PRICE_PIPE_FORMAT)})`;
      case EventType.CONTRACT_PRICED_AT_THE_MARKET:
        return this.getContractPricedAtTheMarketDetail(newContract);
      case EventType.BATCH_DELIVERY_PERIOD_ROLL:
        return `${this.codeToMonthTransform(oldContract.deliveryPeriod)} ${RIGHT_ARROW} ${this.codeToMonthTransform(newContract.deliveryPeriod)}`;
      case EventType.BATCH_FUTURES_MONTH_ROLL:
        return `${this.codeToMonthTransform(oldContract.futuresYearMonth)} ${RIGHT_ARROW} ${this.codeToMonthTransform(newContract.futuresYearMonth)}`;
      case EventType.BASIS_UPDATED_BY_SYSTEM:
        return this.getBasisUpdatedBySystemDetail(oldContract, newContract);
      case EventType.EXCHANGE_CONTRACT_CREATED:
        return `${this.decimalPipe.transform(newContract.futuresPrice, PRICE_PIPE_FORMAT)} ${this.contractTypePipe.transform(newContract.type)}`;
      case EventType.EXCHANGE_CREATED:
        return `Exchange ID ${newContract.exchangeId}`;
      default:
        return '';
    }
  }

  /**
   * Get contract created detail for the specified contract
   * @param contract the contract created
   */
  private getContractCreatedDetail(contract: Contract): string {
    if (contract.isSpot) {
      return `${this.decimalPipe.transform(contract.cashPrice, PRICE_PIPE_FORMAT)} Spot`;
    } else if (contract.type === ContractType.BASIS) {
      return `${this.decimalPipe.transform(contract.basisPrice, PRICE_PIPE_FORMAT)} \
        ${this.contractTypePipe.transform(contract.type)} \
        ${this.titleCasePipe.transform(this.pricingTypePipe.transform(contract.pricingType))}`;
    } else if (contract.type === ContractType.HTA) {
      return `${this.decimalPipe.transform(contract.futuresPrice, PRICE_PIPE_FORMAT)} \
        ${this.contractTypePipe.transform(contract.type)} \
        ${this.titleCasePipe.transform(this.pricingTypePipe.transform(contract.pricingType))}`;
    } else if (contract.type === ContractType.CASH) {
      return `${this.decimalPipe.transform(contract.cashPrice, PRICE_PIPE_FORMAT)} \
        ${this.contractTypePipe.transform(contract.type)} \
        ${this.titleCasePipe.transform(this.pricingTypePipe.transform(contract.pricingType))}`;
    } else {
      return this.contractTypePipe.transform(contract.type);
    }
  }

  /**
   * Get lock price detail for the specified contract
   * @param contract the contract that has its price locked (cashPrice, futuresPrice or basisPrice)
   */
  private getContractLockPriceDetail(contract: Contract): string {
    switch (contract.type) {
      case ContractType.CASH:
        return `${this.decimalPipe.transform(contract.cashPrice, PRICE_PIPE_FORMAT)} Cash`;
      case ContractType.HTA:
        return `${this.decimalPipe.transform(contract.futuresPrice, PRICE_PIPE_FORMAT)} Futures`;
      default:
        return `${this.decimalPipe.transform(contract.basisPrice, PRICE_PIPE_FORMAT)} Basis`;
    }
  }

  /**
   * Get contract priced at the market detail for the specified contract
   * @param contract the contract that is priced at the market
   */
  private getContractPricedAtTheMarketDetail(contract: Contract): string {
    if (contract.type === ContractType.BASIS) {
      return `${this.decimalPipe.transform(contract.basisPrice, PRICE_PIPE_FORMAT)} Basis`;
    } else if (contract.type === ContractType.HTA) {
      return `${this.decimalPipe.transform(contract.futuresPrice, PRICE_PIPE_FORMAT)} Futures`;
    } else {
      return `${this.decimalPipe.transform(contract.cashPrice, PRICE_PIPE_FORMAT)} Cash`;
    }
  }

  /**
   * Get basis updated by system detail for the specified old and new contract
   * @param oldContract the previous contract document
   * @param newContract the current contract document
   */
  private getBasisUpdatedBySystemDetail(oldContract: Contract, newContract: Contract): string {
    if (this.isContractBasisUpdatedDueToBasisAdmin(oldContract, newContract)) {
      return `Bid Change ${this.decimalPipe.transform(oldContract.basisPrice, PRICE_PIPE_FORMAT)} ${RIGHT_ARROW} \
            ${this.decimalPipe.transform(newContract.basisPrice, PRICE_PIPE_FORMAT)}`;
    } else {
      return `Batch Roll ${this.decimalPipe.transform(oldContract.basisPrice, PRICE_PIPE_FORMAT)} ${RIGHT_ARROW} \
            ${this.decimalPipe.transform(newContract.basisPrice, PRICE_PIPE_FORMAT)}`;
    }
  }

  /**
   * Return price adjustment history events
   * for the specified old and new contract, timestamp, and price adjustment map
   * @param oldContract the previous contract document
   * @param newContract the current contract document
   * @param timestamp the timestamp when the contract changed
   * @param priceAdjustmentMap the client's price adjustment map
   */
  private getContractPriceAdjustmentHistoryEvents(oldContract: Contract, newContract: Contract,
                                                  timestamp: number, priceAdjustmentMap: HMSPriceAdjustmentMap): HistoryEvent[] {
    const oldPriceAdjustments = oldContract.priceAdjustments || [];
    const newPriceAdjustments = newContract.priceAdjustments || [];
    const oldAsymmetricDiff = this.asymmetricPriceAdjArrayDiff(oldPriceAdjustments, newPriceAdjustments);
    const newAsymmetricDiff = this.asymmetricPriceAdjArrayDiff(newPriceAdjustments, oldPriceAdjustments);
    const mutatedOldAsymmetricDiff = [...oldAsymmetricDiff];
    const priceAdjustmentHistoryEvents = [];

    newAsymmetricDiff.forEach((newPriceAdj: ContractPriceAdjustment) => {
      const oldPriceAdjIdx = mutatedOldAsymmetricDiff.findIndex((priceAdj: ContractPriceAdjustment) => priceAdj.id === newPriceAdj.id);
      // get price adjustment update events
      if (oldPriceAdjIdx !== -1) {
        const historyEvent = {
          eventType: EventType.PRICE_ADJUSTMENT_UPDATED,
          details: this.getPriceAdjustmentEventDetail(priceAdjustmentMap, mutatedOldAsymmetricDiff[oldPriceAdjIdx], newPriceAdj),
          lastUpdatedBy: this.getContractLastUpdatedBy(newContract, EventType.PRICE_ADJUSTMENT_UPDATED),
          timestamp
        } as HistoryEvent;
        priceAdjustmentHistoryEvents.push(historyEvent);
        mutatedOldAsymmetricDiff.splice(oldPriceAdjIdx, 1);
      // get price adjustment add events
      } else {
        const historyEvent = {
          eventType: EventType.PRICE_ADJUSTMENT_ADDED,
          details: this.getPriceAdjustmentEventDetail(priceAdjustmentMap, newPriceAdj),
          lastUpdatedBy: this.getContractLastUpdatedBy(newContract, EventType.PRICE_ADJUSTMENT_REMOVED),
          timestamp
        } as HistoryEvent;
        priceAdjustmentHistoryEvents.push(historyEvent);
      }
    });

    // get price adjustment removal events
    mutatedOldAsymmetricDiff.forEach((oldPriceAdj: ContractPriceAdjustment) => {
      const historyEvent = {
        eventType: EventType.PRICE_ADJUSTMENT_REMOVED,
        details: this.getPriceAdjustmentEventDetail(priceAdjustmentMap, oldPriceAdj),
        lastUpdatedBy: this.getContractLastUpdatedBy(newContract, EventType.PRICE_ADJUSTMENT_REMOVED),
        timestamp
      } as HistoryEvent;
      priceAdjustmentHistoryEvents.push(historyEvent);
    });

    return priceAdjustmentHistoryEvents;
  }

  /**
   * Return price adjustments from priceAdjustments1 that are not in priceAdjustments2
   * @param priceAdjustments1 the first price adjustment array to be compared
   * @param priceAdjustments2 the second price adjustment array to be compared
   */
  private asymmetricPriceAdjArrayDiff(priceAdjustments1: ContractPriceAdjustment[], priceAdjustments2: ContractPriceAdjustment[]) {
    const DELIMITER = '•';

    // create a counter for all price adjustments in the first adjustment array
    const firstPriceAdjCounter = {};
    priceAdjustments1.forEach(firstPriceAdj => {
      const identifier = `${firstPriceAdj.id}${DELIMITER}${firstPriceAdj.value}`;
      if (!firstPriceAdjCounter[identifier]) {
        firstPriceAdjCounter[identifier] = 1;
      } else {
        firstPriceAdjCounter[identifier]++;
      }
    });

    // decrement counter for adjustments that exists in both adjustment array
    priceAdjustments2.forEach(secondPriceAdj => {
      const identifier = `${secondPriceAdj.id}${DELIMITER}${secondPriceAdj.value}`;
      if (firstPriceAdjCounter[identifier]) {
        firstPriceAdjCounter[identifier]--;
      }
    });

    // grab all price adjustments that exists in the first adjustment array, but not the second adjustment array
    const asymmetricDiff = [];
    Object.keys(firstPriceAdjCounter).forEach(key => {
      while (firstPriceAdjCounter[key] > 0) {
        const split = key.split(DELIMITER);
        const priceAdj: ContractPriceAdjustment = {
          id: split[0],
          value: parseFloat(split[1])
        };
        asymmetricDiff.push(priceAdj);
        firstPriceAdjCounter[key]--;
      }
    });
    return asymmetricDiff;
  }

  /**
   * Return field removal of the generic contract history events
   * for the specified old and new contract, timestamp, commodity profile, and commodity map
   * @param oldContract the previous contract document
   * @param newContract the current contract document
   * @param timestamp the timestamp when the contract changed
   * @param commodityProfile the commodity profile of the contract that is observed
   * @param commodityMap the commodity map document
   */
  private getFieldRemovedContractHistoryEvents(oldContract: Contract, newContract: Contract,
                                               timestamp: number, commodityProfile: CommodityProfile,
                                               commodityMap: CommodityMap): HistoryEvent[] {
    const oldContractFields = Object.keys(oldContract);
    const removeHistoryEvents = [];

    oldContractFields.forEach(oldKey => {
      if (this.isContractKeyEligibleToTrack(oldKey) && this.isRemoveOperation(oldContract[oldKey], newContract[oldKey])) {
        const historyEvent = {
          eventType: EventType.CONTRACT_FIELD_UPDATED,
          details: this.getGenericDetail(oldContract, newContract, oldKey, commodityProfile, commodityMap),
          lastUpdatedBy: this.getContractLastUpdatedBy(newContract, EventType.CONTRACT_FIELD_UPDATED),
          timestamp
        } as HistoryEvent;
        removeHistoryEvents.push(historyEvent);
      }
    });
    return removeHistoryEvents;
  }

  /**
   * Return field set or update of the generic contract history events
   * for the specified old and new contract, timestamp, and commodity profile and commodity map
   * @param oldContract the previous contract document
   * @param newContract the current contract document
   * @param timestamp the timestamp when the contract changed
   * @param commodityProfile the commodity profile of the contract that is observed
   * @param commodityMap the commodity map document
   */
  private getFieldSetOrUpdateContractHistoryEvents(oldContract: Contract, newContract: Contract,
                                                   timestamp: number, commodityProfile: CommodityProfile,
                                                   commodityMap: CommodityMap): HistoryEvent[] {
    const setOrUpdateHistoryEvents = [];
    Object.keys(newContract).forEach(newKey => {
      // check if there is a change in the fields we're interested in
      if (this.isContractKeyEligibleToTrack(newKey) && oldContract[newKey] !== newContract[newKey]) {
        const historyEvent = {
          eventType: EventType.CONTRACT_FIELD_UPDATED,
          details: this.getGenericDetail(oldContract, newContract, newKey, commodityProfile, commodityMap),
          lastUpdatedBy: this.getContractLastUpdatedBy(newContract, EventType.CONTRACT_FIELD_UPDATED),
          timestamp
        } as HistoryEvent;
        setOrUpdateHistoryEvents.push(historyEvent);
      }
    });
    return setOrUpdateHistoryEvents;
  }

  /**
   * Return displayable name from last updated by for the specified contract and event type;
   * returns 'System' for system modified unless eventType === EventType.CONTRACT_CREATED in which case it
   * returns the contract.lastUpdatedByName field
   *
   * @param contract the contract's of an event row to be displayed
   * @param eventType the event type of the event row
   */
  private getContractLastUpdatedBy(contract: Contract, eventType: EventType): string {
    // note: backward compatibility when contract was created from an offer creation
    return this.isUpdatedBySystem(contract) && eventType !== EventType.CONTRACT_CREATED ? 'System' : contract.lastUpdatedByName;
  }

  /**
   * Return true if contract's key is eligible to track for contract history
   * @param key the Contract key to be tracked
   */
  private isContractKeyEligibleToTrack(key: string): boolean {
    return !CONTRACT_FIELD_BLACKLIST.includes(key) && !GENERAL_FIELD_BLACKLIST.includes(key);
  }

  /**************************************** FUNCTIONS TO CHECK SEGMENT DEFINED EVENTS ****************************************/


  // Pricing Imported
  private isPricingImported(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return Object.keys(oldSegment).length === 0 && this.isUpdatedBySystem(newSegment);
  }

  // Pricing Created
  private isPricingCreated(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return Object.keys(oldSegment).length === 0 && !this.isUpdatedBySystem(newSegment)
      && !newSegment.isExchange && !newSegment.hasRelatedHedge;
  }

  // Pricing Order Created
  private isPricingOrderCreated(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return !!(oldSegment.status === PricingSegmentStatus.PENDING_ORDER
      && !oldSegment.orderDocId && newSegment.orderDocId);
  }

  // Pricing Order Updated
  private isPricingOrderUpdated(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return oldSegment.status === PricingSegmentStatus.PENDING_ORDER_UPDATE
      && newSegment.status !== PricingSegmentStatus.PENDING_ORDER_UPDATE;
  }

  // Pricing Order Replaced
  private isPricingOrderReplaced(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return oldSegment.status === PricingSegmentStatus.PENDING_ORDER_RECREATE
      && newSegment.status !== PricingSegmentStatus.PENDING_ORDER_RECREATE && oldSegment.orderDocId !== newSegment.orderDocId;
  }

  // Pricing Order Cancelled
  private isPricingOrderCancelled(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return oldSegment.orderDocId && !newSegment.orderDocId
    || (oldSegment.status === PricingSegmentStatus.PENDING_ORDER_CANCEL && newSegment.status === PricingSegmentStatus.CANCELLED);
  }

  // Pricing Target Price Locked
  private isPricingTargetPriceLocked(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return ((newSegment.cashPricingType === PricingType.LIMIT && newSegment.isCashLocked && !oldSegment.isCashLocked)
      || (newSegment.basisPricingType === PricingType.LIMIT && newSegment.isBasisLocked && !oldSegment.isBasisLocked)
      || (newSegment.futuresPricingType === PricingType.LIMIT && newSegment.isFuturesLocked
        && !oldSegment.isFuturesLocked))
      && !newSegment.isPriceManuallyLocked
      && (newSegment.lastUpdatedByDocId !== BASIS_ADMIN_CHANGE_DOC_ID || !newSegment.cashPricingType);
  }

  // Pricing Target Price Locked Due to Basis Updated (note: only happens for Pricing Cash at Target - 2 events)
  private isPricingTargetPriceLockedDueToBasisUpdated(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return newSegment.cashPricingType === PricingType.LIMIT && newSegment.isCashLocked && !oldSegment.isCashLocked
        && newSegment.lastUpdatedByDocId === BASIS_ADMIN_CHANGE_DOC_ID;
  }

  // Pricing Cancelled
  private isPricingCancelled(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return ((oldSegment.status === PricingSegmentStatus.WORKING_CASH || oldSegment.status === PricingSegmentStatus.WORKING_FUTURES)
      && newSegment.status === PricingSegmentStatus.PENDING_ORDER_CANCEL && !newSegment.isPriceManuallyLocked)
      || (oldSegment.status !== PricingSegmentStatus.CANCELLED
        && oldSegment.status !== PricingSegmentStatus.WORKING_EXCHANGE && newSegment.status === PricingSegmentStatus.CANCELLED);
  }

  // Pricing Deleted
  private isPricingDeleted(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return oldSegment.status !== PricingSegmentStatus.DELETED && newSegment.status === PricingSegmentStatus.DELETED;
  }

  // Pricing Priced
  private isPricingPriced(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return oldSegment.status !== PricingSegmentStatus.PRICED && oldSegment.status !== PricingSegmentStatus.WORKING_EXCHANGE
    && newSegment.status === PricingSegmentStatus.PRICED && !newSegment.hasRelatedHedge;
  }

  // Pricing Manually Price Locked
  private isPricingManuallyLocked(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return !oldSegment.isPriceManuallyLocked && newSegment.isPriceManuallyLocked;
  }

  // Pricing Priced at the Market
  private isPricingPricedAtTheMarket(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return (oldSegment.cashPricingType === PricingType.LIMIT && newSegment.cashPricingType === PricingType.MARKET)
    || (oldSegment.basisPricingType === PricingType.LIMIT && newSegment.basisPricingType === PricingType.MARKET)
    || (oldSegment.futuresPricingType === PricingType.LIMIT && newSegment.futuresPricingType === PricingType.MARKET);
  }

  // Pricing ID Assigned
  private isPricingIdAssigned(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return !!(!oldSegment.accountingSystemId && newSegment.accountingSystemId);
  }

  // Exchange Pricing Created
  private isExchangePricingCreated(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return Object.keys(oldSegment).length === 0 && !this.isUpdatedBySystem(newSegment) && newSegment.isExchange;
  }

  // Exchange Created
  private isSegmentExchangeCreated(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return !!(!oldSegment.exchangeId && newSegment.exchangeId);
  }

  // Exchange Pricing Cancelled
  private isExchangePricingCancelled(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return oldSegment.status === PricingSegmentStatus.WORKING_EXCHANGE && newSegment.status === PricingSegmentStatus.CANCELLED;
  }

  // Exchange Pricing Complete
  private isExchangePricingComplete(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return oldSegment.status === PricingSegmentStatus.WORKING_EXCHANGE && newSegment.status === PricingSegmentStatus.PRICED;
  }

  // OTC Pricing Created
  private isOTCPricingCreated(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return Object.keys(oldSegment).length === 0 && !this.isUpdatedBySystem(newSegment) && newSegment.relatedHedgeType === HedgeType.OTC;
  }

  // Exercised Option Pricing Created
  private isExercisedOptionPricingCreated(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return Object.keys(oldSegment).length === 0 && !this.isUpdatedBySystem(newSegment) && newSegment.relatedHedgeType === HedgeType.OPTION;
  }

  // Second Part Pricing Created
  private isSecondPartPricingCreated(oldSegment: PricingSegment, newSegment: PricingSegment): boolean {
    return !!(
      (oldSegment.dpFirstPartType === PricingSegmentPartType.BASIS && !oldSegment.futuresPricingType && newSegment.futuresPricingType)
      || (oldSegment.dpFirstPartType === PricingSegmentPartType.FUTURES && !oldSegment.basisPricingType && newSegment.basisPricingType)
    );
  }

  /**************************************** HELPER FUNCTIONS FOR GETTING SEGMENT EVENTS ****************************************/

  /**
   * Return a custom history event for the specified old and new pricing segment, event type, timestamp,
   * commodity map, and most recent pricing segment
   * @param oldSegment the previous pricing segment document
   * @param newSegment the current pricing segment document
   * @param eventType the event type of the event row
   * @param timestamp the timestamp when the contract changed
   * @param commodityMap the commodityMap document
   * @param segment the pricing segment document that is observed
   * @param index the index determining where the history event will be placed in a specific timestamp
   */
  private getSegmentCustomHistoryEvent(oldSegment: PricingSegment, newSegment: PricingSegment,
                                       eventType: EventType, timestamp: number, index = 0): HistoryEvent[] {
    return [{
      eventType,
      details: this.getSegmentCustomHistoryEventDetail(oldSegment, newSegment, eventType),
      lastUpdatedBy: this.getSegmentLastUpdatedBy(newSegment),
      timestamp,
      identifier: this.getSegmentIdentifier(newSegment),
      index
    }];
  }

  /**
   * Return the custom pricing segment history event detail for the specified pricing segment and event type
   * @param oldSegment the previous pricing segment document
   * @param newSegment the current pricing segment document
   * @param eventType the event type of the event row
   */
  private getSegmentCustomHistoryEventDetail(oldSegment: PricingSegment, newSegment: PricingSegment, eventType: EventType): string {
    switch (eventType) {
      case EventType.PRICING_CREATED:
        return this.getPricingCreatedDetail(newSegment);
      case EventType.PRICING_ORDER_CREATED:
      case EventType.PRICING_ORDER_UPDATED:
      case EventType.PRICING_ORDER_REPLACED:
        return `Order ID ${newSegment.orderDocId}`;
      case EventType.PRICING_TARGET_PRICE_LOCKED:
        return `${this.getSegmentLockPriceDetail(oldSegment, newSegment)}`;
      case EventType.PRICING_MANUALLY_PRICE_LOCKED:
        return `${this.getSegmentLockPriceDetail(oldSegment, newSegment)} (${newSegment.manualLockPriceDifference})`;
      case EventType.PRICING_PRICED_AT_THE_MARKET:
        return this.getPricingPricedAtTheMarketDetail(oldSegment, newSegment);
      case EventType.PRICING_ID_ASSIGNED:
        return `Pricing ID ${newSegment.accountingSystemId}`;
      case EventType.EXCHANGE_PRICING_CREATED:
      case EventType.OTC_PRICING_CREATED:
      case EventType.EXERCISED_OPTION_PRICING_CREATED:
        return `${this.decimalPipe.transform(newSegment.futuresPrice, PRICE_PIPE_FORMAT)} Futures`;
      case EventType.EXCHANGE_CREATED:
        return `Exchange ID ${newSegment.exchangeId}`;
      case EventType.BASIS_UPDATED_BY_SYSTEM:
        return `Bid Change ${this.decimalPipe.transform(oldSegment.basisPrice, PRICE_PIPE_FORMAT)} ${RIGHT_ARROW} \
        ${this.decimalPipe.transform(newSegment.basisPrice, PRICE_PIPE_FORMAT)}`;
      case EventType.PRICING_PRICED:
        return this.getPricingPricedDetail(newSegment);
      case EventType.SECOND_PART_PRICING_CREATED:
        return this.getSecondPartPricingCreatedDetail(newSegment);
      default:
        return '';
    }
  }

  /**
   * Get pricing created detail for the specified pricing segment
   * @param segment the pricing segment created
   */
  private getPricingCreatedDetail(segment: PricingSegment): string {
    if (segment.cashPricingType) {
      return `${this.decimalPipe.transform(segment.cashPrice, PRICE_PIPE_FORMAT)} \
              Cash ${this.titleCasePipe.transform(this.pricingTypePipe.transform(segment.cashPricingType))}`;
    } else if (segment.futuresPricingType) {
      return `${this.decimalPipe.transform(segment.futuresPrice, PRICE_PIPE_FORMAT)} \
              Futures ${this.titleCasePipe.transform(this.pricingTypePipe.transform(segment.futuresPricingType))}`;
    } else {
      return `${this.decimalPipe.transform(segment.basisPrice, PRICE_PIPE_FORMAT)} \
              Basis ${this.titleCasePipe.transform(this.pricingTypePipe.transform(segment.basisPricingType))}`;
    }
  }

  /**
   * Get lock price detail for the specified old and new pricing segment
   * @param oldSegment the previous pricing segment document
   * @param newSegment the new pricing segment document that has its price locked (cashPrice, futuresPrice, or basisPrice)
   */
  private getSegmentLockPriceDetail(oldSegment: PricingSegment, newSegment: PricingSegment): string {
    if (!oldSegment.isCashLocked && newSegment.isCashLocked && newSegment.cashPricingType) {
      return `${this.decimalPipe.transform(newSegment.cashPrice, PRICE_PIPE_FORMAT)} Cash`;
    } else if (!oldSegment.isFuturesLocked && newSegment.isFuturesLocked && newSegment.futuresPricingType) {
      return `${this.decimalPipe.transform(newSegment.futuresPrice, PRICE_PIPE_FORMAT)} Futures`;
    } else {
      return `${this.decimalPipe.transform(newSegment.basisPrice, PRICE_PIPE_FORMAT)} Basis`;
    }
  }

  /**
   * Get pricing priced at the market detail for the specified old and new pricing segment
   * @param oldSegment the previous pricing segment document
   * @param newSegment the current pricing segment document
   */
  private getPricingPricedAtTheMarketDetail(oldSegment: PricingSegment, newSegment: PricingSegment): string {
    if (oldSegment.cashPricingType === PricingType.LIMIT && newSegment.cashPricingType === PricingType.MARKET) {
      return `${this.decimalPipe.transform(newSegment.cashPrice, PRICE_PIPE_FORMAT)} Cash`;
    } else if (oldSegment.futuresPricingType === PricingType.LIMIT && newSegment.futuresPricingType === PricingType.MARKET) {
      return `${this.decimalPipe.transform(newSegment.futuresPrice, PRICE_PIPE_FORMAT)} Futures`;
    } else {
      return `${this.decimalPipe.transform(newSegment.basisPrice, PRICE_PIPE_FORMAT)} Basis`;
    }
  }

  /**
   * Get pricing priced detail for the specified new pricing segment
   * @param newSegment the current pricing segment document
   */
  private getPricingPricedDetail(newSegment: PricingSegment) {
    if (newSegment.dpFirstPartType === PricingSegmentPartType.BASIS) {
      return `${this.decimalPipe.transform(newSegment.futuresPrice, PRICE_PIPE_FORMAT)} Futures `
      + `${this.titleCasePipe.transform(this.pricingTypePipe.transform(newSegment.futuresPricingType))}`;
    } else if (newSegment.dpFirstPartType === PricingSegmentPartType.FUTURES) {
      return `${this.decimalPipe.transform(newSegment.basisPrice, PRICE_PIPE_FORMAT)} Basis `
      + `${this.titleCasePipe.transform(this.pricingTypePipe.transform(newSegment.basisPricingType))}`;
    } else {
      return '';
    }
  }

  private getSecondPartPricingCreatedDetail(newSegment: PricingSegment) {
    // Note: DP - Basis 2nd Part - Priced at Target
    if (newSegment.dpFirstPartType === PricingSegmentPartType.BASIS) {
      return `${this.decimalPipe.transform(newSegment.futuresPrice, PRICE_PIPE_FORMAT)} Futures `
      + `${this.titleCasePipe.transform(this.pricingTypePipe.transform(newSegment.futuresPricingType))}`;
    // Note: DP - Futures 2nd Part - Priced at Target
    } else {
      return `${this.decimalPipe.transform(newSegment.basisPrice, PRICE_PIPE_FORMAT)} Basis `
      + `${this.titleCasePipe.transform(this.pricingTypePipe.transform(newSegment.basisPricingType))}`;
    }
  }

  /**
   * Return true if pricing segment's key is eligible to track for contract history
   * @param key the pricing segment key to be tracked
   */
  private isSegmentKeyEligibleToTrack(key: string): boolean {
    return !SEGMENT_FIELD_BLACKLIST.includes(key) && !GENERAL_FIELD_BLACKLIST.includes(key);
  }

  /**
   * Return displayable name from last updated by for the specified pricing segment
   * returns 'System' for system modified or the segment.lastUpdatedByName field otherwise
   *
   * @param segment the segment's of an event row to be displayed
   */
  private getSegmentLastUpdatedBy(segment: PricingSegment): string {
    return this.isUpdatedBySystem(segment) ? 'System' : segment.lastUpdatedByName;
  }

  /**
   * Return pricing segment identifier for the specified pricing segment
   * using the formatted creation timestamp
   * @param segment the pricing segment document
   */
  private getSegmentIdentifier(segment: PricingSegment): string {
    return this.datePipe.transform(segment.creationTimestamp, SEGMENT_IDENTIFIER_PIPE_FORMAT);
  }

  /****************************** HELPER FUNCTIONS FOR GETTING BOTH CONTRACT AND SEGMENT EVENTS ******************************/

  /**
   * Return the generic contract history event detail for the specified old and new contract or pricing segment,
   * field name, commodity profile, and commodity map
   * @param oldContractOrSegment the previous contract or pricing segment document
   * @param newContractOrSegment the current contract or pricing segment document
   * @param fieldName the field name of the contract or pricing segment document to be observed
   * @param commodityProfile the commodity profile of the contract that is observed (not used for pricing segment)
   * @param commodityMap the commodity map document
   */
  private getGenericDetail(oldContractOrSegment: Contract|PricingSegment, newContractOrSegment: Contract|PricingSegment,
                           fieldName: string, commodityProfile: CommodityProfile, commodityMap: CommodityMap): string {

    const displayedFieldName = DISPLAYED_FIELD_NAME_MAP[fieldName] || this.convertCamelCaseToTitleCase(fieldName);
    const formattedOldDetail = this.getFormattedDisplay(fieldName, oldContractOrSegment[fieldName], commodityProfile, commodityMap);
    const formattedNewDetail = this.getFormattedDisplay(fieldName, newContractOrSegment[fieldName], commodityProfile, commodityMap);

    if (this.isRemoveOperation(oldContractOrSegment[fieldName], newContractOrSegment[fieldName])) {
      return `${displayedFieldName} ${formattedOldDetail} removed`;
    } else if (this.isSetOperation(oldContractOrSegment[fieldName], newContractOrSegment[fieldName])) {
      return `${displayedFieldName} set ${formattedNewDetail}`;
    } else {
      return `${displayedFieldName} ${formattedOldDetail} ${RIGHT_ARROW} ${formattedNewDetail}`;
    }
  }

  /**
   * Returns true if it is a remove operation for the specified old and new value
   * @param oldVal the previous value
   * @param newVal the current value
   */
  private isRemoveOperation(oldVal: any, newVal: any): boolean {
    return !this.isEmptyVal(oldVal) && this.isEmptyVal(newVal);
  }

  /**
   * Returns true if it is a set operation for the specified old and new value
   * @param oldVal the previous value
   * @param newVal the current value
   */
  private isSetOperation(oldVal: any, newVal: any): boolean {
    return this.isEmptyVal(oldVal) && !this.isEmptyVal(newVal);
  }

  /**
   * Return true if it is falsy except for 0
   * @param val the value passed in
   */
  private isEmptyVal(val: any): boolean {
    return !val && val !== 0;
  }

  /**
   * Return title cased text for the specified camel cased text
   * @param text the camel cased text
   */
  private convertCamelCaseToTitleCase(text: string): string {
    const convertedText = text.replace( /([A-Z])/g, ' $1' );
    return convertedText.charAt(0).toUpperCase() + convertedText.slice(1);
  }

  /**
   * Return user friendly display for the specified field name, value, optional commodity profile, and optional commodity map
   * @param fieldName the contract's or pricing segment's field name passed in
   * @param fieldValue the contract's or pricing segment's field value passed in
   * @param commodityProfile the commodity profile of the contract that is observed
   * @param commodityMap the commodity map document
   */
  private getFormattedDisplay(fieldName: string, fieldValue: any,
                              commodityProfile?: CommodityProfile, commodityMap?: CommodityMap): string {
    let field: string;
    if (fieldName.includes('Timestamp')) {
      field = 'timestamp';
    } else if (fieldName.startsWith('is')) {
      field = 'indicator';
    } else {
      field = fieldName;
    }

    if (!this.isEmptyVal(fieldValue)) {
      switch (field) {
        case 'status':
          return this.titleCasePipe.transform(this.replaceTransform(fieldValue));
        case 'productionYear':
          return this.productionYearPipe.transform(commodityProfile.productionYears[fieldValue].label);
        case 'deliveryPeriod':
        case 'futuresYearMonth':
          return this.codeToMonthTransform(fieldValue);
        case 'quantity':
        case 'targetOrderThreshold':
          return `${this.decimalPipe.transform(fieldValue)} ${this.titleCasePipe.transform(commodityMap.commodities[
            commodityProfile.commodityId].contractUnit)}`;
        case 'expirationDate':
          return this.datePipe.transform(fieldValue, EXPIRATION_PIPE_FORMAT);
        case 'basisPrice':
        case 'cashPrice':
        case 'exchangeRate':
        case 'futuresPrice':
        case 'manualLockPriceDifference':
        // TODO remove freightPrice and basisAdjustment after price adjustments are in place
        case 'freightPrice':
        case 'basisAdjustment':
        case 'priceAdjustment':
          return this.decimalPipe.transform(fieldValue, PRICE_PIPE_FORMAT);
        case 'timestamp':
          return this.datePipe.transform(fieldValue, TIMESTAMP_PIPE_FORMAT);
        case 'indicator':
          return this.yesNoTransform(fieldValue);
        case 'timeInForce':
          return this.timeInForcePipe.transform(fieldValue);
        default:
          return fieldValue;
      }
    }
    return '';
  }

  /**
   * Return true if contract or pricing segment is updated by system
   * @param data the contract or pricing segment
   */
  private isUpdatedBySystem(data: Contract|PricingSegment): boolean {
    return data.lastUpdatedByDocId.includes('(');
  }

  /**
   * Return price adjustment history events detail for the specified
   * price adjustment map, old price adjustment and optional new price adjustment (update event)
   * @param priceAdjustmentMap the client's price adjustment map
   * @param oldPriceAdj the contract or pricing segment's old price adjustment
   * @param newPriceAdj the contract or pricing segment's new price adjustment
   */
  private getPriceAdjustmentEventDetail(priceAdjustmentMap: HMSPriceAdjustmentMap,
                                        oldPriceAdj: ContractPriceAdjustment, newPriceAdj?: ContractPriceAdjustment) {
    if (newPriceAdj) {
      return `${oldPriceAdj.id} ${priceAdjustmentMap.priceAdjustments[oldPriceAdj.id] ?
        priceAdjustmentMap.priceAdjustments[oldPriceAdj.id].name : 'Price Adjustment'} \
        ${this.getFormattedDisplay('priceAdjustment', oldPriceAdj.value)} ${RIGHT_ARROW} \
        ${this.getFormattedDisplay('priceAdjustment', newPriceAdj.value)}`;
    } else {
      return `${oldPriceAdj.id} ${priceAdjustmentMap.priceAdjustments[oldPriceAdj.id] ?
        priceAdjustmentMap.priceAdjustments[oldPriceAdj.id].name : 'Price Adjustment'} \
        ${this.getFormattedDisplay('priceAdjustment', oldPriceAdj.value)}`;
    }
  }

  // TODO: consider using ReplacePipe once it is exported from angular-common-services
  private replaceTransform(value: string, replace?: string, target?: string): string {
    value = value || '';
    const pattern = replace ? new RegExp(replace, 'g') : /_/g;
    target = target || ' ';
    return value.replace(pattern, target);
  }

  // TODO: consider using DisplayBooleanPipe once it is exported from angular-common-services
  private yesNoTransform(value: boolean): string {
    return value ? 'Yes' : 'No';
  }

  // TODO: consider using CodeToMonthPipe once it is exported from angular-common-services
  private codeToMonthTransform(value: string): string {
    if (!value || value.length !== 3) {
      return value;
    }

    const year = value.substring(0, 2);
    const monthCode = value.substring(2);
    const monthCodeList = Object.keys(ContractMonth);
    const idxMonth = monthCodeList.indexOf(monthCode);
    return MONTH[idxMonth] + ' ' + year;
  }
}
