<div fxFlex="100">

  <div *ngIf="!errorMessage" fxLayout="column">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="alerts-filter-field" #filter>
      <button type="button" id="clear-alerts-filter" mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0">

      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="45" fxFlex.gt-xs="15">Title</mat-header-cell>
        <mat-cell *matCellDef="let alert" fxFlex.xs="45" fxFlex.gt-xs="15">{{ alert.title }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="subtitle">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="25">Subtitle</mat-header-cell>
        <mat-cell *matCellDef="let alert" fxFlex.gt-xs="25">{{ alert.subtitle }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="12">Status</mat-header-cell>
        <mat-cell *matCellDef="let alert" fxFlex.xs="30" fxFlex.gt-xs="12">{{ alert.status | replace | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastUpdatedByName">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="15">Updated By</mat-header-cell>
        <mat-cell *matCellDef="let alert" fxFlex.gt-xs="15">{{ alert.lastUpdatedByName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastUpdatedTimestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="20">Updated</mat-header-cell>
        <mat-cell *matCellDef="let alert" fxFlex.gt-xs="20">{{ alert.lastUpdatedTimestamp | date:'short' }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="statusIcon">
        <mat-header-cell *matHeaderCellDef fxFlex.xs="25" fxFlex.gt-xs="13">Update Status</mat-header-cell>
        <mat-cell *matCellDef="let alert" fxFlex.xs="25" fxFlex.gt-xs="13" (click)="$event.stopPropagation()">

          <button type="button" id="revert-alert-search-new" *ngIf="alert.status === 'IN_PROGRESS'" mat-icon-button aria-label="Revert to New" matTooltip="Revert to New" (click)="changeAlertStatus(alert, 'NEW')">
            <mat-icon>undo</mat-icon>
          </button>

          <button type="button" id="mark-alert-search-complete" *ngIf="alert.status === 'IN_PROGRESS' || alert.status === 'NEW'" mat-icon-button aria-label="Mark Complete" matTooltip="Mark Complete" (click)="changeAlertStatus(alert, 'COMPLETE')">
            <mat-icon>done</mat-icon>
          </button>

          <button type="button" id="revert-alert-search-in-progress" *ngIf="alert.status === 'COMPLETE' && alert.lastUpdatedTimestamp >= startDate" mat-icon-button aria-label="Revert to In Progress" matTooltip="Revert to In Progress" (click)="changeAlertStatus(alert, 'IN_PROGRESS')">
            <mat-icon>undo</mat-icon>
          </button>

        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row (click)="selectAlert(alert)" *matRowDef="let alert; columns: columnsToDisplay"></mat-row>
    </mat-table>
    <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50]"></mat-paginator>

  </div>

</div>
