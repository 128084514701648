import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'hms-about-dialog',
  template: `
  <div mat-dialog-content class="about-dialog" fxLayout="column" fxLayoutAlign="start center">
    <img src="assets/ATI_Leaf_color.png" class="hms-logo" alt="ATI Logo">
    <span class="about-version">{{versionNumber}}</span>
    <span class="mat-small">{{versionDate}}</span>
    <p class="mat-small mat-hint">&copy;{{currentYear}} Advance Trading, Inc.</p>
  </div>`,
  styleUrls: ['./about-dialog.component.scss']
})
export class AboutDialogComponent {
  versionNumber: string;
  versionDate: string;
  currentYear = new Date().getFullYear();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data = {}) {
      this.versionNumber = data['versionNumber'];
      this.versionDate = data['versionDate'];
    }
}
