import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';

import { catchError, tap} from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';

import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { Contract } from '@advance-trading/ops-data-lib';
import { ExportService } from '../../service/export.service';

type ContractSearchColumns =
  'accountingSystemId' | 'creationTimestamp' | 'lastUpdatedTimestamp' | 'commodityId' | 'deliveryPeriod' | 'futuresYearMonth' |
  'originatorName' | 'clientLocationName' | 'patronName' | 'type' | 'status' | 'pricingType' | 'side' | 'quantity' | 'comments';

@Component({
  selector: 'hms-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss'],
  providers: [BreakpointObserver]
})
export class ContractsComponent implements AfterViewInit, OnChanges, OnInit, OnDestroy {

  @Input() initialTableState: {[key: string]: string|number};
  @Input() selectedContracts$: Observable<Contract[]>;
  @Output() contractListChange: EventEmitter<any> = new EventEmitter();
  @Output() contractSearchError: EventEmitter<string> = new EventEmitter();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();

  columnsToDisplay = [];
  errorMessage: string;
  dataSource = new ObservableDataSource<Contract>();
  filterValue = new FormControl();
  exportable = false;

  displayCommentsText: boolean = false;

  /** A list that tracks all the rxjs subscriptions this component registers. */
  private subscriptions: Subscription[] = [];

  breakpointGroup: 'xs' | 'md' | 'lg' | 'xl' = 'lg';

  /**
   * A dictionary of strings based on flex shorthand value percentages.
   * Each value represents the _RELATIVE_ flex growth to _all other columns_
   * within a row.
   */
  private colFlex = {
    flex0: '0 0 0%',
    flex5: '.05 1 0%',
    flex10: '.1 1 0%',
    flex20: '.2 1 0%',
    flex30: '.3 1 0%',
    flex40: '.4 1 0%',
    flex50: '.5 1 0%',
    flex60: '.6 1 0%',
    flex70: '.7 1 0%',
    flex80: '.8 1 0%',
    flex90: '.9 1 0%',
    flex100: '1 1 0%',
    flex150: '1.5 1 0%',
    flex200: '2 1 0%',
    flex300: '3 1 0%',
    flex400: '4 1 0%',
    flex500: '5 1 0%',
    flex600: '6 1 0%',
  };

  private columnFlexData: { [key in keyof Record<ContractSearchColumns, string>]: { [key in 'xs' | 'md' | 'lg' | 'xl']: string } } = {
    accountingSystemId: { xs: this.colFlex.flex20, md: this.colFlex.flex30, lg: this.colFlex.flex20, xl: this.colFlex.flex20 },
		creationTimestamp: { xs: this.colFlex.flex30, md: this.colFlex.flex50, lg: this.colFlex.flex20, xl: this.colFlex.flex20 },
		lastUpdatedTimestamp: { xs: this.colFlex.flex30, md: this.colFlex.flex50, lg: this.colFlex.flex20, xl: this.colFlex.flex20 },
		commodityId: { xs: this.colFlex.flex20, md: this.colFlex.flex30, lg: this.colFlex.flex10, xl: this.colFlex.flex10 },
		deliveryPeriod: { xs: this.colFlex.flex30, md: this.colFlex.flex50, lg: this.colFlex.flex20, xl: this.colFlex.flex10 },
		futuresYearMonth: { xs: this.colFlex.flex30, md: this.colFlex.flex50, lg: this.colFlex.flex10, xl: this.colFlex.flex10 },
    originatorName: { xs: this.colFlex.flex30, md: this.colFlex.flex50, lg: this.colFlex.flex20, xl: this.colFlex.flex20 },
		clientLocationName: { xs: this.colFlex.flex30, md: this.colFlex.flex50, lg: this.colFlex.flex20, xl: this.colFlex.flex20 },
		patronName: { xs: this.colFlex.flex30, md: this.colFlex.flex50, lg: this.colFlex.flex30, xl: this.colFlex.flex30 },
		type: { xs: this.colFlex.flex30, md: this.colFlex.flex30, lg: this.colFlex.flex10, xl: this.colFlex.flex20 },
		status: { xs: this.colFlex.flex30, md: this.colFlex.flex50, lg: this.colFlex.flex20, xl: this.colFlex.flex20 },
		pricingType: { xs: this.colFlex.flex30, md: this.colFlex.flex30, lg: this.colFlex.flex10, xl: this.colFlex.flex20 },
		side: { xs: this.colFlex.flex30, md: this.colFlex.flex30, lg: this.colFlex.flex10, xl: this.colFlex.flex20 },
		quantity: { xs: this.colFlex.flex30, md: this.colFlex.flex30, lg: this.colFlex.flex10, xl: this.colFlex.flex20 },
    comments: { xs: this.colFlex.flex30, md: this.colFlex.flex30, lg: this.colFlex.flex40, xl: this.colFlex.flex50 }
  };

  /** A dictionary of columns to display at certain widths for the activity live ledger table. */
  private columns = {
    minimal: [
      'accountingSystemId', 'lastUpdatedTimestamp', 'commodityId', 'status', 'quantity'
    ],
    most: [
      'accountingSystemId', 'creationTimestamp', 'lastUpdatedTimestamp', 'commodityId', 'patronName', 'type', 'status',
      'pricingType', 'side', 'quantity', 'comments'
    ],
    large: [
      'accountingSystemId', 'creationTimestamp', 'lastUpdatedTimestamp', 'commodityId', 'futuresYearMonth', 'patronName',
      'type', 'status', 'pricingType', 'side', 'quantity', 'comments'
    ],
    all: [
      'accountingSystemId', 'creationTimestamp', 'lastUpdatedTimestamp', 'commodityId', 'deliveryPeriod', 'futuresYearMonth',
      'originatorName', 'clientLocationName', 'patronName', 'type', 'status', 'pricingType', 'side', 'quantity', 'comments'
    ]
  };


  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('filter', {static: false}) filter: ElementRef;

  private tableState: {[key: string]: string|number} = {};

  constructor(
    private breakpointObserver: BreakpointObserver,
    private changeDetector: ChangeDetectorRef,
    public exportService: ExportService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.isSearching.emit(true);
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
    this.setupBreakpointObserversForTableColumns();

    // setup listener for filter value changes
    this.filterValue.valueChanges.subscribe((filter: string) => {
      if (filter) {
        this.tableState.filter = filter.trim();
        this.contractListChange.emit(this.tableState);
      } else if (this.tableState.filter) {
        delete this.tableState.filter;
        this.contractListChange.emit(this.tableState);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.filter) {
      this.filter.nativeElement.focus();
    }
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialTableState'] && changes['selectedContracts$']) {
      this.tableState = Object.assign({}, this.initialTableState);

      this.setupBreakpointObserversForTableColumns();

      // detect MatSort and MatPaginator so it is defined
      this.changeDetector.detectChanges();

      const sortDir = this.initialTableState.sortDir as SortDirection;
      const sortColName = this.initialTableState.sortColName as string;
      if (sortDir && sortColName) {
        this.sort.direction = sortDir;
        this.sort.active = sortColName;
      }

      if (this.initialTableState.filter) {
        this.filterValue.setValue(this.initialTableState.filter);
        this.applyFilter(this.filterValue.value);
      }

      // initialize table
      this.dataSource.data$ = this.selectedContracts$.pipe(
        tap(contracts => {
          this.isSearching.emit(false);
          this.exportable = !!contracts.length;

          // initialize pagination state when the datasource exist
          const pageIndex = this.initialTableState.pageIndex as number;
          const pageSize = this.initialTableState.pageSize as number;

          if (pageIndex !== undefined) {
            this.paginator.pageIndex = pageIndex;
          }
          if (pageSize !== undefined) {
            this.paginator.pageSize = pageSize;
          }
        }),
        catchError(err => {
          this.errorMessage = 'Error retrieving contracts; please try again later';
          this.contractSearchError.emit(this.errorMessage);
          this.isSearching.emit(false);
          console.error(`Error retrieving contracts: ${err}`);
          return of([]);
        })
      );
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectContract(contract: Contract) {
    this.router.navigate(['/contracts', contract.docId]);
  }

  handleSortChange() {
    this.tableState.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.tableState.sortColName = this.tableState.sortDir ? this.sort.active : undefined;
    this.contractListChange.emit(this.tableState);
  }

  handlePageChange() {
    this.tableState.pageSize = this.paginator.pageSize;
    this.tableState.pageIndex = this.paginator.pageIndex;
    this.contractListChange.emit(this.tableState);
  }

  getFlexLayout = (columnName: ContractSearchColumns): string => {
    if (!columnName) {
      console.error('no column name given, using default');
      return this.colFlex.flex100;
    }

    const flexValue = this.columnFlexData[columnName][this.breakpointGroup];
    if (!flexValue) {
      console.error('could not locate column name/breakpoint, using default', flexValue);
    }

    return flexValue || this.colFlex.flex100;
  }

  /** Sets up breakpoint observers for the browser window, which push which columns to display at different widths of the window.*/
  private setupBreakpointObserversForTableColumns(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    this.subscriptions.push(this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(state => {
      if (state.breakpoints[Breakpoints.XSmall]) {
        this.columnsToDisplay = this.columns.minimal;
        this.breakpointGroup = 'xs';
        this.displayCommentsText = false;
      } else if (state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.Medium]) {
        this.columnsToDisplay = this.columns.most;
        this.displayCommentsText = false;
        this.breakpointGroup = 'md';
      } else if (state.breakpoints[Breakpoints.Large]) {
        this.columnsToDisplay = this.columns.large;
        this.displayCommentsText = true;
        this.breakpointGroup = 'lg';
      } else if (state.breakpoints[Breakpoints.XLarge]) {
        this.columnsToDisplay = this.columns.all;
        this.displayCommentsText = true;
        this.breakpointGroup = 'xl';
      }
      this.changeDetector.detectChanges();
    }));
  }


}
