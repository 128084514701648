
<!-- Title -->
<div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
  <h2 data-cy="page-title-cash-bids" class="card-title">
    Cash Bids{{ locationName ? ': ' + locationName : '' }}
  </h2>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
    <mat-spinner *ngIf="isLoading" diameter="40" color="accent"></mat-spinner>
  </div>
</div>

<!-- Error message if any -->
<hms-error-message [title]="'Error Retrieving Bids Details'" [message]="errorMessage"></hms-error-message>

<!-- Basis page content -->
<div *ngIf="!errorMessage && userSettings$ | async as userSettings">
  <ng-container>
    <form [formGroup]="locationForm">
      <mat-form-field fxFlex.gt-xs="45">
        <input required matInput placeholder="Location" formControlName="location" [matAutocomplete]="autoLocation">
        <mat-error *ngIf="locationForm.get('location').invalid">You must select a valid location</mat-error>
        <mat-autocomplete #autoLocation="matAutocomplete" [displayWith]="displayLocation">
          <mat-option *ngFor="let location of filteredLocations$ | async" [value]="location">{{ location.name }}</mat-option>
        </mat-autocomplete>
        <button id="bids-clear-location" mat-button *ngIf="locationForm.get('location').value" matSuffix mat-icon-button aria-label="Clear Location" matTooltip="Clear Location" (click)="locationForm.get('location').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </ng-container>

  <!-- Get basis to populate the table -->
  <ng-container *ngIf="commodityMarketDataBasis$ | async as commodityMarketDataBasis">
    <mat-tab-group color="accent" [(selectedIndex)]="selectedTabIndex">
      <!-- List of tabs of different Commodity Profile -->
      <mat-tab *ngFor="let commodity of commodityMarketDataBasis" [label]="commodity.name">
        <ng-template matTabContent>
          <ng-container *ngIf="commodity.basis$ | async as basis">
            <div class="table-container">
              <table mat-table [dataSource]="dataSourceBasis" class="mat-elevation-z0">
                <ng-container *ngFor="let col of displayedColumn" [matColumnDef]="col">
                  <th mat-header-cell *matHeaderCellDef> {{ col | titlecase }} </th>
                  <td [ngClass]="{'futures': col === 'futures'}" mat-cell *matCellDef="let element; let i = index">
                    <div *ngIf="userDataFrequency !== 'ON_DEMAND' || col !== 'delivery'" fxLayout="column" fxLayoutAlign="end space-between">
                      <div>{{ col === 'delivery' ? (element['delivery period'] | codeToMonth) : element[col]}}</div>
                      <div *ngIf="col === 'futures'" class="futures-month-year">{{ (getFuturesYearMonth(element['delivery period']) | codeToMonth) }}</div>
                    </div>
                    <div *ngIf="userDataFrequency === 'ON_DEMAND' && col === 'delivery'" fxLayout="row" fxLayoutAlign="space-between center">
                      <div>{{ col === 'delivery' ? (element['delivery period'] | codeToMonth) : element[col]}} </div>
                      <div (click)="$event.stopPropagation()">
                        <button id="refresh-bids-futures" mat-button matSuffix mat-icon-button aria-label="refresh" (click)="refresh(i);" #dataTooltip="matTooltip"
                                [matTooltip]="lastIdxRefreshed === i ? tooltipMessage : ''" matTooltipClass="market-data-tooltip">
                          <mat-icon>refresh</mat-icon>
                        </button>
                      </div>
                    </div>
                  </td>

                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                <tr (click)="selectBid(bid)" mat-row *matRowDef="let bid; columns: displayedColumn; let i = index" [ngClass]="(authService?.userProfile && canCreateContract()) ? 'contract-creator-row' : ''" attr.data-cy="cashBids-{{commodity.commodityId}}-{{i}}"></tr>
              </table>
            </div>
          </ng-container>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <div class="mat-small" *ngIf="locationName">
    <h4>Disclaimer:</h4>
    <ul class="disclaimer-list">
      <li *ngIf="userDataFrequency !== 'REALTIME'">Market data is delayed by at least 10 minutes</li>
      <li *ngIf="userDataFrequency === 'REALTIME'">Market data is delayed up to 10 minutes</li>
      <li>Market data uses settlement price after market closes</li>
      <li *ngIf="userDataFrequency !== 'NONE'">Market data last updated {{ timestamp }}</li>
      <li *ngIf="userDataFrequency === 'NONE'">Market data last updated {{ timestamp }} and is not being refreshed per your user settings</li>
    </ul>
  </div>
</div>
