import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';

import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Auth0AuthzService, AuthService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource } from '@advance-trading/angular-common-services';
import {
  ExecutionReportService,
  LocationService,
  OperationsDataService,
  OrderFillSummary
} from '@advance-trading/angular-ops-data';
import {
  AdHocOrder,
  Client,
  CommodityMap,
  CommodityProfile,
  CommodityProfileProductionYear,
  Commodity,
  Contract,
  ContractType,
  Hedge,
  HedgeType,
  LedgerAdjustment,
  Location,
  PricingSegment,
  PricingSegmentPartType,
  Side,
  HMSUserPreferences,
} from '@advance-trading/ops-data-lib';

import { AdHocOrderService } from '../../service/ad-hoc-order.service';
import { ClientSelectorService } from '../../service/client-selector.service';
import { ContractService } from '../../service/contract.service';
import { ExportService } from '../../service/export.service';
import { HedgeService } from '../../service/hedge.service';
import { LedgerAdjustmentService } from '../../service/ledger-adjustment.service';
import { LedgerHelperService } from '../../service/ledger-helper.service';
import { PricingSegmentService } from '../../service/pricing-segment.service';
import { UserPreferencesService } from '../../service/user-preferences.service';
import { UserRoles } from '../../utilities/user-roles';

import { ActivityLogDisplay } from './activity-log-display';
import { ICON_MAP } from '../icon-map';

interface ExtendedPricingSegment extends PricingSegment {
  isDPFirstPart: boolean;
}

type LiveLedgerColumns =
  'position' | 'timestamp' | 'activityType' | 'futuresYearMonth' | 'commodityProfile' | 'side' | 'quantity' | 'productionYearLabel' |
  'basisPrice' | 'futuresPrice' | 'cashPrice' | 'originatorName' | 'locationName' | 'patronDisplayName' | 'comments' | 'deleted';


@Component({
  selector: 'hms-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  @Output() activityLogError = new EventEmitter<string>();
  @Output() loaded = new EventEmitter();
  @Input() commodityProfiles: CommodityProfile[];
  @Input() ledgerDate: string;
  @Input() ledgerEndOfDay: string;
  @Input() timezone: string;

  dataSource = new ObservableDataSource<ActivityLogDisplay>();
  columnsToDisplay = [];
  lastIdxClicked = -1;

  commodities: {[key: string]: Commodity};

  /** Whether to display notes as text or an icon. Default is `false`.*/
  displayNotesText: boolean = false;

  /** A list that tracks all the rxjs subscriptions this component registers. */
  private subscriptions: Subscription[] = [];

  private startDate: string;
  private endDate: string;
  private selectedClientDocId: string;
  private prodYearTranslations: { [key: string]: { [key: string]: CommodityProfileProductionYear } };
  private orderTypeRows: {[key: string]: ActivityLogDisplay} = {};
  private exchangeTypeRows: {[key: string]: ActivityLogDisplay} = {};

  private contracts: Contract[] = [];
  private locations: Location[] = [];

  private useLiveLedgerColor = false;

  /**
   * Break Groups for the browser window.
   * @xs extra small to small pixel breakpoints.
   * @md only medium pixel breakpoints.
   * @lg large to extra large pixel breakpoints.
  */
  breakpointGroup: 'xs' | 'md' | 'lg' = 'lg';

  /**
   * A dictionary of strings based on flex shorthand value percentages.
   * Each value represents the _RELATIVE_ flex growth to _all other columns_
   * within a row.
   */
  private colFlex = {
    flex0: '0 0 0%',
    flex5: '.05 1 0%',
    flex10: '.1 1 0%',
    flex20: '.2 1 0%',
    flex30: '.3 1 0%',
    flex40: '.4 1 0%',
    flex50: '.5 1 0%',
    flex60: '.6 1 0%',
    flex70: '.7 1 0%',
    flex80: '.8 1 0%',
    flex90: '.9 1 0%',
    flex100: '1 1 0%',
    flex150: '1.5 1 0%',
    flex200: '2 1 0%',
    flex300: '3 1 0%',
    flex400: '4 1 0%',
    flex500: '5 1 0%',
    flex600: '6 1 0%',
  };

  /**
   * Column flex data of activity log table. Sets up flex row data for each column based on the current
   * breakpoints being used.
   */
  private columnFlexData: { [key in keyof Record<LiveLedgerColumns, string>]: { [key in 'xs' | 'md' | 'lg']: string } } = {
    position: { xs: this.colFlex.flex90, md: this.colFlex.flex50, lg: this.colFlex.flex50 },
    timestamp: { xs: this.colFlex.flex80, md: this.colFlex.flex90, lg: this.colFlex.flex90 },
    activityType: { xs: this.colFlex.flex90, md: this.colFlex.flex60, lg: this.colFlex.flex70 },
    futuresYearMonth: { xs: this.colFlex.flex80, md: this.colFlex.flex80, lg: this.colFlex.flex60 },
    commodityProfile: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex90 },
    side: { xs: this.colFlex.flex90, md: this.colFlex.flex70, lg: this.colFlex.flex50 },
    quantity: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex90 },
    productionYearLabel: { xs: this.colFlex.flex90, md: this.colFlex.flex50, lg: this.colFlex.flex60 },
    basisPrice: { xs: this.colFlex.flex90, md: this.colFlex.flex70, lg: this.colFlex.flex70 },
    futuresPrice: { xs: this.colFlex.flex90, md: this.colFlex.flex70, lg: this.colFlex.flex70 },
    cashPrice: { xs: this.colFlex.flex90, md: this.colFlex.flex70, lg: this.colFlex.flex70 },
    originatorName: { xs: this.colFlex.flex90, md: this.colFlex.flex90, lg: this.colFlex.flex100 },
    locationName: { xs: this.colFlex.flex90, md: this.colFlex.flex90, lg: this.colFlex.flex100 },
    patronDisplayName: { xs: this.colFlex.flex90, md: this.colFlex.flex90, lg: this.colFlex.flex150 },
    comments: { xs: this.colFlex.flex90, md: this.colFlex.flex100, lg: this.colFlex.flex200 },
    deleted: { xs: this.colFlex.flex0, md: this.colFlex.flex0, lg: this.colFlex.flex0 } // this column is visually hidden
  };

  /** A dictionary of columns to display at certain widths for the activity live ledger table. */
  private columns = {
    minimal: [
      'position', 'timestamp', 'activityType', 'futuresYearMonth', 'commodityProfile', 'side', 'displayQuantity', 'quantity',
      'originalQuantity', 'deleted'
    ],
    most: [
      'position', 'timestamp', 'activityType', 'futuresYearMonth', 'commodityProfile', 'side', 'displayQuantity', 'quantity',
      'originalQuantity', 'productionYearLabel', 'basisPrice', 'futuresPrice', 'cashPrice', 'comments', 'deleted'
    ],
    all: [
      'position', 'timestamp', 'activityType', 'futuresYearMonth', 'commodityProfile', 'side', 'displayQuantity', 'quantity',
      'originalQuantity', 'productionYearLabel', 'basisPrice', 'futuresPrice', 'cashPrice', 'originatorName', 'locationName',
      'patronDisplayName', 'comments', 'deleted'
    ]
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private adHocOrderService: AdHocOrderService,
    private breakpointObserver: BreakpointObserver,
    private clientSelectorService: ClientSelectorService,
    private contractService: ContractService,
    private executionReportService: ExecutionReportService,
    public exportService: ExportService,
    private hedgeService: HedgeService,
    private ledgerAdjustmentService: LedgerAdjustmentService,
    private ledgerHelperService: LedgerHelperService,
    private locationService: LocationService,
    private operationsDataService: OperationsDataService,
    private pricingSegmentService: PricingSegmentService,
    private router: Router,
    private userPreferencesSettingsService: UserPreferencesService,
    private changeDetector: ChangeDetectorRef,
  ) { }

  /**
   * Get flex layout for a column within the table.
   * @param columnName Any column name that can be shown in the live ledger table.
   */
  getFlexLayout = (columnName: LiveLedgerColumns): string => {
    if (!columnName) {
      console.error('no column name given, using default');
      return this.colFlex.flex100;
    }

    const flexValue = this.columnFlexData[columnName][this.breakpointGroup];
    if (!flexValue) {
      console.error('could not locate column name/breakpoint, using default', flexValue);
    }

    return flexValue || this.colFlex.flex100;
  }

  ngOnInit() {
    this.setupBreakpointObserversForTableColumns();
  }

  ngOnDestroy(): void {
    this.subscriptions?.forEach?.(sub => sub?.unsubscribe?.());
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  ngOnChanges(changes: SimpleChanges) {
    // get activity log based on which commodity profile is selected
    if (changes['ledgerDate']) {
      this.prodYearTranslations = {};
      this.dataSource.data$ = this.clientSelectorService.getSelectedClient().pipe(
        switchMap((selectedClient: Client) => {
          this.selectedClientDocId = selectedClient.docId;
          const userDocId = this.authService.userProfile.app_metadata.firestoreDocId;
          return this.userPreferencesSettingsService.getHmsPreferencesByUserDocId(userDocId);
        }),
        switchMap((hmsUserPreferences: HMSUserPreferences) => {
          if (hmsUserPreferences) {
            this.useLiveLedgerColor = hmsUserPreferences.useLiveLedgerColor;
          }
          return this.operationsDataService.getCommodityMap();
        }),
        switchMap((doc: CommodityMap) => {
          this.commodities = doc.commodities;
          const dateParams = this.ledgerHelperService.getDateParameters(this.ledgerDate);
          this.endDate = dateParams.endDate;
          this.startDate = dateParams.startDate;
          return combineLatest([
            this.getContractsAndSegmentsObs(),
            this.getHedgesObs(),
            this.getLedgerAdjustmentsObs(),
            this.getAdHocOrdersObs()
          ]);
        }),
        map(([contractsAndSegments, hedges, ledgerAdjustments, adHocOrders]) => {
          // combine all pricing events, hedges, adjustments made today
          let logs: ActivityLogDisplay[] = contractsAndSegments.concat(hedges, ledgerAdjustments, adHocOrders);

          // sort activity logs by creationTimestamp in descending order
          logs = logs.sort((firstLog, secondLog) => secondLog.timestamp.localeCompare(firstLog.timestamp));

          // put additional order type rows in the proper position
          Object.values(this.orderTypeRows).forEach(orderTypeRow => {
            // find index of the event with the same doc id
            const currEventIdx = logs.findIndex(log => log.docId === orderTypeRow.docId);
            // insert current order row at found index
            logs.splice(currEventIdx, 0,  orderTypeRow);
          });

          // put additional exchange type rows in the proper position
          Object.values(this.exchangeTypeRows).forEach(exchangeTypeRow => {
            // find index of the event with the same doc id
            const currEventIdx = logs.findIndex(log => log.docId === exchangeTypeRow.docId);
            // insert current order row at found index
            logs.splice(currEventIdx, 0,  exchangeTypeRow);
          });

          // set position number in descending order
          logs.map((log, index) => log.position = logs.length - index);

          return logs;
        }),
        tap(activityLogs => this.loaded.emit()),
        catchError(err => {
          if (err.name === 'MissingLedgerEndOfDayError') {
            this.activityLogError.emit(err.message);
          } else {
            this.activityLogError.emit('Error retrieving activity log; please try again later');
          }
          console.error(`Error retrieving activity log: ${err}`);
          return of([]);
        })
      );

    }
  }

  getIconName(type: string) {
    return ICON_MAP[type];
  }

  selectActivityLog(activityLog: ActivityLogDisplay) {
    if (activityLog.activityType === 'HEDGE') {
      this.router.navigate(['/hedges', activityLog.docId]);
    } else if (activityLog.activityType === 'ADJUSTMENT') {
      this.router.navigate(['../ledgeradjustments', activityLog.docId], {relativeTo: this.activatedRoute});
    } else if (activityLog.activityType === 'AD_HOC_ORDER') {
      this.router.navigate(['/adhocorders', activityLog.docId]);
    } else if (activityLog.segmentDocId){
      this.router.navigate([ '/contracts', activityLog.contractDocId ], { queryParams: { segmentId: activityLog.segmentDocId }, queryParamsHandling: 'merge' });
    } else {
      this.router.navigate([ '/contracts', activityLog.contractDocId ]);
    }
  }

  getTypeNgClass(activityLog: ActivityLogDisplay) {
    const typeNgClass = {};
    if (this.useLiveLedgerColor) {
      typeNgClass['type-icon'] = true;

      if (activityLog.orderDocId && Number.isFinite(activityLog.futuresPrice)
        && activityLog.activityType !== 'HEDGE' && activityLog.activityType !== 'AD_HOC_ORDER'
      ) {
        typeNgClass['order-row'] = true;
      } else if (activityLog.isExchange) {
        typeNgClass['exchange'] = true;
      } else if (activityLog.activityType === ContractType.BASIS) {
        typeNgClass['basis-contract-or-pricing'] = true;
      } else if (activityLog.activityType === ContractType.HTA) {
        typeNgClass['hta-contract-or-pricing'] = true;
      } else if (activityLog.activityType === ContractType.DP) {
        typeNgClass['dp-pricing'] = true;
      } else if (activityLog.activityType === 'HEDGE') {
        typeNgClass['hedge'] = true;
      } else if (activityLog.activityType === 'AD_HOC_ORDER') {
        typeNgClass['ad-hoc-order'] = true;
      } else if (activityLog.activityType === 'ADJUSTMENT') {
        typeNgClass['ledger-adjustment'] = true;
      }
    }

    return typeNgClass;
  }

  private getContractsAndSegmentsObs(): Observable<ActivityLogDisplay[]> {
    return combineLatest([
      this.getContractsObs(),
      this.getSegmentsObs()
    ]).pipe(
      switchMap(([contracts, segments]) => {
        const logs = contracts.concat(segments);
        return this.handleContractsAndSegments(logs);
      })
    );
  }

  private getContractsObs(): Observable<ActivityLogDisplay[]> {
    return combineLatest([
      this.getCashAndHtaPricingEventContractsObs(),
      this.getBasisPricingEventContractsObs(),
      this.getExchangeContractObs()
    ]).pipe(
      switchMap(([ cashHtaContracts, basisContracts, exchangeContracts ]) => {
        const contracts: Contract[] = cashHtaContracts.concat(basisContracts, exchangeContracts);
        this.contracts = [ ...contracts ];
        if (contracts.length === 0) {
          return of([]);
        }
        const contractRows: ActivityLogDisplay[] =
          contracts.map((contract: Contract) => {
            const profile = this.getCommodityProfile(contract.commodityProfileDocId);
            const activityType = contract.isSpot ? 'SPOT' : contract.type;
            const translatedProdYears = this.getTranslatedProdYears(profile);

            return {
              activityType,
              timestamp: this.getContractTimestamp(contract),
              side: contract.side,
              commodityProfile: profile,
              orderDocId: contract.contractOrderDocId,
              productionYearLabel: translatedProdYears[ contract.productionYear ].label,
              futuresYearMonth: contract.futuresYearMonth,
              quantity: contract.quantity,
              basisPrice: contract.basisPrice,
              futuresPrice: contract.futuresPrice,
              cashPrice: contract.cashPrice,
              originatorName: contract.originatorName,
              locationName: contract.clientLocationName,
              patronName: contract.patronName,
              patronAccountingSystemId: contract.patronAccountingSystemId,
              comments: contract.comments,
              docId: contract.docId,
              contractDocId: contract.docId,
              isPricingSegment: false,
              deletionTimestamp: contract.deletionTimestamp,
              cancellationTimestamp: contract.cancellationTimestamp,
              targetOrderThreshold: contract.targetOrderThreshold,
              isExchange: contract.isExchange,
              exchangeContracts: contract.exchangeContracts,
              originalQuantity: contract.originalQuantity
            } as ActivityLogDisplay;

          });
        return of(contractRows);
      })
    );
  }

  private getCashAndHtaPricingEventContractsObs(): Observable<Contract[]> {
    const excludeDeleted = false;
    const excludeExchange = true;
    return this.contractService.getContractsByTypeAndFuturesLockedTimestamp(
      this.selectedClientDocId, [ContractType.CASH, ContractType.HTA], this.startDate, this.endDate, excludeDeleted, excludeExchange);
  }

  private getBasisPricingEventContractsObs(): Observable<Contract[]> {
    const excludeDeleted = false;
    const excludeExchange = true;
    return this.contractService.getContractsByTypeAndBasisLockedTimestamp(
      this.selectedClientDocId, [ContractType.BASIS], this.startDate, this.endDate, excludeDeleted, excludeExchange);
  }

  private getExchangeContractObs(): Observable<Contract[]> {
    return this.contractService.getExchangeContractsByExchangeTimestamp(this.selectedClientDocId, this.startDate, this.endDate);
  }

  /** Sets up breakpoint observers for the browser window, which push which columns to display at different widths of the window.*/
  private setupBreakpointObserversForTableColumns(): void {
    this.subscriptions.push(this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(state => {
      if (state.breakpoints[Breakpoints.XSmall]) {
        this.columnsToDisplay = this.columns.minimal;
        this.breakpointGroup = 'xs';
        this.displayNotesText = false;
        this.changeDetector.detectChanges();
      } else if (state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.Medium]) {
        this.columnsToDisplay = this.columns.most;
        this.displayNotesText = false;
        this.breakpointGroup = 'md';
        this.changeDetector.detectChanges();
      } else if (state.breakpoints[Breakpoints.Large] || state.breakpoints[Breakpoints.XLarge]) {
        this.columnsToDisplay = this.columns.all;
        this.displayNotesText = true;
        this.breakpointGroup = 'lg';
        this.changeDetector.detectChanges();
      }
    }));
  }

  private getSegmentsObs(): Observable<ActivityLogDisplay[]> {
    return combineLatest([
      this.getHtaAndBasisAndDpCashPricingEventSegmentsObs(),
      this.getDpPricingSegmentsByFuturesLockedTimestampWithFuturesDpSegmentInitialTypeObs(),
      this.getDpPricingSegmentsByBasisLockedTimestampWithBasisDpSegmentInitialTypeObs(),
      this.getExchangePricingSegmentsObs()
    ]).pipe(
      switchMap(([ htaAndBasisAndDpCashSegments,
        dpFuturesLockedSegments,
        dpBasisLockedSegments,
        exchangeSegments ]) => {
        const mappedFullyPricedSegments = htaAndBasisAndDpCashSegments.concat(exchangeSegments).map((segment: PricingSegment) => {
          const mappedSegment = {...segment} as ExtendedPricingSegment;
          mappedSegment.isDPFirstPart = false;
          return mappedSegment;
        });

        const mappedDPFuturesLockedFirstPartSegments = dpFuturesLockedSegments.map((segment: PricingSegment) => {
          const mappedSegment = {...segment} as ExtendedPricingSegment;
          mappedSegment.isDPFirstPart = true;
          // Note: check if it has cash price populated (this could be either when second part is priced at a target)
          if (Number.isFinite(mappedSegment.cashPrice)) {
            delete mappedSegment.cashPrice;
            delete mappedSegment.basisPrice;
          }
          return mappedSegment;
        });

        const mappedDPBasisLockedFirstPartSegments = dpBasisLockedSegments.map((segment: PricingSegment) => {
          const mappedSegment = {...segment} as ExtendedPricingSegment;
          mappedSegment.isDPFirstPart = true;
          // Note: check if it has cash price populated (this could be either when second part is priced at a target)
          if (Number.isFinite(mappedSegment.cashPrice)) {
            delete mappedSegment.cashPrice;
            delete mappedSegment.futuresPrice;
          }
          return mappedSegment;
        });

        const segments: ExtendedPricingSegment[] = mappedFullyPricedSegments.concat(
          mappedDPFuturesLockedFirstPartSegments, mappedDPBasisLockedFirstPartSegments);

        if (segments.length === 0) {
          return of([]);
        }

        return combineLatest(
          segments.map((segment: ExtendedPricingSegment) => {
            return this.getSingleLocation(segment.clientLocationDocId).pipe(
              map((location: Location) => {
                if (!this.locations.includes(location)) {
                  this.locations.push(location);
                }
                const profile = this.getCommodityProfile(segment.commodityProfileDocId);
                return {
                  activityType: segment.contractType,
                  timestamp: this.getSegmentTimestamp(segment),
                  side: segment.side,
                  commodityProfile: profile,
                  orderDocId: segment.orderDocId,
                  futuresYearMonth: segment.futuresYearMonth,
                  quantity: segment.quantity,
                  basisPrice: segment.basisPrice,
                  futuresPrice: segment.futuresPrice,
                  cashPrice: segment.cashPrice,
                  originatorName: segment.originatorName,
                  locationDocId: segment.clientLocationDocId,
                  locationName: location.name,
                  docId: segment.docId,
                  contractDocId: segment.contractDocId,
                  isPricingSegment: true,
                  deletionTimestamp: segment.deletionTimestamp,
                  cancellationTimestamp: segment.cancellationTimestamp,
                  targetOrderThreshold: segment.targetOrderThreshold,
                  isExchange: segment.isExchange,
                  exchangeContracts: segment.exchangeContracts,
                  segmentDocId: segment.docId,
                  originalQuantity: segment.originalQuantity
                } as ActivityLogDisplay;
              })
            );
          })
        );
      }),
      switchMap((segmentRows: ActivityLogDisplay[]) => {
        if (segmentRows.length === 0) {
          return of([]);
        }

        return combineLatest(
          segmentRows.map((segmentRow: ActivityLogDisplay) => {
            return this.getSingleContract(segmentRow.contractDocId).pipe(
              map((contract: Contract) => {
                if (!this.contracts.includes(contract)) {
                  this.contracts.push(contract);
                }
                const translatedProdYears = this.getTranslatedProdYears(segmentRow.commodityProfile);

                return {
                  ...segmentRow,
                  productionYearLabel: translatedProdYears[ contract.productionYear ].label,
                  patronName: contract.patronName,
                  patronAccountingSystemId: contract.patronAccountingSystemId,
                  comments: contract.comments
                };
              })
            );
          })
        );
      })
    );
  }

  private getHtaAndBasisAndDpCashPricingEventSegmentsObs(): Observable<PricingSegment[]> {
    return this.pricingSegmentService.findPricingSegmentsByTypeAndCashLockedTimestamp(
      this.selectedClientDocId, [ContractType.HTA, ContractType.BASIS, ContractType.DP], this.startDate, this.endDate);
  }

  private getDpPricingSegmentsByFuturesLockedTimestampWithFuturesDpSegmentInitialTypeObs(): Observable<PricingSegment[]> {
    const excludeDeleted = false;
    return this.pricingSegmentService.findDpPricingSegmentsByFuturesLockedTimestampWithFuturesDpFirstPartType(
      this.selectedClientDocId, this.startDate, this.endDate, excludeDeleted);
  }

  private getDpPricingSegmentsByBasisLockedTimestampWithBasisDpSegmentInitialTypeObs(): Observable<PricingSegment[]> {
    const excludeDeleted = false;
    return this.pricingSegmentService.findDpPricingSegmentsByBasisLockedTimestampWithBasisDpFirstPartType(
      this.selectedClientDocId, this.startDate, this.endDate, excludeDeleted);
  }

  private getExchangePricingSegmentsObs(): Observable<PricingSegment[]> {
    return this.pricingSegmentService.findExchangePricingSegmentsByExchangeTimestamp(
      this.selectedClientDocId, this.startDate, this.endDate);
  }

  private getHedgesObs(): Observable<ActivityLogDisplay[]> {
    return this.hedgeService.getHedgesByDateRange(this.selectedClientDocId, this.startDate, this.endDate).pipe(
      switchMap((hedges: Hedge[]) => {
        if (hedges.length === 0) {
          return of([]);
        }
        // fetching futures price from ExecutionReports
        return combineLatest(
          hedges.map((hedge: Hedge) => {
            const profile = this.getCommodityProfile(hedge.commodityProfileDocId);
            const translatedProdYears = this.getTranslatedProdYears(profile);
            // TODO undefined type check can be removed after a data conversion updates all Hedge docs
            const isStandardHedge: boolean = !hedge.type || hedge.type === HedgeType.STANDARD;
            const hedgeRow = {
              activityType: 'HEDGE',
              timestamp: hedge.creationTimestamp,
              side: hedge.side,
              commodityProfile: profile,
              productionYearLabel: translatedProdYears[ hedge.productionYear ].label,
              futuresYearMonth: hedge.futuresYearMonth,
              quantity: hedge.quantity,
              originatorName: hedge.creatorName,
              comments: hedge.comments,
              docId: hedge.docId,
              orderDocId: hedge.orderDocId,
              hedgeType: hedge.type || HedgeType.STANDARD,
              futuresPrice: !isStandardHedge ? hedge.price : undefined,
              deletionTimestamp: hedge.deletionTimestamp
            } as ActivityLogDisplay;
            if (hedgeRow.hedgeType !== HedgeType.STANDARD) {
              return of(hedgeRow);
            }
            return this.executionReportService.getOrderFillSummaryByOrderDocId(
              hedgeRow.commodityProfile.accountDocId, hedge.orderDocId).pipe(
                map((orderFillSummary: OrderFillSummary) => {
                  if (orderFillSummary) {
                    return {
                      ...hedgeRow,
                      isSplitFilled: orderFillSummary.isSplitFilled,
                      futuresPrice: this.getDisplayPrice(orderFillSummary.fillPrice, hedgeRow.commodityProfile.commodityId)
                    } as ActivityLogDisplay;
                  }
                  // should not happen
                  console.error(`Order fill data was not able to be retrieved for Hedge ${hedge.docId}`);
                  return hedgeRow;
                }),
                catchError(err => {
                  console.error(`Failed to retrieve order fill data for Hedge with order id ${hedge.orderDocId}: ${err}`);
                  return of(hedgeRow);
                })
              );
          })
        );
      })
    );
  }

  private getLedgerAdjustmentsObs(): Observable<ActivityLogDisplay[]> {
    return this.ledgerAdjustmentService.getLedgerAdjustmentsByDate(this.selectedClientDocId, this.startDate, this.endDate).pipe(
      switchMap((ledgerAdjustments: LedgerAdjustment[]) => {
        if (ledgerAdjustments.length === 0) {
          return of([]);
        }
        const ledgerRows: ActivityLogDisplay[] = ledgerAdjustments.map((ledgerAdjustment: LedgerAdjustment) => {
          const profile = this.getCommodityProfile(ledgerAdjustment.commodityProfileDocId);
          const translatedProdYears = this.getTranslatedProdYears(profile);

          return {
            activityType: 'ADJUSTMENT',
            timestamp: ledgerAdjustment.creationTimestamp,
            commodityProfile: profile,
            productionYearLabel: translatedProdYears[ ledgerAdjustment.productionYear ].label,
            quantity: ledgerAdjustment.quantity,
            originatorName: ledgerAdjustment.creatorName,
            comments: ledgerAdjustment.comments,
            docId: ledgerAdjustment.docId
          } as ActivityLogDisplay;
        });
        return of(ledgerRows);
      })
    );
  }

  private canViewAdHocOrders() {
    return this.authzService.currentUserHasRole(UserRoles.AD_HOC_ORDER_VIEWER_ROLE) ||
      this.authzService.currentUserHasRole(UserRoles.AD_HOC_ORDER_ADMIN_ROLE);
  }

  private getAdHocOrdersObs(): Observable<ActivityLogDisplay[]> {
    if (!this.canViewAdHocOrders()) {
      return of([]);
    }
    return this.adHocOrderService.getAdHocOrdersByCompletionDateRange(
      this.selectedClientDocId, this.startDate, this.endDate).pipe(
        switchMap((adHocOrders: AdHocOrder[]) => {
          if (adHocOrders.length === 0) {
            return of([]);
          }

          // fetching futures price from ExecutionReports
          // get order fill price
          return combineLatest(
            adHocOrders.map((adHocOrder: AdHocOrder) => {
              const profile = this.getCommodityProfile(adHocOrder.commodityProfileDocId);
              const adHocOrderRow = {
                activityType: 'AD_HOC_ORDER',
                timestamp: adHocOrder.completionTimestamp,
                side: adHocOrder.side,
                commodityProfile: profile,
                futuresYearMonth: adHocOrder.contractYearMonth,
                legs: adHocOrder.legs,
                // Note: assuming to use first leg for now. We might need to revisit this workaround later if we have issues.
                quantity: profile.commodityId
                  ? (adHocOrder.quantity * this.commodities[ profile.commodityId ].contractSize)
                  : (adHocOrder.quantity * this.commodities[ adHocOrder.legs[ 0 ].commodityId ].contractSize),
                originatorName: adHocOrder.creatorName,
                comments: adHocOrder.comments,
                docId: adHocOrder.docId,
                orderDocId: adHocOrder.orderDocId
              } as ActivityLogDisplay;

              if (adHocOrderRow.orderDocId) {
                return this.executionReportService.getOrderFillSummaryByOrderDocId(
                  adHocOrderRow.commodityProfile.accountDocId, adHocOrder.orderDocId).pipe(
                    switchMap((orderFillSummary: OrderFillSummary) => {
                      if (orderFillSummary) {
                        // futures ad hoc order
                        if (adHocOrderRow.commodityProfile.commodityId) {
                          return of({
                            ...adHocOrderRow,
                            isSplitFilled: orderFillSummary.isSplitFilled,
                            futuresPrice: this.getDisplayPrice(orderFillSummary.fillPrice, adHocOrderRow.commodityProfile.commodityId)
                          } as ActivityLogDisplay);
                          // spread ad hoc order (need to get order.symbol since commodityId is an empty string)
                        } else {
                          return of({
                            ...adHocOrderRow,
                            isSplitFilled: orderFillSummary.isSplitFilled,
                            futuresPrice: this.getDisplayPrice(orderFillSummary.fillPrice, adHocOrder.legs[ 0 ].commodityId)
                          } as ActivityLogDisplay);
                        }
                      }
                      // this could happen until the order fill data is received from the CME
                      // or ad hoc order that is associated with an order that does not fill
                      return of(adHocOrderRow);
                    }),
                    catchError(err => {
                      console.error(`Failed to retrieve order fill data for AdHocOrder with order id ${adHocOrder.orderDocId}: ${err}`);
                      return of(adHocOrderRow);
                    })
                  );
              }
              return of(adHocOrderRow);
            })
          );
        })
      );
  }

  private getContractTimestamp(contract: Contract): string {
    if (contract.isExchange) {
      return contract.exchangeTimestamp;
    } else if (contract.type === ContractType.CASH || contract.type === ContractType.HTA) {
      return contract.futuresLockedTimestamp;
    } else {
      return contract.basisLockedTimestamp;
    }
  }

  private getSegmentTimestamp(segment: ExtendedPricingSegment): string {
    if (segment.isExchange) {
      return segment.exchangeTimestamp;
    } else if (segment.contractType === ContractType.DP
      && segment.dpFirstPartType === PricingSegmentPartType.FUTURES && segment.isDPFirstPart) {
      return segment.futuresLockedTimestamp;
    } else if (segment.contractType === ContractType.DP
      && segment.dpFirstPartType === PricingSegmentPartType.BASIS && segment.isDPFirstPart) {
      return segment.basisLockedTimestamp;
    } else {
      return segment.cashLockedTimestamp;
    }
  }

  private getCommodityProfile(commodityProfileDocId: string): CommodityProfile {
    return this.commodityProfiles.find(commodityProfile => commodityProfile.docId === commodityProfileDocId);
  }

  // Pull in missing contracts to avoid multiple lookups/emissions
  private getSingleContract(contractDocId: string): Observable<Contract> {
    const queriedResultContract = this.contracts.find(contract => contract.docId === contractDocId);
    return queriedResultContract ?
      of(queriedResultContract) :
      this.contractService.getContractByDocId(this.selectedClientDocId, contractDocId);
  }

  // Pull in locations to avoid multiple lookups/emissions
  private getSingleLocation(locationDocId: string): Observable<Location> {
    const location = this.locations.find(location => location.docId === locationDocId);
    return location ?
      of(location) :
      this.locationService.getLocationByDocId(this.selectedClientDocId, locationDocId);
  }

  private getTranslatedProdYears(commodityProfile: CommodityProfile) {
    if (!this.prodYearTranslations[commodityProfile.commodityId]) {
      this.prodYearTranslations[commodityProfile.commodityId] =
        this.ledgerHelperService.getTranslatedProductionYears(
          this.ledgerDate, commodityProfile.productionYears, this.ledgerEndOfDay, this.timezone);
    }
    return this.prodYearTranslations[commodityProfile.commodityId];
  }

  private getDisplayPrice(price: number, symbol: string) {
    const priceDivisor =  this.commodities[symbol] ? this.commodities[symbol].marketDataDivisor : 1;
    return price / priceDivisor;
  }

  private handleContractsAndSegments(logs: ActivityLogDisplay[]): Observable<ActivityLogDisplay[]> {
    this.orderTypeRows = {};
    this.exchangeTypeRows = {};
    if (logs.length === 0) {
      return of([]);
    }

    // fetching futures price from ExecutionReports
    return combineLatest(
      logs.map((log: ActivityLogDisplay) => {
        // check if the contract/segment is filled directly at the exchange
        if (log.orderDocId && Number.isFinite(log.futuresPrice)) {
          return this.executionReportService.getOrderFillSummaryByOrderDocId(
            log.commodityProfile.accountDocId, log.orderDocId).pipe(
            map((orderFillSummary: OrderFillSummary) => {
              this.addOrderRow(log, orderFillSummary);
              // still return original contract/segment
              return log;
            }),
            catchError(err => {
              console.error(`Failed to retrieve order fill data from execution reports: ${err}`);
              this.addOrderRow(log, undefined);
              return of(log);
            })
          );
        } else {
          if (log.isExchange) {
            this.addExchangeRow(log);
          }
          return of(log);
        }
      })
    );
  }

  private addOrderRow(log: ActivityLogDisplay, orderFillSummary: OrderFillSummary) {
    const currOrderType = {
      ...log
    } as ActivityLogDisplay;

    currOrderType.activityType = 'ORDER';
    currOrderType.quantity = this.getOrderQuantity(log);

    // The order should be the opposite of the associated contract or segment
    if (currOrderType.side === Side.BUY) {
      currOrderType.side = Side.SELL;
    } else {
      currOrderType.side = Side.BUY;
    }

    if (orderFillSummary) {
      currOrderType.isSplitFilled = orderFillSummary.isSplitFilled;
      currOrderType.futuresPrice = orderFillSummary.fillPrice /
                                   this.commodities[log.commodityProfile.commodityId].marketDataDivisor;
    } else {
      console.error(`Order fill data was not able to be retrieved for Order ${log.orderDocId}`);
    }
    // remove basisPrice and cashPrice
    currOrderType.basisPrice = undefined;
    currOrderType.cashPrice = undefined;
    // add order row for the qualifying contract/segment using contract/segment doc id as key to avoid duplicate
    this.orderTypeRows[currOrderType.docId] = currOrderType;
  }

  private addExchangeRow(log: ActivityLogDisplay) {
    const currExchangeType = {
      ...log
    } as ActivityLogDisplay;
    currExchangeType.originalQuantity = undefined;
    currExchangeType.activityType = 'EXCHANGE';
    currExchangeType.quantity = this.getExchangeQuantity(log);

    // The order should be the opposite of the associated contract or segment
    if (currExchangeType.side === Side.BUY) {
      currExchangeType.side = Side.SELL;
    } else {
      currExchangeType.side = Side.BUY;
    }

    // remove basisPrice and cashPrice
    currExchangeType.basisPrice = undefined;
    currExchangeType.cashPrice = undefined;

    // add exchange row for the qualifying contract/segment using contract/segment doc id as key to avoid duplicate
    this.exchangeTypeRows[currExchangeType.docId] = currExchangeType;
  }

  private getOrderQuantity(log: ActivityLogDisplay) {
    const contractSize = this.commodities[log.commodityProfile.commodityId].contractSize;
    const fullContracts = Math.trunc(log.quantity / contractSize);
    const remainingQuantity = log.quantity % contractSize;
    return remainingQuantity >= log.targetOrderThreshold ? (fullContracts + 1) * contractSize : fullContracts * contractSize;
  }

  private getExchangeQuantity(log: ActivityLogDisplay) {
    const contractSize = this.commodities[log.commodityProfile.commodityId].contractSize;
    return log.exchangeContracts * contractSize;
  }

}
