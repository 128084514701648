import { Component } from '@angular/core';
@Component({
  selector: 'hms-bids',
  templateUrl: './bids.component.html',
  styleUrls: ['./bids.component.scss']
})

export class BidsComponent{
  constructor() { }
}
