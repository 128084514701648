import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Auth0AuthzService, AuthService } from '@advance-trading/angular-ati-security';
import { CommonValidators } from '@advance-trading/angular-common-services';
import { CommodityProfileService, OperationsDataService, UserService } from '@advance-trading/angular-ops-data';
import { Client, Commodity, CommodityMap, CommodityProfile, ContractMonth, User } from '@advance-trading/ops-data-lib';

import * as moment from 'moment';

import { Observable, of } from 'rxjs';
import { catchError, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { RollType } from './roll-type';

import { BatchRollService } from '../service/batch-roll.service';
import { ClientSelectorService } from '../service/client-selector.service';
import { DeliveryRoll } from '../utilities/delivery-roll';
import { FuturesRoll } from '../utilities/futures-roll';
import { UserRoles } from '../utilities/user-roles';

const YEAR_FORMAT = 'YY';

@Component({
  selector: 'hms-batch-roll',
  templateUrl: './batch-roll.component.html',
  styleUrls: ['./batch-roll.component.scss']
})
export class BatchRollComponent implements OnInit {
  errorMessage = '';
  isLoading = true;
  updateComplete = true;

  @ViewChild('fromMonthPicker', { static: false }) fromMonthRef;
  @ViewChild('toMonthPicker', { static: false }) toMonthRef;
  @ViewChild('form', { static: false }) batchRollFormRef;

  batchRollForm: FormGroup = this.formBuilder.group({
    commodityProfile: ['', [Validators.required, CommonValidators.objectValidator]],
    rollType: ['', [Validators.required]],
    fromMonth: ['', [Validators.required]],
    toMonth: ['', [Validators.required]],
  });

  selectedClientDocId: string;

  // form data
  commodityProfiles$: Observable<CommodityProfile[]>;

  rollTypes = Object.keys(RollType);

  private contractMonths = Object.keys(ContractMonth);

  private commodities: { [key: string]: Commodity };

  constructor(
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private batchRollService: BatchRollService,
    private clientSelectorService: ClientSelectorService,
    private commodityProfileService: CommodityProfileService,
    private formBuilder: FormBuilder,
    private operationsDataService: OperationsDataService,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.PERIOD_BATCH_UPDATER_ROLE)) {
      this.errorMessage = 'You do not have permission to perform batch roll.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.commodityProfiles$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((client: Client) => {
        this.selectedClientDocId = client.docId;
        return this.operationsDataService.getCommodityMap();
      }),
      switchMap((doc: CommodityMap) => {
        this.commodities = doc.commodities;
        return this.commodityProfileService.getActiveCommodityProfilesByTypeAndClientDocId(this.selectedClientDocId);
      }),
      tap(() => {
        this.isLoading = false;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving batch roll form data; please try again later';
        console.error(`Error retrieving batch roll form data: ${err.message ? err.message : err}`);
        return of([]);
      })
    );

    this.onCommodityProfileChanges();
    this.onRollTypeChanges();
  }

  get maxFromMonth() {
    const toMonth = this.batchRollForm.get('toMonth').value;
    return toMonth ? moment(toMonth).subtract(1, 'month') : undefined;
  }

  get minToMonth() {
    const fromMonth = this.batchRollForm.get('fromMonth').value;
    return fromMonth ? moment(fromMonth).add(1, 'month') : undefined;
  }

  validMonthFilter = (currentMoment: moment.Moment) => {
    const rollType = this.batchRollForm.get('rollType').value;
    const commodityProfile: CommodityProfile = this.batchRollForm.get('commodityProfile').value;
    if (rollType === RollType.FUTURES && commodityProfile) {
      const futuresMonths = this.commodities[commodityProfile.commodityId].contractMonths;
      return futuresMonths.includes(currentMoment.month());
    }
    return true;
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    } else if (this.batchRollForm.hasError('sameFromAndToMonth')) {
      return 'Period to roll from and to must be different';
    }
    return 'Unknown error';
  }

  compareCommodityProfile(profile1: CommodityProfile, profile2: CommodityProfile) {
    return profile1 && profile2 && profile1.docId === profile2.docId;
  }

  allowTabOnly(event: KeyboardEvent) {
    if (event.key !== 'Tab') {
      event.preventDefault();
    }
  }

  selectFromMonth(fromMonth: moment.Moment) {
    this.batchRollForm.get('fromMonth').setValue(fromMonth);
    this.batchRollForm.get('fromMonth').markAsDirty();
    this.fromMonthRef.close();
  }

  selectToMonth(toMonth: moment.Moment) {
    this.batchRollForm.get('toMonth').setValue(toMonth);
    this.batchRollForm.get('toMonth').markAsDirty();
    this.toMonthRef.close();
  }

  reset() {
    // this reset the form behavior to act as if it was rendered in the beginning
    this.batchRollFormRef.resetForm();
  }

  batchUpdate() {
    this.updateComplete = false;
    const commodityProfile = this.batchRollForm.get('commodityProfile').value;
    const rollType = this.batchRollForm.get('rollType').value;
    const currentPeriod = this.translateMomentToContractMonth(this.batchRollForm.get('fromMonth').value);
    const newPeriod = this.translateMomentToContractMonth(this.batchRollForm.get('toMonth').value);

    this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
      switchMap((user: User) => {
        // call http service according to the roll type
        if (rollType === RollType.DELIVERY) {
          const deliveryRoll: DeliveryRoll = {
            clientDocId: this.selectedClientDocId,
            commodityProfileDocId: commodityProfile.docId,
            currentDeliveryPeriod: currentPeriod,
            newDeliveryPeriod: newPeriod,
            initiatorEmailAddress: user.email
          };
          return this.batchRollService.rollDeliveryPeriod(deliveryRoll, this.authService.accessToken);
        } else {
          const futuresRoll: FuturesRoll = {
            clientDocId: this.selectedClientDocId,
            commodityProfileDocId: commodityProfile.docId,
            currentFuturesMonth: currentPeriod,
            newFuturesMonth: newPeriod,
            initiatorEmailAddress: user.email
          };
          return this.batchRollService.rollFuturesPeriod(futuresRoll, this.authService.accessToken);
        }
      }),
      tap(() => {
        this.openSnackBar(`${this.getDisplayedRollType()} roll is in progress for ${commodityProfile.name} from ${currentPeriod} to ${newPeriod}. \
          You will receive an email report on successful roll.`, 'DISMISS');
        this.reset();
        this.updateComplete = true;
      }),
      catchError(err => {
        this.updateComplete = true;
        console.error(`Batch roll failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Batch roll failed: ${errorMsg}`, 'DISMISS', false);
        return of(undefined);
      }),
      take(1)
    ).subscribe();
  }

  private translateMomentToContractMonth(selectedMonth: moment.Moment): string {
    return selectedMonth ? selectedMonth.format(YEAR_FORMAT) + ContractMonth[this.contractMonths[selectedMonth.month()]] : '';
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 5000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private onCommodityProfileChanges() {
    this.batchRollForm.get('commodityProfile').valueChanges.subscribe(() => {
      this.resetMonthsField();
    });
  }

  private onRollTypeChanges() {
    this.batchRollForm.get('rollType').valueChanges.subscribe(() => {
      this.resetMonthsField();
    });
  }

  private resetMonthsField() {
    this.batchRollForm.get('fromMonth').setValue('');
    this.batchRollForm.get('toMonth').setValue('');
  }

  private getDisplayedRollType() {
    const rollType = this.batchRollForm.get('rollType').value;
    return rollType === RollType.DELIVERY ? 'Delivery' : 'Futures';
  }
}
