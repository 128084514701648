import { PricingType } from '@advance-trading/ops-data-lib';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'pricingType'})
export class PricingTypePipe implements PipeTransform {
  /**
   * This converts the pricing type enum value of LIMIT into TARGET
   * @param value the PricingType to be transformed
   */
  transform(value: string): string {
    if (value) {
      return value === PricingType.LIMIT ? 'TARGET' : PricingType.MARKET;
    }
    return '';
  }
}
