import { Component, ChangeDetectorRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';

import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommonValidators } from '@advance-trading/angular-common-services';
import {
  Client,
  Contract,
  ContractMonth,
  ContractStatus,
  ContractType,
  HMSUserSettings,
  Location,
  Patron,
  PricingSegment,
  User
} from '@advance-trading/ops-data-lib';
import { LocationService, PatronService, UserService } from '@advance-trading/angular-ops-data';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ContractService } from '../../service/contract.service';
import { PricingSegmentService } from '../../service/pricing-segment.service';
import { UserSettingsService } from '../../service/user-settings.service';
import { UserRoles } from '../../utilities/user-roles';
import { TargetDisplay } from '../target-display';

import { Originator } from '../../utilities/originator';

const DEFAULT_START_DATE = new Date('1/1/2000').toISOString();
const DEFAULT_END_DATE = new Date('12/31/2099').toISOString();

const YEAR_FORMAT = 'YY';

@Component({
  selector: 'hms-target-search',
  templateUrl: './target-search.component.html',
  styleUrls: ['./target-search.component.scss']
})
export class TargetSearchComponent implements OnInit {
  targetSearchForm: FormGroup = this.formBuilder.group({
    contractId: [''],
    type: [''],
    startDate: ['', { updateOn: 'blur' }],
    endDate: ['', { updateOn: 'blur' }],
    deliveryMonth: ['', { updateOn: 'blur' }],
    futuresMonth: ['', { updateOn: 'blur' }],
    location: ['', [CommonValidators.objectValidator]],
    originator: ['', [CommonValidators.objectValidator]],
    patron: ['', [CommonValidators.objectValidator]]
  });

  contractTypes = Object.keys(ContractType);

  showTargets = false;
  selectedTargets$: Observable<TargetDisplay[]>;
  tableState: { [key: string]: string | number } = {};

  filteredLocations$: Observable<Location[]>;
  filteredOriginators$: Observable<Originator[]>;
  filteredPatrons$: Observable<Patron[]>;

  errorMessage: string;
  isLoading = true;
  isSearching = false;

  private queryParams: Params;
  private contractMonths = Object.keys(ContractMonth);

  @ViewChild('deliveryMonthPicker', { static: false }) deliveryMonthRef: MatDatepicker<moment.Moment>;
  @ViewChild('futuresMonthPicker', { static: false }) futuresMonthRef: MatDatepicker<moment.Moment>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private changeDetector: ChangeDetectorRef,
    private clientSelectorService: ClientSelectorService,
    private contractService: ContractService,
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private patronService: PatronService,
    private pricingSegmentService: PricingSegmentService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private userSettingsService: UserSettingsService
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.CONTRACT_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to search targets.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.prepForLocationSelection();
    this.prepForOriginatorSelection();
    this.prepForPatronSelection();

    // Ensure contract ID is not combined with other search criteria
    this.targetSearchForm.valueChanges.subscribe(() => {
      const id = this.targetSearchForm.get('contractId').value;
      const type = this.targetSearchForm.get('type').value;
      const start = this.targetSearchForm.get('startDate').value;
      const end = this.targetSearchForm.get('endDate').value;
      const location = this.targetSearchForm.get('location').value;
      const originator = this.targetSearchForm.get('originator').value;
      const patron = this.targetSearchForm.get('patron').value;
      const delivery = this.targetSearchForm.get('deliveryMonth').value;
      const futures = this.targetSearchForm.get('futuresMonth').value;

      const hasNoValues = !id && !type && !start && !end && !location && !originator && !patron && !delivery && !futures;
      const hasValueOtherThanId = type || start || end || location || originator || patron || delivery || futures;

      // Disable all other fields when contract ID is entered
      if (id && this.targetSearchForm.get('type').enabled) {
        this.targetSearchForm.get('type').disable();
        this.targetSearchForm.get('startDate').disable();
        this.targetSearchForm.get('endDate').disable();
        this.targetSearchForm.get('location').disable();
        this.targetSearchForm.get('originator').disable();
        this.targetSearchForm.get('patron').disable();
        this.targetSearchForm.get('deliveryMonth').disable();
        this.targetSearchForm.get('futuresMonth').disable();

        // Disable contract ID field if a value is entered in any other field
      } else if (this.targetSearchForm.get('contractId').enabled && hasValueOtherThanId) {
        this.targetSearchForm.get('contractId').disable();
        // Re-enable all fields if a previously entered value has disabled a field(s) but is then cleared
      } else if (hasNoValues && (this.targetSearchForm.get('contractId').disabled || this.targetSearchForm.get('type').disabled)) {
        this.targetSearchForm.enable();
      }
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
      this.queryParams = Object.assign({}, params);
      this.targetSearchForm.get('type').setValue(this.queryParams.type);
      this.targetSearchForm.get('startDate').setValue(this.queryParams.startDate);
      this.targetSearchForm.get('endDate').setValue(this.queryParams.endDate);
      if (this.queryParams.delivery) {
        this.targetSearchForm.get('deliveryMonth').setValue(this.translateContractMonthToMoment(this.queryParams.delivery));
      }
      if (this.queryParams.futures) {
        this.targetSearchForm.get('futuresMonth').setValue(this.translateContractMonthToMoment(this.queryParams.futures));
      }
      const locationValue = this.queryParams.location ?
        { docId: this.queryParams.location, name: '' } as Location : undefined;
      this.targetSearchForm.get('location').setValue(locationValue);
      const originatorValue = this.queryParams.originator ?
        { docId: this.queryParams.originator, firstName: '' } as User : undefined;
      this.targetSearchForm.get('originator').setValue(originatorValue);
      const patronValue = this.queryParams.patron ?
        { docId: this.queryParams.patron, name: '', accountingSystemId: '' } as Patron : undefined;
      this.targetSearchForm.get('patron').setValue(patronValue);
      if (Object.keys(params).length) {
        // Mart form as dirty so reset button appears
        this.targetSearchForm.markAsDirty();
        this.searchTargets();
      }
    }));
    this.isLoading = false;
  }

  reset() {
    this.targetSearchForm.reset();
    // Clear out queryParams so values aren't forced back in
    this.clearQueryParams();
    this.tableState = {};
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });
    this.targetSearchForm.markAsPristine();
  }

  searchTargets(searchButtonClicked: boolean = false) {
    if (searchButtonClicked) {
      // clear initial table state if the user perform a new search
      this.clearQueryParams();
      this.tableState = {};
    } else {
      // set initial table state from query param if the user is back navigating from another page
      const sortDir = this.queryParams.sortDir;
      const sortColName = this.queryParams.sortColName;
      const pageSize = this.queryParams.pageSize;
      const pageIndex = this.queryParams.pageIndex;
      const filter = this.queryParams.filter;
      this.tableState = {
        sortDir,
        sortColName,
        pageSize,
        pageIndex,
        filter
      };
    }

    this.showTargets = false;
    this.changeDetector.detectChanges();
    let clientDocId;
    this.selectedTargets$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap(selectedClient => {
        clientDocId = selectedClient.docId;
        return this.getTargets(clientDocId);
      })
    );
    this.showTargets = true;
    this.changeDetector.detectChanges();
  }

  selectDeliveryMonth(deliveryMonth: moment.Moment) {
    this.targetSearchForm.get('deliveryMonth').setValue(deliveryMonth);
    this.targetSearchForm.get('deliveryMonth').markAsDirty();
    this.deliveryMonthRef.close();
  }

  selectFuturesMonth(futuresMonth: moment.Moment) {
    this.targetSearchForm.get('futuresMonth').setValue(futuresMonth);
    this.targetSearchForm.get('futuresMonth').markAsDirty();
    this.futuresMonthRef.close();
  }

  allowTabOnly(event: KeyboardEvent) {
    if (event.key !== 'Tab') {
      event.preventDefault();
    }
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMax')) {
      return 'Value Invalid';
    }
    return 'Unknown Error';
  }

  handleTargetListError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.changeDetector.detectChanges();
  }


  private getTargets(clientDocId: string): Observable<TargetDisplay[]> {
    const id = this.targetSearchForm.get('contractId').value;
    const type = this.targetSearchForm.get('type').value;
    let startDate = this.targetSearchForm.get('startDate').value;
    let endDate = this.targetSearchForm.get('endDate').value;
    const location = this.targetSearchForm.get('location').value;
    const originator = this.targetSearchForm.get('originator').value;
    const patron = this.targetSearchForm.get('patron').value;
    let delivery = this.targetSearchForm.get('deliveryMonth').value;
    let futures = this.targetSearchForm.get('futuresMonth').value;

    // Clear out queryParams in case search parameters change after a reload, but maintain table state if any
    this.queryParams = this.tableState as Params;

    // Set default date or the date entered by the user
    if (startDate) {
      startDate = new Date(startDate).toISOString();
      this.queryParams.startDate = startDate;
    } else {
      startDate = DEFAULT_START_DATE;
    }

    if (endDate) {
      endDate = new Date(endDate);
      // Allow target made on this day before midnight
      endDate.setHours(23, 59, 59, 999);
      endDate = endDate.toISOString();
      this.queryParams.endDate = endDate;
    } else {
      endDate = DEFAULT_END_DATE;
    }

    if (type) {
      this.queryParams.type = type;
    }

    if (location) {
      this.queryParams.location = location.docId;
    }

    if (originator) {
      this.queryParams.originator = originator.docId;
    }

    if (patron) {
      this.queryParams.patron = patron.docId;
    }

    if (delivery) {
      delivery = this.translateMomentToContractMonth(delivery);
      this.queryParams.delivery = delivery;
    }

    if (futures) {
      futures = this.translateMomentToContractMonth(futures);
      this.queryParams.futures = futures;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
    const searchQueries = [];
    // Check if the user is searching by contract id
    if (id) {
      searchQueries.push(this.loadPricingSegmentsByContractId(clientDocId, id));
    } else {
      if (!type || type === ContractType.BASIS) {
        searchQueries.push(this.convertContractsToTargetDisplays(this.contractService.getContractsBySearchParametersForContractSearch(
          clientDocId, ContractType.BASIS, [ContractStatus.WORKING_BASIS], startDate, endDate, this.getDocIdParameter(location),
          this.getDocIdParameter(patron), this.getDocIdParameter(originator), delivery, futures)));
      }
      if (!type || type === ContractType.CASH) {
        searchQueries.push(this.convertContractsToTargetDisplays(this.contractService.getContractsBySearchParametersForContractSearch(
          clientDocId, ContractType.CASH, [ContractStatus.WORKING_CASH], startDate, endDate, this.getDocIdParameter(location),
          this.getDocIdParameter(patron), this.getDocIdParameter(originator), delivery, futures)));
      }
      if (!type || type === ContractType.HTA) {
        searchQueries.push(this.convertContractsToTargetDisplays(this.contractService.getContractsBySearchParametersForContractSearch(
          clientDocId, ContractType.HTA, [ContractStatus.WORKING_FUTURES], startDate, endDate, this.getDocIdParameter(location),
          this.getDocIdParameter(patron), this.getDocIdParameter(originator), delivery, futures)));
      }
      searchQueries.push(this.loadPricingSegmentsBySearchTerms(
        clientDocId, type, startDate, endDate, this.getDocIdParameter(location), this.getDocIdParameter(originator), delivery, futures,
        this.getDocIdParameter(patron)));
    }
    return combineLatest(searchQueries).pipe(
      map((arrayOfTargetDisplayArrays: TargetDisplay[][]) => {
        return arrayOfTargetDisplayArrays.flat()
          .filter(target => target)
          .sort((a, b) => a.lastUpdatedTimestamp < b.lastUpdatedTimestamp ? -1 : 1);
      })
    );
  }

  handleTargetListChange(tableState: { [key: string]: string | number }) {
    if (tableState.sortDir && tableState.sortColName) {
      this.queryParams.sortDir = tableState.sortDir;
      this.queryParams.sortColName = tableState.sortColName;
    } else if (this.queryParams.sortDir && this.queryParams.sortColName) {
      // remove sorted direction and column in query param if there's no sort applied
      delete this.queryParams.sortDir;
      delete this.queryParams.sortColName;
    }
    if (tableState.pageSize) {
      this.queryParams.pageSize = tableState.pageSize;
    }
    if (tableState.pageIndex !== undefined) {
      this.queryParams.pageIndex = tableState.pageIndex;
    }

    if (tableState.filter) {
      this.queryParams.filter = tableState.filter;
    } else if (this.queryParams.filter) {
      // remove filter query param if there's no filter applied
      delete this.queryParams.filter;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  /**
   * Displays name for location autocomplete input field
   */
  displayLocation(location?: Location) {
    return location && location.name ? `${location.name}` : '';
  }

  /**
   * Displays name for originator autocomplete input field
   */
  displayOriginator(originator?: Originator) {
    return originator && originator.firstName ? `${originator.firstName} ${originator.lastName} (${originator.accountingSystemId})` : '';
  }

  /**
   * Displays name for patron autocomplete input field
   */
  displayPatron(patron?: Patron) {
    return patron && patron.name ? `${patron.name} (${patron.accountingSystemId})` : '';
  }

  private convertContractsToTargetDisplays(contractObservable: Observable<Contract[]>): Observable<TargetDisplay[]> {
    return contractObservable.pipe(
      map((contracts: Contract[]) => {
        return contracts.map(contract => {
          const target = this.getContractTargetPrice(contract);
          return {
            accountingSystemId: contract.accountingSystemId,
            creationTimestamp: contract.creationTimestamp,
            lastUpdatedTimestamp: contract.lastUpdatedTimestamp,
            commodityId: contract.commodityId,
            deliveryPeriod: contract.deliveryPeriod,
            futuresYearMonth: contract.futuresYearMonth,
            originatorName: contract.originatorName,
            clientLocationName: contract.clientLocationName,
            patronName: contract.patronName,
            type: contract.type,
            status: contract.status,
            side: contract.side,
            quantity: contract.quantity,
            targetPrice: target,
            contractDocId: contract.docId,
            segmentDocId: undefined
          } as TargetDisplay;
        });
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        console.error(`Error retrieving target contracts: ${err.message ? err.message : err}`);
        return of(undefined);
      })
    );
  }

  private loadPricingSegmentsByContractId(clientDocId: string, contractId: string): Observable<TargetDisplay[]> {
    return this.contractService.getContractsById(clientDocId, contractId).pipe(
      switchMap((contracts: Contract[]) => {
        if (!contracts.length) {
          return of([]);
        }
        return combineLatest(contracts.map((contract: Contract) => {
          return this.pricingSegmentService.getPricingSegmentsByClientDocIdAndContractDocIdAndStatus(clientDocId, contract.docId)
            .pipe(
              map((segments: PricingSegment[]) => {
                return segments.map(segment => this.translateContractAndSegmentToTargetDisplay(contract, segment));
              }),
              shareReplay({ bufferSize: 1, refCount: true }),
              catchError(err => {
                console.error(`Error retrieving target pricing segments: ${err.message ? err.message : err}`);
                return of(undefined);
              })
            );
        })
        );
      })
    ).pipe(map((arrayOfTargetDisplayArrays: TargetDisplay[][]) => arrayOfTargetDisplayArrays.flat()));
  }

  private loadPricingSegmentsBySearchTerms(
    clientDocId: string, type: string, startDate: string, endDate: string, locationDocId: string,
    originatorDocId: string, delivery: string, futures: string, patronDocId: string
  ): Observable<TargetDisplay[]> {
    return this.pricingSegmentService.findPricingSegmentsBySearchParameters(
      clientDocId, type, startDate, endDate, locationDocId, originatorDocId, delivery, futures
    ).pipe(
      switchMap((pricingSegments: PricingSegment[]) => {
        if (pricingSegments.length === 0) {
          return of([]);
        }
        return combineLatest(
          pricingSegments.map(segment => {
            return this.contractService.getContractByDocId(clientDocId, segment.contractDocId).pipe(
              map((contract: Contract) => {
                // workaround as pricingSegments not searchable by patronDocId; filtered out in getTargets
                if (patronDocId && patronDocId !== contract.patronDocId) {
                  return undefined;
                }
                return this.translateContractAndSegmentToTargetDisplay(contract, segment);
              }),
              shareReplay({ bufferSize: 1, refCount: true }),
              catchError(err => {
                console.error(`Error retrieving target pricing segments: ${err.message ? err.message : err}`);
                return of(undefined);
              })
            );
          })
        );
      })
    );
  }

  private getContractTargetPrice(contract: Contract) {
    if (contract.type === ContractType.CASH) {
      return contract.cashPrice;
    } else if (contract.type === ContractType.BASIS) {
      return contract.basisPrice;
    } else if (contract.type === ContractType.HTA) {
      return contract.futuresPrice;
    }
  }

  private getPricingSegmentTargetPrice(pricingSegment: PricingSegment) {
    if (pricingSegment.basisPricingType && !pricingSegment.isBasisLocked) {
      return pricingSegment.basisPrice;
    } else if (pricingSegment.cashPricingType && !pricingSegment.isCashLocked) {
      return pricingSegment.cashPrice;
    } else if (pricingSegment.futuresPricingType && !pricingSegment.isFuturesLocked) {
      return pricingSegment.futuresPrice;
    }
  }

  private translateContractAndSegmentToTargetDisplay(contract: Contract, segment: PricingSegment): TargetDisplay {
    const target = this.getPricingSegmentTargetPrice(segment);
    return {
      accountingSystemId: contract.accountingSystemId,
      creationTimestamp: segment.creationTimestamp,
      lastUpdatedTimestamp: segment.lastUpdatedTimestamp,
      commodityId: segment.commodityId,
      deliveryPeriod: segment.deliveryPeriod,
      futuresYearMonth: segment.futuresYearMonth,
      originatorName: segment.originatorName,
      clientLocationName: contract.clientLocationName,
      patronName: contract.patronName,
      type: contract.type,
      status: segment.status,
      side: segment.side,
      quantity: segment.quantity,
      targetPrice: target,
      contractDocId: contract.docId,
      segmentDocId: segment.docId
    } as TargetDisplay;
  }

  private translateContractMonthToMoment(value: string): moment.Moment {
    const year = moment.parseTwoDigitYear(value.substring(0, 2));
    const monthCode = value.substring(2);
    const idxMonth = this.contractMonths.indexOf(monthCode);
    return moment().year(year).month(idxMonth).startOf('month');
  }

  private translateMomentToContractMonth(selectedMonth: moment.Moment): string {
    return selectedMonth ? selectedMonth.format(YEAR_FORMAT) + ContractMonth[this.contractMonths[selectedMonth.month()]] : undefined;
  }

  private prepForLocationSelection() {
    let setInitialValue = false;
    this.filteredLocations$ = this.targetSearchForm.controls.location.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | Location>(''),
      switchMap(searchTerm => {
        return this.clientSelectorService.getSelectedClient().pipe(
          switchMap((selectedClient: Client) => {
            return this.locationService.getClientLocationsByClientDocId(selectedClient.docId).pipe(
              map((locations: Location[]) => {
                // populate searchTerm on initial load in case there is a mock object present
                if (!searchTerm) {
                  searchTerm = this.targetSearchForm.get('location').value;
                }
                // Return all locations when a location has already been selected to avoid the user needing to clear the field
                if (typeof searchTerm !== 'string') {
                  // Replace mock location from queryParam if necessary
                  if (searchTerm && !(searchTerm as Location).name && !setInitialValue) {
                    this.targetSearchForm.get('location').setValue(
                      locations.find(location => location.docId === (searchTerm as Location).docId));
                    setInitialValue = true;
                  }
                  return locations;
                }
                return locations.filter(location => location.name.toLowerCase().includes((searchTerm as string).toLowerCase()));
              }),
              shareReplay({ bufferSize: 1, refCount: true }),
              catchError(err => {
                this.isLoading = false;
                this.errorMessage = 'Error retrieving client locations; please try again later';
                console.error(`Error retrieving client locations: ${err}`);
                return of([]);
              })
            );
          }),
        );
      }),
    );
  }

  private prepForOriginatorSelection() {
    let setInitialValue = false;
    this.filteredOriginators$ = this.targetSearchForm.controls.originator.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | Originator>(''),
      switchMap(searchTerm => {
        return this.clientSelectorService.getSelectedClient().pipe(
          switchMap((selectedClient: Client) => {
            return this.userSettingsService.getHmsUserSettingsForClientUsers(selectedClient.docId);
          }),
          switchMap((hmsUserSettings: HMSUserSettings[]) => {
            return combineLatest(
              hmsUserSettings.map((setting: HMSUserSettings) => {
                const accountingSystemId = setting.accountingSystemId;
                return this.userService.getUserByDocId(setting.userDocId)
                  .pipe(
                    map((user: User) => {
                      return { ...user, accountingSystemId } as Originator;
                    })
                  );
              })
            ).pipe(
              map((originators: Originator[]) => {
                if (!searchTerm) {
                  searchTerm = this.targetSearchForm.get('originator').value;
                }
                // Return all originators when an originator has already been selected to avoid the user needing to clear the field
                if (typeof searchTerm !== 'string') {
                  // Replace mock originator from queryParam if necessary
                  if (searchTerm && !(searchTerm as Originator).firstName && !setInitialValue) {
                    const realOriginator = originators.find(originator => originator.docId === (searchTerm as Originator).docId);
                    // ensure originator was found as initial emission only contains current user
                    if (realOriginator) {
                      this.targetSearchForm.get('originator').setValue(realOriginator);
                      setInitialValue = true;
                    }
                  }
                  return originators;
                }
                const lowerCaseSearchTerm = searchTerm.toLowerCase();
                return originators.filter(originator =>
                  (`${originator.firstName} ${originator.lastName}`).toLowerCase().includes((lowerCaseSearchTerm as string).toLowerCase())
                  || originator.accountingSystemId.toLowerCase().includes(lowerCaseSearchTerm));
              }),
              shareReplay({ bufferSize: 1, refCount: true }),
              catchError(err => {
                this.isLoading = false;
                this.errorMessage = 'Error retrieving originators; please try again later';
                console.error(`Error retrieving originators: ${err}`);
                return of([]);
              })
            );
          }),
        );
      }),
    );
  }

  private prepForPatronSelection() {
    let setInitialValue = false;
    this.filteredPatrons$ = this.targetSearchForm.controls.patron.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | Patron>(''),
      switchMap(searchTerm => {
        const hasQueryParamExecution = this.targetSearchForm.get('patron').value
          && !this.targetSearchForm.get('patron').value.name && !searchTerm;
        const hasTyped3Chars = typeof searchTerm === 'string' && searchTerm.length >= 3;

        if (hasTyped3Chars || hasQueryParamExecution) {
          return this.clientSelectorService.getSelectedClient().pipe(
            switchMap((selectedClient: Client) => {
              return this.patronService.getAllPatronsByClientDocId(selectedClient.docId).pipe(
                map((patrons: Patron[]) => {
                  if (!searchTerm) {
                    searchTerm = this.targetSearchForm.get('patron').value;
                  }
                  // Return all patrons when a patron has already been selected to avoid the user needing to clear the field
                  if (typeof searchTerm !== 'string') {
                    // Replace mock patron from queryParam if necessary
                    if (searchTerm && !(searchTerm as Patron).name && !setInitialValue) {
                      this.targetSearchForm.get('patron').setValue(patrons.find(patron => patron.docId === (searchTerm as Patron).docId));
                      setInitialValue = true;
                    }
                    // Note: return empty list since a patron is selected from query param.
                    // This logic means that we wait until a user is entering another patron search
                    return [];
                  }
                  const lowerCaseSearchTerm = searchTerm.toLowerCase();
                  return patrons.filter(patron => patron.name.toLowerCase().includes(lowerCaseSearchTerm) ||
                    patron.accountingSystemId.toLowerCase().includes(lowerCaseSearchTerm));
                }),
                shareReplay({ bufferSize: 1, refCount: true }),
                catchError(err => {
                  this.isLoading = false;
                  this.errorMessage = 'Error retrieving client patrons; please try again later';
                  console.error(`Error retrieving client patrons: ${err}`);
                  return of([]);
                })
              );
            }),
          );
        } else {
          return of([]);
        }
      })
    );
  }

  private getDocIdParameter(obj) {
    return obj ? obj.docId : undefined;
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }
}
