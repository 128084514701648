import { HMSClientSettings } from '@advance-trading/ops-data-lib';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { WHOLE_CENT_REGEX } from 'src/app/utilities/validators/price.validator';
import { ADJUSTMENT_REGEX, BASIS_REGEX, MONTH_CODE_REGEX, WHOLE_CENT_ADJUSTMENT_REGEX } from '../basis-admin/basis-admin.validator';

interface BottomSheetMassUpdateData {
  clientSettings: HMSClientSettings;
}

@Component({
  selector: 'hms-bottom-sheet-mass-update',
  templateUrl: './bottom-sheet-mass-update.component.html',
  styleUrls: ['./bottom-sheet-mass-update.component.scss']
})

export class BottomSheetMassUpdateComponent implements OnInit {

  massAdjustment: FormGroup;
  massChange: FormGroup;

  constructor(
    private fb: FormBuilder,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetMassUpdateComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: BottomSheetMassUpdateData
  ) {}

  ngOnInit() {
    this.massAdjustment = this.fb.group({
      value: new FormControl('', this.getMassAdjustmentValidators()),
      operation: new FormControl('adjustment')
    });

    this.massChange = this.fb.group({
      value: new FormControl('', this.getMassChangeBasisValidators()),
      futuresMonth: new FormControl('', [Validators.pattern(MONTH_CODE_REGEX)]),
      operation: new FormControl('change')
    });
  }

  handleMassChange() {
    this.bottomSheetRef.dismiss({massUpdate: this.massChange});
  }

  handleMassAdjustment() {
    this.bottomSheetRef.dismiss({massUpdate: this.massAdjustment});
  }

  handleMassReset() {
    const massClear = this.fb.group({
      value: new FormControl(''),
      operation: new FormControl('clear')
    });
    this.bottomSheetRef.dismiss({massUpdate: massClear});
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('pattern')) {
      return 'Value invalid';
    } else {
      return 'Unknown error';
    }
  }

  getDecimalPrecisionNote() {
    if (this.useWholeCent) {
      return 'Number should be a maximum of 2 decimal places';
    } else {
      return 'Number should be a maximum of 4 decimal places (to the quarter cent)';
    }
  }

  private getMassAdjustmentValidators() {
    if (this.useWholeCent) {
      return [Validators.pattern(WHOLE_CENT_ADJUSTMENT_REGEX)];
    } else {
      return [Validators.pattern(ADJUSTMENT_REGEX)];
    }
  }

  private getMassChangeBasisValidators() {
    if (this.useWholeCent) {
      return [Validators.pattern(WHOLE_CENT_REGEX)];
    } else {
      return [Validators.pattern(BASIS_REGEX)];
    }
  }

  private get useWholeCent() {
    return this.data.clientSettings.useWholeCent;
  }
}
