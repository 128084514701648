import { FormControl } from '@angular/forms';

export class UniqueKeyValidator {

  static uniqueKey(existingKeys: string[]) {
    return (control: FormControl): { [key: string]: boolean } | null => {
      const controlKey = control.value as string;
      existingKeys = existingKeys.map(key => key.toUpperCase());
      const errors = {};
      if (controlKey && existingKeys.includes(controlKey.toUpperCase())) {
        errors['uniqueKey'] = true;
      }
      return Object.keys(errors).length === 0 ? null : errors;
    };
  }

}
