import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay, switchMap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { AccountService, CommodityProfileService, OperationsDataService } from '@advance-trading/angular-ops-data';
import {
  Account,
  Commodity,
  CommodityMap,
  CommodityProfile,
  CommodityProfileProductionYear,
  HMSClientSettings,
  ProductionYear
} from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { CommodityProfileValidators } from '../commodity-profile.validator';
import { ProductionYearComponent } from '../production-year/production-year.component';
import { UserRoles } from '../../utilities/user-roles';

const YEAR_FORMAT = 'YYYY';
const YEAR_MONTH_FORMAT = 'YYYY-MM';
const FULL_DATE_FORMAT = 'YYYY-MM-DD';
const PRICE_REGEX = /^-?(\d+|\d*\.\d{1,4}|\d+\.\d{0,4})$/;
const POSITIVE_INTEGER = /^\d{1,}$/;
const POSITIVE_OR_NEGATIVE_INTEGER = /^-?\d{1,}$/;

@Component({
  selector: 'hms-new-commodity-profile',
  templateUrl: './new-commodity-profile.component.html',
  styleUrls: ['./new-commodity-profile.component.scss']
})
export class NewCommodityProfileComponent implements OnInit {
  @ViewChildren(ProductionYearComponent) prodYearsComponent: QueryList<ProductionYearComponent>;

  errorMessage = '';
  updateComplete = true;
  commodities: Commodity[];
  accounts$: Observable<Account[]>;
  validFuturesMonths: number[];
  minDate = moment().startOf('month');

  commodityProfileForm: FormGroup = this.formBuilder.group({
    accountingSystemId: ['', [Validators.required, Validators.maxLength(50)]],
    account: ['', [Validators.required]],
    commodity: ['', [Validators.required]],
    defaultGrade: ['', [Validators.required, Validators.maxLength(1)]],
    isActive: [true],
    isSpread: [false],
    hasBasis: [false],
    name: ['', [Validators.required, Validators.maxLength(30)]],
    minPrice: ['', [Validators.required, Validators.pattern(PRICE_REGEX)]],
    maxPrice: ['', [Validators.required, Validators.pattern(PRICE_REGEX)]],
    longThreshold: ['', [Validators.required, Validators.pattern(POSITIVE_INTEGER)]],
    shortThreshold: ['', [Validators.required, Validators.pattern(POSITIVE_OR_NEGATIVE_INTEGER)]],
    targetOrderThreshold: ['', [Validators.required, Validators.pattern(POSITIVE_INTEGER)]],
    prodYears: this.formBuilder.group({})
  });

  addingProdYear = false;

  private commodityProfiles: CommodityProfile[];
  private selectedClientDocId = '';
  private lastAddedProductionYear: string;

  // commodities document futures months
  private futuresMonths: {[key: string]: number[]} = {};

  constructor(
    private accountService: AccountService,
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private operationsDataService: OperationsDataService,
    private commodityProfileService: CommodityProfileService,
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private clientSettingsService: ClientSettingsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.COMMODITY_PROFILE_ADMIN_ROLE)) {
      this.errorMessage = 'You do not have permission to create commodity profiles.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    let commodityCodes;
    this.accounts$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap(selectedClient => {
        this.selectedClientDocId = selectedClient.docId;
        return this.clientSettingsService.getHmsSettingsByClientDocId(this.selectedClientDocId);
      }),
      switchMap((hmsClientSettings: HMSClientSettings) => {
        commodityCodes = Object.values(hmsClientSettings.commodities);
        return this.commodityProfileService.getAllCommodityProfilesByClientDocId(this.selectedClientDocId);
      }),
      switchMap((commodityProfiles: CommodityProfile[]) => {
        this.commodityProfiles = commodityProfiles;
        return this.operationsDataService.getCommodityMap();
      }),
      switchMap((doc: CommodityMap) => {
        this.commodities = Object.values(doc.commodities).sort((a, b) => {
          return a.name <= b.name ? -1 : 1;
        }).filter(commodity => commodityCodes.includes(commodity.id));

        // get futures months from commodities document in firestore
        Object.keys(doc.commodities).forEach((commodityId: string) => {
          this.futuresMonths[commodityId] = doc.commodities[commodityId].contractMonths;
        });

        this.commodityProfileForm.setValidators([CommodityProfileValidators.productionYearsValidator(this.futuresMonths)]);

        return this.accountService.getActiveHedgeAccountsByClientDocId(this.selectedClientDocId);
      }),
      shareReplay(1),
      catchError(err => {
        this.errorMessage = 'Error retrieving client accounts; please try again later';
        console.error(`Error retrieving client accounts: ${err}`);
        return of(undefined);
      })
    );
    this.setupIsSpreadListener();
    this.onCommodityChanges();
  }

  reset() {

    this.commodityProfileForm.reset(
      {
        isActive: true,
      }
    );
    this.commodityProfileForm.markAsPristine();

    // remove existing production years control that is in progress
    const prodYears = this.commodityProfileForm.get('prodYears') as FormGroup;
    Object.keys(prodYears.controls).forEach(key => {
      prodYears.removeControl(key);
    });

    // reset production year variables
    this.addingProdYear = false;
    this.lastAddedProductionYear = undefined;
  }

  submit() {
    this.updateComplete = false;
    const accountDocId = this.commodityProfileForm.value.account.docId;
    let matchingProfile;
    let message;
    if (this.commodityProfileForm.value.isSpread) {
      matchingProfile = this.commodityProfiles.find(profile => profile.accountDocId === accountDocId && profile.isSpreadProfile);
      if (matchingProfile) {
        message = `You already have a${matchingProfile.isActive ? ' ' : 'n inactive '}spread commodity profile set up for this account with the name "${matchingProfile.name}".`;
      }
    } else {
      const commodityId = this.commodityProfileForm.value.commodity.id;
      matchingProfile = this.commodityProfiles.find(profile =>
        profile.accountDocId === accountDocId && profile.commodityId === commodityId);
      if (matchingProfile) {
        message = `You already have a${matchingProfile.isActive ? ' ' : 'n inactive '}commodity profile set up for this account and this commodity with the name "${matchingProfile.name}".`;
      }
    }
    if (matchingProfile) {
      this.updateComplete = true;
      this.openSnackBar(message, 'DISMISS', false);
      return;
    } else {
      const prodYears: FormGroup = this.commodityProfileForm.get('prodYears') as FormGroup;
      const productionYears: {[key: string]: CommodityProfileProductionYear} = {};

      Object.values(prodYears.controls).forEach( (prodYear: FormGroup) => {
        const newProductionYear = {} as CommodityProfileProductionYear;
        newProductionYear.year = moment(prodYear.get('year').value).format(YEAR_FORMAT);
        newProductionYear.label =  prodYear.get('label').value;
        newProductionYear.startDate = moment(prodYear.get('startDate').value).format(FULL_DATE_FORMAT);
        newProductionYear.endDate = moment(prodYear.get('endDate').value).format(FULL_DATE_FORMAT);
        newProductionYear.targetFuturesYearMonth = moment(prodYear.get('targetFuturesYearMonth').value).format(YEAR_MONTH_FORMAT);
        newProductionYear.hedgeBuyFuturesYearMonth = moment(prodYear.get('hedgeBuyFuturesYearMonth').value).format(YEAR_MONTH_FORMAT);
        newProductionYear.hedgeSellFuturesYearMonth = moment(prodYear.get('hedgeSellFuturesYearMonth').value).format(YEAR_MONTH_FORMAT);
        productionYears[newProductionYear.year] = newProductionYear;
      });

      const newCommodityProfile = new CommodityProfile();
      newCommodityProfile.name = this.commodityProfileForm.value.name;
      newCommodityProfile.isActive = this.commodityProfileForm.value.isActive;
      newCommodityProfile.isSpreadProfile = this.commodityProfileForm.value.isSpread;
      newCommodityProfile.accountDocId = this.commodityProfileForm.value.account.docId;
      newCommodityProfile.officeCode = this.commodityProfileForm.value.account.officeCode;
      newCommodityProfile.accountNumber = this.commodityProfileForm.value.account.number;
      if (!newCommodityProfile.isSpreadProfile) {
        newCommodityProfile.accountingSystemId = this.commodityProfileForm.value.accountingSystemId;
        newCommodityProfile.commodityId = this.commodityProfileForm.value.commodity.id;
        newCommodityProfile.defaultGrade = this.commodityProfileForm.value.defaultGrade;
        newCommodityProfile.hasBasis = this.commodityProfileForm.value.hasBasis;
        newCommodityProfile.targetOrderThreshold = parseInt(this.commodityProfileForm.value.targetOrderThreshold, 10);
        newCommodityProfile.minPrice = parseFloat(this.commodityProfileForm.value.minPrice);
        newCommodityProfile.maxPrice = parseFloat(this.commodityProfileForm.value.maxPrice);
        newCommodityProfile.shortThreshold = parseInt(this.commodityProfileForm.value.shortThreshold, 10);
        newCommodityProfile.longThreshold = parseInt(this.commodityProfileForm.value.longThreshold, 10);
        newCommodityProfile.productionYears = productionYears;
      }

      // insert new CommodityProfile data into Firestore
      this.commodityProfileService.createCommodityProfile(this.selectedClientDocId, newCommodityProfile)
        .then(() => {
          this.updateComplete = true;
          console.log('Commodity profile successfully created');
          this.openSnackBar('Commodity profile successfully created', 'DISMISS', true);
          this.router.navigate(['../'], { relativeTo: this.route, replaceUrl: true });
        })
        .catch(err => {
          this.updateComplete = true;
          console.error(`Commodity profile creation failed: ${err}`);
          const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
          this.openSnackBar(`Commodity profile creation failed: ${errorMsg}`, 'DISMISS', false);
        });
    }
  }

  selectMonth(e: moment.Moment, controlName: string, picker: MatDatepicker<moment.Moment>) {
    const control = this.commodityProfileForm.get(controlName);
    control.setValue(e);
    control.markAsTouched();
    picker.close();
  }

  setMinFrom(controlName: string): string {
    return moment(this.commodityProfileForm.get(controlName).value).add(1, 'd').format();
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('pattern')) {
      return 'Value invalid';
    } else if (control.hasError('maxlength')) {
      return `Value cannot exceed  ${control.errors['maxlength'].requiredLength} character${control.errors['maxlength'].requiredLength === 1 ? '' : 's'}`;
    } else if (control.hasError('max')) {
      return 'Value cannot exceed ' + control.errors['max'].max.toLocaleString();
    }
    return 'Unknown error';
  }

  compareAccount(account1: Account, account2: Account) {
    return account1 && account2 && account1.docId === account2.docId;
  }

  compareCommodity(commodity1: Commodity, commodity2: Commodity) {
    return commodity1 && commodity2 && commodity1.id === commodity2.id;
  }

  format4(fieldName: string) {
    const val = Number(this.commodityProfileForm.get(fieldName).value);
    if (val && Number.isFinite(val)) {
      this.commodityProfileForm.get(fieldName).setValue(val.toFixed(4));
    }
  }

  ensureNegative(fieldName: string) {
    const field = this.commodityProfileForm.get(fieldName);
    const val = parseInt(field.value, 10);
    if (val > 0) {
      field.setValue(val * -1);
      field.markAsDirty();
    }
  }

  onProductionYearCreated(productionYear: FormGroup) {
    const year: string = moment(productionYear.get('year').value).format(YEAR_FORMAT);
    const prodYears: FormGroup = this.commodityProfileForm.get('prodYears') as FormGroup;
    prodYears.addControl(year, productionYear);
    this.lastAddedProductionYear = year;
    this.commodityProfileForm.markAsDirty();
    this.addingProdYear = false;
  }

  onProductionYearRemoved() {
    if (this.addingProdYear) {
      this.addingProdYear = false;
      return;
    }
    const prodYears: FormGroup = this.commodityProfileForm.get('prodYears') as FormGroup;
    prodYears.removeControl(this.lastAddedProductionYear);
    this.lastAddedProductionYear = (parseInt(this.lastAddedProductionYear, 10) - 1).toString();
    this.commodityProfileForm.markAsDirty();
  }

  onProductionYearUpdated(event: FormGroup) {
    const prodYears: FormGroup = this.commodityProfileForm.get('prodYears') as FormGroup;
    const year = moment(event.get('year').value).format(YEAR_FORMAT);
    prodYears.setControl(year, event);
    // Note: FormGroup changes is not detected as an onChange event in new ProductionYearComponent
    // New production year component is not being re rendered, so we're resetting the startDateValidator
    this.prodYearsComponent.forEach(prodYearComponent => {
      prodYearComponent.resetStartDateValidator();
    });

    this.commodityProfileForm.markAsDirty();
  }

  addProductionYear() {
    this.addingProdYear = true;
  }

  get hasProdYears() {
    return Object.keys(this.commodityProfileForm.get('prodYears').value).length > 0;
  }

  get canAdd() {
    const prodYears = this.commodityProfileForm.get('prodYears') as FormGroup;
    let returnValue = true;
    Object.keys(prodYears.controls).forEach(year => {
      const prodYear = prodYears.controls[year] as FormGroup;
      if (prodYear.get('label').value === ProductionYear.NEW_PLUS_2) {
        returnValue = false;
      }
    });
    return returnValue;
  }

  trackByYear(index: number, el: any): string {
    return (el.value as FormGroup).get('year').value;
  }

  private onCommodityChanges() {
    this.commodityProfileForm.get('commodity').valueChanges.subscribe((commodity: Commodity) => {
      if (commodity && commodity.contractSize) {
        this.commodityProfileForm.get('targetOrderThreshold').setValidators(
          [Validators.required, Validators.pattern(POSITIVE_INTEGER), Validators.max(commodity.contractSize)]
        );
      } else {
        this.commodityProfileForm.get('targetOrderThreshold').setValidators([Validators.required, Validators.pattern(POSITIVE_INTEGER)]);
      }
      this.commodityProfileForm.get('targetOrderThreshold').updateValueAndValidity();
    });
  }

  private setupIsSpreadListener() {
    this.commodityProfileForm.get('isSpread').valueChanges.subscribe((isSpread: boolean) => {
      if (isSpread) {
        this.commodityProfileForm.get('accountingSystemId').disable();
        this.commodityProfileForm.get('commodity').disable();
        this.commodityProfileForm.get('targetOrderThreshold').disable();
        this.commodityProfileForm.get('minPrice').disable();
        this.commodityProfileForm.get('maxPrice').disable();
        this.commodityProfileForm.get('shortThreshold').disable();
        this.commodityProfileForm.get('longThreshold').disable();
        this.commodityProfileForm.get('prodYears').disable();
        this.commodityProfileForm.get('defaultGrade').disable();
      } else {
        this.commodityProfileForm.get('accountingSystemId').enable();
        this.commodityProfileForm.get('commodity').enable();
        this.commodityProfileForm.get('targetOrderThreshold').enable();
        this.commodityProfileForm.get('minPrice').enable();
        this.commodityProfileForm.get('maxPrice').enable();
        this.commodityProfileForm.get('shortThreshold').enable();
        this.commodityProfileForm.get('longThreshold').enable();
        this.commodityProfileForm.get('prodYears').enable();
        this.commodityProfileForm.get('defaultGrade').enable();
      }
    });
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}
