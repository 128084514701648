
<div>
  <form *ngIf="!errorMessage && clientSettings$ | async" [formGroup]="clientSettingsForm">
    <!-- Commodities -->
    <div class="hms-client-settings-section" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start space-between" fxLayout.xs="column" fxLayoutGap.xs="15px">
      <mat-form-field fxFlex="77">
        <mat-chip-list #commodityChips class="group-list">
          <mat-chip *ngFor="let commodityId of commodities; index as index;"
            [color]="(index + 1) % 2 === 0 ? 'accent' : 'primary'"
            selected
            [removable]="editMode"
            (removed)="removeCommodity(commodityId)">
            {{ getCommodityDisplayById(commodityId) }}
            <mat-icon matChipRemove *ngIf="editMode">cancel</mat-icon>
          </mat-chip>
          <input
            #commodityInput
            matInput
            placeholder="Commodities"
            formControlName="commodities"
            [matAutocomplete]="commodityauto"
            [matChipInputFor]="commodityChips"
            [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
            [matChipInputAddOnBlur]="false"
            (matChipInputTokenEnd)="addCommodity($event)" />
        </mat-chip-list>
        <mat-autocomplete autoActiveFirstOption #commodityauto="matAutocomplete" [displayWith]="getCommodityDisplay" (optionSelected)="addCommodity($event)">
          <mat-option *ngFor="let commodity of filteredCommodities$ | async" [value]="commodity">{{ getCommodityDisplay(commodity) }}</mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <!-- International, Use Price Adjustments, Send Patron Notifications, Patron Email From Address, Group Prefix, Ledger End of Day, Timezone -->
    <div class="hms-client-settings-section" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start space-between" fxLayout.xs="column" fxLayoutGap.xs="15px">
      <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Ledger End of Day" [data]="getLocalTime12HourFormat(clientSettings.ledgerEndOfDay)"></ati-labeled-data>
      <mat-form-field *ngIf="editMode" fxFlex.gt-xs="25">
        <input required matInput [ngxTimepicker]="endOfDayPicker" placeholder="Ledger End of Day" formControlName="ledgerEndOfDay" [disableClick]="true">
        <ngx-material-timepicker-toggle class="not-so-high" matSuffix [for]="endOfDayPicker">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16px" height="16px" ngxMaterialTimepickerToggleIcon preserveAspectRatio="xMidYMid meet">
            <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003                   6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 6 L 11 12.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13 11.585938 L 13 6 L 11 6 z"/>
          </svg>
        </ngx-material-timepicker-toggle>
        <ngx-material-timepicker #endOfDayPicker [defaultTime]="clientSettingsForm.get('ledgerEndOfDay').value" [theme]="hmsTheme"></ngx-material-timepicker>
        <mat-error *ngIf="clientSettingsForm.get('ledgerEndOfDay').invalid">{{ getErrorMessage(clientSettingsForm.get('ledgerEndOfDay')) }}</mat-error>
      </mat-form-field>
      <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Timezone" [data]="clientSettings.timezone"></ati-labeled-data>
      <mat-form-field *ngIf="editMode" fxFlex.gt-xs="25">
        <input required matInput placeholder="Timezone" formControlName="timezone" [matAutocomplete]="autoTimezone">
        <mat-error *ngIf="clientSettingsForm.get('timezone').invalid"> {{ getErrorMessage(clientSettingsForm.get('timezone'), 'timezone') }} </mat-error>
        <button type="button" id="clear-client-settings-timezone" mat-button *ngIf="clientSettingsForm.get('timezone').value" matSuffix mat-icon-button aria-label="Clear Timezone" matTooltip="Clear Timezone" (click)="clearField('timezone')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-autocomplete #autoTimezone="matAutocomplete" [displayWith]="displayTimezone">
        <mat-option *ngFor="let timezone of filteredTimezones$ | async" [value]="timezone">{{ displayTimezone(timezone) }}</mat-option>
      </mat-autocomplete>

      </div>
      <div class="hms-client-settings-section" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start space-between" fxLayout.xs="column" fxLayoutGap.xs="15px">

      <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="International" [data]="clientSettings.isInternational | yesNo"></ati-labeled-data>
      <mat-checkbox *ngIf="editMode" fxFlex.gt-xs="25" formControlName="isInternational">International</mat-checkbox>
      <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Use Price Adjustments" [data]="clientSettings.usePriceAdjustments | yesNo"></ati-labeled-data>
      <mat-checkbox *ngIf="editMode" fxFlex.gt-xs="25" formControlName="usePriceAdjustments">Use Price Adjustments</mat-checkbox>
      <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Use Whole Cent" [data]="clientSettings.useWholeCent | yesNo"></ati-labeled-data>
      <mat-checkbox *ngIf="editMode" fxFlex.gt-xs="25" formControlName="useWholeCent">Use Whole Cent</mat-checkbox>
    </div>

    <h4>Email Settings</h4>

    <div class="hms-client-settings-section" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start space-between" fxLayout.xs="column" fxLayoutGap.xs="15px">
      <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Group Prefix" [data]="clientSettings.groupPrefix"></ati-labeled-data>
      <mat-form-field *ngIf="editMode" fxFlex.gt-xs="25">
        <input matInput placeholder="Group Prefix" formControlName="groupPrefix" trim="blur" mask="A*" />
        <button type="button" id="clear-client-settings-group-prefix" mat-button *ngIf="clientSettingsForm.get('groupPrefix').value" matSuffix mat-icon-button aria-label="Clear Group Prefix" matTooltip="Clear Group Prefix" (click)="clearField('groupPrefix')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Management Group" [data]="getManagementGroupDisplayName(managementGroup)"></ati-labeled-data>
      <mat-form-field *ngIf="editMode" fxFlex.gt-xs="25">
        <input matInput placeholder="Management Group" formControlName="managementGroup" [matAutocomplete]="autoManagementGroup">
        <mat-error *ngIf="clientSettingsForm.get('managementGroup').invalid">{{ getErrorMessage(clientSettingsForm.get('managementGroup'), 'managementGroup') }}</mat-error>
        <button type="button" id="clear-client-settings-timezone" mat-button *ngIf="clientSettingsForm.get('managementGroup').value" matSuffix mat-icon-button aria-label="Clear Management Group" matTooltip="Clear Management Group" (click)="clearField('managementGroup')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-autocomplete #autoManagementGroup="matAutocomplete" [displayWith]="getManagementGroupDisplayName">
        <mat-option *ngFor="let managementGroup of filteredGroups$ | async" [value]="managementGroup">{{ managementGroup.name }}</mat-option>
      </mat-autocomplete>

      </div>
      <div class="hms-client-settings-section" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start space-between" fxLayout.xs="column" fxLayoutGap.xs="15px">

        <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Send Patron Notifications" [data]="clientSettings.sendPatronNotifications | yesNo"></ati-labeled-data>
        <mat-checkbox *ngIf="editMode" fxFlex.gt-xs="25" formControlName="sendPatronNotifications">Send Patron Notifications</mat-checkbox>

      <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Patron Email From" [data]="clientSettings.patronEmailFromAddress"></ati-labeled-data>
      <mat-form-field *ngIf="editMode" fxFlex.gt-xs="25">
        <input [required]="this.clientSettingsForm.get('sendPatronNotifications').value" matInput placeholder="Patron Email From" formControlName="patronEmailFromAddress" trim="blur" />
        <mat-error *ngIf="clientSettingsForm.get('patronEmailFromAddress').invalid">{{ getErrorMessage(clientSettingsForm.get('patronEmailFromAddress')) }}</mat-error>
        <button type="button" id="clear-client-settings-patron-email-from-address" mat-button *ngIf="clientSettingsForm.get('patronEmailFromAddress').value" matSuffix mat-icon-button aria-label="Clear Patron Email From Address" matTooltip="Clear Patron Email From Address" (click)="clearField('patronEmailFromAddress')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </form>
</div>
