<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <div *ngIf="errorMessage">
      <hms-error-message [title]="'Error Retrieving Alert Details'" [message]="errorMessage"></hms-error-message>
    </div>

    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">Alert: {{ alertTitle ? alertTitle : '' }}</h2>
      <mat-spinner class="loading-spinner" *ngIf="isLoading" diameter="40" color="accent"></mat-spinner>
    </div>

    <div *ngIf="!errorMessage && alert$ | async as alert" fxLayout="column" fxLayoutGap="15px">

      <ati-labeled-data fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between start" *ngIf="alert.subtitle" label="Subtitle" data="{{alert.subtitle}}"></ati-labeled-data>

      <ati-labeled-data fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between start" label="Status" data="{{ alert.status | replace | titlecase }}"></ati-labeled-data>

      <ati-labeled-data fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between start" label="Created" data="{{ alert.creationTimestamp | date:'M/d/yyyy, h:mm:ss a' }}"></ati-labeled-data>

      <ati-labeled-data fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between start" label="Last Updated" data="{{ alert.lastUpdatedTimestamp | date:'M/d/yyyy, h:mm:ss a' }}"></ati-labeled-data>

      <ati-labeled-data fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between start" label="Last Updated By" data="{{ alert.lastUpdatedByName }}"></ati-labeled-data>

      <ati-labeled-data fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between start" label="Target" [link]="getTargetFromActionUri(alert.actionUri)" [data]="getTargetFromActionUri(alert.actionUri)"></ati-labeled-data>
    </div>

  </mat-card>
</div>
