<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <hms-error-message [title]="'Error Retrieving Contract Details'" [message]="errorMessage"></hms-error-message>

    <form *ngIf="!errorMessage" [formGroup]="contractForm" (ngSubmit)="submitContractUpdate()" fxLayout="column">

      <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
        <h2 data-cy="page-title-contract-detail" class="card-title">Contract: {{ getTitle() }}</h2>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
          <button data-cy="editContract" id="edit-contract" class="action-button" type="button" aria-label="Edit Contract" matTooltip="Edit Contract" *ngIf="canEditContract && activeTabIndex === 0" (click)="setEditMode(true)" mat-mini-fab color="accent">
            <mat-icon>edit</mat-icon>
          </button>
          <button data-cy="cancelContract" id="cancel-contract" class="action-button" type="button" aria-label="Cancel Contract" *ngIf="canCancelContract && activeTabIndex === 0 && !hasPartialFill" (click)="cancelContract()" mat-mini-fab color="primary" matTooltip="Cancel Contract">
            <mat-icon>do_not_disturb</mat-icon>
          </button>
          <button data-cy="haltContract" id="halt-contract" class="action-button" type="button" aria-label="Cancel Partial Contract" *ngIf="canCancelContract && activeTabIndex === 0 && hasPartialFill && contract.contractOrderDocId" (click)="haltContract()" mat-mini-fab color="primary" matTooltip="Cancel Partial Contract">
            <mat-icon>work_off</mat-icon>
          </button>
          <button data-cy="deleteContract" id="delete-contract" class="action-button" type="button" aria-label="Delete Contract" matTooltip="Delete Contract" *ngIf="canDeleteContract && activeTabIndex === 0 && !hasPartialFill" (click)="deleteContract()" mat-mini-fab color="warn" matTooltip="Delete Contract">
            <mat-icon>delete</mat-icon>
          </button>
          <mat-spinner *ngIf="!updateComplete || isLoading" diameter="40" color="accent"></mat-spinner>
          <button data-cy="updateContract" type="submit" id="update-contract" class="action-button" aria-label="Update Contract" matTooltip="Update Contract" *ngIf="(editMode || creatingContractExchange || creatingPricingExchange) && updateComplete && activeTabIndex === 0" [disabled]="!contractForm.valid || contractForm.pristine || isLoadingBasis" (click)="submitContractUpdate()" mat-mini-fab color="accent">
            <mat-icon>done</mat-icon>
          </button>
          <button data-cy="setContractOrder" type="submit" id="set-contract-order" class="action-button" aria-label="Update Contract" *ngIf="(creatingOrder || recreatingOrder) && activeTabIndex === 0 && (!qstService.enabled || qstCreateError)" [disabled]="orderForm.pristine || !orderForm.valid || qstWorking" (click)="setContractOrder()" mat-mini-fab color="accent" matTooltip="Update Contract">
            <mat-icon>done</mat-icon>
          </button>
          <button data-cy="cancelEditContract" id="cancel-edit-contract" class="action-button" type="button" aria-label="Discard Changes" matTooltip="Discard Changes" *ngIf="editMode && updateComplete && activeTabIndex === 0" (click)="reset()" mat-mini-fab color="">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>

      <mat-tab-group *ngIf="contractDetails$ | async" #tabGroup (selectedTabChange)="onTabChange($event)" (animationDone)="onAnimationDone()">

        <mat-tab label="Details" fxFlex="100">
          <div class="tab-body" fxFlex="100" fxLayout="column">

            <div class="top-row" *ngIf="contract.status === 'PENDING_APPROVAL' && canCompleteContractApproval" fxLayout="row" fxLayoutGap="10px">
              <button data-cy="approveContract" id="approve-contract" type="button"  aria-label="Approve Contract" (click)="approveContract()" mat-raised-button color="accent">Approve</button>
              <button data-cy="denyContract" id="deny-contract" type="button" aria-label="Deny Contract" (click)="denyContract()" mat-stroked-button color="accent">Deny</button>
            </div>

            <div class="top-row" *ngIf="contract.status === 'PENDING_BASIS' && availableQuantity && canEditContract" fxLayout="row" fxLayoutGap="10px">
              <button data-cy="priceBasis" id="price-contract-basis" type="button" aria-label="Price Basis" (click)="priceBasis()" mat-raised-button color="accent">Price Basis</button>
            </div>

            <div class="top-row" *ngIf="contract.status === 'PENDING_FUTURES' && availableQuantity && canEditContract" fxLayout="row" fxLayoutGap="10px">
              <button data-cy="priceFutures" id="price-contract-futures" type="button" aria-label="Price Futures" (click)="priceFutures()" mat-raised-button color="accent">Price Futures</button>
            </div>

            <div class="top-row" *ngIf="contract.status === 'PENDING_PRICE' && availableQuantity && canEditContract" fxLayout="row" fxLayoutGap="10px">
              <button id="price-dp-cash" type="button" aria-label="Price Cash" (click)="priceCash()" mat-raised-button color="accent" data-cy="priceDPCash">Price Cash</button>
              <button id="price-dp-basis" type="button" aria-label="Price Basis" (click)="priceBasis()" mat-raised-button color="accent" data-cy="priceDPBasis">Price Basis</button>
              <button data-cy="price-dp-futures" id="price-dp-futures" type="button" aria-label="Price Futures" (click)="priceFutures()" mat-raised-button color="accent" data-cy="priceDPFutures">Price Futures</button>
            </div>

            <div class="top-row" *ngIf="contract.status === 'WORKING_EXCHANGE' && canCompleteOrderAction" fxLayout="row" fxLayoutGap="10px">
              <button data-cy="completeContractExchange" id="complete-contract-exchange" type="button"  aria-label="Complete Exchange" (click)="completeContractExchange()" mat-raised-button color="accent">Complete Exchange</button>
              <button data-cy="cancelContractExchange" id="cancel-contract-exchange" type="button" aria-label="Cancel Exchange" (click)="cancelContractExchange()" mat-stroked-button color="accent">Cancel Exchange</button>
            </div>

            <div class="top-row" *ngIf="!pricingActionComplete" fxLayout="column" fxLayoutGap="10px">
              <hms-pricing-segment data-cy="pricing-segment" fxFlex="100" [createMode]="true" [dpPricing]="dpPricing" [contract]="contract" [availableQuantity]="availableQuantity" [unpricedQuantity]="unpricedQuantity" [commodityProfile]="profile" (pricingSegmentEvent)="onPricingSegmentEvent($event)"></hms-pricing-segment>
              <mat-divider fxFlex="100" inset></mat-divider>
            </div>

            <!-- Status, Contract Type, Side, Pricing Type -->
            <div class="top-row" fxLayout="row wrap" fxLayoutAlign="start start">
              <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Status" [data]="contract.status | replace | titlecase" data-cy="status"></ati-labeled-data>
              <ati-labeled-data *ngIf="contract.isSpot" fxFlex.gt-xs="25" fxFlex.xs="50" label="Type" data="Spot"></ati-labeled-data>
              <ati-labeled-data *ngIf="!contract.isSpot" fxFlex.gt-xs="25" fxFlex.xs="50" label="Type" [data]="contract.type | contractType"></ati-labeled-data>
              <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Side" [data]="contract.side | titlecase"></ati-labeled-data>
              <ati-labeled-data *ngIf="contract.type !== 'DP'" fxFlex.gt-xs="25" fxFlex.xs="50" label="Pricing Type" [data]="contract.pricingType | pricingType | titlecase"></ati-labeled-data>
            </div>

            <!-- Contract Quantity, Price Locked Quantity, Unpriced Quantity, Working Quantity -->
            <div fxLayout="row wrap" fxLayoutAlign="start start">

              <ati-labeled-data *ngIf="!editMode || !contractForm.get('quantity')" fxFlex.gt-xs="25" fxFlex.xs="50" label="Contract {{ getContractUnit() | contractUnit }}" [data]="contract.quantity | number: '1.0-2' "></ati-labeled-data>
              <div *ngIf="editMode && contractForm.get('quantity')" fxFlex.gt-xs="25" fxFlex.xs="50" fxLayout="row">
                <mat-form-field fxFlex="90">
                  <input required matInput [placeholder]="getContractUnit() | contractUnit" formControlName="quantity">
                  <mat-error *ngIf="contractForm.get('quantity').invalid">{{ getErrorMessage(contractForm.get('quantity')) }}</mat-error>
                  <button id="contract-detail-clear-quantity" type="button" *ngIf="contractForm.get('quantity').value" matSuffix mat-icon-button aria-label="Clear Quantity" matTooltip="Clear Quantity" (click)="clearField('quantity')">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>

              <ati-labeled-data *ngIf="contract.status !== 'COMPLETE'" fxFlex.gt-xs="25" fxFlex.xs="50" label="Priced {{ getContractUnit() | contractUnit }}" [data]="pricedQuantity | number: '1.0-2' "></ati-labeled-data>
              <ati-labeled-data *ngIf="contract.status !== 'COMPLETE'" fxFlex.gt-xs="25" fxFlex.xs="50" label="Unpriced {{ getContractUnit() | contractUnit }}" [data]="unpricedQuantity | number: '1.0-2' "></ati-labeled-data>
              <ati-labeled-data *ngIf="contract.status !== 'COMPLETE'" fxFlex.gt-xs="25" fxFlex.xs="50" label="Working {{ getContractUnit() | contractUnit }}" [data]="workingQuantity | number: '1.0-2' "></ati-labeled-data>
            </div>

            <!-- Contract ID, Custom Contract Id, Order ID -->
            <div fxLayout="row wrap" fxLayoutAlign="start start">
              <!-- Contract ID -->
              <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Contract ID" [data]="contract.accountingSystemId"></ati-labeled-data>
              <!-- Custom Contract ID -->
              <ng-container *ngIf="contract.side === 'SELL' && !contract.accountingSystemId">
                <ati-labeled-data *ngIf="!editMode || !contractForm.get('customContractId')" fxFlex.gt-xs="25" fxFlex.xs="50" label="Custom Contract ID" [data]="contract.customContractId"></ati-labeled-data>
                <div *ngIf="editMode && contractForm.get('customContractId')" fxFlex.gt-xs="25" fxFlex.xs="50">
                  <mat-form-field fxFlex="90">
                    <input matInput placeholder="Custom Contract ID" formControlName="customContractId">
                    <mat-error *ngIf="contractForm.get('customContractId').invalid">{{ getErrorMessage(contractForm.get('customContractId')) }}</mat-error>
                    <div matSuffix fxLayout="row">
                      <button id="contract-detail-clear-custom-contract-id" type="button" *ngIf="contractForm.get('customContractId').value && contractForm.get('customContractId').enabled" mat-icon-button aria-label="Clear Custom Contract ID" matTooltip="Clear Custom Contract ID" (click)="clearField('customContractId')">
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>
                  </mat-form-field>
                </div>
              </ng-container>
              <!-- Order ID -->
              <div fxLayout="row" *ngIf="contract.contractOrderDocId" fxFlex.gt-xs="25" fxFlex.xs="50">
                <ati-labeled-data label="Order ID" [data]="orderDocIdDisplay" [link]="getOrderLink()"></ati-labeled-data>
                <button *ngIf="cancellingOrder && !qstWorking" id="cancel-contract-order" class="action-button" type="button" aria-label="Cancel Order" (click)="cancelOrder()" mat-icon-button color="warn" matTooltip="Cancel Order">
                  <mat-icon>do_not_disturb</mat-icon>
                </button>
                <button *ngIf="updatingOrder && !qstWorking" id="update-contract-order" class="action-button" type="button" aria-label="Update Order" (click)="updateOrder()" mat-icon-button color="primary" matTooltip="Update Order">
                  <mat-icon>flaky</mat-icon>
                </button>
                <button *ngIf="recreatingOrder && !qstWorking" id="recreate-contract-order" class="action-button" type="button" aria-label="Recreate Order" (click)="recreateOrder()" mat-icon-button color="primary" matTooltip="Recreate Order">
                  <mat-icon>flaky</mat-icon>
                </button>
                <mat-spinner *ngIf="qstWorking" diameter="16"></mat-spinner>
              </div>
              <form *ngIf="creatingOrder" class="creating-order" fxFlex.gt-xs="25" fxFlex.xs="100" [formGroup]="orderForm" (ngSubmit)="setContractOrder()">
                <mat-form-field>
                  <input required matInput placeholder="Order ID" formControlName="orderDocId" trim="blur" (blur)="isOrderFormValid" (keyup)="isOrderFormValid">
                  <button class="qst-btn" type="button" id="create-contract-order" *ngIf="!orderForm.get('orderDocId').value && qstService.enabled && !qstWorking" matTooltip="Create Order" matSuffix mat-icon-button aria-label="Create Order" (click)="createOrder()">
                    <mat-icon>receipt</mat-icon>
                  </button>
                  <mat-spinner matSuffix *ngIf="qstWorking" diameter="16"></mat-spinner>
                  <mat-error *ngIf="orderForm.get('orderDocId').invalid">{{ getErrorMessage(orderForm.get('orderDocId')) }}</mat-error>
                  <div class="margin-bottom-thin mat-form-field-subscript-wrapper" [ngClass]="orderFormInvalid ? 'order-hint-position-with-error' : 'order-hint-position'" >
                    <span id="order-hint" class="mat-hint">
                      <li>{{ profile.officeCode }}{{ profile.accountNumber }}</li>
                      <li>
                        {{ contract.side }} {{ getOrderQuantity() }} {{ contract.futuresYearMonth | codeToMonth }} {{ contract.commodityId }} @
                        <span *ngIf="contract.futuresPrice; else contractMarket">{{ contract.futuresPrice | number: '1.4-4'}}</span>
                        <ng-template #contractMarket>Market</ng-template>
                      </li>
                      <li>
                        <span *ngIf="contract.expirationDate">GTD {{ contract.expirationDate | date: 'M/d/yyyy'  }} </span>
                      </li>
                    </span>
                  </div>
                </mat-form-field>
              </form>
              <form *ngIf="recreatingOrder && !contract.contractOrderDocId" fxFlex.gt-xs="25" fxFlex.xs="100" [formGroup]="orderForm" (ngSubmit)="setContractOrder()">
                <mat-form-field>
                  <input required matInput placeholder="Order ID" formControlName="orderDocId" trim="blur">
                  <mat-error *ngIf="orderForm.get('orderDocId').invalid">{{ getErrorMessage(orderForm.get('orderDocId')) }}</mat-error>
                </mat-form-field>
              </form>
            </div>

            <!-- Commodity Profile, Production Year, Patron -->
            <div fxLayout="row wrap" fxLayoutAlign="start start">
              <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Commodity Profile" [data]="contract.commodityProfileName"></ati-labeled-data>

              <ati-labeled-data *ngIf="!editMode || !contractForm.get('productionYear')" fxFlex.gt-xs="25" fxFlex.xs="50" label="Production Year" [data]="getProductionYearLabel() | productionYear"></ati-labeled-data>
              <div *ngIf="editMode && contractForm.get('productionYear')" fxFlex.gt-xs="25" fxFlex.xs="50" fxLayout="row">
                <mat-form-field fxFlex="90">
                  <mat-select required placeholder="Production Year" formControlName="productionYear" [compareWith]="compareProductionYear">
                    <mat-option *ngFor="let prodYear of prodYears" [value]="prodYear">{{ prodYear.label | productionYear }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <ati-labeled-data fxFlex.gt-xs="50" fxFlex.xs="100" label="Patron" [data]="getContractPatronDisplayName()"></ati-labeled-data>
            </div>

            <!-- Client Location, Delivery Location -->
            <div fxLayout="row wrap" fxLayoutAlign="start start">

              <!-- Client Location -->
              <div fxFlex.gt-xs="25" fxFlex.xs="100" *ngIf="!editMode || !contractForm.get('clientLocation')" fxLayout="row">
                <ati-labeled-data label="Client Location" [data]="contract.clientLocationName"></ati-labeled-data>
              </div>
              <div *ngIf="editMode && contractForm.get('clientLocation')" fxFlex.gt-xs="25" fxFlex.xs="100" fxLayout="row">
                <mat-form-field fxFlex="95">
                  <input required matInput placeholder="Client Location" formControlName="clientLocation" [matAutocomplete]="autoClientLocation">
                  <mat-error *ngIf="contractForm.get('clientLocation').invalid">You must select a valid client location</mat-error>
                  <button id="contract-detail-clear-client-location" type="button" *ngIf="contractForm.get('clientLocation').value" matSuffix mat-icon-button aria-label="Clear Client Location" matTooltip="Clear Delivery Location" (click)="clearField('clientLocation')">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-autocomplete #autoClientLocation="matAutocomplete" [displayWith]="displayLocation">
                  <mat-option *ngFor="let location of filteredClientLocations$ | async" [value]="location">{{ displayLocation(location) }}</mat-option>
                </mat-autocomplete>
              </div>

              <!-- Delivery Location -->
              <div fxFlex.gt-xs="25" fxFlex.xs="100" *ngIf="!editMode || !contractForm.get('deliveryLocation')" fxLayout="row">
                <ati-labeled-data label="Delivery Location" [data]="contract.deliveryLocationName"></ati-labeled-data>
              </div>
              <div *ngIf="editMode && contractForm.get('deliveryLocation')" fxFlex.gt-xs="25" fxFlex.xs="100" fxLayout="row" >
                <mat-form-field fxFlex="95">
                  <input required matInput placeholder="Delivery Location" formControlName="deliveryLocation" [matAutocomplete]="autoDeliveryLocation">
                  <mat-error *ngIf="contractForm.get('deliveryLocation').invalid">You must select a valid delivery location</mat-error>
                  <button id="contract-detail-clear-delivery-location" type="button" *ngIf="contractForm.get('deliveryLocation').value" matSuffix mat-icon-button aria-label="Clear Delivery Location" matTooltip="Clear Delivery Location" (click)="clearField('deliveryLocation')">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-autocomplete #autoDeliveryLocation="matAutocomplete" [displayWith]="displayLocation">
                  <mat-option *ngFor="let location of filteredDeliveryLocations$ | async" [value]="location">{{ displayLocation(location) }}</mat-option>
                </mat-autocomplete>
              </div>

              <!-- Delivery Type -->
              <ng-container *ngIf="editMode && canChangeDeliveryType; then editDeliveryTypeTemplate; else deliveryTypeTemplate "></ng-container>
              <ng-template #deliveryTypeTemplate>
                <div fxFlex.gt-xs="25" fxFlex.xs="100" *ngIf="contractForm?.controls?.deliveryType?.value" fxLayout="row">
                  <ati-labeled-data label="Delivery Type" [data]="deliveryTypeLabelValue" data-cy="label-deliveryType"></ati-labeled-data>
                </div>
              </ng-template>
              <ng-template #editDeliveryTypeTemplate>
                  <div fxFlex.gt-xs="25" fxFlex.xs="100" fxLayout="row">
                    <mat-form-field fxFlex="95">
                      <mat-label>Delivery Type</mat-label>
                      <mat-select [value]="contractForm.controls.deliveryType.value" formControlName="deliveryType" data-cy="deliveryType" >
                        <mat-option *ngFor="let type of deliveryTypes" [value]="type">{{ type === 'FOB' ? type : type | titlecase }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
              </ng-template>
            </div>

            <!-- Delivery Period, Futures Year Month, Commodity, Time in Force, Expiration Date -->
            <div fxLayout="row wrap" fxLayoutAlign="start start">
              <ati-labeled-data *ngIf="!editMode || !contractForm.get('deliveryPeriod')" fxFlex.gt-xs="25" fxFlex.xs="50" label="Delivery Period" [data]="contract.deliveryPeriod | codeToMonth"></ati-labeled-data>
              <div *ngIf="editMode && contractForm.get('deliveryPeriod')" fxFlex.gt-xs="25" fxFlex.xs="50" fxLayout="row">
                <mat-form-field fxFlex="90" hmsMonthPicker>
                  <input required matInput [matDatepicker]="deliveryPeriodPicker" placeholder="Delivery Period" formControlName="deliveryPeriod" (keydown)="allowTabOnly($event)">
                  <mat-datepicker-toggle matSuffix [for]="deliveryPeriodPicker"></mat-datepicker-toggle>
                  <mat-datepicker #deliveryPeriodPicker startView="year" (monthSelected)="selectDeliveryPeriod($event)"></mat-datepicker>
                  <mat-error *ngIf="contractForm.get('deliveryPeriod').invalid">{{ getErrorMessage(contractForm.get('deliveryPeriod')) }}</mat-error>
                </mat-form-field>
              </div>

              <ati-labeled-data *ngIf="contract.type !== 'DP' && (!editMode || !contractForm.get('futuresYearMonth'))" fxFlex.gt-xs="25" fxFlex.xs="50" label="Contract" data="{{ contract.futuresYearMonth | codeToMonth }} {{ getCommodityDisplayName() }}"></ati-labeled-data>
              <div *ngIf="contract.type !== 'DP' && editMode && contractForm.get('futuresYearMonth')" fxFlex.gt-xs="25" fxFlex.xs="50" fxLayout="row">
                <mat-form-field fxFlex="90" hmsMonthPicker>
                  <input required matInput [matDatepicker]="futuresMonthPicker" [matDatepickerFilter]="futuresMonthFilter" placeholder="Futures Month" formControlName="futuresYearMonth" [min]="minDate" (keydown)="allowTabOnly($event)">
                  <mat-datepicker-toggle matSuffix [for]="futuresMonthPicker"></mat-datepicker-toggle>
                  <mat-datepicker #futuresMonthPicker startView="year" (monthSelected)="selectFuturesMonth($event)"></mat-datepicker>
                  <mat-error *ngIf="contractForm.get('futuresYearMonth').invalid">{{ getErrorMessage(contractForm.get('futuresYearMonth')) }}</mat-error>
                </mat-form-field>
              </div>

              <ati-labeled-data *ngIf="contract.type === 'DP'" fxFlex.gt-xs="25" fxFlex.xs="50" label="Commodity" [data]="getCommodityDisplayName()"></ati-labeled-data>
              <ati-labeled-data *ngIf="contract.type !== 'DP'" fxFlex.gt-xs="25" fxFlex.xs="50" label="Time in Force" [data]="contract.timeInForce !== 'DAY' ? contract.timeInForce : contract.timeInForce | titlecase"></ati-labeled-data>

              <ati-labeled-data *ngIf="contract.pricingType === 'LIMIT' && (!editMode || !contractForm.get('expirationDate'))" fxFlex.gt-xs="25" fxFlex.xs="50" label="Expiration Date" [data]="getExpirationDate() | date: 'M/d/yyyy' "></ati-labeled-data>
              <div *ngIf="contract.pricingType === 'LIMIT' && editMode && contractForm.get('expirationDate')" fxFlex.gt-xs="25" fxFlex.xs="50" fxLayout="row">
                <mat-form-field fxFlex="90">
                  <input matInput [matDatepicker]="expirationDatePicker" placeholder="Expiration Date" formControlName="expirationDate" [matDatepickerFilter]="expirationDateFilter" [min]="expiryMinDate" [max]="expiryMaxDate">
                  <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #expirationDatePicker></mat-datepicker>
                  <mat-error *ngIf="contractForm.get('expirationDate').invalid">{{ getErrorMessage(contractForm.get('expirationDate')) }}</mat-error>
                </mat-form-field>
              </div>

            </div>

            <!-- Contract Level - Exchange ID, Exchange Type, Exchange Side, Exchange Contracts -->
            <div *ngIf="contract.isExchange" fxLayout="row wrap" fxLayoutAlign="start start">
              <ati-labeled-data *ngIf="!creatingContractExchange && (!editMode || !contractForm.get('contractExchangeId'))" fxFlex.gt-xs="25" fxFlex.xs="50" label="Exchange ID" [data]="contract.exchangeId"></ati-labeled-data>
              <div *ngIf="(creatingContractExchange && !contract.exchangeId) || (editMode && contractForm.get('contractExchangeId'))" fxFlex.gt-xs="25" fxFlex.xs="50">
                <mat-form-field fxFlex="90">
                  <input required matInput placeholder="Exchange ID" formControlName="contractExchangeId" trim="blur">
                  <mat-error *ngIf="contractForm.get('contractExchangeId').invalid">{{ getErrorMessage(contractForm.get('contractExchangeId')) }}</mat-error>
                </mat-form-field>
              </div>

              <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Exchange Type" [data]="contract.exchangeType"></ati-labeled-data>
              <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Exchange Side" [data]="getExchangeSide() | titlecase"></ati-labeled-data>
              <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Exchange Contracts" [data]="contract.exchangeContracts | number:'1.0-0'"></ati-labeled-data>
            </div>

            <!-- Contract Level - Exchange Price, Contra Firm, Contra Account, Exchange Timestamp -->
            <div *ngIf="contract.isExchange" fxLayout="row wrap" fxLayoutAlign="start start">
              <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Exchange Price" [data]="contract.futuresPrice | number: '1.2-5' "></ati-labeled-data>
              <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Contra Firm" [data]="contract.exchangeContraFirm"></ati-labeled-data>
              <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Contra Account" [data]="contract.exchangeContraAccount"></ati-labeled-data>
              <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Exchange Date" [data]="contract.exchangeTimestamp | date: 'M/d/yyyy, h:mm:ss a' "></ati-labeled-data>
            </div>

            <!-- Related Hedge -->
            <div *ngIf="contract.hasRelatedHedge" fxLayout="row wrap" fxLayoutAlign="start start">
              <ati-labeled-data *ngIf="contract.relatedHedgeType === 'OTC'" fxFlex.gt-xs="25" fxFlex.xs="50" label="Related Hedge" data="OTC Trade"></ati-labeled-data>
              <ati-labeled-data *ngIf="contract.relatedHedgeType === 'OPTION'" fxFlex.gt-xs="25" fxFlex.xs="50" label="Related Hedge" data="Exercised Option"></ati-labeled-data>
              <ati-labeled-data *ngIf="contract.relatedHedgeType === 'OPTION'" fxFlex.gt-xs="25" fxFlex.xs="50" label="Option Contracts" [data]="contract.hedgedContracts | number:'1.0-0'"></ati-labeled-data>
            </div>

            <!-- Basis, Futures, Cash, Freight, Basis Adjustment, Exchange Rate -->
            <div fxLayout="row wrap" fxLayoutAlign="start start">

              <!-- Basis Price -->
              <div fxFlex.gt-xs="25" fxFlex.xs="50" *ngIf="(contract.type === 'CASH' || contract.type === 'BASIS') && (!editMode || !contractForm.get('basisPrice') || contractForm.get('basisPrice').disabled)" fxLayout="row">
                <ati-labeled-data label="Basis" [data]="getContractFieldVal('basisPrice') | number: '1.2-4' " data-cy="label-basisPrice"></ati-labeled-data>
                <mat-spinner class="loading-spinner" *ngIf="isLoadingBasis" diameter="16"></mat-spinner>
              </div>
              <div *ngIf="(contract.type === 'CASH' || contract.type === 'BASIS') && editMode && contractForm.get('basisPrice') && contractForm.get('basisPrice').enabled" fxFlex.gt-xs="25" fxFlex.xs="50" fxLayout="row">
                <mat-form-field fxFlex="90">
                  <input required matInput placeholder="Basis" formControlName="basisPrice" trim="blur" (blur)="formatUpdatedPrice('basisPrice')" data-cy="basisPrice">
                  <mat-error *ngIf="contractForm.get('basisPrice').invalid">{{ getErrorMessage(contractForm.get('basisPrice')) }}</mat-error>
                  <div matSuffix fxLayout="row">
                    <mat-spinner class="loading-spinner" *ngIf="isLoadingBasis" diameter="16"></mat-spinner>
                    <button id="contract-detail-clear-basis-price" type="button" *ngIf="contractForm.get('basisPrice').value && contractForm.get('basisPrice').enabled" mat-icon-button aria-label="Clear Basis" matTooltip="Clear Basis" (click)="clearField('basisPrice')">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </mat-form-field>
              </div>

              <!-- Futures Price -->
              <div fxFlex.gt-xs="25" fxFlex.xs="50" *ngIf="(contract.type === 'CASH' || contract.type === 'HTA') && (!editMode || !contractForm.get('futuresPrice') || contractForm.get('futuresPrice').disabled)" fxLayout="row">
                <div fxLayout="row">
                  <ati-labeled-data label="Futures" [data]="getContractFieldVal('futuresPrice') | number: '1.2-5' " data-cy="label-futuresPrice"></ati-labeled-data>
                  <div fxLayout="column" fxLayoutAlign="center center">
                    <button *ngIf="canPriceAtTheMarket" id="price-contract-at-the-market" type="button" aria-label="Price at the Market" (click)="priceAtTheMarket()" mat-icon-button color="accent" matTooltip="Price at the Market">
                      <mat-icon>price_check</mat-icon>
                    </button>
                  </div>
                  <div fxLayout="column" fxLayoutAlign="center center">
                    <button *ngIf="canManuallyLockPrice" id="manually-lock-contract" type="button" aria-label="Lock Price" (click)="lockPrice()" mat-icon-button color="accent" matTooltip="Lock Price">
                      <mat-icon>lock_outline</mat-icon>
                    </button>
                  </div>
                  <div fxLayout="column" fxLayoutAlign="center center">
                    <mat-spinner class="futures-spinner" *ngIf="isLoadingBasis || isLoadingMarketData" diameter="16"></mat-spinner>
                  </div>
                </div>
              </div>
              <div *ngIf="(contract.type === 'CASH' || contract.type === 'HTA') && editMode && contractForm.get('futuresPrice') && contractForm.get('futuresPrice').enabled" fxFlex.gt-xs="25" fxFlex.xs="50" fxLayout="row">
                <mat-form-field fxFlex="90">
                  <input required matInput placeholder="Futures" formControlName="futuresPrice" trim="blur" (blur)="formatUpdatedPrice('futuresPrice')" data-cy="futuresPrice">
                  <mat-error *ngIf="contractForm.get('futuresPrice').invalid">{{ getErrorMessage(contractForm.get('futuresPrice')) }}</mat-error>
                  <div matSuffix fxLayout="row">
                    <mat-spinner class="loading-spinner" *ngIf="isLoadingBasis" diameter="16"></mat-spinner>
                    <button id="contract-detail-clear-futures-price" type="button" *ngIf="contractForm.get('futuresPrice').value && contractForm.get('futuresPrice').enabled" mat-icon-button aria-label="Clear Futures" matTooltip="Clear Futures" (click)="clearField('futuresPrice')">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </mat-form-field>
              </div>

              <!-- Cash Price -->
              <div fxFlex.gt-xs="25" fxFlex.xs="50" *ngIf="contract.type === 'CASH' && (!editMode || !contractForm.get('cashPrice'))" fxLayout="row">
                <ati-labeled-data label="Cash" [data]="contract.cashPrice | number: '1.2-5' " data-cy="label-cashPrice"></ati-labeled-data>
                <mat-spinner class="loading-spinner" *ngIf="isLoadingBasis" diameter="16"></mat-spinner>
              </div>
              <div *ngIf="contract.type === 'CASH' && editMode && contractForm.get('cashPrice')" fxFlex.gt-xs="25" fxFlex.xs="50" fxLayout="row">
                <mat-form-field fxFlex="90">
                  <input required matInput placeholder="Cash" formControlName="cashPrice" trim="blur" (blur)="formatUpdatedPrice('cashPrice')" data-cy="cashPrice">
                  <mat-error *ngIf="contractForm.get('cashPrice').invalid">{{ getErrorMessage(contractForm.get('cashPrice')) }}</mat-error>
                  <div matSuffix fxLayout="row">
                    <mat-spinner class="loading-spinner" *ngIf="isLoadingBasis" diameter="16"></mat-spinner>
                    <button id="contract-detail-clear-cash-price" type="button" *ngIf="contractForm.get('cashPrice').value && contractForm.get('cashPrice').enabled" mat-icon-button aria-label="Clear Cash" matTooltip="Clear Cash" (click)="clearField('cashPrice')">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </mat-form-field>
              </div>

              <!-- Freight Price -->
              <div fxFlex.gt-xs="25" fxFlex.xs="50" *ngIf="!editMode || !contractForm.get('freightPrice') || !canAdjustBasis" fxLayout="row">
                <ati-labeled-data label="Freight" [data]="contract.freightPrice | number: '1.2-4' " data-cy="label-freightPrice"></ati-labeled-data>
              </div>
              <div *ngIf="editMode && contractForm.get('freightPrice') && canAdjustBasis" fxFlex.gt-xs="25" fxFlex.xs="50" fxLayout="row">
                <mat-form-field fxFlex="90">
                  <input required matInput placeholder="Freight" formControlName="freightPrice" trim="blur" (blur)="formatUpdatedPrice('freightPrice')" data-cy="freightPrice">
                  <mat-error *ngIf="contractForm.get('freightPrice').invalid">{{ getErrorMessage(contractForm.get('freightPrice')) }}</mat-error>
                  <button id="contract-detail-clear-freight-price" type="button" *ngIf="contractForm.get('freightPrice').value && contractForm.get('freightPrice').enabled" matSuffix mat-icon-button aria-label="Clear Freight" matTooltip="Clear Freight" (click)="clearField('freightPrice', '0.0000', true)">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>

              <!-- Basis Adjustment -->
              <ati-labeled-data *ngIf="!editMode || !contractForm.get('basisAdjustment') || !canAdjustBasis" fxFlex.gt-xs="25" fxFlex.xs="50" label="Basis Adjustment" [data]="contract.basisAdjustment | number: '1.2-4' " data-cy="label-basisAdjustment"></ati-labeled-data>
              <div *ngIf="editMode && contractForm.get('basisAdjustment') && canAdjustBasis" fxFlex.gt-xs="25" fxFlex.xs="50" fxLayout="row">
                <mat-form-field fxFlex="90">
                  <input required matInput placeholder="Basis Adjustment" formControlName="basisAdjustment" trim="blur" (blur)="formatUpdatedPrice('basisAdjustment')" data-cy="basisAdjustment">
                  <mat-error *ngIf="contractForm.get('basisAdjustment').invalid">{{ getErrorMessage(contractForm.get('basisAdjustment')) }}</mat-error>
                  <button id="contract-detail-clear-basis-adjustment" type="button" *ngIf="contractForm.get('basisAdjustment').value" matSuffix mat-icon-button aria-label="Clear Basis Adjustment" matTooltip="Clear Basis Adjustment" (click)="clearField('basisAdjustment', '0.0000', true)">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>

              <ati-labeled-data *ngIf="isInternational" fxFlex.gt-xs="25" fxFlex.xs="50" label="Exchange Rate" [data]="contract.exchangeRate | number: '1.2-4' "></ati-labeled-data>

            </div>

            <!-- Price Adjustments Total Only -->
            <div fxLayout="row wrap" fxLayoutAlign="start start" *ngIf="displayPriceAdjustmentsTotalOnly">
              <ati-labeled-data fxFlex.gt-xs="30" fxFlex.xs="100" label="Price Adjustments Total" data="{{ priceAdjustmentsTotal | number:'1.0-5' }}"></ati-labeled-data>
            </div>

            <!-- Price Adjustments Panel -->
            <div fxLayout="row wrap" fxLayoutAlign="start start" *ngIf="displayPriceAdjustments">
              <mat-expansion-panel class="mat-elevation-z0" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between start" [expanded]="editMode">

                <mat-expansion-panel-header fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between start">
                  <ati-labeled-data fxFlex.gt-xs="30" fxFlex.xs="100" label="Price Adjustments Total" data="{{ priceAdjustmentsTotal | number:'1.0-5' }}"></ati-labeled-data>
                </mat-expansion-panel-header>

                <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start">
                  <!-- Select field to add new Price Adjustment -->
                  <div *ngIf="displayEditablePriceAdjustments && availablePriceAdjustments.length" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start">
                    <mat-form-field fxFlex.gt-xs="30" fxFlex.xs="100">
                      <mat-label>Price Adjustments</mat-label>
                      <mat-select #newPriceAdjustment>
                        <mat-option *ngFor="let priceAdjustment of availablePriceAdjustments" [value]="priceAdjustment">{{ getHMSPriceAdjustmentDisplay(priceAdjustment) }}</mat-option>
                      </mat-select>
                      <button type="button" id="add-contract-detail-price-adjustment" [disabled]="!newPriceAdjustment.value" mat-button matSuffix mat-icon-button aria-label="Add Price Adjustment" matTooltip="Add Price Adjustment" (click)="addPriceAdjustment($event)" >
                        <mat-icon>add_circle</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                  <!-- Price Adjustment Item display in edit mode if Price Adjustments included in dynamic form and user can adjust prices-->
                  <div *ngIf="displayEditablePriceAdjustments" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.gt-xs="25px" fxLayoutGap.xs="0px">
                    <div class="price-adjustment-item" fxFlex.gt-xs="30" fxFlex.xs="100" formArrayName="priceAdjustments" *ngFor="let priceAdjustmentForm of existingPriceAdjustments; let idx = index;" [formGroup]="priceAdjustmentForm">
                      <mat-form-field fxFlex="100">
                        <input required matInput [placeholder]="getContractPriceAdjustmentLabel(priceAdjustmentForm.get('id').value)" [formControl]="priceAdjustmentForm.get('priceAdjustment')" trim="blur" (blur)="processUpdatedPriceAdjustment(priceAdjustmentForm)" (input)="updatePriceAdjustmentsTotal()">
                        <mat-error *ngIf="priceAdjustmentForm.get('priceAdjustment').invalid">{{ getErrorMessage(priceAdjustmentForm.get('priceAdjustment')) }}</mat-error>
                        <div matSuffix fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
                          <button type="button" id="clear-contract-detail-price-adjustment" *ngIf="priceAdjustmentForm.get('priceAdjustment').value" mat-icon-button aria-label="Clear Value" matTooltip="Clear Value" (click)="priceAdjustmentForm.get('priceAdjustment').setValue('')">
                            <mat-icon>close</mat-icon>
                          </button>
                          <button type="button" id="remove-contract-detail-price-adjustment" mat-icon-button aria-label="Remove Price Adjustment" matTooltip="Remove Price Adjustment" (click)="removePriceAdjustment(idx)">
                            <mat-icon>delete</mat-icon>
                          </button>
                        </div>
                      </mat-form-field>
                    </div>
                  </div>
                  <!-- Price Adjustment Item display in read only mode -->
                  <div *ngIf="!displayEditablePriceAdjustments" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap.gt-xs="25px" fxLayoutGap.xs="0px">
                    <div class="price-adjustment-item" fxFlex.gt-xs="30" fxFlex.xs="100" *ngFor="let priceAdjustment of contract.priceAdjustments;">
                      <ati-labeled-data fxFlex="100" [label]="getContractPriceAdjustmentLabel(priceAdjustment.id)" [data]="priceAdjustment.value | number:'1.0-5'"></ati-labeled-data>
                    </div>
                  </div>
                </div>

              </mat-expansion-panel>
            </div>

            <!-- Originator -->
            <div fxLayout="row wrap" fxLayoutAlign="start start">
              <ati-labeled-data *ngIf="!editMode || !contractForm.get('originator')" fxFlex.gt-xs="50" fxFlex.xs="100" label="Originator" [data]="getContractOriginatorDisplayName()" data-cy="label-originator"></ati-labeled-data>
              <div *ngIf="editMode && contractForm.get('originator')" fxFlex.gt-xs="50" fxFlex.xs="100">
                <mat-form-field fxFlex.gt-xs="95" fxFlex.xs="100" data-cy="originator">
                  <input required matInput placeholder="Originator" formControlName="originator" [matAutocomplete]="autoOriginator">
                  <mat-error *ngIf="contractForm.get('originator').invalid">You must select a valid originator</mat-error>
                  <button id="clear-new-contract-originator" *ngIf="contractForm.get('originator').value" matSuffix mat-icon-button aria-label="Clear Originator" matTooltip="Clear Originator" (click)="clearField('originator')">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-autocomplete #autoOriginator="matAutocomplete" [displayWith]="getOriginatorDisplayName">
                  <mat-option *ngFor="let originator of filteredOriginators$ | async" [value]="originator">{{ getOriginatorDisplayName(originator) }}</mat-option>
                </mat-autocomplete>
              </div>
            </div>

            <!-- Comments -->
            <ati-labeled-data *ngIf="!editMode || !contractForm.get('comments')" fxFlex="100" label="Comments" [data]="contract.comments" data-cy="label-comments"></ati-labeled-data>
            <mat-form-field *ngIf="editMode && contractForm.get('comments')" fxFlex="100">
              <textarea data-cy="comments" matInput placeholder="Comments" formControlName="comments"></textarea>
              <mat-hint>{{ contractForm.get('comments').value ? contractForm.get('comments').value.length : 0 }}/800</mat-hint>
              <mat-error *ngIf="contractForm.get('comments').invalid">{{getErrorMessage(contractForm.get('comments'))}}</mat-error>
              <button id="contract-detail-clear-comments" type="button" *ngIf="contractForm.get('comments').value" matSuffix mat-icon-button aria-label="Clear Comments" matTooltip="Clear Comments" (click)="clearField('comments')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <!-- Instructions -->
            <ati-labeled-data *ngIf="!editMode || !contractForm.get('instructions')" fxFlex="100" label="Instructions" [data]="contract.instructions" data-cy="label-instructions"></ati-labeled-data>
            <mat-form-field *ngIf="editMode && contractForm.get('instructions')" fxFlex="100">
              <textarea data-cy="instructions" matInput placeholder="Instructions" formControlName="instructions" trim="blur"></textarea>
              <mat-hint>{{ contractForm.get('instructions').value ? contractForm.get('instructions').value.length : 0 }}/500</mat-hint>
              <mat-error *ngIf="contractForm.get('instructions').invalid">{{getErrorMessage(contractForm.get('instructions'))}}</mat-error>
              <button id="contract-detail-clear-instructions" type="button" *ngIf="contractForm.get('instructions').value" matSuffix mat-icon-button aria-label="Clear Instructions" matTooltip="Clear Instructions" (click)="clearField('instructions')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <mat-divider inset></mat-divider>

            <!-- Created By, Creation Timestamp, Last Updated By, Last Updated Timestamp, Completion Timestamp, Cancellation Timestamp -->
            <div fxLayout="row wrap" fxLayoutAlign="start start">
              <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Created By" [data]="contract.creatorName"></ati-labeled-data>
              <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Creation Date" [data]="contract.creationTimestamp | date: 'M/d/yyyy, h:mm:ss a' "></ati-labeled-data>
              <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="100" label="Last Updated By" [data]="contract.lastUpdatedByName"></ati-labeled-data>
              <ati-labeled-data *ngIf="!contract.completionTimestamp && !contract.cancellationTimestamp" fxFlex.gt-xs="25" fxFlex.xs="100" label="Last Updated Date" [data]="contract.lastUpdatedTimestamp | date: 'M/d/yyyy, h:mm:ss a' "></ati-labeled-data>
              <ati-labeled-data *ngIf="contract.completionTimestamp" fxFlex.gt-xs="25" fxFlex.xs="100" label="Completion Date" [data]="contract.completionTimestamp | date: 'M/d/yyyy, h:mm:ss a' "></ati-labeled-data>
              <ati-labeled-data *ngIf="contract.cancellationTimestamp" fxFlex.gt-xs="25" fxFlex.xs="100" label="Cancellation Date" [data]="contract.cancellationTimestamp | date: 'M/d/yyyy, h:mm:ss a' "></ati-labeled-data>
            </div>

          </div>
        </mat-tab>

        <mat-tab [disabled]="editMode || !pricingActionComplete" *ngIf="contract.type !== 'CASH'" label="Pricings" fxFlex="100" aria-label="pricings-tab">
          <div class="tab-body" fxLayout="row wrap" fxLayoutAlign="space-between space-between" fxFlex="100" fxLayoutGap="5">

            <ng-container *ngIf="!isLoading && segments.length; else noSegments">
              <div *ngFor="let segment of sortedUnpricedSegments; last as last" fxFlex="100">
                <ng-container *ngIf="segment.status !== 'PRICED'">
                  <hms-pricing-segment data-cy="pricing-segment" [pricingSegment]="segment" [createMode]="false" [contract]="contract" [availableQuantity]="availableQuantity" [unpricedQuantity]="unpricedQuantity" [commodityProfile]="profile" (pricingSegmentEvent)="onPricingSegmentEvent($event)"></hms-pricing-segment>
                  <mat-divider inset *ngIf="!last"></mat-divider>
                </ng-container>
              </div>
              <mat-expansion-panel *ngIf="pricedSegments.length" class="mat-elevation-z0" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between start" [expanded]="!sortedUnpricedSegments.length">
                <mat-expansion-panel-header fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between start">
                  <h4>Priced ({{ pricedSegments.length }})</h4>
                </mat-expansion-panel-header>
                <div class="tab-body" fxLayout="row wrap" fxLayoutAlign="space-between space-between" fxFlex="100" fxLayoutGap="5">
                <div *ngFor="let segment of pricedSegments; last as last" fxFlex="100">
                  <ng-container *ngIf="segment.status === 'PRICED'">
                    <hms-pricing-segment data-cy="pricing-segment" [pricingSegment]="segment" [createMode]="false" [contract]="contract" [availableQuantity]="availableQuantity" [unpricedQuantity]="unpricedQuantity" [commodityProfile]="profile" (pricingSegmentEvent)="onPricingSegmentEvent($event)"></hms-pricing-segment>
                    <mat-divider inset *ngIf="!last"></mat-divider>
                  </ng-container>
                </div>
                </div>
              </mat-expansion-panel>
            </ng-container>
            <ng-template #noSegments>
              <span class="top-row">Contract has no pricings</span>
            </ng-template>

          </div>
        </mat-tab>

        <mat-tab [disabled]="editMode || !pricingActionComplete" label="Orders" fxFlex="100">
          <div class="tab-body" fxLayout="row wrap" fxLayoutAlign="space-between space-between" fxFlex="100" fxLayoutGap="5">
            <span *ngIf="!contract.orderDocIds.length" class="top-row">Contract has no orders</span>
            <hms-orders *ngIf="contract.orderDocIds.length" (orderSearchError)="handleOrderListError($event)" [selectedOrders$]="orders$" [initialTableState]="{}" [contractOrdersMode]="true" fxFlex="100"></hms-orders>
          </div>
        </mat-tab>

        <mat-tab [disabled]="editMode || !pricingActionComplete" #historyTab label="History" fxFlex="100">
          <ng-template mat-tab-label>
            History <span class="beta-label">BETA</span>
          </ng-template>
          <div class="tab-body" fxLayout="row wrap" fxLayoutAlign="space-between space-between" fxFlex="100" fxLayoutGap="5">
            <span class="top-row mat-caption"><span class="beta-label inline">BETA</span>Beta features may be incomplete or change in the future.</span>
            <hms-contract-history *ngIf="contract && historyTab.isActive" class="top-row" [commodityMap]="commodityMap" [commodityProfile]="profile" [priceAdjustmentMap]="priceAdjustmentMap" [selectedClientDocId]="selectedClientDocId" [selectedContract]="contract" fxFlex="100"></hms-contract-history>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </mat-card>
</div>
