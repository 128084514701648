import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';

import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { LocationService } from '@advance-trading/angular-ops-data';
import {
  Client,
  CommodityProfile,
  CommodityProfileProductionYear,
  Contract,
  ContractType,
  HMSUserPreferences,
  Location,
  PricingSegment,
  Side
} from '@advance-trading/ops-data-lib';

import { BasisLogDisplay } from './basis-log-display';
import { ICON_MAP } from '../icon-map';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ContractService } from '../../service/contract.service';
import { PricingSegmentService } from '../../service/pricing-segment.service';
import { ExportService } from '../../service/export.service';
import { LedgerHelperService } from '../../service/ledger-helper.service';
import { UserPreferencesService } from '../../service/user-preferences.service';

type BasisLedgerColumns =
  'position' | 'timestamp' | 'basisType' | 'futuresYearMonth' | 'side' | 'quantity' |
  'OLD' | 'NEW' | 'NEW_PLUS_1' | 'NEW_PLUS_2' |
  'basisPrice' | 'futuresPrice' | 'cashPrice' | 'originatorName' | 'locationName' | 'patronDisplayName' | 'comments' | 'deleted';

@Component({
  selector: 'hms-basis-log',
  templateUrl: './basis-log.component.html',
  styleUrls: ['./basis-log.component.scss']
})
export class BasisLogComponent implements OnChanges, OnDestroy, OnInit, AfterViewInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  @Output() basisLogError = new EventEmitter<string>();
  @Output() loaded = new EventEmitter();

  @Input() commodityProfile: CommodityProfile;
  @Input() ledgerDate: string;
  @Input() ledgerEndOfDay: string;
  @Input() timezone: string;

  profileProdYearLabels: string[];
  activeProdYears: CommodityProfileProductionYear[];

  dataSource = new ObservableDataSource<BasisLogDisplay>();
  columnsToDisplay = [];
  lastIdxClicked = -1;

  displayCommentsText: boolean = false;

  /** A list that tracks all the rxjs subscriptions this component registers. */
  private subscriptions: Subscription[] = [];

  private startDate: string;
  private endDate: string;
  private translatedProductionYears: { [key: string]: CommodityProfileProductionYear };

  private useLiveLedgerColor = false;

  breakpointGroup: 'xs' | 'md' | 'lg' = 'lg';

  /**
   * A dictionary of strings based on flex shorthand value percentages.
   * Each value represents the _RELATIVE_ flex growth to _all other columns_
   * within a row.
   */
  private colFlex = {
    flex0: '0 0 0%',
    flex5: '.05 1 0%',
    flex10: '.1 1 0%',
    flex20: '.2 1 0%',
    flex30: '.3 1 0%',
    flex40: '.4 1 0%',
    flex50: '.5 1 0%',
    flex60: '.6 1 0%',
    flex70: '.7 1 0%',
    flex80: '.8 1 0%',
    flex90: '.9 1 0%',
    flex100: '1 1 0%',
    flex150: '1.5 1 0%',
    flex200: '2 1 0%',
    flex300: '3 1 0%',
    flex400: '4 1 0%',
    flex500: '5 1 0%',
    flex600: '6 1 0%',
  };

  private columnFlexData: { [key in keyof Record<BasisLedgerColumns, string>]: { [key in 'xs' | 'md' | 'lg']: string } } = {
    position: { xs: this.colFlex.flex90, md: this.colFlex.flex50, lg: this.colFlex.flex20 },
    timestamp: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex80 },
    basisType: { xs: this.colFlex.flex90, md: this.colFlex.flex50, lg: this.colFlex.flex60 },
    futuresYearMonth: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex90 },
    side: { xs: this.colFlex.flex90, md: this.colFlex.flex70, lg: this.colFlex.flex50 },
    quantity: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex60 },

    OLD: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex70 },
    NEW: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex70 },
    NEW_PLUS_1: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex70 },
    NEW_PLUS_2: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex70 },

    basisPrice: { xs: this.colFlex.flex90, md: this.colFlex.flex70, lg: this.colFlex.flex80 },
    futuresPrice: { xs: this.colFlex.flex90, md: this.colFlex.flex70, lg: this.colFlex.flex80 },
    cashPrice: { xs: this.colFlex.flex90, md: this.colFlex.flex70, lg: this.colFlex.flex80 },
    originatorName: { xs: this.colFlex.flex90, md: this.colFlex.flex90, lg: this.colFlex.flex90 },
    locationName: { xs: this.colFlex.flex90, md: this.colFlex.flex90, lg: this.colFlex.flex90 },
    patronDisplayName: { xs: this.colFlex.flex90, md: this.colFlex.flex90, lg: this.colFlex.flex150 },
    comments: { xs: this.colFlex.flex90, md: this.colFlex.flex80, lg: this.colFlex.flex300 },
    deleted: { xs: this.colFlex.flex0, md: this.colFlex.flex0, lg: this.colFlex.flex0 } // this column is visually hidden
  };

  /** A dictionary of columns to display at certain widths for the activity live ledger table. */
  private base_columns = {
    minimal: [
     'position', 'timestamp', 'basisType', 'futuresYearMonth', 'side', 'quantity', 'deleted',
    ],
    most: [
     'position', 'timestamp', 'basisType', 'futuresYearMonth', 'side', 'quantity',
     'basisPrice', 'futuresPrice', 'cashPrice', 'comments', 'deleted'
    ],
    all: [
     'position', 'timestamp', 'basisType', 'futuresYearMonth', 'side', 'quantity',
     'basisPrice', 'futuresPrice', 'cashPrice', 'originatorName', 'locationName', 'patronDisplayName', 'comments', 'deleted'
    ]
  };

  private columns = {
    minimal: [...this.base_columns.minimal],
    most: [...this.base_columns.most],
    all: [...this.base_columns.all]
  };

  constructor(
    private authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private clientSelectorService: ClientSelectorService,
    private contractService: ContractService,
    public exportService: ExportService,
    private ledgerHelperService: LedgerHelperService,
    private locationService: LocationService,
    private pricingSegmentService: PricingSegmentService,
    private router: Router,
    private userPreferencesSettingsService: UserPreferencesService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.setupBreakpointObserversForTableColumns();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges) {
    // get basis log based on which commodity profile is selected
    if (changes['commodityProfile'] || changes['ledgerDate']) {
      // get active production years
      this.activeProdYears = this.ledgerHelperService.getActiveProdYears(
        this.ledgerDate, this.commodityProfile.productionYears, this.ledgerEndOfDay, this.timezone);

      // get unique production years label
      this.profileProdYearLabels = Object.values(this.activeProdYears).map(prodYear => prodYear.label);

      // get translations of production years for display on items
      this.translatedProductionYears = this.ledgerHelperService.getTranslatedProductionYears(
        this.ledgerDate, this.commodityProfile.productionYears, this.ledgerEndOfDay, this.timezone);

      this.updateColumns();
      this.setupBreakpointObserversForTableColumns();

      let selectedClientDocId;
      this.dataSource.data$ = this.clientSelectorService.getSelectedClient().pipe(
        switchMap((selectedClient: Client) => {
          selectedClientDocId = selectedClient.docId;
          const userDocId = this.authService.userProfile.app_metadata.firestoreDocId;
          return this.userPreferencesSettingsService.getHmsPreferencesByUserDocId(userDocId);
        }),
        switchMap((hmsUserPreferences: HMSUserPreferences) => {
          if (hmsUserPreferences) {
            this.useLiveLedgerColor = hmsUserPreferences.useLiveLedgerColor;
          }
          const dateParams = this.ledgerHelperService.getDateParameters(this.ledgerDate);
          this.startDate = dateParams.startDate;
          this.endDate = dateParams.endDate;
          return this.getBasisLogObs(selectedClientDocId);

        }),
        tap(basisLogs => this.loaded.emit()),
        catchError(err => {
          if (err.name === 'MissingLedgerEndOfDayError') {
            this.basisLogError.emit(err.message);
          } else {
            this.basisLogError.emit('Error retrieving basis log; please try again later');
          }
          console.error(`Error retrieving basis log: ${err}`);
          return of([]);
        })
      );
    }
  }

  getFlexLayout = (columnName: BasisLedgerColumns): string => {
    if (!columnName) {
      console.error('no column name given, using default');
      return this.colFlex.flex100;
    }

    const flexValue = this.columnFlexData[columnName][this.breakpointGroup];
    if (!flexValue) {
      console.error('could not locate column name/breakpoint, using default', flexValue);
    }

    return flexValue || this.colFlex.flex100;
  }

  getIconName(type: string) {
    return ICON_MAP[type];
  }

  insertArrayAt(array, index, arrayToInsert) {
    Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
  }

  updateColumns() {
    // Create a true copy of the base columns to insert prodYearLabels in to
    this.columns = {
      minimal: [...this.base_columns.minimal],
      most: [...this.base_columns.most],
      all: [...this.base_columns.all]
    };

    this.insertArrayAt(this.columns.all, this.columns.all.indexOf('quantity') + 1, this.profileProdYearLabels);
  }

  selectBasisLog(basisLog: BasisLogDisplay) {
    if (basisLog.segmentDocId) {
      this.router.navigate([ '/contracts', basisLog.docId ], { queryParams: { segmentId: basisLog.segmentDocId }, queryParamsHandling: 'merge' });
    } else {
      this.router.navigate([ '/contracts', basisLog.docId ]);
    }
  }

  getTypeNgClass(basisLog: BasisLogDisplay) {
    const typeNgClass = {};
    if (this.useLiveLedgerColor) {
      typeNgClass['type-icon'] = true;

      if (basisLog.basisType === ContractType.BASIS) {
        typeNgClass['basis-contract-or-pricing'] = true;
      } else if (basisLog.basisType === ContractType.HTA) {
        typeNgClass['hta-contract-or-pricing'] = true;
      } else if (basisLog.basisType === ContractType.DP) {
        typeNgClass['dp-pricing'] = true;
      }
    }

    return typeNgClass;
  }

    /** Sets up breakpoint observers for the browser window, which push which columns to display at different widths of the window.*/
    private setupBreakpointObserversForTableColumns(): void {
      this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
      this.subscriptions.push(this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(state => {
        if (state.breakpoints[Breakpoints.XSmall]) {
          this.columnsToDisplay = this.columns.minimal;
          this.breakpointGroup = 'xs';
          this.displayCommentsText = false;
        } else if (state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.Medium]) {
          this.columnsToDisplay = this.columns.most;
          this.displayCommentsText = false;
          this.breakpointGroup = 'md';
        } else if (state.breakpoints[Breakpoints.Large] || state.breakpoints[Breakpoints.XLarge]) {
          this.columnsToDisplay = this.columns.all;
          this.displayCommentsText = true;
          this.breakpointGroup = 'lg';
        }
        this.changeDetector.detectChanges();
      }));
    }


  // this method get all well formatted basis log
  private getBasisLogObs(clientDocId: string): Observable<BasisLogDisplay[]> {
    return combineLatest([
      this.getContractsObs(clientDocId),
      this.getSegmentsObs(clientDocId)
    ]).pipe(
      map(([contracts, segments]) => {
        // combine all pricing events
        let logs: BasisLogDisplay[] = contracts.concat(segments);

        // sort basis logs by creationTimestamp in descending order
        logs = logs.sort((firstLog, secondLog) => secondLog.timestamp.localeCompare(firstLog.timestamp));

        // set position number in descending order
        logs.map((log, index) => log.position = logs.length - index);

        // calculate accumulating basis total quantity and put it in the display
        const currBasisTotal = {};
        logs.slice().reverse().forEach(log => {
          log.prodYears = {};
          if (!log.deletionTimestamp) {
            const basisTotalLabel = log.basisTotalLabel;

            // add quantity if side is a buy, subtract otherwise
            if (currBasisTotal[basisTotalLabel]) {
              currBasisTotal[basisTotalLabel] += log.side === Side.BUY ? log.quantity : -log.quantity;
            } else {
              currBasisTotal[basisTotalLabel] = log.side === Side.BUY ? log.quantity : -log.quantity;
            }

            log.prodYears[basisTotalLabel] = currBasisTotal[basisTotalLabel];
          }
        });

        return logs;
      })
    );
  }

  private getContractsObs(clientDocId: string): Observable<BasisLogDisplay[]> {
    return this.getCashAndBasisPricingEventContractsObs(clientDocId).pipe(
      map((contracts: Contract[]) => {
        return contracts.map((contract: Contract) => {
          const basisType = contract.isSpot ? 'SPOT' : contract.type;

          return {
            basisType,
            timestamp: contract.isExchange ? contract.exchangeTimestamp : contract.basisLockedTimestamp,
            side: contract.side,
            basisTotalLabel: this.translatedProductionYears[contract.productionYear].label,
            futuresYearMonth: contract.futuresYearMonth,
            quantity: contract.quantity,
            basisPrice: contract.basisPrice,
            futuresPrice: contract.futuresPrice,
            cashPrice: contract.cashPrice,
            originatorName: contract.originatorName,
            locationName: contract.deliveryLocationName,
            patronName: contract.patronName,
            patronAccountingSystemId: contract.patronAccountingSystemId,
            comments: contract.comments,
            docId: contract.docId,
            isPricingSegment: false,
            deletionTimestamp: contract.deletionTimestamp,
            originalQuantity: contract.originalQuantity
          } as BasisLogDisplay;
        });
      })
    );
  }

  private getCashAndBasisPricingEventContractsObs(clientDocId: string): Observable<Contract[]> {
    return this.contractService.getContractsByTypeCommodityProfileAndBasisLockedTimestamp(clientDocId,
                                                                  [ContractType.CASH, ContractType.BASIS],
                                                                  this.commodityProfile.docId, this.startDate, this.endDate);
  }

  private getSegmentsObs(clientDocId: string): Observable<BasisLogDisplay[]> {
    return this.getHtaAndDpBasisLockedPricingEventSegmentsObs(clientDocId).pipe(
      switchMap((htaAndDpBasisSegments: PricingSegment[]) => {
        if (htaAndDpBasisSegments.length === 0) {
          return of([]);
        }

        return combineLatest(
          htaAndDpBasisSegments.map((segment: PricingSegment) =>  {
            return this.contractService.getContractByDocId(clientDocId, segment.contractDocId).pipe(
              map((contract: Contract) => {
                return {
                  basisType: segment.contractType,
                  timestamp: segment.basisLockedTimestamp,
                  side: segment.side,
                  basisTotalLabel: this.translatedProductionYears[contract.productionYear].label,
                  futuresYearMonth: segment.futuresYearMonth,
                  quantity: segment.quantity,
                  basisPrice: segment.basisPrice,
                  futuresPrice: segment.futuresPrice,
                  cashPrice: segment.cashPrice,
                  originatorName: segment.originatorName,
                  locationDocId: segment.deliveryLocationDocId,
                  docId: segment.contractDocId,
                  contractDocId: segment.contractDocId,
                  isPricingSegment: true,
                  patronName: contract.patronName,
                  patronAccountingSystemId: contract.patronAccountingSystemId,
                  comments: contract.comments,
                  deletionTimestamp: segment.deletionTimestamp,
                  segmentDocId: segment.docId,
                  originalQuantity: segment.originalQuantity
                } as BasisLogDisplay;
              })
            );
          })
        );
      }),
      switchMap((segments: BasisLogDisplay[]) => {
        if (segments.length === 0) {
          return of([]);
        }

        return combineLatest(
          segments.map((segment: BasisLogDisplay) =>  {
            return this.locationService.getLocationByDocId(clientDocId, segment.locationDocId).pipe(
              map((location: Location) => {
                return {
                  ...segment,
                  locationName: location.name
                } as BasisLogDisplay;
              })
            );
          })
        );
      })
    );
  }

  private getHtaAndDpBasisLockedPricingEventSegmentsObs(clientDocId: string): Observable<PricingSegment[]> {
    return this.pricingSegmentService.findPricingSegmentsByTypeCommodityProfileAndBasisLockedTimestamp(clientDocId,
                                                                              [ContractType.HTA, ContractType.DP],
                                                                              this.commodityProfile.docId,
                                                                              this.startDate, this.endDate);
  }

}
