<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
      <form [formGroup]="basisLedgerForm">
        <div *ngIf="!errorMessage" fxLayout.gt-xs="row" fxLayout.xs="row wrap" fxLayoutAlign.gt-xs="space-between center" fxLayoutAlign.xs="start start">
          <div fxLayout="row" fxLayoutAlign="start baseline">
            <h2 data-cy="page-title-basis-ledger" class="card-title">Basis Ledger</h2>
            <button id="basis-ledger-go-live" *ngIf="selectedDate && !isLedgerLive && !isLoading" mat-button type="button" color="accent" (click)="goLive()">GO LIVE</button>
            <mat-spinner *ngIf="isLoading" class="loading-indicator" diameter="32" color="accent"></mat-spinner>
          </div>
          <div fxLayout="row" fxLayoutAlign="start baseline" *ngIf="ledgerEndOfDay$ | async" fxFlex.xs="100">
            <mat-form-field fxFlex.xs="100">
              <input matInput required [matDatepicker]="ledgerDatePicker" placeholder="Ledger Date" formControlName="ledgerDate" [max]="currentBusinessDay" (keydown)="listenForEnterOrTab($event)">
              <mat-datepicker-toggle matSuffix [for]="ledgerDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #ledgerDatePicker (closed)="onDatePickerClosed()"></mat-datepicker>
              <mat-error *ngIf="basisLedgerForm.get('ledgerDate').invalid">{{ getErrorMessage(basisLedgerForm.get('ledgerDate')) }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <hms-error-message [title]="'Error Retrieving Basis Ledger'" [message]="errorMessage"></hms-error-message>

        <div *ngIf="!errorMessage">
          <div fxLayout.gt-xs="row wrap" fxLayoutAlign.gt-xs="space-between center" fxLayout.xs="column">
            <mat-form-field fxFlex.gt-xs="45">
              <mat-select required placeholder="Commodity Profile" formControlName="commodityProfile" [compareWith]="compareCommodityProfile">
                <mat-option *ngFor="let profile of commodityProfiles" [value]="profile">{{ profile.name }}</mat-option>
              </mat-select>
              <mat-error *ngIf="basisLedgerForm.get('commodityProfile').invalid">{{ getErrorMessage(basisLedgerForm.get('commodityProfile')) }}</mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="row">
            <hms-basis-summary fxFlex.xs="100" fxFlex.gt-xs="45" fxFlex.gt-md="35" *ngIf="selectedProfile && isLedgerLive" [commodityProfile]="selectedProfile" (loaded)="onBasisSummaryLoaded()" (basisSummaryError)="onBasisSummaryError($event)"></hms-basis-summary>
          </div>
          <hms-basis-log *ngIf="selectedDate && selectedProfile" [commodityProfile]="selectedProfile" (loaded)="onBasisLogLoaded()" (basisLogError)="onBasisLogError($event)" [ledgerDate]="selectedDate" [ledgerEndOfDay]="ledgerEndOfDay" [timezone]="timezone"></hms-basis-log>
        </div>
      </form>
    </mat-card>
  </div>
</div>
