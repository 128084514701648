import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { AlertStatus, AppAlert } from '@advance-trading/ops-data-lib';

import { AppAlertService } from '../../service/app-alert.service';
import { ClientSelectorService } from '../../service/client-selector.service';

import { ALERT_USER_ROLES } from '../../utilities/user-roles';

const DEFAULT_START_DATE = new Date('1/1/2000').toISOString();
const DEFAULT_END_DATE = new Date('12/31/2099').toISOString();
const HMS = 'HMS';

@Component({
  selector: 'hms-alert-search',
  templateUrl: './alert-search.component.html',
  styleUrls: ['./alert-search.component.scss']
})
export class AlertSearchComponent implements OnInit {
  alertSearchForm: FormGroup = this.formBuilder.group({
    title: [''],
    status: [''],
    startDate: ['', { updateOn: 'blur' }],
    endDate: ['', { updateOn: 'blur' }]
  });

  alertStatuses = Object.keys(AlertStatus);

  showAlerts = false;
  selectedAlerts$: Observable<AppAlert[]>;

  isSearching = false;

  errorMessage: string;

  private userAlertRoles: string[] = [];

  constructor(
    private alertService: AppAlertService,
    private authzService: Auth0AuthzService,
    private changeDetector: ChangeDetectorRef,
    private clientSelectorService: ClientSelectorService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.userAlertRoles = this.getUserAlertRecipientRoles();
    if (this.userAlertRoles.length === 0) {
      this.errorMessage = 'You do not have permission to search alerts.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }
  }

  reset() {
    this.alertSearchForm.reset();
    this.alertSearchForm.markAsPristine();
  }

  searchAlerts() {
    this.showAlerts = false;
    this.changeDetector.detectChanges();
    this.selectedAlerts$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap(selectedClient => {
        return this.chooseQuery(selectedClient.docId);
      })
    );

    this.showAlerts = true;
    this.changeDetector.detectChanges();
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMax')) {
      return 'Value Invalid';
    }
    return 'Unknown Error';
  }

  handleAlertListError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
  }

  private chooseQuery(clientDocId: string): Observable<AppAlert[]> {
    const titleValue = this.alertSearchForm.get('title').value;
    const status = this.alertSearchForm.get('status').value;
    let startDate = this.alertSearchForm.get('startDate').value;
    let endDate = this.alertSearchForm.get('endDate').value;
    const hasDate = startDate || endDate;

    // Set default date or the date entered by the user
    if (startDate) {
      startDate = new Date(startDate).toISOString();
    } else {
      startDate = DEFAULT_START_DATE;
    }

    if (endDate) {
      endDate = new Date(endDate);
      // Allow alert dated on this day before midnight
      endDate.setHours(23, 59, 59, 999);
      endDate = endDate.toISOString();
    } else {
      endDate = DEFAULT_END_DATE;
    }

    // three search options selected
    if (titleValue && status && hasDate) {
      return this.alertService.getClientAppAlertsByTitleStatusAndDate(
        clientDocId, titleValue, status, startDate, endDate, HMS, this.userAlertRoles);
      // two search options selected
    } else if (titleValue && status) {
      return this.alertService.getClientAppAlertsByTitleAndStatus(clientDocId, titleValue, status, HMS, this.userAlertRoles);
    } else if (titleValue && hasDate) {
      return this.alertService.getClientAppAlertsByTitleAndDate(clientDocId, titleValue, startDate, endDate, HMS, this.userAlertRoles);
    } else if (status && hasDate) {
      return this.alertService.getClientAppAlertsByStatusAndDate(clientDocId, status, startDate, endDate, HMS, this.userAlertRoles);
      // one search option selected
    } else if (titleValue) {
      return this.alertService.getClientAppAlertsByTitle(clientDocId, titleValue, HMS, this.userAlertRoles);
    } else if (status) {
      return this.alertService.getClientAppAlertsByStatus(clientDocId, status, HMS, this.userAlertRoles);
    } else if (hasDate) {
      return this.alertService.getClientAppAlertsByDate(clientDocId, startDate, endDate, HMS, this.userAlertRoles);
      // no search options selected
    } else {
      return this.alertService.getClientAppAlerts(clientDocId, HMS, this.userAlertRoles);
    }
  }

  private getUserAlertRecipientRoles() {
    const userRoles: string[] = [];
    ALERT_USER_ROLES.forEach(role => {
      if (this.authzService.currentUserHasRole(role)) {
        userRoles.push(role);
      }
    });
    return userRoles;
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

}
