<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <hms-error-message [title]="'Error Retrieving Users List'" [message]="errorMessage"></hms-error-message>
    <div *ngIf="!errorMessage" fxLayout="column">
      <h2 data-cy="page-title-users" class="card-title">Users</h2>
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" #filter>
        <button type="button" id="clear-users-filter" mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="75" fxFlex.gt-xs="25">Name</mat-header-cell>
          <mat-cell *matCellDef="let user" fxFlex.xs="75" fxFlex.gt-xs="25">{{ user.firstName }} {{ user.lastName }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="40">Email</mat-header-cell>
          <mat-cell *matCellDef="let user" fxFlex="40">{{ user.email }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="20">Type</mat-header-cell>
          <mat-cell *matCellDef="let user" fxFlex.gt-xs="20">{{ user.type | titlecase }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="25" fxFlex.gt-xs="15">Status</mat-header-cell>
          <mat-cell *matCellDef="let user" fxFlex.xs="25" fxFlex.gt-xs="15">{{ user.status | titlecase }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
        <mat-row (click)="selectUser(user)" *matRowDef="let user; columns: columnsToDisplay"></mat-row>
      </mat-table>
      <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50]"></mat-paginator>
    </div>
  </mat-card>
</div>
