import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { CommodityProfileService, OperationsDataService } from '@advance-trading/angular-ops-data';
import { Client, Commodity, CommodityMap, CommodityProfile } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { UserRoles } from '../../utilities/user-roles';

interface ExtendedCommodityProfile extends CommodityProfile {
  commodityName?: string;
}

@Component({
  selector: 'hms-commodity-profiles',
  templateUrl: './commodity-profiles.component.html',
  styleUrls: ['./commodity-profiles.component.scss']
})
export class CommodityProfilesComponent implements AfterViewInit, OnInit {
  columnsToDisplay = [];
  errorMessage: string;
  dataSource = new ObservableDataSource<ExtendedCommodityProfile>();
  filterValue = new FormControl();
  isLoading = true;

  private commodityMap: { [key: string]: Commodity; };

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private authzService: Auth0AuthzService,
    private breakpointObserver: BreakpointObserver,
    private changeDetector: ChangeDetectorRef,
    private clientSelectorService: ClientSelectorService,
    private operationsDataService: OperationsDataService,
    private commodityProfileService: CommodityProfileService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.XSmall])
      .subscribe(state => {
        // display only name, active, and basis columns for xsmall screens
        if (state.matches) {
          this.columnsToDisplay = [
            'name', 'isActive'
          ];
          // display name, active, branch and basis for larger screens
        } else {
          this.columnsToDisplay = [
            'name', 'accountNumber', 'commodityName', 'isActive', 'isSpreadProfile'
          ];
        }
      });

    this.dataSource.data$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((doc: CommodityMap) => {
        this.commodityMap = doc.commodities;
        return this.clientSelectorService.getSelectedClient();
      }),
      switchMap((selectedClient: Client) => {
        return this.commodityProfileService.getAllCommodityProfilesByClientDocId(selectedClient.docId);
      }),
      map((commodityProfiles: CommodityProfile[]) => {
        this.isLoading = false;
        return commodityProfiles.map((commodityProfile: CommodityProfile) => {
          return {
            ...commodityProfile,
            commodityName: this.getCommodityName(commodityProfile.commodityId),
          } as ExtendedCommodityProfile;
        });
      }),
      catchError(err => {
        this.isLoading = false;
        this.errorMessage = 'Error retrieving commodity profiles; please try again later';
        console.error(`Error retrieving commodity profiles: ${err}`);
        return of([]);
      })
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.filter.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  get isCommodityProfileAdmin() {
    return this.authzService.currentUserHasRole(UserRoles.COMMODITY_PROFILE_ADMIN_ROLE);
  }

  addCommodityProfile() {
    this.router.navigate(['./new'], { relativeTo: this.route });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectCommodityProfile(commodityProfile: CommodityProfile) {
    this.router.navigate(['./', commodityProfile.docId], { relativeTo: this.route });
  }

  private getCommodityName(commodityId: string): string {
    const commodity = this.commodityMap[commodityId];
    return commodity ? `${commodity.name} (${commodity.id})` : '';
  }

}
