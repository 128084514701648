import { ContractStatus } from '@advance-trading/ops-data-lib/ops/clients/ContractStatus';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

/**
 * Simple status list used to generate status checkboxes for the simple/basic search fields.
 */
enum BasicStatus {
  /** Status: Complete */
  COMPLETE = 'COMPLETE',
  /** Status: Any value with `pending` in the text. */
  PENDING = 'PENDING',
  /** Status: Any value with `working` in the text. */
  WORKING = 'WORKING',
}

/** Provides a simple interface for objects to track checkbox states and values. */
interface IStatusCheckbox {
  checked: boolean;
  value: ContractStatus | BasicStatus;
}

@Component({
  selector: 'hms-contract-status',
  templateUrl: './contract-status.component.html',
  styleUrls: ['./contract-status.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ContractStatusComponent
    }
  ]
})
export class ContractStatusComponent implements OnInit, ControlValueAccessor {

  /** A complete list of all statuses that a person can select. */
  private advancedStatusList: ContractStatus[] = Object.values(ContractStatus);

  /** A complete list of all basic statuses that a person can select. */
  private basicStatusList: BasicStatus[] = Object.values(BasicStatus);

  /** A list of contract status items that fall under the `Pending` category. */
  private pendingStatusItems: ContractStatus[] = [
    ContractStatus.PENDING_APPROVAL,
    ContractStatus.PENDING_BASIS,
    ContractStatus.PENDING_EXCHANGE,
    ContractStatus.PENDING_FUTURES,
    ContractStatus.PENDING_ORDER,
    ContractStatus.PENDING_ORDER_CANCEL,
    ContractStatus.PENDING_ORDER_RECREATE,
    ContractStatus.PENDING_ORDER_UPDATE,
    ContractStatus.PENDING_PRICE,
  ];

  /** A list of contract status items that fall under the `Working` category. */
  private workingStatusItems: ContractStatus[] = [
    ContractStatus.WORKING_BASIS,
    ContractStatus.WORKING_CASH,
    ContractStatus.WORKING_EXCHANGE,
    ContractStatus.WORKING_FUTURES,
    ContractStatus.WORKING_PRICE
  ];

  /** A dictionary of phrases for element titles shown on hover. */
  tooltips = {
    delay: 1000,
    buttons: {
      basic: 'A simpler set of statuses to search against',
      advanced: 'A complete list of all statuses that can be searched against',
      clear: 'Clear all selected status checkboxes'
    },
    checkboxes: {
      complete: `Search against contracts that have a 'Complete' status`,
      pending: `Search against contracts that have the 'Pending' type status`,
      working: `Search against contracts that have the 'Working' type status`,
    }
  };

  /** If this control has been 'touched' or not. */
  touched = false;

  /** If this control is disabled or not. Disables all checkboxes and buttons. */
  disabled = false;

  /** Private value determining whether to show the advance list if `true`, otherwise the simple list. */
  @Input() displayAdvancedListValue = false;

  /** Holds the internal reference to the selected statuses for this component. */
  private selectedStatusesValue: ContractStatus[] = [];

  /** A list of objects that track the state of each checkbox under the 'advance' section. */
  advancedCheckboxes: IStatusCheckbox[] = [];

  /** A list of objects that track the state of each checkbox under the 'basic' section. */
  basicCheckBoxes: IStatusCheckbox[] = [];

  /* Event emitter when the status changes */
  @Output() statusChanges: EventEmitter<{statuses: ContractStatus[], searchLevel: string}> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.basicStatusList.sort();

    Object.values(this.advancedStatusList).forEach((status) => {
      this.advancedCheckboxes.push({
        checked: false,
        value: status,
      });
    });

    Object.values(this.basicStatusList).forEach((status) => {
      this.basicCheckBoxes.push({
        checked: false,
        value: status,
      });
    });
  }

  /** The list of items that is currently being displayed. */
  get statusList(): ContractStatus[] | BasicStatus[] {
    return this.displayAdvancedListValue ? this.advancedStatusList : this.basicStatusList;
  }

  /** The current selected/checked statuses in the component. */
  get selectedStatuses(): ContractStatus[] {
    return this.selectedStatusesValue;
  }

  set selectedStatuses(newStatuses: ContractStatus[]) {
    if (!newStatuses) {
      this.selectedStatuses = [];
    } else {
      this.selectedStatusesValue = newStatuses;
    }
  }

  /** Handle the onChange event for this custom form control. */
  onChange = (items: ContractStatus[]) => { };

  /** Handle the onTouched event for this custom form control. */
  onTouched = () => { };

  /** Handle setup of the onTouched callback function. */
  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }

  /** Handle setup of the onChange callback function. */
  registerOnChange(onChange: (items: ContractStatus[]) => {}): void {
    this.onChange = onChange;
  }

  /** Handle setting the disabled state of this control from a parent form. */
  setDisabledState(disabled: boolean): void {
    this.clearStatusSelection(null);
    this.disabled = disabled;
  }

  /** Sets the 'touched' value to true for this component. */
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  updateLevelSelection(group: any): void {
    const level: string = group.value ? 'Advanced' : 'Basic';
    this.displayAdvancedListValue = group.value
    this.statusChanges.emit({ statuses: this.selectedStatuses, searchLevel: level});
  }

  /**
   * Handles the writeValue set by the parent form to this child control.
   * @param items A list of `ContractStatus` items to set as the currently selected items.
   */
  writeValue(items: ContractStatus[]): void {
    if (items) {
      if (items.length) {
        this.setAdvancedCheckboxValues(items, true);
      } else {
        this.massChangeAllCheckboxes(false);
      }
    } else {
      this.massChangeAllCheckboxes(false);
    }
  }

  /**
   * Clears all status selections.
   * @param event The event passed to this handler.
   */
  clearStatusSelection(event: MouseEvent): void {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    this.massChangeAllCheckboxes(false);
    this.selectedStatuses = [];
    this.displayAdvancedListValue = false;
    this.statusChanges.emit({ statuses: this.selectedStatuses, searchLevel: 'Basic'});
  }

  /**
   * Handles the checkbox change event for the advanced checkboxes.
   * @param event The simple checkbox that triggered this event handler.
   * @param checkbox The object that is tracking the changes for this checkbox.
   */
  onAdvancedCheckboxChange(event: MatCheckboxChange, checkbox: IStatusCheckbox): void {
    const pending = this.basicCheckBoxes.find(bBox => bBox.value === BasicStatus.PENDING);
    const working = this.basicCheckBoxes.find(bBox => bBox.value === BasicStatus.WORKING);
    if (pending.checked) { pending.checked = false; }
    if (working.checked) { working.checked = false; }

    const statusValue: ContractStatus = ContractStatus[checkbox.value];

    if (statusValue) {
      if (event.checked) {
        this.selectedStatuses = this.buildStatusItems([statusValue]);
        this.updateCheckboxValues(true, [statusValue]);
      } else {
        this.selectedStatuses = this.selectedStatuses.filter(status => status !== checkbox.value);
        this.updateCheckboxValues(false, [statusValue]);
      }
    }

    this.updateBasicStatusGroups();
    this.markAsTouched();
    this.statusChanges.emit({ statuses: this.selectedStatuses, searchLevel: 'Advanced'});
  }

  /**
   * Handles the simple checkbox change event. These must be handled differently as they trigger
   * multiple checkbox changes.
   * @param event The simple checkbox that triggered this event handler.
   * @param checkbox The object that is tracking the changes for this checkbox.
   */
  onBasicCheckboxChange(event: MatCheckboxChange, checkbox: IStatusCheckbox): void {
    let pending = this.basicCheckBoxes.find(bBox => bBox.value === BasicStatus.PENDING).checked;
    let working = this.basicCheckBoxes.find(bBox => bBox.value === BasicStatus.WORKING).checked;
    let complete = this.basicCheckBoxes.find(bBox => bBox.value === ContractStatus.COMPLETE).checked;
    this.clearStatusSelection(null);

    if (event.checked) {
      switch (checkbox.value) {
        case BasicStatus.WORKING:
          this.setWorkingStatusItems(true);
          break;
        case BasicStatus.PENDING:
          this.setPendingStatusItems(true);
          break;
        case BasicStatus.COMPLETE:
          this.setCompleteStatusItems(true);
          break;
      }
    } else {
      switch (checkbox.value) {
        case BasicStatus.WORKING:
          this.setWorkingStatusItems(false);
          working = !working; // checked before, now unchecked, don't update
          break;
        case BasicStatus.PENDING:
          this.setPendingStatusItems(false);
          pending = !pending; // checked before, now unchecked, don't update
          break;
        case BasicStatus.COMPLETE:
          this.setCompleteStatusItems(false);
          complete = !complete; // checked before, now unchecked, don't update
          break;
      }
    }
    this.markAsTouched();
    this.massChangeBasicCheckboxes(working, pending, complete);
    this.statusChanges.emit({ statuses: this.selectedStatuses, searchLevel: 'Basic'});
  }

  /**
   * Updates/sets checked state for the basic group checkbox, if all status groups are checked
   * for the respective group in the advanced section.
   */
  private updateBasicStatusGroups(): void {
    const statusCheck = this.getStatusGroupsCheckedState();
    if (statusCheck.allPendingChecked) {
      this.setBasicCheckbox(true, BasicStatus.PENDING);
    }

    if (statusCheck.allWorkingChecked) {
      this.setBasicCheckbox(true, BasicStatus.WORKING);
    }
  }

  /**
   * Provides the correct unique item list for handling both the basic and advanced list items.
   * @param statusListItemsToAdd The items to uniquely add in the current status list.
   * @returns A combined, unique, list of items to have set as the values selected.
   */
  private buildStatusItems(statusListItemsToAdd: ContractStatus[]): ContractStatus[] {
    if (!statusListItemsToAdd) { return []; }

    const returnedArray = this.selectedStatuses.filter(x => !statusListItemsToAdd.includes(x))
      .concat(statusListItemsToAdd.filter(x => !this.selectedStatuses.includes(x)));
    return returnedArray;
  }

  /**
   * Helper function that checks what statuses are checked for the `working` group and the
   * `pending` group.
   * @returns An object that has the boolean values for the checked status groups.
   */
  private getStatusGroupsCheckedState(): { allPendingChecked: boolean, allWorkingChecked: boolean } {
    const pendingStatuses = this.selectedStatuses.filter(status => status.toLocaleLowerCase().startsWith('pending'));
    const workingStatus = this.selectedStatuses.filter(status => status.toLocaleLowerCase().startsWith('working'));

    const pendingCheck = pendingStatuses.every(status => this.pendingStatusItems.includes(status))
      && this.pendingStatusItems.every(status => pendingStatuses.includes(status));

    const workingCheck = workingStatus.every(status => this.workingStatusItems.includes(status))
      && this.workingStatusItems.every(status => workingStatus.includes(status));

    return { allPendingChecked: pendingCheck, allWorkingChecked: workingCheck };
  }

  /**
   * Mass update the checked status of the basic checkbox items.
   * @param working The working checkbox status
   * @param pending The pending checkbox status
   * @param complete The complete checkbox status
   */
  private massChangeBasicCheckboxes(working: boolean, pending: boolean, complete: boolean): void {
    if (working) { this.setWorkingStatusItems(working); }
    if (pending) { this.setPendingStatusItems(pending); }
    if (complete) { this.setCompleteStatusItems(complete); }
    this.onChange(this.selectedStatuses);
  }

  /**
   * Mass change ALL checkboxes to the specified value. Updates the selected statuses accordingly.
   * @param value Checked (true) / Unchecked (false)
   */
  private massChangeAllCheckboxes(value: boolean): void {
    const statuses: ContractStatus[] = [];

    this.basicCheckBoxes.forEach(checkbox => {
      checkbox.checked = value;
      if (value) { statuses.push(checkbox.value as ContractStatus); }
    });

    this.advancedCheckboxes.forEach(checkbox => {
      checkbox.checked = value;
    });

    this.selectedStatuses = statuses;
    this.onChange(this.selectedStatuses);
  }

  /**
   * Removes status items that found in both the passed in array and the current value of `selectedStatuses`.
   * @param statusItemsToRemove The items to remove if they are found in `selectedStatuses`.
   * @returns A list of unique items qualified as a "left outer join" between both arrays.
   */
  private removeStatusItems(statusItemsToRemove: ContractStatus[]): ContractStatus[] {
    return this.selectedStatuses.filter((status) => statusItemsToRemove.includes(status));
  }

  /**
   * Updates the selected statuses and checkbox values to include or remove
   * the full set of "working" type status.
   * @param checked The new checked status for the working checkboxes.
   */
  private setWorkingStatusItems(checked: boolean): void {
    if (checked) {
      this.selectedStatuses = this.buildStatusItems(this.workingStatusItems);
    } else {
      this.selectedStatuses = this.removeStatusItems(this.workingStatusItems);
    }
    this.updateCheckboxValues(checked, this.workingStatusItems, BasicStatus.WORKING);
  }

  /**
   * Updates the selected statuses and checkbox values to include or remove
   * the full set of "pending" type status.
   * @param checked The new checked status for the pending checkboxes.
   */
  private setPendingStatusItems(checked: boolean): void {
    if (checked) {
      this.selectedStatuses = this.buildStatusItems(this.pendingStatusItems);
    } else {
      this.selectedStatuses = this.removeStatusItems(this.pendingStatusItems);
    }
    this.updateCheckboxValues(checked, this.pendingStatusItems, BasicStatus.PENDING);
  }

  /**
   * Updates the selected statuses and checkbox values to include or remove
   * the complete status.
   * @param checked The new checked status for the complete checkbox.
   */
  private setCompleteStatusItems(checked: boolean): void {
    if (checked) {
      this.selectedStatuses = this.buildStatusItems([ContractStatus.COMPLETE]);
    } else {
      this.selectedStatuses = this.removeStatusItems([ContractStatus.COMPLETE]);
    }
    this.updateCheckboxValues(checked, [ContractStatus.COMPLETE]);
  }

  /**
   * This safely builds and mass sets all the contract status checkboxes passed at the same time.
   * @param checkboxes The status checkboxes to set.
   * @param value The checkbox value to set.
   */
  private setAdvancedCheckboxValues(checkboxes: ContractStatus[], value: boolean) {
    if (checkboxes.length && value !== undefined) {
      this.selectedStatuses = this.buildStatusItems(checkboxes);
      this.updateCheckboxValues(value, this.selectedStatuses);
      this.updateBasicStatusGroups();
    }
  }

  /**
   * A helper function that sets checked state of the internally tracked list of
   * checkboxes. Does not emit an onChanges() event. Should be used with statuses
   * like 'pending' and 'working' only.
   * @param checked The new checked state.
   * @param checkbox The basic checkbox to update.
   */
  private setBasicCheckbox(checked: boolean, checkbox: BasicStatus): void {
    const basicBox = this.basicCheckBoxes.find((cBox => cBox.value === checkbox));
    if (basicBox) {
      basicBox.checked = checked;
    }
    this.markAsTouched();
  }

  /**
   * Helper function that updates the checkbox values for the given status list.
   * @param checked The new checked state of the checkbox.
   * @param statusList The list of statuses that will be checked against.
   * @param basicStatus When calling this helper function, this value will make sure values not in ContractStatus Enum
   * but are still a checkbox (I.e. the `Pending` checkbox), gets updated accordingly.
   */
  private updateCheckboxValues(checked: boolean, statusList: ContractStatus[], basicStatus: BasicStatus = null): void {
    statusList.forEach((status) => {
      if (basicStatus) {
        const basicBox = this.basicCheckBoxes.find((checkbox => checkbox.value === basicStatus));
        if (basicBox) {
          basicBox.checked = checked;
        }
      } else {
        const basicBox = this.basicCheckBoxes.find((checkbox => checkbox.value === status));
        if (basicBox) {
          basicBox.checked = checked;
        }
      }

      const advancedBox = this.advancedCheckboxes.find((checkbox => checkbox.value === status));
      if (advancedBox) {
        advancedBox.checked = checked;
      }
    });

    this.markAsTouched();
    this.onChange(this.selectedStatuses);
  }
}
