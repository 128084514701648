import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { CommodityProfileService, LocationService } from '@advance-trading/angular-ops-data';
import {
  Client,
  CommodityProfile,
  Contract,
  ContractMonth,
  ContractStatus,
  ContractType,
  Location,
  PricingSegment
} from '@advance-trading/ops-data-lib';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ContractService } from '../../service/contract.service';
import { ExportService } from '../../service/export.service';
import { PricingSegmentService } from '../../service/pricing-segment.service';

import { FutureDeliveryDisplay } from './future-delivery-display';
import { FutureDeliveryReportableItem } from './future-delivery-reportable-item';
import { FutureDeliveryMiniDisplay } from './future-delivery-mini-display';
import { UserRoles } from '../../utilities/user-roles';

const YEAR_FORMAT = 'YY';
const SELECTION_YEAR_PERIOD = 5;

enum MONTH_CODE_DICT {
  F,
  G,
  H,
  J,
  K,
  M,
  N,
  Q,
  U,
  V,
  X,
  Z
}

@Component({
  selector: 'hms-future-delivery',
  templateUrl: './future-delivery.component.html',
  styleUrls: ['./future-delivery.component.scss']
})
export class FutureDeliveryComponent implements OnInit {
  futureDeliverySearchForm: FormGroup = this.formBuilder.group({
    deliveryPeriods: [[]],
    commodityProfiles: [[]]
  });

  profileSummaryParentHeaders: string[];
  profileSummaryColumnsToDisplay: string[];
  profileSummaryFooterColumnsToDisplay = ['footer'];

  clientParentHeaders: string[];
  clientColumnsToDisplay: string[];
  clientFooterColumnsToDisplay = ['footer'];

  clientMiniColumnsToDisplay: string[] = ['commodityProfile', 'contractType', 'UNPRICED', 'PRICED'];

  deliveryColumns: string[] = [ContractType.CASH, ContractType.BASIS, ContractType.HTA, 'TOTAL'];

  errorMessage: string;
  isLoading = true;
  showMini = false;
  displayReports = false;

  deliveries$: Observable<FutureDeliveryDisplay[]>;
  clientDataSource = new ObservableDataSource<FutureDeliveryDisplay>();
  clientMiniDataSource = new ObservableDataSource<FutureDeliveryMiniDisplay>();
  profileDataSourceMap: { [key: string]: MatTableDataSource<FutureDeliveryDisplay> } = {};

  // form data
  commodityProfiles$: Observable<CommodityProfile[]>;
  periods: string[];

  displayedCommodityProfiles: CommodityProfile[];

  private selectedClientDocId;

  // list used for sorted display purposes
  private locations: Location[];
  private commodityProfiles: CommodityProfile[];

  // dictionary for lookup purposes
  private locationMap: { [key: string]: Location };
  private commodityProfileMap: { [key: string]: CommodityProfile };

  // variable for lookup purpose
  private pricedSegments: PricingSegment[];

  private reportableItems: FutureDeliveryReportableItem[] = [];

  private preservedClientParentHeaders: string[] = ['commodityProfileHeader'];
  private preservedClientColumnsToDisplay: string[] = ['commodityProfileName'];

  private preservedProfileSummaryParentHeaders: string[] = ['locationHeader', 'deliveryPeriodHeader'];
  private preservedProfileSummaryColumnsToDisplay: string[] = ['locationName', 'deliveryPeriod'];

  private contractTypes = [ContractType.CASH, ContractType.BASIS, ContractType.HTA];
  private contractMonths = Object.keys(ContractMonth);

  // tracker variables for table display
  private firstLocationProfile = {};
  private locationRowCount = {};

  // default query date for getting priced and unpriced Contracts or PricingSegments
  private readonly defaultDate = moment().startOf('month');
  // default selection to next calendar month
  private readonly defaultDeliveryPeriodSelection = [this.translateToContractMonth(moment().startOf('month').add(1, 'month'))];

  constructor(
    private authzService: Auth0AuthzService,
    private breakpointObserver: BreakpointObserver,
    private clientSelectorService: ClientSelectorService,
    private commodityProfileService: CommodityProfileService,
    private contractService: ContractService,
    public exportService: ExportService,
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private pricingSegmentService: PricingSegmentService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.MERCHANDISING_REPORTS_GENERATOR_ROLE)) {
      this.errorMessage = 'You do not have permission to access the future delivery report.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.prepForDeliveryPeriodSelection();

    this.commodityProfiles$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((client: Client) => {
        this.selectedClientDocId = client.docId;
        return this.locationService.getActiveLocationsByTypeAndClientDocId(this.selectedClientDocId);
      }),
      switchMap((activeClientLocations: Location[]) => {
        this.locations = activeClientLocations;
        // populate location dictionary
        this.locationMap = {};
        activeClientLocations.forEach((location: Location) => {
          this.locationMap[location.docId] = location;
        });
        return this.commodityProfileService.getActiveCommodityProfilesByTypeAndClientDocId(this.selectedClientDocId);
      }),
      tap((activeCommodityProfiles: CommodityProfile[]) => {
        this.commodityProfiles = activeCommodityProfiles;
        // populate commodity profile dictionary
        this.commodityProfileMap = {};
        activeCommodityProfiles.forEach((commodityProfile: CommodityProfile) => {
          this.commodityProfileMap[commodityProfile.docId] = commodityProfile;
        });

        // set default values for fields selections
        this.futureDeliverySearchForm.get('deliveryPeriods').setValue(this.defaultDeliveryPeriodSelection);
        this.futureDeliverySearchForm.get('commodityProfiles').setValue(this.commodityProfiles);
        this.futureDeliverySearchForm.markAsDirty();

        this.isLoading = false;
      })
    );

    // only show mini client on xs screen; other screens show full-sized
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(state => {
        this.profileSummaryParentHeaders = [...this.preservedProfileSummaryParentHeaders];
        this.profileSummaryColumnsToDisplay = [...this.preservedProfileSummaryColumnsToDisplay];
        this.clientParentHeaders = [...this.preservedClientParentHeaders];
        this.clientColumnsToDisplay = [...this.preservedClientColumnsToDisplay];
        // Mobile Devices
        if (state.breakpoints[Breakpoints.XSmall]) {
          this.showMini = true;
        } else {
          // Tablets
          if (state.breakpoints[Breakpoints.Small]) {
            this.deliveryColumns.forEach(colName => {
              if (colName !== 'TOTAL') {
                // add all active commodity profiles' column as the tables columns
                this.addDisplayColumns(colName);
              }
            });
            // Laptop and Other Large Screens
          } else {
            this.deliveryColumns.forEach(colName => {
              // add all active commodity profiles' column as the tables columns
              this.addDisplayColumns(colName);
            });
          }
          this.showMini = false;
        }
      });
  }

  get currentSelectedDeliveryPeriods() {
    return this.futureDeliverySearchForm.get('deliveryPeriods').value as string[];
  }

  get currentSelectedProfiles() {
    return this.futureDeliverySearchForm.get('commodityProfiles').value as CommodityProfile[];
  }

  get contractTypeCount() {
    return this.contractTypes.length;
  }

  get summaryFooterLength() {
    // unpriced & priced of contract types and total column plus profile
    return (this.contractTypes.length * 2) + 3;
  }

  get profileSummaryFooterLength() {
    // unpriced & priced of contract types and total column plus location and delivery period
    return (this.contractTypes.length * 2) + 4;
  }

  getLocationRowCount(dataRow: FutureDeliveryDisplay) {
    return this.locationRowCount[`${dataRow.commodityProfileDocId}-${dataRow.locationName}`];
  }

  isFirstLocation(dataRow: FutureDeliveryDisplay) {
    return this.firstLocationProfile[`${dataRow.commodityProfileDocId}-${dataRow.locationName}`] === dataRow.deliveryPeriod;
  }

  isFirstContractType(dataRow: FutureDeliveryMiniDisplay) {
    return this.contractTypes[0] === dataRow.contractType;
  }

  getDisplayAmount(value: number) {
    return value.toLocaleString(undefined, { maximumFractionDigits: 2 });
  }

  reset() {
    this.futureDeliverySearchForm.get('deliveryPeriods').setValue([]);
    this.futureDeliverySearchForm.get('commodityProfiles').setValue([]);
    this.futureDeliverySearchForm.markAsPristine();
    this.displayReports = false;
  }

  loadFutureDeliveryReportData() {
    if (!this.currentSelectedDeliveryPeriods.length) {
      this.futureDeliverySearchForm.get('deliveryPeriods').setValue(this.defaultDeliveryPeriodSelection);
      this.futureDeliverySearchForm.markAsDirty();
    }
    if (!this.currentSelectedProfiles.length) {
      this.futureDeliverySearchForm.get('commodityProfiles').setValue(this.commodityProfiles);
      this.futureDeliverySearchForm.markAsDirty();
    }

    // avoid using combineLatest of single profile queries when the user selected all active commodity profiles
    const commodityProfileDocIds = this.currentSelectedProfiles.length === this.commodityProfiles.length ? [] :
      this.currentSelectedProfiles.map((commodityProfile: CommodityProfile) => commodityProfile.docId);

    const currentDeliveryPeriod = this.translateToContractMonth(this.defaultDate);

    // only display profile that is selected (or display all profile if none is selected)
    this.displayedCommodityProfiles = this.currentSelectedProfiles.length ? [...this.currentSelectedProfiles] : [...this.commodityProfiles];
    this.displayedCommodityProfiles.sort(this.sortByAscAccountNumberAndProfileName);

    this.isLoading = true;
    this.deliveries$ = combineLatest([
      this.loadPricedPricingSegmentReportableItems(this.selectedClientDocId, currentDeliveryPeriod, commodityProfileDocIds),
      this.loadUnpricedContractReportableItems(this.selectedClientDocId, currentDeliveryPeriod, commodityProfileDocIds)
    ]).pipe(
      map(([pricedReportableItems, unpricedReportableItems]) => {
        this.reportableItems = pricedReportableItems.concat(unpricedReportableItems);

        // sort location and commodity profile in ascending order
        this.locations.sort((a, b) => a.name > b.name ? 1 : -1);

        // // rows for profile delivery details
        this.profileDataSourceMap = {};
        this.displayedCommodityProfiles.forEach((commodityProfile: CommodityProfile) => {
          // initialize profile delivery table for each profile
          this.profileDataSourceMap[commodityProfile.docId] = new MatTableDataSource<FutureDeliveryDisplay>();
          this.profileDataSourceMap[commodityProfile.docId].data = [];
        });
        // rows for summary
        const clientRows: FutureDeliveryDisplay[] = [];

        // re-initialize trackers for the table display
        this.firstLocationProfile = {};
        this.locationRowCount = {};

        // only populate client summary and location summary if there is data returned
        if (this.reportableItems.length) {
          this.displayedCommodityProfiles.forEach((commodityProfile: CommodityProfile) => {
            this.locations.forEach((location: Location) => {
              let commodityProfileTotalRowIndex = clientRows.map((totalRow: FutureDeliveryDisplay) => totalRow.locationName).indexOf(`${commodityProfile.name} Total`);
              // populate default value for client summary rows
              if (commodityProfileTotalRowIndex === -1) {
                commodityProfileTotalRowIndex = clientRows.length;
                clientRows.push({
                  locationName: `${commodityProfile.name} Total`,
                  commodityProfileDocId: commodityProfile.docId,
                  commodityProfileName: commodityProfile.name,
                  CASH_UNPRICED: 0,
                  CASH_PRICED: 0,
                  HTA_UNPRICED: 0,
                  HTA_PRICED: 0,
                  BASIS_UNPRICED: 0,
                  BASIS_PRICED: 0,
                  TOTAL_UNPRICED: 0,
                  TOTAL_PRICED: 0
                });
              }

              const profileLocationRows: FutureDeliveryDisplay[] = [];

              // get proper reportable items to display as needed
              let filteredReportableItems;
              if (this.currentSelectedDeliveryPeriods.length) {
                filteredReportableItems = this.reportableItems.filter(
                  reportableItem => reportableItem.locationName === location.name &&
                    reportableItem.commodityProfileName === commodityProfile.name &&
                    this.currentSelectedDeliveryPeriods.find(period => period === reportableItem.deliveryPeriod));
              } else {
                filteredReportableItems = this.reportableItems.filter(
                  reportableItem => reportableItem.locationName === location.name &&
                    reportableItem.commodityProfileName === commodityProfile.name);
              }
              // sort filtered item by ascending delivery period
              filteredReportableItems.sort((a, b) => a.deliveryPeriod.localeCompare(b.deliveryPeriod));

              filteredReportableItems.forEach(reportableItem => {
                const unpricedColumn = this.getSubColumnName(reportableItem.contractType, false);
                const pricedColumn = this.getSubColumnName(reportableItem.contractType, true);

                let profileLocationRowIdx = profileLocationRows.findIndex(row => row.deliveryPeriod === reportableItem.deliveryPeriod);
                if (profileLocationRowIdx === -1) {
                  // populate default value for profile summary rows
                  const profileRow: FutureDeliveryDisplay = {
                    locationName: location.name,
                    commodityProfileDocId: commodityProfile.docId,
                    deliveryPeriod: reportableItem.deliveryPeriod,
                    CASH_UNPRICED: 0,
                    CASH_PRICED: 0,
                    HTA_UNPRICED: 0,
                    HTA_PRICED: 0,
                    BASIS_UNPRICED: 0,
                    BASIS_PRICED: 0,
                    TOTAL_UNPRICED: 0,
                    TOTAL_PRICED: 0
                  };
                  profileLocationRowIdx = profileLocationRows.length;
                  profileLocationRows.push(profileRow);

                  // keep track of first location of this profile
                  const currentRowKey = `${profileRow.commodityProfileDocId}-${profileRow.locationName}`;
                  if (this.firstLocationProfile[currentRowKey] === undefined) {
                    this.firstLocationProfile[currentRowKey] = profileRow.deliveryPeriod;
                  }
                  // keep track of how many row for each profile's location
                  if (!this.locationRowCount[currentRowKey]) {
                    this.locationRowCount[currentRowKey] = 1;
                  } else {
                    this.locationRowCount[currentRowKey]++;
                  }
                }

                profileLocationRows[profileLocationRowIdx][unpricedColumn] += reportableItem.UNPRICED;
                profileLocationRows[profileLocationRowIdx][pricedColumn] += reportableItem.PRICED;
                profileLocationRows[profileLocationRowIdx]['TOTAL_UNPRICED'] += reportableItem.UNPRICED;
                profileLocationRows[profileLocationRowIdx]['TOTAL_PRICED'] += reportableItem.PRICED;

                // increment client summary buy and sell quantity location and profile
                clientRows[commodityProfileTotalRowIndex][unpricedColumn] += reportableItem.UNPRICED;
                clientRows[commodityProfileTotalRowIndex][pricedColumn] += reportableItem.PRICED;
                clientRows[commodityProfileTotalRowIndex]['TOTAL_UNPRICED'] += reportableItem.UNPRICED;
                clientRows[commodityProfileTotalRowIndex]['TOTAL_PRICED'] += reportableItem.PRICED;
              });

              // add data that has delivery quantity
              if (profileLocationRows.length) {
                const currentData = this.profileDataSourceMap[profileLocationRows[0].commodityProfileDocId].data;
                this.profileDataSourceMap[profileLocationRows[0].commodityProfileDocId].data = currentData.concat(profileLocationRows);
              }
            });
          });
        }

        const clientMiniRows: FutureDeliveryMiniDisplay[] = [];
        clientRows.forEach(row => {
          this.contractTypes.forEach((type: ContractType) => {
            const unpricedColumn = this.getSubColumnName(type, false);
            const pricedColumn = this.getSubColumnName(type, true);
            clientMiniRows.push({
              commodityProfileName: row.commodityProfileName,
              contractType: type,
              UNPRICED: row[unpricedColumn],
              PRICED: row[pricedColumn]
            });
          });
        });

        // populate client summary datasource
        this.clientDataSource.data$ = of(clientRows);
        // populate client summary mini display datasource
        this.clientMiniDataSource.data$ = of(clientMiniRows);

        this.isLoading = false;
        this.displayReports = true;
        return clientRows;
      }),
      catchError(err => {
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar('Error running report: ' + errorMsg, 'DISMISS', false);
        console.error(`Error retrieving reportable items: ${err}`);
        this.isLoading = false;
        return of([]);
      })
    );
  }

  getSubColumnName(colName: string, isPriced: boolean) {
    return `${colName}_${isPriced ? 'PRICED' : 'UNPRICED'}`;
  }

  private prepForDeliveryPeriodSelection() {
    // Get current 5-year period. Current month till december in the next 5 years. (note: consistent with basis admin)
    const currentMonthIdx = moment().month();
    this.periods = [];
    for (let i = 0; i < SELECTION_YEAR_PERIOD; i++) {
      for (let j = 0; j < moment.monthsShort().length; j++) {
        const currentYearStr = moment().add(i, 'years').format('YY');
        const currentPeriod = currentYearStr + MONTH_CODE_DICT[j];
        if (j >= currentMonthIdx || i !== 0) {
          this.periods.push(currentPeriod);
        }
      }
    }
  }

  private addDisplayColumns(colName: string) {
    const unpricedColumn = this.getSubColumnName(colName, false);
    const pricedColumn = this.getSubColumnName(colName, true);
    this.clientParentHeaders.push(colName);
    if (colName !== ContractType.CASH) {
      this.clientColumnsToDisplay.push(unpricedColumn);
      this.profileSummaryColumnsToDisplay.push(unpricedColumn);
    }
    this.clientColumnsToDisplay.push(pricedColumn);
    this.profileSummaryParentHeaders.push(colName);
    this.profileSummaryColumnsToDisplay.push(pricedColumn);
  }

  private loadPricedPricingSegmentReportableItems(clientDocId: string, deliveryPeriod: string, commodityProfileDocIds: string[])
    : Observable<FutureDeliveryReportableItem[]> {
    return this.pricingSegmentService.findPricedPricingSegmentsForFutureDeliveryReport(
      clientDocId, deliveryPeriod, commodityProfileDocIds)
      .pipe(
        switchMap((pricedSegments: PricingSegment[]) => {
          // save retrieved priced segments for lookup purposes
          this.pricedSegments = pricedSegments;

          if (pricedSegments.length === 0) {
            return of([]);
          }
          return combineLatest(
            pricedSegments.map((pricingSegment: PricingSegment) => {
              let referencedCommodityProfile;
              let referencedLocation;
              return this.getSingleCommodityProfile(pricingSegment.commodityProfileDocId).pipe(
                switchMap((singleCommodityProfile: CommodityProfile) => {
                  referencedCommodityProfile = singleCommodityProfile;
                  if (!this.commodityProfiles.find(commodityProfile => commodityProfile.docId === singleCommodityProfile.docId)) {
                    this.commodityProfiles.push(singleCommodityProfile);
                  }
                  return this.getSingleLocation(pricingSegment.deliveryLocationDocId);
                }),
                map((singleLocation: Location) => {
                  referencedLocation = singleLocation;
                  if (!this.locations.find(location => location.docId === singleLocation.docId)) {
                    this.locations.push(singleLocation);
                  }
                  return {
                    commodityProfileName: referencedCommodityProfile.name,
                    locationName: referencedLocation.name,
                    deliveryPeriod: pricingSegment.deliveryPeriod,
                    contractType: pricingSegment.contractType,
                    UNPRICED: 0,
                    PRICED: pricingSegment.quantity
                  } as FutureDeliveryReportableItem;
                })
              );
            })
          );
        })
      );
  }

  private loadUnpricedContractReportableItems(clientDocId: string, deliveryPeriod: string, commodityProfileDocIds: string[])
    : Observable<FutureDeliveryReportableItem[]> {
    return combineLatest([
      this.contractService.getUnpricedContractsForFutureDeliveryReport(
        clientDocId, ContractType.HTA, [ContractStatus.PENDING_BASIS, ContractStatus.WORKING_BASIS],
        deliveryPeriod, commodityProfileDocIds),
      this.contractService.getUnpricedContractsForFutureDeliveryReport(
        clientDocId, ContractType.BASIS, [ContractStatus.PENDING_FUTURES, ContractStatus.WORKING_FUTURES],
        deliveryPeriod, commodityProfileDocIds)
    ]).pipe(
      switchMap(([htaContracts, basisContracts]) => {
        const queriedContracts = htaContracts.concat(basisContracts);
        if (queriedContracts.length === 0) {
          return of([]);
        }
        return combineLatest(
          queriedContracts.map((contract: Contract) => {
            let referencedCommodityProfile;
            return this.getSingleCommodityProfile(contract.commodityProfileDocId).pipe(
              switchMap((singleCommodityProfile: CommodityProfile) => {
                referencedCommodityProfile = singleCommodityProfile;
                if (!this.commodityProfiles.find(commodityProfile => commodityProfile.docId === singleCommodityProfile.docId)) {
                  this.commodityProfiles.push(singleCommodityProfile);
                }
                return this.getSingleLocation(contract.deliveryLocationDocId);
              }),
              map((singleLocation: Location) => {
                if (!this.locations.find(location => location.docId === singleLocation.docId)) {
                  this.locations.push(singleLocation);
                }
                return {
                  commodityProfileName: referencedCommodityProfile.name,
                  locationName: singleLocation.name,
                  deliveryPeriod: contract.deliveryPeriod,
                  contractType: contract.type,
                  UNPRICED: contract.quantity - this.getPricedSegmentsQuantity(contract.docId),
                  PRICED: 0
                } as FutureDeliveryReportableItem;
              })
            );
          })
        );
      })
    );
  }

  // creating consistent means of referencing active commodityProfile or a commodityProfile that was active at the time of record creation
  private getSingleCommodityProfile(commodityProfileDocId: string): Observable<CommodityProfile> {
    const activeCommodityProfile = this.commodityProfileMap[commodityProfileDocId];
    return activeCommodityProfile ?
      of(activeCommodityProfile) :
      this.commodityProfileService.getCommodityProfileByDocId(this.selectedClientDocId, commodityProfileDocId);
  }

  // creating consistent means of referencing active location or a location that was active at the time of record creation
  private getSingleLocation(locationDocId: string): Observable<Location> {
    const activeLocation = this.locationMap[locationDocId];
    return activeLocation ?
      of(activeLocation) :
      this.locationService.getLocationByDocId(this.selectedClientDocId, locationDocId);
  }

  private getPricedSegmentsQuantity(contractDocId: string): number {
    const contractPricedSegments = this.pricedSegments.filter((segment: PricingSegment) => segment.contractDocId === contractDocId);
    return contractPricedSegments.map(segment => segment.quantity).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  private sortByAscAccountNumberAndProfileName(a: CommodityProfile, b: CommodityProfile): number {
    return (a.officeCode + a.accountNumber + a.name).localeCompare(b.officeCode + b.accountNumber + b.name);
  }

  private translateToContractMonth(selectedMonth: moment.Moment): string {
    return selectedMonth.format(YEAR_FORMAT) + ContractMonth[this.contractMonths[selectedMonth.month()]];
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}
