import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { LocationService } from '@advance-trading/angular-ops-data';
import { Location } from '@advance-trading/ops-data-lib';

import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { ClientSelectorService } from '../../service/client-selector.service';
import { UserRoles } from '../../utilities/user-roles';

@Component({
  selector: 'hms-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements AfterViewInit, OnInit {
  columnsToDisplay = [];
  errorMessage: string;
  dataSource = new ObservableDataSource<Location>();
  filterValue = new FormControl();
  isLoading = true;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private changeDetector: ChangeDetectorRef,
    private clientSelectorService: ClientSelectorService,
    private locationService: LocationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.columnsToDisplay = ['accountingSystemId', 'name', 'isClientLocation', 'isActive', 'bidsIcon'];

    this.dataSource.data$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap(client => {
        return this.locationService.getAllLocationsByClientDocId(client.docId);
      }),
      tap(_ => this.isLoading = false),
      catchError(err => {
        this.isLoading = false;
        this.errorMessage = 'Error retrieving locations; please try again later';
        console.error(`Error retrieving locations: ${err}`);
        return of([]);
      })
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.filter.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  get isLocationAdmin() {
    return this.authzService.currentUserHasRole(UserRoles.LOCATION_ADMIN_ROLE);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectLocation(location: Location) {
    this.router.navigate(['./', location.docId], { relativeTo: this.activatedRoute });
  }

  addLocation() {
    this.router.navigate(['./new'], { relativeTo: this.activatedRoute });
  }

  selectBidsIcon(location: Location) {
    this.router.navigate(['/bids'], { queryParams: { locationId: location.docId } });
  }

}
