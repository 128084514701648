import { CommodityProfile } from '@advance-trading/ops-data-lib';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { PriceValidators } from '../utilities/validators/price.validator';

export class ContractPriceHelper {
  private minPrice: number;
  private maxPrice: number;
  private specialHandling = false;
  private specialHandlingType = '';

  constructor(
    private form: FormGroup,
    private useWholeCent: boolean
  ) { }

  /**
   * Returns the set of validators to be applied to an individual priceAdjustment value field; the actual application of the validators
   * is handled by the individual templates.
   *
   * TODO: Investigate possibility of bringing all priceAdjustments handling (parse, add, remove, prepare for save) into helper instead
   * of replicating the logic across all controllers
   *
   */
  public getPriceAdjustmentsValidators(): ValidatorFn[] {
    const validators = [Validators.required, PriceValidators.nonZeroValidator];
    if (this.useWholeCent) {
      validators.push(PriceValidators.wholeCentValidator);
    } else {
      validators.push(PriceValidators.fiveDigitValidator);
    }
    return validators;
  }

  /**
   * Rounds a value to the appropriate number of decimal places for the type of data it represents, e.g. a calculatedCash value should
   * be passed through with the fieldName "cashPrice", even though it may not be stored in the cashPrice field
   *
   * @param fieldName The name of the field having its value set
   * @param value The value to be set on the field
   */
  public getRoundedValue(fieldName: string, value: number): number {
    const variablePrecision = this.getVariablePrecision(fieldName);
    return this.roundToPrecision(value, variablePrecision);
  }

  /**
   * Rounds a value to the appropriate number of decimal places for the type of data it represents, e.g. a calculatedCash value should
   * be passed through with the fieldName "cashPrice", even though it may not be stored in the cashPrice field. Needed to keep fixed
   * trailing zeroes for display
   *
   * @param fieldName The name of the field having its value set
   * @param value The value to be set on the field
   */
  public getRoundedValueAsString(fieldName: string, value: number): string {
    const variablePrecision = this.getVariablePrecision(fieldName);
    return this.roundToPrecision(value, variablePrecision).toFixed(variablePrecision);
  }

  /**
   * Initial setup of validators, optionally using current state of existing Contract and/or PricingSegment
   *
   * @param args The optional ValidatorSetupArguments containing the state of the form; used with existing Contract object
   */
  public setAllPriceValidators(args?: ValidatorSetupArguments): void {
    if (args) {
      this.consumeArguments(args);
    }
    this.setBasisValidators();
    this.setBasisAdjustmentValidators();
    this.setFuturesValidators();
    this.setFreightValidators();
    this.setCashValidators();
    this.setExchangeRateValidators();
  }

  /**
   * Used to update cash and futures validators from futures to five-digit validation based on changes to other controls
   *
   * @param args ValidatorSetupArguments containing the changes that could trigger a change in validators
   */
  public setCashAndFuturesValidators(args: ValidatorSetupArguments): void {
    this.consumeArguments(args);
    this.setCashValidators();
    this.setFuturesValidators();
  }

  /**
   * Rounds a value to the appropriate number of decimal places and sets that as the value of the named field
   *
   * @param fieldName The name of the field having its value set
   * @param value The value to be set on the field
   */
  public setFieldValue(fieldName: string, value: number): void {
    const roundedValueAsString = this.getRoundedValueAsString(fieldName, value);
    this.form.get(fieldName).setValue(roundedValueAsString);
  }

  private consumeArguments(args: ValidatorSetupArguments): void {
    // use cashPrice as a proxy as max and min prices only apply to cashPrice and futuresPrice
    if (args.commodityProfile) {
      this.minPrice = this.getRoundedValue('cashPrice', args.commodityProfile.minPrice);
      this.maxPrice = this.getRoundedValue('cashPrice', args.commodityProfile.maxPrice);
    }
    if (args.form) {
      this.form = args.form;
    }
    if (args.specialHandling !== undefined) {
      this.specialHandling = args.specialHandling;
    }
    if (args.specialHandlingType) {
      this.specialHandlingType = args.specialHandlingType;
    }
  }

  private getVariablePrecision(fieldName: string): number {
    if (this.useWholeCent) {
      return 2;
    } else if (fieldName === 'priceAdjustments') {
      return 5;
    } else if (this.useFiveDecimalPlaces && ['cashPrice', 'futuresPrice'].includes(fieldName)) {
      return 5;
    } else {
      return 4;
    }
  }

  private roundToPrecision(value: number, precision: number): number {
    return Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
  }

  private setBasisValidators(): void {
    if (this.form.get('basisPrice')) {
      const validators = [Validators.required];
      if (this.useWholeCent) {
        validators.push(PriceValidators.wholeCentValidator);
      } else {
        validators.push(PriceValidators.basicPriceValidator);
      }
      this.form.get('basisPrice').setValidators(validators);
      this.form.get('basisPrice').updateValueAndValidity();
    }
  }

  private setBasisAdjustmentValidators(): void {
    if (this.form.get('basisAdjustment')) {
      const validators = [Validators.required];
      if (this.useWholeCent) {
        validators.push(PriceValidators.wholeCentValidator);
      } else {
        validators.push(PriceValidators.basicPriceValidator);
      }
      this.form.get('basisAdjustment').setValidators(validators);
      this.form.get('basisAdjustment').updateValueAndValidity();
    }
  }

  private setFuturesValidators(): void {
    if (this.form.get('futuresPrice')) {
      const validators = [Validators.required];
      if (Number.isFinite(this.minPrice)) {
        validators.push(Validators.min(this.minPrice));
      }
      if (Number.isFinite(this.maxPrice)) {
        validators.push(Validators.max(this.maxPrice));
      }
      if (this.useWholeCent) {
        validators.push(PriceValidators.wholeCentValidator);
      } else if (this.useFiveDecimalPlaces) {
        validators.push(PriceValidators.fiveDigitValidator);
      } else {
        validators.push(PriceValidators.futuresPriceValidator);
      }
      this.form.get('futuresPrice').setValidators(validators);
      this.form.get('futuresPrice').updateValueAndValidity();
    }
  }

  private setFreightValidators(): void {
    if (this.form.get('freightPrice')) {
      const validators = [Validators.required];
      if (this.useWholeCent) {
        validators.push(PriceValidators.wholeCentValidator);
      } else {
        validators.push(PriceValidators.basicPriceValidator);
      }
      this.form.get('freightPrice').setValidators(validators);
      this.form.get('freightPrice').updateValueAndValidity();
    }
  }

  private setCashValidators(): void {
    if (this.form.get('cashPrice')) {
      const validators = [Validators.required];
      if (Number.isFinite(this.minPrice)) {
        validators.push(Validators.min(this.minPrice));
      }
      if (Number.isFinite(this.maxPrice)) {
        validators.push(Validators.max(this.maxPrice));
      }
      if (this.useWholeCent) {
        validators.push(PriceValidators.wholeCentValidator);
      } else if (this.useFiveDecimalPlaces) {
        validators.push(PriceValidators.fiveDigitValidator);
      } else {
        validators.push(PriceValidators.futuresPriceValidator);
      }
      this.form.get('cashPrice').setValidators(validators);
      this.form.get('cashPrice').updateValueAndValidity();
    }
  }

  private setExchangeRateValidators(): void {
    if (this.form.get('exchangeRate')) {
      const validators = [Validators.required];
      if (this.useWholeCent) {
        validators.push(PriceValidators.wholeCentValidator);
      } else {
        validators.push(PriceValidators.exchangeValidator);
      }
      this.form.get('exchangeRate').setValidators(validators);
      this.form.get('exchangeRate').updateValueAndValidity();
    }
  }

  private get useFiveDecimalPlaces(): boolean {
    return this.specialHandling && ['OTC', 'OPTION'].includes(this.specialHandlingType);
  }

}

export interface ValidatorSetupArguments {
  commodityProfile?: CommodityProfile;
  form?: FormGroup;
  specialHandling?: boolean;
  specialHandlingType?: string;
}
