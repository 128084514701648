<div fxLayout="column" fxLayoutGap.gt-xs="15px" class="ad-hoc-order-leg-detail">
  <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between start">
    <b>{{ leg.side | titlecase }}</b>
  </div>

  <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="start start">
    <ati-labeled-data fxFlex.gt-xs="30" fxFlex.xs="100" label="Commodity" data="{{ getCommodityName(leg.commodityId) }} ({{ leg.commodityId }})"></ati-labeled-data>
    <ati-labeled-data fxFlex.gt-xs="23" fxFlex.xs="100" label="Contract" data="{{ leg.contractYearMonth | codeToMonth }}"></ati-labeled-data>
    <ati-labeled-data fxFlex.gt-xs="23" fxFlex.xs="100" label="Ratio Quantity" [data]="leg.ratioQuantity"></ati-labeled-data>
    <ati-labeled-data fxFlex.gt-xs="24" fxFlex.xs="100" label="Security Type" data="{{ leg.securityType | replace | titlecase }}"></ati-labeled-data>
  </div>
</div>
