import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, startWith, switchMap, tap } from 'rxjs/operators';

import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { UserService } from '@advance-trading/angular-ops-data';
import { NgxMatIntlTelInputComponent } from '@advance-trading/ngx-mat-intl-tel-input';
import { Client, HMSUserPreferences, HMSUserSettings, MarketDataFrequency, PhoneNumberType, User, UserType } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { UserRoles } from '../../utilities/user-roles';
import { UserPreferencesService } from '../../service/user-preferences.service';
import { UserSettingsService } from '../../service/user-settings.service';

interface Auth0Group {
  _id?: string;
  name?: string;
  description?: string;
}

@Component({
  selector: 'hms-user',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  profileForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
      Validators.maxLength(100)]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.maxLength(100)]),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.maxLength(200)]),
    mobileNumber: new FormControl('', Validators.required),
    officeNumber: new FormControl(''),
    tollFreeNumber: new FormControl(''),
    faxNumber: new FormControl(''),
    group: new FormControl(),
    status: new FormControl('', Validators.required),
    marketDataFrequency: new FormControl('', Validators.required),
    hmsEnabled: new FormControl(),
    useLiveLedgerColor: new FormControl()
  });

  userGroups: Auth0Group[];
  user$: Observable<User>;
  selectedClient$: Observable<Client>;
  filteredGroups$: Observable<Auth0Group[]>;
  editMode = false;
  updateComplete = true;
  errorMessage: string;
  addOnBlur = false;
  separatorKeyCodes: number[] = [ENTER, SPACE];
  settingsValid = true;

  marketDataFrequencies = Object.keys(MarketDataFrequency);

  hmsUserPreferences: HMSUserPreferences;

  private user: User;
  private hmsUserSettings: HMSUserSettings;
  private adminUser: User;
  private selectedClient: Client;
  private adminGroups: Auth0Group[];
  private originalUserGroups: Auth0Group[]; // Master copy to revert uncommited changes

  private removeGroupIdList: string[] = [];
  private addGroupIdList: string[] = [];

  @ViewChild('groupauto', { static: false }) private groupMatAuto: MatAutocomplete;
  @ViewChild('groupInput', { static: false }) private groupInput: ElementRef<HTMLInputElement>;
  @ViewChild('mobileNumber', { static: false }) mobileNumber: NgxMatIntlTelInputComponent;
  @ViewChild('officeNumber', { static: false }) officeNumber: NgxMatIntlTelInputComponent;
  @ViewChild('tollFreeNumber', { static: false }) tollFreeNumber: NgxMatIntlTelInputComponent;
  @ViewChild('faxNumber', { static: false }) faxNumber: NgxMatIntlTelInputComponent;

  constructor(
    public snackBar: MatSnackBar,
    private auth0Service: Auth0AuthzService,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private userPreferencesSettingsService: UserPreferencesService,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private clientSelectorService: ClientSelectorService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.profileForm.disable();

    // Get info on the user to change
    this.fetchUser();

    // Setup the autocomplete filter for Clients
    this.selectedClient$ = this.clientSelectorService.getSelectedClient().pipe(
      tap(selectedClient => this.selectedClient = selectedClient)
    );

    // Setup the autocomplete filter for Groups
    this.filteredGroups$ = this.profileForm.get('group').valueChanges
      .pipe(
        startWith<string | Auth0Group>(''),
        filter(value => typeof value === 'string'),
        map((groupName: string) => {
          if (!this.adminGroups) {
            return [];
          } else if (groupName) {
            return this.adminGroups.filter(group => {
              return group.name.toLowerCase().includes(groupName.toLowerCase()) &&
                !this.userGroups.find((value) => group.name === value.name);
            });
          } else {
            return this.adminGroups.filter(group => !this.userGroups.find((value) => group.name === value.name));
          }
        })
      );

  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('validatePhoneNumber')) {
      return 'Invalid phone number';
    } else if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('email')) {
      return 'Invalid email';
    }
    return 'Unknown error';
  }

  getMarketDataFrequencyDisplayText(value: MarketDataFrequency) {
    switch (value) {
      case MarketDataFrequency.NONE:
        return 'None';
      case MarketDataFrequency.DELAY_10:
        return '10 Min Delayed';
      case MarketDataFrequency.ON_DEMAND:
        return 'On-Demand';
      case MarketDataFrequency.REALTIME:
        return 'Real-Time';
      default:
        return 'None';
    }
  }

  clearPhoneNumber(control: FormControl, input: NgxMatIntlTelInputComponent) {
    control.setValue(undefined);
    input.phoneNumber = undefined;
    this.profileForm.markAsDirty();
  }

  displayGroup(group?: Auth0Group) {
    return group ? group.name : '';
  }

  updateUser() {
    this.updateComplete = false;
    // Update fields in user
    const formValues = this.profileForm.getRawValue();
    this.user.firstName = formValues.firstName;
    this.user.lastName = formValues.lastName;
    this.user.email = formValues.email;
    this.updatePhoneNumbers(formValues);
    this.user.status = formValues.status;
    this.user.marketDataFrequency = formValues.marketDataFrequency;

    // note: only allows customers to default to a user type.
    //       This happens when their email domain is not recognized during registration
    //       and we want to make sure the type and clientDocId gets set here, so the user is able to change their user settings
    if (!this.user.type) {
      this.user.type = UserType.CUSTOMER;
    }
    this.user.clientDocId = this.user.clientDocId || this.selectedClient.docId;

    this.user.hmsEnabled = formValues.hmsEnabled;

    // Reset this.originalUserGroups to this.userGroups after update
    this.originalUserGroups = this.userGroups;

    // TODO need to chain together all these calls and understand how to handle errors from one or both of these updates
    if (this.addGroupIdList.length > 0) {
      of(this.addGroupIdList)
        .pipe(
          mergeMap(
            groupIds => forkJoin(groupIds.map(groupId => this.auth0Service.addUserToGroup(groupId, this.user.authId)))
          )
        )
        .subscribe((response) => {
          console.log('Group add successful: ' + JSON.stringify(response));
        }, err => {
          console.error('Group add failed: ' + JSON.stringify(err));
        });
    }

    if (this.removeGroupIdList.length > 0) {
      of(this.removeGroupIdList)
        .pipe(
          mergeMap(
            groupIds => forkJoin(groupIds.map(groupId => this.auth0Service.removeUserFromGroup(groupId, this.user.authId)))
          )
        )
        .subscribe((response) => {
          console.log('Group remove successful: ' + JSON.stringify(response));
        }, err => {
          console.error('Group remove failed: ' + JSON.stringify(err));
        });
    }

    // Update the User object in Firestore
    this.userService.updateUser(this.user, this.userOnlyHasClientAdmin(), this.authService.accessToken)
      .then(() => {
        if (this.userCanAdminister()) {
          // This is a check to prevent an Employee from having their authorized clients overwritten
          if (this.user.type === UserType.CUSTOMER) {
            // TODO determine if this is the place we want to handle this
            this.hmsUserSettings.authorizedClientDocIds = [this.user.clientDocId];
          }
          this.hmsUserSettings.userDocId = this.user.docId;
          return this.userSettingsService.setHmsSettingsByUserDocId(this.user.docId, this.hmsUserSettings);
        }
        return;
      })
      .then(() => {
        if (this.userCanAdminister() || this.isSelf()) {
          this.hmsUserPreferences.useLiveLedgerColor = formValues.useLiveLedgerColor;
          return this.userPreferencesSettingsService.setHmsPreferencesByUserDocId(this.user.docId, this.hmsUserPreferences);
        }
      })
      .then(() => {
        this.updateComplete = true;
        console.log('User successfully updated');
        this.setEditMode(false);
        this.openSnackBar('User successfully updated', 'DISMISS', true);
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`User update failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`User update failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  // Determine if the logged in user can administer the selected user
  userCanAdminister(): boolean {
    return this.userHasUserAdmin() || this.userOnlyHasClientAdmin();
  }

  isSelf(): boolean {
    return this.user && this.user.docId === this.authService.userProfile.app_metadata.firestoreDocId;
  }

  setEditMode(mode) {
    this.editMode = mode;
    if (this.editMode) {
      this.profileForm.enable();
      if (this.isSelf() && !this.userCanAdminister()) {
        this.profileForm.get('marketDataFrequency').disable();
      }
    } else {
      this.profileForm.disable();
      this.setupUserForm();
      this.userGroups = this.originalUserGroups;
    }
  }

  groupRemovable(groupName: string) {
    const removable = this.adminGroups && this.adminGroups.filter(group => group.name === groupName).length > 0;
    return this.editMode && removable && this.userCanAdminister();
  }

  addGroup(event: MatChipInputEvent) {
    let group: Auth0Group = this.profileForm.get('group').value;
    if (typeof group === 'string') {
      const matOption = this.groupMatAuto.options.find(value => value.value.name === group);
      group = matOption ? matOption.value : undefined;
    }
    if (!group) {
      return;
    }
    this.userGroups = this.userGroups.filter(removeGroup => removeGroup._id !== group._id);
    this.userGroups.push(group);
    // Reset the group value to ensure we get a dirty form
    this.groupInput.nativeElement.value = '';
    this.profileForm.get('group').setValue('');

    if (this.removeGroupIdList.includes(group._id)) {
      this.removeGroupIdList = this.removeGroupIdList.filter(remGroupId => remGroupId !== group._id);
    } else if (!this.addGroupIdList.includes(group._id)) {
      this.addGroupIdList.push(group._id);
    }
  }

  removeGroup(group: Auth0Group) {
    this.userGroups = this.userGroups.filter(removeGroup => removeGroup._id !== group._id);
    // Reset the group value to ensure we get a dirty form
    this.profileForm.get('group').markAsDirty();

    if (this.addGroupIdList.includes(group._id)) {
      this.addGroupIdList = this.addGroupIdList.filter(addGroupId => addGroupId !== group._id);
    } else if (!this.removeGroupIdList.includes(group._id)) {
      this.removeGroupIdList.push(group._id);
    }
  }

  appSettingsVisible() {
    return (this.profileForm.get('hmsEnabled').value) &&
      (this.isSelf() || this.userCanAdminister());
  }

  handleSettingsUpdated(settingsUpdated: boolean) {
    if (settingsUpdated) {
      this.profileForm.markAsDirty();
    } else if (!this.editMode) {
      this.profileForm.markAsPristine();
    }
  }

  handleSettingsValid(settingsValid: boolean) {
    this.settingsValid = this.userCanAdminister() ? settingsValid : true;
  }

  handleUserPreferencesUpdated(userPreferencesForm: FormGroup) {
    this.profileForm.get('useLiveLedgerColor').setValue(userPreferencesForm.get('useLiveLedgerColor').value);
    this.profileForm.markAsDirty();
  }

  canViewLiveLedgers() {
    return this.authzService.currentUserHasRole(UserRoles.FULL_LEDGER_VIEWER_ROLE) ||
      this.authzService.currentUserHasRole(UserRoles.LOCATION_LEDGER_VIEWER_ROLE);
  }

  // Fetch the groups that can be assigned by the admin user
  private fetchAdminGroups() {
    const adminGroups$ = this.auth0Service.getGroups(true) as Observable<Auth0Group[]>;
    adminGroups$
      .subscribe(groups => {
        this.adminGroups = groups.map(group => {
          if (this.userOnlyHasClientAdmin()) {
            // Drop off the prefix and :: separator for display
            group.name = group.name.split('::')[1] || group.name;
          }
          return group;
        });
      });
  }

  private fetchUser() {
    this.user$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        // Fetch the User to change
        return this.userService.getUserByDocId(params.get('docId'));
      }),
      switchMap(user => {
        this.user = user;
        return this.userSettingsService.getHmsSettingsByUserDocId(this.user.docId);
      }),
      switchMap(hmsSettings => {
        this.setupHmsUserSettings(hmsSettings);
        return this.userPreferencesSettingsService.getHmsPreferencesByUserDocId(this.user.docId);
      }),
      switchMap((hmsUserPreferences: HMSUserPreferences) => {
        this.setupHMSUserPreferences(hmsUserPreferences);
        if (this.user.docId === this.authService.userProfile.app_metadata.firestoreDocId) {
          return of(this.user);
        }
        return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId);
      }),
      switchMap(loggedInUser => {
        this.adminUser = loggedInUser;
        return of(this.user);
      }),
      tap(user => {
        // Fetch the groups that logged in user can administer
        if (this.userCanAdminister()) {
          this.fetchAdminGroups();
        } else {
          this.adminGroups = [];
        }

        this.setupUserForm();
        // do not retrieve groups for non-registered users (i.e. non-employee brokers)
        if (this.user.authId && this.userCanAdminister()) {
          const userGroups$: Observable<Auth0Group[]> = this.auth0Service.getUserGroups(this.user.authId) as Observable<Auth0Group[]>;
          userGroups$.subscribe(groups => {
            const displayGroups = groups.map(group => {
              if (!this.userHasUserAdmin()) {
                // Drop off the prefix and :: separator for display
                group.name = group.name.split('::')[1] || group.name;
              }
              return group;
            });
            this.userGroups = displayGroups;
            this.originalUserGroups = displayGroups;
          });
        }
      }),
      catchError(err => {
        this.errorMessage = 'This user either does not exist or you do not have permission to view the information.';
        console.error(`Error occurred fetching user and client info: ${err}`);
        return of(undefined);
      })
    );
  }

  private userHasUserAdmin(): boolean {
    return this.auth0Service.currentUserHasRole(UserRoles.USER_ADMIN_ROLE);
  }

  private userOnlyHasClientAdmin(): boolean {
    return !this.auth0Service.currentUserHasRole(UserRoles.USER_ADMIN_ROLE) &&
      this.auth0Service.currentUserHasRole(UserRoles.CLIENT_USER_ADMIN_ROLE) &&
      this.user && this.adminUser &&
      (this.user.clientDocId === this.adminUser.clientDocId ||
        this.user.clientDocId === ''
      );
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  // Initialized the FormControls with values from Firestore
  private setupUserForm() {
    this.profileForm.get('firstName').setValue(this.user.firstName);
    this.profileForm.get('lastName').setValue(this.user.lastName);
    this.profileForm.get('email').setValue(this.user.email);
    this.setupPhoneNumbers();
    this.profileForm.get('status').setValue(this.user.status);
    this.profileForm.get('marketDataFrequency').setValue(this.user.marketDataFrequency);
    this.profileForm.get('hmsEnabled').setValue(this.user.hmsEnabled);
    this.profileForm.get('group').setValue('');

    // Reset the form to pristine so we only allow actual changes to be sent
    this.profileForm.markAsPristine();
    // Reset the group change tracking arrays
    this.addGroupIdList = [];
    this.removeGroupIdList = [];
  }

  private setupPhoneNumbers() {
    const mobileFromUser = this.user.phoneNumbers.find(phoneNumber => phoneNumber.type === PhoneNumberType.MOBILE);
    const officeFromUser = this.user.phoneNumbers.find(phoneNumber => phoneNumber.type === PhoneNumberType.OFFICE);
    const tollFreeFromUser = this.user.phoneNumbers.find(phoneNumber => phoneNumber.type === PhoneNumberType.TOLL_FREE);
    const faxFromUser = this.user.phoneNumbers.find(phoneNumber => phoneNumber.type === PhoneNumberType.FAX);

    if (mobileFromUser) {
      this.mobileNumber.phoneNumber = mobileFromUser.number;
      const mobileRaw = parsePhoneNumberFromString('+' + mobileFromUser.countryCode + mobileFromUser.number);
      this.mobileNumber.selectedCountry = this.mobileNumber.allCountries.find(
        country => country.iso2.toLowerCase() === mobileRaw.country.toLowerCase());
      this.mobileNumber.onPhoneNumberChange();
    }

    if (officeFromUser) {
      this.officeNumber.phoneNumber = officeFromUser.number;
      const officeRaw = parsePhoneNumberFromString('+' + officeFromUser.countryCode + officeFromUser.number);
      this.officeNumber.selectedCountry = this.officeNumber.allCountries.find(
        country => country.iso2.toLowerCase() === officeRaw.country.toLowerCase());
      this.officeNumber.onPhoneNumberChange();
    }

    if (tollFreeFromUser) {
      this.tollFreeNumber.phoneNumber = tollFreeFromUser.number;
      const tollFreeRaw = parsePhoneNumberFromString('+' + tollFreeFromUser.countryCode + tollFreeFromUser.number);
      this.tollFreeNumber.selectedCountry = this.tollFreeNumber.allCountries.find(
        country => country.iso2.toLowerCase() === tollFreeRaw.country.toLowerCase());
      this.tollFreeNumber.onPhoneNumberChange();
    }

    if (faxFromUser) {
      this.faxNumber.phoneNumber = faxFromUser.number;
      const faxRaw = parsePhoneNumberFromString('+' + faxFromUser.countryCode + faxFromUser.number);
      this.faxNumber.selectedCountry = this.faxNumber.allCountries.find(
        country => country.iso2.toLowerCase() === faxRaw.country.toLowerCase());
      this.faxNumber.onPhoneNumberChange();
    }
  }

  private updatePhoneNumbers(formValues) {
    this.user.phoneNumbers = [];
    if (formValues.mobileNumber) {
      this.user.phoneNumbers.push({
        countryCode: this.mobileNumber.selectedCountry.dialCode,
        number: String(this.mobileNumber.phoneNumber),
        type: PhoneNumberType.MOBILE
      });
    }
    if (formValues.officeNumber) {
      this.user.phoneNumbers.push({
        countryCode: this.officeNumber.selectedCountry.dialCode,
        number: String(this.officeNumber.phoneNumber),
        type: PhoneNumberType.OFFICE
      });
    }
    if (formValues.tollFreeNumber) {
      this.user.phoneNumbers.push({
        countryCode: this.tollFreeNumber.selectedCountry.dialCode,
        number: String(this.tollFreeNumber.phoneNumber),
        type: PhoneNumberType.TOLL_FREE
      });
    }
    if (formValues.faxNumber) {
      this.user.phoneNumbers.push({
        countryCode: this.faxNumber.selectedCountry.dialCode,
        number: String(this.faxNumber.phoneNumber),
        type: PhoneNumberType.FAX
      });
    }
  }

  private setupHmsUserSettings(hmsSettings: HMSUserSettings) {
    this.hmsUserSettings = new HMSUserSettings();
    if (hmsSettings) {
      this.hmsUserSettings.accountingSystemId = hmsSettings.accountingSystemId || '';
      this.hmsUserSettings.authorizedClientDocIds = hmsSettings.authorizedClientDocIds || [];
      this.hmsUserSettings.authorizedLocationDocIds = hmsSettings.authorizedLocationDocIds || [];
      this.hmsUserSettings.contractLimits = hmsSettings.contractLimits || [];
      this.hmsUserSettings.userDocId = hmsSettings.userDocId || '';
      this.hmsUserSettings.isAuthorizedAtAllLocations = hmsSettings.isAuthorizedAtAllLocations;
      if (hmsSettings.baseLocationDocId) {
        this.hmsUserSettings.baseLocationDocId = hmsSettings.baseLocationDocId;
      }
    }
  }

  private setupHMSUserPreferences(hmsUserPreferences: HMSUserPreferences) {
    this.hmsUserPreferences = new HMSUserPreferences();
    if (hmsUserPreferences) {
      this.hmsUserPreferences.useLiveLedgerColor = hmsUserPreferences.useLiveLedgerColor;
    }
  }
}
