import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommodityProfileService } from '@advance-trading/angular-ops-data';
import { Client, CommodityProfile, Contract, ContractStatus } from '@advance-trading/ops-data-lib';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';

import * as moment from 'moment';
import { Observable } from 'rxjs';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { ClientSelectorService } from 'src/app/service/client-selector.service';
import { ContractService } from 'src/app/service/contract.service';
import { ExportService } from 'src/app/service/export.service';
import { UserRoles } from 'src/app/utilities/user-roles';

const PENDING_STATUSES = [
  ContractStatus.PENDING_BASIS,
  ContractStatus.PENDING_FUTURES,
  ContractStatus.PENDING_ORDER,
  ContractStatus.PENDING_ORDER_CANCEL,
  ContractStatus.PENDING_ORDER_RECREATE,
  ContractStatus.PENDING_ORDER_UPDATE,
  ContractStatus.PENDING_PRICE
];

const WORKING_STATUSES = [
  ContractStatus.WORKING_CASH,
  ContractStatus.WORKING_BASIS,
  ContractStatus.WORKING_FUTURES,
  ContractStatus.WORKING_PRICE
];

const FULLDATE_FORMAT = 'YYYY-MM-DD';

@Component({
  selector: 'hms-expiring-contracts',
  templateUrl: './expiring-contracts.component.html',
  styleUrls: ['./expiring-contracts.component.scss']
})
export class ExpiringContractsComponent implements OnInit {
  expiringContractsSearchForm: FormGroup = this.formBuilder.group({
    endDate: ['', { updateOn: 'blur' }]
  });

  filteredCommodityProfiles$: Observable<CommodityProfile[]>;

  errorMessage: string;
  isLoading = true;
  displayReports = false;

  expiringContracts$: Observable<Contract[]>;
  commodityProfileMap: { [key: string]: CommodityProfile };
  dataSourceMap: { [key: string]: MatTableDataSource<Contract> } = {};
  columnsToDisplay;
  footerColumnsToDisplay = ['footer'];

  minEndDate = moment().startOf('day').toISOString();

  private queryParams: Params;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private breakpointObserver: BreakpointObserver,
    private clientSelectorService: ClientSelectorService,
    private commodityProfileService: CommodityProfileService,
    private contractService: ContractService,
    public exportService: ExportService,
    private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.CONTRACT_REPORTS_GENERATOR_ROLE)) {
      this.errorMessage = 'You do not have permission to access the expiring contracts report.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(state => {
      // display columns for xsmall screen
      if (state.breakpoints[Breakpoints.XSmall]) {
        this.columnsToDisplay = [
          'expirationDate', 'patronName', 'quantity'
        ];
      } else if (state.breakpoints[Breakpoints.Small]) {
        this.columnsToDisplay = [
          'expirationDate', 'deliveryPeriod', 'futuresYearMonth',
          'status', 'patronName', 'type', 'quantity', 'basisPrice', 'futuresPrice',
          'cashPrice'
        ];
        // display columns for larger screens
      } else {
        this.columnsToDisplay = [
          'accountingSystemId', 'expirationDate',
          'deliveryPeriod', 'futuresYearMonth', 'originatorName',
          'status', 'patronName', 'type', 'quantity',
          'basisPrice', 'futuresPrice', 'cashPrice'
        ];
      }
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      this.queryParams = Object.assign({}, params);
      if (this.queryParams.endDate) {
        this.expiringContractsSearchForm.get('endDate').setValue(moment(this.queryParams.endDate).toISOString());
        this.expiringContractsSearchForm.markAsDirty();
        this.loadExpiringContractsReportData();
      } else {
        // set initial form by resetting the form to its default value
        this.expiringContractsSearchForm.get('endDate').setValue(moment().endOf('month').toISOString());
        this.expiringContractsSearchForm.markAsDirty();
        this.isLoading = false;
      }
    });
  }

  accountNumberAndProfileAscOrder = (nameA: KeyValue<string, CommodityProfile>, nameB: KeyValue<string, CommodityProfile>): number => {
    const accountAndProfileA = nameA.value.officeCode + nameA.value.accountNumber + nameA.value.name;
    const accountAndProfileB = nameB.value.officeCode + nameB.value.accountNumber + nameA.value.name;
    return accountAndProfileA.localeCompare(accountAndProfileB);
  }

  reset() {
    this.expiringContractsSearchForm.get('endDate').setValue('');
    this.expiringContractsSearchForm.markAsPristine();
    // Clear out queryParams so values aren't forced back in
    this.queryParams = {} as Params;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });
    this.displayReports = false;
  }

  loadExpiringContractsReportData() {
    if (!this.expiringContractsSearchForm.get('endDate').value) {
      this.expiringContractsSearchForm.get('endDate').setValue(moment().endOf('month').toISOString());
    }
    const endDate = moment(this.expiringContractsSearchForm.get('endDate').value).format(FULLDATE_FORMAT);

    // store FULLDATE_FORMAT date instead of ISO string since current moment library doesn't support changing
    // timezone to central time
    this.queryParams.endDate = endDate;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });

    this.isLoading = true;
    let selectedClientDocId;
    this.expiringContracts$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((client: Client) => {
        selectedClientDocId = client.docId;
        return this.commodityProfileService.getActiveCommodityProfilesByTypeAndClientDocId(selectedClientDocId);
      }),
      switchMap((commodityProfiles: CommodityProfile[]) => {
        this.commodityProfileMap = {};
        commodityProfiles.forEach((commodityProfile: CommodityProfile) => {
          this.commodityProfileMap[commodityProfile.commodityId] = commodityProfile;
        });
        return combineLatest([
          this.contractService.getExpiringContractsByStatus(selectedClientDocId, PENDING_STATUSES, endDate),
          this.contractService.getExpiringContractsByStatus(selectedClientDocId, WORKING_STATUSES, endDate)
        ]);
      }),
      map(([pendingExpContracts, workingExpContracts]) => {
        // sort retrieved expiring contracts by ascending expirationDate
        return pendingExpContracts.concat(workingExpContracts)
          .sort((contractA, contractB) => contractA.expirationDate.localeCompare(contractB.expirationDate));
      }),
      tap((contracts: Contract[]) => {
        this.populateDatasource(contracts);
        this.displayReports = true;
        this.isLoading = false;
      }),
      catchError(err => {
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar('Error running report: ' + errorMsg, 'DISMISS', false);
        console.error(`Error retrieving reportable items: ${err}`);
        this.isLoading = false;
        return of([]);
      })
    );
  }

  selectContract(contract: Contract) {
    this.router.navigate(['contracts', contract.docId]);
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    }
    return 'Unknown Error';
  }

  getExportName(commodityId: string) {
    return `Expiring Contracts Report-${commodityId}`;
  }

  private populateDatasource(contracts: Contract[]) {
    this.dataSourceMap = {};

    // initialize each table's datasource for each account
    Object.keys(this.commodityProfileMap).forEach((commodityId: string) => {
      this.dataSourceMap[commodityId] = new MatTableDataSource<Contract>();
      this.dataSourceMap[commodityId].data = [];
    });

    // populate table datasource
    contracts.forEach((contract: Contract) => {
      this.dataSourceMap[contract.commodityId].data.push(contract);
    });
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}
