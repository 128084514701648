<div id="fab-dismiss"
     *ngIf="fabTogglerState==='active'"
     (click)="onToggleFab()">
</div>
<div class="fab-container">
    <button data-cy="fab-toggler-btn" mat-fab class="fab-toggler"
            id="speed-dialer-open"
            aria-label="Open Speed Dial"
            [matTooltip]="tooltip"
            matTooltipPosition="left"
            (click)="onToggleFab()">
      <i class="material-icons" [@fabToggler]="{value: fabTogglerState}">add</i> <!-- Animation here -->
    </button>
    <div [@speedDialStagger]="buttons.length"> <!-- and here -->
      <button *ngFor="let btn of buttons"
              [matTooltip]="btn.label"
              [id]="btn.id"
              [attr.aria-label]="btn.label"
              [attr.data-cy]="btn.id"
              matTooltipPosition="left"
              mat-mini-fab
              class="fab-secondary"
              color="secondary"
              (click)="onNavigate(btn.uri, btn.queryParams)">
        <mat-icon [svgIcon]="btn.svgIcon"></mat-icon>
      </button>
    </div>
  </div>
