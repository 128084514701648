<form [formGroup]="priceAdjustmentForm">
  <div fxLayout.xs="row wrap" fxLayoutAlign="space-between start" fxLayout.gt-xs="row">
    <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="15" fxFlex.xs="25" label="ID" [data]="priceAdjustmentForm.get('id').value"></ati-labeled-data>
    <mat-form-field *ngIf="editMode" fxFlex.gt-xs="15" fxFlex.xs="25" >
      <input required matInput placeholder="ID" formControlName="id" trim="blur">
      <mat-error *ngIf="priceAdjustmentForm.get('id').invalid">{{ getErrorMessage(priceAdjustmentForm.get('id')) }}</mat-error>
    </mat-form-field>
    <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="55" fxFlex.xs="75" label="Name" [data]="priceAdjustmentForm.get('name').value"></ati-labeled-data>
    <mat-form-field *ngIf="editMode" [fxFlex.gt-xs]="55" fxFlex.xs="40">
      <input required matInput placeholder="Name" formControlName="name" trim="blur">
      <mat-error *ngIf="priceAdjustmentForm.get('name').invalid">{{ getErrorMessage(priceAdjustmentForm.get('name')) }}</mat-error>
    </mat-form-field>
    <div fxFlex.gt-xs="20" fxFlex.xs="25">
      <button [id]="(priceAdjustment ? 'update' : 'add') + '-price-adjustment'" type="button" [attr.aria-label]="(priceAdjustment ? 'Update' : 'Add') + ' Price Adjustment'" color="accent" *ngIf="editMode" [disabled]="priceAdjustmentForm.invalid || !editMode || priceAdjustmentForm.pristine" mat-icon-button (click)="onPriceAdjustmentUpdated()" [matTooltip]="(priceAdjustment? 'Update' : 'Add') + ' Price Adjustment'">
        <mat-icon>check_circle</mat-icon>
      </button>
      <button id="remove-price-adjustment" type="button" aria-label="Remove Price Adjustment" color="warn" *ngIf="editMode && priceAdjustment" mat-icon-button (click)="onPriceAdjustmentRemoved()" matTooltip="Remove Price Adjustment">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</form>
