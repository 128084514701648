<div fxFlex="100">

  <div *ngIf="!errorMessage" fxLayout="column">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="targets-filter-field" #filter>
      <button type="button" id="clear-targets-filter" mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <button *ngIf="exportable" aria-label="Export" id="export-targets" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
    <mat-menu #exportMenu="matMenu">
      <button type="button" id="export-targets-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Targets'))">Excel</button>
      <button type="button" id="export-targets-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Targets'))">CSV</button>
      <button type="button" id="export-targets-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Targets'))">JSON</button>
    </mat-menu>

    <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort class="mat-elevation-z0" (matSortChange)="handleSortChange()">

      <ng-container matColumnDef="accountingSystemId">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="15" fxFlex.sm="8" fxFlex.md="9" fxFlex.gt-md="7">ID</mat-header-cell>
        <mat-cell *matCellDef="let target" fxFlex.xs ="15" fxFlex.sm="8" fxFlex.md="9" fxFlex.gt-md="7">{{ target.accountingSystemId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="creationTimestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.sm="14" fxFlex.md="10" fxFlex.gt-md="9">Created</mat-header-cell>
        <mat-cell *matCellDef="let target" fxFlex.sm="14" fxFlex.md="10" fxFlex.gt-md="9"><span class="forced-wrap">{{ target.creationTimestamp | date: 'MM/dd/yyyy hh:mm:ss a' }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastUpdatedTimestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="27" fxFlex.sm="14" fxFlex.md="10" fxFlex.gt-md="9">Updated</mat-header-cell>
        <mat-cell *matCellDef="let target" fxFlex.xs="27" fxFlex.sm="14" fxFlex.md="10" fxFlex.gt-md="9"><span class="forced-wrap">{{ target.lastUpdatedTimestamp | date: 'MM/dd/yyyy hh:mm:ss a' }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="commodityId">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="6" fxFlex.gt-md="4">Cmdty</mat-header-cell>
        <mat-cell *matCellDef="let target" fxFlex.xs="14" fxFlex.sm="8" fxFlex.md="6" fxFlex.gt-md="4">{{ target.commodityId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="deliveryPeriod">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="5">Delivery</mat-header-cell>
        <mat-cell *matCellDef="let target" fxFlex.gt-md="5">{{ target.deliveryPeriod }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="futuresYearMonth">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.md="6" fxFlex.gt-md="5">Futures</mat-header-cell>
        <mat-cell *matCellDef="let target" fxFlex.md="6" fxFlex.gt-md="5">{{ target.futuresYearMonth }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="originatorName">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="9">Originator</mat-header-cell>
        <mat-cell *matCellDef="let target" fxFlex.gt-md="9">{{ target.originatorName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="clientLocationName">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-md="9">Location</mat-header-cell>
        <mat-cell *matCellDef="let target" fxFlex.gt-md="9">{{ target.clientLocationName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="patronName">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.sm="12" fxFlex.md="13" fxFlex.gt-md="9">Patron</mat-header-cell>
        <mat-cell *matCellDef="let target" fxFlex.sm="12" fxFlex.md="13" fxFlex.gt-md="9">{{ target.patronName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.sm="6" fxFlex.md="7" fxFlex.gt-md="5">Type</mat-header-cell>
        <mat-cell *matCellDef="let target" fxFlex.sm="6" fxFlex.md="7" fxFlex.gt-md="5">{{ target.type === 'HTA' || target.type === 'DP' ? target.type : target.type | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs ="22" fxFlex.sm="11" fxFlex.md="12" fxFlex.gt-md="10">Status</mat-header-cell>
        <mat-cell *matCellDef="let target" fxFlex.xs="22" fxFlex.sm="11" fxFlex.md="12" fxFlex.gt-md="10">{{ target.status | replace | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="side">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.sm="6" fxFlex.md="6" fxFlex.gt-md="5">Side</mat-header-cell>
        <mat-cell *matCellDef="let target" fxFlex.sm="6" fxFlex.md="6" fxFlex.gt-md="5">{{ target.side | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="22" fxFlex.sm="13" fxFlex.md="14" fxFlex.gt-md="9">Qty</mat-header-cell>
        <mat-cell *matCellDef="let target" fxFlex.xs="22" fxFlex.sm="13" fxFlex.md="14" fxFlex.gt-md="9">{{ target.quantity | number:'1.0-2' }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="targetPrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.sm="8" fxFlex.md="7" fxFlex.gt-md="5">Target</mat-header-cell>
        <mat-cell *matCellDef="let target" fxFlex.sm="8" fxFlex.md="7" fxFlex.gt-md="5">{{ target.targetPrice | number:'1.4-4' }}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row (click)="selectTarget(target)" *matRowDef="let target; columns: columnsToDisplay"></mat-row>
    </mat-table>
    <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50]" (page)="handlePageChange()"></mat-paginator>

  </div>

</div>
