import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { AuthService } from '@advance-trading/angular-ati-security';

import { UserStatusService } from '../auth/user-status.service';

@Component({
  selector: 'hms-callback',
  template: `
    <ng-container *ngIf="errorMessage$ | async"></ng-container>
    <div *ngIf="!errorMessage" fxLayout="column" fxFill>
      <div fxLayoutAlign="center center" fxLayout="column" fxFlex="90">
        <mat-spinner diameter="60" color="accent"></mat-spinner>
        <p>Loading...</p>
      </div>
    </div>
    <div *ngIf="errorMessage" fxLayout="row" fxLayoutAlign="space-around start">
      <mat-card fxFlex.lt-md="95" fxFlex.md="75" fxFlex.gt-md="65">
        <div>
        <hms-error-message [title]="'Error Loading HMS'" [message]="errorMessage"></hms-error-message>
        </div>
      </mat-card>
    </div>
  `,
  styles: [`:host {flex: 1 1 auto;}`]
})
export class CallbackComponent implements OnInit {
  // The object to indicate an error occurred
  errorMessage$: Observable<string>;
  errorMessage = '';

  constructor(
    private authService: AuthService,
    private userStatusService: UserStatusService
  ) { }

  ngOnInit() {
    // Subscribe to handleAuthCallback observable
    // Response will be the accessToken but it is unused
    this.errorMessage$ = this.authService.handleAuthCallback().pipe(
      switchMap(_ => {
        // Get the userProfile after authentication complete
        return this.authService.userProfile$;
      }),
      switchMap(userProfile => {
        return this.userStatusService.checkActiveUser(userProfile, true);
      }),
      tap(errorMessage => this.errorMessage = errorMessage)
    );
  }

}
