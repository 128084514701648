import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { combineLatest, concat, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, take, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { PatronService } from '@advance-trading/angular-ops-data';
import { NgxMatIntlTelInputComponent } from '@advance-trading/ngx-mat-intl-tel-input';
import { Client, HMSClientSettings, Patron, PhoneNumber, PhoneNumberType } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { UserRoles } from '../../utilities/user-roles';
import { UniqueKeyValidator } from '../../utilities/validators/unique-key-validator';

@Component({
  selector: 'hms-patron-detail',
  templateUrl: './patron-detail.component.html',
  styleUrls: ['./patron-detail.component.scss']
})
export class PatronDetailComponent implements OnInit {
  errorMessage: string;
  editMode = false;
  updateComplete = true;
  enableAddress = false;
  isLoading = false;

  allValidEmailsObservable$: Observable<string[]>;

  patron$: Observable<Patron>;
  sendPatronNotifications: boolean;
  private patron: Patron;

  @ViewChild('phoneNumber', { static: false }) phoneNumber: NgxMatIntlTelInputComponent;

  patronForm: FormGroup = this.formBuilder.group({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(200)]),
    isActive: new FormControl(),
    hasBadCredit: new FormControl(false),
    address: new FormGroup({
      street1: new FormControl('', [
        Validators.required,
        Validators.maxLength(200)
      ]),
      street2: new FormControl('', [
        Validators.maxLength(200)
      ]),
      city: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      region: new FormControl('', [
        Validators.required
      ]),
      postalCode: new FormControl('', [
        Validators.required
      ]),
      country: new FormControl('', [
        Validators.required
      ])
    }),
    emails: new FormArray([]),
    newEmail: new FormControl('', [Validators.maxLength(200), Validators.email])
  });

  phoneNumbers: PhoneNumber[] = [];
  emails: string[] = [];
  emailNotifications: string[] = [];
  smsNotifications: string[] = [];

  private patronDocId: string;
  private selectedClientDocId = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private formBuilder: FormBuilder,
    private patronService: PatronService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.patronForm.disable();
    this.patronForm.get('address').disable();

    this.patron$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((client: Client) => {
        this.selectedClientDocId = client.docId;
        return this.clientSettingsService.getHmsSettingsByClientDocId(this.selectedClientDocId);
      }),
      switchMap((clientSettings: HMSClientSettings) => {
        this.sendPatronNotifications = clientSettings.sendPatronNotifications;
        return this.activatedRoute.paramMap;
      }),
      switchMap((paramMap: ParamMap) => {
        this.patronDocId = paramMap.get('docId');
        return this.patronService.getPatronByDocId(this.selectedClientDocId, this.patronDocId);
      }),
      tap((patron: Patron) => {
        this.patron = patron;
        this.setEditMode(false);
        this.isLoading = false;
      })
    );

    // define what fields will be watched for valueChanges to be available for emailNotifications
    const emailInputObservables = combineLatest([
      // separately initializing the startsWith value for each observable because otherwise, it wouldn't emit until both had emitted
      this.patronForm.get('newEmail').valueChanges.pipe(startWith(false)),
      this.patronForm.get('emails').valueChanges.pipe(startWith(false))
    ]);

    this.allValidEmailsObservable$ = concat(
      // emit first set immediately when edit mode changes
      emailInputObservables.pipe(take(1)),
      // provide 500 millisecond delay after an email address is updated thereafter before reflecting changes in the notifications below
      // still returning this.allValidEmailAddresses so as not to recreate the logic or separately validate the subscription return
      emailInputObservables.pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
    ).pipe(map(() => this.allValidEmailAddresses));

  }

  get isPatronAdmin() {
    return this.authzService.currentUserHasRole(UserRoles.PATRON_ADMIN_ROLE);
  }

  setEditMode(mode) {
    this.editMode = mode;
    if (this.editMode) {
      this.patronForm.enable();
      if (!this.enableAddress) {
        this.patronForm.get('address').disable();
      }
    } else { // Abandon an update
      this.patronForm.disable();
      this.setupPatronForm();
    }
  }

  async saveForm() {
    this.updateComplete = false;

    const formValues = this.patronForm.getRawValue();
    this.patron.name = formValues.name;
    this.patron.isActive = formValues.isActive;
    this.patron.hasBadCredit = formValues.hasBadCredit;
    this.patron.phoneNumbers = this.phoneNumbers;
    this.patron.emails = this.allValidEmailAddresses;

    if (this.sendPatronNotifications) {
      this.patron.smsNotifications = this.smsNotifications;
      this.patron.emailNotifications = this.emailNotifications;
    }

    if (!this.enableAddress) {
      delete this.patron.address;
    } else {
      this.patron.address = formValues.address;
    }

    this.patronService.updatePatron(this.selectedClientDocId, this.patron)
      .then(() => {
        this.updateComplete = true;
        console.log('Patron successfully updated');
        this.openSnackBar('Patron successfully updated', 'DISMISS', true);
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`Patron update failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Patron update failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  private setupPatronForm() {
    // Check necessary to prevent any race condition in our test suite
    if (this.phoneNumber) {
      this.phoneNumber.phoneNumber = '';
    }

    if (this.patron.address) {
      this.enableAddress = true;
    }

    this.patronForm.reset();
    this.patronForm.patchValue(this.patron);
    // explicitly set value to false if undefined to avoid storing null in firestore if control untouched
    if (typeof this.patron.hasBadCredit !== 'boolean') {
      this.patronForm.get('hasBadCredit').setValue(false);
    }

    // Set the street2 to empty string if it is null or undefined
    if (!this.patronForm.get('address').get('street2').value) {
      this.patronForm.get('address').get('street2').setValue('');
    }

    this.phoneNumbers = Array.from(this.patron.phoneNumbers);

    const emailControlArray = this.patronForm.get('emails') as FormArray;
    emailControlArray.clear();
    this.patron.emails.forEach(emailAddress => emailControlArray.push(this.getEmailControl(emailAddress)));
    this.removeInvalidEmailNotificationsAndResetValidators();
    // allow fallthrough if arrays don't exist on legacy data
    this.smsNotifications = Array.from(this.patron.smsNotifications || []);
    this.emailNotifications = Array.from(this.patron.emailNotifications || []);

    this.patronForm.markAsPristine();
  }

  showAddress() {
    this.enableAddress = true;
    this.patronForm.get('address').enable();
  }

  hideAddress() {
    this.enableAddress = false;
    this.patronForm.get('address').disable();
  }

  clearAddress() {
    this.hideAddress();
    this.patronForm.markAsDirty();
  }

  onPhoneNumberCreated(phoneNumber: PhoneNumber) {
    this.phoneNumbers.push(phoneNumber);
    this.patronForm.markAsDirty();
  }

  onPhoneNumberRemoved(index?: number) {
    this.phoneNumbers.splice(index, 1);
    this.smsNotifications = this.smsNotifications.filter(notification => this.mobilePhones.includes(notification));
    this.patronForm.markAsDirty();
  }

  onPhoneNumberUpdated(index: number, event: PhoneNumber) {
    this.phoneNumbers[index] = event;
    this.patronForm.markAsDirty();
    this.smsNotifications = this.smsNotifications.filter(notification => this.mobilePhones.includes(notification));
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('maxlength')) {
      return 'Value cannot exceed ' + control.errors['maxlength'].requiredLength + ' characters';
    } else if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('email')) {
      return 'Invalid email';
    } else if (control.hasError('uniqueKey')) {
      return 'Email already exists';
    }
    return 'Unknown error';
  }

  onSmsNotificationChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.smsNotifications.push(event.source.value);
    } else {
      this.smsNotifications = this.smsNotifications.filter(arrayItem => arrayItem !== event.source.value);
    }
    this.patronForm.markAsDirty();
  }

  onEmailNotificationChange(event: MatCheckboxChange) {
    if (event.checked) {
      this.emailNotifications.push(event.source.value);
    } else {
      this.emailNotifications = this.emailNotifications.filter(arrayItem => arrayItem !== event.source.value);
    }
    this.patronForm.markAsDirty();
  }

  addEmailForm() {
    const emailControlArray = this.patronForm.get('emails') as FormArray;
    emailControlArray.push(this.getEmailControl(this.patronForm.get('newEmail').value));
    emailControlArray.markAsDirty();
    this.patronForm.get('newEmail').setValue('');
    this.removeInvalidEmailNotificationsAndResetValidators();
  }

  getEmailLabel(index: number): string {
    return `Email ${(index + 1)}`;
  }

  removeInvalidEmailNotificationsAndResetValidators(index?: number) {
    // if this function is called with the argument index, it's the blur event of a specific field. We don't want to reset
    // the validators if it is invalid due to a unique key validation (both fields will end up having the same value and be valid)
    if (Number.isFinite(index) && !this.existingEmailControls[index].valid) {
      return;
    }

    // clear out notifications from the list of emails available for notifications if they're no longer relevant
    // e.g. an existing email removed or updated
    this.emailNotifications = this.emailNotifications.filter(emailAddress => this.allValidEmailAddresses.includes(emailAddress));

    // pull all valid email addresses deliberately excluding the newEmail field, otherwise if it's got a valid entry and
    // you edit an existing field then blur, the newEmail field becomes invalid. This will be used as the basis for the uniqueKey validator
    const validEmailAddresses = this.existingEmailControls.filter(emailControl => this.hasValidValue(emailControl))
      .map(emailField => emailField.value);
    // set validators on existingEmailForms
    this.existingEmailControls.forEach(emailControl => {
      if (emailControl.valid) {
        // validEmailAddresses currently contains the emailField.value, so it must be excluded from the validator to prevent it
        // from getting flagged as invalid
        const otherEmails = validEmailAddresses.filter(emailAddress => emailAddress.toLowerCase() !== emailControl.value.toLowerCase());
        emailControl.setValidators([Validators.email, Validators.maxLength(200), UniqueKeyValidator.uniqueKey(otherEmails)]);
        emailControl.updateValueAndValidity();
      }
    });
    // set validators on newEmail field. Field value will not be in validEmailAddresses, so no filter necessary
    this.patronForm.get('newEmail').setValidators([
      Validators.email,
      Validators.maxLength(200),
      UniqueKeyValidator.uniqueKey(validEmailAddresses)]);
    this.patronForm.get('newEmail').updateValueAndValidity();
  }

  removeEmailForm(index: number) {
    const emailFieldArray = this.patronForm.get('emails') as FormArray;
    emailFieldArray.removeAt(index);
    this.removeInvalidEmailNotificationsAndResetValidators();
    emailFieldArray.markAsDirty();
  }

  get allValidEmailAddresses(): string[] {
    const validEmails = this.existingEmailControls.filter(emailControl => this.hasValidValue(emailControl))
      .map(emailControl => emailControl.value);
    if (this.hasValidValue(this.patronForm.get('newEmail'))) {
      validEmails.push(this.patronForm.get('newEmail').value);
    }
    return validEmails;
  }

  get existingEmailControls(): FormControl[] {
    const emailFieldArray = this.patronForm.get('emails') as FormArray;
    return emailFieldArray.controls as FormControl[];
  }

  get mobilePhones(): string[] {
    return this.phoneNumbers.filter(phoneNumber => phoneNumber.type === PhoneNumberType.MOBILE)
      .map(phoneNumber => this.formatPhoneNumberForSmsNotificationStorage(phoneNumber));
  }

  private formatPhoneNumberForSmsNotificationStorage(phoneNumber: PhoneNumber): string {
    return `+${phoneNumber.countryCode}${phoneNumber.number}`;
  }

  private hasValidValue(formControl: AbstractControl): boolean {
    return formControl.value && formControl.valid;
  }

  private getEmailControl(emailAddress: string): FormControl {
    return this.formBuilder.control(emailAddress, [Validators.email, Validators.maxLength(200),
    UniqueKeyValidator.uniqueKey(this.allValidEmailAddresses.filter(email => email.toLowerCase() !== emailAddress.toLowerCase()))]);
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }


}
