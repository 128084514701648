import { Component, Input } from '@angular/core';

/**
 * Provides a standard way to build and show error messages.
 */
export interface ErrorMessage {
  /** The title displayed above the error message. */
  title: string;
  /** Message to display within the error. */
  message: string;
  /** Contact information given will override the default contact info. */
  contactInfo?: string;
  /** The subject of the error message. */
  subject?: string;
  /** The type of error message. */
  type?: ErrorMessageType
}

/** Error message types, based on message predicates. */
export enum ErrorMessageType {
  ErrorRetrieving,
  ErrorPerforming
}

/**
 * Displays an error message to the user with contact information to help resolve
 * the error. **This component relies on a `message` being passed in, it will not show
 * if no message is passed.**
 */
@Component({
  selector: 'hms-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent {

  /** A default contact information string, used by all types of error messages. */
  readonly DefaultContactInfo =  `If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
  or call us at <a href="tel:800-747-9021">800-747-9021</a>`

    /** The default 'Error'. */
  readonly Error: string = 'Error'

  /** Private title reference. */
  private _title: string = this.Error

  /**
   * Content displayed above the message in a header. Default value:
   * ```string
   * Error
   * ```
   * @param value The message title. If passed a falsy value, uses default value.
   */
  @Input() set title(value: string) {
    if (value) {
      this._title = value;
    } else {
      this._title = this.Error
    }
  }
  get title(): string { return this._title }

  /**
   * Set the error message through a bindable property.
   * @param message An `ErrorMessage` object to set the error message.
   * If a `subject` and a `type` is given, a title is auto generated
   * Example:
   * ```string
   * subject = 'Closest to Market Report'
   * type = 'ErrorRetrieving'
   * resulting message = 'Error Retrieving Closest to Market Report'
   * ```
   * **NOTE :** If a subject and a type is given _with a title_, the title
   * will be overridden by the generated string from the subject and type.
   */
  @Input() set errorMessage(message: ErrorMessage) {
    if (typeof message === null || typeof message === undefined) {
      return
    }

    const generateTitle = message.subject && message.type;
    if (generateTitle) {
      this.title = this.generateTitle(message.subject, message.type);
    } else {
      this.title = message.title
    }
    this.message = message.message;
    this.contactInformation = message.contactInfo ? message.contactInfo : this.Error
  }

  private _contactInformation: string = this.DefaultContactInfo
  /**
   * Set contact information that should be displayed to the user. **NOTE :** If no
   * message is passed, this component will not display!
   * Message will be inserted as HTML node, rendering any HTML in message string.
   * Default value:
   * ```string
   * If you have questions, please contact us by
   * <a href="mailto:support@advance-trading.com">email</a>or call us at <a href="tel:800-747-9021">800-747-9021</a>
   * ```
   */
  @Input() set contactInformation(value: string) {
    if (value) {
      this._contactInformation = value;
    } else {
      this._contactInformation = this.DefaultContactInfo;
    }
  }
  get contactInformation(): string { return this._contactInformation; }

  /** Message displayed to the user. */
  @Input() message = '';

  /**
   * Generate title of error message component
   * @param subject The subject of the error message title.
   * @param type The type of title to generate.
   */
  generateTitle = (subject: string, type: ErrorMessageType): string => {
    if (subject) {
      switch (type) {
        case ErrorMessageType.ErrorPerforming:
          return `Error Performing ${subject}`
        case ErrorMessageType.ErrorRetrieving:
          return `Error Retrieving ${subject}`
      }
    } else {
      return this.Error
    }
  }

  constructor() {}
}
