import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, shareReplay, switchMap } from 'rxjs/operators';

import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommodityProfileService, OperationsDataService, UserService } from '@advance-trading/angular-ops-data';
import {
  Client,
  Commodity,
  CommodityMap,
  CommodityProfile,
  CommodityProfileProductionYear,
  HMSClientSettings,
  LedgerAdjustment,
  LedgerAdjustmentType,
  User
} from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { LedgerAdjustmentService } from '../../service/ledger-adjustment.service';
import { LedgerHelperService } from '../../service/ledger-helper.service';
import { UserRoles } from '../../utilities/user-roles';

const QUANTITY_REGEX = /^-?(?=.*[1-9])\d*(?:\.\d{0,2})?$/;

@Component({
  selector: 'hms-new-ledger-adjustment',
  templateUrl: './new-ledger-adjustment.component.html',
  styleUrls: ['./new-ledger-adjustment.component.scss']
})
export class NewLedgerAdjustmentComponent implements OnInit {
  adjustmentForm: FormGroup = this.formBuilder.group({
    commodityProfile: ['', [Validators.required]],
    prodYear: ['', [Validators.required]],
    quantity: ['', [Validators.required, Validators.pattern(QUANTITY_REGEX)]],
    comments: ['', [Validators.maxLength(400)]]
  });

  updateComplete = true;
  errorMessage: string;

  prodYears: CommodityProfileProductionYear[] = [];
  commodities: Commodity[];

  // form data
  commodityProfiles$: Observable<CommodityProfile[]>;

  private selectedClientDocId: string;
  private loggedInUser: User;
  private ledgerEndOfDay: string;
  private timezone: string;

  constructor(
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private formBuilder: FormBuilder,
    private ledgerAdjustmentService: LedgerAdjustmentService,
    private ledgerHelperService: LedgerHelperService,
    private operationsDataService: OperationsDataService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.LEDGER_ADJUSTMENT_ADMIN_ROLE)) {
      this.errorMessage = 'You do not have permission to create ledger adjustments.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.commodityProfiles$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((doc: CommodityMap) => {
        this.commodities = Object.values(doc.commodities);
        return this.clientSelectorService.getSelectedClient();
      }),
      switchMap((selectedClient: Client) => {
        this.selectedClientDocId = selectedClient.docId;
        return this.clientSettingsService.getHmsSettingsByClientDocId(this.selectedClientDocId);
      }),
      switchMap((clientSettings: HMSClientSettings) => {
        this.ledgerEndOfDay = clientSettings.ledgerEndOfDay;
        this.timezone = clientSettings.timezone;
        return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId);
      }),
      switchMap((user: User) => {
        this.loggedInUser = user;
        return this.commodityProfileService.getActiveCommodityProfilesByTypeAndClientDocId(this.selectedClientDocId, false);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving commodity profiles; please try again later';
        console.error(`Error retrieving commodity profiles: ${err}`);
        return of([]);
      })
    );
    this.setupCommodityProfileListener();
  }

  submit() {
    // Create a new ledger adjustment object to store in the database
    const newAdjustment = new LedgerAdjustment();
    const commodityProfile = this.adjustmentForm.get('commodityProfile').value;
    const productionYear = this.adjustmentForm.get('prodYear').value;
    newAdjustment.commodityProfileDocId = commodityProfile.docId;
    newAdjustment.productionYear = productionYear.year;
    newAdjustment.quantity = parseFloat(this.adjustmentForm.get('quantity').value);
    newAdjustment.type = LedgerAdjustmentType.STANDARD;
    newAdjustment.creatorDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    const userName = `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`;
    newAdjustment.creatorName = userName;
    newAdjustment.lastUpdatedByName = userName;

    const comments = this.adjustmentForm.get('comments').value;
    if (comments) {
      newAdjustment.comments = comments;
    }

    // Create new ledger adjustment by calling the ledger adjustment service
    this.updateComplete = false;
    this.ledgerAdjustmentService.createLedgerAdjustment(this.selectedClientDocId, newAdjustment)
      .then(() => {
        this.updateComplete = true;
        console.log('Ledger adjustment successfully created');
        this.openSnackBar('Ledger adjustment successfully created', 'DISMISS', true);
        this.router.navigate(['/liveledgers/activityledger'], { replaceUrl: true });
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`Ledger adjustment creation failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Ledger adjustment creation failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  reset() {
    this.adjustmentForm.reset();
    this.adjustmentForm.markAsPristine();
  }

  clearField(fieldName: string) {
    const field = this.adjustmentForm.get(fieldName);
    field.setValue('');
    field.markAsDirty();
  }

  getContractUnit(commodities: Commodity[], commodityProfile: CommodityProfile) {
    return commodities.find(commodity => commodity.id === commodityProfile.commodityId).contractUnit;
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value Required';
    } else if (control.hasError('pattern')) {
      return 'Value invalid';
    } else if (control.hasError('maxlength')) {
      return 'Value cannot exceed ' + control.errors['maxlength'].requiredLength + ' characters';
    }
    return 'Unknown Error';
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private setupCommodityProfileListener() {
    this.adjustmentForm.get('commodityProfile').valueChanges.subscribe((profile: CommodityProfile) => {
      if (profile) {
        this.prodYears = Object.values(
          this.ledgerHelperService.getTranslatedUniqueActiveProdYears(profile.productionYears, this.ledgerEndOfDay, this.timezone)
        );
      } else {
        this.prodYears = [];
      }
      this.adjustmentForm.get('prodYear').setValue('');
    });
  }
}
