import { FormControl } from '@angular/forms';
import { WHOLE_CENT_REGEX } from '../../utilities/validators/price.validator';

// Allow +/- number as input to indicate increment or decrement (multiple of a quarter cents)
export const ADJUSTMENT_REGEX = /^[+-](\d+|\d*\.\d{1,2}|\d*\.\d{2}(0|(00)|(25)|(50)|(5)|(75))|\d+\.\d{0,2}|\d+\.\d{0,2}(0|(00)|(25)|(50)|(5)|(75)))$/;
// Allow +/- number as input to indicate increment or decrement (up to 2 decimal places)
export const WHOLE_CENT_ADJUSTMENT_REGEX = /^[+-](\d+|\d*\.\d{1,2}|\d*\.\d{2})$/;
// Allow both positive and negative numbers, with no more than 4 digits after a decimal (multiple of a quarter cents)
export const BASIS_REGEX = /^-?(\d+|\d*\.\d{1,2}|\d*\.\d{2}(0|(00)|(25)|(50)|(5)|(75))|\d+\.\d{0,2}|\d+\.\d{0,2}(0|(00)|(25)|(50)|(5)|(75)))$/;
// Allow a 2 digit year and specific month codes
export const MONTH_CODE_REGEX = /^\d{2}[F|G|H|J|K|M|N|Q|U|V|X|Z]$/;

export class BasisAdminValidators {
  static basisValidator(control: FormControl): {[key: string]: boolean} | null {
    const basis = control.value ? control.value['basis'] : '';
    const invalidBasis = basis ? !BASIS_REGEX.test(basis) : false;
    return invalidBasis ? {invalidBasis: true} : null;
  }

  static wholeCentBasisValidator(control: FormControl): { [key: string]: boolean } | null {
    const price = control.value ? control.value['basis'] : '';
    const invalidWholeCentPrice = price ? !WHOLE_CENT_REGEX.test(price) : false;
    return invalidWholeCentPrice ? { invalidWholeCentPrice: true } : null;
  }

  static futuresYearMonthValidator(control: FormControl): {[key: string]: boolean} | null {
    const futuresYearMonth = control.value ? control.value['futuresYearMonth'] : '';
    const invalidFuturesYearMonth = futuresYearMonth ? !MONTH_CODE_REGEX.test(futuresYearMonth) : false;
    return invalidFuturesYearMonth ? {invalidFuturesYearMonth: true} : null;
  }

  static fieldDependencyValidator(control: FormControl): {[key: string]: boolean} | null {
    const basis = control.value ? control.value['basis'] : '';
    const futuresYearMonth = control.value ? control.value['futuresYearMonth'] : '';
    const fieldDependencyError = (Number.isFinite(basis) && !futuresYearMonth) || (!Number.isFinite(basis) && futuresYearMonth);
    return fieldDependencyError ? {fieldDependencyError: true} : null;
  }
}
