<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <hms-error-message [title]="'Error Retrieving Ad Hoc Order Details'" [message]="errorMessage"></hms-error-message>

    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">Ad Hoc Order</h2>

      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <button type="button" id="edit-ad-hoc-order" class="action-button" aria-label="Edit Ad Hoc Order" matTooltip="Edit Ad Hoc Order" *ngIf="canEdit" (click)="setEditMode(true)" mat-mini-fab color="accent">
          <mat-icon>edit</mat-icon>
        </button>
        <button type="button" id="cancel-ad-hoc-order" class="action-button" aria-label="Cancel Ad Hoc Order" *ngIf="canCancelAdHocOrder" (click)="cancelLimitAdHocOrder()" mat-mini-fab color="primary" matTooltip="Cancel Ad Hoc Order">
          <mat-icon>do_not_disturb</mat-icon>
        </button>
        <mat-spinner class="loading-spinner" *ngIf="!updateComplete || isLoading" diameter="40" color="accent"></mat-spinner>
        <button type="submit" id="update-ad-hoc-order" class="action-button" aria-label="Update Ad Hoc Order" *ngIf="editMode && updateComplete" [disabled]="adHocOrderForm.pristine || !adHocOrderForm.valid" (click)="submitUpdate()" mat-mini-fab color="accent" matTooltip="Update Ad Hoc Order" data-cy="submit">
          <mat-icon>done</mat-icon>
        </button>
        <button type="submit" id="update-ad-hoc-order-exchange-order" class="action-button" aria-label="Update Ad Hoc Order" *ngIf="creatingOrder || recreatingOrder" [disabled]="orderForm.pristine || !orderForm.valid || qstWorking" (click)="setAdHocOrderExchangeOrder()" mat-mini-fab color="accent" matTooltip="Update Ad Hoc Order" data-cy="submit">
          <mat-icon>done</mat-icon>
        </button>
        <button type="button" id="clear-ad-hoc-order" class="action-button" aria-label="Discard Changes" *ngIf="editMode && updateComplete" type="button" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>

    <form *ngIf="!errorMessage && adHocOrder$ | async as adHocOrder" [formGroup]="adHocOrderForm" (ngSubmit)="submit()" fxLayout="column">

      <div fxLayout="column" fxLayoutGap="15px">

        <div class="top-row" fxLayout="row wrap" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Status" data="{{ adHocOrder.status | replace | titlecase }}" data-cy="status"></ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Commodity Profile" [data]="adHocOrder.commodityProfileName"></ati-labeled-data>
          <div fxLayout="row" *ngIf="exchangeOrderId" class="order-id-wrapper" fxFlex.gt-xs="34" fxFlex.xs="100" >
            <ati-labeled-data *ngIf="!shouldDisplayOrderIdField" label="Order ID" [data]="exchangeOrderId" [link]="getOrderLink()"></ati-labeled-data>
            <mat-form-field *ngIf="shouldDisplayOrderIdField">
              <input required matInput placeholder="Order ID" formControlName="orderDocId" trim="blur" data-cy="orderDocId">
              <mat-error *ngIf="adHocOrderForm.get('orderDocId').invalid">{{ getErrorMessage(adHocOrderForm.get('orderDocId')) }}</mat-error>
              <div class="mat-form-field-subscript-wrapper" [ngClass]="(adHocOrderForm.get('orderDocId').invalid && adHocOrderForm.touched) ? 'order-hint-position-with-error' : 'order-hint-position'" >
                <span id="order-hint" class="mat-hint">
                  <li>{{ commodityProfile.officeCode }}{{ commodityProfile.accountNumber }}</li>
                  <li>
                    {{ adHocOrder.side }} {{ adHocOrder.quantity }} {{ adHocOrder.contractYearMonth | codeToMonth }} {{ commodityProfile.commodityId }} @
                    <span *ngIf="adHocOrder.price; else adHocOrderMarket">{{ adHocOrder.price | number: '1.4-4'}}</span>
                    <ng-template #adHocOrderMarket>Market</ng-template>
                  </li>
                  <li>
                    <span *ngIf="adHocOrder.expirationDate">GTD {{ adHocOrder.expirationDate | date: 'M/d/yyyy'  }} </span>
                  </li>
                </span>
              </div>
            </mat-form-field>
            <button *ngIf="cancellingOrder && !qstWorking" id="cancel-ad-hoc-order-exchange-order" class="action-button" type="button" aria-label="Cancel Exchange Order" (click)="cancelExchangeOrder()" mat-icon-button color="warn" matTooltip="Cancel Exchange Order">
              <mat-icon>do_not_disturb</mat-icon>
            </button>
            <button *ngIf="updatingOrder && !qstWorking" id="update-ad-hoc-order-exchange-order" class="action-button" type="button" aria-label="Update Order" (click)="updateExchangeOrder()" mat-icon-button color="primary" matTooltip="Update Order">
              <mat-icon>flaky</mat-icon>
            </button>
            <button *ngIf="recreatingOrder && !qstWorking" id="recreate-ad-hoc-order-exchange-order" class="action-button" type="button" aria-label="Recreate Order" (click)="recreateExchangeOrder()" mat-icon-button color="primary" matTooltip="Recreate Order">
              <mat-icon>flaky</mat-icon>
            </button>
            <mat-spinner *ngIf="qstWorking" diameter="16"></mat-spinner>
          </div>
          <form *ngIf="creatingOrder || (recreatingOrder && !exchangeOrderId)" class="order-id-wrapper" fxFlex.gt-xs="34" fxFlex.xs="100" [formGroup]="orderForm" (ngSubmit)="setAdHocOrderExchangeOrder()">
            <mat-form-field>
              <input required matInput placeholder="Order ID" formControlName="orderDocId" trim="blur" data-cy="orderDocId" (blur)="isOrderFormValid" (keyup)="isOrderFormValid">
              <button class="qst-btn" type="button" id="create-ad-hoc-order-exchange-order" *ngIf="!orderForm.get('orderDocId').value && qstService.enabled && !qstWorking" matTooltip="Create Order" matSuffix mat-icon-button aria-label="Create Order" (click)="createExchangeOrder()">
                <mat-icon>receipt</mat-icon>
              </button>
              <mat-spinner matSuffix *ngIf="qstWorking" diameter="16"></mat-spinner>
              <mat-error *ngIf="orderForm.get('orderDocId').invalid">{{ getErrorMessage(orderForm.get('orderDocId')) }}</mat-error>
              <div class="mat-form-field-subscript-wrapper" [ngClass]="orderFormInvalid ? 'order-hint-position-with-error' : 'order-hint-position'" >
                <div *ngIf="adHocOrder.legs.length; else noAdHocOrderLegs">
                  <div>
                    <span id="order-hint" class="mat-hint">
                      <ul>
                        <li>{{ commodityProfile.officeCode }}{{ commodityProfile.accountNumber }}</li>
                        <li>
                          {{ adHocOrder.legs[0].side }} {{ adHocOrder.quantity }} {{ adHocOrder.legs[0].contractYearMonth | codeToMonth }} {{ adHocOrder.legs[0].commodityId }} /
                          {{ adHocOrder.legs[1].side }} {{ adHocOrder.quantity }} {{ adHocOrder.legs[1].contractYearMonth | codeToMonth}} {{ adHocOrder.legs[1].commodityId }} @
                          <span *ngIf="adHocOrder.price; else adHocOrderLegsMarket"> {{ adHocOrder.price | number: '1.4-4'}}</span>
                          <ng-template #adHocOrderLegsMarket>Market</ng-template>
                        </li>
                        <li>
                          <span *ngIf="adHocOrder.expirationDate">GTD {{ adHocOrder.expirationDate | date: 'M/d/yyyy'  }} </span>
                        </li>
                      </ul>
                    </span>
                  </div>
                </div>
                <ng-template #noAdHocOrderLegs>
                  <div>
                    <span id="order-hint" class="mat-hint">
                      <li>{{ commodityProfile.officeCode }}{{ commodityProfile.accountNumber }}</li>
                      <li>
                        {{ adHocOrder.side }} {{ adHocOrder.quantity }} {{ adHocOrder.contractYearMonth | codeToMonth }} {{ commodityProfile.commodityId }} @
                        <span *ngIf="adHocOrder.price; else adHocCreateOrderMarket">{{ adHocOrder.price | number: '1.4-4'}}</span>
                        <ng-template #adHocCreateOrderMarket>Market</ng-template>
                      </li>
                      <li>
                        <span *ngIf="adHocOrder.expirationDate">GTD {{ adHocOrder.expirationDate | date: 'M/d/yyyy'  }} </span>
                      </li>
                    </span>
                  </div>
                </ng-template>
              </div>
            </mat-form-field>
          </form>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Side" data="{{ adHocOrder.side | titlecase }}"></ati-labeled-data>
          <div fxFlex.gt-xs="33" fxFlex.xs="100">
            <ati-labeled-data *ngIf="!editMode || !adHocOrderForm.get('quantity').enabled" label="Quantity" data="{{ adHocOrder.quantity }} {{ getContractLabel(adHocOrder.quantity) }}"></ati-labeled-data>
            <mat-form-field *ngIf="editMode && adHocOrderForm.get('quantity').enabled" data-cy="quantity">
              <input required matInput placeholder="Quantity" formControlName="quantity">
            <mat-error *ngIf="adHocOrderForm.get('quantity').invalid">{{ getErrorMessage(adHocOrderForm.get('quantity')) }}</mat-error>
          </mat-form-field>
          </div>
          <div fxFlex.gt-xs="33" fxFlex.xs="100">
            <ati-labeled-data *ngIf="adHocOrder.contractYearMonth && (!editMode || !adHocOrderForm.get('futuresYearMonth').enabled)" label="Contract" data="{{ adHocOrder.contractYearMonth | codeToMonth }} {{ getCommodity() }}"></ati-labeled-data>
            <mat-form-field *ngIf="editMode && adHocOrderForm.get('futuresYearMonth').enabled" hmsMonthPicker>
              <input required matInput [matDatepicker]="contractYearMonthPicker" [errorStateMatcher]="futuresMonthErrorMatcher" [matDatepickerFilter]="validContractMonthFilter" placeholder="Contract Month" formControlName="futuresYearMonth" [min]="minDate" tabindex="6">
              <mat-datepicker-toggle matSuffix [for]="contractYearMonthPicker" data-cy="contractYearMonthPicker" #contractYearMonthPickerToggle tabindex="7"></mat-datepicker-toggle>
              <mat-datepicker #contractYearMonthPicker startView="year" (monthSelected)="selectContractYearMonth($event, 'futuresYearMonth')" (closed)="onCloseDatePicker('futuresYearMonthPickerToggle')" panelClass="futures-date-panel month-picker"></mat-datepicker>
              <mat-error *ngIf="adHocOrderForm.get('futuresYearMonth').invalid">{{ getErrorMessage(adHocOrderForm.get('futuresYearMonth')) }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start start">
          <div fxFlex.gt-xs="33" fxFlex.xs="100">
            <ati-labeled-data *ngIf="!editMode || !adHocOrderForm.get('type').enabled" label="Type" data="{{ adHocOrder.type | titlecase }}"></ati-labeled-data>
            <mat-button-toggle-group *ngIf="editMode && adHocOrderForm.get('type').enabled" formControlName="type" aria-label="Order Type" matTooltip="Order Type">
              <mat-button-toggle required *ngFor="let type of orderTypes" [value]="type" attr.data-cy="orderType-{{type}}" tabindex="3">{{ type | titlecase }}</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Security Type" data="{{ (adHocOrder.securityType === 'UDS' || adHocOrder.securityType === 'OTC') ? adHocOrder.securityType : adHocOrder.securityType | replace | titlecase }}"></ati-labeled-data>
          <ati-labeled-data *ngIf="adHocOrder.securitySubType" fxFlex.gt-xs="34" fxFlex.xs="100" label="Security Subtype" data="{{ adHocOrder.securitySubType | titlecase }}"></ati-labeled-data>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Time in Force" data="{{ adHocOrder.timeInForce !== 'DAY' ? adHocOrder.timeInForce : adHocOrder.timeInForce | titlecase }}"></ati-labeled-data>
          <div *ngIf="adHocOrder.type === 'LIMIT'" fxFlex.gt-xs="33" fxFlex.xs="100">
            <ati-labeled-data *ngIf="!editMode || !adHocOrderForm.get('price').enabled" label="Price" data="{{ adHocOrder.price | number:'1.4-4' }}"></ati-labeled-data>
            <mat-form-field *ngIf="editMode && adHocOrderForm.get('price').enabled" fxFlex.gt-xs="250px" fxFlex.xs="100" data-cy="price">
              <input required matInput placeholder="Price" formControlName="price" tabindex="10">
              <mat-error *ngIf="adHocOrderForm.get('price').invalid">{{ getErrorMessage(adHocOrderForm.get('price')) }}</mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="adHocOrder.type === 'LIMIT'" fxFlex.gt-xs="34" fxFlex.xs="100">
            <ati-labeled-data *ngIf="!editMode || !adHocOrderForm.get('expirationDate').enabled" label="Expiration Date" data="{{ adHocOrder.expirationDate | date: 'M/d/yyyy' }}"></ati-labeled-data>
            <mat-form-field *ngIf="editMode && adHocOrderForm.get('expirationDate').enabled">
              <input matInput [matDatepicker]="expirationDatePicker" placeholder="Expiration Date" formControlName="expirationDate" [matDatepickerFilter]="expDateFilter" [min]="currentDate" tabindex="8">
              <mat-datepicker-toggle matSuffix [for]="expirationDatePicker" data-cy="expirationDatePicker" #expirationDatePickerToggle tabindex="9"></mat-datepicker-toggle>
              <mat-datepicker #expirationDatePicker (closed)="onCloseDatePicker('expirationDatePickerToggle')"></mat-datepicker>
              <mat-error *ngIf="adHocOrderForm.get('expirationDate').invalid">{{ getErrorMessage(adHocOrderForm.get('expirationDate')) }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Creation Date" data="{{ adHocOrder.creationTimestamp | date: 'M/d/yyyy, h:mm:ss a' }}"></ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="67" fxFlex.xs="100" label="Creator" [data]="adHocOrder.creatorName"></ati-labeled-data>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Last Updated Date" data="{{ adHocOrder.lastUpdatedTimestamp | date: 'M/d/yyyy, h:mm:ss a' }}"></ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="67" fxFlex.xs="100" label="Last Updated By" [data]="adHocOrder.lastUpdatedByName"></ati-labeled-data>
        </div>

        <div *ngIf="adHocOrder.status === 'CANCELLED'">
          <ati-labeled-data label="Cancellation Date" data="{{ adHocOrder.cancellationTimestamp | date: 'M/d/yyyy, h:mm:ss a' }}"></ati-labeled-data>
        </div>

        <ati-labeled-data *ngIf="!shouldDisplayCommentField" label="Comments" [data]="adHocOrder.comments"></ati-labeled-data>
        <mat-form-field *ngIf="shouldDisplayCommentField">
          <textarea matInput placeholder="Comments" formControlName="comments" trim="blur" data-cy="comments"></textarea>
          <mat-error *ngIf="adHocOrderForm.get('comments').invalid">{{ getErrorMessage(adHocOrderForm.get('comments')) }}</mat-error>
          <button type="button" mat-button *ngIf="adHocOrderForm.get('comments').value" matSuffix mat-icon-button id="ad-hoc-order-detail-clear-comments" aria-label="Clear Comments" matTooltip="Clear Comments" (click)="clearField('comments')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <!--
          Fill Price or Split Fill Prices for partial fills
        -->
        <h3 fxFlex="100" *ngIf="fillDataSource.data.length">Fills</h3>
        <mat-table *ngIf="fillDataSource.data.length" [dataSource]="fillDataSource" class="mat-elevation-z0" fxFlex>
          <ng-container matColumnDef="quantity">
            <mat-header-cell *matHeaderCellDef [fxFlex.xs]="hasLeg ? '24' : '50'" [fxFlex.gt-xs]="hasLeg ? '25' : '50'">Qty</mat-header-cell>
            <mat-cell *matCellDef="let fill" [fxFlex.xs]="hasLeg ? '24' : '50'" [fxFlex.gt-xs]="hasLeg ? '25' : '50'">{{ fill.quantity }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="orderFillPrice">
            <mat-header-cell *matHeaderCellDef [fxFlex.xs]="hasLeg ? '25' : '50'" [fxFlex.gt-xs]="hasLeg ? '25' : '50'">Price</mat-header-cell>
            <mat-cell *matCellDef="let fill" [fxFlex.xs]="hasLeg ? '25' : '50'" [fxFlex.gt-xs]="hasLeg ? '25' : '50'">{{ getDisplayPrice(fill.orderFillPrice, fill.orderSymbol) | number: '1.4-4' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="orderBuyPrice">
            <mat-header-cell *matHeaderCellDef fxFlex.xs="25" fxFlex.gt-xs="25">Buy</mat-header-cell>
            <mat-cell *matCellDef="let fill" fxFlex.xs="25" fxFlex.gt-xs="25">{{ getDisplayPrice(fill.orderBuyPrice, fill.orderBuySymbol) | number: '1.4-4' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="orderSellPrice">
            <mat-header-cell *matHeaderCellDef fxFlex.xs="26" fxFlex.gt-xs="25">Sell</mat-header-cell>
            <mat-cell *matCellDef="let fill" fxFlex.xs="26" fxFlex.gt-xs="25">{{ getDisplayPrice(fill.orderSellPrice, fill.orderSellSymbol) | number: '1.4-4' }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="fillColumnsToDisplay"></mat-header-row>
          <mat-row *matRowDef="let fill; columns: fillColumnsToDisplay"></mat-row>
        </mat-table>

        <ng-container *ngIf="adHocOrder.legs.length && !editMode">
          <h3 fxFlex="100">Legs</h3>
          <div *ngFor="let leg of adHocOrder.legs; last as last" fxFlex="100">
            <hms-ad-hoc-order-leg [leg]="leg" [commodityMap]="commodityMap"></hms-ad-hoc-order-leg>
            <mat-divider inset *ngIf="!last"></mat-divider>
          </div>
        </ng-container>
        <ng-container *ngIf="adHocOrder.securityType === 'FUTURE_SPREAD' && editMode">
        <h4>{{ adHocOrder.side === 'BUY' ? 'Buy' : 'Sell' }}</h4>
        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
          <!-- Commodities -->
          <mat-form-field fxFlex.gt-xs="250px" data-cy="firstCommodity">
            <mat-select required placeholder="Commodity" formControlName="firstCommodity" tabindex="11">
              <mat-option *ngFor="let commodity of commodities" [value]="commodity">{{ commodity }}</mat-option>
            </mat-select>
            <mat-error *ngIf="adHocOrderForm.get('firstCommodity').invalid">{{ getErrorMessage(adHocOrderForm.get('firstCommodity')) }}</mat-error>
          </mat-form-field>
          <!-- Futures Year Month -->
          <mat-form-field hmsMonthPicker fxFlex.gt-xs="250px">
            <input required matInput [matDatepicker]="firstFuturesYearMonthPicker" [errorStateMatcher]="firstFuturesMonthErrorMatcher" [matDatepickerFilter]="validFirstContractMonthFilter" placeholder="Contract Month" formControlName="firstFuturesYearMonth" [min]="minDate" tabindex="12">
            <mat-datepicker-toggle matSuffix [for]="firstFuturesYearMonthPicker" data-cy="firstFuturesYearMonthPicker" #firstFuturesYearMonthPickerToggle tabindex="13"></mat-datepicker-toggle>
            <mat-datepicker #firstFuturesYearMonthPicker startView="year" (monthSelected)="selectContractYearMonth($event, 'firstFuturesYearMonth')" (closed)="onCloseDatePicker('firstFuturesYearMonthPickerToggle')" panelClass="futures-date-panel month-picker"></mat-datepicker>
            <mat-error *ngIf="adHocOrderForm.get('firstFuturesYearMonth').invalid">{{ getErrorMessage(adHocOrderForm.get('firstFuturesYearMonth')) }}</mat-error>
          </mat-form-field>
        </div>
        <h4>{{ adHocOrder.side === 'BUY' ? 'Sell' : 'Buy' }}</h4>
        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
          <!-- Commodities -->
          <mat-form-field fxFlex.gt-xs="250px" data-cy="secondCommodity">
            <mat-select required placeholder="Commodity" formControlName="secondCommodity" tabindex="14">
              <mat-option *ngFor="let commodity of commodities" [value]="commodity">{{ commodity }}</mat-option>
            </mat-select>
            <mat-error *ngIf="adHocOrderForm.get('secondCommodity').invalid">{{ getErrorMessage(adHocOrderForm.get('secondCommodity')) }}</mat-error>
          </mat-form-field>
          <!-- Futures Year Month -->
          <mat-form-field hmsMonthPicker fxFlex.gt-xs="250px">
            <input required matInput [matDatepicker]="secondFuturesYearMonthPicker" [errorStateMatcher]="secondFuturesMonthErrorMatcher" [matDatepickerFilter]="validSecondContractMonthFilter" placeholder="Contract Month" formControlName="secondFuturesYearMonth" [min]="minDate" tabindex="15">
            <mat-datepicker-toggle matSuffix [for]="secondFuturesYearMonthPicker" data-cy="secondFuturesYearMonthPicker" #secondFuturesYearMonthPickerToggle tabindex="16"></mat-datepicker-toggle>
            <mat-datepicker #secondFuturesYearMonthPicker startView="year" (monthSelected)="selectContractYearMonth($event, 'secondFuturesYearMonth')" (closed)="onCloseDatePicker('secondFuturesYearMonthPickerToggle')" panelClass="futures-date-panel month-picker"></mat-datepicker>
            <mat-error *ngIf="adHocOrderForm.get('secondFuturesYearMonth').invalid">{{ getErrorMessage(adHocOrderForm.get('secondFuturesYearMonth')) }}</mat-error>
          </mat-form-field>
        </div>
        </ng-container>
      </div>
    </form>
  </mat-card>
</div>
