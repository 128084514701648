import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import firebase from 'firebase/app';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { AuthService } from '@advance-trading/angular-ati-security';
import { Client, Contract, ContractType, PricingSegment, PricingSegmentPartType, PricingSegmentStatus, PricingType } from '@advance-trading/ops-data-lib';

@Injectable({
  providedIn: 'root'
})
export class PricingSegmentService {

  constructor(
    private db: AngularFirestore,
    private authService: AuthService
  ) {}

  /**
   * Return all pricing segments for the specified client and contract
   *
   * @param clientDocId The docId of the Client containing the contract PricingSegments
   * @param contractDocId The docId of the Contract containing the PricingSegments
   */
  getPricingSegmentsByClientDocIdAndContractDocId(clientDocId: string, contractDocId: string): Observable<PricingSegment[]> {
    return this.db.collection<PricingSegment>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath(contractDocId)}/${PricingSegment.getDataPath()}`)
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return all pricing segments for the specified client and contract with a different delivery location than the contract
   *
   * @param clientDocId The docId of the Client containing the contract PricingSegments
   * @param contractDocId The docId of the Contract containing the PricingSegments
   * @param deliveryLocationDocId The deliveryLocationDocId of the Contract which differs from PricingSegment
   */
  getPricingSegmentsByClientDocIdAndContractDocIdWithDifferentDeliveryLocationDocId(
    clientDocId: string, contractDocId: string, deliveryLocationDocId: string): Observable<PricingSegment[]> {
    return this.db.collection<PricingSegment>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath(contractDocId)}/${PricingSegment.getDataPath()}`,
      ref => ref.where('deliveryLocationDocId', '!=', deliveryLocationDocId)
        .where('status', '==', PricingSegmentStatus.PRICED)
    ).valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Return pricing segments for the specified Client, contract types and cashLockedTimestamp date range
   *
   * @param clientDocId The docId of the Client containing the PricingSegments
   * @param contractTypes The list of contract types of the PricingSegments to be returned
   * @param startDate The date before or when the PricingSegments' cash were locked
   * @param endDate The date after or when the PricingSegments' cash were locked
   */
  findPricingSegmentsByTypeAndCashLockedTimestamp(
    clientDocId: string, contractTypes: ContractType[], startDate: string, endDate: string): Observable<PricingSegment[]> {

    return this.db.collectionGroup<PricingSegment>(`${PricingSegment.getDataPath()}`,
      ref => ref.where('clientDocId', '==', clientDocId)
        .where('contractType', 'in', contractTypes)
        .where('cashLockedTimestamp', '>=', startDate).where('cashLockedTimestamp', '<=', endDate)
        .where('isExchange', '==', false)
    ).valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return DP pricing segments for the specified Client, futuresLockedTimestamp date range and
   * futures was priced on the first part
   *
   * @param clientDocId The docId of the Client containing the PricingSegments
   * @param startDate The date before or when the PricingSegments' futures were locked
   * @param endDate The date after or when the PricingSegments' futures were locked
   * @param excludeDeleted optional parameter used to exclude deleted items from results
   */
  findDpPricingSegmentsByFuturesLockedTimestampWithFuturesDpFirstPartType(
    clientDocId: string, startDate: string, endDate: string, excludeDeleted?: boolean): Observable<PricingSegment[]> {
    return this.db.collectionGroup<PricingSegment>(`${PricingSegment.getDataPath()}`,
      ref => {
        let finalRef = ref.where('clientDocId', '==', clientDocId)
          .where('contractType', '==', ContractType.DP)
          .where('futuresLockedTimestamp', '>=', startDate)
          .where('futuresLockedTimestamp', '<=', endDate)
          .where('dpFirstPartType', '==', PricingSegmentPartType.FUTURES);
        if (excludeDeleted) {
          finalRef = finalRef.where('deletionTimestamp', '==', '');
        }
        return finalRef;
      }
    ).valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Return pricing segments for the specified Client, contract types, commodity profile and futuresLockedTimestamp date range
   *
   * @param clientDocId The docId of the Client containing the PricingSegments
   * @param contractTypes The list of contract types of the PricingSegments to be returned
   * @param commodityProfileDocId The docId  of the CommodityProfile containing the PricingSegments
   * @param startDate The date before or when the PricingSegments' futures were locked
   * @param endDate The date after or when the PricingSegments' futures were locked
   */
  findPricingSegmentsByTypeCommodityProfileAndFuturesLockedTimestamp(
    clientDocId: string, contractTypes: ContractType[], commodityProfileDocId: string,
    startDate: string, endDate: string): Observable<PricingSegment[]> {

    return this.db.collectionGroup<PricingSegment>(`${PricingSegment.getDataPath()}`,
      ref => ref.where('clientDocId', '==', clientDocId)
        .where('contractType', 'in', contractTypes)
        .where('commodityProfileDocId', '==', commodityProfileDocId)
        .where('futuresLockedTimestamp', '>=', startDate).where('futuresLockedTimestamp', '<=', endDate)
        .where('isExchange', '==', false)
    ).valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return pricing segments for the specified Client, contract types, and futuresLockedTimestamp date range
   *
   * @param clientDocId The docId of the Client containing the PricingSegments
   * @param startDate The date before or when the PricingSegments' futures were locked
   * @param endDate The date after or when the PricingSegments' futures were locked
   */
  findBasisExchangePricingSegmentsByTypeFuturesLockedTimestampAndStatus(
    clientDocId: string, startDate: string, endDate: string): Observable<PricingSegment[]> {

    return this.db.collectionGroup<PricingSegment>(`${PricingSegment.getDataPath()}`,
      ref => ref.where('clientDocId', '==', clientDocId)
        .where('contractType', '==', ContractType.BASIS)
        .where('futuresLockedTimestamp', '>=', startDate).where('futuresLockedTimestamp', '<=', endDate)
        .where('isExchange', '==', true)
        .where('status', '==', PricingSegmentStatus.PRICED)
    ).valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return DP pricing segments for the specified Client, basisLockedTimestamp date range and
   * basis was priced on the first part
   *
   * @param clientDocId The docId of the Client containing the PricingSegments
   * @param startDate The date before or when the PricingSegments' basis were locked
   * @param endDate The date after or when the PricingSegments' basis were locked
   * @param excludeDeleted optional parameter used to exclude deleted items from results
   */
  findDpPricingSegmentsByBasisLockedTimestampWithBasisDpFirstPartType(
    clientDocId: string, startDate: string, endDate: string, excludeDeleted?: boolean): Observable<PricingSegment[]> {
    return this.db.collectionGroup<PricingSegment>(`${PricingSegment.getDataPath()}`,
      ref => {
        let finalRef = ref.where('clientDocId', '==', clientDocId)
          .where('contractType', '==', ContractType.DP)
          .where('basisLockedTimestamp', '>=', startDate)
          .where('basisLockedTimestamp', '<=', endDate)
          .where('dpFirstPartType', '==', PricingSegmentPartType.BASIS);
        if (excludeDeleted) {
          finalRef = finalRef.where('deletionTimestamp', '==', '');
        }
        return finalRef;
      }
    ).valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Return pricing segments for the specified Client, contract types, commodity profile and basisLockedTimestamp date range
   *
   * @param clientDocId The docId of the Client containing the PricingSegments
   * @param contractTypes The list of contract types of the PricingSegments to be returned
   * @param commodityProfileDocId The docId  of the CommodityProfile containing the PricingSegments
   * @param startDate The date before or when the PricingSegments' basis were locked
   * @param endDate The date after or when the PricingSegments' basis were locked
   */
  findPricingSegmentsByTypeCommodityProfileAndBasisLockedTimestamp(
    clientDocId: string, contractTypes: ContractType[], commodityProfileDocId: string,
    startDate: string, endDate: string): Observable<PricingSegment[]> {

    return this.db.collectionGroup<PricingSegment>(`${PricingSegment.getDataPath()}`,
      ref => ref.where('clientDocId', '==', clientDocId)
        .where('contractType', 'in', contractTypes)
        .where('commodityProfileDocId', '==', commodityProfileDocId)
        .where('basisLockedTimestamp', '>=', startDate).where('basisLockedTimestamp', '<=', endDate)
    ).valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return DP pricing segments for the specified Client, cashLockedTimestamp date range with pricing type of Market or Limit
   *
   * @param clientDocId The docId of the Client containing the PricingSegments
   * @param startDate The date before or when the PricingSegments' basis were locked
   * @param endDate The date after or when the PricingSegments' basis were locked
   * @param excludeDeleted optional parameter used to exclude deleted items from results
   */
  findDpPricingSegmentsByCashLockedTimestampAndCashPricingType(
    clientDocId: string, startDate: string, endDate: string, excludeDeleted?: boolean): Observable<PricingSegment[]> {
    return this.db.collectionGroup<PricingSegment>(`${PricingSegment.getDataPath()}`,
      ref => {
        let finalRef = ref.where('clientDocId', '==', clientDocId)
          .where('contractType', '==', ContractType.DP)
          .where('cashPricingType', 'in', [PricingType.MARKET, PricingType.LIMIT])
          .where('cashLockedTimestamp', '>=', startDate)
          .where('cashLockedTimestamp', '<=', endDate);
        if (excludeDeleted) {
          finalRef = finalRef.where('deletionTimestamp', '==', '');
        }
        return finalRef;
      }
    ).valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  // Closest To Market Report Queries

  /**
   * Return pricing segments for the specified Client and Commodity Profiles
   * commodityProfileDocIds can be an empty array or undefined
   * @param clientDocId The docId of the Client containing the Pricing Segments
   * @param commodityProfileDocIds commodityProfileDocIda of the Pricing Segments to be returned
   */
  findPricingSegmentsForClosestToMarketReport(
    clientDocId: string, commodityProfileDocIds: string[]): Observable<PricingSegment[]> {
    if (!commodityProfileDocIds || !commodityProfileDocIds.length) {
      return this.findPricingSegmentsForClosestToMarketReportByCommodityProfile(clientDocId, undefined);
    } else {
      return combineLatest(commodityProfileDocIds.map(commodityProfileDocId =>
        this.findPricingSegmentsForClosestToMarketReportByCommodityProfile(clientDocId, commodityProfileDocId))).pipe(
        // force potential array of contract arrays into single array
        map(arrayOfContractArrays => (arrayOfContractArrays as PricingSegment[][]).flat()),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    }
  }

  /**
   * Return pricing segments for the specified Client and Commodity Profile
   * commodityProfileDocId can be undefined, is only applied as query filter if not undefined
   * @param clientDocId The docId of the Client containing the Contracts
   * @param commodityProfileDocId commodityProfileDocId of the Contracts to be returned
   */
  private findPricingSegmentsForClosestToMarketReportByCommodityProfile(
    clientDocId: string, commodityProfileDocId: string): Observable<PricingSegment[]> {
    return this.db.collectionGroup<PricingSegment>(`${PricingSegment.getDataPath()}`,
      ref => {
        let finalRef = ref.where('clientDocId', '==', clientDocId)
          .where('status', 'in', [PricingSegmentStatus.WORKING_CASH, PricingSegmentStatus.WORKING_FUTURES]);
        if (commodityProfileDocId) {
          finalRef = finalRef.where('commodityProfileDocId', '==', commodityProfileDocId);
        }
        return finalRef;
      }
    ).valueChanges();
  }

  // Future Delivery Report Queries

  /**
   * Return priced pricing segments for the specified Client, delivery period, and commodity profiles
   *
   * @param clientDocId The docId of the Client containing the PricingSegments
   * @param deliveryPeriod The starting deliveryPeriod of PricingSegments to be returned
   * @param commodityProfileDocIds The list of commodity profile doc ids of the PricingSegments to be returned. Can be an empty array
   */
  findPricedPricingSegmentsForFutureDeliveryReport(
    clientDocId: string, deliveryPeriod: string, commodityProfileDocIds: string[]): Observable<PricingSegment[]> {
    if (commodityProfileDocIds.length) {
      const subQueryObservables = [];
      commodityProfileDocIds.forEach(docId => {
        subQueryObservables.push(this.findPricedPricingSegments(clientDocId, deliveryPeriod, docId));
      });
      return combineLatest(subQueryObservables).pipe(
        map(arrayOfSegments => (arrayOfSegments as PricingSegment[][]).flat()),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    } else {
      return this.findPricedPricingSegments(clientDocId, deliveryPeriod).pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
    }
  }

  /**
   * Return priced pricing segments for the specified Client, delivery period, and commodity profile
   *
   * @param clientDocId The docId of the Client containing the PricingSegments
   * @param deliveryPeriod The starting deliveryPeriod of PricingSegments to be returned
   * @param commodityProfileDocId The commodity profile doc id of the PricingSegments to be returned
   */
  private findPricedPricingSegments(clientDocId: string, deliveryPeriod: string, commodityProfileDocId?: string)
    : Observable<PricingSegment[]> {
    return this.db.collectionGroup<PricingSegment>(`${PricingSegment.getDataPath()}`,
    ref => {
      let finalRef = ref.where('clientDocId', '==', clientDocId)
        .where('status', '==', PricingSegmentStatus.PRICED)
        .where('deliveryPeriod', '>=', deliveryPeriod)
        .where('contractType', 'in', [ContractType.CASH, ContractType.BASIS, ContractType.HTA]);
      if (commodityProfileDocId) {
        finalRef = finalRef.where('commodityProfileDocId', '==', commodityProfileDocId);
      }
      return finalRef;
    }).valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  // Target Search Queries

  /**
   * Return all pricing segments for the specified client and contract and working statuses
   *
   * @param clientDocId The docId of the Client containing the contract PricingSegments
   * @param contractDocId The docId of the Contract containing the PricingSegments
   */
  getPricingSegmentsByClientDocIdAndContractDocIdAndStatus(clientDocId: string, contractDocId: string): Observable<PricingSegment[]> {
    return this.db.collection<PricingSegment>(
      `${Client.getDataPath(clientDocId)}/${Contract.getDataPath(contractDocId)}/${PricingSegment.getDataPath()}`,
      ref => ref.where('status', 'in',
        [PricingSegmentStatus.WORKING_BASIS, PricingSegmentStatus.WORKING_CASH, PricingSegmentStatus.WORKING_FUTURES])
    ).valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return pricing segments for the specified Client, type, date range, client location, creator, delivery, and futures
   * Any or all parameters besides clientDocId can be undefined, and are only applied as query filters if not undefined
   * @param clientDocId The docId of the Client containing the Pricing Segments
   * @param contractType type of the Contracts of the Pricing Segments to be returned
   * @param startDate The date before or when the Pricing Segments were last updated
   * @param endDate The date after or when the Pricing Segments were last updated
   * @param clientLocationDocId clientLocationDocId of the Pricing Segments to be returned
   * @param originatorDocId creatorDocId of the Pricing Segments to be returned
   * @param delivery deliveryPeriod of the Pricing Segments to be returned
   * @param futures futuresYearMonth of the Pricing Segments to be returned
   */
  findPricingSegmentsBySearchParameters(
    clientDocId: string, contractType: string, startDate: string, endDate: string,
    clientLocationDocId: string, originatorDocId: string, delivery: string, futures: string): Observable<PricingSegment[]> {
    return this.db.collectionGroup<PricingSegment>(`${PricingSegment.getDataPath()}`,
      ref => {
        let finalRef = ref.where('clientDocId', '==', clientDocId)
          .where('status', 'in',
            [PricingSegmentStatus.WORKING_BASIS, PricingSegmentStatus.WORKING_CASH, PricingSegmentStatus.WORKING_FUTURES]);
        if (contractType) {
          finalRef = finalRef.where('contractType', '==', contractType);
        }
        if (startDate && endDate) {
          finalRef = finalRef.where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate);
        }
        if (clientLocationDocId) {
          finalRef = finalRef.where('clientLocationDocId', '==', clientLocationDocId);
        }
        if (originatorDocId) {
          finalRef = finalRef.where('originatorDocId', '==', originatorDocId);
        }
        if (delivery) {
          finalRef = finalRef.where('deliveryPeriod', '==', delivery);
        }
        if (futures) {
          finalRef = finalRef.where('futuresYearMonth', '==', futures);
        }
        return finalRef;
      }
    ).valueChanges();
  }

  /**
   * Return exchange pricing segments for the specified Client within the specified exchangeTimestamp range
   *
   * @param clientDocId The docId of the Client containing the PricingSegments
   * @param startDate The date before or when an exchange was created for the PricingSegments
   * @param endDate The date after or when an exchange was created for the PricingSegments
   * @param commodityProfileDocId The optional commodity profile doc id of the PricingSegments to be returned
   */
  findExchangePricingSegmentsByExchangeTimestamp(
    clientDocId: string, startDate: string, endDate: string, commodityProfileDocId?: string): Observable<PricingSegment[]> {

    return this.db.collectionGroup<PricingSegment>(`${PricingSegment.getDataPath()}`,
      ref => {
        let finalRef = ref.where('clientDocId', '==', clientDocId)
          .where('isExchange', '==', true)
          .where('exchangeTimestamp', '>=', startDate)
          .where('exchangeTimestamp', '<=', endDate)
          .where('contractType', '==', ContractType.BASIS);
        if (commodityProfileDocId) {
          finalRef = finalRef.where('commodityProfileDocId', '==', commodityProfileDocId);
        }
        return finalRef;
      }).valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return all pricing segments for the specified Client, commodity profile, and delivery period for the Delivery monthly roll report
   *
   * @param clientDocId The docId of the Client containing the contract PricingSegments
   * @param commodityProfileDocId The commodity profile doc id of the PricingSegments to be returned
   * @param deliveryPeriod deliveryPeriod of the Pricing Segments to be returned
   */
  findPricingSegmentsForDeliveryMonthlyRoll(
    clientDocId: string, commodityProfileDocId: string, deliveryPeriod: string): Observable<PricingSegment[]> {
    return this.db.collectionGroup<PricingSegment>(`${PricingSegment.getDataPath()}`,
      ref => ref.where('clientDocId', '==', clientDocId)
        .where('commodityProfileDocId', '==', commodityProfileDocId)
        .where('deliveryPeriod', '==', deliveryPeriod)
        .where('isBasisLocked', '==', false)
        .where('status', 'not-in', [
          PricingSegmentStatus.CANCELLED,
          PricingSegmentStatus.DELETED,
          PricingSegmentStatus.EXPIRED,
          PricingSegmentStatus.PRICED
        ])
    ).valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Return all pricing segments for the specified Client, commodity profile, and futures year month for the Futures monthly roll report
   *
   * @param clientDocId The docId of the Client containing the contract PricingSegments
   * @param commodityProfileDocId The commodity profile doc id of the PricingSegments to be returned
   * @param futuresYearMonth futuresYearMonth of the Pricing Segments to be returned
   */
  findPricingSegmentsForFuturesMonthlyRoll(
    clientDocId: string, commodityProfileDocId: string, futuresYearMonth: string): Observable<PricingSegment[]> {
    return this.db.collectionGroup<PricingSegment>(`${PricingSegment.getDataPath()}`,
      ref => ref.where('clientDocId', '==', clientDocId)
        .where('commodityProfileDocId', '==', commodityProfileDocId)
        .where('futuresYearMonth', '==', futuresYearMonth)
        .where('isFuturesLocked', '==', false)
        .where('status', 'not-in', [
          PricingSegmentStatus.CANCELLED,
          PricingSegmentStatus.DELETED,
          PricingSegmentStatus.EXPIRED,
          PricingSegmentStatus.PRICED
        ])
    ).valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Create a pricing segment for the specified client and contract
   *
   * @param clientDocId The docId of the Client
   * @param contractDocId The docId of the Contract
   * @param pricingSegment The PricingSegment object
   */
  createPricingSegment(clientDocId: string, contractDocId: string, pricingSegment: PricingSegment): Promise<void> {
    pricingSegment.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.doc<PricingSegment>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath(contractDocId)}/${PricingSegment.getDataPath(pricingSegment.docId)}`)
      .set(pricingSegment.getPlainObject());
  }

  /**
   * Update a PricingSegment document for the specified Client and Contract
   *
   * @param clientDocId The docId of the Client
   * @param contractDocId The docId of the Contract
   * @param pricingSegment The PricingSegment object
   */
  updatePricingSegment(clientDocId: string, contractDocId: string, pricingSegment: PricingSegment): Promise<void> {
    pricingSegment.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;

    // Cancelling second part of DP pricing in WORKING_FUTURES
    if (!pricingSegment.futuresPricingType) {
      // @ts-ignore:
      pricingSegment.futuresPricingType = (firebase.firestore.FieldValue.delete());
    }
    if (!Number.isFinite(pricingSegment.futuresPrice)) {
      // @ts-ignore:
      pricingSegment.futuresPrice = firebase.firestore.FieldValue.delete();
    }
    if (!pricingSegment.futuresYearMonth) {
      // @ts-ignore:
      pricingSegment.futuresYearMonth = firebase.firestore.FieldValue.delete();
    }

    // Cancelling second part of DP pricing in WORKING_BASIS
    if (!pricingSegment.basisPricingType) {
      // @ts-ignore:
      pricingSegment.basisPricingType = firebase.firestore.FieldValue.delete();
    }
    if (!Number.isFinite(pricingSegment.basisPrice)) {
      // @ts-ignore:
      pricingSegment.basisPrice = firebase.firestore.FieldValue.delete();
    }

    return this.db.doc<PricingSegment>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath(contractDocId)}/${PricingSegment.getDataPath(pricingSegment.docId)}`)
      .update(pricingSegment);
  }

}
