import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { UserService } from '@advance-trading/angular-ops-data';
import { Client, MarketDataUsage, User } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ExportService } from '../../service/export.service';
import { MarketDataUsageService } from '../../service/market-data-usage.service';
import { UserRoles } from '../../utilities/user-roles';

import { OnDemandQuotesDisplay } from './on-demand-quotes-display';

const PERIOD_FORMAT = 'YYYY-MM';

@Component({
  selector: 'hms-on-demand-quotes',
  templateUrl: './on-demand-quotes.component.html',
  styleUrls: ['./on-demand-quotes.component.scss']
})
export class OnDemandQuotesComponent implements OnInit {
  onDemandQuotesSearchForm: FormGroup = this.formBuilder.group({
    periodMonth: [''],
  });

  errorMessage: string;
  isLoading = false;
  displayReports = false;

  onDemandQuotes$: Observable<OnDemandQuotesDisplay[]>;
  dataSource: MatTableDataSource<OnDemandQuotesDisplay> = new MatTableDataSource<OnDemandQuotesDisplay>();
  columnsToDisplay = ['name', 'userOnDemandUsage'];
  footerColumnsToDisplay = ['footer'];

  clientOnDemandUsage = 0;
  periodMonthHeader: moment.Moment;

  @ViewChild('periodMonthPicker', { static: false }) periodMonthRef;

  private defaultDate = moment();

  constructor(
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    public exportService: ExportService,
    private formBuilder: FormBuilder,
    private marketDataUsageService: MarketDataUsageService,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.OPERATIONS_REPORTS_GENERATOR_ROLE)) {
      this.errorMessage = 'You do not have permission to access the on-demand quotes report.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    // default selected month to be this month
    this.onDemandQuotesSearchForm.get('periodMonth').setValue(this.defaultDate);
    this.onDemandQuotesSearchForm.markAsDirty();
  }

  selectPeriod(e: moment.Moment) {
    this.onDemandQuotesSearchForm.get('periodMonth').setValue(e);
    this.onDemandQuotesSearchForm.markAsDirty();
    this.periodMonthRef.close();
  }

  loadOnDemandQuotesReportData() {
    if (!this.onDemandQuotesSearchForm.get('periodMonth').value) {
      this.onDemandQuotesSearchForm.get('periodMonth').setValue(this.defaultDate);
      this.onDemandQuotesSearchForm.markAsDirty();
    }
    this.periodMonthHeader = this.onDemandQuotesSearchForm.get('periodMonth').value;
    this.isLoading = true;
    this.onDemandQuotes$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((client: Client) => {
        return this.marketDataUsageService.getMarketDataUsageByDocId(client.docId,
          this.translateMomentToPeriod(this.onDemandQuotesSearchForm.get('periodMonth').value));
      }),
      switchMap((marketDataUsage: MarketDataUsage) => {
        if (marketDataUsage) {
          // get client demand usage
          this.clientOnDemandUsage = marketDataUsage.clientOnDemandUsage;

          return combineLatest(Object.keys(marketDataUsage.userOnDemandUsage).map((userDocId: string) => {
            return this.userService.getUserByDocId(userDocId).pipe(
              map((user: User) => {
                return {
                  name: `${user.firstName} ${user.lastName}`,
                  userOnDemandUsage: marketDataUsage.userOnDemandUsage[userDocId]
                } as OnDemandQuotesDisplay;
              })
            );
          }));
        } else {
          this.clientOnDemandUsage = 0;
          return of([]);
        }
      }),
      map((onDemandQuotes: OnDemandQuotesDisplay[]) => onDemandQuotes.sort(this.sortByAscName)),
      tap((onDemandQuotes: OnDemandQuotesDisplay[]) => {
        this.dataSource.data = onDemandQuotes;
        this.isLoading = false;
        this.displayReports = true;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar('Error running report: ' + errorMsg, 'DISMISS', false);
        console.error(`Error retrieving reportable items: ${err}`);
        this.isLoading = false;
        this.clientOnDemandUsage = 0;
        return of([]);
      })
    );
  }

  reset() {
    this.onDemandQuotesSearchForm.get('periodMonth').setValue('');
    this.onDemandQuotesSearchForm.markAsPristine();
    this.displayReports = false;
  }

  private sortByAscName(a: OnDemandQuotesDisplay, b: OnDemandQuotesDisplay): number {
    return a.name.localeCompare(b.name);
  }

  private translateMomentToPeriod(selectedPeriod: moment.Moment): string {
    return selectedPeriod.format(PERIOD_FORMAT);
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}
