<div fxLayout="row" fxLayoutAlign="space-around start">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <!-- Error message -->
    <div *ngIf="errorMessage">
      <hms-error-message [title]="'Error Retrieving New Ad Hoc Order Form'" [message]="errorMessage"></hms-error-message>
    </div>

    <form *ngIf="!errorMessage && commodityMap$ | async as commodityMap" [formGroup]="adHocOrderForm" (ngSubmit)="submit()" fxLayout="column" fxLayoutGap="15px">
      <!-- Title and Buttons -->
      <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
          <h2 class="card-title">New Ad Hoc Order</h2>
          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
            <button type="submit" id="create-ad-hoc-order" class="action-button" aria-label="Create Ad Hoc Order" *ngIf="updateComplete" [disabled]="!adHocOrderForm.valid" mat-mini-fab color="accent" matTooltip="Create Ad Hoc Order" data-cy="submit" tabindex="18">
                <mat-icon>done</mat-icon>
            </button>
            <button type="button" id="clear-ad-hoc-order" class="action-button" aria-label="Discard Changes" *ngIf="!adHocOrderForm.pristine && updateComplete" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes" tabindex="19">
                <mat-icon>clear</mat-icon>
            </button>
            <mat-spinner *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
          </div>
      </div>

      <!-- Side -->
      <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
        <div>
          <mat-button-toggle-group formControlName="side" aria-label="Buy or Sell" #BuySellToggle>
            <mat-button-toggle required *ngFor="let side of sides" [value]="side" attr.data-cy="side-{{side}}" tabindex="1">{{ side | titlecase }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div>
          <mat-button-toggle-group formControlName="securityType" aria-label="Futures or Spread" matTooltip="Security Type">
            <mat-button-toggle required *ngFor="let type of securityTypes" [value]="type" attr.data-cy="securityType-{{type}}" tabindex="2">{{ type | replace:'_':' ' | titlecase }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div>
          <mat-button-toggle-group formControlName="orderType" aria-label="Order Type" matTooltip="Order Type">
            <mat-button-toggle required *ngFor="let type of orderTypes" [value]="type" attr.data-cy="orderType-{{type}}" tabindex="3">{{ type | titlecase }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <!-- Commodity Profile and Quantity -->
      <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
        <div *ngIf="adHocOrderForm.get('securityType').value">
          <mat-form-field fxFlex.gt-xs="250px" fxFlex.xs="100" data-cy="commodityProfile">
            <mat-select [compareWith]="compareCommodityProfile" required placeholder="Commodity Profile" formControlName="commodityProfile" tabindex="4">
              <mat-option *ngFor="let profile of commodityProfiles$ | async" [value]="profile">{{ profile.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="adHocOrderForm.get('commodityProfile').invalid">{{ getErrorMessage(adHocOrderForm.get('commodityProfile')) }}</mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex.gt-xs="250px" fxFlex.xs="100" data-cy="contract">
            <input required matInput placeholder="Contracts" formControlName="contract" tabindex="5">
            <mat-error *ngIf="adHocOrderForm.get('contract').invalid">{{ getErrorMessage(adHocOrderForm.get('contract')) }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- Futures Year Month and Expiration Date-->
      <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
        <mat-form-field *ngIf="adHocOrderForm.get('securityType').value === 'FUTURE'" hmsMonthPicker fxFlex.gt-xs="250px">
          <input required matInput [matDatepicker]="contractYearMonthPicker" [errorStateMatcher]="futuresMonthErrorMatcher" [matDatepickerFilter]="validContractMonthFilter" placeholder="Contract Month" formControlName="futuresYearMonth" [min]="minDate" tabindex="6">
          <mat-datepicker-toggle matSuffix [for]="contractYearMonthPicker" data-cy="contractYearMonthPicker" #contractYearMonthPickerToggle tabindex="7"></mat-datepicker-toggle>
          <mat-datepicker #contractYearMonthPicker startView="year" (monthSelected)="selectContractYearMonth($event, 'futuresYearMonth')" (closed)="onCloseDatePicker('contractYearMonthPickerToggle')" panelClass="futures-date-panel month-picker"></mat-datepicker>
          <mat-error *ngIf="adHocOrderForm.get('futuresYearMonth').invalid">{{ getErrorMessage(adHocOrderForm.get('futuresYearMonth')) }}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="adHocOrderForm.get('orderType').value === 'LIMIT'" fxFlex.gt-xs="250px">
          <input matInput [matDatepicker]="expirationDatePicker" placeholder="Expiration Date" formControlName="expirationDate" [matDatepickerFilter]="expDateFilter" [min]="currentDate" tabindex="8">
          <mat-datepicker-toggle matSuffix [for]="expirationDatePicker" data-cy="expirationDatePicker" #expirationDatePickerToggle tabindex="9"></mat-datepicker-toggle>
          <mat-datepicker #expirationDatePicker (closed)="onCloseDatePicker('expirationDatePickerToggle')"></mat-datepicker>
          <mat-error *ngIf="adHocOrderForm.get('expirationDate').invalid">{{ getErrorMessage(adHocOrderForm.get('expirationDate')) }}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="adHocOrderForm.get('orderType').value === 'LIMIT'" fxFlex.gt-xs="250px" fxFlex.xs="100" data-cy="price">
          <input required matInput placeholder="Price" formControlName="price" tabindex="10">
          <mat-error *ngIf="adHocOrderForm.get('price').invalid">{{ getErrorMessage(adHocOrderForm.get('price')) }}</mat-error>
        </mat-form-field>

      </div>

      <div *ngIf="adHocOrderForm.get('securityType').value === 'FUTURE_SPREAD' && adHocOrderForm.get('side').value">
        <h4>{{ adHocOrderForm.get('side').value === 'BUY' ? 'Buy' : 'Sell' }}</h4>
        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
          <!-- Commodities -->
          <mat-form-field fxFlex.gt-xs="250px" data-cy="firstCommodity">
            <mat-select required placeholder="Commodity" formControlName="firstCommodity" tabindex="11">
              <mat-option *ngFor="let commodity of commodities$ | async" [value]="commodity">{{ commodity }}</mat-option>
            </mat-select>
            <mat-error *ngIf="adHocOrderForm.get('firstCommodity').invalid">{{ getErrorMessage(adHocOrderForm.get('firstCommodity')) }}</mat-error>
          </mat-form-field>
          <!-- Futures Year Month -->
          <mat-form-field hmsMonthPicker fxFlex.gt-xs="250px">
            <input required matInput [matDatepicker]="firstFuturesYearMonthPicker" [errorStateMatcher]="firstFuturesMonthErrorMatcher" [matDatepickerFilter]="validFirstContractMonthFilter" placeholder="Contract Month" formControlName="firstFuturesYearMonth" [min]="minDate" tabindex="12">
            <mat-datepicker-toggle matSuffix [for]="firstFuturesYearMonthPicker" data-cy="firstFuturesYearMonthPicker" #firstFuturesYearMonthPickerToggle tabindex="13"></mat-datepicker-toggle>
            <mat-datepicker #firstFuturesYearMonthPicker startView="year" (monthSelected)="selectContractYearMonth($event, 'firstFuturesYearMonth')" (closed)="onCloseDatePicker('firstFuturesYearMonthPickerToggle')" panelClass="futures-date-panel month-picker"></mat-datepicker>
            <mat-error *ngIf="adHocOrderForm.get('firstFuturesYearMonth').invalid">{{ getErrorMessage(adHocOrderForm.get('firstFuturesYearMonth')) }}</mat-error>
          </mat-form-field>
        </div>
        <h4>{{ adHocOrderForm.get('side').value === 'BUY' ? 'Sell' : 'Buy' }}</h4>
        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
          <!-- Commodities -->
          <mat-form-field fxFlex.gt-xs="250px" data-cy="secondCommodity">
            <mat-select required placeholder="Commodity" formControlName="secondCommodity" tabindex="14">
              <mat-option *ngFor="let commodity of commodities$ | async" [value]="commodity">{{ commodity }}</mat-option>
            </mat-select>
            <mat-error *ngIf="adHocOrderForm.get('secondCommodity').invalid">{{ getErrorMessage(adHocOrderForm.get('secondCommodity')) }}</mat-error>
          </mat-form-field>
          <!-- Futures Year Month -->
          <mat-form-field hmsMonthPicker fxFlex.gt-xs="250px">
            <input required matInput [matDatepicker]="secondFuturesYearMonthPicker" [errorStateMatcher]="secondFuturesMonthErrorMatcher" [matDatepickerFilter]="validSecondContractMonthFilter" placeholder="Contract Month" formControlName="secondFuturesYearMonth" [min]="minDate" tabindex="15">
            <mat-datepicker-toggle matSuffix [for]="secondFuturesYearMonthPicker" data-cy="secondFuturesYearMonthPicker" #secondFuturesYearMonthPickerToggle tabindex="16"></mat-datepicker-toggle>
            <mat-datepicker #secondFuturesYearMonthPicker startView="year" (monthSelected)="selectContractYearMonth($event, 'secondFuturesYearMonth')" (closed)="onCloseDatePicker('secondFuturesYearMonthPickerToggle')" panelClass="futures-date-panel month-picker"></mat-datepicker>
            <mat-error *ngIf="adHocOrderForm.get('secondFuturesYearMonth').invalid">{{ getErrorMessage(adHocOrderForm.get('secondFuturesYearMonth')) }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- Comments -->
      <mat-form-field data-cy="comments">
        <textarea matInput placeholder="Comments" formControlName="comments" trim="blur" tabindex="17"></textarea>
        <mat-error *ngIf="adHocOrderForm.get('comments').invalid">{{ getErrorMessage(adHocOrderForm.get('comments')) }}</mat-error>
        <button type="button" id="new-ad-hoc-order-clear-comments" mat-button *ngIf="adHocOrderForm.get('comments').value" matSuffix mat-icon-button aria-label="Clear Comments" matTooltip="Clear Comments" (click)="adHocOrderForm.get('comments').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </form>

  </mat-card>
</div>
