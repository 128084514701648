import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewChecked, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabGroup } from '@angular/material/tabs';

import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { BasisService, CommodityProfileService, LocationService } from '@advance-trading/angular-ops-data';
import { Basis, CommodityProfile, ContractMonth, DeliveryPeriodBasis, HMSClientSettings, Location } from '@advance-trading/ops-data-lib';

import { BottomSheetDeliveryPeriodComponent } from '../bottom-sheet-delivery-period/bottom-sheet-delivery-period.component';
import { BottomSheetMassUpdateComponent } from '../bottom-sheet-mass-update/bottom-sheet-mass-update.component';
import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { ExportService } from '../../service/export.service';
import { ImportService } from '../../service/import.service';
import { ConfirmDialogComponent } from '../../utilities/ui/confirm-dialog/confirm-dialog.component';
import { UserRoles } from '../../utilities/user-roles';

import { BasisAdminValidators, MONTH_CODE_REGEX, BASIS_REGEX } from './basis-admin.validator';

const ROW_OFFSET = 2;
const INITIAL_PAGEINDEX = 0;
const INITIAL_PAGESIZE = 10;

interface CommodityProfileBases extends CommodityProfile {
  bases$: Observable<Basis[]>;
}

interface BasisRow {
  map: {string: string};
}

const MONTH_CODE_DICT = {
  Jan: ContractMonth.F,
  Feb: ContractMonth.G,
  Mar: ContractMonth.H,
  Apr: ContractMonth.J,
  May: ContractMonth.K,
  Jun: ContractMonth.M,
  Jul: ContractMonth.N,
  Aug: ContractMonth.Q,
  Sep: ContractMonth.U,
  Oct: ContractMonth.V,
  Nov: ContractMonth.X,
  Dec: ContractMonth.Z
};

@Component({
  selector: 'hms-basis-admin',
  templateUrl: './basis-admin.component.html',
  styleUrls: ['./basis-admin.component.scss']
})
export class BasisAdminComponent implements OnInit, AfterViewChecked {
  errorMessage = '';
  isLoading = true;
  editMode = false;
  updateSuccess = true;

  commodityProfileBases$: Observable<CommodityProfileBases[]>;
  selectedProfile: CommodityProfileBases;
  basisFormMap: {[profileDocId: string]: FormGroup} = {};
  dataSourceMap: {[profileDocId: string]: MatTableDataSource<BasisRow>} = {};
  columnsToDisplayMap: {[profileDocId: string]: string[]} = {};

  clientSettings: HMSClientSettings;

  // Advanced add/edit basis variables
  monthSelected: string;
  yearSelected: string;
  rowSelection: SelectionModel<any> = new SelectionModel<any>(true, []);
  colSelection: SelectionModel<any> = new SelectionModel<any>(true, []);
  periodChanged = false;

  private locations: Location[];
  private activeLocationMap: {[locationDocId: string]: Location};
  private commodityProfileBases: CommodityProfileBases[];
  private profileBasesMap: {[profileDocId: string]: {[locationDocId: string]: Basis}} = {};
  private selectedClientDocId = '';
  private isBasisLoadedMap = {};

  // paginator state tracker for specifying formControlName
  // note: this handles case where page is rendered, but paginator is still undefined
  private pageIndex = INITIAL_PAGEINDEX;
  private pageSize = INITIAL_PAGESIZE;
  private selectedTabIdx = 0;
  private paginators: MatPaginator[];

  // variables for basis import
  private commodityProfileMap: {[profileName: string]: CommodityProfile} = {};
  private locationIndexMap: {[locationName: string]: number} = {};
  private readonly SHEET_NAME = 'HMS Basis';

  // workaround when paginator is not detected with elements that have *ngIf
  // reference link: https://github.com/angular/components/issues/10205
  @ViewChildren(MatPaginator) set matPaginators(mp: QueryList<MatPaginator>) {
    this.paginators = mp.toArray();

    // get profile list in the order that it was initialized (should be consistent with tab index)
    const profileDocIds = Object.keys(this.dataSourceMap);
    if (profileDocIds.length === this.paginators.length) {
      profileDocIds.forEach((profileDocId, index) => {
        this.dataSourceMap[profileDocId].paginator = this.paginators[index];
      });
    }
  }
  @ViewChild('tabs', {static: false}) tabs: MatTabGroup;

  constructor(
    private authzService: Auth0AuthzService,
    private basisService: BasisService,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    public exportService: ExportService,
    private formBuilder: FormBuilder,
    public importService: ImportService,
    private locationService: LocationService,
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    const isBasisAdmin = this.authzService.currentUserHasRole(UserRoles.BASIS_ADMIN_ROLE);
    if (!isBasisAdmin) {
      this.errorMessage = 'You do not have permission to administer basis.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.commodityProfileBases$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap(client => {
        this.selectedClientDocId = client.docId;
        return this.clientSettingsService.getHmsSettingsByClientDocId(client.docId);
      }),
      switchMap((settings: HMSClientSettings) => {
        this.clientSettings = settings;
        return this.locationService.getActiveLocationsByClientDocId(this.selectedClientDocId);
      }),
      switchMap((locations: Location[]) => {
        // get locations in order of ascending client locations and ascending non-client locations
        const activeClientLocations = locations.filter((location: Location) => location.isClientLocation);
        const activeNonClientLocations = locations.filter((location: Location) => !location.isClientLocation);
        this.locations = activeClientLocations.concat(activeNonClientLocations);

        this.activeLocationMap = {};
        this.locations.forEach((location: Location, index: number) => {
          this.activeLocationMap[location.docId] = location;
          this.locationIndexMap[location.name] = index;
        });
        return this.commodityProfileService.getActiveCommodityProfilesWithBasisByClientDocId(this.selectedClientDocId);
      }),
      map((commodityProfiles: CommodityProfile[]) => {
        const cpBases = [];

        commodityProfiles.forEach(profile => {
          this.commodityProfileMap[profile.name] = profile;
          cpBases.push({...profile, bases$: this.getBasesObservable(profile) });
          // initialize datasource for each commodity profile
          this.dataSourceMap[profile.docId] = new MatTableDataSource<BasisRow>();
        });
        return cpBases;
      }),
      tap( (cpBases: CommodityProfileBases[]) => {
        this.commodityProfileBases = cpBases;
        // When there is no selected profile yet, we initialize with the first commodity profile bases
        if (!this.selectedProfile && cpBases.length) {
          this.selectedProfile = cpBases[0];
        }
        if (!cpBases.length) {
          this.isLoading = false;
          this.openSnackBar('There are no active commodity profiles for commodities with basis.', 'DISMISS', false);
        }
      }),
      shareReplay({bufferSize: 1, refCount: true}),
      catchError(err => {
        this.errorMessage = 'Error retrieving basis data; please try again later';
        console.error(`Error retrieving basis data: ${err}`);
        return of([]);
      })
    );

  }

  ngAfterViewChecked() {
    // Change the original behavior of mat-tab-groups (to handle change tab event)
    if (this.tabs) {
      this.tabs._handleClick = this.interceptTabChange.bind(this);
    }
  }

  onTabSelect(e) {
    // clear selected checkboxes to initial state
    this.clearSelection();
    // reinitialize table of currently selected profile if there was a change previously
    if (this.basisFormMap[this.selectedProfile.docId].invalid ||
        this.basisFormMap[this.selectedProfile.docId].dirty ||
        this.periodChanged) {
        // reset paginator state since table is about to be re rendered
        // note: paginator is not being reevaluated, so we need to reset the state to have view consistency
        this.paginators[this.selectedTabIdx].pageIndex = INITIAL_PAGEINDEX;
        this.paginators[this.selectedTabIdx].pageSize = INITIAL_PAGESIZE;
        // populate table data so the table is re rendered
        this.populateTableData(this.selectedProfile.docId);
    }
    this.selectedTabIdx = e.index;
    this.selectedProfile = this.commodityProfileBases[e.index];

    // re-initialize tracked paginator state by invoking page change event
    this.handlePageChange();
  }

  clearSelection() {
    this.rowSelection.clear();
    this.colSelection.clear();
  }

  handleSave() {
    this.updateBasis();
  }

  setEditMode(value: boolean) {
    this.editMode = value;

    if (this.editMode) {
      this.commodityProfileBases.forEach(profile => {
        // initiate setDisabledState to be called by the basis-input component
        this.basisFormMap[profile.docId].enable();
      });
    } else {
      this.commodityProfileBases.forEach(profile => {
        if (profile.docId === this.selectedProfile.docId) {
          // re-render spreadsheet of selected profile
          this.populateTableData(profile.docId);
        } else {
          // disable spreadsheet (to handle if previously enabled)
          this.basisFormMap[profile.docId].disable();
        }
      });
    }
  }

  handleDiscard() {
    this.clearSelection();
    this.setEditMode(false);
  }

  openMassUpdateSheet() {
    const bottomSheetRef = this.bottomSheet.open(BottomSheetMassUpdateComponent, {data: {
      clientSettings: this.clientSettings
    }});
    bottomSheetRef.afterDismissed().subscribe(data => {
      if (data) {
        this.handleMassUpdate(data.massUpdate);
      }
    });
  }

  openManagePeriodSheet() {
    const periodList = this.columnsToDisplayMap[this.selectedProfile.docId].slice(1);

    const bottomSheetRef = this.bottomSheet.open(BottomSheetDeliveryPeriodComponent, {data: periodList});
    bottomSheetRef.afterDismissed().subscribe(data => {
      if (data) {
        const newColumnToDisplay = [];
        newColumnToDisplay.push('location');
        data['periods'].forEach(period => {
          newColumnToDisplay.push(period);
        });
        this.manageDeliveryPeriod(newColumnToDisplay);
      }
    });
  }

  handlePageChange() {
    this.pageSize = this.paginators[this.selectedTabIdx].pageSize;
    this.pageIndex = this.paginators[this.selectedTabIdx].pageIndex;
  }

  getConvertedIndex(index: number) {
    return index + (this.pageSize * this.pageIndex);
  }

  getFormControlName(index: number, col: string) {
    return `${this.getConvertedIndex(index)}-${col}`;
  }

  getExportableItems() {
    const exportableItems: {[sheetName: string]: any[]} = {};
    exportableItems[this.SHEET_NAME] = [];
    Object.keys(this.dataSourceMap).forEach((profileDocId: string) => {
      const currProfile = this.commodityProfileBases.find((profile: CommodityProfileBases) => profile.docId === profileDocId);
      // get rows populated
      this.dataSourceMap[profileDocId].data.forEach((row: BasisRow) => {
        const exportableRow = {};
        exportableRow['commodity profile'] = currProfile.name;
        Object.keys(row).forEach(col => {
          if (col === 'location') {
            exportableRow[col] = this.activeLocationMap[row[col]].name;
          } else if (row[col]) {
            exportableRow['delivery period'] = col;
            exportableRow['futures'] = row[col]['futuresYearMonth'];
            exportableRow['basis'] = row[col]['basis'].toFixed(4);
            exportableItems[this.SHEET_NAME].push({...exportableRow});
          }
        });
      });
    });
    return exportableItems;
  }

  importBasisFile() {
    document.getElementById('spreadsheet-file').click();
  }

  convertBasisFileToSpreadsheet(file: File) {
    // Note: handle workaround ensuring the file is reinitialized on every import button click.
    // There's a logic to clear the selected file in the template ensuring an import happen every time even
    // if the user imports the same file. The check here is needed because clearing selected file is considered a change event,
    // which we want to ignore.
    if (!file) {
      return;
    }

    const importedRows = {};
    // check for duplicate rows
    const importedRowMap = {};
    const importedDeliveryPeriodMap = {};
    // used to validate delivery periods imported from spreadsheet
    let importedDeliveryPeriods = [];
    // used to validate locations imported from spreadsheet
    const importedLocations = [];
    // used to validate commodity profiles imported from spreadsheet
    const importedCommodityProfiles = [];
    this.importService.importXlsx(file).then(sheets => {
      this.populateTableData(this.selectedProfile.docId);
      this.validateSheetName(sheets);
      this.validateSpreadsheetHeaders(sheets);

      // first iteration is to prepare form to exist for the import by managing delivery period
      // and import all data for validation
      sheets[this.SHEET_NAME].forEach((row, rowIdx: number) => {
        if (this.importedProfileMatchesSelectedProfile(row['commodity profile'])) {
          this.checkImportedRowForDuplicates(importedRowMap, row, rowIdx);
          importedDeliveryPeriodMap[row['delivery period']] = true;
        }
        row['delivery period'] = row['delivery period'].toUpperCase();
        importedDeliveryPeriods.push(row['delivery period']);
        importedLocations.push(row['location']);
        importedCommodityProfiles.push(row['commodity profile']);
      });

      this.validateSpreadsheetCommodityProfiles(importedCommodityProfiles);
      this.validateSpreadsheetLocations(importedLocations);
      this.validateSpreadsheetDeliveryPeriods(importedDeliveryPeriods);

      importedDeliveryPeriods = ['location'];
      importedDeliveryPeriods = importedDeliveryPeriods.concat(Object.keys(importedDeliveryPeriodMap).sort());
      this.manageDeliveryPeriod(importedDeliveryPeriods);

      // second iteration is to populate form fields and marking the cells that changed dirty
      sheets[this.SHEET_NAME].forEach(row => {
        if (this.importedProfileMatchesSelectedProfile(row['commodity profile'])) {
          const basis = this.parseBasis(row['basis']);
          const deliveryPeriod = row['delivery period'];
          const futuresYearMonth = row['futures'].toUpperCase();
          const updatedBasisCell = {
            basis,
            deliveryPeriod,
            futuresYearMonth
          } as DeliveryPeriodBasis;
          const rowIdx = this.locationIndexMap[row['location']];
          const currBasisCell: DeliveryPeriodBasis = this.basisFormMap[this.selectedProfile.docId]
                                                        .get(rowIdx + '-' + deliveryPeriod).value || {};
          importedRows[rowIdx + '-' + deliveryPeriod] = updatedBasisCell;

          if (currBasisCell.basis !== updatedBasisCell.basis
              || currBasisCell.futuresYearMonth !== updatedBasisCell.futuresYearMonth) {
            if (!Number.isFinite(updatedBasisCell.basis) && !updatedBasisCell.futuresYearMonth) {
              // ensure form is set to null if both basis and futuresYearMonth is empty
              this.basisFormMap[this.selectedProfile.docId].get(rowIdx + '-' + deliveryPeriod).setValue(null);
            } else {
              // ensure form has a value when either futuresYearMonth or basis is entered
              this.basisFormMap[this.selectedProfile.docId].get(rowIdx + '-' + deliveryPeriod).setValue(updatedBasisCell);
            }
            this.basisFormMap[this.selectedProfile.docId].get(rowIdx + '-' + deliveryPeriod).markAsDirty();
          }
        }
      });

      this.clearMissedCells(importedRows);

      if (this.basisFormMap[this.selectedProfile.docId].pristine) {
        this.openSnackBar(`No Basis Changes Made for ${this.selectedProfile.name}`, 'DISMISS', true);
      } else {
        this.openSnackBar(`Basis Imported for ${this.selectedProfile.name}`, 'DISMISS', true);
      }

    }).catch(err => {
      // cancel importing and re-populate table
      console.error(`Basis import failed: ${err}`);
      this.populateTableData(this.selectedProfile.docId);
      const errorNames = ['MissingLocationError', 'InvalidLocationError', 'MissingCommodityProfileError',
                          'InvalidCommodityProfileError', 'InvalidDeliveryPeriodError', 'MissingDeliveryPeriodError',
                          'InvalidImportedHeaderError', 'EmptyImportedHeaderError', 'DuplicateRowError',
                          'IncorrectSheetName'];
      if (errorNames.includes(err.name)) {
        this.openSnackBar(`Basis Import Cancelled: ${err.message}`, 'DISMISS', false);
      } else {
        this.openSnackBar(`Something Unexpected Occurred`, 'DISMISS', false);
      }
    });
  }

  getErrorMessage() {
    if (this.clientSettings.useWholeCent) {
      return 'Each basis cell should contain a number (to the whole cent) and \
              a valid three character futures period for the commodity (e.g. 20N for 2020 July)';
    } else {
      return 'Each basis cell should contain a number (to the quarter cent) and \
              a valid three character futures period for the commodity (e.g. 20N for 2020 July)';
    }
  }

  private parseBasis(basis: string) {
    return BASIS_REGEX.test(basis) && Number.isFinite(parseFloat(basis)) ? parseFloat(basis) : '';
  }

  private validateSpreadsheetDeliveryPeriods(importedDeliveryPeriods: string[]) {
    // validate delivery periods, and throw error if delivery periods are invalid
    importedDeliveryPeriods.forEach((deliveryPeriod: string, rowIdx: number) => {
      if (!MONTH_CODE_REGEX.test(deliveryPeriod)) {
        const invalidDeliveryPeriodError = new Error();
        if (this.isImportedCellEmpty(deliveryPeriod)) {
          invalidDeliveryPeriodError.name = 'MissingDeliveryPeriodError';
          invalidDeliveryPeriodError.message = `Missing delivery period detected at row ${rowIdx + ROW_OFFSET}. \
          Valid delivery period should be formatted as 2-digit year followed by a month code (i.e 21F)`;
        } else {
          invalidDeliveryPeriodError.name = 'InvalidDeliveryPeriodError';
          invalidDeliveryPeriodError.message = `Invalid delivery period '${deliveryPeriod}' detected at row ${rowIdx + ROW_OFFSET}. \
          Valid delivery period should be formatted as 2-digit year followed by a month code (i.e 21F)`;
        }
        throw invalidDeliveryPeriodError;
      }
    });
  }

  private validateSpreadsheetLocations(importedLocations: string[]) {
    importedLocations.forEach((importedLocation, rowIdx: number) => {
      if (!this.locations.find(location => location.name === importedLocation)) {
        const unexpectedLocationError = new Error();
        if (this.isImportedCellEmpty(importedLocation)) {
          unexpectedLocationError.name = 'MissingLocationError';
          unexpectedLocationError.message = `No Location found at row ${rowIdx + ROW_OFFSET}.`;
        } else {
          unexpectedLocationError.name = 'InvalidLocationError';
          unexpectedLocationError.message = `'${importedLocation}' at row ${rowIdx + ROW_OFFSET} is not a valid location in HMS.`;
        }
        throw unexpectedLocationError;
      }
    });
  }

  private validateSpreadsheetCommodityProfiles(importedCommodityProfiles: string[]) {
    if (importedCommodityProfiles.includes(this.selectedProfile.name)) {
      importedCommodityProfiles.forEach((commodityProfile, rowIdx: number) => {
        if (this.isImportedCellEmpty(commodityProfile)) {
          const noCommodityProfileError = new Error(`Missing commodity profile at row ${rowIdx + ROW_OFFSET} in the imported spreadsheet`);
          noCommodityProfileError.name = 'MissingCommodityProfileError';
          throw noCommodityProfileError;
        } else if (!this.commodityProfileBases.find(profileName => profileName.name === commodityProfile)) {
          const noCommodityProfileError = new Error(`'${commodityProfile}' at row ${rowIdx + ROW_OFFSET} is not a valid commodity profile in HMS`);
          noCommodityProfileError.name = 'InvalidCommodityProfileError';
          throw noCommodityProfileError;
        }
      });
      // Find at least one instance of currently selected commodity profile
      if (!importedCommodityProfiles.find(profileName => profileName === this.selectedProfile.name)) {
        const noCommodityProfileError = new Error(`Cannot find commodity profile ${this.selectedProfile.name} in the imported spreadsheet`);
        noCommodityProfileError.name = 'InvalidCommodityProfileError';
        throw noCommodityProfileError;
      }
    }
  }

  private checkImportedRowForDuplicates(importedRowMap: {[key: string]: number}, row: any, rowIdx: number) {
    // check imported spreadsheet for duplicate entries
    const rowMapKey = row['commodity profile'] + row['location'] + row['delivery period'];
    if (!importedRowMap[rowMapKey]) {
      importedRowMap[rowMapKey] = rowIdx + ROW_OFFSET;
    } else {
      const duplicateRowError = new Error(`Duplicate rows found at row ${importedRowMap[rowMapKey]} & ${rowIdx + ROW_OFFSET}`);
      duplicateRowError.name = 'DuplicateRowError';
      throw duplicateRowError;
    }
  }

  private validateSpreadsheetHeaders(sheets) {
    if (sheets[this.SHEET_NAME].length) {
      const expectedHeaders = ['commodity profile', 'location', 'delivery period', 'futures', 'basis'];
      const importedHeaders = Object.keys(sheets[this.SHEET_NAME][0]);
      const invalidHeaders = importedHeaders.filter(header => !expectedHeaders.includes(header));
      if (invalidHeaders.includes('__EMPTY')) {
        const invalidImportedHeaderError = new Error(`Missing Header(s) found in imported file: One or more of the header cells is blank`);
        invalidImportedHeaderError.name = 'EmptyImportedHeaderError';
        throw invalidImportedHeaderError;
      } else if (invalidHeaders.length) {
        const invalidImportedHeaderError = new Error(`Unexpected Header(s) found in imported file: ${invalidHeaders.join(', ')}`);
        invalidImportedHeaderError.name = 'InvalidImportedHeaderError';
        throw invalidImportedHeaderError;
      }
    }
  }

  private validateSheetName(sheets) {
    if (!sheets[this.SHEET_NAME]) {
      const incorrectSheetName = new Error(`Cannot find sheet named '${this.SHEET_NAME}'`);
      incorrectSheetName.name = 'IncorrectSheetName';
      throw incorrectSheetName;
    }
  }

  private clearMissedCells(importedRows) {
    // Clear the value from any cell that previously had a value,
    // and no value was found in the imported spreadsheet
    Object.keys(this.basisFormMap[this.selectedProfile.docId].value).forEach(field => {
      const cellValue = this.basisFormMap[this.selectedProfile.docId].value[field];
      const importedRow = importedRows[field];
      if (cellValue && !importedRow) {
        this.basisFormMap[this.selectedProfile.docId].get(field).setValue(null);
        this.basisFormMap[this.selectedProfile.docId].get(field).markAsDirty();
      }
    });
  }

  private isImportedCellEmpty(colName: string) {
    return colName === '__EMPTY' || colName === '';
  }

  private importedProfileMatchesSelectedProfile(commodityProfileName: string) {
    return this.selectedProfile.name === commodityProfileName;
  }

  private isBasisValueChange(
    currDeliveryPeriodBases: {[key: string]: DeliveryPeriodBasis},
    newDeliveryPeriodBases: {[key: string]: DeliveryPeriodBasis}) {
    const currentPeriods = Object.keys(currDeliveryPeriodBases);
    const newPeriods = Object.keys(newDeliveryPeriodBases);
    let basisValueChange = false;
    currentPeriods.forEach(period => {
      // remove update
      if (currDeliveryPeriodBases[period] && !newDeliveryPeriodBases[period]) {
        basisValueChange = true;
      }
    });
    newPeriods.forEach(period => {
      // initialization update
      if (!currDeliveryPeriodBases[period] ) {
        basisValueChange = true;
      // value change update
      } else if (currDeliveryPeriodBases[period].basis !== newDeliveryPeriodBases[period].basis
        || currDeliveryPeriodBases[period].futuresYearMonth !== newDeliveryPeriodBases[period].futuresYearMonth) {
        basisValueChange = true;
      }
    });
    return basisValueChange;
  }

  private updateBasis() {
    this.updateSuccess = false;

    // Get all the basis value in the form that user entered
    const fields = Object.keys(this.basisFormMap[this.selectedProfile.docId].value);
    const newBasis = {};
    fields.forEach(field => {
      if (this.basisFormMap[this.selectedProfile.docId].value[field] !== null) {
        const fieldList = field.split('-');
        const locationIndex = parseInt(fieldList[0], 10);
        const deliveryPeriod = fieldList[1];
        const currentLocationDocId = this.locations[locationIndex].docId;

        if (!newBasis[currentLocationDocId] ) {
          newBasis[currentLocationDocId] = {};
        }
        newBasis[currentLocationDocId][deliveryPeriod] = this.basisFormMap[this.selectedProfile.docId].value[field];
      }
    });

    // Handle if the user remove all the basis of a current location
    this.locations.forEach(location => {
      if (!newBasis[location.docId]) {
        newBasis[location.docId] = {};
      }
    });

    // Filter to only have new basis for the location that have basis changes
    const filteredNewBasis = {};
    this.dataSourceMap[this.selectedProfile.docId].data.forEach((row: BasisRow) => {
      const currDeliveryPeriodBasis: {[key: string]: DeliveryPeriodBasis} = {};
      Object.keys(row).forEach(key => {
        if (key !== 'location') {
          currDeliveryPeriodBasis[key] = row[key] as DeliveryPeriodBasis;
        }
      });

      if (this.isBasisValueChange(currDeliveryPeriodBasis, newBasis[row['location']])) {
        filteredNewBasis[row['location']] = newBasis[row['location']];
      }
    });

    // Update basis database with new basis entered by the user
    const basisUpdates: Basis[] = [];
    const updatedLocationDocIds = Object.keys(filteredNewBasis);
    updatedLocationDocIds.forEach(locationDocId => {
      // Get the updated basis value
      const newDeliveryPeriodBases = filteredNewBasis[locationDocId];

      // Create a Basis object to be updated
      const newBasisData = new Basis();
      newBasisData.deliveryPeriodBases = newDeliveryPeriodBases;
      newBasisData.commodityProfileDocId = this.selectedProfile.docId;
      newBasisData.locationDocId = locationDocId;

      // Check if basis currently exist
      if (this.profileBasesMap[this.selectedProfile.docId][locationDocId]) {
        // Set the doc id to be the same as the ones that already exist
        newBasisData.docId = this.profileBasesMap[this.selectedProfile.docId][locationDocId].docId;
      }

      basisUpdates.push(newBasisData);
    });

    // Perform an update to the basis in the database
    this.basisService.setBases(this.selectedClientDocId, basisUpdates)
      .then(() => {
        this.updateSuccess = true;
        console.log('Basis successfully updated');
        this.setEditMode(false);

        // Clear checkboxes
        this.clearSelection();
        this.openSnackBar('Basis successfully updated', 'DISMISS', true);
      })
      .catch(err => {
        this.updateSuccess = true;
        console.error(`Basis update failed: ${JSON.stringify(err)}`);
        let errorMessage = '';
        switch (err.code) {
          case 'permission-denied':
            errorMessage = 'Insufficient permissions';
            break;
          default:
            errorMessage = 'Unknown error occurred';
        }
        this.openSnackBar('Basis update failed: ' + errorMessage, 'DISMISS', false);
      });

  }

  private populateTableData(profileDocId: string) {
    this.periodChanged = false;
    this.clearSelection();
    this.populateColumns(profileDocId);
    this.dataSourceMap[profileDocId].data = this.populateDataRows(profileDocId);
    this.populateFormGroup(profileDocId);

    // only set loading to false when everything is loaded
    this.isBasisLoadedMap[profileDocId] = true;
    if (Object.keys(this.isBasisLoadedMap).length === this.commodityProfileBases.length) {
      this.isLoading = false;
    }
  }

  private populateColumns(profileDocId: string) {
    const currentPeriod = this.getCurrentPeriod();
    this.columnsToDisplayMap[profileDocId] = [];
    Object.values(this.profileBasesMap[profileDocId]).forEach((basis: Basis) => {
      const currentPeriods = Object.keys(basis.deliveryPeriodBases);
      this.columnsToDisplayMap[profileDocId] = this.columnsToDisplayMap[profileDocId].concat(currentPeriods);
    });

    // filter duplicates period
    this.columnsToDisplayMap[profileDocId] = [... new Set(this.columnsToDisplayMap[profileDocId])];
    // Display only most recent delivery period
    this.columnsToDisplayMap[profileDocId] = this.columnsToDisplayMap[profileDocId].filter(period => period >= currentPeriod);

    // Sort column based on delivery period
    this.columnsToDisplayMap[profileDocId].sort();
    this.columnsToDisplayMap[profileDocId].splice(0, 0, 'location');
  }

  private getCurrentPeriod(): string {
    const currentMonth = moment().format('MMM');
    const currentYear = moment().format('YY');
    return currentYear + MONTH_CODE_DICT[currentMonth];
  }

  private populateDataRows(profileDocId: string): BasisRow[] {
    return this.locations.map((location: Location) => {
      const currentBasis = this.profileBasesMap[profileDocId][location.docId];
      const newRow = {};
      this.columnsToDisplayMap[profileDocId].forEach(column => {
        if (column === 'location') {
          newRow[column] = location.docId;
        } else {
          if (currentBasis) {
            const currentPeriodBasis = currentBasis.deliveryPeriodBases[column];
            if (currentPeriodBasis) {
              // Note: auto rounding to 2 decimal place if the client is set to use whole cent (display only)
              //       ensuring basis prices still being valid when the users first enter the basis admin.
              if (this.clientSettings.useWholeCent) {
                currentPeriodBasis.basis = this.roundToPrecision(currentPeriodBasis.basis, 2);
              }
              newRow[column] = Object.assign({}, currentPeriodBasis);
            } else {
              newRow[column] = null;
            }
          } else {
            newRow[column] = null;
          }
        }
      });
      return newRow as BasisRow;
    });
  }

  private populateFormGroup(profileDocId: string) {
    const priceValidator = this.clientSettings.useWholeCent ? BasisAdminValidators.wholeCentBasisValidator
                            : BasisAdminValidators.basisValidator;
    const formGroup = {};
    this.dataSourceMap[profileDocId].data.forEach((row, index) => {
      const keys = Object.keys(row);
      keys.forEach(key => {
        if (key !== 'location') {
          const field = index + '-' + key;
          // Only allow user to enter number
          formGroup[field] = new FormControl({
            value: row[key] ? {...row[key]} : null,
            disabled: !this.editMode
          }, [
            priceValidator,
            BasisAdminValidators.futuresYearMonthValidator,
            BasisAdminValidators.fieldDependencyValidator
          ]);
        }
      });
    });
    this.basisFormMap[profileDocId] = this.formBuilder.group(formGroup);
  }

  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private openConfirmDialog(title: string, mode: string, args?: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { title },
      height: 'auto',
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(confirm => {
      if (!confirm) {
        return;
      }

      // We can add another mode here if needed
      if (mode === 'change tab') {
        this.matTabSwitchTab(args);
      }

    });
  }

  private interceptTabChange() {
    // If the form is dirty, make sure that the user wants to switch tabs and lose their changes
    if (this.basisFormMap[this.selectedProfile.docId].dirty || this.periodChanged) {
      this.openConfirmDialog('All unsaved changes will be lost if you switch tabs', 'change tab', arguments);
    } else {
      // Let user switch tab if the form is not dirty
      this.matTabSwitchTab(arguments);
    }
  }

  private matTabSwitchTab(args) {
    MatTabGroup.prototype._handleClick.apply(this.tabs, args);
  }

  private handleMassUpdate(massUpdate: FormGroup) {
    if ((massUpdate.value['value'] !== '' || massUpdate.value['futuresMonth'] !== '') &&
         massUpdate.valid && massUpdate.value['operation'] === 'change') {
      // Update the value of selected rows and columns
      this.rowSelection.selected.forEach(row => {
        this.colSelection.selected.forEach(col => {
          const updatedFormVal = {};
          const updatedDeliveryPeriodBasis: DeliveryPeriodBasis =
              this.basisFormMap[this.selectedProfile.docId].get(row + '-' + col).value
              || {basis: '', futuresYearMonth: '', deliveryPeriod: col};
          if (massUpdate.value['value']) {
            updatedDeliveryPeriodBasis.basis = parseFloat(massUpdate.value['value']);
          }
          if (massUpdate.value['futuresMonth']) {
            updatedDeliveryPeriodBasis.futuresYearMonth = massUpdate.value['futuresMonth'];
          }
          updatedFormVal[row + '-' + col] = updatedDeliveryPeriodBasis;
          this.basisFormMap[this.selectedProfile.docId].patchValue(updatedFormVal);
          this.basisFormMap[this.selectedProfile.docId].get(row + '-' + col).markAsDirty();
        });
      });
    } else if (massUpdate.value['value'] !== '' && massUpdate.valid && massUpdate.value['operation'] === 'adjustment') {
      const updateVal = parseFloat(massUpdate.value['value']);

      // Update the value of selected rows and columns
      this.rowSelection.selected.forEach(row => {
        this.colSelection.selected.forEach(col => {
          // Only update the cells that have a basis value
          if (this.basisFormMap[this.selectedProfile.docId].value[row + '-' + col] !== null) {

            const updatedFormVal = {};
            let updatedDeliveryPeriodBasis: DeliveryPeriodBasis = this.basisFormMap[this.selectedProfile.docId].get(row + '-' + col).value;
            if (updatedDeliveryPeriodBasis) {
              // Round to 4 decimal places to avoid dirty numbers
              updatedDeliveryPeriodBasis.basis = Math.round((updatedDeliveryPeriodBasis.basis + updateVal) * 10000.0) / 10000.0;
            } else {
              updatedDeliveryPeriodBasis = {
                basis: updateVal,
                futuresYearMonth: '',
                deliveryPeriod: col
              };
            }
            // Convert number to string for form control value
            updatedFormVal[row + '-' + col] = updatedDeliveryPeriodBasis;
            this.basisFormMap[this.selectedProfile.docId].patchValue(updatedFormVal);
            this.basisFormMap[this.selectedProfile.docId].get(row + '-' + col).markAsDirty();
          }
        });
      });
    } else if (massUpdate.value['operation'] === 'clear') {
      this.clearSelected();
    }
  }

  private clearSelected() {
    this.rowSelection.selected.forEach(row => {
      this.colSelection.selected.forEach(col => {
        // Clear value from the form if it has a basis value
        if (this.basisFormMap[this.selectedProfile.docId].value[row + '-' + col]) {
          const updatedFormVal = {};
          updatedFormVal[row + '-' + col] = null;
          this.basisFormMap[this.selectedProfile.docId].patchValue(updatedFormVal);
          this.basisFormMap[this.selectedProfile.docId].get(row + '-' + col).markAsDirty();
        }
      });
    });
  }

  private getBasesObservable(profile: CommodityProfile): Observable<Basis[]> {
    return this.basisService.getBasesByClientCommodityProfile(this.selectedClientDocId, profile.docId).pipe(
      tap(bases => {
        this.profileBasesMap[profile.docId] = {};
        bases.forEach((basis: Basis) => {
          // Only put basis for active locations
          if (this.activeLocationMap[basis.locationDocId]) {
            this.profileBasesMap[profile.docId][basis.locationDocId] = basis;
          }
        });

        // Populate table
        this.populateTableData(profile.docId);
      })
    );
  }

  private manageDeliveryPeriod(newColumnsToDisplay: string[]) {
    // Remove and add period
    for (let i = 0; i < this.locations.length; i++) {
      // Remove period from form and column selection
      this.columnsToDisplayMap[this.selectedProfile.docId].forEach(col => {
        if (newColumnsToDisplay.findIndex(newCol => newCol === col) === -1) {
          // Remove from form
          this.basisFormMap[this.selectedProfile.docId].removeControl(i + '-' + col);
          // Remove from column selection if any
          this.colSelection.deselect(col);

          // Basis for a delivery period has been deleted.
          // Mark form as dirty so that the user is able to save
          this.basisFormMap[this.selectedProfile.docId].markAsDirty();
        }
      });

      // Add new delivery period
      newColumnsToDisplay.forEach(col => {
        if (this.columnsToDisplayMap[this.selectedProfile.docId].findIndex(currCol => currCol === col) === -1) {
          this.basisFormMap[this.selectedProfile.docId].addControl(i + '-' + col, new FormControl(
            {value: null, disabled: !this.editMode}
            , [
              BasisAdminValidators.basisValidator,
              BasisAdminValidators.futuresYearMonthValidator,
              BasisAdminValidators.fieldDependencyValidator
            ]
          ));
        }
      });
    }

    // Update column
    this.columnsToDisplayMap[this.selectedProfile.docId] = newColumnsToDisplay;

    // Indicate that there is a period change
    this.periodChanged = true;
  }

  private roundToPrecision(value: number, precision: number) {
    return Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
  }
}
