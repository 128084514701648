export class UserRoles {
  public static AD_HOC_ORDER_ADMIN_ROLE = 'AdHocOrderAdmin';
  public static AD_HOC_ORDER_VIEWER_ROLE = 'AdHocOrderViewer';
  public static BASIS_ADJUSTER_ROLE = 'BasisAdjuster';
  public static BASIS_ADMIN_ROLE = 'BasisAdmin';
  public static CLIENT_APPROVER_ROLE = 'ClientApprover';
  public static CLIENT_SETTINGS_ADMIN_ROLE = 'ClientSettingsAdmin';
  public static CLIENT_USER_ADMIN_ROLE = 'ClientUserAdmin';
  public static COMMODITY_PROFILE_ADMIN_ROLE = 'CommodityProfileAdmin';
  public static CONTRACT_CREATOR_ROLE = 'ContractCreator';
  public static CONTRACT_DELETER_ROLE = 'ContractDeleter';
  public static CONTRACT_REPORTS_GENERATOR_ROLE = 'ContractReportsGenerator';
  public static CONTRACT_UPDATER_ROLE = 'ContractUpdater';
  public static CONTRACT_VIEWER_ROLE = 'ContractViewer';
  public static EXCHANGE_CREATOR_ROLE = 'ExchangeCreator';
  public static FULL_LEDGER_VIEWER_ROLE = 'FullLedgerViewer';
  public static HEDGE_CREATOR_ROLE = 'HedgeCreator';
  public static HEDGED_CONTRACT_CREATOR_ROLE = 'HedgedContractCreator';
  public static HEDGE_VIEWER_ROLE = 'HedgeViewer';
  public static HMS_ADMIN_ROLE = 'HMSAdmin';
  public static LEDGER_ADJUSTMENT_ADMIN_ROLE = 'LedgerAdjustmentAdmin';
  public static LEDGER_ADJUSTMENT_VIEWER_ROLE = 'LedgerAdjustmentViewer';
  public static LOCATION_ADMIN_ROLE = 'LocationAdmin';
  public static LOCATION_LEDGER_VIEWER_ROLE = 'LocationLedgerViewer';
  public static MANUAL_PRICE_LOCKER_ROLE = 'ManualPriceLocker';
  public static MARKET_FUTURES_ADJUSTER_ROLE = 'MarketFuturesAdjuster';
  public static MERCHANDISING_REPORTS_GENERATOR_ROLE = 'MerchandisingReportsGenerator';
  public static OPERATIONS_REPORTS_GENERATOR_ROLE = 'OperationsReportsGenerator';
  public static ORDER_ADMIN_ROLE = 'OrderAdmin';
  public static ORDER_VIEWER_ROLE = 'OrderViewer';
  public static PATRON_ADMIN_ROLE = 'PatronAdmin';
  public static PERIOD_BATCH_UPDATER_ROLE = 'PeriodBatchUpdater';
  public static PRICE_ADJUSTER_ROLE = 'PriceAdjuster';
  public static USER_ADMIN_ROLE = 'UserAdmin';
}

export const ALERT_USER_ROLES = [
  UserRoles.HEDGE_CREATOR_ROLE,
  UserRoles.CLIENT_APPROVER_ROLE,
  UserRoles.ORDER_ADMIN_ROLE
];

export const REPORT_USER_ROLES = [
  UserRoles.CONTRACT_REPORTS_GENERATOR_ROLE,
  UserRoles.MERCHANDISING_REPORTS_GENERATOR_ROLE,
  UserRoles.OPERATIONS_REPORTS_GENERATOR_ROLE
];
