import { Component } from '@angular/core';

@Component({
  selector: 'hms-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {
  constructor() { }
}
