<div fxLayout="row" fxLayoutAlign="space-around start">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <hms-error-message [title]="'Error Retrieving Purchases & Sales Report'" [message]="errorMessage"></hms-error-message>

    <div *ngIf="!errorMessage && commodityProfiles$ | async" fxLayout="column" fxLayoutAlign="space-between start">
      <h2 data-cy="page-title-purchases-&-sales-report" class="card-title">Purchases & Sales Report</h2>
      <form [formGroup]="purchasesAndSalesSearchForm" (ngSubmit)="loadPurchasesAndSalesReportData()" fxLayout="column">
        <div fxLayoutGap.gt-xs="25px" fxLayout.xs="column">
          <mat-form-field fxFlex.gt-xs="250px">
            <input matInput [matDatepicker]="startDatePicker" placeholder="Start Date" formControlName="startDate" [min]="minStartDate" [max]="maxStartDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="purchasesAndSalesSearchForm.get('startDate').invalid">{{ getErrorMessage(purchasesAndSalesSearchForm.get('startDate')) }}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex.gt-xs="250px">
            <input matInput [matDatepicker]="endDatePicker" placeholder="End Date" formControlName="endDate" [min]="minEndDate" [max]="maxEndDate">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker [startAt]="startAtEndDate"></mat-datepicker>
            <mat-error *ngIf="purchasesAndSalesSearchForm.get('endDate').invalid">{{ getErrorMessage(purchasesAndSalesSearchForm.get('endDate')) }}</mat-error>
          </mat-form-field>
        </div>
        <div class="mat-small bottom-gap">Note: Quantities are displayed by Delivery Location. Dates begin and end on the Ledger End of Day ({{ ledgerEndOfDayLocal }}) not midnight.</div>
        <div *ngIf="!isLoading" fxLayout="row" fxLayoutGap="10px">
          <button id="generate-purchases-and-sales-report" [disabled]="purchasesAndSalesSearchForm.invalid" aria-label="Generate Report" type="submit" mat-raised-button color="accent">Generate Report</button>
          <button type="button" id="clear-purchases-and-sales-report" *ngIf="!purchasesAndSalesSearchForm.pristine" aria-label="Discard Report Options" (click)="reset()" mat-stroked-button color="accent">Reset</button>
        </div>
        <div *ngIf="isLoading" fxLayout="row" fxLayoutGap="10px">
          <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
        </div>

      </form>
      <div *ngIf="showMini && displayReports" id="client-summary-mini" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10" class="full-width">
        <h4>Client Summary</h4>
        <table mat-table [dataSource]="clientMiniDataSource" class="mat-elevation-z0 full-width" multiTemplateDataRows>

          <ng-container matColumnDef="commodityProfile">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstProdYear(clientMiniRow) ? 'first location mat-cell rotate' : 'dummy'}}" [attr.rowspan]="productionYearCount" ><div>{{ clientMiniRow.commodityProfileName }}</div></td>
          </ng-container>

          <ng-container matColumnDef="productionYear">
            <th mat-header-cell *matHeaderCellDef class="centered">Production Year</th>
            <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstProdYear(clientMiniRow) ? 'first mat-cell commodity-profile-mini' : 'mat-cell commodity-profile-mini'}}">{{ clientMiniRow.productionYear | productionYear }}</td>
          </ng-container>

          <ng-container matColumnDef="BUY">
            <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
            <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstProdYear(clientMiniRow) ? 'first mat-cell number-cell-mini' : 'mat-cell number-cell-mini'}}">{{ getDisplayAmount(clientMiniRow.BUY) }}</td>
          </ng-container>

          <ng-container matColumnDef="SELL">
            <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
            <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstProdYear(clientMiniRow) ? 'first mat-cell number-cell-mini' : 'mat-cell number-cell-mini'}}">{{ getDisplayAmount(clientMiniRow.SELL) }}</td>
          </ng-container>

          <ng-container matColumnDef="noDataFooter">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="clientMiniColumnsToDisplay.length">No Purchases or Sales</td>
          </ng-container>

          <tr mat-row *matHeaderRowDef="clientMiniColumnsToDisplay"></tr>
          <tr mat-row *matRowDef="let clientMiniRow; columns: clientMiniColumnsToDisplay"></tr>
          <tr mat-row *matFooterRowDef="['noDataFooter']" [style.display]="clientMiniDataSource.data.length ? 'none' : 'table-row'"></tr>
        </table>
      </div>
      <div *ngIf="!showMini && displayReports" id="client-summary-large" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10">
        <h4>Client Summary</h4>
        <table mat-table [dataSource]="clientDataSource" class="mat-elevation-z0" multiTemplateDataRows>

          <ng-container matColumnDef="commodityProfileHeader">
            <th mat-header-cell *matHeaderCellDef rowspan="2" class="mat-cell commodity-profile"></th>
          </ng-container>

          <ng-container [matColumnDef]="label" *ngFor="let label of allProductionYears">
            <th mat-header-cell *matHeaderCellDef colspan="2" class="centered">{{ label | productionYear }}</th>
          </ng-container>

          <ng-container matColumnDef="commodityProfileName">
            <th class="dummy" mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let clientRow" class="mat-cell commodity-profile">{{ clientRow.commodityProfileName }}</td>
          </ng-container>

          <ng-container matColumnDef="OLD_BUY">
            <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
            <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.OLD_BUY) }}</td>
          </ng-container>

          <ng-container matColumnDef="OLD_SELL">
            <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
            <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.OLD_SELL) }}</td>
          </ng-container>

          <ng-container matColumnDef="NEW_BUY">
            <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
            <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_BUY) }}</td>
          </ng-container>

          <ng-container matColumnDef="NEW_SELL">
            <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
            <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_SELL) }}</td>
          </ng-container>

          <ng-container matColumnDef="NEW_PLUS_1_BUY">
            <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
            <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_PLUS_1_BUY) }}</td>
          </ng-container>

          <ng-container matColumnDef="NEW_PLUS_1_SELL">
            <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
            <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_PLUS_1_SELL) }}</td>
          </ng-container>

          <ng-container matColumnDef="NEW_PLUS_2_BUY">
            <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
            <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_PLUS_2_BUY) }}</td>
          </ng-container>

          <ng-container matColumnDef="NEW_PLUS_2_SELL">
            <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
            <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_PLUS_2_SELL) }}</td>
          </ng-container>

          <ng-container matColumnDef="noDataFooter">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="clientColumnsToDisplay.length">No Purchases or Sales</td>
          </ng-container>

          <tr mat-row *matHeaderRowDef="clientParentHeaders"></tr>
          <tr mat-row *matHeaderRowDef="clientColumnsToDisplay"></tr>
          <tr mat-row *matRowDef="let clientRow; columns: clientColumnsToDisplay"></tr>
          <tr mat-row *matFooterRowDef="['noDataFooter']" [style.display]="clientDataSource.data.length ? 'none' : 'table-row'"></tr>
        </table>
      </div>
      <div *ngIf="!showMini && displayReports" id="location-summary" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10">
        <h4>Delivery Location Summary
          <button matTooltip="Export" aria-label="Export" id="export-purchase-sales-report" mat-icon-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button"><mat-icon>save_alt</mat-icon></button>
        </h4>
        <mat-menu #exportMenu="matMenu">
          <button type="button" aria-label="Export Excel" id="export-purchase-sales-report-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'P&S Report'))">Excel</button>
          <button type="button" aria-label="Export CSV" id="export-purchase-sales-report-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'P&S Report'))">CSV</button>
          <button type="button" aria-label="Export JSON" id="export-purchase-sales-report-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'P&S Report'))">JSON</button>
        </mat-menu>

        <table matTableExporter #exporter="matTableExporter" mat-table [dataSource]="locationDataSource" class="mat-elevation-z0" multiTemplateDataRows>

          <ng-container matColumnDef="locationHeader">
            <th mat-header-cell *matHeaderCellDef rowspan="2"></th>
          </ng-container>

          <ng-container matColumnDef="commodityProfileHeader">
            <th mat-header-cell *matHeaderCellDef rowspan="2" class="commodity-profile"></th>
          </ng-container>

          <ng-container [matColumnDef]="label" *ngFor="let label of allProductionYears">
            <th mat-header-cell *matHeaderCellDef colspan="2" class="centered">{{ label | productionYear }}</th>
          </ng-container>

          <ng-container matColumnDef="commodityProfileName">
            <th class="dummy" mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'first mat-cell commodity-profile-mini' : 'mat-cell commodity-profile-mini'}}">{{ locationRow.commodityProfileName }}</td>
          </ng-container>

          <ng-container matColumnDef="locationName">
            <th class="dummy" mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let locationRow" [attr.rowspan]="commodityProfileCount" class="{{ isFirstProfile(locationRow) ? 'first mat-cell rotate' : 'dummy'}}"><div>{{ locationRow.locationName }}</div></td>
          </ng-container>

          <ng-container matColumnDef="OLD_BUY">
            <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
            <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'first mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.OLD_BUY) }}</td>
          </ng-container>

          <ng-container matColumnDef="OLD_SELL">
            <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
            <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'first mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.OLD_SELL) }}</td>
          </ng-container>

          <ng-container matColumnDef="NEW_BUY">
            <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
            <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'first mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_BUY) }}</td>
          </ng-container>

          <ng-container matColumnDef="NEW_SELL">
            <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
            <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'first mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_SELL) }}</td>
          </ng-container>

          <ng-container matColumnDef="NEW_PLUS_1_BUY">
            <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
            <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'first mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_PLUS_1_BUY) }}</td>
          </ng-container>

          <ng-container matColumnDef="NEW_PLUS_1_SELL">
            <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
            <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'first mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_PLUS_1_SELL) }}</td>
          </ng-container>

          <ng-container matColumnDef="NEW_PLUS_2_BUY">
            <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
            <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'first mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_PLUS_2_BUY) }}</td>
          </ng-container>

          <ng-container matColumnDef="NEW_PLUS_2_SELL">
            <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
            <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'first mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_PLUS_2_SELL) }}</td>
          </ng-container>

          <ng-container matColumnDef="noDataFooter">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="locationColumnsToDisplay.length">No Purchases or Sales</td>
          </ng-container>

          <tr mat-row *matHeaderRowDef="locationParentHeaders"></tr>
          <tr mat-row *matHeaderRowDef="locationColumnsToDisplay"></tr>
          <tr mat-row *matRowDef="let locationRow; columns: locationColumnsToDisplay"></tr>
          <tr mat-row *matFooterRowDef="['noDataFooter']" [style.display]="locationDataSource.data.length ? 'none' : 'table-row'"></tr>
        </table>
      </div>
    </div>

  </mat-card>
</div>

