import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AuthService } from '@advance-trading/angular-ati-security';
import { Client, Hedge } from '@advance-trading/ops-data-lib';

@Injectable({
  providedIn: 'root'
})
export class HedgeService {

  constructor(
    private db: AngularFirestore,
    private authService: AuthService
  ) { }

  /**
   * Create a Hedge document for the specified Client
   *
   * @param clientDocId clientDocId The docId of the Client
   * @param hedge The Hedge object to add
   */
  createHedge(clientDocId: string, hedge: Hedge): Promise<void> {
    hedge.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.doc<Hedge>(`${Client.getDataPath(clientDocId)}/${Hedge.getDataPath(hedge.docId)}`).set(hedge.getPlainObject());
  }

  /**
   * Update a Hedge document for the specified Client
   *
   * @param clientDocId The docId of the Client containing the updated hedge
   * @param hedge The Hedge object to update
   */
  updateHedge(clientDocId: string, hedge: Hedge): Promise<void> {
    hedge.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.doc(`${Client.getDataPath(clientDocId)}/${Hedge.getDataPath(hedge.docId)}`).update(hedge);
  }

  /**
   * Return all hedges for the specified Client
   *
   * @param clientDocId The docId of the Client containing the Hedges
   */
  getAllHedgesByClientDocId(clientDocId: string): Observable<Hedge[]> {
    return this.db.collection<Hedge>(`${Client.getDataPath(clientDocId)}/${Hedge.getDataPath()}`)
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return hedges for the specified Client and date range
   * @param clientDocId The docId of the Client containing the Hedges
   * @param startDate The date before or when the Hedges were made
   * @param endDate The date after or when the Hedges were made
   */
  getHedgesByDateRange(clientDocId: string, startDate: string, endDate: string): Observable<Hedge[]> {
    return this.db.collection<Hedge>(`${Client.getDataPath(clientDocId)}/${Hedge.getDataPath()}`,
      ref => ref.where('creationTimestamp', '>=', startDate).where('creationTimestamp', '<=', endDate))
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return hedge for the specified Client by docId
   *
   * @param clientDocId The docId of the Client containing the Hedges
   * @param hedgeDocId The docId of Hedge to be returned
   */
  getHedgeByDocId(clientDocId: string, hedgeDocId: string): Observable<Hedge> {
    return this.db.doc<Hedge>(`${Client.getDataPath(clientDocId)}/${Hedge.getDataPath(hedgeDocId)}`)
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return hedges for the specified Client and futures month
   * @param clientDocId The docId of the Client containing the Hedges
   * @param futuresYearMonth The futures month in Hedges being returned
   */
  getHedgesByFuturesMonth(clientDocId: string, futuresYearMonth: string): Observable<Hedge[]> {
    return this.db.collection<Hedge>(`${Client.getDataPath(clientDocId)}/${Hedge.getDataPath()}`,
      ref => ref.where('futuresYearMonth', '==', futuresYearMonth))
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return hedges for the specified Client and Order
   * @param clientDocId The docId of the Client containing the Hedges
   * @param orderDocId The docId of the Order of Hedges being returned
   */
  getHedgesByOrderDocId(clientDocId: string, orderDocId: string): Observable<Hedge[]> {
    return this.db.collection<Hedge>(`${Client.getDataPath(clientDocId)}/${Hedge.getDataPath()}`,
      ref => ref.where('orderDocId', '==', orderDocId))
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return hedges for the specified Client and commodity profile
   * @param clientDocId The docId of the Client containing the Hedges
   * @param commodityProfileDocId The commodity profile doc id in Hedges being returned
   */
  getHedgesByCommodityProfileDocId(clientDocId: string, commodityProfileDocId: string): Observable<Hedge[]> {
    return this.db.collection<Hedge>(`${Client.getDataPath(clientDocId)}/${Hedge.getDataPath()}`,
      ref => ref.where('commodityProfileDocId', '==', commodityProfileDocId))
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return hedges for the specified Client and date range and futures month
   * @param clientDocId The docId of the Client containing the Hedges
   * @param startDate The date before or when the Hedges were made
   * @param endDate The date after or when the Hedges were made
   * @param futuresYearMonth The futures month in Hedges being returned
   */
  getHedgesByDateRangeAndFuturesMonth(clientDocId: string, startDate: string, endDate: string,
                                      futuresYearMonth: string): Observable<Hedge[]> {
    return this.db.collection<Hedge>(`${Client.getDataPath(clientDocId)}/${Hedge.getDataPath()}`,
      ref => ref.where('creationTimestamp', '>=', startDate).where('creationTimestamp', '<=', endDate)
      .where('futuresYearMonth', '==', futuresYearMonth))
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return hedges for the specified Client and date range and commodity profile
   * @param clientDocId The docId of the Client containing the Hedges
   * @param startDate The date before or when the Hedges were made
   * @param endDate The date after or when the Hedges were made
   * @param commodityProfileDocId The commodity profile doc id in Hedges being returned
   */
  getHedgesByDateRangeAndCommodityProfileDocId(clientDocId: string, startDate: string, endDate: string,
                                               commodityProfileDocId: string): Observable<Hedge[]> {
    return this.db.collection<Hedge>(`${Client.getDataPath(clientDocId)}/${Hedge.getDataPath()}`,
      ref => ref.where('creationTimestamp', '>=', startDate).where('creationTimestamp', '<=', endDate)
      .where('commodityProfileDocId', '==', commodityProfileDocId))
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }


  /**
   * Return hedges for the specified Client and futures month and commodity profile doc id
   * @param clientDocId The docId of the Client containing the Hedges
   * @param futuresYearMonth The futures month in Hedges being returned
   * @param commodityProfileDocId The commodity profile doc id in Hedges being returned
   */
  getHedgesByFuturesMonthAndCommodityProfileDocId(clientDocId: string,
                                                  futuresYearMonth: string,
                                                  commodityProfileDocId: string): Observable<Hedge[]> {
    return this.db.collection<Hedge>(`${Client.getDataPath(clientDocId)}/${Hedge.getDataPath()}`,
      ref => ref.where('futuresYearMonth', '==', futuresYearMonth)
      .where('commodityProfileDocId', '==', commodityProfileDocId))
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return hedges for the specified Client and date range and futures month and commodity profile doc id
   * @param clientDocId The docId of the Client containing the Hedges
   * @param startDate The date before or when the Hedges were made
   * @param endDate The date after or when the Hedges were made
   * @param futuresYearMonth The futures month in Hedges being returned
   * @param commodityProfileDocId The commodity profile doc id in Hedges being returned
   */
  getHedgesByDateRangeAndFuturesMonthAndCommodityProfileDocId(clientDocId: string,
                                                              startDate: string, endDate: string,
                                                              futuresYearMonth: string,
                                                              commodityProfileDocId: string): Observable<Hedge[]> {
    return this.db.collection<Hedge>(`${Client.getDataPath(clientDocId)}/${Hedge.getDataPath()}`,
      ref => ref.where('creationTimestamp', '>=', startDate).where('creationTimestamp', '<=', endDate)
      .where('futuresYearMonth', '==', futuresYearMonth)
      .where('commodityProfileDocId', '==', commodityProfileDocId))
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

}
