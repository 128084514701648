<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

      <hms-error-message [title]="'Error Retrieving Patron Details'" [message]="errorMessage"></hms-error-message>

      <div *ngIf="!errorMessage" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h2 data-cy="page-title-patrons" class="card-title">Patrons</h2>
          <div *ngIf="isLoading" fxFlex="100" fxLayoutAlign="end center">
            <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
          </div>
          <button type="button" *ngIf="!isLoading && isPatronAdmin" id="add-new-patron" class="action-button" aria-label="Add New Patron" (click)="addPatron()" mat-mini-fab color="accent" matTooltip="Add New Patron">
            <mat-icon>add</mat-icon>
          </button>
        </div>

        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="patrons-filter-field" #filter>
          <button type="button" id="clear-patrons-filter" mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0">
            <ng-container matColumnDef="accountingSystemId">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="35" fxFlex.gt-xs="40">ID</mat-header-cell>
                <mat-cell *matCellDef="let patron" fxFlex.xs="35" fxFlex.gt-xs="40">{{ patron.accountingSystemId }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="50" fxFlex.gt-xs="40">Name</mat-header-cell>
                <mat-cell *matCellDef="let patron" fxFlex.xs="50" fxFlex.gt-xs="40">{{ patron.name }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="isActive">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="15" fxFlex.gt-xs="20">Active</mat-header-cell>
                <mat-cell *matCellDef="let patron" fxFlex.xs="15" fxFlex.gt-xs="20">{{ patron.isActive | yesNo }}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row (click)="selectPatron(patron)" *matRowDef="let patron; columns: columnsToDisplay"></mat-row>
        </mat-table>
        <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50]"></mat-paginator>

      </div>
    </mat-card>
  </div>
