<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
      <div *ngIf="!errorMessage" fxLayout.gt-xs="row" fxLayout.xs="row wrap" fxLayoutAlign.gt-xs="space-between center" fxLayoutAlign.xs="start start">
        <div fxLayout="row" fxLayoutAlign="start baseline">
          <h2 data-cy="page-title-activity-ledger" class="card-title">Activity Ledger</h2>
          <button id="activity-ledger-go-live" *ngIf="selectedDate && !isLedgerLive && !isLoading" mat-button type="button" color="accent" (click)="goLive()">GO LIVE</button>
          <mat-spinner *ngIf="isLoading" class="loading-indicator" diameter="32" color="accent"></mat-spinner>
        </div>
        <form fxLayout="row" fxLayoutAlign="start baseline" [formGroup]="activityLedgerForm" *ngIf="ledgerEndOfDay$ | async" fxFlex.xs="100">
          <mat-form-field fxFlex="100">
            <input matInput required [matDatepicker]="ledgerDatePicker" placeholder="Ledger Date" formControlName="ledgerDate" [max]="currentBusinessDay" (keydown)="listenForEnterOrTab($event)">
            <mat-datepicker-toggle matSuffix [for]="ledgerDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #ledgerDatePicker (closed)="onDatePickerClosed()"></mat-datepicker>
            <mat-error *ngIf="activityLedgerForm.get('ledgerDate').invalid">{{ getErrorMessage(activityLedgerForm.get('ledgerDate')) }}</mat-error>
          </mat-form-field>
        </form>
      </div>

      <hms-error-message [title]="'Error Retrieving Activity Ledger'" [message]="errorMessage"></hms-error-message>

      <div *ngIf="!errorMessage">
        <div *ngIf="isLedgerLive" fxLayout="row" fxLayoutAlign.gt-xs="space-between center" fxLayout.xs="column">
          <hms-positions-summary fxFlex.xs="100" fxFlex.gt-xs="45" fxFlex.gt-md="35" (loaded)="onPositionsSummaryLoaded()" (positionsSummaryError)="onPositionsSummaryError($event)" [commodityProfiles]="activeNonSpreadCommodityProfiles"></hms-positions-summary>
          <hms-basis-summary fxFlex.xs="100" fxFlex.gt-xs="45" fxFlex.gt-md="35" (loaded)="onBasisSummaryLoaded()" (basisSummaryError)="onBasisSummaryError($event)" [commodityProfiles]="activeNonSpreadCommodityProfiles"></hms-basis-summary>
        </div>
        <hms-activity-log *ngIf="selectedDate" (loaded)="onActivityLogLoaded()" (activityLogError)="onActivityLogError($event)" [ledgerDate]="selectedDate" [ledgerEndOfDay]="ledgerEndOfDay" [timezone]="timezone" [commodityProfiles]="allCommodityProfiles"></hms-activity-log>
      </div>
    </mat-card>
  </div>
</div>
