import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { LocationService } from '@advance-trading/angular-ops-data';
import { Client, Location, PhoneNumber } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { UserRoles } from '../../utilities/user-roles';
import { UniqueKeyValidator } from '../../utilities/validators/unique-key-validator';

@Component({
  selector: 'hms-new-location',
  templateUrl: './new-location.component.html',
  styleUrls: ['./new-location.component.scss']
})
export class NewLocationComponent implements OnInit {
  locationForm: FormGroup = this.formBuilder.group({
    id: new FormControl('', [Validators.required]),
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(100)]),
    isActive: new FormControl(true),
    isClientLocation: new FormControl(true),
    address: new FormGroup({
      street1: new FormControl('', [
        Validators.required,
        Validators.maxLength(200)
      ]),
      street2: new FormControl('', [
        Validators.maxLength(200)
      ]),
      city: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)
      ]),
      region: new FormControl('', [
        Validators.required
      ]),
      postalCode: new FormControl('', [
        Validators.required
      ]),
      country: new FormControl('', [
        Validators.required
      ])
    }),
    accountingSettings: new FormArray([]),
    newSettingKey: new FormControl('', [Validators.maxLength(100)]),
    newSettingValue: new FormControl('', [Validators.maxLength(500)]),
  });

  selectedClient$: Observable<Client>;
  selectedClientDocId = '';
  errorMessage = '';
  updateComplete = true;

  // Indicators for hide/show
  enableAddress = false;

  phoneNumbers: PhoneNumber[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.LOCATION_ADMIN_ROLE)) {
      this.errorMessage = 'You do not have permission to create locations.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.locationForm.get('address').disable();
    this.selectedClient$ = this.clientSelectorService.getSelectedClient().pipe(
      tap(selectedClient => this.selectedClientDocId = selectedClient.docId)
    );
  }

  reset() {
    this.locationForm.reset({
      isActive: true,
      isClientLocation: true,
      id: '',
      newSettingKey: '',
      newSettingValue: ''
    });
    const settingsFormArray = this.locationForm.get('accountingSettings') as FormArray;
    settingsFormArray.clear();
    this.phoneNumbers = [];
    this.locationForm.markAsPristine();
  }

  submit() {
    this.updateComplete = false;

    const formValues = this.locationForm.getRawValue();
    const newLocation = new Location();
    newLocation.accountingSystemId = formValues.id;
    newLocation.name = formValues.name;
    newLocation.isActive = formValues.isActive;
    newLocation.isClientLocation = formValues.isClientLocation;
    if (this.enableAddress) {
      newLocation.address = formValues.address;
    }
    newLocation.phoneNumbers = this.phoneNumbers;
    if (formValues.newSettingKey) {
      this.addAccountingSettingForm();
    }
    const settingsFormArray = this.locationForm.get('accountingSettings') as FormArray;
    settingsFormArray.controls.forEach((accountingSettingForm: FormGroup) => {
      let settingValue = accountingSettingForm.get('settingValue').value;
      if (this.appearsBoolean(settingValue)) {
        settingValue = this.getValueAsBoolean(settingValue);
      }
      newLocation.accountingSystemSettings[accountingSettingForm.get('settingKey').value] = settingValue;
    });

    // insert new Location data into Firestore
    this.locationService.createLocation(this.selectedClientDocId, newLocation)
      .then(() => {
        this.updateComplete = true;
        console.log('Location successfully created');
        this.openSnackBar('Location successfully created', 'DISMISS', true);
        this.router.navigate(['../'], { relativeTo: this.activatedRoute, replaceUrl: true });
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`Location creation failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Location creation failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('maxlength')) {
      return 'Value cannot exceed ' + control.errors['maxlength'].requiredLength + ' characters';
    } else if (control.hasError('uniqueKey')) {
      return 'Setting already exists';
    }
    return 'Unknown error';
  }

  onPhoneNumberCreated(phoneNumber: PhoneNumber) {
    this.phoneNumbers.push(phoneNumber);
    this.locationForm.markAsDirty();
  }

  onPhoneNumberRemoved(index?: number) {
    this.phoneNumbers.splice(index, 1);
    this.locationForm.markAsDirty();
  }

  toggleAddressForm() {
    this.enableAddress = !this.enableAddress;

    if (this.enableAddress) {
      this.locationForm.get('address').enable();
    } else {
      this.locationForm.get('address').disable();
    }
  }

  setAccountingSystemIdRequired() {
    if (this.locationForm.get('isClientLocation').value) {
      this.locationForm.get('id').setValidators([Validators.required]);
    } else {
      this.locationForm.get('id').clearValidators();
    }
  }

  appearsBoolean(value: any) {
    return (typeof (value) === 'string' && ['true', 'false'].includes(value.toLowerCase())) || typeof (value) === 'boolean';
  }

  addAccountingSettingForm() {
    const settingsFormArray = this.locationForm.get('accountingSettings') as FormArray;
    const keyField = this.locationForm.get('newSettingKey');
    const valueField = this.locationForm.get('newSettingValue');
    settingsFormArray.push(this.getAccountingSettingForm(keyField.value, valueField.value));
    this.locationForm.get('accountingSettings').markAsDirty();
    keyField.setValue('');
    keyField.setValidators([Validators.maxLength(100), UniqueKeyValidator.uniqueKey(this.existingAccountingSettingsKeys)]);
    valueField.setValue('');
  }

  checkForBoolean(settingKey, settingValue) {
    if (this.appearsBoolean(settingValue)) {
      const updatedForm = this.existingAccountingSettings.find(
        (accountingSetting: FormGroup) => accountingSetting.get('settingKey').value === settingKey);
      updatedForm.get('settingValue').setValue(this.getValueAsBoolean(settingValue));
    }
  }

  listenForEnter(event: KeyboardEvent, currentContents: string, index: number) {
    if (event.key === 'Enter') {
      const settingsFormArray = this.locationForm.get('accountingSettings') as FormArray;
      const settingValueField = settingsFormArray.controls[index].get('settingValue');
      if (settingValueField.value !== currentContents) {
        settingValueField.setValue(currentContents);
        settingValueField.markAsDirty();
        settingValueField.updateValueAndValidity();
        if (this.locationForm.valid) {
          this.submit();
        }
      }
    }
  }

  removeAccountingSettingForm(index: number) {
    const settingsFormArray = this.locationForm.get('accountingSettings') as FormArray;
    settingsFormArray.removeAt(index);
    this.locationForm.get('accountingSettings').markAsDirty();
    this.locationForm.get('newSettingKey').setValidators([
      Validators.maxLength(100), UniqueKeyValidator.uniqueKey(this.existingAccountingSettingsKeys)]);
    this.locationForm.get('newSettingKey').updateValueAndValidity();
  }

  get addAccountingFormDisabled() {
    const keyField = this.locationForm.get('newSettingKey');
    const valueField = this.locationForm.get('newSettingValue');
    return !keyField.value || !keyField.valid || !valueField.value || !valueField.valid;
  }

  get existingAccountingSettings() {
    const settingsFormArray = this.locationForm.get('accountingSettings') as FormArray;
    return settingsFormArray.controls as FormGroup[];
  }

  get existingAccountingSettingsKeys() {
    return this.existingAccountingSettings.map(accountingSettingsForm => accountingSettingsForm.get('settingKey').value);
  }

  // return XOR comparison on values in new accounting setting fields
  get partialNewSettingEntered() {
    const settingKey = this.locationForm.get('newSettingKey').value;
    const settingValue = this.locationForm.get('newSettingValue').value;
    return (settingKey === '' && settingValue !== '') || (settingValue === '' && settingKey !== '');
  }

  private getAccountingSettingForm(key: string, value: any) {
    const accountingSettingForm = this.formBuilder.group({ settingKey: [key, [Validators.required, Validators.maxLength(100)]] });
    if (this.appearsBoolean(value)) {
      accountingSettingForm.addControl('settingValue', new FormControl(this.getValueAsBoolean(value)));
    } else {
      accountingSettingForm.addControl('settingValue',
        new FormControl(value, { updateOn: 'blur', validators: [Validators.maxLength(500)] }));
    }
    return accountingSettingForm;
  }

  // Force case-insensitive conversion of 'true' and 'false' to corresponding boolean values or simply return boolean
  private getValueAsBoolean(value: string | boolean): boolean {
    if (typeof (value) === 'boolean') {
      return value;
    }
    return typeof (value) === 'string' && value.toLowerCase() === 'true';
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

}
