import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';

import { ActivityLedgerComponent } from './live-ledger/activity-ledger/activity-ledger.component';
import { AdHocOrderDetailComponent } from './ad-hoc-orders/ad-hoc-order-detail/ad-hoc-order-detail.component';
import { AdHocOrderSearchComponent } from './ad-hoc-orders/ad-hoc-order-search/ad-hoc-order-search.component';
import { AlertDetailComponent } from './alerts/alert-detail/alert-detail.component';
import { AlertSearchComponent } from './alerts/alert-search/alert-search.component';
import { BasisAdminComponent } from './basis/basis-admin/basis-admin.component';
import { BasisLedgerComponent } from './live-ledger/basis-ledger/basis-ledger.component';
import { BatchRollComponent } from './batch-roll/batch-roll.component';
import { BidsComponent } from './bids/bids.component';
import { CallbackComponent } from './callback/callback.component';
import { ClientSettingsComponent } from './client-settings/client-settings.component';
import { ClosestToMarketComponent } from './reports/closest-to-market/closest-to-market.component';
import { CommodityProfileDetailComponent } from './commodity-profiles/commodity-profile-detail/commodity-profile-detail.component';
import { CommodityProfilesComponent } from './commodity-profiles/commodity-profiles/commodity-profiles.component';
import { ContractDetailComponent } from './contracts/contract-detail/contract-detail.component';
import { ContractSearchComponent } from './contracts/contract-search/contract-search.component';
import { ExpiringContractsComponent } from './reports/expiring-contracts/expiring-contracts.component';
import { FutureDeliveryComponent } from './reports/future-delivery/future-delivery.component';
import { HedgeDetailComponent } from './hedges/hedge-detail/hedge-detail.component';
import { HedgeSearchComponent } from './hedges/hedge-search/hedge-search.component';
import { HomeComponent } from './home/home.component';
import { LedgerAdjustmentDetailComponent } from './ledger-adjustments/ledger-adjustment-detail/ledger-adjustment-detail.component';
import { LedgerAdjustmentSearchComponent } from './ledger-adjustments/ledger-adjustment-search/ledger-adjustment-search.component';
import { LocationsComponent } from './locations/locations/locations.component';
import { LocationDetailComponent } from './locations/location-detail/location-detail.component';
import { LogoutComponent } from './logout/logout.component';
import { MonthlyRollReportComponent } from './reports/monthly-roll-report/monthly-roll-report.component';
import { NewAdHocOrderComponent } from './ad-hoc-orders/new-ad-hoc-order/new-ad-hoc-order.component';
import { NewCommodityProfileComponent } from './commodity-profiles/new-commodity-profile/new-commodity-profile.component';
import { NewContractComponent } from './contracts/new-contract/new-contract.component';
import { NewHedgeComponent } from './hedges/new-hedge/new-hedge.component';
import { NewLedgerAdjustmentComponent } from './ledger-adjustments/new-ledger-adjustment/new-ledger-adjustment.component';
import { NewLocationComponent } from './locations/new-location/new-location.component';
import { NewPatronComponent } from './patrons/new-patron/new-patron.component';
import { OnDemandQuotesComponent } from './reports/on-demand-quotes/on-demand-quotes.component';
import { OrderDetailComponent } from './orders/order-detail/order-detail.component';
import { OrderFillComponent } from './reports/order-fill/order-fill.component';
import { OrderSearchComponent } from './orders/order-search/order-search.component';
import { PatronDetailComponent } from './patrons/patron-detail/patron-detail.component';
import { PatronsComponent } from './patrons/patrons/patrons.component';
import { PositionsLedgerComponent } from './live-ledger/positions-ledger/positions-ledger.component';
import { PriceAdjustmentsComponent } from './price-adjustments/price-adjustments/price-adjustments.component';
import { PurchasesAndSalesComponent } from './reports/purchases-and-sales/purchases-and-sales.component';
import { ReconciliationComponent } from './reports/reconciliation/reconciliation.component';
import { RegistrationComponent } from './registration/registration.component';
import { ReportsComponent } from './reports/reports/reports.component';
import { TargetSearchComponent } from './targets/target-search/target-search.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { UsersComponent } from './users/users/users.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'accounts/:accountDocId/orders/:orderDocId',
    component: OrderDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'adhocorders',
    component: AdHocOrderSearchComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'adhocorders/new',
    pathMatch: 'full',
    component: NewAdHocOrderComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'adhocorders/:docId',
    component: AdHocOrderDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'alerts',
    component: AlertSearchComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'alerts/:docId',
    component: AlertDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'basisadmin',
    component: BasisAdminComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'bids',
    component: BidsComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'contracts',
    component: ContractSearchComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'contracts/new',
    pathMatch: 'full',
    component: NewContractComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'contracts/:docId',
    component: ContractDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'hedges',
    component: HedgeSearchComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'hedges/new',
    pathMatch: 'full',
    component: NewHedgeComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'hedges/:docId',
    component: HedgeDetailComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'liveledgers',
    children: [
      {
        path: 'activityledger',
        component: ActivityLedgerComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'basisledger',
        component: BasisLedgerComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'positionsledger',
        component: PositionsLedgerComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'ledgeradjustments',
        component: LedgerAdjustmentSearchComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'ledgeradjustments/new',
        pathMatch: 'full',
        component: NewLedgerAdjustmentComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'ledgeradjustments/:docId',
        component: LedgerAdjustmentDetailComponent,
        canActivate: [
          AuthGuard
        ]
      }
    ]
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [
      AuthGuard
    ]
  },

  {
    path: 'orders',
    component: OrderSearchComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'register',
    component: RegistrationComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'reports',
    children: [
      {
        path: '',
        component: ReportsComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'expiringcontracts',
        component: ExpiringContractsComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'futuredelivery',
        component: FutureDeliveryComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'monthlyroll',
        component: MonthlyRollReportComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'ondemandquotes',
        component: OnDemandQuotesComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'closesttomarket',
        component: ClosestToMarketComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'orderfill',
        component: OrderFillComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'purchasesandsales',
        component: PurchasesAndSalesComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'reconciliation',
        component: ReconciliationComponent,
        canActivate: [
          AuthGuard
        ]
      }
    ]
  },
  {
    path: 'settings',
    children: [
      {
        path: 'batchroll',
        component: BatchRollComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'client',
        component: ClientSettingsComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'commodityprofiles',
        component: CommodityProfilesComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'commodityprofiles/new',
        pathMatch: 'full',
        component: NewCommodityProfileComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'commodityprofiles/:docId',
        component: CommodityProfileDetailComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'locations',
        component: LocationsComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'locations/new',
        pathMatch: 'full',
        component: NewLocationComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'locations/:docId',
        component: LocationDetailComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'patrons',
        component: PatronsComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'patrons/new',
        pathMatch: 'full',
        component: NewPatronComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'patrons/:docId',
        component: PatronDetailComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'priceadjustments',
        component: PriceAdjustmentsComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [
          AuthGuard
        ]
      },
      {
        path: 'users/:docId',
        component: UserDetailComponent,
        canActivate: [
          AuthGuard
        ]
      }
    ]
  },
  {
    path: 'targets',
    component: TargetSearchComponent,
    canActivate: [
      AuthGuard
    ]
  },
  {
    path: 'users/:docId',
    redirectTo: 'settings/users/:docId',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  providers: [AuthGuard],
  exports: [
    RouterModule
  ]
})
export class HmsRoutingModule {}
