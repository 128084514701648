import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

import * as moment from 'moment';

import { ContractMonth } from '@advance-trading/ops-data-lib';

const MONTHS = moment.monthsShort();
const ADD_BASIS_YEAR_PERIOD = 5;

const MONTH_CODE_DICT = {
  Jan: ContractMonth.F,
  Feb: ContractMonth.G,
  Mar: ContractMonth.H,
  Apr: ContractMonth.J,
  May: ContractMonth.K,
  Jun: ContractMonth.M,
  Jul: ContractMonth.N,
  Aug: ContractMonth.Q,
  Sep: ContractMonth.U,
  Oct: ContractMonth.V,
  Nov: ContractMonth.X,
  Dec: ContractMonth.Z
};

@Component({
  selector: 'hms-bottom-sheet-delivery-period',
  templateUrl: './bottom-sheet-delivery-period.component.html',
  styleUrls: ['./bottom-sheet-delivery-period.component.scss']
})

export class BottomSheetDeliveryPeriodComponent implements OnInit {
  removable = true;
  chipListPeriods: string[];
  chipListChanged = false;

  periods: string[];
  periodSelected: string;
  oldPeriodDict: {[key: string]: boolean} = {};

  constructor(private bottomSheetRef: MatBottomSheetRef<BottomSheetDeliveryPeriodComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public periodExistedList: string[]) {}

  ngOnInit() {
    // Get current 5-year period
    const currentMonthIdx = MONTHS.findIndex(m => m === moment().format('MMM'));
    this.periods = [];
    for (let i = 0; i < ADD_BASIS_YEAR_PERIOD; i++) {
      for (let j = 0; j < MONTHS.length; j++) {
        const currentYearStr = moment().add(i, 'years').format('YY');
        const currentPeriod = currentYearStr + MONTH_CODE_DICT[MONTHS[j]] ;
        if ( (j >= currentMonthIdx || i !== 0) && this.periodExistedList.findIndex(d => d === currentPeriod) === -1) {
          this.periods.push(currentPeriod);
        }
      }
    }
    this.periodSelected = this.periods[0];

    this.chipListPeriods = [...this.periodExistedList];

    this.chipListPeriods.forEach(period => {
      this.oldPeriodDict[period] = true;
    });
  }

  addNewPeriod() {
    // remove the current period from available period
    const index = this.periods.indexOf(this.periodSelected);
    this.periods.splice(index, 1);

    // add period selected to the current period displayed in spreadsheet
    this.chipListPeriods.push(this.periodSelected);
    this.chipListPeriods.sort();

    // reset the periodSelected to be the first period
    this.periodSelected = this.periods[0];

    this.chipListChanged = true;
  }

  onApplyChanges() {
    this.bottomSheetRef.dismiss({periods: this.chipListPeriods});
  }

  remove(period: string): void {
    const index = this.chipListPeriods.indexOf(period);
    if (index >= 0) {
      // add the removed period to available period to be added
      this.periods.push(this.chipListPeriods[index]);
      this.periods.sort();
      this.periodSelected = this.periods[0];

      // remove period from the chip display
      this.chipListPeriods.splice(index, 1);

      this.chipListChanged = true;
    }
  }

}
