import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { Client, MarketDataUsage } from '@advance-trading/ops-data-lib';

@Injectable({
  providedIn: 'root'
})
export class MarketDataUsageService {

  constructor(
    private db: AngularFirestore
  ) { }

  /**
   * Return market data usage for the specified Client by docId
   *
   * @param clientDocId The docId of the Client containing the MarketDataUsages
   * @param marketDataUsageDocId The docId of MarketDataUsage to be returned
   */
  getMarketDataUsageByDocId(clientDocId: string, marketDataUsageDocId: string): Observable<MarketDataUsage> {
    return this.db.doc<MarketDataUsage>(`${Client.getDataPath(clientDocId)}/${MarketDataUsage.getDataPath(marketDataUsageDocId)}`)
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

}
