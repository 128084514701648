<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <hms-error-message [title]="'Error Retrieving Client Settings'" [message]="errorMessage"></hms-error-message>

    <div *ngIf="!errorMessage">
      <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
        <h2 data-cy="page-title-client-settings" class="card-title">Client Settings</h2>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
          <button type="button" id="edit-client-settings" class="action-button" aria-label="Edit Client Settings" matTooltip="Edit Client Settings" *ngIf="!editMode && updateComplete" (click)="setEditMode(true)" mat-mini-fab color="accent">
            <mat-icon>edit</mat-icon>
          </button>
          <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
          <button type="submit" id="update-client-settings" class="action-button" aria-label="Update Client Settings" matTooltip="Update Client Settings" *ngIf="editMode && updateComplete" [disabled]="!clientSettingsForm.valid || clientSettingsForm.pristine || uncommittedAccountingSettingsChangesExist" (click)="updateClientSettings()" mat-mini-fab color="accent">
            <mat-icon>done</mat-icon>
          </button>
          <button type="button" id="cancel-edit-client-settings" class="action-button" aria-label="Discard Changes" matTooltip="Discard Changes" *ngIf="editMode && updateComplete" (click)="setEditMode(false)" mat-mini-fab color="">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </div>

      <mat-tab-group #tabGroup (selectedTabChange)="onTabChange($event)">

        <mat-tab label="Main" fxFlex="100">
          <ng-template mat-tab-label>
            <span class="mat-tab-label-text">Main</span>
            <mat-icon fontSet="material-icons-outlined" class="error-icon" mat-list-icon [ngClass]="{'show' : errorNotification['mainError'], 'hide' : !errorNotification['mainError']}">error</mat-icon>
          </ng-template>
          <div class="tab-body">
            <hms-main-settings *ngIf="filteredTimezones$ && filteredCommodities$ && clientSettings$ && filteredGroups$ | async"
              [editMode]="editMode"
              [clientSettings]="clientSettings"
              [clientSettings$]="clientSettings$"
              [selectedClientDocId]="selectedClientDocId"
              [commodities]="commodities"
              [commodityMap]="commodityMap"
              [managementGroup]="managementGroup"
              [managementGroups]="managementGroups"
              [filteredGroups$]="filteredGroups$"
              [filteredTimezones$]="filteredTimezones$"
              [filteredCommodities$]="filteredCommodities$"
              [clientSettingsForm]="clientSettingsForm"
              [activeTabName]="activeTabName"
              (mainError)="onFormErrorUpdate('mainError', $event)">
            </hms-main-settings>
          </div>
        </mat-tab>

        <mat-tab label="Accounting" fxFlex="100">
          <ng-template mat-tab-label>
            <span class="mat-tab-label-text">Accounting</span>
            <mat-icon fontSet="material-icons-outlined" class="error-icon" mat-list-icon [ngClass]="{'show' : errorNotification['accountingError'], 'hide' : !errorNotification['accountingError']}">error</mat-icon>
          </ng-template>
          <div class="tab-body">
            <form *ngIf="!errorMessage && clientSettings$ | async" [formGroup]="clientSettingsForm">
              <div>
                <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="25" label="Accounting System" [data]="clientSettings.accountingSystem | replace | titlecase"></ati-labeled-data>
                <mat-form-field *ngIf="editMode" fxFlex.gt-xs="25">
                  <mat-select placeholder="Accounting System" formControlName="accountingSystem">
                    <mat-option *ngFor="let system of accountingSystems" [value]="system">{{ system | replace | titlecase }}</mat-option>
                  </mat-select>
                  <button type="button" id="clear-client-settings-accounting-system" mat-button *ngIf="clientSettingsForm.get('accountingSystem').value" matSuffix mat-icon-button aria-label="Clear Accounting System" matTooltip="Clear Accounting System" (click)="clearField('accountingSystem')">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div>
                <h4 fxFlex="100">Accounting System Settings</h4>
              </div>
              <div>
                <div class="hms-client-settings-section">
                  <hms-accounting-setting *ngFor="let accountingSettingGroup of clientSettingsForm.get('accountingSettings').controls | keyvalue ; trackBy:trackBySettingKey"
                    (accountingSettingUpdated)="onAccountingSettingUpdated($event)"
                    (accountingSettingRemoved)="onAccountingSettingRemoved($event)"
                    [accountingSetting]="accountingSettingGroup.value"
                    (accountingError)="onFormErrorUpdate('accountingError', $event)"
                    [existingAccountingSettingKeys]="existingAccountingSettingKeys"
                    [editMode]="editMode"
                    [activeTabName]="activeTabName">
                  </hms-accounting-setting>
                  <h5 *ngIf="editMode">Add Setting</h5>
                  <hms-accounting-setting #newAccountingSetting *ngIf="editMode"
                    (accountingSettingUpdated)="onAccountingSettingCreated($event)"
                    (accountingSettingRemoved)="onAccountingSettingRemoved($event)"
                    [existingAccountingSettingKeys]="existingAccountingSettingKeys"
                    [editMode]="editMode">
                  </hms-accounting-setting>
                </div>
              </div>
            </form>
          </div>
        </mat-tab>

        <mat-tab label="Billing" fxFlex="100">
          <ng-template mat-tab-label>
            <span class="mat-tab-label-text">Billing</span>
            <mat-icon fontSet="material-icons-outlined" class="error-icon" mat-list-icon [ngClass]="{'show' : errorNotification['billingError'], 'hide' : !errorNotification['billingError']}">error</mat-icon>
          </ng-template>
          <div *ngIf="!errorMessage && clientSettings$ | async" class="tab-body">
            <hms-billing-settings
              (billingError)="onFormErrorUpdate('billingError', $event)"
              [clientSettings]="clientSettings"
              [editMode]="editMode"
              [clientSettingsForm]="clientSettingsForm"
              [activeTabName]="activeTabName">
            </hms-billing-settings>
          </div>
        </mat-tab>

      </mat-tab-group>
    </div>
  </mat-card>
</div>
