<div fxLayout="row" fxLayoutAlign="space-around top" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">
    <!-- Title -->
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 data-cy="page-title-basis" class="card-title">Basis</h2>
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <mat-spinner *ngIf="isLoading || !updateSuccess" diameter="40" color="accent"></mat-spinner>

        <button id="edit-basis" aria-label="Edit Basis"  *ngIf="!editMode && !isLoading && selectedProfile" mat-button mat-mini-fab color="accent"
                matTooltip="Edit Basis" (click)="setEditMode(true)">
          <mat-icon>edit</mat-icon>
        </button>

        <button id="update-basis" aria-label="Update Basis" *ngIf="editMode && updateSuccess" mat-button mat-mini-fab color="accent" matTooltip="Save" (click)="handleSave()"
                [disabled]="basisFormMap[selectedProfile.docId].invalid || basisFormMap[selectedProfile.docId].pristine || !updateSuccess">
          <mat-icon>done</mat-icon>
        </button>
        <button id="clear-basis" aria-label="Clear Basis" *ngIf="editMode && updateSuccess" mat-button mat-mini-fab color=""
                matTooltip="Discard Changes" (click)="handleDiscard()">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>

    <!-- Error message if any -->
    <hms-error-message [title]="'Error Retrieving Basis Details'" [message]="errorMessage"></hms-error-message>

    <!-- Basis page content -->
    <div *ngIf="!errorMessage">
      <div class="mat-small bottom-gap">Note: Client Locations designated with an asterisk.</div>

      <!-- Checkbox Selector Buttons -->
      <div fxLayout="row" fxLayoutGap="5px" class="basis-admin-btn-container">
        <button *ngIf="!editMode" aria-label="Export Excel" id="export-basis-admin-as-xlsx" mat-raised-button color="primary" class="export-btn" type="button" (click)="exportService.exportXlsx(getExportableItems(), 'Basis Admin')">Export</button>
        <input #spreadsheetFile id='spreadsheet-file' type='file' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" (change)="convertBasisFileToSpreadsheet($event.target.files[0])" (click)="spreadsheetFile.value = ''" hidden/>
        <button type="button" *ngIf="editMode" id="import-basis" aria-label="Import Basis" mat-raised-button color="primary" (click)="importBasisFile()">Import</button>
        <button type="button" *ngIf="editMode" id="open-mass-update" aria-label="Open Mass Update" mat-stroked-button color="accent" [disabled]="this.rowSelection.selected.length === 0 || this.colSelection.selected.length === 0"
                (click)="openMassUpdateSheet()" class="mass-update-btn">Mass Update</button>
        <button type="button" *ngIf="editMode" id="open-manage-period" aria-label="Open Manage Period" mat-stroked-button color="accent" (click)="openManagePeriodSheet()" class="manage-period-btn">Manage Periods</button>
        <button type="button" *ngIf="editMode" id="clear-basis-selections" aria-label="Clear Basis Selections" mat-stroked-button color="accent" (click)="clearSelection()" class="clear-selection-btn">Clear Selections</button>
      </div>

      <ng-container *ngIf="commodityProfileBases$ | async as profiles">
        <mat-tab-group color="accent" (selectedTabChange)="onTabSelect($event)" #tabs>
          <!-- List of tabs of different Commodity Profile -->
          <mat-tab *ngFor="let profile of profiles" [label]="profile.name">
            <ng-container *ngIf="profile.bases$ | async as bases">
              <form class="container" [formGroup]="basisFormMap[profile.docId]" (ngSubmit)="handleSave()" #spreadsheetForm="ngForm">
                <div class="spreadsheet-container">
                  <table mat-table [dataSource]="dataSourceMap[profile.docId]" class="mat-elevation-z0">
                    <ng-container *ngFor="let col of columnsToDisplayMap[profile.docId]" [matColumnDef]="col" [sticky]="col === 'location'">
                      <th mat-header-cell *matHeaderCellDef>
                        <ng-container *ngIf="col === 'location'">{{ col | titlecase }} </ng-container>
                        <!-- Column checkboxes and Clear button-->
                        <ng-container *ngIf="col !== 'location'">
                          <mat-checkbox (change)="colSelection.toggle(col)" [checked]="colSelection.isSelected(col)" [disabled]="!editMode">
                            {{ col | codeToMonth | titlecase }}
                          </mat-checkbox>
                        </ng-container>
                      </th>
                      <td mat-cell *matCellDef="let element; let i = index">
                        <ng-container>
                          <div *ngIf="col !== 'location'">
                            <mat-form-field appearance="outline" class="basis-input">
                              <hms-basis-input [clientSettings]="clientSettings" [deliveryPeriod]="col" [formControlName]="getFormControlName(i, col)"></hms-basis-input>
                            </mat-form-field>
                          </div>
                          <!-- Location names or Row checkboxes-->
                          <div *ngIf="col === 'location'" class="location-name">
                            <ng-container *ngIf="col === 'location'">
                              <mat-checkbox [disabled]="!editMode" (change)="rowSelection.toggle(getConvertedIndex(i))" [checked]="rowSelection.isSelected(getConvertedIndex(i))">{{ activeLocationMap[element[col]].isClientLocation ? activeLocationMap[element[col]].name + '*' : activeLocationMap[element[col]].name }}</mat-checkbox>
                            </ng-container>
                          </div>
                        </ng-container>
                      </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="columnsToDisplayMap[profile.docId]; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsToDisplayMap[profile.docId];"></tr>
                  </table>
                </div>
                <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 30]" (page)="handlePageChange()"></mat-paginator>
                <div *ngIf="basisFormMap[profile.docId].invalid" class="alert-danger alert">
                  <mat-error class="mat-small">
                    {{ getErrorMessage() }}
                  </mat-error>
                </div>
              </form>
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </ng-container>
    </div>
  </mat-card>
</div>
