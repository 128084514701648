<hms-error-message [title]="'Error Retrieving Quotes'" [message]="errorMessage"></hms-error-message>

<div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
  <h2 data-cy="page-title-quotes" class="card-title">Quotes</h2>
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
    <mat-spinner *ngIf="isLoading" diameter="40" color="accent"></mat-spinner>
  </div>
</div>

<div *ngIf="!errorMessage && hmsClientSettings$ | async as hmsClientSettings">
  <mat-tab-group color="accent">
    <mat-tab *ngFor="let commodityId of hmsClientSettings.commodities" [label]="commodityMap.commodities[commodityId]['name']">
      <ng-template matTabContent>
        <hms-single-commodity-quotes [commodityId]="commodityId" [mode]="QUOTES_MODE"
                                     (singleQuoteError)="onSingleQuotesError($event)"
                                     (loaded)="onSingleQuotesLoaded($event)"></hms-single-commodity-quotes>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
  <div class="mat-small">
    <h4>Disclaimer:</h4>
    <ul style="padding-left: 15px;">
      <li *ngIf="userDataFrequency !== 'REALTIME'">Market data is delayed by at least 10 minutes</li>
      <li *ngIf="userDataFrequency === 'REALTIME'">Market data is delayed up to 10 minutes</li>
      <li *ngIf="userDataFrequency !== 'NONE'">Market data last updated {{ timestamp }}</li>
      <li *ngIf="userDataFrequency === 'NONE'">Market data last updated {{ timestamp }} and is not being refreshed per your user settings</li>
      <li>Trade times displayed in CT timezone</li>
    </ul>
  </div>
</div>

