<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <hms-error-message [title]="'Error Retrieving Patron Details'" [message]="errorMessage"></hms-error-message>

    <!--Title and Buttons-->
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">Patron: {{ patron ? patron.name : '' }}</h2>
      <mat-spinner *ngIf="isLoading" class="loading-spinner" diameter="40" color="accent"></mat-spinner>
      <div *ngIf="!isLoading" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <button type="button" id="edit-patron" class="action-button" aria-label="Edit Patron" matTooltip="Edit Patron" *ngIf="!editMode && updateComplete && isPatronAdmin" (click)="setEditMode(true)" mat-mini-fab color="accent">
          <mat-icon>edit</mat-icon>
        </button>
        <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
        <button type="submit" id="update-patron" class="action-button" aria-label="Update Patron" matTooltip="Update Patron" *ngIf="editMode && updateComplete" [disabled]="!patronForm.valid || patronForm.pristine" (click)="saveForm()" mat-mini-fab color="accent">
          <mat-icon>done</mat-icon>
        </button>
        <button type="button" id="cancel-edit-patron" class="action-button" aria-label="Discard Changes" matTooltip="Discard Changes" *ngIf="editMode && updateComplete" (click)="setEditMode(false)" mat-mini-fab color="">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>

    <form *ngIf="!errorMessage && patron$ | async as patron" [formGroup]="patronForm" (ngSubmit)="saveForm()" fxLayout="column" fxLayoutGap="15px">

      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
        <ati-labeled-data fxFlex="45" label="ID" [data]="patron.accountingSystemId"></ati-labeled-data>

        <ati-labeled-data fxFlex="45" *ngIf="!editMode" label="Name" [data]="patron.name"></ati-labeled-data>
        <mat-form-field fxFlex="45" *ngIf="editMode">
          <input required matInput placeholder="Name" formControlName="name" trim="blur">
          <mat-error *ngIf="patronForm.get('name').invalid">{{getErrorMessage(patronForm.get('name'))}}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="space-between end" fxFlex="100" fxLayoutGap="5">
        <ati-labeled-data *ngIf="!editMode" fxFlex.xs="100" fxFlex.gt-xs="45" label="Bad Credit" [data]="patron.hasBadCredit | yesNo"></ati-labeled-data>
        <div *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45" class="checkbox-group">
          <mat-checkbox formControlName="hasBadCredit">Bad Credit</mat-checkbox>
        </div>
        <ati-labeled-data  *ngIf="!editMode" fxFlex.xs="100" fxFlex.gt-xs="45" label="Status" [data]="patron.isActive ? 'Active' : 'Inactive'"></ati-labeled-data>
        <div *ngIf="editMode" fxFlex.xs="100" fxFlex.gt-xs="45" class="checkbox-group">
          <label class="check-radio-label mat-small mat-hint">Status</label>
          <mat-checkbox formControlName="isActive">Active</mat-checkbox>
        </div>
      </div>

      <div fxFlex="100" formArrayName="emails" *ngFor="let emailControl of existingEmailControls; let idx = index;" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
        <ati-labeled-data *ngIf="!editMode" [label]="getEmailLabel(idx)" [data]="emailControl.value" fxFlex="45"></ati-labeled-data>
        <mat-form-field *ngIf="editMode" fxFlex="45">
          <input matInput [placeholder]="getEmailLabel(idx)" [formControl]="emailControl" trim="blur" (blur)="removeInvalidEmailNotificationsAndResetValidators(idx);">
          <mat-error *ngIf="emailControl.invalid">{{ getErrorMessage(emailControl) }}</mat-error>
          <button matSuffix type="button" id="remove-new-patron-email" mat-icon-button aria-label="Remove Email" matTooltip="Remove Email" (click)="removeEmailForm(idx)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div *ngIf="editMode" fxFlex="100" fxLayout="row wrap">
        <mat-form-field fxFlex="45">
          <input matInput placeholder="New Email" formControlName="newEmail" trim="blur">
          <mat-error *ngIf="patronForm.get('newEmail').invalid">{{ getErrorMessage(patronForm.get('newEmail')) }}</mat-error>
          <button type="button" matSuffix id="add-email" [disabled]="!patronForm.get('newEmail').value || !patronForm.get('newEmail').valid" mat-icon-button aria-label="Add Email" matTooltip="Add Email" (click)="addEmailForm()">
            <mat-icon>add_circle</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div *ngIf="patron?.address || editMode" fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
        <h4>Address</h4>
        <button *ngIf="!enableAddress && !patron?.address" [disabled]="patronForm.disabled" id="add-patron-address" class="action-button" type="button" aria-label="Add Address" matTooltip="Add Address" color="accent" mat-icon-button (click)="showAddress()">
          <mat-icon>add_circle</mat-icon>
        </button>
        <button *ngIf="enableAddress && !patron?.address" [disabled]="patronForm.disabled" id="cancel-patron-address" class="action-button" type="button" aria-label="Cancel Address" matTooltip="Cancel Address" color="primary" mat-icon-button (click)="hideAddress()">
          <mat-icon>cancel</mat-icon>
        </button>
        <button *ngIf="editMode && patron?.address" [disabled]="patronForm.disabled" id="delete-patron-address" class="action-button" type="button" aria-label="Delete Address" matTooltip="Delete Address" color="warn" mat-icon-button (click)="clearAddress()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div *ngIf="patron?.address || editMode" fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
        <ati-address *ngIf="enableAddress" [editMode]="editMode" [addressForm]="patronForm.get('address')"></ati-address>
      </div>

      <mat-expansion-panel *ngIf="phoneNumbers.length || editMode" class="mat-elevation-z0" expanded="phoneNumbers.length">
        <mat-expansion-panel-header>
          <mat-panel-title>Phone Numbers</mat-panel-title>
        </mat-expansion-panel-header>
        <ati-phone-number *ngFor="let phoneNumber of phoneNumbers; let index = index" [editMode]="editMode" [phoneNumber]="phoneNumber" (numberUpdated)="onPhoneNumberUpdated(index, $event)" (numberRemoved)="onPhoneNumberRemoved(index)"></ati-phone-number>
        <ati-phone-number *ngIf="editMode" [editMode]="editMode" (numberUpdated)="onPhoneNumberCreated($event)"></ati-phone-number>
      </mat-expansion-panel>

      <div *ngIf="sendPatronNotifications" fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100">
        <h4>Notifications</h4>
        <div class="notification-body" *ngIf="!editMode" fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100">
          <div class="notification-item" fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100">
            <div fxFlex="100" class="check-radio-label mat-small mat-hint">SMS</div>
            <div *ngFor="let smsNotification of smsNotifications" fxFlex="100">
              {{ smsNotification | phoneFormat }}
            </div>
            <div *ngIf="!smsNotifications.length">-</div>
          </div>
          <div class="notification-item" fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100">
            <div fxFlex="100" class="check-radio-label mat-small mat-hint">Email</div>
            <div *ngFor="let emailNotification of emailNotifications" fxFlex="100">
              {{ emailNotification }}
            </div>
            <div *ngIf="!emailNotifications.length">-</div>
          </div>
        </div>
        <div class="notification-body" *ngIf="editMode" fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100">
          <div *ngFor="let mobilePhone of mobilePhones" fxFlex="100" class="notification-item">
            <mat-checkbox [checked]="smsNotifications.includes(mobilePhone)" (change)="onSmsNotificationChange($event)" [value]="mobilePhone">SMS - {{ mobilePhone | phoneFormat }}</mat-checkbox>
          </div>
          <div *ngFor="let email of allValidEmailsObservable$ | async" fxFlex="100" class="notification-item">
            <mat-checkbox [checked]="emailNotifications.includes(email)" (change)="onEmailNotificationChange($event)" [value]="email">Email - {{ email }}</mat-checkbox>
          </div>
        </div>
      </div>

    </form>

  </mat-card>
</div>
