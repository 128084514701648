import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import * as moment from 'moment';

const MIN_DATE = moment('1970-01-01').toISOString();
const FULLDATE_FORMAT = 'YYYY-MM-DD';

export class StartDateErrorMatcher implements ErrorStateMatcher {

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control.touched && control.invalid) || (form.enabled && form.hasError('startDateGap'));
  }

}

export class ProductionYearFuturesMonthErrorMatcher implements ErrorStateMatcher {

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if (control.value) {
      return control.invalid;
    }
    return control.touched && control.invalid;
  }

}

export class ProductionYearValidators {

  static startDateMin(min: string) {
    return (formGroup: FormGroup): {[key: string]: boolean} | null => {
      const startDate = formGroup.get('startDate').value;
      const errors = {};

      if (startDate && min !== MIN_DATE && moment(startDate).format(FULLDATE_FORMAT) !== moment(min).format(FULLDATE_FORMAT)) {
        errors['startDateGap'] = true;
      }

      return Object.keys(errors).length === 0 ? null : errors;
    };
  }

}
