<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

      <hms-error-message [title]="'Error Performing Ledger Adjustments Search'" [message]="errorMessage"></hms-error-message>

      <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
        <h2 data-cy="page-title-ledger-adjustments" class="card-title">Ledger Adjustments</h2>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
          <mat-spinner *ngIf="isLoading" diameter="40" color="accent"></mat-spinner>

          <button type="button" *ngIf="!isLoading && isLedgerAdjustmentAdmin" id="add-new-ledger-adjustment" class="action-button" aria-label="Add New Ledger Adjustment" (click)="addAdjustment()" mat-mini-fab color="accent" matTooltip="Add New Ledger Adjustment">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>

      <div *ngIf="!errorMessage">
        <form *ngIf="!errorMessage" [formGroup]="adjustmentSearchForm" (ngSubmit)="searchAdjustments()" fxLayout="column">
          <div fxLayout.xs="column" *ngIf="clientSettings$ | async">
            <mat-form-field fxFlex.gt-xs="250px">
              <input matInput placeholder="Commodity Profile" formControlName="commodityProfile" [matAutocomplete]="autoCommodityProfile">
              <mat-error *ngIf="adjustmentSearchForm.get('commodityProfile').invalid">You must select a valid commodity profile</mat-error>
              <button type="button" id="clear-adjustment-search-commodity-profile" mat-button *ngIf="adjustmentSearchForm.get('commodityProfile').value" matSuffix mat-icon-button aria-label="Clear Commodity Profile" matTooltip="Clear Commodity Profile" (click)="clearField('commodityProfile')">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-autocomplete #autoCommodityProfile="matAutocomplete" [displayWith]="displayCommodityProfile">
              <mat-option *ngFor="let profile of filteredCommodityProfiles$ | async" [value]="profile">{{ displayCommodityProfile(profile) }}</mat-option>
            </mat-autocomplete>
          </div>

          <div *ngIf="adjustmentSearchForm.get('commodityProfile').value" class="checkbox-group" formArrayName="prodYear">
            <span class="check-radio-label">Production Year</span>
            <ng-container *ngFor="let prodYear of prodYears; index as i">
                <mat-checkbox [value]="prodYear" [formControlName]="i">{{ prodYear.label | productionYear }}{{ yearSuffix(prodYear) }}</mat-checkbox>
            </ng-container>
          </div>

          <div class="checkbox-group">
            <span class="check-radio-label">Type</span>
            <div  fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="row wrap" fxLayoutGap.xs="15px">
              <mat-checkbox fxFlex.gt-xs="15" fxFlex.xs="100" #typeBoxes *ngFor="let type of ledgerAdjustmentTypes" (change)="onTypeChange($event)" [value]="type">
                {{ type | replace | titlecase }}
              </mat-checkbox>
            </div>
          </div>

          <div fxLayoutGap.gt-xs="25px" fxLayout.xs="column">
            <mat-form-field fxFlex.gt-xs="250px">
              <input matInput [matDatepicker]="startDatePicker" placeholder="Created Date (Start)" formControlName="startDate" [max]="adjustmentSearchForm.get('endDate').value">
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
              <mat-error *ngIf="adjustmentSearchForm.get('startDate').invalid">{{ getErrorMessage(adjustmentSearchForm.get('startDate')) }}</mat-error>
            </mat-form-field>
            <mat-form-field fxFlex.gt-xs="250px">
              <input matInput [matDatepicker]="endDatePicker" placeholder="Created Date (End)" formControlName="endDate" [min]="adjustmentSearchForm.get('startDate').value">
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
              <mat-error *ngIf="adjustmentSearchForm.get('endDate').invalid">{{ getErrorMessage(adjustmentSearchForm.get('endDate')) }}</mat-error>
            </mat-form-field>
          </div>

          <div *ngIf="!isSearching" fxLayout="row" fxLayoutGap="10px">
            <button id="submit-adjustment-search" [disabled]="adjustmentSearchForm.invalid" aria-label="Search" type="submit" mat-raised-button color="accent">Search</button>
            <button type="button" id="clear-adjustment-search" *ngIf="!adjustmentSearchForm.pristine" aria-label="Discard Search Options" (click)="reset()" mat-stroked-button color="accent">Reset</button>
          </div>
          <div *ngIf="isSearching" fxLayout="row" fxLayoutGap="10px">
            <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
          </div>

        </form>
      </div>

      <div *ngIf="showAdjustments" class="adjustment-list">
        <hms-ledger-adjustments (adjustmentSearchError)="handleAdjustmentListError($event)" (isSearching)="handleIsSearching($event)" [selectedAdjustments$]="selectedAdjustments$"></hms-ledger-adjustments>
      </div>

    </mat-card>
  </div>
</div>
