<div fxLayout="row" fxLayoutAlign="space-around start">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <hms-error-message [title]="'Error Retrieving Order Fill Report'" [message]="errorMessage"></hms-error-message>

    <div *ngIf="!errorMessage && clientSettings$ | async" fxLayout="column" fxLayoutAlign="space-between start">
      <h2 data-cy="page-title-order-fill-report" class="card-title">Order Fill Report</h2>
      <form [formGroup]="orderFillSearchForm" (ngSubmit)="loadOrderFillReportData()" fxLayout="column">
        <div fxLayoutGap.gt-xs="25px" fxLayout.xs="column">
          <mat-form-field fxFlex.gt-xs="250px">
            <input matInput [matDatepicker]="startDatePicker" placeholder="Start Date" formControlName="startDate" [min]="minStartDate" [max]="maxStartDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="orderFillSearchForm.get('startDate').invalid">{{ getErrorMessage(orderFillSearchForm.get('startDate')) }}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex.gt-xs="250px">
            <input matInput [matDatepicker]="endDatePicker" placeholder="End Date" formControlName="endDate" [min]="minEndDate" [max]="maxEndDate">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
            <mat-error *ngIf="orderFillSearchForm.get('endDate').invalid">{{ getErrorMessage(orderFillSearchForm.get('endDate')) }}</mat-error>
          </mat-form-field>
        </div>
        <div class="mat-small bottom-gap">Note: Dates begin and end on the Ledger End of Day ({{ ledgerEndOfDayLocal }}) not midnight.</div>
        <div *ngIf="!isLoading" fxLayout="row" fxLayoutGap="10px">
          <button id="generate-order-fill-report" [disabled]="orderFillSearchForm.invalid" aria-label="Generate Report" type="submit" mat-raised-button color="accent">Generate Report</button>
          <button type="button" id="clear-order-fill-report" *ngIf="!orderFillSearchForm.pristine" aria-label="Discard Report Options" (click)="reset()" mat-stroked-button color="accent">Reset</button>
        </div>
        <div *ngIf="isLoading" fxLayout="row" fxLayoutGap="10px">
          <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
        </div>
      </form>

      <!--
        Fill Price or Split Fill Prices for partial fills
      -->
      <ng-container *ngIf="fills$ | async">
        <div *ngIf="displayReports" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10" class="full-width">
          <ng-container *ngFor="let account of accountMap | keyvalue">
            <h4>
              Account: {{ account.key }}
              <button *ngIf="fillDataSourceMap[account.value].data.length" matTooltip="Export" aria-label="Export" id="export-order-fill-report" mat-icon-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button"><mat-icon>save_alt</mat-icon></button>
            </h4>
            <mat-menu #exportMenu="matMenu">
              <button type="button" aria-label="Export Excel" id="export-order-fill-report-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Order Fill Report'))">Excel</button>
              <button type="button" aria-label="Export CSV" id="export-order-fill-report-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Order Fill Report'))">CSV</button>
              <button type="button" aria-label="Export JSON" id="export-order-fill-report-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Order Fill Report'))">JSON</button>
            </mat-menu>

            <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="fillDataSourceMap[account.value]" class="mat-elevation-z0 full-width">
              <ng-container matColumnDef="orderDocId">
                <mat-header-cell *matHeaderCellDef fxFlex.xs="40" fxFlex.gt-xs="20">Order ID</mat-header-cell>
                <mat-cell *matCellDef="let fill" fxFlex.xs="40" fxFlex.gt-xs="20">{{ fill.orderDocId }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="fillTimestamp">
                <mat-header-cell *matHeaderCellDef fxFlex.gt-xs="15">Filled</mat-header-cell>
                <mat-cell *matCellDef="let fill" fxFlex.gt-xs="15"><span class="forced-wrap">{{ fill.fillTimestamp | date: 'MM/dd/yyyy hh:mm:ss a' }}</span></mat-cell>
              </ng-container>

              <ng-container matColumnDef="security">
                <mat-header-cell *matHeaderCellDef fxFlex.xs="20" fxFlex.gt-xs="10">Security</mat-header-cell>
                <mat-cell *matCellDef="let fill" fxFlex.xs="20" fxFlex.gt-xs="10">{{ fill.security }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="side">
                <mat-header-cell *matHeaderCellDef fxFlex.gt-xs="5">Side</mat-header-cell>
                <mat-cell *matCellDef="let fill" fxFlex.gt-xs="5">{{ fill.side | titlecase }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="fillQuantity">
                <mat-header-cell *matHeaderCellDef fxFlex.xs="20" fxFlex.gt-xs="10">Qty</mat-header-cell>
                <mat-cell *matCellDef="let fill" fxFlex.xs="20" fxFlex.gt-xs="10">{{ fill.fillQuantity }}/{{fill.orderQuantity}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="orderTargetPrice">
                <mat-header-cell *matHeaderCellDef fxFlex.gt-xs="10">Target</mat-header-cell>
                <mat-cell *matCellDef="let fill" fxFlex.gt-xs="10">{{ fill.orderType === 'MARKET' ? 'Market' : (fill.orderTargetPrice | number: '1.4-4') }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="orderFillPrice">
                <mat-header-cell *matHeaderCellDef fxFlex.xs="20" fxFlex.gt-xs="10">Fill</mat-header-cell>
                <mat-cell *matCellDef="let fill" fxFlex.xs="20" fxFlex.gt-xs="10">{{ fill.orderFillPrice | number: '1.4-4' }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="orderBuyPrice">
                <mat-header-cell *matHeaderCellDef fxFlex.gt-xs="10">Buy</mat-header-cell>
                <mat-cell *matCellDef="let fill" fxFlex.gt-xs="10">{{ fill.orderBuyPrice ? (fill.orderBuyPrice | number: '1.4-4') : '-' }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="orderSellPrice">
                <mat-header-cell *matHeaderCellDef fxFlex.gt-xs="10">Sell</mat-header-cell>
                <mat-cell *matCellDef="let fill" fxFlex.gt-xs="10">{{ fill.orderSellPrice ? (fill.orderSellPrice | number: '1.4-4') : '-' }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="footer">
                <mat-footer-cell *matFooterCellDef fxFlex="100">No Fills</mat-footer-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="fillColumnsToDisplay"></mat-header-row>
              <mat-row (click)="selectFill(fill)" *matRowDef="let fill; columns: fillColumnsToDisplay"></mat-row>
              <ng-container *ngIf="!fillDataSourceMap[account.value].data.length">
                <mat-footer-row *matFooterRowDef="footerColumnsToDisplay"></mat-footer-row>
              </ng-container>
            </mat-table>
          </ng-container>
        </div>
      </ng-container>
    </div>

  </mat-card>
</div>

