import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumberWithError, ParseError, NumberFormat } from 'libphonenumber-js';

const DEFAULT_FORMAT: NumberFormat = 'INTERNATIONAL';
@Pipe({ name: 'phoneFormat' })
export class PhoneFormatPipe implements PipeTransform {
  /**
   * Formats phone numbers as stored in the Patron.smsNotifications array e.g. +1##########
   * to look like the read-only version of the ati-phone-number. Returns passed in value verbatim on parse failure
   * @param value the phone number to be transformed
   * @param format optional NumberFormat to be used in formatting the number. Valid values are 'NATIONAL', 'National',
   * 'INTERNATIONAL', 'International', 'E.164', 'RFC3966', and 'IDD'
   */
  transform(value: string, format?: NumberFormat): string {
    try {
      const phoneNumber = parsePhoneNumberWithError(value);
      if (!format) {
        format = DEFAULT_FORMAT;
      }
      return phoneNumber.format(format);
    } catch (error) {
      if (error instanceof ParseError) {
        return value;
      } else {
        throw error;
      }
    }
  }
}
