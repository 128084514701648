import { ContractType } from '@advance-trading/ops-data-lib';
import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'contractType'})
export class ContractTypePipe implements PipeTransform {
  constructor(private titleCasePipe: TitleCasePipe) {}

  /**
   * This converts the contract type to be more readable
   * @param value the ContractType to be transformed
   */
  transform(value: string): string {
    return value === ContractType.HTA || value === ContractType.DP ? value : this.titleCasePipe.transform(value);
  }
}
