<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

      <hms-error-message [title]="'Error Performing Ad Hoc Orders Search'" [message]="errorMessage"></hms-error-message>

      <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
        <h2 data-cy="page-title-ad-hoc-orders" class="card-title">Ad Hoc Orders</h2>
        <div fxLayout="row" fxLayoutAlign="space-around">
          <mat-spinner class="loading-spinner" *ngIf="isLoading" diameter="40" color="accent"></mat-spinner>
          <button type="button" id="add-new-ad-hoc-order" class="action-button" *ngIf="!isLoading && isAdHocOrderAdmin" aria-label="Add New Ad Hoc Order" (click)="addAdHocOrder()" mat-mini-fab color="accent" matTooltip="Add New Ad Hoc Order">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>

      <form *ngIf="!errorMessage" [formGroup]="adHocOrderSearchForm" (ngSubmit)="searchAdHocOrders(true)" fxLayout="column">
        <div fxLayoutGap.gt-xs="25px" fxLayout.xs="column">

          <mat-form-field fxFlex.gt-xs="250px">
            <mat-label>Order ID</mat-label>
            <input matInput formControlName="orderDocId" mask="A*">
          </mat-form-field>

          <mat-form-field fxFlex.gt-xs="250px">
            <input matInput placeholder="Commodity Profile" formControlName="commodityProfile" [matAutocomplete]="autoCommodityProfile">
            <mat-error *ngIf="adHocOrderSearchForm.get('commodityProfile').invalid">You must select a valid commodity profile</mat-error>
            <button type="button" id="ad-hoc-order-search-clear-commodity-profile" mat-button *ngIf="adHocOrderSearchForm.get('commodityProfile').value" matSuffix mat-icon-button aria-label="Clear Commodity Profile" matTooltip="Clear Commodity Profile" (click)="adHocOrderSearchForm.get('commodityProfile').setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoCommodityProfile="matAutocomplete" [displayWith]="displayCommodityProfile">
            <mat-option *ngFor="let profile of filteredCommodityProfiles$ | async" [value]="profile">{{ displayCommodityProfile(profile) }}</mat-option>
          </mat-autocomplete>
        </div>

        <div fxLayout.xs="column">
          <mat-form-field hmsMonthPicker fxFlex.gt-xs="250px">
            <input matInput [matDatepicker]="contractYearMonthPicker" placeholder="Contract Month" formControlName="contractYearMonth">
            <mat-datepicker-toggle matSuffix [for]="contractYearMonthPicker"></mat-datepicker-toggle>
            <mat-datepicker #contractYearMonthPicker startView="year" (monthSelected)="selectContractYearMonth($event)" panelClass="futures-date-panel month-picker"></mat-datepicker>
            <mat-error *ngIf="adHocOrderSearchForm.get('contractYearMonth').invalid">{{ getErrorMessage(adHocOrderSearchForm.get('contractYearMonth')) }}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayoutGap.gt-xs="25px" fxLayout.xs="column">

          <mat-form-field fxFlex.gt-xs="250px">
            <input matInput [matDatepicker]="startDatePicker" placeholder="Created Date (Start)" formControlName="startDate" [max]="adHocOrderSearchForm.get('endDate').value">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker ></mat-datepicker>
            <mat-error *ngIf="adHocOrderSearchForm.get('startDate').invalid">{{ getErrorMessage(adHocOrderSearchForm.get('startDate')) }}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.gt-xs="250px">
            <input matInput [matDatepicker]="endDatePicker" placeholder="Created Date (End)" formControlName="endDate" [min]="adHocOrderSearchForm.get('startDate').value">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
            <mat-error *ngIf="adHocOrderSearchForm.get('endDate').invalid">{{ getErrorMessage(adHocOrderSearchForm.get('endDate')) }}</mat-error>
          </mat-form-field>

          <mat-form-field fxFlex.gt-xs="250px">
            <input matInput [matDatepicker]="lastUpdatedDatePicker" placeholder="Last Updated Date" formControlName="lastUpdatedDate">
            <mat-datepicker-toggle matSuffix [for]="lastUpdatedDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #lastUpdatedDatePicker></mat-datepicker>
            <mat-error *ngIf="adHocOrderSearchForm.get('lastUpdatedDate').invalid">{{ getErrorMessage(adHocOrderSearchForm.get('lastUpdatedDate')) }}</mat-error>
          </mat-form-field>
        </div>

        <div class="checkbox-group">
          <span class="check-radio-label">Status</span>
          <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="row wrap" fxLayoutGap.xs="15px" class="checkbox-wrapper">
            <mat-checkbox fxFlex.gt-xs="30" fxFlex.xs="100" #statusBoxes *ngFor="let status of availableStatuses" (change)="onStatusChange($event)" [value]="status">
              {{ status | replace | titlecase }}
            </mat-checkbox>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
          <mat-spinner class="loading-spinner" *ngIf="isSearching" diameter="40" color="accent"></mat-spinner>
          <button id="submit-ad-hoc-order-search" *ngIf="!isSearching" [disabled]="adHocOrderSearchForm.invalid" aria-label="Search" type="submit" mat-raised-button color="accent">Search</button>
          <button type="button" id="clear-ad-hoc-order-search" *ngIf="!adHocOrderSearchForm.pristine && !isSearching" aria-label="Discard Search Options" (click)="reset()" mat-stroked-button color="accent">Reset</button>
        </div>
      </form>

      <div *ngIf="showAdHocOrders && !errorMessage" class="ad-hoc-order-list">
        <hms-ad-hoc-orders (adHocOrderListChange)="handleAdHocOrderListChange($event)" (adHocOrderSearchError)="handleAdHocOrderListError($event)" (isSearching)="handleIsSearching($event)" [selectedAdHocOrders$]="selectedAdHocOrders$" [initialTableState]="tableState"></hms-ad-hoc-orders>
      </div>
    </mat-card>
  </div>
</div>
