<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

      <hms-error-message [title]="'Error Performing Contract Search'" [message]="errorMessage"></hms-error-message>

      <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
        <h2 data-cy="page-title-contracts" class="card-title">Contracts</h2>
        <div fxLayout="row" fxLayoutAlign="space-around">
          <mat-spinner class="loading-spinner" *ngIf="isLoading" diameter="40" color="accent"></mat-spinner>
          <button data-cy="add-contract-btn" type="button" id="add-new-contract" class="action-button" *ngIf="!isLoading && isContractCreator" aria-label="Add New Contract" (click)="addContract()" mat-mini-fab color="accent" matTooltip="Add New Contract">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>

      <form *ngIf="!errorMessage" [formGroup]="contractSearchForm" (ngSubmit)="searchContracts(true)" fxLayout="column">
        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="20px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="70">
            <mat-label>Contract ID</mat-label>
            <input matInput formControlName="contractId" trim="blur" mask="A*">
          </mat-form-field>

          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="70">
            <mat-label>Exchange ID</mat-label>
            <input matInput formControlName="exchangeId" trim="blur" mask="A*">
          </mat-form-field>
        </div>

        <mat-radio-group formControlName="type">
          <span class="check-radio-label">Type</span>
          <mat-radio-button *ngFor="let type of contractTypes" [value]="type">{{ type === 'HTA' || type === 'DP' ? type : type | titlecase }}</mat-radio-button>
        </mat-radio-group>

        <hms-contract-status (statusChanges)="updateStatuses($event)" formControlName="statuses" [displayAdvancedListValue]="searchLevel === 'Advanced' ? true : false"></hms-contract-status>

        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="20px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column">
          <div fxFlex.gt-xs="45" fxLayout.gt-sm="row wrap" fxLayoutAlign.gt-sm="space-between center" fxLayout.sm="column" fxLayout.xs="column">
            <mat-form-field  fxFlex.md="47" fxFlex.lg="48" fxFlex.gt-lg="49" >
              <input matInput [matDatepicker]="startDatePicker" placeholder="Updated Date (Start)" formControlName="startDate" [max]="contractSearchForm.get('endDate').value">
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
              <mat-error *ngIf="contractSearchForm.get('startDate').invalid">{{ getErrorMessage(contractSearchForm.get('startDate')) }}</mat-error>
            </mat-form-field>
            <mat-form-field  fxFlex.md="47" fxFlex.lg="48" fxFlex.gt-lg="49">
              <input matInput [matDatepicker]="endDatePicker" placeholder="Updated Date (End)" formControlName="endDate" [min]="contractSearchForm.get('startDate').value">
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
              <mat-error *ngIf="contractSearchForm.get('endDate').invalid">{{ getErrorMessage(contractSearchForm.get('endDate')) }}</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex.gt-xs="45" fxLayout.gt-sm="row wrap" fxLayoutAlign.gt-sm="space-between center" fxLayout.sm="column" fxLayout.xs="column">
            <mat-form-field hmsMonthPicker fxFlex.md="47" fxFlex.lg="48" fxFlex.gt-lg="49">
              <input matInput [matDatepicker]="deliveryMonthPicker" placeholder="Delivery Period" formControlName="deliveryMonth" (keydown)="allowTabOnly($event)">
              <mat-datepicker-toggle matSuffix [for]="deliveryMonthPicker" data-cy="deliveryPeriodPicker"></mat-datepicker-toggle>
              <mat-datepicker #deliveryMonthPicker startView="year" (monthSelected)="selectDeliveryMonth($event)" panelClass="month-picker"></mat-datepicker>
              <mat-error *ngIf="contractSearchForm.get('deliveryMonth').invalid">{{ getErrorMessage(contractSearchForm.get('deliveryMonth')) }}</mat-error>
            </mat-form-field>
            <mat-form-field  hmsMonthPicker fxFlex.md="47" fxFlex.lg="48" fxFlex.gt-lg="49" data-cy="futuresYearMonth">
              <input matInput [matDatepicker]="futuresMonthPicker" placeholder="Futures Month" formControlName="futuresMonth" (keydown)="allowTabOnly($event)">
              <mat-datepicker-toggle matSuffix [for]="futuresMonthPicker" data-cy="futuresMonthPicker"></mat-datepicker-toggle>
              <mat-datepicker #futuresMonthPicker startView="year" (monthSelected)="selectFuturesMonth($event)" panelClass="month-picker"></mat-datepicker>
              <mat-error *ngIf="contractSearchForm.get('futuresMonth').invalid">{{ getErrorMessage(contractSearchForm.get('futuresMonth')) }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="20px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column">
          <mat-form-field fxFlex.gt-xs="45" data-cy="clientLocation">
            <input matInput placeholder="Client Location" formControlName="location" [matAutocomplete]="autoLocation">
            <mat-error *ngIf="contractSearchForm.get('location').invalid">You must select a valid location</mat-error>
            <button type="button" id="contract-search-clear-location" mat-button *ngIf="contractSearchForm.get('location').value" matSuffix mat-icon-button aria-label="Clear Location" matTooltip="Clear Location" (click)="contractSearchForm.get('location').setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoLocation="matAutocomplete" [displayWith]="displayLocation">
            <mat-option *ngFor="let location of filteredLocations$ | async" [value]="location">{{ displayLocation(location) }}</mat-option>
          </mat-autocomplete>

          <mat-form-field fxFlex.gt-xs="45" data-cy="patron">
            <input matInput placeholder="Patron" formControlName="patron" [matAutocomplete]="autoPatron">
            <mat-error *ngIf="contractSearchForm.get('patron').invalid">You must select a valid patron</mat-error>
            <button type="button" id="contract-search-clear-patron" mat-button *ngIf="contractSearchForm.get('patron').value" matSuffix mat-icon-button aria-label="Clear Patron" matTooltip="Clear Patron" (click)="contractSearchForm.get('patron').setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoPatron="matAutocomplete" [displayWith]="displayPatron">
            <mat-option *ngFor="let patron of filteredPatrons$ | async" [value]="patron">{{ displayPatron(patron) }}</mat-option>
          </mat-autocomplete>
        </div>

        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="20px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column">
          <mat-form-field fxFlex.gt-xs="45" data-cy="originator">
            <input matInput placeholder="Originator" formControlName="originator" [matAutocomplete]="autoOriginator">
            <mat-error *ngIf="contractSearchForm.get('originator').invalid">You must select a valid originator</mat-error>
            <button type="button" id="contract-search-clear-originator" mat-button *ngIf="contractSearchForm.get('originator').value" matSuffix mat-icon-button aria-label="Clear Originator" matTooltip="Clear Originator" (click)="contractSearchForm.get('originator').setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoOriginator="matAutocomplete" [displayWith]="displayOriginator">
            <mat-option *ngFor="let originator of filteredOriginators$ | async" [value]="originator">{{ displayOriginator(originator) }}</mat-option>
          </mat-autocomplete>
        </div>

        <div *ngIf="!isSearching" fxLayout="row" fxLayoutGap="10px">
          <button id="submit-contract-search" [disabled]="contractSearchForm.invalid" aria-label="Search" type="submit" mat-raised-button color="accent">Search</button>
          <button type="button" id="clear-contract-search" *ngIf="!contractSearchForm.pristine" aria-label="Reset" (click)="reset()" mat-stroked-button color="accent">Reset</button>
        </div>
        <div *ngIf="isSearching" fxLayout="row" fxLayoutGap="10px">
          <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
        </div>

      </form>

      <!-- Display Contracts list screen below ContractSearch -->
      <div *ngIf="showContracts && !errorMessage" class="contract-list">
        <hms-contracts (contractListChange)="handleContractListChange($event)" (contractSearchError)="handleContractListError($event)" (isSearching)="handleIsSearching($event)" [selectedContracts$]="selectedContracts$" [initialTableState]="tableState"></hms-contracts>
      </div>

    </mat-card>
  </div>

</div>
