import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import moment from 'moment-timezone';
import { from, Observable, of } from 'rxjs';
import { catchError, concatMap, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { Auth0AuthzService, AuthService } from '@advance-trading/angular-ati-security';
import { ConfirmationDialogService } from '@advance-trading/angular-common-services';
import { CommodityProfileService, OperationsDataService, UserService } from '@advance-trading/angular-ops-data';
import {
  Commodity,
  CommodityMap,
  CommodityProfile,
  CommodityProfileProductionYear,
  HMSDailyLedger,
  LedgerAdjustment,
  LedgerAdjustmentType,
  ProductionYear,
  User
} from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { HMSDailyLedgerService } from '../../service/hms-daily-ledger.service';
import { LedgerAdjustmentService } from '../../service/ledger-adjustment.service';
import { LedgerHelperService } from '../../service/ledger-helper.service';
import { CommodityProfileValidators } from '../commodity-profile.validator';
import { ProductionYearComponent } from '../production-year/production-year.component';
import { UserRoles } from '../../utilities/user-roles';
import { LedgerDay } from '../../utilities/ledger-day';

import { HedgeableQuantityDialogComponent } from '../hedgeable-quantity-dialog/hedgeable-quantity-dialog.component';

const YEAR_FORMAT = 'YYYY';
const YEAR_MONTH_FORMAT = 'YYYY-MM';
const FULL_DATE_FORMAT = 'YYYY-MM-DD';
const PRICE_REGEX = /^-?(\d+|\d*\.\d{1,4}|\d+\.\d{0,4})$/;
const POSITIVE_INTEGER = /^\d{1,}$/;
const POSITIVE_OR_NEGATIVE_INTEGER = /^-?\d{1,}$/;

@Component({
  selector: 'hms-commodity-profile-detail',
  templateUrl: './commodity-profile-detail.component.html',
  styleUrls: ['./commodity-profile-detail.component.scss']
})
export class CommodityProfileDetailComponent implements OnInit {
  @ViewChildren(ProductionYearComponent) prodYearsComponent: QueryList<ProductionYearComponent>;

  errorMessage = '';
  updateComplete = true;
  editMode = false;
  commodityMap$: Observable<CommodityMap>;
  commodityProfile: CommodityProfile;
  minDate = moment().startOf('month');
  commodity: Commodity;
  addingProdYear = false;
  preservedProductionYears: string[] = [];

  commodityProfileForm: FormGroup = this.formBuilder.group({
    isActive: [true],
    hasBasis: [false],
    commodity: [''],
    name: ['', [Validators.required, Validators.maxLength(30)]],
    minPrice: ['', [Validators.required, Validators.pattern(PRICE_REGEX)]],
    maxPrice: ['', [Validators.required, Validators.pattern(PRICE_REGEX)]],
    longThreshold: ['', [Validators.required, Validators.pattern(POSITIVE_INTEGER)]],
    shortThreshold: ['', [Validators.required, Validators.pattern(POSITIVE_OR_NEGATIVE_INTEGER)]],
    targetOrderThreshold: ['', [Validators.required, Validators.pattern(POSITIVE_INTEGER)]],
    defaultGrade: ['', [Validators.required, Validators.maxLength(1)]],
    prodYears: this.formBuilder.group({})
  });

  private selectedClientDocId = '';
  private validFuturesMonths: number[];
  private todayMoment: moment.Moment;
  private loggedInUser: User;

  // commodities document futures months
  futuresMonths: { [key: string]: number[] } = {};
  lastAddedProductionYear: string;
  ledgerEndOfDay: string;
  timezone: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private changeDetectorService: ChangeDetectorRef,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private operationsDataService: OperationsDataService,
    private commodityProfileService: CommodityProfileService,
    private dialogService: ConfirmationDialogService,
    private formBuilder: FormBuilder,
    public hedgeableQuantityDialog: MatDialog,
    private hmsDailyLedgerService: HMSDailyLedgerService,
    private ledgerAdjustmentService: LedgerAdjustmentService,
    private ledgerHelperService: LedgerHelperService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.setEditMode(false);
    let commodityProfileDocId: string;
    this.commodityMap$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap(selectedClient => {
        this.selectedClientDocId = selectedClient.docId;
        return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId);
      }),
      switchMap((user: User) => {
        this.loggedInUser = user;
        return this.clientSettingsService.getHmsLedgerEndOfDaySettingsByClientDocId(this.selectedClientDocId);
      }),
      switchMap((ledgerDay: LedgerDay) => {
        this.timezone = ledgerDay.timezone;
        this.ledgerEndOfDay = this.ledgerHelperService.getLedgerEndOfDayFromMoment(ledgerDay.ledgerEndOfDay);
        this.todayMoment = moment.tz(this.ledgerHelperService.getCurrentBusinessDay(this.ledgerEndOfDay, this.timezone), this.timezone);
        return this.activatedRoute.paramMap;
      }),
      switchMap((paramMap: ParamMap) => {
        commodityProfileDocId = paramMap.get('docId');
        return this.commodityProfileService.getCommodityProfileByDocId(this.selectedClientDocId, commodityProfileDocId);
      }),
      switchMap((commodityProfile: CommodityProfile) => {
        this.commodityProfile = commodityProfile;
        return this.operationsDataService.getCommodityMap();
      }),
      tap((doc: CommodityMap) => {
        // get futures months from commodities document in firestore
        Object.keys(doc.commodities).forEach((commodityId: string) => {
          this.futuresMonths[commodityId] = doc.commodities[commodityId].contractMonths;
        });

        // set commodity profile form validator
        this.commodityProfileForm.setValidators([CommodityProfileValidators.productionYearsValidator(this.futuresMonths)]);
        this.validFuturesMonths = this.futuresMonths[this.commodityProfile.commodityId];

        if (this.commodityProfile.commodityId) {
          this.commodity = Object.values(doc.commodities).find(commodity => commodity.id === this.commodityProfile.commodityId);
        }
        if (this.commodity && this.commodity.contractSize) {
          this.commodityProfileForm.get('targetOrderThreshold').setValidators(
            [Validators.required, Validators.pattern(POSITIVE_INTEGER), Validators.max(this.commodity.contractSize)]
          );
        }
        this.setupCommodityProfileForm();
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving commodity profile; please try again later';
        console.error(`Error retrieving commodity profile: ${err}`);
        return of(undefined);
      })
    );
  }

  get canRoll() {
    const prodYearForms = Object.values((this.commodityProfileForm.get('prodYears') as FormGroup).controls) as FormGroup[];
    // if an old prodYear exists that expires today, we can't adjust dates to have another inactive old
    const expiringProdYearExists = prodYearForms.find(prodYearForm => prodYearForm.get('label').value === ProductionYear.OLD &&
      this.todayMoment.isSame(this.getEndOfDay(prodYearForm.get('endDate').value))) !== undefined;
    const newProdYearExists = prodYearForms.find(prodYearForm => prodYearForm.get('label').value === ProductionYear.NEW) !== undefined;
    return !expiringProdYearExists && newProdYearExists && !this.editMode && this.updateComplete && this.isCommodityProfileAdmin;
  }

  get isCommodityProfileAdmin() {
    return this.authzService.currentUserHasRole(UserRoles.COMMODITY_PROFILE_ADMIN_ROLE);
  }

  get isHMSAdmin() {
    return this.authzService.currentUserHasRole(UserRoles.HMS_ADMIN_ROLE);
  }

  checkForInactivationOfSpreadProfile() {
    this.updateComplete = false;
    if (!this.commodityProfile.isSpreadProfile && this.commodityProfile.isActive && !this.commodityProfileForm.value.isActive) {
      this.hmsDailyLedgerService.getAllHMSDailyLedgersByCommodityProfileAndBusinessDate(this.selectedClientDocId, this.commodityProfile.docId, this.todayMoment.format(FULL_DATE_FORMAT)).pipe(
        take(1),
        catchError(err => {
          const message = 'Error loading daily ledgers';
          console.error(`${message}: ${err.message}`);
          return of([])
        })
      ).subscribe((hmsDailyLedgers: HMSDailyLedger[]) => {
        const ledgersWithHedgeableQuantities = hmsDailyLedgers.filter(dailyLedger => dailyLedger.hedgeableQuantity != 0);
        if (ledgersWithHedgeableQuantities.length) {
          const dialogRef = this.hedgeableQuantityDialog.open(HedgeableQuantityDialogComponent, {
            data: {
              ledgersWithHedgeableQuantities: ledgersWithHedgeableQuantities
            },
            height: 'auto',
            width: '400px'
          });
          dialogRef.afterClosed().subscribe(decision => {
            console.log(decision);
            if (decision === 'create') {
              from(ledgersWithHedgeableQuantities).pipe(
                concatMap((ledger: HMSDailyLedger) => {
                  const newAdjustment = new LedgerAdjustment();
                  newAdjustment.commodityProfileDocId = this.commodityProfile.docId;
                  newAdjustment.productionYear = ledger.productionYear;
                  newAdjustment.quantity = ledger.hedgeableQuantity * -1;
                  newAdjustment.type = LedgerAdjustmentType.STANDARD;
                  newAdjustment.creatorDocId = this.authService.userProfile.app_metadata.firestoreDocId;
                  const userName = `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`;
                  newAdjustment.creatorName = userName;
                  newAdjustment.lastUpdatedByName = userName;
                  newAdjustment.comments = 'Automatically generated ledger adjustment when deactivating a commodity profile'
                  return this.ledgerAdjustmentService.createLedgerAdjustment(this.selectedClientDocId, newAdjustment);
                })
              ).subscribe(results => {
                this.processFormChanges();
              });
            } else if (decision === 'ignore') {
              this.processFormChanges();
            } else {
              // cancel, no click
              this.updateComplete = true;
            }
          });
        } else {
          this.processFormChanges();
        }
      });
    } else {
      this.processFormChanges();
    }
  }

  setEditMode(editable: boolean): void {
    this.editMode = editable;
    if (this.editMode) {
      this.commodityProfileForm.enable();
      if (this.commodityProfile.isSpreadProfile) {
        this.commodityProfileForm.get('hasBasis').disable();
        this.commodityProfileForm.get('targetOrderThreshold').disable();
        this.commodityProfileForm.get('minPrice').disable();
        this.commodityProfileForm.get('maxPrice').disable();
        this.commodityProfileForm.get('shortThreshold').disable();
        this.commodityProfileForm.get('longThreshold').disable();
        this.commodityProfileForm.get('prodYears').disable();
        this.commodityProfileForm.get('defaultGrade').disable();
      }
    } else {
      this.commodityProfileForm.disable();
    }
  }

  reset() {
    this.setupCommodityProfileForm();
    this.addingProdYear = false;
    this.commodityProfileForm.markAsPristine();
    this.setEditMode(false);
  }

  validFuturesMonthFilter = (currentMoment: moment.Moment | null): boolean => {
    if (currentMoment) {
      const month = currentMoment.month();
      // return true if validFuturesMonths can't be set due to no commodity or no commodity entry in futuresMonths
      return this.validFuturesMonths ? this.validFuturesMonths.includes(month) : true;
    } else {
      return true;
    }
  }

  selectMonth(e: moment.Moment, controlName: string, picker: MatDatepicker<moment.Moment>) {
    const control = this.commodityProfileForm.get(controlName);
    control.setValue(e);
    // necessary for the form to register as dirty for saving if month-picker is only field touched
    control.markAsDirty();
    picker.close();
  }

  setMinFrom(controlName: string): string {
    return moment(this.commodityProfileForm.get(controlName).value).add(1, 'd').format();
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('pattern')) {
      return 'Value invalid';
    } else if (control.hasError('maxlength')) {
      return `Value cannot exceed ${control.errors['maxlength'].requiredLength} character${control.errors['maxlength'].requiredLength === 1 ? '' : 's'}`;
    } else if (control.hasError('max')) {
      return 'Value cannot exceed ' + control.errors['max'].max.toLocaleString();
    }
    return 'Unknown error';
  }

  format4(fieldName: string) {
    const val = Number(this.commodityProfileForm.get(fieldName).value);
    if (val && Number.isFinite(val)) {
      this.commodityProfileForm.get(fieldName).setValue(val.toFixed(4));
    }
  }

  ensureNegative(fieldName: string) {
    const field = this.commodityProfileForm.get(fieldName);
    const val = parseInt(field.value, 10);
    if (val > 0) {
      field.setValue(val * -1);
      field.markAsDirty();
    }
  }

  get commodityName() {
    return this.commodity ? `${this.commodity.name} (${this.commodity.id})` : '';
  }

  get accountNumber() {
    return `${this.commodityProfile.officeCode}${this.commodityProfile.accountNumber}`;
  }

  get prodYearsControls() {
    return (this.commodityProfileForm.get('prodYears') as FormGroup).controls;
  }

  onProductionYearCreated(productionYear: FormGroup) {
    const year: string = moment(productionYear.get('year').value).format(YEAR_FORMAT);
    const prodYears: FormGroup = this.commodityProfileForm.get('prodYears') as FormGroup;
    prodYears.addControl(year, productionYear);
    this.lastAddedProductionYear = year;
    this.commodityProfileForm.markAsDirty();
    this.addingProdYear = false;
  }

  onProductionYearRemoved() {
    if (this.addingProdYear) {
      this.addingProdYear = false;
      return;
    }
    const prodYears: FormGroup = this.commodityProfileForm.get('prodYears') as FormGroup;
    prodYears.removeControl(this.lastAddedProductionYear);
    this.lastAddedProductionYear = (parseInt(this.lastAddedProductionYear, 10) - 1).toString();
    this.commodityProfileForm.markAsDirty();
  }

  onProductionYearUpdated() {
    const prodYears: FormGroup = this.commodityProfileForm.get('prodYears') as FormGroup;
    // Note: FormGroup changes is not detected as an onChange event in new ProductionYearComponent
    // New production year component is not being re rendered, so we're resetting the startDateValidator
    this.prodYearsComponent.forEach(prodYearComponent => {
      prodYearComponent.resetStartDateValidator();
      this.changeDetectorService.detectChanges();
      const prodYearForm = prodYearComponent.productionYearForm;
      const year = moment(prodYearForm.get('year').value).format(YEAR_FORMAT);
      prodYears.setControl(year, prodYearForm);
    });

    this.commodityProfileForm.markAsDirty();
  }

  addProductionYear() {
    this.addingProdYear = true;
  }

  rollProductionYears() {
    // confirm if the user wants to roll production years
    this.dialogService.open({
      title: 'Roll Production Years',
      message: 'Are you sure you want to roll all Production Years (e.g. New to Old, New + 1 to New)?',
      btnColor: 'accent'
    },
      'auto',
      '400px'
    );

    this.dialogService.afterClosed().subscribe(confirm => {
      if (!confirm) {
        return;
      }
      this.commodityProfile.productionYears = this.getProductionYears();
      const labels = Object.values(ProductionYear);
      Object.values(this.commodityProfile.productionYears).map(productionYear => {
        const labelIndex = labels.indexOf(productionYear.label);
        if (labelIndex > 0) {
          productionYear.label = labels[labelIndex - 1];
        }
      });
      // check for two active olds, adjust dates accordingly
      const activeOlds = Object.values(this.commodityProfile.productionYears).filter(productionYear =>
        productionYear.label === ProductionYear.OLD && this.todayMoment.isSameOrBefore(this.getEndOfDay(productionYear.endDate)));
      if (activeOlds.length > 1) {
        const inactiveOld = this.commodityProfile.productionYears[activeOlds[0].year];
        const activeOld = this.commodityProfile.productionYears[activeOlds[1].year];
        inactiveOld.endDate = this.todayMoment.format(FULL_DATE_FORMAT);
        activeOld.startDate = moment(this.todayMoment).add(1, 'day').format(FULL_DATE_FORMAT);
      }
      this.updateCommodityProfile(`Production years successfully rolled`, 'Failure rolling production years', false);
    });

  }

  get canAdd() {
    const prodYears = this.commodityProfileForm.get('prodYears') as FormGroup;
    let returnValue = true;
    Object.keys(prodYears.controls).forEach(year => {
      const prodYear = prodYears.controls[year] as FormGroup;
      if (prodYear.get('label').value === ProductionYear.NEW_PLUS_2) {
        returnValue = false;
      }
    });
    return returnValue;
  }

  trackByYear(index: number, el: any): string {
    return (el.value as FormGroup).get('year').value;
  }

  private getEndOfDay(date?: string) {
    return this.ledgerHelperService.endOfBusinessDay(moment(date), this.ledgerEndOfDay, this.timezone);
  }

  private getProductionYears(): { [key: string]: CommodityProfileProductionYear } {
    const prodYears: FormGroup = this.commodityProfileForm.get('prodYears') as FormGroup;
    const productionYears: { [key: string]: CommodityProfileProductionYear } = {};
    Object.values(prodYears.controls).forEach((prodYear: FormGroup) => {
      const newProductionYear = {} as CommodityProfileProductionYear;
      newProductionYear.year = moment(prodYear.get('year').value).format(YEAR_FORMAT);
      newProductionYear.label = prodYear.get('label').value;
      newProductionYear.startDate = moment(prodYear.get('startDate').value).format(FULL_DATE_FORMAT);
      newProductionYear.endDate = moment(prodYear.get('endDate').value).format(FULL_DATE_FORMAT);
      newProductionYear.targetFuturesYearMonth = moment(prodYear.get('targetFuturesYearMonth').value).format(YEAR_MONTH_FORMAT);
      newProductionYear.hedgeBuyFuturesYearMonth = moment(prodYear.get('hedgeBuyFuturesYearMonth').value).format(YEAR_MONTH_FORMAT);
      newProductionYear.hedgeSellFuturesYearMonth = moment(prodYear.get('hedgeSellFuturesYearMonth').value).format(YEAR_MONTH_FORMAT);
      productionYears[newProductionYear.year] = newProductionYear;
    });
    return productionYears;
  }

  private setupCommodityProfileForm() {
    if (this.commodityProfile) {
      this.commodityProfileForm.patchValue(this.commodityProfile);
      this.format4('minPrice');
      this.format4('maxPrice');
      this.preservedProductionYears = [];
      const prodYears: FormGroup = this.commodityProfileForm.get('prodYears') as FormGroup;
      Object.keys(prodYears.controls).forEach(prodYear => prodYears.removeControl(prodYear));
      Object.values(this.commodityProfile.productionYears).forEach(productionYear => {
        const productionYearForm = this.formBuilder.group({
          year: [moment(productionYear.year, YEAR_FORMAT), [Validators.required]],
          label: [productionYear.label, [Validators.required, Validators.maxLength(12)]],
          startDate: [moment(productionYear.startDate, FULL_DATE_FORMAT).format(), { updateOn: 'blur' }, [Validators.required]],
          endDate: [moment(productionYear.endDate, FULL_DATE_FORMAT).format(), { updateOn: 'blur' }, [Validators.required]],
          targetFuturesYearMonth: [moment(productionYear.targetFuturesYearMonth, YEAR_MONTH_FORMAT), [Validators.required]],
          hedgeBuyFuturesYearMonth: [moment(productionYear.hedgeBuyFuturesYearMonth, YEAR_MONTH_FORMAT), [Validators.required]],
          hedgeSellFuturesYearMonth: [moment(productionYear.hedgeSellFuturesYearMonth, YEAR_MONTH_FORMAT), [Validators.required]],
        });
        prodYears.addControl(productionYear.year, productionYearForm);
        this.preservedProductionYears.push(productionYear.year);
        this.lastAddedProductionYear = productionYear.year;
      });
    }
  }

  private processFormChanges() {
    this.commodityProfile.name = this.commodityProfileForm.value.name;
    this.commodityProfile.isActive = this.commodityProfileForm.value.isActive;
    if (!this.commodityProfile.isSpreadProfile) {
      this.commodityProfile.hasBasis = this.commodityProfileForm.value.hasBasis;
      this.commodityProfile.defaultGrade = this.commodityProfileForm.value.defaultGrade;
      this.commodityProfile.targetOrderThreshold = parseInt(this.commodityProfileForm.value.targetOrderThreshold, 10);
      this.commodityProfile.minPrice = parseFloat(this.commodityProfileForm.value.minPrice);
      this.commodityProfile.maxPrice = parseFloat(this.commodityProfileForm.value.maxPrice);
      this.commodityProfile.shortThreshold = parseInt(this.commodityProfileForm.value.shortThreshold, 10);
      this.commodityProfile.longThreshold = parseInt(this.commodityProfileForm.value.longThreshold, 10);
      this.commodityProfile.productionYears = this.getProductionYears();
    }
    this.updateCommodityProfile('Commodity profile successfully updated', 'Commodity profile update failed', true);
  }

  private updateCommodityProfile(successMsg: string, failMsg: string, navigateAway: boolean) {
    this.commodityProfileService.updateCommodityProfile(this.selectedClientDocId, this.commodityProfile)
      .then(() => {
        this.updateComplete = true;
        console.log(successMsg);
        this.openSnackBar(successMsg, 'DISMISS', true);
        if (navigateAway) {
          this.router.navigate(['../'], { relativeTo: this.activatedRoute });
        }
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`${failMsg}: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`${failMsg}: ${errorMsg}`, 'DISMISS', false);
      });
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}
