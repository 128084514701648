import { CbotGrainsOilseeds, MgexWheat } from '@advance-trading/exchange-trading-utilities';
import { MarketData, Side } from '@advance-trading/ops-data-lib';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommodityFuturesPriceService {

  private cbotGrainsOilseeds: CbotGrainsOilseeds;
  private mgexWheat: MgexWheat;

  private marketFunctionsByCommodity = {
    'KE': {
      isMarketOpen: (dt: Date) => this.cbotGrainsOilseeds.isMarketOpen(dt),
      isMarketHalted: (dt: Date) => this.cbotGrainsOilseeds.isMarketHalted(dt)
    },
    'MWE': {
      isMarketOpen: (dt: Date) => this.mgexWheat.isMarketOpen(dt),
      isMarketHalted: (dt: Date) => this.mgexWheat.isMarketHalted(dt)
    },
    'ZC': {
      isMarketOpen: (dt: Date) => this.cbotGrainsOilseeds.isMarketOpen(dt),
      isMarketHalted: (dt: Date) => this.cbotGrainsOilseeds.isMarketHalted(dt)
    },
    'ZO': {
      isMarketOpen: (dt: Date) => this.cbotGrainsOilseeds.isMarketOpen(dt),
      isMarketHalted: (dt: Date) => this.cbotGrainsOilseeds.isMarketHalted(dt)
    },
    'ZS': {
      isMarketOpen: (dt: Date) => this.cbotGrainsOilseeds.isMarketOpen(dt),
      isMarketHalted: (dt: Date) => this.cbotGrainsOilseeds.isMarketHalted(dt)
    },
    'ZW': {
      isMarketOpen: (dt: Date) => this.cbotGrainsOilseeds.isMarketOpen(dt),
      isMarketHalted: (dt: Date) => this.cbotGrainsOilseeds.isMarketHalted(dt)
    }
  }

  constructor() {
    this.cbotGrainsOilseeds = new CbotGrainsOilseeds();
    this.mgexWheat = new MgexWheat();
  }

  /**
   * Get the market price at the current moment
   * @param contractSide The side of a contract  Buy or Sell
   * @param marketData The market data which gives us the commodity symbol
   * @returns a number for the market price
   */
  getMarketPrice(contractSide: Side, marketData: MarketData, delayed?: boolean): number {
    if (!marketData.commodity) {
      return undefined;
    }

    if(this.isMarketClosedForCommodity(marketData.commodity) && marketData.settlementPrice) {
      return marketData.settlementPrice;
    }
    return contractSide === Side.BUY ? marketData.bid : marketData.ask;
  }

  /**
   * Determine if the market is currently closed (and not halted) for the given commodity
   * @param comm The commodity symbol to verify
   * @param delayed Optional boolean to indicate we need to include a delay in determining market closed
   * @returns Returns a true/false value for when the market is closed
   */
  private isMarketClosedForCommodity(comm: string, delayed?: boolean): boolean {
    const commodity = this.marketFunctionsByCommodity[comm];
    if (delayed) {
      const now = new Date().toLocaleString('en-US', {timeZone: 'America/Chicago'});
      const chicagoNow = new Date(now);
      chicagoNow.setMinutes(chicagoNow.getMinutes() - 10);
      return !commodity.isMarketOpen(chicagoNow) && !commodity.isMarketHalted(chicagoNow);
    }
    return !commodity.isMarketOpen() && !commodity.isMarketHalted();
  }
}

