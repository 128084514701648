<div class="container">
  <h3>Mass Update</h3>
  <div class="mat-small note">
    <ul>
      <li>Remember to select intersecting columns and rows before performing a mass update</li>
      <li>{{ getDecimalPrecisionNote() }}</li>
      <li>Adjustment only applies to fields with a basis</li>
      <li>Adjustment requires "+" or "-" operator</li>
    </ul>
  </div>

  <h4>Mass Basis Adjustment</h4>
  <form [formGroup]="massAdjustment" (ngSubmit)="handleMassAdjustment()" #massAdjustmentForm="ngForm">
    <div fxLayout="row" fxLayoutGap="15px">
      <mat-form-field>
        <input matInput formControlName="value" type="text" name="adjustmentValue" placeholder="Adjustment">
        <mat-hint *ngIf="!massAdjustment.get('value').invalid">Ex. +0.5, -0.5</mat-hint>
        <mat-error *ngIf="massAdjustment.get('value').invalid">
          {{ getErrorMessage(massAdjustment.get('value')) }}
        </mat-error>
      </mat-form-field>
      <button id="mass-adjust-basis" aria-label="Mass Adjust Basis" mat-stroked-button color="accent" type="submit" [disabled]="massAdjustment.invalid || !massAdjustment.get('value').value">Adjust</button>
    </div>
  </form>

  <h4>Mass Basis Update</h4>
  <form [formGroup]="massChange" (ngSubmit)="handleMassChange()" #massChangeForm="ngForm">
    <div fxLayout="row" fxLayoutGap="15px">
      <mat-form-field>
        <input matInput formControlName="value" type="text" name="updateValue" placeholder="Basis">
        <mat-hint *ngIf="!massChange.get('value').invalid">Ex. 0.5, -0.5</mat-hint>
        <mat-error *ngIf="massChange.get('value').invalid">
            {{ getErrorMessage(massChange.get('value')) }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput formControlName="futuresMonth" type="text" name="futuresMonth" placeholder="Futures Month">
        <mat-hint *ngIf="!massChange.get('futuresMonth').invalid">Ex. 21F</mat-hint>
        <mat-error *ngIf="massChange.get('futuresMonth').invalid">
            {{ getErrorMessage(massChange.get('futuresMonth')) }}
        </mat-error>
      </mat-form-field>
      <button id="mass-update-basis" aria-label="Mass Update Basis" mat-stroked-button color="accent" type="submit" [disabled]="massChange.invalid || (!massChange.get('value').value && !massChange.get('futuresMonth').value)">Set</button>
    </div>
    <button id="mass-reset-basis" aria-label="Mass Reset Basis" class="reset-btn" mat-stroked-button color="accent" type="button" (click)="handleMassReset()">Mass Reset</button>
  </form>
</div>
