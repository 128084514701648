import { HMSUserPreferences } from '@advance-trading/ops-data-lib';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'hms-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss']
})
export class UserPreferencesComponent implements OnChanges {

  userPreferencesForm: FormGroup = this.formBuilder.group({});

  @Input() hmsUserPreferences: HMSUserPreferences;
  @Input() editMode: boolean;
  @Input() profileForm: FormGroup;

  @Output() userPreferencesUpdated: EventEmitter<FormGroup> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hmsUserPreferences'] || changes['editMode']) {
      this.userPreferencesForm = this.formBuilder.group({
        useLiveLedgerColor: [this.hmsUserPreferences.useLiveLedgerColor, [Validators.required]]
      });

      this.userPreferencesForm.valueChanges.subscribe(() => {
        this.userPreferencesUpdated.emit(this.userPreferencesForm);
      });
    }
  }

}
