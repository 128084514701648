<div fxFlex="100">

  <div *ngIf="!errorMessage" fxLayout="column">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="hedges-filter-field" #filter>
      <button type="button" id="clear-hedges-filter" mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button *ngIf="exportable" aria-label="Export" id="export-hedges" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
    <mat-menu #exportMenu="matMenu">
      <button type="button" aria-label="Export Excel" id="export-hedges-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Hedges'))">Excel</button>
      <button type="button" aria-label="Export CSV" id="export-hedges-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Hedges'))">CSV</button>
      <button type="button" aria-label="Export JSON" id="export-hedges-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Hedges'))">JSON</button>
    </mat-menu>

    <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort class="mat-elevation-z0" (matSortChange)="handleSortChange()">

      <ng-container matColumnDef="creationTimestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="50" fxFlex.gt-xs="20">Created</mat-header-cell>
        <mat-cell *matCellDef="let hedge"  fxFlex.xs="50" fxFlex.gt-xs="20">{{ hedge.creationTimestamp | date: 'M/d/yyyy h:mm:ss a' }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="10">Type</mat-header-cell>
        <mat-cell *matCellDef="let hedge" fxFlex.gt-xs="10"> {{ hedge.type | hedgeType }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="commodityProfileName">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="50" fxFlex.gt-xs="14">Profile</mat-header-cell>
        <mat-cell *matCellDef="let hedge"  fxFlex.xs="50" fxFlex.gt-xs="14">{{ hedge.commodityProfileName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="productionYearLabel">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="20">Production Year</mat-header-cell>
        <mat-cell *matCellDef="let hedge" fxFlex.gt-xs="20">{{ hedge.productionYearLabel | productionYear }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="futuresYearMonth">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="10">Contract</mat-header-cell>
        <mat-cell *matCellDef="let hedge" fxFlex.gt-xs="10">{{ hedge.futuresYearMonth | codeToMonth }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="side">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="8">Side</mat-header-cell>
        <mat-cell *matCellDef="let hedge" fxFlex.gt-xs="8">{{ hedge.side | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="quantityInContracts">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="9">Qty</mat-header-cell>
        <mat-cell *matCellDef="let hedge" fxFlex.gt-xs="9">{{ hedge.quantityInContracts }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="futuresPrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="9">Fill</mat-header-cell>
        <mat-cell *matCellDef="let hedge" fxFlex.gt-xs="9">
          <ng-container *ngIf="hedge.isSplitFilled">{{ hedge.futuresPrice | number:'1.4-4' }}*</ng-container>
          <ng-container *ngIf="!hedge.isSplitFilled">{{ hedge.futuresPrice | number:'1.4-4' }}</ng-container>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row (click)="selectHedge(hedge)" *matRowDef="let hedge; columns: columnsToDisplay"></mat-row>
    </mat-table>
    <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50]" (page)="handlePageChange()"></mat-paginator>
  </div>
</div>
