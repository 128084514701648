import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay, switchMap, tap } from 'rxjs/operators';

import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommodityProfileService, OperationsDataService, UserService } from '@advance-trading/angular-ops-data';
import {
  AdHocOrder,
  AdHocOrderLeg,
  Client,
  CommodityProfile,
  CommodityMap,
  ContractMonth,
  HMSClientSettings,
  OrderType,
  SecurityType,
  Side,
  TimeInForce,
  User
} from '@advance-trading/ops-data-lib';

import { AdHocOrderService } from '../../service/ad-hoc-order.service';
import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { UserRoles } from '../../utilities/user-roles';

import { FirstFuturesMonthErrorMatcher, FuturesMonthErrorMatcher, FuturesValidators, SecondFuturesMonthErrorMatcher } from '../../utilities/validators/futures.validator';

// formats
const DATE_FORMAT = 'YYYY-MM-DD';
const PRICE_REGEX = /^-?(\d+|\d*\.\d{1,4}|\d+\.\d{0,4})$/;
const CONTRACTS_REGEX = /^(?=.*[1-9])\d*$/;

@Component({
  selector: 'hms-new-ad-hoc-order',
  templateUrl: './new-ad-hoc-order.component.html',
  styleUrls: ['./new-ad-hoc-order.component.scss']
})
export class NewAdHocOrderComponent implements OnInit {
  @ViewChild('contractYearMonthPicker', {static: false}) contractYearMonthRef;
  @ViewChild('firstFuturesYearMonthPicker', {static: false}) firstFuturesYearMonthPickerRef;
  @ViewChild('secondFuturesYearMonthPicker', {static: false}) secondFuturesYearMonthPickerRef;
  @ViewChild('BuySellToggle', {static: false, read: ElementRef}) BuySellToggle;
  @ViewChild('contractYearMonthPickerInput', {static: false}) contractYearMonthPickerInput: ElementRef;
  @ViewChild('contractYearMonthPickerToggle', {static: false, read: ElementRef}) contractYearMonthPickerToggle: ElementRef;
  @ViewChild('expirationDatePickerToggle', {static: false, read: ElementRef}) expirationDatePickerToggle: ElementRef;
  @ViewChild('firstFuturesYearMonthPickerToggle', {static: false, read: ElementRef}) firstFuturesYearMonthPickerToggle: ElementRef;
  @ViewChild('secondFuturesYearMonthPickerToggle', {static: false, read: ElementRef}) secondFuturesYearMonthPickerToggle: ElementRef;

  adHocOrderForm: FormGroup = this.formBuilder.group({
    side: ['', [Validators.required]],
    securityType: ['', Validators.required],
    orderType: ['', Validators.required],
    commodityProfile: ['', [Validators.required]],
    contract: ['', [Validators.required, Validators.maxLength(3), Validators.pattern(CONTRACTS_REGEX)]],
    expirationDate: [''],
    price: ['', [Validators.required, Validators.pattern(PRICE_REGEX)]],
    futuresYearMonth: ['', [Validators.required]],
    firstFuturesYearMonth: ['', [Validators.required]],
    firstCommodity: ['', [Validators.required]],
    secondFuturesYearMonth: ['', [Validators.required]],
    secondCommodity: ['', [Validators.required]],
    comments: ['', Validators.maxLength(400)]
  });

  updateComplete = true;
  errorMessage: string;

  // form data
  sides = Object.keys(Side);
  commodityProfiles$: Observable<CommodityProfile[]>;
  commodities$: Observable<string[]>;
  commodityMap$: Observable<CommodityMap>;

  // TODO: Update when supporting other security type
  securityTypes = [SecurityType.FUTURE, SecurityType.FUTURE_SPREAD];

  // TODO: Update when allowing other order type
  orderTypes = [OrderType.MARKET, OrderType.LIMIT];

  // error state matcher to check contractYearMonth valid state
  futuresMonthErrorMatcher = new FuturesMonthErrorMatcher();
  firstFuturesMonthErrorMatcher = new FirstFuturesMonthErrorMatcher();
  secondFuturesMonthErrorMatcher = new SecondFuturesMonthErrorMatcher();

  // don't allow futures month in the past. Futures datepickers default to first of month.
  minDate = moment().startOf('month');
  currentDate = moment().format();

  private selectedClientDocId: string;
  private loggedInUser: User;
  private contractMonths = Object.keys(ContractMonth);

  // valid contract months for mat-datepicker filter
  private validContractMonths: number[];

  // commodities document futures months
  private futuresMonths: { [key: string]: number[] } = {};

  constructor(
    private adHocOrderService: AdHocOrderService,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private formBuilder: FormBuilder,
    private operationsDataService: OperationsDataService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.AD_HOC_ORDER_ADMIN_ROLE)) {
      this.errorMessage = 'You do not have permission to create ad hoc orders.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.commodityMap$ = this.operationsDataService.getCommodityMap().pipe(
      tap((doc: CommodityMap) => {
        // get futures months from commodities document in firestore
        Object.keys(doc.commodities).forEach((commodityId: string) => {
          this.futuresMonths[commodityId] = doc.commodities[commodityId].contractMonths;
        });

        // set ad hoc order form group validator
        this.adHocOrderForm.setValidators([
          FuturesValidators.futurePeriodValidator(this.futuresMonths),
          FuturesValidators.firstFuturePeriodValidator(this.futuresMonths),
          FuturesValidators.secondFuturePeriodValidator(this.futuresMonths)
        ]);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving commodity map; please try again later';
        console.error(`Error retrieving commodity map: ${err}`);
        return of(undefined);
      })
    );

    this.commodities$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((selectedClient: Client) => {
        return this.clientSettingsService.getHmsSettingsByClientDocId(selectedClient.docId);
      }),
      switchMap((clientSettings: HMSClientSettings) => {
        return of(clientSettings.commodities);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving commodities; please try again later';
        console.error(`Error retrieving commodities: ${err}`);
        return of([]);
      })
    );

    // listen to order type to handle price required field for limit ad hoc order
    this.setupOrderTypeListener();

    // listen to security type to handle form validity before submitting
    this.setupSecurityTypeListener();

  }

  submit() {
    const securityType = this.adHocOrderForm.get('securityType').value;
    // Create a new hedge object to store in the database
    const newAdHocOrder = new AdHocOrder();

    newAdHocOrder.side = this.adHocOrderForm.get('side').value;
    const commodityProfile = this.adHocOrderForm.get('commodityProfile').value;
    newAdHocOrder.commodityProfileDocId = commodityProfile.docId;
    newAdHocOrder.quantity = parseInt(this.adHocOrderForm.get('contract').value, 10);
    newAdHocOrder.type = this.adHocOrderForm.get('orderType').value;
    newAdHocOrder.securityType = securityType;

    // Populate optional ad hoc order limit's fields
    // TimeInForce defaults to GTC
    if (newAdHocOrder.type === OrderType.LIMIT) {
      if (this.adHocOrderForm.get('expirationDate').value) {
        newAdHocOrder.expirationDate = moment(this.adHocOrderForm.get('expirationDate').value).format(DATE_FORMAT);
        newAdHocOrder.timeInForce = TimeInForce.GTD;
      }
      if (this.adHocOrderForm.get('price').value) {
        newAdHocOrder.price = parseFloat(this.adHocOrderForm.get('price').value);
      }
    } else {
      newAdHocOrder.timeInForce = TimeInForce.DAY;
    }

    // Handle future ad hoc order
    if (securityType === SecurityType.FUTURE) {
      // get contract period
      const contractYear = moment(this.adHocOrderForm.get('futuresYearMonth').value).format('YY');
      const contractMonth = this.contractMonths[moment(this.adHocOrderForm.get('futuresYearMonth').value).get('month')];
      newAdHocOrder.contractYearMonth = `${contractYear}${contractMonth}`;

      // Handle spread ad hoc order
    } else {
      const firstOrderLeg = {} as AdHocOrderLeg;
      firstOrderLeg.commodityId = this.adHocOrderForm.get('firstCommodity').value;
      const firstContractYear = moment(this.adHocOrderForm.get('firstFuturesYearMonth').value).format('YY');
      const firstContractMonth = this.contractMonths[moment(this.adHocOrderForm.get('firstFuturesYearMonth').value).get('month')];
      firstOrderLeg.contractYearMonth = `${firstContractYear}${firstContractMonth}`;
      firstOrderLeg.side = this.adHocOrderForm.get('side').value;
      // Default value for order leg
      firstOrderLeg.securityType = SecurityType.FUTURE;
      firstOrderLeg.ratioQuantity = 1;

      const secondOrderLeg = {} as AdHocOrderLeg;
      secondOrderLeg.commodityId = this.adHocOrderForm.get('secondCommodity').value;
      const secondContractYear = moment(this.adHocOrderForm.get('secondFuturesYearMonth').value).format('YY');
      const secondContractMonth = this.contractMonths[moment(this.adHocOrderForm.get('secondFuturesYearMonth').value).get('month')];
      secondOrderLeg.contractYearMonth = `${secondContractYear}${secondContractMonth}`;
      secondOrderLeg.side = this.adHocOrderForm.get('side').value === Side.BUY ? Side.SELL : Side.BUY;
      // Default value for order leg
      secondOrderLeg.securityType = SecurityType.FUTURE;
      secondOrderLeg.ratioQuantity = 1;

      newAdHocOrder.legs = [firstOrderLeg, secondOrderLeg];
    }

    newAdHocOrder.creatorDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    const userName = `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`;
    newAdHocOrder.creatorName = userName;
    newAdHocOrder.lastUpdatedByName = userName;

    const comments = this.adHocOrderForm.get('comments').value;
    if (comments) {
      newAdHocOrder.comments = comments;
    }

    // Create new adhoc order document by calling adhoc service
    this.updateComplete = false;

    this.adHocOrderService.createAdHocOrder(this.selectedClientDocId, newAdHocOrder)
      .then(() => {
        this.updateComplete = true;
        console.log('Ad hoc order successfully created');
        this.openSnackBar('Ad hoc order successfully created', 'DISMISS', true);
        this.router.navigate(['../'], { relativeTo: this.route, replaceUrl: true });
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`Ad hoc order creation failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Ad hoc order creation failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  selectContractYearMonth(e: moment.Moment, formControlName: string) {

    this.adHocOrderForm.markAsDirty();

    if (formControlName === 'futuresYearMonth') {
      this.adHocOrderForm.get('futuresYearMonth').setValue(e);
      this.contractYearMonthRef.close();
    } else if (formControlName === 'firstFuturesYearMonth') {
      this.adHocOrderForm.get('firstFuturesYearMonth').setValue(e);
      this.firstFuturesYearMonthPickerRef.close();
    } else if (formControlName === 'secondFuturesYearMonth') {
      this.adHocOrderForm.get('secondFuturesYearMonth').setValue(e);
      this.secondFuturesYearMonthPickerRef.close();
    }

  }

  onCloseDatePicker(datePickerToggleId) {
    let toggleButton = null;
    // mat-date-picker has no native focusable element,
    // get the first available focusable element which is the toggle button
    if (datePickerToggleId === 'contractYearMonthPickerToggle') {
      toggleButton = this.contractYearMonthPickerToggle.nativeElement?.firstChild;
    } else if (datePickerToggleId === 'expirationDatePickerToggle') {
      toggleButton = this.expirationDatePickerToggle.nativeElement?.firstChild;
    } else if (datePickerToggleId === 'firstFuturesYearMonthPickerToggle') {
      toggleButton = this.firstFuturesYearMonthPickerToggle.nativeElement?.firstChild;
    } else if (datePickerToggleId === 'secondFuturesYearMonthPickerToggle') {
      toggleButton = this.secondFuturesYearMonthPickerToggle.nativeElement?.firstChild;
    }
    toggleButton?.focus?.();
  }

  reset() {
    this.adHocOrderForm.reset();
    this.adHocOrderForm.markAsPristine();
  }

  compareCommodityProfile(cp1: CommodityProfile, cp2: CommodityProfile) {
    return cp1 && cp2 && cp1.docId === cp2.docId;
  }

  validContractMonthFilter = (currentMoment: moment.Moment | null): boolean => {
    if (currentMoment) {
      const month = currentMoment.month();
      const commodityProfile = this.adHocOrderForm.get('commodityProfile').value as CommodityProfile;

      if (commodityProfile) {
        // get valid contract month for the current commodity profile
        this.validContractMonths = this.futuresMonths[commodityProfile.commodityId];
        return this.validContractMonths.includes(month);
      }

      // return true for any month above or the same as current month
      return true;
    } else {
      return true;
    }
  }

  validFirstContractMonthFilter = (currentMoment: moment.Moment | null): boolean => {
    if (currentMoment) {
      const month = currentMoment.month();
      const commodity = this.adHocOrderForm.get('firstCommodity').value;

      if (commodity) {
        // get valid contract month for the current commodity
        this.validContractMonths = this.futuresMonths[commodity];
        return this.validContractMonths.includes(month);
      }

      // return true for any month above or the same as current month
      return true;
    } else {
      return true;
    }

  }

  validSecondContractMonthFilter = (currentMoment: moment.Moment | null): boolean => {
    if (currentMoment) {
      const month = currentMoment.month();
      const commodity = this.adHocOrderForm.get('secondCommodity').value;

      if (commodity) {
        // get valid contract month for the current commodity
        this.validContractMonths = this.futuresMonths[commodity];
        return this.validContractMonths.includes(month);
      }

      // return true for any month above or the same as current month
      return true;
    } else {
      return true;
    }
  }

  // don't allow weekend days to be selected for expiration date
  expDateFilter = (currentDate: Date): boolean => {
    const dayOfTheWeek = moment(currentDate).weekday();
    return dayOfTheWeek !== 0 && dayOfTheWeek !== 6;
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value Required';
    } else if (control.hasError('maxlength')) {
      return 'Value cannot exceed ' + control.errors['maxlength'].requiredLength + ' characters';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    } else if (control.hasError('invalidFuturesMonth')) {
      return 'Value invalid for profile';
    } else if (control.hasError('invalidFirstFuturesMonth')) {
      return 'Value must precede second contract month';
    } else if (control.hasError('invalidSecondFuturesMonth')) {
      return 'Value must exceed first contract month';
    } else if (control.hasError('invalidCommodityFirstFuturesMonth')
      || control.hasError('invalidCommoditySecondFuturesMonth')) {
      return 'Value invalid for commodity';
    } else if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('pattern')) {
      return 'Value Invalid';
    }
    return 'Unknown Error';
  }

  private setupOrderTypeListener() {
    this.adHocOrderForm.controls['orderType'].valueChanges.subscribe(() => {
      if (this.adHocOrderForm.get('orderType').value === OrderType.LIMIT) {
        this.adHocOrderForm.get('price').enable();
      } else {
        this.adHocOrderForm.get('price').disable();
      }
    });
  }

  private setupSecurityTypeListener() {
    this.adHocOrderForm.controls['securityType'].valueChanges.subscribe(() => {

      // clear commodity profile (since the profile list now changes)
      this.adHocOrderForm.get('commodityProfile').reset();
      this.adHocOrderForm.get('commodityProfile').markAsPristine();

      // setup future ad hoc order form
      if (this.adHocOrderForm.get('securityType').value === SecurityType.FUTURE) {
        this.adHocOrderForm.get('firstFuturesYearMonth').disable();

        this.adHocOrderForm.get('firstCommodity').disable();

        this.adHocOrderForm.get('secondFuturesYearMonth').disable();

        this.adHocOrderForm.get('secondCommodity').disable();

        this.adHocOrderForm.get('futuresYearMonth').enable();

        // Only change commodity profiles list when security type changes
        this.commodityProfiles$ = this.clientSelectorService.getSelectedClient().pipe(
          switchMap((selectedClient: Client) => {
            this.selectedClientDocId = selectedClient.docId;
            return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId);
          }),
          switchMap((user: User) => {
            this.loggedInUser = user;
            return this.commodityProfileService.getActiveCommodityProfilesByTypeAndClientDocId(this.selectedClientDocId);
          }),
          shareReplay({ bufferSize: 1, refCount: true }),
          catchError(err => {
            this.errorMessage = 'Error retrieving commodity profiles; please try again later';
            console.error(`Error retrieving commodity profiles: ${err}`);
            return of([]);
          })
        );
        // setup spread ad hoc order form
      } else {
        this.adHocOrderForm.get('futuresYearMonth').disable();

        this.adHocOrderForm.get('firstFuturesYearMonth').enable();

        this.adHocOrderForm.get('firstCommodity').enable();

        this.adHocOrderForm.get('secondFuturesYearMonth').enable();

        this.adHocOrderForm.get('secondCommodity').enable();

        // Only change commodity profiles list when security type changes
        this.commodityProfiles$ = this.clientSelectorService.getSelectedClient().pipe(
          switchMap((selectedClient: Client) => {
            this.selectedClientDocId = selectedClient.docId;
            return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId);
          }),
          switchMap((user: User) => {
            this.loggedInUser = user;
            return this.commodityProfileService.getActiveCommodityProfilesByTypeAndClientDocId(this.selectedClientDocId, true);
          }),
          shareReplay({ bufferSize: 1, refCount: true }),
          catchError(err => {
            this.errorMessage = 'Error retrieving commodity profiles; please try again later';
            console.error(`Error retrieving commodity profiles: ${err}`);
            return of([]);
          })
        );
      }
    });
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}
