<hms-error-message [title]="'Error Retrieving Commodity Quotes'" [message]="errorMessage"></hms-error-message>

<div *ngIf="!errorMessage" [ngClass]="mode === FULL ? 'table-container' : 'table-container-side'">
  <ng-container *ngIf="marketData$ | async as marketData">
    <table mat-table [dataSource]="marketDataSource" class="mat-elevation-z0">
      <ng-container matColumnDef="contractYearMonth">
        <th mat-header-cell *matHeaderCellDef>Contract</th>
        <td mat-cell *matCellDef="let marketData; let i = index" >
          <div  fxLayout="row" fxLayoutAlign="space-between center">
            <div *ngIf="marketData.contractSecondaryYearMonth">
                {{ marketData.contractYearMonth | codeToMonth }}/{{ marketData.contractSecondaryYearMonth | codeToMonth }}
            </div>
            <div *ngIf="!marketData.contractSecondaryYearMonth">
                {{ marketData.contractYearMonth | codeToMonth }}
            </div>
            <div *ngIf="userDataFrequency === 'ON_DEMAND'" (click)="$event.stopPropagation()">
              <button id="refresh-quotes" mat-button matSuffix mat-icon-button aria-label="refresh" (click)="refresh(i)" #dataTooltip="matTooltip"
                      [matTooltip]="lastIdxRefreshed === i ? tooltipMessage : ''" matTooltipClass="market-data-tooltip">
                <mat-icon>refresh</mat-icon>
              </button>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="last">
        <th mat-header-cell *matHeaderCellDef>Last</th>
        <td mat-cell *matCellDef="let marketData">
          <div>
            {{ isValidNumber(marketData.last) ? (marketData.last | number:'1.4-4') : '-' }}
          </div>
          <div class="mat-small date-time" *ngIf="marketData.tradeDateTime">
            {{ marketData.tradeDateTime | date:'M/d/yy, H:mm' }}
          </div>
          <div class="mat-small date-time" *ngIf="!marketData.tradeDateTime" style="visibility: hidden;">
            &nbsp;
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="change">
        <th mat-header-cell *matHeaderCellDef>Change</th>
        <td mat-cell *matCellDef="let marketData">
          <div [ngClass]="getChangeClass(marketData.change)">
            {{ isValidNumber(marketData.change) ? (marketData.change | number:'1.4-4') : '-' }}
          </div>
          <div class="mat-small" style="visibility: hidden">
            &nbsp;
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="low">
        <th mat-header-cell *matHeaderCellDef>Low</th>
        <td mat-cell *matCellDef="let marketData">
          <div>
            {{ isValidNumber(marketData.low) ? (marketData.low | number:'1.4-4') : '-' }}
          </div>
          <div class="mat-small" style="visibility: hidden">
            &nbsp;
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="high">
        <th mat-header-cell *matHeaderCellDef>High</th>
        <td mat-cell *matCellDef="let marketData">
          <div>
            {{ isValidNumber(marketData.high) ? (marketData.high | number:'1.4-4') : '-' }}
          </div>
          <div class="mat-small" style="visibility: hidden">
            &nbsp;
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="bid">
        <th mat-header-cell *matHeaderCellDef>Bid</th>
        <td mat-cell *matCellDef="let marketData">
          <div>
            {{ isValidNumber(marketData.bid) ? (marketData.bid | number:'1.4-4') : '-' }}
          </div>
          <div class="mat-small date-time" *ngIf="marketData.bidDateTime">
            {{ marketData.bidDateTime | date:'M/d/yy, H:mm' }}
          </div>
          <div class="mat-small date-time" *ngIf="!marketData.bidDateTime" style="visibility: hidden;">
            &nbsp;
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="ask">
        <th mat-header-cell *matHeaderCellDef>Ask</th>
        <td mat-cell *matCellDef="let marketData">
          <div>
            {{ isValidNumber(marketData.ask) ? (marketData.ask | number:'1.4-4') : '-' }}
          </div>
          <div class="mat-small date-time" *ngIf="marketData.askDateTime">
            {{ marketData.askDateTime | date:'M/d/yy, H:mm' }}
          </div>
          <div class="mat-small date-time" *ngIf="!marketData.askDateTime" style="visibility: hidden;">
            &nbsp;
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="open">
        <th mat-header-cell *matHeaderCellDef>Open</th>
        <td mat-cell *matCellDef="let marketData">
          <div>
            {{ isValidNumber(marketData.open) ? (marketData.open | number:'1.4-4') : '-' }}
          </div>
          <div class="mat-small" style="visibility: hidden">
            &nbsp;
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="settlementPrice">
        <th mat-header-cell *matHeaderCellDef>Settle</th>
        <td mat-cell *matCellDef="let marketData">
          <div>
            {{ isValidNumber(marketData.settlementPrice) ? (marketData.settlementPrice | number:'1.4-4') : '-' }}
          </div>
          <div class="mat-small date-time" *ngIf="marketData.settleDateTime">
            {{ marketData.settleDateTime | date:'M/d/yy, H:mm' }}
          </div>
          <div class="mat-small date-time" *ngIf="!marketData.settleDateTime" style="visibility: hidden;">
            &nbsp;
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
      <tr (click)="selectRow(i)" mat-row *matRowDef="let row; columns: displayedColumn; let i = index" [ngClass]="(authService?.userProfile && canCreateContract()) ? 'contract-creator-row' : ''" attr.data-cy="quotes-{{commodityId}}-{{i}}"></tr>
    </table>
  </ng-container>
</div>
<div *ngIf="!errorMessage && mode === SIDE" class="mat-small">
  <h4>Disclaimer:</h4>
  <ul style="padding-left: 15px;">
    <li *ngIf="userDataFrequency !== 'REALTIME'">Market data is delayed by at least 10 minutes</li>
    <li *ngIf="userDataFrequency === 'REALTIME'">Market data is delayed up to 10 minutes</li>
    <li *ngIf="userDataFrequency !== 'NONE'">Market data last updated {{ timestamp }}</li>
    <li *ngIf="userDataFrequency === 'NONE'">Market data last updated {{ timestamp }} and is not being refreshed per your user settings</li>
    <li>Trade times displayed in CT timezone</li>
  </ul>
</div>
