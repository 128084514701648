<div fxFlex="100">

  <div *ngIf="!errorMessage" fxLayout="column">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="contracts-filter-field" #filter>
      <button type="button" id="clear-contracts-filter" mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <button *ngIf="exportable" aria-label="Export" id="export-contracts" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
    <mat-menu #exportMenu="matMenu">
      <button type="button" aria-label="Export Excel" id="export-contracts-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Contracts'))">Excel</button>
      <button type="button" aria-label="Export CSV" id="export-contracts-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Contracts'))">CSV</button>
      <button type="button" aria-label="Export JSON" id="export-contracts-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Contracts'))">JSON</button>
    </mat-menu>

    <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort class="mat-elevation-z0" (matSortChange)="handleSortChange()">

      <ng-container matColumnDef="accountingSystemId">
        <mat-header-cell *matHeaderCellDef mat-sort-header [style.flex]="getFlexLayout('accountingSystemId')">ID</mat-header-cell>
        <mat-cell *matCellDef="let contract" [style.flex]="getFlexLayout('accountingSystemId')">{{ contract.accountingSystemId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="creationTimestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header [style.flex]="getFlexLayout('creationTimestamp')">Created</mat-header-cell>
        <mat-cell *matCellDef="let contract" [style.flex]="getFlexLayout('creationTimestamp')"><span class="forced-wrap">{{ contract.creationTimestamp | date: 'MM/dd/yyyy hh:mm:ss a' }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastUpdatedTimestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header [style.flex]="getFlexLayout('lastUpdatedTimestamp')">Updated</mat-header-cell>
        <mat-cell *matCellDef="let contract" [style.flex]="getFlexLayout('lastUpdatedTimestamp')"><span class="forced-wrap">{{ contract.lastUpdatedTimestamp | date: 'MM/dd/yyyy hh:mm:ss a' }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="commodityId">
        <mat-header-cell *matHeaderCellDef mat-sort-header [style.flex]="getFlexLayout('commodityId')">Cmdty</mat-header-cell>
        <mat-cell *matCellDef="let contract" [style.flex]="getFlexLayout('commodityId')">{{ contract.commodityId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="deliveryPeriod">
        <mat-header-cell *matHeaderCellDef mat-sort-header [style.flex]="getFlexLayout('deliveryPeriod')">Delivery</mat-header-cell>
        <mat-cell *matCellDef="let contract" [style.flex]="getFlexLayout('deliveryPeriod')">{{ contract.deliveryPeriod }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="futuresYearMonth">
        <mat-header-cell *matHeaderCellDef mat-sort-header [style.flex]="getFlexLayout('futuresYearMonth')">Futures</mat-header-cell>
        <mat-cell *matCellDef="let contract" [style.flex]="getFlexLayout('futuresYearMonth')">{{ contract.futuresYearMonth }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="originatorName">
        <mat-header-cell *matHeaderCellDef mat-sort-header [style.flex]="getFlexLayout('originatorName')">Originator</mat-header-cell>
        <mat-cell *matCellDef="let contract" [style.flex]="getFlexLayout('originatorName')">{{ contract.originatorName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="clientLocationName">
        <mat-header-cell *matHeaderCellDef mat-sort-header [style.flex]="getFlexLayout('clientLocationName')">Location</mat-header-cell>
        <mat-cell *matCellDef="let contract" [style.flex]="getFlexLayout('clientLocationName')">{{ contract.clientLocationName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="patronName">
        <mat-header-cell *matHeaderCellDef mat-sort-header [style.flex]="getFlexLayout('patronName')">Patron</mat-header-cell>
        <mat-cell *matCellDef="let contract" [style.flex]="getFlexLayout('patronName')">
          <div *ngIf="contract.patronAccountingSystemId; else noPatronAccountingSystemId">
            <div class="truncate-overflow-2">
              {{ contract.patronName }}
            </div>
            <div>
              ({{ contract.patronAccountingSystemId }})
            </div>
          </div>
          <ng-template #noAccountingSystemId>
            <div class="truncate-overflow-3">
              {{ contract.patronName }}
            </div>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header [style.flex]="getFlexLayout('type')">Type</mat-header-cell>
        <mat-cell *matCellDef="let contract" [style.flex]="getFlexLayout('type')">{{ contract.type === 'HTA' || contract.type === 'DP' ? contract.type : contract.type | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header [style.flex]="getFlexLayout('status')">Status</mat-header-cell>
        <mat-cell *matCellDef="let contract" [style.flex]="getFlexLayout('status')">{{ contract.status | replace | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="pricingType">
        <mat-header-cell *matHeaderCellDef mat-sort-header [style.flex]="getFlexLayout('pricingType')">Pricing</mat-header-cell>
        <mat-cell *matCellDef="let contract" [style.flex]="getFlexLayout('pricingType')">{{ contract.pricingType | pricingType | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="side">
        <mat-header-cell *matHeaderCellDef mat-sort-header [style.flex]="getFlexLayout('side')">Side</mat-header-cell>
        <mat-cell *matCellDef="let contract" [style.flex]="getFlexLayout('side')">{{ contract.side | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header [style.flex]="getFlexLayout('quantity')">Qty</mat-header-cell>
        <mat-cell *matCellDef="let contract" [style.flex]="getFlexLayout('quantity')">{{ contract.quantity | number:'1.0-2' }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="comments">
        <ng-container *ngIf="displayCommentsText; then commentsText; else commentsIcon"></ng-container>
        <!-- comment icon template -->
        <ng-template #commentsIcon>
          <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('comments')">Comments</mat-header-cell>
          <mat-cell *matCellDef="let contract; let i = index" [style.flex]="getFlexLayout('comments')">
            <button *ngIf="contract.comments" id="open-activity-log-comment" mat-button matSuffix mat-icon-button
              aria-label="Comments" (mouseenter)="commentTooltip.show()" (mouseleave)="commentTooltip.hide()"
              (click)="commentTooltip.show(); $event.stopPropagation();" #commentTooltip="matTooltip"
              [matTooltip]="contract.comments" matTooltipClass="comment-tooltip">
              <mat-icon svgIcon="comment"></mat-icon>
              <span class="cdk-visually-hidden">{{ contract.comments }}</span>
            </button>
          </mat-cell>
        </ng-template>
        <!-- comment text template -->
        <ng-template #commentsText>
          <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('comments')">Comments</mat-header-cell>
          <mat-cell *matCellDef="let contract; let i = index" [style.flex]="getFlexLayout('comments')">
            <p class="comments-text-inline">{{ contract.comments }}</p>
          </mat-cell>
        </ng-template>

      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row (click)="selectContract(contract)" *matRowDef="let contract; columns: columnsToDisplay"></mat-row>
    </mat-table>
    <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50]" (page)="handlePageChange()"></mat-paginator>

  </div>

</div>
