import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn } from '@angular/forms';

import * as moment from 'moment';

import { CommodityProfile } from '@advance-trading/ops-data-lib';

export class FuturesMonthErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control.touched && control.invalid) || (form.enabled && form.hasError('invalidFuturesMonth'));
  }
}

export class FirstFuturesMonthErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control.touched && control.invalid) || (form.enabled && form.hasError('invalidFirstFuturesMonth')
            || (form.enabled && form.hasError('invalidCommodityFirstFuturesMonth')));
  }
}

export class SecondFuturesMonthErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control.touched && control.invalid) || (form.enabled && form.hasError('invalidSecondFuturesMonth')
            || (form.enabled && form.hasError('invalidCommoditySecondFuturesMonth')));
  }
}

export class FuturesValidators {

  /**
   * Ensures the futures date in form compatible with the commodity profile.
   * @param futuresMonths object containing valid futures months for each commodity
   * @returns a validator function that validates futuresYearMonth in the form group to be valid
   */
  static futurePeriodValidator(futuresMonths: {[key: string]: number[]}, fixedCommodityProfile?:CommodityProfile): ValidatorFn {
    return (formGroup: FormGroup): { [key: string]: boolean } | null => {
      if (!formGroup.get('futuresYearMonth').value || (formGroup.get('commodityProfile') && !formGroup.get('commodityProfile').value)
        || formGroup.get('futuresYearMonth').disabled) {
        return null;
      }
      const futuresMonth = moment(formGroup.get('futuresYearMonth').value).month();
      const commodityProfile: CommodityProfile = formGroup.get('commodityProfile') ? formGroup.get('commodityProfile').value : fixedCommodityProfile;
      let existingErrors = formGroup.get('futuresYearMonth').errors;
      if (existingErrors) {
        delete existingErrors.invalidFuturesMonth;
        const errorLength = Object.keys(existingErrors).length;
        existingErrors = errorLength === 0 ? null : existingErrors;
      }

      const error = futuresMonths[commodityProfile.commodityId].includes(futuresMonth)
          ? existingErrors
          : { invalidFuturesMonth: true };

      formGroup.get('futuresYearMonth').setErrors(error);
      return error;
    };
  }

  /**
   * Ensures the futures date in form compatible with the commodity profile and before the second futures month.
   * @param futuresMonths object containing valid futures months for each commodity
   * @returns a validator function that validates firstFuturesYearMonth in the form group to be valid
   */
  static firstFuturePeriodValidator(futuresMonths: {[key: string]: number[]}): ValidatorFn {
    return (formGroup: FormGroup): { [key: string]: boolean } | null => {
      if (!formGroup.get('firstFuturesYearMonth').value) {
        return null;
      }
      const firstFuturesMonth = moment(formGroup.get('firstFuturesYearMonth').value).month();
      const firstFuturesYear = moment(formGroup.get('firstFuturesYearMonth').value).year();

      let existingErrors = formGroup.get('firstFuturesYearMonth').errors;
      if (existingErrors) {
        delete existingErrors.invalidFirstFuturesMonth;
        delete existingErrors.invalidCommodityFirstFuturesMonth;
        const errorLength = Object.keys(existingErrors).length;
        existingErrors = errorLength === 0 ? null : existingErrors;
      }

      // check if the first futures month is before the second futures month
      if (formGroup.get('secondFuturesYearMonth').value) {
        const secondFuturesMonth = moment(formGroup.get('secondFuturesYearMonth').value).month();
        const secondFuturesYear = moment(formGroup.get('secondFuturesYearMonth').value).year();

        if (secondFuturesYear < firstFuturesYear || (secondFuturesYear === firstFuturesYear && firstFuturesMonth >= secondFuturesMonth)) {
          const invalidFirstFuturesMonthError = { invalidFirstFuturesMonth: true };
          formGroup.get('firstFuturesYearMonth').setErrors(invalidFirstFuturesMonthError);
          return invalidFirstFuturesMonthError;
        }
      }

      if (!formGroup.get('firstCommodity').value) {
        return null;
      }

      const error = futuresMonths[formGroup.get('firstCommodity').value]
                    .includes(firstFuturesMonth) ? existingErrors : { invalidCommodityFirstFuturesMonth: true };

      formGroup.get('firstFuturesYearMonth').setErrors(error);
      return error;
    };
  }

  /**
   * Ensures the futures date form compatible with the commodity and after the first futures month.
   * @param futuresMonths object containing valid futures months for each commodity
   * @returns a validator function that validates secondFuturesYearMonth in the form group to be valid
   */
  static secondFuturePeriodValidator(futuresMonths: {[key: string]: number[]}): ValidatorFn {
    return (formGroup: FormGroup): { [key: string]: boolean } | null => {
      if (!formGroup.get('secondFuturesYearMonth').value) {
        return null;
      }
      const secondFuturesMonth = moment(formGroup.get('secondFuturesYearMonth').value).month();
      const secondFuturesYear = moment(formGroup.get('secondFuturesYearMonth').value).year();

      let existingErrors = formGroup.get('secondFuturesYearMonth').errors;
      if (existingErrors) {
        delete existingErrors.invalidSecondFuturesMonth;
        delete existingErrors.invalidCommoditySecondFuturesMonth;
        const errorLength = Object.keys(existingErrors).length;
        existingErrors = errorLength === 0 ? null : existingErrors;
      }


      // check if the first futures month is before the second futures month
      if (formGroup.get('firstFuturesYearMonth').value) {
        const firstFuturesMonth = moment(formGroup.get('firstFuturesYearMonth').value).month();
        const firstFuturesYear = moment(formGroup.get('firstFuturesYearMonth').value).year();

        if (secondFuturesYear < firstFuturesYear || (secondFuturesYear === firstFuturesYear && secondFuturesMonth <= firstFuturesMonth)) {
          const invalidSecondFuturesMonthError = { invalidSecondFuturesMonth: true };
          formGroup.get('secondFuturesYearMonth').setErrors(invalidSecondFuturesMonthError);
          return invalidSecondFuturesMonthError;
        }
      }

      if (!formGroup.get('secondCommodity').value) {
        return null;
      }

      const error = futuresMonths[formGroup.get('secondCommodity').value]
                    .includes(secondFuturesMonth) ? existingErrors : { invalidCommoditySecondFuturesMonth: true };
      formGroup.get('secondFuturesYearMonth').setErrors(error);
      return error;
    };
  }

}
