import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { Auth0AuthzService, AuthService } from '@advance-trading/angular-ati-security';
import { CommodityProfileService, MarketDataService, OperationsDataService } from '@advance-trading/angular-ops-data';
import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { Client, Commodity, Contract, CommodityProfile, PricingSegment, MarketData, CommodityMap, Side } from '@advance-trading/ops-data-lib';

import * as moment from 'moment';

import { MarketDataHelper } from 'src/app/utilities/market-data-helper';
import { UserRoles } from 'src/app/utilities/user-roles';

import { ExportService } from 'src/app/service/export.service';
import { ClientSelectorService } from 'src/app/service/client-selector.service';
import { ContractService } from 'src/app/service/contract.service';
import { PricingSegmentService } from 'src/app/service/pricing-segment.service';

import { ClosestToMarketDisplay } from './closest-to-market-display';

@Component({
  selector: 'hms-closest-to-market',
  templateUrl: './closest-to-market.component.html',
  styleUrls: ['./closest-to-market.component.scss']
})
export class ClosestToMarketComponent implements OnInit {
  reportDataSourceMap: { [key: string]: MatTableDataSource<ClosestToMarketDisplay> } = {};
  commodityProfiles$: Observable<CommodityProfile[]>;
  columnsToDisplay: string[];
  footerColumnsToDisplay = ['footer'];
  selectedCommodityProfileDocIds: string[] = [];

  errorMessage: string;
  isLoading = true;
  displayReports = false;

  closestToMarketSearchForm: FormGroup = this.formBuilder.group({
    commodityProfiles: [[]]
  });


  private commodities: { [key: string]: Commodity };
  private dataSource: ObservableDataSource<ClosestToMarketDisplay> = new ObservableDataSource<ClosestToMarketDisplay>();
  private latestUpdate = moment();
  private queryParams: Params;
  private reportCommodityProfiles: CommodityProfile[];
  private selectedClientDocId: string;
  private marketDataMap: { [key: string]: Observable<MarketData> };

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private breakpointObserver: BreakpointObserver,
    private clientSelectorService: ClientSelectorService,
    private commodityProfileService: CommodityProfileService,
    private contractService: ContractService,
    public exportService: ExportService,
    private formBuilder: FormBuilder,
    private marketDataService: MarketDataService,
    private operationsDataService: OperationsDataService,
    private pricingSegmentService: PricingSegmentService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.CONTRACT_REPORTS_GENERATOR_ROLE)) {
      this.errorMessage = 'You do not have permission to access the Closest to Market report.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(state => {
      // display columns for xsmall screen
      if (state.breakpoints[Breakpoints.XSmall]) {
        this.columnsToDisplay = [
          'priceDifference', 'marketPrice', 'workingPrice', 'futuresMonth', 'quantity'
        ];
        // display columns for larger screens
      } else if (state.breakpoints[Breakpoints.Small]) {
        this.columnsToDisplay = [
          'priceDifference', 'marketPrice', 'workingPrice', 'deliveryPeriod', 'futuresMonth', 'contractType', 'quantity', 'patronName'
        ];
      } else {
        this.columnsToDisplay = [
          'priceDifference', 'marketPrice', 'workingPrice', 'deliveryPeriod', 'futuresMonth', 'contractType', 'side', 'quantity', 'originatorName', 'patronName'
        ];
      }
    });

    this.commodityProfiles$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((selectedClient: Client) => {
        this.selectedClientDocId = selectedClient.docId;
        return this.operationsDataService.getCommodityMap();
      }),
      switchMap((commodityMap: CommodityMap) => {
        this.commodities = commodityMap.commodities;
        return this.commodityProfileService.getActiveCommodityProfilesByTypeAndClientDocId(this.selectedClientDocId);
      }),
      tap((commodityProfiles: CommodityProfile[]) => {
        this.reportCommodityProfiles = commodityProfiles;
        this.reportDataSourceMap = {};
        this.reportCommodityProfiles.map(commodityProfile => {
          this.reportDataSourceMap[commodityProfile.docId] = new MatTableDataSource<ClosestToMarketDisplay>();
          this.reportDataSourceMap[commodityProfile.docId].data = [];
        });
        this.isLoading = false;
        this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
          this.queryParams = Object.assign({}, params);
          if (this.queryParams.commodityProfile) {
            this.selectedCommodityProfileDocIds = this.queryParams.commodityProfile.split(',');
            this.closestToMarketSearchForm.get('commodityProfiles').setValue(
              this.reportCommodityProfiles.filter(commodityProfile => this.selectedCommodityProfileDocIds.includes(commodityProfile.docId))
            );
          }
          if (Object.keys(params).length) {
            this.closestToMarketSearchForm.markAsDirty();
            this.loadClosestToMarketReportData();
          } else {
            this.selectAllCommodityProfiles();
          }
        }));
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.isLoading = false;
        this.errorMessage = 'Error retrieving client commodity profiles; please try again later';
        console.error(`Error retrieving client commodity profiles: ${err}`);
        return of([]);
      })
    );

  }

  loadClosestToMarketReportData() {
    this.isLoading = true;
    this.displayReports = false;
    // Clear out queryParams in case search parameters change after a reload
    this.queryParams = {} as Params;

    // Fill in default (full) set of commodity profiles if none selected
    if (!this.currentSelectedProfiles.length) {
      this.selectAllCommodityProfiles();
    }
    this.selectedCommodityProfileDocIds = this.currentSelectedProfiles.map(profile => profile.docId);

    this.queryParams.commodityProfile = this.selectedCommodityProfileDocIds.join(',');

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });

    // only pass empty array if all are checked so that there are not a bunch of separate queries to
    // return the same as a single query without filter
    const searchProfiles = this.selectedCommodityProfileDocIds.length === this.reportCommodityProfiles.length ?
      [] : this.selectedCommodityProfileDocIds;

    // reinitialize marketDataMap so data is fresh for this report run
    this.marketDataMap = {};

    this.dataSource.data$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((selectedClient: Client) => {
        this.selectedClientDocId = selectedClient.docId;
        return combineLatest([
          this.loadContracts(this.selectedClientDocId, searchProfiles),
          this.loadPricingSegments(this.selectedClientDocId, searchProfiles)
        ]).pipe(
          map((arrayOfClosestToMarketDisplayArrays: ClosestToMarketDisplay[][]) => {
            this.isLoading = false;

            const reportableItems = arrayOfClosestToMarketDisplayArrays.flat()
              // remove rows for which market price could not be retrieved
              .filter(row => row !== undefined)
              // sort by price difference in absolute terms
              .sort((a, b) => Math.abs(a.priceDifference) > Math.abs(b.priceDifference) ? 1 : -1);

            this.reportCommodityProfiles.map(commodityProfile =>
              this.reportDataSourceMap[commodityProfile.docId].data = reportableItems
                .filter(item => item.commodityProfileDocId === commodityProfile.docId)
                // only return top 15 results per commodity profile
                .slice(0, 15)
            );
            this.displayReports = true;
            return reportableItems;
          }));
      }),
      catchError(err => {
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar('Error running report: ' + errorMsg, 'DISMISS', false);
        console.error(`Error retrieving reportable items: ${err}`);
        this.isLoading = false;
        return of([]);

      })
    );
  }

  reset() {
    this.selectedCommodityProfileDocIds = [];
    this.closestToMarketSearchForm.get('commodityProfiles').setValue([]);
    this.closestToMarketSearchForm.markAsPristine();
    this.displayReports = false;
    this.queryParams = {};
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });

  }

  selectContract(contract: ClosestToMarketDisplay) {
    this.router.navigate(['/contracts', contract.contractDocId]);
  }

  get currentSelectedProfiles() {
    return this.closestToMarketSearchForm.get('commodityProfiles').value;
  }

  get currentFirstProfile() {
    return this.currentSelectedProfiles.length ? this.currentSelectedProfiles[0].name : '';
  }

  get sortedReportCommodityProfiles() {
    return this.reportCommodityProfiles.sort(this.sortByAscAccountNumberAndProfileName);
  }

  get selectCommodityProfileOptions() {
    return this.reportCommodityProfiles.sort(this.sortByAscProfileNameAndDocId);
  }

  get timestamp() {
    return this.latestUpdate.format('M/D/YYYY, h:mm:ss a');
  }


  private sortByAscAccountNumberAndProfileName(a: CommodityProfile, b: CommodityProfile): number {
    return (a.officeCode + a.accountNumber + a.name).localeCompare(b.officeCode + b.accountNumber + b.name);
  }

  private sortByAscProfileNameAndDocId(a: CommodityProfile, b: CommodityProfile): number {
    return (a.name + b.docId).localeCompare(b.name + b.docId);
  }

  private getWorkingPrice(contractOrPricingSegment: Contract | PricingSegment) {
    return contractOrPricingSegment.futuresPrice || 0;
  }

  private getMarketDivisor(symbol: string) {
    return this.commodities[symbol].marketDataDivisor;
  }

  private getMarketPrice(marketData: MarketData, side: Side) {
    this.latestUpdate = moment();
    return parseFloat(((side === Side.BUY ? marketData.bid : marketData.ask) / this.getMarketDivisor(marketData.commodity)).toFixed(4));
  }

  private loadContracts(selectedClientDocId: string, commodityProfileDocIds: string[]): Observable<ClosestToMarketDisplay[]> {
    return this.contractService.getContractsForClosestToMarketReport(selectedClientDocId, commodityProfileDocIds).pipe(
      switchMap((contractItems: Contract[]) => {
        if (contractItems.length === 0) {
          return of([]);
        }
        return combineLatest(
          contractItems.map(contractItem => {
            return this.loadMarketData(MarketDataHelper.getMarketDataDocId(contractItem)).pipe(
              map((marketData: MarketData) => {
                const workingPrice = this.getWorkingPrice(contractItem);
                const marketPrice = this.getMarketPrice(marketData, contractItem.side);
                return {
                  priceDifference: marketPrice - workingPrice,
                  deliveryPeriod: contractItem.deliveryPeriod,
                  futuresMonth: contractItem.futuresYearMonth,
                  originatorName: contractItem.originatorName,
                  patronName: contractItem.patronName,
                  contractType: contractItem.type,
                  side: contractItem.side,
                  quantity: contractItem.quantity,
                  workingPrice,
                  marketPrice,
                  contractDocId: contractItem.docId,
                  commodityProfileDocId: contractItem.commodityProfileDocId
                } as ClosestToMarketDisplay;
              }),
              shareReplay({ bufferSize: 1, refCount: true }),
              catchError(err => {
                console.error(`Error retrieving contract details: ${err.message ? err.message : err}`);
                return of(undefined);
              })
            );
          })
        );
      })
    );
  }

  private loadPricingSegments(selectedClientDocId: string, commodityProfileDocIds: string[]): Observable<ClosestToMarketDisplay[]> {
    return this.pricingSegmentService.findPricingSegmentsForClosestToMarketReport(selectedClientDocId, commodityProfileDocIds).pipe(
      switchMap((pricingSegments: PricingSegment[]) => {
        if (pricingSegments.length === 0) {
          return of([]);
        }
        return combineLatest(
          pricingSegments.map(segmentItem => {
            const workingPrice = this.getWorkingPrice(segmentItem);
            let contractItem;
            return this.contractService.getContractByDocId(selectedClientDocId, segmentItem.contractDocId).pipe(
              switchMap((contract: Contract) => {
                contractItem = contract;
                return this.loadMarketData(MarketDataHelper.getMarketDataDocId(segmentItem));
              }),
              map((marketData: MarketData) => {
                const marketPrice = this.getMarketPrice(marketData, segmentItem.side);
                return {
                  priceDifference: marketPrice - workingPrice,
                  deliveryPeriod: segmentItem.deliveryPeriod,
                  futuresMonth: segmentItem.futuresYearMonth,
                  originatorName: contractItem.originatorName,
                  patronName: contractItem.patronName,
                  contractType: contractItem.type,
                  side: segmentItem.side,
                  quantity: segmentItem.quantity,
                  workingPrice,
                  marketPrice,
                  contractDocId: segmentItem.contractDocId,
                  commodityProfileDocId: segmentItem.commodityProfileDocId
                } as ClosestToMarketDisplay;
              }),
              shareReplay({ bufferSize: 1, refCount: true }),
              catchError(err => {
                console.error(`Error retrieving pricing segment details: ${err.message ? err.message : err}`);
                return of(undefined);
              })
            );
          })
        );
      })
    );
  }

  private loadMarketData(marketDataDocId: string): Observable<MarketData> {
    if (!this.marketDataMap[marketDataDocId]) {
      this.marketDataMap[marketDataDocId] =
        this.marketDataService.getRealTimeMarketDataByDocId(marketDataDocId, this.authService.accessToken)
          .pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }
    return (this.marketDataMap[marketDataDocId]);
  }

  private selectAllCommodityProfiles() {
    this.closestToMarketSearchForm.get('commodityProfiles').setValue(this.reportCommodityProfiles);
    this.closestToMarketSearchForm.markAsDirty();
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}
