import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { CommonValidators } from '@advance-trading/angular-common-services';
import { OperationsDataService } from '@advance-trading/angular-ops-data';
import { Client, Commodity, CommodityMap, HMSClientSettings } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';

@Component({
  selector: 'hms-side-nav-quotes',
  templateUrl: './side-nav-quotes.component.html',
  styleUrls: ['./side-nav-quotes.component.scss']
})

export class SideNavQuotesComponent implements OnInit {
  commodityMap: CommodityMap;

  selectedClient: Client;

  errorMessage: string;

  filteredCommodities$: Observable<Commodity[]>;
  selectedCommodityId: string;
  selectedCommodityName: string;

  commodityForm: FormGroup = this.formBuilder.group({
    commodity: ['', [CommonValidators.objectValidator]],
  });

  readonly QUOTES_MODE = 'SIDE';

  constructor(
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private formBuilder: FormBuilder,
    private operationsDataService: OperationsDataService
  ) { }

  ngOnInit() {
    this.prepForCommoditySelection();
  }

  /**
   * Displays name for Commodity autocomplete input field
   */
  displayCommodity(commodity?: Commodity) {
    return commodity ? commodity.name : '';
  }

  private prepForCommoditySelection() {
    this.filteredCommodities$ = this.commodityForm.controls.commodity.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | Commodity>(''),
      switchMap(searchTerm => {
        return this.clientSelectorService.getSelectedClient().pipe(
          switchMap(client => {
            this.selectedClient = client;
            return this.operationsDataService.getCommodityMap();
          }),
          switchMap((commodityMap: CommodityMap) => {
            this.commodityMap = commodityMap;
            return this.clientSettingsService.getHmsSettingsByClientDocId(this.selectedClient.docId);
          }),
          map((settings: HMSClientSettings) => {
            const commodites = settings.commodities.map(commodity => this.commodityMap.commodities[commodity]);

            if (!this.selectedCommodityId) {
              const defaultCommodity = Object.values(commodites)[0];
              this.selectedCommodityId = defaultCommodity.id;
              this.selectedCommodityName = defaultCommodity.name;
              this.commodityForm.get('commodity').setValue(defaultCommodity);
            }

            const key = (searchTerm as Commodity).id;
            if (typeof searchTerm !== 'string' && this.commodityMap.commodities[key]) {
              this.selectedCommodityId = key;
              this.selectedCommodityName = this.commodityMap.commodities[key].name;
            }

            // Return all commodity when a commodity
            // has already been selected to avoid the user needing to clear the field
            if (typeof searchTerm !== 'string') {
              return commodites;
            }
            return commodites.filter(commodity => commodity.name.toLowerCase().includes(searchTerm.toLowerCase()));
          }),
          shareReplay({ bufferSize: 1, refCount: true }),
          catchError(err => {
            this.errorMessage = 'Error retrieving commodities; please try again later';
            console.error(`Error retrieving commodities: ${err}`);
            return of([]);
          })
        );
      })
    );
  }
}
