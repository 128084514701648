<div fxLayout="row" fxLayoutAlign="space-around start">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <hms-error-message [title]="'Error Retrieving Future Delivery Report'" [message]="errorMessage"></hms-error-message>

    <div *ngIf="!errorMessage && commodityProfiles$ | async as activeCommodityProfiles" fxLayout="column" fxLayoutAlign="space-between start">
      <h2 data-cy="page-title-future-delivery-report" class="card-title">Future Delivery Report</h2>
      <form [formGroup]="futureDeliverySearchForm" (ngSubmit)="loadFutureDeliveryReportData()" fxLayout="column" class="full-width">
        <div fxLayoutGap.gt-xs="25px" fxLayout.xs="column">
          <mat-form-field fxFlex.gt-xs="250px">
            <mat-label>Delivery Period</mat-label>
            <mat-select formControlName="deliveryPeriods" multiple>
              <mat-select-trigger>
                <ng-container *ngIf="currentSelectedDeliveryPeriods.length < periods.length">
                  {{ currentSelectedDeliveryPeriods.length }} out of {{ periods.length }} delivery periods
                </ng-container>
                <ng-container *ngIf="currentSelectedDeliveryPeriods.length === periods.length">
                  All delivery periods ({{ periods.length }})
                </ng-container>
              </mat-select-trigger>
              <mat-option *ngFor="let period of periods" [value]="period">{{ period | codeToMonth }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field fxFlex.gt-xs="250px">
            <mat-label>Commodity Profile</mat-label>
            <mat-select formControlName="commodityProfiles" multiple>
              <mat-select-trigger>
                <ng-container *ngIf="currentSelectedProfiles.length < activeCommodityProfiles.length">
                  {{ currentSelectedProfiles.length }} out of {{ activeCommodityProfiles.length }} commodity profiles
                </ng-container>
                <ng-container *ngIf="currentSelectedProfiles.length === activeCommodityProfiles.length">
                  All commodity profiles ({{ activeCommodityProfiles.length }})
                </ng-container>
              </mat-select-trigger>
              <mat-option *ngFor="let profile of activeCommodityProfiles" [value]="profile">{{ profile.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="!isLoading" fxLayout="row" fxLayoutGap="10px">
          <button id="generate-future-delivery-report" [disabled]="futureDeliverySearchForm.invalid" aria-label="Generate Report" type="submit" mat-raised-button color="accent">Generate Report</button>
          <button type="button" id="clear-future-delivery-report" *ngIf="!futureDeliverySearchForm.pristine" aria-label="Discard Report Options" (click)="reset()" mat-stroked-button color="accent">Reset</button>
        </div>
        <div *ngIf="isLoading" fxLayout="row" fxLayoutGap="10px">
          <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
        </div>
      </form>
    </div>

    <ng-container *ngIf="deliveries$ | async">
      <!-- Commodity Summary -->
      <div *ngIf="!showMini && displayReports" id="client-summary-large" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10">
        <h4>Commodity Summary</h4>
        <table mat-table [dataSource]="clientDataSource" class="mat-elevation-z0" multiTemplateDataRows>

          <ng-container matColumnDef="commodityProfileHeader">
            <th mat-header-cell *matHeaderCellDef rowspan="2" class="mat-cell commodity-profile"></th>
          </ng-container>

          <ng-container [matColumnDef]="col" *ngFor="let col of deliveryColumns">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="col === 'CASH' ? 1 : 2" class="centered"><b>{{ col === 'HTA' || col === 'DP' ? col : col | titlecase }}</b></th>
          </ng-container>

          <ng-container matColumnDef="commodityProfileName">
            <th class="dummy" mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let clientRow" class="mat-cell commodity-profile">{{ clientRow.commodityProfileName }}</td>
          </ng-container>

          <ng-container *ngFor="let col of deliveryColumns">
            <ng-container [matColumnDef]="getSubColumnName(col, false)">
              <th mat-header-cell *matHeaderCellDef class="centered">Unpriced</th>
              <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow[getSubColumnName(col, false)]) }}</td>
            </ng-container>
            <ng-container [matColumnDef]="getSubColumnName(col, true)">
              <th mat-header-cell *matHeaderCellDef class="centered">Priced</th>
              <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow[getSubColumnName(col, true)]) }}</td>
            </ng-container>
          </ng-container>

          <ng-container matColumnDef="footer">
            <td mat-footer-cell *matFooterCellDef [attr.colspan]="summaryFooterLength">No Deliveries</td>
          </ng-container>

          <tr mat-row *matHeaderRowDef="clientParentHeaders"></tr>
          <tr mat-row *matHeaderRowDef="clientColumnsToDisplay"></tr>
          <tr mat-row *matRowDef="let clientRow; columns: clientColumnsToDisplay"></tr>
          <ng-container *ngIf="!clientDataSource.data.length">
            <tr mat-row *matFooterRowDef="clientFooterColumnsToDisplay"></tr>
          </ng-container>
        </table>
      </div>
      <!-- Mini Summary -->
      <div *ngIf="showMini && displayReports" id="profile-summary-mini" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10" class="full-width">
        <h4>Commodity Summary</h4>
        <table mat-table [dataSource]="clientMiniDataSource" class="mat-elevation-z0 full-width" multiTemplateDataRows>

          <ng-container matColumnDef="commodityProfile">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstContractType(clientMiniRow) ? 'first mat-cell rotate' : 'dummy'}}" [attr.rowspan]="contractTypeCount" ><div>{{ clientMiniRow.commodityProfileName }}</div></td>
          </ng-container>

          <ng-container matColumnDef="contractType">
            <th mat-header-cell *matHeaderCellDef>Contract Type</th>
            <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstContractType(clientMiniRow) ? 'first mat-cell commodity-profile-mini' : 'mat-cell commodity-profile-mini'}}">{{ clientMiniRow.contractType === 'HTA' || clientMiniRow.contractType === 'DP' ? clientMiniRow.contractType : clientMiniRow.contractType  | titlecase }}</td>
          </ng-container>

          <ng-container matColumnDef="UNPRICED">
            <th mat-header-cell *matHeaderCellDef class="centered">Unpriced</th>
            <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstContractType(clientMiniRow) ? 'first mat-cell number-cell-mini' : 'mat-cell number-cell-mini'}}">{{ getDisplayAmount(clientMiniRow.UNPRICED) }}</td>
          </ng-container>

          <ng-container matColumnDef="PRICED">
            <th mat-header-cell *matHeaderCellDef class="centered">Priced</th>
            <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstContractType(clientMiniRow) ? 'first mat-cell number-cell-mini' : 'mat-cell number-cell-mini'}}">{{ getDisplayAmount(clientMiniRow.PRICED) }}</td>
          </ng-container>

          <ng-container matColumnDef="footer">
            <td mat-footer-cell *matFooterCellDef colspan="4">No Deliveries</td>
          </ng-container>

          <tr mat-row *matHeaderRowDef="clientMiniColumnsToDisplay"></tr>
          <tr mat-row *matRowDef="let clientMiniRow; columns: clientMiniColumnsToDisplay"></tr>
          <ng-container *ngIf="!clientMiniDataSource.data.length">
            <tr mat-row *matFooterRowDef="clientFooterColumnsToDisplay"></tr>
          </ng-container>
        </table>
      </div>
      <div *ngIf="displayReports" class="mat-small disclaimer-gap">Disclaimer: Commodity Summary is the total delivery quantity for all locations for the selected delivery periods.</div>
      <ng-container *ngFor="let profile of displayedCommodityProfiles">
        <!-- Profile Summary -->
        <div *ngIf="!showMini && displayReports" id="profile-summary" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10">
          <h4>
            {{ profile.name }}
            <button *ngIf="profileDataSourceMap[profile.docId].data.length" matTooltip="Export" aria-label="Export" id="export-future-delivery-report" mat-icon-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button"><mat-icon>save_alt</mat-icon></button>
          </h4>
          <mat-menu #exportMenu="matMenu">
            <button type="button" aria-label="Export Excel" id="export-future-delivery-report-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Future Delivery Report'))">Excel</button>
            <button type="button" aria-label="Export CSV" id="export-future-delivery-report-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Future Delivery Report'))">CSV</button>
            <button type="button" aria-label="Export JSON" id="export-future-delivery-report-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Future Delivery Report'))">JSON</button>
          </mat-menu>

          <table matTableExporter #exporter="matTableExporter" mat-table [dataSource]="profileDataSourceMap[profile.docId]" class="mat-elevation-z0" multiTemplateDataRows>

            <ng-container matColumnDef="locationHeader">
              <th mat-header-cell *matHeaderCellDef rowspan="2"></th>
            </ng-container>

            <ng-container matColumnDef="deliveryPeriodHeader">
              <th mat-header-cell *matHeaderCellDef rowspan="2" class="commodity-profile"></th>
            </ng-container>

            <ng-container [matColumnDef]="col" *ngFor="let col of deliveryColumns">
              <th mat-header-cell *matHeaderCellDef [attr.colspan]="col === 'CASH' ? 1 : 2" class="centered"><b>{{ col === 'HTA' || col === 'DP' ? col : col | titlecase }}</b></th>
            </ng-container>

            <ng-container matColumnDef="locationName">
              <th class="dummy" mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let profileRow" [attr.rowspan]="getLocationRowCount(profileRow)" class="{{ isFirstLocation(profileRow) ? 'first mat-cell rotate' : 'dummy'}}"><div>{{ profileRow.locationName }}</div></td>
            </ng-container>

            <ng-container matColumnDef="deliveryPeriod">
              <th class="dummy" mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let profileRow" class="{{ isFirstLocation(profileRow) ? 'first mat-cell' : 'mat-cell'}}"><div>{{ profileRow.deliveryPeriod | codeToMonth }}</div></td>
            </ng-container>

            <ng-container *ngFor="let col of deliveryColumns">
              <ng-container [matColumnDef]="getSubColumnName(col, false)">
                <th mat-header-cell *matHeaderCellDef class="centered">Unpriced</th>
                <td mat-cell *matCellDef="let profileRow" class="{{ isFirstLocation(profileRow) ? 'first mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(profileRow[getSubColumnName(col, false)]) }}</td>
              </ng-container>
              <ng-container [matColumnDef]="getSubColumnName(col, true)">
                <th mat-header-cell *matHeaderCellDef class="centered">Priced</th>
                <td mat-cell *matCellDef="let profileRow" class="{{ isFirstLocation(profileRow) ? 'first mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(profileRow[getSubColumnName(col, true)]) }}</td>
              </ng-container>
            </ng-container>

            <ng-container matColumnDef="footer">
              <td mat-footer-cell *matFooterCellDef [attr.colspan]="profileSummaryFooterLength">No Deliveries</td>
            </ng-container>

            <tr mat-row *matHeaderRowDef="profileSummaryParentHeaders"></tr>
            <tr mat-row *matHeaderRowDef="profileSummaryColumnsToDisplay"></tr>
            <tr mat-row *matRowDef="let profileRow; columns: profileSummaryColumnsToDisplay"></tr>
            <ng-container *ngIf="!profileDataSourceMap[profile.docId].data.length">
              <tr mat-row *matFooterRowDef="profileSummaryFooterColumnsToDisplay"></tr>
            </ng-container>
          </table>
        </div>
      </ng-container>
    </ng-container>
  </mat-card>
</div>
