import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import * as moment from 'moment';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { Client, CommodityProfile } from '@advance-trading/ops-data-lib';

import { UserRoles } from '../../utilities/user-roles';
import { ClientSelectorService } from 'src/app/service/client-selector.service';
import { ClientSettingsService } from 'src/app/service/client-settings.service';
import { CommodityProfileService } from '@advance-trading/angular-ops-data';
import { LedgerHelperService } from 'src/app/service/ledger-helper.service';
import { LedgerDay } from '../../utilities/ledger-day';

@Component({
  selector: 'hms-activity-ledger',
  templateUrl: './activity-ledger.component.html',
  styleUrls: ['./activity-ledger.component.scss']
})
export class ActivityLedgerComponent implements OnInit {
  errorMessage: string;
  isLoading = true;
  selectedDate: string;
  ledgerEndOfDay$: Observable<moment.Moment>;
  ledgerEndOfDay: string;
  timezone: string;
  allCommodityProfiles: CommodityProfile[] = [];
  activeNonSpreadCommodityProfiles: CommodityProfile[] = [];

  activityLedgerForm: FormGroup = this.formBuilder.group({
    ledgerDate: ['', [Validators.required]],
  });

  private activityLogLoaded = false;
  private positionsSummaryLoaded = false;
  private basisSummaryLoaded = false;
  private selectedClientDocId = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private formBuilder: FormBuilder,
    private ledgerHelperService: LedgerHelperService,
    private router: Router
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.FULL_LEDGER_VIEWER_ROLE) &&
      !this.authzService.currentUserHasRole(UserRoles.LOCATION_LEDGER_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to view the activity ledger.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }
    this.ledgerEndOfDay$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((client: Client) => {
        this.selectedClientDocId = client.docId;
        return this.commodityProfileService.getAllCommodityProfilesByClientDocId(this.selectedClientDocId);
      }),
      switchMap((commodityProfiles: CommodityProfile[]) => {
        this.allCommodityProfiles = commodityProfiles;
        this.activeNonSpreadCommodityProfiles = this.allCommodityProfiles.filter(commodityProfile => commodityProfile.isActive && !commodityProfile.isSpreadProfile);
        return this.clientSettingsService.getHmsLedgerEndOfDaySettingsByClientDocId(this.selectedClientDocId);
      }),
      tap((ledgerDay: LedgerDay) => {
        this.ledgerEndOfDay = this.ledgerHelperService.getLedgerEndOfDayFromMoment(ledgerDay.ledgerEndOfDay);
        this.timezone = ledgerDay.timezone;
        this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
          const queryParams = Object.assign({}, params);
          if (queryParams.ledgerDate) {
            this.selectedDate = this.ledgerHelperService.forceLedgerEndOfDayFromFullDateFormat(
              queryParams.ledgerDate, this.ledgerEndOfDay, this.timezone);
          } else {
            this.selectedDate = this.currentBusinessDay;
          }
        });
        this.activityLedgerForm.get('ledgerDate').setValue(this.selectedDate);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving client settings; please try again later';
        console.error(`Error retrieving client settings: ${err}`);
        return of(undefined);
      })
    );
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('matDatepickerParse')) {
      return 'Value required';
    }
    if (control.hasError('matDatepickerMax')) {
      return 'Value invalid';
    }
    return 'Unknown error';
  }

  listenForEnterOrTab(event: KeyboardEvent) {
    if (['Enter', 'Tab'].includes(event.key)) {
      this.updateSelectedDate();
    }
  }

  goLive() {
    this.activityLedgerForm.get('ledgerDate').setValue(this.currentBusinessDay);
    this.updateSelectedDate();
  }

  onActivityLogError(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  onPositionsSummaryError(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  onBasisSummaryError(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  onActivityLogLoaded() {
    this.activityLogLoaded = true;
    if ((this.positionsSummaryLoaded && this.basisSummaryLoaded) || !this.isLedgerLive) {
      this.isLoading = false;
    }
  }

  onPositionsSummaryLoaded() {
    this.positionsSummaryLoaded = true;

    if (this.activityLogLoaded && this.basisSummaryLoaded) {
      this.isLoading = false;
    }
  }

  onBasisSummaryLoaded() {
    this.basisSummaryLoaded = true;

    if (this.activityLogLoaded && this.positionsSummaryLoaded) {
      this.isLoading = false;
    }
  }

  onDatePickerClosed() {
    this.updateSelectedDate();
  }

  get currentBusinessDay() {
    return this.ledgerHelperService.getCurrentBusinessDay(this.ledgerEndOfDay, this.timezone);
  }

  get isLedgerLive() {
    return this.selectedDate === this.currentBusinessDay;
  }

  private updateSelectedDate() {
    const ledgerDate = this.activityLedgerForm.get('ledgerDate').value as Date;
    let newSelectedDate = this.ledgerHelperService.forceLedgerEndOfDay(
      this.ledgerHelperService.convertDateToMoment(ledgerDate), this.ledgerEndOfDay, this.timezone);
    if (!newSelectedDate) {
      newSelectedDate = this.currentBusinessDay;
      this.activityLedgerForm.get('ledgerDate').setValue(newSelectedDate);
    }
    if (newSelectedDate !== this.selectedDate) {
      this.isLoading = true;
      this.selectedDate = newSelectedDate;
      const queryParams: Params = {};
      // preserve selected ledger date in query param if not current
      if (this.selectedDate !== this.currentBusinessDay) {
        queryParams.ledgerDate = this.ledgerHelperService.convertISOStringToFullDate(this.selectedDate);
      }
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        replaceUrl: true,
        queryParams
      });
    }
  }
}
