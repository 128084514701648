import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { PatronService } from '@advance-trading/angular-ops-data';
import { Patron } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { UserRoles } from '../../utilities/user-roles';

@Component({
  selector: 'hms-patrons',
  templateUrl: './patrons.component.html',
  styleUrls: ['./patrons.component.scss']
})
export class PatronsComponent implements AfterViewInit, OnInit {
  columnsToDisplay: string[] = ['accountingSystemId', 'name', 'isActive'];
  errorMessage: string;
  dataSource = new ObservableDataSource<Patron>();
  filterValue = new FormControl();
  isLoading = true;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('filter', { static: false }) filter: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private changeDetector: ChangeDetectorRef,
    private clientSelectorService: ClientSelectorService,
    private patronService: PatronService,
    private router: Router
  ) { }

  ngOnInit() {
    this.dataSource.data$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap(selectedClient => {
        return this.patronService.getAllPatronsByClientDocId(selectedClient.docId);
      }),
      tap(patrons => this.isLoading = false),
      catchError(err => {
        this.isLoading = false;
        this.errorMessage = 'Error retrieving patrons; please try again later';
        console.error(`Error retrieving patrons: ${err}`);
        return of([]);
      })
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.filter.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  get isPatronAdmin() {
    return this.authzService.currentUserHasRole(UserRoles.PATRON_ADMIN_ROLE);
  }

  addPatron() {
    this.router.navigate(['./new'], { relativeTo: this.activatedRoute });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim().toLowerCase();
    if (filterValue === 'y' || filterValue === 'ye' || filterValue === 'yes') {
      filterValue = 'true';
    }
    if (filterValue === 'n' || filterValue === 'no') {
      filterValue = 'false';
    }
    this.dataSource.filter = filterValue;
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectPatron(patron: Patron) {
    this.router.navigate(['./', patron.docId], { relativeTo: this.activatedRoute });
  }

}
