// Angular
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule, MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// External libraries
import { NgxMaskModule } from 'ngx-mask';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { MatTableExporterModule } from 'mat-table-exporter';

// Internal libraries
import { AtiSecurityModule } from '@advance-trading/angular-ati-security';
import { CommonServicesModule } from '@advance-trading/angular-common-services';
import { NgxMatIntlTelInputModule } from '@advance-trading/ngx-mat-intl-tel-input';

// Local
import { environment } from '../environments/environment';
import { HmsRoutingModule } from './hms.routing.module';
import { MonthPickerDirective } from './month-picker/month-picker.directive';
import { UserStatusService } from './auth/user-status.service';
import { YearPickerDirective } from './commodity-profiles/year-picker/year-picker.directive';

// Components
import { AboutDialogComponent } from './utilities/ui/about-dialog/about-dialog.component';
import { AccountingSettingComponent } from './client-settings/accounting-setting/accounting-setting.component';
import { ActivityLedgerComponent } from './live-ledger/activity-ledger/activity-ledger.component';
import { ActivityLogComponent } from './live-ledger/activity-log/activity-log.component';
import { AdHocOrderDetailComponent } from './ad-hoc-orders/ad-hoc-order-detail/ad-hoc-order-detail.component';
import { AdHocOrderLegComponent } from './ad-hoc-orders/ad-hoc-order-leg/ad-hoc-order-leg.component';
import { AdHocOrdersComponent } from './ad-hoc-orders/ad-hoc-orders/ad-hoc-orders.component';
import { AdHocOrderSearchComponent } from './ad-hoc-orders/ad-hoc-order-search/ad-hoc-order-search.component';
import { AlertDetailComponent } from './alerts/alert-detail/alert-detail.component';
import { AlertsComponent } from './alerts/alerts/alerts.component';
import { AlertSearchComponent } from './alerts/alert-search/alert-search.component';
import { BasisAdminComponent } from './basis/basis-admin/basis-admin.component';
import { BasisInputComponent } from './utilities/ui/basis-input/basis-input.component';
import { BasisLedgerComponent } from './live-ledger/basis-ledger/basis-ledger.component';
import { BasisLogComponent } from './live-ledger/basis-log/basis-log.component';
import { BasisSummaryComponent } from './live-ledger/basis-summary/basis-summary.component';
import { BatchRollComponent } from './batch-roll/batch-roll.component';
import { BidsComponent } from './bids/bids.component';
import { BillingSettingsComponent } from './client-settings/billing-settings/billing-settings.component';
import { BottomSheetDeliveryPeriodComponent } from './basis/bottom-sheet-delivery-period/bottom-sheet-delivery-period.component';
import { BottomSheetMassUpdateComponent } from './basis/bottom-sheet-mass-update/bottom-sheet-mass-update.component';
import { CallbackComponent } from './callback/callback.component';
import { ClientSettingsComponent } from './client-settings/client-settings.component';
import { ClosestToMarketComponent } from './reports/closest-to-market/closest-to-market.component';
import { CommodityProfileDetailComponent } from './commodity-profiles/commodity-profile-detail/commodity-profile-detail.component';
import { CommodityProfilesComponent } from './commodity-profiles/commodity-profiles/commodity-profiles.component';
import { ConfirmDialogComponent } from './utilities/ui/confirm-dialog/confirm-dialog.component';
import { ContractDetailComponent } from './contracts/contract-detail/contract-detail.component';
import { ContractHistoryComponent } from './contracts/contract-history/contract-history.component';
import { ContractStatusComponent } from './contracts/contract-status/contract-status.component';
import { ContractsComponent } from './contracts/contracts/contracts.component';
import { ContractSearchComponent } from './contracts/contract-search/contract-search.component';
import { ErrorMessageComponent } from './utilities/ui/error-message/error-message.component';
import { ExpiringContractsComponent } from './reports/expiring-contracts/expiring-contracts.component';
import { FutureDeliveryComponent } from './reports/future-delivery/future-delivery.component';
import { HedgeableQuantityDialogComponent } from './commodity-profiles/hedgeable-quantity-dialog/hedgeable-quantity-dialog.component';
import { HedgeDetailComponent } from './hedges/hedge-detail/hedge-detail.component';
import { HedgesComponent } from './hedges/hedges/hedges.component';
import { HedgeSearchComponent } from './hedges/hedge-search/hedge-search.component';
import { HmsComponent } from './hms.component';
import { HomeComponent } from './home/home.component';
import { LedgerAdjustmentDetailComponent } from './ledger-adjustments/ledger-adjustment-detail/ledger-adjustment-detail.component';
import { LedgerAdjustmentsComponent } from './ledger-adjustments/ledger-adjustments/ledger-adjustments.component';
import { LedgerAdjustmentSearchComponent } from './ledger-adjustments/ledger-adjustment-search/ledger-adjustment-search.component';
import { LocationDetailComponent } from './locations/location-detail/location-detail.component';
import { LocationsComponent } from './locations/locations/locations.component';
import { LogoutComponent } from './logout/logout.component';
import { MainSettingsComponent } from './client-settings/main-settings/main-settings.component';
import { MonthlyRollReportComponent } from './reports/monthly-roll-report/monthly-roll-report.component';
import { MonthPickerComponent } from './month-picker/month-picker.component';
import { NewAdHocOrderComponent } from './ad-hoc-orders/new-ad-hoc-order/new-ad-hoc-order.component';
import { NewCommodityProfileComponent } from './commodity-profiles/new-commodity-profile/new-commodity-profile.component';
import { NewContractComponent } from './contracts/new-contract/new-contract.component';
import { NewHedgeComponent } from './hedges/new-hedge/new-hedge.component';
import { NewLedgerAdjustmentComponent } from './ledger-adjustments/new-ledger-adjustment/new-ledger-adjustment.component';
import { NewLocationComponent } from './locations/new-location/new-location.component';
import { NewPatronComponent } from './patrons/new-patron/new-patron.component';
import { OnDemandQuotesComponent } from './reports/on-demand-quotes/on-demand-quotes.component';
import { OrderDetailComponent } from './orders/order-detail/order-detail.component';
import { OrderFillComponent } from './reports/order-fill/order-fill.component';
import { OrderLegComponent } from './orders/order-leg/order-leg.component';
import { OrdersComponent } from './orders/orders/orders.component';
import { OrderSearchComponent } from './orders/order-search/order-search.component';
import { PatronDetailComponent } from './patrons/patron-detail/patron-detail.component';
import { PatronsComponent } from './patrons/patrons/patrons.component';
import { PositionsLedgerComponent } from './live-ledger/positions-ledger/positions-ledger.component';
import { PositionsLogComponent } from './live-ledger/positions-log/positions-log.component';
import { PositionsSummaryComponent } from './live-ledger/positions-summary/positions-summary.component';
import { PriceAdjustmentComponent } from './price-adjustments/price-adjustment/price-adjustment.component';
import { PriceAdjustmentsComponent } from './price-adjustments/price-adjustments/price-adjustments.component';
import { PricingSegmentComponent } from './contracts/pricing-segment/pricing-segment.component';
import { ProductionYearComponent } from './commodity-profiles/production-year/production-year.component';
import { PurchasesAndSalesComponent } from './reports/purchases-and-sales/purchases-and-sales.component';
import { ReconciliationComponent } from './reports/reconciliation/reconciliation.component';
import { RegistrationComponent } from './registration/registration.component';
import { ReportsComponent } from './reports/reports/reports.component';
import { SelectableBidsComponent } from './bids/selectable-bids/selectable-bids.component';
import { SelectableQuotesComponent } from './quotes/selectable-quotes/selectable-quotes.component';
import { SideNavQuotesComponent } from './quotes/side-nav-quotes/side-nav-quotes.component';
import { SingleCommodityQuotesComponent } from './quotes/single-commodity-quotes/single-commodity-quotes.component';
import { SpeedDialFabComponent } from './speed-dial-fab/speed-dial-fab.component';
import { TargetsComponent } from './targets/targets/targets.component';
import { TargetSearchComponent } from './targets/target-search/target-search.component';
import { UserDetailComponent } from './users/user-detail/user-detail.component';
import { UsersComponent } from './users/users/users.component';
import { UserPreferencesComponent } from './users/user-preferences/user-preferences.component';
import { UserSettingsComponent } from './users/user-settings/user-settings.component';

// Pipes
import {
  DatePipe,
  DecimalPipe,
  TitleCasePipe
} from '@angular/common';
import { ContractTypePipe } from './utilities/pipes/contract-type.pipe';
import { ContractUnitPipe } from './utilities/pipes/contract-unit.pipe';
import { HedgeTypePipe } from './utilities/pipes/hedge-type.pipe';
import { PhoneFormatPipe } from './utilities/pipes/phone-format.pipe';
import { PricingTypePipe } from './utilities/pipes/pricing-type.pipe';
import { ProductionYearPipe } from './utilities/pipes/production-year.pipe';
import { OrderSecurityPipe } from './utilities/pipes/order-security.pipe';
import { TimeInForcePipe } from './utilities/pipes/time-in-force.pipe';

@NgModule({
  declarations: [
    AboutDialogComponent,
    AccountingSettingComponent,
    ActivityLedgerComponent,
    ActivityLogComponent,
    AdHocOrderDetailComponent,
    AdHocOrderLegComponent,
    AdHocOrdersComponent,
    AdHocOrderSearchComponent,
    AlertDetailComponent,
    AlertsComponent,
    AlertSearchComponent,
    BasisAdminComponent,
    BasisInputComponent,
    BasisLedgerComponent,
    BasisLogComponent,
    BasisSummaryComponent,
    BatchRollComponent,
    BidsComponent,
    BillingSettingsComponent,
    BottomSheetDeliveryPeriodComponent,
    BottomSheetMassUpdateComponent,
    CallbackComponent,
    ClientSettingsComponent,
    ClosestToMarketComponent,
    CommodityProfileDetailComponent,
    CommodityProfilesComponent,
    ConfirmDialogComponent,
    ContractDetailComponent,
    ContractHistoryComponent,
    ContractSearchComponent,
    ContractsComponent,
    ContractStatusComponent,
    ContractTypePipe,
    ContractUnitPipe,
    ErrorMessageComponent,
    ExpiringContractsComponent,
    FutureDeliveryComponent,
    HedgeableQuantityDialogComponent,
    HedgeDetailComponent,
    HedgesComponent,
    HedgeSearchComponent,
    HedgeTypePipe,
    HmsComponent,
    HomeComponent,
    LedgerAdjustmentDetailComponent,
    LedgerAdjustmentsComponent,
    LedgerAdjustmentSearchComponent,
    LocationDetailComponent,
    LocationsComponent,
    LogoutComponent,
    MainSettingsComponent,
    MonthlyRollReportComponent,
    MonthPickerComponent,
    MonthPickerDirective,
    NewAdHocOrderComponent,
    NewCommodityProfileComponent,
    NewContractComponent,
    NewHedgeComponent,
    NewLedgerAdjustmentComponent,
    NewLocationComponent,
    NewPatronComponent,
    OnDemandQuotesComponent,
    OrderDetailComponent,
    OrderFillComponent,
    OrderLegComponent,
    OrdersComponent,
    OrderSearchComponent,
    OrderSecurityPipe,
    PatronDetailComponent,
    PatronsComponent,
    PhoneFormatPipe,
    PositionsLedgerComponent,
    PositionsLogComponent,
    PositionsSummaryComponent,
    PriceAdjustmentComponent,
    PriceAdjustmentsComponent,
    PricingSegmentComponent,
    PricingTypePipe,
    ProductionYearComponent,
    ProductionYearPipe,
    PurchasesAndSalesComponent,
    ReconciliationComponent,
    RegistrationComponent,
    ReportsComponent,
    SelectableBidsComponent,
    SelectableQuotesComponent,
    SideNavQuotesComponent,
    SingleCommodityQuotesComponent,
    SpeedDialFabComponent,
    TargetsComponent,
    TargetSearchComponent,
    TimeInForcePipe,
    UserDetailComponent,
    UsersComponent,
    UserPreferencesComponent,
    UserSettingsComponent,
    YearPickerDirective
  ],
  entryComponents: [
    AboutDialogComponent,
    BottomSheetDeliveryPeriodComponent,
    BottomSheetMassUpdateComponent,
    ConfirmDialogComponent,
    HedgeableQuantityDialogComponent
  ],
  imports: [
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AtiSecurityModule.configure(environment),
    BrowserAnimationsModule,
    BrowserModule,
    CommonServicesModule.configure(environment),
    FlexLayoutModule,
    FormsModule,
    HmsRoutingModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTableExporterModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxMaskModule.forRoot(),
    NgxMaterialTimepickerModule,
    NgxMatIntlTelInputModule,
    NgxTrimDirectiveModule,
    ReactiveFormsModule
  ],
  providers: [
    UserStatusService,
    {
      provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
      useValue: { restoreFocus: false, hasBackdrop: true }
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true }
    },
    {
      provide: 'Window',
      useValue: window
    },
    ContractTypePipe,
    DatePipe,
    DecimalPipe,
    HedgeTypePipe,
    PricingTypePipe,
    ProductionYearPipe,
    TimeInForcePipe,
    TitleCasePipe
  ],
  bootstrap: [ HmsComponent ]
})
export class HmsModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIcon('ad_hoc_order', domSanitizer.bypassSecurityTrustResourceUrl('./assets/post_add.svg'));
    matIconRegistry.addSvgIcon('adjustment', domSanitizer.bypassSecurityTrustResourceUrl('./assets/transform.svg'));
    matIconRegistry.addSvgIcon('basis', domSanitizer.bypassSecurityTrustResourceUrl('./assets/view_list.svg'));
    matIconRegistry.addSvgIcon('cash', domSanitizer.bypassSecurityTrustResourceUrl('./assets/attach_money.svg'));
    matIconRegistry.addSvgIcon('comment', domSanitizer.bypassSecurityTrustResourceUrl('./assets/comment.svg'));
    matIconRegistry.addSvgIcon('contracts',
        domSanitizer.bypassSecurityTrustResourceUrl('./assets/file-document-edit-outline.svg'));
    matIconRegistry.addSvgIcon('create_another', domSanitizer.bypassSecurityTrustResourceUrl('./assets/checkbox-marked-plus.svg'));
    matIconRegistry.addSvgIcon('dp', domSanitizer.bypassSecurityTrustResourceUrl('./assets/schedule.svg'));
    matIconRegistry.addSvgIcon('exchange', domSanitizer.bypassSecurityTrustResourceUrl('./assets/swap_horiz.svg'));
    matIconRegistry.addSvgIcon('hedge', domSanitizer.bypassSecurityTrustResourceUrl('./assets/scale-balance.svg'));
    matIconRegistry.addSvgIcon('hta', domSanitizer.bypassSecurityTrustResourceUrl('./assets/trending_up.svg'));
    matIconRegistry.addSvgIcon('order', domSanitizer.bypassSecurityTrustResourceUrl('./assets/receipt.svg'));
    matIconRegistry.addSvgIcon('quotes', domSanitizer.bypassSecurityTrustResourceUrl('./assets/chart-line.svg'));
    matIconRegistry.addSvgIcon('spot', domSanitizer.bypassSecurityTrustResourceUrl('./assets/circle-double.svg'));
    matIconRegistry.addSvgIcon('target', domSanitizer.bypassSecurityTrustResourceUrl('./assets/target.svg'));
  }
}
