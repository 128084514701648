<div fxLayout="row" fxLayoutAlign="space-around start">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <hms-error-message [title]="'Error Retrieving New Patron Form'" [message]="errorMessage"></hms-error-message>

    <form *ngIf="!errorMessage" [formGroup]="patronForm" (ngSubmit)="submit()" fxLayout="column" fxLayoutGap="15px">
      <!-- Title and Buttons -->
      <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="card-title">New Patron</h2>
        <div *ngIf="clientSettings$ | async" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
          <button type="submit" id="create-patron" class="action-button" aria-label="Create Patron" *ngIf="updateComplete" [disabled]="!patronForm.valid" (click)="submit()" mat-mini-fab color="accent" matTooltip="Create Patron">
            <mat-icon>done</mat-icon>
          </button>
          <button type="button" id="clear-patron" class="action-button" aria-label="Discard Changes" *ngIf="!patronForm.pristine && updateComplete" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-spinner *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
        <mat-form-field fxFlex="45">
          <input required matInput placeholder="ID" formControlName="id" trim="blur">
          <mat-error *ngIf="patronForm.get('id').invalid">{{getErrorMessage(patronForm.get('id'))}}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="45">
          <input required matInput placeholder="Name" formControlName="name" trim="blur">
          <mat-error *ngIf="patronForm.get('name').invalid">{{getErrorMessage(patronForm.get('name'))}}</mat-error>
        </mat-form-field>
      </div>

      <div class="checkbox-group" fxLayout="row wrap" fxLayoutGap="25">
        <mat-checkbox fxFlex formControlName="isActive">Is Active</mat-checkbox>
      </div>

      <div fxFlex="100" formArrayName="emails" *ngFor="let emailControl of existingEmailControls; let idx = index;" fxLayout="row wrap" fxLayoutAlign="space-between baseline">
        <mat-form-field fxFlex="45">
          <input matInput [placeholder]="getEmailLabel(idx)" [formControl]="emailControl" trim="blur" (blur)="removeInvalidEmailNotificationsAndResetValidators(idx)">
          <mat-error *ngIf="emailControl.invalid">{{ getErrorMessage(emailControl) }}</mat-error>
          <button matSuffix type="button" id="remove-new-patron-email" mat-icon-button aria-label="Remove Email" matTooltip="Remove Email" (click)="removeEmailForm(idx)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div fxFlex="100" fxLayout="row wrap">
        <mat-form-field fxFlex="45">
          <input matInput placeholder="New Email" formControlName="newEmail" trim="blur">
          <mat-error *ngIf="patronForm.get('newEmail').invalid">{{ getErrorMessage(patronForm.get('newEmail')) }}</mat-error>
          <button type="button" matSuffix id="add-email" [disabled]="!patronForm.get('newEmail').value || !patronForm.get('newEmail').valid" mat-icon-button aria-label="Add Email" matTooltip="Add Email" (click)="addEmailForm()">
            <mat-icon>add_circle</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
        <h4>Address</h4>
        <button *ngIf="!enableAddress && !patron?.address" [disabled]="patronForm.disabled" id="add-patron-address" class="action-button" type="button" aria-label="Add Address" matTooltip="Add Address" color="accent" mat-icon-button (click)="showAddress()">
          <mat-icon>add_circle</mat-icon>
        </button>
        <button *ngIf="enableAddress && !patron?.address" [disabled]="patronForm.disabled" id="cancel-patron-address" class="action-button" type="button" aria-label="Cancel Address" matTooltip="Cancel Address" color="primary" mat-icon-button (click)="hideAddress()">
          <mat-icon>cancel</mat-icon>
        </button>
      </div>
      <ati-address *ngIf="enableAddress" [addressForm]="patronForm.get('address')"></ati-address>

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title>Phone Numbers</mat-panel-title>
        </mat-expansion-panel-header>
        <ati-phone-number *ngFor="let phoneNumber of phoneNumbers; let index = index" [editMode]=true [phoneNumber]="phoneNumber" (numberUpdated)="onPhoneNumberUpdated(index, $event)" (numberRemoved)="onPhoneNumberRemoved(index)"></ati-phone-number>
        <ati-phone-number [editMode]=true (numberUpdated)="onPhoneNumberCreated($event)"></ati-phone-number>
      </mat-expansion-panel>

      <div *ngIf="sendPatronNotifications" fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100">
        <h4>Notifications</h4>
        <div class="notification-body" fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100">
          <div *ngFor="let mobilePhone of mobilePhones" fxFlex="100" class="notification-item">
            <mat-checkbox [checked]="smsNotifications.includes(mobilePhone)" (change)="onSmsNotificationChange($event)" [value]="mobilePhone">SMS - {{ mobilePhone | phoneFormat }}</mat-checkbox>
          </div>
          <div *ngFor="let email of allValidEmailsObservable$ | async" fxFlex="100" class="notification-item">
            <mat-checkbox [checked]="emailNotifications.includes(email)" (change)="onEmailNotificationChange($event)" [value]="email">Email - {{ email }}</mat-checkbox>
          </div>
        </div>
      </div>


    </form>

  </mat-card>
</div>
