<div fxLayout="column">
  <h4 data-cy="activity-log-component">Activity Log
    <button matTooltip="Export" aria-label="Export" id="export-activity-log" mat-icon-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button"><mat-icon>save_alt</mat-icon></button>
  </h4>
  <mat-menu #exportMenu="matMenu">
    <button type="button" aria-label="Export Excel" id="export-activity-log-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Activity Log'))">Excel</button>
    <button type="button" aria-label="Export CSV" id="export-activity-log-as-csv"  mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Activity Log'))">CSV</button>
    <button type="button" aria-label="Export JSON" id="export-activity-log-as-json"  mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Activity Log'))">JSON</button>
  </mat-menu>
  <mat-table matTableExporter #exporter="matTableExporter" [hiddenColumns]="[0, 6]" [dataSource]="dataSource" class="mat-elevation-z0">
    <ng-container matColumnDef="position">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('position')">#</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" [style.flex]="getFlexLayout('position')">{{ activityLog.position }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="timestamp">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('timestamp')">Time</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" [style.flex]="getFlexLayout('timestamp')">{{ activityLog.timestamp | date: 'shortTime' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="activityType">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('activityType')">Type</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" [style.flex]="getFlexLayout('activityType')">
        <mat-icon [ngClass]="getTypeNgClass(activityLog)" [svgIcon]="getIconName(activityLog.activityType)"></mat-icon>
        <span class="cdk-visually-hidden">{{ activityLog.activityType === 'HTA' || activityLog.activityType === 'DP' ? activityLog.activityType : activityLog.activityType | replace | titlecase }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="futuresYearMonth">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('futuresYearMonth')">Contract</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" [style.flex]="getFlexLayout('futuresYearMonth')">
        <ng-container *ngIf="!activityLog.legs || activityLog.futuresYearMonth">{{ activityLog.futuresYearMonth | codeToMonth }}</ng-container>
        <ng-container *ngIf="activityLog.legs && !activityLog.futuresYearMonth">{{ activityLog.legs[0].contractYearMonth | codeToMonth }}/<br>{{ activityLog.legs[1].contractYearMonth | codeToMonth }}</ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="commodityProfile">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('commodityProfile')">Profile</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" [style.flex]="getFlexLayout('commodityProfile')">{{ activityLog.commodityProfile.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="side">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('side')">Side</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" [style.flex]="getFlexLayout('side')">{{ activityLog.side | titlecase }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="displayQuantity">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('quantity')">Qty</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" [style.flex]="getFlexLayout('quantity')">
        <div  fxLayout="column" fxFlex="100">
          <div fxFlex="100">{{ activityLog.quantity | number:'1.0-0' }}</div>
          <div fxFlex="100" *ngIf="activityLog.originalQuantity" class="deleted">{{ activityLog.originalQuantity | number:'1.0-0' }}</div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Quantity</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" class="cdk-visually-hidden">{{ activityLog.quantity | number:'1.0-0' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="originalQuantity">
      <mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Original Quantity</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" class="cdk-visually-hidden">{{ activityLog.originalQuantity | number:'1.0-0' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="productionYearLabel">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('productionYearLabel')">Year</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" [style.flex]="getFlexLayout('productionYearLabel')">{{ activityLog.productionYearLabel | productionYear }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="basisPrice">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('basisPrice')">Basis</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" [style.flex]="getFlexLayout('basisPrice')">{{ activityLog.basisPrice | number:'1.4-4' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="futuresPrice">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('futuresPrice')">Futures</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" [style.flex]="getFlexLayout('futuresPrice')">
        <div *ngIf="!activityLog.isSplitFilled">{{ activityLog.futuresPrice | number:'1.4-5' }}</div>
        <div *ngIf="activityLog.isSplitFilled">{{ activityLog.futuresPrice | number:'1.4-5' }}*</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cashPrice">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('cashPrice')">Cash</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" [style.flex]="getFlexLayout('cashPrice')">{{ activityLog.cashPrice | number:'1.4-5' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="originatorName">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('originatorName')">Originator</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" [style.flex]="getFlexLayout('originatorName')">{{ activityLog.originatorName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="locationName">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('locationName')">Client<br />Location</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" [style.flex]="getFlexLayout('locationName')">{{ activityLog.locationName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="patronDisplayName">
      <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('patronDisplayName')">Patron</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" [style.flex]="getFlexLayout('patronDisplayName')" >
        <div *ngIf="activityLog.patronAccountingSystemId; else noPatronAccountingSystemId">
          <div class="truncate-overflow-2">
            {{ activityLog.patronName }}
          </div>
          <div>
            ({{ activityLog.patronAccountingSystemId }})
          </div>
        </div>
        <ng-template #noPatronAccountingSystemId>
          <div class="truncate-overflow-3">
            {{ activityLog.patronName }}
          </div>
        </ng-template>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="comments">
      <ng-container *ngIf="displayNotesText; then commentsText; else commentsIcon"></ng-container>
      <!-- comment icon template -->
      <ng-template #commentsIcon>
        <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('comments')">Comments</mat-header-cell>
        <mat-cell *matCellDef="let activityLog; let i = index" [style.flex]="getFlexLayout('comments')">
          <button *ngIf="activityLog.comments" id="open-activity-log-comment" mat-button matSuffix mat-icon-button
            aria-label="Comments" (mouseenter)="commentTooltip.show()" (mouseleave)="commentTooltip.hide()"
            (click)="commentTooltip.show(); $event.stopPropagation();" #commentTooltip="matTooltip"
            [matTooltip]="activityLog.comments" matTooltipClass="comment-tooltip">
            <mat-icon svgIcon="comment"></mat-icon>
            <span class="cdk-visually-hidden">{{ activityLog.comments }}</span>
          </button>
        </mat-cell>
      </ng-template>
      <!-- comment text template -->
      <ng-template #commentsText>
        <mat-header-cell *matHeaderCellDef [style.flex]="getFlexLayout('comments')">Comments</mat-header-cell>
        <mat-cell ngClass="comment-cell" *matCellDef="let activityLog; let i = index" [style.flex]="getFlexLayout('comments')">
          <p class="comments-text-inline">{{ activityLog.comments }}</p>
        </mat-cell>
      </ng-template>
    </ng-container>

    <ng-container matColumnDef="deleted">
      <mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Deleted</mat-header-cell>
      <mat-cell *matCellDef="let activityLog" class="cdk-visually-hidden">{{ activityLog.deletionTimestamp ? 'Y' : '' }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
    <mat-row (click)="selectActivityLog(activityLog)" *matRowDef="let activityLog; columns: columnsToDisplay" [ngClass]="{ 'deleted': activityLog.deletionTimestamp || activityLog.cancellationTimestamp }"
              attr.data-cy="activityLog-{{activityLog.comments}}"></mat-row>
  </mat-table>
  <mat-paginator color="primary" [pageSizeOptions]="[50, 100, 500]"></mat-paginator>
</div>
