import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay, switchMap, tap } from 'rxjs/operators';

import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommodityProfileService, OperationsDataService, UserService } from '@advance-trading/angular-ops-data';
import { Client, Commodity, CommodityMap, CommodityProfile, HMSClientSettings, LedgerAdjustment, LedgerAdjustmentType, User } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { LedgerAdjustmentService } from '../../service/ledger-adjustment.service';
import { LedgerHelperService } from '../../service/ledger-helper.service';
import { UserRoles } from '../../utilities/user-roles';

@Component({
  selector: 'hms-ledger-adjustment-detail',
  templateUrl: './ledger-adjustment-detail.component.html',
  styleUrls: ['./ledger-adjustment-detail.component.scss']
})
export class LedgerAdjustmentDetailComponent implements OnInit {
  adjustmentForm: FormGroup = this.formBuilder.group({
    comments: ['', [Validators.maxLength(400)]]
  });
  editMode = false;
  updateComplete = true;
  isLoading = true;
  errorMessage: string;
  adjustment: LedgerAdjustment;
  commodityProfile$: Observable<CommodityProfile>;
  commodityProfile: CommodityProfile;

  private commodities: Commodity[];
  private loggedInUser: User;
  private ledgerEndOfDay: string;
  private selectedClientDocId: string;
  private timezone: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private formBuilder: FormBuilder,
    private ledgerAdjustmentService: LedgerAdjustmentService,
    private ledgerHelperService: LedgerHelperService,
    private operationsDataService: OperationsDataService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.LEDGER_ADJUSTMENT_VIEWER_ROLE) &&
      !this.authzService.currentUserHasRole(UserRoles.FULL_LEDGER_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to view ledger adjustments.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.setEditMode(false);
    let adjustmentDocId: string;
    this.commodityProfile$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((doc: CommodityMap) => {
        this.commodities = Object.values(doc.commodities);
        return this.clientSelectorService.getSelectedClient();
      }),
      switchMap((selectedClient: Client) => {
        this.selectedClientDocId = selectedClient.docId;
        return this.clientSettingsService.getHmsSettingsByClientDocId(this.selectedClientDocId);
      }),
      switchMap((clientSettings: HMSClientSettings) => {
        this.ledgerEndOfDay = clientSettings.ledgerEndOfDay;
        this.timezone = clientSettings.timezone;
        return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId);
      }),
      switchMap((user: User) => {
        this.loggedInUser = user;
        return this.activatedRoute.paramMap;
      }),
      switchMap((paramMap: ParamMap) => {
        adjustmentDocId = paramMap.get('docId');
        return this.ledgerAdjustmentService.getLedgerAdjustmentByDocId(this.selectedClientDocId, adjustmentDocId);
      }),
      switchMap((adjustment: LedgerAdjustment) => {
        this.adjustment = adjustment;
        return this.commodityProfileService.getCommodityProfileByDocId(this.selectedClientDocId, this.adjustment.commodityProfileDocId);
      }),
      tap((commodityProfile: CommodityProfile) => {
        this.commodityProfile = commodityProfile;
        this.setupAdjustmentForm();
        this.isLoading = false;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.isLoading = false;
        this.errorMessage = 'Error retrieving ledger adjustment details; please try again later';
        console.error(`Error retrieving ledger adjustment details: ${err}`);
        return of(undefined);
      })
    );
  }

  get isLedgerAdjustmentAdmin() {
    return this.authzService.currentUserHasRole(UserRoles.LEDGER_ADJUSTMENT_ADMIN_ROLE);
  }

  get prodYearLabel() {
    return this.ledgerHelperService.getProdYearLabelForLedgerAdjustment(
      this.adjustment, this.commodityProfile, this.ledgerEndOfDay, this.timezone);
  }

  submit() {
    const userName = `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`;
    this.adjustment.lastUpdatedByName = userName;
    this.adjustment.lastUpdatedTimestamp = moment().toISOString();

    const comments = this.adjustmentForm.get('comments').value;
    if (comments) {
      this.adjustment.comments = comments;
    } else {
      if (this.adjustment.comments) {
        delete this.adjustment.comments;
      }
    }

    // Update adjustment by calling the adjustment service
    this.updateComplete = false;
    this.ledgerAdjustmentService.updateLedgerAdjustment(this.selectedClientDocId, this.adjustment)
      .then(() => {
        this.updateComplete = true;
        console.log('Ledger adjustment successfully updated');
        this.openSnackBar('Ledger adjustment successfully updated', 'DISMISS', true);
        this.router.navigate(['../'], { relativeTo: this.activatedRoute });
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`Ledger adjustment update failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Ledger adjustment update failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  reset() {
    this.adjustmentForm.reset();
    this.setupAdjustmentForm();
    this.adjustmentForm.markAsPristine();
    this.setEditMode(false);
  }

  clearField(fieldName: string) {
    const field = this.adjustmentForm.get(fieldName);
    field.setValue('');
    field.markAsDirty();
  }

  compareCommodityProfile(profile1: CommodityProfile, profile2: CommodityProfile) {
    return profile1 && profile2 && profile1.docId === profile2.docId;
  }

  get createdDate() {
    return moment(this.adjustment.creationTimestamp).format('M/D/YYYY, h:mm:ss A');
  }

  get lastUpdatedDate() {
    return moment(this.adjustment.lastUpdatedTimestamp).format('M/D/YYYY, h:mm:ss A');
  }

  get contractUnit() {
    return this.commodities.find(commodity => commodity.id === this.commodityProfile.commodityId).contractUnit;
  }

  get displaySystemAdjustmentLink() {
    return this.adjustment.type === LedgerAdjustmentType.CONTRACT || this.adjustment.type === LedgerAdjustmentType.PRICING_SEGMENT;
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value Required';
    } else if (control.hasError('pattern')) {
      return 'Value invalid';
    } else if (control.hasError('maxlength')) {
      return 'Value cannot exceed ' + control.errors['maxlength'].requiredLength + ' characters';
    }
    return 'Unknown Error';
  }

  setEditMode(editable: boolean): void {
    this.editMode = editable;
    if (this.editMode) {
      this.adjustmentForm.enable();
    } else {
      this.adjustmentForm.disable();
    }
  }

  getSystemAdjustmentLabel(): string {
    if (this.adjustment.relatedIdentifier) {
      return this.adjustment.type === LedgerAdjustmentType.CONTRACT ? 'Related Contract' : 'Related Pricing';
    }
    return '';
  }

  getSystemAdjustmentDetail(): string {
    if (this.adjustment.relatedIdentifier) {
      return this.adjustment.relatedIdentifier;
    }
    return this.adjustment.type === LedgerAdjustmentType.CONTRACT ? 'Related Contract' : 'Related Pricing';
  }

  private setupAdjustmentForm(): void {
    if (this.adjustment) {
      this.adjustmentForm.get('comments').setValue(this.adjustment.comments);
    }
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }
}
