// Angular
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';

// External libraries
import * as moment from 'moment';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay, switchMap, tap, take } from 'rxjs/operators';

// Internal libraries
import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { OperationsDataService, UserService } from '@advance-trading/angular-ops-data';
import { AlertStatus, AppAlert, Client, CommodityMap, ContractType, HMSClientSettings, User } from '@advance-trading/ops-data-lib';

// Local
import { AppAlertService } from './service/app-alert.service';
import { AuthGuard } from './auth/auth.guard';
import { ClientSelectorService } from './service/client-selector.service';
import { ClientSettingsService } from './service/client-settings.service';
import { AboutDialogComponent } from './utilities/ui/about-dialog/about-dialog.component';
import { ALERT_USER_ROLES, REPORT_USER_ROLES, UserRoles } from './utilities/user-roles';

const SILENCE_TIME_FORMAT = 'h:mm a';

@Component({
  selector: 'hms-root',
  templateUrl: './hms.component.html',
  styleUrls: ['./hms.component.scss'],
  providers: [BreakpointObserver]
})
export class HmsComponent implements OnInit {
  // Time Picker customization
  hmsTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#E0E0E0',
      buttonColor: '#000000DE'
    },
    dial: {
        // $md-atigreen2 500 weight from our material theme
      dialBackgroundColor: '#789C63',
    },
    clockFace: {
      clockFaceBackgroundColor: '#FFFFFF',
      // $md-atigreen2 500 weight from our material theme
      clockHandColor: '#789C63',
      clockFaceTimeInactiveColor: '#000000DE'
    }
  };

  silenceAlertForm = this.formBuilder.group({
    silenceUntilTime: [],
  });

  silenceError = '';

  title = 'Advance Trading Hedge Management System - HMS';
  sideNavOpened: boolean;
  rightSheetOpened: boolean;
  rightSheetDisplay: string;

  sideNavMode = 'over';
  loggedInUser$: Observable<User>;

  tooltip = 'New...';

  ledgerExpanded = false;
  reportsExpanded = false;
  settingsExpanded = false;

  @ViewChild('rightSheet', {static: false}) rightSheet: MatSidenav;
  @ViewChild('silenceTrigger', {static: false}) silenceTrigger: MatMenuTrigger;

  hmsClientSettings$: Observable<HMSClientSettings>;
  commodityMap: CommodityMap;
  isRegistering = false;
  isXSmall = false;

  errorMessage: string;
  selectedClient$: Observable<Client>;
  authorizedClients$: Observable<Client[]>;

  quotesContainerSize = 'large';
  cashBidsContainerSize = 'large';

  // Alert observables
  newAlerts$: Observable<AppAlert[]>;
  inProgressAlerts$: Observable<AppAlert[]>;
  completedAlerts$: Observable<AppAlert[]>;
  mutedTime = '';
  userAlertRoles: string[] = [];

  // Quotes/Cash Bids Dropdown
  marketDropdownOptions = ['Quotes', 'Cash Bids'];
  marketDropdownSelection: string;

  // defined constant for right sheet display
  readonly QUOTES = 'QUOTES';
  readonly ALERTS = 'ALERTS';
  readonly CASHBIDS = 'CASHBIDS';

  // defined constant for quotes side sheet view mode
  readonly QUOTES_MODE = 'SIDE';

  private loggedInUser: User;

  constructor(
    private alertService: AppAlertService,
    private authzService: Auth0AuthzService,
    private breakpointObserver: BreakpointObserver,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private dialog: MatDialog,
    @Inject('environment') private environment,
    private formBuilder: FormBuilder,
    private operationsDataService: OperationsDataService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
    public authGuard: AuthGuard,
    public authService: AuthService,
  ) {
    this.authGuard.authErrors$.subscribe(error => {
      // TODO is this always returning going to cover up other errors? Perhaps we don't send '' back
      this.errorMessage = error;
    });
    this.authService.localAuthSetup().pipe(
      catchError(err => {
        // Check for a Firebase login problem associated with email verification
        if (err.error && err.error.message.includes('not verified')) {
          // Show not verified email screen
          console.log('Your email is NOT yet verified');
          this.errorMessage = `Your email address is not yet verified. ` +
              `Please follow the instructions contained in the verification email and then login again.`;
        } else {
          console.error(`Error occurred in authService.localAuthSetup: ${err}`);
          this.errorMessage = 'An unknown login error has occurred. Please try again later.';
        }
        return of(undefined);
      })
    ).subscribe();
  }

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe(state => {
        if (state.breakpoints[Breakpoints.XLarge] || state.breakpoints[Breakpoints.Large]) {
          this.sideNavMode = 'side';
          this.sideNavOpened = true;
        } else {
          this.sideNavMode = 'over';
          this.sideNavOpened = false;
        }

        // Set an indicator of XSmall screen size to assist in customizing toolbar
        this.isXSmall = state.breakpoints[Breakpoints.XSmall];

        // set the size for side sheet quote container
        if (state.breakpoints[Breakpoints.XSmall] || state.breakpoints[Breakpoints.Small]) {
          this.quotesContainerSize = 'small';
          this.cashBidsContainerSize = 'small';
        }  else {
          this.quotesContainerSize = 'large';
          this.cashBidsContainerSize = 'large';
        }

      });

    this.loggedInUser$ = this.authService.userProfile$.pipe(
      switchMap(userProfile => {
        // Avoid an error when a new user is registered but their Auth0 token doesn't yet have a firestoreDocId
        if (userProfile.app_metadata['firestoreDocId']) {
          return this.userService.getUserByDocId(userProfile.app_metadata['firestoreDocId']);
        } else {
          return this.userService.getUserByAuthId(userProfile.sub);
        }
      }),
      tap(user => this.loggedInUser = user),
      catchError(err => {
        console.error(`Could not retrieve User by firestoreDocId or Auth0 authId: ${err}`);
        return of(undefined);
      })
    );

    this.authorizedClients$ = this.clientSelectorService?.getAuthorizedClients();
    this.selectedClient$ = this.clientSelectorService.getSelectedClient().pipe(
      tap(selectedClient => {
        console.log(`Selected client is: ${selectedClient.name}`);
        this.getHmsClientSettingsObs(selectedClient);
        this.userAlertRoles = this.getUserAlertRecipientRoles();
        // skip alert retrieval if user doesn't have any roles that receive alerts
        if (this.userAlertRoles.length) {
          this.newAlerts$ = this.alertService.getClientAppAlertsByStatusInAscendingDate(
            selectedClient.docId, 'HMS', this.userAlertRoles, AlertStatus.NEW);
          this.inProgressAlerts$ = this.alertService.getClientAppAlertsByStatusInAscendingDate(
            selectedClient.docId, 'HMS', this.userAlertRoles, AlertStatus.IN_PROGRESS);
          this.completedAlerts$ = this.alertService.getRealtimeCompletedClientAppAlerts(
            selectedClient.docId, this.userAlertRoles);
        }
      }),
      catchError(err => {
        console.error(`${err}`);
        // Ignore selectedClient errors during registration
        if (!this.isRegistering) {
          this.errorMessage = `No authorized client for this user; please contact your administrator.`;
        }
        return of(undefined);
      })
    );

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const alertIdMatcher = 'alertId=';
        const alertIdIndex = event.url.indexOf(alertIdMatcher);
        if (alertIdIndex > 0) {
          const alertId = event.url.substr(alertIdIndex + alertIdMatcher.length, 36);
          // Update a NEW app alert to IN_PROGRESS when navigating to a screen via AppAlert.actionUri
          this.alertService.getAppAlertByDocId(alertId).pipe(
            take(1)
          ).subscribe(alert => {
            if (alert.status === AlertStatus.NEW) {
              this.markAlertInProgress(alertId);
            }
          });
        }
        if (event.url.endsWith('/register')) {
          this.isRegistering = true;
          this.errorMessage = '';
          this.sideNavOpened = false;
        } else {
          this.isRegistering = false;
        }
        if (event.url.includes('/liveledgers')) {
          this.ledgerExpanded = true;
          this.reportsExpanded = false;
          this.settingsExpanded = false;
        } else if (event.url.includes('/reports')) {
          this.ledgerExpanded = false;
          this.reportsExpanded = true;
          this.settingsExpanded = false;
        } else if (event.url.includes('/settings')) {
          this.ledgerExpanded = false;
          this.reportsExpanded = false;
          this.settingsExpanded = true;
        } else {
          this.ledgerExpanded = false;
          this.reportsExpanded = false;
          this.settingsExpanded = false;
        }
      }
    });
  }

  updateSelectedClient(clientDocId: string) {
    // TODO add confirmation dialog?
    this.handleSideNavigation();
    this.router.navigate(['/home']);
    this.clientSelectorService.updateSelectedClient(clientDocId);
  }

  handleSideNavigation() {
    if (this.sideNavMode !== 'side') {
      this.sideNavOpened = !this.sideNavOpened;
    }
  }

  navigateToAlert(alert: AppAlert) {
    this.rightSheet.toggle();

    // Handle the situation where a user is already at the actionUri and clicks the new alert
    if (alert.status === AlertStatus.NEW && alert.actionUri === this.router.url) {
      this.markAlertInProgress(alert.docId);
    }

    this.router.navigateByUrl(alert.actionUri);
  }

  navigateToAlertNoId(actionUri: string) {
    const strippedActionUri = actionUri.replace(/(\?|\&)alertId.+$/, '');
    this.rightSheet.toggle();
    this.router.navigateByUrl(strippedActionUri);
  }

  navigateToAlertsSearch() {
    this.rightSheet.toggle();
    this.router.navigate(['/alerts']);
  }

  navigateToProfile() {
    this.router.navigate(['/settings/users', this.loggedInUser.docId]);
  }

  navigateToLogout() {
    this.router.navigate(['/logout']);
  }

  showAbout() {
    this.dialog.open(AboutDialogComponent, {
      data: {
        versionNumber: this.environment.versionNumber,
        versionDate: this.environment.versionDate
      },
      height: 'auto',
      width: '600px'
    });
  }

  canViewAdHocOrders() {
    return this.authzService.currentUserHasRole(UserRoles.AD_HOC_ORDER_ADMIN_ROLE) ||
      this.authzService.currentUserHasRole(UserRoles.AD_HOC_ORDER_VIEWER_ROLE);
  }

  canViewBasisAdmin() {
    return this.authzService.currentUserHasRole(UserRoles.BASIS_ADMIN_ROLE);
  }

  canViewBatchRoll() {
    return this.authzService.currentUserHasRole(UserRoles.PERIOD_BATCH_UPDATER_ROLE);
  }

  canViewClientSettings() {
    return this.authzService.currentUserHasRole(UserRoles.HMS_ADMIN_ROLE);
  }

  canViewContractReports() {
    return this.authzService.currentUserHasRole(UserRoles.CONTRACT_REPORTS_GENERATOR_ROLE);
  }

  canViewContractSearch() {
    return this.authzService.currentUserHasRole(UserRoles.CONTRACT_VIEWER_ROLE);
  }

  canViewHedges() {
    return this.authzService.currentUserHasRole(UserRoles.HEDGE_CREATOR_ROLE) ||
      this.authzService.currentUserHasRole(UserRoles.HEDGED_CONTRACT_CREATOR_ROLE) ||
      this.authzService.currentUserHasRole(UserRoles.HEDGE_VIEWER_ROLE);
  }

  canViewLedgerAdjustments() {
    return this.authzService.currentUserHasRole(UserRoles.LEDGER_ADJUSTMENT_ADMIN_ROLE) ||
      this.authzService.currentUserHasRole(UserRoles.LEDGER_ADJUSTMENT_VIEWER_ROLE);
  }

  canViewLiveLedgers() {
    return this.authzService.currentUserHasRole(UserRoles.FULL_LEDGER_VIEWER_ROLE) ||
      this.authzService.currentUserHasRole(UserRoles.LOCATION_LEDGER_VIEWER_ROLE);
  }

  canViewMerchandisingReports() {
    return this.authzService.currentUserHasRole(UserRoles.MERCHANDISING_REPORTS_GENERATOR_ROLE);
  }

  canViewOperationsReports() {
    return this.authzService.currentUserHasRole(UserRoles.OPERATIONS_REPORTS_GENERATOR_ROLE);
  }

  canViewOrders() {
    return this.authzService.currentUserHasRole(UserRoles.ORDER_ADMIN_ROLE) ||
      this.authzService.currentUserHasRole(UserRoles.ORDER_VIEWER_ROLE);
  }

  canViewPriceAdjustments() {
    return this.authzService.currentUserHasRole(UserRoles.CLIENT_SETTINGS_ADMIN_ROLE);
  }

  canViewReports() {
    const userRoles: string[] = [];
    REPORT_USER_ROLES.forEach(role => {
      if (this.authzService.currentUserHasRole(role)) {
        userRoles.push(role);
      }
    });
    return userRoles.length ? true : false;
  }

  get fabButtons() {
    const buttons = [];
    if (this.canCreateContracts()) {
      buttons.push(
       {
          svgIcon: 'dp',
          id: 'speed-dialer-dp',
          label: 'DP Contract',
          uri: '/contracts/new',
          queryParams: {
            contractType : ContractType.DP
          }
        },
        {
          svgIcon: 'basis',
          id: 'speed-dialer-basis',
          label: 'Basis Contract',
          uri: '/contracts/new',
          queryParams: {
            contractType : ContractType.BASIS
          }
        },
        {
          svgIcon: 'hta',
          id: 'speed-dialer-hta',
          label: 'HTA Contract',
          uri: '/contracts/new',
          queryParams: {
            contractType : ContractType.HTA
          }
        },
        {
          svgIcon: 'cash',
          id: 'speed-dialer-cash',
          label: 'Cash Contract',
          uri: '/contracts/new',
          queryParams: {
            contractType : ContractType.CASH
          }
        },
        {
          svgIcon: 'spot',
          id: 'speed-dialer-spot',
          label: 'Spot Contract',
          uri: '/contracts/new',
          queryParams: {
            contractType : ContractType.CASH,
            isSpot : 'TRUE'
          }
        }
      );
    }
    if (this.authzService.currentUserHasRole(UserRoles.HEDGE_CREATOR_ROLE) ||
      this.authzService.currentUserHasRole(UserRoles.HEDGED_CONTRACT_CREATOR_ROLE)) {
      buttons.push(
        {
          svgIcon: 'hedge',
          id: 'speed-dialer-hedge',
          label: 'Hedge',
          uri: '/hedges/new'
        }
      );
    }
    return buttons;
  }

  handleMarketDropdown(selection: string) {
    this.marketDropdownSelection = selection;
    switch (selection) {
      case 'Quotes': {
        this.handleRightSheet('QUOTES');
        break;
      }
      default:
        this.handleRightSheet('CASHBIDS');
        break;
    }
  }

  handleRightSheet(display: string) {
    if (!this.rightSheetOpened) {
      this.rightSheetDisplay = display;
      this.rightSheet.toggle();
      // Handle when user click a different commodity or different right sheet display
    } else if (this.rightSheetDisplay !== display) {
      this.rightSheet.toggle();
      setTimeout(() => {
        this.rightSheetDisplay = display;
        this.rightSheet.toggle();
      }, 300);
      // We want to close the sheet when it is ALERTS and user clicks icon again
    } else if (this.rightSheetDisplay === this.ALERTS) {
      this.rightSheet.toggle();
    }
  }

  getRightSheetClass() {
    switch (this.marketDropdownSelection) {
      case 'Quotes': {
        if (this.quotesContainerSize === 'large') {
          return this.rightSheetDisplay === this.QUOTES ? ['quotes-sheet-container-lg'] : [];
        } else {
          return this.rightSheetDisplay === this.QUOTES ? ['quotes-sheet-container-s'] : [];
        }
      }
      default: {
        if (this.cashBidsContainerSize === 'large') {
          return this.rightSheetDisplay === this.CASHBIDS ? ['cash-bids-sheet-container-lg'] : [];
        } else {
          return this.rightSheetDisplay === this.CASHBIDS ? ['cash-bids-sheet-container-s'] : [];
        }
      }
    }
  }

  markAlertComplete(alertDocId: string) {
    this.alertService.updateAppAlertStatus(alertDocId, AlertStatus.COMPLETE)
      .catch(err => {
        console.error(`App alert update failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Alert update failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  markAlertInProgress(alertDocId: string) {
    this.alertService.updateAppAlertStatus(alertDocId, AlertStatus.IN_PROGRESS)
      .catch(err => {
        console.error(`App alert update failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Alert update failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  markAlertNew(alertDocId: string) {
    this.alertService.updateAppAlertStatus(alertDocId, AlertStatus.NEW)
      .catch(err => {
        console.error(`App alert update failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Alert update failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  get silenceAlertDefaultTime() {
    if (this.silenceAlertForm.get('silenceUntilTime').value && this.silenceAlertForm.get('silenceUntilTime').valid) {
      // Construct a moment from the time in the input field and reformat to SILENCE_TIME_FORMAT
      const formattedTime = moment(this.silenceAlertForm.get('silenceUntilTime').value, SILENCE_TIME_FORMAT).format(SILENCE_TIME_FORMAT);
      // To avoid seeing "Invalid DateTime" values in the input field, set the input field to standardized 12 hr time string
      if (this.silenceAlertForm.get('silenceUntilTime').value !== formattedTime) {
        this.silenceAlertForm.get('silenceUntilTime').setValue(formattedTime);
      }
      return formattedTime;
    } else {
      return moment().add(60, 'minutes').format(SILENCE_TIME_FORMAT);
    }
  }

  silenceAlertCustomTime(time: string) {
    const now = moment();
    const future = moment(time, SILENCE_TIME_FORMAT);
    // If not valid, just clear and leave them in the input
    if (!future.isValid()) {
      this.silenceAlertForm.get('silenceUntilTime').setValue('');
      return;
    }
    if (future.isBefore(now)) {
      future.add(1, 'day');
    }
    this.silenceAlertForm.get('silenceUntilTime').setValue(future.format(SILENCE_TIME_FORMAT));
    const milliseconds = future.diff(now);
    this.silenceAlert(milliseconds / 60 / 1000);
  }

  silenceAlert(silenceMinutes: number) {
    const now = moment();
    this.mutedTime = now.add(silenceMinutes, 'minutes').format(SILENCE_TIME_FORMAT);
    setTimeout(() => {
      this.mutedTime = '';
      this.silenceAlertForm.get('silenceUntilTime').setValue('');
    }, silenceMinutes * 60 * 1000);
  }

  private getHmsClientSettingsObs(selectedClient: Client) {
    this.hmsClientSettings$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((commodityMap: CommodityMap) => {
        this.commodityMap = commodityMap;
        return this.clientSettingsService.getHmsSettingsByClientDocId(selectedClient.docId);
      }),
      tap(settings => {
        if (!settings) {
          console.error('missing client settings: ', settings);
          this.errorMessage = 'Client missing HMS settings; please contact your administrator';
        } else if (this.errorMessage.toLocaleLowerCase().includes('missing HMS settings'.toLocaleLowerCase())) {
          // This clears the error if it was generated by a disconnect, but then the connection was re-established.
          this.errorMessage = '';
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving HMS client settings; please try again later';
        console.error(`Error retrieving HMS client settings: ${err}`);
        return of(undefined);
      })
    );
  }

  private getUserAlertRecipientRoles() {
    const userRoles: string[] = [];
    ALERT_USER_ROLES.forEach(role => {
      if (this.authzService.currentUserHasRole(role)) {
        userRoles.push(role);
      }
    });
    return userRoles;
  }

  private canCreateContracts() {
    return this.authzService.currentUserHasRole(UserRoles.CONTRACT_CREATOR_ROLE);
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

}
