import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ActivatedRoute, Params, Router} from '@angular/router';

import * as moment from 'moment';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';

import {Auth0AuthzService} from '@advance-trading/angular-ati-security';
import {ObservableDataSource} from '@advance-trading/angular-common-services';
import {CommodityProfileService, ExecutionReportService, LocationService, OperationsDataService, OrderFill, OrderService} from '@advance-trading/angular-ops-data';
import {
  AdHocOrder,
  AdHocOrderLeg,
  Client,
  Commodity,
  CommodityMap,
  CommodityProfile,
  Contract,
  ContractType,
  Hedge,
  HMSClientSettings,
  LedgerAdjustment,
  LedgerAdjustmentType,
  Location,
  Order,
  PricingSegment,
  PricingSegmentPartType,
  ProductionYear,
  Side
} from '@advance-trading/ops-data-lib';

import {ClientSelectorService} from '../../service/client-selector.service';
import {ClientSettingsService} from '../../service/client-settings.service';
import {ContractService} from '../../service/contract.service';
import {ExportService} from '../../service/export.service';
import {HedgeService} from '../../service/hedge.service';
import {LedgerHelperService} from '../../service/ledger-helper.service';
import {PricingSegmentService} from '../../service/pricing-segment.service';
import {UserRoles} from '../../utilities/user-roles';

import {ReconciliationDisplay} from './reconciliation-display';
import {ReconciliationMiniDisplay} from './reconciliation-mini-display';
import {ReconciliationReportableItem} from './reconciliation-reportable-item';
import { LedgerAdjustmentService } from '../../service/ledger-adjustment.service';
import { AdHocOrderService } from '../../service/ad-hoc-order.service';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'hms-reconciliation',
  templateUrl: './reconciliation.component.html',
  styleUrls: ['./reconciliation.component.scss']
})
export class ReconciliationComponent implements OnInit {

  @ViewChild('tabGroup', { static: false }) tabGroupRef: MatTabGroup;

  reconciliationSearchForm: FormGroup = this.formBuilder.group({
    effectiveDate: ['', {updateOn: 'blur'}]
  });

  locationParentHeaders: string[];
  locationColumnsToDisplay: string[];

  clientParentHeaders: string[];
  clientColumnsToDisplay: string[];

  clientMiniColumnsToDisplay: string[] = ['commodityProfile', 'productionYear', 'BUY', 'SELL'];

  detailColumnsToDisplay: string[] = [ 'locationName', 'commodityProfileName', 'accountingSystemId', 'contractOrPricing', 'timestamp',
    'contractType', 'originator', 'deliveryPeriod', 'productionYear', 'displayBUY', 'displaySELL', 'BUY', 'SELL', 'originalBUY',
    'originalSELL', 'basisPrice', 'futuresPrice', 'cashPrice', 'patron', 'comments' ];
  hedgeDetailColumnsToDisplay: string[] = [ 'location', 'commodityProfile', 'timestamp', 'originator', 'deliveryPeriod', 'productionYear',
    'displayBUY', 'displaySELL', 'BUY', 'SELL', 'originalBUY', 'originalSELL', 'basisPrice', 'futuresPrice', 'cashPrice', 'patron', 'comments'
  ];

  productionYearNames: string[] = [];
  clientProductionYears: string[] = [];
  allProductionYears = Object.keys(ProductionYear);

  errorMessage: string;
  isLoading = true;
  showMini = false;
  displayReports = false;

  clientDataSource = new ObservableDataSource<ReconciliationDisplay>();
  clientMiniDataSource = new ObservableDataSource<ReconciliationMiniDisplay>();
  locationDataSource = new ObservableDataSource<ReconciliationDisplay>();
  detailDataSource = new ObservableDataSource<ReconciliationReportableItem>();

  clientHedgeDataSource = new ObservableDataSource<ReconciliationDisplay>();
  clientMiniHedgeDataSource = new ObservableDataSource<ReconciliationMiniDisplay>();
  hedgeTypeDataSource = new ObservableDataSource<ReconciliationDisplay>();
  hedgeDetailDataSource = new ObservableDataSource<ReconciliationReportableItem>();


  commodityProfiles$: Observable<CommodityProfile[]>;
  activeTabIndex = 0;
  navToHedges = false;


  private ledgerEndOfDay: string;
  private timezone: string;
  private selectedClientDocId: string;
  private locations: Location[] = [];
  // displayLocations emptied each run so that we don't display all-zero rows
  // but preserving all previously read locations to avoid excess document reads
  private displayLocations: Location[] = [];
  private commodities: Commodity[];

  private allCommodityProfiles: CommodityProfile[];
  private activeNonSpreadCommodityProfiles: CommodityProfile[];
  private activeCommodityProfiles: CommodityProfile[];

  private contracts: Contract[];
  private reportableItems: ReconciliationReportableItem[] = [];
  private hedgeAdjustmentAndOrderItems: ReconciliationReportableItem[] = [];

  private preservedClientParentHeaders: string[] = ['commodityProfileHeader'];
  private preservedClientColumnsToDisplay: string[] = ['commodityProfileName'];

  private preservedLocationParentHeaders: string[] = ['locationHeader', 'commodityProfileHeader'];
  private preservedLocationColumnsToDisplay: string[] = ['locationName', 'commodityProfileName'];

  private productionYearsWithData: string[] = [];
  private productionYearsWithDataMini: string[] = [];

  private productionYearsWithAdjustmentData: string[] = [];

  private queryParams: Params;
  private scrollFocus: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private adHocOrderService: AdHocOrderService,
    private authzService: Auth0AuthzService,
    private breakpointObserver: BreakpointObserver,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private contractService: ContractService,
    private executionReportService: ExecutionReportService,
    public exportService: ExportService,
    private formBuilder: FormBuilder,
    private hedgeService: HedgeService,
    private ledgerAdjustmentService: LedgerAdjustmentService,
    private ledgerHelperService: LedgerHelperService,
    private locationService: LocationService,
    private operationsDataService: OperationsDataService,
    private orderService: OrderService,
    private pricingSegmentService: PricingSegmentService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.OPERATIONS_REPORTS_GENERATOR_ROLE)) {
      this.errorMessage = 'You do not have permission to access the reconciliation report.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.commodityProfiles$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((selectedClient: Client) => {
        this.selectedClientDocId = selectedClient.docId;
        return this.operationsDataService.getCommodityMap();
      }),
      switchMap((doc: CommodityMap) => {
        this.commodities = Object.values(doc.commodities);
        return this.clientSettingsService.getHmsSettingsByClientDocId(this.selectedClientDocId);
      }),
      switchMap((clientSettings: HMSClientSettings) => {
        this.ledgerEndOfDay = clientSettings.ledgerEndOfDay;
        this.timezone = clientSettings.timezone;
        return this.commodityProfileService.getAllCommodityProfilesByClientDocId(this.selectedClientDocId);
      }),
      tap((allCommodityProfiles: CommodityProfile[]) => {
        this.allCommodityProfiles = allCommodityProfiles;
        this.activeCommodityProfiles = this.allCommodityProfiles.filter(commodityProfile => commodityProfile.isActive);
        this.activeNonSpreadCommodityProfiles = this.activeCommodityProfiles.filter(commodityProfile => !commodityProfile.isSpreadProfile);
        this.clientProductionYears = [];
        this.activeCommodityProfiles.map(commodityProfile => {
          this.clientProductionYears.push(...Object.values(commodityProfile.productionYears).map(productionYear => productionYear.label));
        });
        this.reconciliationSearchForm.get('effectiveDate').setValue(this.currentBusinessDay);
        this.reconciliationSearchForm.markAsDirty();
        this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
          this.queryParams = Object.assign({}, params);
          if (this.queryParams.effectiveDate) {
            this.reconciliationSearchForm.get('effectiveDate').setValue(this.queryParams.effectiveDate);
          }
          this.scrollFocus = this.queryParams.scrollFocus;
          this.activeTabIndex = this.queryParams.activeTabIndex === '1' ? 1 : 0;
          if (this.activeTabIndex === 1) {
            this.navToHedges = true;
          }
          if (Object.keys(params).length) {
            // Mart form as dirty so reset button appears
            this.reconciliationSearchForm.markAsDirty();
            this.isLoading = true;
            this.loadReconciliationReportData();
            if (this.scrollFocus) {
              const observer = new MutationObserver((mutations, obs) => {
                const scrollElement = document.getElementById(this.scrollFocus);
                if (scrollElement) {
                  this.scrollTo(this.scrollFocus);
                  obs.disconnect();
                  return;
                }
              });
              observer.observe(document.body, {
                childList: true
                , subtree: true
                , attributes: false
                , characterData: false
              });
            }
          }
        }));
        this.isLoading = false;
      }));
    // only show mini client on xs screen; other screens show full-sized client and location
    this.breakpointObserver.observe([Breakpoints.XSmall])
      .subscribe(state => {
        if (state.breakpoints[Breakpoints.XSmall]) {
          this.showMini = true;
        } else {
          this.showMini = false;
        }
      });
  }

  get commodityProfileCount() {
    return this.activeNonSpreadCommodityProfiles.length;
  }

  get allCommodityProfileCount() {
    return this.activeCommodityProfiles.length;
  }

  get productionYearCount() {
    const rowcount = this.productionYearsWithDataMini.length;
    return rowcount === 0 ? 4 : rowcount;
  }

  isFirstProfile(dataRow: ReconciliationDisplay) {
    return this.activeNonSpreadCommodityProfiles && this.activeNonSpreadCommodityProfiles[ 0 ].name === dataRow.commodityProfileName;
  }

  isFirstHedgeProfile(dataRow: ReconciliationDisplay) {
    return this.activeCommodityProfiles && this.activeCommodityProfiles[ 0 ].name === dataRow.commodityProfileName;
  }


  isFirstDetailLocation(dataRow: ReconciliationReportableItem) {
    const firstDetailForLocation = this.detailDataSource.data.map(detailRow => detailRow.locationName).indexOf(dataRow.locationName);
    return firstDetailForLocation === this.detailDataSource.data.indexOf(dataRow);
  }

  isFirstDetailLocationCommodity(dataRow: ReconciliationReportableItem) {
    const dataset = this.detailDataSource.data.indexOf(dataRow) > -1 ? this.detailDataSource.data : this.hedgeDetailDataSource.data;
    const locationRows = dataset.filter(detailRow => detailRow.locationName === dataRow.locationName);
    const firstDetailForLocationAndCommodity = locationRows
      .map(detailRow => detailRow.commodityProfileName).indexOf(dataRow.commodityProfileName);
    return firstDetailForLocationAndCommodity === locationRows.indexOf(dataRow);
  }

  isFirstProdYear(dataRow: ReconciliationMiniDisplay) {
    return this.productionYearsWithDataMini && this.productionYearsWithDataMini[ 0 ] === dataRow.productionYear;
  }

  getDisplayAmount(value: number) {
    return value === -1 ? '-' : value.toLocaleString(undefined, {maximumFractionDigits: 2});
  }

  getDetailAnchor(locationName: string, commodityProfileName: string) {
    return `${this.getSafeId(locationName)}_${this.getSafeId(commodityProfileName)}_Details`;
  }

  getDetailCellClassName(dataRow: ReconciliationReportableItem, columnName: string) {
    const classes = ['mat-cell'];
    if (this.isFirstDetailLocationCommodity(dataRow) || dataRow.timestamp==='Total') {
      // add border to top of cell if it's the first row of a given location/commodity combo
      classes.push('thick');
    }
    if (dataRow.timestamp === 'Total') {
      // make summary rows bold
      classes.push('summary');
      if (['basisPrice', 'futuresPrice', 'cashPrice'].includes(columnName)) {
        // make weighted averages italic
        classes.push('weighted-average');
      }
    } else if (dataRow.timestamp === '') {
      classes.push('summary')
    }
    if ([ 'BUY', 'SELL', 'basisPrice', 'futuresPrice', 'cashPrice' ].includes(columnName)) {
      // make numeric cells right-aligned
      classes.push('number-cell');
      if (!dataRow.contributoryQuantity) {
        classes.push('not-applied');
      }
    }
    if ([ 'locationName', 'commodityProfileName' ].includes(columnName)) {
      classes.push('cdk-visually-hidden');
    }
    if (columnName === 'timestamp') {
      // make timestamp fixed width to force wrapping after date
      classes.push('forced-wrap');
    }
    if (columnName === 'comments') {
      classes.push('comments');
    }
    return classes.join(' ');
  }

  getDetailToolTip(dataRow: ReconciliationReportableItem) {
    if (['Total', ''].includes(dataRow.timestamp)) {
      return `View ${dataRow.locationName} Summary`;
    } else if (dataRow.locationName === 'Hedges') {
      return 'View Hedge';
    } else if (dataRow.locationName === 'Ledger Adjustments') {
      return 'View Ledger Adjustment';
    } else if (dataRow.locationName === 'Ad Hoc Orders') {
      return 'View As Hoc Order';
    } else if (dataRow.segmentDocId) {
      return 'View Pricing';
    } else if (dataRow.contractDocId) {
      return 'View Contract';
    } else {
      return `View ${dataRow.locationName} Summary`;
    }
  }

  getFirstHedgeDetailId(locationName: string, commodityProfileName): string {
    const firstDetailRow = this.hedgeDetailDataSource.data.filter((row: ReconciliationReportableItem) =>
      row.locationName === locationName && row.commodityProfileName === commodityProfileName)[ 0 ];
    return this.getRowId(firstDetailRow);
  }

  getFirstDetailId(locationName: string, commodityProfileName): string {
    const firstDetailRow = this.detailDataSource.data.filter((row: ReconciliationReportableItem) =>
      row.locationName === locationName && row.commodityProfileName === commodityProfileName)[ 0 ];
    return this.getRowId(firstDetailRow);
  }

  getLocationRowToolTip(locationRow: ReconciliationDisplay): string {
    const prefix = this.hasQuantity(locationRow) ? 'View Details' : 'No Data';
    return `${prefix} for ${locationRow.locationName} ${locationRow.commodityProfileName}`;
  }

  getLocationRowClassName(locationRow: ReconciliationDisplay): string {
    return this.hasQuantity(locationRow) ? 'has-data': 'no-data';
  }

  getSummaryAnchor(locationName: string) {
    return `${this.getSafeId(locationName)}_Summary`;
  }

  get ledgerEndOfDayLocal() {
    return moment(this.currentBusinessDay).add(1,'millisecond').format('h:mm A');
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMax')) {
      return 'Value Invalid';
    }
    return 'Unknown Error';
  }

  getRowId(dataRow: ReconciliationReportableItem) {
    // begin ids with underscore since numeric first characters are invalid, and if they're replaced with underscores as in
    // this.getSafeId, there's a minor chance of a collision
    if (dataRow.locationName === 'Ledger Adjustments') {
      return `ledger_adjustment_${dataRow.contractDocId}_`;
    } else if (dataRow.segmentDocId) {
      if (dataRow.locationName === 'Exchanges') {
        return `exchange_${dataRow.segmentDocId}_${dataRow.contractDocId}_`;
      } else if (dataRow.locationName === 'Orders') {
        return `order_${dataRow.segmentDocId}_${dataRow.contractDocId}_`;;
      } else {
        return `_${dataRow.segmentDocId}_${dataRow.contractDocId}_`;
      }
    } else if (dataRow.contractDocId) {
      if (dataRow.locationName === 'Exchanges') {
        return `exchange_${dataRow.contractDocId}_`;
      } else {
        return `_${dataRow.contractDocId}_`;
      }
    } else {
      return this.getDetailAnchor(dataRow.locationName, dataRow.commodityProfileName);
    }
  }

  handleDetailRowClick(dataRow: ReconciliationReportableItem) {
    const rowId = this.getRowId(dataRow);
    if (dataRow.segmentDocId && rowId.indexOf('order') !== 0) {
      this.storeScrollFocus(rowId).then(() => this.router.navigate([ '/contracts', dataRow.contractDocId ]
        , { queryParams: { segmentId: dataRow.segmentDocId }, queryParamsHandling: 'merge' }));
    } else if (dataRow.contractDocId) {
      if (rowId.indexOf('order') === 0) {
        this.storeScrollFocus(rowId).then(() => this.router.navigate([ '/accounts', dataRow.contractDocId, 'orders', dataRow.segmentDocId ]));
      } else if (rowId.indexOf('ledger_adjustment') === 0) {
        this.storeScrollFocus(rowId).then(() => this.router.navigate([ '/liveledgers/ledgeradjustments', dataRow.contractDocId ]));
      } else {
        this.storeScrollFocus(rowId).then(() => this.router.navigate([ '/contracts', dataRow.contractDocId ]));
      }
    } else {
      this.scrollTo(this.getSummaryAnchor(dataRow.locationName));
    }
  }

  handleHedgeDetailRowClick(dataRow: ReconciliationReportableItem) {
    const rowId = this.getRowId(dataRow);
    if (dataRow.segmentDocId) {
      this.storeScrollFocus(rowId).then(() => this.router.navigate([ '/contracts', dataRow.contractDocId ]
        , { queryParams: { segmentId: dataRow.segmentDocId }, queryParamsHandling: 'merge' }));
    } else if (dataRow.contractDocId) {
      this.storeScrollFocus(rowId).then(() => this.router.navigate([ '/contracts', dataRow.contractDocId ]));
    } else {
      this.scrollTo(this.getSummaryAnchor(dataRow.locationName));
    }
  }

  reset() {
    this.reconciliationSearchForm.get('effectiveDate').setValue('');
    this.scrollFocus = '';
    this.activeTabIndex = 0;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });
    this.reconciliationSearchForm.markAsPristine();
    this.displayReports = false;
  }

  loadReconciliationReportData() {
    this.isLoading = true;

    this.displayLocations = [];

    let effectiveDate = this.reconciliationSearchForm.get('effectiveDate').value ? this.ledgerHelperService.forceLedgerEndOfDay(
      moment(this.getDatepickerValueAsISOString('effectiveDate')), this.ledgerEndOfDay, this.timezone) : undefined;

    if (!effectiveDate) {
      // if no end date exists, effective date is today
      effectiveDate =  this.currentBusinessDay;
      this.reconciliationSearchForm.get('effectiveDate').setValue(effectiveDate);
      this.reconciliationSearchForm.markAsDirty();
    }

    this.queryParams.effectiveDate = effectiveDate;
    this.queryParams.scrollFocus = this.scrollFocus;
    this.queryParams.activeTabIndex = this.activeTabIndex;

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });

    const startOfEffectiveDate = moment(effectiveDate).subtract(1, 'day').add(1, 'millisecond').toISOString();

    this.hedgeAdjustmentAndOrderItems = [];
    const adjustmentTypes = [ 'Exchanges', 'Orders', 'Ledger Adjustments' ];
    this.locationDataSource.data$ = this.loadContractReportableItems(this.selectedClientDocId, startOfEffectiveDate, effectiveDate).pipe(
      switchMap((contractReportableItems: ReconciliationReportableItem[][]) => {
        this.reportableItems = contractReportableItems.flat();
        return this.loadOrderReportableItems(this.selectedClientDocId, startOfEffectiveDate, effectiveDate);
      }),
      switchMap((orderReportableItems: ReconciliationReportableItem[][]) => {

        this.hedgeAdjustmentAndOrderItems = this.hedgeAdjustmentAndOrderItems.concat(orderReportableItems.flat());
        return this.loadLedgerAdjustmentReportableItems(this.selectedClientDocId, startOfEffectiveDate, effectiveDate);
      }),
      switchMap((ledgerAdjustmentReportableItems: ReconciliationReportableItem[]) => {
        this.hedgeAdjustmentAndOrderItems = this.hedgeAdjustmentAndOrderItems.concat(ledgerAdjustmentReportableItems);
        return this.loadPricingSegmentReportableItems(this.selectedClientDocId, startOfEffectiveDate, effectiveDate);
      }),
      map((segmentReportableItems: ReconciliationReportableItem[][]) => {
        this.reportableItems = this.reportableItems.concat(...segmentReportableItems.flat());
        this.reportableItems.filter(reportableItem => reportableItem.isOffset === true).map(offsetItem => {
          const parentRow = this.reportableItems.find(
            parentItem => parentItem.contractDocId === offsetItem.contractDocId && parentItem.segmentDocId === undefined);
          if (parentRow) {
            parentRow.BUY += offsetItem.BUY;
            parentRow.SELL += offsetItem.SELL;
            // remove parent if it gets zeroed out
            if (parentRow.BUY === 0 && parentRow.SELL === 0) {
              this.reportableItems.splice(this.reportableItems.indexOf(parentRow), 1);
            }
            this.reportableItems.splice(this.reportableItems.indexOf(offsetItem));
          }
        });
        this.locations.sort((a, b) => a.name > b.name ? 1 : -1);
        this.activeNonSpreadCommodityProfiles.sort((a, b) => a.name > b.name ? 1 : -1);
        const locationRows: ReconciliationDisplay[] = [];
        const clientRows: ReconciliationDisplay[] = [];
        const detailRows: ReconciliationReportableItem[] = [];
        const adjustmentClientRows: ReconciliationDisplay[] = [];
        const adjustmentTypeRows: ReconciliationDisplay[] = [];
        const adjustmentDetailRows: ReconciliationReportableItem[] = [];
        this.productionYearsWithData = [];
        this.productionYearsWithAdjustmentData = [];

        this.displayLocations.sort((a, b) => a.name < b.name ? -1 : 1).map((location: Location) => {
          const locationDetailRows = this.reportableItems.filter(reportableItem => reportableItem.locationName === location.name);
          this.activeNonSpreadCommodityProfiles.map((commodityProfile: CommodityProfile) => {
            const profileProdYears = Object.values(commodityProfile.productionYears).map(productionYear => productionYear.label);
            const oldProdYearDefaultValue = this.getDefaultForProfileProdYear(profileProdYears, ProductionYear.OLD);
            const newProdYearDefaultValue = this.getDefaultForProfileProdYear(profileProdYears, ProductionYear.NEW);
            const newPlus1ProdYearDefaultValue = this.getDefaultForProfileProdYear(profileProdYears, ProductionYear.NEW_PLUS_1);
            const newPlus2ProdYearDefaultValue = this.getDefaultForProfileProdYear(profileProdYears, ProductionYear.NEW_PLUS_2);
            let commodityProfileTotalRowIndex = clientRows.map(
              (totalRow: ReconciliationDisplay) => totalRow.locationName).indexOf(`${commodityProfile.name} Total`);
            if (commodityProfileTotalRowIndex === -1) {
              commodityProfileTotalRowIndex = clientRows.length;
              clientRows.push({
                locationName: `${commodityProfile.name} Total`,
                commodityProfileName: commodityProfile.name,
                OLD_BUY: oldProdYearDefaultValue,
                OLD_SELL: oldProdYearDefaultValue,
                NEW_BUY: newProdYearDefaultValue,
                NEW_SELL: newProdYearDefaultValue,
                NEW_PLUS_1_BUY: newPlus1ProdYearDefaultValue,
                NEW_PLUS_1_SELL: newPlus1ProdYearDefaultValue,
                NEW_PLUS_2_BUY: newPlus2ProdYearDefaultValue,
                NEW_PLUS_2_SELL: newPlus2ProdYearDefaultValue,
              });
            }
            const locationCommodityRow = {
              locationName: location.name,
              commodityProfileName: commodityProfile.name,
              OLD_BUY: oldProdYearDefaultValue,
              OLD_SELL: oldProdYearDefaultValue,
              NEW_BUY: newProdYearDefaultValue,
              NEW_SELL: newProdYearDefaultValue,
              NEW_PLUS_1_BUY: newPlus1ProdYearDefaultValue,
              NEW_PLUS_1_SELL: newPlus1ProdYearDefaultValue,
              NEW_PLUS_2_BUY: newPlus2ProdYearDefaultValue,
              NEW_PLUS_2_SELL: newPlus2ProdYearDefaultValue,
            };
            const locationCommodityDetailRows = locationDetailRows
              .filter(reportableItem => reportableItem.commodityProfileName === commodityProfile.name);
            // sort details by timestamp desc
            locationCommodityDetailRows.sort((a, b) => a.timestamp > b.timestamp ? -1 : 1);
            locationCommodityDetailRows.map(reportableItem => {
              const productionYearLabel = reportableItem.productionYear;
              const buyColumn = this.getColumnName(productionYearLabel, true);
              const soldColumn = this.getColumnName(productionYearLabel, false);
              if (reportableItem.contributoryQuantity) {
                locationCommodityRow[ buyColumn ] += reportableItem.BUY ? reportableItem.contributoryQuantity : 0;
                locationCommodityRow[ soldColumn ] += reportableItem.SELL ? reportableItem.contributoryQuantity : 0;
                clientRows[ commodityProfileTotalRowIndex ][ buyColumn ] += reportableItem.BUY ? reportableItem.contributoryQuantity : 0;
                clientRows[ commodityProfileTotalRowIndex ][ soldColumn ] += reportableItem.SELL ? reportableItem.contributoryQuantity : 0;
              }
              if (!this.productionYearsWithData.includes(productionYearLabel)) {
                this.productionYearsWithData.push(productionYearLabel);
              }
            });
            // calculate aggregates for location, insert summary row if there are any for the location and commodity profile
            // initialize quantity variables at 1 as they will be used as divisor for weighted averages; this avoids NaN for rows without
            // given pricing types
            if (locationCommodityDetailRows.length) {
              const rowsWithCashValues = locationCommodityDetailRows
                .filter(locationCommodityDetailRow => Number.isFinite(locationCommodityDetailRow.cashPrice)
                  && locationCommodityDetailRow.contributoryQuantity);
              let locationCommodityCashTotal = 0;
              let locationCommodityCashQuantity = 1;
              if (rowsWithCashValues.length) {
                locationCommodityCashTotal = rowsWithCashValues
                  .map(locationCommodityDetailRow =>
                    locationCommodityDetailRow.cashPrice * (locationCommodityDetailRow.contributoryQuantity))
                  .reduce((a, b) => a + b);
                locationCommodityCashQuantity = rowsWithCashValues
                  .map(locationCommodityDetailRow => locationCommodityDetailRow.contributoryQuantity)
                  .reduce((a, b) => a + b);
              }
              const locationCommodityAverageCashPrice = locationCommodityCashTotal / locationCommodityCashQuantity;

              const rowsWithBasisValues = locationCommodityDetailRows
                .filter(locationCommodityDetailRow => Number.isFinite(locationCommodityDetailRow.basisPrice)
                  && locationCommodityDetailRow.contributoryQuantity);
              let locationCommodityBasisTotal = 0;
              let locationCommodityBasisQuantity = 1;
              if (rowsWithBasisValues.length) {
                locationCommodityBasisTotal = rowsWithBasisValues
                  .map(locationCommodityDetail =>
                    locationCommodityDetail.basisPrice * (locationCommodityDetail.contributoryQuantity))
                  .reduce((a, b) => a + b);
                locationCommodityBasisQuantity = rowsWithBasisValues
                  .map(locationCommodityDetail => locationCommodityDetail.contributoryQuantity)
                  .reduce((a, b) => a + b);
              }
              const locationCommodityAverageBasisPrice = locationCommodityBasisTotal / locationCommodityBasisQuantity;

              const rowsWithFuturesValues = locationCommodityDetailRows
                .filter(locationCommodityDetailRow => Number.isFinite(locationCommodityDetailRow.futuresPrice)
                  && locationCommodityDetailRow.contributoryQuantity);
              let locationCommodityFuturesTotal = 0;
              let locationCommodityFuturesQuantity = 1;
              if (rowsWithFuturesValues.length) {
                locationCommodityFuturesTotal = rowsWithFuturesValues
                  .map(locationCommodityDetail =>
                    locationCommodityDetail.futuresPrice * (locationCommodityDetail.contributoryQuantity))
                  .reduce((a, b) => a + b);
                locationCommodityFuturesQuantity = rowsWithFuturesValues
                  .map(locationCommodityDetail => locationCommodityDetail.contributoryQuantity)
                  .reduce((a, b) => a + b);
              }
              const locationCommodityAverageFuturesPrice = locationCommodityFuturesTotal / locationCommodityFuturesQuantity;
              const locationCommodityTotalBUYRows = locationCommodityDetailRows
                .filter(locationCommodityDetail => locationCommodityDetail.BUY && locationCommodityDetail.contributoryQuantity);
              const locationCommodityTotalBUY = locationCommodityTotalBUYRows.length ? locationCommodityTotalBUYRows
                .map(locationCommodityDetail => locationCommodityDetail.contributoryQuantity).reduce((a, b) => a + b) : 0;
              const locationCommodityTotalSELLRows = locationCommodityDetailRows
                .filter(locationCommodityDetail => locationCommodityDetail.SELL && locationCommodityDetail.contributoryQuantity);
              const locationCommodityTotalSELL = locationCommodityTotalSELLRows.length ? locationCommodityTotalSELLRows
                .map(locationCommodityDetail => locationCommodityDetail.contributoryQuantity).reduce((a, b) => a + b) : 0;
              const locationCommoditySummaryRow = {
                commodityProfileName: commodityProfile.name,
                locationName: location.name,
                timestamp: `Total`,
                BUY: locationCommodityTotalBUY,
                SELL: locationCommodityTotalSELL,
                basisPrice: locationCommodityAverageBasisPrice,
                futuresPrice: locationCommodityAverageFuturesPrice,
                cashPrice: locationCommodityAverageCashPrice,
                contributoryQuantity: locationCommodityTotalBUY + locationCommodityTotalSELL
              } as ReconciliationReportableItem;
              const spacerRow = {
                commodityProfileName: commodityProfile.name,
                locationName: location.name,
                timestamp: '',
                BUY: 0,
                SELL: 0,
                basisPrice: 0,
                futuresPrice: 0,
                cashPrice: 0,
                contributoryQuantity: 0,
                accountingSystemId: location.name,
                contractOrPricing: commodityProfile.name
              } as ReconciliationReportableItem;
              detailRows.push(spacerRow, ...locationCommodityDetailRows, locationCommoditySummaryRow);
            }
            locationRows.push(locationCommodityRow);
          });
        });
        adjustmentTypes.map(type => {
          const typeDetailRows = this.hedgeAdjustmentAndOrderItems.filter(reportableItem => reportableItem.locationName === type);
          this.activeCommodityProfiles.map((commodityProfile: CommodityProfile) => {
            const profileProdYears = Object.values(commodityProfile.productionYears).map(productionYear => productionYear.label);
            const oldProdYearDefaultValue = this.getDefaultForProfileProdYear(profileProdYears, ProductionYear.OLD);
            const newProdYearDefaultValue = this.getDefaultForProfileProdYear(profileProdYears, ProductionYear.NEW);
            const newPlus1ProdYearDefaultValue = this.getDefaultForProfileProdYear(profileProdYears, ProductionYear.NEW_PLUS_1);
            const newPlus2ProdYearDefaultValue = this.getDefaultForProfileProdYear(profileProdYears, ProductionYear.NEW_PLUS_2);
            let commodityProfileTotalRowIndex = adjustmentClientRows.map(
              (totalRow: ReconciliationDisplay) => totalRow.locationName).indexOf(`${commodityProfile.name} Total`);
            if (commodityProfileTotalRowIndex === -1) {
              commodityProfileTotalRowIndex = adjustmentClientRows.length;
              adjustmentClientRows.push({
                locationName: `${commodityProfile.name} Total`,
                commodityProfileName: commodityProfile.name,
                OLD_BUY: oldProdYearDefaultValue,
                OLD_SELL: oldProdYearDefaultValue,
                NEW_BUY: newProdYearDefaultValue,
                NEW_SELL: newProdYearDefaultValue,
                NEW_PLUS_1_BUY: newPlus1ProdYearDefaultValue,
                NEW_PLUS_1_SELL: newPlus1ProdYearDefaultValue,
                NEW_PLUS_2_BUY: newPlus2ProdYearDefaultValue,
                NEW_PLUS_2_SELL: newPlus2ProdYearDefaultValue,
              });
            }
            const typeCommodityRow = {
              locationName: type,
              commodityProfileName: commodityProfile.name,
              OLD_BUY: oldProdYearDefaultValue,
              OLD_SELL: oldProdYearDefaultValue,
              NEW_BUY: newProdYearDefaultValue,
              NEW_SELL: newProdYearDefaultValue,
              NEW_PLUS_1_BUY: newPlus1ProdYearDefaultValue,
              NEW_PLUS_1_SELL: newPlus1ProdYearDefaultValue,
              NEW_PLUS_2_BUY: newPlus2ProdYearDefaultValue,
              NEW_PLUS_2_SELL: newPlus2ProdYearDefaultValue,
            };
            const typeCommodityDetailRows = typeDetailRows
              .filter(reportableItem => reportableItem.commodityProfileName === commodityProfile.name);
            // sort details by timestamp desc
            typeCommodityDetailRows.sort((a, b) => a.timestamp > b.timestamp ? -1 : 1);
            typeCommodityDetailRows.map(reportableItem => {
              const productionYearLabel = reportableItem.productionYear;
              const buyColumn = this.getColumnName(productionYearLabel, true);
              const soldColumn = this.getColumnName(productionYearLabel, false);
              if (reportableItem.contributoryQuantity) {
                typeCommodityRow[ buyColumn ] += reportableItem.BUY ? reportableItem.contributoryQuantity : 0;
                typeCommodityRow[ soldColumn ] += reportableItem.SELL ? reportableItem.contributoryQuantity : 0;
                adjustmentClientRows[ commodityProfileTotalRowIndex ][ buyColumn ] += reportableItem.BUY ? reportableItem.contributoryQuantity : 0;
                adjustmentClientRows[ commodityProfileTotalRowIndex ][ soldColumn ] += reportableItem.SELL ? reportableItem.contributoryQuantity : 0;
              }
              if (!this.productionYearsWithAdjustmentData.includes(productionYearLabel)) {
                this.productionYearsWithAdjustmentData.push(productionYearLabel);
              }
            });
            if (typeCommodityDetailRows.length) {
              const rowsWithCashValues = typeCommodityDetailRows
                .filter(locationCommodityDetailRow => Number.isFinite(locationCommodityDetailRow.cashPrice)
                  && locationCommodityDetailRow.contributoryQuantity);
              let locationCommodityCashTotal = 0;
              let locationCommodityCashQuantity = 1;
              if (rowsWithCashValues.length) {
                locationCommodityCashTotal = rowsWithCashValues
                  .map(locationCommodityDetailRow =>
                    locationCommodityDetailRow.cashPrice * (locationCommodityDetailRow.contributoryQuantity))
                  .reduce((a, b) => a + b);
                locationCommodityCashQuantity = rowsWithCashValues
                  .map(locationCommodityDetailRow => locationCommodityDetailRow.contributoryQuantity)
                  .reduce((a, b) => a + b);
              }
              const locationCommodityAverageCashPrice = locationCommodityCashTotal / locationCommodityCashQuantity;

              const rowsWithBasisValues = typeCommodityDetailRows
                .filter(locationCommodityDetailRow => Number.isFinite(locationCommodityDetailRow.basisPrice)
                  && locationCommodityDetailRow.contributoryQuantity);
              let locationCommodityBasisTotal = 0;
              let locationCommodityBasisQuantity = 1;
              if (rowsWithBasisValues.length) {
                locationCommodityBasisTotal = rowsWithBasisValues
                  .map(locationCommodityDetail =>
                    locationCommodityDetail.basisPrice * (locationCommodityDetail.contributoryQuantity))
                  .reduce((a, b) => a + b);
                locationCommodityBasisQuantity = rowsWithBasisValues
                  .map(locationCommodityDetail => locationCommodityDetail.contributoryQuantity)
                  .reduce((a, b) => a + b);
              }
              const locationCommodityAverageBasisPrice = locationCommodityBasisTotal / locationCommodityBasisQuantity;

              const rowsWithFuturesValues = typeCommodityDetailRows
                .filter(locationCommodityDetailRow => Number.isFinite(locationCommodityDetailRow.futuresPrice)
                  && locationCommodityDetailRow.contributoryQuantity);
              let locationCommodityFuturesTotal = 0;
              let locationCommodityFuturesQuantity = 1;
              if (rowsWithFuturesValues.length) {
                locationCommodityFuturesTotal = rowsWithFuturesValues
                  .map(locationCommodityDetail =>
                    locationCommodityDetail.futuresPrice * (locationCommodityDetail.contributoryQuantity))
                  .reduce((a, b) => a + b);
                locationCommodityFuturesQuantity = rowsWithFuturesValues
                  .map(locationCommodityDetail => locationCommodityDetail.contributoryQuantity)
                  .reduce((a, b) => a + b);
              }
              const locationCommodityAverageFuturesPrice = locationCommodityFuturesTotal / locationCommodityFuturesQuantity;
              const locationCommodityTotalBUYRows = typeCommodityDetailRows
                .filter(locationCommodityDetail => locationCommodityDetail.BUY && locationCommodityDetail.contributoryQuantity);
              const locationCommodityTotalBUY = locationCommodityTotalBUYRows.length ? locationCommodityTotalBUYRows
                .map(locationCommodityDetail => locationCommodityDetail.contributoryQuantity).reduce((a, b) => a + b) : 0;
              const locationCommodityTotalSELLRows = typeCommodityDetailRows
                .filter(locationCommodityDetail => locationCommodityDetail.SELL && locationCommodityDetail.contributoryQuantity);
              const locationCommodityTotalSELL = locationCommodityTotalSELLRows.length ? locationCommodityTotalSELLRows
                .map(locationCommodityDetail => locationCommodityDetail.contributoryQuantity).reduce((a, b) => a + b) : 0;
              const locationCommoditySummaryRow = {
                commodityProfileName: commodityProfile.name,
                locationName: type,
                timestamp: `Total`,
                BUY: locationCommodityTotalBUY,
                SELL: locationCommodityTotalSELL,
                basisPrice: locationCommodityAverageBasisPrice,
                futuresPrice: locationCommodityAverageFuturesPrice,
                cashPrice: locationCommodityAverageCashPrice,
                contributoryQuantity: locationCommodityTotalBUY + locationCommodityTotalSELL
              } as ReconciliationReportableItem;
              const spacerRow = {
                commodityProfileName: commodityProfile.name,
                locationName: type,
                timestamp: '',
                BUY: 0,
                SELL: 0,
                basisPrice: 0,
                futuresPrice: 0,
                cashPrice: 0,
                contributoryQuantity: 0
              } as ReconciliationReportableItem;
              adjustmentDetailRows.push(spacerRow, ...typeCommodityDetailRows, locationCommoditySummaryRow);
            }
            adjustmentTypeRows.push(typeCommodityRow);
          });
        });
        this.locationParentHeaders = [...this.preservedLocationParentHeaders];
        this.locationColumnsToDisplay = [...this.preservedLocationColumnsToDisplay];
        this.clientParentHeaders = [...this.preservedClientParentHeaders];
        this.clientColumnsToDisplay = [...this.preservedClientColumnsToDisplay];
        this.productionYearsWithDataMini = [];
        this.allProductionYears.map(prodYear => {
          if (this.clientProductionYears.includes(prodYear)) {
            this.addDisplayColumns(prodYear);
            this.productionYearsWithDataMini.push(prodYear);
          }
        });
        const clientMiniRows: ReconciliationMiniDisplay[] = [];
        clientRows.map(row => {
          this.productionYearsWithDataMini.map(prodYear => {
            const boughtColumn = this.getColumnName(prodYear, true);
            const soldColumn = this.getColumnName(prodYear, false);
            clientMiniRows.push({
              commodityProfileName: row.commodityProfileName,
              productionYear: prodYear,
              BUY: row[ boughtColumn ],
              SELL: row[ soldColumn ],
            } as ReconciliationMiniDisplay);
          });
        });
        const adjustmentMiniRows: ReconciliationMiniDisplay[] = [];
        adjustmentClientRows.map(row => {
          this.productionYearsWithDataMini.map(prodYear => {
            const boughtColumn = this.getColumnName(prodYear, true);
            const soldColumn = this.getColumnName(prodYear, false);
            adjustmentMiniRows.push({
              commodityProfileName: row.commodityProfileName,
              productionYear: prodYear,
              BUY: row[ boughtColumn ],
              SELL: row[ soldColumn ],
            } as ReconciliationMiniDisplay);
          });
        });
        this.clientDataSource.data$ = of(clientRows);
        this.clientMiniDataSource.data$ = of(clientMiniRows);
        this.detailDataSource.data$ = of(detailRows);

        this.clientHedgeDataSource.data$ = of(adjustmentClientRows);
        this.clientMiniHedgeDataSource.data$ = of(adjustmentMiniRows);
        this.hedgeTypeDataSource.data$ = of(adjustmentTypeRows);
        this.hedgeDetailDataSource.data$ = of(adjustmentDetailRows);
        this.displayReports = true;
        this.isLoading = false;
        return locationRows;
      }),
      catchError(err => {
        this.openSnackBar('Error running report: ' + err.message, 'DISMISS', false);
        console.error(`Error retrieving reportable items: ${err}`);
        this.isLoading = false;
        return of([]);
      })
    );
  }

  onAnimationDone() {
    if (this.navToHedges) {
      this.tabGroupRef.selectedIndex = 1;
      this.navToHedges = false;
    }
  }
  onTabChange(event: MatTabChangeEvent) {
    this.activeTabIndex = event.index;
    this.queryParams.activeTabIndex = this.activeTabIndex;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  public scrollTo(elementId: string) {
    this.storeScrollFocus(elementId);
    const scrollTarget = document.getElementById(elementId);
    scrollTarget.scrollIntoView(true);
    // nudge it down some because the calculation for scrollIntoView scrolls the top of the element to window 0, not to where the element
    // is at the top of its enclosing scrolling element.
    const enclosingSideNav = this.findSideNavAncestor(scrollTarget);
    enclosingSideNav.scrollBy(0, -100);
  }

  private findSideNavAncestor(element: HTMLElement) {
    const parent = element.offsetParent as HTMLElement;
    return parent.tagName.toLowerCase() === 'mat-sidenav-content' ? parent : this.findSideNavAncestor(parent);
  }

  private getDatepickerValueAsISOString(fieldName: string) {
    const val = this.reconciliationSearchForm.get(fieldName).value;
    return val && val.toISOString ? val.toISOString() : val;
  }

  private getDefaultForProfileProdYear(profileProdYears: ProductionYear[], prodYear: ProductionYear) {
    return (!profileProdYears.length && prodYear === ProductionYear.OLD) || profileProdYears.includes(prodYear) ? 0 : -1;
  }

  private addDisplayColumns(prodYear: string) {
    this.productionYearNames.push(prodYear);
    this.clientParentHeaders.push(prodYear);
    this.locationParentHeaders.push(prodYear);
    const buyColumn = this.getColumnName(prodYear, true);
    const soldColumn = this.getColumnName(prodYear, false);
    this.clientColumnsToDisplay.push(buyColumn, soldColumn);
    this.locationColumnsToDisplay.push(buyColumn, soldColumn);
  }

  private getColumnName(prodYear: string, BUY: boolean) {
    return `${prodYear}_${BUY ? 'BUY' : 'SELL'}`;
  }

  private getContractSize(commodityId: string) {
    return this.commodities.find(commodity => [ commodity.id, commodity.gmiSymbol, commodity.electronicOptionsSymbol ].includes(commodityId)).contractSize;
  }

  // creating consistent means of referencing active commodityProfile or a commodityProfile that was active at the time of record creation
  private getSingleCommodityProfile(commodityProfileDocId: string): CommodityProfile {
    return  this.allCommodityProfiles.find(commodityProfile => commodityProfile.docId === commodityProfileDocId);
  }

  private getCommodityProfileByAccountDocIdAndCommodityId(accountDocId: string, commodityId?: string):CommodityProfile {
    const commodityProfilesByAccount = this.allCommodityProfiles.filter(commodityProfile => commodityProfile.accountDocId === accountDocId);
    if (commodityProfilesByAccount.length === 1) {
      return commodityProfilesByAccount[ 0 ];
    } else {
      return commodityProfilesByAccount.find(commodityProfile => commodityProfile.commodityId === commodityId);
    }
  }

  private getSafeId(value: string) {
    return value.replace(/(^\d)|\s|[^A-Za-z0-9]+/g, '_');
  }

  // creating consistent means of referencing active location or a location that was active at the time of record creation
  private getSingleLocation(locationDocId: string): Observable<Location> {
    const activeLocation = this.locations.find(location => location.docId === locationDocId);
    return activeLocation ?
      of(activeLocation) :
      this.locationService.getLocationByDocId(this.selectedClientDocId, locationDocId);
  }

  public get currentBusinessDay(): string {
    return this.ledgerHelperService.getCurrentBusinessDay(this.ledgerEndOfDay, this.timezone);
  }

  // Pull in missing contracts to get production year
  private getSingleContract(contractDocId: string): Observable<Contract> {
    const queriedResultContract = this.contracts.find(contract => contract.docId === contractDocId);
    return queriedResultContract ?
      of(queriedResultContract) :
      this.contractService.getContractByDocId(this.selectedClientDocId, contractDocId);
  }

  private hasQuantity(reconciliationDisplay: ReconciliationDisplay): boolean {
    return Object.values(reconciliationDisplay)
      // ignore non-numeric values
      .filter(value => Number.isFinite(value))
      // ignore -1, used as flag for "no data"
      .filter(numericValue => numericValue >= 0)
      // returns true if at least one has a quantity
      .reduce((a, b) => a + b) > 0;
  }

  private loadLedgerAdjustmentReportableItems(clientDocId: string, startDate: string, endDate: string):
    Observable<ReconciliationReportableItem[]> {
    const types = [
      LedgerAdjustmentType.ROLL,
      LedgerAdjustmentType.STANDARD
    ];
    return this.ledgerAdjustmentService.getLedgerAdjustmentsByTypeAndDate(clientDocId, types, startDate, endDate).pipe(
      map((ledgerAdjustments: LedgerAdjustment[]) => {
        if (ledgerAdjustments.length === 0) {
          return [];
        }
        return ledgerAdjustments.map((ledgerAdjustment: LedgerAdjustment) => {
          const profile = this.getSingleCommodityProfile(ledgerAdjustment.commodityProfileDocId)
          return {
            commodityProfileName: profile.name,
            locationName: 'Ledger Adjustments',
            productionYear: this.ledgerHelperService.getProdYearLabelForLedgerAdjustment(
              ledgerAdjustment, profile, this.ledgerEndOfDay, this.timezone),
            BUY: ledgerAdjustment.quantity > 0 ? ledgerAdjustment.quantity : 0,
            SELL: ledgerAdjustment.quantity < 0 ? ledgerAdjustment.quantity * -1 : 0,
            timestamp: ledgerAdjustment.creationTimestamp,
            contractType: 'LEDGER_ADJUSTMENT',
            originator: `${ledgerAdjustment.creatorName}`,
            deliveryPeriod: '',
            patron: '',
            comments: ledgerAdjustment.comments,
            isOffset: false,
            contractDocId: ledgerAdjustment.docId,
            contributoryQuantity: Math.abs(ledgerAdjustment.quantity)
          } as ReconciliationReportableItem;
        });
      })
    );
  }

  private loadOrderReportableItems(clientDocId: string, startDate: string, endDate: string)
    : Observable<ReconciliationReportableItem[][]>{
    const combinedFills: { [ key: string ]: OrderFill[] } = {};
    return combineLatest([ ... new Set(this.allCommodityProfiles.map(commodityProfile => commodityProfile.accountDocId)) ].map((accountDocId: string) => {
      return this.executionReportService.getOrderFillsByAccountDocIdAndDate(this.selectedClientDocId, accountDocId, startDate, endDate)
    })).pipe(
      switchMap((orderFills: { [ key: string ]: OrderFill[] }[]) => {
        orderFills.forEach((fills: { [ key: string ]: OrderFill[] }) => {
          Object.keys(fills).forEach((orderDocId: string) => {
            combinedFills[ orderDocId ] = fills[ orderDocId ];
          });
        });

        if (Object.keys(combinedFills).length === 0) {
          return of([]);
        }

        return combineLatest(Object.keys(combinedFills).map((orderDocId: string) => {
          return this.orderService.findClientOrdersByOrderDocId(this.selectedClientDocId, orderDocId);
        }));
      }),
      map((orders: Order[][]) => {
        const orderMap: { [ key: string ]: Order } = {};
        // store orders in a dictionary for displaying proper price purposes
        orders.flat().forEach((order: Order) => {
          orderMap[ order.docId ] = order;
        });
        const orderFillReports: ReconciliationReportableItem[][] = [];
        Object.keys(combinedFills).forEach((orderDocId: string) => {
          orderFillReports.push(combinedFills[ orderDocId ].map((fill: OrderFill) => {
            const order = orderMap[ fill.orderDocId ];
            const profile = this.getCommodityProfileByAccountDocIdAndCommodityId(order.accountDocId, order.symbol);
            if (fill.legFills) {
              Object.keys(fill.legFills).map(key => {
                const legFill = fill.legFills[ key ];
                const quantity = fill.fillQuantity * this.getContractSize(legFill.security.substring(0,2));
                const security = [ ...legFill.security ];
                const decade = [ ...new Date().getFullYear().toString() ][ 3 ];
                const contractYearMonth = `${decade}${security[ security.length - 2 ]}${security[ security.length - 1 ]}`;
                return {
                  commodityProfileName: profile.name,
                  locationName: 'Orders',
                  productionYear: ProductionYear.OLD,
                  BUY: legFill.side === Side.BUY ? quantity : 0,
                  SELL: legFill.side === Side.SELL ? quantity : 0,
                  timestamp: fill.fillTimestamp,
                  contractType: 'ORDER_LEG',
                  originator: `${order.creatorName}`,
                  deliveryPeriod: contractYearMonth,
                  futuresPrice: legFill.fillPrice,
                  patron: '',
                  comments: legFill.security,
                  isOffset: false,
                  contractDocId: order.accountDocId,
                  segmentDocId: order.docId,
                  contributoryQuantity: quantity,
                } as ReconciliationReportableItem;
              })
            } else {
              const quantity = fill.fillQuantity * this.getContractSize(order.symbol);
              return {
                commodityProfileName: profile.name,
                locationName: 'Orders',
                productionYear: ProductionYear.OLD,
                BUY: order.side === Side.BUY ? quantity : 0,
                SELL: order.side === Side.SELL ? quantity : 0,
                timestamp: fill.fillTimestamp,
                contractType: 'ORDER',
                originator: `${order.creatorName}`,
                deliveryPeriod: order.contractYearMonth,
                futuresPrice: fill.fillPrice,
                patron: '',
                comments: fill.security,
                isOffset: false,
                contractDocId: order.accountDocId,
                segmentDocId: order.docId,
                contributoryQuantity: quantity
              } as ReconciliationReportableItem;
            }
          }));
        });

        // sort result in descending fillTimestamp, but still maintain grouping of orderDocId
        const sortedOrderFillReports = [];
        orderFillReports.forEach((fills: ReconciliationReportableItem[]) => {
          sortedOrderFillReports.push(fills.sort((a, b) => b.timestamp.localeCompare(a.timestamp)));
        });

        return sortedOrderFillReports.flat();
      }));
  }

  private loadPricingSegmentReportableItems(clientDocId: string, startDate: string, endDate: string)
    : Observable<ReconciliationReportableItem[][]> {
    const excludeDeleted = true;
    const segmentQueries = [
      this.pricingSegmentService.findDpPricingSegmentsByBasisLockedTimestampWithBasisDpFirstPartType(
        clientDocId, startDate, endDate, excludeDeleted),
      this.pricingSegmentService.findDpPricingSegmentsByCashLockedTimestampAndCashPricingType(
        clientDocId, startDate, endDate, excludeDeleted),
      this.pricingSegmentService.findDpPricingSegmentsByFuturesLockedTimestampWithFuturesDpFirstPartType(
        clientDocId, startDate, endDate, excludeDeleted),
      this.pricingSegmentService.findPricingSegmentsByTypeAndCashLockedTimestamp(
        clientDocId, [ContractType.BASIS, ContractType.HTA], startDate, endDate),
      this.pricingSegmentService.findBasisExchangePricingSegmentsByTypeFuturesLockedTimestampAndStatus(clientDocId, startDate, endDate)
    ].concat(this.contracts.map(contract =>
      this.pricingSegmentService.getPricingSegmentsByClientDocIdAndContractDocIdWithDifferentDeliveryLocationDocId(
        contract.clientDocId, contract.docId, contract.deliveryLocationDocId)));
    return combineLatest(segmentQueries).pipe(
      switchMap((resultSet: PricingSegment[][]) => {
        const queriedPricingSegments: PricingSegment[] = resultSet.flat();
        if (queriedPricingSegments.length === 0) {
          return of([]);
        }
        return combineLatest(
          queriedPricingSegments.map((pricingSegment: PricingSegment) => {
            const referencedCommodityProfile = this.getSingleCommodityProfile(pricingSegment.commodityProfileDocId);
            let referencedLocation;
            return this.getSingleLocation(pricingSegment.deliveryLocationDocId).pipe(
              switchMap((singleLocation: Location) => {
                referencedLocation = singleLocation;
                if (!this.locations.find(location => location.docId === singleLocation.docId)) {
                  this.locations.push(singleLocation);
                }
                if (!this.displayLocations.find(location => location.docId === singleLocation.docId)) {
                  this.displayLocations.push(singleLocation);
                }
                return this.getSingleContract(pricingSegment.contractDocId);
              }),
              map((singleContract: Contract) => {
                // prevent double-reads for DP contracts
                if (!this.contracts.find(contract => contract.docId === singleContract.docId)) {
                  this.contracts.push(singleContract);
                }
                const segmentReturnArray: ReconciliationReportableItem[] = [];
                let definitiveTimestamp;
                if (singleContract.type === ContractType.DP) {
                  if (pricingSegment.dpFirstPartType === PricingSegmentPartType.BASIS) {
                    definitiveTimestamp = pricingSegment.basisLockedTimestamp;
                  } else if (pricingSegment.dpFirstPartType === PricingSegmentPartType.FUTURES) {
                    definitiveTimestamp = pricingSegment.futuresLockedTimestamp;
                  } else {
                    // PricingSegmentPartType.CASH
                    definitiveTimestamp = pricingSegment.cashLockedTimestamp;
                  }
                } else if (singleContract.type === ContractType.BASIS) {
                  if (pricingSegment.exchangeContracts) {
                    this.hedgeAdjustmentAndOrderItems.push({
                      commodityProfileName: referencedCommodityProfile.name,
                      locationName: 'Exchanges',
                      productionYear: this.ledgerHelperService.getProdYearLabelForContract(
                        singleContract, referencedCommodityProfile, this.ledgerEndOfDay, this.timezone),
                      BUY: pricingSegment.side === Side.SELL ? pricingSegment.exchangeContracts *
                        this.commodities.find(commodity => commodity.id === referencedCommodityProfile.commodityId).contractSize : 0,
                      SELL: pricingSegment.side === Side.BUY ? pricingSegment.exchangeContracts *
                        this.commodities.find(commodity => commodity.id === referencedCommodityProfile.commodityId).contractSize : 0,
                      timestamp: pricingSegment.cashLockedTimestamp,
                      contractType: 'EXCHANGE',
                      originator: `${pricingSegment.originatorName}`,
                      deliveryPeriod: pricingSegment.deliveryPeriod,
                      basisPrice: Number.isFinite(pricingSegment.basisPrice) ?
                        pricingSegment.basisPrice : singleContract.basisPrice,
                      futuresPrice: Number.isFinite(pricingSegment.futuresPrice) ?
                        pricingSegment.futuresPrice : singleContract.futuresPrice,
                      cashPrice: Number.isFinite(pricingSegment.cashPrice) ?
                        pricingSegment.cashPrice : singleContract.cashPrice,
                      patron: `${singleContract.patronName}`,
                      comments: singleContract.comments,
                      isOffset: false,
                      contractDocId: singleContract.docId,
                      segmentDocId: pricingSegment.docId,
                      contributoryQuantity: pricingSegment.quantity
                    } as ReconciliationReportableItem);
                  }
                  if (pricingSegment.futuresLockedTimestamp &&
                    pricingSegment.deliveryLocationDocId === singleContract.deliveryLocationDocId) {
                    const contractReportableRow = this.reportableItems.find(contractReportableItem =>
                      contractReportableItem.contractDocId === singleContract.docId);
                    if (contractReportableRow) {
                      contractReportableRow.contributoryQuantity -= pricingSegment.quantity;
                    }
                    definitiveTimestamp = pricingSegment.futuresLockedTimestamp;
                  }
                  // revert previous decisions if this segment was pulled to replace parent totals
                  if (singleContract.deliveryLocationDocId !== pricingSegment.deliveryLocationDocId) {
                    definitiveTimestamp = singleContract.basisLockedTimestamp;
                  }
                } else {
                  // ContractType.CASH, ContractType.HTA
                  if (singleContract.type === ContractType.HTA && pricingSegment.cashLockedTimestamp &&
                    pricingSegment.deliveryLocationDocId === singleContract.deliveryLocationDocId) {
                    const contractReportableRow = this.reportableItems.find(contractReportableItem =>
                      contractReportableItem.contractDocId === singleContract.docId);
                    if (contractReportableRow) {
                      contractReportableRow.contributoryQuantity -= pricingSegment.quantity;
                    }
                    definitiveTimestamp = pricingSegment.cashLockedTimestamp;
                  }
                  // revert previous decisions if this segment was pulled to replace parent totals
                  if (singleContract.deliveryLocationDocId !== pricingSegment.deliveryLocationDocId) {
                    definitiveTimestamp = singleContract.futuresLockedTimestamp;
                  }
                }
                const buyQuantity = pricingSegment.side === Side.BUY ? pricingSegment.quantity : 0;
                const sellQuantity = pricingSegment.side === Side.SELL ? pricingSegment.quantity : 0;
                segmentReturnArray.push({
                  commodityProfileName: referencedCommodityProfile.name,
                  locationName: referencedLocation.name,
                  productionYear: this.ledgerHelperService.getProdYearLabelForContract(
                    singleContract, referencedCommodityProfile, this.ledgerEndOfDay, this.timezone),
                  BUY: buyQuantity,
                  SELL: sellQuantity,
                  timestamp: definitiveTimestamp,
                  contractType: singleContract.isSpot ? 'SPOT' : singleContract.type,
                  originator: `${pricingSegment.originatorName}`,
                  deliveryPeriod: pricingSegment.deliveryPeriod,
                  basisPrice: Number.isFinite(pricingSegment.basisPrice) ? pricingSegment.basisPrice : singleContract.basisPrice,
                  futuresPrice: Number.isFinite(pricingSegment.futuresPrice) ? pricingSegment.futuresPrice : singleContract.futuresPrice,
                  cashPrice: Number.isFinite(pricingSegment.cashPrice) ? pricingSegment.cashPrice : singleContract.cashPrice,
                  patron: `${singleContract.patronName}`,
                  comments: singleContract.comments,
                  isOffset: false,
                  contractDocId: singleContract.docId,
                  segmentDocId: pricingSegment.docId,
                  contributoryQuantity: pricingSegment.quantity,
                  contractOrPricing: 'Pricing',
                  accountingSystemId: singleContract.accountingSystemId,
                  originalQuantity: pricingSegment.originalQuantity
                } as ReconciliationReportableItem);
                // these are segments with a different delivery location than their parent contract. Originally we were adding an offset
                // row, now subtracting from parent contract. Will not display in details.
                if (singleContract.deliveryLocationDocId !== pricingSegment.deliveryLocationDocId) {
                  segmentReturnArray.push({
                    commodityProfileName: referencedCommodityProfile.name,
                    locationName: singleContract.deliveryLocationName,
                    productionYear: this.ledgerHelperService.getProdYearLabelForContract(
                      singleContract, referencedCommodityProfile, this.ledgerEndOfDay, this.timezone),
                    BUY: pricingSegment.side === Side.BUY ? pricingSegment.quantity * -1 : 0,
                    SELL: pricingSegment.side === Side.SELL ? pricingSegment.quantity * -1 : 0,
                    timestamp: definitiveTimestamp,
                    contractType: singleContract.isSpot ? 'SPOT' : singleContract.type,
                    originator: `${pricingSegment.originatorName}`,
                    deliveryPeriod: pricingSegment.deliveryPeriod,
                    basisPrice: Number.isFinite(pricingSegment.basisPrice) ? pricingSegment.basisPrice : singleContract.basisPrice,
                    futuresPrice: Number.isFinite(pricingSegment.futuresPrice) ? pricingSegment.futuresPrice : singleContract.futuresPrice,
                    cashPrice: Number.isFinite(pricingSegment.cashPrice) ? pricingSegment.cashPrice : singleContract.cashPrice,
                    patron: `${singleContract.patronName}`,
                    comments: singleContract.comments,
                    isOffset: true,
                    contractDocId: singleContract.docId,
                    segmentDocId: pricingSegment.docId,
                    contributoryQuantity: pricingSegment.quantity * -1,
                    contractOrPricing: 'Pricing',
                    accountingSystemId: singleContract.accountingSystemId,
                    originalQuantity: pricingSegment.originalQuantity
                  } as ReconciliationReportableItem);
                }
                return segmentReturnArray;
              })
            );
          })
        );
      })
    );
  }

  private loadContractReportableItems(clientDocId: string, startDate: string, endDate: string)
    : Observable<ReconciliationReportableItem[][]> {
    const excludeDeleted = true;
    const excludeExchange = true;
    return combineLatest([
      this.contractService.getContractsByTypeAndFuturesLockedTimestamp(
        clientDocId, [ContractType.CASH, ContractType.HTA], startDate, endDate, excludeDeleted, excludeExchange),
      this.contractService.getContractsByTypeAndBasisLockedTimestamp(
        clientDocId, [ContractType.BASIS], startDate, endDate, excludeDeleted, excludeExchange),
      this.contractService.getExchangeContractsByTypeAndCompletionTimestamp(
        clientDocId, [ContractType.CASH, ContractType.HTA], startDate, endDate)
    ]).pipe(
      switchMap(([
        htaContracts,
        basisContracts,
        exchangeContracts
      ]) => {
        const queriedContracts: Contract[] = htaContracts.concat(basisContracts, exchangeContracts);
        this.contracts = queriedContracts;
        if (queriedContracts.length === 0) {
          this.contracts = [];
          return of([]);
        }
        return combineLatest(
          queriedContracts.map((contract: Contract) => {
            const referencedCommodityProfile = this.getSingleCommodityProfile(contract.commodityProfileDocId);
            return this.getSingleLocation(contract.deliveryLocationDocId).pipe(
              map((singleLocation: Location) => {
                const returnedRows = [];
                if (!this.locations.find(location => location.docId === singleLocation.docId)) {
                  this.locations.push(singleLocation);
                }
                if (!this.displayLocations.find(location => location.docId === singleLocation.docId)) {
                  this.displayLocations.push(singleLocation);
                }
                // futuresLockedTimestamp used for ContractType.CASH, ContractType.HTA
                const definitiveTimestamp = contract.type ===
                  ContractType.BASIS ? contract.basisLockedTimestamp : contract.futuresLockedTimestamp;
                // Only add primary row if timestamp applies (to weed out cash/hta exchange from previous days completed today)
                if (moment(definitiveTimestamp).isSameOrAfter(moment(startDate))) {
                  if (contract.exchangeContracts) {
                    const contractSize = this.getContractSize(referencedCommodityProfile.commodityId);
                    this.hedgeAdjustmentAndOrderItems.push({
                      commodityProfileName: referencedCommodityProfile.name,
                      locationName: 'Exchanges',
                      productionYear: this.ledgerHelperService.getProdYearLabelForContract(
                        contract, referencedCommodityProfile, this.ledgerEndOfDay, this.timezone),
                      BUY: contract.side === Side.SELL ? contract.exchangeContracts * contractSize : 0,
                      SELL: contract.side === Side.BUY ? contract.exchangeContracts * contractSize : 0,
                      timestamp: contract.futuresLockedTimestamp,
                      contractType: 'EXCHANGE',
                      originator: `${contract.originatorName}`,
                      deliveryPeriod: contract.deliveryPeriod,
                      basisPrice: contract.basisPrice,
                      futuresPrice: contract.futuresPrice,
                      cashPrice: contract.cashPrice,
                      patron: `${contract.patronName}`,
                      comments: contract.comments,
                      isOffset: false,
                      contractDocId: contract.docId,
                      contributoryQuantity: contract.quantity,
                      contractOrPricing: 'Contract',
                      accountingSystemId: contract.accountingSystemId
                    } as ReconciliationReportableItem);
                  }
                  returnedRows.push({
                    commodityProfileName: referencedCommodityProfile.name,
                    locationName: singleLocation.name,
                    productionYear: this.ledgerHelperService.getProdYearLabelForContract(
                      contract, referencedCommodityProfile, this.ledgerEndOfDay, this.timezone),
                    BUY: contract.side === Side.BUY ? contract.quantity : 0,
                    SELL: contract.side === Side.SELL ? contract.quantity : 0,
                    timestamp: definitiveTimestamp,
                    contractType: contract.isSpot ? 'SPOT' : contract.type,
                    originator: `${contract.originatorName}`,
                    deliveryPeriod: contract.deliveryPeriod,
                    basisPrice: contract.basisPrice,
                    futuresPrice: contract.futuresPrice,
                    cashPrice: contract.cashPrice,
                    patron: `${contract.patronName}`,
                    comments: contract.comments,
                    isOffset: false,
                    contractDocId: contract.docId,
                    contributoryQuantity: contract.quantity,
                    contractOrPricing: 'Contract',
                    accountingSystemId: contract.accountingSystemId,
                    originalQuantity: contract.originalQuantity
                  } as ReconciliationReportableItem);
                }
                return returnedRows;
              })
            );
          })
        );
      })
    );
  }

  private storeScrollFocus(elementId: string): Promise<boolean> {
    this.scrollFocus = elementId;
    this.queryParams.scrollFocus = this.scrollFocus;
    return this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

}
