import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DeliveryRoll } from '../utilities/delivery-roll';
import { FuturesRoll } from '../utilities/futures-roll';

@Injectable({
  providedIn: 'root'
})
export class BatchRollService {

  constructor(
    @Inject('environment') private environment,
    private http: HttpClient
  ) {}

  /**
   * Update Contracts and PricingSegments to have its delivery period rolled
   * by calling a http service
   * @param deliveryRoll The request body to perform batch delivery period roll
   * @param accessToken The auth0 accessToken
   */
  rollDeliveryPeriod(deliveryRoll: DeliveryRoll, accessToken: string): Observable<any> {
    return this.http.post(this.environment.services.httpPeriodRolls + '/rolls/delivery', deliveryRoll,
      {headers: new HttpHeaders().set('Authorization', `Bearer ${accessToken}`)});
  }

  /**
   * Update Contracts and PricingSegments to have its futures period rolled
   * by calling a http service
   * @param futuresRoll The request body to perform batch futures period roll
   * @param accessToken The auth0 accessToken
   */
  rollFuturesPeriod(futuresRoll: FuturesRoll, accessToken: string): Observable<any>  {
    return this.http.post(this.environment.services.httpPeriodRolls + '/rolls/futures', futuresRoll,
      {headers: new HttpHeaders().set('Authorization', `Bearer ${accessToken}`)});
  }

}
