<div fxLayout="column">
  <h4>{{ commodityProfile ? 'Positions Summary' : 'Long / Short Summary' }}</h4>
  <mat-table [dataSource]="dataSource" class="mat-elevation-z0">
    <ng-container matColumnDef="commodityProfile">
      <mat-header-cell *matHeaderCellDef>{{ commodityProfile ? '' : 'Profile' }}</mat-header-cell>
      <mat-cell *matCellDef="let positionsSummary">
        <div>
          <div>{{ commodityProfile ? 'Long/Short' : positionsSummary.commodityProfile.name }}</div>
          <div class="rollover-total">Rollover</div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="label" *ngFor="let label of profileProdYearLabels">
      <mat-header-cell *matHeaderCellDef>{{ label | productionYear }}</mat-header-cell>
      <mat-cell *matCellDef="let positionsSummary">
        <div>
          <div>{{ isValidNumber(positionsSummary.hedgeableProdYears[label]) ?  (positionsSummary.hedgeableProdYears[label] | number:'1.0-0') : ((positionsSummary.rolloverProdYears[label] | number:'1.0-0') || 0) }}</div>
          <div class="rollover-total">{{ isValidNumber(positionsSummary.rolloverProdYears[label]) ? (positionsSummary.rolloverProdYears[label] | number:'1.0-0') : 0}}</div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay;"></mat-header-row>
    <mat-row *matRowDef="let positionsSummary; columns: columnsToDisplay"></mat-row>
  </mat-table>
</div>
