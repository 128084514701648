import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { Client, HMSPriceAdjustment, HMSPriceAdjustmentMap } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { PriceAdjustmentService } from '../../service/price-adjustment.service';
import { UserRoles } from '../../utilities/user-roles';

import { PriceAdjustmentComponent } from '../price-adjustment/price-adjustment.component';

@Component({
  selector: 'hms-price-adjustments',
  templateUrl: './price-adjustments.component.html',
  styleUrls: ['./price-adjustments.component.scss']
})
export class PriceAdjustmentsComponent implements OnInit {
  priceAdjustmentsForm = this.formBuilder.group({
    priceAdjustments: this.formBuilder.group({})
  });

  editMode = false;
  updateComplete = false;
  errorMessage: string;
  priceAdjustmentMap$: Observable<HMSPriceAdjustmentMap>;
  existingPriceAdjustmentKeys: string[] = [];

  private selectedClientDocId: string;
  private priceAdjustmentMap: HMSPriceAdjustmentMap;

  @ViewChild('newPriceAdjustment', { static: false }) private newPriceAdjustment: PriceAdjustmentComponent;

  constructor(
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private formBuilder: FormBuilder,
    private priceAdjustmentService: PriceAdjustmentService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.CLIENT_SETTINGS_ADMIN_ROLE)) {
      this.errorMessage = 'You do not have permission to administer price adjustments.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }
    this.priceAdjustmentMap$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((selectedClient: Client) => {
        this.selectedClientDocId = selectedClient.docId;
        return this.priceAdjustmentService.getHMSPriceAdjustmentMapByClientDocId(this.selectedClientDocId);
      }),
      map((adjustmentMap: HMSPriceAdjustmentMap) => {
        this.updateComplete = true;
        this.priceAdjustmentMap = adjustmentMap || new HMSPriceAdjustmentMap().getPlainObject();
        this.setupPriceAdjustmentsForm();
        this.setEditMode(false);
        return this.priceAdjustmentMap;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving price adjustments; please try again later';
        console.error(`Error retrieving priceAdjustments: ${err}`);
        this.updateComplete = true;
        return of(undefined);
      })
    );
  }

  get uncommittedAdjustmentChangesExist() {
    const priceAdjustmentsFormGroup: FormGroup = this.priceAdjustmentsForm.get('priceAdjustments') as FormGroup;
    return Object.values(priceAdjustmentsFormGroup.controls).find((control: FormGroup) => control.dirty);
  }

  setEditMode(mode) {
    this.editMode = mode;
    if (this.editMode) {
      this.priceAdjustmentsForm.enable();
    } else {
      this.priceAdjustmentsForm.disable();
      this.setupPriceAdjustmentsForm();
    }
  }

  updatePriceAdjustments() {
    this.updateComplete = false;

    // instantiate new priceAdjustments so .getPlainObject will work on set
    const submittedPriceAdjustmentMap = new HMSPriceAdjustmentMap();
    const priceAdjustmentFormGroup: FormGroup = this.priceAdjustmentsForm.get('priceAdjustments') as FormGroup;
    submittedPriceAdjustmentMap.priceAdjustments = {};
    Object.values(priceAdjustmentFormGroup.controls).forEach((priceAdjustment: FormGroup) => {
      const id = priceAdjustment.get('id').value;
      const name = priceAdjustment.get('name').value;
      submittedPriceAdjustmentMap.priceAdjustments[id] = { id, name } as HMSPriceAdjustment;
    });

    // preserve priceAdjustmentMap since we're remaining on page
    this.priceAdjustmentMap = submittedPriceAdjustmentMap.getPlainObject();

    // Update the PriceAdjustments object in Firestore
    this.priceAdjustmentService.setHMSPriceAdjustmentMapByClientDocId(this.selectedClientDocId, submittedPriceAdjustmentMap)
      .then(() => {
        this.updateComplete = true;
        console.log('Price adjustments successfully updated');
        this.setEditMode(false);
        this.openSnackBar('Price adjustments successfully updated', 'DISMISS', true);
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`Price Adjustments update failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Price Adjustments update failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  onPriceAdjustmentCreated(priceAdjustment: FormGroup) {
    const id: string = priceAdjustment.get('id').value;
    const name: string = priceAdjustment.get('name').value;
    const clonedForm = this.formBuilder.group({
      adjustmentKey: [id],
      id: [id],
      name: [name]
    });
    const priceAdjustmentsFormGroup: FormGroup = this.priceAdjustmentsForm.get('priceAdjustments') as FormGroup;
    priceAdjustmentsFormGroup.addControl(id, clonedForm);
    this.updateExistingPriceAdjustmentKeys();
    this.newPriceAdjustment.setupPriceAdjustmentForm();
    this.priceAdjustmentsForm.markAsDirty();
  }

  onPriceAdjustmentRemoved(priceAdjustment: FormGroup) {
    const adjustmentsKey: string = priceAdjustment.get('adjustmentKey').value;
    const priceAdjustmentFormGroup: FormGroup = this.priceAdjustmentsForm.get('priceAdjustments') as FormGroup;
    priceAdjustmentFormGroup.removeControl(adjustmentsKey);
    this.updateExistingPriceAdjustmentKeys();
    this.priceAdjustmentsForm.markAsDirty();
  }

  onPriceAdjustmentUpdated(priceAdjustment: FormGroup) {
    const adjustmentKey: string = priceAdjustment.get('adjustmentKey').value;
    const id: string = priceAdjustment.get('id').value;
    const priceAdjustmentsFormGroup: FormGroup = this.priceAdjustmentsForm.get('priceAdjustments') as FormGroup;
    if (id === adjustmentKey) {
      // no update to id
      priceAdjustmentsFormGroup.setControl(adjustmentKey, priceAdjustment);
    } else {
      // id updated, need to remove old key
      priceAdjustmentsFormGroup.removeControl(adjustmentKey);
      // set key to updated id
      priceAdjustment.get('adjustmentKey').setValue(id);
      priceAdjustmentsFormGroup.addControl(id, priceAdjustment);
      this.updateExistingPriceAdjustmentKeys();
    }
    this.priceAdjustmentsForm.markAsDirty();
  }

  trackBySettingKey(index: number, el: any): string {
    return (el.value as FormGroup).get('adjustmentKey').value;
  }

  private setupPriceAdjustmentsForm() {
    const priceAdjustmentsFormGroup: FormGroup = this.priceAdjustmentsForm.get('priceAdjustments') as FormGroup;
    Object.keys(priceAdjustmentsFormGroup.controls).forEach(settingKey => priceAdjustmentsFormGroup.removeControl(settingKey));
    Object.keys(this.priceAdjustmentMap.priceAdjustments).forEach(priceAdjustmentKey => {
      const priceAdjustmentForm = this.formBuilder.group({
        adjustmentKey: [priceAdjustmentKey],
        id: [priceAdjustmentKey, [Validators.required]],
        name: [this.priceAdjustmentMap.priceAdjustments[priceAdjustmentKey].name, [Validators.required]]
      });
      priceAdjustmentsFormGroup.addControl(priceAdjustmentKey, priceAdjustmentForm);
    });
    this.updateExistingPriceAdjustmentKeys();
    this.priceAdjustmentsForm.markAsPristine();
  }

  private updateExistingPriceAdjustmentKeys() {
    const priceAdjustmentFormGroup: FormGroup = this.priceAdjustmentsForm.get('priceAdjustments') as FormGroup;
    this.existingPriceAdjustmentKeys =
      Object.keys(priceAdjustmentFormGroup.controls).map(priceAdjustmentKey => priceAdjustmentKey.toUpperCase());
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

}
