<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <hms-error-message [title]="'Error Retrieving Location Details'" [message]="errorMessage"></hms-error-message>

    <form *ngIf="!errorMessage && location$ | async as location" [formGroup]="locationForm" (ngSubmit)="saveForm()" fxLayout="column" fxLayoutGap="15px">
      <!--Title and Buttons-->
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="card-title">Location: {{ location ? location.name : '' }}</h2>
        <mat-spinner *ngIf="isLoading" class="loading-spinner" diameter="40" color="accent"></mat-spinner>
        <div *ngIf="!isLoading" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
            <button type="button" id="edit-location" class="action-button" aria-label="Edit Location" matTooltip="Edit Location" *ngIf="!editMode && updateComplete && isLocationAdmin" (click)="setEditMode(true)" mat-mini-fab color="accent">
              <mat-icon>edit</mat-icon>
            </button>
            <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
            <button type="submit" id="update-location" class="action-button" aria-label="Update Location" matTooltip="Update Location" *ngIf="editMode && updateComplete" [disabled]="!locationForm.valid || locationForm.pristine || partialNewSettingEntered" (click)="saveForm()" mat-mini-fab color="accent">
              <mat-icon>done</mat-icon>
            </button>
            <button type="button" id="cancel-edit-location" class="action-button" aria-label="Discard Changes" matTooltip="Discard Changes" *ngIf="editMode && updateComplete" (click)="setEditMode(false)" mat-mini-fab color="">
              <mat-icon>clear</mat-icon>
            </button>
          </div>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100">
        <ati-labeled-data fxFlex="75"  *ngIf="!editMode" label="Name" [data]="locationForm.get('name').value"></ati-labeled-data>
        <mat-form-field  *ngIf="editMode" fxFlex.gt-xs="75" fxFlex.xs="100">
          <input required matInput placeholder="Name" formControlName="name" trim="blur">
          <mat-error *ngIf="locationForm.get('name').invalid">{{getErrorMessage(locationForm.get('name'))}}</mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="start end" fxFlex="100">
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" *ngIf="!editMode" label="Status" [data]="locationForm.get('isActive').value ? 'Active' : 'Inactive'"></ati-labeled-data>
        <div *ngIf="editMode" class="checkbox-group" fxFlex.gt-xs="25" fxFlex.xs="50">
          <label class="check-radio-label mat-small mat-hint">Status</label>
          <mat-checkbox formControlName="isActive">Active</mat-checkbox>
        </div>
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="Client Location" [data]="location.isClientLocation | yesNo"></ati-labeled-data>
      </div>

      <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100">
        <ati-labeled-data fxFlex.gt-xs="25" fxFlex.xs="50" label="ID" [data]="location.accountingSystemId"></ati-labeled-data>
      </div>

        <div *ngIf="location?.address || editMode" fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
        <h4>Address</h4>
        <button *ngIf="!enableAddress && !location?.address" [disabled]="locationForm.disabled" id="add-location-address" class="action-button" type="button" aria-label="Add Address" matTooltip="Add Address" color="accent" mat-icon-button (click)="showAddress()">
          <mat-icon>add_circle</mat-icon>
        </button>
        <button *ngIf="enableAddress && !location?.address" [disabled]="locationForm.disabled" id="cancel-location-address" class="action-button" type="button" aria-label="Cancel Address" matTooltip="Cancel Address" color="primary" mat-icon-button (click)="hideAddress()">
          <mat-icon>cancel</mat-icon>
        </button>
        <button *ngIf="editMode && location?.address" [disabled]="locationForm.disabled" id="delete-location-address" class="action-button" type="button" aria-label="Delete Address" matTooltip="Delete Address" color="warn" mat-icon-button (click)="clearAddress()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div *ngIf="location?.address || editMode" fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
        <ati-address *ngIf="enableAddress" [editMode]="editMode" [addressForm]="locationForm.get('address')"></ati-address>
      </div>

      <mat-expansion-panel *ngIf="phoneNumbers.length || editMode" class="mat-elevation-z0" expanded="phoneNumbers.length">
        <mat-expansion-panel-header>
          <mat-panel-title>Phone Numbers</mat-panel-title>
        </mat-expansion-panel-header>
        <ati-phone-number *ngFor="let phoneNumber of phoneNumbers; let index = index" [editMode]="editMode" [phoneNumber]="phoneNumber" (numberUpdated)="phoneNumbers[index] = $event; locationForm.markAsDirty()" (numberRemoved)="onPhoneNumberRemoved(index)"></ati-phone-number>
        <ati-phone-number *ngIf="editMode" [editMode]="editMode" (numberUpdated)="onPhoneNumberCreated($event)"></ati-phone-number>
      </mat-expansion-panel>

      <h4>Accounting System Settings</h4>
      <div *ngIf="!editMode" fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100">
        <ati-labeled-data fxFlex="100" *ngFor="let accountingSetting of existingAccountingSettingsKeys" [label]="accountingSetting" [data]="appearsBoolean(location.accountingSystemSettings[accountingSetting]) ? (location.accountingSystemSettings[accountingSetting] | yesNo) : location.accountingSystemSettings[accountingSetting]"></ati-labeled-data>
      </div>
      <div *ngIf="editMode" fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100">
        <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100" formArrayName="accountingSettings" *ngFor="let accountingSettingForm of existingAccountingSettings; let idx = index;" [formGroup]="accountingSettingForm">
          <mat-form-field fxFlex="100" *ngIf="!appearsBoolean(accountingSettingForm.get('settingValue').value)">
            <input required matInput [placeholder]="accountingSettingForm.get('settingKey').value" [formControl]="accountingSettingForm.get('settingValue')" trim="blur" (blur)="checkForBoolean(accountingSettingForm.get('settingKey').value, accountingSettingForm.get('settingValue').value)" #settingValueField (keypress)="listenForEnter($event, settingValueField.value, idx)">
            <mat-error *ngIf="accountingSettingForm.get('settingValue').invalid">{{ getErrorMessage(accountingSettingForm.get('settingValue')) }}</mat-error>
            <div matSuffix fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
              <button type="button" id="clear-new-location-accounting-setting-value" *ngIf="accountingSettingForm.get('settingValue').value" mat-icon-button aria-label="Clear Value" matTooltip="Clear Value" (click)="accountingSettingForm.get('settingValue').setValue('')">
                <mat-icon>close</mat-icon>
              </button>
              <button type="button" id="remove-new-location-accounting-setting-value" mat-icon-button aria-label="Remove Accounting Setting" matTooltip="Remove Accounting Setting" (click)="removeAccountingSettingForm(idx)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-form-field>
          <div *ngIf="appearsBoolean(accountingSettingForm.get('settingValue').value)" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between end">
            <div class="checkbox-group">
              <label class="check-radio-label mat-small mat-hint">{{ accountingSettingForm.get('settingKey').value }}</label>
              <mat-checkbox [formControl]="accountingSettingForm.get('settingValue')">Enabled</mat-checkbox>
            </div>
            <button type="button" id="remove-new-location-accounting-setting-value" mat-icon-button class="checkbox-fake-suffix" aria-label="Remove Accounting Setting" matTooltip="Remove Accounting Setting" (click)="removeAccountingSettingForm(idx)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between start">
          <mat-form-field fxFlex="30">
            <input matInput placeholder="Setting" formControlName="newSettingKey" trim="blur">
            <mat-error *ngIf="locationForm.get('newSettingKey').invalid">{{ getErrorMessage(locationForm.get('newSettingKey')) }}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="67">
            <input matInput placeholder="Value" formControlName="newSettingValue" trim="blur">
            <mat-error *ngIf="locationForm.get('newSettingValue').invalid">{{ getErrorMessage(locationForm.get('newSettingValue')) }}</mat-error>
            <button type="button" matSuffix id="add-accounting-setting" [disabled]="addAccountingFormDisabled" mat-icon-button aria-label="Add Accounting Setting" matTooltip="Add Accounting Setting" (click)="addAccountingSettingForm()">
              <mat-icon>add_circle</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
    </form>

  </mat-card>
</div>
