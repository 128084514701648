import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { CommodityProfileService, LocationService } from '@advance-trading/angular-ops-data';
import {
  Client,
  CommodityProfile,
  Contract,
  ContractType,
  HMSClientSettings,
  Location,
  PricingSegment,
  ProductionYear,
  Side
} from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { ContractService } from '../../service/contract.service';
import { ExportService } from '../../service/export.service';
import { LedgerHelperService } from '../../service/ledger-helper.service';
import { PricingSegmentService } from '../../service/pricing-segment.service';
import { UserRoles } from '../../utilities/user-roles';

import { PurchasesAndSalesDisplay } from './purchases-and-sales-display';
import { PurchasesAndSalesMiniDisplay } from './purchases-and-sales-mini-display';
import { PurchasesAndSalesReportableItem } from './purchases-and-sales-reportable-item';

const MILITARY_FORMAT = 'HH:mm';
@Component({
  selector: 'hms-purchases-and-sales',
  templateUrl: './purchases-and-sales.component.html',
  styleUrls: ['./purchases-and-sales.component.scss']
})
export class PurchasesAndSalesComponent implements OnInit {
  purchasesAndSalesSearchForm: FormGroup = this.formBuilder.group({
    startDate: [''],
    endDate: ['']
  });

  locationParentHeaders: string[];
  locationColumnsToDisplay: string[];

  clientParentHeaders: string[];
  clientColumnsToDisplay: string[];

  clientMiniColumnsToDisplay: string[] = ['commodityProfile', 'productionYear', 'BUY', 'SELL'];

  productionYearNames: string[] = [];
  clientProductionYears: string[] = [];
  allProductionYears = Object.keys(ProductionYear);

  errorMessage: string;
  isLoading = true;
  showMini = false;
  displayReports = false;

  clientDataSource = new ObservableDataSource<PurchasesAndSalesDisplay>();
  clientMiniDataSource = new ObservableDataSource<PurchasesAndSalesMiniDisplay>();
  locationDataSource = new ObservableDataSource<PurchasesAndSalesDisplay>();

  commodityProfiles$: Observable<CommodityProfile[]>;

  private ledgerEndOfDay: string;
  private timezone: string;
  private selectedClientDocId: string;
  private locations: Location[] = [];
  // displayLocations emptied each run so that we don't display all-zero rows
  // but preserving all previously read locations to avoid excess document reads
  private displayLocations: Location[] = [];
  private commodityProfiles: CommodityProfile[];
  private contracts: Contract[];
  private reportableItems: PurchasesAndSalesReportableItem[] = [];

  private preservedClientParentHeaders: string[] = ['commodityProfileHeader'];
  private preservedClientColumnsToDisplay: string[] = ['commodityProfileName'];

  private preservedLocationParentHeaders: string[] = ['locationHeader', 'commodityProfileHeader'];
  private preservedLocationColumnsToDisplay: string[] = ['locationName', 'commodityProfileName'];

  private productionYearsWithData: string[] = [];
  private productionYearsWithDataMini: string[] = [];

  constructor(
    private authzService: Auth0AuthzService,
    private breakpointObserver: BreakpointObserver,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private contractService: ContractService,
    public exportService: ExportService,
    private formBuilder: FormBuilder,
    private ledgerHelperService: LedgerHelperService,
    private locationService: LocationService,
    private pricingSegmentService: PricingSegmentService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.MERCHANDISING_REPORTS_GENERATOR_ROLE)) {
      this.errorMessage = 'You do not have permission to access the purchases and sales report.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.commodityProfiles$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((selectedClient: Client) => {
        this.selectedClientDocId = selectedClient.docId;
        return this.clientSettingsService.getHmsSettingsByClientDocId(this.selectedClientDocId);
      }),
      switchMap((clientSettings: HMSClientSettings) => {
        this.ledgerEndOfDay = clientSettings.ledgerEndOfDay;
        this.timezone = clientSettings.timezone;
        return this.commodityProfileService.getActiveCommodityProfilesByTypeAndClientDocId(this.selectedClientDocId);
      }),
      tap((activeCommodityProfiles: CommodityProfile[]) => {
        this.commodityProfiles = activeCommodityProfiles;
        this.clientProductionYears = [];
        activeCommodityProfiles.map(commodityProfile => {
          this.clientProductionYears.push(...Object.values(commodityProfile.productionYears).map(productionYear => productionYear.label));
        });
        this.purchasesAndSalesSearchForm.get('startDate').setValue(this.currentBusinessDay);
        this.purchasesAndSalesSearchForm.get('endDate').setValue(this.currentBusinessDay);
        this.purchasesAndSalesSearchForm.markAsDirty();
        this.isLoading = false;
      }));
    // only show mini client on xs screen; other screens show full-sized client and location
    this.breakpointObserver.observe([Breakpoints.XSmall])
      .subscribe(state => {
        if (state.breakpoints[Breakpoints.XSmall]) {
          this.showMini = true;
        } else {
          this.showMini = false;
        }
      });
  }

  get commodityProfileCount() {
    return this.commodityProfiles.length;
  }

  get productionYearCount() {
    const rowcount = this.productionYearsWithDataMini.length;
    return rowcount === 0 ? 4 : rowcount;
  }

  get minStartDate() {
    const endDate = this.purchasesAndSalesSearchForm.get('endDate').value;
    // if end date exists, min start date is one year prior. If none, any start date is valid.
    let minStartDate = endDate ? moment(endDate).subtract(1, 'year').toISOString() : undefined;
    if (minStartDate) {
      const endDateMoment = moment(endDate);
      const startDateMoment = moment(minStartDate);
      // allow for Feb End of Month to Feb End of Month when end is a leap year
      if (endDateMoment.isLeapYear() && endDateMoment.month() === 1 && endDateMoment.date() === 29) {
        startDateMoment.date(28);
      }
      // allow for 2/29 on leap year through March 1st of next year
      if (startDateMoment.isLeapYear() && endDateMoment.month() === 2 && endDateMoment.date() === 1) {
        startDateMoment.month(1).date(29);
      }
      minStartDate = startDateMoment.toISOString();
    }
    return minStartDate;
  }

  get maxStartDate() {
    const endDate = this.purchasesAndSalesSearchForm.get('endDate').value;
    return endDate ? endDate : this.currentBusinessDay;
  }

  get minEndDate() {
    const startDate = this.purchasesAndSalesSearchForm.get('startDate').value;
    return startDate ? startDate : undefined;
  }

  get maxEndDate() {
    const startDate = moment(this.purchasesAndSalesSearchForm.get('startDate').value);
    const endOfDayToday = moment(this.currentBusinessDay);
    // possible max end date is one year after start date if present, if not, it's today
    const endDate = startDate ?
      moment(this.forceLedgerEndOfDay(moment(startDate).add(1, 'year'), this.ledgerEndOfDay, false)) :
      endOfDayToday;
    // allow for Feb End of Month to Feb End of Month when end is a leap year
    if (endDate.isLeapYear() && startDate.month() === 1 && startDate.date() === 28) {
      endDate.date(29);
    }
    // allow for 2/29 on leap year through March 1st of next year
    if (startDate.isLeapYear() && startDate.month() === 1 && startDate.date() === 29) {
      endDate.month(2).date(1);
    }
    // if possible max end date is in future, discard in favor of today.
    return (endDate.isBefore(endOfDayToday) ? endDate : endOfDayToday).toISOString();
  }

  get startAtEndDate() {
    const startDate = this.purchasesAndSalesSearchForm.get('startDate').value;
    const endDate = this.purchasesAndSalesSearchForm.get('endDate').value;
    return endDate || startDate || this.currentBusinessDay;
  }

  isFirstProfile(dataRow: PurchasesAndSalesDisplay) {
    return this.commodityProfiles && this.commodityProfiles[0].name === dataRow.commodityProfileName;
  }

  isFirstProdYear(dataRow: PurchasesAndSalesMiniDisplay) {
    return this.productionYearsWithDataMini && this.productionYearsWithDataMini[0] === dataRow.productionYear;
  }

  getDisplayAmount(value: number) {
    return value === -1 ? '-' : value.toLocaleString(undefined, { maximumFractionDigits: 2 });
  }

  get ledgerEndOfDayLocal() {
    return moment(this.currentBusinessDay).format('h:mm A');
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMax')) {
      return 'Value Invalid';
    }
    return 'Unknown Error';
  }

  reset() {
    this.purchasesAndSalesSearchForm.get('startDate').setValue('');
    this.purchasesAndSalesSearchForm.get('endDate').setValue('');
    this.purchasesAndSalesSearchForm.markAsPristine();
    this.displayReports = false;
  }

  loadPurchasesAndSalesReportData() {

    this.displayLocations = [];

    let startDate = this.getDatepickerValueAsISOString('startDate');
    let endDate = this.getDatepickerValueAsISOString('endDate');

    if (!startDate) {
      // if end date present but no start date, set start date to min (one year prior to end)
      // if no end date exists, start date is today
      startDate = endDate ? this.minStartDate : this.currentBusinessDay;
      this.purchasesAndSalesSearchForm.get('startDate').setValue(startDate);
      this.purchasesAndSalesSearchForm.markAsDirty();
    }
    if (!endDate) {
      // force end date to earlier of two values; one year past start date or today
      endDate = this.maxEndDate;
      this.purchasesAndSalesSearchForm.get('endDate').setValue(endDate);
      this.purchasesAndSalesSearchForm.markAsDirty();
    }

    // force end date to ledger end of day and start date to one millisecond after previous ledger end of day to address #505
    startDate = this.forceLedgerEndOfDay(moment(startDate), this.ledgerEndOfDay, true);
    endDate = this.forceLedgerEndOfDay(moment(endDate), this.ledgerEndOfDay, false);

    this.isLoading = true;
    let cachedContractReportableItems: PurchasesAndSalesReportableItem[];
    this.locationDataSource.data$ = this.loadContractReportableItems(this.selectedClientDocId, startDate, endDate).pipe(
      switchMap((contractReportableItems: PurchasesAndSalesReportableItem[]) => {
        cachedContractReportableItems = contractReportableItems;
        return this.loadPricingSegmentReportableItems(this.selectedClientDocId, startDate, endDate);
      }),
      map((segmentReportableItems: PurchasesAndSalesReportableItem[][]) => {
        this.reportableItems = cachedContractReportableItems.concat(...segmentReportableItems.flat());
        this.locations.sort((a, b) => a.name > b.name ? 1 : -1);
        this.commodityProfiles.sort((a, b) => a.name > b.name ? 1 : -1);
        const locationRows: PurchasesAndSalesDisplay[] = [];
        const clientRows: PurchasesAndSalesDisplay[] = [];
        this.productionYearsWithData = [];
        this.displayLocations.sort((a, b) => a.name < b.name ? -1 : 1).map((location: Location) => {
          this.commodityProfiles.map((commodityProfile: CommodityProfile) => {
            const profileProdYears = Object.values(commodityProfile.productionYears).map(productionYear => productionYear.label);
            const oldProdYearDefaultValue = this.getDefaultForProfileProdYear(profileProdYears, ProductionYear.OLD);
            const newProdYearDefaultValue = this.getDefaultForProfileProdYear(profileProdYears, ProductionYear.NEW);
            const newPlus1ProdYearDefaultValue = this.getDefaultForProfileProdYear(profileProdYears, ProductionYear.NEW_PLUS_1);
            const newPlus2ProdYearDefaultValue = this.getDefaultForProfileProdYear(profileProdYears, ProductionYear.NEW_PLUS_2);
            let commodityProfileTotalRowIndex = clientRows.map((totalRow: PurchasesAndSalesDisplay) => totalRow.locationName).indexOf(`${commodityProfile.name} Total`);
            if (commodityProfileTotalRowIndex === -1) {
              commodityProfileTotalRowIndex = clientRows.length;
              clientRows.push({
                locationName: `${commodityProfile.name} Total`,
                commodityProfileName: commodityProfile.name,
                OLD_BUY: oldProdYearDefaultValue,
                OLD_SELL: oldProdYearDefaultValue,
                NEW_BUY: newProdYearDefaultValue,
                NEW_SELL: newProdYearDefaultValue,
                NEW_PLUS_1_BUY: newPlus1ProdYearDefaultValue,
                NEW_PLUS_1_SELL: newPlus1ProdYearDefaultValue,
                NEW_PLUS_2_BUY: newPlus2ProdYearDefaultValue,
                NEW_PLUS_2_SELL: newPlus2ProdYearDefaultValue,
              });
            }
            const locationCommodityRow = {
              locationName: location.name,
              commodityProfileName: commodityProfile.name,
              OLD_BUY: oldProdYearDefaultValue,
              OLD_SELL: oldProdYearDefaultValue,
              NEW_BUY: newProdYearDefaultValue,
              NEW_SELL: newProdYearDefaultValue,
              NEW_PLUS_1_BUY: newPlus1ProdYearDefaultValue,
              NEW_PLUS_1_SELL: newPlus1ProdYearDefaultValue,
              NEW_PLUS_2_BUY: newPlus2ProdYearDefaultValue,
              NEW_PLUS_2_SELL: newPlus2ProdYearDefaultValue,
            };
            this.reportableItems.filter(
              reportableItem => reportableItem.locationName === location.name &&
                reportableItem.commodityProfileName === commodityProfile.name).map(reportableItem => {
                  const productionYearLabel = reportableItem.productionYear;
                  const buyColumn = this.getColumnName(productionYearLabel, true);
                  const soldColumn = this.getColumnName(productionYearLabel, false);
                  locationCommodityRow[buyColumn] += reportableItem.BUY;
                  locationCommodityRow[soldColumn] += reportableItem.SELL;
                  clientRows[commodityProfileTotalRowIndex][buyColumn] += reportableItem.BUY;
                  clientRows[commodityProfileTotalRowIndex][soldColumn] += reportableItem.SELL;
                  if (!this.productionYearsWithData.includes(productionYearLabel)) {
                    this.productionYearsWithData.push(productionYearLabel);
                  }
                });
            locationRows.push(locationCommodityRow);
          });
        });
        this.locationParentHeaders = [...this.preservedLocationParentHeaders];
        this.locationColumnsToDisplay = [...this.preservedLocationColumnsToDisplay];
        this.clientParentHeaders = [...this.preservedClientParentHeaders];
        this.clientColumnsToDisplay = [...this.preservedClientColumnsToDisplay];
        this.productionYearsWithDataMini = [];
        this.allProductionYears.map(prodYear => {
          if (this.clientProductionYears.includes(prodYear)) {
            this.addDisplayColumns(prodYear);
            this.productionYearsWithDataMini.push(prodYear);
          }
        });
        const clientMiniRows: PurchasesAndSalesMiniDisplay[] = [];
        clientRows.map(row => {
          this.productionYearsWithDataMini.map(prodYear => {
            const boughtColumn = this.getColumnName(prodYear, true);
            const soldColumn = this.getColumnName(prodYear, false);
            clientMiniRows.push({
              commodityProfileName: row.commodityProfileName,
              productionYear: prodYear,
              BUY: row[boughtColumn],
              SELL: row[soldColumn],
            } as PurchasesAndSalesMiniDisplay);
          });
        });
        this.clientDataSource.data$ = of(clientRows);
        this.clientMiniDataSource.data$ = of(clientMiniRows);
        this.isLoading = false;
        this.displayReports = true;
        return locationRows;
      }),
      catchError(err => {
        this.openSnackBar('Error running report: ' + err.message, 'DISMISS', false);
        console.error(`Error retrieving reportable items: ${err}`);
        this.isLoading = false;
        return of([]);
      })
    );
  }

  private forceLedgerEndOfDay(day: moment.Moment, ledgerEndOfDay: string, isStartDate: boolean): string {
    const endOfDay = this.endOfBusinessDay(day, ledgerEndOfDay);
    if (isStartDate) {
      endOfDay.subtract(1, 'day').add(1, 'millisecond');
    }
    return endOfDay.toISOString();
  }

  private endOfBusinessDay(day: moment.Moment, ledgerEndOfDay: string): moment.Moment {
    const timeModel = moment.tz(ledgerEndOfDay, MILITARY_FORMAT, this.timezone);
    // set second and millisecond to avoid ExpressionChanged error on this.currentBusinessDay
    return moment.tz(day, this.timezone)
      .set('hour', timeModel.hour()).set('minute', timeModel.minute()).set('second', 0).set('millisecond', 0);
  }

  private getDatepickerValueAsISOString(fieldName: string) {
    const val = this.purchasesAndSalesSearchForm.get(fieldName).value;
    return val && val.toISOString ? val.toISOString() : val;
  }

  private getDefaultForProfileProdYear(profileProdYears: ProductionYear[], prodYear: ProductionYear) {
    return profileProdYears.includes(prodYear) ? 0 : -1;
  }

  private addDisplayColumns(prodYear: string) {
    this.productionYearNames.push(prodYear);
    this.clientParentHeaders.push(prodYear);
    this.locationParentHeaders.push(prodYear);
    const buyColumn = this.getColumnName(prodYear, true);
    const soldColumn = this.getColumnName(prodYear, false);
    this.clientColumnsToDisplay.push(buyColumn, soldColumn);
    this.locationColumnsToDisplay.push(buyColumn, soldColumn);
  }

  private getColumnName(prodYear: string, BUY: boolean) {
    return `${prodYear}_${BUY ? 'BUY' : 'SELL'}`;
  }

  // creating consistent means of referencing active commodityProfile or a commodityProfile that was active at the time of record creation
  private getSingleCommodityProfile(commodityProfileDocId: string): Observable<CommodityProfile> {
    const activeCommodityProfile = this.commodityProfiles.find(commodityProfile => commodityProfile.docId === commodityProfileDocId);
    return activeCommodityProfile ?
      of(activeCommodityProfile) :
      this.commodityProfileService.getCommodityProfileByDocId(this.selectedClientDocId, commodityProfileDocId);
  }

  // creating consistent means of referencing active location or a location that was active at the time of record creation
  private getSingleLocation(locationDocId: string): Observable<Location> {
    const activeLocation = this.locations.find(location => location.docId === locationDocId);
    return activeLocation ?
      of(activeLocation) :
      this.locationService.getLocationByDocId(this.selectedClientDocId, locationDocId);
  }

  private get currentBusinessDay(): string {
    const now = moment();
    const todaysLedgerEndOfDay = this.endOfBusinessDay(now, this.ledgerEndOfDay);
    return this.forceLedgerEndOfDay(now.isBefore(todaysLedgerEndOfDay) ?
      now :
      now.add(1, 'days'), this.ledgerEndOfDay, false);
  }

  // Pull in missing contracts to get production year
  private getSingleContract(contractDocId: string): Observable<Contract> {
    const queriedResultContract = this.contracts.find(contract => contract.docId === contractDocId);
    return queriedResultContract ?
      of(queriedResultContract) :
      this.contractService.getContractByDocId(this.selectedClientDocId, contractDocId);
  }

  private loadPricingSegmentReportableItems(clientDocId: string, startDate: string, endDate: string)
    : Observable<PurchasesAndSalesReportableItem[][]> {
    const excludeDeleted = true;
    const segmentQueries = [
      this.pricingSegmentService.findDpPricingSegmentsByBasisLockedTimestampWithBasisDpFirstPartType(
        clientDocId, startDate, endDate, excludeDeleted),
      this.pricingSegmentService.findDpPricingSegmentsByCashLockedTimestampAndCashPricingType(
        clientDocId, startDate, endDate, excludeDeleted),
      this.pricingSegmentService.findDpPricingSegmentsByFuturesLockedTimestampWithFuturesDpFirstPartType(
        clientDocId, startDate, endDate, excludeDeleted)
    ].concat(this.contracts.map(contract =>
      this.pricingSegmentService.getPricingSegmentsByClientDocIdAndContractDocIdWithDifferentDeliveryLocationDocId(
        contract.clientDocId, contract.docId, contract.deliveryLocationDocId)));
    return combineLatest(segmentQueries).pipe(
      switchMap((resultSet: PricingSegment[][]) => {
        const queriedPricingSegments: PricingSegment[] = resultSet.flat();
        if (queriedPricingSegments.length === 0) {
          return of([]);
        }
        return combineLatest(
          queriedPricingSegments.map((pricingSegment: PricingSegment) => {
            let referencedCommodityProfile;
            let referencedLocation;
            return this.getSingleCommodityProfile(pricingSegment.commodityProfileDocId).pipe(
              switchMap((singleCommodityProfile: CommodityProfile) => {
                referencedCommodityProfile = singleCommodityProfile;
                if (!this.commodityProfiles.find(commodityProfile => commodityProfile.docId === singleCommodityProfile.docId)) {
                  this.commodityProfiles.push(singleCommodityProfile);
                }
                return this.getSingleLocation(pricingSegment.deliveryLocationDocId);
              }),
              switchMap((singleLocation: Location) => {
                referencedLocation = singleLocation;
                if (!this.locations.find(location => location.docId === singleLocation.docId)) {
                  this.locations.push(singleLocation);
                }
                if (!this.displayLocations.find(location => location.docId === singleLocation.docId)) {
                  this.displayLocations.push(singleLocation);
                }
                return this.getSingleContract(pricingSegment.contractDocId);
              }),
              map((singleContract: Contract) => {
                // prevent double-reads for DP contracts
                if (!this.contracts.find(contract => contract.docId === singleContract.docId)) {
                  this.contracts.push(singleContract);
                }
                const segmentReturnArray: PurchasesAndSalesReportableItem[] = [];
                segmentReturnArray.push({
                  commodityProfileName: referencedCommodityProfile.name,
                  locationName: referencedLocation.name,
                  productionYear: this.ledgerHelperService.getProdYearLabelForContract(
                    singleContract, referencedCommodityProfile, this.ledgerEndOfDay, this.timezone),
                  BUY: pricingSegment.side === Side.BUY ? pricingSegment.quantity : 0,
                  SELL: pricingSegment.side === Side.SELL ? pricingSegment.quantity : 0
                } as PurchasesAndSalesReportableItem);
                // add offset record for non-DP contracts
                if (singleContract.type !== ContractType.DP) {
                  segmentReturnArray.push({
                    commodityProfileName: referencedCommodityProfile.name,
                    locationName: singleContract.deliveryLocationName,
                    productionYear: this.ledgerHelperService.getProdYearLabelForContract(
                      singleContract, referencedCommodityProfile, this.ledgerEndOfDay, this.timezone),
                    BUY: pricingSegment.side === Side.BUY ? pricingSegment.quantity * -1 : 0,
                    SELL: pricingSegment.side === Side.SELL ? pricingSegment.quantity * -1 : 0
                  } as PurchasesAndSalesReportableItem);
                }
                return segmentReturnArray;
              })
            );
          })
        );
      })
    );
  }

  private loadContractReportableItems(clientDocId: string, startDate: string, endDate: string)
    : Observable<PurchasesAndSalesReportableItem[]> {
    const excludeDeleted = true;
    return combineLatest([
      this.contractService.getContractsByTypeAndFuturesLockedTimestamp(
        clientDocId, [ContractType.CASH, ContractType.HTA], startDate, endDate, excludeDeleted),
      this.contractService.getContractsByTypeAndBasisLockedTimestamp(
        clientDocId, [ContractType.BASIS], startDate, endDate, excludeDeleted)
    ]).pipe(
      switchMap(([
        htaContracts,
        basisContracts]) => {
        const queriedContracts: Contract[] = htaContracts.concat(basisContracts);
        this.contracts = queriedContracts;
        if (queriedContracts.length === 0) {
          this.contracts = [];
          return of([]);
        }
        return combineLatest(
          queriedContracts.map((contract: Contract) => {
            let referencedCommodityProfile;
            return this.getSingleCommodityProfile(contract.commodityProfileDocId).pipe(
              switchMap((singleCommodityProfile: CommodityProfile) => {
                referencedCommodityProfile = singleCommodityProfile;
                if (!this.commodityProfiles.find(commodityProfile => commodityProfile.docId === singleCommodityProfile.docId)) {
                  this.commodityProfiles.push(singleCommodityProfile);
                }
                return this.getSingleLocation(contract.deliveryLocationDocId);
              }),
              map((singleLocation: Location) => {
                if (!this.locations.find(location => location.docId === singleLocation.docId)) {
                  this.locations.push(singleLocation);
                }
                if (!this.displayLocations.find(location => location.docId === singleLocation.docId)) {
                  this.displayLocations.push(singleLocation);
                }
                return {
                  commodityProfileName: referencedCommodityProfile.name,
                  locationName: singleLocation.name,
                  productionYear: this.ledgerHelperService.getProdYearLabelForContract(
                    contract, referencedCommodityProfile, this.ledgerEndOfDay, this.timezone),
                  BUY: contract.side === Side.BUY ? contract.quantity : 0,
                  SELL: contract.side === Side.SELL ? contract.quantity : 0
                } as PurchasesAndSalesReportableItem;
              })
            );
          })
        );
      })
    );
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

}
