<div fxLayout="row" fxLayoutAlign="space-around start">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <!-- Error message -->
    <hms-error-message [title]="'Error Retrieving New Hedge Form'" [message]="errorMessage"></hms-error-message>

    <form *ngIf="!errorMessage" [formGroup]="hedgeForm" (ngSubmit)="submit()" fxLayout="column" fxLayoutGap="15px">

      <!-- Title and Buttons -->
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="card-title">{{ isStandardHedgeCreator ? 'New Hedge' : 'New OTC Hedge'}}</h2>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
          <button type="submit" id="create-hedge" class="action-button" aria-label="Create Hedge" data-cy="submit" *ngIf="updateComplete" [disabled]="!hedgeForm.valid" mat-mini-fab color="accent" matTooltip="Create Hedge" (click)="submit()">
              <mat-icon>done</mat-icon>
          </button>
          <button type="button" id="clear-hedge" class="action-button" aria-label="Discard Changes" *ngIf="!hedgeForm.pristine && updateComplete" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes">
              <mat-icon>clear</mat-icon>
          </button>
          <mat-spinner *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
        </div>
      </div>

      <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">

        <!-- Side -->
        <div>
          <mat-button-toggle-group formControlName="side" aria-label="Buy or Sell">
            <mat-button-toggle required *ngFor="let side of sides" attr.data-cy="side-{{side}}" [value]="side">{{ side | titlecase }}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <!-- Type -->
        <div *ngIf="isStandardHedgeCreator && isOTCHedgeCreator">
          <mat-button-toggle-group formControlName="type" aria-label="Hedge Type" matTooltip="Hedge Type">
            <mat-button-toggle data-cy="type-STANDARD" value="STANDARD">Standard</mat-button-toggle>
            <mat-button-toggle data-cy="type-OTC" value="OTC">OTC</mat-button-toggle>
          </mat-button-toggle-group>
        </div>

      </div>

      <!-- Commodity Profile and Production Year -->
      <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column">
        <div>
          <mat-form-field fxFlex.gt-xs="250px" fxFlex.xs="100" data-cy="commodityProfile">
            <mat-select [compareWith]="compareCommodityProfile" required placeholder="Commodity Profile" formControlName="commodityProfile">
              <mat-option *ngFor="let profile of commodityProfiles$ | async" [value]="profile">{{ profile.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="hedgeForm.get('commodityProfile').invalid">{{ getErrorMessage(hedgeForm.get('commodityProfile')) }}</mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex.gt-xs="250px" fxFlex.xs="100" *ngIf="hedgeForm.get('commodityProfile').value" data-cy="productionYear">
            <mat-select [compareWith]="compareProductionYear" required placeholder="Production Year" formControlName="prodYear">
              <mat-option *ngFor="let prodYear of prodYears" [value]="prodYear">{{ prodYear.label | productionYear }}</mat-option>
            </mat-select>
            <mat-error *ngIf="hedgeForm.get('prodYear').invalid">{{ getErrorMessage(hedgeForm.get('prodYear')) }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- Futures Year Month, Quantity, Order ID -->
      <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
        <mat-form-field hmsMonthPicker fxFlex.gt-xs="250px" fxFlex.xs="100">
          <input required matInput [matDatepicker]="futuresYearMonthPicker" [errorStateMatcher]="futuresMonthErrorMatcher" [matDatepickerFilter]="validFuturesMonthFilter" placeholder="Futures Month" formControlName="futuresYearMonth" [min]="minDate" (keydown)="$event.preventDefault()">
          <mat-datepicker-toggle matSuffix [for]="futuresYearMonthPicker" data-cy="futuresYearMonthPicker"></mat-datepicker-toggle>
          <mat-datepicker #futuresYearMonthPicker startView="year" (monthSelected)="selectFuturesYearMonth($event)" panelClass="futures-date-panel month-picker"></mat-datepicker>
          <mat-error *ngIf="hedgeForm.get('futuresYearMonth').invalid || hedgeForm.hasError('invalidFuturesMonth')">{{ getErrorMessage(hedgeForm.get('futuresYearMonth')) }}</mat-error>
        </mat-form-field>
        <div>
          <mat-form-field fxFlex.gt-xs="250px" fxFlex.xs="100">
            <input required matInput placeholder="Contracts" data-cy="contracts" formControlName="contracts" trim="blur" (blur)="formatQuantity('contracts')">
            <mat-error *ngIf="hedgeForm.get('contracts').invalid">{{ getErrorMessage(hedgeForm.get('contracts')) }}</mat-error>
          </mat-form-field>
        </div>
        <mat-form-field *ngIf="hedgeForm.get('type').value === 'STANDARD'" fxFlex.gt-xs="250px" fxFlex.xs="100">
          <input required matInput placeholder="Order ID" formControlName="orderId" trim="blur" data-cy="orderId">
          <button id="create-hedge-order" class="qst-btn" type="button" *ngIf="!hedgeForm.get('orderId').value && qstService.enabled && readyToCreateHedgeOrder() && !qstWorking" matTooltip="Create Order" matSuffix mat-icon-button aria-label="Create Order" (click)="createHedgeOrder()">
            <mat-icon>receipt</mat-icon>
          </button>
          <mat-spinner matSuffix *ngIf="qstWorking" diameter="16"></mat-spinner>
          <mat-error *ngIf="hedgeForm.get('orderId').invalid">{{ getErrorMessage(hedgeForm.get('orderId')) }}</mat-error>
          <div *ngIf="qpProfileDocId && commodityId" class="margin-bottom-thin mat-form-field-subscript-wrapper" [ngClass]="(hedgeForm.get('orderId').invalid && hedgeForm.touched) ? 'order-hint-position-with-error' : 'order-hint-position'" >
            <span id="order-hint" class="mat-hint">
              <li>{{ commodityProfileNumber() }}</li>
              <li>{{ side }} {{ quantity }} {{ futuresYearMonth | date:'MMM yy' }} {{ commodityId }} @ Market</li>
            </span>
          </div>
        </mat-form-field>
      </div>

      <!-- Price, Related Quantity -->
      <div *ngIf="hedgeForm.get('type').value === 'OTC'" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
        <div>
          <mat-form-field fxFlex.gt-xs="250px" fxFlex.xs="100">
            <input required matInput placeholder="Price" formControlName="price" trim="blur" (blur)="processUpdatedPrice('price')">
            <mat-error *ngIf="hedgeForm.get('price').invalid">{{ getErrorMessage(hedgeForm.get('price')) }}</mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field fxFlex.gt-xs="250px" fxFlex.xs="100">
            <input required matInput placeholder="Related Quantity" formControlName="relatedQuantity" trim="blur" (blur)="formatQuantity('relatedQuantity')">
            <mat-error *ngIf="hedgeForm.get('relatedQuantity').invalid">{{ getErrorMessage(hedgeForm.get('relatedQuantity')) }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- Comments -->
      <mat-form-field data-cy="comments">
        <textarea matInput placeholder="Comments" formControlName="comments" trim="blur"></textarea>
        <mat-error *ngIf="hedgeForm.get('comments').invalid">{{ getErrorMessage(hedgeForm.get('comments')) }}</mat-error>
        <button type="button" id="clear-hedge-comments" mat-button *ngIf="hedgeForm.get('comments').value" matSuffix mat-icon-button aria-label="Clear Comments" matTooltip="Clear Comments" (click)="hedgeForm.get('comments').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </form>

  </mat-card>
</div>
