<div fxLayout="column">
  <h4>Basis Summary</h4>
  <mat-table [dataSource]="dataSource" class="mat-elevation-z0">
    <ng-container matColumnDef="commodityProfile">
      <mat-header-cell *matHeaderCellDef>Profile</mat-header-cell>
      <mat-cell *matCellDef="let basisSummary">{{ basisSummary.commodityProfile.name }}</mat-cell>
    </ng-container>

    <ng-container [matColumnDef]="label" *ngFor="let label of profileProdYearLabels">
      <mat-header-cell *matHeaderCellDef>{{ label | productionYear }}</mat-header-cell>
      <mat-cell *matCellDef="let basisSummary">
          {{ basisSummary.basisQtyProdYears[label] ?  (basisSummary.basisQtyProdYears[label] | number:'1.0-0') : 0 }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columnsToDisplay;"></mat-header-row>
    <mat-row *matRowDef="let basisSummary; columns: columnsToDisplay"></mat-row>
  </mat-table>
</div>
