<mat-expansion-panel class="mat-elevation-z0" [expanded]="shouldExpand">
  <mat-expansion-panel-header>
    <mat-panel-title required>{{panelTitle()}}</mat-panel-title>
  </mat-expansion-panel-header>
  <form [formGroup]="productionYearForm">
    <div fxLayout.xs="column" fxLayout.gt-xs="row wrap" fxLayoutGap="35px">
      <!-- Year -->
      <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="100px" fxFlex.xs="100" label="Year" [data]="productionYearForm.get('year').value | date : 'yyyy'"></ati-labeled-data>
      <mat-form-field *ngIf="editMode" hmsYearPicker fxFlex.gt-xs="100px" fxFlex.xs="100" class="production-year-field">
        <input [min]="minYearDate"  required matInput [matDatepicker]="yearPicker" placeholder="Year" formControlName="year" (keydown)="$event.preventDefault()">
        <mat-datepicker-toggle matSuffix [for]="yearPicker"></mat-datepicker-toggle>
        <mat-datepicker #yearPicker startView="multi-year" (yearSelected)="selectMonthOrYear($event, 'year',yearPicker)" panelClass="year-picker"></mat-datepicker>
        <mat-error *ngIf="productionYearForm.get('year').invalid">{{ getErrorMessage(productionYearForm.get('year')) }}</mat-error>
      </mat-form-field>

      <!-- Label -->
      <ati-labeled-data fxFlex.gt-xs="100px" fxFlex.xs="100" label="Label" [data]="productionYearForm.get('label').value | productionYear "></ati-labeled-data>

      <!-- Start and End Date -->
      <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="100px" fxFlex.xs="100" label="Start Date" [data]="productionYearForm.get('startDate').value | date : 'MM/dd/yyyy'"></ati-labeled-data>
      <mat-form-field *ngIf="editMode" fxFlex.gt-xs="100px" fxFlex.xs="100" class="production-year-field">
        <input [errorStateMatcher]="startDateErrorMatcher" [min]="getMinStartDate()" [max]="productionYearForm.get('endDate').value" required matInput [matDatepicker]="startDatePicker" placeholder="Start Date" formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
        <mat-error *ngIf="productionYearForm.get('startDate').invalid || productionYearForm.hasError('startDateGap')">{{ getErrorMessage(productionYearForm.get('startDate')) }}</mat-error>
      </mat-form-field>
      <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="100px" fxFlex.xs="100" label="End Date" [data]="productionYearForm.get('endDate').value | date : 'MM/dd/yyyy'"></ati-labeled-data>
      <mat-form-field *ngIf="editMode" fxFlex.gt-xs="100px" fxFlex.xs="100" class="production-year-field">
        <input [min]="productionYearForm.get('startDate').value" [max]="getMaxEndDate()" required matInput [matDatepicker]="endDatePicker" placeholder="End Date" formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
        <mat-error *ngIf="productionYearForm.get('endDate').invalid">{{ getErrorMessage(productionYearForm.get('endDate')) }}</mat-error>
      </mat-form-field>

      <!-- Future Months, buttons -->
      <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="120px" fxFlex.xs="100" label="Target Month" [data]="productionYearForm.get('targetFuturesYearMonth').value | date : 'MMM yyyy'"></ati-labeled-data>
      <mat-form-field *ngIf="editMode" hmsMonthPicker fxFlex.gt-xs="120px" fxFlex.xs="100" class="production-year-field">
        <input [errorStateMatcher]="targetFuturesYearMonthErrorMatcher" required matInput [matDatepicker]="futuresMonthYearPicker" [matDatepickerFilter]="validFuturesMonthFilter" placeholder="Target Month" formControlName="targetFuturesYearMonth" [min]="minDate" (keydown)="$event.preventDefault()">
        <mat-datepicker-toggle matSuffix [for]="futuresMonthYearPicker"></mat-datepicker-toggle>
        <mat-datepicker #futuresMonthYearPicker startView="year" (monthSelected)="selectMonthOrYear($event, 'targetFuturesYearMonth',futuresMonthYearPicker)" panelClass="month-picker"></mat-datepicker>
        <mat-error *ngIf="productionYearForm.get('targetFuturesYearMonth').invalid">{{ getErrorMessage(productionYearForm.get('targetFuturesYearMonth')) }}</mat-error>
      </mat-form-field>
      <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="150px" fxFlex.xs="100" label="Hedge Buy Month" [data]="productionYearForm.get('hedgeBuyFuturesYearMonth').value | date : 'MMM yyyy'"></ati-labeled-data>
      <mat-form-field *ngIf="editMode" hmsMonthPicker fxFlex.gt-xs="150px" fxFlex.xs="100" class="production-year-field">
        <input [errorStateMatcher]="hedgeBuyFuturesYearMonthErrorMatcher" required matInput [matDatepicker]="hedgeBuyMonthYearPicker" [matDatepickerFilter]="validFuturesMonthFilter" placeholder="Hedge Buy Month" formControlName="hedgeBuyFuturesYearMonth" [min]="minDate" (keydown)="$event.preventDefault()">
        <mat-datepicker-toggle matSuffix [for]="hedgeBuyMonthYearPicker"></mat-datepicker-toggle>
        <mat-datepicker #hedgeBuyMonthYearPicker startView="year" (monthSelected)="selectMonthOrYear($event, 'hedgeBuyFuturesYearMonth', hedgeBuyMonthYearPicker)" panelClass="futures-date-panel month-picker"></mat-datepicker>
        <mat-error *ngIf="productionYearForm.get('hedgeBuyFuturesYearMonth').invalid">{{ getErrorMessage(productionYearForm.get('hedgeBuyFuturesYearMonth')) }}</mat-error>
      </mat-form-field>
      <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="150px" fxFlex.xs="100" label="Hedge Sell Month" [data]="productionYearForm.get('hedgeSellFuturesYearMonth').value | date : 'MMM yyyy'"></ati-labeled-data>
      <mat-form-field *ngIf="editMode" hmsMonthPicker fxFlex.gt-xs="150px" fxFlex.xs="100" class="production-year-field">
        <input [errorStateMatcher]="hedgeSellFuturesYearMonthErrorMatcher" required matInput [matDatepicker]="hedgeSellMonthYearPicker" [matDatepickerFilter]="validFuturesMonthFilter" placeholder="Hedge Sell Month" formControlName="hedgeSellFuturesYearMonth" [min]="minDate" (keydown)="$event.preventDefault()">
        <mat-datepicker-toggle matSuffix [for]="hedgeSellMonthYearPicker"></mat-datepicker-toggle>
        <mat-datepicker #hedgeSellMonthYearPicker startView="year" (monthSelected)="selectMonthOrYear($event, 'hedgeSellFuturesYearMonth', hedgeSellMonthYearPicker)" panelClass="futures-date-panel month-picker"></mat-datepicker>
        <mat-error *ngIf="productionYearForm.get('hedgeSellFuturesYearMonth').invalid">{{ getErrorMessage(productionYearForm.get('hedgeSellFuturesYearMonth')) }}</mat-error>
      </mat-form-field>
      <div class="production-year-buttons">
        <button id="update-production-year" type="button" aria-label="Update Production Year" color="primary" *ngIf="editMode" [disabled]="productionYearForm.invalid || !editMode || productionYearForm.pristine" mat-icon-button (click)="onProductionYearUpdated()" matTooltip="Update Production Year">
          <mat-icon>check_circle</mat-icon>
        </button>
        <button id="remove-production-year" type="button" aria-label="Remove Production Year" color="warn" *ngIf="needsDeleteButton" mat-icon-button (click)="onProductionYearRemoved()" matTooltip="Remove Production Year">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </form>
</mat-expansion-panel>
