import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import * as moment from 'moment';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommodityProfileService, OperationsDataService, UserService } from '@advance-trading/angular-ops-data';
import {
  AlertStatus,
  Client,
  Commodity,
  CommodityMap,
  CommodityProfile,
  CommodityProfileProductionYear,
  ContractMonth,
  Hedge,
  HedgeType,
  HMSClientSettings,
  HMSDailyLedger,
  OrderType,
  Side,
  TimeInForce,
  User
} from '@advance-trading/ops-data-lib';

import { AppAlertService } from '../../service/app-alert.service';
import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { HedgeService } from '../../service/hedge.service';
import { HMSDailyLedgerService } from '../../service/hms-daily-ledger.service';
import { QSTService } from '../../service/qst.service';
import { UserRoles } from '../../utilities/user-roles';

import { FuturesMonthErrorMatcher, FuturesValidators } from '../../utilities/validators/futures.validator';
import { LedgerHelperService } from '../../service/ledger-helper.service';

const CONTRACTS_REGEX = /^(?=.*[1-9])\d*$/;
const FUTURES_REGEX = /^(\d+|\d*\.\d{1,2}|\d*\.\d{2}(0|(00)|(25)|(50)|(5)|(75))|\d+\.\d{0,2}|\d+\.\d{0,2}(0|(00)|(25)|(50)|(5)|(75)))$/;
const QUANTITY_REGEX = /^(?=.*[1-9])\d*(?:\.\d{0,2})?$/;
const FULLDATE_FORMAT = 'YYYY-MM-DD';

@Component({
  selector: 'hms-new-hedge',
  templateUrl: './new-hedge.component.html',
  styleUrls: ['./new-hedge.component.scss']
})
export class NewHedgeComponent implements OnDestroy, OnInit {
  @ViewChild('futuresYearMonthPicker', { static: false }) futuresYearMonthRef;

  hedgeForm: FormGroup = this.formBuilder.group({
    side: ['', [Validators.required]],
    prodYear: ['', [Validators.required]],
    commodityProfile: ['', [Validators.required]],
    contracts: ['', [Validators.required, Validators.pattern(CONTRACTS_REGEX), Validators.maxLength(3)]],
    futuresYearMonth: ['', [Validators.required]],
    orderId: ['', [Validators.required]],
    comments: ['', [Validators.maxLength(400)]],
    type: [HedgeType.STANDARD, [Validators.required]],
    price: ['', [Validators.required, Validators.pattern(FUTURES_REGEX)]],
    relatedQuantity: ['', [Validators.required, Validators.pattern(QUANTITY_REGEX)]],
  });

  errorMessage: string;
  updateComplete = true;

  // Indicator to help with progress spinner for QST
  qstWorking = false;

  // form data
  commodityProfiles$: Observable<CommodityProfile[]>;
  sides = Object.keys(Side);
  prodYears: CommodityProfileProductionYear[] = [];

  side: Side;
  commodityId: string;
  quantity: number;
  futuresYearMonth: moment.Moment;

  // error state matcher to check futuresYearMonth valid state
  futuresMonthErrorMatcher = new FuturesMonthErrorMatcher();
  // don't allow futures month in the past. Futures datepickers default to first of month.
  minDate = moment().startOf('month');

  private contractMonths = Object.keys(ContractMonth);
  private commodities: Commodity[];
  private commoditiesMap: { [key: string]: Commodity };
  private selectedClientDocId: string;
  private selectedCommodityProfile: CommodityProfile;
  private commodityProfiles: CommodityProfile[];
  private loggedInUser: User;

  // valid futures month for mat-datepicker filter
  private validFuturesMonths: number[];

  // commodities document futures months
  private futuresMonths: { [key: string]: number[] } = {};

  // Store the clientOrderId returned from QST for use in matching with qstService.orderEntry$
  private newOrderClientId = '';
  private exchangeOrderIdSub: Subscription;

  private qpProfileDocId: string;
  private qpProdYear: string;
  private ledgerBusinessDate: string;
  private dailyLedger: HMSDailyLedger;
  private alertDocId: string;
  private ledgerEndOfDay: string;
  private timezone: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appAlertService: AppAlertService,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private formBuilder: FormBuilder,
    private hedgeService: HedgeService,
    private hmsDailyLedgerService: HMSDailyLedgerService,
    private ledgerHelperService: LedgerHelperService,
    private operationsDataService: OperationsDataService,
    public qstService: QSTService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService
  ) { }

  ngOnInit() {
    if (!this.isStandardHedgeCreator && !this.isOTCHedgeCreator) {
      this.errorMessage = 'You do not have permission to create hedges.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.commodityProfiles$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((doc: CommodityMap) => {
        this.commoditiesMap = doc.commodities;
        this.commodities = Object.values(this.commoditiesMap);

        // get futures months from commodities document in firestore
        Object.keys(this.commoditiesMap).forEach((commodityId: string) => {
          this.futuresMonths[commodityId] = this.commoditiesMap[commodityId].contractMonths;
        });

        // set hedge form group validators
        this.hedgeForm.setValidators([FuturesValidators.futurePeriodValidator(this.futuresMonths)]);

        return this.clientSelectorService.getSelectedClient();
      }),
      switchMap((selectedClient: Client) => {
        this.selectedClientDocId = selectedClient.docId;
        return this.clientSettingsService.getHmsSettingsByClientDocId(selectedClient.docId);
      }),
      switchMap((clientSettings: HMSClientSettings) => {
        this.ledgerEndOfDay = clientSettings.ledgerEndOfDay;
        this.timezone = clientSettings.timezone;
        this.ledgerBusinessDate = moment.tz(
          this.ledgerHelperService.getCurrentBusinessDay(this.ledgerEndOfDay, this.timezone), this.timezone
        ).format(FULLDATE_FORMAT);
        return this.activatedRoute.queryParamMap;
      }),
      switchMap((queryParamMap: ParamMap) => {
        this.alertDocId = queryParamMap.get('alertId');
        this.qpProfileDocId = queryParamMap.get('profileId');
        this.qpProdYear = queryParamMap.get('prodYear');
        return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId);
      }),
      switchMap((user: User) => {
        this.loggedInUser = user;
        if (this.qpProfileDocId && this.qpProdYear) {
          return this.hmsDailyLedgerService.getHMSDailyLedgerByCommodityProfileAndProductionYearAndDate(
            this.selectedClientDocId, this.qpProfileDocId, this.qpProdYear, this.ledgerBusinessDate).pipe(take(1));
        }
        return of(undefined);
      }),
      switchMap((dailyLedger: HMSDailyLedger) => {
        this.dailyLedger = dailyLedger;
        return this.commodityProfileService.getActiveCommodityProfilesByTypeAndClientDocId(this.selectedClientDocId);
      }),
      tap(profiles => {
        this.commodityProfiles = profiles;
        this.populateFormFromQueryParam();
        this.hedgeForm.get('type').setValue(this.defaultType);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving commodity profiles; please try again later';
        console.error(`Error retrieving commodity profiles: ${err}`);
        return of([]);
      })
    );

    this.exchangeOrderIdSub = this.qstService.orderEntry$.pipe(
      filter(orderEntry => orderEntry.OD === this.newOrderClientId),
      map(orderEntry => orderEntry.XID)
    ).subscribe(xid => {
      if (xid) {
        this.qstWorking = false;
        this.setOrderDocId(xid);
      }
    });
    this.onCommodityProfileChanges();
    this.onSideAndProdYearChanges();
    this.onTypeChanges();
  }

  ngOnDestroy() {
    if (this.exchangeOrderIdSub) {
      this.exchangeOrderIdSub.unsubscribe();
    }
  }

  commodityProfileNumber() {
    return `${this.selectedCommodityProfile.officeCode}${this.selectedCommodityProfile.accountNumber}`
  }

  get isStandardHedgeCreator() {
    return this.authzService?.currentUserHasRole?.(UserRoles.HEDGE_CREATOR_ROLE) ?? false;
  }

  /** Checks if the current user has the _full_ Live Ledger View security role. */
  get hasLivLedgerViewSecurity(): boolean {
    return this.authzService?.currentUserHasRole?.(UserRoles.FULL_LEDGER_VIEWER_ROLE) ?? false;
  }

  get isOTCHedgeCreator() {
    return this.authzService?.currentUserHasRole?.(UserRoles.HEDGED_CONTRACT_CREATOR_ROLE) ?? false;
  }

  get defaultType() {
    return this.isStandardHedgeCreator ? HedgeType.STANDARD : HedgeType.OTC;
  }

  submit() {
    // Create a new hedge object to store in the database
    const newHedge = new Hedge();
    newHedge.side = this.hedgeForm.get('side').value;
    newHedge.productionYear = this.hedgeForm.get('prodYear').value.year;
    const commodityProfile = this.hedgeForm.get('commodityProfile').value;
    newHedge.commodityProfileDocId = commodityProfile.docId;
    newHedge.type = this.hedgeForm.get('type').value;

    // multiply number of contracts with the contractSize of the commodityProfile
    const contractSize = this.commodities.find(commodity => commodity.id === commodityProfile.commodityId).contractSize;
    newHedge.quantity = parseInt(this.hedgeForm.get('contracts').value, 10) * contractSize;

    // get futures period
    const futuresYear = moment(this.hedgeForm.get('futuresYearMonth').value).format('YY');
    const futuresMonth = this.contractMonths[moment(this.hedgeForm.get('futuresYearMonth').value).get('month')];
    newHedge.futuresYearMonth = `${futuresYear}${futuresMonth}`;

    // Set orderDocId from form for standard hedges
    if (newHedge.type === HedgeType.STANDARD) {
      newHedge.orderDocId = this.hedgeForm.get('orderId').value;
    }

    // Set price and related quantity for OTC hedges
    if (newHedge.type === HedgeType.OTC) {
      newHedge.price = Number(this.hedgeForm.get('price').value);
      newHedge.relatedQuantity = Number(this.hedgeForm.get('relatedQuantity').value);
    }

    // Set comments if they exist
    const comments = this.hedgeForm.get('comments').value;
    if (comments) {
      newHedge.comments = comments;
    }

    newHedge.creatorDocId = this.loggedInUser.docId;
    newHedge.creatorName = `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`;

    // Create new hedge by calling the hedge service
    this.updateComplete = false;
    this.hedgeService.createHedge(this.selectedClientDocId, newHedge)
      .then(async () => {
        this.updateComplete = true;
        const routeToLiveLedgerScreen = this.hasLivLedgerViewSecurity;
        const routeToHedgesSearchPage = this.isOTCHedgeCreator && !this.hasLivLedgerViewSecurity;

        if (routeToLiveLedgerScreen) {
          this.openSnackBar('Hedge successfully created', 'DISMISS', true);
          this.router.navigate(['/liveledgers/activityledger'], { replaceUrl: true });
        } else if (routeToHedgesSearchPage) {
          this.openSnackBar('Hedge successfully created', 'DISMISS', true);
          this.router.navigate(['/hedges'], { replaceUrl: true });
        }
      })
      .then(async () => {
        if (this.alertDocId) {
          try {
            await this.appAlertService.updateAppAlertStatus(this.alertDocId, AlertStatus.COMPLETE, newHedge.docId);
          } catch (err) {
            console.error(`App alert update failed: ${err}`);
            Promise.resolve();
          }
        }
        Promise.resolve();
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`Hedge creation failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Hedge creation failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  selectFuturesYearMonth(e: moment.Moment) {
    this.hedgeForm.get('futuresYearMonth').setValue(e);
    this.futuresYearMonthRef.close();
  }

  processUpdatedPrice(formControlName: string) {
    const price = Number(this.hedgeForm.get(formControlName).value);
    if (this.hedgeForm.get(formControlName).value && Number.isFinite(price)) {
      this.hedgeForm.get(formControlName).setValue(price.toFixed(4));
    } else {
      this.hedgeForm.get(formControlName).setValue('');
    }
  }

  formatQuantity(formControlName: string) {
    const formControl = this.hedgeForm.get(formControlName);
    const quantity = Number(formControl.value);
    if (formControl.value && Number.isFinite(quantity)) {
      formControl.setValue(quantity.toString());
    }
  }

  reset() {
    this.hedgeForm.reset(
      {
        type: this.defaultType
      }
    );
    this.populateFormFromQueryParam();
    this.hedgeForm.markAsPristine();
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('pattern')) {
      return 'Value invalid';
    } else if (control.hasError('maxlength')) {
      return 'Value cannot exceed ' + control.errors['maxlength'].requiredLength + ' characters';
    } else if (this.hedgeForm.hasError('invalidFuturesMonth')) {
      return 'Value invalid';
    }
    return 'Unknown Error';
  }

  compareCommodityProfile(cp1: CommodityProfile, cp2: CommodityProfile) {
    return cp1 && cp2 && cp1.docId === cp2.docId;
  }

  compareProductionYear(prodYear1: CommodityProfileProductionYear, prodYear2: CommodityProfileProductionYear) {
    return prodYear1 && prodYear2 && prodYear1.year === prodYear2.year;
  }

  validFuturesMonthFilter = (currentMoment: moment.Moment | null): boolean => {
    if (currentMoment) {
      const month = currentMoment.month();
      const commodityProfile = this.hedgeForm.get('commodityProfile').value as CommodityProfile;

      if (commodityProfile) {
        // get valid futures month for the current commodity profile
        this.validFuturesMonths = this.futuresMonths[commodityProfile.commodityId];
        return this.validFuturesMonths.includes(month);
      }

      // return true for any month above or the same as current month
      return true;
    } else {
      return true;
    }
  }

  createHedgeOrder() {
    if (this.qstService.enabled) {
      const commodityProfile = this.hedgeForm.get('commodityProfile').value as CommodityProfile;
      const accountNumber = `${commodityProfile.officeCode}${commodityProfile.accountNumber}`;
      const futuresYear = moment(this.hedgeForm.get('futuresYearMonth').value).format('YY');
      const futuresMonth = this.contractMonths[moment(this.hedgeForm.get('futuresYearMonth').value).get('month')];
      const futuresMonthYear = `${futuresMonth}${futuresYear}`;

      const side = this.hedgeForm.get('side').value;
      const contract = `${commodityProfile.commodityId}${futuresMonthYear}`;
      const quantity = this.hedgeForm.get('contracts').value;

      this.qstWorking = true;
      const commodity = this.commoditiesMap[commodityProfile.commodityId];
      this.qstService.createOrder(commodity, contract, quantity, accountNumber, side,
        OrderType.MARKET, undefined, undefined, TimeInForce.GTC)
        .then(clientOrderId => {
          this.newOrderClientId = clientOrderId;
        })
        .catch(err => {
          console.log(`QST error response in new hedge: ${JSON.stringify(err)}`);
          this.qstWorking = false;
          this.openSnackBar('Error from QST: ' + err, 'DISMISS', false);
        });
    }
  }

  readyToCreateHedgeOrder() {
    return this.hedgeForm.get('side').valid && this.hedgeForm.get('commodityProfile').valid &&
      this.hedgeForm.get('contracts').valid && this.hedgeForm.get('futuresYearMonth').valid;
  }

  private populateFormFromQueryParam() {
    const queryProfile = this.commodityProfiles.find(profile => profile.docId === this.qpProfileDocId);

    if (queryProfile) {
      this.hedgeForm.get('commodityProfile').setValue(queryProfile);
      const queryProdYear = queryProfile.productionYears[this.qpProdYear];
      this.hedgeForm.get('prodYear').setValue(queryProdYear);

      if (this.dailyLedger) {
        const triggerLongHedge = parseInt(this.dailyLedger.hedgeableQuantity.toFixed(0), 10) >= Math.abs(queryProfile.longThreshold);
        const triggerShortHedge = parseInt(this.dailyLedger.hedgeableQuantity.toFixed(0), 10) <= -Math.abs(queryProfile.shortThreshold);

        let hedgeMonth: string;
        let side: Side;
        if (triggerLongHedge) {
          hedgeMonth = queryProdYear.hedgeSellFuturesYearMonth;
          side = Side.SELL;
        } else if (triggerShortHedge) {
          hedgeMonth = queryProdYear.hedgeBuyFuturesYearMonth;
          side = Side.BUY;
        } else {
          // don't set form values in the event something has changed since alert was created
          this.openSnackBar('A hedge may no longer be needed. Please review the current long/short position.', 'DISMISS', false);
          return;
        }
        this.hedgeForm.get('side').setValue(side);
        this.hedgeForm.get('futuresYearMonth').setValue(moment(hedgeMonth).endOf('month'));
        const contractSize = this.commodities.find(commodity => commodity.id === queryProfile.commodityId).contractSize;
        const absHedgeableQuantity = Math.abs(this.dailyLedger.hedgeableQuantity);
        const fullContracts = Math.trunc(absHedgeableQuantity / contractSize);
        const remainingQuantity = absHedgeableQuantity % contractSize;
        const roundedRemainingQuantity = parseInt(remainingQuantity.toFixed(0), 10);
        const threshold = side === Side.BUY ? queryProfile.shortThreshold : queryProfile.longThreshold;
        const hedgeQuantity = roundedRemainingQuantity >= Math.abs(threshold) ? fullContracts + 1 : fullContracts;
        this.hedgeForm.get('contracts').setValue(hedgeQuantity);

        // Set values for Order ID hint
        this.quantity = hedgeQuantity;
        this.side = side;
        this.futuresYearMonth = moment(hedgeMonth).endOf('month');
        this.commodityId = queryProfile.commodityId;
      }
    }
  }

  private onCommodityProfileChanges() {
    this.hedgeForm.get('commodityProfile').valueChanges.subscribe((profile: CommodityProfile) => {
      if (profile) {
        this.prodYears = Object.values(
          this.ledgerHelperService.getTranslatedUniqueActiveProdYears(profile.productionYears, this.ledgerEndOfDay, this.timezone)
        );
      } else {
        this.prodYears = [];
      }

      this.hedgeForm.get('prodYear').setValue('');
      this.selectedCommodityProfile = profile;
    });
  }

  private onSideAndProdYearChanges() {
    combineLatest([this.hedgeForm.get('side').valueChanges, this.hedgeForm.get('prodYear').valueChanges])
      .subscribe(([side, prodYear]) => {
        if (side && prodYear) {
          if (side === Side.BUY) {
            this.hedgeForm.get('futuresYearMonth').setValue(moment(prodYear['hedgeBuyFuturesYearMonth']));
          } else {
            this.hedgeForm.get('futuresYearMonth').setValue(moment(prodYear['hedgeSellFuturesYearMonth']));
          }
        }
      });
  }

  private onTypeChanges() {
    this.hedgeForm.get('type').valueChanges.subscribe(type => {
      if (type === HedgeType.STANDARD) {
        this.hedgeForm.get('orderId').enable();

        this.hedgeForm.get('price').disable();
        this.hedgeForm.get('relatedQuantity').disable();
      } else {
        this.hedgeForm.get('price').enable();
        this.hedgeForm.get('relatedQuantity').enable();

        this.hedgeForm.get('orderId').disable();
      }
    });
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private setOrderDocId(exchangeOrderId: string) {
    this.hedgeForm.get('orderId').setValue(exchangeOrderId);
  }

}
