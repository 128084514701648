import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';

import { AuthService } from '@advance-trading/angular-ati-security';
import { UserService } from '@advance-trading/angular-ops-data';
import { NgxMatIntlTelInputComponent } from '@advance-trading/ngx-mat-intl-tel-input';
import { PhoneNumberType, Status, User } from '@advance-trading/ops-data-lib';
import { take } from 'rxjs/operators';

@Component({
  selector: 'hms-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  personalInfoForm = new FormGroup({
    firstName: new FormControl('', [
      Validators.required
    ]),
    lastName: new FormControl('', [
      Validators.required
    ]),
    mobilePhone: new FormControl('', [
      Validators.required
    ])
  });

  accountInfoForm = new FormGroup({
    number: new FormControl('', [
      Validators.required
    ]),
    contactName: new FormControl('', [
      Validators.required])
  });

  userStatus = '';
  isLinear = false;
  step1State: string;
  step2State: string;
  step3State: string;
  step1Editable = true;
  step2Editable = true;

  @ViewChild(MatStepper, { static: true }) stepper: MatStepper;
  @ViewChild('mobilePhone', { static: false }) mobilePhone: NgxMatIntlTelInputComponent;

  // An indicator to help with displaying the progress spinner for step 1; initialize to false to display button
  regTypeInProgress = false;

  private snackBarRef: MatSnackBarRef<SimpleSnackBar>;
  private user: User;

  constructor(
    public authService: AuthService,
    public snackBar: MatSnackBar,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userService.getUserByAuthId(this.authService.userProfile.sub).pipe(
      take(1)
    ).subscribe(currentUser => {
      // Initialize a new User if they haven't registered
      if (!currentUser) {
        this.user = new User(
          this.authService.userProfile.sub,
          this.authService.userProfile.email
        );
      } else {
        // Move everyone else to the last step
        this.step1State = 'done';
        this.step2State = 'done';
        this.step3State = 'done';
        this.step1Editable = false;
        this.step2Editable = false;
        this.stepper.selectedIndex = 2;
        this.personalInfoForm.disable();
        this.accountInfoForm.disable();
        // Store the status for customizing the final step message
        this.userStatus = currentUser.status;
      }
    }, err => console.error(`Error from getUser: ${err}`));
  }

  savePersonalInfo() {
    this.regTypeInProgress = true;
    this.userService.getRegistrationType(this.authService.accessToken)
      .then(regTypeResponse => {
        this.regTypeInProgress = false;
        this.user.firstName = this.personalInfoForm.value.firstName;
        this.user.lastName = this.personalInfoForm.value.lastName;
        this.user.phoneNumbers = [];
        this.user.phoneNumbers.push({
          countryCode: this.mobilePhone.selectedCountry.dialCode,
          number: String(this.mobilePhone.phoneNumber),
          type: PhoneNumberType.MOBILE
        });
        this.stepper.selected.completed = true;
        this.stepper.next();
        if (!regTypeResponse.requiresAccountNumber) {
          this.accountInfoForm.get('number').setValue('**NOT REQUIRED**');
          this.accountInfoForm.get('contactName').setValue('**NOT REQUIRED**');
          this.saveAccountInfo();
        }
      })
      .catch(err => {
        console.error(err);
        this.regTypeInProgress = false;
        this.openSnackBar('An unexpected error ocurred. Please try again.', 'RETRY');
      });
  }

  saveAccountInfo() {
    this.user.status = Status.PENDING;
    this.user.registrationAccount = this.accountInfoForm.value.number;
    this.user.registrationContactName = this.accountInfoForm.value.contactName;

    this.userService.createUser(this.user)
      .then(response => {
        this.stepper.selected.completed = true;
        this.stepper.next();
        this.step3State = 'done';
        this.step1Editable = false;
        this.step2Editable = false;
      })
      .catch(err => {
        console.error(err);
        this.openSnackBar('An unexpected error ocurred. Please try again.', 'RETRY');
      });
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('validatePhoneNumber')) {
      return 'Invalid phone number';
    } else if (control.hasError('required')) {
      return 'You must enter a value';
    }
    return 'Unknown error';
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string) {
    this.snackBarRef = this.snackBar.open(message, action, {
      verticalPosition: 'bottom'
    });
    this.snackBarRef.onAction().subscribe(() => {
      switch (this.stepper.selectedIndex) {
        case 0:
          this.savePersonalInfo();
          break;
        case 1:
          this.saveAccountInfo();
          break;
        case 2:
          // Do nothing
        default:
          // Do nothing
      }
    });
  }
}
