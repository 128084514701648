import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'hms-confirm-dialog',
  template: `
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
    <p>Do you want to continue?</p>
  </div>
  <div mat-dialog-actions>
    <button type="button" id="confirm-dialog-no" aria-label="No" class="secondary" mat-button [mat-dialog-close]="false">No</button>
    <button type="button" id="confirm-dialog-yes" aria-label="Yes" class="primary" mat-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
  </div>`,
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  title: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data = {}) {
      this.title = data['title'];
    }
}
