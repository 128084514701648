import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';

import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { Client, CommodityProfile, CommodityProfileProductionYear, HMSDailyLedger } from '@advance-trading/ops-data-lib';

import { BasisSummaryDisplay } from './basis-summary-display';
import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { HMSDailyLedgerService } from '../../service/hms-daily-ledger.service';
import { LedgerHelperService } from '../../service/ledger-helper.service';
import { LedgerDay } from '../../utilities/ledger-day';

const FULLDATE_FORMAT = 'YYYY-MM-DD';

@Component({
  selector: 'hms-basis-summary',
  templateUrl: './basis-summary.component.html',
  styleUrls: ['./basis-summary.component.scss']
})
export class BasisSummaryComponent implements OnInit, OnChanges {

  @Input() commodityProfile: CommodityProfile;
  @Input() commodityProfiles: CommodityProfile[];
  @Output() basisSummaryError = new EventEmitter<string>();
  @Output() loaded = new EventEmitter<boolean>();

  dataSource = new ObservableDataSource<BasisSummaryDisplay>();
  columnsToDisplay = [];
  profileProdYearLabels = [];

  private todayDate: string;
  private ledgerEndOfDay: string;
  private timezone: string;

  constructor(
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private hmsDailyLedgerService: HMSDailyLedgerService,
    private ledgerHelperService: LedgerHelperService
  ) { }

  ngOnInit() {
    if (!this.commodityProfile) {
      this.setDataSourceForAllCommodityProfiles();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['commodityProfile']) {
      let selectedClientDocId;
      this.dataSource.data$ = this.clientSelectorService.getSelectedClient().pipe(
        switchMap((selectedClient: Client) => {
          selectedClientDocId = selectedClient.docId;
          return this.clientSettingsService.getHmsLedgerEndOfDaySettingsByClientDocId(selectedClientDocId);
        }),
        switchMap((ledgerDay: LedgerDay) => {
          this.ledgerEndOfDay = this.ledgerHelperService.getLedgerEndOfDayFromMoment(ledgerDay.ledgerEndOfDay);
          this.timezone = ledgerDay.timezone;
          this.setDateParam();

          // this can't happen until ledgerEndOfDay and timezone are set or the columns won't adjust for rolled profiles
          const activeProductionYears = this.ledgerHelperService.getTranslatedUniqueActiveProdYears(
            this.commodityProfile.productionYears, this.ledgerEndOfDay, this.timezone);

          this.setProfileProductionYearLabels([this.commodityProfile]);
          this.columnsToDisplay = [];
          this.profileProdYearLabels.forEach(label => {
            this.columnsToDisplay.push(label);
          });

          return combineLatest(
            Object.values(activeProductionYears).map((productionYear: CommodityProfileProductionYear) => {
              return this.hmsDailyLedgerService
                         .getHMSDailyLedgerByCommodityProfileAndProductionYear(selectedClientDocId, this.commodityProfile.docId,
                                                                               productionYear.year, this.todayDate);
            })
          ).pipe(
            map((hmsDailyLedgers: HMSDailyLedger[][]) => {
              const basisQtyProdYears: {[key: string]: number} = {};
              hmsDailyLedgers.forEach((dailyLedgers: HMSDailyLedger[]) => {
                dailyLedgers.forEach((dailyLedger: HMSDailyLedger) => {
                  const currentProdYearLabel = activeProductionYears[dailyLedger.productionYear].label;
                  basisQtyProdYears[currentProdYearLabel] = dailyLedger.basisQuantity;
                });
              });

              return [{
                activeProductionYears,
                basisQtyProdYears
              }] as BasisSummaryDisplay[];
            })
          );
        }),
        tap((basisSummary: BasisSummaryDisplay[]) => {
          this.loaded.emit();
        }),
        catchError(err => {
          if (err.name === 'MissingLedgerEndOfDayError') {
            this.basisSummaryError.emit(err.message);
          } else {
            this.basisSummaryError.emit('Error retrieving basis summary; please try again later');
          }
          console.error(`Error retrieving basis summary: ${err}`);
          return of([]);
        })
      );
    } else if (changes[ 'commodityProfiles' ]) {
      this.setDataSourceForAllCommodityProfiles();
    }
  }

  private getBasisSummary(selectedClientDocId: string): Observable<BasisSummaryDisplay[]> {
    if (this.commodityProfiles.length === 0) {
      return of([]);
    }
    this.columnsToDisplay = [];
    this.setProfileProductionYearLabels(this.commodityProfiles);

    return combineLatest(this.commodityProfiles.map((profile: CommodityProfile) => {
      // only get active production years
      const activeProductionYears = this.ledgerHelperService.getTranslatedUniqueActiveProdYears(
        profile.productionYears, this.ledgerEndOfDay, this.timezone);
      // get today's accumulating hedgeable quantity
      return combineLatest(
        Object.values(activeProductionYears).map((productionYear: CommodityProfileProductionYear) => {
          return this.hmsDailyLedgerService
            .getHMSDailyLedgerByCommodityProfileAndProductionYear(selectedClientDocId, profile.docId,
              productionYear.year, this.todayDate);
        })).pipe(
          map((hmsDailyLedgers: HMSDailyLedger[][]) => {
            const basisQtyProdYears: { [ key: string ]: number } = {};
            hmsDailyLedgers.forEach((dailyLedgers: HMSDailyLedger[]) => {
              dailyLedgers.forEach((dailyLedger: HMSDailyLedger) => {
                const currentProdYearLabel = activeProductionYears[ dailyLedger.productionYear ].label;
                basisQtyProdYears[ currentProdYearLabel ] = dailyLedger.basisQuantity;
              });
            });

            return {
              commodityProfile: profile,
              activeProductionYears,
              basisQtyProdYears
            } as BasisSummaryDisplay;

          })
        );
    }));
  }

  private setProfileProductionYearLabels(profiles: CommodityProfile[]) {
    this.profileProdYearLabels = [];
    profiles.forEach((profile: CommodityProfile) => {
      const prodYears = this.ledgerHelperService.getTranslatedUniqueActiveProdYears(
        profile.productionYears, this.ledgerEndOfDay, this.timezone);
      Object.values(prodYears).forEach((prodYear: CommodityProfileProductionYear) => {
        this.profileProdYearLabels.push(prodYear.label);
      });
    });
    // filter production year label duplicates
    this.profileProdYearLabels = [... new Set(this.profileProdYearLabels)];
  }

  private setDataSourceForAllCommodityProfiles() {
    let selectedClientDocId;
    this.dataSource.data$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((selectedClient: Client) => {
        selectedClientDocId = selectedClient.docId;
        return this.clientSettingsService.getHmsLedgerEndOfDaySettingsByClientDocId(selectedClientDocId);
      }),
      switchMap((ledgerDay: LedgerDay) => {
        this.ledgerEndOfDay = this.ledgerHelperService.getLedgerEndOfDayFromMoment(ledgerDay.ledgerEndOfDay);
        this.timezone = ledgerDay.timezone;
        this.setDateParam();
        return this.getBasisSummary(selectedClientDocId);
      }),
      tap((basisSummary: BasisSummaryDisplay[]) => {
        this.columnsToDisplay = [ 'commodityProfile' ];
        this.profileProdYearLabels.forEach(label => {
          this.columnsToDisplay.push(label);
        });
        this.loaded.emit();
      }),
      catchError(err => {
        if (err.name === 'MissingLedgerEndOfDayError') {
          this.basisSummaryError.emit(err.message);
        } else {
          this.basisSummaryError.emit('Error retrieving basis summary; please try again later');
        }
        console.error(`Error retrieving basis summary: ${err}`);
        return of([]);
      })
    );
  }

  private setDateParam() {
    this.todayDate = moment.tz(this.ledgerHelperService.getCurrentBusinessDay(this.ledgerEndOfDay, this.timezone), this.timezone)
      .format(FULLDATE_FORMAT);
  }

}
