<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <div *ngIf="errorMessage">
      <hms-error-message [title]="'Error Retrieving New Commodity Profile Form'" [message]="errorMessage"></hms-error-message>
    </div>

    <form *ngIf="!errorMessage" [formGroup]="commodityProfileForm" (ngSubmit)="submit()" fxLayout="column" fxLayoutGap="15px">
      <!-- Title and Buttons -->
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="card-title">New Commodity Profile</h2>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
          <button id="create-commodity-profile" class="action-button" aria-label="Create Commodity Profile" *ngIf="updateComplete"
                  [disabled]="!commodityProfileForm.valid || (!hasProdYears && !this.commodityProfileForm.get('isSpread').value)"
                  type="submit" mat-mini-fab color="accent" matTooltip="Create Commodity Profile">
            <mat-icon>done</mat-icon>
          </button>
          <button type="button" id="clear-commodity-profile" class="action-button" aria-label="Discard Changes" *ngIf="!commodityProfileForm.pristine && updateComplete" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-spinner *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
        </div>
      </div>

      <!-- Name, Account, isActive, isSpread -->
      <div fxLayoutGap="5%" fxLayout.gt-xs="row wrap" fxLayoutAlign.gt-xs="start start" fxLayout.xs="column">
        <mat-form-field fxFlex="20">
          <input required matInput placeholder="Name" trim="blur" formControlName="name" />
          <mat-error *ngIf="commodityProfileForm.get('name').invalid">{{ getErrorMessage(commodityProfileForm.get('name')) }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="20">
          <mat-select [compareWith]="compareAccount" #account required placeholder="Account" formControlName="account">
            <mat-option *ngFor="let account of accounts$ | async" [value]="account">{{ account.officeCode + account.number + ' ' + account.name }}</mat-option>
          </mat-select>
          <mat-error *ngIf="commodityProfileForm.get('account').invalid">{{ getErrorMessage(commodityProfileForm.get('account')) }}</mat-error>
        </mat-form-field>
        <div class="checkbox-group" fxFlex="20">
          <span class="check-radio-label mat-small mat-hint">Status</span>
          <mat-checkbox formControlName="isActive">Active</mat-checkbox>
        </div>
        <mat-checkbox fxFlex="20" formControlName="isSpread">Spread Profile</mat-checkbox>
      </div>

      <!-- Accounting System ID, Commodity, Default Grade, Has Basis -->
      <div *ngIf="!commodityProfileForm.get('isSpread').value" fxLayoutGap="5%" fxLayout.gt-xs="row wrap" fxLayoutAlign.gt-xs="start start" fxLayout.xs="column">
        <mat-form-field fxFlex="20">
          <input required matInput placeholder="ID" trim="blur" mask="A*" formControlName="accountingSystemId" />
          <mat-error *ngIf="commodityProfileForm.get('accountingSystemId').invalid">{{ getErrorMessage(commodityProfileForm.get('accountingSystemId')) }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="20">
          <mat-select [compareWith]="compareCommodity" #commodity required placeholder="Commodity" formControlName="commodity">
            <mat-option *ngFor="let commodity of commodities" [value]="commodity">{{ commodity.name + ' (' + commodity.id + ')' }}</mat-option>
          </mat-select>
          <mat-error *ngIf="commodityProfileForm.get('commodity').invalid">{{ getErrorMessage(commodityProfileForm.get('commodity')) }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="20">
          <input required matInput placeholder="Default Grade" trim="blur" mask="A*" formControlName="defaultGrade" />
          <mat-error *ngIf="commodityProfileForm.get('defaultGrade').invalid">{{ getErrorMessage(commodityProfileForm.get('defaultGrade')) }}</mat-error>
        </mat-form-field>
        <div class="checkbox-group" fxFlex="20">
          <span class="check-radio-label mat-small mat-hint">Basis</span>
          <mat-checkbox formControlName="hasBasis">Enabled</mat-checkbox>
        </div>
      </div>

      <!-- Target Order Threshold, Short Threshold, Long Threshold -->
      <div *ngIf="!commodityProfileForm.get('isSpread').value" fxLayoutGap="5%" fxLayout.gt-xs="row wrap" fxLayoutAlign.gt-xs="start start" fxLayout.xs="column">
        <mat-form-field fxFlex="20">
          <input required matInput placeholder="Target Order Threshold" formControlName="targetOrderThreshold" trim="blur">
          <mat-error *ngIf="commodityProfileForm.get('targetOrderThreshold').invalid">{{ getErrorMessage(commodityProfileForm.get('targetOrderThreshold')) }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="20">
          <input (blur)="ensureNegative('shortThreshold')" required matInput placeholder="Short Threshold" formControlName="shortThreshold" trim="blur">
          <mat-error *ngIf="commodityProfileForm.get('shortThreshold').invalid">{{ getErrorMessage(commodityProfileForm.get('shortThreshold')) }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="20">
          <input required matInput placeholder="Long Threshold" formControlName="longThreshold" trim="blur">
          <mat-error *ngIf="commodityProfileForm.get('longThreshold').invalid">{{ getErrorMessage(commodityProfileForm.get('longThreshold')) }}</mat-error>
        </mat-form-field>
      </div>

      <!-- Min Price, Max Price -->
      <div *ngIf="!commodityProfileForm.get('isSpread').value" fxLayoutGap="5%" fxLayout.gt-xs="row wrap" fxLayoutAlign.gt-xs="start start" fxLayout.xs="column">
        <mat-form-field fxFlex="20">
          <input (blur)="format4('minPrice')" required matInput placeholder="Minimum Price" formControlName="minPrice" trim="blur">
          <mat-error *ngIf="commodityProfileForm.get('minPrice').invalid">{{ getErrorMessage(commodityProfileForm.get('minPrice')) }}</mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="20">
          <input (blur)="format4('maxPrice')" required matInput placeholder="Maximum Price" formControlName="maxPrice" trim="blur">
          <mat-error *ngIf="commodityProfileForm.get('maxPrice').invalid">{{ getErrorMessage(commodityProfileForm.get('maxPrice')) }}</mat-error>
        </mat-form-field>
      </div>

      <!-- Hiding Production Years as Futures Months filter dependent on commodity -->
      <div *ngIf="commodityProfileForm.get('commodity').value && !commodityProfileForm.get('isSpread').value">
          <hms-production-year *ngFor="let productionYearGroup of commodityProfileForm.get('prodYears').controls | keyvalue ; trackBy:trackByYear" [commodityId]="commodityProfileForm.get('commodity').value.id"
                              [futuresMonths]="futuresMonths" [productionYear]="productionYearGroup.value" (productionYearUpdated)="onProductionYearUpdated($event)" (productionYearRemoved)="onProductionYearRemoved($event)"
                              [productionYears]="commodityProfileForm.get('prodYears')"[lastAddedProductionYear]="lastAddedProductionYear" [addingProdYear]="addingProdYear"></hms-production-year>
          <div class="production-year-btn-container">
            <button *ngIf="!addingProdYear && canAdd" id="add-production-year" type="button" aria-label="Add Production Year" color="accent" mat-icon-button (click)="addProductionYear()" matTooltip="Add Production Year">
              <mat-icon>add_circle</mat-icon>
            </button>
          </div>
          <hms-production-year *ngIf="addingProdYear" [commodityId]="commodityProfileForm.get('commodity').value.id"
                               [futuresMonths]="futuresMonths" (productionYearUpdated)="onProductionYearCreated($event)" (productionYearRemoved)="onProductionYearRemoved($event)" [productionYears]="commodityProfileForm.get('prodYears')"
                               [lastAddedProductionYear]="lastAddedProductionYear" #newProdYear></hms-production-year>
      </div>
    </form>

  </mat-card>
</div>
