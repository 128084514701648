
import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { HMSClientSettings } from '@advance-trading/ops-data-lib';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { UserRoles } from '../../utilities/user-roles';

import moment from 'moment';

@Component({
  selector: 'hms-billing-settings',
  templateUrl: './billing-settings.component.html',
  styleUrls: ['./billing-settings.component.scss']
})
export class BillingSettingsComponent implements OnInit {

  @Input() editMode: boolean;
  @Input() clientSettings: HMSClientSettings;
  @Input() clientSettingsForm: FormGroup;
  @Input() activeTabName: string;
  @Output() billingError = new EventEmitter<boolean>();

  /** ISO string for tomorrow's date. */
  tomorrow = moment().add(1, 'day').toISOString();
  errorMessage: string;

  constructor(
    private authzService: Auth0AuthzService
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.HMS_ADMIN_ROLE)) {
      this.errorMessage = 'You do not have permission to administer client billing settings.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }
    this.billingDayRequired();
  }

  billingDayRequired() {
    this.clientSettingsForm.get('billingDay').valueChanges.subscribe(value => {
      if(!Number.isFinite(Number(value)) || !value || value === '0') {
        this.clientSettingsForm.get('billingMonthlyFlatFee').disable();
        this.clientSettingsForm.get('billingUsageRate').disable();
        this.clientSettingsForm.get('billingStartDate').disable();
        this.clientSettingsForm.get('billingRenewalDate').disable();
        this.clientSettingsForm.get('billingContactName').disable();
        this.clientSettingsForm.get('billingContactEmail').disable();
      } else {
        this.clientSettingsForm.get('billingMonthlyFlatFee').enable();
        this.clientSettingsForm.get('billingUsageRate').enable();
        this.clientSettingsForm.get('billingStartDate').enable();
        this.clientSettingsForm.get('billingRenewalDate').enable();
        this.clientSettingsForm.get('billingContactName').enable();
        this.clientSettingsForm.get('billingContactEmail').enable();      }
     });
  }

  clearField(fieldName: string, resetValue?: string) {
    const field = this.clientSettingsForm.get(fieldName);
    field.setValue(resetValue || '');
    field.markAsDirty();
  }

  getStartDate() {
    return typeof this.clientSettings.billingStartDate === 'string' ? this.clientSettings.billingStartDate : '';
  }

  getRenewalDate() {
    return typeof this.clientSettings.billingRenewalDate === 'string' ? this.clientSettings.billingRenewalDate : '';
  }

  getErrorMessage(control: FormControl) {
    this.activeTabName === 'Billing' ? this.billingError.emit(false) : this.billingError.emit(true);
    if (control.hasError('max')) {
      return `Value cannot exceed ${control.errors['max'].max}`;
    } else if (control.hasError('min')) {
      return `Value cannot be lower than 0, where 0 indicates a non billed customer`;
    } else if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('maxlength')) {
      return `Value cannot exceed ${control.errors['maxlength'].requiredLength} character${control.errors['maxlength'].requiredLength === 1 ? '' : 's'}`;
    } else  if (control.hasError('pattern')) {
      return 'Invalid value';
    }
    return 'Unknown error';
  }

  /**
   * Rounds a value to the appropriate number of decimal places for the type of data it represents, e.g. a calculatedCash value should
   * be passed through with the fieldName "cashPrice", even though it may not be stored in the cashPrice field. Needed to keep fixed
   * trailing zeroes for display
   *
   * @param fieldName The name of the field having its value set
   * @param value The value to be set on the field
   */
  public getRoundedValueAsString(value: number, precision: number): string {
    if(!Number.isFinite(Number(value)) || !value || Number(value) < 0) return '';
    return this.roundToPrecision(value, precision).toFixed(precision);
  }

  private roundToPrecision(value: number, precision: number): number {
    return Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
  }

  public formatToPrecision(fieldName: string, precision: number) {
    let value = this.getRoundedValueAsString(this.clientSettingsForm.get(fieldName).value, precision)
    this.clientSettingsForm.get(fieldName).setValue(value);
  }

}
