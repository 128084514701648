import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import * as moment from 'moment';
import { concat, Observable, ReplaySubject, timer } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { AuthService } from '@advance-trading/angular-ati-security';
import { AlertStatus, AppAlert, User } from '@advance-trading/ops-data-lib';

const EXISTING_STATUS_ERROR_MSG = 'AppAlert status cannot be updated to existing status';

@Injectable({
  providedIn: 'root'
})
export class AppAlertService {

  constructor(
    private authService: AuthService,
    private db: AngularFirestore
  ) {}

  /**
   * Return a single AppAlert document by a specificed docId
   *
   * @param docId The docId of the Alert to be returned
   */
  getAppAlertByDocId(docId: string): Observable<AppAlert> {
    return this.db.doc<AppAlert>(AppAlert.getDataPath(docId)).valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Retrieves all AppAlerts associated with the provided clientDocId and application with the provided status
   * @param clientDocId The docId of the Client
   * @param application One of 'HMS', 'ATOM', or 'ATI_MOBILE' to filter AppAlerts returned
   * @param userRoles The roles associated with the app alerts a user should be receiving
   * @param status AlertStatus to filter on
   */
  getClientAppAlertsByStatusInAscendingDate(
    clientDocId: string, application: string, userRoles: string[], status: AlertStatus): Observable<AppAlert[]> {
    return this.db.collection<AppAlert>(AppAlert.getDataPath(), ref => ref
      .where('clientDocId', '==', clientDocId)
      .where('application', '==', application)
      .where('recipientRole', 'in', userRoles)
      .where('status', '==', status)
      .orderBy('lastUpdatedTimestamp'))
      .valueChanges()
      .pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Retrieves realtime (current local date) completed AppAlerts with the provided clientDocId
   * @param clientDocId The docId of the Client
   * @param userRoles The roles associated with the app alerts a user should be receiving
   */
  getRealtimeCompletedClientAppAlerts(clientDocId: string, userRoles: string[]) {
    const dayPassed = new ReplaySubject<moment.Moment>(1);
    const dayPassed$ = dayPassed.asObservable();
    let retrievedMoment;
    let isRetrievedMomentEmitted;
    let nextDayMoment;
    return dayPassed$.pipe(
      startWith(moment()),
      switchMap((dayMoment: moment.Moment) => {
        retrievedMoment = dayMoment;
        nextDayMoment = moment(dayMoment).add(1, 'days').startOf('day');
        isRetrievedMomentEmitted = false;
        return concat(timer(), timer(nextDayMoment.diff(dayMoment)));
      }),
      map(() => {
        if (isRetrievedMomentEmitted) {
          dayPassed.next(nextDayMoment);
        } else {
          isRetrievedMomentEmitted = true;
        }
        return moment(retrievedMoment);
      }),
      switchMap((currMoment: moment.Moment) => {
        const startDate = currMoment.startOf('day').toISOString();
        const endDate = currMoment.endOf('day').toISOString();
        return this.getClientAppAlertsByStatusAndDate(clientDocId, AlertStatus.COMPLETE, startDate, endDate, 'HMS', userRoles);
      }),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  // no search criteria

  /**
   * Retrieves all AppAlerts associated with the provided clientDocId and application
   * @param clientDocId The docId of the Client
   * @param application One of 'HMS', 'ATOM', or 'ATI_MOBILE' to filter AppAlerts returned
   * @param userRoles The roles associated with the app alerts a user should be receiving
   */
  getClientAppAlerts(clientDocId: string, application: string, userRoles: string[]): Observable<AppAlert[]> {
    return this.db.collection<AppAlert>(AppAlert.getDataPath(), ref => ref
      .where('clientDocId', '==', clientDocId)
      .where('application', '==', application)
      .where('recipientRole', 'in', userRoles)
      .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges()
      .pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  // one search criteria

  /**
   * Return all Alert documents with a particular title for a particular client and application
   *
   * @param clientDocId The docId of the Client containing the Alerts
   * @param title The title of the Alerts
   * @param application One of 'HMS', 'ATOM', or 'ATI_MOBILE' to filter AppAlerts returned
   * @param userRoles The roles associated with the app alerts a user should be receiving
   */
  getClientAppAlertsByTitle(clientDocId: string, title: string, application: string, userRoles: string[]): Observable<AppAlert[]> {
    return this.db.collection<AppAlert>(AppAlert.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('title', '==', title)
      .where('application', '==', application)
      .where('recipientRole', 'in', userRoles)
      .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
        shareReplay({bufferSize: 1, refCount: true})
      );
  }

  /**
   * Return all Alert documents with a particular status for a particular client and application
   *
   * @param clientDocId The docId of the Client containing the Alerts
   * @param status The status of the Alerts
   * @param application One of 'HMS', 'ATOM', or 'ATI_MOBILE' to filter AppAlerts returned
   * @param userRoles The roles associated with the app alerts a user should be receiving
   */
  getClientAppAlertsByStatus(clientDocId: string, status: AlertStatus, application: string, userRoles: string[]): Observable<AppAlert[]> {
    return this.db.collection<AppAlert>(AppAlert.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('status', '==', status)
      .where('application', '==', application)
      .where('recipientRole', 'in', userRoles)
      .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
        shareReplay({bufferSize: 1, refCount: true})
      );
  }

  /**
   * Return all Alert documents with a particular date range for a particular client and application
   *
   * @param clientDocId The docId of the Client containing the Alerts
   * @param startDate The date before or when the Alerts were last updated
   * @param endDate The date after or when the Alerts were last updated
   * @param application One of 'HMS', 'ATOM', or 'ATI_MOBILE' to filter AppAlerts returned
   * @param userRoles The roles associated with the app alerts a user should be receiving
   */
  getClientAppAlertsByDate(clientDocId: string, startDate: string, endDate: string,
                           application: string, userRoles: string[]): Observable<AppAlert[]> {
    return this.db.collection<AppAlert>(AppAlert.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
      .where('application', '==', application)
      .where('recipientRole', 'in', userRoles)
      .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
        shareReplay({bufferSize: 1, refCount: true})
      );
  }

  // two search criteria

  /**
   * Return all Alert documents with a particular title and status for a particular client and application
   *
   * @param clientDocId The docId of the Client containing the Alerts
   * @param title The title of the Alerts
   * @param status The status of the Alerts
   * @param application One of 'HMS', 'ATOM', or 'ATI_MOBILE' to filter AppAlerts returned
   * @param userRoles The roles associated with the app alerts a user should be receiving
   */
  getClientAppAlertsByTitleAndStatus(clientDocId: string, title: string, status: AlertStatus,
                                     application: string, userRoles: string[]): Observable<AppAlert[]> {
    return this.db.collection<AppAlert>(AppAlert.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('title', '==', title).where('status', '==', status)
      .where('application', '==', application)
      .where('recipientRole', 'in', userRoles)
      .orderBy('lastUpdatedTimestamp', 'desc')).valueChanges().pipe(
        shareReplay({bufferSize: 1, refCount: true})
      );
  }

  /**
   * Return all Alert documents with a particular title and date range for a particular client and application
   *
   * @param clientDocId The docId of the Client containing the Alerts
   * @param title The title of the Alerts
   * @param startDate The date before or when the Alerts were last updated
   * @param endDate The date after or when the Alerts were last updated
   * @param application One of 'HMS', 'ATOM', or 'ATI_MOBILE' to filter AppAlerts returned
   * @param userRoles The roles associated with the app alerts a user should be receiving
   */
  getClientAppAlertsByTitleAndDate(clientDocId: string, title: string, startDate: string, endDate: string,
                                   application: string, userRoles: string[]): Observable<AppAlert[]> {
    return this.db.collection<AppAlert>(AppAlert.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('title', '==', title).where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
      .where('application', '==', application)
      .where('recipientRole', 'in', userRoles)
      .orderBy('lastUpdatedTimestamp', 'desc'))
        .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Retrieves all AppAlerts associated with the provided clientDocId, application, and date
   * @param clientDocId The docId of the Client
   * @param status AlertStatus to filter on
   * @param startDate An ISO formatted date and time string (e.g. 2020-10-15'T'00:00:00.000Z)
   * @param endDate An ISO formatted date and time string (e.g. 2020-10-15'T'23:59:59.999Z)
   * @param application One of 'HMS', 'ATOM', or 'ATI_MOBILE' to filter AppAlerts returned
   * @param userRoles The roles associated with the app alerts a user should be receiving
   */
  getClientAppAlertsByStatusAndDate(
    clientDocId: string, status: AlertStatus, startDate: string, endDate: string, application: string, userRoles: string[]):
    Observable<AppAlert[]> {

    return this.db.collection<AppAlert>(AppAlert.getDataPath(), ref => ref
      .where('clientDocId', '==', clientDocId)
      .where('application', '==', application)
      .where('recipientRole', 'in', userRoles)
      .where('status', '==', status)
      .where('lastUpdatedTimestamp', '>=', startDate)
      .where('lastUpdatedTimestamp', '<=', endDate)
      .orderBy('lastUpdatedTimestamp', 'desc'))
      .valueChanges()
      .pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  // three search criteria

  /**
   * Return all Alert documents with a particular title, status, and date range for a particular client and application
   *
   * @param clientDocId The docId of the Client containing the Alerts
   * @param title The title of the Alerts
   * @param status The status of the Alerts
   * @param startDate The date before or when the Alerts were last updated
   * @param endDate The date after or when the Alerts were last updated
   * @param application One of 'HMS', 'ATOM', or 'ATI_MOBILE' to filter AppAlerts returned
   * @param userRoles The roles associated with the app alerts a user should be receiving
   */
  getClientAppAlertsByTitleStatusAndDate(clientDocId: string, title: string, status: AlertStatus, startDate: string, endDate: string,
                                         application: string, userRoles: string[]): Observable<AppAlert[]> {
    return this.db.collection<AppAlert>(AppAlert.getDataPath(), ref => ref.where('clientDocId', '==', clientDocId)
      .where('title', '==', title).where('status', '==', status)
      .where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate)
      .where('application', '==', application)
      .where('recipientRole', 'in', userRoles)
      .orderBy('lastUpdatedTimestamp', 'desc'))
      .valueChanges().pipe(
        shareReplay({bufferSize: 1, refCount: true})
      );
  }

  /**
   * Updates an AppAlert object's status, lastUpdatedTimestamp, and lastUpdatedByDocId
   * @param alertDocId The AppAlert to update
   * @param status The new status to set on the AppAlert
   * @param hedgeDocId Optional docId of the newly created hedge to replace the original alertUri
   */
  updateAppAlertStatus(alertDocId: string, status: AlertStatus, hedgeDocId?: string): Promise<void> {
    const lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    const alertRef = this.db.doc<AppAlert>(AppAlert.getDataPath(alertDocId)).ref;
    return this.db.doc<User>(User.getDataPath(lastUpdatedByDocId)).get().toPromise()
      .then(userSnapshot => {
        const user = userSnapshot.data() as User;
        return this.db.firestore.runTransaction(tx => {
          return tx.get(alertRef).then(alertDoc => {
            const alertData = alertDoc.data() as AppAlert;
            if (alertData.status === status) {
              // No update because status is the same
              throw new Error(EXISTING_STATUS_ERROR_MSG);
            }
            const fieldUpdates = {
              status,
              lastUpdatedTimestamp: new Date().toISOString(),
              lastUpdatedByDocId,
              lastUpdatedByName: `${user.firstName} ${user.lastName}`
            };
            if (hedgeDocId) {
              fieldUpdates[ 'actionUri' ] = `/hedges/${hedgeDocId}`;
            }
            return tx.update(alertRef, fieldUpdates);
          });
        });
      }).then(_ => {
        console.log('AppAlert updated');
        return Promise.resolve();
      }).catch(err => {
        if (err.message === EXISTING_STATUS_ERROR_MSG) {
          console.log(`AppAlert was not updated: ${err.message}`);
          return Promise.resolve();
        }
        return Promise.reject(err);
      });
  }

}
