import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { AuthService } from '@advance-trading/angular-ati-security';

import { UserStatusService } from './user-status.service';

@Injectable()
export class AuthGuard implements CanActivate {

  private loader$ = new BehaviorSubject<boolean>(false);
  loading$ = this.loader$.asObservable();

  private errorSubject$ = new BehaviorSubject<string>('');
  authErrors$ = this.errorSubject$.asObservable();

  constructor(
    private authService: AuthService,
    private userStatusService: UserStatusService
  ) {
    this.loader$.pipe(
      debounceTime(300) // To ensure we don't get quick spinner flashes
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.loader$.next(true);
    return this.authService.isAuthenticated$.pipe(
      switchMap(loggedIn => {
        if (!loggedIn) {
          window.sessionStorage.setItem('originUri', state.url);
          this.authService.login(state.url);
          return of({});
        }
        return this.authService.userProfile$;
      }),
      switchMap(profile => {
        this.loader$.next(false);
        if (state.url.startsWith('/register')) {
          return of('');
        } else if (profile.app_metadata) {
          return this.userStatusService.checkActiveUser(profile);
        } else {
          return of('');
        }
      }),
      switchMap(errorMessage => {
        // This check will result in the user needing to refresh their browser to reset any auth related errors;
        // this was done to ensure that authGuard doesn't trample any other app component errors.
        if (errorMessage) {
          this.errorSubject$.next(errorMessage);
        }
        return this.authService.isAuthenticated$;
      })
    );
  }

}
