import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';

import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, shareReplay, startWith, tap } from 'rxjs/operators';

import moment from 'moment';
import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommonValidators } from '@advance-trading/angular-common-services';
import { OperationsDataService } from '@advance-trading/angular-ops-data';
import { AccountingSystem, Client, Commodity, CommodityMap, HMSClientSettings } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../service/client-selector.service';
import { ClientSettingsService } from '../service/client-settings.service';

import { AccountingSettingComponent } from './accounting-setting/accounting-setting.component';

import { UserRoles } from '../utilities/user-roles';

// Formatting REGEX
const MILITARY_TIME = 'HH:mm';
const LOCAL_12_HOUR_TIME = 'LT';
const TWENTYFOUR_HOUR_REGEX = /^\d{2}\:\d{2}$/;
// pattern must match what timepicker can recognize or form isn't set as invalid with invalid input
const TWELVE_HOUR_REGEX = /^(([0-1]?\d|2[0-3])\:[0-5]\d)(\s[aApP][mM])?$/;
const DISPLAY_NAME_WITH_EMAIL_REGEX = /^((\S+.{0,}\S+)|\S)\s<[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}>$/i;
const DATE_FORMAT = 'YYYY-MM-DD';
const NUMERIC_REGEX = /^(?=.*[0-9])\d*$/;

interface Auth0Group {
  _id?: string;
  name?: string;
  description?: string;
}

@Component({
  selector: 'hms-client-settings',
  templateUrl: './client-settings.component.html',
  styleUrls: ['./client-settings.component.scss']
})
export class ClientSettingsComponent implements OnInit {
  clientSettingsForm = this.formBuilder.group({
    commodities: [[]],
    isInternational: [false],
    groupPrefix: [''],
    accountingSystem: [''],
    ledgerEndOfDay: ['', [Validators.required, Validators.pattern(TWELVE_HOUR_REGEX)]],
    accountingSettings: this.formBuilder.group({}),
    usePriceAdjustments: [false],
    patronEmailFromAddress: ['', [Validators.maxLength(200), Validators.pattern(DISPLAY_NAME_WITH_EMAIL_REGEX)]],
    sendPatronNotifications: [false],
    timezone: ['', [Validators.required, CommonValidators.objectValidator]],
    managementGroup: ['', [CommonValidators.objectValidator]],
    useWholeCent: [false],

    // Billing Settings
    billingMonthlyFlatFee: ['0.00', [Validators.required, Validators.min(0)]],
    billingUsageRate: ['0.00000', [Validators.required, Validators.min(0)]],
    billingStartDate: ['', [Validators.required]],
    billingRenewalDate: ['',[Validators.required]],
    billingContactName: ['',[Validators.required]],
    billingContactEmail: ['', [Validators.required, Validators.email]],
    billingDay: ['', [Validators.required, Validators.pattern(NUMERIC_REGEX), Validators.min(0), Validators.max(28)]]
  });

  editMode = false;
  updateComplete = false;
  errorMessage: string;
  clientSettings$: Observable<HMSClientSettings>;
  filteredCommodities$: Observable<Commodity[]>;
  filteredTimezones$: Observable<moment.MomentZone[]>;
  clientSettings: HMSClientSettings;
  commodities: string[];
  allHmsEnabledCommodities: Commodity[] = [];
  accountingSystems = Object.keys(AccountingSystem);
  separatorKeyCodes: number[] = [ENTER, SPACE];

  filteredGroups$: Observable<Auth0Group[]>;
  private managementGroup: Auth0Group;
  private managementGroups: Auth0Group[];

  // navigation properties
  activeTabName = '';

  errorNotification = {
    'mainError' : false,
    'accountingError' : false,
    'billingError' : false,
  }

  hmsTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#E0E0E0',
      buttonColor: '#000000DE'
    },
    dial: {
      // $md-atigreen2 500 weight from our material theme
      dialBackgroundColor: '#789C63',
    },
    clockFace: {
      clockFaceBackgroundColor: '#FFFFFF',
      // $md-atigreen2 500 weight from our material theme
      clockHandColor: '#789C63',
      clockFaceTimeInactiveColor: '#000000DE'
    }
  };

  private commodityMap: CommodityMap;
  private selectedClientDocId: string;
  private allTimezones: moment.MomentZone[] = moment.tz.names().map(name => moment.tz.zone(name));

  @ViewChild('newAccountingSetting', { static: false }) private newAccountingSetting: AccountingSettingComponent;

  constructor(
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private formBuilder: FormBuilder,
    private operationsDataService: OperationsDataService,
    private snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.HMS_ADMIN_ROLE)) {
      this.errorMessage = 'You do not have permission to administer client settings.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.clientSettings$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((commodityMap: CommodityMap) => {
        this.commodityMap = commodityMap;
        this.allHmsEnabledCommodities = Object.values(this.commodityMap.commodities).filter((commodity: Commodity) => commodity.hmsEnabled);
        return this.clientSelectorService.getSelectedClient();
      }),
      // Get HMS Client Settings
      switchMap((selectedClient: Client) => {
        this.selectedClientDocId = selectedClient.docId;
        return this.clientSettingsService.getHmsSettingsByClientDocId(this.selectedClientDocId);
      }),
      switchMap((clientSettings: HMSClientSettings) => {
        this.clientSettings = clientSettings || new HMSClientSettings();
        this.commodities = clientSettings.commodities;
        return this.authzService.getGroups(true) as Observable<Auth0Group[]>;
      }),
      tap((managementGroups: Auth0Group[]) => {
        this.managementGroups = managementGroups.filter(managementGroup =>
          managementGroup.name.includes(this.clientSettings.groupPrefix)).map(managementGroup => {
            return managementGroup;
          });
        this.managementGroup = managementGroups.find(managementGroup => managementGroup._id === this.clientSettings.managementGroupId);
        this.clientSettingsForm.get('managementGroup').setValue(this.managementGroup);
        this.prepManagementGroupForSelection();
        this.setEditMode(false);
        this.updateComplete = true;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving client settings; please try again later';
        console.error(`Error retrieving client settings: ${err}`);
        this.updateComplete = true;
        return of(undefined);
      }));
    this.filteredCommodities$ = this.clientSettingsForm.get('commodities').valueChanges.pipe(
      startWith<string | Commodity>(''),
      filter(value => typeof value === 'string'),
      map((groupName: string) => {
        if (!this.commodityMap.commodities) {
          return [];
        } else if (groupName) {
          return this.allHmsEnabledCommodities.filter(commodity => {
            return !this.commodities.find((value) => commodity.id === value)
              && (commodity.name.toLowerCase().includes(groupName.toLowerCase())
                || commodity.id.toLowerCase().includes(groupName.toLowerCase()));
          });
        } else {
          return this.allHmsEnabledCommodities.filter(commodity => !this.commodities.find((value) => commodity.id === value));
        }
      })
    );
    this.filteredTimezones$ = this.clientSettingsForm.get('timezone').valueChanges.pipe(
      startWith<string | moment.MomentZone>(''),
      filter(value => typeof value === 'string'),
      map((zoneInput: string) => {
        if (zoneInput) {
          return this.allTimezones.filter(zone => zone.name.toLowerCase().includes(zoneInput.toLowerCase()));
        } else {
          return this.allTimezones;
        }
      })
    );
    this.onSendPatronNotificationsChanges();

  }

  clearField(fieldName: string) {
    const field = this.clientSettingsForm.get(fieldName);
    field.setValue('');
    field.markAsDirty();
  }

  onTabChange(event: MatTabChangeEvent) {
    this.activeTabName = event.tab.textLabel;
  }

  updateClientSettings() {
    this.updateComplete = false;

    // instantiate new clientSettings so .getPlainObject will work on set. Use Object.assign to ensure no unhandled properties get lost.
    const submittedClientSettings = Object.assign(new HMSClientSettings(), this.clientSettings);
    submittedClientSettings.clientDocId = this.selectedClientDocId;
    submittedClientSettings.endOfDayScheduledJob = this.clientSettings.endOfDayScheduledJob || '';
    submittedClientSettings.commodities = this.commodities;
    const formValues = this.clientSettingsForm.getRawValue();
    submittedClientSettings.isInternational = formValues.isInternational;
    submittedClientSettings.groupPrefix = formValues.groupPrefix;
    submittedClientSettings.ledgerEndOfDay = this.getMilitaryTimeFormat(formValues.ledgerEndOfDay);
    if (formValues.accountingSystem) {
      submittedClientSettings.accountingSystem = formValues.accountingSystem;
    }
    submittedClientSettings.usePriceAdjustments = formValues.usePriceAdjustments;
    submittedClientSettings.timezone = formValues.timezone.name;
    submittedClientSettings.managementGroupId = formValues.managementGroup && formValues.managementGroup._id ?
      formValues.managementGroup._id : '';
    submittedClientSettings.sendPatronNotifications = formValues.sendPatronNotifications;
    submittedClientSettings.patronEmailFromAddress = formValues.patronEmailFromAddress;
    const accountingSettings: FormGroup = this.clientSettingsForm.get('accountingSettings') as FormGroup;
    const accountingSystemSettings: { [key: string]: any } = {};
    Object.values(accountingSettings.controls).forEach((accountingSetting: FormGroup) => {
      const settingKey = accountingSetting.get('settingKey').value;
      const settingValue = accountingSetting.get('settingValue').value;
      accountingSystemSettings[settingKey] = settingValue;
    });
    submittedClientSettings.accountingSystemSettings = accountingSystemSettings;
    submittedClientSettings.useWholeCent = formValues.useWholeCent;

    submittedClientSettings.billingMonthlyFlatFee = Number(formValues.billingMonthlyFlatFee);
    submittedClientSettings.billingUsageRate = Number(formValues.billingUsageRate);
    submittedClientSettings.billingStartDate = moment(formValues.billingStartDate).format(DATE_FORMAT);
    submittedClientSettings.billingRenewalDate = moment(formValues.billingRenewalDate).format(DATE_FORMAT);
    submittedClientSettings.billingContactName = formValues.billingContactName;
    submittedClientSettings.billingContactEmail = formValues.billingContactEmail;

    submittedClientSettings.billingDay = Number(formValues.billingDay);
    if(submittedClientSettings.billingDay === 0) {
      submittedClientSettings.billingMonthlyFlatFee = 0;
      submittedClientSettings.billingUsageRate = 0;
      submittedClientSettings.billingStartDate = "";
      submittedClientSettings.billingRenewalDate = "";
      submittedClientSettings.billingContactName = "";
      submittedClientSettings.billingContactEmail = "";
    } else {
      submittedClientSettings.billingMonthlyFlatFee = Number(formValues.billingMonthlyFlatFee);
      submittedClientSettings.billingUsageRate = Number(formValues.billingUsageRate);
      submittedClientSettings.billingStartDate = moment(formValues.billingStartDate).format(DATE_FORMAT);
      submittedClientSettings.billingRenewalDate = moment(formValues.billingRenewalDate).format(DATE_FORMAT);
      submittedClientSettings.billingContactName = formValues.billingContactName;
      submittedClientSettings.billingContactEmail = formValues.billingContactEmail;
    }

    // preserve clientSettings since we're remaining on page
    this.clientSettings = submittedClientSettings.getPlainObject();

    // Update the HMSClientSettings object in Firestore
    this.clientSettingsService.setHmsSettingsByClientDocId(this.selectedClientDocId, submittedClientSettings)
      .then(() => {
        this.updateComplete = true;
        console.log('Client settings successfully updated');
        this.setEditMode(false);
        this.openSnackBar('Client settings successfully updated', 'DISMISS', true);
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`Client settings update failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Client settings update failed: ${errorMsg}`, 'DISMISS', false);
      });

  }

  private onSendPatronNotificationsChanges() {
    this.clientSettingsForm.get('sendPatronNotifications').valueChanges.subscribe((checked) => {
      const validatorArray = [Validators.maxLength(200), Validators.pattern(DISPLAY_NAME_WITH_EMAIL_REGEX)];
      const emailField = this.clientSettingsForm.get('patronEmailFromAddress');
      if (checked) {
        validatorArray.push(Validators.required);
      }
      emailField.setValidators(validatorArray);
      emailField.updateValueAndValidity();
    });
  }


  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private getMilitaryTimeFormat(timeString: string) {
    return moment(timeString, LOCAL_12_HOUR_TIME).format(MILITARY_TIME);
  }

  getLocalTime12HourFormat(timeString: string) {
    return TWENTYFOUR_HOUR_REGEX.test(timeString) ?
      moment(timeString, MILITARY_TIME).format(LOCAL_12_HOUR_TIME) : '';
  }

  private setupClientSettingsForm() {
    this.clientSettingsForm.patchValue(this.clientSettings);
    this.clientSettingsForm.get('ledgerEndOfDay').setValue(this.getLocalTime12HourFormat(this.clientSettings.ledgerEndOfDay));
    this.clientSettingsForm.get('timezone').setValue(moment.tz.zone(this.clientSettings.timezone));
    this.clientSettingsForm.get('billingUsageRate').setValue(this.getRoundedValueAsString(this.clientSettings.billingUsageRate, 5))
    this.clientSettingsForm.get('billingMonthlyFlatFee').setValue(this.getRoundedValueAsString(this.clientSettings.billingMonthlyFlatFee, 2))
    this.clientSettingsForm.get('billingStartDate').setValue(this.clientSettings.billingStartDate ? moment(this.clientSettings.billingStartDate).toDate() : '');
    this.clientSettingsForm.get('billingRenewalDate').setValue(this.clientSettings.billingRenewalDate ? moment(this.clientSettings.billingRenewalDate).toDate() : '');
    this.commodities = this.clientSettings.commodities;
    const acctSettings: FormGroup = this.clientSettingsForm.get('accountingSettings') as FormGroup;
    Object.keys(acctSettings.controls).forEach(settingKey => acctSettings.removeControl(settingKey));
    Object.keys(this.clientSettings.accountingSystemSettings).forEach(accountingSystemSettingKey => {
      const accountingSettingForm = this.formBuilder.group({
        settingKey: [accountingSystemSettingKey, [Validators.required]],
        settingValue: [this.clientSettings.accountingSystemSettings[accountingSystemSettingKey], [Validators.required]]
      });
      acctSettings.addControl(accountingSystemSettingKey, accountingSettingForm);
    });
    this.clientSettingsForm.markAsPristine();
  }

  // begin form-level and generic functions
  setEditMode(mode) {
    this.editMode = mode;
    if (this.editMode) {
      this.clientSettingsForm.enable();
    } else {
      this.clientSettingsForm.disable();
      this.setupClientSettingsForm();
      Object.keys(this.errorNotification).forEach(error => {
        this.errorNotification[error] = false;
      });
    }
    this.changeDetector.detectChanges();
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('pattern')) {
      return 'Invalid value';
    } else if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('maxlength')) {
      return `Value cannot exceed  ${control.errors['maxlength'].requiredLength} character${control.errors['maxlength'].requiredLength === 1 ? '' : 's'}`;
    }
    return 'Unknown error';
  }

  prepManagementGroupForSelection() {
    // Setup the autocomplete filter for Groups
    this.filteredGroups$ = this.clientSettingsForm.get('managementGroup').valueChanges.pipe(
      startWith<string | Auth0Group>(''),
      filter(value => typeof value === 'string'),
      map((groupName: string) => {
        if (groupName) {
          return this.managementGroups.filter(managementGroup => {
            return managementGroup.name.toLowerCase().includes(groupName.toLowerCase());
          });
        } else {
          return this.managementGroups;
        }
      })
    );
  }
  // end form-level and generic functions

  // begin accountingSystemSettings functions
  get existingAccountingSettingKeys() {
    const accountingSettings: FormGroup = this.clientSettingsForm.get('accountingSettings') as FormGroup;
    return Object.keys(accountingSettings.controls);
  }

  get uncommittedAccountingSettingsChangesExist() {
    const accountingSettingsFormGroup: FormGroup = this.clientSettingsForm.get('accountingSettings') as FormGroup;
    return Object.values(accountingSettingsFormGroup.controls).find((control: FormGroup) => control.dirty);
  }

  onFormErrorUpdate(errorType: string, error: boolean) {
    this.errorNotification[errorType] = error;
  }

  onAccountingSettingCreated(accountingSetting: FormGroup) {
    const settingKey: string = accountingSetting.get('settingKey').value;
    const settingValue: string = accountingSetting.get('settingValue').value;
    const clonedForm = this.formBuilder.group({
      settingKey: [settingKey, [Validators.required]],
      settingValue: [settingValue, [Validators.required]]
    });
    const accountingSettings: FormGroup = this.clientSettingsForm.get('accountingSettings') as FormGroup;
    accountingSettings.addControl(settingKey, clonedForm);
    this.newAccountingSetting.setupAccountingSettingForm();
    this.clientSettingsForm.markAsDirty();
  }

  onAccountingSettingRemoved(accountingSetting: FormGroup) {
    const settingKey: string = accountingSetting.get('settingKey').value;
    const accountingSettings: FormGroup = this.clientSettingsForm.get('accountingSettings') as FormGroup;
    accountingSettings.removeControl(settingKey);
    this.clientSettingsForm.markAsDirty();
  }

  onAccountingSettingUpdated(accountingSetting: FormGroup) {
    const settingKey: string = accountingSetting.get('settingKey').value;
    const accountingSettings: FormGroup = this.clientSettingsForm.get('accountingSettings') as FormGroup;
    accountingSettings.setControl(settingKey, accountingSetting);
    this.clientSettingsForm.markAsDirty();
  }

  trackBySettingKey(index: number, el: any): string {
    return (el.value as FormGroup).get('settingKey').value;
  }
  // end accountingSystemSettings functions

  // start formatting functions
  /**
   * Rounds a value to the appropriate number of decimal places for the type of data it represents, e.g. a calculatedCash value should
   * be passed through with the fieldName "cashPrice", even though it may not be stored in the cashPrice field. Needed to keep fixed
   * trailing zeroes for display
   *
   * @param fieldName The name of the field having its value set
   * @param value The value to be set on the field
   */
  public getRoundedValueAsString(value: number, precision: number): string {
    if(!Number.isFinite(Number(value)) || !value || Number(value) < 0) return '';
    return this.roundToPrecision(value, precision).toFixed(precision);
  }

  private roundToPrecision(value: number, precision: number): number {
    return Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
  }

  public formatToPrecision(fieldName: string, precision: number) {
    let value = this.getRoundedValueAsString(this.clientSettingsForm.get(fieldName).value, precision)
    this.clientSettingsForm.get(fieldName).setValue(value);
  }
  // end formatting functions
}
