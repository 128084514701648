import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, HostBinding, OnChanges, OnDestroy, Optional, Self, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

import { Subject } from 'rxjs';

import { DeliveryPeriodBasis, HMSClientSettings } from '@advance-trading/ops-data-lib';
import { BASIS_REGEX, MONTH_CODE_REGEX } from '../../../basis/basis-admin/basis-admin.validator';
import { PriceValidators } from '../../validators/price.validator';

@Component({
  selector: 'hms-basis-input',
  template: `
    <div [formGroup]="parts" class="basis-input-container">
      <input class="basis-input-value" trim="blur" formControlName="basis" (blur)="onBlur()">
      <span class="basis-input-spacer">|</span>
      <input class="basis-input-futures" trim="blur" formControlName="futuresYearMonth" maxlength="3" (blur)="onBlur()">
    </div>
  `,
  styles: [`
    .basis-input-container {
      display: flex;
    }
    .basis-input-value {
      border: none;
      background: none;
      padding: 0;
      outline: none;
      font: inherit;
      text-align: center;
      width: 58%;
    }
    .basis-input-futures {
      border: none;
      background: none;
      padding: 0;
      outline: none;
      font: inherit;
      text-align: center;
      width: 32%;
    }
    .basis-input-spacer {
      font-size: 1.3em;
      opacity: 0;
      width: 10%;
      text-align: center;
      transition: opacity 200ms;
    }
    :host.floating .basis-input-spacer {
      opacity: 1;
    }
  `],
  providers: [{
    provide: MatFormFieldControl,
    useExisting: BasisInputComponent,
    multi: true
  }],
})
export class BasisInputComponent implements
  ControlValueAccessor, MatFormFieldControl<DeliveryPeriodBasis>, OnChanges, OnDestroy {

  static nextId = 0;

  @HostBinding() id = `hms-basis-input-${BasisInputComponent.nextId++}`;

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @HostBinding('attr.aria-describedby') describedBy = '';

  parts: FormGroup;

  focused = false;
  errorState = false;
  controlType = 'hms-basis-input';
  stateChanges = new Subject<void>();

  @Input()
  deliveryPeriod = '';

  @Input()
  get clientSettings() {
    return this.inputClientSettings;
  }
  set clientSettings(settings: HMSClientSettings) {
    this.inputClientSettings = settings;
    this.stateChanges.next();
  }
  private inputClientSettings: HMSClientSettings = new HMSClientSettings();

  @Input()
  get placeholder() {
    return this.inputPlaceholder;
  }
  set placeholder(plh) {
    this.inputPlaceholder = plh;
    this.stateChanges.next();
  }
  private inputPlaceholder: string;

  @Input()
  get required() {
    return this.inputRequired;
  }
  set required(req) {
    this.inputRequired = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private inputRequired = false;

  @Input()
  get disabled(): boolean { return this.inputDisabled; }
  set disabled(value: boolean) {
    this.inputDisabled = coerceBooleanProperty(value);
    this.inputDisabled ? this.parts.disable() : this.parts.enable();
    this.stateChanges.next();
  }
  private inputDisabled = false;

  @Input()
  get value(): DeliveryPeriodBasis | null {
    const formValue = this.parts.value;
    if (formValue.basis && formValue.futuresYearMonth) {
      return { basis: formValue.basis, futuresYearMonth: formValue.futuresYearMonth, deliveryPeriod: this.deliveryPeriod };
    } else {
      return null;
    }
  }

  set value(basisValue: DeliveryPeriodBasis | null) {
    if (basisValue) {
      this.parts.markAllAsTouched();
      this.parts.setValue({
        basis: Number.isFinite(basisValue.basis) ? basisValue.basis.toFixed(this.inputClientSettings.useWholeCent ? 2 : 4) : '',
        futuresYearMonth: basisValue.futuresYearMonth
      });
      // Check for any invalid data coming into the form from parent component
      this.errorState = this.parts.invalid;
      // Note: update control error state for mass update
      if (this.ngControl && this.ngControl.control) {
        if (this.errorState) {
          const basisErrors = this.parts.controls.basis.errors;
          const futuresErrors = this.parts.controls.futuresYearMonth.errors;
          this.ngControl.control.setValidators((control) => control.errors);
          this.ngControl.control.setErrors(basisErrors || futuresErrors || this.parts.errors);
        } else {
          this.ngControl.control.setErrors(null);
        }
      }
    } else {
      this.parts.setValue({
        basis: '',
        futuresYearMonth: ''
      });
    }
    this.stateChanges.next();
  }

  get empty() {
    const formValue = this.parts.value;
    return !formValue.basis && !formValue.futuresYearMonth;
  }

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fb: FormBuilder,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>) {
    this.parts = this.fb.group({
      basis: new FormControl('', [Validators.pattern(BASIS_REGEX)]),
      futuresYearMonth: new FormControl('', [
        Validators.pattern(MONTH_CODE_REGEX)
      ])
    }, { validators: this.fieldDependencyValidator });
    if (ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      ngControl.valueAccessor = this;
    }

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
    this.onTouched();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['clientSettings']) {
      const basisPriceValidator = this.inputClientSettings.useWholeCent ? PriceValidators.wholeCentValidator
        : PriceValidators.basicPriceValidator;
      this.parts.get('basis').setValidators(basisPriceValidator);
      this.parts.get('basis').updateValueAndValidity();

      const decimalPrecision = this.inputClientSettings.useWholeCent ? 2 : 4;
      const basis = this.parts.get('basis').value ? Number(this.parts.get('basis').value).toFixed(decimalPrecision)
        : this.parts.get('basis').value;
      this.parts.setValue({
        basis,
        futuresYearMonth: this.parts.get('futuresYearMonth').value
      });
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  onBlur() {
    // convert futures field to upper case if a future month field is entered
    this.setFuturesToUpperCase();

    // Only take action if the values have been modified
    if (this.parts.dirty) {
      // Clear any existing errors set during mass updates or basis import
      this.ngControl.control.setErrors(null);
      this.errorState = (this.parts.controls.basis.invalid && this.parts.controls.basis.touched) ||
        (this.parts.controls.futuresYearMonth.invalid && this.parts.controls.futuresYearMonth.touched) ||
        this.requiredFieldsMissing();
      if (this.errorState) {
        // Set the errors on the ngControl so it can propagate up to parent component FormGroup
        const basisErrors = this.parts.controls.basis.errors;
        const futuresErrors = this.parts.controls.futuresYearMonth.errors;
        this.ngControl.control.setErrors(basisErrors || futuresErrors || { required: true });
      } else if (this.bothFieldsBlank()) {
        // Send back a null if all fields cleared
        this.onChange(null);
      } else if (this.parts.controls.basis.valid && this.parts.controls.futuresYearMonth.valid &&
        this.parts.controls.basis.value !== '' && this.parts.controls.futuresYearMonth.value !== '') {
        // Send back the new values to parent component FormGroup
        const newValue = Object.assign({}, this.parts.value);
        newValue.deliveryPeriod = this.deliveryPeriod;
        newValue.basis = parseFloat(newValue.basis);
        this.onChange(newValue);
      }
    }
  }

  onChange = (_: any) => { };

  onTouched = () => { };

  writeValue(newValue: DeliveryPeriodBasis | null): void {
    this.value = newValue;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private bothFieldsBlank() {
    return this.parts.value.basis === '' && this.parts.value.futuresYearMonth === '';
  }

  private requiredFieldsMissing() {
    if (this.bothFieldsBlank()) {
      return false;
    } else if (
      (this.parts.value.basis !== '' && (this.parts.value.futuresYearMonth === '' && this.parts.controls.futuresYearMonth.touched)) ||
      ((this.parts.value.basis === '' && this.parts.controls.basis.touched) && this.parts.value.futuresYearMonth !== '')) {
      return true;
    } else {
      return false;
    }
  }

  private fieldDependencyValidator(control: FormGroup) {
    const basis = control.get('basis');
    const futuresYearMonth = control.get('futuresYearMonth');

    if ((basis.value !== '' && (futuresYearMonth.value === '' && futuresYearMonth.touched)) ||
      ((basis.value === '' && basis.touched) && futuresYearMonth.value !== '')) {
      return {
        fieldDependencyError: true
      };
    } else {
      return null;
    }
  }

  private setFuturesToUpperCase() {
    if (this.parts.get('futuresYearMonth').value) {
      this.parts.get('futuresYearMonth').setValue(this.parts.get('futuresYearMonth').value.toUpperCase());
    }
  }
}
