import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'timeInForce'})
export class TimeInForcePipe implements PipeTransform {
  constructor(private titleCasePipe: TitleCasePipe) {}

  /**
   * This converts the contract time in force to be more readable
   * @param value the timeInForce to be transformed
   */
  transform(value: string): string {
    return value !== 'DAY' ? value : this.titleCasePipe.transform(value);
  }
}
