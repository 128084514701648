import { Component, OnInit } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, shareReplay, switchMap } from 'rxjs/operators';

import { AuthService } from '@advance-trading/angular-ati-security';
import { OperationsDataService } from '@advance-trading/angular-ops-data';
import { CommodityMap, HMSClientSettings, MarketData, MarketDataFrequency } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { QuotesMode } from '../quotes-mode';

@Component({
  selector: 'hms-selectable-quotes',
  templateUrl: './selectable-quotes.component.html',
  styleUrls: ['./selectable-quotes.component.scss'],
})
export class SelectableQuotesComponent implements OnInit {
  // Observable
  hmsClientSettings$: Observable<HMSClientSettings>;
  marketData$: Observable<MarketData[]>;
  displayedColumn: string[];

  errorMessage: string;
  isLoading = true;

  userDataFrequency: MarketDataFrequency;

  timestamp: string;
  commodityMap: CommodityMap;

  // Defined constant to specify single quote view mode
  readonly QUOTES_MODE = QuotesMode.FULL;

  constructor(
    private authService: AuthService,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private operationsDataService: OperationsDataService
  ) { }

  ngOnInit() {

    this.userDataFrequency = this.authService.userProfile.app_metadata.marketDataFrequency;

    this.hmsClientSettings$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((commodityMap: CommodityMap) => {
        this.commodityMap = commodityMap;
        return this.clientSelectorService.getSelectedClient();
      }),
      switchMap(selectedClient => {
        return this.clientSettingsService.getHmsSettingsByClientDocId(selectedClient.docId);
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving market data settings; please try again later';
        console.error(`Error retrieving HMS client settings: ${err}`);
        return of(undefined);
      })
    );

  }

  onSingleQuotesError(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  onSingleQuotesLoaded(timestamp: string) {
    this.isLoading = false;
    this.timestamp = timestamp;
  }
}
