import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import * as moment from 'moment';
import { concat, Observable, ReplaySubject, throwError, timer } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';

import { AuthService } from '@advance-trading/angular-ati-security';
import { Client, HMSClientSettings } from '@advance-trading/ops-data-lib';

import { LedgerDay } from '../utilities/ledger-day';

const FULLDATE_FORMAT = 'YYYY-MM-DD';

@Injectable({
  providedIn: 'root'
})
export class ClientSettingsService {

  constructor(
    private db: AngularFirestore,
    private authService: AuthService
  ) {}

  /**
   * Return the HMSClientSettings document for the specified Client
   *
   * @param clientDocId The docId of the Client for which to retrieve HMS settings
   */
  getHmsSettingsByClientDocId(clientDocId: string): Observable<HMSClientSettings> {
    return this.db.doc<HMSClientSettings>(`${Client.getDataPath(clientDocId)}/${HMSClientSettings.getDataPath()}`)
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return the realtime ledgerEndOfDay moment from the HMSClientSettings document for the specified Client
   *
   * @param clientDocId The docId of the Client for which to retrieve HMS' ledgerEndOfDay settings
   */
  getHmsLedgerEndOfDaySettingsByClientDocId(clientDocId: string): Observable<LedgerDay> {
    const ledgerDayPassed = new ReplaySubject<moment.Moment>(1);
    const ledgerDayPassed$ = ledgerDayPassed.asObservable();
    let retrievedLEDMoment;
    let isRetrievedLEDMomentEmitted;
    let nextLedgerDayMoment;
    let timezone;
    return this.db.doc<HMSClientSettings>(`${Client.getDataPath(clientDocId)}/${HMSClientSettings.getDataPath()}`)
      .valueChanges()
      .pipe(
        switchMap((settings: HMSClientSettings) => {
          if (!settings.ledgerEndOfDay) {
            const missingLedgerEndOfDay = new Error('Ledger End of Day missing from Client Settings; contact admin to configure');
            missingLedgerEndOfDay.name = 'MissingLedgerEndOfDayError';
            return throwError(missingLedgerEndOfDay);
          }
          timezone = settings.timezone;

          const ledgerEndOfDayTime = settings.ledgerEndOfDay.split(':');
          const ledgerEndOfDayHour = parseInt(ledgerEndOfDayTime[0], 10);
          const ledgerEndOfDayMinute = parseInt(ledgerEndOfDayTime[1], 10);
          const todaysLedgerEndOfDay = moment.tz(timezone)
            .set('hours', ledgerEndOfDayHour)
            .set('minutes', ledgerEndOfDayMinute)
            .set('seconds', 0)
            .set('milliseconds', 0);

          ledgerDayPassed.next(todaysLedgerEndOfDay);
          return ledgerDayPassed$;
        }),
        switchMap((ledgerEndOfDayMoment: moment.Moment) => {
          retrievedLEDMoment = ledgerEndOfDayMoment;
          const comparableLEDMoment = moment().isBefore(ledgerEndOfDayMoment) ? moment(ledgerEndOfDayMoment)
                                      : moment(ledgerEndOfDayMoment).add(1, 'days');
          nextLedgerDayMoment = moment(ledgerEndOfDayMoment).add(1, 'days');
          isRetrievedLEDMomentEmitted = false;
          return concat(timer(), timer(comparableLEDMoment.diff(moment())));
        }),
        map(() => {
          if (isRetrievedLEDMomentEmitted) {
            ledgerDayPassed.next(nextLedgerDayMoment);
          } else {
            isRetrievedLEDMomentEmitted = true;
          }
          return {
            ledgerEndOfDay: moment(retrievedLEDMoment),
            timezone
          } as LedgerDay;
        }),
        distinctUntilChanged((x, y) => x.ledgerEndOfDay.isSame(y.ledgerEndOfDay)),
        shareReplay({bufferSize: 1, refCount: true})
      );
  }

  /**
   * Set HMSClientSettings document for the specified Client
   *
   * @param clientDocId The docId of the Client for which to update HMS settings
   * @param hmsClientSettings The HMSClientSettings object
   */
  setHmsSettingsByClientDocId(clientDocId: string, hmsClientSettings: HMSClientSettings): Promise<void> {
    hmsClientSettings.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.doc<HMSClientSettings>(`${Client.getDataPath(clientDocId)}/${HMSClientSettings.getDataPath()}`)
      .set(hmsClientSettings.getPlainObject());
  }

}
