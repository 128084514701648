<div fxLayout="column" fxLayoutGap.gt-xs="15px" class="order-leg-detail">

  <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between start">
    <b>{{ leg.side | titlecase }}: {{ leg.security | orderSecurity : commodityMap }}</b>
  </div>

  <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between start">
    <ati-labeled-data label="Contract" [data]="leg.contractYearMonth | codeToMonth" fxFlex.gt-xs="33" fxFlex.xs="100"></ati-labeled-data>
    <ati-labeled-data label="Symbol" [data]="leg.symbol" fxFlex.gt-xs="33" fxFlex.xs="100"></ati-labeled-data>
    <ati-labeled-data label="Ratio Quantity" [data]="leg.ratioQuantity" fxFlex.gt-xs="34" fxFlex.xs="100"></ati-labeled-data>
  </div>

  <div fxLayout="row wrap" fxFlex="100" fxLayoutAlign="space-between start">
    <ati-labeled-data label="Security Type" [data]="(leg.securityType === 'UDS' || leg.securityType === 'OTC') ? leg.securityType : leg.securityType | replace | titlecase" fxFlex.gt-xs="33" fxFlex.xs="100"></ati-labeled-data>
    <ati-labeled-data *ngIf="leg.securitySubType" label="Security Subtype" [data]="leg.securitySubType | titlecase" fxFlex.gt-xs="33" fxFlex.xs="100"></ati-labeled-data>
    <ati-labeled-data *ngIf="leg.securityType === 'OPTION'" label="Strike Price" [data]="getDisplayPrice(leg.strikePrice, leg.symbol) | number: '1.4-4'" fxFlex.gt-xs="34" fxFlex.xs="100"></ati-labeled-data>
  </div>

</div>
