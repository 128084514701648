<form [formGroup]="accountingSettingForm">
  <div class="accounting-setting-container" fxLayout.xs="column" fxLayoutAlign="space-between start" fxLayout.gt-xs="row wrap">
    <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="55" fxFlex.xs="100" [label]="accountingSettingForm.get('settingKey').value" [data]="isBoolean ? (accountingSettingForm.get('settingValue').value | yesNo) : accountingSettingForm.get('settingValue').value"></ati-labeled-data>
    <mat-form-field *ngIf="editMode && !accountingSetting" fxFlex.gt-xs="15" fxFlex.xs="100" >
      <input required matInput placeholder="Setting" formControlName="settingKey" trim="blur">
      <mat-error *ngIf="accountingSettingForm.get('settingKey').invalid">{{ getErrorMessage(accountingSettingForm.get('settingKey')) }}</mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="editMode  && !isBoolean" [fxFlex.gt-xs]="accountingSetting ? 75 : 55" fxFlex.xs="100">
      <input required matInput [placeholder]="accountingSetting ? accountingSettingForm.get('settingKey').value : 'Value'" formControlName="settingValue" trim="blur">
      <mat-error *ngIf="accountingSettingForm.get('settingValue').invalid">{{ getErrorMessage(accountingSettingForm.get('settingValue')) }}</mat-error>
    </mat-form-field>
    <div *ngIf="editMode && isBoolean" class="checkbox-group" [fxFlex.gt-xs]="75" fxFlex.xs="100">
      <span class="check-radio-label mat-small mat-hint">{{ accountingSettingForm.get('settingKey').value }}</span>
      <mat-checkbox formControlName="settingValue">Enabled</mat-checkbox>
    </div>
    <div fxFlex.gt-xs="20" fxFlex.xs="100">
      <button id="update-accounting-setting" type="button" aria-label="Update Setting" color="accent" *ngIf="editMode" [disabled]="accountingSettingForm.invalid || !editMode || accountingSettingForm.pristine" mat-icon-button (click)="onAccountingSettingUpdated()" matTooltip="Update Setting">
        <mat-icon>check_circle</mat-icon>
      </button>
      <button id="remove-accounting-setting" type="button" aria-label="Remove Setting" color="warn" *ngIf="editMode && accountingSetting" mat-icon-button (click)="onAccountingSettingRemoved()" matTooltip="Remove Setting">
        <mat-icon>delete_outline</mat-icon>
      </button>
    </div>
  </div>
</form>
