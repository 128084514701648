import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import * as moment from 'moment';
import { combineLatest, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';

import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommonValidators, ConfirmationDialogService } from '@advance-trading/angular-common-services';
import { BasisService, CommodityProfileService, LocationService, MarketDataService, OperationsDataService, OrderService, UserService } from '@advance-trading/angular-ops-data';
import {
  AlertStatus,
  Basis,
  Client,
  CommodityMap,
  CommodityProfile,
  CommodityProfileProductionYear,
  Contract,
  ContractMonth,
  ContractPriceAdjustment,
  ContractStatus,
  ContractType,
  DeliveryType,
  HedgeType,
  HMSClientSettings,
  HMSPriceAdjustment,
  HMSPriceAdjustmentMap,
  HMSUserSettings,
  Location,
  MarketData,
  Order,
  OrderStatus,
  OrderType,
  PricingSegment,
  PricingSegmentPartType,
  PricingSegmentStatus,
  Side,
  TimeInForce,
  User
} from '@advance-trading/ops-data-lib';

import { AppAlertService } from '../../service/app-alert.service';
import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { ContractService } from '../../service/contract.service';
import { LedgerHelperService } from '../../service/ledger-helper.service';
import { PriceAdjustmentService } from '../../service/price-adjustment.service';
import { PricingSegmentEvent } from '../pricing-segment/pricing-segment-event';
import { PricingSegmentService } from '../../service/pricing-segment.service';
import { QSTService } from '../../service/qst.service';
import { UserSettingsService } from '../../service/user-settings.service';

import { UserRoles } from '../../utilities/user-roles';

import { Originator } from '../../utilities/originator';
import { FIVE_DIGIT_REGEX, FUTURES_REGEX, PRICE_REGEX } from '../../utilities/validators/price.validator';
import { ContractPriceHelper } from '../contract-price-helper';
import { TitleCasePipe } from '@angular/common';
import { CommodityFuturesPriceService } from 'src/app/service/commodity-futures-price.service';

// regex for form values
const QUANTITY_REGEX = /^(?=.*[1-9])\d*(?:\.\d{0,2})?$/;
const ALPHANUMERIC_REGEX = /^\w+$/;

const DATE_FORMAT = 'YYYY-MM-DD';
const YEAR_FORMAT = 'YY';

const EDIT_STATUSES = [
  ContractStatus.COMPLETE,
  ContractStatus.DENIED,
  ContractStatus.EXPIRED, // TODO Do we need to allow updates on expired contracts?
  ContractStatus.PENDING_APPROVAL,
  ContractStatus.PENDING_BASIS,
  ContractStatus.PENDING_FUTURES,
  ContractStatus.PENDING_PRICE,
  ContractStatus.WORKING_BASIS,
  ContractStatus.WORKING_CASH,
  ContractStatus.WORKING_FUTURES,
  ContractStatus.WORKING_PRICE
];

const ORDER_STATUSES = [
  ContractStatus.PENDING_ORDER,
  ContractStatus.PENDING_ORDER_UPDATE,
  ContractStatus.PENDING_ORDER_CANCEL,
  ContractStatus.PENDING_ORDER_RECREATE,
  ContractStatus.PENDING_EXCHANGE,
  ContractStatus.WORKING_EXCHANGE
];

const CLIENT_LOCATION_BLACKLIST_STATUSES = [
  ContractStatus.COMPLETE,
  ContractStatus.CANCELLED,
  ContractStatus.EXPIRED,
  ContractStatus.DENIED,
  ContractStatus.DELETED,
  ContractStatus.WORKING_EXCHANGE
];

const EXCHANGE_EDITABLE_CONTRACT_STATUSES = [
  ContractStatus.WORKING_EXCHANGE,
  ContractStatus.COMPLETE
];

const UNPRICED_SEGMENT_SORT_ORDER = [
  PricingSegmentStatus.PENDING_EXCHANGE,
  PricingSegmentStatus.PENDING_ORDER,
  PricingSegmentStatus.PENDING_ORDER_UPDATE,
  PricingSegmentStatus.PENDING_ORDER_CANCEL,
  PricingSegmentStatus.PENDING_ORDER_RECREATE,
  PricingSegmentStatus.PENDING_BASIS,
  PricingSegmentStatus.PENDING_FUTURES,
  PricingSegmentStatus.WORKING_EXCHANGE,
  PricingSegmentStatus.WORKING_BASIS,
  PricingSegmentStatus.WORKING_CASH,
  PricingSegmentStatus.WORKING_FUTURES
];

@Component({
  selector: 'hms-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.scss']
})
export class ContractDetailComponent implements OnDestroy, OnInit {

  @ViewChild('tabGroup', { static: false }) tabGroupRef: MatTabGroup;
  @ViewChild('futuresMonthPicker', { static: false }) futuresMonthRef: MatDatepicker<moment.Moment>;
  @ViewChild('deliveryPeriodPicker', { static: false }) deliveryPeriodRef: MatDatepicker<moment.Moment>;
  @ViewChild('newPriceAdjustment', { static: false }) newPriceAdjustment;

  errorMessage = '';


  // booleans tracking actions taking place in template
  isLoading = true;
  isLoadingBasis = false;
  isLoadingMarketData = false;
  editMode = false;
  qstWorking = false;
  qstCreateError = false;
  creatingContractExchange = false;
  creatingOrder = false;
  creatingPricingExchange = false;
  cancellingOrder = false;
  pricingActionComplete = true;
  recreatingOrder = false;
  updateComplete = true;
  updatingOrder = false;
  hasPartialFill = false;
  orderFormInvalid = false;

  // navigation properties
  activeTabIndex = 0;
  navToPricing = false;

  // minimum date for futures month
  minDate = moment().startOf('month');
  expiryMinDate = new Date(); // don't allow expiration date in the past
  expiryMaxDate = new Date();

  // quantities for segments
  availableQuantity = 0;
  pricedQuantity = 0;
  unpricedQuantity = 0;
  workingQuantity = 0;

  commodityMap: CommodityMap;
  contractDetails$: Observable<any>;
  contract: Contract;
  dpPricing: ContractType;
  filteredClientLocations$: Observable<Location[]>;
  filteredDeliveryLocations$: Observable<Location[]>;
  filteredOriginators$: Observable<Originator[]>;
  isInternational: boolean;
  priceAdjustmentsTotal = 0;
  profile: CommodityProfile;
  segments: PricingSegment[];
  selectedClientDocId: string;
  orders$: Observable<Order[]>;
  priceAdjustmentMap: HMSPriceAdjustmentMap;
  prodYears: CommodityProfileProductionYear[] = [];
  usePriceAdjustments = false;

  // forms
  contractForm: FormGroup = this.formBuilder.group({});

  orderForm: FormGroup = this.formBuilder.group({
    orderDocId: ['', [Validators.required]]
  });

  private contractMonths = Object.keys(ContractMonth);
  private deliveryLocation: Location;

  private clientLocation: Location;

  // queryParams for navigating to segment tab
  private alertDocIdQueryParam: string;
  private segmentDocIdQueryParam: string;

  // Store the clientOrderId returned from QST for use in matching with qstService.orderEntry$
  private newOrderClientId = '';

  // used for time-dependent translation of production year
  private ledgerEndOfDay: string;
  private timezone: string;

  // used to find name, authorized locations, contract limits, and accounting system id for updates
  private loggedInUser: User;
  private userSettings: HMSUserSettings;

  // order-related properties and status booleans
  private exchangeOrderIdSub: Subscription;
  private existingOrder: Order;
  private contractOrderActionComplete = true;
  private segmentOrderActionComplete = true;

  private useWholeCent = false;
  private contractPriceHelper: ContractPriceHelper;

  // note: this never be set to true again if the user manually locked the futures.
  //       futures price can only be manually locked once.
  private manualLockActionEnabled = true;

  /** A list of any subscriptions that this component are subscribed to. */
  private subscriptions: Subscription[] = [];

  /** Public reference to the `DeliveryType` enum values. */
  deliveryTypes = Object.values(DeliveryType);

  constructor(
    private activatedRoute: ActivatedRoute,
    private appAlertService: AppAlertService,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private basisService: BasisService,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private contractService: ContractService,
    private dialogService: ConfirmationDialogService,
    private formBuilder: FormBuilder,
    private ledgerHelperService: LedgerHelperService,
    private locationService: LocationService,
    private marketDataService: MarketDataService,
    private operationsDataService: OperationsDataService,
    private orderService: OrderService,
    private priceAdjustmentService: PriceAdjustmentService,
    private pricingSegmentService: PricingSegmentService,
    public qstService: QSTService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private titleCasePipe: TitleCasePipe,
    private commodityFuturesPriceService: CommodityFuturesPriceService
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.CONTRACT_VIEWER_ROLE) &&
      !this.authzService.currentUserHasRole(UserRoles.FULL_LEDGER_VIEWER_ROLE) &&
      !this.authzService.currentUserHasRole(UserRoles.LOCATION_LEDGER_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to view contracts.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.setEditMode(false);
    this.contractDetails$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((client: Client) => {
        this.selectedClientDocId = client.docId;
        return this.clientSettingsService.getHmsSettingsByClientDocId(this.selectedClientDocId);
      }),
      switchMap((clientSettings: HMSClientSettings) => {
        this.isInternational = clientSettings.isInternational;
        this.ledgerEndOfDay = clientSettings.ledgerEndOfDay;
        this.timezone = clientSettings.timezone;
        this.usePriceAdjustments = clientSettings.usePriceAdjustments;
        this.useWholeCent = clientSettings.useWholeCent;
        return this.priceAdjustmentService.getHMSPriceAdjustmentMapByClientDocId(this.selectedClientDocId);
      }),
      switchMap((priceAdjustmentMap: HMSPriceAdjustmentMap) => {
        this.priceAdjustmentMap = priceAdjustmentMap;
        return this.activatedRoute.queryParamMap;
      }),
      switchMap((queryParamMap: ParamMap) => {
        this.alertDocIdQueryParam = queryParamMap.get('alertId');
        this.segmentDocIdQueryParam = queryParamMap.get(`segmentId`);
        return this.activatedRoute.paramMap;
      }),
      switchMap((paramMap: ParamMap) => {
        return this.contractService.getContractByDocId(this.selectedClientDocId, paramMap.get('docId'));
      }),
      switchMap((contract: Contract) => {
        this.contract = contract;
        if (!this.contract) {
          return throwError('No contract found');
        }
        this.getOrdersObservable(contract.orderDocIds);
        return this.locationService.getLocationByDocId(this.selectedClientDocId, this.contract.deliveryLocationDocId);
      }),
      switchMap((deliveryLocation: Location) => {
        this.deliveryLocation = deliveryLocation;

        return this.locationService.getLocationByDocId(this.selectedClientDocId, this.contract.clientLocationDocId);
      }),
      switchMap((clientLocation: Location) => {
        this.clientLocation = clientLocation;
        return this.commodityProfileService.getCommodityProfileByDocId(this.selectedClientDocId, this.contract.commodityProfileDocId);
      }),
      switchMap((profile: CommodityProfile) => {
        this.profile = profile;
        this.prodYears = Object.values(
          this.ledgerHelperService.getTranslatedUniqueActiveProdYears(profile.productionYears, this.ledgerEndOfDay, this.timezone)
        );
        return this.pricingSegmentService.getPricingSegmentsByClientDocIdAndContractDocId(this.contract.clientDocId, this.contract.docId);
      }),
      switchMap((segments: PricingSegment[]) => {
        this.handlePricingSegments(segments);
        sessionStorage.removeItem('contractHistory');
        return this.operationsDataService.getCommodityMap();
      }),
      switchMap((commodityMap: CommodityMap) => {
        this.commodityMap = commodityMap;
        return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId);
      }),
      switchMap((loggedInUser: User) => {
        this.loggedInUser = loggedInUser;
        return this.userSettingsService.getHmsSettingsByUserDocId(this.authService.userProfile.app_metadata.firestoreDocId);
      }),
      switchMap((userSettings: HMSUserSettings) => {
        if (this.contract.contractOrderDocId) {
          return this.orderService.getOrderByDocId(this.contract.accountDocId, this.contract.contractOrderDocId).pipe(
            map((order: Order) => {
              this.existingOrder = order;
              return userSettings;
            }),
            catchError(err => {
              console.error(`Error retrieving Order associated with Contract: ${err}`);
              return of(userSettings);
            })
          );
        } else {
          return of(userSettings);
        }
      }),
      tap((hmsUserSettings: HMSUserSettings) => {
        this.isLoading = false;
        this.userSettings = hmsUserSettings;
        this.calculatePricedQuantity();
        this.handlingPendingOrderAction();
        this.setupContractForm();
        if (this.segmentDocIdQueryParam) {
          this.navToPricing = true;
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving contract details; please try again later';
        console.error(`Error retrieving contract details: ${err}`);
        this.isLoading = false;
        return of(undefined);
      })
    );
  }

  ngOnDestroy() {
    if (this.exchangeOrderIdSub) {
      this.exchangeOrderIdSub.unsubscribe();
    }

    this.subscriptions?.forEach?.(sub => sub?.unsubscribe?.());
  }

  /** Ensures the delivery type label has matching case as other values in the form. */
  get deliveryTypeLabelValue(): string {
    const value = this.contractForm.controls.deliveryType.value;
    return value === 'FOB' ? value : this.titleCasePipe.transform(value);
  }

  /** Whether this form is allowed to change the delivery type or not. */
  get canChangeDeliveryType(): boolean {
    // These statuses prevent updating delivery type.
    const hasOkStatus = ![ContractStatus.CANCELLED, ContractStatus.DENIED, ContractStatus.DELETED].includes(this.contract.status);

    // Delivery type is not available on DP contracts
    const hasOkType = Object.values(ContractType).includes(this.contract.type) && ![ContractType.DP].includes(this.contract.type);

    return hasOkStatus && hasOkType;
  }

  get canAdjustBasis() {
    return this.authzService.currentUserHasRole(UserRoles.BASIS_ADJUSTER_ROLE);
  }

  get canEditContract() {
    return !this.isInTransitionalState && this.contract
      && ((this.isContractUpdater && EDIT_STATUSES.includes(this.contract.status)) || this.canEditContractExchange);
  }

  get canCompleteContractApproval() {
    return this.authzService.currentUserHasRole(UserRoles.CLIENT_APPROVER_ROLE) && !this.isInTransitionalState &&
      this.contract && this.contract.status === ContractStatus.PENDING_APPROVAL;
  }

  get canPriceAtTheMarket() {
    // TODO remove for future implementation - toggled off for now
    return false;
    return this.isContractUpdater && !this.isInTransitionalState && this.contract && this.isWorkingTargetContract &&
      (this.contract.type === ContractType.CASH || this.contract.type === ContractType.HTA);
  }

  get canManuallyLockPrice() {
    return this.isManualPriceLocker && !this.isInTransitionalState && this.contract && this.isWorkingTargetContract
      && this.manualLockActionEnabled &&
      (this.contract.type === ContractType.CASH || this.contract.type === ContractType.HTA);
  }

  get canCancelContract() {
    return this.isContractUpdater && !this.isInTransitionalState && this.contract && (this.isWorkingTargetContract ||
      this.contract.status === ContractStatus.PENDING_APPROVAL || this.contract.status === ContractStatus.DENIED);
  }

  get canDeleteContract() {
    // note: handle race condition where the contract is fully priced, but still
    //       waiting for the function to set the contract status to COMPLETE
    if (!this.unpricedQuantity && this.contract && this.contract.status !== ContractStatus.COMPLETE) {
      return false;
    }

    return this.isContractDeleter && !this.isInTransitionalState && this.contract && this.isContractEligibleToDelete(this.contract);
  }

  get canCompleteOrderAction() {
    return this.isOrderAdmin && !this.isInTransitionalState && this.contract && (ORDER_STATUSES.includes(this.contract.status));
  }

  get orderDocIdDisplay() {
    return this.hasPartialFill ? `${this.contract.contractOrderDocId} (${this.existingOrder.quantity - this.existingOrder.unfilledQuantity}/${this.existingOrder.quantity})` : this.contract.contractOrderDocId;
  }

  get isOrderFormValid() {
    this.orderFormInvalid = this.orderForm.invalid;
    return this.orderFormInvalid;
  }

  setEditMode(mode: boolean): void {
    this.editMode = mode;
    this.contractForm.markAsPristine();
  }

  reset() {
    this.setEditMode(false);
    this.setupContractForm();
  }

  getTitle() {
    if (this.contract) {
      if (this.contract.accountingSystemId) {
        return `${this.contract.accountingSystemId} for ${this.contract.patronName}`;
      }
      return `${this.contract.patronName}`;
    }
    return '';
  }

  getContractPatronDisplayName() {
    return `${this.contract.patronName} (${this.contract.patronAccountingSystemId})`;
  }

  getCommodityDisplayName() {
    const name = this.commodityMap.commodities[this.contract.commodityId].name;
    return `${name} (${this.profile.commodityId})`;
  }

  getProductionYearLabel() {
    return this.ledgerHelperService.getProdYearLabelForContract(this.contract, this.profile, this.ledgerEndOfDay, this.timezone);
  }

  compareProductionYear(prodYear1: CommodityProfileProductionYear, prodYear2: CommodityProfileProductionYear) {
    return prodYear1 && prodYear2 && prodYear1.year === prodYear2.year;
  }

  getContractUnit() {
    return this.commodityMap.commodities[this.contract.commodityId].contractUnit;
  }

  getOriginatorDisplayName(originator: Originator) {
    const inactiveStatus = originator.status === 'ACTIVE' || !originator.status ? '' : `[${originator.status}]`;
    return originator ? `${originator.firstName} ${originator.lastName} (${originator.accountingSystemId}) ${inactiveStatus}` : '';
  }

  getContractOriginatorDisplayName() {
    return `${this.contract.originatorName} (${this.contract.originatorAccountingSystemId})`;
  }

  getExpirationDate() {
    return typeof this.contract.expirationDate === 'string' ? this.contract.expirationDate : '';
  }

  getExchangeSide() {
    return this.getOrderSideFromContractSide(this.contract.side);
  }

  /**
   * Displays name for location autocomplete input field
   */
  displayLocation(location?: Location) {
    return location ? location.name : '';
  }

  futuresMonthFilter = (currentMoment: moment.Moment | null): boolean => {
    if (currentMoment) {
      const commodityFuturesMonths = this.commodityMap.commodities[this.contract.commodityId].contractMonths;
      return commodityFuturesMonths.includes(currentMoment.month());
    } else {
      return false;
    }
  }

  selectFuturesMonth(event: moment.Moment) {
    this.contractForm.get('futuresYearMonth').setValue(event);
    this.contractForm.get('futuresYearMonth').markAsDirty();
    this.verifyMarketFuturesDataExists();
    this.futuresMonthRef.close();
  }

  selectDeliveryPeriod(event: moment.Moment) {
    this.contractForm.get('deliveryPeriod').setValue(event);
    this.contractForm.get('deliveryPeriod').markAsDirty();
    this.deliveryPeriodRef.close();
  }

  // don't allow weekend days to be selected for expiration date
  expirationDateFilter = (currentDate: Date): boolean => {
    const dayOfTheWeek = moment(currentDate).weekday();
    return dayOfTheWeek !== 0 && dayOfTheWeek !== 6;
  }

  allowTabOnly(event: KeyboardEvent) {
    if (event.key !== 'Tab') {
      event.preventDefault();
    }
  }

  formatUpdatedPrice(formControlName: string) {
    const price = Number(this.contractForm.get(formControlName).value);
    if (this.contractForm.get(formControlName).value && Number.isFinite(price)) {
      this.contractPriceHelper.setFieldValue(formControlName, price);
    } else {
      this.contractForm.get(formControlName).setValue('');
    }
  }

  clearField(fieldName: string, resetValue?: string, isPrice = false) {
    const field = this.contractForm.get(fieldName);
    let finalResetValue = resetValue;
    if (isPrice) {
      finalResetValue = this.contractPriceHelper.getRoundedValueAsString(fieldName, Number(resetValue));
    }
    field.setValue(finalResetValue || '');
    field.markAsDirty();
  }

  getErrorMessage(control: FormControl) {
    const valueInvalid = 'Value invalid';
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('pattern')) {
      return valueInvalid;
    } else if (control.hasError('matDatepickerMin')) {
      return valueInvalid;
    } else if (control.hasError('matDatepickerFilter')) {
      return valueInvalid;
    } else if (control.hasError('min')) {
      return 'Value must be at least ' + control.errors['min'].min;
    } else if (control.hasError('max')) {
      return `Value cannot exceed ${control.errors['max'].max}`;
    } else if (control.hasError('maxlength')) {
      return `Value cannot exceed ${control.errors['maxlength'].requiredLength} characters`;
    } else if (control.hasError('invalidBasicPrice')) {
      return 'Value should be a number (to the quarter cent)';
    } else if (control.hasError('invalidFiveDigitPrice')) {
      return 'Value should be a number (up to five decimal places)';
    } else if (control.hasError('invalidFuturesPrice')) {
      return 'Value should be a number (to the quarter cent)';
    } else if (control.hasError('invalidWholeCentPrice')) {
      return 'Value should be a number (to the whole cent)';
    } else if (control.hasError('invalidNonZeroPrice')) {
      return 'Value cannot be zero';
    } else if (control.hasError('matDatepickerMax')) {
      const maxDate = new Date(control.errors[ 'matDatepickerMax' ].max).toLocaleDateString();
      return `Value must be on or before ${maxDate}`;
    }
    return 'Unknown error';
  }

  onTabChange(event: MatTabChangeEvent) {
    this.activeTabIndex = event.index;
  }

  onAnimationDone() {
    if (this.navToPricing) {
      this.tabGroupRef.selectedIndex = 1;
      this.navToPricing = false;
    }
  }

  approveContract() {
    const isOrderQuantity = this.contract.quantity >= this.contract.targetOrderThreshold;

    // Cash Target w/ less than one contract of quantity
    if (this.contract.type === ContractType.CASH && !isOrderQuantity) {
      this.contract.status = ContractStatus.WORKING_CASH;
    }

    // HTA Target w/ less than one contract of quantity
    if (this.contract.type === ContractType.HTA && !isOrderQuantity) {
      this.contract.status = ContractStatus.WORKING_FUTURES;
    }

    // Basis Target of any quantity
    if (this.contract.type === ContractType.BASIS) {
      this.contract.status = ContractStatus.WORKING_BASIS;
    }

    // Cash or HTA Target w/ enough quantity for at least one contract
    if ((this.contract.type === ContractType.CASH || this.contract.type === ContractType.HTA) && isOrderQuantity) {
      this.contract.status = ContractStatus.PENDING_ORDER;
    }

    this.updateContract(true);
  }

  denyContract() {
    this.contract.status = ContractStatus.DENIED;
    this.updateContract(true);
  }

  cancelContract() {
    // confirm if the user wants to cancel contract
    this.dialogService.open({
      title: 'Cancel Contract',
      message: 'This action cannot be undone. Do you want to cancel this contract?',
      btnColor: 'accent'
    },
      'auto',
      '400px'
    );

    this.subscriptions.push(this.dialogService.afterClosed().subscribe(confirm => {
      if (!confirm) {
        return;
      }

      if (this.contract.contractOrderDocId) {
        this.contract.status = ContractStatus.PENDING_ORDER_CANCEL;
        this.updateContract(true);
      } else {
        this.setContractCancelled();
      }
    }));
  }

  deleteContract() {
    // confirm if the user wants to delete contract
    if (this.contract.isExchange) {
      this.dialogService.open({
        title: 'Delete Exchange Contract',
        message: `This action cannot be undone. Do you want to delete this contract?`,
        btnColor: 'accent'
      },
        'auto',
        '400px'
      );
    } else {
      this.dialogService.open({
        title: 'Delete Contract',
        message: `This action cannot be undone. Do you want to delete this contract?`,
        btnColor: 'accent'
      },
        'auto',
        '400px'
      );
    }


    this.subscriptions.push(this.dialogService.afterClosed().subscribe(confirm => {
      if (!confirm) {
        return;
      }

      this.setContractDeleted();
    }));
  }

  completeContractExchange() {
    // confirm the exchange has filled
    this.dialogService.open(
      {
        title: 'Confirm Exchange Completed',
        message: `Has exchange ${this.contract.exchangeId} filled in the trading platform?`,
        btnColor: 'accent'
      },
      'auto',
      '400px'
    );
    this.subscriptions.push(this.dialogService.afterClosed().subscribe(confirm => {
      if (!confirm) {
        return;
      }
      const exchangeCompleteTimestamp = new Date().toISOString();
      this.contract.futuresLockedTimestamp = exchangeCompleteTimestamp;
      if (this.contract.type === ContractType.CASH) {
        this.contract.status = ContractStatus.COMPLETE;
        this.contract.basisLockedTimestamp = exchangeCompleteTimestamp;
        this.contract.cashLockedTimestamp = exchangeCompleteTimestamp;
        this.contract.completionTimestamp = exchangeCompleteTimestamp;
      } else {
        this.contract.status = ContractStatus.PENDING_BASIS;
      }
      this.updateContract(true);
    }));
  }

  cancelContractExchange() {
    // confirm the exchange has been cancelled/rejected
    this.dialogService.open(
      {
        title: 'Confirm Exchange Cancelled',
        message: `Has exchange ${this.contract.exchangeId} been cancelled or rejected in the trading platform?`,
        btnColor: 'accent'
      },
      'auto',
      '400px'
    );
    this.subscriptions.push(this.dialogService.afterClosed().subscribe(confirm => {
      if (!confirm) {
        return;
      }
      this.contract.status = ContractStatus.CANCELLED;
      this.contract.cancellationTimestamp = new Date().toISOString();
      this.updateContract(true);
    }));
  }

  haltContract() {
    // confirm if the user wants to halt contract
    const completedQuantity = (this.existingOrder.quantity - this.existingOrder.unfilledQuantity) * this.commodityMap.commodities[ this.contract.commodityId ].contractSize;
    this.dialogService.open({
      title: 'Cancel Contract',
      message: `This contract has a partially filled order. Do you wish to reduce the contract size to ${completedQuantity} ${this.getContractUnit().toLowerCase()} and cancel the remainder of the contract?`,
      btnColor: 'accent'
    },
      'auto',
      '400px'
    );

    this.dialogService.afterClosed().subscribe(confirm => {
      if (!confirm) {
        return;
      }
      this.contract.quantity = completedQuantity;
      this.contract.versionNumber = this.contract.versionNumber + 1;
      this.contract.versionCreationTimestamp = new Date().toISOString();
      this.updateContract(true);
    });
  }

  setContractOrder() {
    if (this.orderForm.invalid) {
      return false;
    }
    this.contract.status = this.contract.type === ContractType.CASH ? ContractStatus.WORKING_CASH : ContractStatus.WORKING_FUTURES;
    const orderDocId = this.orderForm.get('orderDocId').value.trim();
    this.contract.contractOrderDocId = orderDocId;
    this.contract.orderDocIds.push(orderDocId);
    this.creatingOrder = false;
    this.recreatingOrder = false;
    this.orderForm.get('orderDocId').setValue('');
    this.orderForm.get('orderDocId').markAsUntouched();
    this.updateContract(true);
  }

  createOrder() {
    if (this.qstService.enabled) {
      const accountNumber = `${this.profile.officeCode}${this.profile.accountNumber}`;

      let expirationDate;
      if (this.contract.timeInForce === TimeInForce.GTD) {
        expirationDate = moment(this.contract.expirationDate).format('MM/DD/YYYY');
      }

      const commodityId = this.profile.commodityId;
      const contractMonth = this.contract.futuresYearMonth.substring(2);
      const contractYear = this.contract.futuresYearMonth.substring(0, 2);
      const contract = `${this.profile.commodityId}${contractMonth}${contractYear}`;

      this.qstWorking = true;
      const commodity = this.commodityMap.commodities[commodityId];
      const quantity = this.getOrderQuantity();
      this.qstService.createOrder(commodity, contract, quantity, accountNumber,
        this.getOrderSideFromContractSide(this.contract.side), OrderType.LIMIT, this.contract.futuresPrice,
        undefined, this.contract.timeInForce, expirationDate)
        .then(clientOrderId => {
          this.newOrderClientId = clientOrderId;
        })
        .catch(err => {
          console.log(`QST createOrder error response in contract detail: ${JSON.stringify(err)}`);
          this.qstWorking = false;
          this.qstCreateError = true;
          this.openSnackBar('Error from QST: ' + err, 'DISMISS', false);
        });
    }
  }

  cancelOrder() {
    if (this.qstService.enabled && this.existingOrder) {
      this.qstService.cancelOrder(this.existingOrder.accountNumber, this.existingOrder.clientOrderId)
        .then(response => {
          // Cancel needs to explicitly handle setting qstWorking to false because the order entry callback doesn't include xid
          this.qstWorking = false;
          this.handleCancelledOrder();
        })
        .catch(err => {
          console.log(`QST cancelOrder error response in contract detail: ${JSON.stringify(err)}`);
          this.qstWorking = false;
          this.openSnackBar('Error from QST: ' + err, 'DISMISS', false);
        });
    } else {
      // confirm the user has completed the exchange order cancellation
      this.dialogService.open(
        {
          title: 'AST Not Enabled - Cancel Order',
          message: `Proceed to trading platform to cancel order. Is order ${this.contract.contractOrderDocId} cancelled?`,
          btnColor: 'accent'
        },
        'auto',
        '400px'
      );

      this.subscriptions.push(this.dialogService.afterClosed().subscribe(confirm => {
        if (!confirm) {
          return;
        }
        this.handleCancelledOrder();
      }));
    }
  }

  updateOrder() {
    if (this.qstService.enabled && this.existingOrder) {

      let expirationDate;
      if (this.contract.timeInForce === TimeInForce.GTD) {
        expirationDate = moment(this.contract.expirationDate).format('MM/DD/YYYY');
      }

      const commodityId = this.profile.commodityId;
      const contractMonth = this.contract.futuresYearMonth.substring(2);
      const contractYear = this.contract.futuresYearMonth.substring(0, 2);
      const futuresContract = `${this.profile.commodityId}${contractMonth}${contractYear}`;

      this.qstWorking = true;
      const commodity = this.commodityMap.commodities[commodityId];
      const quantity = this.getOrderQuantity();
      this.qstService.cancelReplaceOrder(commodity, futuresContract, quantity, this.existingOrder.accountNumber,
        this.existingOrder.clientOrderId, this.getOrderSideFromContractSide(this.contract.side), OrderType.LIMIT,
        this.contract.futuresPrice, undefined, this.contract.timeInForce, expirationDate)
        .then(clientOrderId => {
          this.qstWorking = false;
          this.newOrderClientId = clientOrderId;
          if (!clientOrderId) {
            const cancelledError = new Error(`QST action cancelled`);
            cancelledError.name = 'QSTActionCancelled';
            throw cancelledError;
          }
          return this.orderService.updateClientOrderId(
            this.existingOrder.accountDocId,
            this.existingOrder.docId,
            clientOrderId,
            this.authService.accessToken);
        })
        .then(orderUpdateResponse => {
          return this.handleUpdatedOrder();
        })
        .catch(err => {
          console.log(`QST cancelReplaceOrder error response in contract detail: ${JSON.stringify(err)}`);
          this.qstWorking = false;
          let displayedError = '';
          if (err.name === 'QSTActionCancelled') {
            this.openSnackBar(err.message, 'DISMISS', true);
            return;
          } else if (err.name === 'ClientOrderIdUpdateError') {
            displayedError = `Error updating Order ID ${this.newOrderClientId}`;
          } else {
            displayedError = `Error from QST: ${err}`;
          }
          this.openSnackBar(displayedError, 'DISMISS', false);
        });
    } else {
      // confirm the user has completed the exchange order update
      this.dialogService.open(
        {
          title: 'AST Not Enabled - Update Order',
          message: `Proceed to trading platform to update order. Is order ${this.contract.contractOrderDocId} updated?`,
          btnColor: 'accent'
        },
        'auto',
        '400px'
      );

      this.subscriptions.push(this.dialogService.afterClosed().subscribe(confirm => {
        if (!confirm) {
          return;
        }
        this.handleUpdatedOrder();
      }));
    }
  }

  recreateOrder() {
    if (this.qstService.enabled && this.existingOrder) {
      // First cancel the existing order
      this.qstService.cancelOrder(this.existingOrder.accountNumber, this.existingOrder.clientOrderId)
        .then(response => {
          this.contract.contractOrderDocId = undefined;
          // TODO handle completion of createOrder step
          const accountNumber = `${this.profile.officeCode}${this.profile.accountNumber}`;

          let expirationDate;
          if (this.contract.timeInForce === TimeInForce.GTD) {
            expirationDate = moment(this.contract.expirationDate).format('MM/DD/YYYY');
          }

          const commodityId = this.profile.commodityId;
          const contractMonth = this.contract.futuresYearMonth.substring(2);
          const contractYear = this.contract.futuresYearMonth.substring(0, 2);
          const contract = `${this.profile.commodityId}${contractMonth}${contractYear}`;

          this.qstWorking = true;
          const commodity = this.commodityMap.commodities[commodityId];
          const quantity = this.getOrderQuantity();
          return this.qstService.createOrder(commodity, contract, quantity, accountNumber,
            this.getOrderSideFromContractSide(this.contract.side), OrderType.LIMIT, this.contract.futuresPrice,
            undefined, this.contract.timeInForce, expirationDate);
        })
        .then(clientOrderId => {
          this.newOrderClientId = clientOrderId;
        })
        .catch(err => {
          console.log(`QST cancel and recreate error response in contract detail: ${JSON.stringify(err)}`);
          this.qstWorking = false;
          // TODO do we need to do a Contract.contractOrderDocId update if cancel is successful but create fails?
          if (!this.contract.contractOrderDocId) {
            this.qstCreateError = true;
          }
          this.openSnackBar('Error from QST: ' + err, 'DISMISS', false);
        });

    } else {
      // confirm the user has completed the exchange order cancel and recreate
      this.dialogService.open(
        {
          title: 'AST Not Enabled - Cancel and Recreate Order',
          message: `Proceed to trading platform to cancel and recreate order. Be sure to enter and save the new order ID on the contract. Is order ${this.contract.contractOrderDocId} cancelled and recreated?`,
          btnColor: 'accent'
        },
        'auto',
        '400px'
      );

      this.subscriptions.push(this.dialogService.afterClosed().subscribe(confirm => {
        if (!confirm) {
          return;
        }
        this.contract.contractOrderDocId = undefined;
      }));
    }
  }

  handleOrderListError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  priceAtTheMarket() {
    // TODO placeholder for future implementation
  }

  lockPrice() {
    this.manualLockActionEnabled = false;
    this.isLoadingMarketData = true;
    const marketDataDocId = this.contract.commodityId + this.contract.futuresYearMonth.substr(2) +
      this.contract.futuresYearMonth.substring(0, 2);
    this.subscriptions.push(this.marketDataService.getRealTimeMarketDataByDocId(marketDataDocId, this.authService.accessToken).pipe(
      take(1),
      catchError(err => {
        const message = `No market data found for ${marketDataDocId}`;
        console.error(`${message}: ${err.message}`);
        this.openSnackBar(`Unable to retrieve current market data for ${marketDataDocId}`, 'DISMISS', false);
        this.isLoadingMarketData = false;
        return throwError(message);
      })
    ).subscribe((marketData: MarketData) => {
      this.isLoadingMarketData = false;
      const marketPrice = this.commodityFuturesPriceService.getMarketPrice(this.contract.side, marketData)
                        / this.commodityMap.commodities[this.contract.commodityId].marketDataDivisor;
      const priceDifference = this.contract.futuresPrice - marketPrice;
      // confirm if the user wants to manually lock the futures price
      this.dialogService.open({
        title: 'Manually Lock Price',
        message: `This action cannot be undone.
        \tThe ${marketPrice.toFixed(4)} market price is currently ${(Math.abs(priceDifference)).toFixed(4)} from the ${this.contract.futuresPrice.toFixed(4)} target price.
        \tDo you want to lock the target price on this contract?`,
        btnColor: 'accent'
      },
        'auto',
        '400px'
      );
      this.subscriptions.push(this.dialogService.afterClosed().subscribe(confirm => {
        if (!confirm) {
          this.manualLockActionEnabled = true;
          return;
        }
        this.handleManuallyLockedPrice(marketPrice, priceDifference);
      }));
    }));
  }

  priceBasis() {
    if (this.contract.type === ContractType.DP) {
      this.dpPricing = ContractType.BASIS;
    }
    this.pricingActionComplete = false;
  }

  priceFutures() {
    if (this.contract.type === ContractType.DP) {
      this.dpPricing = ContractType.HTA;
    }
    this.pricingActionComplete = false;
  }

  priceCash() {
    if (this.contract.type === ContractType.DP) {
      this.dpPricing = ContractType.CASH;
    }
    this.pricingActionComplete = false;
  }

  onPricingSegmentEvent(event: PricingSegmentEvent) {
    const action = event.action;
    const segment = event.pricingSegment;

    switch (action) {
      case 'pricingActionCancelled':
        this.pricingActionComplete = true;
        break;
      case 'basisPricedForHTA':
      case 'basisPricedForDPFirstPart':
      case 'futuresPricedForBasis':
      case 'futuresPricedForDPFirstPart':
      case 'cashPriced':
        this.pricingActionComplete = true;
        this.contract.versionNumber = this.contract.versionNumber + 1;
        this.contract.versionCreationTimestamp = new Date().toISOString();
        this.setContractStatusOnCreatePricingSegment(segment);
        this.createPricingSegment(segment)
          .then(() => this.updateContract(false));
        break;
      case 'pricingSegmentOrderCreated':
        this.contract.orderDocIds.push(segment.orderDocId);
        this.updatePricingSegment(segment)
          .then(() => this.updateContract(false));
        break;
      case 'pricingSegmentOrderUpdated':
        this.handleUpdatedSegmentOrder(segment);
        break;
      case 'pricingSegmentWithOrderCancelled':
      case 'pricingSegmentExchangeComplete':
      case 'pricingSegmentExchangeIdCreated':
      case 'pricingSegmentUpdated':
        this.updatePricingSegment(segment);
        break;
      case 'pricingSegmentOrderCancelled':
        this.handleCancelledSegmentOrder(segment);
        break;
      case 'pricingSegmentCancelled':
      case 'pricingSegmentExchangeCancelled':
        this.handleCancelledSegment(segment);
        break;
      case 'pricingSegmentDeleted':
        this.handleDeletedSegment(segment);
        break;
      case 'basisPricedForDPSecondPart':
      case 'futuresPricedForDPSecondPart':
        this.setDPContractStatusOnCreateOrUpdatePricingSegment(segment);
        this.contract.versionNumber = this.contract.versionNumber + 1;
        this.contract.versionCreationTimestamp = new Date().toISOString();
        this.updatePricingSegment(segment)
          .then(() => this.updateContract(false));
        break;
      case 'pricingSegmentManuallyPriced':
        this.contract.versionNumber = this.contract.versionNumber + 1;
        this.contract.versionCreationTimestamp = new Date().toISOString();
        this.updatePricingSegment(segment)
          .then(() => this.updateContract(false));
        break;
      case 'pricingSegmentHalted':
        // can only be triggered on BASIS contracts and will result in a return of unpriced quantity
        if (this.contract.status === ContractStatus.PENDING_FUTURES) {
          this.updatePricingSegment(segment);
        } else {
          this.contract.status = ContractStatus.PENDING_FUTURES;
          this.updatePricingSegment(segment)
            .then(() => this.updateContract(false));
        }
        break;
      default:
        console.error('Unexpected pricing segment update occurred!');
        this.pricingActionComplete = true;
        break;
    }
  }

  getOrderLink() {
    return this.router.createUrlTree(['/accounts', this.profile.accountDocId, 'orders', this.contract.contractOrderDocId]).toString();
  }

  submitContractUpdate() {
    const formValues = this.contractForm.getRawValue();

    // Check for form controls to see if field is editable and should be updated/saved
    if (this.contractForm.get('productionYear')) {
      this.contract.productionYear = formValues.productionYear.year;
    }
    if (this.contractForm.get('futuresYearMonth')) {
      this.contract.futuresYearMonth = this.translateMomentToContractMonth(moment(formValues.futuresYearMonth));
    }
    if (this.contractForm.get('deliveryPeriod')) {
      this.contract.deliveryPeriod = this.translateMomentToContractMonth(moment(formValues.deliveryPeriod));
    }
    if (this.contractForm.get('deliveryLocation')) {
      this.contract.deliveryLocationDocId = formValues.deliveryLocation.docId;
      this.contract.deliveryLocationAccountingSystemId = formValues.deliveryLocation.accountingSystemId;
      this.contract.deliveryLocationName = formValues.deliveryLocation.name;
    }

    if (this.contractForm.get('deliveryType')) {
      this.contract.deliveryType = formValues.deliveryType;
    }

    if (this.contractForm.get('quantity')) {
      // check if quantity has been reduced to the point that it has nothing that can be priced
      const formQuantity = Number(formValues.quantity);
      // reduce quantity to only priced plus working - nothing left to price. Set to COMPLETE or WORKING
      if (formQuantity < this.contract.quantity && formQuantity === (this.pricedQuantity + this.workingQuantity)) {
        // 0 working quantity = 100% priced
        if (this.workingQuantity === 0) {
          const currentTimestamp = new Date().toISOString();
          this.contract.status = ContractStatus.COMPLETE;
          if (this.contract.type === ContractType.BASIS || this.contract.type === ContractType.DP) {
            this.contract.futuresLockedTimestamp = currentTimestamp;
            this.contract.cashLockedTimestamp = currentTimestamp;
          }
          if (this.contract.type === ContractType.HTA || this.contract.type === ContractType.DP) {
            this.contract.basisLockedTimestamp = currentTimestamp;
          }
          this.contract.completionTimestamp = currentTimestamp;
        } else {
          // only working pricings remain; setting of status and timestamps will be handled on making target
          if (this.contract.type === ContractType.BASIS) {
            this.contract.status = ContractStatus.WORKING_FUTURES;
          } else if (this.contract.type === ContractType.HTA) {
            this.contract.status = ContractStatus.WORKING_BASIS;
          } else if (this.contract.type === ContractType.DP) {
            this.contract.status = ContractStatus.WORKING_PRICE;
          }
        }
      }
      // No need to update status on reduction in quantity with bushels remaining to be priced as status will remain PENDING
      // More unpriced bushels available, set to PENDING
      else if (formQuantity > this.contract.quantity) {
        if (this.contract.type === ContractType.HTA && this.contract.status === ContractStatus.WORKING_BASIS) {
          this.contract.status = ContractStatus.PENDING_BASIS;
        } else if (this.contract.type === ContractType.BASIS && this.contract.status === ContractStatus.WORKING_FUTURES) {
          this.contract.status = ContractStatus.PENDING_FUTURES;
        } else if (this.contract.type === ContractType.DP && this.contract.status === ContractStatus.WORKING_PRICE) {
          this.contract.status = ContractStatus.PENDING_PRICE;
        }
      }
      if (formQuantity !== this.contract.quantity) {
        if (this.contract.originalQuantity === undefined) {
          this.contract.originalQuantity = this.contract.quantity;
        }
        this.contract.quantity = formQuantity;
      }
    }
    if (this.contractForm.get('basisPrice')) {
      this.contract.basisPrice = Number(formValues.basisPrice);
    }
    if (this.contractForm.get('futuresPrice')) {
      this.contract.futuresPrice = Number(formValues.futuresPrice);
    }
    if (this.contractForm.get('freightPrice')) {
      this.contract.freightPrice = Number(formValues.freightPrice);
    }
    if (this.contractForm.get('cashPrice')) {
      this.contract.cashPrice = Number(formValues.cashPrice);
    }
    if (this.contractForm.get('basisAdjustment')) {
      this.contract.basisAdjustment = Number(formValues.basisAdjustment);
    }
    if (this.contractForm.get('originator')) {
      const originator = formValues.originator;
      this.contract.originatorAccountingSystemId = originator.accountingSystemId;
      this.contract.originatorDocId = originator.docId;
      this.contract.originatorName = `${originator.firstName} ${originator.lastName}`;
    }
    if (this.contractForm.get('priceAdjustments')) {
      // empty array to prevent duplicates
      this.contract.priceAdjustments = [];
      const priceAdjustmentsForm = this.contractForm.get('priceAdjustments') as FormArray;
      priceAdjustmentsForm.controls.forEach((priceAdjustmentItem: FormGroup) =>
        this.contract.priceAdjustments.push({
          id: priceAdjustmentItem.get('id').value,
          value: parseFloat(Number(priceAdjustmentItem.get('priceAdjustment').value).toFixed(5))
        } as ContractPriceAdjustment));
      this.contract.priceAdjustmentsTotal = parseFloat(this.priceAdjustmentsTotal.toFixed(5));
    }
    if (this.contractForm.get('customContractId')) {
      this.contract.customContractId = this.contractForm.get('customContractId').value;
    }
    if (this.contractForm.get('expirationDate')) {
      if (formValues.expirationDate) {
        this.contract.expirationDate = moment(formValues.expirationDate).format(DATE_FORMAT);
        this.contract.timeInForce = TimeInForce.GTD;
      } else {
        delete this.contract.expirationDate;
        this.contract.timeInForce = TimeInForce.GTC;
      }
    }
    if (this.contractForm.get('comments')) {
      if (formValues.comments) {
        this.contract.comments = formValues.comments;
      } else {
        delete this.contract.comments;
      }
    }
    if (this.contractForm.get('instructions')) {
      if (formValues.instructions) {
        this.contract.instructions = formValues.instructions;
      } else {
        delete this.contract.instructions;
      }
    }
    if (this.contractForm.get('clientLocation')) {
      const selectedLocation = formValues.clientLocation as Location;
      this.contract.clientLocationName = selectedLocation.name;
      this.contract.clientLocationAccountingSystemId = selectedLocation.accountingSystemId;
      this.contract.clientLocationDocId = selectedLocation.docId;
    }

    if (this.contractForm.get('contractExchangeId')) {
      if (this.creatingContractExchange) {
        this.contract.status = ContractStatus.WORKING_EXCHANGE;
        this.contract.exchangeTimestamp = new Date().toISOString();
      }
      this.contract.exchangeId = formValues.contractExchangeId.trim();
      this.creatingContractExchange = false;
    }

    this.contract.versionNumber = this.contract.versionNumber + 1;
    this.contract.versionCreationTimestamp = new Date().toISOString();
    this.updateContract(true);
  }

  // begin price adjustment functions

  get canAdjustPrices() {
    return this.authzService.currentUserHasRole(UserRoles.PRICE_ADJUSTER_ROLE);
  }

  get availablePriceAdjustments() {
    return Object.values(this.priceAdjustmentMap.priceAdjustments).sort((a, b) => a.name < b.name ? -1 : 1);
  }

  get existingPriceAdjustments() {
    const priceAdjustmentsArray = this.contractForm.get('priceAdjustments') as FormArray;
    return priceAdjustmentsArray.controls as FormGroup[];
  }

  get displayEditablePriceAdjustments() {
    return this.editMode && this.canAdjustPrices && this.usePriceAdjustments && this.contractForm.get('priceAdjustments');
  }

  get displayPriceAdjustmentsTotalOnly() {
    return this.contract.side === Side.BUY && this.usePriceAdjustments && !this.editMode && !this.contract.priceAdjustments.length;
  }

  get displayPriceAdjustments() {
    return this.contract.side === Side.BUY && ((this.usePriceAdjustments && this.editMode) || this.contract.priceAdjustments.length > 0);
  }

  addPriceAdjustment(event: KeyboardEvent) {
    // prevent panel from opening on select
    event.stopImmediatePropagation();
    const priceAdjustmentsForm = this.contractForm.get('priceAdjustments') as FormArray;
    const selectedPriceAdjustment = this.newPriceAdjustment.value as HMSPriceAdjustment;
    // insert vs push to keep new contract pattern of newest at top
    priceAdjustmentsForm.insert(0, this.getPriceAdjustmentForm(selectedPriceAdjustment.id, ''));
    // set value to '' to prevent most recently removed adjustment from getting re-selected
    this.newPriceAdjustment.value = '';
  }

  /**
   * Get display value for an HMSPriceAdjustment
   *
   * Used when the HMSPriceAdjustment object is available, e.g. as option values in a select and the
   * Client's HMSPriceAdjustmentMap.priceAdjustments map has been passed to the template
   *
   * @param priceAdjustment The HMSPriceAdjustment for which a display value is needed
   */
  getHMSPriceAdjustmentDisplay(priceAdjustment: HMSPriceAdjustment) {
    return `${priceAdjustment.name} (${priceAdjustment.id})`;
  }


  /**
   * Get display value for a ContractPriceAdjustment
   *
   * Used when only the id of an HMSPriceAdjustment is known, e.g. when used as labels for
   * individual price adjustment fields on the template.
   *
   * @param id The id of HMSPriceAdjustment for which a display value is needed taken from the ContractPriceAdjustment
   */
  getContractPriceAdjustmentLabel(id: string) {
    const hmsPriceAdjustment = this.priceAdjustmentMap.priceAdjustments[id];
    return hmsPriceAdjustment ? this.getHMSPriceAdjustmentDisplay(hmsPriceAdjustment) : `Price Adjustment (${id})`;
  }

  processUpdatedPriceAdjustment(priceAdjustmentForm: FormGroup) {
    // do not automatically fix field via rounding if field invalid due to precision
    if (!priceAdjustmentForm.get('priceAdjustment').valid) {
      return;
    }
    const price = Number(priceAdjustmentForm.get('priceAdjustment').value);
    if (priceAdjustmentForm.get('priceAdjustment').value && Number.isFinite(price)) {
      priceAdjustmentForm.get('priceAdjustment').setValue(this.contractPriceHelper.getRoundedValue('priceAdjustment', price));
    }
  }

  removePriceAdjustment(index: number) {
    const priceAdjustmentsArray = this.contractForm.get('priceAdjustments') as FormArray;
    priceAdjustmentsArray.removeAt(index);
    this.updatePriceAdjustmentsTotal();
    this.contractForm.get('priceAdjustments').markAsDirty();
  }

  updatePriceAdjustmentsTotal() {
    const priceAdjustmentsForm = this.contractForm.get('priceAdjustments') as FormArray;
    this.priceAdjustmentsTotal = 0;
    Object.values(priceAdjustmentsForm.controls).forEach((priceAdjustmentItem: FormGroup) => {
      const numericPriceAdjustmentValue = Number(priceAdjustmentItem.get('priceAdjustment').value);
      this.priceAdjustmentsTotal += Number.isFinite(numericPriceAdjustmentValue) ? numericPriceAdjustmentValue : 0;
    });
  }

  /**
   * Get contract field value assuming the form control and the contract has the same field name
   * @param fieldName the contract field name
   */
  getContractFieldVal(fieldName: string) {
    return this.contractForm.get(fieldName) ? this.contractForm.get(fieldName).value : this.contract[fieldName];
  }

  private getPriceAdjustmentForm(key: string, value: string): FormGroup {
    return this.formBuilder.group({
      id: [key], priceAdjustment: [value, this.contractPriceHelper.getPriceAdjustmentsValidators()]
    });
  }

  // end price adjustment functions

  private get isContractUpdater() {
    return this.authzService.currentUserHasRole(UserRoles.CONTRACT_UPDATER_ROLE);
  }

  private get isContractDeleter() {
    return this.authzService.currentUserHasRole(UserRoles.CONTRACT_DELETER_ROLE);
  }

  private get isOrderAdmin() {
    return this.authzService.currentUserHasRole(UserRoles.ORDER_ADMIN_ROLE);
  }

  private get isManualPriceLocker() {
    return this.authzService.currentUserHasRole(UserRoles.MANUAL_PRICE_LOCKER_ROLE);
  }

  private get isInTransitionalState() {
    return this.isLoading || this.editMode || !this.updateComplete || !this.contractOrderActionComplete ||
      !this.segmentOrderActionComplete || !this.pricingActionComplete;
  }

  private get isWorkingTargetContract() {
    return (this.contract.type === ContractType.CASH && this.contract.status === ContractStatus.WORKING_CASH) ||
      (this.contract.type === ContractType.BASIS && this.contract.status === ContractStatus.WORKING_BASIS) ||
      (this.contract.type === ContractType.HTA && this.contract.status === ContractStatus.WORKING_FUTURES);
  }

  private get canEditContractExchange() {
    return this.isOrderAdmin && this.contract.isExchange && EXCHANGE_EDITABLE_CONTRACT_STATUSES.includes(this.contract.status);
  }

  get unpricedSegments() {
    return this.segments.filter((segment) => segment.status !== 'PRICED');
  }

  get sortedUnpricedSegments() {
    return this.segments.filter((segment) => segment.status !== 'PRICED').sort(
      (a, b) => UNPRICED_SEGMENT_SORT_ORDER.indexOf(a.status) < UNPRICED_SEGMENT_SORT_ORDER.indexOf(b.status) ? -1 : 1);
  }

  get pricedSegments() {
    return this.segments.filter((segment) => segment.status === 'PRICED');
  }

  private getPartialContractOriginator() {
    const name = this.contract.originatorName.split(' ');
    return {
      firstName: name.shift(),
      lastName: name.join(' '),
      docId: this.contract.originatorDocId,
      accountingSystemId: this.contract.originatorAccountingSystemId
    } as Originator;
  }

  private handlingPendingOrderAction() {
    // ensure these properties are reset on new Contract doc emission for changed data while viewing page
    this.contractOrderActionComplete = true;
    this.segmentOrderActionComplete = true;
    this.creatingOrder = false;
    this.navToPricing = false;
    this.cancellingOrder = false;
    this.updatingOrder = false;
    this.recreatingOrder = false;
    this.creatingContractExchange = false;
    this.creatingPricingExchange = false;
    if (this.contract.status === ContractStatus.PENDING_ORDER && this.canCompleteOrderAction) {
      this.contractOrderActionComplete = false;
      this.creatingOrder = true;
      this.setupQST();
    } else if (this.contract.status === ContractStatus.PENDING_ORDER_CANCEL && this.canCompleteOrderAction) {
      this.contractOrderActionComplete = false;
      this.cancellingOrder = true;
      this.setupQST();
    } else if (this.contract.status === ContractStatus.PENDING_ORDER_UPDATE && this.canCompleteOrderAction) {
      this.contractOrderActionComplete = false;
      this.updatingOrder = true;
      this.setupQST();
    } else if (this.contract.status === ContractStatus.PENDING_ORDER_RECREATE && this.canCompleteOrderAction) {
      this.contractOrderActionComplete = false;
      this.recreatingOrder = true;
      this.setupQST();
    } else if (this.contract.status === ContractStatus.PENDING_EXCHANGE && this.canCompleteOrderAction) {
      this.contractOrderActionComplete = false;
      this.creatingContractExchange = true;
    } else if (this.alertDocIdQueryParam && this.segmentDocIdQueryParam) {
      this.segmentOrderActionComplete = false;
      this.navToPricing = true;
      if (this.tabGroupRef) {
        this.tabGroupRef.selectedIndex = 1;
      }
    }
  }

  private setupQST() {
    this.subscriptions.push(this.exchangeOrderIdSub = this.qstService.orderEntry$.pipe(
      filter(orderEntry => orderEntry.OD === this.newOrderClientId),
      map(orderEntry => orderEntry.XID)
    ).subscribe(xid => {
      if (xid) {
        this.qstWorking = false;
        this.orderForm.get('orderDocId').setValue(xid);
        this.orderForm.get('orderDocId').markAsDirty();
        if (this.contract.status === ContractStatus.PENDING_ORDER || this.contract.status === ContractStatus.PENDING_ORDER_RECREATE) {
          this.setContractOrder();
        }
      }
    }));
  }

  private getOrderSideFromContractSide(contractSide: Side) {
    if (contractSide === Side.BUY) {
      return Side.SELL;
    } else {
      return Side.BUY;
    }
  }

  private handleUpdatedOrder() {
    this.contract.status = this.contract.type === ContractType.CASH ? ContractStatus.WORKING_CASH : ContractStatus.WORKING_FUTURES;
    this.updatingOrder = false;
    this.updateContract(true);
  }

  private handleCancelledOrder() {
    // Order was cancelled due to a manual price lock
    if (this.contract.isPriceManuallyLocked) {
      // Do not set status
      this.contract.contractOrderDocId = '';
      this.cancellingOrder = false;
      this.updateContract(true);
      // Order was cancelled due to contract being cancelled
    } else if (this.contract.quantity >= this.contract.targetOrderThreshold) {
      this.setContractCancelled();
      // Order was cancelled due to a contract quantity update reducing it from a quantity large enough for an order
      // to a quantity not sufficient for an order
    } else {
      this.contract.status = this.contract.type === ContractType.CASH ? ContractStatus.WORKING_CASH : ContractStatus.WORKING_FUTURES;
      this.contract.contractOrderDocId = '';
      this.cancellingOrder = false;
      this.updateContract(true);
    }
  }

  private setContractCancelled() {
    this.contract.status = ContractStatus.CANCELLED;
    this.contract.cancellationTimestamp = new Date().toISOString();
    this.cancellingOrder = false;
    this.updateContract(true);
  }

  private setContractDeleted(): Promise<any> {
    this.contract.status = ContractStatus.DELETED;
    return this.updateContract(true);
  }

  private handleManuallyLockedPrice(marketPrice: number, priceDifference: number) {
    const currentTimestamp = new Date().toISOString();
    this.contract.isPriceManuallyLocked = true;
    this.contract.manualLockPriceDifference = parseFloat((Math.abs(priceDifference)).toFixed(4));
    this.contract.futuresLockedTimestamp = currentTimestamp;
    if (this.contract.contractOrderDocId) {
      this.contract.status = ContractStatus.PENDING_ORDER_CANCEL;
    }
    if (this.contract.type === ContractType.CASH) {
      this.contract.cashLockedTimestamp = currentTimestamp;
      this.contract.basisLockedTimestamp = currentTimestamp;
      this.contract.futuresPrice = parseFloat(marketPrice.toFixed(4));
      this.contract.basisAdjustment = parseFloat((this.contract.basisAdjustment + priceDifference).toFixed(4));
    }
    this.updateContract(true);
  }

  private updateContract(showSnackbar: boolean) {
    this.updateComplete = false;
    if (this.userSettings.accountingSystemId) {
      this.contract.lastUpdatedByAccountingSystemId = this.userSettings.accountingSystemId;
    }
    this.contract.lastUpdatedByName = `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`;
    this.contract.lastUpdatedTimestamp = new Date().toISOString();
    return this.contractService.updateContract(this.selectedClientDocId, this.contract)
      .then(() => {
        this.updateComplete = true;
        console.log('Contract successfully updated');
        this.setEditMode(false);
        if (showSnackbar) {
          this.openSnackBar('Contract successfully updated', 'DISMISS', true);
        }
      })
      .then(() => {
        if (this.alertDocIdQueryParam) {
          return this.appAlertService.updateAppAlertStatus(this.alertDocIdQueryParam, AlertStatus.COMPLETE)
            .catch(err => {
              console.error(`App alert update failed: ${err}`);
              return Promise.resolve();
            });
        }
        return Promise.resolve();
      })
      .then(() => {
        if (this.contract.type === ContractType.DP && this.availableQuantity) {
          // do nothing
        } else if (this.isOrderAdmin) {
          return this.router.navigate(['/liveledgers/activityledger'], { replaceUrl: true });
        }
        return this.router.navigate([], { queryParams: { alertId: null }, queryParamsHandling: 'merge', replaceUrl: true });
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`Contract update failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Contract update failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  private createPricingSegment(segment: PricingSegment) {
    this.updateComplete = false;
    return this.pricingSegmentService.createPricingSegment(this.selectedClientDocId, this.contract.docId, segment)
      .then(() => {
        this.updateComplete = true;
        console.log('Pricing segment successfully created');
        this.openSnackBar('Pricing successfully created', 'DISMISS', true);
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`Pricing segment creation failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Pricing creation failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  private updatePricingSegment(segment: PricingSegment, showSnackbar = true) {
    this.updateComplete = false;
    // clone object to avoid firestore.field.delete() being sent to template;
    segment = Object.assign({}, segment);
    if (this.userSettings.accountingSystemId) {
      segment.lastUpdatedByAccountingSystemId = this.userSettings.accountingSystemId;
    }
    segment.lastUpdatedByName = `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`;
    segment.lastUpdatedTimestamp = new Date().toISOString();
    return this.pricingSegmentService.updatePricingSegment(this.contract.clientDocId, this.contract.docId, segment)
      .then(() => {
        this.updateComplete = true;
        console.log('Pricing segment successfully updated');
        this.setEditMode(false);

        if (showSnackbar) {
          this.openSnackBar('Pricing successfully updated', 'DISMISS', true);
        }
      })
      .then(() => {
        if (this.alertDocIdQueryParam) {
          return this.appAlertService.updateAppAlertStatus(this.alertDocIdQueryParam, AlertStatus.COMPLETE)
            .catch(err => {
              console.error(`App alert update failed: ${err}`);
              return Promise.resolve();
            });
        }
        return Promise.resolve();
      })
      .then(() => {
        if (this.isOrderAdmin) {
          return this.router.navigate(['/liveledgers/activityledger'], { replaceUrl: true });
        }
        return this.router.navigate([],
          { queryParams: { alertId: null, segmentId: null }, queryParamsHandling: 'merge', replaceUrl: true });
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`Pricing segment update failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Pricing update failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  private setContractStatusOnCreatePricingSegment(segment: PricingSegment) {
    if (this.contract.type === ContractType.BASIS) {
      this.setBasisContractStatusOnCreatePricingSegment(segment);
    } else if (this.contract.type === ContractType.HTA) {
      this.setHTAContractStatusOnCreatePricingSegment(segment);
    } else if (this.contract.type === ContractType.DP) {
      this.setDPContractStatusOnCreateOrUpdatePricingSegment(segment);
    }
  }

  private setBasisContractStatusOnCreatePricingSegment(segment: PricingSegment) {
    // contract quantity is not fully priced for futures
    if (this.contract.quantity > parseFloat((this.pricedQuantity + this.workingQuantity + segment.quantity).toFixed(2))) {
      this.contract.status = ContractStatus.PENDING_FUTURES;
      // total contract quantity is priced for futures but at least one segment is still in WORKING_FUTURES or PENDING_ORDER status
    } else {
      this.contract.status = ContractStatus.WORKING_FUTURES;
    }
  }

  private setHTAContractStatusOnCreatePricingSegment(segment: PricingSegment) {
    // contract quantity is not fully priced for basis
    if (this.contract.quantity > parseFloat((this.pricedQuantity + this.workingQuantity + segment.quantity).toFixed(2))) {
      this.contract.status = ContractStatus.PENDING_BASIS;
      // total contract quantity is priced for basis but at least one segment is still in WORKING_BASIS status
    } else if (segment.status === PricingSegmentStatus.WORKING_BASIS
      || this.doesSegmentStatusExist(PricingSegmentStatus.WORKING_BASIS)) {
      this.contract.status = ContractStatus.WORKING_BASIS;
    }
  }

  private setDPContractStatusOnCreateOrUpdatePricingSegment(segment: PricingSegment) {
    const newSegmentPending = segment.status === PricingSegmentStatus.PENDING_BASIS
      || segment.status === PricingSegmentStatus.PENDING_FUTURES;
    const existingSegmentPending = this.doesSegmentStatusExist(PricingSegmentStatus.PENDING_BASIS)
      || this.doesSegmentStatusExist(PricingSegmentStatus.PENDING_FUTURES);
    const newSegmentWorking = segment.status === PricingSegmentStatus.WORKING_BASIS
      || segment.status === PricingSegmentStatus.WORKING_CASH
      || segment.status === PricingSegmentStatus.WORKING_FUTURES;
    const existingSegmentWorking = this.doesSegmentStatusExist(PricingSegmentStatus.WORKING_BASIS)
      || this.doesSegmentStatusExist(PricingSegmentStatus.WORKING_CASH)
      || this.doesSegmentStatusExist(PricingSegmentStatus.WORKING_FUTURES);
    // contract quantity is fully priced and represented in segments (at least first part)
    if (this.contract.quantity === parseFloat((this.pricedQuantity + this.workingQuantity + segment.quantity).toFixed(2))) {
      // one or more segments are in a pending status
      if (newSegmentPending || existingSegmentPending) {
        this.contract.status = ContractStatus.PENDING_PRICE;
        // all non-cash-locked segments in a working status
      } else if (newSegmentWorking || existingSegmentWorking) {
        this.contract.status = ContractStatus.WORKING_PRICE;
      } else {
        // leave status as-is for fully priced with no pending/working segments to avoid extra line in history;
        // firestoreOnPricingSegmentUpdate will set contract to COMPLETE
      }
      // contract quantity is not fully priced and represented in segments
    } else {
      this.contract.status = ContractStatus.PENDING_PRICE;
    }
  }

  private doesSegmentStatusExist(segmentStatus: PricingSegmentStatus): boolean {
    const matchingSegment = this.segments.find((segment: PricingSegment) => segment.status === segmentStatus);
    return matchingSegment ? true : false;
  }

  private handleUpdatedSegmentOrder(segment) {
    segment.status = segment.cashPricingType ? PricingSegmentStatus.WORKING_CASH : PricingSegmentStatus.WORKING_FUTURES;
    this.updatePricingSegment(segment);
  }

  private handleCancelledSegmentOrder(segment: PricingSegment) {
    const isOrderQuantity = segment.quantity >= segment.targetOrderThreshold;
    // Order was cancelled due to a manual price lock
    if (segment.isPriceManuallyLocked) {
      // Do not set status
      segment.orderDocId = '';
      this.updatePricingSegment(segment);
      // Order was cancelled due to segment being cancelled (basis segment or DP segment working cash or working futures)
    } else if (isOrderQuantity &&
      (this.contract.type === ContractType.BASIS || this.contract.type === ContractType.DP)) {
      this.handleCancelledSegment(segment);
      // Order was cancelled due to a segment quantity update reducing it from a quantity large enough for an order
      // to a quantity not sufficient for an order or was cancelled as the second part of a DP segment
    } else {
      // order cancelled for DP segment 2nd part working futures
      if (this.contract.type === ContractType.DP && segment.isBasisLocked) {
        segment.status = isOrderQuantity ? PricingSegmentStatus.PENDING_FUTURES : PricingSegmentStatus.WORKING_FUTURES;
        // order cancelled for quantity change on basis or DP segment working cash or futures
      } else {
        segment.status = segment.cashPricingType ? PricingSegmentStatus.WORKING_CASH : PricingSegmentStatus.WORKING_FUTURES;
      }
      segment.orderDocId = '';
      this.updatePricingSegment(segment);
    }
  }

  private handleCancelledSegment(segment) {
    if (this.contract.status === ContractStatus.DELETED) {
      segment.status = PricingSegmentStatus.CANCELLED;
      segment.cancellationTimestamp = new Date().toISOString();
      this.updatePricingSegment(segment);
    } else {
      this.contract.versionNumber = this.contract.versionNumber + 1;
      this.contract.versionCreationTimestamp = new Date().toISOString();
      this.setContractStatusOnCancelPricingSegment();
      if (this.contract.type === ContractType.DP &&
        (
          // second part DP, clean
          (segment.status === PricingSegmentStatus.WORKING_BASIS && segment.isFuturesLocked) ||
          (segment.status === PricingSegmentStatus.WORKING_FUTURES && segment.isBasisLocked) ||
          // second part DP, PENDING_ORDER_CANCEL
          (segment.status === PricingSegmentStatus.PENDING_ORDER_CANCEL && segment.dpFirstPartType === PricingSegmentPartType.BASIS)
        )
      ) {
        if (segment.status === PricingSegmentStatus.WORKING_BASIS) {
          delete segment.basisPrice;
          delete segment.basisPricingType;
          segment.status = PricingSegmentStatus.PENDING_BASIS;
        } else if (segment.status === PricingSegmentStatus.WORKING_FUTURES ||
          (segment.status === PricingSegmentStatus.PENDING_ORDER_CANCEL &&
            segment.dpFirstPartType === PricingSegmentPartType.BASIS && segment.futuresPricingType)
        ) {
          delete segment.futuresYearMonth;
          delete segment.futuresPrice;
          delete segment.futuresPricingType;
          segment.orderDocId = '';
          segment.status = PricingSegmentStatus.PENDING_FUTURES;
        }
        this.setDPContractStatusOnCreateOrUpdatePricingSegment(segment);
      } else {
        segment.status = PricingSegmentStatus.CANCELLED;
        segment.cancellationTimestamp = new Date().toISOString();
      }
      this.updatePricingSegment(segment)
        .then(() => this.updateContract(false));
    }
  }

  private setContractStatusOnCancelPricingSegment() {
    if (this.contract.type === ContractType.BASIS) {
      this.contract.status = ContractStatus.PENDING_FUTURES;
    } else if (this.contract.type === ContractType.HTA) {
      this.contract.status = ContractStatus.PENDING_BASIS;
    } else if (this.contract.type === ContractType.DP) {
      this.contract.status = ContractStatus.PENDING_PRICE;
    }
  }

  private handleDeletedSegment(segment: PricingSegment) {
    this.contract.versionNumber = this.contract.versionNumber + 1;
    this.contract.versionCreationTimestamp = new Date().toISOString();
    this.setContractStatusOnDeletePricingSegment();
    segment.status = PricingSegmentStatus.DELETED;
    this.updatePricingSegment(segment)
      .then(() => this.updateContract(false));
  }

  private setContractStatusOnDeletePricingSegment() {
    if (this.contract.type === ContractType.BASIS
      && (this.contract.status === ContractStatus.WORKING_FUTURES || this.contract.status === ContractStatus.COMPLETE)) {
      this.contract.status = ContractStatus.PENDING_FUTURES;
    } else if (this.contract.type === ContractType.HTA
      && (this.contract.status === ContractStatus.WORKING_BASIS || this.contract.status === ContractStatus.COMPLETE)) {
      this.contract.status = ContractStatus.PENDING_BASIS;
    } else if (this.contract.type === ContractType.DP
      && (this.contract.status === ContractStatus.WORKING_PRICE || this.contract.status === ContractStatus.COMPLETE)) {
      this.contract.status = ContractStatus.PENDING_PRICE;
    }
  }

  private translateContractMonthToMoment(value: string): moment.Moment {
    const year = moment.parseTwoDigitYear(value.substring(0, 2));
    const monthCode = value.substring(2);
    const idxMonth = this.contractMonths.indexOf(monthCode);
    return moment().year(year).month(idxMonth).startOf('month');
  }

  private translateMomentToContractMonth(selectedMonth: moment.Moment): string {
    return `${selectedMonth.format(YEAR_FORMAT)}${ContractMonth[this.contractMonths[selectedMonth.month()]]}`;
  }

  private calculatePricedQuantity() {
    this.pricedQuantity = 0;
    this.workingQuantity = 0;
    this.availableQuantity = 0;
    let pendingSegmentQuantity = 0;
    if (this.contract.status === ContractStatus.COMPLETE) {
      this.pricedQuantity = this.contract.quantity;
      this.availableQuantity = 0;
    } else if (this.isWorkingTargetContract) {
      this.workingQuantity = this.contract.quantity;
      this.unpricedQuantity = this.contract.quantity;
    } else {
      this.segments.map((segment: PricingSegment) => {
        if (segment.isCashLocked) {
          this.pricedQuantity += segment.quantity;
        } else if (segment.status === PricingSegmentStatus.WORKING_CASH || segment.status === PricingSegmentStatus.WORKING_BASIS ||
          segment.status === PricingSegmentStatus.WORKING_FUTURES || segment.status === PricingSegmentStatus.WORKING_EXCHANGE) {
          this.workingQuantity += segment.quantity;
        } else {
          pendingSegmentQuantity += segment.quantity;
        }
      });
      this.unpricedQuantity = this.contract.quantity - this.pricedQuantity;
      this.availableQuantity = parseFloat((this.unpricedQuantity - this.workingQuantity - pendingSegmentQuantity).toFixed(2));
    }
  }

  private handlePricingSegments(allContractSegments: PricingSegment[]) {
    // avoid updating `this.segments` if a segment order action is in progress
    if (this.segmentOrderActionComplete) {
      // ensure cancelled and deleted segments are not displayed and their quantities are not included in quantity checks
      const activeSegments = allContractSegments.filter(
        segment => segment.status !== PricingSegmentStatus.CANCELLED && segment.status !== PricingSegmentStatus.DELETED);
      activeSegments.sort((segmentA, segmentB) => {
        if (segmentA.status === PricingSegmentStatus.PRICED && segmentB.status !== PricingSegmentStatus.PRICED) {
          return 1;
        } else if (segmentB.status === PricingSegmentStatus.PRICED && segmentA.status !== PricingSegmentStatus.PRICED) {
          return -1;
        } else if (segmentA.lastUpdatedTimestamp < segmentB.lastUpdatedTimestamp) {
          return 1;
        } else {
          return -1;
        }
      });
      this.segments = activeSegments;
    }
  }

  private getOrderQuantity(): number {
    const contractSize = this.commodityMap.commodities[this.contract.commodityId].contractSize;
    const fullContracts = Math.trunc(this.contract.quantity / contractSize);
    const remainingQuantity = this.contract.quantity % contractSize;
    return remainingQuantity >= this.contract.targetOrderThreshold ? fullContracts + 1 : fullContracts;
  }

  private onDeliveryPeriodChanges() {
    this.subscriptions.push(this.contractForm.get('deliveryPeriod').valueChanges.subscribe(() => {
      if (this.editMode && this.contractForm.get('deliveryPeriod').enabled && this.contractForm.get('deliveryPeriod').valid) {
        this.getBasis();
      }
    }));
  }

  private onDeliveryLocationChanges() {
    this.subscriptions.push(this.contractForm.get('deliveryLocation').valueChanges.subscribe(() => {
      if (this.editMode && this.contractForm.get('deliveryLocation').enabled && this.contractForm.get('deliveryLocation').valid) {
        this.getBasis();
      }
    }));
  }

  private onCashPriceChanges() {
    this.subscriptions.push(this.contractForm.get('cashPrice').valueChanges.subscribe(() => {
      if (this.editMode && this.contractForm.get('cashPrice').enabled && this.contractForm.get('cashPrice').valid) {
        this.processUpdatedPrice();
      }
    }));
  }

  private onBasisPriceChanges() {
    this.subscriptions.push(this.contractForm.get('basisPrice').valueChanges.subscribe(() => {
      if (this.editMode && this.contractForm.get('basisPrice').enabled && this.contractForm.get('basisPrice').valid) {
        this.processUpdatedPrice();
      }
    }));
  }

  private onBasisAdjustmentChanges() {
    this.contractForm.get('basisAdjustment').valueChanges.subscribe(() => {
      if (this.editMode && this.contractForm.get('basisAdjustment').enabled && this.contractForm.get('basisAdjustment').valid) {
        this.processUpdatedPrice();
      }
    });
  }

  private onFreightPriceChanges() {
    this.subscriptions.push(this.contractForm.get('freightPrice').valueChanges.subscribe(() => {
      if (this.editMode && this.contractForm.get('freightPrice').enabled && this.contractForm.get('freightPrice').valid) {
        this.processUpdatedPrice();
      }
    }));
  }

  private processUpdatedPrice() {
    if (this.contract.type === ContractType.CASH) {
      const basisPrice = Number(this.getContractFieldVal('basisPrice'));
      const basisAdjustment = Number(this.getContractFieldVal('basisAdjustment'));
      const freightPrice = Number(this.getContractFieldVal('freightPrice'));
      const cashPrice = Number(this.getContractFieldVal('cashPrice'));
      const targetFuturesPrice = cashPrice - basisPrice - basisAdjustment + freightPrice;
      this.contractForm.get('futuresPrice').setValue(targetFuturesPrice.toFixed(this.getVariablePrecision('futuresPrice')));
    }
  }

  private getBasis() {
    const side = this.contract.side;
    const deliveryLocationDocId = this.contractForm.get('deliveryLocation').value.docId;
    const profileDocId = this.contract.commodityProfileDocId;

    if (side === Side.BUY) {
      this.isLoadingBasis = true;
      this.subscriptions.push(this.basisService.getBasisByClientCommodityProfileAndLocation(this.selectedClientDocId, profileDocId, deliveryLocationDocId).pipe(
        take(1),
        tap((basis: Basis) => {
          this.handleBasis(basis);
          this.isLoadingBasis = false;
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        catchError(err => {
          this.isLoadingBasis = false;
          this.contractForm.get('deliveryPeriod').setValue('');
          this.contractForm.get('basisPrice').setValue('');
          this.openSnackBar('Error retrieving basis for selected delivery period', 'DISMISS', false);
          console.error(`Error retrieving basis data: ${err.message || err}`);
          return of(undefined);
        })
      ).subscribe());
    } else {
      // sell contract
      this.handleManualBasisEntry();
    }
  }

  // handleBasis is currently only called for BUY side working cash contracts
  private handleBasis(basis: Basis) {
    const deliveryPeriod = this.contractForm.get('deliveryPeriod').value as moment.Moment;
    if (basis) {
      const deliveryPeriodBasis = basis.deliveryPeriodBases[this.translateMomentToContractMonth(deliveryPeriod)];
      if (deliveryPeriodBasis) {
        this.contractForm.get('basisPrice').disable();
        this.contractPriceHelper.setFieldValue('basisPrice', deliveryPeriodBasis.basis);
        this.processUpdatedPrice();
      } else {
        // no delivery period Basis found
        this.handleManualBasisEntry();
      }
    } else {
      // no Basis found
      this.handleManualBasisEntry();
    }
  }

  // allow basis to be entered directly
  private handleManualBasisEntry() {
    if (this.contractForm.get('deliveryLocation').dirty || this.contractForm.get('deliveryPeriod').dirty) {
      this.contractForm.get('basisPrice').setValue('');
    }
    this.contractForm.get('basisPrice').enable();
  }

  private prepForClientLocationSelection() {
    this.filteredClientLocations$ = this.contractForm.controls.clientLocation.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | Location>(''),
      switchMap(searchTerm => {
        return this.getActiveAuthorizedLocations().pipe(
          map((locations: Location[]) => {
            // Return all locations when a location has already been selected to avoid the user needing to clear the field
            if (typeof searchTerm !== 'string') {
              return locations;
            }
            return locations.filter(location => location.name.toLowerCase().includes(searchTerm.toLowerCase()));
          }),
          shareReplay({ bufferSize: 1, refCount: true }),
          catchError(err => {
            this.errorMessage = 'Error retrieving delivery locations; please try again later';
            console.error(`Error retrieving delivery locations: ${err}`);
            return of([]);
          })
        );
      }),
    );
  }

  private prepForDeliveryLocationSelection() {
    this.filteredDeliveryLocations$ = this.contractForm.controls.deliveryLocation.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | Location>(''),
      switchMap(searchTerm => {
        return this.getActiveAuthorizedLocations().pipe(
          map((locations: Location[]) => {
            // Return all locations when a location has already been selected to avoid the user needing to clear the field
            if (typeof searchTerm !== 'string') {
              return locations;
            }
            return locations.filter(location => location.name.toLowerCase().includes(searchTerm.toLowerCase()));
          }),
          shareReplay({ bufferSize: 1, refCount: true }),
          catchError(err => {
            this.errorMessage = 'Error retrieving delivery locations; please try again later';
            console.error(`Error retrieving delivery locations: ${err}`);
            return of([]);
          })
        );
      }),
    );
  }

  private getActiveAuthorizedLocations(): Observable<Location[]> {
    if (this.userSettings.isAuthorizedAtAllLocations) {
      return this.locationService.getActiveLocationsByClientDocId(this.selectedClientDocId);
    }
    return combineLatest(this.userSettings.authorizedLocationDocIds.map(
      locationDocId => this.locationService.getLocationByDocId(this.selectedClientDocId, locationDocId))).pipe(
        map((locations: Location[]) => locations.filter(location => location.isActive))
      );
  }

  private getClientLocationControl(contract: Contract) {
    return this.formBuilder.control({
      accountingSystemId: contract.clientLocationAccountingSystemId,
      docId: contract.clientLocationDocId,
      name: contract.clientLocationName,
    }, [Validators.required, CommonValidators.objectValidator]);
  }

  private getOrdersObservable(orderDocIds: string[]) {
    if (orderDocIds.length > 0) {
      this.orders$ = combineLatest(
        orderDocIds.map((orderDocId: string) => {
          return this.orderService.findClientOrdersByOrderDocId(this.selectedClientDocId, orderDocId).pipe(
            map((orders: Order[]) => {
              if (orders[ 0 ].status === OrderStatus.PARTIALLY_FILLED) {
                this.hasPartialFill = true;
              }
              return orders[0];
            })
          );
        })
      ).pipe(
        map((orders: Order[]) => {
          // ensure only orderDocIds with an Order doc found are included to avoid errors for bad ids or not yet received Order docs
          return orders.filter((order: Order) => order);
        })
      );
    } else {
      this.orders$ = of([]);
    }
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  // Dynamic Form Groups

  private setupContractForm() {
    this.contractPriceHelper = new ContractPriceHelper(undefined, this.useWholeCent);

    // Default form for editMode if specific contract state is not yet accounted for below
    this.contractForm = this.formBuilder.group({
      comments: [this.contract.comments || '', [Validators.maxLength(800)]],
      instructions: [this.contract.instructions || '', [Validators.maxLength(500)]],
      originator: [this.getPartialContractOriginator(),
      [Validators.required, CommonValidators.objectValidator]]
    });

    // Set value of priceAdjustmentsTotal for display on initial load / form reset
    this.priceAdjustmentsTotal = this.contract.priceAdjustmentsTotal;

    switch (this.contract.type) {
      case ContractType.CASH:
        this.prepCashContractForm();
        break;
      case ContractType.HTA:
        this.prepHTAContractForm();
        break;
      case ContractType.BASIS:
        this.prepBasisContractForm();
        break;
      case ContractType.DP:
        this.prepDPContractForm();
        break;
      default:
        console.error('Unexpected contract type encountered!');
        break;
    }

    // check to see if clientLocation needed, regardless of form configuration.
    if (this.isContractUpdater && !CLIENT_LOCATION_BLACKLIST_STATUSES.includes(this.contract.status)) {
      this.contractForm.addControl('clientLocation', this.getClientLocationControl(this.contract));
      this.prepForClientLocationSelection();
    }

    this.contractPriceHelper.setAllPriceValidators({
      commodityProfile: this.profile,
      form: this.contractForm,
      specialHandling: this.contract.hasRelatedHedge || this.contract.isExchange,
      specialHandlingType: this.contract.relatedHedgeType || 'EXCHANGE'
    });

    if (this.contract?.deliveryType) {
      this.contractForm.addControl('deliveryType', new FormControl(this.contract.deliveryType, [Validators.required]))
    }

    // note: indicate the form as touched, so that the users know what
    //       fields are invalid when they enter an edit mode (i.e expiration date, contract prices)
    this.contractForm.markAllAsTouched();
  }

  private prepCashContractForm() {
    switch (this.contract.status) {
      case ContractStatus.WORKING_CASH:
        this.prepFormForWorkingCashContract();
        break;
      case ContractStatus.COMPLETE:
        this.prepFormForCompleteCashContract();
        break;
      case ContractStatus.PENDING_EXCHANGE:
        this.prepFormForPendingExchangeCashContract();
        break;
      case ContractStatus.WORKING_EXCHANGE:
        this.prepFormForWorkingExchangeCashContract();
        break;
      default:
        console.log('No dynamic form found, using default form');
        break;
    }
  }

  private prepHTAContractForm() {
    switch (this.contract.status) {
      case ContractStatus.WORKING_FUTURES:
        this.prepFormForWorkingHTAContract();
        break;
      case ContractStatus.PENDING_BASIS:
      case ContractStatus.WORKING_BASIS:
        this.prepFormForLockedHTAContract();
        break;
      case ContractStatus.COMPLETE:
        this.prepFormForCompleteHTAContract();
        break;
      case ContractStatus.PENDING_EXCHANGE:
        this.prepFormForPendingExchangeHTAContract();
        break;
      case ContractStatus.WORKING_EXCHANGE:
        this.prepFormForWorkingExchangeHTAContract();
        break;
      default:
        console.log('No dynamic form found, using default form');
        break;
    }
  }

  private prepBasisContractForm() {
    switch (this.contract.status) {
      case ContractStatus.WORKING_BASIS:
        this.prepFormForWorkingBasisContract();
        break;
      case ContractStatus.PENDING_FUTURES:
      case ContractStatus.WORKING_FUTURES:
        this.prepFormForLockedBasisContract();
        break;
      case ContractStatus.COMPLETE:
        this.prepFormForCompleteBasisContract();
        break;
      default:
        console.log('No dynamic form found, using default form');
        break;
    }
  }

  private prepDPContractForm() {
    switch (this.contract.status) {
      case ContractStatus.WORKING_PRICE:
      case ContractStatus.PENDING_PRICE:
        this.prepFormForEditableDPContract();
        break;
      default:
        console.log('No dynamic form found, using default form');
        break;
    }
  }

  private prepFormForEditableDPContract() {
    this.contractForm = this.formBuilder.group({
      productionYear: [this.profile.productionYears[this.contract.productionYear], [Validators.required]],
      clientLocation: [this.clientLocation, [Validators.required, CommonValidators.objectValidator]],
      deliveryPeriod: [this.translateContractMonthToMoment(this.contract.deliveryPeriod), [Validators.required]],
      deliveryLocation: [this.deliveryLocation, [Validators.required, CommonValidators.objectValidator]],
      priceAdjustments: this.formBuilder.array([]),
      basisAdjustment: [this.contract.basisAdjustment.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]],
      freightPrice: [this.contract.freightPrice.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]],
      comments: [this.contract.comments || '', [Validators.maxLength(800)]],
      instructions: [this.contract.instructions || '', [Validators.maxLength(500)]],
      originator: [this.getPartialContractOriginator(),
        [ Validators.required, CommonValidators.objectValidator ] ],
      quantity: [ this.contract.quantity, this.getQuantityValidators(this.pricedQuantity + this.workingQuantity) ]

    });
    this.populatePriceAdjustmentControls();
    this.prepForClientLocationSelection();
    this.prepForDeliveryLocationSelection();
    this.prepForOriginatorSelection();
  }

  private prepFormForWorkingCashContract() {
    // note: we do allow futures price update through price calculation
    this.contractForm = this.formBuilder.group({
      productionYear: [this.profile.productionYears[this.contract.productionYear], [Validators.required]],
      futuresYearMonth: [this.translateContractMonthToMoment(this.contract.futuresYearMonth), [Validators.required]],
      deliveryPeriod: [this.translateContractMonthToMoment(this.contract.deliveryPeriod), [Validators.required]],
      deliveryLocation: [this.deliveryLocation, [Validators.required, CommonValidators.objectValidator]],
      deliveryType: [this.contract.deliveryType, [Validators.required]],
      quantity: [this.contract.quantity, this.getQuantityValidators()],
      basisPrice: [this.contract.basisPrice.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]],
      priceAdjustments: this.formBuilder.array([]),
      basisAdjustment: [this.contract.basisAdjustment.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]],
      futuresPrice: [{ value: this.contract.futuresPrice.toFixed(4), disabled: true }, this.getPriceValidators()],
      freightPrice: [this.contract.freightPrice.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]],
      cashPrice: [this.contract.cashPrice.toFixed(4), this.getPriceValidators()],
      expirationDate: [this.contract.expirationDate ? moment(this.contract.expirationDate).toDate() : ''],
      comments: [this.contract.comments || '', [Validators.maxLength(800)]],
      instructions: [this.contract.instructions || '', [Validators.maxLength(500)]],
      originator: [this.getPartialContractOriginator(),
      [Validators.required, CommonValidators.objectValidator]]
    });

    this.populatePriceAdjustmentControls();
    this.populateCustomContractIdControl();

    this.prepForDeliveryLocationSelection();
    this.onDeliveryPeriodChanges();
    this.onDeliveryLocationChanges();
    this.onCashPriceChanges();

    if (this.contract.side === Side.BUY) {
      this.contractForm.get('basisPrice').disable();
    }

    this.onBasisPriceChanges();
    this.onBasisAdjustmentChanges();
    this.onFreightPriceChanges();
    this.prepForOriginatorSelection();
    this.verifyMarketFuturesDataExists();
  }

  private prepFormForCompleteCashContract() {
    this.contractForm = this.formBuilder.group({
      futuresPrice: [
        { value: this.contract.futuresPrice.toFixed(this.getVariablePrecision('futuresPrice')), disabled: true },
        this.getPriceValidators()
      ],
      deliveryType: [this.contract.deliveryType, [Validators.required]],
      cashPrice: [this.contract.cashPrice.toFixed(this.getVariablePrecision('cashPrice')), this.getPriceValidators()],
      comments: [this.contract.comments || '', [Validators.maxLength(800)]],
      deliveryLocation: [this.deliveryLocation, [Validators.required, CommonValidators.objectValidator]],
      instructions: [this.contract.instructions || '', [Validators.maxLength(500)]],
      originator: [this.getPartialContractOriginator(),
        [ Validators.required, CommonValidators.objectValidator ] ],
      // not setting min quantity on complete as we do on locked hta/basis/editable DP as there should be no discrete pricings on cash
      quantity: [this.contract.quantity, this.getQuantityValidators() ]
    });

    if (this.canEditContractExchange) {
      this.contractForm.addControl('contractExchangeId', new FormControl(this.contract.exchangeId, [Validators.required]));
    }

    if (!this.contract.isSpot) {
      this.contractForm.addControl('basisAdjustment',
        new FormControl(this.contract.basisAdjustment.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]));
      this.contractForm.addControl('freightPrice',
        new FormControl(this.contract.freightPrice.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]));
      this.onBasisAdjustmentChanges();
      this.onFreightPriceChanges();
    }

    this.onCashPriceChanges();
    this.prepForOriginatorSelection();
  }

  private prepFormForPendingExchangeCashContract() {
    if (this.creatingContractExchange && !this.contract.exchangeId) {
      this.contractForm = this.formBuilder.group({
        contractExchangeId: ['', [Validators.required]]
      });
    }
  }

  private prepFormForWorkingExchangeCashContract() {
    this.contractForm = this.formBuilder.group({
      contractExchangeId: [this.contract.exchangeId, [Validators.required]]
    });
  }

  private prepFormForWorkingHTAContract() {
    this.contractForm = this.formBuilder.group({
      productionYear: [this.profile.productionYears[this.contract.productionYear], [Validators.required]],
      futuresYearMonth: [this.translateContractMonthToMoment(this.contract.futuresYearMonth), [Validators.required]],
      deliveryPeriod: [this.translateContractMonthToMoment(this.contract.deliveryPeriod), [Validators.required]],
      deliveryLocation: [this.deliveryLocation, [Validators.required, CommonValidators.objectValidator]],
      deliveryType: [this.contract.deliveryType, [Validators.required]],
      quantity: [this.contract.quantity, this.getQuantityValidators()],
      priceAdjustments: this.formBuilder.array([]),
      basisAdjustment: [this.contract.basisAdjustment.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]],
      futuresPrice: [this.contract.futuresPrice.toFixed(4), this.getPriceValidators()],
      freightPrice: [this.contract.freightPrice.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]],
      expirationDate: [this.contract.expirationDate ? moment(this.contract.expirationDate).toDate() : ''],
      comments: [this.contract.comments || '', [Validators.maxLength(800)]],
      instructions: [this.contract.instructions || '', [Validators.maxLength(500)]],
      originator: [this.getPartialContractOriginator(),
      [Validators.required, CommonValidators.objectValidator]]
    });
    this.populatePriceAdjustmentControls();
    this.populateCustomContractIdControl();
    this.prepForDeliveryLocationSelection();
    this.prepForOriginatorSelection();
    this.verifyMarketFuturesDataExists();
  }

  private prepFormForLockedHTAContract() {
    this.contractForm = this.formBuilder.group({
      futuresYearMonth: [this.translateContractMonthToMoment(this.contract.futuresYearMonth), [Validators.required]],
      deliveryPeriod: [this.translateContractMonthToMoment(this.contract.deliveryPeriod), [Validators.required]],
      deliveryLocation: [this.deliveryLocation, [Validators.required, CommonValidators.objectValidator]],
      deliveryType: [this.contract.deliveryType, [Validators.required]],
      priceAdjustments: this.formBuilder.array([]),
      basisAdjustment: [this.contract.basisAdjustment.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]],
      futuresPrice: [this.contract.futuresPrice.toFixed(this.getVariablePrecision('futuresPrice')), this.getPriceValidators()],
      freightPrice: [this.contract.freightPrice.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]],
      expirationDate: [this.contract.expirationDate ? moment(this.contract.expirationDate).toDate() : ''],
      comments: [this.contract.comments || '', [Validators.maxLength(800)]],
      instructions: [this.contract.instructions || '', [Validators.maxLength(500)]],
      originator: [this.getPartialContractOriginator(),
        [ Validators.required, CommonValidators.objectValidator ] ],
      quantity: [ this.contract.quantity, this.getQuantityValidators(this.pricedQuantity + this.workingQuantity) ]
    });
    this.populatePriceAdjustmentControls();
    this.prepForDeliveryLocationSelection();
    this.prepForOriginatorSelection();
    this.verifyMarketFuturesDataExists();
  }

  private prepFormForCompleteHTAContract() {
    this.contractForm = this.formBuilder.group({
      comments: [this.contract.comments || '', [Validators.maxLength(800)]],
      instructions: [this.contract.instructions || '', [Validators.maxLength(500)]],
      deliveryType: [this.contract.deliveryType, [Validators.required]],
      clientLocation: [this.clientLocation, [Validators.required, CommonValidators.objectValidator]],
      deliveryLocation: [this.deliveryLocation, [Validators.required, CommonValidators.objectValidator]],
      originator: [this.getPartialContractOriginator(),
      [Validators.required, CommonValidators.objectValidator]]
    });

    if (this.canEditContractExchange) {
      this.contractForm.addControl('contractExchangeId', new FormControl(this.contract.exchangeId, [Validators.required]));
    }

    this.prepForOriginatorSelection();
    this.prepForClientLocationSelection();
    this.prepForDeliveryLocationSelection();
  }

  private prepFormForPendingExchangeHTAContract() {
    if (this.creatingContractExchange && !this.contract.exchangeId) {
      this.contractForm = this.formBuilder.group({
        contractExchangeId: ['', [Validators.required]]
      });
    }
  }

  private prepFormForWorkingExchangeHTAContract() {
    this.contractForm = this.formBuilder.group({
      contractExchangeId: [this.contract.exchangeId, [Validators.required]]
    });
  }

  private prepFormForWorkingBasisContract() {
    this.contractForm = this.formBuilder.group({
      productionYear: [this.profile.productionYears[this.contract.productionYear], [Validators.required]],
      futuresYearMonth: [this.translateContractMonthToMoment(this.contract.futuresYearMonth), [Validators.required]],
      deliveryPeriod: [this.translateContractMonthToMoment(this.contract.deliveryPeriod), [Validators.required]],
      deliveryLocation: [this.deliveryLocation, [Validators.required, CommonValidators.objectValidator]],
      deliveryType: [this.contract.deliveryType, [Validators.required]],
      quantity: [this.contract.quantity, this.getQuantityValidators()],
      basisPrice: [this.contract.basisPrice.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]],
      basisAdjustment: [this.contract.basisAdjustment.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]],
      freightPrice: [this.contract.freightPrice.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]],
      priceAdjustments: this.formBuilder.array([]),
      expirationDate: [this.contract.expirationDate ? moment(this.contract.expirationDate).toDate() : ''],
      comments: [this.contract.comments || '', [Validators.maxLength(800)]],
      instructions: [this.contract.instructions || '', [Validators.maxLength(500)]],
      originator: [this.getPartialContractOriginator(),
      [Validators.required, CommonValidators.objectValidator]]
    });
    this.populatePriceAdjustmentControls();
    this.prepForDeliveryLocationSelection();
    this.prepForOriginatorSelection();
    this.verifyMarketFuturesDataExists();
  }

  private prepFormForLockedBasisContract() {
    this.contractForm = this.formBuilder.group({
      productionYear: [this.profile.productionYears[this.contract.productionYear], [Validators.required]],
      futuresYearMonth: [this.translateContractMonthToMoment(this.contract.futuresYearMonth), [Validators.required]],
      basisPrice: [this.contract.basisPrice.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]],
      basisAdjustment: [this.contract.basisAdjustment.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]],
      freightPrice: [this.contract.freightPrice.toFixed(4), [Validators.required, Validators.pattern(PRICE_REGEX)]],
      priceAdjustments: this.formBuilder.array([]),
      expirationDate: [this.contract.expirationDate ? moment(this.contract.expirationDate).toDate() : ''],
      comments: [this.contract.comments || '', [Validators.maxLength(800)]],
      instructions: [this.contract.instructions || '', [Validators.maxLength(500)]],
      originator: [ this.getPartialContractOriginator(), [ Validators.required, CommonValidators.objectValidator ] ],
      quantity: [ this.contract.quantity, this.getQuantityValidators(this.pricedQuantity + this.workingQuantity) ]
    });

    this.populatePriceAdjustmentControls();
    this.prepForOriginatorSelection();
    this.verifyMarketFuturesDataExists();
  }

  private prepFormForCompleteBasisContract() {
    this.contractForm = this.formBuilder.group({
      comments: [this.contract.comments || '', [Validators.maxLength(800)]],
      instructions: [this.contract.instructions || '', [Validators.maxLength(500)]],
      originator: [this.getPartialContractOriginator(), [Validators.required, CommonValidators.objectValidator]]
    });
    this.prepForOriginatorSelection();
  }

  private populatePriceAdjustmentControls() {
    if (this.contract.priceAdjustments) {
      const priceAdjustmentsArray = this.contractForm.get('priceAdjustments') as FormArray;
      this.contract.priceAdjustments.forEach((priceAdjustment: ContractPriceAdjustment) => {
        priceAdjustmentsArray.push(this.getPriceAdjustmentForm(priceAdjustment.id, priceAdjustment.value.toFixed(5)));
      });
    }
  }

  private populateCustomContractIdControl() {
    if (this.contract.side === Side.SELL && !this.contract.accountingSystemId) {
      this.contractForm.addControl('customContractId',
        new FormControl(this.contract.customContractId || '', [Validators.pattern(ALPHANUMERIC_REGEX), Validators.maxLength(30)]));
    }
  }

  private getQuantityValidators(minimumQuantity?: number) {
    // set quantity limit from user settings
    const quantityValidators = [ Validators.required, Validators.pattern(QUANTITY_REGEX) ];
    if (Number.isFinite(minimumQuantity)) {
      quantityValidators.push(Validators.min(minimumQuantity));
    }
    const profileContractLimit = this.userSettings.contractLimits.find(limit => limit.commodityProfileDocId === this.profile.docId);
    if (profileContractLimit) {
      quantityValidators.push(Validators.max(profileContractLimit.quantityLimit));
    }
    return quantityValidators;
  }

  private getPriceValidators() {
    // set min and max prices from commodity profile
    // TODO For now min/max prices are being applied to either futures and/or cash depending on contract type
    const pricingPattern = this.contract.hasRelatedHedge ? FIVE_DIGIT_REGEX : FUTURES_REGEX;
    return [Validators.required, Validators.pattern(pricingPattern),
    Validators.min(this.profile.minPrice), Validators.max(this.profile.maxPrice)];
  }

  private prepForOriginatorSelection() {
    let setInitialValue = false;
    this.filteredOriginators$ = this.contractForm.controls.originator.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | Originator>(''),
      switchMap(searchTerm => {
        return this.clientSelectorService.getSelectedClient().pipe(
          switchMap((selectedClient: Client) => {
            return this.userSettingsService.getHmsUserSettingsForClientUsers(selectedClient.docId);
          }),
          switchMap((hmsUserSettings: HMSUserSettings[]) => {
            return combineLatest(
              hmsUserSettings.map((setting: HMSUserSettings) => {
                const accountingSystemId = setting.accountingSystemId;
                return this.userService.getUserByDocId(setting.userDocId)
                  .pipe(
                    map((user: User) => {
                      return { ...user, accountingSystemId } as Originator;
                    })
                  );
              })
            ).pipe(
              map((originators: Originator[]) => {
                if (!searchTerm) {
                  searchTerm = this.contractForm.get('originator').value;
                }
                if (!setInitialValue) {
                  const contractOriginator = originators.find(originator =>
                    originator.accountingSystemId === this.contract.originatorAccountingSystemId);
                  if (contractOriginator) {
                    this.contractForm.get('originator').setValue(contractOriginator);
                    setInitialValue = true;
                  }
                }

                // Return all originators when an originator has already been selected to avoid the user needing to clear the field
                if (typeof searchTerm !== 'string') {
                  return originators;
                }
                const comparisonTerm = (searchTerm as string).toLowerCase();
                return originators.filter(
                  originator =>
                    (`${originator.firstName} ${originator.lastName}`).toLowerCase().includes(comparisonTerm) ||
                    originator.accountingSystemId.toLowerCase().includes(comparisonTerm)
                );
              }),
              shareReplay({ bufferSize: 1, refCount: true }),
              catchError(err => {
                this.errorMessage = 'Error retrieving originators; please try again later';
                console.error(`Error retrieving originators: ${err}`);
                return of([]);
              })
            );
          }),
        );
      }),
    );
  }

  // note: We currently allow up to 4-5 decimal places precision for all the prices. This function is only used for
  //       calculations and original data population. This should not be used for display formatter or validations.
  //       This handles old contracts that already has its price locked.
  private getVariablePrecision(fieldName: string): number {
    return ['cashPrice', 'futuresPrice'].includes(fieldName) && this.contract.hasRelatedHedge ? 5 : 4;
  }

  private handleInvalidFuturesMonth(futuresMonth: moment.Moment) {
    this.contractForm.get('futuresYearMonth').setValue('');
    this.openSnackBar(`${moment(futuresMonth).format('MMM yyyy')} is either expired or not yet active.`, 'DISMISS', false);
  }

  private verifyMarketFuturesDataExists() {
    // this is a pared-down version of NewContractComponent.getMarketFuturesPrice.
    // Unlike its counterpart, it doesn't actually set any fields/properties with the price,
    // it simply verifies that market data can be retrieved successfully for the selected month.
    const futuresMonth = this.contractForm.get('futuresYearMonth').value;
    if (futuresMonth) {
      this.isLoadingMarketData = true;
      const contractYearMonth = this.translateMomentToContractMonth(futuresMonth as moment.Moment);
      const commodityId = this.contract.commodityId;
      const docId = commodityId + contractYearMonth.substr(2) + contractYearMonth.substring(0, 2);
      this.subscriptions.push(this.marketDataService.getRealTimeMarketDataByDocId(docId, this.authService.accessToken).pipe(
        take(1),
        tap((marketData: MarketData) => {
          // TODO: Handle undefined expirationDate? OK for expiryMaxDate, not for check for invalid month
          this.expiryMaxDate = moment(marketData.expirationDate).endOf('day').toDate();
          if (!marketData || moment(marketData.expirationDate).startOf('day').isBefore(moment())) {
            this.handleInvalidFuturesMonth(futuresMonth);
          }
          this.isLoadingMarketData = false;
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        catchError(err => {
          this.isLoadingMarketData = false;
          if (err.status === 404) {
            // not found; this means the month has expired or is not yet active and is not valid
            this.handleInvalidFuturesMonth(futuresMonth);
          } else {
            // generic message for all other errors
            this.openSnackBar('Error retrieving market data for selected futures month', 'DISMISS', false);
          }
          console.error(`Error retrieving market data: ${err.message ? err.message : err}`);
          return of(undefined);
        })
      ).subscribe());
    }
  }

  private isContractEligibleToDelete(contract: Contract): boolean {
    const SEGMENT_PENDING_ORDER_STATUSES = [
      PricingSegmentStatus.PENDING_ORDER,
      PricingSegmentStatus.PENDING_ORDER_UPDATE,
      PricingSegmentStatus.PENDING_ORDER_RECREATE,
      PricingSegmentStatus.PENDING_ORDER_CANCEL
    ];

    // Note: OTC deletion is not yet supported
    if (contract.hasRelatedHedge && contract.relatedHedgeType === HedgeType.OTC) {
      return false;
    } else if (this.segments && this.segments.find((segment: PricingSegment) =>
      segment.hasRelatedHedge && segment.relatedHedgeType === HedgeType.OTC)) {
      return false;
      // Note: Basis contract with pending orders pricing deletion is not yet supported
    } else if (this.segments && this.segments.find((segment: PricingSegment) => SEGMENT_PENDING_ORDER_STATUSES.includes(segment.status))) {
      return false;
    }

    if (contract.relatedHedgeType === HedgeType.OPTION && !contract.hedgeDocId) {
      return false;
    } else if (this.segments && this.segments.find((segment: PricingSegment) =>
      segment.relatedHedgeType === HedgeType.OPTION && !segment.hedgeDocId)) {
      return false;
    }

    if (contract.type === ContractType.CASH && contract.status === ContractStatus.COMPLETE) {
      return true;
    } else if (contract.type === ContractType.BASIS
      && (contract.status === ContractStatus.COMPLETE
        || contract.status === ContractStatus.PENDING_FUTURES
        || contract.status === ContractStatus.WORKING_FUTURES)) {
      return true;
    } else if (contract.type === ContractType.HTA
      && (contract.status === ContractStatus.COMPLETE
        || contract.status === ContractStatus.PENDING_BASIS
        || contract.status === ContractStatus.WORKING_BASIS)) {
      return true;
    } else if (contract.type === ContractType.DP
      && (contract.status === ContractStatus.COMPLETE
        || contract.status === ContractStatus.PENDING_PRICE
        || contract.status === ContractStatus.WORKING_PRICE)) {
      return true;
    }
    return false;
  }
}
