import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

import * as moment from 'moment';

import { ProductionYear } from '@advance-trading/ops-data-lib';

import { ProductionYearFuturesMonthErrorMatcher, ProductionYearValidators, StartDateErrorMatcher } from './production-year.validator';
import { LedgerHelperService } from '../../service/ledger-helper.service';

const YEAR_FORMAT = 'YYYY';
const FULL_DATE_FORMAT = 'YYYY-MM-DD';
const MIN_DATE = moment('1970-01-01').toISOString();

@Component({
  selector: 'hms-production-year',
  templateUrl: './production-year.component.html',
  styleUrls: ['./production-year.component.scss'],
  providers: []
})
export class ProductionYearComponent implements OnChanges {

  @Input() productionYear: FormGroup;
  @Input() commodityId: string;
  @Input() futuresMonths: { [key: string]: number[] };
  @Input() lastAddedProductionYear: string;
  @Input() editMode = true;
  @Input() productionYears: FormGroup;
  @Input() createMode = true;
  @Input() addingProdYear = true;
  @Input() preservedProductionYears: string[] = [];
  @Input() ledgerEndOfDay: string;
  @Input() timezone: string;
  @Output() productionYearUpdated = new EventEmitter<FormGroup>();
  @Output() productionYearRemoved = new EventEmitter<boolean>();

  minYearDate = moment().startOf('year').add(-1, 'year');

  productionYearForm: FormGroup = this.formBuilder.group({
    year: ['', [Validators.required]],
    label: ['', [Validators.required, Validators.maxLength(12)]],
    startDate: ['', { updateOn: 'blur' }, [Validators.required]],
    endDate: ['', { updateOn: 'blur' }, [Validators.required]],
    targetFuturesYearMonth: ['', [Validators.required]],
    hedgeBuyFuturesYearMonth: ['', [Validators.required]],
    hedgeSellFuturesYearMonth: ['', [Validators.required]],
  });

  startDateErrorMatcher = new StartDateErrorMatcher();
  targetFuturesYearMonthErrorMatcher = new ProductionYearFuturesMonthErrorMatcher();
  hedgeBuyFuturesYearMonthErrorMatcher = new ProductionYearFuturesMonthErrorMatcher();
  hedgeSellFuturesYearMonthErrorMatcher = new ProductionYearFuturesMonthErrorMatcher();

  private validFuturesMonths: number[];

  validFuturesMonthFilter = (currentMoment: moment.Moment|null): boolean => {
    if (currentMoment) {
      const month = currentMoment.month();
      this.validFuturesMonths = this.futuresMonths[this.commodityId];
      // ensuring greater than current month since date value is set to 1st of month
      return this.validFuturesMonths.includes(month);
    } else {
      return false;
    }
  }

  get minDate() {
    return this.createMode ? moment().startOf('month') : null;
  }

  getMinStartDate = () => {
    if (this.hasProdYearValue) {
      const currYear = parseInt(moment(this.productionYearForm.get('year').value).format(YEAR_FORMAT), 10);
      const prevYear = (currYear - 1).toString();
      const prevProdYear = this.productionYears.get(prevYear);
      return prevProdYear ? moment(prevProdYear.get('endDate').value).add(1, 'days').toISOString() : MIN_DATE;
    }
    return MIN_DATE;
  }

  getMaxEndDate = () => {
    if (this.hasProdYearValue) {
      const currYear = parseInt(moment(this.productionYearForm.get('year').value).format(YEAR_FORMAT), 10);
      const precedingYear = (currYear + 1).toString();
      const precedingProdYear = this.productionYears.get(precedingYear);
      return precedingProdYear ? moment(precedingProdYear.get('startDate').value).subtract(1, 'days').toISOString() : undefined;
    }
    return undefined;
  }

  constructor(
    private formBuilder: FormBuilder,
    private ledgerHelperService: LedgerHelperService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['editMode']) {
      if (this.editMode) {
        this.productionYearForm.enable();
        if (!this.shouldExpand) {
          this.productionYearForm.get('startDate').disable();
          this.productionYearForm.get('endDate').disable();
          this.productionYearForm.get('targetFuturesYearMonth').disable();
          this.productionYearForm.get('hedgeBuyFuturesYearMonth').disable();
          this.productionYearForm.get('hedgeSellFuturesYearMonth').disable();
        }
        this.productionYearForm.get('year').disable();
      } else {
        this.productionYearForm.disable();
        this.setupProductionYearForm();
      }
    }

    // set up initial production year form according to passed property
    if (changes['productionYear'] || changes['lastAddedProductionYear'] || changes['productionYears']) {
      this.setupProductionYearForm();
    }

    if (changes['commodityId'] || changes['futuresMonths']) {
      this.validFuturesMonthFilter = (currentMoment: moment.Moment|null): boolean => {
        if  (currentMoment) {
          const month = currentMoment.month();
          this.validFuturesMonths = this.futuresMonths[this.commodityId];
          // ensuring greater than current month since date value is set to 1st of month
          return this.validFuturesMonths.includes(month);
        } else {
          return false;
        }
      };

    }
  }

  get shouldExpand() {
    if (this.createMode || !this.productionYear) {
      // automatically expand panel when creating new production year
      return true;
    } else {
      // ledger end of day on the calendar date specified as the end date of the production year
      const endDateMoment = this.ledgerHelperService.endOfBusinessDay(
        moment.tz(this.productionYear.get('endDate').value, this.timezone)
        , this.ledgerEndOfDay
        , this.timezone);
      // ledger end of day on the current business day (if it's after ledger end of day for this calendar day, it's
      // treated as the next business day)
      const currentBusinessDayMoment = moment.tz(
        this.ledgerHelperService.getCurrentBusinessDay(this.ledgerEndOfDay, this.timezone)
        , this.timezone);
      // if the production year hasn't ended yet, expand the panel. If it has, panel should default to collapsed
      return endDateMoment.isSameOrAfter(currentBusinessDayMoment);
    }
  }

  panelTitle() {
    if (!this.productionYear) {
      return 'New Production Year';
    }
    const label = `${this.productionYearForm.get('label').value.charAt(0)}${this.productionYearForm.get('label').value.substr(1).toLowerCase().replace('_plus_', '+')}`;
    if (this.shouldExpand) {
      return label;
    } else {
      return `${label} (${moment(this.productionYearForm.get('year').value).format(YEAR_FORMAT)})`;
    }
  }

  onProductionYearUpdated() {
    this.productionYearUpdated.emit(this.productionYearForm);
  }

  onProductionYearRemoved() {
    this.productionYearRemoved.emit(true);
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('matDatepickerFilter')) {
      return 'Value invalid';
    } else if (control.hasError('matDatepickerMin')) {
      const formControlName = Object.keys(control.parent.controls).find(controlName => control.parent.controls[controlName] === control);
      if (formControlName.indexOf('end') > -1) {
        return 'End date must follow start date';
      } else {
        return 'Must follow end of previous crop year';
      }
    } else if (control.hasError('matDatepickerMax')) {
      return 'Value invalid';
    } else if (control.hasError('maxlength')) {
      return 'Value cannot exceed ' + control.errors['maxlength'].requiredLength + ' characters';
    } else if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (this.productionYearForm.hasError('startDateGap')) {
      return `Date gap from ${this.getYearString()} end date`;
    }
    return 'Unknown error';
  }

  selectMonthOrYear(e: moment.Moment, controlName: string, picker: MatDatepicker<moment.Moment>) {
    const control = this.productionYearForm.get(controlName);
    control.setValue(e);
    control.markAsTouched();
    control.markAsDirty();
    picker.close();
  }

  private get hasProdYearValue() {
    return this.productionYears && Object.keys(this.productionYears.controls).length > 0 && this.productionYearForm.get('year').value;
  }

  // function to get previous or preceding year string
  private getYearString(prev: boolean = true) {
    const currYear = parseInt(this.productionYearForm.get('year').value.format(YEAR_FORMAT), 10);
    if (prev) {
      return (currYear - 1).toString();
    }
    return (currYear + 1).toString();
  }

  resetStartDateValidator() {
    this.productionYearForm.setValidators(ProductionYearValidators.startDateMin(this.getMinStartDate()));
  }

  get needsDeleteButton() {
    // only show delete if this is new production year or last production year and no new production year is being added
    return !this.productionYear
      || (this.productionYear === this.productionYears.get(this.lastAddedProductionYear)
        && !this.addingProdYear && this.editMode
        && !this.preservedProductionYears.includes(moment(this.productionYear.get('year').value).format(YEAR_FORMAT)));
  }

  private setupProductionYearForm() {
    if (this.productionYear) {
      // pre-populate form field if there is an existing production year set into the component
      const year: moment.Moment = this.productionYear.get('year').value as moment.Moment;
      const label = this.productionYear.get('label').value;
      const startDate = this.productionYear.get('startDate').value;
      const endDate = this.productionYear.get('endDate').value;
      const targetFuturesYearMonth: moment.Moment = this.productionYear.get('targetFuturesYearMonth').value as moment.Moment;
      const hedgeBuyFuturesYearMonth: moment.Moment = this.productionYear.get('hedgeBuyFuturesYearMonth').value as moment.Moment;
      const hedgeSellFuturesYearMonth: moment.Moment = this.productionYear.get('hedgeSellFuturesYearMonth').value as moment.Moment;

      this.productionYearForm.get('year').setValue(moment(year, YEAR_FORMAT));
      this.productionYearForm.get('year').disable();
      this.productionYearForm.get('label').setValue(label);
      this.productionYearForm.get('startDate').setValue(startDate);
      this.productionYearForm.get('endDate').setValue(endDate);
      this.productionYearForm.get('targetFuturesYearMonth').setValue(targetFuturesYearMonth);
      this.productionYearForm.get('hedgeBuyFuturesYearMonth').setValue(hedgeBuyFuturesYearMonth);
      this.productionYearForm.get('hedgeSellFuturesYearMonth').setValue(hedgeSellFuturesYearMonth);
    } else {
      const prevProdYear = this.lastAddedProductionYear ? this.productionYears.get(this.lastAddedProductionYear) : undefined;
      if (prevProdYear) {
        // pre-populate start date right after the end date of the previously added production year
        const lastAddedProductionYear: number = parseInt(this.lastAddedProductionYear, 10);
        const prepopulatedStartDate = moment(prevProdYear.get('endDate').value, FULL_DATE_FORMAT).add(1, 'days');
        const prepopulatedEndDate = moment(prevProdYear.get('endDate').value, FULL_DATE_FORMAT).add(1, 'year');
        this.productionYearForm.get('startDate').setValue(prepopulatedStartDate.format());
        this.productionYearForm.get('endDate').setValue(prepopulatedEndDate.format());
        this.productionYearForm.get('year').setValue(moment(lastAddedProductionYear + 1, YEAR_FORMAT));
        this.productionYearForm.get('year').disable();
        const prodYears = Object.keys(ProductionYear);
        this.productionYearForm.get('label').setValue(ProductionYear[prodYears[prodYears.indexOf(prevProdYear.get('label').value) + 1]]);
      } else {
        this.productionYearForm.get('year').enable();
        this.productionYearForm.get('label').setValue(ProductionYear.OLD);
      }
    }

    // Set up production year form validation
    this.resetStartDateValidator();
  }

}
