import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { Client, HMSDailyLedger } from '@advance-trading/ops-data-lib';

@Injectable({
  providedIn: 'root'
})
export class HMSDailyLedgerService {

  constructor(
    private db: AngularFirestore
  ) { }

  /**
   * Return hms daily ledgers for the specified Client, commodity profile, and business date
   * @param clientDocId The docId of the Client containing the HMSDailyLedgers
   * @param commodityProfileDocId The docId of the CommodityProfile of HMSDailyLedgers being returned
   * @param businessDate The businessDate of HMSDailyLedgers being returned
   */
  getAllHMSDailyLedgersByCommodityProfileAndBusinessDate(
    clientDocId: string, commodityProfileDocId: string, businessDate: string): Observable<HMSDailyLedger[]> {

    return this.db.collection<HMSDailyLedger>(`${Client.getDataPath(clientDocId)}/${HMSDailyLedger.getDataPath()}`,
      ref => ref.where('commodityProfileDocId', '==', commodityProfileDocId)
        .where('businessDate', '==', businessDate))
      .valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Return hms daily ledgers for the specified Client, commodity profile, production year, and business date
   * @param clientDocId The docId of the Client containing the HMSDailyLedgers
   * @param commodityProfileDocId The docId of the CommodityProfile of HMSDailyLedgers being returned
   * @param productionYear The productionYear of HMSDailyLedgers being returned
   * @param businessDate The businessDate of HMSDailyLedgers being returned
   */
  getHMSDailyLedgerByCommodityProfileAndProductionYear(
    clientDocId: string, commodityProfileDocId: string, productionYear: string, businessDate: string): Observable<HMSDailyLedger[]> {

    return this.db.collection<HMSDailyLedger>(`${Client.getDataPath(clientDocId)}/${HMSDailyLedger.getDataPath()}`,
      ref => ref.where('commodityProfileDocId', '==', commodityProfileDocId)
      .where('productionYear', '==', productionYear)
      .where('businessDate', '==', businessDate))
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  getHMSDailyLedgerByCommodityProfileAndProductionYearAndDate(
    clientDocId: string, commodityProfileDocId: string, productionYear: string, businessDate: string): Observable<HMSDailyLedger> {

    return this.db.collection<HMSDailyLedger>(`${Client.getDataPath(clientDocId)}/${HMSDailyLedger.getDataPath()}`,
      ref => ref.where('commodityProfileDocId', '==', commodityProfileDocId)
      .where('productionYear', '==', productionYear)
      .where('businessDate', '==', businessDate))
      .get().pipe(
        map(querySnapshot => {
          if (querySnapshot.empty) {
            throw Error('No HMSDailyLedger document found');
          } else if (querySnapshot.docs.length === 1) {
            return querySnapshot.docs[0].data() as HMSDailyLedger;
          } else {
            // more than one ledger doc found
            throw Error('More than one HMSDailyLedger document found');
          }
        }),
        shareReplay({bufferSize: 1, refCount: true})
      );
  }

}
