<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

      <hms-error-message [title]="'Error Retrieving Locations List'" [message]="errorMessage"></hms-error-message>

      <div *ngIf="!errorMessage" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h2 data-cy="page-title-locations" class="card-title">Locations</h2>
          <div *ngIf="isLoading" fxFlex="100" fxLayoutAlign="end center">
            <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
          </div>
          <button type="button" *ngIf="!isLoading && isLocationAdmin" id="add-new-location" class="action-button" aria-label="Add New Location" (click)="addLocation()" mat-mini-fab color="accent" matTooltip="Add New Location">
            <mat-icon>add</mat-icon>
          </button>
        </div>

        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="locations-filter-field" #filter>
          <button type="button" id="clear-locations-filter" mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>

        <mat-table [dataSource]="dataSource" matSort class="mat-elevation-z0">
          <ng-container matColumnDef="accountingSystemId">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="20">ID</mat-header-cell>
            <mat-cell *matCellDef="let location" fxFlex="20">{{ location.accountingSystemId }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="35">Name</mat-header-cell>
            <mat-cell *matCellDef="let location" fxFlex="35">{{ location.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="isClientLocation">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="20">Client Location</mat-header-cell>
            <mat-cell *matCellDef="let location" fxFlex="20">{{ location.isClientLocation | yesNo }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="isActive">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="15">Active</mat-header-cell>
            <mat-cell *matCellDef="let location" fxFlex="15">{{ location.isActive | yesNo }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="bidsIcon">
            <mat-header-cell *matHeaderCellDef fxFlex="10">Bids</mat-header-cell>
            <mat-cell *matCellDef="let bids" fxFlex="10">
              <div *ngIf="bids.isActive" (click)="$event.stopPropagation()">
                <mat-icon id="locationBidsIcon" (click)="selectBidsIcon(bids)" color="primary" fontSet="material-icons-outlined" matTooltip="Location Bids">local_atm</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
          <mat-row (click)="selectLocation(location)" *matRowDef="let location; columns: columnsToDisplay"></mat-row>
        </mat-table>
        <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50]"></mat-paginator>

      </div>
    </mat-card>
  </div>
