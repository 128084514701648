import { Injectable } from '@angular/core';
import {
  CommodityProfile,
  CommodityProfileProductionYear,
  Contract,
  ContractStatus,
  Hedge,
  HedgeStatus,
  LedgerAdjustment,
  ProductionYear
} from '@advance-trading/ops-data-lib';
import * as moment from 'moment';
import { LedgerDates } from '../utilities/ledger-dates';

const MILITARY_FORMAT = 'HH:mm';
const FULL_DATE_FORMAT = 'YYYY-MM-DD';

@Injectable({
  providedIn: 'root'
})
export class LedgerHelperService {

  /**
   * Convert string representation of date to Full Date format for use in queryParams
   *
   * @param dateString Time in ISO format
   */
  convertISOStringToFullDate(dateString: string): string {
    return moment(dateString).format(FULL_DATE_FORMAT);
  }

  /**
   * Convert date to moment
   *
   * @param date JavaScript date object to be converted
   */
  convertDateToMoment(date: Date): moment.Moment {
    return moment(date);
  }

  /**
   * Determine the end of the current business day (1 millisecond before the ledgerEndOfDay) as an ISO string
   *
   * @param ledgerEndOfDay Time specifying when the ledgers are reset, Format: HH:mm (24 hour format)
   * @param timezone the moment.tz timezone name for which the time is being set
   */
  getCurrentBusinessDay(ledgerEndOfDay: string, timezone: string): string {
    const now = moment();
    const todaysLedgerEndOfDay = this.endOfBusinessDay(now, ledgerEndOfDay, timezone);
    return this.forceLedgerEndOfDay(now.isBefore(todaysLedgerEndOfDay) ?
      now :
      now.add(1, 'days'), ledgerEndOfDay, timezone);
  }

  /**
   * Get the HH:mm version of the client's ledgerEndOfDay from a moment.Moment
   *
   * @param ledgerMoment A moment.Moment set with client's ledgerEndOfDay (from clientSettingsService)
   */
  getLedgerEndOfDayFromMoment(ledgerMoment: moment.Moment): string {
    return ledgerMoment.format(MILITARY_FORMAT);
  }

  /**
   * Determine the end of the provided business day (1 millisecond before the ledgerEndOfDay) as a moment
   *
   * @param day moment representing the day for which you wish to determine the end of day
   * @param ledgerEndOfDay Time specifying when the ledgers are reset, Format: HH:mm (24 hour format)
   * @param timezone the moment.tz timezone name for which the time is being set
   */
  endOfBusinessDay(day: moment.Moment, ledgerEndOfDay: string, timezone: string): moment.Moment {
    const timeModel = moment.tz(ledgerEndOfDay, MILITARY_FORMAT, timezone);
    // set second and millisecond to avoid ExpressionChanged error on this.currentBusinessDay
    const endOfBusinessDayMoment = moment.tz(day, timezone)
      .set('hour', timeModel.hour())
      .set('minute', timeModel.minute())
      .set('second', 0)
      .set('millisecond', 0)
      .subtract(1, 'millisecond');
    if (endOfBusinessDayMoment.isBefore(day)) {
      endOfBusinessDayMoment.add(1, 'day');
    }
    return endOfBusinessDayMoment;
  }

  /**
   * Determine the end of the provided business day (1 millisecond before the ledgerEndOfDay) as an ISO string
   *
   * @param day moment representing the day for which you wish to determine the end of day
   * @param ledgerEndOfDay Time specifying when the ledgers are reset, Format: HH:mm (24 hour format)
   * @param timezone the moment.tz timezone name for which the time is being set
   */
  forceLedgerEndOfDay(day: moment.Moment, ledgerEndOfDay: string, timezone: string): string {
    return this.endOfBusinessDay(day, ledgerEndOfDay, timezone).toISOString();
  }

  /**
   * Determine the end of the provided business day (1 millisecond before the ledgerEndOfDay) as an ISO string
   *
   * @param ledgerDate string representing the day for which you wish to determine the end of day Format: YYYY-MM-DD
   * @param ledgerEndOfDay Time specifying when the ledgers are reset, Format: HH:mm (24 hour format)
   * @param timezone the moment.tz timezone name for which the time is being set
   */
  forceLedgerEndOfDayFromFullDateFormat(ledgerDate: string, ledgerEndOfDay: string, timezone: string): string {
    return this.endOfBusinessDay(moment(ledgerDate, FULL_DATE_FORMAT), ledgerEndOfDay, timezone).toISOString();
  }

  /**
   * Get the date parameters used for ledger queries based on a provided effective date
   *
   * @param reportDate date in ISO string representing the day from which you wish to derive the other parameters.
   * Assumes 1 millisecond prior to end of current business day.
   */
  getDateParameters(reportDate: string): LedgerDates {
    const endDateMoment = moment(reportDate);
    const startDateMoment = moment(reportDate).add(1, 'millisecond').subtract(1, 'day');
    return {
      endDate: endDateMoment.toISOString(),
      startDate: startDateMoment.toISOString()
    } as LedgerDates;
  }

  /**
   * Get all production years from a given map that were active on the provided date
   *
   * @param reportDate Date in ISO string from which active prod years are meant to be calculated
   * @param productionYears Map of CommodityProfileProductionYears
   * @param ledgerEndOfDay Time specifying when the ledgers are reset, Format: HH:mm (24 hour format)
   * @param timezone the moment.tz timezone name for which the time is being set
   */
  getActiveProdYears(
    reportDate: string,
    productionYears: { [key: string]: CommodityProfileProductionYear },
    ledgerEndOfDay: string,
    timezone: string
  ): CommodityProfileProductionYear[] {
    const reportDateMoment = moment(reportDate);
    return Object.values(this.getTranslatedProductionYears(reportDate, productionYears, ledgerEndOfDay, timezone))
      .filter(productionYear =>
        this.endOfBusinessDay(moment(productionYear.endDate, FULL_DATE_FORMAT), ledgerEndOfDay, timezone).isSameOrAfter(reportDateMoment));
  }

  /**
   * Get all production years from a given map that are active on the current date. Filters out multiple valid OLD production years
   * on days of prodYear rolls if we rely on button roll and not scheduled rolls and translates for current ledger day
   *
   * @param productionYears Map of CommodityProfileProductionYears
   * @param ledgerEndOfDay Time specifying when the ledgers are reset, Format: HH:mm (24 hour format)
   * @param timezone the moment.tz timezone name for which the time is being set
   */
  getTranslatedUniqueActiveProdYears(
    productionYears: { [key: string]: CommodityProfileProductionYear },
    ledgerEndOfDay: string,
    timezone: string
  ): { [key: string]: CommodityProfileProductionYear } {
    const currentDate = this.getCurrentBusinessDay(ledgerEndOfDay, timezone);
    const currentDateMoment = moment.tz(currentDate, timezone);
    const prodYearMap = {};
    const allActiveProdYears =  Object.values(this.getTranslatedProductionYears(currentDate, productionYears, ledgerEndOfDay, timezone))
      .filter(productionYear =>
        this.endOfBusinessDay(moment(productionYear.endDate, FULL_DATE_FORMAT), ledgerEndOfDay, timezone).isSameOrAfter(currentDateMoment));
    allActiveProdYears.map(prodYear => prodYearMap[prodYear.year] = prodYear);
    return prodYearMap;
  }

  /**
   * Get production years from a given map that were active on the provided date with adjusted labels
   *
   * @param reportDate Date in ISO string from which active prod years are meant to be calculated
   * @param productionYears Map of CommodityProfileProductionYears
   * @param timezone the moment.tz timezone name for which the time is being set
   */
  getTranslatedProductionYears(
    reportDate: string,
    productionYears: { [key: string]: CommodityProfileProductionYear },
    ledgerEndOfDay: string,
    timezone: string
  ): { [key: string]: CommodityProfileProductionYear } {
    const reportDateMoment = this.endOfBusinessDay(moment(reportDate), ledgerEndOfDay, timezone);
    const prodYearOldBeginsAfterReportDate = Object.values(productionYears)
      .filter(prodYear => prodYear.label === ProductionYear.OLD &&
        this.endOfBusinessDay(moment(prodYear.startDate, FULL_DATE_FORMAT), ledgerEndOfDay, timezone).isAfter(reportDateMoment)).length > 0;
    const prodYearOnReportDate = Object.values(productionYears)
      .find(prodYear =>
        this.endOfBusinessDay(moment(prodYear.startDate, FULL_DATE_FORMAT), ledgerEndOfDay, timezone).isSameOrBefore(reportDateMoment)
        && this.endOfBusinessDay(moment(prodYear.endDate, FULL_DATE_FORMAT), ledgerEndOfDay, timezone).isSameOrAfter(reportDateMoment));
    const labelArray = Object.values(ProductionYear);
    const prodYears: { [key: string]: CommodityProfileProductionYear } = {};
    Object.values(productionYears).forEach(prodYear => {
      // Cloning prodYear to prevent unintentional modification of incoming productionYears
      const clonedProdYear = { ...prodYear };
      const offset = labelArray.indexOf(prodYearOnReportDate.label);
      // shift backward if no OLD on date passed in
      if (prodYearOnReportDate && prodYearOnReportDate.label !== ProductionYear.OLD) {
      // IF THERE IS NO OLD IN ACTIVE PROD YEARS - OLD naturally expired, no one rolled prod years so labels are [NEW, ...]
        // find current position of label in array (0-4), apply offset (0-4)
        let labelIndex = labelArray.indexOf(clonedProdYear.label) - offset;
        // take item from array, can't go below zero so set that as floor
        if (labelIndex < 0) {
          labelIndex = 0;
        }
        clonedProdYear.label = labelArray[labelIndex];
        prodYears[clonedProdYear.year] = clonedProdYear;
      } else if (moment(clonedProdYear.startDate).isAfter(reportDateMoment) && prodYearOldBeginsAfterReportDate) {
        // IF THERE IS MORE THAN ONE OLD IN ACTIVE PROD YEARS - Date in past, prod year on report date has expired. Set including report
        // date is [OLD, OLD ...]
        // Adjust labels forward if there are prod years after the one containing the report date that are labeled ProductionYear.OLD
        // Remove previously used ProductionYear from array.
        // This is done before adjusting the label as ProductionYear.OLD has been used for the reportDate,
        // The first year that starts after reportDate should be ProductionYear.NEW and so forth.
        labelArray.shift();
        // If labels are still available, subsequent years will be adjusted to display the next available ProductionYear
        // If no labels remain, the production year will not be added; this eliminates the problem of multiple ProductionYear.NEW_PLUS_2
        // That condition should not exist, as at any time, there can only be four active production years
        if (labelArray.length) {
          clonedProdYear.label = labelArray[0];
          prodYears[clonedProdYear.year] = clonedProdYear;
        }
      } else {
        // IF THERE IS EXACTLY ONE OLD IN ACTIVE PROD YEARS - Normal expected state. Prod years are [OLD, NEW, ...]
        prodYears[clonedProdYear.year] = clonedProdYear;
      }
    });
    return prodYears;
  }

  /**
   * Determine what production year label should have applied to a contract at the time it reached its terminal stage
   *
   * @param contract Contract to be analyzed
   * @param profile CommodityProfile under which the contract was created
   * @param ledgerEndOfDay Time specifying when the ledgers are reset, Format: HH:mm (24 hour format)
   * @param timezone the moment.tz timezone name for which the time is being set
   */
  getProdYearLabelForContract(contract: Contract, profile: CommodityProfile, ledgerEndOfDay: string, timezone: string): string {
    // intialize array with current moment, which should be used if none of the other three exist
    let timestamps = [moment().toISOString()];
    if (contract.completionTimestamp) {
      timestamps.push(contract.completionTimestamp);
    }
    if (contract.cancellationTimestamp !== '') {
      timestamps.push(contract.cancellationTimestamp);
    }
    if (contract.deletionTimestamp !== '') {
      timestamps.push(contract.deletionTimestamp);
    }
    // For DENIED contracts, ignore all other timestamps in favor of lastUpdated
    if (contract.status === ContractStatus.DENIED) {
      timestamps = [contract.lastUpdatedTimestamp];
    }
    // sort alphabetically which for ISO timestamps is also chronological, return first element which should be oldest
    const oldestTimestamp = timestamps.sort((a, b) => a < b ? -1 : 1)[0];
    return this.getProdYearLabelForEffectiveTimestamp(contract.productionYear, oldestTimestamp, profile, ledgerEndOfDay, timezone);
  }

  /**
   * Determine what production year label should have applied to a ledger adjustment at the time it was created
   *
   * @param ledgerAdjustment LedgerAdjustment to be analyzed
   * @param profile CommodityProfile under which the ledger adjustment was created
   * @param ledgerEndOfDay Time specifying when the ledgers are reset, Format: HH:mm (24 hour format)
   * @param timezone the moment.tz timezone name for which the time is being set
   */
  getProdYearLabelForLedgerAdjustment(
    ledgerAdjustment: LedgerAdjustment, profile: CommodityProfile, ledgerEndOfDay: string, timezone: string): string {
    const effectiveTimestamp = ledgerAdjustment.creationTimestamp;
    return this.getProdYearLabelForEffectiveTimestamp(
      ledgerAdjustment.productionYear, effectiveTimestamp, profile, ledgerEndOfDay, timezone);
  }


  /**
   * Determine what production year label should have applied to a hedge at the time it was created or deleted
   *
   * @param hedge Hedge to be analyzed
   * @param profile CommodityProfile under which the hedge was created
   * @param ledgerEndOfDay Time specifying when the ledgers are reset, Format: HH:mm (24 hour format)
   * @param timezone the moment.tz timezone name for which the time is being set
   */
  getProdYearLabelForHedge(hedge: Hedge, profile: CommodityProfile, ledgerEndOfDay: string, timezone: string): string {
    // Only two possible statuses on hedge; utilize deletion if DELETED, otherwise use creation
    const effectiveTimestamp = hedge.status === HedgeStatus.DELETED ? hedge.deletionTimestamp : hedge.creationTimestamp;
    return this.getProdYearLabelForEffectiveTimestamp(hedge.productionYear, effectiveTimestamp, profile, ledgerEndOfDay, timezone);
  }

  /**
   * Determine what production year label should have applied to a hedge at the time it reached its terminal stage
   *
   * @param prodYear Four-character numeric year stored on the object
   * @param effectiveTimestamp Timestamp in ISO format at which the object (Contract, Hedge, LedgerAdjustment) reached its terminal stage
   * @param profile CommodityProfile under which the object was created
   * @param ledgerEndOfDay Time specifying when the ledgers are reset, Format: HH:mm (24 hour format)
   * @param timezone the moment.tz timezone name for which the time is being set
   */
  private getProdYearLabelForEffectiveTimestamp(
    prodYear: string, effectiveTimestamp: string, profile: CommodityProfile, ledgerEndOfDay: string, timezone: string): string {
    const prodYears = this.getTranslatedProductionYears(effectiveTimestamp, profile.productionYears, ledgerEndOfDay, timezone);
    return prodYears[prodYear].label;
  }

}
