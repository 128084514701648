import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { AppAlert } from '@advance-trading/ops-data-lib';

import { AppAlertService } from '../../service/app-alert.service';

@Component({
  selector: 'hms-alert-detail',
  templateUrl: './alert-detail.component.html',
  styleUrls: ['./alert-detail.component.scss']
})
export class AlertDetailComponent implements OnInit {
  isLoading = false;
  errorMessage: string;

  alert$: Observable<AppAlert>;

  alertTitle: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private alertService: AppAlertService,
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.alert$ = this.activatedRoute.paramMap.pipe(
      switchMap((paramMap: ParamMap) => {
        return this.alertService.getAppAlertByDocId(paramMap.get('docId'));
      }),
      tap((alert: AppAlert) => {
        this.alertTitle = alert.title;
        this.isLoading = false;
      }),
      catchError(err => {
        this.errorMessage = 'Error retrieving alert data; please try again later';
        console.error(`Error retrieving alert data: ${err}`);
        return of(undefined);
      })
    );
  }

  getTargetFromActionUri(actionUri: string) {
    const indexToRemove = actionUri.indexOf('&alertId') !== -1 ? actionUri.indexOf('&alertId') : actionUri.indexOf('?alertId');
    return actionUri.slice(0, indexToRemove);
  }
}
