<div fxLayout="row" fxLayoutAlign="space-around top" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="60">
    <h2>User Registration</h2>
    <mat-stepper labelPosition="bottom" [linear]="isLinear" #stepper>
      <mat-step class="register-step" [stepControl]="personalInfoForm" [state]="step1State" [editable]="step1Editable" label="Personal Info">
        <form [formGroup]="personalInfoForm" (ngSubmit)="savePersonalInfo()" fxLayout="column">
          <mat-form-field>
            <input required matInput placeholder="First Name" formControlName="firstName" trim="blur">
            <mat-error *ngIf="personalInfoForm.get('firstName').invalid">{{getErrorMessage(personalInfoForm.get('firstName'))}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input required matInput placeholder="Last Name" formControlName="lastName" trim="blur">
            <mat-error *ngIf="personalInfoForm.get('lastName').invalid">{{getErrorMessage(personalInfoForm.get('lastName'))}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <ngx-mat-intl-tel-input required [preferredCountries]="['us']" [enablePlaceholder]="true" [enableSearch]="false" name="mobilePhone" formControlName="mobilePhone" #mobilePhone></ngx-mat-intl-tel-input>
            <mat-label>Mobile Phone</mat-label>
            <mat-error *ngIf="personalInfoForm.get('mobilePhone').invalid">{{getErrorMessage(personalInfoForm.get('mobilePhone'))}}</mat-error>
          </mat-form-field>
          <mat-spinner class="update-spinner" *ngIf="regTypeInProgress" diameter="40" color="accent"></mat-spinner>
          <button id="registration-next" class="register-button" aria-label="Next" *ngIf="!regTypeInProgress" mat-raised-button color="accent" type="submit" [disabled]="!personalInfoForm.valid">Next</button>
        </form>
      </mat-step>
      <mat-step [stepControl]="accountInfoForm" [state]="step2State" [editable]="step2Editable" label="Account Info">
        <form [formGroup]="accountInfoForm" (ngSubmit)="saveAccountInfo()" fxLayout="column">
          <mat-form-field>
            <input required matInput placeholder="Primary Account Number" formControlName="number" trim="blur">
            <mat-error *ngIf="accountInfoForm.get('number').invalid">{{getErrorMessage(accountInfoForm.get('number'))}}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input required matInput placeholder="Broker Name" formControlName="contactName" trim="blur">
            <mat-error *ngIf="accountInfoForm.get('contactName').invalid">{{getErrorMessage(accountInfoForm.get('contactName'))}}</mat-error>
          </mat-form-field>
          <div fxLayout="row">
            <button type="button" id="registration-back" class="register-button" aria-label="Back" matStepperPrevious mat-button>Back</button>
            <button id="submit-registration" aria-label="Submit Registration" class="register-button" mat-raised-button color="accent" type="submit" [disabled]="!accountInfoForm.valid">Submit</button>
          </div>
        </form>
      </mat-step>
      <mat-step [state]="step3State" editable="false" label="Done">
        <ng-container *ngIf="!userStatus || userStatus === 'PENDING'">
          <h3>Your Account is Pending Approval</h3>
          <p>Thank you for submitting your information!  The administrators are finishing the final setup needed for your account.  You will receive an email at <b>{{authService.userProfile?.email}}</b> when your registration is approved.</p>
          <p>Please <a id="register-logout" (click)="authService.logout()">logout</a> and log back in once you are activated.</p>
        </ng-container>
        <ng-container *ngIf="userStatus && userStatus === 'ACTIVE'">
          <h3>Your Account is Active</h3>
          <p>The administrators have already activated your account.</p>
          <p>Please <a id="register-logout" (click)="authService.logout()">logout</a> and log back in to begin using HMS.</p>
        </ng-container>
        <ng-container *ngIf="userStatus && (userStatus === 'DENIED' || userStatus === 'INACTIVE')">
          <h3>Your Account is Inactive or Denied</h3>
          <p>The administrators have inactivated or denied your account.</p>
        </ng-container>
        <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a> or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
        <a href="https://www.advance-trading.com/">www.advance-trading.com</a>
      </mat-step>
    </mat-stepper>
  </mat-card>
</div>
