import { AfterViewInit, Component, ChangeDetectorRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommonValidators } from '@advance-trading/angular-common-services';
import { Client, Contract, ContractMonth, ContractStatus, ContractType, HMSUserSettings, Location, Patron, User } from '@advance-trading/ops-data-lib';
import { LocationService, PatronService, UserService } from '@advance-trading/angular-ops-data';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ContractService } from '../../service/contract.service';
import { UserSettingsService } from '../../service/user-settings.service';
import { UserRoles } from '../../utilities/user-roles';
import { Params, ActivatedRoute, Router } from '@angular/router';

import { Originator } from '../../utilities/originator';

const DEFAULT_START_DATE = new Date('1/1/2000').toISOString();
const DEFAULT_END_DATE = new Date('12/31/2099').toISOString();

const YEAR_FORMAT = 'YY';

@Component({
  selector: 'hms-contract-search',
  templateUrl: './contract-search.component.html',
  styleUrls: ['./contract-search.component.scss']
})
export class ContractSearchComponent implements OnInit, AfterViewInit {
  contractSearchForm: FormGroup = this.formBuilder.group({
    contractId: [''],
    exchangeId: [''],
    type: [''],
    startDate: ['', { updateOn: 'blur' }],
    endDate: ['', { updateOn: 'blur' }],
    deliveryMonth: ['', { updateOn: 'blur' }],
    futuresMonth: ['', { updateOn: 'blur' }],
    location: ['', [CommonValidators.objectValidator]],
    originator: ['', [CommonValidators.objectValidator]],
    patron: ['', [CommonValidators.objectValidator]],
    statuses: [[]]
  });

  contractTypes = Object.keys(ContractType);

  showContracts = false;
  selectedContracts$: Observable<Contract[]>;
  tableState: { [key: string]: string | number } = {};

  filteredLocations$: Observable<Location[]>;
  filteredOriginators$: Observable<Originator[]>;
  filteredPatrons$: Observable<Patron[]>;

  errorMessage: string;
  isLoading = true;
  isSearching = false;
  availableStatuses = Object.values(ContractStatus);

  private queryParams: Params;
  private selectedStatuses: ContractStatus[] = [];
  private searchLevel: string;
  private contractMonths = Object.keys(ContractMonth);

  @ViewChildren('statusBoxes') statusBoxes: QueryList<MatCheckbox>;
  @ViewChild('deliveryMonthPicker', { static: false }) deliveryMonthRef;
  @ViewChild('futuresMonthPicker', { static: false }) futuresMonthRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private contractService: ContractService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private patronService: PatronService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private userSettingsService: UserSettingsService
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.CONTRACT_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to search contracts.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.prepForLocationSelection();
    this.prepForOriginatorSelection();
    this.prepForPatronSelection();
  }

  ngAfterViewInit() {
    this.contractSearchForm.valueChanges.subscribe(() => {
      // Ensure contract/order ID is not combined with other search criteria
      const contractId = this.contractSearchForm.get('contractId').value;
      const exchangeId = this.contractSearchForm.get('exchangeId').value;

      const formControlKeys: string[] = Object.keys(this.contractSearchForm.controls);
      const foundCtrlValue = formControlKeys.find(key => {
        const ctrlValue = this.contractSearchForm.get(key).value;
        if (Array.isArray(ctrlValue) && ctrlValue.length > 0) {
          // Contract status checkboxes are passed by an array value, requiring this check
          return true;
        } else if (!Array.isArray(ctrlValue) && this.contractSearchForm.get(key).value) {
          return true;
        }
        return false;
      })

      const formHasValues = foundCtrlValue !== undefined;
      const hasValueOtherThanContractId = formHasValues && !contractId;
      const hasValueOtherThanOrderId = formHasValues && !exchangeId;

      if (contractId && this.contractSearchForm.get('type').enabled) {
        // Disable all other fields when contract ID is entered
        formControlKeys.forEach(key => {
          if (key !== 'contractId') {
            this.contractSearchForm.get(key).disable({ emitEvent: false });
          }
        })
        this.statusBoxes.forEach((statusBox: MatCheckbox) => statusBox.disabled = true);
      } else if (exchangeId && this.contractSearchForm.get('type').enabled) {
        // Disable all other fields when order ID is entered
        formControlKeys.forEach(key => {
          if (key !== 'exchangeId') {
            this.contractSearchForm.get(key).disable({ emitEvent: false });
          }
        })
        this.statusBoxes.forEach((statusBox: MatCheckbox) => statusBox.disabled = true);
      } else if (hasValueOtherThanContractId || hasValueOtherThanOrderId) {
        // Disable contract ID field if a value is entered in any other field
        if (!contractId && this.contractSearchForm.get('contractId').enabled) {
          this.contractSearchForm.get('contractId').disable({ emitEvent: false });
        }
        // Disable order ID field if a value is entered in any other field
        if (!exchangeId && this.contractSearchForm.get('exchangeId').enabled) {
          this.contractSearchForm.get('exchangeId').disable({ emitEvent: false })
        }
      }
      else if (!formHasValues && (this.contractSearchForm.get('contractId').disabled || this.contractSearchForm.get('type').disabled)) {
        // Re-enable all fields if a previously entered value has disabled a field(s) but is then cleared
        this.contractSearchForm.enable();
        this.statusBoxes.forEach((statusBox: MatCheckbox) => statusBox.disabled = false);
      }
    });

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
      this.queryParams = Object.assign({}, params);

      if (this.queryParams.contractId) {
        this.contractSearchForm.get('contractId').setValue(this.queryParams.contractId);
      }

      if (this.queryParams.exchangeId) {
        this.contractSearchForm.get('exchangeId').setValue(this.queryParams.exchangeId);
      }

      this.contractSearchForm.get('type').setValue(this.queryParams.type);
      this.contractSearchForm.get('startDate').setValue(this.queryParams.startDate);
      this.contractSearchForm.get('endDate').setValue(this.queryParams.endDate);
      if (this.queryParams.delivery) {
        this.contractSearchForm.get('deliveryMonth').setValue(this.translateContractMonthToMoment(this.queryParams.delivery));
      }
      if (this.queryParams.futures) {
        this.contractSearchForm.get('futuresMonth').setValue(this.translateContractMonthToMoment(this.queryParams.futures));
      }
      const locationValue = this.queryParams.location ?
        { docId: this.queryParams.location, name: '' } as Location : undefined;
      this.contractSearchForm.get('location').setValue(locationValue);
      const originatorValue = this.queryParams.originator ?
        { docId: this.queryParams.originator, firstName: '' } as User : undefined;
      this.contractSearchForm.get('originator').setValue(originatorValue);
      const patronValue = this.queryParams.patron ?
        { docId: this.queryParams.patron, name: '', accountingSystemId: '' } as Patron : undefined;
      this.contractSearchForm.get('patron').setValue(patronValue);
      if (this.queryParams.status) {
        this.selectedStatuses = this.queryParams.status.split(',');
        this.contractSearchForm.get('statuses').setValue(this.queryParams.status.split(','));
      }
      this.searchLevel = this.queryParams.searchLevel;
      if (Object.keys(params).length) {
        // Mark form as dirty so reset button appears
        this.contractSearchForm.markAsDirty();
        this.searchContracts();
      }
    }));

    this.isLoading = false;

    // setting here as [checked] property on template was not setting inner input type="checkbox" to checked
    this.statusBoxes.forEach(statusBox => statusBox.checked = this.selectedStatuses.includes(statusBox.value as ContractStatus) && !statusBox.disabled);
    this.changeDetector.detectChanges();
  }

  get isContractCreator() {
    return this.authzService.currentUserHasRole(UserRoles.CONTRACT_CREATOR_ROLE);
  }

  addContract() {
    this.router.navigate(['/contracts/new']);
  }

  reset() {
    this.selectedStatuses = [];
    this.searchLevel = 'Basic';
    this.statusBoxes.forEach(statusBox => statusBox.checked = false);
    this.contractSearchForm.reset();
    // Clear out queryParams so values aren't forced back in
    this.clearQueryParams();
    this.tableState = {};
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true
    });
    this.contractSearchForm.markAsPristine();
  }

  /* Function which will update the statuses being selected in the status component */
  updateStatuses(event: {statuses: ContractStatus[], searchLevel: string}): void {
    this.selectedStatuses = event.statuses;
    this.searchLevel = event.searchLevel;
  }

  searchContracts(searchButtonClicked: boolean = false) {
    if (searchButtonClicked) {
      // clear initial table state if the user perform a new search
      this.clearQueryParams();
      this.tableState = {};
    } else {
      // set initial table state from query param if the user is back navigating from another page
      const sortDir = this.queryParams.sortDir;
      const sortColName = this.queryParams.sortColName;
      const pageSize = this.queryParams.pageSize;
      const pageIndex = this.queryParams.pageIndex;
      const filter = this.queryParams.filter;
      this.tableState = {
        sortDir,
        sortColName,
        pageSize,
        pageIndex,
        filter
      };
    }

    this.showContracts = false;
    this.changeDetector.detectChanges();
    let clientDocId;
    this.selectedContracts$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap(selectedClient => {
        clientDocId = selectedClient.docId;
        return this.getContracts(clientDocId);
      })
    );

    this.showContracts = true;
    this.changeDetector.detectChanges();
  }

  selectDeliveryMonth(deliveryMonth: moment.Moment) {
    this.contractSearchForm.get('deliveryMonth').setValue(deliveryMonth);
    this.contractSearchForm.get('deliveryMonth').markAsDirty();
    this.deliveryMonthRef.close();
  }

  selectFuturesMonth(futuresMonth: moment.Moment) {
    this.contractSearchForm.get('futuresMonth').setValue(futuresMonth);
    this.contractSearchForm.get('futuresMonth').markAsDirty();
    this.futuresMonthRef.close();
  }

  allowTabOnly(event: KeyboardEvent) {
    if (event.key !== 'Tab') {
      event.preventDefault();
    }
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMax')) {
      return 'Value Invalid';
    }
    return 'Unknown Error';
  }

  handleContractListError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.changeDetector.detectChanges();
  }

  private getContracts(clientDocId: string): Observable<Contract[]> {
    const contractId = this.contractSearchForm.get('contractId').value;
    const exchangeId = this.contractSearchForm.get('exchangeId').value;
    const type = this.contractSearchForm.get('type').value;
    let startDate = this.contractSearchForm.get('startDate').value;
    let endDate = this.contractSearchForm.get('endDate').value;
    const location = this.contractSearchForm.get('location').value;
    const originator = this.contractSearchForm.get('originator').value;
    const patron = this.contractSearchForm.get('patron').value;
    let delivery = this.contractSearchForm.get('deliveryMonth').value;
    let futures = this.contractSearchForm.get('futuresMonth').value;

    // copying as search methods involving status use splice, emptying passed in array
    const searchStatuses: ContractStatus[] = this.contractSearchForm.get('statuses').value;


    // Clear out queryParams in case search parameters change after a reload, but maintain table state if any
    this.queryParams = this.tableState as Params;

    if (contractId) {
      this.queryParams.contractID = contractId;
    }

    if (exchangeId) {
      this.queryParams.exchangeId = exchangeId;
    }

    // Set default date or the date entered by the user
    if (startDate) {
      startDate = new Date(startDate).toISOString();
      this.queryParams.startDate = startDate;
    } else {
      startDate = DEFAULT_START_DATE;
    }

    if (endDate) {
      endDate = new Date(endDate);
      // Allow contract made on this day before midnight
      endDate.setHours(23, 59, 59, 999);
      endDate = endDate.toISOString();
      this.queryParams.endDate = endDate;
    } else {
      endDate = DEFAULT_END_DATE;
    }

    if (type) {
      this.queryParams.type = type;
    }

    if (location) {
      this.queryParams.location = location.docId;
    }

    if (originator) {
      this.queryParams.originator = originator.docId;
    }

    if (patron) {
      this.queryParams.patron = patron.docId;
    }

    if (delivery) {
      delivery = this.translateMomentToContractMonth(delivery);
      this.queryParams.delivery = delivery;
    }

    if (futures) {
      futures = this.translateMomentToContractMonth(futures);
      this.queryParams.futures = futures;
    }

    // testing for length rather than hasStatus because all statuses = no status filters, but we want to re-select on back
    if (searchStatuses.length) {
      this.queryParams.status = searchStatuses.join(',');
    }

    this.queryParams.searchLevel = this.searchLevel;

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });

    // Check if the user is searching by contract id, exchange id, or other parameters
    if (contractId) {
      return this.contractService.getContractsById(clientDocId, contractId);
    } else if (exchangeId) {
      return this.contractService.getContractsByExchangeId(clientDocId, exchangeId);
    } else {
      return this.contractService.getContractsBySearchParametersForContractSearch(
        clientDocId, type, searchStatuses, startDate, endDate, this.getDocIdParameter(location),
        this.getDocIdParameter(patron), this.getDocIdParameter(originator), delivery, futures);
    }
  }

  handleContractListChange(tableState: { [key: string]: string | number }) {
    if (tableState.sortDir && tableState.sortColName) {
      this.queryParams.sortDir = tableState.sortDir;
      this.queryParams.sortColName = tableState.sortColName;
    } else if (this.queryParams.sortDir && this.queryParams.sortColName) {
      // remove sorted direction and column in query param if there's no sort applied
      delete this.queryParams.sortDir;
      delete this.queryParams.sortColName;
    }
    if (tableState.pageSize) {
      this.queryParams.pageSize = tableState.pageSize;
    }
    if (tableState.pageIndex !== undefined) {
      this.queryParams.pageIndex = tableState.pageIndex;
    }

    if (tableState.filter) {
      this.queryParams.filter = tableState.filter;
    } else if (this.queryParams.filter) {
      // remove filter query param if there's no filter applied
      delete this.queryParams.filter;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  /**
   * Displays name for location autocomplete input field
   */
  displayLocation(location?: Location) {
    return location && location.name ? `${location.name}` : '';
  }

  /**
   * Displays name for originator autocomplete input field
   */
  displayOriginator(originator?: Originator) {
    return originator && originator.firstName ? `${originator.firstName} ${originator.lastName} (${originator.accountingSystemId})` : '';
  }

  /**
   * Displays name for patron autocomplete input field
   */
  displayPatron(patron?: Patron) {
    return patron && patron.name ? `${patron.name} (${patron.accountingSystemId})` : '';
  }

  private translateContractMonthToMoment(value: string): moment.Moment {
    const year = moment.parseTwoDigitYear(value.substring(0, 2));
    const monthCode = value.substring(2);
    const idxMonth = this.contractMonths.indexOf(monthCode);
    return moment().year(year).month(idxMonth).startOf('month');
  }

  private translateMomentToContractMonth(selectedMonth: moment.Moment): string {
    return selectedMonth ? selectedMonth.format(YEAR_FORMAT) + ContractMonth[this.contractMonths[selectedMonth.month()]] : undefined;
  }

  private prepForLocationSelection() {
    let setInitialValue = false;
    this.filteredLocations$ = this.contractSearchForm.controls.location.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | Location>(''),
      switchMap(searchTerm => {
        return this.clientSelectorService.getSelectedClient().pipe(
          switchMap((selectedClient: Client) => {
            return this.locationService.getClientLocationsByClientDocId(selectedClient.docId).pipe(
              map((locations: Location[]) => {
                // populate searchTerm on initial load in case there is a mock object present
                if (!searchTerm) {
                  searchTerm = this.contractSearchForm.get('location').value;
                }
                // Return all locations when a location has already been selected to avoid the user needing to clear the field
                if (typeof searchTerm !== 'string') {
                  // Replace mock location from queryParam if necessary
                  if (searchTerm && !(searchTerm as Location).name && !setInitialValue) {
                    this.contractSearchForm.get('location').setValue(
                      locations.find(location => location.docId === (searchTerm as Location).docId));
                    setInitialValue = true;
                  }
                  return locations;
                }
                return locations.filter(location => location.name.toLowerCase().includes((searchTerm as string).toLowerCase()));
              }),
              shareReplay({ bufferSize: 1, refCount: true }),
              catchError(err => {
                this.isLoading = false;
                this.errorMessage = 'Error retrieving client locations; please try again later';
                console.error(`Error retrieving client locations: ${err}`);
                return of([]);
              })
            );
          }),
        );
      }),
    );
  }

  private prepForOriginatorSelection() {
    let setInitialValue = false;
    this.filteredOriginators$ = this.contractSearchForm.controls.originator.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | Originator>(''),
      switchMap(searchTerm => {
        return this.clientSelectorService.getSelectedClient().pipe(
          switchMap((selectedClient: Client) => {
            return this.userSettingsService.getHmsUserSettingsForClientUsers(selectedClient.docId);
          }),
          switchMap((hmsUserSettings: HMSUserSettings[]) => {
            return combineLatest(
              hmsUserSettings.map((setting: HMSUserSettings) => {
                const accountingSystemId = setting.accountingSystemId;
                return this.userService.getUserByDocId(setting.userDocId)
                  .pipe(
                    map((user: User) => {
                      return { ...user, accountingSystemId } as Originator;
                    })
                  );
              })
            ).pipe(
              map((originators: Originator[]) => {
                if (!searchTerm) {
                  searchTerm = this.contractSearchForm.get('originator').value;
                }
                // Return all originators when an originator has already been selected to avoid the user needing to clear the field
                if (typeof searchTerm !== 'string') {
                  // Replace mock originator from queryParam if necessary
                  if (searchTerm && !(searchTerm as Originator).firstName && !setInitialValue) {
                    const realOriginator = originators.find(originator => originator.docId === (searchTerm as Originator).docId);
                    // ensure originator was found as initial emission only contains current user
                    if (realOriginator) {
                      this.contractSearchForm.get('originator').setValue(realOriginator);
                      setInitialValue = true;
                    }
                  }
                  return originators;
                }
                const lowerCaseSearchTerm = searchTerm.toLowerCase();
                return originators.filter(originator =>
                  (`${originator.firstName} ${originator.lastName}`).toLowerCase().includes((lowerCaseSearchTerm as string).toLowerCase())
                  || originator.accountingSystemId.toLowerCase().includes(lowerCaseSearchTerm));
              }),
              shareReplay({ bufferSize: 1, refCount: true }),
              catchError(err => {
                this.isLoading = false;
                this.errorMessage = 'Error retrieving originators; please try again later';
                console.error(`Error retrieving originators: ${err}`);
                return of([]);
              })
            );
          }),
        );
      }),
    );
  }

  private prepForPatronSelection() {
    let setInitialValue = false;
    this.filteredPatrons$ = this.contractSearchForm.controls.patron.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | Patron>(''),
      switchMap(searchTerm => {
        const hasQueryParamExecution = this.contractSearchForm.get('patron').value
          && !this.contractSearchForm.get('patron').value.name && !searchTerm;
        const hasTyped3Chars = typeof searchTerm === 'string' && searchTerm.length >= 3;

        if (hasTyped3Chars || hasQueryParamExecution) {
          return this.clientSelectorService.getSelectedClient().pipe(
            switchMap((selectedClient: Client) => {
              return this.patronService.getAllPatronsByClientDocId(selectedClient.docId).pipe(
                map((patrons: Patron[]) => {
                  if (!searchTerm) {
                    searchTerm = this.contractSearchForm.get('patron').value;
                  }
                  // Return all patrons when a patron has already been selected to avoid the user needing to clear the field
                  if (typeof searchTerm !== 'string') {
                    // Replace mock patron from queryParam if necessary
                    if (searchTerm && !(searchTerm as Patron).name && !setInitialValue) {
                      this.contractSearchForm.get('patron').setValue(patrons.find(patron => patron.docId === (searchTerm as Patron).docId));
                      setInitialValue = true;
                    }
                    // Note: return empty list since a patron is selected from query param.
                    // This logic means that we wait until a user is entering another patron search
                    return [];
                  }
                  const lowerCaseSearchTerm = searchTerm.toLowerCase();
                  return patrons.filter(patron => patron.name.toLowerCase().includes(lowerCaseSearchTerm) ||
                    patron.accountingSystemId.toLowerCase().includes(lowerCaseSearchTerm));
                }),
                shareReplay({ bufferSize: 1, refCount: true }),
                catchError(err => {
                  this.isLoading = false;
                  this.errorMessage = 'Error retrieving client patrons; please try again later';
                  console.error(`Error retrieving client patrons: ${err}`);
                  return of([]);
                })
              );
            })
          );
        } else {
          return of([]);
        }
      }),
    );
  }

  private getDocIdParameter(obj) {
    return obj ? obj.docId : undefined;
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }
}
