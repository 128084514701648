import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';

import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { CommodityMap, Order, SecurityType } from '@advance-trading/ops-data-lib';
import { OrderDisplay } from '../order-display';
import { ExportService } from '../../service/export.service';
import { ExecutionReportService, OperationsDataService, OrderFillSummary } from '@advance-trading/angular-ops-data';

@Component({
  selector: 'hms-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers: [BreakpointObserver]
})
export class OrdersComponent implements AfterViewInit, OnChanges, OnInit {

  @Input() initialTableState: {[key: string]: string|number};
  @Input() selectedOrders$: Observable<OrderDisplay[]>;
  @Input() contractOrdersMode = false;

  columnsToDisplay = [];
  errorMessage: string;
  dataSource = new ObservableDataSource<Order>();
  exportable = false;
  filterValue = new FormControl();

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('filter', {static: false}) filter: ElementRef;

  @Output() orderListChange: EventEmitter<any> = new EventEmitter();
  @Output() orderSearchError: EventEmitter<string> = new EventEmitter();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();

  private commodityMap: CommodityMap;
  private tableState: {[key: string]: string|number} = {};

  constructor(
    private breakpointObserver: BreakpointObserver,
    private changeDetector: ChangeDetectorRef,
    private executionReportService: ExecutionReportService,
    public exportService: ExportService,
    private operationsDataService: OperationsDataService,
    private router: Router
  ) { }

  ngOnInit() {
    this.isSearching.emit(true);
    // setup listener for filter value changes
    this.filterValue.valueChanges.subscribe((filter: string) => {
      if (filter) {
        this.tableState.filter = filter.trim();
        this.orderListChange.emit(this.tableState);
      } else if (this.tableState.filter) {
        delete this.tableState.filter;
        this.orderListChange.emit(this.tableState);
      }
    });

    this.breakpointObserver.observe([Breakpoints.XSmall])
      .subscribe(state => {
        // display columns for xsmall screen
        if (state.matches) {
          this.columnsToDisplay = [
            'docId', 'creationTimestamp', 'lastUpdatedTimestamp'
          ];
        // display columns for larger screens
        } else {
          this.columnsToDisplay = [
            'docId', 'creationTimestamp', 'lastUpdatedTimestamp', 'status', 'security', 'type', 'side', 'quantity', 'price', 'fillPrice'
          ];
        }
      });

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.filter) {
      this.filter.nativeElement.focus();
    }
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialTableState'] || changes['selectedOrders$']) {
      this.tableState = Object.assign({}, this.initialTableState);

      // detect MatSort and MatPaginator so it is defined
      this.changeDetector.detectChanges();

      const sortDir = this.initialTableState.sortDir as SortDirection;
      const sortColName = this.initialTableState.sortColName as string;
      if (sortDir && sortColName) {
        this.sort.direction = sortDir;
        this.sort.active = sortColName;
      }

      if (this.initialTableState.filter) {
        this.filterValue.setValue(this.initialTableState.filter);
        this.applyFilter(this.filterValue.value);
      }

      // initialize table
      this.dataSource.data$ =  this.operationsDataService.getCommodityMap().pipe(
        switchMap((doc: CommodityMap) => {
          this.commodityMap = doc;
          return this.selectedOrders$;
        }),
        switchMap((orders: Order[]) => {
          if (orders.length === 0) {
            return of([]);
          }

          // fetching fill price from ExecutionReports
          return combineLatest(orders.map((order: Order) => {
            return this.executionReportService.getOrderFillSummaryByOrderDocId(order.accountDocId, order.docId).pipe(
              map((orderFillSummary: OrderFillSummary) => {
                if (orderFillSummary) {
                  return {
                    ...order,
                    isSplitFilled: orderFillSummary.isSplitFilled,
                    fillPrice: this.getDisplayPrice(orderFillSummary.fillPrice, order)
                  } as OrderDisplay;
                }
                // this could happen for orders that are not filled
                return order as OrderDisplay;
              }),
              catchError(err => {
                console.error(`Failed to retrieve order fill data from execution reports: ${err}`);
                return of(order as OrderDisplay);
              })
            );
          }));
        }),
        map((orders: OrderDisplay[]) => {
          return orders.map((order: OrderDisplay) => {
            order.price = this.getDisplayPrice(order.price, order);
            return order;
          });
        }),
        tap(orders => {
          this.isSearching.emit(false);
          this.exportable = !!orders.length;

          // initialize pagination state when the datasource exist
          const pageIndex = this.initialTableState.pageIndex as number;
          const pageSize = this.initialTableState.pageSize as number;

          if (pageIndex !== undefined) {
            this.paginator.pageIndex = pageIndex;
          }
          if (pageSize !== undefined) {
            this.paginator.pageSize = pageSize;
          }
        }),
        catchError(err => {
          this.errorMessage = 'Error retrieving orders; please try again later';
          this.orderSearchError.emit(this.errorMessage);
          this.isSearching.emit(false);
          console.error(`Error retrieving orders: ${err}`);
          return of([]);
        })
      );
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectOrder(order: Order) {
    this.router.navigate(['/accounts', order.accountDocId, 'orders', order.docId]);
  }

  handleSortChange() {
    this.tableState.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.tableState.sortColName = this.tableState.sortDir ? this.sort.active : undefined;
    this.orderListChange.emit(this.tableState);
  }

  handlePageChange() {
    this.tableState.pageSize = this.paginator.pageSize;
    this.tableState.pageIndex = this.paginator.pageIndex;
    this.orderListChange.emit(this.tableState);
  }

  private getDisplayPrice(price: number, order: Order) {
    let priceDivisor;
    if (order.securityType === SecurityType.OPTION) {
      const commodity = Object.values(this.commodityMap.commodities).find(cmd => cmd.electronicOptionsSymbol === order.symbol);
      priceDivisor = commodity ? commodity.marketDataDivisor : 1;
    } else {
      priceDivisor =  this.commodityMap.commodities[order.symbol] ? this.commodityMap.commodities[order.symbol].marketDataDivisor : 1;
    }
    return price / priceDivisor;
  }
}
