<div fxLayout="row" fxLayoutAlign="space-around start">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

      <hms-error-message [title]="'Error Retrieving New Location Form'" [message]="errorMessage"></hms-error-message>

      <form *ngIf="!errorMessage" [formGroup]="locationForm" (ngSubmit)="submit()" fxLayout="column" fxLayoutGap="15px">
        <!-- Title and Buttons -->
        <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
            <h2 class="card-title">New Location</h2>
            <div *ngIf="selectedClient$ | async" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
              <button type="submit" id="create-location" class="action-button" aria-label="Create Location" *ngIf="updateComplete" [disabled]="!locationForm.valid || partialNewSettingEntered" (click)="submit()" mat-mini-fab color="accent" matTooltip="Create Location">
                  <mat-icon>done</mat-icon>
              </button>
              <button type="button" id="clear-location" class="action-button" aria-label="Discard Changes" *ngIf="!locationForm.pristine && updateComplete" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes">
                  <mat-icon>clear</mat-icon>
              </button>
              <mat-spinner *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
            </div>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100">
          <mat-form-field fxFlex.gt-xs="75" fxFlex.xs="100">
            <input required matInput placeholder="Name" formControlName="name" trim="blur">
            <mat-error *ngIf="locationForm.get('name').invalid">{{getErrorMessage(locationForm.get('name'))}}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start end" fxFlex="100">
          <div class="checkbox-group" fxFlex.gt-xs="25" fxFlex.xs="50">
            <label class="check-radio-label mat-small mat-hint">Status</label>
            <mat-checkbox formControlName="isActive">Active</mat-checkbox>
          </div>
          <div class="checkbox-group" fxFlex.gt-xs="25" fxFlex.xs="50">
            <mat-checkbox formControlName="isClientLocation" (change)="setAccountingSystemIdRequired()">Client Location</mat-checkbox>
          </div>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100">
          <mat-form-field fxFlex.gt-xs="25" fxFlex.xs="50">
            <input [required]="locationForm.get('isClientLocation').value" matInput placeholder="ID" formControlName="id" trim="blur">
            <mat-error *ngIf="locationForm.get('id').invalid">{{getErrorMessage(locationForm.get('id'))}}</mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start" fxFlex="100">
          <h4>Address</h4>
          <button type="button" *ngIf="!location?.address" id="enable-location-address" class="action-button" type="button" aria-label="Show/Hide Address" [color]="!enableAddress ? 'accent' : 'primary'" mat-icon-button (click)="toggleAddressForm()" matTooltip="Show/Hide Address">
            <mat-icon>{{!enableAddress ? 'add_circle' : 'cancel'}}</mat-icon>
          </button>
        </div>
        <ati-address *ngIf="enableAddress || location?.address" [addressForm]="locationForm.get('address')"></ati-address>

        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>Phone Numbers</mat-panel-title>
          </mat-expansion-panel-header>
          <ati-phone-number *ngFor="let phoneNumber of phoneNumbers; let index = index" [editMode]="true" [phoneNumber]="phoneNumber" (numberUpdated)="phoneNumbers[index] = $event; locationForm.markAsDirty()" (numberRemoved)="onPhoneNumberRemoved(index)"></ati-phone-number>
          <ati-phone-number [editMode]=true (numberUpdated)="onPhoneNumberCreated($event)"></ati-phone-number>
        </mat-expansion-panel>

        <h4>Accounting System Settings</h4>
        <div fxFlex="100" formArrayName="accountingSettings" *ngFor="let accountingSettingForm of existingAccountingSettings; let idx = index;" [formGroup]="accountingSettingForm">
          <mat-form-field fxFlex="100" *ngIf="!appearsBoolean(accountingSettingForm.get('settingValue').value)">
            <input required matInput [placeholder]="accountingSettingForm.get('settingKey').value" [formControl]="accountingSettingForm.get('settingValue')" trim="blur" (blur)="checkForBoolean(accountingSettingForm.get('settingKey').value, accountingSettingForm.get('settingValue').value)" #settingValueField (keypress)="listenForEnter($event, settingValueField.value, idx)">
            <mat-error *ngIf="accountingSettingForm.get('settingValue').invalid">{{ getErrorMessage(accountingSettingForm.get('settingValue')) }}</mat-error>
            <div matSuffix fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px">
              <button type="button" id="clear-new-location-accounting-setting-value" *ngIf="accountingSettingForm.get('settingValue').value" mat-icon-button aria-label="Clear Value" matTooltip="Clear Value" (click)="accountingSettingForm.get('settingValue').setValue('')">
                <mat-icon>close</mat-icon>
              </button>
              <button type="button" id="remove-new-location-accounting-setting-value" mat-icon-button aria-label="Remove Accounting Setting" matTooltip="Remove Accounting Setting" (click)="removeAccountingSettingForm(idx)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </mat-form-field>
          <div *ngIf="appearsBoolean(accountingSettingForm.get('settingValue').value)" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between end">
            <div class="checkbox-group">
              <label class="check-radio-label mat-small mat-hint">{{ accountingSettingForm.get('settingKey').value }}</label>
              <mat-checkbox [formControl]="accountingSettingForm.get('settingValue')">Enabled</mat-checkbox>
            </div>
            <button type="button" id="remove-new-location-accounting-setting-value" mat-icon-button class="checkbox-fake-suffix" aria-label="Remove Accounting Setting" matTooltip="Remove Accounting Setting" (click)="removeAccountingSettingForm(idx)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between start">
          <mat-form-field fxFlex="30">
            <input matInput placeholder="Setting" formControlName="newSettingKey" trim="blur">
            <mat-error *ngIf="locationForm.get('newSettingKey').invalid">{{ getErrorMessage(locationForm.get('newSettingKey')) }}</mat-error>
          </mat-form-field>
          <mat-form-field fxFlex="67">
            <input matInput placeholder="Value" formControlName="newSettingValue" trim="blur">
            <mat-error *ngIf="locationForm.get('newSettingValue').invalid">{{ getErrorMessage(locationForm.get('newSettingValue')) }}</mat-error>
            <button type="button" matSuffix id="add-accounting-setting" [disabled]="addAccountingFormDisabled" mat-icon-button aria-label="Add Accounting Setting" matTooltip="Add Accounting Setting" (click)="addAccountingSettingForm()">
              <mat-icon>add_circle</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </form>

    </mat-card>
  </div>

