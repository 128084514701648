import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import * as moment from 'moment';
import { Observable, of, timer } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';

import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommonValidators } from '@advance-trading/angular-common-services';
import { BasisService, CommodityProfileService, LocationService, MarketDataService, OperationsDataService } from '@advance-trading/angular-ops-data';
import {
  Basis,
  Client,
  CommodityMap,
  CommodityProfile,
  ContractMonth,
  HMSUserSettings,
  Location,
  MarketData,
  MarketDataFrequency,
  Side
} from '@advance-trading/ops-data-lib';

import { UserRoles } from '../../utilities/user-roles';
import { UserSettingsService } from '../../service/user-settings.service';
import { ClientSelectorService } from '../../service/client-selector.service';
import { CommodityFuturesPriceService } from '../../service/commodity-futures-price.service';

const NUM_DECIMAL = 4;

const MONTH_CODE_DICT = {
  Jan: ContractMonth.F,
  Feb: ContractMonth.G,
  Mar: ContractMonth.H,
  Apr: ContractMonth.J,
  May: ContractMonth.K,
  Jun: ContractMonth.M,
  Jul: ContractMonth.N,
  Aug: ContractMonth.Q,
  Sep: ContractMonth.U,
  Oct: ContractMonth.V,
  Nov: ContractMonth.X,
  Dec: ContractMonth.Z
};

interface CommodityMarketDataBasis extends CommodityProfile {
  basis$: Observable<Basis>;
}

interface BidRow {
  basis: string;
  cash: string;
  'delivery period': string;
  futures: string;
}

@Component({
  selector: 'hms-selectable-bids',
  templateUrl: './selectable-bids.component.html',
  styleUrls: ['./selectable-bids.component.scss']
})
export class SelectableBidsComponent implements OnInit {
  errorMessage = '';
  isLoading = false;
  selectedTabIndex: number;
  userDataFrequency: MarketDataFrequency;
  timestamp: string;
  locationName: string;

  locationForm: FormGroup = this.formBuilder.group({
    location: ['', [CommonValidators.objectValidator]],
  });

  // Data collections
  userSettings$: Observable<HMSUserSettings>;
  filteredLocations$: Observable<Location[]>;
  commodityMarketDataBasis$: Observable<CommodityMarketDataBasis[]>;

  // Basis table variables
  dataSourceBasis = [];
  displayedColumn = ['delivery', 'futures', 'basis', 'cash'];

  // On-Demand Market Data
  lastIdxRefreshed: number;
  tooltipMessage: string;

  @ViewChildren('dataTooltip') tooltips: QueryList<MatTooltip>;

  private locationDocId: string;

  private currMarketData: MarketData[];
  private currentBasis: Basis;
  private commodityMap: CommodityMap;

  constructor(
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private clientSelectorService: ClientSelectorService,
    private commodityFuturesPriceService: CommodityFuturesPriceService,
    private route: ActivatedRoute,
    private router: Router,
    private commodityProfileService: CommodityProfileService,
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private basisService: BasisService,
    private marketdataService: MarketDataService,
    private operationsDataService: OperationsDataService,
    private userSettingsService: UserSettingsService
  ) { }

  ngOnInit() {
    this.userDataFrequency = this.authService.userProfile.app_metadata.marketDataFrequency;
    let defaultLocationDocId;

    // Get location from the query parameters
    this.userSettings$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((commodityMap: CommodityMap) => {
        this.commodityMap = commodityMap;
        return this.route.queryParamMap;
      }),
      switchMap((params: ParamMap) => {
        if (params.get('locationId')) {
          defaultLocationDocId = params.get('locationId');
        }
        const userDocId = this.authService.userProfile.app_metadata.firestoreDocId;
        return this.userSettingsService.getHmsSettingsByUserDocId(userDocId);
      }),
      tap((hmsSettings: HMSUserSettings) => {
        // if there is no query param set, use the user base location
        if (!defaultLocationDocId && hmsSettings && hmsSettings.baseLocationDocId) {
          defaultLocationDocId = hmsSettings.baseLocationDocId;
        }
        this.prepForLocationSelection(defaultLocationDocId);

        // One full day in milliseconds for repeat if the user remains on the screen
        const fullDayMillis: number = 1000 * 60 * 60 * 24;
        if(MarketDataFrequency.REALTIME === this.userDataFrequency) {
          timer(this.getNextEndOfMarketTime(false), fullDayMillis).subscribe( () => this.populateTable());
        } else {
          timer(this.getNextEndOfMarketTime(true), fullDayMillis).subscribe( () => this.populateTable());
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError((err) => {
        this.errorMessage = 'Error retrieving user settings; please try again later';
        console.error(`Error retrieving user settings: ${err}`);
        return of(undefined);
      })
    );
  }

  canCreateContract() {
    return this.authzService.currentUserHasRole(UserRoles.CONTRACT_CREATOR_ROLE);
  }

  selectBid(bid: BidRow) {
    // should not navigate to new contract when the user is not a contract creator or the bid does not have futures
    if (!this.canCreateContract() || !this.bidHasFutures(bid)) {
      return;
    }
    // Route to new contract if the user clicks a basis
    this.router.navigate(['/contracts/new'], {
      queryParams: { basisId: this.currentBasis.docId, delivery: bid['delivery period'] }
    });
  }

  refresh(index: number) {
    this.lastIdxRefreshed = index;

    const contractYearMonth = this.getFuturesYearMonth(this.dataSourceBasis[index]['delivery period']);
    const marketDataToFetch = this.currMarketData[this.getMarketDataIndex(contractYearMonth)];
    this.tooltipMessage = 'Loading ...';
    const currentTooltip = this.tooltips.toArray()[index];
    currentTooltip.message = this.tooltipMessage;
    currentTooltip.show();

    // check if there is market data for this delivery period
    if (marketDataToFetch) {
      this.marketdataService.getRealTimeMarketDataByDocId(marketDataToFetch.docId, this.authService.accessToken).pipe(
        take(1),
        tap((marketData: MarketData) => {
          this.tooltipMessage = this.getTooltipMessage(marketData, index);
          currentTooltip.message = this.tooltipMessage;
        }),
        catchError(err => {
          // TODO Do we really want to wipe the whole screen for a single real-time data pull failure?
          this.errorMessage = 'Error retrieving real-time market data; please try again later';
          console.error(`Error retrieving real-time market data: ${err}`);
          return of(undefined);
        })
      ).subscribe();
      // this can happen when the BasisAdmin entered a wrong futures month when specifying the basis
    } else {
      this.tooltipMessage = this.getTooltipMessage({} as MarketData, index);
      currentTooltip.message = this.tooltipMessage;
    }
  }

  /**
   * Displays name for location autocomplete input field
   */
  displayLocation(location?: Location) {
    return location ? location.name : '';
  }

  getFuturesYearMonth(deliveryPeriod: string) {
    return this.currentBasis.deliveryPeriodBases[deliveryPeriod].futuresYearMonth;
  }

  private populateTable() {
    if (this.currentBasis) {
      // Get the basis for the current commodity profile
      this.populateDatasource();
    } else {
      this.dataSourceBasis = [];
    }
  }

  private populateDatasource() {
    let months = [];
    Object.keys(this.currentBasis.deliveryPeriodBases).forEach(key => {
      months.push(key);
    });
    months.sort();

    // Display only most recent delivery period
    const currentPeriod = this.getCurrentPeriod();
    months = months.filter(m => m >= currentPeriod);

    // Populate data source
    this.dataSourceBasis = months.map(m => {
      const row = {};
      row['delivery period'] = m;
      const basisMap = this.currentBasis.deliveryPeriodBases[m];
      row['basis'] = basisMap.basis.toFixed(NUM_DECIMAL);
      const marketDataIndex = this.getMarketDataIndex(basisMap.futuresYearMonth);
      if (marketDataIndex !== -1) {
        // TODO how to handle undefined coming back from getMarketPrice?
        const delayed = this.userDataFrequency !== MarketDataFrequency.REALTIME;
        const futures = this.commodityFuturesPriceService.getMarketPrice(Side.BUY, this.currMarketData[marketDataIndex], delayed) /
          this.getCommodityMarketDataDivisor(this.currMarketData[marketDataIndex].commodity);
        row['futures'] = futures.toFixed(NUM_DECIMAL);
        row['cash'] = (Math.round((futures + basisMap.basis) * 10000) / 10000).toFixed(NUM_DECIMAL);
      } else {
        row['futures'] = '-';
        row['cash'] = '-';
      }
      return row;
    });
  }

  private getCommodityMarketDataDivisor(id: string): number {
    // using same fallthrough value as other components with fallthrough
    return this.commodityMap.commodities[id] ? this.commodityMap.commodities[id].marketDataDivisor : 1;
  }

  private getCurrentPeriod(): string {
    const currentMonth = moment().format('MMM');
    const currentYear = moment().format('YY');
    return currentYear + MONTH_CODE_DICT[currentMonth];
  }

  private getMarketDataIndex(period: string): number {
    return this.currMarketData.findIndex(data => period === data.contractYearMonth);
  }

  private getCommodityMarketDataBasesObservable(): Observable<CommodityMarketDataBasis[]> {
    return this.clientSelectorService.getSelectedClient().pipe(
      switchMap(client => {
        return this.commodityProfileService.getActiveCommodityProfilesWithBasisByClientDocId(client.docId);
      }),
      map((cp: CommodityProfile[]) => {
        const cmb: CommodityMarketDataBasis[] = cp.map((profile: CommodityProfile) => {
          return { ...profile, basis$: this.getMarketDataAndBasis(profile) } as CommodityMarketDataBasis;
        });
        return cmb;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving commodity profile; please try again later';
        console.error(`Error retrieving commodity profile: ${err}`);
        return of([]);
      })
    );
  }

  private getMarketData(commodityId: string): Observable<MarketData[]> {
    if (this.userDataFrequency === MarketDataFrequency.REALTIME) {
      return this.marketdataService.getRealTimeFuturesMarketDataByCommodity(commodityId);
    } else if (this.userDataFrequency === MarketDataFrequency.DELAY_10 || this.userDataFrequency === MarketDataFrequency.ON_DEMAND) {
      return this.marketdataService.getDelayedFuturesMarketDataByCommodity(commodityId);
    } else {
      return this.marketdataService.getDelayedFuturesMarketDataByCommodity(commodityId).pipe(
        take(1)
      );
    }
  }

  private getMarketDataAndBasis(profile: CommodityProfile): Observable<Basis> {
    // TODO we need to look at whether having the marketdata Observable at the beginning of this chain is
    // efficient; each update to market data will cause all the operators below in the pipe to re-execute
    return this.getMarketData(profile.commodityId).pipe(
      switchMap((marketdata: MarketData[]) => {
        this.timestamp = moment().format('M/D/YYYY, h:mm:ss A');
        // get market data that's not expired
        const todayDate = moment();
        this.currMarketData = marketdata.filter(data => moment(data.expirationDate).endOf('day').isAfter(todayDate));

        return this.clientSelectorService.getSelectedClient();
      }),
      switchMap((client: Client) => {
        return this.basisService.getBasisByClientCommodityProfileAndLocation(client.docId, profile.docId, this.locationDocId);
      }),
      tap((basis: Basis) => {
        this.currentBasis = basis;
        // Populate table according to the current tab
        this.populateTable();
        this.isLoading = false;
        // Handle when the user refreshed a button and then try to switch tabs
        this.lastIdxRefreshed = -1;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving market and basis data; please try again later';
        console.error(`Error retrieving market and basis data: ${err}`);
        return of(undefined);
      })
    );
  }

  private prepForLocationSelection(defaultLocationDocId: string) {
    this.filteredLocations$ = this.locationForm.controls.location.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | Location>(''),
      switchMap(searchTerm => {
        // Location object selected from list
        if (typeof searchTerm === 'object') {
          // Check that location has changed
          if (this.locationDocId !== searchTerm.docId) {
            this.isLoading = true;
            this.locationDocId = searchTerm.docId;
            this.locationName = searchTerm.name;
            this.commodityMarketDataBasis$ = this.getCommodityMarketDataBasesObservable();
          }
        }
        return this.clientSelectorService.getSelectedClient().pipe(
          switchMap(client => {
            return this.locationService.getActiveLocationsByClientDocId(client.docId);
          }),
          map((locations: Location[]) => {
            // Set initial location from query param or user base location
            if (defaultLocationDocId && !this.locationDocId) {
              const defaultLocation = locations.find(location => location.docId === defaultLocationDocId);
              this.locationForm.get('location').setValue(defaultLocation);
            } else if (!this.locationDocId) {
              const defaultLocation = locations.find(location => location.isClientLocation);
              this.locationForm.get('location').setValue(defaultLocation);
            }
            // Return all locations when a location has already been selected to avoid the user needing to clear the field
            if (typeof searchTerm !== 'string') {
              return locations;
            }
            return locations.filter(location => location.name.toLowerCase().includes(searchTerm.toLowerCase()));
          }),
          shareReplay({ bufferSize: 1, refCount: true }),
          catchError(err => {
            this.errorMessage = 'Error retrieving client locations; please try again later';
            console.error(`Error retrieving client locations: ${err}`);
            return of([]);
          })
        );
      }),
    );
  }

  private getNextEndOfMarketTime(delayed: boolean = true): Date {
    return moment().set('hour', 13)
                  .set('minute', delayed ? 30 : 20)
                  .set('second', 0)
                  .set('millisecond', 1000) // Adding one second to close
                  .toDate();
  }

  private getTooltipMessage(marketData: MarketData, index: number) {
    const priceDivisor = this.getCommodityMarketDataDivisor(marketData.commodity);
    const realtimeBidOrSettle = this.commodityFuturesPriceService.getMarketPrice(Side.BUY, marketData);
    const realtimeFutures = realtimeBidOrSettle ? (realtimeBidOrSettle / priceDivisor).toFixed(NUM_DECIMAL) : 'n/a';
    const realtimeBasis = this.dataSourceBasis[index]['basis'];
    let realtimeCash = 'n/a';
    if (realtimeBidOrSettle) {
      realtimeCash = (realtimeBidOrSettle / priceDivisor + parseFloat(this.dataSourceBasis[index]['basis'])).toFixed(NUM_DECIMAL);
    }
    return `
    Real-Time Market Data

    \tFutures:\t\t${realtimeFutures}
    \tBasis:\t\t${realtimeBasis}
    \tCash:\t\t${realtimeCash}`;
  }

  private bidHasFutures(bid: BidRow) {
    const currentBidRow: BidRow = this.dataSourceBasis.find(data => data['delivery period'] === bid['delivery period']);
    return currentBidRow.futures !== '-';
  }

}
