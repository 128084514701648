<div fxLayout="row" fxLayoutAlign="space-around start">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <!-- Error message -->
    <hms-error-message [title]="'Error Retrieving Ledger Adjustment Details'" [message]="errorMessage"></hms-error-message>

    <!-- Title and Buttons -->
    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">Ledger Adjustment</h2>

      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <button type="button" id="edit-ledger-adjustment" class="action-button" aria-label="Edit Ledger Adjustment" matTooltip="Edit Ledger Adjustment" *ngIf="!editMode && updateComplete && !isLoading && isLedgerAdjustmentAdmin" (click)="setEditMode(true)" mat-mini-fab color="accent">
          <mat-icon>edit</mat-icon>
        </button>
        <button type="submit" id="update-ledger-adjustment" class="action-button" aria-label="Update Ledger Adjustment" *ngIf="editMode && updateComplete" [disabled]="!adjustmentForm.valid || adjustmentForm.pristine" (click)="submit()" mat-mini-fab color="accent" matTooltip="Update Ledger Adjustment">
            <mat-icon>done</mat-icon>
        </button>
        <button type="button" id="clear-ledger-adjustment" class="action-button" aria-label="Discard Changes" *ngIf="updateComplete && editMode" (click)="reset()" mat-mini-fab color="" matTooltip="Discard Changes">
            <mat-icon>clear</mat-icon>
        </button>
        <mat-spinner class="loading-spinner" *ngIf="!updateComplete || isLoading" diameter="40" color="accent"></mat-spinner>
      </div>
    </div>

    <form *ngIf="!errorMessage && commodityProfile$ | async" [formGroup]="adjustmentForm" (ngSubmit)="submit()" fxLayout="column" fxLayoutGap="15px">

      <!-- Commodity Profile, Quantity, ProdYear -->
      <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start start" fxLayout.xs="column" fxLayoutGap.xs="15px">
        <ati-labeled-data fxFlex.gt-xs="250px" label="Commodity Profile" [data]="commodityProfile.name"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="250px" label="Production Year" [data]="prodYearLabel | productionYear"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="250px" [label]="contractUnit | contractUnit" [data]="adjustment.quantity | number"></ati-labeled-data>
      </div>
      <!-- Creator, Creation Timestamp -->
      <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start start" fxLayout.xs="column" fxLayoutGap.xs="15px">
        <ati-labeled-data fxFlex.gt-xs="250px" label="Creator" [data]="adjustment.creatorName"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="250px" label="Creation Date" [data]="createdDate"></ati-labeled-data>
      </div>
      <!-- Last Updater, Last Updated Timestamp -->
      <div *ngIf="adjustment && adjustment.creationTimestamp !== adjustment.lastUpdatedTimestamp" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start start" fxLayout.xs="column" fxLayoutGap.xs="15px">
        <ati-labeled-data fxFlex.gt-xs="250px" label="Last Updated By" [data]="adjustment.lastUpdatedByName"></ati-labeled-data>
        <ati-labeled-data fxFlex.gt-xs="250px" label="Last Updated Date" [data]="lastUpdatedDate"></ati-labeled-data>
      </div>
      <!-- Comments -->
      <ati-labeled-data *ngIf="displaySystemAdjustmentLink" [label]="getSystemAdjustmentLabel()" [data]="getSystemAdjustmentDetail()" [link]="adjustment.relatedUri"></ati-labeled-data>
      <ati-labeled-data *ngIf="!editMode" label="Comments" [data]="adjustment.comments"></ati-labeled-data>
      <mat-form-field *ngIf="editMode">
        <textarea matInput placeholder="Comments" formControlName="comments" trim="blur"></textarea>
        <mat-error *ngIf="adjustmentForm.get('comments').invalid">{{ getErrorMessage(adjustmentForm.get('comments')) }}</mat-error>
        <button type="button" id="clear-ledger-adjustment-comments" mat-button *ngIf="editMode && adjustmentForm.get('comments').value" matSuffix mat-icon-button aria-label="Clear Comments" matTooltip="Clear Comments" (click)="clearField('comments')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </form>

  </mat-card>
</div>
