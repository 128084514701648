import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ObservableDataSource } from '@advance-trading/angular-common-services';

import { LedgerAdjustmentDisplay } from '../ledger-adjustment-display';
import { ExportService } from '../../service/export.service';

@Component({
  selector: 'hms-ledger-adjustments',
  templateUrl: './ledger-adjustments.component.html',
  styleUrls: ['./ledger-adjustments.component.scss']
})
export class LedgerAdjustmentsComponent implements OnInit, AfterViewInit {

  @Input() selectedAdjustments$: Observable<LedgerAdjustmentDisplay[]>;

  errorMessage: string;
  columnsToDisplay = [];
  dataSource = new ObservableDataSource<LedgerAdjustmentDisplay>();
  filterValue = new FormControl();

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('filter', {static: false}) filter: ElementRef;

  @Output() adjustmentSearchError: EventEmitter<string> = new EventEmitter();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private changeDetector: ChangeDetectorRef,
    public exportService: ExportService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isSearching.emit(true);

    this.breakpointObserver.observe([Breakpoints.XSmall])
    .subscribe(state => {
      // display columns for xsmall screens
      if (state.matches) {
        this.columnsToDisplay = [
          'creationTimestamp', 'type', 'commodityProfileName', 'quantity'
        ];
      // display columns for larger screens
      } else {
        this.columnsToDisplay = [
          'creationTimestamp', 'type', 'commodityProfileName', 'productionYearLabel', 'quantity'
        ];
      }
    });

    this.dataSource.data$ = this.selectedAdjustments$.pipe(
      tap(() => {
        this.isSearching.emit(false);
      }),
      catchError(err => {
        this.errorMessage = 'Error retrieving ledger adjustments; please try again later';
        this.adjustmentSearchError.emit(this.errorMessage);
        this.isSearching.emit(false);
        console.error(`Error retrieving ledger adjustments: ${err}`);
        return of([]);
      })
    );

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.filter.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectAdjustment(adjustment: LedgerAdjustmentDisplay) {
    this.router.navigate(['./', adjustment.docId], {relativeTo: this.route});
  }

}
