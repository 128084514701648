import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { Commodity, CommodityMap, Order, SecurityType, Side } from '@advance-trading/ops-data-lib';
import { ExecutionReportService, OperationsDataService, OrderFill, OrderService } from '@advance-trading/angular-ops-data';

import { OrderFillDisplay } from '../../utilities/order-fill-display';
import { UserRoles } from '../../utilities/user-roles';

import { ObservableDataSource } from '@advance-trading/angular-common-services';

@Component({
  selector: 'hms-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
  isLoading = false;
  errorMessage: string;

  order$: Observable<Order>;

  fillDataSource = new ObservableDataSource<OrderFillDisplay>();
  fillColumnsToDisplay = [];
  hasLeg = false;

  orderDocId: string;
  commodityMap: CommodityMap;
  private orderAccountDocId: string;
  private commodity: Commodity;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authzService: Auth0AuthzService,
    private executionReportService: ExecutionReportService,
    private operationsDataService: OperationsDataService,
    private orderService: OrderService
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.ORDER_VIEWER_ROLE) &&
      !this.authzService.currentUserHasRole(UserRoles.FULL_LEDGER_VIEWER_ROLE) &&
      !this.authzService.currentUserHasRole(UserRoles.LOCATION_LEDGER_VIEWER_ROLE) &&
      !this.authzService.currentUserHasRole(UserRoles.CONTRACT_VIEWER_ROLE) &&
      !this.authzService.currentUserHasRole(UserRoles.AD_HOC_ORDER_VIEWER_ROLE) &&
      !this.authzService.currentUserHasRole(UserRoles.HEDGE_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to view orders.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.isLoading = true;
    this.order$ = this.activatedRoute.paramMap.pipe(
      switchMap((paramMap: ParamMap) => {
        this.orderAccountDocId = paramMap.get('accountDocId');
        this.orderDocId = paramMap.get('orderDocId');
        return this.operationsDataService.getCommodityMap();
      }),
      switchMap((commodityMap: CommodityMap) => {
        this.commodityMap = commodityMap;
        return this.orderService.getOrderByDocId(this.orderAccountDocId, this.orderDocId);
      }),
      tap((order: Order) => {
        // initialize commodity if needed (only used for OPTION orders)
        this.commodity = order.securityType === SecurityType.OPTION ?
          Object.values(this.commodityMap.commodities).find(cmd => cmd.electronicOptionsSymbol === order.symbol) :
          undefined;
        this.fillDataSource.data$ = this.executionReportService.getOrderFillsByOrderDocId(order.accountDocId, order.docId).pipe(
          map((fills: OrderFill[]) => {
            return fills.map(fill => {

              if (fill.legFills) {
                const orderLegFillKeys = Object.keys(fill.legFills);
                const buyKey = orderLegFillKeys.find(key => key.includes(Side.BUY));
                const sellKey = orderLegFillKeys.find(key => key.includes(Side.SELL));
                return {
                  quantity: fill.fillQuantity,
                  orderSymbol: order.symbol,
                  orderFillPrice: fill.fillPrice,
                  orderBuySymbol: this.getLegFillSymbol(fill.legFills[buyKey].security, order),
                  orderBuyPrice: fill.legFills[buyKey].fillPrice,
                  orderSellSymbol: this.getLegFillSymbol(fill.legFills[sellKey].security, order),
                  orderSellPrice: fill.legFills[sellKey].fillPrice
                } as OrderFillDisplay;
              } else {
                return {
                  quantity: fill.fillQuantity,
                  orderSymbol: order.symbol,
                  orderFillPrice: fill.fillPrice,
                } as OrderFillDisplay;
              }

            });
          }),
          tap((fills: OrderFillDisplay[]) => {
            if (fills.find(fill => fill.orderBuyPrice === undefined && fill.orderSellPrice === undefined)) {
              // Future order displayed column
              this.fillColumnsToDisplay = ['quantity', 'orderFillPrice'];

              this.hasLeg = false;
            } else {
              // Spread order displayed column
              this.fillColumnsToDisplay = ['quantity', 'orderFillPrice', 'orderBuyPrice', 'orderSellPrice'];

              this.hasLeg = true;
            }
          })
        );
        this.isLoading = false;
      }),
      catchError(err => {
        this.errorMessage = 'Error retrieving order data; please try again later';
        console.error(`Error retrieving order data: ${err}`);
        return of(undefined);
      })
    );
  }

  getDisplayPrice(price: number, symbol: string, order: Order) {
    let priceDivisor;
    if (order.securityType === SecurityType.OPTION) {
      priceDivisor = this.commodity ? this.commodity.marketDataDivisor : 1;
    } else {
      priceDivisor = this.commodityMap.commodities[symbol] ? this.commodityMap.commodities[symbol].marketDataDivisor : 1;
    }

    return price / priceDivisor;
  }

  getQuantityDisplay(quantity: number) {
    return `${quantity} contract${quantity === 1 ? '' : 's'}`;
  }

  private getLegFillSymbol(security: string, order: Order) {
    if (order.securityType === SecurityType.OPTION) {
      const splitSecurity = security.split(' ');
      return splitSecurity[0].substring(0, splitSecurity.length - 2);
    } else {
      return security.substring(0, security.length - 2);
    }
  }
}
