<hms-error-message [title]="'Error Retrieving Quotes'" [message]="errorMessage"></hms-error-message>

<div *ngIf="!errorMessage">
  <h2 class="card-title">
    Commodity{{ selectedCommodityName ? ': ' + selectedCommodityName : '' }}
  </h2>
  <ng-container>
    <form [formGroup]="commodityForm">
      <mat-form-field fxFlex.gt-xs="45">
        <input required matInput placeholder="Commodity" formControlName="commodity" [matAutocomplete]="autoCommodity">
        <mat-error *ngIf="commodityForm.get('commodity').invalid">You must select a valid commodity</mat-error>
        <mat-autocomplete #autoCommodity="matAutocomplete" [displayWith]="displayCommodity">
        <mat-option *ngFor="let commodity of filteredCommodities$ | async" [value]="commodity">({{ commodity.id }}) {{ commodity.name }}</mat-option>
        </mat-autocomplete>
        <button id="quotes-clear-commodity" mat-button *ngIf="commodityForm.get('commodity').value"
                matSuffix mat-icon-button aria-label="Clear Commodity" matTooltip="Clear Commodity"
                (click)="commodityForm.get('commodity').setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </ng-container>

  <hms-single-commodity-quotes [commodityId]="selectedCommodityId" [mode]="QUOTES_MODE"></hms-single-commodity-quotes>
</div>
