<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

      <hms-error-message [title]="'Error Performing Orders Search'" [message]="errorMessage"></hms-error-message>

      <form *ngIf="!errorMessage" [formGroup]="orderSearchForm" (ngSubmit)="searchOrders(true)" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <h2 data-cy="page-title-orders" class="card-title">Orders</h2>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxFlex="100" fxLayoutGap="5">
          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="70">
            <mat-label>Order ID</mat-label>
            <input matInput formControlName="orderId" trim="blur" mask="A*">
          </mat-form-field>

          <mat-form-field fxFlex.gt-xs="45" fxFlex.xs="70">
            <mat-label>Symbol</mat-label>
            <input matInput placeholder="Ex. ZC, ZS" formControlName="symbol" trim="blur" mask="A*">
          </mat-form-field>
        </div>

        <div class="checkbox-group">
          <span class="check-radio-label">Status</span>
          <mat-checkbox #statusBoxes *ngFor="let status of orderStatuses" (change)="onStatusChange($event)" [value]="status">
            {{ status | replace | titlecase }}
          </mat-checkbox>
        </div>

        <mat-radio-group formControlName="type">
          <span class="check-radio-label">Type</span>
          <mat-radio-button *ngFor="let type of orderTypes" [value]="type">
            {{ type | replace | titlecase }}
          </mat-radio-button>
        </mat-radio-group>

        <div fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="25px" fxLayoutAlign.gt-xs="start center" fxLayout.xs="column" fxLayoutGap.xs="15px">
          <mat-form-field>
            <input matInput [matDatepicker]="startDatePicker" placeholder="Updated Date (Start)" formControlName="startDate" [max]="orderSearchForm.get('endDate').value">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="orderSearchForm.get('startDate').invalid">{{ getErrorMessage(orderSearchForm.get('startDate')) }}</mat-error>
          </mat-form-field>
          <mat-form-field>
            <input matInput [matDatepicker]="endDatePicker" placeholder="Updated Date (End)" formControlName="endDate" [min]="orderSearchForm.get('startDate').value">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
            <mat-error *ngIf="orderSearchForm.get('endDate').invalid">{{ getErrorMessage(orderSearchForm.get('endDate')) }}</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="!isSearching" fxLayout="row" fxLayoutGap="10px">
          <button id="submit-order-search" [disabled]="orderSearchForm.invalid" aria-label="Search" type="submit" mat-raised-button color="accent">Search</button>
          <button type="button" id="clear-order-search" *ngIf="!orderSearchForm.pristine" aria-label="Reset" (click)="reset()" mat-stroked-button color="accent">Reset</button>
        </div>
        <div *ngIf="isSearching" fxLayout="row" fxLayoutGap="10px">
          <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
        </div>

      </form>

      <!-- Display Orders list screen below OrderSearch -->
      <div *ngIf="showOrders && !errorMessage" class="order-list">
        <hms-orders (orderListChange)="handleOrderListChange($event)" (orderSearchError)="handleOrderListError($event)" (isSearching)="handleIsSearching($event)" [selectedOrders$]="selectedOrders$" [initialTableState]="tableState"></hms-orders>
      </div>

    </mat-card>
  </div>

</div>
