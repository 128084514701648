<div class="root-container" fxFlexFill>
  <mat-toolbar class="toolbar" fxLayout="row">
    <button data-cy="toggle-nav-btn" type="button" *ngIf="!isRegistering" id="toggle-nav" aria-label="Toggle Nav" mat-icon-button (click)="sidenav.toggle()" class="menu"><mat-icon>menu</mat-icon></button>
    <img src="assets/ATI_Leaf_color.png" class="ati-logo" alt="ATI Logo">
    <span class="fill-remaining-space"></span>
    <span *ngIf="authService.userProfile?.app_metadata.marketDataFrequency && hmsClientSettings$ | async as hmsClientSettings">
      <button type="button" id="quotes-menu" aria-label="Open Quotes Menu" mat-icon-button [matMenuTriggerFor]="quotesMenu">
        <mat-icon svgIcon="quotes" class="quotes-icon" matTooltip="Quotes"></mat-icon>
      </button>
      <mat-menu #quotesMenu="matMenu">
        <button mat-menu-item *ngFor="let selectedOption of marketDropdownOptions" id="open-market-dropdown-options" aria-label="Open Market Dropdown Options"
                              (click)="handleMarketDropdown(selectedOption)">
          {{ selectedOption }}
        </button>
      </mat-menu>
    </span>
    <span *ngIf="!isRegistering" (click)="handleRightSheet(ALERTS)">
      <button type="button" id="alerts-btn" aria-label="Open Alerts" mat-icon-button>
        <mat-icon class="alerts-icon"
                  [matBadge]="(newAlerts$ | async)?.length"
                  [matBadgeHidden]="(newAlerts$ | async)?.length === 0"
                  matBadgeSize="small"
                  matBadgeColor="warn"
                  matBadgePosition="above after"
                  matTooltip="Alerts"
                  fontSet="material-icons-outlined">notifications</mat-icon>
      </button>
    </span>
    <span>
      <button data-cy="profile-btn" type="button" *ngIf="isXSmall" id="profile-menu-btn" aria-label="Open Profile Menu" mat-icon-button [matMenuTriggerFor]="profile"><mat-icon>more_vert</mat-icon></button>
      <button data-cy="profile-btn" type="button" *ngIf="!isXSmall" id="profile-menu-btn" aria-label="Open Profile Menu" mat-button [matMenuTriggerFor]="profile"><img class="profile-picture" [src]="authService?.userProfile?.picture || 'assets/nopicture.png'" alt="Profile Picture"></button>
      <mat-menu id="profile-menu" #profile="matMenu">
        <ng-container *ngIf="!isRegistering && (loggedInUser$ | async) as user">
          <h4 id="welcome-item">{{user.firstName}} {{user.lastName}}</h4>
          <mat-divider></mat-divider>
          <a mat-menu-item id="profile-item" (click)="navigateToProfile()">Profile</a>
        </ng-container>
        <a mat-menu-item id="about-item" (click)="showAbout()">About HMS</a>
        <a mat-menu-item id="login-item" (click)="authService.login()" *ngIf="!authService?.isLoggedIn">Login</a>
        <a data-cy="logout-item" mat-menu-item id="logout-item" (click)="navigateToLogout()" *ngIf="authService?.isLoggedIn">Logout</a>
      </mat-menu>
    </span>
    <audio *ngIf="(newAlerts$ | async)?.length > 0 && !mutedTime" autoplay loop src="https://storage.googleapis.com/ati-static/Twinkles.wav" type="audio/wav"></audio>
  </mat-toolbar>
  <mat-sidenav-container class="nav-container">
    <mat-sidenav #sidenav data-cy="sidenav" fxLayout="column" [(opened)]="sideNavOpened" [mode]="sideNavMode" class="nav-menu" fxFlex.xs="65vw" fxFlex.sm="35vw" fxFlex.md="25vw" fxFlex.gt-md="15vw">
      <ng-container *ngIf="selectedClient$ | async as selectedClient">
        <ng-container *ngIf="selectedClient === undefined">
          <div class="nav-error" fxLayout="column" fxLayoutAlign="start center">
            <mat-icon class="error-icon" color="warn">error_outline</mat-icon>
            <p>Error Loading Navigation</p>
          </div>
        </ng-container>
        <ng-container *ngIf="selectedClient">
          <div class="nav-client-logo" fxLayout="column" fxLayoutAlign="start center">
            <img [src]="'https://storage.googleapis.com/ati-static/hms/nav-logo/' + selectedClient?.docId + '.png'" [alt]="selectedClient.name + ' Logo'">
          </div>
          <mat-divider></mat-divider>
          <mat-nav-list>
            <a routerLink="/home" matLine (click)="handleSideNavigation()">
              <mat-list-item routerLinkActive="active" class="nav-menu-item">
                <mat-icon fontSet="material-icons-outlined" mat-list-icon>home</mat-icon>
                Home
                <mat-divider></mat-divider>
              </mat-list-item>
            </a>
            <mat-expansion-panel data-cy="live-ledgers-nav" *ngIf="authService?.userProfile && (canViewLiveLedgers() || canViewLedgerAdjustments())" class="nav-menu-item mat-elevation-z0" [expanded]="ledgerExpanded" (opened)="settingsExpanded = false; ledgerExpanded = true; reportsExpanded = false">
              <mat-expansion-panel-header class="nav-menu-expansion-panel-header" collapsedHeight="56px">
                <mat-panel-title><mat-icon fontSet="material-icons-outlined" mat-list-icon>playlist_play</mat-icon><span data-cy="live-ledgers-nav-link" class="mat-body">Live Ledgers</span></mat-panel-title>
              </mat-expansion-panel-header>
              <mat-divider></mat-divider>
              <mat-nav-list>
                <a data-cy="activity-ledger-nav-link" routerLink="/liveledgers/activityledger" *ngIf="authService?.userProfile && canViewLiveLedgers()" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Activity Ledger
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a data-cy="positions-ledger-nav-link" routerLink="/liveledgers/positionsledger" *ngIf="authService?.userProfile && canViewLiveLedgers()" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Positions Ledger
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a data-cy="basis-ledger-nav-link" routerLink="/liveledgers/basisledger" *ngIf="authService?.userProfile && canViewLiveLedgers()" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Basis Ledger
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a data-cy="ledger-adjustments-nav-link" routerLink="/liveledgers/ledgeradjustments" *ngIf="authService?.userProfile && canViewLedgerAdjustments()" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Ledger Adjustments
                  </mat-list-item>
                </a>
              </mat-nav-list>
            </mat-expansion-panel>
            <mat-divider></mat-divider>
            <a data-cy="contracts-nav-link" routerLink="/contracts" *ngIf="authService?.userProfile && canViewContractSearch()" matLine (click)="handleSideNavigation()">
              <mat-list-item routerLinkActive="active" class="nav-menu-item">
                <mat-icon svgIcon="contracts" mat-list-icon></mat-icon>
                Contracts
                <mat-divider></mat-divider>
              </mat-list-item>
            </a>
            <a data-cy="targets-nav-link" routerLink="/targets" *ngIf="authService?.userProfile && canViewContractSearch()" matLine (click)="handleSideNavigation()">
              <mat-list-item routerLinkActive="active" class="nav-menu-item">
                <mat-icon svgIcon="target" mat-list-icon></mat-icon>
                Targets
                <mat-divider></mat-divider>
              </mat-list-item>
            </a>
            <a data-cy="orders-nav-link" routerLink="/orders" *ngIf="authService?.userProfile && canViewOrders()" matLine (click)="handleSideNavigation()">
              <mat-list-item routerLinkActive="active" class="nav-menu-item">
                <mat-icon fontSet="material-icons-outlined" mat-list-icon>receipt</mat-icon>
                Orders
                <mat-divider></mat-divider>
              </mat-list-item>
            </a>
            <a data-cy="hedges-nav-link" routerLink="/hedges" *ngIf="authService?.userProfile && canViewHedges()" matLine (click)="handleSideNavigation()">
              <mat-list-item routerLinkActive="active" class="nav-menu-item">
                <mat-icon svgIcon="hedge" mat-list-icon></mat-icon>
                Hedges
                <mat-divider></mat-divider>
              </mat-list-item>
            </a>
            <a data-cy="ad-hoc-orders-nav-link" routerLink="/adhocorders" *ngIf="authService?.userProfile && canViewAdHocOrders()" matLine (click)="handleSideNavigation()">
              <mat-list-item routerLinkActive="active" class="nav-menu-item">
                <mat-icon fontSet="material-icons-outlined" mat-list-icon>post_add</mat-icon>
                Ad Hoc Orders
                <mat-divider></mat-divider>
              </mat-list-item>
            </a>
            <a data-cy="basis-nav-link"routerLink="/basisadmin" *ngIf="authService?.userProfile && canViewBasisAdmin()" matLine (click)="handleSideNavigation()">
              <mat-list-item routerLinkActive="active" class="nav-menu-item">
                <mat-icon fontSet="material-icons-outlined" mat-list-icon>view_list</mat-icon>
                Basis
                <mat-divider></mat-divider>
              </mat-list-item>
            </a>
            <a data-cy="cash-bids-nav-link" routerLink="/bids" matLine (click)="handleSideNavigation()">
              <mat-list-item routerLinkActive="active" class="nav-menu-item">
                <mat-icon fontSet="material-icons-outlined" mat-list-icon>local_atm</mat-icon>
                Cash Bids
                <mat-divider></mat-divider>
              </mat-list-item>
            </a>
            <mat-expansion-panel data-cy="reports-nav" *ngIf="authService?.userProfile && canViewReports()" class="nav-menu-item mat-elevation-z0" [expanded]="reportsExpanded" (opened)="settingsExpanded = false; ledgerExpanded = false; reportsExpanded = true">
              <mat-expansion-panel-header class="nav-menu-expansion-panel-header" collapsedHeight="56px">
                <mat-panel-title><mat-icon fontSet="material-icons-outlined" mat-list-icon>assessment</mat-icon><span class="mat-body">Reports</span></mat-panel-title>
              </mat-expansion-panel-header>
              <mat-divider></mat-divider>
              <mat-nav-list>
                <a data-cy="closest-to-market-nav-link" *ngIf="canViewContractReports()" routerLink="/reports/closesttomarket" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Closest to Market
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a data-cy="expiring-contracts-nav-link" *ngIf="canViewContractReports()" routerLink="/reports/expiringcontracts" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Expiring Contracts
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a data-cy="future-delivery-nav-link" *ngIf="canViewMerchandisingReports()" routerLink="/reports/futuredelivery" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Future Delivery
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a data-cy="monthly-roll-nav-link" *ngIf="canViewContractReports()" routerLink="/reports/monthlyroll" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Monthly Roll
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a data-cy="on-demand-quotes-nav-link" *ngIf="canViewOperationsReports()" routerLink="/reports/ondemandquotes" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    On-Demand Quotes
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a data-cy="order-fill-nav-link" *ngIf="canViewOperationsReports()" routerLink="/reports/orderfill" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Order Fill
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a data-cy="purchases-sales-nav-link" *ngIf="canViewMerchandisingReports()" routerLink="/reports/purchasesandsales" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Purchases & Sales
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a *ngIf="canViewOperationsReports()" routerLink="/reports/reconciliation" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Reconciliation
                  </mat-list-item>
                </a>
              </mat-nav-list>
            </mat-expansion-panel>
            <mat-divider></mat-divider>
            <mat-expansion-panel data-cy="settings-nav" class="nav-menu-item mat-elevation-z0" [expanded]="settingsExpanded" (opened)="settingsExpanded = true; ledgerExpanded = false; reportsExpanded = false">
              <mat-expansion-panel-header class="nav-menu-expansion-panel-header" collapsedHeight="56px">
                <mat-panel-title><mat-icon fontSet="material-icons-outlined" mat-list-icon>settings</mat-icon><span class="mat-body">Settings</span></mat-panel-title>
              </mat-expansion-panel-header>
              <mat-divider></mat-divider>
              <mat-nav-list>
                <a data-cy="batch-roll-nav-link" routerLink="/settings/batchroll" *ngIf="authService?.userProfile && canViewBatchRoll()" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Batch Roll
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a data-cy="client-settings-nav-link" routerLink="/settings/client" *ngIf="authService?.userProfile && canViewClientSettings()" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Client Settings
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a data-cy="commodity-profiles-nav-link" routerLink="/settings/commodityprofiles" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Commodity Profiles
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a data-cy="locations-nav-link" routerLink="/settings/locations" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Locations
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a data-cy="patrons-nav-link" routerLink="/settings/patrons" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Patrons
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a data-cy="price-adjustments-nav-link" routerLink="/settings/priceadjustments" *ngIf="authService?.userProfile && canViewPriceAdjustments()" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Price Adjustments
                    <mat-divider></mat-divider>
                  </mat-list-item>
                </a>
                <a data-cy="users-nav-link" routerLink="/settings/users" matLine (click)="handleSideNavigation()">
                  <mat-list-item routerLinkActive="active" class="nav-menu-item">
                    <mat-icon fontSet="material-icons-outlined" mat-list-icon>keyboard_arrow_right</mat-icon>
                    Users
                  </mat-list-item>
                </a>
              </mat-nav-list>
            </mat-expansion-panel>
            <mat-divider></mat-divider>
          </mat-nav-list>
          <span class="fill-remaining-space"></span>
          <ng-container *ngIf="authorizedClients$ | async as authorizedClients">
            <mat-form-field *ngIf="authorizedClients?.length > 1" class="client-selection">
              <mat-label>Client Selector</mat-label>
              <mat-select [ngModel]="selectedClient.docId" (ngModelChange)="updateSelectedClient($event)">
                <mat-option *ngFor="let authorizedClient of authorizedClients" [value]="authorizedClient?.docId">{{ authorizedClient?.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </ng-container>
      </ng-container>
    </mat-sidenav>
    <mat-sidenav-content fxLayout="column" class="nav-content" fxFlex="grow">
      <router-outlet *ngIf="!errorMessage && (authGuard.loading$ | async) === false"></router-outlet>
      <div fxLayoutAlign="center center" fxLayout="column" fxFlex="90" *ngIf="!errorMessage && authGuard.loading$ | async">
        <mat-spinner diameter="60" color="accent"></mat-spinner>
        <p>Loading...</p>
      </div>
      <div fxLayoutAlign="space-around start" fxLayout="row" *ngIf="errorMessage">
        <mat-card fxFlex.lt-md="95" fxFlex.md="75" fxFlex.gt-md="65">
          <div>
            <hms-error-message [title]="'Error Loading HMS'" [message]="errorMessage" ></hms-error-message>
          </div>
        </mat-card>
      </div>
      <hms-speed-dial-fab *ngIf="!isRegistering && authService?.userProfile && fabButtons.length" [tooltip]="tooltip" [fabButtons]="fabButtons"></hms-speed-dial-fab>
    </mat-sidenav-content>
      <mat-sidenav #rightSheet [ngClass]="getRightSheetClass()" fxLayout="column" [autoFocus]="false"
                  [(opened)]="rightSheetOpened" mode="over" position="end" class="nav-menu alerts-pane">

      <ng-container *ngIf="rightSheetDisplay === CASHBIDS">
        <hms-selectable-bids></hms-selectable-bids>
      </ng-container>

      <ng-container *ngIf="rightSheetDisplay === QUOTES && authService.userProfile?.app_metadata.marketDataFrequency">
        <hms-side-nav-quotes></hms-side-nav-quotes>
      </ng-container>

      <ng-container *ngIf="rightSheetDisplay === ALERTS">
        <div fxLayout="row" fxLayoutAlign="start center">
          <h4 class="alerts-title">Alerts</h4>
          <span class="fill-remaining-space"></span>
          <span class="mat-caption" *ngIf="mutedTime">Silenced until {{mutedTime}}</span>
          <span *ngIf="mutedTime">
            <button type="button" id="cancel-alert-silence" mat-icon-button aria-label="Cancel Silence" matTooltip="Cancel Silence" (click)="mutedTime = ''">
              <mat-icon>close</mat-icon>
            </button>
          </span>
          <span>
            <button *ngIf="!mutedTime"
              id="silence-alert-btn"
              mat-icon-button aria-label="Silence Alert"
              matTooltip="Silence Alert"
              [matMenuTriggerFor]="silenceMenu"
              #silenceTrigger="matMenuTrigger">
              <mat-icon>volume_off</mat-icon>
            </button>
            <mat-menu id="silence-menu" #silenceMenu="matMenu">
              <span mat-menu-item id="silence-30-item" (click)="silenceAlert(30)">For 30 min</span>
              <span mat-menu-item id="silence-custom-item" (click)="$event.stopPropagation()">
                <form [formGroup]="silenceAlertForm" (ngSubmit)="silenceAlertCustomTime(silenceAlertForm.get('silenceUntilTime').value)">
                  <mat-form-field>
                    <input matInput [ngxTimepicker]="silencePicker" placeholder="Until Time" formControlName="silenceUntilTime" [disableClick]="true">
                    <ngx-material-timepicker-toggle class="not-so-high" matSuffix [for]="silencePicker">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16px" height="16px" ngxMaterialTimepickerToggleIcon preserveAspectRatio="xMidYMid meet">
                        <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003                   6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 6 L 11 12.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13 11.585938 L 13 6 L 11 6 z"/>
                      </svg>
                    </ngx-material-timepicker-toggle>
                    <ngx-material-timepicker #silencePicker [preventOverlayClick]="false" [defaultTime]="silenceAlertDefaultTime" [theme]="hmsTheme" (timeSet)="silenceAlertCustomTime($event)" (opened)="silenceTrigger.closeMenu()"></ngx-material-timepicker>
                  </mat-form-field>
                </form>
              </span>
            </mat-menu>
          </span>
        </div>
        <mat-divider class="alerts-thick-divider"></mat-divider>
        <mat-list class="alerts-list">
          <h3 mat-subheader>New ({{(newAlerts$ | async)?.length}})</h3>
          <div *ngFor="let newAlert of newAlerts$ | async">
            <mat-list-item class="notification-item-new notification-item">
              <div fxLayout="row" fxFill fxLayoutAlign="space-between center">
                <div class="notification-item-clickarea" fxLayout="column" (click)="navigateToAlert(newAlert)">
                  <div class="mat-body-strong">{{newAlert.title}}</div>
                  <div class="mat-caption">{{newAlert.subtitle}}</div>
                  <div class="mat-caption notification-item-timestamp">{{newAlert.lastUpdatedTimestamp | date : 'short'}}</div>
                </div>
                <div class="notification-item-buttons" fxLayout="row" fxLayoutAlign="end center">
                  <button type="button" id="mark-alert-complete" mat-icon-button aria-label="Mark Complete" matTooltip="Mark Complete" (click)="markAlertComplete(newAlert.docId)"><mat-icon>done</mat-icon></button>
                </div>
              </div>
            </mat-list-item>
            <mat-divider></mat-divider>
          </div>
          <h3 mat-subheader>In Progress ({{(inProgressAlerts$ | async)?.length}})</h3>
          <div *ngFor="let inProgressAlert of inProgressAlerts$ | async">
            <mat-list-item class="notification-item-new notification-item">
              <div fxLayout="row" fxFill fxLayoutAlign="space-between center">
                <div class="notification-item-clickarea" fxLayout="column" (click)="navigateToAlert(inProgressAlert)">
                  <div class="mat-body-strong">{{inProgressAlert.title}}</div>
                  <div class="mat-caption">{{inProgressAlert.subtitle}}</div>
                  <div class="mat-caption notification-item-timestamp">{{inProgressAlert.lastUpdatedTimestamp | date : 'short'}} - {{inProgressAlert.lastUpdatedByName}}</div>
                </div>
                <div class="notification-item-buttons" fxLayout="row" fxLayoutAlign="end center">
                  <button type="button" id="revert-alert-new" class="revert-button" mat-icon-button aria-label="Revert to New" matTooltip="Revert to New" (click)="markAlertNew(inProgressAlert.docId)"><mat-icon>undo</mat-icon></button>
                  <button type="button" id="mark-alert-complete" mat-icon-button aria-label="Mark Complete" matTooltip="Mark Complete" (click)="markAlertComplete(inProgressAlert.docId)"><mat-icon>done</mat-icon></button>
                </div>
              </div>
            </mat-list-item>
            <mat-divider></mat-divider>
          </div>
          <h3 mat-subheader>Recently Completed ({{(completedAlerts$ | async)?.length}})</h3>
          <div *ngFor="let completedAlert of completedAlerts$ | async">
            <mat-list-item class="notification-item-complete notification-item">
              <div fxLayout="row" fxFill fxLayoutAlign="space-between center">
                <div class="notification-item-clickarea" fxLayout="column" (click)="navigateToAlertNoId(completedAlert.actionUri)">
                  <div class="mat-body-strong">{{completedAlert.title}}</div>
                  <div class="mat-caption">{{completedAlert.subtitle}}</div>
                  <div class="mat-caption notification-item-timestamp">{{completedAlert.lastUpdatedTimestamp | date : 'short'}} - {{completedAlert.lastUpdatedByName}}</div>
                </div>
                <div class="notification-item-buttons" fxLayout="row" fxLayoutAlign="end center">
                  <button type="button" id="revert-alert-in-progress" class="revert-button" mat-icon-button aria-label="Revert to In Progress" matTooltip="Revert to In Progress" (click)="markAlertInProgress(completedAlert.docId)"><mat-icon>undo</mat-icon></button>
                </div>
              </div>
            </mat-list-item>
            <mat-divider></mat-divider>
          </div>
          <div fxLayout="row" fxLayoutAlign="center start">
            <button type="button" *ngIf="userAlertRoles.length > 0" id="search-alerts-btn" mat-button color="primary" aria-label="Search Alerts" matTooltip="Search Alerts" (click)="navigateToAlertsSearch()">More</button>
          </div>
        </mat-list>
      </ng-container>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
