import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

import { AuthService } from '@advance-trading/angular-ati-security';
import { HMSUserSettings, User } from '@advance-trading/ops-data-lib';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  constructor(
    private db: AngularFirestore,
    private authService: AuthService
  ) {}

  /**
   * Return the HMSUserSettings document for the specified User
   *
   * @param userDocId The docId of the User for which to retrieve HMS settings
   */
  getHmsSettingsByUserDocId(userDocId: string): Observable<HMSUserSettings> {
    return this.db.doc<HMSUserSettings>(`${User.getDataPath(userDocId)}/${HMSUserSettings.getDataPath()}`)
      .valueChanges();
  }

  /**
   * Return the HMSUserSettings document for users with an accountingSystemId for the specified Client
   *
   * @param clientDocId The clientDocId of one of the authorizedClientDocIds of HMSUserSettings to be retrieved
   */
  getHmsUserSettingsForClientUsers(clientDocId: string): Observable<HMSUserSettings[]> {
    return this.db.collectionGroup<HMSUserSettings>(`UserSettings`,
      ref => ref.where('accountingSystemId', '>', '')
      .where('authorizedClientDocIds', 'array-contains', clientDocId)
      .where('docId', '==', 'hmsUserSettings'))
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return the uncached HMSUserSettings document for users with an accountingSystemId for the specified Client
   * issue link: https://github.com/angular/angularfire/issues/2012
   *
   * @param clientDocId The clientDocId of one of the authorizedClientDocIds of HMSUserSettings to be retrieved
   */
  getUncachedHmsUserSettingsForClientUsers(clientDocId: string): Observable<HMSUserSettings[]> {
    return this.db.collectionGroup<HMSUserSettings>(`UserSettings`,
      ref => ref.where('accountingSystemId', '>', '')
      .where('authorizedClientDocIds', 'array-contains', clientDocId)
      .where('docId', '==', 'hmsUserSettings'))
      .snapshotChanges().pipe(
        filter(snapshot => {
          let shouldEmit = false;
          snapshot.forEach(doc => {
            if (!doc.payload.doc.metadata.fromCache) {
              shouldEmit = true;
            }
          });
          return shouldEmit;
        }),
        map(snapshot => snapshot.map(doc => doc.payload.doc.data() as HMSUserSettings)),
        shareReplay({bufferSize: 1, refCount: true})
      );
  }

  /**
   * Set HMSUserSettings document for the specified User
   *
   * @param userDocId The docId of the User for which to update HMS settings
   * @param hmsUserSettings The HMSUserSettings object
   */
  setHmsSettingsByUserDocId(userDocId: string, hmsUserSettings: HMSUserSettings): Promise<void> {
    hmsUserSettings.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.doc<HMSUserSettings>(`${User.getDataPath(userDocId)}/${HMSUserSettings.getDataPath()}`)
      .set(hmsUserSettings.getPlainObject());
  }

}
