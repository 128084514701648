<div fxLayout="row" fxLayoutAlign="space-around start">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <div *ngIf="errorMessage">
      <h2 class="card-title">Error Retrieving Reconciliation Report</h2>
      <p [innerHTML]="errorMessage"></p>
      <p>If you have questions, please contact us by <a href="mailto:support@advance-trading.com">email</a>
          or call us at <a href="tel:800-747-9021">800-747-9021</a></p>
    </div>

    <div *ngIf="!errorMessage && commodityProfiles$ | async" fxLayout="column">
      <h2 class="card-title">Reconciliation Report</h2>
      <form [formGroup]="reconciliationSearchForm" (ngSubmit)="scrollFocus = '';loadReconciliationReportData();" fxLayout="column">
        <div fxLayoutGap.gt-xs="25px" fxLayout.xs="column">
          <mat-form-field fxFlex.gt-xs="250px">
            <input matInput [matDatepicker]="effectiveDatePicker" placeholder="Effective Date" formControlName="effectiveDate" [max]="currentBusinessDay">
            <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #effectiveDatePicker></mat-datepicker>
            <mat-error *ngIf="reconciliationSearchForm.get('effectiveDate').invalid">{{ getErrorMessage(reconciliationSearchForm.get('effectiveDate')) }}</mat-error>
          </mat-form-field>
        </div>
        <div class="mat-small">Note: Quantities are displayed by Delivery Location. Dates begin and end on the Ledger End of Day ({{ ledgerEndOfDayLocal }}) not midnight.</div>
        <div class="mat-small bottom-gap"><span class="beta-label inline">BETA</span>Beta features may be incomplete or change in the future.</div>
        <div *ngIf="!isLoading" fxLayout="row" fxLayoutGap="10px" class="bottom-gap">
          <button id="generate-purchases-and-sales-report" [disabled]="reconciliationSearchForm.invalid" aria-label="Generate Report" type="submit" mat-raised-button color="accent">Generate Report</button>
          <button type="button" id="clear-purchases-and-sales-report" *ngIf="!reconciliationSearchForm.pristine" aria-label="Discard Report Options" (click)="reset()" mat-stroked-button color="accent">Reset</button>
        </div>
        <div *ngIf="isLoading" fxLayout="row" fxLayoutGap="10px">
          <mat-spinner class="loading-spinner" diameter="40" color="accent"></mat-spinner>
        </div>

      </form>
      <mat-tab-group *ngIf="displayReports" #tabGroup (selectedTabChange)="onTabChange($event)" (animationDone)="onAnimationDone()" dynamicHeight>
        <mat-tab label="Contracts and Pricings" fxFlex="100">
          <div *ngIf="showMini && displayReports" id="client-summary-mini" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10" class="full-width">
            <h4 class="section-head">Client Summary</h4>
            <table mat-table [dataSource]="clientMiniDataSource" class="mat-elevation-z0 full-width" multiTemplateDataRows>

              <ng-container matColumnDef="commodityProfile">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstProdYear(clientMiniRow) ? 'thick location mat-cell rotate' : 'dummy'}}" [attr.rowspan]="productionYearCount" ><div>{{ clientMiniRow.commodityProfileName }}</div></td>
              </ng-container>

              <ng-container matColumnDef="productionYear">
                <th mat-header-cell *matHeaderCellDef class="centered">Production Year</th>
                <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstProdYear(clientMiniRow) ? 'thick mat-cell commodity-profile-mini' : 'mat-cell commodity-profile-mini'}}">{{ clientMiniRow.productionYear | productionYear }}</td>
              </ng-container>

              <ng-container matColumnDef="BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstProdYear(clientMiniRow) ? 'thick mat-cell number-cell-mini' : 'mat-cell number-cell-mini'}}">{{ getDisplayAmount(clientMiniRow.BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstProdYear(clientMiniRow) ? 'thick mat-cell number-cell-mini' : 'mat-cell number-cell-mini'}}">{{ getDisplayAmount(clientMiniRow.SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="noDataFooter">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="clientMiniColumnsToDisplay.length">No Contracts or Pricings</td>
              </ng-container>

              <tr mat-row *matHeaderRowDef="clientMiniColumnsToDisplay"></tr>
              <tr mat-row *matRowDef="let clientMiniRow; columns: clientMiniColumnsToDisplay"></tr>
              <tr mat-row *matFooterRowDef="['noDataFooter']" [style.display]="clientMiniDataSource.data.length ? 'none' : 'table-row'"></tr>
            </table>
          </div>
          <div *ngIf="!showMini && displayReports" id="client-summary-large" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10">
            <h4 class="section-head">Client Summary</h4>
            <table mat-table [dataSource]="clientDataSource" class="mat-elevation-z0" multiTemplateDataRows>

              <ng-container matColumnDef="commodityProfileHeader">
                <th mat-header-cell *matHeaderCellDef rowspan="2" class="mat-cell commodity-profile"></th>
              </ng-container>

              <ng-container [matColumnDef]="label" *ngFor="let label of allProductionYears">
                <th mat-header-cell *matHeaderCellDef colspan="2" class="centered">{{ label | productionYear }}</th>
              </ng-container>

              <ng-container matColumnDef="commodityProfileName">
                <th class="dummy" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let clientRow" class="mat-cell commodity-profile">{{ clientRow.commodityProfileName }}</td>
              </ng-container>

              <ng-container matColumnDef="OLD_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.OLD_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="OLD_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.OLD_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_1_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_PLUS_1_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_1_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_PLUS_1_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_2_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_PLUS_2_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_2_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_PLUS_2_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="noDataFooter">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="clientColumnsToDisplay.length">No Contracts or Pricings</td>
              </ng-container>

              <tr mat-row *matHeaderRowDef="clientParentHeaders"></tr>
              <tr mat-row *matHeaderRowDef="clientColumnsToDisplay"></tr>
              <tr mat-row *matRowDef="let clientRow; columns: clientColumnsToDisplay"></tr>
              <tr mat-row *matFooterRowDef="['noDataFooter']" [style.display]="clientDataSource.data.length ? 'none' : 'table-row'"></tr>
            </table>
          </div>
          <div *ngIf="!showMini && displayReports" id="location-summary" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10">
            <h4 class="section-head">Delivery Location Summary
              <button matTooltip="Export" aria-label="Export" id="export-purchase-sales-report" mat-icon-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button"><mat-icon>save_alt</mat-icon></button>
            </h4>
            <mat-menu #exportMenu="matMenu">
              <button type="button" aria-label="Export Excel" id="export-purchase-sales-report-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'P&S Report'))">Excel</button>
              <button type="button" aria-label="Export CSV" id="export-purchase-sales-report-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'P&S Report'))">CSV</button>
              <button type="button" aria-label="Export JSON" id="export-purchase-sales-report-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'P&S Report'))">JSON</button>
            </mat-menu>

            <table matTableExporter #exporter="matTableExporter" mat-table [dataSource]="locationDataSource" class="mat-elevation-z0 location-table" multiTemplateDataRows>

              <ng-container matColumnDef="locationHeader">
                <th mat-header-cell *matHeaderCellDef rowspan="2"></th>
              </ng-container>

              <ng-container matColumnDef="commodityProfileHeader">
                <th mat-header-cell *matHeaderCellDef rowspan="2" class="commodity-profile"></th>
              </ng-container>

              <ng-container [matColumnDef]="label" *ngFor="let label of allProductionYears">
                <th mat-header-cell *matHeaderCellDef colspan="2" class="centered">{{ label | productionYear }}</th>
              </ng-container>

              <ng-container matColumnDef="commodityProfileName">
                <th class="dummy" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'thick mat-cell commodity-profile-mini clickable scroll-target' : 'mat-cell commodity-profile-mini'}}"><a *ngIf="isFirstProfile(locationRow)" id="{{ getSummaryAnchor(locationRow.locationName) }}"></a>{{ locationRow.commodityProfileName }}</td>
              </ng-container>

              <ng-container matColumnDef="locationName">
                <th class="dummy" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let locationRow" [attr.rowspan]="commodityProfileCount" class="{{ isFirstProfile(locationRow) ? 'thick mat-cell rotate clickable' : 'dummy'}}"><div>{{ locationRow.locationName }}</div></td>
              </ng-container>

              <ng-container matColumnDef="OLD_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.OLD_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="OLD_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.OLD_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_1_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_PLUS_1_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_1_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_PLUS_1_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_2_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_PLUS_2_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_2_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_PLUS_2_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="noDataFooter">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="locationColumnsToDisplay.length">No Contracts or Pricings</td>
              </ng-container>

              <tr mat-row *matHeaderRowDef="locationParentHeaders"></tr>
              <tr mat-row *matHeaderRowDef="locationColumnsToDisplay"></tr>
              <tr mat-row *matRowDef="let locationRow; columns: locationColumnsToDisplay" (click)="scrollTo(getFirstDetailId(locationRow.locationName, locationRow.commodityProfileName))"  [matTooltip]="getLocationRowToolTip(locationRow)" [attr.aria-label]="getLocationRowToolTip(locationRow)" [ngClass]="getLocationRowClassName(locationRow)"></tr>
              <tr mat-row *matFooterRowDef="['noDataFooter']" [style.display]="locationDataSource.data.length ? 'none' : 'table-row'"></tr>
            </table>
          </div>
          <div *ngIf="!showMini && displayReports" id="details" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10" class="detail-container">
            <h4 class="section-head">Details
              <button matTooltip="Export" aria-label="Export" id="export-purchase-sales-detail-report" mat-icon-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button"><mat-icon>save_alt</mat-icon></button>
            </h4>
            <mat-menu #exportMenu="matMenu">
              <button type="button" aria-label="Export Excel" id="export-purchase-sales-detail-report-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'P&S Report'))">Excel</button>
              <button type="button" aria-label="Export CSV" id="export-purchase-sales-detail-report-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'P&S Report'))">CSV</button>
              <button type="button" aria-label="Export JSON" id="export-purchase-sales-detail-report-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'P&S Report'))">JSON</button>
            </mat-menu>

            <table matTableExporter #exporter="matTableExporter" [hiddenColumns]="[9, 10]" mat-table [dataSource]="detailDataSource" class="mat-elevation-z0 detail-table full-width" multiTemplateDataRows>

              <ng-container matColumnDef="locationName">
                <th mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Location</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'locationName') }}"><div><a id="{{ getRowId(detailRow) }}"></a>{{ detailRow.timestamp === '' ? '' : detailRow.locationName }}</div></td>
              </ng-container>

              <ng-container matColumnDef="commodityProfileName">
                <th mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Commodity Profile</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'commodityProfileName') }}">{{ detailRow.timestamp === '' ? '' : detailRow.commodityProfileName }}</td>
              </ng-container>

              <ng-container matColumnDef="accountingSystemId">
                <th mat-header-cell *matHeaderCellDef>ID</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'accountingSystemId') }}">{{ detailRow.timestamp === '' ? detailRow.locationName : detailRow.accountingSystemId }}</td>
              </ng-container>

              <ng-container matColumnDef="contractOrPricing">
                <th mat-header-cell *matHeaderCellDef>Transaction</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'contractOrPricing') }}">{{ detailRow.timestamp === '' ? detailRow.commodityProfileName : detailRow.contractOrPricing }}</td>
              </ng-container>

              <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef>Date/Time</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'timestamp') }}">{{ detailRow.timestamp === 'Total' ? detailRow.timestamp : detailRow.timestamp | date: 'MM/dd/yyyy hh:mm:ss a' }}</td>
              </ng-container>

              <ng-container matColumnDef="contractType">
                <th mat-header-cell *matHeaderCellDef>Type</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'contractType') }}">{{ detailRow.contractType | contractType }}</td>
              </ng-container>

              <ng-container matColumnDef="originator">
                <th mat-header-cell *matHeaderCellDef>Originator</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'originator') }}">{{ detailRow.originator }}</td>
              </ng-container>

              <ng-container matColumnDef="deliveryPeriod">
                <th mat-header-cell *matHeaderCellDef>Del Period</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'deliveryPeriod') }}">{{ detailRow.deliveryPeriod }}</td>
              </ng-container>

              <ng-container matColumnDef="productionYear">
                <th mat-header-cell *matHeaderCellDef>Crop Year</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'productionYear') }}">{{ detailRow.productionYear | productionYear }}</td>
              </ng-container>

              <ng-container matColumnDef="displayBUY">
                <th mat-header-cell *matHeaderCellDef>Qty Bought</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'BUY') }}">
                  <div fxLayout="column">
                    <div fxFlex="100">{{ detailRow.timestamp === '' ? '' : detailRow.BUY | number:'1.0-2' }}</div>
                    <div fxFlex="100" *ngIf="detailRow.timestamp!=='' && detailRow.BUY && detailRow.originalQuantity" class="deleted">{{ detailRow.originalQuantity | number:'1.0-2' }}</div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="displaySELL">
                <th mat-header-cell *matHeaderCellDef>Qty Sold</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'SELL') }}">
                  <div fxLayout="column">
                    <div fxFlex="100">{{ detailRow.timestamp === '' ? '' : detailRow.SELL | number:'1.0-2' }}</div>
                    <div fxFlex="100" *ngIf="detailRow.timestamp!=='' && detailRow.SELL && detailRow.originalQuantity" class="deleted">{{ detailRow.originalQuantity | number:'1.0-2' }}</div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="BUY">
                <th mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Qty Bought</th>
                <td mat-cell *matCellDef="let detailRow" class="cdk-visually-hidden">{{ detailRow.timestamp === '' ? '' : detailRow.BUY | number:'1.0-2' }}</td>
              </ng-container>

              <ng-container matColumnDef="SELL">
                <th mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Qty Sold</th>
                <td mat-cell *matCellDef="let detailRow" class="cdk-visually-hidden">{{ detailRow.timestamp === '' ? '' : detailRow.SELL | number:'1.0-2' }}</td>
              </ng-container>

              <ng-container matColumnDef="originalBUY">
                <th mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Original Qty Bought</th>
                <td mat-cell *matCellDef="let detailRow" class="cdk-visually-hidden">{{ detailRow.timestamp === '' || !detailRow.originalQuantity ? '' : detailRow.BUY ? (detailRow.originalQuantity | number:'1.0-2') : '0' }}</td>
              </ng-container>

              <ng-container matColumnDef="originalSELL">
                <th mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Original Qty Sold</th>
                <td mat-cell *matCellDef="let detailRow" class="cdk-visually-hidden">{{ detailRow.timestamp === '' || !detailRow.originalQuantity ? '' : detailRow.SELL ? (detailRow.originalQuantity | number:'1.0-2') : '0' }}</td>
              </ng-container>

              <ng-container matColumnDef="basisPrice">
                <th mat-header-cell *matHeaderCellDef>Basis</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'basisPrice') }}">{{ detailRow.timestamp === ''  ? '' : detailRow.basisPrice | number:'1.2-4' }}</td>
              </ng-container>

              <ng-container matColumnDef="futuresPrice">
                <th mat-header-cell *matHeaderCellDef>Futures</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'futuresPrice') }}">{{ detailRow.timestamp === '' ? '' : detailRow.futuresPrice | number:'1.2-4' }}</td>
              </ng-container>

              <ng-container matColumnDef="cashPrice">
                <th mat-header-cell *matHeaderCellDef>Cash</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'cashPrice') }}">{{ detailRow.timestamp === '' ? '' : detailRow.cashPrice | number:'1.2-4' }}</td>
              </ng-container>

              <ng-container matColumnDef="patron">
                <th mat-header-cell *matHeaderCellDef>Patron</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'patron') }}">{{ detailRow.patron }}</td>
              </ng-container>

              <ng-container matColumnDef="comments">
                <th mat-header-cell *matHeaderCellDef>Comments</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'comments') }}">{{ detailRow.comments }}</td>
              </ng-container>

              <ng-container matColumnDef="noDataFooter">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="detailColumnsToDisplay.length">No Contracts or Pricings</td>
              </ng-container>

              <tr mat-row *matHeaderRowDef="detailColumnsToDisplay; sticky:true"></tr>
              <tr mat-row *matRowDef="let detailRow; columns: detailColumnsToDisplay" (click)="handleDetailRowClick(detailRow)" matTooltip="{{ getDetailToolTip(detailRow) }}" attr.aria-label="{{ getDetailToolTip(detailRow) }}" ngClass="has-data"></tr>
              <tr mat-row *matFooterRowDef="['noDataFooter']" [style.display]="detailDataSource.data.length ? 'none' : 'table-row'"></tr>
            </table>
          </div>
        </mat-tab>
        <mat-tab label="Other Transactions" fxFlex="100">
          <div *ngIf="showMini && displayReports" id="client-summary-mini-hedge" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10" class="full-width">
            <h4 class="section-head">Client Summary</h4>
            <table mat-table [dataSource]="clientMiniHedgeDataSource" class="mat-elevation-z0 full-width" multiTemplateDataRows>

              <ng-container matColumnDef="commodityProfile">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstProdYear(clientMiniRow) ? 'thick location mat-cell rotate' : 'dummy'}}" [attr.rowspan]="productionYearCount" ><div>{{ clientMiniRow.commodityProfileName }}</div></td>
              </ng-container>

              <ng-container matColumnDef="productionYear">
                <th mat-header-cell *matHeaderCellDef class="centered">Production Year</th>
                <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstProdYear(clientMiniRow) ? 'thick mat-cell commodity-profile-mini' : 'mat-cell commodity-profile-mini'}}">{{ clientMiniRow.productionYear | productionYear }}</td>
              </ng-container>

              <ng-container matColumnDef="BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstProdYear(clientMiniRow) ? 'thick mat-cell number-cell-mini' : 'mat-cell number-cell-mini'}}">{{ getDisplayAmount(clientMiniRow.BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let clientMiniRow" class="{{ isFirstProdYear(clientMiniRow) ? 'thick mat-cell number-cell-mini' : 'mat-cell number-cell-mini'}}">{{ getDisplayAmount(clientMiniRow.SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="noDataFooter">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="clientMiniColumnsToDisplay.length">No Orders, Exchanges, or Ledger Adjustments</td>
              </ng-container>

              <tr mat-row *matHeaderRowDef="clientMiniColumnsToDisplay"></tr>
              <tr mat-row *matRowDef="let clientMiniRow; columns: clientMiniColumnsToDisplay"></tr>
              <tr mat-row *matFooterRowDef="['noDataFooter']" [style.display]="clientMiniHedgeDataSource.data.length ? 'none' : 'table-row'"></tr>
            </table>
          </div>
          <div *ngIf="!showMini && displayReports" id="client-summary-large-hedge" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10">
            <h4 class="section-head">Client Summary</h4>
            <table mat-table [dataSource]="clientHedgeDataSource" class="mat-elevation-z0" multiTemplateDataRows>

              <ng-container matColumnDef="commodityProfileHeader">
                <th mat-header-cell *matHeaderCellDef rowspan="2" class="mat-cell commodity-profile"></th>
              </ng-container>

              <ng-container [matColumnDef]="label" *ngFor="let label of allProductionYears">
                <th mat-header-cell *matHeaderCellDef colspan="2" class="centered">{{ label | productionYear }}</th>
              </ng-container>

              <ng-container matColumnDef="commodityProfileName">
                <th class="dummy" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let clientRow" class="mat-cell commodity-profile">{{ clientRow.commodityProfileName }}</td>
              </ng-container>

              <ng-container matColumnDef="OLD_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.OLD_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="OLD_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.OLD_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_1_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_PLUS_1_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_1_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_PLUS_1_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_2_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_PLUS_2_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_2_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let clientRow" class="number-cell">{{ getDisplayAmount(clientRow.NEW_PLUS_2_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="noDataFooter">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="clientColumnsToDisplay.length">No Orders, Exchanges, or Ledger Adjustments</td>
              </ng-container>

              <tr mat-row *matHeaderRowDef="clientParentHeaders"></tr>
              <tr mat-row *matHeaderRowDef="clientColumnsToDisplay"></tr>
              <tr mat-row *matRowDef="let clientRow; columns: clientColumnsToDisplay"></tr>
              <tr mat-row *matFooterRowDef="['noDataFooter']" [style.display]="clientHedgeDataSource.data.length ? 'none' : 'table-row'"></tr>
            </table>
          </div>
          <div *ngIf="!showMini && displayReports" id="location-summary" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10">
            <h4 class="section-head">Transaction Type Summary
              <button matTooltip="Export" aria-label="Export" id="export-purchase-sales-adjustment-report" mat-icon-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button"><mat-icon>save_alt</mat-icon></button>
            </h4>
            <mat-menu #exportMenu="matMenu">
              <button type="button" aria-label="Export Excel" id="export-purchase-sales-adjustment-report-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'P&S Report'))">Excel</button>
              <button type="button" aria-label="Export CSV" id="export-purchase-sales-adjustment-report-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'P&S Report'))">CSV</button>
              <button type="button" aria-label="Export JSON" id="export-purchase-sales-adjustment-report-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'P&S Report'))">JSON</button>
            </mat-menu>

            <table matTableExporter #exporter="matTableExporter" mat-table [dataSource]="hedgeTypeDataSource" class="mat-elevation-z0 location-table" multiTemplateDataRows>

              <ng-container matColumnDef="locationHeader">
                <th mat-header-cell *matHeaderCellDef rowspan="2"></th>
              </ng-container>

              <ng-container matColumnDef="commodityProfileHeader">
                <th mat-header-cell *matHeaderCellDef rowspan="2" class="commodity-profile"></th>
              </ng-container>

              <ng-container [matColumnDef]="label" *ngFor="let label of allProductionYears">
                <th mat-header-cell *matHeaderCellDef colspan="2" class="centered">{{ label | productionYear }}</th>
              </ng-container>

              <ng-container matColumnDef="commodityProfileName">
                <th class="dummy" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstHedgeProfile(locationRow) ? 'thick mat-cell commodity-profile-mini clickable scroll-target' : 'mat-cell commodity-profile-mini'}}"><a *ngIf="isFirstHedgeProfile(locationRow)" id="{{ getSummaryAnchor(locationRow.locationName) }}"></a>{{ locationRow.commodityProfileName }}</td>
              </ng-container>

              <ng-container matColumnDef="locationName">
                <th class="dummy" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let locationRow" [attr.rowspan]="allCommodityProfileCount" class="{{ isFirstHedgeProfile(locationRow) ? 'thick mat-cell rotate clickable' : 'dummy'}}"><div>{{ locationRow.locationName }}</div></td>
              </ng-container>

              <ng-container matColumnDef="OLD_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstHedgeProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.OLD_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="OLD_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstHedgeProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.OLD_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstHedgeProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstHedgeProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_1_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstHedgeProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_PLUS_1_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_1_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstHedgeProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_PLUS_1_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_2_BUY">
                <th mat-header-cell *matHeaderCellDef class="centered">Bought</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstHedgeProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_PLUS_2_BUY) }}</td>
              </ng-container>

              <ng-container matColumnDef="NEW_PLUS_2_SELL">
                <th mat-header-cell *matHeaderCellDef class="centered">Sold</th>
                <td mat-cell *matCellDef="let locationRow" class="{{ isFirstHedgeProfile(locationRow) ? 'thick mat-cell number-cell' : 'mat-cell number-cell'}}">{{ getDisplayAmount(locationRow.NEW_PLUS_2_SELL) }}</td>
              </ng-container>

              <ng-container matColumnDef="noDataFooter">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="locationColumnsToDisplay.length">No Orders, Exchanges, or Ledger Adjustments</td>
              </ng-container>

              <tr mat-row *matHeaderRowDef="locationParentHeaders"></tr>
              <tr mat-row *matHeaderRowDef="locationColumnsToDisplay"></tr>
              <tr mat-row *matRowDef="let locationRow; columns: locationColumnsToDisplay" (click)="scrollTo(getFirstHedgeDetailId(locationRow.locationName, locationRow.commodityProfileName))" [matTooltip]="getLocationRowToolTip(locationRow)" [attr.aria-label]="getLocationRowToolTip(locationRow)" [ngClass]="getLocationRowClassName(locationRow)"></tr>
              <tr mat-row *matFooterRowDef="['noDataFooter']" [style.display]="hedgeTypeDataSource.data.length ? 'none' : 'table-row'"></tr>
            </table>
          </div>

          <div *ngIf="!showMini && displayReports" id="hedge-details" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10" class="detail-container">
            <h4 class="section-head">Details
              <button matTooltip="Export" aria-label="Export" id="export-purchase-sales-hedge-detail-report" mat-icon-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button"><mat-icon>save_alt</mat-icon></button>
            </h4>
            <mat-menu #exportMenu="matMenu">
              <button type="button" aria-label="Export Excel" id="export-purchase-sales-hedge-detail-report-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'P&S Report'))">Excel</button>
              <button type="button" aria-label="Export CSV" id="export-purchase-sales-hedge-detail-report-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'P&S Report'))">CSV</button>
              <button type="button" aria-label="Export JSON" id="export-purchase-sales-hedge-detail-report-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'P&S Report'))">JSON</button>
            </mat-menu>

            <table matTableExporter #exporter="matTableExporter" [hiddenColumns]="[9, 10]" mat-table [dataSource]="hedgeDetailDataSource" class="mat-elevation-z0 detail-table full-width" multiTemplateDataRows>

              <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef>Transaction Type</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'location') }}"><div><a id="{{ getRowId(detailRow) }}"></a>{{ detailRow.timestamp === 'Total' ? '' : detailRow.locationName }}</div></td>
              </ng-container>

              <ng-container matColumnDef="commodityProfile">
                <th mat-header-cell *matHeaderCellDef>Commodity Profile</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'commodityProfile') }}">{{ detailRow.timestamp === 'Total' ? '' : detailRow.commodityProfileName }}</td>
              </ng-container>

              <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef>Date/Time</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'timestamp') }}">{{ detailRow.timestamp === 'Total' ? detailRow.timestamp : detailRow.timestamp | date: 'MM/dd/yyyy hh:mm:ss a' }}</td>
              </ng-container>

              <ng-container matColumnDef="originator">
                <th mat-header-cell *matHeaderCellDef>Originator</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'originator') }}">{{ detailRow.originator }}</td>
              </ng-container>

              <ng-container matColumnDef="deliveryPeriod">
                <th mat-header-cell *matHeaderCellDef>Del Period</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'deliveryPeriod') }}">{{ detailRow.deliveryPeriod }}</td>
              </ng-container>

              <ng-container matColumnDef="productionYear">
                <th mat-header-cell *matHeaderCellDef>Crop Year</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'productionYear') }}">{{ detailRow.productionYear | productionYear }}</td>
              </ng-container>

             <ng-container matColumnDef="displayBUY">
                <th mat-header-cell *matHeaderCellDef>Qty Bought</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'BUY') }}">
                  <div fxLayout="column">
                    <div fxFlex="100">{{ detailRow.timestamp === '' ? '' : detailRow.BUY | number:'1.0-2' }}</div>
                    <div fxFlex="100" *ngIf="detailRow.timestamp!=='' && detailRow.BUY && detailRow.originalQuantity" class="deleted">{{ detailRow.originalQuantity | number:'1.0-2' }}</div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="displaySELL">
                <th mat-header-cell *matHeaderCellDef>Qty Sold</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'SELL') }}">
                  <div fxLayout="column">
                    <div fxFlex="100">{{ detailRow.timestamp === '' ? '' : detailRow.SELL | number:'1.0-2' }}</div>
                    <div fxFlex="100" *ngIf="detailRow.timestamp!=='' && detailRow.SELL && detailRow.originalQuantity" class="deleted">{{ detailRow.originalQuantity | number:'1.0-2' }}</div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="BUY">
                <th mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Qty Bought</th>
                <td mat-cell *matCellDef="let detailRow" class="cdk-visually-hidden">{{ detailRow.timestamp === '' ? '' : detailRow.BUY | number:'1.0-2' }}</td>
              </ng-container>

              <ng-container matColumnDef="SELL">
                <th mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Qty Sold</th>
                <td mat-cell *matCellDef="let detailRow" class="cdk-visually-hidden">{{ detailRow.timestamp === '' ? '' : detailRow.SELL | number:'1.0-2' }}</td>
              </ng-container>

              <ng-container matColumnDef="originalBUY">
                <th mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Original Qty Bought</th>
                <td mat-cell *matCellDef="let detailRow" class="cdk-visually-hidden">{{ detailRow.timestamp === '' || !detailRow.originalQuantity ? '' : detailRow.BUY ? (detailRow.originalQuantity | number:'1.0-2') : '0' }}</td>
              </ng-container>

              <ng-container matColumnDef="originalSELL">
                <th mat-header-cell *matHeaderCellDef class="cdk-visually-hidden">Original Qty Sold</th>
                <td mat-cell *matCellDef="let detailRow" class="cdk-visually-hidden">{{ detailRow.timestamp === '' || !detailRow.originalQuantity ? '' : detailRow.SELL ? (detailRow.originalQuantity | number:'1.0-2') : '0' }}</td>
              </ng-container>

               <ng-container matColumnDef="basisPrice">
                <th mat-header-cell *matHeaderCellDef>Basis</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'basisPrice') }}">{{ detailRow.timestamp === '' ? '' : detailRow.basisPrice | number:'1.2-4' }}</td>
              </ng-container>

              <ng-container matColumnDef="futuresPrice">
                <th mat-header-cell *matHeaderCellDef>Futures</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'futuresPrice') }}">{{ detailRow.timestamp === '' ? '' : detailRow.futuresPrice | number:'1.2-4' }}</td>
              </ng-container>

              <ng-container matColumnDef="cashPrice">
                <th mat-header-cell *matHeaderCellDef>Cash</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'cashPrice') }}">{{ detailRow.timestamp === '' ? '': detailRow.cashPrice | number:'1.2-4' }}</td>
              </ng-container>

              <ng-container matColumnDef="patron">
                <th mat-header-cell *matHeaderCellDef>Patron</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'patron') }}">{{ detailRow.patron }}</td>
              </ng-container>

              <ng-container matColumnDef="comments">
                <th mat-header-cell *matHeaderCellDef>Comments</th>
                <td mat-cell *matCellDef="let detailRow" class="{{ getDetailCellClassName(detailRow, 'comments') }}">{{ detailRow.comments }}</td>
              </ng-container>

              <ng-container matColumnDef="noDataFooter">
                <td mat-footer-cell *matFooterCellDef [attr.colspan]="detailColumnsToDisplay.length">No Orders, Exchanges, or Ledger Adjustments</td>
              </ng-container>

              <tr mat-row *matHeaderRowDef="hedgeDetailColumnsToDisplay; sticky: true"></tr>
              <tr mat-row *matRowDef="let detailRow; columns: hedgeDetailColumnsToDisplay" (click)="handleDetailRowClick(detailRow)" matTooltip="{{ getDetailToolTip(detailRow) }}" attr.aria-label="{{ getDetailToolTip(detailRow) }}" ngClass="has-data"></tr>
              <tr mat-row *matFooterRowDef="['noDataFooter']" [style.display]="hedgeDetailDataSource.data.length ? 'none' : 'table-row'"></tr>
            </table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

  </mat-card>
</div>

