<hms-error-message [title]="'Error Retrieving Client Billing Settings'" [message]="errorMessage"></hms-error-message>
<form [formGroup]="clientSettingsForm">
  <div *ngIf="!errorMessage && clientSettings" class="hms-client-settings-section billing-tab-body" fxLayout.gt-xs="row wrap" fxLayoutGap.gt-xs="20px"
    fxLayout.xs="column" fxLayoutGap.xs="15px">

    <div class="mat-small top-gap" fxFlex.gt-xs="100">
      Note: All fields are required if Billing Date is populated, set Billing Date to 0 for non-billed clients (all other fields will be cleared on submission)
    </div>

    <!-- Start Date -->
    <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="30" label="Start Date" [data]="getStartDate() | date: 'M/d/yyyy' ">
    </ati-labeled-data>
    <mat-form-field *ngIf="editMode" fxFlex.gt-xs="30">
      <input matInput [matDatepicker]="billingStartDate" placeholder="Start Date" formControlName="billingStartDate">
      <mat-datepicker-toggle matSuffix [for]="billingStartDate" data-cy="billingStartDate">
      </mat-datepicker-toggle>
      <mat-datepicker #billingStartDate panelClass="month-picker"></mat-datepicker>
      <mat-error *ngIf="clientSettingsForm.get('billingStartDate').invalid">
        {{ getErrorMessage(clientSettingsForm.get('billingStartDate')) }}
      </mat-error>
    </mat-form-field>

    <!-- Renewal Date -->
    <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="30" label="Renewal Date" [data]="getRenewalDate() | date: 'M/d/yyyy' ">
    </ati-labeled-data>
    <mat-form-field *ngIf="editMode" fxFlex.gt-xs="30">
      <input matInput [matDatepicker]="billingRenewalDate" [min]="tomorrow" placeholder="Renewal Date"
        formControlName="billingRenewalDate" [minDate]="tomorrow">
      <mat-datepicker-toggle matSuffix [for]="billingRenewalDate" data-cy="billingRenewalDate">
      </mat-datepicker-toggle>
      <mat-datepicker #billingRenewalDate panelClass="month-picker"></mat-datepicker>
      <mat-error *ngIf="clientSettingsForm.get('billingRenewalDate').invalid">
        {{ getErrorMessage(clientSettingsForm.get('billingRenewalDate')) }}
      </mat-error>
    </mat-form-field>

    <!-- Billing Day -->
    <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="30" label="Billing Day" [data]="clientSettings.billingDay">
    </ati-labeled-data>
    <mat-form-field *ngIf="editMode" fxFlex.gt-xs="30">
      <input matInput placeholder="Billing Day" formControlName="billingDay" data-cy="billingDay">
      <mat-error *ngIf="clientSettingsForm.get('billingDay').invalid">
        {{ getErrorMessage(clientSettingsForm.get('billingDay')) }}
      </mat-error>
      <button type="button" id="clear-client-settings-billing-day" *ngIf="clientSettingsForm.get('billingDay').value"
        matSuffix mat-icon-button aria-label="Clear Billing Day" matTooltip="Clear Billing Day" (click)="clearField('billingDay', '0')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!-- Monthly Flat Fee -->
    <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="30" label="Monthly Flat Fee" [data]="clientSettings.billingMonthlyFlatFee | number: '1.2'">
    </ati-labeled-data>
    <mat-form-field *ngIf="editMode" fxFlex.gt-xs="30">
      <input matInput placeholder="Monthly Flat Fee"
        formControlName="billingMonthlyFlatFee"
        (blur)="formatToPrecision('billingMonthlyFlatFee', 2)">
      <mat-error *ngIf="clientSettingsForm.get('billingMonthlyFlatFee').invalid">
        {{ getErrorMessage(clientSettingsForm.get('billingMonthlyFlatFee')) }}
      </mat-error>
      <button type="button" id="clear-client-settings-billing-monthly-flat-fee" *ngIf="clientSettingsForm.get('billingMonthlyFlatFee').value"
      [disabled]="clientSettingsForm.get('billingDay').value === '0'" matSuffix mat-icon-button aria-label="Clear Billing Monthly Flat Fee"
      matTooltip="Clear Billing Montly Flat Fee" (click)="clearField('billingMonthlyFlatFee', '0.00')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!-- Usage Rate -->
    <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="30" label="Usage Rate" [data]="clientSettings.billingUsageRate | number: '1.5'">
    </ati-labeled-data>
    <mat-form-field *ngIf="editMode" fxFlex.gt-xs="30">
      <input matInput placeholder="Usage Rate"
        (blur)="formatToPrecision('billingUsageRate', 5)"
        formControlName="billingUsageRate">
      <mat-error *ngIf="clientSettingsForm.get('billingUsageRate').invalid">
        {{ getErrorMessage(clientSettingsForm.get('billingUsageRate')) }}
      </mat-error>
      <button type="button" id="clear-client-settings-billing-hedge-rate" *ngIf="clientSettingsForm.get('billingUsageRate').value"
        [disabled]="clientSettingsForm.get('billingDay').value === '0'" matSuffix mat-icon-button aria-label="Clear Billing Usage Rate"
        matTooltip="Clear Billing Usage Rate" (click)="clearField('billingUsageRate', '0.00000')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <h4 fxFlex="100">Billing Contact</h4>
    <!-- Contact Name -->
    <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="30" label="Contact Name" [data]="clientSettings.billingContactName">
    </ati-labeled-data>
    <mat-form-field *ngIf="editMode" fxFlex.gt-xs="30">
      <input matInput placeholder="Contact Name" formControlName="billingContactName"
        trim="blur" />
      <mat-error *ngIf="clientSettingsForm.get('billingContactName').invalid">
        {{ getErrorMessage(clientSettingsForm.get('billingContactName')) }}
      </mat-error>
      <button type="button" id="clear-client-settings-billing-contact-name" *ngIf="clientSettingsForm.get('billingContactName').value"
        [disabled]="clientSettingsForm.get('billingDay').value === '0'" matSuffix mat-icon-button aria-label="Clear Billing Contact Name"
        matTooltip="Clear Billing Contact Name" (click)="clearField('billingContactName')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <!-- Contact Email -->
    <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="30" label="Contact Email" [data]="clientSettings.billingContactEmail">
    </ati-labeled-data>
    <mat-form-field *ngIf="editMode" fxFlex.gt-xs="30">
      <input matInput placeholder="Contact Email" formControlName="billingContactEmail"
        trim="blur" />
      <mat-error *ngIf="clientSettingsForm.get('billingContactEmail').invalid">
        {{ getErrorMessage(clientSettingsForm.get('billingContactEmail')) }}
      </mat-error>
      <button type="button" id="clear-client-settings-billing-contact-email" *ngIf="clientSettingsForm.get('billingContactEmail').value"
        [disabled]="clientSettingsForm.get('billingDay').value === '0'" matSuffix mat-icon-button aria-label="Clear Billing Contact Email"
        matTooltip="Clear Billing Contact Email" (click)="clearField('billingContactEmail')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
</form>
