import { Component, Input } from '@angular/core';

import { CommodityMap, OrderLeg } from '@advance-trading/ops-data-lib';

@Component({
  selector: 'hms-order-leg',
  templateUrl: './order-leg.component.html',
  styleUrls: ['./order-leg.component.scss']
})
export class OrderLegComponent {

  @Input() leg: OrderLeg;
  @Input() commodityMap: CommodityMap;

  constructor() { }

  getDisplayPrice(price: number, symbol: string) {
    // only used for options; look up commodity by electronicOptionsSymbol since it might not be the same as the id/key
    const commodity = Object.values(this.commodityMap.commodities).find(cmdty => cmdty.electronicOptionsSymbol === symbol);
    // fall through to hard-coded divisor if commodity not found; same fallthrough as other components with fallthrough
    const priceDivisor = commodity ? commodity.marketDataDivisor : 1;
    return price / priceDivisor;
  }

}
