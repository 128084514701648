import { AfterViewInit, ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';

import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, take } from 'rxjs/operators';

import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommonValidators } from '@advance-trading/angular-common-services';
import { CommodityProfileService,
         ExecutionReportService,
         OperationsDataService,
         OrderFillSummary,
         OrderService } from '@advance-trading/angular-ops-data';
import { AdHocOrder, AdHocOrderStatus, Client, CommodityMap, CommodityProfile, ContractMonth, Order } from '@advance-trading/ops-data-lib';

import { AdHocOrderService } from '../../service/ad-hoc-order.service';
import { ClientSelectorService } from '../../service/client-selector.service';
import { UserRoles } from '../../utilities/user-roles';

import { AdHocOrderDisplay } from '../ad-hoc-order-display';


const START_OF_DAY = moment().startOf('day').toISOString();
const END_OF_DAY = moment().endOf('day').toISOString();

const YEAR_FORMAT = 'YY';

@Component({
  selector: 'hms-ad-hoc-order-search',
  templateUrl: './ad-hoc-order-search.component.html',
  styleUrls: ['./ad-hoc-order-search.component.scss']
})
export class AdHocOrderSearchComponent implements OnInit, AfterViewInit {

  @ViewChildren('statusBoxes') statusBoxes: QueryList<MatCheckbox>;
  @ViewChild('contractYearMonthPicker', {static: false}) contractYearMonthRef;

  adHocOrderSearchForm: FormGroup = this.formBuilder.group({
    orderDocId: [''],
    commodityProfile: ['', CommonValidators.objectValidator],
    contractYearMonth: [''],
    startDate: ['', {updateOn: 'blur'}],
    endDate: ['', {updateOn: 'blur'}],
    lastUpdatedDate: ['', {updateOn: 'blur'}],
    statuses: [[]]
  });

  showAdHocOrders = false;
  selectedAdHocOrders$: Observable<AdHocOrderDisplay[]>;
  tableState: {[key: string]: string|number} = {};

  filteredCommodityProfiles$: Observable<CommodityProfile[]>;

  errorMessage: string;
  isLoading = true;
  isSearching = false;

  availableStatuses = Object.values(AdHocOrderStatus);

  private queryParams: Params;
  private commodityMap: CommodityMap;
  private contractMonths = Object.keys(ContractMonth);
  private selectedStatuses: AdHocOrderStatus[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private adHocOrderService: AdHocOrderService,
    private authzService: Auth0AuthzService,
    private changeDetector: ChangeDetectorRef,
    private clientSelectorService: ClientSelectorService,
    private commodityProfileService: CommodityProfileService,
    private executionReportService: ExecutionReportService,
    private formBuilder: FormBuilder,
    private operationsDataService: OperationsDataService,
    private orderService: OrderService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    if (!this.isAdHocOrderAdmin && !this.authzService.currentUserHasRole(UserRoles.AD_HOC_ORDER_VIEWER_ROLE)) {
      this.errorMessage = 'You do not have permission to search ad hoc orders.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    this.prepForCommodityProfileSelection();
  }

  ngAfterViewInit() {

    // build form values from query params if any
    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params => {
      this.queryParams = Object.assign({}, params);
      if (this.queryParams.startDate) {
        this.adHocOrderSearchForm.get('startDate').setValue(this.queryParams.startDate);
      }

      if (this.queryParams.endDate) {
        this.adHocOrderSearchForm.get('endDate').setValue(this.queryParams.endDate);
      }

      if (this.queryParams.lastUpdatedDate) {
        this.adHocOrderSearchForm.get('lastUpdatedDate').setValue(this.queryParams.lastUpdatedDate);
      }

      const commodityProfileValue = this.queryParams.commodityProfile ?
        { docId: this.queryParams.commodityProfile, name: '' } as CommodityProfile : undefined;
      this.adHocOrderSearchForm.get('commodityProfile').setValue(commodityProfileValue);
      if (this.queryParams.contractYearMonth) {
        this.adHocOrderSearchForm.get('contractYearMonth').setValue(moment(this.queryParams.contractYearMonth));
      }

      if (this.queryParams.status) {
        this.selectedStatuses = this.queryParams.status.split(',');
        this.adHocOrderSearchForm.get('statuses').setValue(this.selectedStatuses);
      }

      if (Object.keys(params).length) {
        this.adHocOrderSearchForm.markAllAsTouched();
        this.adHocOrderSearchForm.markAsDirty();
        this.searchAdHocOrders();
      }
    }));

    this.adHocOrderSearchForm.valueChanges.subscribe((values) => {
      const orderDocId = this.adHocOrderSearchForm.get('orderDocId').value;
      const commodityProfile: CommodityProfile = this.adHocOrderSearchForm.get('commodityProfile').value;
      const contractYearMonth = this.adHocOrderSearchForm.get('contractYearMonth').value;
      const start = this.adHocOrderSearchForm.get('startDate').value;
      const end = this.adHocOrderSearchForm.get('endDate').value;
      const lastUpdated = this.adHocOrderSearchForm.get('lastUpdatedDate').value;
      const statuses = this.selectedStatuses.length;

      const hasNoValues = !orderDocId && !start && !end && !contractYearMonth && !commodityProfile && !lastUpdated && !statuses;
      const hasValueOtherThanId = commodityProfile || contractYearMonth || start || end || lastUpdated || statuses;

      // When searching ad-hoc contracts, order ID cannot be combined with other search criteria
      // Disable all other fields when order ID is entered
      if (orderDocId && this.adHocOrderSearchForm.get('commodityProfile').enabled) {
        this.adHocOrderSearchForm.get('commodityProfile').disable();
        this.adHocOrderSearchForm.get('contractYearMonth').disable();
        this.adHocOrderSearchForm.get('startDate').disable();
        this.adHocOrderSearchForm.get('endDate').disable();
        this.adHocOrderSearchForm.get('lastUpdatedDate').disable();
      } else if (this.adHocOrderSearchForm.get('orderDocId').enabled && hasValueOtherThanId) {
        // Disable order ID field if a value is entered in any other field
        this.adHocOrderSearchForm.get('orderDocId').disable();
      } else if (hasNoValues && (this.adHocOrderSearchForm.get('orderDocId').disabled ||
        this.adHocOrderSearchForm.get('commodityProfile').disabled || this.adHocOrderSearchForm.get('startDate').disabled ||
        this.adHocOrderSearchForm.get('endDate').disabled || this.adHocOrderSearchForm.get('lastUpdatedDate').disabled)) {
        // Re-enable all fields if a previously entered value has disabled a field(s) but is then cleared
        this.adHocOrderSearchForm.enable();
      }

      if (lastUpdated && this.adHocOrderSearchForm.get('startDate').enabled && this.adHocOrderSearchForm.get('endDate').enabled) {
        this.adHocOrderSearchForm.get('startDate').disable();
        this.adHocOrderSearchForm.get('endDate').disable();
      }

      if (this.adHocOrderSearchForm.get('lastUpdatedDate').enabled && (start || end)) {
        this.adHocOrderSearchForm.get('lastUpdatedDate').disable();
      }

    });

    this.isLoading = false;

    // setting here as [checked] property on template was not setting inner input type="checkbox" to checked
    this.statusBoxes.forEach(statusBox => statusBox.checked = this.selectedStatuses.includes(statusBox.value as AdHocOrderStatus) && !statusBox.disabled);
    this.changeDetector.detectChanges();
  }

  get isAdHocOrderAdmin() {
    return this.authzService.currentUserHasRole(UserRoles.AD_HOC_ORDER_ADMIN_ROLE);
  }

  searchAdHocOrders(searchButtonClicked: boolean = false) {
    if (searchButtonClicked) {
      // clear initial table state if the user perform a new search
      this.clearQueryParams();
      this.tableState = {};
    } else {
      // set initial table state from query param if the user is back navigating from another page
      const sortDir = this.queryParams.sortDir;
      const sortColName = this.queryParams.sortColName;
      const pageSize = this.queryParams.pageSize;
      const pageIndex = this.queryParams.pageIndex;
      const filter = this.queryParams.filter;
      this.tableState = {
        sortDir,
        sortColName,
        pageSize,
        pageIndex,
        filter
      };
    }

    // Pre-flight check before making the search, prevents searching against _all_ ad hoc orders.
    const startDateCtrl: AbstractControl = this.adHocOrderSearchForm.get('startDate');
    const endDateCtrl: AbstractControl = this.adHocOrderSearchForm.get('endDate');

    const startDateEnabled = startDateCtrl?.enabled;
    const endDateEnabled = endDateCtrl?.enabled;
    const missingStartDate = !startDateCtrl?.value && startDateEnabled;
    const missingEndDate = !endDateCtrl?.value && endDateEnabled;

    // Logic prevents adding end date if they may have been searching by another field value.
    // Handles URL parameter start/end date as well, using pristine/touched.
    if (missingStartDate &&  (!this.adHocOrderSearchForm.pristine || !this.adHocOrderSearchForm.touched)) {
      this.adHocOrderSearchForm.get('startDate').setValue(START_OF_DAY);
      if (missingEndDate) {
        this.adHocOrderSearchForm.get('endDate').setValue(END_OF_DAY);
      }
    } else {
      if (missingEndDate) {
        this.adHocOrderSearchForm.get('endDate').setValue(END_OF_DAY);
      }
    }

    this.showAdHocOrders = false;
    this.changeDetector.detectChanges();
    let selectedClientDocId;
    this.selectedAdHocOrders$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((doc: CommodityMap) => {
        this.commodityMap = doc;
        return this.clientSelectorService.getSelectedClient();
      }),
      switchMap((selectedClient: Client) => {
        selectedClientDocId = selectedClient.docId;
        return this.getAdHocOrders(selectedClientDocId);
      }),
      switchMap((adHocOrders: AdHocOrder[]) => {
        // Handle edge case when there is no ad hoc order found
        if (adHocOrders.length === 0) {
          return of([]);
        }

        // get commodity profile name
        return combineLatest(
          adHocOrders.map(adHocOrder => {
            return this.commodityProfileService.getCommodityProfileByDocId(selectedClientDocId, adHocOrder.commodityProfileDocId).pipe(
              map(profile => {
                // Add field that contains the displayed period for filtering purpose
                const contractDisplayName = this.getPeriodFromMonthCode(adHocOrder.contractYearMonth);
                return {
                  ...adHocOrder,
                  commodityProfileName: profile.name,
                  commodityProfile: profile,
                  contractDisplayName
                } as AdHocOrderDisplay;
              })
            );
          })
        );
      }),
      switchMap((adHocOrders: AdHocOrderDisplay[]) => {
        // Handle edge case when there is no ad hoc order found
        if (adHocOrders.length === 0) {
          return of([]);
        }

        // get order fill price
        return combineLatest(
          adHocOrders.map((adHocOrder: AdHocOrderDisplay) => {
            if (adHocOrder.orderDocId) {
              return this.executionReportService.getOrderFillSummaryByOrderDocId(
                adHocOrder.commodityProfile.accountDocId, adHocOrder.orderDocId).pipe(
                  switchMap((orderFillSummary: OrderFillSummary) => {
                    if (orderFillSummary) {
                      // futures ad hoc order
                      if (adHocOrder.commodityProfile.commodityId) {
                        return of({
                          ...adHocOrder,
                          isSplitFilled: orderFillSummary.isSplitFilled,
                          fillPrice: this.getDisplayPrice(orderFillSummary.fillPrice, adHocOrder.commodityProfile.commodityId)
                        } as AdHocOrderDisplay);
                        // spread ad hoc order (need to get order.symbol since commodityId is an empty string)
                      } else {
                        return this.orderService.getOrderByDocId(
                          adHocOrder.commodityProfile.accountDocId, adHocOrder.orderDocId).pipe(
                            map((order: Order) => {
                              return {
                                ...adHocOrder,
                                isSplitFilled: orderFillSummary.isSplitFilled,
                                fillPrice: this.getDisplayPrice(orderFillSummary.fillPrice, order.symbol)
                              } as AdHocOrderDisplay;
                            })
                          );
                      }
                    }
                    // this could happen until the order fill data is received from the CME
                    // or ad hoc order that is associated with an order that does not fill
                    return of(adHocOrder);
                  }),
                  catchError(err => {
                    console.error(`Failed to retrieve order fill data from execution reports: ${err}`);
                    return of(adHocOrder);
                  })
                );
            }
            return of(adHocOrder);
          })
        );
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.showAdHocOrders = true;
    this.changeDetector.detectChanges();
    this.adHocOrderSearchForm.markAsDirty();
  }

  addAdHocOrder() {
    this.router.navigate(['/adhocorders/new']);
  }

  reset() {
    this.selectedStatuses = [];
    this.statusBoxes.forEach(statusBox => statusBox.checked = false);
    this.adHocOrderSearchForm.reset();
    this.clearQueryParams();
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
    });
    this.showAdHocOrders = false;
    this.adHocOrderSearchForm.get('startDate').setValue('');
    this.adHocOrderSearchForm.get('endDate').setValue('');
    this.adHocOrderSearchForm.markAsPristine();
    this.adHocOrderSearchForm.enable();
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('matDatepickerParse')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value Invalid';
    } else if (control.hasError('matDatepickerMax')) {
      return 'Value Invalid';
    } else if (control.hasError('required')) {
      return 'Value Required'
    }
    return 'Unknown Error';
  }

  onStatusChange(event) {
    if (event.checked) {
      this.selectedStatuses.push(event.source.value);
    } else {
      this.selectedStatuses = this.selectedStatuses.filter(status => status !== event.source.value);
    }
    this.adHocOrderSearchForm.get('statuses').setValue(this.selectedStatuses);
    if (this.selectedStatuses.length) {
      this.adHocOrderSearchForm.get('statuses').markAsDirty();
    } else {
      this.adHocOrderSearchForm.get('statuses').markAsPristine();
    }
  }

  handleAdHocOrderListChange(tableState: {[key: string]: string|number}) {
    if (tableState.sortDir && tableState.sortColName) {
      this.queryParams.sortDir = tableState.sortDir;
      this.queryParams.sortColName = tableState.sortColName;
    } else if (this.queryParams.sortDir && this.queryParams.sortColName) {
      // remove sorted direction and column in query param if there's no sort applied
      delete this.queryParams.sortDir;
      delete this.queryParams.sortColName;
    }
    if (tableState.pageSize) {
      this.queryParams.pageSize = tableState.pageSize;
    }
    if (tableState.pageIndex !== undefined) {
      this.queryParams.pageIndex = tableState.pageIndex;
    }

    if (tableState.filter) {
      this.queryParams.filter = tableState.filter;
    } else if (this.queryParams.filter) {
      // remove filter query param if there's no filter applied
      delete this.queryParams.filter;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });
  }

  handleAdHocOrderListError(errorMessage: string) {
    this.openSnackBar(errorMessage, 'DISMISS', false);
  }

  handleIsSearching(isSearching: boolean) {
    this.isSearching = isSearching;
    this.changeDetector.detectChanges();
  }

  selectContractYearMonth(e: moment.Moment) {
    this.adHocOrderSearchForm.get('contractYearMonth').setValue(e);
    this.adHocOrderSearchForm.markAsDirty();
    this.contractYearMonthRef.close();
  }

  /**
   * Displays name for commodity profile autocomplete input field
   */
  displayCommodityProfile(profile?: CommodityProfile) {
    return profile ? profile.name : '';
  }

  private getAdHocOrders(clientDocId: string): Observable<AdHocOrder[]> {
    const orderDocId = this.adHocOrderSearchForm.get('orderDocId').value;
    let startDate = this.adHocOrderSearchForm.get('startDate').value;
    let endDate = this.adHocOrderSearchForm.get('endDate').value;
    let lastUpdatedDate = this.adHocOrderSearchForm.get('lastUpdatedDate').value;
    let lastUpdatedDateEnd = lastUpdatedDate;
    const contractYearMonth = this.adHocOrderSearchForm.get('contractYearMonth').value ?
      this.translateToContractMonth(this.adHocOrderSearchForm.get('contractYearMonth').value) :
      undefined;
    const commodityProfile: CommodityProfile = this.adHocOrderSearchForm.get('commodityProfile').value;

    // copying as search methods involving status use splice, emptying passed in array
    const searchStatuses = this.selectedStatuses.length && this.selectedStatuses.length < this.availableStatuses.length ?
      [...this.selectedStatuses] : [];

    // Convert to ISO date standard if date given
    // Set default date or the date entered by the user
    if (startDate) {
      startDate = new Date(startDate).toISOString();
      this.queryParams.startDate = startDate;
    }

    if (endDate) {
      endDate = new Date(endDate);
      // Allow contract made on this day before midnight
      endDate.setHours(23, 59, 59, 999);
      endDate = endDate.toISOString();
      this.queryParams.endDate = endDate;
    } else {
      endDate = END_OF_DAY;
    }

    if (lastUpdatedDate) {
      lastUpdatedDate = new Date(lastUpdatedDate);
      lastUpdatedDateEnd = new Date(lastUpdatedDate);
      // Allow contract last updated on this day before midnight
      lastUpdatedDate.setHours(0, 0, 0, 0);
      lastUpdatedDateEnd.setHours(23, 59, 59, 999);
      lastUpdatedDate = lastUpdatedDate.toISOString();
      lastUpdatedDateEnd = lastUpdatedDateEnd.toISOString();
      this.queryParams.lastUpdatedDate = lastUpdatedDate;
    } else {
      lastUpdatedDate = '';
      lastUpdatedDateEnd = '';
    }

    if (commodityProfile) {
      this.queryParams.commodityProfile = commodityProfile.docId;
    }

    if (contractYearMonth) {
      this.queryParams.contractYearMonth = this.adHocOrderSearchForm.get('contractYearMonth').value.toISOString();
    }

    // testing for length rather than hasStatus because all statuses = no status filters, but we want to re-select on back
    if (this.selectedStatuses.length) {
      this.queryParams.status = this.selectedStatuses.join(',');
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      replaceUrl: true,
      queryParams: this.queryParams
    });

    if (orderDocId) {
      return this.adHocOrderService.getAdHocOrdersByOrderDocId(clientDocId, orderDocId);
    } else if (startDate || endDate || contractYearMonth || commodityProfile || lastUpdatedDate) {
      const commodityProfileDocId = commodityProfile ? commodityProfile.docId : '';
      return this.adHocOrderService.getAdHocOrdersBySearchParameters(
        clientDocId, startDate, endDate, contractYearMonth, commodityProfileDocId, lastUpdatedDate, lastUpdatedDateEnd, searchStatuses);
    }
    return this.adHocOrderService.getAllAdHocOrdersByClientDocId(clientDocId);
  }

  // This returns the month year formatted period from a code
  private getPeriodFromMonthCode(value: string): string {

    if (value) {
      const year = value.substring(0, 2);
      const monthCode = value.substring(2);
      const idxMonth = this.contractMonths.indexOf(monthCode);
      return moment.monthsShort()[idxMonth] + ' ' + year;
    } else {
      return value;
    }

  }

  private translateToContractMonth(selectedMonth: moment.Moment): string {
    return selectedMonth.format(YEAR_FORMAT) + ContractMonth[this.contractMonths[selectedMonth.month()]];
  }

  private prepForCommodityProfileSelection() {
    let setInitialValue = false;
    this.filteredCommodityProfiles$ = this.adHocOrderSearchForm.controls.commodityProfile.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | CommodityProfile>(''),
      switchMap(searchTerm => {
        return this.clientSelectorService.getSelectedClient().pipe(
          switchMap((selectedClient: Client) => {
            return this.commodityProfileService.getAllCommodityProfilesByClientDocId(selectedClient.docId).pipe(
              map((profiles: CommodityProfile[]) => {
                // populate searchTerm on initial load in case there is a mock object present
                if (!searchTerm) {
                  searchTerm = this.adHocOrderSearchForm.get('commodityProfile').value;
                }
                // Return all commodity profiles when a profile has already been selected to avoid the user needing to clear the field
                if (typeof searchTerm !== 'string') {
                  // Replace mock commodityProfile from queryParam if necessary
                  if (searchTerm && !(searchTerm as CommodityProfile).name && !setInitialValue) {
                    this.adHocOrderSearchForm.get('commodityProfile').setValue(
                      profiles.find(profile => profile.docId === (searchTerm as CommodityProfile).docId));
                    setInitialValue = true;
                  }
                  return profiles;
                }
                return profiles.filter(profile => profile.name.toLowerCase().includes((searchTerm as string).toLowerCase()));
              }),
              shareReplay({ bufferSize: 1, refCount: true }),
              catchError(err => {
                this.isLoading = false;
                this.errorMessage = 'Error retrieving client commodity profiles; please try again later';
                console.error(`Error retrieving client commodity profiles: ${err}`);
                return of([]);
              })
            );
          }),
        );
      }),
    );
  }

  private clearQueryParams() {
    this.queryParams = {} as Params;
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private getDisplayPrice(price: number, symbol: string) {
    const priceDivisor = this.commodityMap.commodities[symbol] ? this.commodityMap.commodities[symbol].marketDataDivisor : 1;
    return price / priceDivisor;
  }

}
