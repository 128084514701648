import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { CommodityProfileService, LocationService } from '@advance-trading/angular-ops-data';
import { Client, CommodityProfile, ContractLimit, HMSUserSettings, Location } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';

const POSITIVE_INTEGER = /^\d{1,}$/;

@Component({
  selector: 'hms-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit, OnChanges {

  userSettingsForm: FormGroup = this.formBuilder.group({
    accountingSystemId: ['', {updateOn: 'blur'}]
  });

  errorMessage: string;

  locations: Location[] = [];
  locationSelected: Location;
  activeLocations$: Observable<Location[]>;

  authorizedLocationDocIDs: string[];
  authorizedLocations: Location[];

  baseLocation: Location;

  checkedLocations: SelectionModel<any> = new SelectionModel<any>(true, []);

  commodityProfiles$: Observable<CommodityProfile[]>;
  contractLimits: ContractLimit[] = [];
  private formValid = true;

  @Input() editMode = false;
  @Input() hmsUserSettings: HMSUserSettings;
  @Input() updateComplete = true;
  @Input() userCanAdminister = false;

  @Output() settingsUpdated: EventEmitter<boolean> = new EventEmitter();
  @Output() settingsValid: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private clientSelectorService: ClientSelectorService,
    private commodityProfileService: CommodityProfileService,
    private formBuilder: FormBuilder,
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.settingsUpdated.emit(false);
    this.settingsValid.emit(true);
    this.authorizedLocationDocIDs = this.hmsUserSettings.authorizedLocationDocIds.filter(val => val);
    this.userSettingsForm.get('accountingSystemId').setValue(this.hmsUserSettings.accountingSystemId);
    this.getLocations();
    this.contractLimits = this.hmsUserSettings.contractLimits.filter(val => val);

    this.userSettingsForm.controls['accountingSystemId'].valueChanges.subscribe((val: string) => {
      if (val !== this.hmsUserSettings.accountingSystemId) {
        this.settingsUpdated.emit(true);
      }
    });

    this.commodityProfiles$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((selectedClient: Client) => {
        return this.commodityProfileService.getActiveCommodityProfilesByTypeAndClientDocId(selectedClient.docId);
      }),
      tap((profiles: CommodityProfile[]) => {
        profiles.forEach(profile => {
          const profileLimit = this.contractLimits.find(limit => limit.commodityProfileDocId === profile.docId);
          this.userSettingsForm.addControl(profile.docId, new FormControl(profileLimit ? profileLimit.quantityLimit.toString() : '', Validators.pattern(POSITIVE_INTEGER)));
        });
      })
    );

  }

  ngOnChanges() {
    if (!this.editMode && this.updateComplete) {
      this.reset();
    } else if (!this.updateComplete) {
      this.submit();
    }
  }

  get canEditSettings() {
    return this.editMode && this.userCanAdminister;
  }

  addNewLocation() {
    this.authorizedLocations.push(this.locationSelected);
    this.authorizedLocationDocIDs.push(this.locationSelected.docId);
    this.locations = this.locations.filter(val => val !== this.locationSelected);
    this.locationSelected = this.locations[0];
    this.checkedLocations.clear();
    this.settingsUpdated.emit(true);
  }

  addAllLocations() {
    this.locations.forEach(loc => {
      this.authorizedLocations.push(loc);
      this.authorizedLocationDocIDs.push(loc.docId);
    });
    this.locationSelected = null;
    this.locations = [];

    this.checkedLocations.clear();
    this.settingsUpdated.emit(true);
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('pattern')) {
      return 'Invalid Limit';
    }
    return 'Unknown Error';
  }

  removeSelectedLocations() {
    this.checkedLocations.selected.forEach((loc: Location) => {
      this.authorizedLocationDocIDs = this.authorizedLocationDocIDs.filter(val => val !== loc.docId);
      this.authorizedLocations = this.authorizedLocations.filter(val => val !== loc);
      if (this.baseLocation && loc.docId === this.baseLocation.docId) {
        this.baseLocation = undefined;
      }
      this.locations.push(loc);
    });

    this.locationSelected = this.locations[0];

    this.settingsUpdated.emit(true);
    this.checkedLocations.clear();
  }

  setAsBase() {
    this.baseLocation = this.checkedLocations.selected[0];
    this.settingsUpdated.emit(true);
    this.checkedLocations.clear();
  }

  getContractLimit(profileDocId: string) {
    const contractLimit = this.contractLimits.find(limit => limit.commodityProfileDocId === profileDocId);
    if (contractLimit) {
      return contractLimit.quantityLimit;
    } else {
      return null;
    }
  }

  updateContractLimit(profileDocId: string) {
    const contractLimit = this.userSettingsForm.get(profileDocId).value;
    const index = this.contractLimits.findIndex(x => x.commodityProfileDocId === profileDocId);
    if (!contractLimit) {
      this.contractLimits.splice(index, 1);
    } else if (index > -1) {
      this.contractLimits.splice(index, 1);
      this.contractLimits.push({commodityProfileDocId: profileDocId, quantityLimit: contractLimit});
    } else {
      this.contractLimits.push({commodityProfileDocId: profileDocId, quantityLimit: contractLimit});
    }

    this.formValid = this.userSettingsForm.get(profileDocId).valid;
    this.contractLimits.forEach(element => {
      this.formValid = this.formValid && element.quantityLimit >= 0;
    });
    this.settingsValid.emit(this.formValid);

    this.settingsUpdated.emit(true);
  }

  private reset() {
    this.checkedLocations.clear();
    this.userSettingsForm.reset();
    this.authorizedLocationDocIDs = this.hmsUserSettings.authorizedLocationDocIds.filter(val => val);
    this.userSettingsForm.get('accountingSystemId').setValue(this.hmsUserSettings.accountingSystemId);
    this.contractLimits = this.hmsUserSettings.contractLimits.filter(val => val);
    this.getLocations();
    this.settingsUpdated.emit(false);
  }

  private submit() {
    this.hmsUserSettings.authorizedLocationDocIds = this.authorizedLocationDocIDs;
    this.hmsUserSettings.accountingSystemId = this.userSettingsForm.get('accountingSystemId').value;
    if (this.baseLocation) {
      this.hmsUserSettings.baseLocationDocId = this.baseLocation.docId;
    } else {
      delete this.hmsUserSettings.baseLocationDocId;
    }
    this.hmsUserSettings.contractLimits = this.contractLimits.filter(val => val);
    this.settingsUpdated.emit(false);
  }

  private getLocations() {
    this.activeLocations$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap(client => {
        return this.locationService.getAllLocationsByClientDocId(client.docId).pipe(
          map((locations: Location[]) => {
            this.locations = locations.filter(val => !this.authorizedLocationDocIDs.includes(val.docId) && val.isActive);
            this.authorizedLocations = locations.filter(val => this.authorizedLocationDocIDs.includes(val.docId));
            this.locationSelected = this.locations[0];
            this.baseLocation = this.authorizedLocations.find(loc => loc.docId === this.hmsUserSettings.baseLocationDocId);
            return this.locations;
          }),
          catchError(err => {
            this.errorMessage = 'Error retrieving locations; please try again later';
            console.error(`Error retrieving locations: ${err}`);
            return of([]);
          })
        );
      })
    );
  }

}
