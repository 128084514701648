import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { speedDialFabAnimations } from './speed-dial-fab.animations';

@Component({
  selector: 'hms-speed-dial-fab', // Move out to common project and switch from hms to ati
  templateUrl: './speed-dial-fab.component.html',
  styleUrls: ['./speed-dial-fab.component.scss'],
  animations: speedDialFabAnimations
})
export class SpeedDialFabComponent {
  @Input() fabButtons;
  @Input() tooltip = '';

  buttons = [];
  fabTogglerState = 'inactive';

  constructor(
    private router: Router,
  ) { }

  showItems() {
    this.fabTogglerState = 'active';
    this.buttons = this.fabButtons;
  }

  hideItems() {
    this.fabTogglerState = 'inactive';
    this.buttons = [];
  }

  onToggleFab() {
    this.buttons.length ? this.hideItems() : this.showItems();
  }

  onNavigate(uri: string, queryParams: {}) {
    this.onToggleFab();
    // TODO include replaceUrl: true??
    this.router.navigate([uri], { queryParams });
  }
}
