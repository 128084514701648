import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ObservableDataSource } from '@advance-trading/angular-common-services';

import { HedgeDisplay } from '../hedge-display';
import { ExportService } from '../../service/export.service';

@Component({
  selector: 'hms-hedges',
  templateUrl: './hedges.component.html',
  styleUrls: ['./hedges.component.scss']
})
export class HedgesComponent implements AfterViewInit, OnChanges, OnInit {

  @Input() initialTableState: {[key: string]: string|number};
  @Input() selectedHedges$: Observable<HedgeDisplay[]>;
  @Output() hedgeListChange: EventEmitter<any> = new EventEmitter();
  @Output() hedgeSearchError: EventEmitter<string> = new EventEmitter();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();

  errorMessage: string;
  columnsToDisplay = [];
  dataSource = new ObservableDataSource<HedgeDisplay>();
  filterValue = new FormControl();
  exportable = false;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('filter', {static: false}) filter: ElementRef;

  private tableState: {[key: string]: string|number} = {};

  constructor(
    private breakpointObserver: BreakpointObserver,
    private changeDetector: ChangeDetectorRef,
    public exportService: ExportService,
    private router: Router) {}

  ngOnInit() {
    this.isSearching.emit(true);
    this.breakpointObserver.observe([Breakpoints.XSmall])
    .subscribe(state => {
      // display columns for xsmall screens
      if (state.matches) {
        this.columnsToDisplay = [
          'creationTimestamp', 'commodityProfileName'
        ];
      // display columns for larger screens
      } else {
        this.columnsToDisplay = [
          'creationTimestamp',
          'type',
          'commodityProfileName',
          'productionYearLabel',
          'futuresYearMonth',
          'side',
          'quantityInContracts',
          'futuresPrice'
        ];
      }
    });

    // setup listener for filter value changes
    this.filterValue.valueChanges.subscribe((filter: string) => {
      if (filter) {
        this.tableState.filter = filter.trim();
        this.hedgeListChange.emit(this.tableState);
      } else if (this.tableState.filter) {
        delete this.tableState.filter;
        this.hedgeListChange.emit(this.tableState);
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.filter) {
      this.filter.nativeElement.focus();
    }
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialTableState'] && changes['selectedHedges$']) {
      this.tableState = Object.assign({}, this.initialTableState);

      // detect MatSort and MatPaginator so it is defined
      this.changeDetector.detectChanges();

      const sortDir = this.initialTableState.sortDir as SortDirection;
      const sortColName = this.initialTableState.sortColName as string;
      if (sortDir && sortColName) {
        this.sort.direction = sortDir;
        this.sort.active = sortColName;
      }

      if (this.initialTableState.filter) {
        this.filterValue.setValue(this.initialTableState.filter);
        this.applyFilter(this.filterValue.value);
      }

      // initialize table
      this.dataSource.data$ = this.selectedHedges$.pipe(
        tap((hedges: HedgeDisplay[]) => {
          this.isSearching.emit(false);
          this.exportable = !!hedges.length;

          // initialize pagination state when the datasource exist
          const pageIndex = this.initialTableState.pageIndex as number;
          const pageSize = this.initialTableState.pageSize as number;

          if (pageIndex !== undefined) {
            this.paginator.pageIndex = pageIndex;
          }
          if (pageSize !== undefined) {
            this.paginator.pageSize = pageSize;
          }
        }),
        catchError(err => {
          this.errorMessage = 'Error retrieving hedges; please try again later';
          this.hedgeSearchError.emit(this.errorMessage);
          this.isSearching.emit(false);
          console.error(`Error retrieving hedges: ${err}`);
          return of([]);
        })
      );
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectHedge(hedge: HedgeDisplay) {
    this.router.navigate(['/hedges', hedge.docId]);
  }

  handleSortChange() {
    this.tableState.sortDir = this.sort.direction !== '' ? this.sort.direction : undefined;
    this.tableState.sortColName = this.tableState.sortDir ? this.sort.active : undefined;
    this.hedgeListChange.emit(this.tableState);
  }

  handlePageChange() {
    this.tableState.pageSize = this.paginator.pageSize;
    this.tableState.pageIndex = this.paginator.pageIndex;
    this.hedgeListChange.emit(this.tableState);
  }
}
