import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import * as moment from 'moment';
import { combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';

import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { CommonValidators, ConfirmationDialogService } from '@advance-trading/angular-common-services';
import {
  BasisService,
  CommodityProfileService,
  LocationService,
  MarketDataService,
  OperationsDataService,
  PatronService,
  UserService
} from '@advance-trading/angular-ops-data';
import {
  Basis,
  Client,
  CommodityMap,
  CommodityProfile,
  CommodityProfileProductionYear,
  Contract,
  ContractMonth,
  ContractStatus,
  ContractType,
  DeliveryType,
  ExchangeType,
  HedgeType,
  HMSClientSettings,
  HMSPriceAdjustment,
  HMSPriceAdjustmentMap,
  HMSUserSettings,
  Location,
  MarketData,
  MarketDataFrequency,
  Patron,
  PricingType,
  Side,
  Status,
  TimeInForce,
  User
} from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { ContractService } from '../../service/contract.service';
import { PriceAdjustmentService } from '../../service/price-adjustment.service';
import { UserRoles } from '../../utilities/user-roles';
import { UserSettingsService } from '../../service/user-settings.service';

import { OptionQuantityErrorMatcher } from '../contract.validator';

import { Originator } from '../../utilities/originator';
import { LedgerHelperService } from '../../service/ledger-helper.service';
import { ContractPriceHelper } from '../contract-price-helper';
import { CommodityFuturesPriceService } from 'src/app/service/commodity-futures-price.service';

const QUANTITY_REGEX = /^(?=.*[1-9])\d*(?:\.\d{0,2})?$/;
const CONTRACTS_REGEX = /^(?=.*[1-9])\d*$/;

const ALPHANUMERIC_REGEX = /^\w+$/;


// formats
const DATE_FORMAT = 'YYYY-MM-DD';
const YEAR_FORMAT = 'YY';

/** Describes the object returned from cash calculation on the form snapshot. */
interface SnapshotCashValues {
  freightPrice: number;
  basisPrice: number;
  basisAdjustment: number;
  futuresPrice: number;
  cashPrice: number;
}

@Component({
  selector: 'hms-new-contract',
  templateUrl: './new-contract.component.html',
  styleUrls: ['./new-contract.component.scss'],
})
export class NewContractComponent implements OnInit {
  errorMessage = '';
  updateComplete = true;
  isLoading = false;
  isLoadingBasis = false;
  isLoadingFutures = false;
  isLoadingPatron = true;

  @ViewChild('deliveryPeriodPicker', { static: false }) deliveryPeriodRef;
  @ViewChild('futuresMonthPicker', { static: false }) futuresMonthRef;
  @ViewChild('newPriceAdjustment', { static: false }) newPriceAdjustment;
  @ViewChild('deliveryPeriodPickerToggle', { static: false, read: ElementRef }) deliveryPeriodPickerToggle;
  @ViewChild('futuresMonthPickerToggle', { static: false, read: ElementRef }) futuresMonthPickerToggle;
  @ViewChild('expirationDatePickerToggle', { static: false, read: ElementRef }) expirationDatePickerToggle;
  @ViewChild('contractTypeToggle', { static: false, read: ElementRef }) ContractTypeToggle;

  contractForm: FormGroup = this.formBuilder.group({
    contractType: [ContractType.CASH, [Validators.required]],
    pricingType: [PricingType.MARKET, [Validators.required]],
    side: [Side.BUY, [Validators.required]],
    deliveryType: [DeliveryType.DELIVERED, [Validators.required]],
    clientLocation: ['', [Validators.required, CommonValidators.objectValidator]],
    deliveryLocation: ['', [Validators.required, CommonValidators.objectValidator]],
    patron: ['', [Validators.required, CommonValidators.objectValidator]],
    productionYear: ['', [Validators.required]],
    commodityProfile: ['', [Validators.required]],
    quantity: ['', [Validators.required, Validators.pattern(QUANTITY_REGEX)]],
    basisPrice: [''],
    futuresPrice: [''],
    cashPrice: [''],
    freightPrice: ['0.0000'],
    basisAdjustment: ['0.0000'],
    exchangeRate: ['1.0000'],
    deliveryPeriod: ['', [Validators.required]],
    futuresYearMonth: ['', [Validators.required]],
    expirationDate: [''],
    comments: ['', [Validators.maxLength(800)]],
    instructions: ['', [Validators.maxLength(500)]],
    priceAdjustments: this.formBuilder.group({}),
    customContractId: ['', [Validators.pattern(ALPHANUMERIC_REGEX), Validators.maxLength(30)]],
    specialHandling: [false, [Validators.required]],
    specialHandlingType: ['', [Validators.required]],
    exchangeType: [ExchangeType.EFP, [Validators.required]],
    exchangeContracts: ['', [Validators.required, Validators.pattern(CONTRACTS_REGEX)]],
    exchangeContraFirm: ['', [Validators.required, Validators.maxLength(100)]],
    exchangeContraAccount: ['', [Validators.required, Validators.maxLength(30)]],
    optionContracts: ['', [Validators.required, Validators.pattern(CONTRACTS_REGEX)]],
    originator: ['', [Validators.required, CommonValidators.objectValidator]]
  });

  // error matcher to detect form validation errors
  optionQuantityErrorMatcher = new OptionQuantityErrorMatcher();

  // form data
  commodityMap$: Observable<CommodityMap>;
  commodityProfiles$: Observable<CommodityProfile[]>;
  prodYears: CommodityProfileProductionYear[] = [];
  filteredClientLocations$: Observable<Location[]>;
  filteredDeliveryLocations$: Observable<Location[]>;
  filteredOriginators$: Observable<Originator[]>;
  activePatrons$: Observable<Patron[]>;
  patronControlChanges$: Observable<string | Patron>;
  filteredPatrons$: Observable<Patron[]>;

  // enum allowed values
  contractTypes = Object.keys(ContractType);
  deliveryTypes = Object.keys(DeliveryType);
  exchangeTypes = Object.keys(ExchangeType);
  pricingTypes = Object.keys(PricingType);
  sides = Object.keys(Side);

  isSpot = false;
  contractType = ContractType.CASH;
  isInternational = false;
  // Don't allow delivery month or futures month in the past. Delivery and Futures datepickers default to first of month.
  minDate = moment().startOf('month');
  // Don't allow expiration date in the past
  expiryMinDate = new Date();
  expiryMaxDate = new Date();

  marketBasisPrice: number;
  marketFuturesPrice: number;
  priceAdjustmentsTotal = 0;
  usePriceAdjustments = false;

  private contractMonths = Object.keys(ContractMonth);

  // location set from user base location or location from Basis from `basisDocId` query param
  private defaultLocationDocId: string;
  private defaultLocation: Location;

  private selectedClient: Client;
  private loggedInUser: User;
  private userSettings: HMSUserSettings;
  private commodityMap: CommodityMap;
  private profiles: CommodityProfile[];
  private futuresMonths: number[] = [];
  private currentBasis: Basis;
  private priceAdjustmentMap: HMSPriceAdjustmentMap;

  // preserved values from query params
  private qpContractType: ContractType;
  private qpFuturesMonthMoment: moment.Moment;
  private qpDeliveryPeriodMoment: moment.Moment;
  private qpBasis: Basis;
  private qpBasisPrice: string;
  private qpCommodityId: string;
  private qpCommodityProfile: CommodityProfile;
  private qpIsSpot: boolean;

  private setFuturesMonthFromBasis = true;

  private ledgerEndOfDay: string;
  private timezone: string;

  private useWholeCent = false;
  private contractPriceHelper: ContractPriceHelper;

  private contractFormFieldSubscriptions: Subscription[] = [];

  private marketData: MarketData;

  private patrons: Patron[] = [];
  private locations: Location[] = [];
  private clientLocations: Location[] = [];
  private originators: Originator[] = [];

  private filteredPatronsSubject: ReplaySubject<Patron[]> = new ReplaySubject(1);
  private filteredPatrons: Patron[] = [];

  // TODO: This should have a type. Cannot do as there are conflicting references of what the form is
  //       between the contractForm type and contract and what values it will hold.
  /** Holds a "snapshot" of the contract form right as the user hits the submit button. */
  private formSnapshot: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private basisService: BasisService,
    private contractService: ContractService,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private dialogService: ConfirmationDialogService,
    private formBuilder: FormBuilder,
    private ledgerHelperService: LedgerHelperService,
    private locationService: LocationService,
    private marketDataService: MarketDataService,
    private operationsDataService: OperationsDataService,
    private patronService: PatronService,
    private priceAdjustmentService: PriceAdjustmentService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private commodityFuturesPriceService: CommodityFuturesPriceService
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.CONTRACT_CREATOR_ROLE)) {
      this.errorMessage = 'You do not have permission to create contracts.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }

    let queryParams;
    const userDataFrequency = this.authService.userProfile.app_metadata.marketDataFrequency;
    if (!userDataFrequency || ![MarketDataFrequency.ON_DEMAND, MarketDataFrequency.REALTIME].includes(userDataFrequency)) {
      this.errorMessage = 'You do not have access to submit a new contract due to your Market Data Frequency user settings.';
      return;
    }

    this.isLoading = true;

    this.prepForPatronSelection();

    // Get selected client
    this.commodityProfiles$ = this.clientSelectorService.getSelectedClient().pipe(
      // Get HMS Client Settings and Price Adjustments
      switchMap((selectedClient: Client) => {
        this.selectedClient = selectedClient;
        // Load HMSClientSettings and HMSPriceAdjustmentMap for selected client, then return user
        // Performed as separate function to get around limitations to operator chaining mentioned
        // in https://github.com/ReactiveX/rxjs/issues/5599
        return this.getUserAndSettings();
      }),
      // Get HMS User Settings
      switchMap((loggedInUser: User) => {
        this.loggedInUser = loggedInUser;
        return this.getHMSSettingsActiveOriginatorsAndActiveLocations();
      }),
      // Get query parameters
      switchMap((activeLocations: Location[]) => {
        this.locations = activeLocations;
        this.clientLocations = this.locations.filter(location => location.isClientLocation);
        return this.activatedRoute.queryParamMap;
      }),
      // Get Basis document if `basisDocId` query param is passed from Cash Bids screen
      switchMap((paramMap: ParamMap) => {
        queryParams = paramMap;
        this.resetQueryParams();
        const basisDocId = queryParams.get('basisId');
        if (basisDocId) {
          return this.basisService.getBasisByDocId(this.selectedClient.docId, basisDocId);
        }
        return of(undefined);
      }),
      // Get active commodity profiles
      switchMap((basis: Basis) => {
        if (basis && (this.userSettings.authorizedLocationDocIds.includes(basis.locationDocId) || this.isAtiUser)) {
          this.defaultLocationDocId = basis.locationDocId;
          this.qpBasis = basis;
          this.currentBasis = basis;
        }

        // Set default location from query param or user base location if required
        if (this.defaultLocationDocId && !this.defaultLocation) {
          this.defaultLocation = this.clientLocations.find(location => location.docId === this.defaultLocationDocId);
          if (this.defaultLocation) {
            this.contractForm.get('clientLocation').setValue(this.defaultLocation);
            this.contractForm.get('deliveryLocation').setValue(this.defaultLocation);
          }
        }

        return this.commodityProfileService.getActiveCommodityProfilesByTypeAndClientDocId(this.selectedClient.docId);
      }),
      // Handle populating form from query params
      map((profiles: CommodityProfile[]) => {
        this.contractPriceHelper = new ContractPriceHelper(this.contractForm, this.useWholeCent);
        this.contractPriceHelper.setAllPriceValidators();
        this.profiles = profiles;
        this.processQueryParams(queryParams);

        this.prepForLocationSelection();
        this.prepForOriginatorSelection();

        this.setupContractFormFieldChangeSubscription();
        this.contractForm.setAsyncValidators([this.optionContractQuantityValidator]);

        this.reset();
        return this.profiles;
      }),
      tap(() => {
        this.isLoading = false;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = this.errorMessage ? `Error retrieving contract form data: ${this.errorMessage}` : 'Error retrieving contract form data; please try again later';
        console.error(`Error retrieving contract form data: ${err.message ? err.message : err}`);
        return of([]);
      })
    );

    this.commodityMap$ = this.operationsDataService.getCommodityMap().pipe(
      map((commodityMap: CommodityMap) => {
        return this.commodityMap = commodityMap;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving commodities; please try again later';
        console.error(`Error retrieving commodities: ${err}`);
        return of(undefined);
      })
    );
  }

  get canAdjustBasis() {
    return this.authzService.currentUserHasRole(UserRoles.BASIS_ADJUSTER_ROLE);
  }

  get canAdjustMarketFutures() {
    return this.authzService.currentUserHasRole(UserRoles.MARKET_FUTURES_ADJUSTER_ROLE);
  }

  get canAdjustPrices() {
    return this.authzService.currentUserHasRole(UserRoles.PRICE_ADJUSTER_ROLE);
  }

  get canCreateExchange() {
    // include market cash but not spot
    return this.authzService.currentUserHasRole(UserRoles.EXCHANGE_CREATOR_ROLE) &&
      (this.contractForm.get('contractType').value === ContractType.CASH ||
        this.contractForm.get('contractType').value === ContractType.HTA) &&
      this.contractForm.get('pricingType').value === PricingType.MARKET;
  }

  get canCreateHedgedContract() {
    // include market cash including spot
    return this.authzService.currentUserHasRole(UserRoles.HEDGED_CONTRACT_CREATOR_ROLE) &&
      (this.contractType === ContractType.CASH ||
        this.contractForm.get('contractType').value === ContractType.HTA) &&
      this.contractForm.get('pricingType').value === PricingType.MARKET;
  }

  get canSubmitContract() {
    return this.contractForm.valid && this.hasRequiredFields;
  }

  // note: this checks whether all the required fields are entered (handling cases where a field could be disabled)
  get hasRequiredFields() {
    if (this.isLoadingBasis || this.isLoadingFutures) {
      return false;
    }
    const formValues = this.contractForm.getRawValue();

    if (this.contractType === ContractType.CASH && this.isSpot && formValues.productionYear
      && formValues.deliveryPeriod && formValues.futuresYearMonth
      && formValues.basisPrice && formValues.futuresPrice && formValues.cashPrice
      && formValues.side) {
      return true;
    }
    if (this.contractType === ContractType.CASH && !this.isSpot
      && formValues.basisPrice && formValues.futuresPrice && formValues.cashPrice) {
      return true;
    }
    if (this.contractType === ContractType.BASIS && formValues.basisPrice) {
      return true;
    }
    if (this.contractType === ContractType.HTA && formValues.futuresPrice) {
      return true;
    }
    if (this.contractType === ContractType.DP) {
      return true;
    }
    return false;
  }

  // begin price adjustment functions

  get unusedPriceAdjustments() {
    const priceAdjustments = this.contractForm.get('priceAdjustments') as FormGroup;
    return Object.values(this.priceAdjustmentMap.priceAdjustments).filter(priceAdjustment =>
      !Object.keys(priceAdjustments.controls).includes(priceAdjustment.id)).sort((a, b) => a.name < b.name ? -1 : 1);
  }

  get usedPriceAdjustments() {
    const priceAdjustments = this.contractForm.get('priceAdjustments') as FormGroup;
    // returning in reverse order so newest is at top
    return Object.keys(priceAdjustments.controls).reverse();
  }

  addPriceAdjustment(event: KeyboardEvent) {
    // prevent panel from opening on select
    event.stopImmediatePropagation();
    const priceAdjustments = this.contractForm.get('priceAdjustments') as FormGroup;
    const selectedPriceAdjustment = this.newPriceAdjustment.value as HMSPriceAdjustment;
    priceAdjustments.addControl(
      selectedPriceAdjustment.id,
      this.formBuilder.control('', this.contractPriceHelper.getPriceAdjustmentsValidators()));
    // set value to '' to prevent most recently removed adjustment from getting re-selected
    this.newPriceAdjustment.value = '';
  }

  /**
   * Get display value for an HMSPriceAdjustment
   *
   * Used when the HMSPriceAdjustment object is available, e.g. as option values in a select and the
   * Client's HMSPriceAdjustmentMap.priceAdjustments map has been passed to the template
   *
   * @param priceAdjustment The HMSPriceAdjustment for which a display value is needed
   */
  getHMSPriceAdjustmentDisplay(priceAdjustment: HMSPriceAdjustment) {
    return priceAdjustment ? `${priceAdjustment.name} (${priceAdjustment.id})` : '';
  }

  /**
   * Get display value for a ContractPriceAdjustment
   *
   * Used when only the id of an HMSPriceAdjustment is known, e.g. when used as labels for
   * individual price adjustment fields on the template.
   *
   * @param id The id of HMSPriceAdjustment for which a display value is needed taken from the ContractPriceAdjustment
   */
  getContractPriceAdjustmentLabel(id: string) {
    return this.getHMSPriceAdjustmentDisplay(this.priceAdjustmentMap.priceAdjustments[id]);
  }

  processUpdatedPriceAdjustment(formControlName: string) {
    const priceAdjustmentField = this.contractForm.get('priceAdjustments').get(formControlName);
    // do not automatically fix field via rounding if field invalid due to precision
    if (!priceAdjustmentField.valid) {
      return;
    }
    const price = Number(priceAdjustmentField.value);
    if (priceAdjustmentField.value && Number.isFinite(price)) {
      priceAdjustmentField.setValue(this.contractPriceHelper.getRoundedValueAsString('priceAdjustments', price));
    }
  }

  removePriceAdjustment(id: string) {
    const priceAdjustments = this.contractForm.get('priceAdjustments') as FormGroup;
    priceAdjustments.removeControl(id);
    this.updatePriceAdjustmentsTotal();
  }

  updatePriceAdjustmentsTotal() {
    const priceAdjustments = this.contractForm.get('priceAdjustments') as FormGroup;
    this.priceAdjustmentsTotal = 0;
    Object.values(priceAdjustments.controls).forEach(priceAdjustment => {
      const numericPriceAdjustmentValue = Number(priceAdjustment.value);
      this.priceAdjustmentsTotal += Number.isFinite(numericPriceAdjustmentValue) ? numericPriceAdjustmentValue : 0;
    });
    this.calculateCash();
  }

  // end price adjustment functions

  allowTabOnly(event: KeyboardEvent) {
    if (event.key !== 'Tab') {
      event.preventDefault();
    }
  }

  selectDeliveryPeriod(deliveryPeriod: moment.Moment) {
    this.contractForm.get('deliveryPeriod').setValue(deliveryPeriod);
    this.contractForm.get('deliveryPeriod').markAsDirty();
    this.deliveryPeriodRef.close();
  }

  validFuturesMonthFilter = (currentMoment: moment.Moment | null): boolean => {
    if (currentMoment) {
      return this.futuresMonths.includes(currentMoment.month());
    } else {
      return false;
    }
  }

  selectFuturesMonth(futuresMonth: moment.Moment) {
    if (futuresMonth !== (this.contractForm.get('futuresYearMonth').value as moment.Moment)) {
      this.contractForm.get('futuresYearMonth').setValue(futuresMonth);
      this.contractForm.get('futuresYearMonth').markAsDirty();
      this.getMarketFuturesPrice();
      // check for existence since it's undefined if coming from location basis
      if (this.futuresMonthRef) {
        this.futuresMonthRef.close();
      }
    }
  }
  // don't allow weekend days to be selected for expiration date
  expDateFilter = (currentDate: Date): boolean => {
    const dayOfTheWeek = moment(currentDate).weekday();
    return dayOfTheWeek !== 0 && dayOfTheWeek !== 6;
  }

  getContractUnit() {
    const profile = this.contractForm.get('commodityProfile').value;
    return this.commodityMap.commodities[profile.commodityId].contractUnit;
  }

  getDisplayPrice(price: string, formControlName: string) {
    const priceNumber = Number(price);
    if (Number.isFinite(priceNumber)) {
      return this.contractPriceHelper.getRoundedValueAsString(formControlName, priceNumber);
    }
    return '';
  }

  compareCommodityProfile(profile1: CommodityProfile, profile2: CommodityProfile) {
    return profile1 && profile2 && profile1.docId === profile2.docId;
  }

  compareProductionYear(prodYear1: CommodityProfileProductionYear, prodYear2: CommodityProfileProductionYear) {
    return prodYear1 && prodYear2 && prodYear1.year === prodYear2.year;
  }

  formatQuantity(formControlName: string) {
    const formControl = this.contractForm.get(formControlName);
    const quantity = Number(formControl.value);
    if (formControl.value && Number.isFinite(quantity)) {
      formControl.setValue(quantity.toString());
    }
  }

  processUpdatedPrice(formControlName: string) {
    const price = Number(this.contractForm.get(formControlName).value);
    if (this.contractForm.get(formControlName).value && Number.isFinite(price)) {
      this.contractPriceHelper.setFieldValue(formControlName, price);
      this.calculateCash();
    } else {
      this.contractForm.get(formControlName).setValue('');
    }
  }

  clearField(fieldName: string, resetValue?: string) {
    const field = this.contractForm.get(fieldName);
    field.setValue(resetValue || '');
    field.markAsDirty();
    if (fieldName !== 'cashPrice') {
      this.calculateCash();
    }
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('pattern')) {
      return 'Value invalid';
    } else if (control.hasError('matDatepickerMin')) {
      return 'Value invalid';
    } else if (control.hasError('min')) {
      return 'Value must exceed ' + control.errors['min'].min.toFixed(4);
      // avoid formatting max for quantity
    } else if (control.hasError('max') && control.value === this.contractForm.get('quantity').value) {
      return 'Value cannot exceed ' + control.errors['max'].max;
    } else if (control.hasError('max')) {
      return 'Value cannot exceed ' + control.errors['max'].max.toFixed(4);
    } else if (control.hasError('maxlength')) {
      return 'Value cannot exceed ' + control.errors['maxlength'].requiredLength + ' characters';
    } else if (control.hasError('invalidBasicPrice')) {
      return 'Value should be a number (to the quarter cent)';
    } else if (control.hasError('invalidFiveDigitPrice')) {
      return 'Value should be a number (up to five decimal places)';
    } else if (control.hasError('invalidFuturesPrice')) {
      return 'Value should be a number (to the quarter cent)';
    } else if (control.hasError('invalidWholeCentPrice')) {
      return 'Value should be a number (to the whole cent)';
    } else if (control.hasError('invalidNonZeroPrice')) {
      return 'Value cannot be zero';
    } else if (control.hasError('invalidExchange')) {
      return 'Value should be a number (up to four decimal places)';
    } else if (this.contractForm.hasError('optionQuantity')) {
      return 'Must align with contract quantity';
    } else if (control.hasError('matDatepickerMax')) {
      const maxDate = new Date(control.errors[ 'matDatepickerMax' ].max).toLocaleDateString();
      return `Value must be on or before ${maxDate}`;
    }
    return 'Unknown error';
  }

  /**
   * Displays name for location autocomplete input field
   */
  displayLocation(location?: Location) {
    return location ? location.name : '';
  }

  /**
   * Displays name for originator autocomplete input field
   */
  displayOriginator(originator?: Originator) {
    return originator && originator.firstName ? `${originator.firstName} ${originator.lastName} (${originator.accountingSystemId})` : '';
  }

  /**
   * Displays number and name for patron autocomplete input field
   */
  displayPatron(patron?: Patron) {
    return patron ? `${patron.name} (${patron.accountingSystemId})` : '';
  }

  onCloseDatePicker(datePickerToggleId) {
    this[ datePickerToggleId ].nativeElement.firstChild.focus();
  }

  refreshFutures() {
    this.isLoadingFutures = true;
    this.contractForm.get('futuresPrice').markAsUntouched();
    this.getMarketFuturesPrice(true);
  }

  reset() {
    const isLoggedInUserActiveOriginator = this.loggedInUser.status === Status.ACTIVE && this.userSettings.accountingSystemId;
    this.contractForm.reset(
      {
        contractType: this.qpIsSpot ? 'SPOT' : this.qpContractType || ContractType.CASH,
        pricingType: PricingType.MARKET,
        side: Side.BUY,
        deliveryType: DeliveryType.DELIVERED,
        clientLocation: this.defaultLocation || '',
        deliveryLocation: this.defaultLocation || '',
        patron: '',
        productionYear: '',
        deliveryPeriod: this.qpDeliveryPeriodMoment || '',
        futuresYearMonth: this.qpFuturesMonthMoment || '',
        freightPrice: this.useWholeCent ? '0.00' : '0.0000',
        basisAdjustment: this.useWholeCent ? '0.00' : '0.0000',
        exchangeRate: this.useWholeCent ? '1.00' : '1.0000',
        basisPrice: this.qpBasisPrice || '',
        futuresPrice: '',
        commodityProfile: this.qpCommodityProfile || '',
        specialHandling: false,
        specialHandlingType: this.defaultSpecialHandlingType,
        exchangeType: ExchangeType.EFP,
        originator: isLoggedInUserActiveOriginator ?
          { ... this.loggedInUser, accountingSystemId: this.userSettings.accountingSystemId } as Originator :
          ''
      },
      {
        emitEvent: false
      }
    );
    if (this.contractForm.get('contractType').value === 'SPOT') {
      this.isSpot = true;
      this.contractType = ContractType.CASH;
    } else {
      this.isSpot = false;
      this.contractType = this.contractForm.get('contractType').value;
    }

    if (this.qpBasis && this.contractForm.get('deliveryPeriod').value) {
      const qpDeliveryPeriodBasis = this.qpBasis.deliveryPeriodBases[
        this.translateMomentToContractMonth(this.contractForm.get('deliveryPeriod').value)];
      this.marketBasisPrice = qpDeliveryPeriodBasis ? qpDeliveryPeriodBasis.basis : undefined;
    } else {
      this.marketBasisPrice = undefined;
    }
    this.marketFuturesPrice = undefined;

    const profile = this.contractForm.get('commodityProfile').value;
    if (profile) {
      this.prodYears = Object.values(
        this.ledgerHelperService.getTranslatedUniqueActiveProdYears(profile.productionYears, this.ledgerEndOfDay, this.timezone)
      );
      if (this.qpFuturesMonthMoment) {
        this.setProdYear(this.qpFuturesMonthMoment);
      } else {
        this.contractForm.get('productionYear').setValue(this.prodYears[0]);
      }
      this.futuresMonths = this.commodityMap.commodities[profile.commodityId].contractMonths;
    }
    // note: we don't need to update the futuresMonths variable if there is no profile because the futures month selection
    //       will not show at all. When they select a commodity profile, our commodity profile field listener will
    //       update the futuresMonths variable.

    this.setEnabledStatusOfFieldsOnReset();

    this.usedPriceAdjustments.forEach(priceAdjustmentId => this.removePriceAdjustment(priceAdjustmentId));
    this.calculateCash();

    // reset price validators according to the default values
    const specialHandling = this.contractForm.get('specialHandling').value;
    const specialHandlingType = this.contractForm.get('specialHandlingType').value;
    this.contractPriceHelper.setAllPriceValidators({ commodityProfile: profile, specialHandling, specialHandlingType });

    this.contractForm.markAsPristine();
  }

  submit(addAnother = false) {
    // create snapshot here
    this.formSnapshot = { ...this.contractForm.getRawValue() };

    const prodYearLabel = this.formSnapshot.productionYear.label;
    const prodYearStart = moment(this.formSnapshot.productionYear.startDate);
    const prodYearEnd = moment(this.formSnapshot.productionYear.endDate);
    const futuresMonth = this.formSnapshot.futuresYearMonth;
    const futuresMonthWithinProdYear = prodYearStart <= futuresMonth && prodYearEnd >= futuresMonth;
    const contractType = this.formSnapshot.contractType;
    if (futuresMonthWithinProdYear || contractType === ContractType.DP) {
      this.submitContract(addAnother);
    } else {
      this.dialogService.open(
        {
          title: 'Futures Month Warning',
          message: `Futures month ${this.translateMomentToContractMonth(futuresMonth)} is outside prod year ${prodYearLabel}. Do you wish to proceed with contract creation?`,
          btnColor: 'accent'
        },
        'auto',
        '400px'
      );

      this.dialogService.afterClosed().subscribe(confirm => {
        if (!confirm) {
          return;
        }
        this.submitContract(addAnother);
      });
    }
  }

  private submitContract(addAnother: boolean) {
    this.updateComplete = false;
    const newContract = this.buildContractFromSnapshot();

    if (!newContract) return; // prevent creating a contract with no data, snapshot can be undefined.

    this.contractService.createContract(this.selectedClient.docId, newContract)
      .then(() => {
        this.updateComplete = true;
        console.log('Contract successfully created');
        this.openSnackBar(`Contract successfully created for ${newContract.patronName}`, 'DISMISS', true);
        if (addAnother) {
          // stay on new contract screen with patron field getting cleared
          this.contractForm.get('patron').setValue('', { emitEvent: false });
          this.contractForm.get('patron').markAsUntouched();
        } else if (newContract.type === ContractType.DP) {
          this.router.navigate(['/contracts', newContract.docId]);
        } else if (this.canViewLedger) {
          this.router.navigate(['/liveledgers/activityledger'], { replaceUrl: true });
        } else {
          // stay on new contract screen if user does not have access to view the ledger
          this.reset();
          this.router.navigate([], { replaceUrl: true });
        }
      })
      .catch(err => {
        this.updateComplete = true;
        console.error(`Contract creation failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Contract creation failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  private get isAtiUser() {
    // TODO should we use a role here instead?
    return this.userSettings.isAuthorizedAtAllLocations;
  }

  private get canViewLedger() {
    return this.authzService.currentUserHasRole(UserRoles.FULL_LEDGER_VIEWER_ROLE) ||
      this.authzService.currentUserHasRole(UserRoles.LOCATION_LEDGER_VIEWER_ROLE);
  }

  private get defaultSpecialHandlingType() {
    return this.canCreateExchange && !this.canCreateHedgedContract ? 'EXCHANGE' : '';
  }

  /**
   * Calculates and returns values from the passed in form snapshot. Returns `undefined` if
   * unable to use snapshot data.
   * @param formSnapshot The object to pull calculations from.
   */
  private calculateCashOnSnapshot(formSnapshot: any): SnapshotCashValues | undefined {
    if (!formSnapshot) return undefined;

    const values: SnapshotCashValues = {
      freightPrice: undefined,
      basisPrice: undefined,
      basisAdjustment: undefined,
      futuresPrice: undefined,
      cashPrice: undefined,
    }

    values.freightPrice = Number(formSnapshot.freightPrice);
    values.basisAdjustment = Number(formSnapshot.basisAdjustment);

    if (formSnapshot.basisPrice) {
      values.basisPrice = Number(formSnapshot.basisPrice);
    }

    if (formSnapshot.futuresPrice) {
      values.futuresPrice = Number(formSnapshot.futuresPrice);
    }

    if (Number.isFinite(values.basisPrice) && Number.isFinite(values.futuresPrice)) {
      const cashPrice = values.basisPrice + values.futuresPrice - values.freightPrice + values.basisAdjustment;
      values.cashPrice = this.contractPriceHelper.getRoundedValue('cashPrice', cashPrice);
    }

    return values;
  }

  private buildContractFromSnapshot(): Contract {
    const generateSnapshotError = () => {
      this.openSnackBar(`An error has occurred while attempting to create the contract, please try again. If the issue continues, please contact support.`);
      console.error(`Unable to create new contract with form snapshot data. Snapshot data: ${this.formSnapshot}`);
      return undefined;
    }

    if (!this.formSnapshot) generateSnapshotError();

    const contract = new Contract();
    contract.versionNumber = 1;
    contract.status = ContractStatus.NEW;
    contract.clientDocId = this.selectedClient.docId;
    contract.clientName = this.selectedClient.name;
    contract.type = this.contractType;
    contract.isSpot = this.isSpot;
    contract.clientLocationDocId = this.formSnapshot.clientLocation.docId;
    contract.clientLocationAccountingSystemId = this.formSnapshot.clientLocation.accountingSystemId;
    contract.clientLocationName = this.formSnapshot.clientLocation.name;
    contract.deliveryLocationDocId = this.formSnapshot.deliveryLocation.docId;
    contract.deliveryLocationAccountingSystemId = this.formSnapshot.deliveryLocation.accountingSystemId;
    contract.deliveryLocationName = this.formSnapshot.deliveryLocation.name;
    contract.patronDocId = this.formSnapshot.patron.docId;
    contract.patronAccountingSystemId = this.formSnapshot.patron.accountingSystemId;
    contract.patronName = this.formSnapshot.patron.name;
    contract.commodityProfileDocId = this.formSnapshot.commodityProfile.docId;
    contract.commodityProfileAccountingSystemId = this.formSnapshot.commodityProfile.accountingSystemId;
    contract.commodityProfileName = this.formSnapshot.commodityProfile.name;
    contract.productionYear = this.formSnapshot.productionYear.year;
    contract.accountDocId = this.formSnapshot.commodityProfile.accountDocId;
    contract.commodityId = this.formSnapshot.commodityProfile.commodityId;
    contract.deliveryPeriod = this.translateMomentToContractMonth(moment(this.formSnapshot.deliveryPeriod));
    contract.quantity = Number(this.formSnapshot.quantity);
    contract.freightPrice = Number(this.formSnapshot.freightPrice);
    contract.basisAdjustment = Number(this.formSnapshot.basisAdjustment);
    contract.exchangeRate = Number(this.formSnapshot.exchangeRate);
    const creatorName = `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`;
    contract.creatorDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    contract.creatorAccountingSystemId = this.userSettings.accountingSystemId;
    contract.creatorName = creatorName;
    contract.lastUpdatedByAccountingSystemId = this.userSettings.accountingSystemId;
    contract.lastUpdatedByName = creatorName;
    contract.originatorDocId = this.formSnapshot.originator.docId;
    contract.originatorAccountingSystemId = this.formSnapshot.originator.accountingSystemId;
    contract.originatorName = `${this.formSnapshot.originator.firstName} ${this.formSnapshot.originator.lastName}`;

    // Fields not populated or defaulted for DP Contracts
    if (contract.type !== ContractType.DP) {
      contract.pricingType = this.formSnapshot.pricingType;
      contract.side = this.formSnapshot.side;
      contract.deliveryType = this.formSnapshot.deliveryType;
      contract.futuresYearMonth = this.translateMomentToContractMonth(moment(this.formSnapshot.futuresYearMonth));
    }

    // No prices will be entered for DP Contracts
    // Basis price only entered for Basis Contracts
    if (contract.type === ContractType.BASIS) {
      contract.basisPrice = Number(this.formSnapshot.basisPrice);
    }

    // Futures price only entered for HTA and Cash Contracts
    if (contract.type === ContractType.HTA || contract.type === ContractType.CASH) {
      contract.futuresPrice = Number(this.formSnapshot.futuresPrice);

      // Cash and HTA target contracts need to have targetOrderThreshold stored; defaults to 0
      if (contract.pricingType === PricingType.LIMIT) {
        contract.targetOrderThreshold = parseInt(this.formSnapshot.commodityProfile.targetOrderThreshold, 10);
      } else {
        // For PricingType.MARKET we have to indicate if the market price has been adjusted
        contract.isMarketFuturesPriceAdjusted = contract.futuresPrice !==
          this.contractPriceHelper.getRoundedValue('futuresPrice', this.marketFuturesPrice);
      }
    }

    // Cash price only entered for Cash Contract
    if (contract.type === ContractType.CASH) {
      /// calculate cash on snapshot here for final values after clicking submit
      const cashValues = this.calculateCashOnSnapshot(this.formSnapshot);
      if (cashValues) {
        contract.cashPrice = cashValues.cashPrice;
        contract.freightPrice = cashValues.freightPrice;
        contract.basisPrice = cashValues.basisPrice;
        contract.basisAdjustment = cashValues.basisAdjustment;
        contract.futuresPrice = cashValues.futuresPrice;
        contract.cashPrice = cashValues.cashPrice;
      } else {
        generateSnapshotError();
      }
    }

    // Optional expiration date field - TimeInForce defaults to GTC
    if (this.formSnapshot.expirationDate) {
      contract.expirationDate = moment(this.formSnapshot.expirationDate).format(DATE_FORMAT);
      contract.timeInForce = TimeInForce.GTD;
    }

    // Optional custom contract ID for sale contracts
    if (this.formSnapshot.customContractId) {
      contract.customContractId = this.formSnapshot.customContractId;
    }

    // Optional Price Adjustments
    if (contract.side === Side.BUY) {
      contract.priceAdjustmentsTotal = this.contractPriceHelper.getRoundedValue('priceAdjustments', this.priceAdjustmentsTotal);
      contract.priceAdjustments = Object.keys(this.formSnapshot.priceAdjustments).map(key => ({ id: key, value: Number(this.formSnapshot.priceAdjustments[key]) }));
    }

    // Optional exchange
    if (this.formSnapshot.specialHandling && this.formSnapshot.specialHandlingType === 'EXCHANGE' &&
      (contract.type === ContractType.CASH || contract.type === ContractType.HTA) && contract.pricingType === PricingType.MARKET) {
      contract.isExchange = true;
      contract.exchangeType = this.formSnapshot.exchangeType;
      contract.exchangeContracts = Number(this.formSnapshot.exchangeContracts);
      contract.exchangeContraFirm = this.formSnapshot.exchangeContraFirm;
      contract.exchangeContraAccount = this.formSnapshot.exchangeContraAccount;
    }

    // Optional OTC Trade
    if (this.formSnapshot.specialHandling && this.formSnapshot.specialHandlingType === 'OTC' &&
      (contract.type === ContractType.CASH || contract.type === ContractType.HTA) && contract.pricingType === PricingType.MARKET) {
      contract.hasRelatedHedge = true;
      contract.relatedHedgeType = HedgeType.OTC;
    }

    // Optional Exercised Option
    if (this.formSnapshot.specialHandling && this.formSnapshot.specialHandlingType === 'OPTION' &&
      (contract.type === ContractType.CASH || contract.type === ContractType.HTA) && contract.pricingType === PricingType.MARKET) {
      contract.hasRelatedHedge = true;
      contract.relatedHedgeType = HedgeType.OPTION;
      contract.hedgedContracts = Number(this.formSnapshot.optionContracts);
    }

    // Optional comments
    if (this.formSnapshot.comments) {
      contract.comments = this.formSnapshot.comments;
    }

    // Optional instructions
    if (this.formSnapshot.instructions) {
      contract.instructions = this.formSnapshot.instructions
    }

    return contract;
  }

  // used when queryparams change due to navigation from left nav or speed dial
  private resetQueryParams() {
    this.qpContractType = undefined;
    this.qpFuturesMonthMoment = undefined;
    this.qpDeliveryPeriodMoment = undefined;
    this.qpBasisPrice = undefined;
    this.qpCommodityProfile = undefined;
    this.defaultLocationDocId = this.userSettings.baseLocationDocId;
    this.defaultLocation = undefined;
    this.qpBasis = undefined;
  }

  private processUserSettings() {
    // ensure accounting system id (buyer code) is assigned
    if (!this.userSettings.accountingSystemId && !this.isAtiUser) {
      this.errorMessage = 'No user accounting system ID found';
      throw new Error(this.errorMessage);
    }
    // ensure authorized location(s) is assigned
    if (!this.userSettings.authorizedLocationDocIds.length && !this.isAtiUser) {
      this.errorMessage = 'No user authorized location found';
      throw new Error(this.errorMessage);
    }
    this.defaultLocationDocId = this.userSettings.baseLocationDocId;
  }

  // TODO Exchange Rate is not yet being accounted for in handling prices
  private calculateCash(skipBlankCash?: boolean) {
    const freightPrice = Number(this.contractForm.get('freightPrice').value);
    const basisAdjustment = Number(this.contractForm.get('basisAdjustment').value);
    // use marketBasisPrice unless manuallyEnteringBasis
    let basisPrice: number;
    if (this.contractForm.get('basisPrice').enabled) {
      basisPrice = Number(this.contractForm.get('basisPrice').value);
    } else if (Number.isFinite(this.marketBasisPrice)) {
      basisPrice = this.marketBasisPrice;
    }
    // set futures price and set cash form value if disabled
    let futuresPrice: number;
    if (this.contractForm.get('futuresPrice').enabled) {
      futuresPrice = Number(this.contractForm.get('futuresPrice').value);
    } else if (Number.isFinite(this.marketFuturesPrice)) {
      futuresPrice = this.marketFuturesPrice;
    }
    let cashPrice: number;
    if (Number.isFinite(basisPrice) && Number.isFinite(futuresPrice)) {
      cashPrice = basisPrice + futuresPrice - freightPrice + basisAdjustment;
      if (this.contractForm.get('cashPrice').disabled) {
        this.contractPriceHelper.setFieldValue('cashPrice', cashPrice);
        this.contractForm.get('cashPrice').markAsTouched();
      }
    }
    // set cash form value to calculated cash price if available and no cash value set
    let cashFormValue = Number(this.contractForm.get('cashPrice').value);
    if (Number.isFinite(cashPrice) && !cashFormValue && !skipBlankCash) {
      this.contractPriceHelper.setFieldValue('cashPrice', cashPrice);
      this.contractForm.get('cashPrice').markAsTouched();
      cashFormValue = cashPrice;
    }
    // set futures form value using current basis and cash values
    if (this.contractForm.get('cashPrice').enabled && Number.isFinite(cashFormValue) && Number.isFinite(basisPrice)) {
      const targetFuturesPrice = cashFormValue - basisPrice + freightPrice - basisAdjustment;
      this.contractPriceHelper.setFieldValue('futuresPrice', targetFuturesPrice);
      this.contractForm.get('futuresPrice').markAsTouched();
    }
  }

  private onDeliveryLocationChanges() {
    return this.contractForm.get('deliveryLocation').valueChanges.subscribe(() => {
      this.getBasisIfEligible();
    });
  }

  private onCommodityProfileChanges() {
    return this.contractForm.get('commodityProfile').valueChanges.subscribe((profile: CommodityProfile) => {
      this.currentBasis = undefined;
      if (profile) {
        // set contract limits from user settings
        const contractLimit = this.userSettings.contractLimits.find(limit => limit.commodityProfileDocId === profile.docId);
        const quantityValidators = contractLimit ? [Validators.required, Validators.pattern(QUANTITY_REGEX),
        Validators.max(contractLimit.quantityLimit)] : [Validators.required, Validators.pattern(QUANTITY_REGEX)];
        this.contractForm.get('quantity').setValidators(quantityValidators);
        this.contractForm.get('quantity').updateValueAndValidity();

        // set min and max price from commodity profile
        this.contractPriceHelper.setCashAndFuturesValidators({ commodityProfile: profile });

        this.contractForm.get('deliveryPeriod').setValue('');
        this.contractForm.get('deliveryPeriod').markAsUntouched();

        // if initially setting commodity profile coming in from quote there may be more than one profile for that commodity. We want to
        // preserve the month selection and run the market futures call that would have been done had there been a single match
        const preserveMonth = this.qpFuturesMonthMoment === this.contractForm.get('futuresYearMonth').value &&
          this.qpCommodityId === profile.commodityId;
        if (preserveMonth) {
          this.getMarketFuturesPrice();
        } else {
          this.contractForm.get('futuresYearMonth').setValue('');
          this.contractForm.get('futuresYearMonth').markAsUntouched();
        }
        this.contractForm.get('basisPrice').setValue('');
        this.contractForm.get('futuresPrice').setValue('');
        this.contractForm.get('cashPrice').setValue('');
        this.usedPriceAdjustments.forEach(priceAdjustmentId => this.removePriceAdjustment(priceAdjustmentId));
        this.priceAdjustmentsTotal = 0;
        this.marketBasisPrice = undefined;
        this.marketFuturesPrice = undefined;
        this.futuresMonths = this.commodityMap.commodities[profile.commodityId].contractMonths;

        this.prodYears = Object.values(
          this.ledgerHelperService.getTranslatedUniqueActiveProdYears(profile.productionYears, this.ledgerEndOfDay, this.timezone)
        );
        this.contractForm.get('productionYear').setValue(this.prodYears[0]);

        if (this.isSpot) {
          this.contractForm.get('deliveryPeriod').setValue(moment());
          if (this.contractForm.get('side').value === Side.SELL) {
            this.selectNearbyFuturesMonth();
          }
        }

        this.setEnabledStatusOfPriceFields();
        this.getBasisIfEligible();
      } else {
        this.prodYears = [];
        this.contractForm.get('productionYear').setValue('');
      }
    });
  }

  private onProductionYearChanges() {
    return this.contractForm.get('productionYear').valueChanges.subscribe((prodYear: CommodityProfileProductionYear) => {
      if (prodYear && this.contractForm.get('pricingType').value === PricingType.LIMIT) {
        const futuresMoment = moment(prodYear.targetFuturesYearMonth);
        this.selectFuturesMonth(futuresMoment);
      }
    });
  }

  // this.contractForm.get('contractType').value can be SPOT, CASH, HTA, BASIS, or DP
  // this.contractType can be CASH, HTA, BASIS, or DP (no SPOT)
  private onContractTypeChanges() {
    return this.contractForm.get('contractType').valueChanges.subscribe(contractType => {
      if (contractType === 'SPOT') {
        this.isSpot = true;
        this.contractType = ContractType.CASH;
      } else {
        this.isSpot = false;
        this.contractType = contractType;
      }
      if (this.defaultSpecialHandlingType && contractType !== ContractType.CASH && contractType !== ContractType.HTA) {
        this.contractForm.get('specialHandling').setValue(false);
      } else if (!this.defaultSpecialHandlingType && this.contractType !== ContractType.CASH && contractType !== ContractType.HTA) {
        this.contractForm.get('specialHandling').setValue(false);
      }
      this.handleSpotContract();
      this.setEnabledStatusOfPriceFields();
      // potentially reenable basis for buy contracts in situations where there is no basis set.
      this.handleBasisIfEligible();
    });
  }

  private onPricingTypeChanges() {
    return this.contractForm.get('pricingType').valueChanges.subscribe(pricingType => {
      this.setEnabledStatusOfPriceFields();
      const prodYear = this.contractForm.get('productionYear').value as CommodityProfileProductionYear;
      if (pricingType === PricingType.LIMIT && prodYear && !this.contractForm.get('futuresYearMonth').value) {
        const futuresMonth = moment(prodYear.targetFuturesYearMonth);
        this.selectFuturesMonth(futuresMonth);
      } else if (pricingType === PricingType.MARKET && this.contractForm.get('commodityProfile').value
        && this.contractForm.get('futuresYearMonth') && this.contractForm.get('futuresYearMonth').value) {
        if (!this.marketFuturesPrice) {
          this.getMarketFuturesPrice();
        } else {
          this.contractPriceHelper.setFieldValue('futuresPrice', this.marketFuturesPrice);
          this.calculateCash();
        }
      }
      if (pricingType !== PricingType.MARKET) {
        this.contractForm.get('specialHandling').setValue(false);
      }
      // potentially reenable basis for buy contracts in situations where there is no basis set.
      this.handleBasisIfEligible();
    });
  }

  private onSideChanges() {
    return this.contractForm.get('side').valueChanges.subscribe(side => {
      this.setEnabledStatusOfPriceFields();

      if (this.contractForm.get('pricingType').value === PricingType.MARKET && this.marketData) {
        const commodityId = (this.contractForm.get('commodityProfile').value as CommodityProfile).commodityId;
        const marketDataDivisor = this.commodityMap.commodities[commodityId].marketDataDivisor;
        this.marketFuturesPrice = this.getMarketDataFuturesPrice(this.marketData, side) / marketDataDivisor;
        this.contractPriceHelper.setFieldValue('futuresPrice', this.marketFuturesPrice);
      }

      if (side === Side.SELL) {
        this.contractForm.get('basisPrice').setValue('');
        this.contractForm.get('cashPrice').setValue('');
        this.marketBasisPrice = undefined;
      } else {
        this.getBasisIfEligible();
      }
    });
  }

  private onDeliveryPeriodChanges() {
    return this.contractForm.get('deliveryPeriod').valueChanges.subscribe(() => {
      if (this.currentBasis) {
        this.handleBasisIfEligible();
      } else {
        this.getBasisIfEligible();
      }
    });
  }

  private onSpecialHandlingChanges() {
    return this.contractForm.get('specialHandling').valueChanges.subscribe(specialHandling => {
      if (specialHandling) {
        this.contractForm.get('specialHandlingType').enable();
      } else {
        this.contractForm.get('specialHandlingType').disable();
      }
      const specialHandlingType = this.contractForm.get('specialHandlingType').value;
      this.contractPriceHelper.setCashAndFuturesValidators({ specialHandling });
      this.setEnabledStatusOfSpecialHandlingFields(specialHandling, specialHandlingType);
    });
  }

  private onSpecialHandlingTypeChanges() {
    return this.contractForm.get('specialHandlingType').valueChanges.subscribe(specialHandlingType => {
      const specialHandling = this.contractForm.get('specialHandling').value;
      this.contractPriceHelper.setCashAndFuturesValidators({ specialHandlingType });
      this.setEnabledStatusOfSpecialHandlingFields(specialHandling, specialHandlingType);
    });
  }

  private setupContractFormFieldChangeSubscription() {
    this.contractFormFieldSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.contractFormFieldSubscriptions = [];
    this.contractFormFieldSubscriptions.push(this.onCommodityProfileChanges());
    this.contractFormFieldSubscriptions.push(this.onProductionYearChanges());
    this.contractFormFieldSubscriptions.push(this.onContractTypeChanges());
    this.contractFormFieldSubscriptions.push(this.onDeliveryLocationChanges());
    this.contractFormFieldSubscriptions.push(this.onPricingTypeChanges());
    this.contractFormFieldSubscriptions.push(this.onSideChanges());
    this.contractFormFieldSubscriptions.push(this.onDeliveryPeriodChanges());
    this.contractFormFieldSubscriptions.push(this.onSpecialHandlingChanges());
    this.contractFormFieldSubscriptions.push(this.onSpecialHandlingTypeChanges());
  }

  private setEnabledStatusOfSpecialHandlingFields(specialHandling: boolean, specialHandlingType: string) {
    if (specialHandling && specialHandlingType === 'EXCHANGE') {
      this.contractForm.get('exchangeType').enable();
      this.contractForm.get('exchangeContracts').enable();
      this.contractForm.get('exchangeContraFirm').enable();
      this.contractForm.get('exchangeContraAccount').enable();

      this.contractForm.get('optionContracts').disable();
    } else if (specialHandling && specialHandlingType === 'OPTION') {
      this.contractForm.get('optionContracts').enable();

      this.contractForm.get('exchangeType').disable();
      this.contractForm.get('exchangeContracts').disable();
      this.contractForm.get('exchangeContraFirm').disable();
      this.contractForm.get('exchangeContraAccount').disable();
    } else {
      this.contractForm.get('exchangeType').disable();
      this.contractForm.get('exchangeContracts').disable();
      this.contractForm.get('exchangeContraFirm').disable();
      this.contractForm.get('exchangeContraAccount').disable();
      this.contractForm.get('optionContracts').disable();
    }
  }

  private setEnabledStatusOfFieldsOnReset() {
    this.setEnabledStatusOfPriceFields();

    const specialHandling = this.contractForm.get('specialHandling').value;
    const specialHandlingType = this.contractForm.get('specialHandlingType').value;
    if (!specialHandling) {
      this.contractForm.get('specialHandlingType').disable();
    } else {
      // note: this line would never execute today since we always
      //       reset to a non special handling state
      this.contractForm.get('specialHandlingType').enable();
    }
    this.setEnabledStatusOfSpecialHandlingFields(specialHandling, specialHandlingType);

    if (this.isSpot) {
      this.contractForm.get('deliveryPeriod').disable();
      this.contractForm.get('futuresYearMonth').disable();
      this.contractForm.get('productionYear').disable();
    } else if (this.contractType === ContractType.DP) {
      this.contractForm.get('deliveryPeriod').enable();
      this.contractForm.get('futuresYearMonth').disable();
      this.contractForm.get('productionYear').enable();
    } else {
      this.contractForm.get('deliveryPeriod').enable();
      this.contractForm.get('futuresYearMonth').enable();
      this.contractForm.get('productionYear').enable();
    }
  }

  /**
   * Ensures the quantity on a contract with an option type related hedge matches the hedged quantity for the exercised option
   */
  private optionContractQuantityValidator = (formGroup: FormGroup) => {
    if (!formGroup.value.specialHandling || formGroup.value.specialHandlingType !== 'OPTION') {
      return of(undefined);
    }
    const contractSize = this.commodityMap.commodities[formGroup.value.commodityProfile.commodityId].contractSize;
    const optionQuantity = parseInt((Number(formGroup.value.optionContracts) * contractSize).toFixed(0), 10);
    const quantity = Number(formGroup.value.quantity);
    if (optionQuantity !== quantity) {
      return of({ optionQuantity: true });
    }
    return of(undefined);
  }

  private setEnabledStatusOfPriceFields() {
    const pricingType = this.contractForm.get('pricingType').value;
    const side = this.contractForm.get('side').value;
    this.setFieldEnabled('basisPrice',
      (
        (side === Side.SELL && (this.contractType === ContractType.CASH || this.contractType === ContractType.BASIS)) ||
        (this.contractType === ContractType.BASIS && pricingType === PricingType.LIMIT) ||
        (this.isSpot && side === Side.SELL)
      )
    );
    this.setFieldEnabled('futuresPrice',
      ((this.contractType === ContractType.HTA || this.contractType === ContractType.CASH)
        && pricingType === PricingType.MARKET && this.canAdjustMarketFutures)
      || (this.contractType === ContractType.HTA && pricingType === PricingType.LIMIT));
    this.setFieldEnabled('cashPrice', this.contractType === ContractType.CASH && pricingType === PricingType.LIMIT);
    this.setFuturesMonthFromBasis = pricingType === PricingType.MARKET && this.contractType !== ContractType.DP;
  }

  private setFieldEnabled(fieldName: string, enabled: boolean) {
    if (enabled) {
      this.contractForm.get(fieldName).enable();
    } else {
      this.contractForm.get(fieldName).disable();
    }
  }

  private handleSpotContract() {
    if (this.isSpot) {
      this.contractForm.get('pricingType').setValue(PricingType.MARKET);
      this.contractForm.get('side').setValue(Side.BUY);
      this.contractForm.get('productionYear').disable();
      this.contractForm.get('deliveryPeriod').disable();
      this.contractForm.get('futuresYearMonth').disable();
      if (this.contractForm.get(`commodityProfile`).value) {
        this.contractForm.get('deliveryPeriod').setValue(moment());
        this.contractForm.get('productionYear').setValue(this.prodYears[0]);
      }
    } else {
      this.contractForm.get('productionYear').enable();
      this.contractForm.get('deliveryPeriod').enable();
      this.setFieldEnabled('futuresYearMonth', this.contractType !== ContractType.DP);
    }
  }

  private processQueryParams(queryParams: ParamMap) {
    const type = queryParams.get('contractType');
    const isSpot = Boolean(queryParams.get('isSpot'));
    this.qpIsSpot = isSpot;
    this.isSpot = isSpot;
    if (isSpot) {
      this.contractForm.get('contractType').setValue('SPOT');
    } else if (type) {
      this.qpContractType = ContractType[type] as ContractType;
      this.contractForm.get('contractType').setValue(this.qpContractType);
    }
    // if coming from quotes, and a single profile matches the commodity, set profile
    this.qpCommodityId = queryParams.get('commodityId');
    if (this.qpCommodityId) {
      const matchedCommodityProfiles = this.profiles.filter(profile => profile.commodityId === this.qpCommodityId);
      if (matchedCommodityProfiles.length === 1) {
        this.qpCommodityProfile = matchedCommodityProfiles[0];
        this.contractForm.get('commodityProfile').setValue(this.qpCommodityProfile);
      }
    }
    // if coming from bids, and location is in user's auth locations, set profile
    if (this.qpBasis) {
      this.qpCommodityProfile = this.profiles.find(profile => profile.docId === this.qpBasis.commodityProfileDocId);
      this.contractForm.get('commodityProfile').setValue(this.qpCommodityProfile);
    }
    const deliveryPeriod = queryParams.get('delivery');
    if (deliveryPeriod && this.qpBasis) {
      const qpDeliveryPeriodBasis = this.qpBasis.deliveryPeriodBases[deliveryPeriod];
      if (qpDeliveryPeriodBasis) {
        this.qpBasisPrice = this.contractPriceHelper.getRoundedValueAsString('basisPrice', qpDeliveryPeriodBasis.basis);
        this.marketBasisPrice = qpDeliveryPeriodBasis.basis;
        this.contractForm.get('basisPrice').setValue(this.qpBasisPrice);
      }
      this.qpDeliveryPeriodMoment = this.translateContractMonthToMoment(deliveryPeriod);
      this.contractForm.get('deliveryPeriod').setValue(this.qpDeliveryPeriodMoment);
      this.qpFuturesMonthMoment = this.translateContractMonthToMoment(this.qpBasis.deliveryPeriodBases[deliveryPeriod].futuresYearMonth);
      this.selectFuturesMonth(this.qpFuturesMonthMoment);
      this.setProdYear(this.qpFuturesMonthMoment);
    }
    const futuresMonth = queryParams.get('futuresYearMonth');
    if (futuresMonth) {
      this.qpFuturesMonthMoment = this.translateContractMonthToMoment(futuresMonth);
      this.selectFuturesMonth(this.qpFuturesMonthMoment);
      this.setProdYear(this.qpFuturesMonthMoment);
    }
    this.calculateCash();
    this.setEnabledStatusOfPriceFields();
  }

  private setProdYear(futuresMonth: moment.Moment) {
    if (futuresMonth) {
      const commodityProfile = this.contractForm.get('commodityProfile').value as CommodityProfile;
      if (commodityProfile) {
        const productionYear = Object.values(commodityProfile.productionYears).find((prodYear: CommodityProfileProductionYear) =>
          this.isFuturesMonthInProdYear(futuresMonth, prodYear));
        if (productionYear) {
          this.contractForm.get('productionYear').setValue(productionYear);
        }
      }
    }
  }

  /**
   * Indicates if a futures month is within the range of the input production year
   * @param futuresYearMonth Futures month year Moment
   * @param productionYear CommodityProfileProductionYear
   */
  private isFuturesMonthInProdYear(futuresYearMonth: moment.Moment, productionYear: CommodityProfileProductionYear): boolean {
    return futuresYearMonth.isSameOrAfter(moment(productionYear.startDate, DATE_FORMAT))
      && futuresYearMonth.isSameOrBefore(moment(productionYear.endDate, DATE_FORMAT).endOf('day'));
  }

  private prepForLocationSelection() {
    this.filteredClientLocations$ = this.contractForm.controls.clientLocation.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | Location>(''),
      map(searchTerm => {
        // Return all locations when a location has already been selected to avoid the user needing to clear the field
        if (typeof searchTerm !== 'string') {
          return this.clientLocations;
        }
        return this.clientLocations.filter(location => location.name.toLowerCase().includes(searchTerm.toLowerCase()));
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving client locations; please try again later';
        console.error(`Error retrieving client locations: ${err}`);
        return of([]);
      }),
    );

    this.filteredDeliveryLocations$ = this.contractForm.controls.deliveryLocation.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | Location>(''),
      map(searchTerm => {
        // Return all locations when a location has already been selected to avoid the user needing to clear the field
        if (typeof searchTerm !== 'string') {
          return this.locations;
        }
        return this.locations.filter(location => location.name.toLowerCase().includes(searchTerm.toLowerCase()));
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving delivery locations; please try again later';
        console.error(`Error retrieving delivery locations: ${err}`);
        return of([]);
      }),
    );
  }

  private getActiveAuthorizedLocations(): Observable<Location[]> {
    if (this.userSettings.isAuthorizedAtAllLocations) {
      return this.locationService.getActiveLocationsByClientDocId(this.selectedClient.docId);
    }
    return combineLatest(this.userSettings.authorizedLocationDocIds.map(
      locationDocId => this.locationService.getLocationByDocId(this.selectedClient.docId, locationDocId)
    )).pipe(
      map(locations => locations.filter(location => location.isActive))
    );
  }

  private prepForOriginatorSelection() {
    this.filteredOriginators$ = this.contractForm.controls.originator.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | Originator>(''),
      map(searchTerm => {
        if (!searchTerm) {
          searchTerm = this.contractForm.get('originator').value;
        }
        // Return all originators when an originator has already been selected to avoid the user needing to clear the field
        if (typeof searchTerm !== 'string') {
          return this.originators;
        }
        const comparisonTerm = (searchTerm as string).toLowerCase();
        return this.originators.filter(
          originator =>
            (`${originator.firstName} ${originator.lastName}`).toLowerCase().includes(comparisonTerm) ||
            originator.accountingSystemId.toLowerCase().includes(comparisonTerm)
        );
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving originators; please try again later';
        console.error(`Error retrieving originators: ${err}`);
        return of([]);
      })
    );
  }

  /**
   * This prepares all the necessary variables and mechanisms to setup the patron selection
   */
  private prepForPatronSelection(): void {
    this.isLoadingPatron = true;
    this.activePatrons$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((client: Client) => {
        return this.patronService.getActivePatronsByClientDocId(client.docId);
      }),
      tap((patrons: Patron[]) => {
        this.patrons = patrons;
        this.filteredPatronsSubject.next(this.filterPatrons(this.contractForm.get('patron').value));
        this.isLoadingPatron = false;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        this.errorMessage = 'Error retrieving client patrons; please try again later';
        console.error(`Error retrieving client patrons: ${err}`);
        return of([]);
      })
    );

    this.patronControlChanges$ = this.contractForm.controls.patron.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      startWith<string | Patron>(''),
      tap((searchTerm: string | Patron) => {
        this.filteredPatronsSubject.next(this.filterPatrons(searchTerm));
      })
    );

    this.filteredPatrons$ = this.filteredPatronsSubject.asObservable().pipe(
      tap((filteredPatrons: Patron[]) => {
        this.filteredPatrons = filteredPatrons;
      })
    );
  }

  /**
   * This return a filtered patrons from the specified search term
   * @param searchTerm the user input search term to be filtered against
   * @returns the filtered patron list
   */
  private filterPatrons(searchTerm: string | Patron): Patron[] {
    const hasTyped3Chars = typeof searchTerm === 'string' && searchTerm.length >= 3;
    if (hasTyped3Chars) {
      const lowerCaseSearchTerm = (searchTerm as string).toLowerCase();
      return this.patrons.filter(patron => patron.name.toLowerCase().includes(lowerCaseSearchTerm) ||
        patron.accountingSystemId.toLowerCase().includes(lowerCaseSearchTerm));
    }
    return [];
  }

  private isEligibleToUseMarketBasisPrice() {
    const side = this.contractForm.get('side').value as Side;
    const deliveryLocation = this.contractForm.get('deliveryLocation').value as Location;
    const profile = this.contractForm.get('commodityProfile').value as CommodityProfile;
    return this.contractType !== ContractType.DP && side === Side.BUY && deliveryLocation && deliveryLocation.docId && profile;
  }

  private getBasisIfEligible() {
    const deliveryLocation = this.contractForm.get('deliveryLocation').value as Location;
    const profile = this.contractForm.get('commodityProfile').value as CommodityProfile;
    if (this.isEligibleToUseMarketBasisPrice()) {
      this.isLoadingBasis = true;
      this.basisService.getBasisByClientCommodityProfileAndLocation(this.selectedClient.docId, profile.docId, deliveryLocation.docId).pipe(
        take(1),
        tap((basis: Basis) => {
          this.currentBasis = basis;
          this.handleBasisIfEligible();
          this.isLoadingBasis = false;
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        catchError(err => {
          this.contractForm.get('basisPrice').disable();
          this.contractForm.get('basisPrice').setValue('');
          this.contractForm.get('cashPrice').setValue('');
          this.marketBasisPrice = undefined;
          this.isLoadingBasis = false;
          this.openSnackBar('Error retrieving basis for selected delivery period', 'DISMISS', false);
          console.error(`Error retrieving basis data: ${err.message ? err.message : err}`);
          return of(undefined);
        })
      ).subscribe();
    }
  }

  // note: this function should only be invoked when the queried basis is up to date
  //       to the specific commodity profile and delivery location
  private handleBasisIfEligible() {
    if (!this.isEligibleToUseMarketBasisPrice()) {
      return;
    }

    const deliveryPeriod = this.contractForm.get('deliveryPeriod').value as moment.Moment;
    if (this.currentBasis && deliveryPeriod) {
      const deliveryPeriodBasis = this.currentBasis.deliveryPeriodBases[this.translateMomentToContractMonth(deliveryPeriod)];
      if (deliveryPeriodBasis) {
        this.marketBasisPrice = deliveryPeriodBasis.basis;
        this.contractPriceHelper.setFieldValue('basisPrice', this.marketBasisPrice);
        const futuresMonth = this.translateContractMonthToMoment(deliveryPeriodBasis.futuresYearMonth);
        if (this.isSpot || (this.setFuturesMonthFromBasis && !this.contractForm.get('futuresYearMonth').value)) {
          this.selectFuturesMonth(futuresMonth);
        }
        if (!(this.contractType === ContractType.BASIS && this.contractForm.get('pricingType').value === PricingType.LIMIT)) {
          this.contractForm.get('basisPrice').disable();
        }
        this.calculateCash();
        // No basis for delivery period - enable basis input
      } else {
        this.marketBasisPrice = undefined;
        if (this.isSpot) {
          this.handleNoBasisForSpot();
        } else {
          this.enableManualBasisEntry();
        }
      }
    } else if (deliveryPeriod) {
      this.marketBasisPrice = undefined;
      if (this.isSpot) {
        this.handleNoBasisForSpot();
      } else {
        this.enableManualBasisEntry();
      }
    }
  }

  private handleNoBasisForSpot() {
    if (this.isSpot) {
      this.openSnackBar('No basis for the selected commodity profile, delivery period and delivery location. \
                        Please contact a Basis Admin for assistance', 'DISMISS', false);
      // clear delivery period so that the user is not able to submit a bad spot contract for the selected commodity profile and location
      this.contractForm.get('basisPrice').setValue('', { emitEvent: false });
      this.contractForm.get('basisPrice').disable();

      this.selectNearbyFuturesMonth();
    }
  }

  private selectNearbyFuturesMonth() {
    const selectedFuturesMonth = moment();
    let nearbyMonthIdx = this.futuresMonths.findIndex(month => month >= selectedFuturesMonth.get('month'));
    if (nearbyMonthIdx === -1) {
      // select the first available futures month in the next year if there is no nearby month
      nearbyMonthIdx = 0;
      selectedFuturesMonth.add(1, 'years');
    }
    selectedFuturesMonth.set('month', this.futuresMonths[nearbyMonthIdx]);
    this.selectFuturesMonth(selectedFuturesMonth);
  }

  private enableManualBasisEntry() {
    this.contractForm.get('basisPrice').setValue('');
    if (!(this.contractType === ContractType.CASH && this.contractForm.get('pricingType').value === PricingType.LIMIT)) {
      this.contractForm.get('cashPrice').setValue('');
    }
    if (this.contractType === ContractType.CASH || this.contractType === ContractType.BASIS) {
      this.contractForm.get('basisPrice').enable();
    }
  }

  private getMarketFuturesPrice(isRefresh = false) {
    const futuresMonth = this.contractForm.get('futuresYearMonth').value;
    const commodityId = (this.contractForm.get('commodityProfile').value as CommodityProfile).commodityId;
    if (futuresMonth && commodityId) {
      this.isLoadingFutures = true;
      if (!isRefresh) {
        this.contractForm.get('futuresPrice').setValue('');
        this.contractForm.get('cashPrice').setValue('');
      }
      this.marketFuturesPrice = undefined;
      const contractYearMonth = this.translateMomentToContractMonth(futuresMonth as moment.Moment);
      const docId = commodityId + contractYearMonth.substr(2) + contractYearMonth.substring(0, 2);
      this.marketDataService.getRealTimeMarketDataByDocId(docId, this.authService.accessToken).pipe(
        take(1),
        tap((marketData: MarketData) => {
          this.marketData = marketData;
          // TODO: Handle undefined expirationDate? OK for expiryMaxDate, not for check for invalid month
          this.expiryMaxDate = moment(marketData.expirationDate).endOf('day').toDate();
          if (!marketData || moment(marketData.expirationDate).startOf('day').isBefore(moment())) {
            this.handleInvalidFuturesMonth(futuresMonth);
          } else {
            const side = this.contractForm.get('side').value;
            const marketDataDivisor = this.commodityMap.commodities[commodityId].marketDataDivisor;
            this.marketFuturesPrice = this.getMarketDataFuturesPrice(marketData, side) / marketDataDivisor;
            this.contractPriceHelper.setFieldValue('futuresPrice', this.marketFuturesPrice);
            this.calculateCash();
          }
          this.isLoadingFutures = false;
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        catchError(err => {
          this.isLoadingFutures = false;
          if (err.status === 404) {
            // not found; this means the month has expired or is not yet active and is not valid
            this.handleInvalidFuturesMonth(futuresMonth);
          } else {
            // generic message for all other errors
            this.openSnackBar('Error retrieving market data for selected futures month', 'DISMISS', false);
          }
          console.error(`Error retrieving market data: ${err.message ? err.message : err}`);
          return of(undefined);
        })
      ).subscribe();
    }
  }

  private getMarketDataFuturesPrice(marketData: MarketData, side: Side): number {
    return this.commodityFuturesPriceService.getMarketPrice(side, marketData);
  }

  private handleInvalidFuturesMonth(futuresMonth: moment.Moment) {
    this.contractForm.get('futuresYearMonth').setValue('');
    this.openSnackBar(`${moment(futuresMonth).format('MMM yyyy')} is either expired or not yet active.`, 'DISMISS', false);
  }

  private translateContractMonthToMoment(value: string): moment.Moment {
    const year = moment.parseTwoDigitYear(value.substring(0, 2));
    const monthCode = value.substring(2);
    const idxMonth = this.contractMonths.indexOf(monthCode);
    return moment().year(year).month(idxMonth).startOf('month');
  }

  private translateMomentToContractMonth(selectedMonth: moment.Moment): string {
    return selectedMonth ? selectedMonth.format(YEAR_FORMAT) + ContractMonth[this.contractMonths[selectedMonth.month()]] : '';
  }

  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

  private getUserAndSettings(): Observable<User> {
    return this.clientSettingsService.getHmsSettingsByClientDocId(this.selectedClient.docId).pipe(
      switchMap((hmsClientSettings: HMSClientSettings) => {
        this.isInternational = hmsClientSettings.isInternational;
        this.usePriceAdjustments = hmsClientSettings.usePriceAdjustments;
        this.ledgerEndOfDay = hmsClientSettings.ledgerEndOfDay;
        this.timezone = hmsClientSettings.timezone;
        this.useWholeCent = hmsClientSettings.useWholeCent;
        return this.priceAdjustmentService.getHMSPriceAdjustmentMapByClientDocId(this.selectedClient.docId);
      }),
      switchMap((hmsPriceAdjustmentMap: HMSPriceAdjustmentMap) => {
        this.priceAdjustmentMap = hmsPriceAdjustmentMap;
        return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId);
      })
    );
  }

  private getHMSSettingsActiveOriginatorsAndActiveLocations(): Observable<Location[]> {
    return this.userSettingsService.getHmsSettingsByUserDocId(this.authService.userProfile.app_metadata.firestoreDocId).pipe(
      switchMap((hmsUserSettings: HMSUserSettings) => {
        this.userSettings = hmsUserSettings;
        this.processUserSettings();
        return this.getActiveOriginators();
      }),
      switchMap((activeOriginators: Originator[]) => {
        this.originators = activeOriginators;
        return this.getActiveAuthorizedLocations();
      })
    );
  }

  private getActiveOriginators(): Observable<Originator[]> {
    return this.userSettingsService.getUncachedHmsUserSettingsForClientUsers(this.selectedClient.docId).pipe(
      switchMap((hmsUserSettings: HMSUserSettings[]) => {
        return combineLatest(
          hmsUserSettings.map((setting: HMSUserSettings) => {
            const accountingSystemId = setting.accountingSystemId;
            return this.userService.getUserByDocId(setting.userDocId).pipe(
              map((user: User) => {
                return { ...user, accountingSystemId } as Originator;
              })
            );
          })
        );
      }),
      map((originators: Originator[]) => {
        return originators.filter(originator => originator.status === Status.ACTIVE);
      })
    );
  }
}
