import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { UniqueKeyValidator } from '../../utilities/validators/unique-key-validator';

@Component({
  selector: 'hms-price-adjustment',
  templateUrl: './price-adjustment.component.html',
  styleUrls: ['./price-adjustment.component.scss']
})
export class PriceAdjustmentComponent implements OnChanges {

  @Input() priceAdjustment: FormGroup;
  @Input() editMode = true;
  @Input() existingPriceAdjustmentKeys: string[];
  @Output() priceAdjustmentUpdated = new EventEmitter<FormGroup>();
  @Output() priceAdjustmentRemoved = new EventEmitter<FormGroup>();

  priceAdjustmentForm: FormGroup = this.formBuilder.group({
    adjustmentKey: [''],
    id: ['', [Validators.required, Validators.maxLength(10)]],
    name: ['', [Validators.required, Validators.maxLength(30)]]
  });

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['editMode']) {
      if (this.editMode) {
        this.priceAdjustmentForm.enable();
        this.setupPriceAdjustmentForm();
      } else {
        this.priceAdjustmentForm.disable();
        this.setupPriceAdjustmentForm();
      }
    } else if (changes['priceAdjustment'] && !this.editMode) {
      this.setupPriceAdjustmentForm();
    } else if (changes['existingPriceAdjustmentKeys']) {
      this.setValidatorsOnIdFormControl();
    }
  }

  onPriceAdjustmentUpdated() {
    this.priceAdjustmentForm.markAsPristine();
    this.priceAdjustmentUpdated.emit(this.priceAdjustmentForm);
  }

  onPriceAdjustmentRemoved() {
    this.priceAdjustmentRemoved.emit(this.priceAdjustmentForm);
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value Required';
    } else if (control.hasError('maxlength')) {
      return `Max ${control.errors['maxlength'].requiredLength} characters`;
    } else if (control.hasError('uniqueKey')) {
      return 'ID must be unique';
    }
    return 'Unknown error';
  }

  setupPriceAdjustmentForm() {
    if (this.priceAdjustment) {
      this.priceAdjustmentForm = this.priceAdjustment;
      this.priceAdjustment.get('name').setValidators([Validators.required, Validators.maxLength(30)]);
      this.priceAdjustmentForm.get('name').updateValueAndValidity();
    } else {
      this.priceAdjustmentForm.get('adjustmentKey').setValue('');
      this.priceAdjustmentForm.get('id').setValue('');
      this.priceAdjustmentForm.get('name').setValue('');
      this.priceAdjustmentForm.markAsPristine();
      this.priceAdjustmentForm.markAsUntouched();
    }
    this.setValidatorsOnIdFormControl();
  }

  private setValidatorsOnIdFormControl() {
    const idFormControl = this.priceAdjustmentForm.get('id');
    // remove current key from array so that field can be "updated" to itself. Will support case correction
    // as well as eliminating false error on touching field without updating
    const existingKey = this.priceAdjustmentForm.get('adjustmentKey').value.toUpperCase();
    const allOtherKeys = this.existingPriceAdjustmentKeys.filter(priceAdjustmentKey => priceAdjustmentKey !== existingKey);
    idFormControl.setValidators([Validators.required, Validators.maxLength(10), UniqueKeyValidator.uniqueKey(allOtherKeys)]);
    idFormControl.updateValueAndValidity();
  }
}
