<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <hms-error-message [title]="'Error Retrieving Hedge Details'" [message]="errorMessage"></hms-error-message>

    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 class="card-title">Hedge</h2>
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <button type="button" id="edit-hedge" class="action-button" aria-label="Edit Hedge" matTooltip="Edit Hedge" *ngIf="canEditHedge" (click)="setEditMode(true)" mat-mini-fab color="accent">
          <mat-icon>edit</mat-icon>
        </button>
        <mat-spinner class="loading-spinner" *ngIf="!updateComplete || isLoading" diameter="40" color="accent"></mat-spinner>
        <button type="submit" id="update-hedge" class="action-button" aria-label="Update Hedge" matTooltip="Update Hedge" *ngIf="editMode && updateComplete" [disabled]="!hedgeForm.valid || hedgeForm.pristine" (click)="submitHedgeUpdate()" mat-mini-fab color="accent">
          <mat-icon>done</mat-icon>
        </button>
        <button type="button" id="cancel-edit-hedge" class="action-button" aria-label="Discard Changes" matTooltip="Discard Changes" *ngIf="editMode && updateComplete" (click)="reset()" mat-mini-fab color="">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="!errorMessage && hedge$ | async as hedge">
      <div fxLayout="column" fxLayoutGap="15px">
        <div fxLayout="row wrap" fxLayoutAlign="space-between start">
          <ati-labeled-data *ngIf="!editMode" fxFlex.gt-xs="33" fxFlex.xs="100" label="Order ID" [data]="hedge.orderDocId" [link]="getOrderLink()"></ati-labeled-data>
          <form *ngIf="editMode" fxFlex.gt-xs="33" fxFlex.xs="100" [formGroup]="hedgeForm" (ngSubmit)="submitHedgeUpdate()">
            <mat-form-field>
              <input required matInput placeholder="Order ID" formControlName="orderDocId" trim="blur">
              <mat-error *ngIf="hedgeForm.get('orderDocId').invalid">{{ getErrorMessage(hedgeForm.get('orderDocId')) }}</mat-error>
            </mat-form-field>
          </form>

          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Commodity Profile" [data]="hedge.commodityProfileName"></ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="34" fxFlex.xs="100" label="Contract" data="{{ hedge.futuresYearMonth | codeToMonth }}"></ati-labeled-data>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="space-between start">
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Side" data="{{ hedge.side | titlecase }}"></ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Quantity" data="{{ hedge.quantityInContracts }} {{ getContractLabel(hedge.quantityInContracts) }}"></ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="34" fxFlex.xs="100" label="{{ quantityUnit | titlecase }}" data="{{ hedge.quantity | number }}"></ati-labeled-data>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="space-between start">
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Creation Timestamp" data="{{ hedge.creationTimestamp | date: 'M/d/yyyy, h:mm:ss a' }}"></ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Creator" [data]="hedge.creatorName"></ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="34" fxFlex.xs="100" label="Production Year" data="{{ hedge.productionYearLabel | productionYear }}"></ati-labeled-data>
        </div>

        <div *ngIf="hedge.type && hedge.type !== 'STANDARD'" fxLayout="row wrap" fxLayoutAlign="start start">
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Type" data="{{ hedge.type | hedgeType }}"></ati-labeled-data>
          <ati-labeled-data fxFlex.gt-xs="33" fxFlex.xs="100" label="Price" data="{{ hedge.price | number: '1.4-5' }}"></ati-labeled-data>
          <ati-labeled-data *ngIf="hedge.type === 'OTC'" fxFlex.gt-xs="34" fxFlex.xs="100" label="Related Quantity" data="{{ hedge.relatedQuantity | number: '1.0-2' }}"></ati-labeled-data>
        </div>

        <!-- Comments -->
        <ati-labeled-data label="Comments" [data]="hedge.comments"></ati-labeled-data>

        <!-- Fill Price or Split Fill Prices for partial fills -->
        <h3 fxFlex="100" *ngIf="fillDataSource.data.length">Fills</h3>
        <mat-table *ngIf="fillDataSource.data.length" [dataSource]="fillDataSource" class="mat-elevation-z0" fxFlex>
          <ng-container matColumnDef="quantity">
            <mat-header-cell *matHeaderCellDef fxFlex="50">Qty</mat-header-cell>
            <mat-cell *matCellDef="let fill" fxFlex="50">{{ fill.quantity }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="orderFillPrice">
            <mat-header-cell *matHeaderCellDef fxFlex="50">Price</mat-header-cell>
            <mat-cell *matCellDef="let fill" fxFlex="50">{{ getDisplayPrice(fill.orderFillPrice, fill.orderSymbol) | number: '1.4-4' }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="fillColumnsToDisplay"></mat-header-row>
          <mat-row *matRowDef="let fill; columns: fillColumnsToDisplay"></mat-row>
        </mat-table>
      </div>
    </div>
  </mat-card>
</div>
