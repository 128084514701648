import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { Component, EventEmitter, ElementRef, OnInit, ViewChild, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';

import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Observable } from 'rxjs';

import moment from 'moment';
import { Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { Commodity, CommodityMap, HMSClientSettings } from '@advance-trading/ops-data-lib';
import { AccountingSettingComponent } from '../accounting-setting/accounting-setting.component';
import { UserRoles } from '../../utilities/user-roles';

const MILITARY_TIME = 'HH:mm';
const LOCAL_12_HOUR_TIME = 'LT';
const TWENTYFOUR_HOUR_REGEX = /^\d{2}\:\d{2}$/;

interface Auth0Group {
  _id?: string;
  name?: string;
  description?: string;
}

@Component({
  selector: 'hms-main-settings',
  templateUrl: './main-settings.component.html',
  styleUrls: ['./main-settings.component.scss']
})
export class MainSettingsComponent implements OnInit {

  updateComplete = false;
  errorMessage: string;
  allHmsEnabledCommodities: Commodity[] = [];
  separatorKeyCodes: number[] = [ENTER, SPACE];

  hmsTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#E0E0E0',
      buttonColor: '#000000DE'
    },
    dial: {
      // $md-atigreen2 500 weight from our material theme
      dialBackgroundColor: '#789C63',
    },
    clockFace: {
      clockFaceBackgroundColor: '#FFFFFF',
      // $md-atigreen2 500 weight from our material theme
      clockHandColor: '#789C63',
      clockFaceTimeInactiveColor: '#000000DE'
    }
  };

  @Input() editMode: boolean;
  @Input() clientSettingsForm: FormGroup;

  @Input() selectedClientDocId: string;
  @Input() clientSettings: HMSClientSettings;
  @Input() clientSettings$: Observable<HMSClientSettings>;
  @Input() commodities: string[];
  @Input() commodityMap: CommodityMap;

  @Input() managementGroup: Auth0Group;
  @Input() managementGroups: Auth0Group[];
  @Input() filteredTimezones$: Observable<moment.MomentZone[]>;
  @Input() filteredCommodities$: Observable<Commodity[]>;
  @Input() filteredGroups$: Observable<Auth0Group[]>;

  @Input() activeTabName: string;
  @Output() mainError = new EventEmitter<boolean>();

  @ViewChild('commodityauto', { static: false }) private commodityMatAuto: MatAutocomplete;
  @ViewChild('commodityInput', { static: false }) private commodityInput: ElementRef<HTMLInputElement>;
  @ViewChild('newAccountingSetting', { static: false }) private newAccountingSetting: AccountingSettingComponent;

  constructor(
    private authzService: Auth0AuthzService
  ) { }

  ngOnInit() {
    if (!this.authzService.currentUserHasRole(UserRoles.HMS_ADMIN_ROLE)) {
      this.errorMessage = 'You do not have permission to administer client settings.';
      console.error(`Permission Error: ${this.errorMessage}`);
      return;
    }
  }

  clearField(fieldName: string) {
    const field = this.clientSettingsForm.get(fieldName);
    field.setValue('');
    field.markAsDirty();
  }

  /**
   * Displays name for timezone autocomplete input field
   */
  displayTimezone(timezone?: moment.MomentZone) {
    return timezone ? timezone.name : '';
  }

  getErrorMessage(control: FormControl, controlName?: string) {
    this.activeTabName === 'Main' ? this.mainError.emit(false) : this.mainError.emit(true);
    if(controlName === 'timezone') {
      return 'You must select a valid timezone'
    } else if (controlName === 'managementGroup') {
      return 'You must select a valid management group'
    } else if (control.hasError('pattern')) {
      return 'Invalid value';
    } else if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('maxlength')) {
      return `Value cannot exceed ${control.errors['maxlength'].requiredLength} character${control.errors['maxlength'].requiredLength === 1 ? '' : 's'}`;
    }
    return 'Unknown error';
  }
  // end form-level and generic functions

  // begin commodity functions
  get commoditiesDisplay() {
    return this.clientSettings.commodities.map(symbol =>
      this.getCommodityDisplayById(symbol)).join(', ');
  }

  getCommodityDisplay(commodity?: Commodity) {
    return commodity ? `${commodity.name} (${commodity.id})` : '';
  }

  getCommodityDisplayById(commodityId: string) {
    return this.getCommodityDisplay(this.commodityMap.commodities[commodityId]);
  }

  addCommodity(event: MatChipInputEvent) {
    let commodity: Commodity = this.clientSettingsForm.get('commodities').value;
    if (typeof commodity === 'string') {
      const matOption = this.commodityMatAuto.options.find(value => value.value.name === commodity);
      commodity = matOption ? matOption.value : undefined;
    }
    if (!commodity) {
      return;
    }
    this.commodities = this.commodities.filter(commodityId => commodityId !== commodity.id);
    this.commodities.push(commodity.id);
    // Reset the group value to ensure we get a dirty form
    this.commodityInput.nativeElement.value = '';
    this.clientSettingsForm.get('commodities').setValue('');
  }

  removeCommodity(commodityId: string) {
    this.commodities = this.commodities.filter(existingCommodityId => existingCommodityId !== commodityId);
    // Reset the group value to ensure we get a dirty form
    this.clientSettingsForm.get('commodities').markAsDirty();
  }
  // end commodity functions

  // begin ledgerEndOfDay functions
  getLocalTime12HourFormat(timeString: string) {
    return TWENTYFOUR_HOUR_REGEX.test(timeString) ?
      moment(timeString, MILITARY_TIME).format(LOCAL_12_HOUR_TIME) : '';
  }

  getManagementGroupDisplayName(managementGroup: Auth0Group) {
    return managementGroup ? managementGroup.name : '';
  }
  // end ledgerEndOfDay functions

}
