<div fxFlex="100">

  <div *ngIf="!errorMessage" fxLayout="column">
    <mat-form-field *ngIf="!contractOrdersMode">
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="orders-filter-field" #filter>
      <button type="button" id="clear-orders-filter" mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <button *ngIf="exportable && !contractOrdersMode && selectedOrders$ | async" aria-label="Export" id="export-orders" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
    <mat-menu #exportMenu="matMenu">
      <button type="button" aria-label="Export Excel" id="export-orders-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Orders'))">Excel</button>
      <button type="button" aria-label="Export CSV" id="export-orders-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Orders'))">CSV</button>
      <button type="button" aria-label="Export JSON" id="export-orders-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Orders'))">JSON</button>
    </mat-menu>

    <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort class="mat-elevation-z0" (matSortChange)="handleSortChange()">

      <ng-container matColumnDef="docId">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="40" fxFlex.gt-xs="19">ID</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.xs="40" fxFlex.gt-xs="19">{{ order.docId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="creationTimestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="28" fxFlex.gt-xs="13">Created</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.xs="28" fxFlex.gt-xs="13"><span class="forced-wrap">{{ order.creationTimestamp | date: 'MM/dd/yyyy hh:mm:ss a' }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastUpdatedTimestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="32" fxFlex.gt-xs="13">Updated</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.xs="32" fxFlex.gt-xs="13"><span class="forced-wrap">{{ order.lastUpdatedTimestamp | date: 'MM/dd/yyyy hh:mm:ss a' }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="8">Status</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.gt-xs="8">{{ order.status | replace | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="security">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="10">Security</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.gt-xs="10">{{ order.security }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="8">Type</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.gt-xs="8">{{ order.type | replace | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="side">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="5">Side</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.gt-xs="5">{{ order.side | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="6">Qty</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.gt-xs="6">{{ order.quantity }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="9">Target</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.gt-xs="9">
          <ng-container>{{ order.type === 'MARKET' ? 'Market' : (order.price | number:'1.4-4') }}</ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="fillPrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="9">Fill</mat-header-cell>
        <mat-cell *matCellDef="let order" fxFlex.gt-xs="9">
          <ng-container *ngIf="order.isSplitFilled">{{ order.fillPrice | number:'1.4-4' }}*</ng-container>
          <ng-container *ngIf="!order.isSplitFilled">{{ order.fillPrice | number:'1.4-4' }}</ng-container>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row (click)="selectOrder(order)" *matRowDef="let order; columns: columnsToDisplay"></mat-row>
    </mat-table>
    <mat-paginator *ngIf="!contractOrdersMode" color="primary" [pageSizeOptions]="[10, 20, 50]" (page)="handlePageChange()"></mat-paginator>

  </div>
</div>
