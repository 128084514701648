import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

import * as moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { CommodityProfileService } from '@advance-trading/angular-ops-data';
import { Client, CommodityProfile, CommodityProfileProductionYear, HMSDailyLedger } from '@advance-trading/ops-data-lib';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ClientSettingsService } from '../../service/client-settings.service';
import { HMSDailyLedgerService } from '../../service/hms-daily-ledger.service';
import { LedgerHelperService } from '../../service/ledger-helper.service';
import { PositionsSummaryDisplay } from './positions-summary-display';
import { LedgerDay } from '../../utilities/ledger-day';

const FULL_DATE_FORMAT = 'YYYY-MM-DD';

@Component({
  selector: 'hms-positions-summary',
  templateUrl: './positions-summary.component.html',
  styleUrls: ['./positions-summary.component.scss']
})
export class PositionsSummaryComponent implements OnInit, OnChanges {

  @Input() commodityProfile: CommodityProfile;
  @Input() commodityProfiles: CommodityProfile[];
  @Output() positionsSummaryError = new EventEmitter<string>();
  @Output() loaded = new EventEmitter<boolean>();

  dataSource = new ObservableDataSource<PositionsSummaryDisplay>();
  columnsToDisplay = [];
  profileProdYearLabels = [];

  private isMobileDevice = false;
  private todayDate: string;
  private ledgerEndOfDay: string;
  private timezone: string;
  private yesterdayDate: string;

  isValidNumber = (num) => Number.isFinite(num);

  constructor(
    private breakpointObserver: BreakpointObserver,
    private clientSelectorService: ClientSelectorService,
    private clientSettingsService: ClientSettingsService,
    private commodityProfileService: CommodityProfileService,
    private hmsDailyLedgerService: HMSDailyLedgerService,
    private ledgerHelperService: LedgerHelperService
  ) { }

  ngOnInit() {
    // set is mobile device flag initially
    this.breakpointObserver.observe([Breakpoints.XSmall])
    .subscribe(state => {
      if (state.breakpoints[Breakpoints.XSmall]) {
        this.isMobileDevice = true;
      } else {
        this.isMobileDevice = false;
      }
    });

    if (!this.commodityProfile) {
      this.setDataSourceForAllCommodityProfiles();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['commodityProfile']) {

      this.columnsToDisplay = [];
      let selectedClientDocId;
      this.dataSource.data$ = this.clientSelectorService.getSelectedClient().pipe(
        switchMap((selectedClient: Client) => {
          selectedClientDocId = selectedClient.docId;
          return this.clientSettingsService.getHmsLedgerEndOfDaySettingsByClientDocId(selectedClientDocId);
        }),
        switchMap((ledgerDay: LedgerDay) => {
          this.ledgerEndOfDay = this.ledgerHelperService.getLedgerEndOfDayFromMoment(ledgerDay.ledgerEndOfDay);
          this.timezone = ledgerDay.timezone;
          this.setDateParam();
          return this.getCommodityProfilePositionsSummary(selectedClientDocId);
        }),
        tap((positionsSummary: PositionsSummaryDisplay[]) => {
          if (this.isMobileDevice) {
            this.columnsToDisplay = [];
          } else {
            this.columnsToDisplay = ['commodityProfile'];
          }
          this.profileProdYearLabels.forEach(label => {
            this.columnsToDisplay.push(label);
          });
          this.loaded.emit();
        }),
        catchError(err => {
          if (err.name === 'MissingLedgerEndOfDayError') {
            this.positionsSummaryError.emit(err.message);
          } else {
            this.positionsSummaryError.emit('Error retrieving positions summary; please try again later');
          }
          console.error(`Error retrieving positions summary: ${err}`);
          return of([]);
        })
      );

    } else if (changes[ 'commodityProfiles' ]) {
      this.setDataSourceForAllCommodityProfiles();
    }
  }

  private getAllNonSpreadPositionsSummary(selectedClientDocId: string): Observable<PositionsSummaryDisplay[]> {
    return this.buildPositionsSummary(
      of(this.commodityProfiles), selectedClientDocId);
  }

  private getCommodityProfilePositionsSummary(selectedClientDocId: string): Observable<PositionsSummaryDisplay[]> {
    return this.buildPositionsSummary(
      this.commodityProfileService.getCommodityProfileByDocId(selectedClientDocId, this.commodityProfile.docId).pipe(
      switchMap((profile: CommodityProfile) => of([profile]))), selectedClientDocId
    );
  }

  private buildPositionsSummary(commodityProfiles$: Observable<CommodityProfile[]>, selectedClientDocId: string):
      Observable<PositionsSummaryDisplay[]> {
    return commodityProfiles$.pipe(
      switchMap((profiles: CommodityProfile[]) => {
        if (profiles.length === 0) {
          return of([]);
        }
        this.columnsToDisplay = [];
        this.setProfileProductionYearLabels(profiles);

        return combineLatest(profiles.map((profile: CommodityProfile) => {
          // only get active production years
          const activeProductionYears = this.ledgerHelperService.getTranslatedUniqueActiveProdYears(
            profile.productionYears, this.ledgerEndOfDay, this.timezone);
          // get today's accumulating hedgeable quantity
          return combineLatest(
            Object.values(activeProductionYears).map((productionYear: CommodityProfileProductionYear) => {
              return this.hmsDailyLedgerService
                     .getHMSDailyLedgerByCommodityProfileAndProductionYear(selectedClientDocId, profile.docId,
                                                                           productionYear.year, this.todayDate);
            })).pipe(
              map((hmsDailyLedgers: HMSDailyLedger[][]) => {
                const hedgeableProdYears: {[key: string]: number} = {};
                hmsDailyLedgers.forEach((dailyLedgers: HMSDailyLedger[]) => {
                  dailyLedgers.forEach((dailyLedger: HMSDailyLedger) => {
                    const currentProdYearLabel = activeProductionYears[dailyLedger.productionYear].label;
                    if (hedgeableProdYears[currentProdYearLabel]) {
                      // handle if there are multiple today's HMSDailyLedger documents reflecting a profile doc id and production year
                      hedgeableProdYears[currentProdYearLabel] += dailyLedger.hedgeableQuantity;
                    } else {
                      hedgeableProdYears[currentProdYearLabel] = dailyLedger.hedgeableQuantity;
                    }
                  });
                });

                return {
                  commodityProfile: profile,
                  activeProductionYears,
                  hedgeableProdYears
                } as PositionsSummaryDisplay;

              })
            );
        }));
      }),
      switchMap((positionsSummary: PositionsSummaryDisplay[]) => {
        if (positionsSummary.length === 0) {
          return of([]);
        }

        // get rollovers quantity from yesterday
        return combineLatest(
          positionsSummary.map((summary: PositionsSummaryDisplay) => {
            return combineLatest(
              Object.values(summary.activeProductionYears).map((productionYear: CommodityProfileProductionYear) => {
                return this.hmsDailyLedgerService
                       .getHMSDailyLedgerByCommodityProfileAndProductionYear(selectedClientDocId, summary.commodityProfile.docId,
                                                                             productionYear.year, this.yesterdayDate);
              })).pipe(
                map((hmsDailyLedgers: HMSDailyLedger[][]) => {
                  const rolloverProdYears: {[key: string]: number} = {};
                  hmsDailyLedgers.forEach((dailyLedgers: HMSDailyLedger[]) => {
                    dailyLedgers.forEach((dailyLedger: HMSDailyLedger) => {
                      const currentProdYearLabel = summary.activeProductionYears[dailyLedger.productionYear].label;
                      if (rolloverProdYears[currentProdYearLabel]) {
                        // handle if there are multiple rollover's HMSDailyLedger documents reflecting a profile doc id and production year
                        rolloverProdYears[currentProdYearLabel] += dailyLedger.hedgeableQuantity;
                      } else {
                        rolloverProdYears[currentProdYearLabel] = dailyLedger.hedgeableQuantity;
                      }
                    });
                  });

                  return {
                    ...summary,
                    rolloverProdYears
                  } as PositionsSummaryDisplay;

                })
            );
          })
        );
      })
    );
  }

  private setProfileProductionYearLabels(profiles: CommodityProfile[]) {
    this.profileProdYearLabels = [];
    profiles.forEach((profile: CommodityProfile) => {
      const prodYears = this.ledgerHelperService.getTranslatedUniqueActiveProdYears(
        profile.productionYears, this.ledgerEndOfDay, this.timezone);
      Object.values(prodYears).forEach((prodYear: CommodityProfileProductionYear) => {
        this.profileProdYearLabels.push(prodYear.label);
      });
    });
    // filter production year label duplicates
    this.profileProdYearLabels = [... new Set(this.profileProdYearLabels)];
  }

  private setDataSourceForAllCommodityProfiles() {
    let selectedClientDocId;
    this.dataSource.data$ = this.clientSelectorService.getSelectedClient().pipe(
      switchMap((selectedClient: Client) => {
        selectedClientDocId = selectedClient.docId;
        return this.clientSettingsService.getHmsLedgerEndOfDaySettingsByClientDocId(selectedClientDocId);
      }),
      switchMap((ledgerDay: LedgerDay) => {
        this.ledgerEndOfDay = this.ledgerHelperService.getLedgerEndOfDayFromMoment(ledgerDay.ledgerEndOfDay);
        this.timezone = ledgerDay.timezone;
        this.setDateParam();
        return this.getAllNonSpreadPositionsSummary(selectedClientDocId);
      }),
      tap((positionsSummary: PositionsSummaryDisplay[]) => {
        this.columnsToDisplay = [ 'commodityProfile' ];
        this.profileProdYearLabels.forEach(label => {
          this.columnsToDisplay.push(label);
        });
        this.loaded.emit();
      }),
      catchError(err => {
        if (err.name === 'MissingLedgerEndOfDayError') {
          this.positionsSummaryError.emit(err.message);
        } else {
          this.positionsSummaryError.emit('Error retrieving positions summary; please try again later');
        }
        console.error(`Error retrieving positions summary: ${err}`);
        return of([]);
      })
    );
  }

  private setDateParam() {
    this.todayDate = moment.tz(this.ledgerHelperService.getCurrentBusinessDay(this.ledgerEndOfDay, this.timezone), this.timezone)
      .format(FULL_DATE_FORMAT);
    this.yesterdayDate = moment.tz(this.ledgerHelperService.getCurrentBusinessDay(this.ledgerEndOfDay, this.timezone), this.timezone)
      .subtract(1, 'days').format(FULL_DATE_FORMAT);
  }

}
