import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { AuthService } from '@advance-trading/angular-ati-security';
import { Client, HMSPriceAdjustmentMap } from '@advance-trading/ops-data-lib';

@Injectable({
  providedIn: 'root'
})
export class PriceAdjustmentService {

  constructor(
    private db: AngularFirestore,
    private authService: AuthService
  ) {}

  /**
   * Return the HMSPriceAdjustmentMap document for the specified Client
   *
   * @param clientDocId The docId of the Client for which to retrieve the Price Adjustment Map
   */
  getHMSPriceAdjustmentMapByClientDocId(clientDocId: string): Observable<HMSPriceAdjustmentMap> {
    return this.db.doc<HMSPriceAdjustmentMap>(`${Client.getDataPath(clientDocId)}/${HMSPriceAdjustmentMap.getDataPath()}`)
      .valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Set HMSPriceAdjustmentMap document for the specified Client
   *
   * @param clientDocId The docId of the Client for which to update the Price Adjustment Map
   * @param priceAdjustmentMap The HMSPriceAdjustmentMap object
   */
  setHMSPriceAdjustmentMapByClientDocId(clientDocId: string, priceAdjustmentMap: HMSPriceAdjustmentMap): Promise<void> {
    priceAdjustmentMap.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.doc<HMSPriceAdjustmentMap>(`${Client.getDataPath(clientDocId)}/${HMSPriceAdjustmentMap.getDataPath()}`)
      .set(priceAdjustmentMap.getPlainObject());
  }

}
