import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { combineLatest, Observable, of, OperatorFunction } from 'rxjs';
import { catchError, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { MarketDataService, OperationsDataService } from '@advance-trading/angular-ops-data';
import { CommodityMap, MarketData, MarketDataFrequency } from '@advance-trading/ops-data-lib';

import { QuotesMode } from '../quotes-mode';
import { UserRoles } from 'src/app/utilities/user-roles';

@Component({
  selector: 'hms-single-commodity-quotes',
  templateUrl: './single-commodity-quotes.component.html',
  styleUrls: ['./single-commodity-quotes.component.scss'],
  providers: [BreakpointObserver]
})
export class SingleCommodityQuotesComponent implements OnInit, OnChanges {
  @Input() mode: QuotesMode;
  @Input() commodityId: string;
  @Input() commodityName: string;
  @Output() singleQuoteError = new EventEmitter<string>();
  @Output() loaded = new EventEmitter<string>();

  marketData$: Observable<MarketData[]>;
  marketDataSource = new MatTableDataSource<MarketData>();
  displayedColumn: string[];

  @ViewChildren('dataTooltip') tooltips: QueryList<MatTooltip>;
  tooltipMessage: string;
  lastIdxRefreshed: number;

  userDataFrequency: MarketDataFrequency;

  errorMessage: string;
  timestamp: string;

  private marketDataDivisor;

  // defined constant for single quote view mode
  readonly SIDE = QuotesMode.SIDE;
  readonly FULL = QuotesMode.FULL;

  isValidNumber = (num) => Number.isFinite(num);

  constructor(private marketDataService: MarketDataService,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private operationsDataService: OperationsDataService,
    private router: Router) { }

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe(state => {
        if (state.breakpoints[Breakpoints.XSmall]) {
          this.displayedColumn = ['contractYearMonth', 'last', 'change'];
        } else if (state.breakpoints[Breakpoints.Small]) {
          this.displayedColumn = ['contractYearMonth', 'last', 'change', 'bid', 'ask'];
        } else {
          this.displayedColumn = ['contractYearMonth', 'last', 'change', 'low', 'high', 'bid', 'ask', 'open', 'settlementPrice'];
        }
      });
  }

  ngOnChanges() {
    this.setMarketData();
  }

  refresh(index: number) {
    this.lastIdxRefreshed = index;

    const currMarketData = this.marketDataSource.data[index];
    this.tooltipMessage = 'Loading ...';
    const currentTooltip = this.tooltips.toArray()[index];
    currentTooltip.message = this.tooltipMessage;
    currentTooltip.show();

    this.marketDataService.getRealTimeMarketDataByDocId(currMarketData.docId, this.authService.accessToken).pipe(
      take(1),
      tap((marketData: MarketData) => {
        this.tooltipMessage = this.getTooltipMessage(marketData);
        currentTooltip.message = this.tooltipMessage;
      }),
      catchError(err => {
        // TODO Do we really want to wipe the whole screen for a single real-time data pull failure?
        this.errorMessage = 'Error retrieving real-time market data; please try again later';
        console.error(`Error retrieving real-time market data: ${err}`);
        return of(undefined);
      })
    ).subscribe();
  }

  canCreateContract() {
    return this.authzService.currentUserHasRole(UserRoles.CONTRACT_CREATOR_ROLE);
  }

  selectRow(idx: number) {
    const currMarketData = this.marketDataSource.data[idx];
    // should not navigate to new contract when the user is not a contract creator or for spread quotes
    if (!this.canCreateContract() || currMarketData.contractSecondaryYearMonth) {
      return;
    }

    this.router.navigate(['/contracts/new'], {
      queryParams: { commodityId: this.commodityId, futuresYearMonth: currMarketData.contractYearMonth }
    });
  }

  getChangeClass(value: number) {
    return this.isValidNumber(value) ? ['price-change', value > 0 ? 'pos' : (value < 0 ? 'neg' : '')] : [];
  }

  private setMarketData() {
    if (!this.commodityId) {
      this.marketData$ = of([]);
      return;
    }

    this.userDataFrequency = this.authService.userProfile.app_metadata.marketDataFrequency;

    // Set the market datasource to be displayed
    this.marketData$ = this.operationsDataService.getCommodityMap().pipe(
      switchMap((doc: CommodityMap) => {
        this.marketDataDivisor = doc.commodities[this.commodityId].marketDataDivisor;
        return this.getMarketData();
      }),
      map(([futures, spreads]) => {
        return futures.concat(spreads);
      }),
      this.handleMarketData()
    );
  }

  private getMarketData() {
    if (this.userDataFrequency === MarketDataFrequency.DELAY_10 || this.userDataFrequency === MarketDataFrequency.ON_DEMAND) {
      // ON DEMAND & DELAYED
      return combineLatest([
        this.marketDataService.getDelayedFuturesMarketDataByCommodity(this.commodityId, 8),
        this.marketDataService.getDelayedSpreadMarketDataByCommodity(this.commodityId, 1)]);
    } else if (this.userDataFrequency === MarketDataFrequency.REALTIME) {
      // REALTIME
      return combineLatest([
        this.marketDataService.getRealTimeFuturesMarketDataByCommodity(this.commodityId, 8),
        this.marketDataService.getRealTimeSpreadMarketDataByCommodity(this.commodityId, 1)]);

    } else {
      // NONE
      return combineLatest([
        this.marketDataService.getDelayedFuturesMarketDataByCommodity(this.commodityId, 8),
        this.marketDataService.getDelayedSpreadMarketDataByCommodity(this.commodityId, 1)]).pipe(
          take(1)
        );

    }
  }

  private handleMarketData<T>(): OperatorFunction<MarketData[], MarketData[]> {
    return (source$: Observable<MarketData[]>): Observable<MarketData[]> => {
      return source$.pipe(
        map(data => {
          return data.map(d => {
            const marketdata: MarketData = {} as MarketData;
            Object.assign(marketdata, d);
            marketdata.last = d.last / this.marketDataDivisor;
            marketdata.change = d.change / this.marketDataDivisor;
            marketdata.low = d.low / this.marketDataDivisor;
            marketdata.high = d.high / this.marketDataDivisor;
            marketdata.bid = d.bid / this.marketDataDivisor;
            marketdata.ask = d.ask / this.marketDataDivisor;
            marketdata.open = d.open / this.marketDataDivisor;
            marketdata.settlementPrice = d.settlementPrice / this.marketDataDivisor;
            return marketdata;
          });
        }),
        tap(data => {
          const now = moment();
          this.marketDataSource.data = data.filter(marketData => moment(marketData.expirationDate).endOf('day').isAfter(now));
          this.timestamp = moment().format('M/D/YYYY, h:mm:ss A');
          this.loaded.emit(this.timestamp);
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        catchError(err => {
          this.errorMessage = 'Error retrieving market data; please try again later';
          this.singleQuoteError.emit('Error retrieving market data; please try again later');
          console.error(`Error retrieving market data: ${err}`);
          return of([]);
        })
      );
    };
  }

  private getTooltipMessage(marketData: MarketData) {
    const realtimeLast = marketData['last'] !== undefined ? (marketData['last'] / this.marketDataDivisor).toFixed(4) : '-';
    const realtimeChange = marketData['change'] !== undefined ? (marketData['change'] / this.marketDataDivisor).toFixed(4) : '-';
    const realtimeLow = marketData['low'] !== undefined ? (marketData['low'] / this.marketDataDivisor).toFixed(4) : '-';
    const realtimeHigh = marketData['high'] !== undefined ? (marketData['high'] / this.marketDataDivisor).toFixed(4) : '-';
    const realtimeBid = marketData['bid'] !== undefined ? (marketData['bid'] / this.marketDataDivisor).toFixed(4) : '-';
    const realtimeAsk = marketData['ask'] !== undefined ? (marketData['ask'] / this.marketDataDivisor).toFixed(4) : '-';
    const realtimeOpen = marketData['open'] !== undefined ? (marketData['open'] / this.marketDataDivisor).toFixed(4) : '-';
    const realtimeSettle = marketData['settlementPrice'] !== undefined ? (marketData['settlementPrice']
      / this.marketDataDivisor).toFixed(4) : '-';

    return `
    Real-Time Market Data

    \tLast:\t${realtimeLast}
    \t\t\t${marketData['tradeDateTime'] ? formatDate(marketData['tradeDateTime'], 'M/d/yy, H:mm', 'en') : 'no time available'}

    \tChange:\t${realtimeChange}

    \tLow:\t${realtimeLow}

    \tHigh:\t${realtimeHigh}

    \tBid:\t\t${realtimeBid}
    \t\t\t${marketData['bidDateTime'] ? formatDate(marketData['bidDateTime'], 'M/d/yy, H:mm', 'en') : 'no time available'}

    \tAsk:\t${realtimeAsk}
    \t\t\t${marketData['askDateTime'] ? formatDate(marketData['askDateTime'], 'M/d/yy, H:mm', 'en') : 'no time available'}

    \tOpen:\t${realtimeOpen}

    \tSettle:\t${realtimeSettle}
    \t\t\t${marketData['settleDateTime'] ? formatDate(marketData['settleDateTime'], 'M/d/yy, H:mm', 'en') : 'no time available'}`;
  }
}
