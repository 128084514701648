import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import firebase from 'firebase/app';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { AuthService } from '@advance-trading/angular-ati-security';
import { Client, Contract, ContractStatus, ContractType } from '@advance-trading/ops-data-lib';

const MAXIMUM_ARRAY_SIZE = 10;

// TODO Remove when available in library - temporarily in HMS
@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(
    private db: AngularFirestore,
    private authService: AuthService
  ) { }

  /**
   * Return a single contract document for the specified Client and docId
   * @param clientDocId The docId of the Client containing the Contracts
   * @param contractDocId contractDocId of Contract to be returned
   */
  getContractByDocId(clientDocId: string, contractDocId: string): Observable<Contract> {
    return this.db.doc<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath(contractDocId)}`)
      .valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  // ContractSearchComponent Queries

  /**
   * Return contracts for the specified Client and id
   * @param clientDocId The docId of the Client containing the Contracts
   * @param contractId accountingSystemId of the Contracts to be returned
   */
  getContractsById(clientDocId: string, contractId: string): Observable<Contract[]> {
    return this.db.collection<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath()}`,
      ref => ref.where('accountingSystemId', '==', contractId))
      .valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Returns and observable containing contracts that contain associated exchangeId.
   * @param clientDocId The docId of the Client containing the Contracts!
   * @param exchangeId The associated contract order ID
   * @returns A list of contracts that reference the specified order ID
   */
  getContractsByExchangeId(clientDocId: string, exchangeId: string): Observable<Contract[]> {
    return this.db.collection<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath()}`,
      ref => ref.where('exchangeId', '==', exchangeId))
      .valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Return contracts for the specified Client, statuses, type, date range, client location, patron, and originator
   * Breaks up statuses parameter into chunks as necessary to not exceed the firebase limit of 10 items per array for in filter
   * Queries are built in private method getContractsBySearchParameters then results are combined and resorted in this function
   * Any or all parameters besides statuses can be undefined, are only applied as query filters if not undefined
   * Parameter statuses can be zero-length but must not be undefined
   * @param clientDocId The docId of the Client containing the Contracts
   * @param type type of the Contracts to be returned
   * @param statuses ContractStatuses of the Contracts to be returned. Must not be undefined
   * @param startDate The date before or when the Contracts were last updated
   * @param endDate The date after or when the Contracts were last updated
   * @param clientLocationDocId clientLocationDocId of the Contracts to be returned
   * @param patronDocId patronDocId of the Contracts to be returned
   * @param originatorDocId originatorDocId of the Contracts to be returned
   * @param delivery deliveryPeriod of the Contracts to be returned
   * @param futures futuresYearMonth of the Contracts to be returned
   */
  getContractsBySearchParametersForContractSearch(
    clientDocId: string, type: string, statuses: ContractStatus[], startDate: string, endDate: string, clientLocationDocId: string,
    patronDocId: string, originatorDocId: string, delivery: string, futures: string): Observable<Contract[]> {

    if (statuses.length > MAXIMUM_ARRAY_SIZE) {
      // break statuses into chunks of 10, the maximum supported by firebase for an `in` query
      const subQueryObservables = [];
      for (let index = 0; index < statuses.length; index += MAXIMUM_ARRAY_SIZE) {
        subQueryObservables.push(this.getContractsBySearchParameters(clientDocId, type, statuses.slice(index, index + MAXIMUM_ARRAY_SIZE),
          startDate, endDate, clientLocationDocId, patronDocId, originatorDocId, delivery, futures));
      }
      return combineLatest(subQueryObservables).pipe(
        // force potential array of contract arrays into single array then ensure sorting is applied across entire set
        map(arrayOfContractArrays => (arrayOfContractArrays as Contract[][]).flat()
          .sort((a, b) => a.lastUpdatedTimestamp < b.lastUpdatedTimestamp ? 1 : -1)),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    } else {
      return this.getContractsBySearchParameters(clientDocId, type, statuses,
        startDate, endDate, clientLocationDocId, patronDocId, originatorDocId, delivery, futures
      ).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }
  }

  /**
   * Return contracts for the specified Client, statuses, type, date range, client location, patron, and originator
   * Any or all parameters besides statuses can be undefined, are only applied as query filters if not undefined
   * Parameter statuses can be zero-length but must not be undefined
   * @param clientDocId The docId of the Client containing the Contracts
   * @param type type of the Contracts to be returned
   * @param statuses ContractStatuses of the Contracts to be returned
   * @param startDate The date before or when the Contracts were last updated
   * @param endDate The date after or when the Contracts were last updated
   * @param clientLocationDocId clientLocationDocId of the Contracts to be returned
   * @param patronDocId patronDocId of the Contracts to be returned
   * @param originatorDocId originatorDocId of the Contracts to be returned
   * @param delivery deliveryPeriod of the Contracts to be returned
   * @param futures futuresYearMonth of the Contracts to be returned
   */
  private getContractsBySearchParameters(
    clientDocId: string, type: string, statuses: ContractStatus[], startDate: string, endDate: string,
    clientLocationDocId: string, patronDocId: string, originatorDocId: string, delivery: string, futures: string): Observable<Contract[]> {

    return this.db.collection<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath()}`,
      ref => {
        let finalRef = ref.orderBy('lastUpdatedTimestamp', 'desc');
        if (type) {
          finalRef = finalRef.where('type', '==', type);
        }
        if (statuses.length) {
          finalRef = finalRef.where('status', 'in', statuses);
        }
        if (startDate && endDate) {
          finalRef = finalRef.where('lastUpdatedTimestamp', '>=', startDate).where('lastUpdatedTimestamp', '<=', endDate);
        }
        if (clientLocationDocId) {
          finalRef = finalRef.where('clientLocationDocId', '==', clientLocationDocId);
        }
        if (patronDocId) {
          finalRef = finalRef.where('patronDocId', '==', patronDocId);
        }
        if (originatorDocId) {
          finalRef = finalRef.where('originatorDocId', '==', originatorDocId);
        }
        if (delivery) {
          finalRef = finalRef.where('deliveryPeriod', '==', delivery);
        }
        if (futures) {
          finalRef = finalRef.where('futuresYearMonth', '==', futures);
        }
        return finalRef;
      }
    ).valueChanges();
  }

  // Closest To Market Report Queries

  /**
   * Return contracts for the specified Client and Commodity Profiles
   * commodityProfileDocId can be an empty array or undefined
   * @param clientDocId The docId of the Client containing the Contracts
   * @param commodityProfileDocIds commodityProfileDocIds of the Contracts to be returned
   */
  getContractsForClosestToMarketReport(clientDocId: string, commodityProfileDocIds: string[]): Observable<Contract[]> {
    if (!commodityProfileDocIds || !commodityProfileDocIds.length) {
      return this.getContractsForClosestToMarketReportByCommodityProfile(clientDocId, undefined);
    } else {
      return combineLatest(commodityProfileDocIds.map(commodityProfileDocId =>
        this.getContractsForClosestToMarketReportByCommodityProfile(clientDocId, commodityProfileDocId))).pipe(
          // force potential array of contract arrays into single array
          map(arrayOfContractArrays => (arrayOfContractArrays as Contract[][]).flat()),
          shareReplay({ bufferSize: 1, refCount: true })
        );
    }
  }

  /**
   * Return contracts for the specified Client and Commodity Profile
   * commodityProfileDocId can be undefined, is only applied as query filter if not undefined
   * @param clientDocId The docId of the Client containing the Contracts
   * @param commodityProfileDocId commodityProfileDocId of the Contracts to be returned
   */
  private getContractsForClosestToMarketReportByCommodityProfile(
    clientDocId: string, commodityProfileDocId: string): Observable<Contract[]> {
    return this.db.collection<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath()}`,
      ref => {
        let finalRef = ref.where('status', 'in', [ContractStatus.WORKING_CASH, ContractStatus.WORKING_FUTURES]);
        if (commodityProfileDocId) {
          finalRef = finalRef.where('commodityProfileDocId', '==', commodityProfileDocId);
        }
        return finalRef;
      }
    ).valueChanges();
  }

  // Expiring Contracts Report Queries

  /**
   * Return contracts that are expiring before an end date
   * specified Client, statuses, and end date
   * @param clientDocId The docId of the Client containing the Contracts
   * @param statuses ContractStatuses of the Contracts to be returned. Must not be undefined
   * @param endDate The date before or at the Contracts' expiration date
   */
  getExpiringContractsByStatus(clientDocId: string, statuses: ContractStatus[], endDate: string): Observable<Contract[]> {
    return this.db.collection<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath()}`,
      ref => ref.where('status', 'in', statuses)
        .where('expirationDate', '<=', endDate)
    ).valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  // Pricing Event Queries

  /**
   * Return contracts for the specified Client, contract types and futuresLockedTimestamp date range
   *
   * @param clientDocId The docId of the Client containing the Contracts
   * @param contractTypes The list of contract types of the Contracts to be returned
   * @param startDate The date before or when the Contracts' futures were locked
   * @param endDate The date after or when the Contracts' futures were locked
   * @param excludeDeleted optional parameter used to exclude deleted items from results
   * @param excludeExchange optional parameter used to exclude exchange items from results
   */
  getContractsByTypeAndFuturesLockedTimestamp(
    clientDocId: string, contractTypes: ContractType[], startDate: string, endDate: string,
    excludeDeleted?: boolean, excludeExchange?: boolean): Observable<Contract[]> {

    return this.db.collection<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath()}`,
      ref => {
        let finalRef = ref.where('type', 'in', contractTypes)
          .where('futuresLockedTimestamp', '>=', startDate)
          .where('futuresLockedTimestamp', '<=', endDate);
        if (excludeDeleted) {
          finalRef = finalRef.where('deletionTimestamp', '==', '');
        }
        if (excludeExchange) {
          finalRef = finalRef.where('isExchange', '==', false);
        }
        return finalRef;
      }
    ).valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Return exchange contracts for the specified Client, contract types and completionTimestamp date range with isExchange true
   *
   * @param clientDocId The docId of the Client containing the Contracts
   * @param contractTypes The list of contract types of the Contracts to be returned
   * @param startDate The date before or when the exchange Contracts were completed
   * @param endDate The date after or when the exchange Contracts were completed
   */
  getExchangeContractsByTypeAndCompletionTimestamp(
    clientDocId: string, contractTypes: ContractType[], startDate: string, endDate: string): Observable<Contract[]> {

    return this.db.collection<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath()}`,
      ref => {
        const finalRef = ref.where('type', 'in', contractTypes)
          .where('completionTimestamp', '>=', startDate)
          .where('completionTimestamp', '<=', endDate)
          .where('isExchange', '==', true);
        return finalRef;
      }
    ).valueChanges().pipe(shareReplay({bufferSize: 1, refCount: true}));
  }

  /**
   * Return contracts for the specified Client, contract types, commodity profile and futuresLockedTimestamp date range
   *
   * @param clientDocId The docId of the Client containing the Contracts
   * @param contractTypes The list of contract types of the Contracts to be returned
   * @param commodityProfileDocId The docId  of the CommodityProfile containing the Contracts
   * @param startDate The date before or when the Contracts' futures were locked
   * @param endDate The date after or when the Contracts' futures were locked
   */
  getContractsByTypeCommodityProfileAndFuturesLockedTimestamp(
    clientDocId: string, contractTypes: ContractType[], commodityProfileDocId: string, startDate: string,
    endDate: string): Observable<Contract[]> {

    return this.db.collection<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath()}`,
      ref => ref.where('type', 'in', contractTypes)
        .where('commodityProfileDocId', '==', commodityProfileDocId)
        .where('futuresLockedTimestamp', '>=', startDate).where('futuresLockedTimestamp', '<=', endDate)
        .where('isExchange', '==', false)
    ).valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Return contracts for the specified Client, contract types and basisLockedTimestamp date range
   *
   * @param clientDocId The docId of the Client containing the Contracts
   * @param contractTypes The list of contract types of the Contracts to be returned
   * @param startDate The date before or when the Contracts' basis were locked
   * @param endDate The date after or when the Contracts' basis were locked
   * @param excludeDeleted optional parameter used to exclude deleted items from results
   * @param excludeExchange optional parameter used to exclude exchange items from results
   */
  getContractsByTypeAndBasisLockedTimestamp(
    clientDocId: string, contractTypes: ContractType[], startDate: string, endDate: string,
    excludeDeleted?: boolean, excludeExchange?: boolean): Observable<Contract[]> {
    return this.db.collection<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath()}`,
      ref => {
        let finalRef = ref.where('type', 'in', contractTypes)
          .where('basisLockedTimestamp', '>=', startDate)
          .where('basisLockedTimestamp', '<=', endDate);
        if (excludeDeleted) {
          finalRef = finalRef.where('deletionTimestamp', '==', '');
        }
        if (excludeExchange) {
          finalRef = finalRef.where('isExchange', '==', false);
        }
        return finalRef;
      }
    ).valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Return contracts for the specified Client, contract types, commodity profile and basisLockedTimestamp date range
   *
   * @param clientDocId The docId of the Client containing the Contracts
   * @param commodityProfileDocId The docId of the CommodityProfile containing the Contracts
   * @param startDate The date before or when the Contracts' basis were locked
   * @param endDate The date after or when the Contracts' basis were locked
   */
  getContractsByTypeCommodityProfileAndBasisLockedTimestamp(
    clientDocId: string, contractTypes: ContractType[], commodityProfileDocId: string, startDate: string,
    endDate: string): Observable<Contract[]> {

    return this.db.collection<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath()}`,
      ref => ref.where('type', 'in', contractTypes)
        .where('commodityProfileDocId', '==', commodityProfileDocId)
        .where('basisLockedTimestamp', '>=', startDate).where('basisLockedTimestamp', '<=', endDate)
    ).valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Return unpriced contracts for the specified Client, contract type, statuses, starting delivery period and commodity profiles
   *
   * @param clientDocId The docId of the Client containing the Contracts
   * @param contractType The contract type of the Contracts to be returned
   * @param statuses ContractStatuses of the Contracts to be returned. Must not be undefined
   * @param deliveryPeriod The starting delivery period of Contracts to be returned
   * @param commodityProfileDocIds The list of commodity profile doc ids of the Contracts to be returned. Can be an empty array
   */
  getUnpricedContractsForFutureDeliveryReport(
    clientDocId: string, contractType: ContractType,
    statuses: ContractStatus[], deliveryPeriod: string, commodityProfileDocIds: string[]): Observable<Contract[]> {
    // has commodity profile selection
    if (commodityProfileDocIds.length) {
      const subQueryObservables = [];
      commodityProfileDocIds.forEach(docId => {
        subQueryObservables.push(this.getContractsByTypeStatusDeliveryPeriodAndCommodityProfiles(
          clientDocId, contractType, statuses, deliveryPeriod, docId));
      });
      return combineLatest(subQueryObservables).pipe(
        map(arrayOfContractArrays => (arrayOfContractArrays as Contract[][]).flat()),
        shareReplay({ bufferSize: 1, refCount: true })
      );
      // no commodity profile selection
    } else {
      return this.getContractsByTypeStatusDeliveryPeriodAndCommodityProfiles(
        clientDocId, contractType, statuses, deliveryPeriod).pipe(
          shareReplay({ bufferSize: 1, refCount: true })
        );
    }
  }

  /**
   * Return contracts for the specified Client, contract type, commodity profile, and delivery period for the Delivery monthly roll report
   *
   * @param clientDocId The docId of the Client containing the Contracts
   * @param type The contract type of the Contracts to be returned
   * @param commodityProfileDocId The commodity profile doc id of the Contracts to be returned
   * @param deliveryPeriod The delivery period of Contracts to be returned
   */
  getDeliveryRollContracts(
    clientDocId: string, type: ContractType, commodityProfileDocId: string, deliveryPeriod: string): Observable<Contract[]> {
    const excludedStatuses = [
      ContractStatus.CANCELLED,
      ContractStatus.COMPLETE,
      ContractStatus.DELETED,
      ContractStatus.DENIED,
      ContractStatus.EXPIRED
    ];
    if (type === ContractType.BASIS) {
      excludedStatuses.push(ContractStatus.PENDING_FUTURES);
      excludedStatuses.push(ContractStatus.WORKING_FUTURES);
    }
    return this.db.collection<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath()}`,
      ref => ref.where('commodityProfileDocId', '==', commodityProfileDocId)
        .where('deliveryPeriod', '==', deliveryPeriod)
        .where('type', '==', type)
        .where('status', 'not-in', excludedStatuses)
    ).valueChanges();
  }

  /**
   * Return contracts for the specified Client, contract type, commodity profile, and delivery period for the Futures monthly roll report
   *
   * @param clientDocId The docId of the Client containing the Contracts
   * @param type The contract type of the Contracts to be returned
   * @param commodityProfileDocId The commodity profile doc id of the Contracts to be returned
   * @param futuresYearMonth The futures year month of Contracts to be returned
   */
  getFuturesRollContracts(
    clientDocId: string, type: ContractType, commodityProfileDocId: string, futuresYearMonth: string): Observable<Contract[]> {
    const excludedStatuses = [
      ContractStatus.CANCELLED,
      ContractStatus.COMPLETE,
      ContractStatus.DELETED,
      ContractStatus.DENIED,
      ContractStatus.EXPIRED
    ];
    if (type === ContractType.HTA) {
      excludedStatuses.push(ContractStatus.PENDING_BASIS);
      excludedStatuses.push(ContractStatus.WORKING_BASIS);
    }
    return this.db.collection<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath()}`,
      ref => ref.where('commodityProfileDocId', '==', commodityProfileDocId)
        .where('futuresYearMonth', '==', futuresYearMonth)
        .where('type', '==', type)
        .where('status', 'not-in', excludedStatuses)
    ).valueChanges();
  }

  /**
   * Return unpriced contracts for the specified Client, contract type, statuses, starting delivery period and optional commodity profile
   *
   * @param clientDocId The docId of the Client containing the Contracts
   * @param contractType The contract type of the Contracts to be returned
   * @param statuses ContractStatuses of the Contracts to be returned. Must not be undefined
   * @param deliveryPeriod The starting delivery period of Contracts to be returned
   * @param commodityProfileDocId The optional commodity profile doc id of the Contracts to be returned
   */
  private getContractsByTypeStatusDeliveryPeriodAndCommodityProfiles(
    clientDocId: string, contractType: ContractType, statuses: ContractStatus[],
    deliveryPeriod: string, commodityProfileDocId?: string): Observable<Contract[]> {

    return this.db.collection<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath()}`,
      ref => {
        let finalRef = ref.where('type', '==', contractType)
          .where('status', 'in', statuses)
          .where('deliveryPeriod', '>=', deliveryPeriod);
        if (commodityProfileDocId) {
          finalRef = finalRef.where('commodityProfileDocId', '==', commodityProfileDocId);
        }
        return finalRef;
      }).valueChanges();
  }

  /**
   * Return exchange contracts for the specified Client within the specified exchangeTimestamp range
   *
   * @param clientDocId The docId of the Client containing the Contracts
   * @param startDate The date before or when an exchange was created for the Contracts
   * @param endDate The date after or when an exchange was created for the Contracts
   * @param commodityProfileDocId The optional commodity profile doc id of the Contracts to be returned
   */
  getExchangeContractsByExchangeTimestamp(
    clientDocId: string, startDate: string, endDate: string, commodityProfileDocId?: string): Observable<Contract[]> {

    return this.db.collection<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath()}`,
      ref => {
        let finalRef = ref.where('isExchange', '==', true)
          .where('exchangeTimestamp', '>=', startDate)
          .where('exchangeTimestamp', '<=', endDate);
        if (commodityProfileDocId) {
          finalRef = finalRef.where('commodityProfileDocId', '==', commodityProfileDocId);
        }
        return finalRef;
      }).valueChanges().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  /**
   * Create a Create document for the specified Client
   *
   * @param clientDocId The docId of the Client for which to add a Contract
   * @param contract The Contract object to add
   */
  createContract(clientDocId: string, contract: Contract): Promise<void> {
    contract.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    return this.db.doc<Contract>(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath(contract.docId)}`)
      .set(contract.getPlainObject());
  }

  /**
   * Update a Contract document for the specified Client
   *
   * @param clientDocId The docId of the Client containing the updated contract
   * @param contract The Contract object to update
   */
  updateContract(clientDocId: string, contract: Contract): Promise<void> {
    contract.lastUpdatedByDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    if (!contract.expirationDate) {
      //@ts-ignore
      contract.expirationDate = firebase.firestore.FieldValue.delete();
    }
    if (!contract.comments) {
      //@ts-ignore
      contract.comments = firebase.firestore.FieldValue.delete();
    }
    if (!contract.instructions) {
      //@ts-ignore
      contract.instructions = firebase.firestore.FieldValue.delete();
    }
    if (!contract.customContractId) {
      //@ts-ignore
      contract.customContractId = firebase.firestore.FieldValue.delete();
    }
    return this.db.doc(`${Client.getDataPath(clientDocId)}/${Contract.getDataPath(contract.docId)}`).update(contract);
  }
}
