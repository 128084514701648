<div fxLayout="column" fxLayoutAlign="space-around none" fxLayoutGap="10">
  <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
    <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

      <hms-error-message [title]="'Error Retrieving Closest to Market Report'" [message]="errorMessage"></hms-error-message>

      <div *ngIf="!errorMessage && commodityProfiles$ | async" fxLayout="column" fxLayoutAlign="space-between start">
        <h2 data-cy="page-title-closest-to-market" class="card-title">Closest to Market</h2>
        <form [formGroup]="closestToMarketSearchForm" (ngSubmit)="loadClosestToMarketReportData()" fxLayout="column" class="full-width">
          <div fxLayoutGap.gt-xs="25px" fxLayout.xs="column">
            <mat-form-field fxFlex.gt-xs="250px">
              <mat-label>Commodity Profile</mat-label>
              <mat-select formControlName="commodityProfiles" multiple>
                <mat-select-trigger>
                <span *ngIf="currentSelectedProfiles.length === selectCommodityProfileOptions.length">
                  All Commodity Profiles ({{ currentSelectedProfiles.length }})
                </span>
                  <span *ngIf="selectCommodityProfileOptions.length > currentSelectedProfiles.length">
                    {{currentSelectedProfiles.length}} out of {{ selectCommodityProfileOptions.length }} Commodity Profiles
                  </span>
                </mat-select-trigger>
                <mat-option *ngFor="let commodityProfile of selectCommodityProfileOptions" [value]="commodityProfile">{{commodityProfile.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="!isLoading" fxLayout="row" fxLayoutGap="10px">
            <button type="submit" id="generate-closest-to-market-report" aria-label="Generate Report" type="submit" mat-raised-button color="accent">Generate Report</button>
            <button type="button" id="clear-closest-to-market-report" *ngIf="!this.closestToMarketSearchForm.pristine" aria-label="Discard Report Options" (click)="reset()" type="reset" mat-stroked-button color="accent">Reset</button>
          </div>
          <div *ngIf="isLoading" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-spinner diameter="40" color="accent"></mat-spinner>
          </div>
          <div class="mat-small" *ngIf="!isLoading">
            <h4>Disclaimer:</h4>
            <ul class="disclaimer-list">
              <li>Market data is delayed up to 10 minutes</li>
              <li>Market data last updated {{ timestamp }}</li>
            </ul>
          </div>
         </form>
      </div>

      <div *ngIf="!errorMessage && displayReports" fxLayout="column" fxLayoutGap="10px">
        <ng-container *ngFor="let commodityProfile of sortedReportCommodityProfiles">
          <div *ngIf="!selectedCommodityProfileDocIds.length || selectedCommodityProfileDocIds.includes(commodityProfile.docId)">
            <h4>
              {{ commodityProfile.name }}
              <button *ngIf="reportDataSourceMap[commodityProfile.docId].data.length" matTooltip="Export" aria-label="Export" id="export-closest-to-market-report" mat-icon-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button"><mat-icon>save_alt</mat-icon></button>
            </h4>
            <mat-menu #exportMenu="matMenu">
              <button type="button" aria-label="Export Excel" id="export-closest-to-market-report-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'Closest to Market Report'))">Excel</button>
              <button type="button" aria-label="Export CSV" id="export-closest-to-market-report-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'Closest to Market Report'))">CSV</button>
              <button type="button" aria-label="Export JSON" id="export-closest-to-market-report-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'Closest to Market Report'))">JSON</button>
            </mat-menu>
            <div>
              <div fxLayout="column" fxFlex="100">
                <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="reportDataSourceMap[commodityProfile.docId]" class="mat-elevation-z0">
                  <ng-container matColumnDef="priceDifference">
                    <mat-header-cell *matHeaderCellDef fxFlex.xs="29" fxFlex.sm="15" fxFlex.gt-sm="11">Distance</mat-header-cell>
                    <mat-cell *matCellDef="let contract" fxFlex.xs="29" fxFlex.sm="15" fxFlex.gt-sm="11">{{ contract.priceDifference | number: '1.4-4'  }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="marketPrice">
                    <mat-header-cell *matHeaderCellDef fxFlex.xs="20" fxFlex.sm="10" fxFlex.gt-sm="10">Market</mat-header-cell>
                    <mat-cell *matCellDef="let contract" fxFlex.xs="20" fxFlex.sm="10" fxFlex.gt-sm="10">{{ contract.marketPrice | number: '1.4-4' }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="workingPrice">
                    <mat-header-cell *matHeaderCellDef fxFlex.xs="20" fxFlex.sm="10" fxFlex.gt-sm="10">Working</mat-header-cell>
                    <mat-cell *matCellDef="let contract" fxFlex.xs="20" fxFlex.sm="10" fxFlex.gt-sm="10">{{ contract.workingPrice | number: '1.4-4' }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="deliveryPeriod">
                    <mat-header-cell *matHeaderCellDef fxFlex.sm="10" fxFlex.gt-sm="6">Delivery</mat-header-cell>
                    <mat-cell *matCellDef="let contract" fxFlex.sm="10" fxFlex.gt-sm="6">{{ contract.deliveryPeriod }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="futuresMonth">
                    <mat-header-cell *matHeaderCellDef fxFlex.xs="13" fxFlex.sm="10" fxFlex.gt-sm="6">Fut</mat-header-cell>
                    <mat-cell *matCellDef="let contract" fxFlex.xs="13" fxFlex.sm="10" fxFlex.gt-sm="6">{{ contract.futuresMonth }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="contractType">
                    <mat-header-cell *matHeaderCellDef fxFlex.sm="10" fxFlex.gt-sm="7">Type</mat-header-cell>
                    <mat-cell *matCellDef="let contract" fxFlex.sm="10" fxFlex.gt-sm="7">{{ contract.contractType === 'HTA' || contract.contractType === 'DP' ? contract.contractType : contract.contractType | titlecase }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="side">
                    <mat-header-cell *matHeaderCellDef fxFlex.gt-sm="7">Side</mat-header-cell>
                    <mat-cell *matCellDef="let contract" fxFlex.gt-sm="7">{{ contract.side | titlecase }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="quantity">
                    <mat-header-cell *matHeaderCellDef fxFlex.xs="13" fxFlex.sm="10" fxFlex.gt-sm="9">Qty</mat-header-cell>
                    <mat-cell *matCellDef="let contract" fxFlex.xs="13" fxFlex.sm="10" fxFlex.gt-sm="9">{{ contract.quantity | number:'1.0-2' }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="originatorName">
                    <mat-header-cell *matHeaderCellDef fxFlex.gt-sm="17">Originator</mat-header-cell>
                    <mat-cell *matCellDef="let contract" fxFlex.gt-sm="17">{{ contract.originatorName }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="patronName">
                    <mat-header-cell *matHeaderCellDef fxFlex.sm="25" fxFlex.gt-sm="17">Patron</mat-header-cell>
                    <mat-cell *matCellDef="let contract" fxFlex.sm="25" fxFlex.gt-sm="17">{{ contract.patronName }}</mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="footer">
                    <mat-footer-cell *matFooterCellDef fxFlex="100">No Contracts or Pricings</mat-footer-cell>
                  </ng-container>
                  <mat-header-row click="" *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                  <mat-row (click)="selectContract(contract)" *matRowDef="let contract; columns: columnsToDisplay"></mat-row>
                  <ng-container *ngIf="!reportDataSourceMap[commodityProfile.docId].data.length">
                    <mat-footer-row *matFooterRowDef="footerColumnsToDisplay"></mat-footer-row>
                  </ng-container>
                </mat-table>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </mat-card>
  </div>
</div>
