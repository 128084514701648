<div fxFlex="100">
  <div *ngIf="!errorMessage" fxLayout="column">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [formControl]="filterValue" id="ad-hoc-order-filter-field" #filter>
      <button type="button" id="clear-ad-hoc-order-filter" mat-button *ngIf="filterValue.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearFilter()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <button *ngIf="exportable" aria-label="Export" id="export-ad-hoc-orders" mat-raised-button color="primary" class="export-btn" [matMenuTriggerFor]="exportMenu" type="button">Export</button>
    <mat-menu #exportMenu="matMenu">
      <button type="button" aria-label="Export Excel" id="export-ad-hoc-orders-as-xlsx" mat-menu-item (click)="exporter.exportTable('xlsx', exportService.getExportOptions('xlsx', 'AdHocOrders'))">Excel</button>
      <button type="button" aria-label="Export CSV" id="export-ad-hoc-orders-as-csv" mat-menu-item (click)="exporter.exportTable('csv', exportService.getExportOptions('csv', 'AdHocOrders'))">CSV</button>
      <button type="button" aria-label="Export JSON" id="export-ad-hoc-orders-as-json" mat-menu-item (click)="exporter.exportTable('json', exportService.getExportOptions('json', 'AdHocOrders'))">JSON</button>
    </mat-menu>

    <mat-table matTableExporter #exporter="matTableExporter" [dataSource]="dataSource" matSort class="mat-elevation-z0" (matSortChange)="handleSortChange()">

      <ng-container matColumnDef="orderDocId">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="40" fxFlex.gt-xs="17">Order ID</mat-header-cell>
        <mat-cell *matCellDef="let adHocOrder" fxFlex.xs="40" fxFlex.gt-xs="17">{{ adHocOrder.orderDocId }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="creationTimestamp">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="14">Created</mat-header-cell>
        <mat-cell *matCellDef="let adHocOrder" fxFlex.xs="30" fxFlex.gt-xs="14"><span class="forced-wrap">{{ adHocOrder.creationTimestamp | date: 'MM/dd/yyyy hh:mm:ss a' }}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.xs="30" fxFlex.gt-xs="12">Status</mat-header-cell>
        <mat-cell *matCellDef="let adHocOrder"  fxFlex.xs="30" fxFlex.gt-xs="12">{{ adHocOrder.status | replace | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="commodityProfileName">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="12">Profile</mat-header-cell>
        <mat-cell *matCellDef="let adHocOrder" fxFlex.gt-xs="12">{{ adHocOrder.commodityProfileName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="contractYearMonth">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="8">Contract</mat-header-cell>
        <mat-cell *matCellDef="let adHocOrder" fxFlex.gt-xs="8">
          <ng-container *ngIf="adHocOrder.contractYearMonth">{{ adHocOrder.contractYearMonth | codeToMonth }}</ng-container>
          <ng-container *ngIf="!adHocOrder.contractYearMonth">{{ adHocOrder.legs[0].contractYearMonth | codeToMonth }}/{{ adHocOrder.legs[1].contractYearMonth | codeToMonth }}</ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="8">Type</mat-header-cell>
        <mat-cell *matCellDef="let adHocOrder" fxFlex.gt-xs="8">{{ adHocOrder.type | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="side">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="5">Side</mat-header-cell>
        <mat-cell *matCellDef="let adHocOrder" fxFlex.gt-xs="5">{{ adHocOrder.side | titlecase }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="8">Qty</mat-header-cell>
        <mat-cell *matCellDef="let adHocOrder" fxFlex.gt-xs="8">{{ adHocOrder.quantity }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="price">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="8">Target</mat-header-cell>
        <mat-cell *matCellDef="let adHocOrder" fxFlex.gt-xs="8">
          <ng-container>{{ adHocOrder.price ? (adHocOrder.price | number:'1.4-4') : 'Market' }}</ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="fillPrice">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.gt-xs="8">Fill</mat-header-cell>
        <mat-cell *matCellDef="let adHocOrder" fxFlex.gt-xs="8">
          <ng-container *ngIf="adHocOrder.isSplitFilled">{{ adHocOrder.fillPrice | number:'1.4-4' }}*</ng-container>
          <ng-container *ngIf="!adHocOrder.isSplitFilled">{{ adHocOrder.fillPrice | number:'1.4-4' }}</ng-container>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row (click)="selectAdHocOrder(adHocOrder)" *matRowDef="let adHocOrder; columns: columnsToDisplay"></mat-row>
    </mat-table>
    <mat-paginator color="primary" [pageSizeOptions]="[10, 20, 50]" (page)="handlePageChange()"></mat-paginator>
  </div>
</div>
