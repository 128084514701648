import { AuthService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource } from '@advance-trading/angular-common-services';
import {
  CommodityMap,
  CommodityProfile,
  Contract,
  ContractType,
  HistoryRecord,
  HMSPriceAdjustmentMap
} from '@advance-trading/ops-data-lib';
import { Component, Input, OnChanges } from '@angular/core';
import { of } from 'rxjs';

import { catchError, map, tap } from 'rxjs/operators';
import { ContractHistoryHelperService } from '../../service/contract-history-helper.service';
import { HistoryRecordService } from '../../service/history-record.service';

import { HistoryEvent } from './contract-history-display';

@Component({
  selector: 'hms-contract-history',
  templateUrl: './contract-history.component.html',
  styleUrls: ['./contract-history.component.scss']
})
export class ContractHistoryComponent implements OnChanges {
  @Input() commodityMap: CommodityMap;
  @Input() commodityProfile: CommodityProfile;
  @Input() priceAdjustmentMap: HMSPriceAdjustmentMap;
  @Input() selectedClientDocId: string;
  @Input() selectedContract: Contract;

  errorMessage: string;
  dataSource = new ObservableDataSource<HistoryEvent>();
  columnsToDisplay = ['eventType', 'details', 'lastUpdatedBy', 'timestamp'];
  isLoading = false;

  constructor(
    private authService: AuthService,
    private contractHistoryHelperService: ContractHistoryHelperService,
    private historyRecordService: HistoryRecordService
  ) { }

  ngOnChanges() {
    if (!sessionStorage.getItem('contractHistory')) {
      this.isLoading = true;
      this.dataSource.data$ = this.historyRecordService
        .getContractHistoryByDocId(this.selectedClientDocId, this.selectedContract.docId, this.authService.accessToken).pipe(
          map((historyRecords) => {
            const contractRecords = this.getContractHistory(historyRecords['contractHistory']);
            // note: consistent with contract details component as it is not displaying segment tab for CASH contracts
            const segmentRecords = this.selectedContract.type !== ContractType.CASH
              ? this.getSegmentHistory(historyRecords['pricingSegmentHistory']) : [];

            return contractRecords.concat(segmentRecords)
              .sort((a: HistoryEvent, b: HistoryEvent) => {
                // if records have the same timestamp, order by index (higher index after lower index)
                if (b.timestamp === a.timestamp) {
                  return b.index - a.index;
                }
                // order by most recent record otherwise
                return b.timestamp - a.timestamp;
              });
          }),
          tap((historyEvents: HistoryEvent[]) => {
            sessionStorage.setItem('contractHistory', JSON.stringify(historyEvents));
            this.isLoading = false;
          }),
          catchError(err => {
            this.errorMessage = 'Error retrieving contract history; please try again later';
            console.error(`Error retrieving contract history: ${err}`);
            this.isLoading = false;
            return of([]);
          })
        );
    } else {
      this.dataSource.data = JSON.parse(sessionStorage.getItem('contractHistory'));
    }
  }

  // Contract History Functions
  private getContractHistory(contractHistoryRecords: HistoryRecord[]): HistoryEvent[] {
    let allContractHistoryEvents = [];
    contractHistoryRecords.forEach((newHistoryRecord: HistoryRecord, index: number) => {
      const oldHistoryRecord = contractHistoryRecords[index - 1];
      const historyEvents = this.contractHistoryHelperService
        .getContractHistoryEvents(oldHistoryRecord, newHistoryRecord,
          this.commodityProfile, this.priceAdjustmentMap, this.commodityMap);
      allContractHistoryEvents = allContractHistoryEvents.concat(historyEvents);
    });
    return allContractHistoryEvents;
  }

  // Pricing Segment History Functions
  private getSegmentHistory(historyRecordsMap: { [docId: string]: HistoryRecord[] }): HistoryEvent[] {
    let allSegmentHistoryEvents = [];
    Object.values(historyRecordsMap).forEach((segmentRecords: HistoryRecord[]) => {
      allSegmentHistoryEvents = allSegmentHistoryEvents.concat(this.getSingleSegmentHistory(segmentRecords));
    });
    return allSegmentHistoryEvents;
  }

  private getSingleSegmentHistory(segmentHistoryRecords: HistoryRecord[]): HistoryEvent[] {
    let singleSegmentHistoryEvents = [];
    segmentHistoryRecords.forEach((newHistoryRecord: HistoryRecord, index: number) => {
      const oldHistoryRecord = segmentHistoryRecords[index - 1];
      const historyEvents = this.contractHistoryHelperService.getSegmentHistoryEvents(
        oldHistoryRecord, newHistoryRecord, this.commodityProfile, this.commodityMap);
      singleSegmentHistoryEvents = singleSegmentHistoryEvents.concat(historyEvents);
    });
    return singleSegmentHistoryEvents;
  }
}
