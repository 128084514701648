import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input,
  OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';

import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '@advance-trading/angular-ati-security';
import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { ExecutionReportService, LocationService, OperationsDataService, OrderFillSummary } from '@advance-trading/angular-ops-data';
import {
  Client,
  Commodity,
  CommodityMap,
  CommodityProfile,
  CommodityProfileProductionYear,
  Contract,
  ContractType,
  Hedge,
  HedgeType,
  HMSDailyLedger,
  HMSUserPreferences,
  LedgerAdjustment,
  Location,
  PricingSegment,
  Side
} from '@advance-trading/ops-data-lib';

import moment from 'moment-timezone';

import { ClientSelectorService } from '../../service/client-selector.service';
import { ContractService } from '../../service/contract.service';
import { ExportService } from '../../service/export.service';
import { HedgeService } from '../../service/hedge.service';
import { HMSDailyLedgerService } from '../../service/hms-daily-ledger.service';
import { LedgerAdjustmentService } from '../../service/ledger-adjustment.service';
import { LedgerHelperService } from '../../service/ledger-helper.service';
import { PricingSegmentService } from '../../service/pricing-segment.service';
import { UserPreferencesService } from '../../service/user-preferences.service';

import { ICON_MAP } from '../icon-map';

import { PositionsLogDisplay } from './positions-log-display';

const FULL_DATE_FORMAT = 'YYYY-MM-DD';

type LiveLedgerColumns =
  'position' | 'timestamp' | 'positionsType' | 'futuresYearMonth' | 'side' | 'displayQuantity' | 'quantity' | 'originalQuantity' |
  'OLD' | 'NEW' | 'NEW_PLUS_1' | 'NEW_PLUS_2' |
  'basisPrice' | 'futuresPrice' | 'cashPrice' | 'originatorName' | 'locationName' | 'patronDisplayName' | 'comments' | 'deleted';

@Component({
  selector: 'hms-positions-log',
  templateUrl: './positions-log.component.html',
  styleUrls: ['./positions-log.component.scss']
})
export class PositionsLogComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit {

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  @Output() positionsLogError = new EventEmitter<string>();
  @Output() loaded = new EventEmitter();

  @Input() commodityProfile: CommodityProfile;
  @Input() ledgerDate: string;
  @Input() ledgerEndOfDay: string;
  @Input() timezone: string;
  profileProdYearLabels: string[];
  activeProdYears: CommodityProfileProductionYear[];

  dataSource = new ObservableDataSource<PositionsLogDisplay>();
  columnsToDisplay = [];
  lastIdxClicked = -1;

  displayNotesText: boolean = false;

  /** A list that tracks all the rxjs subscriptions this component registers. */
  private subscriptions: Subscription[] = [];

  private startDate: string;
  private endDate: string;
  private yesterdayDate: string;
  private selectedClientDocId: string;
  private translatedProductionYears: { [key: string]: CommodityProfileProductionYear };

  readonly ROLLOVER_TOTAL = 'Rollover Totals';

  private commodities: {[key: string]: Commodity};
  private orderTypeRows: {[key: string]: PositionsLogDisplay} = {};
  private exchangeTypeRows: {[key: string]: PositionsLogDisplay} = {};

  private useLiveLedgerColor = false;

   breakpointGroup: 'xs' | 'md' | 'lg' = 'lg';

   /**
    * A dictionary of strings based on flex shorthand value percentages.
    * Each value represents the _RELATIVE_ flex growth to _all other columns_
    * within a row.
    */
   private colFlex = {
     flex0: '0 0 0%',
     flex5: '.05 1 0%',
     flex10: '.1 1 0%',
     flex20: '.2 1 0%',
     flex30: '.3 1 0%',
     flex40: '.4 1 0%',
     flex50: '.5 1 0%',
     flex60: '.6 1 0%',
     flex70: '.7 1 0%',
     flex80: '.8 1 0%',
     flex90: '.9 1 0%',
     flex100: '1 1 0%',
     flex150: '1.5 1 0%',
     flex200: '2 1 0%',
     flex300: '3 1 0%',
     flex400: '4 1 0%',
     flex500: '5 1 0%',
     flex600: '6 1 0%',
   };

   private columnFlexData: { [key in keyof Record<LiveLedgerColumns, string>]: { [key in 'xs' | 'md' | 'lg']: string } } = {
     position: { xs: this.colFlex.flex90, md: this.colFlex.flex50, lg: this.colFlex.flex20 },
     timestamp: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex80 },
     positionsType: { xs: this.colFlex.flex90, md: this.colFlex.flex50, lg: this.colFlex.flex60 },
     futuresYearMonth: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex90 },
     side: { xs: this.colFlex.flex90, md: this.colFlex.flex70, lg: this.colFlex.flex50 },
     displayQuantity: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex60 },
     quantity: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex60 },
     originalQuantity: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex60 },

     OLD: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex70 },
     NEW: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex70 },
     NEW_PLUS_1: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex70 },
     NEW_PLUS_2: { xs: this.colFlex.flex80, md: this.colFlex.flex70, lg: this.colFlex.flex70 },

     basisPrice: { xs: this.colFlex.flex90, md: this.colFlex.flex70, lg: this.colFlex.flex80 },
     futuresPrice: { xs: this.colFlex.flex90, md: this.colFlex.flex70, lg: this.colFlex.flex80 },
     cashPrice: { xs: this.colFlex.flex90, md: this.colFlex.flex70, lg: this.colFlex.flex80 },
     originatorName: { xs: this.colFlex.flex90, md: this.colFlex.flex90, lg: this.colFlex.flex90 },
     locationName: { xs: this.colFlex.flex90, md: this.colFlex.flex90, lg: this.colFlex.flex90 },
     patronDisplayName: { xs: this.colFlex.flex90, md: this.colFlex.flex90, lg: this.colFlex.flex150 },
     comments: { xs: this.colFlex.flex90, md: this.colFlex.flex80, lg: this.colFlex.flex300 },
     deleted: { xs: this.colFlex.flex0, md: this.colFlex.flex0, lg: this.colFlex.flex0 } // this column is visually hidden
   };

   /** A dictionary of columns to display at certain widths for the activity live ledger table. */
   private base_columns = {
     minimal: [
      'position', 'timestamp', 'positionsType', 'futuresYearMonth', 'side', 'displayQuantity', 'quantity', 'originalQuantity', 'deleted',
     ],
     most: [
      'position', 'timestamp', 'positionsType', 'futuresYearMonth', 'side', 'displayQuantity', 'quantity', 'originalQuantity',
      'basisPrice', 'futuresPrice', 'cashPrice', 'comments', 'deleted'
     ],
     all: [
      'position', 'timestamp', 'positionsType', 'futuresYearMonth', 'side', 'displayQuantity', 'quantity', 'originalQuantity',
      'basisPrice', 'futuresPrice', 'cashPrice', 'originatorName', 'locationName', 'patronDisplayName', 'comments', 'deleted'
     ]
   };

   private columns = {
    minimal: [...this.base_columns.minimal],
    most: [...this.base_columns.most],
    all: [...this.base_columns.all]
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private breakpointObserver: BreakpointObserver,
    private clientSelectorService: ClientSelectorService,
    private contractService: ContractService,
    private executionReportService: ExecutionReportService,
    public exportService: ExportService,
    private hedgeService: HedgeService,
    private hmsDailyLedgerService: HMSDailyLedgerService,
    private ledgerAdjustmentService: LedgerAdjustmentService,
    private ledgerHelperService: LedgerHelperService,
    private locationService: LocationService,
    private operationsDataService: OperationsDataService,
    private pricingSegmentService: PricingSegmentService,
    private router: Router,
    private userPreferencesSettingsService: UserPreferencesService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.setupBreakpointObserversForTableColumns();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges) {
    // get positions log based on which commodity profile is selected
    if (changes['commodityProfile'] || changes['ledgerDate']) {
      // get active production years
      this.activeProdYears = this.ledgerHelperService.getActiveProdYears(
        this.ledgerDate, this.commodityProfile.productionYears, this.ledgerEndOfDay, this.timezone);

      // get unique production years label
      this.profileProdYearLabels = Object.values(this.activeProdYears).map(prodYear => prodYear.label);

      // get translations of production years for display on items
      this.translatedProductionYears = this.ledgerHelperService.getTranslatedProductionYears(
        this.ledgerDate, this.commodityProfile.productionYears, this.ledgerEndOfDay, this.timezone);

      this.updateColumns();
      this.setupBreakpointObserversForTableColumns();

      let includeProductionYears = true;

      this.dataSource.data$ = this.clientSelectorService.getSelectedClient().pipe(
        switchMap((selectedClient: Client) => {
          this.selectedClientDocId = selectedClient.docId;
          const userDocId = this.authService.userProfile.app_metadata.firestoreDocId;
          return this.userPreferencesSettingsService.getHmsPreferencesByUserDocId(userDocId);
        }),
        switchMap((hmsUserPreferences: HMSUserPreferences) => {
          if (hmsUserPreferences) {
            this.useLiveLedgerColor = hmsUserPreferences.useLiveLedgerColor;
          }
          return this.operationsDataService.getCommodityMap();
        }),
        switchMap((doc: CommodityMap) => {
          this.commodities = doc.commodities;
          const dateParams = this.ledgerHelperService.getDateParameters(this.ledgerDate);
          this.startDate = dateParams.startDate;
          this.endDate = dateParams.endDate;
          this.yesterdayDate = moment.tz(this.startDate, this.timezone).format(FULL_DATE_FORMAT);
          return this.getPositionsLogObs(includeProductionYears);
        }),
        tap((positionsLogs: PositionsLogDisplay[]) => {
          this.loaded.emit();
        }),
        catchError(err => {
          if (err.name === 'MissingLedgerEndOfDayError') {
            this.positionsLogError.emit(err.message);
          } else {
            this.positionsLogError.emit('Error retrieving positions log; please try again later');
          }
          console.error(`Error retrieving positions log: ${err}`);
          return of([]);
        })
      );
    }
  }

  getFlexLayout = (columnName: LiveLedgerColumns): string => {
    if (!columnName) {
      console.error('no column name given, using default');
      return this.colFlex.flex100;
    }

    const flexValue = this.columnFlexData[columnName][this.breakpointGroup];
    if (!flexValue) {
      console.error('could not locate column name/breakpoint, using default', flexValue);
    }

    return flexValue || this.colFlex.flex100;
  }

  getIconName(type: string) {
    return ICON_MAP[type];
  }

  selectPositionsLog(positionsLog: PositionsLogDisplay) {
    if (positionsLog.positionsType === this.ROLLOVER_TOTAL) {
      return;
    }

    if (positionsLog.positionsType === 'HEDGE') {
      this.router.navigate(['/hedges', positionsLog.docId]);
    } else if (positionsLog.positionsType === 'ADJUSTMENT') {
      this.router.navigate(['../ledgeradjustments', positionsLog.docId], {relativeTo: this.activatedRoute});
    } else if (positionsLog.segmentDocId) {
      this.router.navigate([ '/contracts', positionsLog.contractDocId ], { queryParams: { segmentId: positionsLog.segmentDocId }, queryParamsHandling: 'merge' });
    } else {
      this.router.navigate([ '/contracts', positionsLog.contractDocId ]);
    }
  }

  getTypeNgClass(positionsLog: PositionsLogDisplay) {
    const typeNgClass = {};
    if (this.useLiveLedgerColor) {
      typeNgClass['type-icon'] = true;

      if (positionsLog.orderDocId && Number.isFinite(positionsLog.futuresPrice)
        && positionsLog.positionsType !== 'HEDGE'
      ) {
        typeNgClass['order-row'] = true;
      } else if (positionsLog.isExchange) {
        typeNgClass['exchange'] = true;
      } else if (positionsLog.positionsType === ContractType.BASIS) {
        typeNgClass['basis-contract-or-pricing'] = true;
      } else if (positionsLog.positionsType === ContractType.HTA) {
        typeNgClass['hta-contract-or-pricing'] = true;
      } else if (positionsLog.positionsType === ContractType.DP) {
        typeNgClass['dp-pricing'] = true;
      } else if (positionsLog.positionsType === 'HEDGE') {
        typeNgClass['hedge'] = true;
      } else if (positionsLog.positionsType === 'ADJUSTMENT') {
        typeNgClass['ledger-adjustment'] = true;
      }
    }

    return typeNgClass;
  }

  insertArrayAt(array, index, arrayToInsert) {
    Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
  }

  updateColumns() {
    // Create a true copy of the base columns to insert prodYearLabels in to
    this.columns = {
      minimal: [...this.base_columns.minimal],
      most: [...this.base_columns.most],
      all: [...this.base_columns.all]
    };

    this.insertArrayAt(this.columns.all, this.columns.all.indexOf('originalQuantity'), this.profileProdYearLabels);
  }

  // this method get all well formatted positions log
  private getPositionsLogObs(includeProductionYears: boolean): Observable<PositionsLogDisplay[]> {
    let rolloverProdYears = {};
    return combineLatest(this.activeProdYears.map((prodYear: CommodityProfileProductionYear) => {
      return this.hmsDailyLedgerService.getHMSDailyLedgerByCommodityProfileAndProductionYear(
        this.selectedClientDocId, this.commodityProfile.docId, prodYear.year, this.yesterdayDate);
    })).pipe(
      switchMap((hmsDailyLedgers: HMSDailyLedger[][]) => {
        rolloverProdYears = {};
        hmsDailyLedgers.forEach((dailyLedgers: HMSDailyLedger[]) => {
          dailyLedgers.forEach((dailyLedger: HMSDailyLedger) => {
            const currentProdYearLabel = this.translatedProductionYears[dailyLedger.productionYear].label;
            rolloverProdYears[currentProdYearLabel] = dailyLedger.hedgeableQuantity;
          });
        });

        return combineLatest(
          [ this.getContractsAndSegmentsObs(),
            this.getHedgesObs(),
            this.getLedgerAdjustmentsObs()
          ]);
      }),
      map(([contractsAndSegments, hedges, ledgerAdjustments]) => {
        // combine all pricing events, hedges, adjustments made today
        let logs: PositionsLogDisplay[] = contractsAndSegments.concat(hedges, ledgerAdjustments);

        // sort positions logs by creationTimestamp in descending order
        logs = logs.sort((firstLog, secondLog) => secondLog.timestamp.localeCompare(firstLog.timestamp));

        // put additional order type rows in the proper position
        Object.values(this.orderTypeRows).forEach(orderTypeRow => {
          // find index of the event with the same doc id
          const currEventIdx = logs.findIndex(log => log.docId === orderTypeRow.docId);
          // insert current order row at found index
          logs.splice(currEventIdx, 0,  orderTypeRow);
        });

        // put additional exchange type rows in the proper position
        Object.values(this.exchangeTypeRows).forEach(exchangeTypeRow => {
          // find index of the event with the same doc id
          const currEventIdx = logs.findIndex(log => log.docId === exchangeTypeRow.docId);
          // insert current exchange row at found index
          logs.splice(currEventIdx, 0,  exchangeTypeRow);
        });

        // set position number in descending order
        logs.map((log, index) => log.position = logs.length - index);

        // calculate accumulating hedgeable total quantity and put it in the display
        const currHedgeableTotal = {};
        logs.slice().reverse().forEach(log => {
          log.prodYears = {};
          // note: ignore running total calculation for deleted OTC contracts or segments
          if (log.hedgeType !== HedgeType.OTC || !log.deletionTimestamp) {
            // current production year label to determine which running total to add
            const hedgeableTotalLabel = log.hedgeableTotalLabel;
            // handle when there is no rollover quantity
            const rolloverQuantity = rolloverProdYears[hedgeableTotalLabel] ? rolloverProdYears[hedgeableTotalLabel] : 0;

            // add quantity if side is a buy
            // or undefined (for ledger adjustment), subtract otherwise
            const rowHistoricalQuantity = log.originalQuantity ?? log.quantity;
            if (currHedgeableTotal[hedgeableTotalLabel] !== undefined) {
              currHedgeableTotal[hedgeableTotalLabel] += log.side === Side.BUY  ||
                                                          !log.side ?
                                                          rowHistoricalQuantity :
                                                          -rowHistoricalQuantity;
            } else {
              currHedgeableTotal[hedgeableTotalLabel] = log.side === Side.BUY ||
                                                        !log.side ?
                                                        (rolloverQuantity + rowHistoricalQuantity) :
                                                        (rolloverQuantity - rowHistoricalQuantity);
            }
            log.prodYears[hedgeableTotalLabel] = currHedgeableTotal[hedgeableTotalLabel];
          }
        });

        // only insert production year totals row if production year columns displayed
        if (includeProductionYears) {
          const rolloverRow = { positionsType: this.ROLLOVER_TOTAL } as PositionsLogDisplay;
          rolloverRow.prodYears = {};
          this.profileProdYearLabels.forEach(label => {
            rolloverRow.prodYears[label] = rolloverProdYears[label] ? rolloverProdYears[label] : 0;
          });
          logs.push(rolloverRow);
        }

        return logs;
      })
    );
  }

  private getContractsAndSegmentsObs(): Observable<PositionsLogDisplay[]> {
    return combineLatest([
      this.getContractsObs(),
      this.getSegmentsObs()
    ]).pipe(
      switchMap(([contracts, segments]) => {
        const logs = contracts.concat(segments);
        return this.handleContractsAndSegments(logs);
      })
    );
  }

  private getContractsObs(): Observable<PositionsLogDisplay[]> {
    return combineLatest([this.getCashAndHtaPricingEventContractsObs(),
      this.getExchangeContractObs()])
    .pipe(
      map(([contracts, exchangeContracts]) => {
        contracts = contracts.concat(exchangeContracts);
        return contracts.map((contract: Contract) => {
          const positionsType = contract.isSpot ? 'SPOT' : contract.type;
          return {
            positionsType,
            timestamp: contract.isExchange ? contract.exchangeTimestamp : contract.futuresLockedTimestamp,
            side: contract.side,
            hedgeableTotalLabel: this.translatedProductionYears[contract.productionYear].label,
            futuresYearMonth: contract.futuresYearMonth,
            quantity: contract.quantity,
            basisPrice: contract.basisPrice,
            futuresPrice: contract.futuresPrice,
            cashPrice: contract.cashPrice,
            originatorName: contract.originatorName,
            locationName: contract.clientLocationName,
            patronName: contract.patronName,
            patronAccountingSystemId: contract.patronAccountingSystemId,
            comments: contract.comments,
            docId: contract.docId,
            contractDocId: contract.docId,
            isPricingSegment: false,
            pricingType: contract.pricingType,
            orderDocId: contract.contractOrderDocId,
            deletionTimestamp: contract.deletionTimestamp,
            cancellationTimestamp: contract.cancellationTimestamp,
            targetOrderThreshold: contract.targetOrderThreshold,
            isExchange: contract.isExchange,
            exchangeContracts: contract.exchangeContracts,
            hedgeType: contract.relatedHedgeType,
            originalQuantity: contract.originalQuantity
          } as PositionsLogDisplay;
        });
      })
    );
  }

  private getCashAndHtaPricingEventContractsObs(): Observable<Contract[]> {
    return this.contractService.getContractsByTypeCommodityProfileAndFuturesLockedTimestamp(
      this.selectedClientDocId, [ContractType.CASH, ContractType.HTA], this.commodityProfile.docId, this.startDate, this.endDate);
  }

  private getExchangeContractObs(): Observable<Contract[]> {
    return this.contractService.getExchangeContractsByExchangeTimestamp(
      this.selectedClientDocId, this.startDate, this.endDate, this.commodityProfile.docId);
  }

  /** Sets up breakpoint observers for the browser window, which push which columns to display at different widths of the window.*/
  private setupBreakpointObserversForTableColumns(): void {
    this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    this.subscriptions.push(this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(state => {
      if (state.breakpoints[Breakpoints.XSmall]) {
        this.columnsToDisplay = this.columns.minimal;
        this.breakpointGroup = 'xs';
        this.displayNotesText = false;
      } else if (state.breakpoints[Breakpoints.Small] || state.breakpoints[Breakpoints.Medium]) {
        this.columnsToDisplay = this.columns.most;
        this.displayNotesText = false;
        this.breakpointGroup = 'md';
      } else if (state.breakpoints[Breakpoints.Large] || state.breakpoints[Breakpoints.XLarge]) {
        this.columnsToDisplay = this.columns.all;
        this.displayNotesText = true;
        this.breakpointGroup = 'lg';
      }
      this.changeDetector.detectChanges();
    }));
  }

  private getSegmentsObs(): Observable<PositionsLogDisplay[]> {
    return combineLatest([this.getBasisAndDpFuturesLockedPricingEventSegmentsObs(), this.getExchangePricingSegmentsObs()]).pipe(
      switchMap(([segments, exchangeSegments]) => {
        segments = segments.concat(exchangeSegments);
        if (segments.length === 0) {
          return of([]);
        }

        return combineLatest(
          segments.map((segment: PricingSegment) =>  {
            return this.contractService.getContractByDocId(this.selectedClientDocId, segment.contractDocId).pipe(
              map((contract: Contract) => {
                return {
                  positionsType: segment.contractType,
                  timestamp: segment.isExchange ? segment.exchangeTimestamp : segment.futuresLockedTimestamp,
                  side: segment.side,
                  hedgeableTotalLabel: this.translatedProductionYears[contract.productionYear].label,
                  futuresYearMonth: segment.futuresYearMonth,
                  quantity: segment.quantity,
                  basisPrice: segment.basisPrice,
                  futuresPrice: segment.futuresPrice,
                  cashPrice: segment.cashPrice,
                  originatorName: segment.originatorName,
                  locationDocId: segment.clientLocationDocId,
                  docId: segment.docId,
                  contractDocId: segment.contractDocId,
                  isPricingSegment: true,
                  patronName: contract.patronName,
                  patronAccountingSystemId: contract.patronAccountingSystemId,
                  comments: contract.comments,
                  futuresPricingType: segment.futuresPricingType,
                  cashPricingType: segment.cashPricingType,
                  orderDocId: segment.orderDocId,
                  deletionTimestamp: segment.deletionTimestamp,
                  cancellationTimestamp: segment.cancellationTimestamp,
                  targetOrderThreshold: segment.targetOrderThreshold,
                  isExchange: segment.isExchange,
                  exchangeContracts: segment.exchangeContracts,
                  hedgeType: segment.relatedHedgeType,
                  segmentDocId: segment.docId,
                  originalQuantity: segment.originalQuantity
                } as PositionsLogDisplay;
              })
            );
          })
        );
      }),
      switchMap((segments: PositionsLogDisplay[]) => {
        if (segments.length === 0) {
          return of([]);
        }

        return combineLatest(
          segments.map((segment: PositionsLogDisplay) =>  {
            return this.locationService.getLocationByDocId(this.selectedClientDocId, segment.locationDocId).pipe(
              map((location: Location) => {
                return {
                  ...segment,
                  locationName: location.name
                } as PositionsLogDisplay;
              })
            );
          })
        );
      })
    );
  }

  private getBasisAndDpFuturesLockedPricingEventSegmentsObs(): Observable<PricingSegment[]> {
    return this.pricingSegmentService.findPricingSegmentsByTypeCommodityProfileAndFuturesLockedTimestamp(
      this.selectedClientDocId, [ContractType.BASIS, ContractType.DP], this.commodityProfile.docId, this.startDate, this.endDate);
  }

  private getExchangePricingSegmentsObs(): Observable<PricingSegment[]> {
    return this.pricingSegmentService.findExchangePricingSegmentsByExchangeTimestamp(
      this.selectedClientDocId, this.startDate, this.endDate, this.commodityProfile.docId);
  }

  private getHedgesObs(): Observable<PositionsLogDisplay[]> {
    return this.hedgeService.getHedgesByDateRangeAndCommodityProfileDocId(
      this.selectedClientDocId, this.startDate, this.endDate, this.commodityProfile.docId).pipe(
      map((hedges: Hedge[]) => {
        return hedges.map((hedge: Hedge) => {
          // TODO undefined type check can be removed after a data conversion updates all Hedge docs
          const isStandardHedge: boolean = !hedge.type || hedge.type === HedgeType.STANDARD;
          return {  positionsType: 'HEDGE',
                    timestamp: hedge.creationTimestamp,
                    side: hedge.side,
                    hedgeableTotalLabel: this.translatedProductionYears[hedge.productionYear].label,
                    futuresYearMonth: hedge.futuresYearMonth,
                    quantity: hedge.quantity,
                    originatorName: hedge.creatorName,
                    comments: hedge.comments,
                    docId: hedge.docId,
                    orderDocId: hedge.orderDocId,
                    hedgeType: hedge.type || HedgeType.STANDARD,
                    futuresPrice: !isStandardHedge ? hedge.price : undefined,
                    deletionTimestamp: hedge.deletionTimestamp
                  }  as PositionsLogDisplay;
        });
      }),
      switchMap((hedges: PositionsLogDisplay[]) => {
        if (hedges.length === 0) {
          return of([]);
        }
        // fetching futures price from ExecutionReports
        return combineLatest(
          hedges.map((hedge: PositionsLogDisplay) => {
            if (hedge.hedgeType !== HedgeType.STANDARD) {
              return of(hedge);
            }
            return this.executionReportService.getOrderFillSummaryByOrderDocId(
              this.commodityProfile.accountDocId, hedge.orderDocId).pipe(
              map((orderFillSummary: OrderFillSummary) => {
                if (orderFillSummary) {
                  return {
                    ...hedge,
                    isSplitFilled: orderFillSummary.isSplitFilled,
                    futuresPrice: orderFillSummary.fillPrice / this.commodities[this.commodityProfile.commodityId].marketDataDivisor
                  } as PositionsLogDisplay;
                }
                // should not happen
                console.error(`Order fill data was not able to be retrieved for Hedge ${hedge.docId}`);
                return hedge;
              }),
              catchError(err => {
                console.error(`Failed to retrieve order fill data from execution reports: ${err}`);
                return of(hedge);
              })
            );
          })
        );
      })
    );
  }

  private getLedgerAdjustmentsObs(): Observable<PositionsLogDisplay[]> {
    return this.ledgerAdjustmentService.getLedgerAdjustmentsByDateAndCommodityProfileDocId(
      this.selectedClientDocId, this.startDate, this.endDate, this.commodityProfile.docId).pipe(
      map((ledgerAdjustments: LedgerAdjustment[]) => {
        return ledgerAdjustments.map((ledgerAdjustment: LedgerAdjustment) => {
          return {  positionsType: 'ADJUSTMENT',
                    timestamp: ledgerAdjustment.creationTimestamp,
                    hedgeableTotalLabel: this.translatedProductionYears[ledgerAdjustment.productionYear].label,
                    quantity: ledgerAdjustment.quantity,
                    originatorName: ledgerAdjustment.creatorName,
                    comments: ledgerAdjustment.comments,
                    docId: ledgerAdjustment.docId
                  } as PositionsLogDisplay;
          });
      })
    );
  }

  private handleContractsAndSegments(logs: PositionsLogDisplay[]): Observable<PositionsLogDisplay[]> {
    this.orderTypeRows = {};
    this.exchangeTypeRows = {};
    if (logs.length === 0) {
      return of([]);
    }

    // fetching futures price from ExecutionReports
    return combineLatest(
      logs.map((log: PositionsLogDisplay) => {
        // check if the contract/segment is filled directly at the exchange
        if (log.orderDocId) {
          return this.executionReportService.getOrderFillSummaryByOrderDocId(
            this.commodityProfile.accountDocId, log.orderDocId).pipe(
            map((orderFillSummary: OrderFillSummary) => {
              this.addOrderRow(log, orderFillSummary);
              // still return original contract/segment
              return log;
            }),
            catchError(err => {
              console.error(`Failed to retrieve order fill data from execution reports: ${err}`);
              this.addOrderRow(log, undefined);
              return of(log);
            })
          );
        } else {
          if (log.isExchange) {
            this.addExchangeRow(log);
          }
          return of(log);
        }
      })
    );
  }

  private addOrderRow(log: PositionsLogDisplay, orderFillSummary: OrderFillSummary) {
    const currOrderType = {
      ...log
    } as PositionsLogDisplay;

    currOrderType.positionsType = 'ORDER';
    currOrderType.quantity = this.getOrderQuantity(log);

    // The order should be the opposite of the associated contract or segment
    if (currOrderType.side === Side.BUY) {
      currOrderType.side = Side.SELL;
    } else {
      currOrderType.side = Side.BUY;
    }

    if (orderFillSummary) {
      currOrderType.isSplitFilled = orderFillSummary.isSplitFilled;
      currOrderType.futuresPrice = orderFillSummary.fillPrice /
                                   this.commodities[this.commodityProfile.commodityId].marketDataDivisor;
    } else {
      console.error(`Order fill data was not able to be retrieved for Order ${log.orderDocId}`);
    }
    // remove basisPrice and cashPrice
    currOrderType.basisPrice = undefined;
    currOrderType.cashPrice = undefined;
    // add order row for the qualifying contract/segment using contract/segment doc id as key to avoid duplicate
    this.orderTypeRows[currOrderType.docId] = currOrderType;
  }

  private addExchangeRow(log: PositionsLogDisplay) {
    const currExchangeType = {
      ...log
    } as PositionsLogDisplay;

    currExchangeType.originalQuantity = undefined;

    currExchangeType.positionsType = 'EXCHANGE';
    currExchangeType.quantity = this.getExchangeQuantity(log);

    // The order should be the opposite of the associated contract or segment
    if (currExchangeType.side === Side.BUY) {
      currExchangeType.side = Side.SELL;
    } else {
      currExchangeType.side = Side.BUY;
    }

    // remove basisPrice and cashPrice
    currExchangeType.basisPrice = undefined;
    currExchangeType.cashPrice = undefined;

    // add exchange row for the qualifying contract/segment using contract/segment doc id as key to avoid duplicate
    this.exchangeTypeRows[currExchangeType.docId] = currExchangeType;
  }

  private getOrderQuantity(log: PositionsLogDisplay) {
    const contractSize = this.commodities[this.commodityProfile.commodityId].contractSize;
    const fullContracts = Math.trunc(log.quantity / contractSize);
    const remainingQuantity = log.quantity % contractSize;
    return remainingQuantity >= log.targetOrderThreshold ? (fullContracts + 1) * contractSize : fullContracts * contractSize;
  }

  private getExchangeQuantity(log: PositionsLogDisplay) {
    const contractSize = this.commodities[this.commodityProfile.commodityId].contractSize;
    return log.exchangeContracts * contractSize;
  }

}
