import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ObservableDataSource } from '@advance-trading/angular-common-services';
import { AlertStatus, AppAlert } from '@advance-trading/ops-data-lib';

import { AppAlertService } from '../../service/app-alert.service';

@Component({
  selector: 'hms-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  providers: [BreakpointObserver]
})
export class AlertsComponent implements AfterViewInit, OnInit {

  @Input() selectedAlerts$: Observable<AppAlert[]>;

  columnsToDisplay = [];
  errorMessage: string;
  dataSource = new ObservableDataSource<AppAlert>();
  filterValue = new FormControl();

  startDate: string;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild('filter', {static: false}) filter: ElementRef;

  @Output() alertSearchError: EventEmitter<string> = new EventEmitter();
  @Output() isSearching: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private alertService: AppAlertService,
    private breakpointObserver: BreakpointObserver,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.isSearching.emit(true);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.startDate = today.toISOString();

    this.dataSource.data$ = this.selectedAlerts$.pipe(
      tap(alerts => this.isSearching.emit(false)),
      catchError(err => {
        this.errorMessage = 'Error retrieving alerts; please try again later';
        this.alertSearchError.emit(this.errorMessage);
        this.isSearching.emit(false);
        console.error(`Error retrieving alerts: ${err}`);
        return of([]);
      })
    );

    this.breakpointObserver.observe([Breakpoints.XSmall])
    .subscribe(state => {
      // display columns for xsmall screen
      if (state.matches) {
        this.columnsToDisplay = [
          'title', 'status', 'statusIcon'
        ];
      // display columns for larger screens
      } else {
        this.columnsToDisplay = [
          'title', 'subtitle', 'status', 'lastUpdatedByName', 'lastUpdatedTimestamp', 'statusIcon'
        ];
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.filter.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  clearFilter() {
    this.filterValue.setValue('');
    this.applyFilter('');
  }

  selectAlert(alert: AppAlert) {
    // Complete status routes to AlertDetail, others route to actionUri
    if (alert.status === AlertStatus.COMPLETE) {
      this.router.navigate(['/alerts', alert.docId]);
    } else {
      this.router.navigateByUrl(alert.actionUri);
    }
  }

  changeAlertStatus(alert: AppAlert, newStatus: AlertStatus) {
    this.alertService.updateAppAlertStatus(alert.docId, newStatus)
      .catch(err => {
        console.error(`App alert update failed: ${err}`);
        const errorMsg = err.code === 'permission-denied' ? 'Insufficient permissions' : 'Unknown error occurred';
        this.openSnackBar(`Alert update failed: ${errorMsg}`, 'DISMISS', false);
      });
  }

  // Display the snackbar message at bottom of screen
  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

}
