<div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="10">
  <mat-card fxFlex.lt-md="95" fxFlex.md="90" fxFlex.gt-md="90">

    <hms-error-message [title]="'Error Retrieving Price Adjustments'" [message]="errorMessage"></hms-error-message>

    <div *ngIf="!errorMessage" fxLayout="row" fxLayoutAlign="space-between center">
      <h2 data-cy="page-title-price-adjustments" class="card-title">Price Adjustments</h2>
      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
        <button type="button" id="edit-price-adjustments" class="action-button" aria-label="Edit Price Adjustments" matTooltip="Edit Price Adjustments" *ngIf="!editMode && updateComplete" (click)="setEditMode(true)" mat-mini-fab color="accent">
          <mat-icon>edit</mat-icon>
        </button>
        <mat-spinner class="update-spinner" *ngIf="!updateComplete" diameter="40" color="accent"></mat-spinner>
        <button type="submit" id="update-price-adjustments" class="action-button" aria-label="Update Price Adjustments" matTooltip="Update Price Adjustments" *ngIf="editMode && updateComplete" [disabled]="!priceAdjustmentsForm.valid || priceAdjustmentsForm.pristine || uncommittedAdjustmentChangesExist" (click)="updatePriceAdjustments()" mat-mini-fab color="accent">
          <mat-icon>done</mat-icon>
        </button>
        <button type="button" id="cancel-edit-price-adjustments" class="action-button" aria-label="Discard Changes" matTooltip="Discard Changes" *ngIf="editMode && updateComplete" (click)="setEditMode(false)" mat-mini-fab color="">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </div>

    <form *ngIf="!errorMessage && priceAdjustmentMap$ | async" [formGroup]="priceAdjustmentsForm">
      <div>
        <hms-price-adjustment *ngFor="let priceAdjustment of priceAdjustmentsForm.get('priceAdjustments').controls | keyvalue ; trackBy:trackBySettingKey"
          [priceAdjustment]="priceAdjustment.value" (priceAdjustmentUpdated)="onPriceAdjustmentUpdated($event)" (priceAdjustmentRemoved)="onPriceAdjustmentRemoved($event)"
          [existingPriceAdjustmentKeys]="existingPriceAdjustmentKeys" [editMode]="editMode"></hms-price-adjustment>
        <h5 *ngIf="editMode">Add Price Adjustment</h5>
        <hms-price-adjustment #newPriceAdjustment *ngIf="editMode" (priceAdjustmentUpdated)="onPriceAdjustmentCreated($event)" (priceAdjustmentRemoved)="onPriceAdjustmentRemoved($event)"
          [existingPriceAdjustmentKeys]="existingPriceAdjustmentKeys" [editMode]="editMode"></hms-price-adjustment>
      </div>
    </form>
  </mat-card>
</div>
