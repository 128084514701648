import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  constructor() {}

  /**
   * Return json formatted object for the specified xlsx file
   * Example:
   * {
   *  'Beans': {
   *    0: {'21J': '0.3•21Z'}
   *  }
   * }
   *
   * Note: duplicate columns will be parsed with a trailing underscore and a count starting at 1
   * Example:
   * {
   *  'Beans': {
   *    0: {
   *      '21J': '0.3•21Z',
   *      '21J_1': '0.3•21Z'
   *    }
   *  }
   * }
   *
   * @param file the xlsx file
   */
  importXlsx(file: File): Promise<{[sheetName: string]: {[colName: string]: string}[]}> {
    // TODO: use dynamic import to lazy load the XLSX module after typescript is updated to version >= 3.8
    // to improve initial application load time
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    return new Promise((resolve, reject) => {
      fileReader.onload = () => {
        const arrayBuffer = fileReader.result as ArrayBuffer;
        const data = new Uint8Array(arrayBuffer);
        const binaryArray: string[] = [];
        data.forEach((charCode: number) => {
          binaryArray.push(String.fromCharCode(charCode));
        });
        const bstr = binaryArray.join('');
        const workbook = XLSX.read(bstr, {type: 'binary'});

        const json = {};
        workbook.SheetNames.forEach((sheetName: string) => {
          // note: setting defval to '' ensures a default value for empty cells, raw is false to ensure string data type
          // note: setting range to 0 ensures the header row is always imported, even if its blank
          json[sheetName] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName],
            {range: 0, defval: '', raw: false}) as {[colName: string]: string}[];
        });
        resolve(json);
      };
      fileReader.onerror = () => reject(new Error('error importing xlsx file'));
    });

  }
}
