import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

import { AuthService, Auth0AuthzService } from '@advance-trading/angular-ati-security';
import { ConfirmationDialogService } from '@advance-trading/angular-common-services';
import { BasisService, LocationService, MarketDataService, OperationsDataService, OrderService, UserService } from '@advance-trading/angular-ops-data';
import {
  Basis,
  Commodity,
  CommodityMap,
  CommodityProfile,
  Contract,
  ContractMonth,
  ContractStatus,
  ContractType,
  ExchangeType,
  HedgeType,
  HMSClientSettings,
  HMSUserSettings,
  MarketData,
  PricingSegment,
  PricingType,
  PricingSegmentStatus,
  Side,
  User,
  OrderType,
  TimeInForce,
  Order,
  PricingSegmentPartType,
  OrderStatus
} from '@advance-trading/ops-data-lib';

import { ClientSettingsService } from '../../service/client-settings.service';
import { QSTService } from '../../service/qst.service';
import { UserSettingsService } from '../../service/user-settings.service';
import { UserRoles } from '../../utilities/user-roles';

import { OptionQuantityErrorMatcher } from '../contract.validator';
import { PricingSegmentEvent } from './pricing-segment-event';
import { ContractPriceHelper } from '../contract-price-helper';
import { CommodityFuturesPriceService } from 'src/app/service/commodity-futures-price.service';

const QUANTITY_REGEX = /^(?=.*[1-9])\d*(?:\.\d{0,2})?$/;
const CONTRACTS_REGEX = /^(?=.*[1-9])\d*$/;
const FIVE_DIGIT_REGEX = /^-?(\d+|\d*\.\d{1,5}|\d+\.\d{0,5})$/;
const PRICE_REGEX = /^-?(\d+|\d*\.\d{1,2}|\d*\.\d{2}(0|(00)|(25)|(50)|(5)|(75))|\d+\.\d{0,2}|\d+\.\d{0,2}(0|(00)|(25)|(50)|(5)|(75)))$/;
const FUTURES_REGEX = /^(\d+|\d*\.\d{1,2}|\d*\.\d{2}(0|(00)|(25)|(50)|(5)|(75))|\d+\.\d{0,2}|\d+\.\d{0,2}(0|(00)|(25)|(50)|(5)|(75)))$/;

const YEAR_FORMAT = 'YY';

const FIVE_DIGIT_HANDLING_TYPES = ['OTC', 'OPTION'];

const ORDER_STATUSES = [
  PricingSegmentStatus.PENDING_ORDER,
  PricingSegmentStatus.PENDING_ORDER_UPDATE,
  PricingSegmentStatus.PENDING_ORDER_CANCEL,
  PricingSegmentStatus.PENDING_ORDER_RECREATE,
  PricingSegmentStatus.WORKING_EXCHANGE,
  PricingSegmentStatus.PENDING_EXCHANGE
];

@Component({
  selector: 'hms-pricing-segment',
  templateUrl: './pricing-segment.component.html',
  styleUrls: ['./pricing-segment.component.scss']
})
export class PricingSegmentComponent implements OnDestroy, OnChanges, OnInit {

  @Input() pricingSegment: PricingSegment;
  @Input() commodityProfile: CommodityProfile;
  @Input() contract: Contract;
  @Input() availableQuantity: number;
  @Input() unpricedQuantity: number;
  @Input() createMode: boolean;
  @Input() dpPricing: ContractType;
  @Output() pricingSegmentEvent = new EventEmitter<PricingSegmentEvent>();

  @ViewChild('futuresMonthPicker', {static: false}) futuresMonthRef: MatDatepicker<moment.Moment>;

  // TODO handle errors from component
  errorMessage = '';
  editMode = false;

  pricingSegmentForm: FormGroup = this.formBuilder.group({});

  orderForm: FormGroup = this.formBuilder.group({
    orderDocId: ['', [Validators.required]]
  });

  // error matcher to detect form validation errors
  optionQuantityErrorMatcher = new OptionQuantityErrorMatcher();

  exchangeTypes = Object.keys(ExchangeType);
  pricingTypes = Object.keys(PricingType);

  segmentDetails$: Observable<CommodityMap>;
  basis$: Observable<Basis>;
  futures$: Observable<MarketData>;

  pricingBasisForHTA = false;
  pricingFuturesForBasis = false;
  pricingBasisForDPFirstPart = false;
  pricingFuturesForDPFirstPart = false;
  pricingCash = false;

  isFormLoading = true;
  isLoadingBasis = false;
  isLoadingMarketData = false;

  updateComplete = true;
  orderActionComplete = true;
  pricingActionComplete = true;

  qstWorking = false;
  qstCreateError = false;
  creatingOrder = false;
  cancellingOrder = false;
  updatingOrder = false;
  recreatingOrder = false;
  hasPartialFill = false;

  orderFormInvalid = false;

  deliveryLocationName: string;

  minDate = moment().startOf('month');
  marketBasisPrice: number;

  private marketFuturesPrice: number;
  private marketCashPrice: number;
  private loggedInUser: User;
  private userSettings: HMSUserSettings;
  private commodities: Commodity[];
  private commodityMap: CommodityMap;
  private contractMonths = Object.keys(ContractMonth);
  private selectedFuturesYearMonth: string;

  // Store the clientOrderId returned from QST for use in matching with qstService.orderEntry$
  private newOrderClientId = '';
  private exchangeOrderIdSub: Subscription;
  private existingOrder: Order;

  private clientSettings: HMSClientSettings;
  private contractPriceHelper: ContractPriceHelper;

  constructor(
    private authService: AuthService,
    private authzService: Auth0AuthzService,
    private basisService: BasisService,
    private clientSettingsService: ClientSettingsService,
    private dialogService: ConfirmationDialogService,
    private formBuilder: FormBuilder,
    private marketDataService: MarketDataService,
    private operationsDataService: OperationsDataService,
    private orderService: OrderService,
    public qstService: QSTService,
    private router: Router,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private userSettingsService: UserSettingsService,
    private locationService: LocationService,
    private commodityFuturesPriceService: CommodityFuturesPriceService
  ) { }

  ngOnInit() {

    // Pricing basis for HTA contract
    if (this.createMode && this.contract.type === ContractType.HTA) {
      this.pricingBasisForHTA = true;
      this.isLoadingBasis = true;
      this.setupSegmentFormForPricingBasis();
      this.onBasisPricingTypeChanges();
    }

    // Pricing basis for DP contract, segment first part
    if (this.createMode && this.contract.type === ContractType.DP && this.dpPricing === ContractType.BASIS) {
      this.pricingBasisForDPFirstPart = true;
      this.isLoadingBasis = true;
      this.setupSegmentFormForPricingBasis();
      this.onBasisPricingTypeChanges();
    }

    // Pricing futures for Basis contract
    if (this.createMode && this.contract.type === ContractType.BASIS) {
      this.pricingFuturesForBasis = true;
      this.isLoadingMarketData = true;
      this.setupSegmentFormForPricingFuturesForBasis();
      this.onFuturesPricingTypeChanges();
      this.onSpecialHandlingChanges();
      this.onSpecialHandlingTypeChanges();
      this.pricingSegmentForm.setAsyncValidators([this.optionContractQuantityValidator]);
    }

    // Pricing futures for DP contract, segment first part
    if (this.createMode && this.contract.type === ContractType.DP && this.dpPricing === ContractType.HTA) {
      this.pricingFuturesForDPFirstPart = true;
      this.setupSegmentFormForPricingFuturesForDPFirstPart();
      this.onFuturesPricingTypeChanges();
    }

    // Pricing cash for DP contract
    if (this.createMode && this.contract.type === ContractType.DP && this.dpPricing === ContractType.CASH) {
      this.pricingCash = true;
      this.isLoadingBasis = true;
      this.setupSegmentFormForPricingCash();
      this.onCashPricingTypeChanges();
    }

    if (!this.createMode && this.contract.type === ContractType.BASIS) {
      if (this.pricingSegment.status === PricingSegmentStatus.PENDING_EXCHANGE) {
        this.editMode = true;
        this.setupSegmentFormForCreatingExchangeId();
      } else if (this.pricingSegment.status === PricingSegmentStatus.WORKING_EXCHANGE) {
        this.setupSegmentFormForEditingExchange();
      }
    }

    this.segmentDetails$ = this.clientSettingsService.getHmsSettingsByClientDocId(this.contract.clientDocId).pipe(
      switchMap((settings: HMSClientSettings) => {
        this.clientSettings = settings;
        return this.userService.getUserByDocId(this.authService.userProfile.app_metadata.firestoreDocId);
      }),
      switchMap((user: User) => {
        this.loggedInUser = user;
        return this.userSettingsService.getHmsSettingsByUserDocId(user.docId);
      }),
      switchMap((hmsUserSettings: HMSUserSettings) => {
        this.userSettings = hmsUserSettings;
        return this.locationService.getLocationByDocId(this.contract.clientDocId,
          this.pricingSegment ? this.pricingSegment.deliveryLocationDocId : this.contract.deliveryLocationDocId);
      }),
      switchMap((location: any) => {
        this.deliveryLocationName = location.name;
        return this.operationsDataService.getCommodityMap();
      }),
      switchMap((commodityMap: CommodityMap) => {
        this.commodityMap = commodityMap;
        this.commodities = Object.values(commodityMap.commodities) as Commodity[];
        if (this.pricingSegment && this.pricingSegment.orderDocId) {
          return this.orderService.getOrderByDocId(this.contract.accountDocId, this.pricingSegment.orderDocId).pipe(
            map((order: Order) => {
              this.existingOrder = order;
              if (this.existingOrder.status === OrderStatus.PARTIALLY_FILLED) {
                this.hasPartialFill = true;
              }
              return commodityMap;
            }),
            catchError(err => {
              console.error(`Error retrieving Order associated with PricingSegment: ${err}`);
              return of(commodityMap);
            })
          );
        } else {
          return of(commodityMap);
        }
      }),
      tap(() => {
        if (this.canPriceBasisForDPSecondPart) {
          this.setupSegmentFormForPricingBasisForDPSecondPart();
          this.onBasisPricingTypeChanges();
        } else if (this.canPriceFuturesForDPSecondPart) {
          this.setupSegmentFormForPricingFuturesForDPSecondPart();
          this.onFuturesPricingTypeChanges();
        }

        this.contractPriceHelper = new ContractPriceHelper(this.pricingSegmentForm, this.clientSettings.useWholeCent);
        this.contractPriceHelper.setAllPriceValidators({
          commodityProfile: this.commodityProfile,
        });

        if (this.pricingBasisForHTA || this.pricingBasisForDPFirstPart || this.pricingCash) {
          this.isLoadingBasis = true;
          this.setBasisObservable();
        }
        if (this.pricingFuturesForBasis) {
          this.setFuturesObservable();
        }
        this.isFormLoading = false;
        this.handlingPendingOrderAction();
      }),
      catchError(err => {
        this.errorMessage = 'Error retrieving pricing details; please try again later';
        console.error(`Error retrieving pricing segment details: ${err}`);
        return of(undefined);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['availableQuantity'] && this.pricingSegmentForm && this.pricingSegmentForm.get('quantity')) {
      this.pricingSegmentForm.get('quantity').setValidators(
        [Validators.required, Validators.pattern(QUANTITY_REGEX), Validators.max(this.availableQuantity)]);
      this.pricingSegmentForm.get('quantity').updateValueAndValidity();
      this.pricingSegmentForm.get('quantity').markAsTouched();
    }
  }

  ngOnDestroy() {
    if (this.exchangeOrderIdSub) {
      this.exchangeOrderIdSub.unsubscribe();
    }
  }

  get canAdjustMarketFutures() {
    return this.authzService.currentUserHasRole(UserRoles.MARKET_FUTURES_ADJUSTER_ROLE);
  }

  get canManuallyLockPrice() {
    return this.isManualPriceLocker && !this.isInTransitionalState && this.pricingSegment &&
      (this.pricingSegment.status === PricingSegmentStatus.WORKING_FUTURES ||
      this.pricingSegment.status === PricingSegmentStatus.WORKING_CASH);
  }

  get canCancelPricingSegment() {
    return !this.createMode && this.isContractUpdater && !this.isInTransitionalState &&
      this.pricingSegment && this.isWorkingTargetPricingSegment;
  }

  get canDeletePricingSegment() {
    // note:
    // handle race condition where the contract is fully priced, but still
    // waiting for the function to set the contract status to COMPLETE
    if (this.unpricedQuantity === 0 && this.contract.status !== ContractStatus.COMPLETE) {
      return false;
    }

    return !this.createMode && this.isContractDeleter && !this.isInTransitionalState
      && this.pricingSegment && this.isSegmentEligibleToDelete(this.pricingSegment);
  }

  get isLoading() {
    return this.isFormLoading || this.isLoadingBasis || this.isLoadingMarketData;
  }

  get isOrderFormValid() {
    this.orderFormInvalid = this.orderForm.invalid;
    return this.orderFormInvalid;
  }

  get contractUnit() {
    return this.commodities ? this.commodities.find(commodity => commodity.id === this.contract.commodityId).contractUnit : '';
  }

  get displayCashForPricingBasis() {
    return this.pricingBasisForHTA || this.pricingBasisForDPSecondPart;
  }

  get displayCashForPricingFutures() {
    return this.pricingFuturesForBasis || this.pricingFuturesForDPSecondPart;
  }

  get displayRelatedHedge() {
    return this.pricingSegment.relatedHedgeType === 'OTC' ? 'OTC Trade' : 'Exercised Option';
  }

  get basisPrice() {
    return Number.isFinite(this.contract.basisPrice) ? this.contract.basisPrice : this.pricingSegment.basisPrice;
  }

  get futuresPrice() {
    return this.contract.futuresPrice || this.pricingSegment.futuresPrice;
  }

  get cashPriceForPricingBasis() {
    const basisFormValue = Number(this.pricingSegmentForm.get('basisPrice').value);
    const basisPrice = Number.isFinite(basisFormValue) ? basisFormValue : 0;
    return this.futuresPrice + basisPrice + this.contract.basisAdjustment - this.contract.freightPrice;
  }

  get cashPriceForPricingFutures() {
    const futuresFormValue = Number(this.pricingSegmentForm.get('futuresPrice').value);
    const futuresPrice = Number.isFinite(futuresFormValue) ? futuresFormValue : 0;
    return futuresPrice + this.basisPrice + this.contract.basisAdjustment - this.contract.freightPrice;
  }

  get canCreateExchangePricing() {
    return this.authzService.currentUserHasRole(UserRoles.EXCHANGE_CREATOR_ROLE) && this.pricingFuturesForBasis;
  }

  get canCreateHedgedPricing() {
    return this.authzService.currentUserHasRole(UserRoles.HEDGED_CONTRACT_CREATOR_ROLE) && this.pricingFuturesForBasis;
  }

  get canCreateOptionPricing() {
    return this.canCreateHedgedPricing && this.availableQuantity >= this.contractSize;
  }

  get canEditPricing():boolean {
    return this.pricingSegment && !this.isInTransitionalState &&
      // can edit exchange
      this.authzService.currentUserHasRole(UserRoles.EXCHANGE_CREATOR_ROLE) && this.pricingSegment.status === PricingSegmentStatus.WORKING_EXCHANGE;
  }

  get canPriceBasisForDPSecondPart(): boolean {
    return this.pricingSegment && this.pricingSegment.isFuturesLocked &&
      !this.pricingSegment.isBasisLocked && !this.pricingSegment.basisPricingType
      // There is a race condition where Firestore onPricingSegmentCreate overwrites the PRICED status with PENDING_BASIS
      // if basis is priced within seconds of pricing futures.
      && (Number.isFinite(this.pricingSegment.marketFuturesPrice) || this.pricingSegment.futuresPricingType === PricingType.LIMIT);
  }

  get canPriceFuturesForDPSecondPart(): boolean {
    return this.pricingSegment && this.pricingSegment.isBasisLocked &&
      !this.pricingSegment.isFuturesLocked && !this.pricingSegment.futuresPricingType;
  }

  get editingPricing(): boolean {
    return this.editMode && this.updateComplete &&
      // editing exchange
      this.pricingSegment.status === PricingSegmentStatus.WORKING_EXCHANGE;
  }

  get futuresPriceForPricingCash() {
    if (this.pricingSegmentForm.get('cashPricingType').value === PricingType.MARKET) {
      if (this.pricingSegmentForm.get('futuresPrice')) {
        const futuresFormValue = Number(this.pricingSegmentForm.get('futuresPrice').value);
        if (Number.isFinite(futuresFormValue)) {
          return futuresFormValue;
        }
      }
      return this.contractPriceHelper.getRoundedValue('futuresPrice', this.marketFuturesPrice) || 0;
    } else {
      const cashFormValue = Number(this.pricingSegmentForm.get('cashPrice').value);
      const cashPrice = Number.isFinite(cashFormValue) ? cashFormValue : 0;
      return this.contractPriceHelper.getRoundedValue('cashPrice',
        (cashPrice - this.basisPriceForPricingCash + this.contract.freightPrice - this.contract.basisAdjustment));
    }
  }

  get orderDocIdDisplay() {
    return this.hasPartialFill ? `${this.pricingSegment.orderDocId} (${this.existingOrder.quantity - this.existingOrder.unfilledQuantity}/${this.existingOrder.quantity})` : this.pricingSegment.orderDocId;
  }

  get pricingFuturesWithExchange() {
    return this.pricingFuturesForBasis && this.pricingSegmentForm.get('specialHandling').value &&
      this.pricingSegmentForm.get('specialHandlingType').value === 'EXCHANGE';
  }

  get pricingFuturesWithExercisedOption() {
    return this.pricingFuturesForBasis && this.pricingSegmentForm.get('specialHandling').value &&
      this.pricingSegmentForm.get('specialHandlingType').value === 'OPTION';
  }

  get pricingBasisForDPSecondPart(): boolean {
    return this.editMode && this.canPriceBasisForDPSecondPart;
  }

  get pricingFuturesForDPSecondPart(): boolean {
    return this.editMode && this.canPriceFuturesForDPSecondPart;
  }

  allowTabOnly(event: KeyboardEvent) {
    if (event.key !== 'Tab') {
      event.preventDefault();
    }
  }

  calculateCashPrice() {
    const calculatedCashPrice = this.basisPriceForPricingCash + this.futuresPriceForPricingCash
      - this.contract.freightPrice + this.contract.basisAdjustment;
    this.marketCashPrice = this.contractPriceHelper.getRoundedValue('cashPrice', calculatedCashPrice);
    this.contractPriceHelper.setFieldValue('cashPrice', this.marketCashPrice);
    this.pricingSegmentForm.get('cashPrice').markAsTouched();
  }

  refreshFutures() {
    this.isLoadingMarketData = true;
    this.pricingSegmentForm.get('futuresPrice').markAsUntouched();
    const futuresYearMonth = this.contract.futuresYearMonth || this.selectedFuturesYearMonth;
    const docId = this.contract.commodityId + futuresYearMonth.substr(2) + futuresYearMonth.substring(0, 2);
    this.marketDataService.getRealTimeMarketDataByDocId(docId, this.authService.accessToken).pipe(
      take(1),
      catchError(err => {
        const message = `No market data found for ${docId}`;
        console.error(`${message}: ${err.message}`);
        this.openSnackBar(`Unable to retrieve current market data for ${docId}`, 'DISMISS', false);
        this.isLoadingMarketData = false;
        return throwError(message);
      })
    ).subscribe((marketData: MarketData) => {
      this.isLoadingMarketData = false;
      const marketPrice = this.commodityFuturesPriceService.getMarketPrice(this.contract.side, marketData) / this.commodityMap.commodities[this.contract.commodityId].marketDataDivisor;
      this.contractPriceHelper.setFieldValue('futuresPrice', marketPrice);
      this.pricingSegmentForm.get('futuresPrice').markAsDirty();
      if (this.pricingCash) {
        this.calculateCashPrice();
      }
    });
  }

  setExchangeId() {
    this.pricingSegment.status = PricingSegmentStatus.WORKING_EXCHANGE;
    this.pricingSegment.exchangeId = this.pricingSegmentForm.get('exchangeId').value;
    this.pricingSegment.exchangeTimestamp = new Date().toISOString();
    this.pricingSegmentEvent.emit({ action: 'pricingSegmentExchangeIdCreated', pricingSegment: this.pricingSegment });
  }

  futuresMonthFilter = (currentMoment: moment.Moment|null): boolean => {
    if (currentMoment) {
      const commodityFuturesMonths = this.commodities.find(commodity => commodity.id === this.contract.commodityId).contractMonths;
      return commodityFuturesMonths.includes(currentMoment.month());
    } else {
      return false;
    }
  }

  selectFuturesMonth(event: moment.Moment) {
    this.pricingSegmentForm.get('futuresMonth').setValue(event);
    this.pricingSegmentForm.get('futuresMonth').markAsDirty();
    this.futuresMonthRef.close();
    const futuresYearMonth = this.translateMomentToContractMonth(event);
    // set new market data if futures year month changed
    if (futuresYearMonth !== this.selectedFuturesYearMonth) {
      this.selectedFuturesYearMonth = futuresYearMonth;
      this.isLoadingMarketData = true;
      this.setFuturesObservable();
    }
  }

  formatPrice(formControlName: string) {
    const price = Number(this.pricingSegmentForm.get(formControlName).value);
    if (this.pricingSegmentForm.get(formControlName).value && Number.isFinite(price)) {
      this.contractPriceHelper.setFieldValue(formControlName, price);
    }
  }

  setEditMode(mode: boolean): void {
    this.editMode = mode;
    this.pricingSegmentForm.disable();
    if (this.editMode) {
      this.pricingSegmentForm.enable();
    }
    this.pricingSegmentForm.markAsPristine();
  }

  clearField(fieldName: string) {
    const field = this.pricingSegmentForm.get(fieldName);
    field.setValue('');
    field.markAsDirty();
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'Value required';
    } else if (control.hasError('pattern')) {
      return 'Value invalid';
    } else if (control.hasError('min')) {
      return 'Value must be at least ' + control.errors['min'].min;
    } else if (control.hasError('max')) {
      return 'Value cannot exceed ' + control.errors['max'].max;
    } else if (this.pricingSegmentForm.hasError('optionQuantity')) {
      return 'Must align with pricing quantity';
    } else if (control.hasError('invalidBasicPrice')) {
      return 'Value should be a number (to the quarter cent)';
    } else if (control.hasError('invalidFiveDigitPrice')) {
      return 'Value should be a number (up to five decimal places)';
    } else if (control.hasError('invalidFuturesPrice')) {
      return 'Value should be a number (to the quarter cent)';
    } else if (control.hasError('invalidWholeCentPrice')) {
      return 'Value should be a number (to the whole cent)';
    }
    return 'Unknown error';
  }

  getExchangeSide() {
    return this.getOrderSideFromSegmentSide(this.pricingSegment.side);
  }

  getOrderLink() {
    return this.router.createUrlTree(['/accounts', this.commodityProfile.accountDocId, 'orders', this.pricingSegment.orderDocId])
      .toString();
  }

  cancelPricingBasisForDPSecondPart() {
    this.setEditMode(false);
    this.setupSegmentFormForPricingBasisForDPSecondPart();
    this.onBasisPricingTypeChanges();
  }

  cancelPricingFuturesForDPSecondPart() {
    this.selectedFuturesYearMonth = '';
    this.setEditMode(false);
    this.setupSegmentFormForPricingFuturesForDPSecondPart();
    this.onFuturesPricingTypeChanges();
  }

  cancelPricingSegment() {
    // confirm if the user wants to cancel pricing segment
    this.dialogService.open({
        title: 'Cancel Pricing',
        message: 'This action cannot be undone. Do you want to cancel this pricing?',
        btnColor: 'accent'
      },
      'auto',
      '400px'
    );

    this.dialogService.afterClosed().subscribe(confirm => {
      if (!confirm) {
        return;
      }

      if (this.pricingSegment.orderDocId) {
        this.pricingSegment.status = PricingSegmentStatus.PENDING_ORDER_CANCEL;
        this.pricingSegmentEvent.emit(
          { action: 'pricingSegmentWithOrderCancelled', pricingSegment: this.pricingSegment } as PricingSegmentEvent);
      } else {
        this.pricingSegmentEvent.emit({ action: 'pricingSegmentCancelled', pricingSegment: this.pricingSegment } as PricingSegmentEvent);
      }
    });
  }

  deletePricingSegment() {
    // confirm if the user wants to delete pricing segment
    if (this.pricingSegment.isExchange) {
      this.dialogService.open({
          title: 'Delete Exchange Pricing',
          message: `This action cannot be undone. Do you want to delete this pricing?`,
          btnColor: 'accent'
        },
        'auto',
        '400px'
      );
    } else {
      this.dialogService.open({
          title: 'Delete Pricing',
          message: `This action cannot be undone. Do you want to delete this pricing?`,
          btnColor: 'accent'
        },
        'auto',
        '400px'
      );
    }

    this.dialogService.afterClosed().subscribe(confirm => {
      if (!confirm) {
        return;
      }

      this.pricingSegmentEvent.emit({ action: 'pricingSegmentDeleted', pricingSegment: this.pricingSegment } as PricingSegmentEvent);
    });
  }

  haltPricingSegment() {
    const completedQuantity = (this.existingOrder.quantity - this.existingOrder.unfilledQuantity) * this.contractSize;
    this.dialogService.open({
      title: 'Cancel Pricing',
      message: `This pricing segment has a partially filled order. Do you wish to reduce the pricing size to ${completedQuantity} ${this.contractUnit.toLowerCase()} and cancel the remainder of the pricing?`,
      btnColor: 'accent'
    },
      'auto',
      '400px'
    );

    this.dialogService.afterClosed().subscribe(confirm => {
      if (!confirm) {
        return;
      }
      this.pricingSegment.quantity = completedQuantity;
      this.pricingSegmentEvent.emit({ action: 'pricingSegmentHalted', pricingSegment: this.pricingSegment } as PricingSegmentEvent);
    });
  }

  setSegmentOrder() {
    if (this.orderForm.invalid) {
      return false;
    }
    if (this.pricingSegment.contractType === ContractType.BASIS) {
      this.pricingSegment.status = PricingSegmentStatus.WORKING_FUTURES;
    } else if (this.pricingSegment.futuresPricingType) {
      // DP contract - segment futures priced
      this.pricingSegment.status = PricingSegmentStatus.WORKING_FUTURES;
    } else {
      // DP contract - segment cash priced
      this.pricingSegment.status = PricingSegmentStatus.WORKING_CASH;
    }
    this.pricingSegment.orderDocId = this.orderForm.get('orderDocId').value;
    this.creatingOrder = false;
    this.orderForm.get('orderDocId').setValue('');
    this.orderForm.get('orderDocId').markAsUntouched();
    this.pricingSegmentEvent.emit({ action: 'pricingSegmentOrderCreated', pricingSegment: this.pricingSegment } as PricingSegmentEvent);
  }

  createOrder() {
    if (this.qstService.enabled) {
      const accountNumber = `${this.commodityProfile.officeCode}${this.commodityProfile.accountNumber}`;

      let expirationDate;
      let timeInForce = TimeInForce.GTC;
      if (this.contract.timeInForce === TimeInForce.GTD) {
        expirationDate = moment(this.contract.expirationDate).format('MM/DD/YYYY');
        timeInForce = TimeInForce.GTD;
      }

      const commodityId = this.commodityProfile.commodityId;
      const contractMonth = this.pricingSegment.futuresYearMonth.substring(2);
      const contractYear  = this.pricingSegment.futuresYearMonth.substring(0, 2);
      const futuresContract = `${this.commodityProfile.commodityId}${contractMonth}${contractYear}`;

      this.qstWorking = true;
      const commodity = this.commodityMap.commodities[commodityId];
      const quantity = this.getOrderQuantity();
      this.qstService.createOrder(commodity, futuresContract, quantity, accountNumber,
          this.getOrderSideFromSegmentSide(this.pricingSegment.side), OrderType.LIMIT, this.pricingSegment.futuresPrice,
          undefined, timeInForce, expirationDate)
        .then(clientOrderId => {
          this.newOrderClientId = clientOrderId;
        })
        .catch(err => {
          console.log(`QST createOrder error response in pricing segment detail: ${JSON.stringify(err)}`);
          this.qstWorking = false;
          this.qstCreateError = true;
          this.openSnackBar('Error from QST: ' + err, 'DISMISS', false);
        });
    }
  }

  cancelOrder() {
    if (this.qstService.enabled && this.existingOrder) {
      this.qstService.cancelOrder(this.existingOrder.accountNumber, this.existingOrder.clientOrderId)
        .then(response => {
          // Cancel needs to explicitly handle setting qstWorking to false because the order entry callback doesn't include xid
          this.qstWorking = false;
          this.pricingSegmentEvent.emit(
            { action: 'pricingSegmentOrderCancelled', pricingSegment: this.pricingSegment } as PricingSegmentEvent);
          this.cancellingOrder = false;
        })
        .catch(err => {
          console.log(`QST cancelOrder error response in pricing segment detail: ${JSON.stringify(err)}`);
          this.qstWorking = false;
          this.openSnackBar('Error from QST: ' + err, 'DISMISS', false);
        });
    } else {
      // confirm the user has completed the exchange order cancellation
      this.dialogService.open(
        {
          title: 'AST Not Enabled - Cancel Order',
          message: `Proceed to trading platform to cancel order. Is order ${this.pricingSegment.orderDocId} cancelled?`,
          btnColor: 'accent'
        },
        'auto',
        '400px'
      );

      this.dialogService.afterClosed().subscribe(confirm => {
        if (!confirm) {
          return;
        }
        this.pricingSegmentEvent.emit(
          { action: 'pricingSegmentOrderCancelled', pricingSegment: this.pricingSegment } as PricingSegmentEvent);
        this.cancellingOrder = false;
      });
    }
  }

  recreateOrder() {
    if (this.qstService.enabled && this.existingOrder) {
      // First cancel the existing order
      this.qstService.cancelOrder(this.existingOrder.accountNumber, this.existingOrder.clientOrderId)
        .then(response => {
          this.pricingSegment.orderDocId = undefined;
          // TODO handle completion of createOrder step - copied from TODO on ContractDetail
          const accountNumber = `${this.commodityProfile.officeCode}${this.commodityProfile.accountNumber}`;

          const commodityId = this.commodityProfile.commodityId;
          const contractMonth = this.pricingSegment.futuresYearMonth.substring(2);
          const contractYear  = this.pricingSegment.futuresYearMonth.substring(0, 2);
          const contract = `${this.commodityProfile.commodityId}${contractMonth}${contractYear}`;

          this.qstWorking = true;
          const commodity = this.commodityMap.commodities[commodityId];
          const quantity = this.getOrderQuantity();
          return this.qstService.createOrder(commodity, contract, quantity, accountNumber,
              this.getOrderSideFromSegmentSide(this.pricingSegment.side), OrderType.LIMIT, this.pricingSegment.futuresPrice,
              undefined, TimeInForce.GTC);
        })
        .then(clientOrderId => {
          this.newOrderClientId = clientOrderId;
        })
        .catch(err => {
          console.log(`QST cancel and recreate error response in pricing segment: ${JSON.stringify(err)}`);
          this.qstWorking = false;
          // TODO do we need to do a PricingSegment.orderDocId update if cancel is successful but create fails?
          if (!this.pricingSegment.orderDocId) {
            this.qstCreateError = true;
          }
          this.openSnackBar('Error from QST: ' + err, 'DISMISS', false);
        });

    } else {
      // confirm the user has completed the exchange order cancel and recreate
      this.dialogService.open(
        {
          title: 'AST Not Enabled - Cancel and Recreate Order',
          message: `Proceed to trading platform to cancel and recreate order. Be sure to enter and save the new order ID on the pricing. Is order ${this.pricingSegment.orderDocId} cancelled and recreated?`,
          btnColor: 'accent'
        },
        'auto',
        '400px'
      );

      this.dialogService.afterClosed().subscribe(confirm => {
        if (!confirm) {
          return;
        }
        this.pricingSegment.orderDocId = undefined;
      });
    }
  }

  updateOrder() {
    if (this.qstService.enabled && this.existingOrder) {

      let expirationDate;
      let timeInForce = TimeInForce.GTC;
      if (this.contract.timeInForce === TimeInForce.GTD) {
        expirationDate = moment(this.contract.expirationDate).format('MM/DD/YYYY');
        timeInForce = TimeInForce.GTD;
      }

      const commodityId = this.commodityProfile.commodityId;
      const contractMonth = this.pricingSegment.futuresYearMonth.substring(2);
      const contractYear  = this.pricingSegment.futuresYearMonth.substring(0, 2);
      const futuresContract = `${this.commodityProfile.commodityId}${contractMonth}${contractYear}`;

      this.qstWorking = true;
      const commodity = this.commodityMap.commodities[commodityId];
      const quantity = this.getOrderQuantity();
      this.qstService.cancelReplaceOrder(commodity, futuresContract, quantity, this.existingOrder.accountNumber,
          this.existingOrder.clientOrderId, this.getOrderSideFromSegmentSide(this.pricingSegment.side), OrderType.LIMIT,
          this.pricingSegment.futuresPrice, undefined, timeInForce, expirationDate)
        .then(clientOrderId => {
          this.qstWorking = false;
          this.newOrderClientId = clientOrderId;
          if (!clientOrderId) {
            const cancelledError = new Error(`QST action cancelled`);
            cancelledError.name = 'QSTActionCancelled';
            throw cancelledError;
          }
          return this.orderService.updateClientOrderId(
            this.existingOrder.accountDocId,
            this.existingOrder.docId,
            clientOrderId,
            this.authService.accessToken);
        })
        .then(orderUpdateResponse => {
          this.pricingSegmentEvent.emit(
            { action: 'pricingSegmentOrderUpdated', pricingSegment: this.pricingSegment } as PricingSegmentEvent);
          this.updatingOrder = false;
        })
        .catch(err => {
          console.log(`QST cancelReplaceOrder error response in pricing segment detail: ${JSON.stringify(err)}`);
          this.qstWorking = false;
          let displayedError = '';
          if (err.name === 'QSTActionCancelled') {
            this.openSnackBar(err.message, 'DISMISS', true);
            return;
          } else if (err.name === 'ClientOrderIdUpdateError') {
            displayedError = `Error updating Order ID ${this.newOrderClientId}`;
          } else {
            displayedError = `Error from QST: ${err}`;
          }
          this.openSnackBar(displayedError, 'DISMISS', false);
        });
    } else {
      // confirm the user has completed the exchange order cancellation
      this.dialogService.open(
        {
          title: 'AST Not Enabled - Update Order',
          message: `Proceed to trading platform to update order. Is order ${this.pricingSegment.orderDocId} updated?`,
          btnColor: 'accent'
        },
        'auto',
        '400px'
      );

      this.dialogService.afterClosed().subscribe(confirm => {
        if (!confirm) {
          return;
        }
        this.pricingSegmentEvent.emit({ action: 'pricingSegmentOrderUpdated', pricingSegment: this.pricingSegment } as PricingSegmentEvent);
        this.updatingOrder = false;
      });
    }
  }

  cancelPricingAction() {
    this.pricingSegmentEvent.emit({ action: 'pricingActionCancelled' } as PricingSegmentEvent);
  }

  priceBasisForHTA() {
    const basisPricingType = this.pricingSegmentForm.get('basisPricingType').value;
    const currentTimestamp = new Date().toISOString();
    const newSegment = this.prepNewPricingSegment(currentTimestamp);
    newSegment.futuresYearMonth = this.contract.futuresYearMonth;
    newSegment.basisPrice = Number(this.pricingSegmentForm.get('basisPrice').value);
    newSegment.basisPricingType = basisPricingType;
    newSegment.futuresPrice = this.contract.futuresPrice;
    newSegment.isFuturesLocked = true;
    newSegment.futuresLockedTimestamp = this.contract.futuresLockedTimestamp;
    const calculatedCashPrice = newSegment.basisPrice + newSegment.futuresPrice + newSegment.basisAdjustment - newSegment.freightPrice;
    newSegment.cashPrice = parseFloat(calculatedCashPrice.toFixed(this.getVariablePrecision('cashPrice')));
    newSegment.isMarketFuturesPriceAdjusted = this.contract.isMarketFuturesPriceAdjusted;
    if (this.contract.pricingType === PricingType.MARKET) {
      newSegment.marketFuturesPrice = this.contract.marketFuturesPrice;
      newSegment.marketFuturesPriceDifference = this.contract.marketFuturesPriceDifference;
    }
    if (basisPricingType === PricingType.MARKET) {
      newSegment.isBasisLocked = true;
      newSegment.basisLockedTimestamp = currentTimestamp;
      newSegment.isCashLocked = true;
      newSegment.cashLockedTimestamp = currentTimestamp;
      newSegment.status = PricingSegmentStatus.PRICED;
    } else {
      newSegment.status = PricingSegmentStatus.WORKING_BASIS;
    }
    this.pricingSegmentEvent.emit({ action: 'basisPricedForHTA', pricingSegment: newSegment } as PricingSegmentEvent);
  }

  priceBasisForDPFirstPart() {
    const basisPricingType = this.pricingSegmentForm.get('basisPricingType').value;
    const currentTimestamp = new Date().toISOString();
    const newSegment = this.prepNewPricingSegment(currentTimestamp);
    newSegment.basisPrice = Number(this.pricingSegmentForm.get('basisPrice').value);
    newSegment.basisPricingType = basisPricingType;
    if (basisPricingType === PricingType.MARKET) {
      newSegment.isBasisLocked = true;
      newSegment.basisLockedTimestamp = currentTimestamp;
      newSegment.status = PricingSegmentStatus.PENDING_FUTURES;
    } else {
      newSegment.status = PricingSegmentStatus.WORKING_BASIS;
    }
    newSegment.dpFirstPartType = PricingSegmentPartType.BASIS;
    this.pricingSegmentEvent.emit({ action: 'basisPricedForDPFirstPart', pricingSegment: newSegment } as PricingSegmentEvent);
  }

  priceBasisForDPSecondPart() {
    const basisPricingType = this.pricingSegmentForm.get('basisPricingType').value;
    const currentTimestamp = new Date().toISOString();

    this.pricingSegment.basisPrice = Number(this.pricingSegmentForm.get('basisPrice').value);
    this.pricingSegment.basisPricingType = basisPricingType;
    const calculatedCashPrice = this.pricingSegment.basisPrice + this.pricingSegment.futuresPrice +
      this.pricingSegment.basisAdjustment - this.pricingSegment.freightPrice;
    this.pricingSegment.cashPrice = parseFloat(calculatedCashPrice.toFixed(this.getVariablePrecision('cashPrice')));
    if (basisPricingType === PricingType.MARKET) {
      this.pricingSegment.isBasisLocked = true;
      this.pricingSegment.basisLockedTimestamp = currentTimestamp;
      this.pricingSegment.isCashLocked = true;
      this.pricingSegment.cashLockedTimestamp = currentTimestamp;
      this.pricingSegment.status = PricingSegmentStatus.PRICED;
    } else {
      this.pricingSegment.status = PricingSegmentStatus.WORKING_BASIS;
    }
    this.pricingSegmentEvent.emit({ action: 'basisPricedForDPSecondPart', pricingSegment: this.pricingSegment } as PricingSegmentEvent);
  }

  priceFuturesForBasis() {
    const futuresPricingType = this.pricingSegmentForm.get('futuresPricingType').value;
    const specialHandling = this.pricingSegmentForm.get('specialHandling').value;
    const specialHandlingType = this.pricingSegmentForm.get('specialHandlingType').value;
    const currentTimestamp = new Date().toISOString();
    const newSegment = this.prepNewPricingSegment(currentTimestamp);
    newSegment.futuresYearMonth = this.contract.futuresYearMonth;
    newSegment.basisPrice = this.contract.basisPrice;
    newSegment.isBasisLocked = true;
    newSegment.basisLockedTimestamp = this.contract.basisLockedTimestamp;
    newSegment.futuresPrice = Number(this.pricingSegmentForm.get('futuresPrice').value);
    newSegment.futuresPricingType = futuresPricingType;
    const calculatedCashPrice = newSegment.basisPrice + newSegment.futuresPrice + newSegment.basisAdjustment - newSegment.freightPrice;
    newSegment.cashPrice = parseFloat(calculatedCashPrice.toFixed(this.getVariablePrecision('cashPrice')));
    if (specialHandling && specialHandlingType === 'EXCHANGE') {
      newSegment.quantity = Number(this.pricingSegmentForm.get('quantity').value);
      newSegment.status = PricingSegmentStatus.PENDING_EXCHANGE;
      newSegment.isMarketFuturesPriceAdjusted = this.contractPriceHelper.getRoundedValue('futuresPrice', this.marketFuturesPrice)
        !== newSegment.futuresPrice;
      newSegment.isExchange = true;
      newSegment.exchangeType = this.pricingSegmentForm.get('exchangeType').value;
      newSegment.exchangeContracts = Number(this.pricingSegmentForm.get('exchangeContracts').value);
      newSegment.exchangeContraFirm = this.pricingSegmentForm.get('exchangeContraFirm').value;
      newSegment.exchangeContraAccount = this.pricingSegmentForm.get('exchangeContraAccount').value;
    } else if (futuresPricingType === PricingType.MARKET) {
      newSegment.isFuturesLocked = true;
      newSegment.futuresLockedTimestamp = currentTimestamp;
      newSegment.isCashLocked = true;
      newSegment.cashLockedTimestamp = currentTimestamp;
      newSegment.status = PricingSegmentStatus.PRICED;
      newSegment.isMarketFuturesPriceAdjusted = this.contractPriceHelper.getRoundedValue('futuresPrice', this.marketFuturesPrice)
        !== newSegment.futuresPrice;
      if (specialHandling && specialHandlingType === 'OTC') {
        newSegment.hasRelatedHedge = true;
        newSegment.relatedHedgeType = HedgeType.OTC;
      }
      if (specialHandling && specialHandlingType === 'OPTION') {
        newSegment.hasRelatedHedge = true;
        newSegment.relatedHedgeType = HedgeType.OPTION;
        newSegment.hedgedContracts = Number(this.pricingSegmentForm.get('optionContracts').value);
      }
    } else {
      newSegment.targetOrderThreshold = this.commodityProfile.targetOrderThreshold;
      const isOrderQuantity = newSegment.quantity >= this.commodityProfile.targetOrderThreshold;
      newSegment.status = isOrderQuantity ? PricingSegmentStatus.PENDING_ORDER : PricingSegmentStatus.WORKING_FUTURES;
    }
    this.pricingSegmentEvent.emit({ action: 'futuresPricedForBasis', pricingSegment: newSegment } as PricingSegmentEvent);
  }

  priceFuturesForDPFirstPart() {
    const futuresPricingType = this.pricingSegmentForm.get('futuresPricingType').value;
    const currentTimestamp = new Date().toISOString();
    const newSegment = this.prepNewPricingSegment(currentTimestamp);
    newSegment.futuresYearMonth = this.translateMomentToContractMonth(moment(this.pricingSegmentForm.get('futuresMonth').value));
    newSegment.futuresPrice = Number(this.pricingSegmentForm.get('futuresPrice').value);
    newSegment.futuresPricingType = futuresPricingType;
    if (futuresPricingType === PricingType.MARKET) {
      newSegment.isFuturesLocked = true;
      newSegment.futuresLockedTimestamp = currentTimestamp;
      newSegment.status = PricingSegmentStatus.PENDING_BASIS;
      newSegment.isMarketFuturesPriceAdjusted = this.contractPriceHelper.getRoundedValue('futuresPrice', this.marketFuturesPrice)
        !== newSegment.futuresPrice;
    } else {
      newSegment.targetOrderThreshold = this.commodityProfile.targetOrderThreshold;
      const isOrderQuantity = newSegment.quantity >= this.commodityProfile.targetOrderThreshold;
      newSegment.status = isOrderQuantity ? PricingSegmentStatus.PENDING_ORDER : PricingSegmentStatus.WORKING_FUTURES;
    }
    newSegment.dpFirstPartType = PricingSegmentPartType.FUTURES;
    this.pricingSegmentEvent.emit({ action: 'futuresPricedForDPFirstPart', pricingSegment: newSegment } as PricingSegmentEvent);
  }

  priceFuturesForDPSecondPart() {
    const futuresPricingType = this.pricingSegmentForm.get('futuresPricingType').value;
    const currentTimestamp = new Date().toISOString();
    this.pricingSegment.futuresYearMonth = this.translateMomentToContractMonth(moment(this.pricingSegmentForm.get('futuresMonth').value));
    this.pricingSegment.futuresPrice = Number(this.pricingSegmentForm.get('futuresPrice').value);
    this.pricingSegment.futuresPricingType = futuresPricingType;
    const calculatedCashPrice = this.pricingSegment.basisPrice + this.pricingSegment.futuresPrice +
      this.pricingSegment.basisAdjustment - this.pricingSegment.freightPrice;
    this.pricingSegment.cashPrice = this.contractPriceHelper.getRoundedValue('cashPrice', calculatedCashPrice);
    if (futuresPricingType === PricingType.MARKET) {
      this.pricingSegment.isFuturesLocked = true;
      this.pricingSegment.futuresLockedTimestamp = currentTimestamp;
      this.pricingSegment.isCashLocked = true;
      this.pricingSegment.cashLockedTimestamp = currentTimestamp;
      this.pricingSegment.status = PricingSegmentStatus.PRICED;
      this.pricingSegment.isMarketFuturesPriceAdjusted = this.contractPriceHelper.getRoundedValue('futuresPrice', this.marketFuturesPrice)
        !== this.pricingSegment.futuresPrice;
    } else {
      this.pricingSegment.targetOrderThreshold = this.commodityProfile.targetOrderThreshold;
      const isOrderQuantity = this.pricingSegment.quantity >= this.commodityProfile.targetOrderThreshold;
      this.pricingSegment.status = isOrderQuantity ? PricingSegmentStatus.PENDING_ORDER : PricingSegmentStatus.WORKING_FUTURES;
    }
    this.pricingSegmentEvent.emit({ action: 'futuresPricedForDPSecondPart', pricingSegment: this.pricingSegment } as PricingSegmentEvent);
  }

  priceCash() {
    const cashPricingType = this.pricingSegmentForm.get('cashPricingType').value;
    const currentTimestamp = new Date().toISOString();
    const newSegment = this.prepNewPricingSegment(currentTimestamp);
    newSegment.futuresYearMonth = this.translateMomentToContractMonth(moment(this.pricingSegmentForm.get('futuresMonth').value));
    newSegment.basisPrice = this.basisPriceForPricingCash;
    newSegment.futuresPrice = this.futuresPriceForPricingCash;
    newSegment.cashPricingType = cashPricingType;
    if (cashPricingType === PricingType.MARKET) {
      newSegment.isBasisLocked = true;
      newSegment.basisLockedTimestamp = currentTimestamp;
      newSegment.isFuturesLocked = true;
      newSegment.futuresLockedTimestamp = currentTimestamp;
      newSegment.cashPrice = this.marketCashPrice;
      newSegment.isCashLocked = true;
      newSegment.cashLockedTimestamp = currentTimestamp;
      newSegment.status = PricingSegmentStatus.PRICED;
      newSegment.isMarketFuturesPriceAdjusted = this.contractPriceHelper.getRoundedValue('futuresPrice', this.marketFuturesPrice)
        !== newSegment.futuresPrice;
    } else {
      newSegment.cashPrice = Number(this.pricingSegmentForm.get('cashPrice').value);
      newSegment.targetOrderThreshold = this.commodityProfile.targetOrderThreshold;
      const isOrderQuantity = newSegment.quantity >= this.commodityProfile.targetOrderThreshold;
      newSegment.status = isOrderQuantity ? PricingSegmentStatus.PENDING_ORDER : PricingSegmentStatus.WORKING_CASH;
    }
    newSegment.dpFirstPartType = PricingSegmentPartType.CASH;
    this.pricingSegmentEvent.emit({ action: 'cashPriced', pricingSegment: newSegment } as PricingSegmentEvent);
  }

  cancelPricingExchange() {
    // confirm the exchange has been cancelled/rejected
    this.dialogService.open(
      {
        title: 'Confirm Exchange Cancelled',
        message: `Has exchange ${this.pricingSegment.exchangeId} been cancelled or rejected in the trading platform?`,
        btnColor: 'accent'
      },
      '250px',
      '400px'
    );
    this.dialogService.afterClosed().subscribe(confirm => {
      if (!confirm) {
        return;
      }
      this.pricingSegment.status = PricingSegmentStatus.CANCELLED;
      this.pricingSegment.cancellationTimestamp = new Date().toISOString();
      this.pricingSegmentEvent.emit({ action: 'pricingSegmentExchangeCancelled', pricingSegment: this.pricingSegment});
    });
  }

  completePricingExchange() {
    // confirm the exchange has filled
    this.dialogService.open(
      {
        title: 'Confirm Exchange Completed',
        message: `Has exchange ${this.pricingSegment.exchangeId} filled in the trading platform?`,
        btnColor: 'accent'
      },
      '250px',
      '400px'
    );
    this.dialogService.afterClosed().subscribe(confirm => {
      if (!confirm) {
        return;
      }
      const exchangeCompleteTimestamp = new Date().toISOString();
      this.pricingSegment.status = PricingSegmentStatus.PRICED;
      this.pricingSegment.isFuturesLocked = true;
      this.pricingSegment.futuresLockedTimestamp = exchangeCompleteTimestamp;
      this.pricingSegment.isCashLocked = true;
      this.pricingSegment.cashLockedTimestamp = exchangeCompleteTimestamp;
      this.pricingSegment.exchangeTimestamp = exchangeCompleteTimestamp;
      this.pricingSegmentEvent.emit({ action: 'pricingSegmentExchangeComplete', pricingSegment: this.pricingSegment});
    });
  }

  lockPrice() {
    this.isLoadingMarketData = true;
    const marketDataDocId = this.pricingSegment.commodityId + this.pricingSegment.futuresYearMonth.substr(2) +
      this.pricingSegment.futuresYearMonth.substring(0, 2);
    this.marketDataService.getRealTimeMarketDataByDocId(marketDataDocId, this.authService.accessToken).pipe(
      take(1),
      catchError(err => {
        const message = `No market data found for ${marketDataDocId}`;
        console.error(`${message}: ${err.message}`);
        this.openSnackBar(`Unable to retrieve current market data for ${marketDataDocId}`, 'DISMISS', false);
        this.isLoadingMarketData = false;
        return throwError(message);
      })
    ).subscribe((marketData: MarketData) => {
      this.isLoadingMarketData = false;
      const marketPrice = this.commodityFuturesPriceService.getMarketPrice(this.contract.side, marketData)
                                / this.commodityMap.commodities[this.pricingSegment.commodityId].marketDataDivisor;
      const priceDifference = this.pricingSegment.futuresPrice - marketPrice;
      // confirm if the user wants to manually lock the futures price
      this.dialogService.open({
        title: 'Manually Lock Price',
        message: `This action cannot be undone.
        \tThe ${marketPrice.toFixed(4)} market price is currently ${(Math.abs(priceDifference)).toFixed(4)} from the ${this.pricingSegment.futuresPrice.toFixed(4)} target price.
        \tDo you want to lock the target price on this contract?`,
        btnColor: 'accent'
      },
        'auto',
        '400px'
      );
      this.dialogService.afterClosed().subscribe(confirm => {
        if (!confirm) {
          return;
        }
        this.handleManuallyLockedPrice(marketPrice, priceDifference);
      });
    });
  }

  reset() {
    this.setEditMode(false);
    // if editing exchange
    if (this.pricingSegment.contractType === ContractType.BASIS && this.pricingSegment.status === PricingSegmentStatus.WORKING_EXCHANGE) {
      this.setupSegmentFormForEditingExchange();
    }
    this.pricingSegmentForm.markAsPristine();
  }

  setEditModeAndBasisObservable() {
    this.setEditMode(true);
    // this is necessary as the initialization of this.onBasisPricingTypeChanges enabled field in absence of marketBasis
    if (this.contract.side === Side.BUY) {
      this.pricingSegmentForm.get('basisPrice').disable();
    }
    this.isLoadingBasis = true;
    this.setBasisObservable();
  }

  submitPricingUpdate() {
    // deliberately non-specific so that other pricingSegment edits can be accommodated in the future
    if (this.pricingSegmentForm.get('exchangeType')) {
      this.pricingSegment.exchangeType = this.pricingSegmentForm.get('exchangeType').value;
    }
    if (this.pricingSegmentForm.get('exchangeContracts')) {
      this.pricingSegment.exchangeContracts = Number(this.pricingSegmentForm.get('exchangeContracts').value);
    }
    if (this.pricingSegmentForm.get('exchangeContraFirm')) {
      this.pricingSegment.exchangeContraFirm = this.pricingSegmentForm.get('exchangeContraFirm').value;
    }
    if (this.pricingSegmentForm.get('exchangeContraAccount')) {
      this.pricingSegment.exchangeContraAccount = this.pricingSegmentForm.get('exchangeContraAccount').value;
    }
    if (this.pricingSegmentForm.get('futuresPrice')) {
      this.pricingSegment.futuresPrice = parseFloat(this.pricingSegmentForm.get('futuresPrice').value);
    }
    this.pricingSegmentEvent.emit({ action: 'pricingSegmentUpdated', pricingSegment: this.pricingSegment } as PricingSegmentEvent);
  }

  private get isContractUpdater() {
    return this.authzService.currentUserHasRole(UserRoles.CONTRACT_UPDATER_ROLE);
  }

  private get isContractDeleter() {
    return this.authzService.currentUserHasRole(UserRoles.CONTRACT_DELETER_ROLE);
  }

  private get isManualPriceLocker() {
    return this.authzService.currentUserHasRole(UserRoles.MANUAL_PRICE_LOCKER_ROLE);
  }

  private get canCompleteOrderAction() {
    return this.authzService.currentUserHasRole(UserRoles.ORDER_ADMIN_ROLE) && !this.isInTransitionalState &&
      this.pricingSegment && ORDER_STATUSES.includes(this.pricingSegment.status);
  }

  private get isInTransitionalState() {
    return this.isLoading || this.editMode || !this.updateComplete || !this.orderActionComplete || !this.pricingActionComplete;
  }

  private get basisPriceForPricingCash() {
    if (this.pricingSegmentForm.get('basisPrice')) {
      const basisFormValue = Number(this.pricingSegmentForm.get('basisPrice').value);
      if (Number.isFinite(basisFormValue)) {
        return basisFormValue;
      }
    }
    return this.marketBasisPrice || 0;
  }

  private get contractSize() {
    return this.commodityMap.commodities[this.contract.commodityId].contractSize;
  }

  private get defaultSpecialHandlingType() {
    if (this.canCreateExchangePricing && !this.canCreateHedgedPricing) {
      return 'EXCHANGE';
    } else if (this.canCreateHedgedPricing && !this.canCreateOptionPricing && !this.canCreateExchangePricing) {
      return 'OTC';
    }
    return '';
  }

  private get isWorkingTargetPricingSegment() {
    const workingFuturesBasisSegment = this.contract.type === ContractType.BASIS &&
      this.pricingSegment.status === PricingSegmentStatus.WORKING_FUTURES;
    const workingBasisHTASegment = this.contract.type === ContractType.HTA &&
      this.pricingSegment.status === PricingSegmentStatus.WORKING_BASIS;
    const workingCashDPSegment = this.contract.type === ContractType.DP &&
      this.pricingSegment.status === PricingSegmentStatus.WORKING_CASH;
    const workingFuturesDPSegment = this.contract.type === ContractType.DP &&
      this.pricingSegment.status === PricingSegmentStatus.WORKING_FUTURES;
    const workingBasisDPSegment = this.contract.type === ContractType.DP &&
      this.pricingSegment.status === PricingSegmentStatus.WORKING_BASIS;

    return workingFuturesBasisSegment || workingBasisHTASegment || workingCashDPSegment ||
      workingFuturesDPSegment || workingBasisDPSegment;
  }

  // note: We currently allow up to 4-5 decimal places precision for all the prices. This function is only used for
  //       calculations before creating or updating pricing segments. This should not be used for display formatter or validations.
  //       This handles old contracts that already has its price locked.
  private getVariablePrecision(fieldName: string): number {
    const fiveDigitFields = ['cashPrice', 'futuresPrice'];
    if (this.pricingSegmentForm.get('specialHandling') && this.pricingSegmentForm.get('specialHandlingType')) {
      const specialHandling = this.pricingSegmentForm.get('specialHandling').value;
      const specialHandlingType = this.pricingSegmentForm.get('specialHandlingType').value;
      return fiveDigitFields.includes(fieldName) && specialHandling && FIVE_DIGIT_HANDLING_TYPES.includes(specialHandlingType) ? 5 : 4;
    }
    // fallthrough if special handling unavailable
    return fiveDigitFields.includes(fieldName) && this.contract.hasRelatedHedge ? 5 : 4;
  }

  private getPriceValidators(useFiveDigit: boolean) {
    // set min and max prices from commodity profile
    // TODO For now min/max prices are being applied to either futures and/or cash depending on contract type
    const pricingPattern = useFiveDigit ? FIVE_DIGIT_REGEX : FUTURES_REGEX;
    return [Validators.required, Validators.pattern(pricingPattern),
      Validators.min(this.commodityProfile.minPrice), Validators.max(this.commodityProfile.maxPrice)];
  }

  private prepNewPricingSegment(currentTimestamp: string) {
    const newSegment = new PricingSegment();
    newSegment.clientDocId = this.contract.clientDocId;
    newSegment.contractDocId = this.contract.docId;
    newSegment.contractVersionNumber = this.contract.versionNumber + 1;
    newSegment.contractType = this.contract.type;
    newSegment.side = this.contract.side;
    newSegment.commodityProfileDocId = this.contract.commodityProfileDocId;
    newSegment.commodityId = this.contract.commodityId;
    newSegment.clientLocationDocId = this.contract.clientLocationDocId;
    newSegment.deliveryLocationDocId = this.contract.deliveryLocationDocId;
    newSegment.freightPrice = this.contract.freightPrice;
    newSegment.basisAdjustment = this.contract.basisAdjustment;
    newSegment.deliveryPeriod = this.contract.deliveryPeriod;
    newSegment.quantity = Number(this.pricingSegmentForm.get('quantity').value);
    newSegment.creationTimestamp = currentTimestamp;
    newSegment.creatorDocId = this.authService.userProfile.app_metadata.firestoreDocId;
    newSegment.creatorAccountingSystemId = this.userSettings.accountingSystemId;
    const userName = `${this.loggedInUser.firstName} ${this.loggedInUser.lastName}`;
    newSegment.creatorName = userName;
    newSegment.originatorDocId = this.contract.originatorDocId;
    newSegment.originatorAccountingSystemId = this.contract.originatorAccountingSystemId;
    newSegment.originatorName = this.contract.originatorName;
    newSegment.lastUpdatedTimestamp = currentTimestamp;
    newSegment.lastUpdatedByAccountingSystemId = this.userSettings.accountingSystemId;
    newSegment.lastUpdatedByName = userName;
    return newSegment;
  }

  private handleManuallyLockedPrice(marketPrice: number, priceDifference: number) {
    const currentTimestamp = new Date().toISOString();
    this.pricingSegment.isPriceManuallyLocked = true;
    this.pricingSegment.manualLockPriceDifference = parseFloat((Math.abs(priceDifference)).toFixed(4));
    this.pricingSegment.futuresLockedTimestamp = currentTimestamp;
    this.pricingSegment.isFuturesLocked = true;
    if (this.pricingSegment.orderDocId) {
      this.pricingSegment.status = PricingSegmentStatus.PENDING_ORDER_CANCEL;
    }
    if (this.pricingSegment.cashPricingType) {
      this.pricingSegment.cashLockedTimestamp = currentTimestamp;
      this.pricingSegment.isCashLocked = true;
      this.pricingSegment.basisLockedTimestamp = currentTimestamp;
      this.pricingSegment.isBasisLocked = true;
      this.pricingSegment.futuresPrice = parseFloat(marketPrice.toFixed(4));
      this.pricingSegment.basisAdjustment = parseFloat((this.pricingSegment.basisAdjustment + priceDifference).toFixed(4));
    }
    this.pricingSegmentEvent.emit({ action: 'pricingSegmentManuallyPriced', pricingSegment: this.pricingSegment } as PricingSegmentEvent);
  }

  private setupSegmentFormForPricingBasis() {
    this.pricingSegmentForm = this.formBuilder.group({
      quantity: [ this.availableQuantity,
        [ Validators.required, Validators.pattern(QUANTITY_REGEX), Validators.max(this.availableQuantity) ] ],
      basisPrice: [ '', [ Validators.required, Validators.pattern(PRICE_REGEX) ] ],
      basisPricingType: [ PricingType.MARKET, [ Validators.required ] ],
    });
    if (this.contract.side === Side.BUY) {
      this.pricingSegmentForm.get('basisPrice').disable();
    }
  }

  private setupSegmentFormForCreatingExchangeId() {
    this.pricingSegmentForm = this.formBuilder.group({
      exchangeId: [ '', [ Validators.required ] ]
    });
  }

  private setupSegmentFormForEditingExchange() {
    this.pricingSegmentForm = this.formBuilder.group({
      exchangeType: [ { value: this.pricingSegment.exchangeType, disabled: true }, [ Validators.required ] ],
      exchangeContracts: [ { value: this.pricingSegment.exchangeContracts, disabled: true }, [ Validators.required, Validators.pattern(CONTRACTS_REGEX) ] ],
      exchangeContraFirm: [ { value: this.pricingSegment.exchangeContraFirm, disabled: true }, [ Validators.required, Validators.maxLength(100) ] ],
      exchangeContraAccount: [ { value: this.pricingSegment.exchangeContraAccount, disabled: true }, [ Validators.required, Validators.maxLength(30) ] ],
      futuresPrice: [ { value: this.pricingSegment.futuresPrice, disabled: true } ] // validators set by contractPricingHelper
    });
  }

  private setupSegmentFormForPricingFuturesForBasis() {
    this.pricingSegmentForm = this.formBuilder.group({
      specialHandling: [ false, [ Validators.required ] ],
      specialHandlingType: [ { value: '', disabled: true }, [ Validators.required ] ],
      quantity: [ this.availableQuantity,
        [ Validators.required, Validators.pattern(QUANTITY_REGEX), Validators.max(this.availableQuantity) ] ],
      futuresPrice: [ { value: '', disabled: !this.canAdjustMarketFutures }, this.getPriceValidators(this.contract.hasRelatedHedge) ],
      futuresPricingType: [ PricingType.MARKET, [ Validators.required ] ],
      exchangeType: [ { value: ExchangeType.EFP, disabled: true }, [ Validators.required ] ],
      exchangeContracts: [ { value: '', disabled: true }, [ Validators.required, Validators.pattern(CONTRACTS_REGEX) ] ],
      exchangeContraFirm: [ { value: '', disabled: true }, [ Validators.required, Validators.maxLength(100) ] ],
      exchangeContraAccount: [ { value: '', disabled: true }, [ Validators.required, Validators.maxLength(30) ] ],
      optionContracts: [ { value: '', disabled: true }, [ Validators.required, Validators.pattern(CONTRACTS_REGEX) ] ]
    });
  }

  private setupSegmentFormForPricingFuturesForDPFirstPart() {
    this.pricingSegmentForm = this.formBuilder.group({
      quantity: [ this.availableQuantity,
        [ Validators.required, Validators.pattern(QUANTITY_REGEX), Validators.max(this.availableQuantity) ] ],
      futuresMonth: [ '', [ Validators.required ] ],
      futuresPrice: [ { value: '', disabled: !this.canAdjustMarketFutures }, this.getPriceValidators(this.contract.hasRelatedHedge) ],
      futuresPricingType: [ PricingType.MARKET, [ Validators.required ] ],
    });
  }

  private setupSegmentFormForPricingFuturesForDPSecondPart() {
    this.pricingSegmentForm = this.formBuilder.group({
      futuresMonth: [ '', [ Validators.required ] ],
      futuresPrice: [ '', [ Validators.required, Validators.pattern(FUTURES_REGEX) ] ],
      futuresPricingType: [ PricingType.MARKET, [ Validators.required ] ],
    });
  }

  private setupSegmentFormForPricingBasisForDPSecondPart() {
    this.pricingSegmentForm = this.formBuilder.group({
      basisPrice: [ '', [ Validators.required, Validators.pattern(PRICE_REGEX) ] ],
      basisPricingType: [ PricingType.MARKET, [ Validators.required ] ],
    });
    if (this.contract.side === Side.BUY) {
      this.pricingSegmentForm.get('basisPrice').disable();
    }
  }

  private setupSegmentFormForPricingCash() {
    this.pricingSegmentForm = this.formBuilder.group({
      quantity: [ this.availableQuantity,
        [ Validators.required, Validators.pattern(QUANTITY_REGEX), Validators.max(this.availableQuantity) ] ],
      futuresMonth: [ '', [ Validators.required ] ],
      futuresPrice: [{ value: '', disabled: !this.canAdjustMarketFutures }, this.getPriceValidators(this.contract.hasRelatedHedge) ],
      cashPrice: [ '', this.getPriceValidators(this.contract.hasRelatedHedge) ],
      cashPricingType: [ PricingType.MARKET, [ Validators.required ] ],
    });
    this.pricingSegmentForm.get('cashPrice').disable();
  }

  private setBasisObservable() {

    if (this.contract.side === Side.BUY) {
      this.basis$ = this.basisService.getBasisByClientCommodityProfileAndLocation(
        this.contract.clientDocId, this.commodityProfile.docId, this.contract.deliveryLocationDocId).pipe(
        take(1),
        tap((basis: Basis) => {
          this.handleBasis(basis);
          this.isLoadingBasis = false;
        }),
        shareReplay({bufferSize: 1, refCount: true}),
        catchError(err => {
          this.isLoadingBasis = false;
          if (this.pricingSegmentForm.get('basisPrice')) {
            this.pricingSegmentForm.get('basisPrice').setValue('');
          }
          this.openSnackBar('Error retrieving basis for contract delivery period', 'DISMISS', false);
          console.error(`Error retrieving basis data: ${err.message || err}`);
          return of(undefined);
        })
      );
    } else {
      // sell contract
      this.handleManualBasisEntry();
    }
  }

  // handleBasis is only called for BUY side contracts
  private handleBasis(basis: Basis) {
    if (basis) {
      const deliveryPeriodBasis = basis.deliveryPeriodBases[this.contract.deliveryPeriod];
      if (deliveryPeriodBasis) {
        // note: market basis price is automatically rounded if the useWholeCent settings is turned on (consistently with basis admin)
        this.marketBasisPrice = this.contractPriceHelper.getRoundedValue('basisPrice', deliveryPeriodBasis.basis);
        if (!this.pricingCash) {
          this.contractPriceHelper.setFieldValue('basisPrice', this.marketBasisPrice);
        }
      } else {
        // no delivery period Basis found
        this.handleManualBasisEntry();
      }
    } else {
      // no Basis found
      this.handleManualBasisEntry();
    }
  }

  // allow basis to be entered directly
  private handleManualBasisEntry() {
    if (!this.pricingSegmentForm.get('basisPrice')) {
      this.pricingSegmentForm.addControl('basisPrice',
        this.formBuilder.control('', [ Validators.required, Validators.pattern(PRICE_REGEX) ]));
    }
    this.pricingSegmentForm.get('basisPrice').setValue('');
    this.pricingSegmentForm.get('basisPrice').enable();
    this.isLoadingBasis = false;
  }

  private setFuturesObservable() {
    const futuresYearMonth = this.contract.futuresYearMonth || this.selectedFuturesYearMonth;
    const docId = this.contract.commodityId + futuresYearMonth.substr(2) + futuresYearMonth.substring(0, 2);
    this.futures$ = this.marketDataService.getRealTimeMarketDataByDocId(docId, this.authService.accessToken).pipe(
      take(1),
      tap((marketData: MarketData) => {
        if (!marketData || moment(marketData.expirationDate).startOf('day').isBefore(moment())) {
          this.handleInvalidFuturesMonth(futuresYearMonth);
        } else {
          const marketDataFuturesPrice = this.commodityFuturesPriceService.getMarketPrice(this.contract.side, marketData);
          const marketDataDivisor = this.commodities.find(commodity => commodity.id === this.contract.commodityId).marketDataDivisor;
          this.marketFuturesPrice = marketDataFuturesPrice / marketDataDivisor;
          if (this.pricingSegmentForm.get('futuresPrice')) {
            this.contractPriceHelper.setFieldValue('futuresPrice', this.marketFuturesPrice);
          }
          if (this.pricingCash) {
            this.calculateCashPrice();
          }
        }
        this.isLoadingMarketData = false;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      catchError(err => {
        if (err.status === 404) {
          // not found; this means the month has expired or is not yet active and is not valid
          this.handleInvalidFuturesMonth(futuresYearMonth);
        } else {
          // generic message for all other errors
          this.openSnackBar('Error retrieving market data for selected futures month', 'DISMISS', false);
        }
        console.error(`Error retrieving market data: ${err}`);
        this.isLoadingMarketData = false;
        return of(undefined);
      })
    );

  }

  private handleInvalidFuturesMonth(futuresYearMonth: string) {
    const futuresYearMonthMoment = this.translateContractMonthToMoment(futuresYearMonth);
    const futuresYearMonthField = this.pricingSegmentForm.get('futuresMonth');
    // if month is chosen within this component (pricing cash, pricing futures for dp), blank field and force new selection
    if (futuresYearMonthField) {
      futuresYearMonthField.setValue('');
      this.openSnackBar(`${futuresYearMonthMoment.format('MMM yyyy')} is either expired or not yet active.`, 'DISMISS', false);
    } else {
      // not currently editing form with futures month (pricing futures for basis) so they need to cancel pricing and update contract
      // before proceeding
      this.cancelPricingAction();
      this.openSnackBar(
        `${futuresYearMonthMoment.format('MMM yyyy')} is either expired or not yet active. Please edit the futures month on the contract
         before pricing futures.`
        , 'DISMISS', false);
    }
  }

  private handlingPendingOrderAction() {
    if (this.createMode) {
      return;
    }
    // ensure these properties are reset on new doc emission for changed data while viewing page
    this.orderActionComplete = true;
    this.creatingOrder = false;
    this.cancellingOrder = false;
    this.updatingOrder = false;
    this.recreatingOrder = false;
    if (this.pricingSegment.status === PricingSegmentStatus.PENDING_ORDER && this.canCompleteOrderAction) {
      this.orderActionComplete = false;
      this.creatingOrder = true;
      this.setupQST();
    } else if (this.pricingSegment.status === PricingSegmentStatus.PENDING_ORDER_CANCEL && this.canCompleteOrderAction) {
      this.orderActionComplete = false;
      this.cancellingOrder = true;
      this.setupQST();
    } else if (this.pricingSegment.status === PricingSegmentStatus.PENDING_ORDER_UPDATE && this.canCompleteOrderAction) {
      this.orderActionComplete = false;
      this.updatingOrder = true;
      this.setupQST();
    } else if (this.pricingSegment.status === PricingSegmentStatus.PENDING_ORDER_RECREATE && this.canCompleteOrderAction) {
      this.orderActionComplete = false;
      this.recreatingOrder = true;
      this.setupQST();
    }
  }

  private setupQST() {
    this.exchangeOrderIdSub = this.qstService.orderEntry$.pipe(
      filter(orderEntry => orderEntry.OD === this.newOrderClientId),
      map(orderEntry => orderEntry.XID)
    ).subscribe(xid => {
      if (xid) {
        this.qstWorking = false;
        this.orderForm.get('orderDocId').setValue(xid);
        this.orderForm.get('orderDocId').markAsDirty();
        if (
          this.pricingSegment.status === PricingSegmentStatus.PENDING_ORDER ||
          this.pricingSegment.status === PricingSegmentStatus.PENDING_ORDER_RECREATE) {
          this.setSegmentOrder();
        }
      }
    });
  }

  private getOrderQuantity(): number {
    const fullContracts = Math.trunc(this.pricingSegment.quantity / this.contractSize);
    const remainingQuantity = this.pricingSegment.quantity % this.contractSize;
    return remainingQuantity >= this.pricingSegment.targetOrderThreshold ? fullContracts + 1 : fullContracts;
  }

  private getOrderSideFromSegmentSide(segmentSide: Side) {
    if (segmentSide === Side.BUY) {
      return Side.SELL;
    } else {
      return Side.BUY;
    }
  }

  private onBasisPricingTypeChanges() {
    this.pricingSegmentForm.get('basisPricingType').valueChanges.subscribe(value => {
      this.pricingSegmentForm.get('basisPrice').setValue(Number.isFinite(this.marketBasisPrice) ?
        this.contractPriceHelper.getRoundedValueAsString('basisPrice', this.marketBasisPrice) : '');
      if (value === PricingType.MARKET && this.contract.side === Side.BUY && Number.isFinite(this.marketBasisPrice)) {
        this.pricingSegmentForm.get('basisPrice').disable();
      } else {
        this.pricingSegmentForm.get('basisPrice').enable();
      }
    });
  }

  private onFuturesPricingTypeChanges() {
    this.pricingSegmentForm.get('futuresPricingType').valueChanges.subscribe(value => {
      if (value === PricingType.MARKET && Number.isFinite(this.marketFuturesPrice)) {
        this.contractPriceHelper.setFieldValue('futuresPrice', this.marketFuturesPrice);
        const futuresYearMonth = this.contract.futuresYearMonth || this.selectedFuturesYearMonth;
        if (futuresYearMonth) {
          this.isLoadingMarketData = true;
          this.setFuturesObservable();
        }
      }
      this.setEnabledStatusOfPriceFields();
    });
  }

  private onCashPricingTypeChanges() {
    this.pricingSegmentForm.get('cashPricingType').valueChanges.subscribe(value => {
      this.pricingSegmentForm.get('cashPrice').setValue(Number.isFinite(this.marketCashPrice) ?
        this.contractPriceHelper.getRoundedValueAsString('cashPrice', this.marketCashPrice) : '');
      if (value === PricingType.MARKET) {
        this.pricingSegmentForm.get('cashPrice').disable();
        this.isLoadingMarketData = true;
        this.setFuturesObservable();
       } else {
        this.pricingSegmentForm.get('cashPrice').enable();
      }
      this.setEnabledStatusOfPriceFields();
    });
  }

  private onSpecialHandlingChanges() {
    this.pricingSegmentForm.get('specialHandling').valueChanges.subscribe(specialHandling => {
      if (specialHandling) {
        this.pricingSegmentForm.get('specialHandlingType').enable();
        this.pricingSegmentForm.get('futuresPricingType').setValue(PricingType.MARKET);
        if (this.defaultSpecialHandlingType) {
          this.pricingSegmentForm.get('specialHandlingType').setValue(this.defaultSpecialHandlingType);
        }
      } else {
        this.pricingSegmentForm.get('specialHandlingType').disable();
      }
      const specialHandlingType = this.pricingSegmentForm.get('specialHandlingType').value;
      this.setEnabledStatusOfSpecialHandlingFields(specialHandling, specialHandlingType);
      this.contractPriceHelper.setAllPriceValidators({specialHandling});
    });
  }

  private onSpecialHandlingTypeChanges() {
    this.pricingSegmentForm.get('specialHandlingType').valueChanges.subscribe(specialHandlingType => {
      const specialHandling = this.pricingSegmentForm.get('specialHandling').value;
      this.setEnabledStatusOfSpecialHandlingFields(specialHandling, specialHandlingType);
      this.contractPriceHelper.setAllPriceValidators({specialHandlingType});
    });
  }

  private setEnabledStatusOfSpecialHandlingFields(specialHandling: boolean, specialHandlingType: string) {
    if (specialHandling && specialHandlingType === 'EXCHANGE') {
      this.pricingSegmentForm.get('exchangeType').enable();
      this.pricingSegmentForm.get('exchangeContracts').enable();
      this.pricingSegmentForm.get('exchangeContraFirm').enable();
      this.pricingSegmentForm.get('exchangeContraAccount').enable();

      this.pricingSegmentForm.get('optionContracts').disable();
    } else if (specialHandling && specialHandlingType === 'OPTION') {
      this.pricingSegmentForm.get('optionContracts').enable();

      this.pricingSegmentForm.get('exchangeType').disable();
      this.pricingSegmentForm.get('exchangeContracts').disable();
      this.pricingSegmentForm.get('exchangeContraFirm').disable();
      this.pricingSegmentForm.get('exchangeContraAccount').disable();
    } else {
      this.pricingSegmentForm.get('exchangeType').disable();
      this.pricingSegmentForm.get('exchangeContracts').disable();
      this.pricingSegmentForm.get('exchangeContraFirm').disable();
      this.pricingSegmentForm.get('exchangeContraAccount').disable();
      this.pricingSegmentForm.get('optionContracts').disable();
    }
  }

  private setEnabledStatusOfPriceFields() {
    this.setFieldEnabled('futuresPrice', this.shouldEnableFuturesPrice());
  }

  private setFieldEnabled(fieldName: string, enabled: boolean) {
    if (enabled) {
      this.pricingSegmentForm.get(fieldName).enable();
    } else {
      this.pricingSegmentForm.get(fieldName).disable();
    }
  }

  private shouldEnableFuturesPrice() {
    const cashPricingType = this.pricingSegmentForm.get('cashPricingType') ?
                            this.pricingSegmentForm.get('cashPricingType').value :
                            undefined;

    const futuresPricingType = this.pricingSegmentForm.get('futuresPricingType') ?
                               this.pricingSegmentForm.get('futuresPricingType').value :
                               undefined;

    return (this.canAdjustMarketFutures && (cashPricingType === PricingType.MARKET || futuresPricingType === PricingType.MARKET))
    || futuresPricingType === PricingType.LIMIT;
  }

  private isSegmentEligibleToDelete(segment: PricingSegment) {
    // Note: OTC, and exercised option deletion are not yet supported
    if (segment.hasRelatedHedge && segment.relatedHedgeType === HedgeType.OTC) {
      return false;
    // Note: only allow exercised option deletion if it has a linked hedge
    // TODO: remove the check below if Alcivia prod doesn't have an exercised option created
    //       prior to this implementation
    } else if (segment.relatedHedgeType === HedgeType.OPTION && !segment.hedgeDocId) {
      return false;
    }

    if (segment.status === PricingSegmentStatus.PRICED && segment.contractType === ContractType.BASIS) {
      return true;
    } else if (segment.status === PricingSegmentStatus.PRICED && segment.contractType === ContractType.HTA) {
      return true;
    } else if ((segment.status === PricingSegmentStatus.PRICED
      || segment.status === PricingSegmentStatus.PENDING_BASIS
      || segment.status === PricingSegmentStatus.PENDING_FUTURES
      || (segment.status === PricingSegmentStatus.WORKING_BASIS && segment.futuresLockedTimestamp)
      || (segment.status === PricingSegmentStatus.WORKING_FUTURES && segment.basisLockedTimestamp && segment.orderDocId === ''))
      && segment.contractType === ContractType.DP) {
      return true;
    }

    return false;
  }

  /**
   * Ensures the quantity on a segment with an option type related hedge matches the hedged quantity for the exercised option
   */
  private optionContractQuantityValidator = (formGroup: FormGroup) => {
    if (!formGroup.value.specialHandling || formGroup.value.specialHandlingType !== 'OPTION') {
      return of(undefined);
    }
    const optionQuantity = parseInt((Number(formGroup.value.optionContracts) * this.contractSize).toFixed(0), 10);
    const quantity = Number(formGroup.value.quantity);
    if (optionQuantity !== quantity) {
      return of({ optionQuantity: true });
    }
    return of(undefined);
  }

  private translateContractMonthToMoment(value: string): moment.Moment {
    const year = moment.parseTwoDigitYear(value.substring(0, 2));
    const monthCode = value.substring(2);
    const idxMonth = this.contractMonths.indexOf(monthCode);
    return moment().year(year).month(idxMonth).startOf('month');
  }

  private translateMomentToContractMonth(selectedMonth: moment.Moment): string {
    return selectedMonth.format(YEAR_FORMAT) + ContractMonth[this.contractMonths[selectedMonth.month()]];
  }

  private openSnackBar(message: string, action?: string, success = true) {
    if (success) {
      this.snackBar.open(message, action, {
        duration: 3000,
        verticalPosition: 'bottom'
      });
    } else {
      this.snackBar.open(message, action, {
        verticalPosition: 'bottom'
      });
    }
  }

}
